import { Role } from 'model';
import request from 'requesters/user.request';
import { PaginationData, QueryParams } from 'types';

const roleService = {
  getRoles: async (query: QueryParams) => {
    return request.get<PaginationData<Role>>(`/roles`, {
      params: query,
    });
  },
};

export default roleService;
