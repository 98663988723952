import BaseValidation from 'model/BaseValidation';
import request from 'requesters/core.request';

const baseValidationService = {
  createBaseValidation: async <T = unknown, U = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    data: T,
    params?: U
  ) => {
    return request.post<BaseValidation>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-validations`,
      data,
      { params }
    );
  },
  updateBaseValidation: async <T = unknown, U = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    baseValidationId: string,
    data: T,
    params?: U
  ) => {
    return request.put<BaseValidation>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-validations/${baseValidationId}`,
      data,
      { params }
    );
  },
  deleteBaseValidation: async <U = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    baseValidationId: string,
    params?: U
  ) => {
    return request.delete<BaseValidation>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-validations/${baseValidationId}`,
      { params }
    );
  },
};
export default baseValidationService;
