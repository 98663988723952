import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  theme,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePickerRange, SearchSelect } from 'components';
import { Company, IssuePriority, IssueStatus, User } from 'model';
import { useState, useEffect } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { TRA_PRIORITIES_COLOR, TRA_STATUS_COLOR } from 'utils/contants';
import { jsonToArray, parseQueryParams } from 'utils';
import dayjs from 'dayjs';
type FilterFormData = {
  assigneeId?: string;
  companyId?: string;
  groupIssues?: string;
  issueStatusId?: string[];
  startDate: unknown[];
  dtPriority?: string[];
};
type FilterIssueFormProps = {
  companies: Company[];
  users: User[];
  issueStatuses: IssueStatus[];
  userCompanyId: undefined | string;
  workspaceId: string;
  projectId: string;
  activeTab: string;
  isOpenFilter: boolean;
  issuePriorities: IssuePriority[];
  setIsOpenFilter: (value: boolean) => void;
  handleFilter: (value: { [key: string]: unknown }) => void;
  isReset: boolean;
  setReset: (value: boolean) => void;
  module: string;
  isOwnerCompany: boolean;
};
type PriorityCode = 'STANDARD' | 'CONFIDENTIAL' | 'IMPORTANT' | 'URGENT';
export default function FilterDocumentsTransmissionIncoming(props: FilterIssueFormProps) {
  const {
    companies,
    users,
    handleFilter,
    issueStatuses,
    isOwnerCompany,
    issuePriorities,
    userCompanyId,
    isReset,
    setReset,
    setIsOpenFilter,
    isOpenFilter,
    activeTab,
  } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [isFiltering] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [lastSubmitFilterData, setLastSubmitFilterData] = useState<FilterFormData>({
    assigneeId: undefined,
    companyId: undefined,
    groupIssues: isOwnerCompany ? 'all' : 'myCompany',
    issueStatusId: [],
    startDate: [],
    dtPriority: [],
  });
  const [, setOriginatorId] = useState('');
  const [filterUsers, setFilterUsers] = useState<User[]>([]);
  const setUpData = (filterData: FilterFormData) => {
    const groupIssue = filterData.groupIssues ?? 'all';
    form.setFieldsValue(filterData);
    form.setFieldValue('groupIssues', groupIssue);
    setDisableGroup(groupIssue === 'all' ? false : true);
  };
  useEffect(() => {
    const formattedParams: FilterFormData = {
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      dtPriority: [],
    };
    const params = parseQueryParams(window.location.search);
    if (params['startDate[gte]']) {
      formattedParams.startDate = [dayjs(params['startDate[gte]'].replace('+', ' '))];
    }
    if (params['startDate[lte]']) {
      formattedParams.startDate = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...formattedParams?.startDate,
        dayjs(params['startDate[lte]'].replace('+', ' ')),
      ];
    }
    if (params['companyId[]']) {
      const companyId = jsonToArray(params['companyId[]'])[0];
      if (companyId !== userCompanyId) formattedParams.companyId = companyId || undefined;
      else {
        if (params['assigneeId[]']) {
          formattedParams.companyId = companyId || undefined;
        } else {
          formattedParams.groupIssues = 'myCompany';
        }
      }
    }
    if (params['profileId']) {
      formattedParams.groupIssues = 'myIssue';
    }
    if (params['watcherId']) {
      formattedParams.groupIssues = 'watched';
    }
    if (params['assigneeId[]']) {
      formattedParams['assigneeId'] = jsonToArray(params['assigneeId[]'])[0] || undefined;
    }
    if (params['issueStatusId[]']) {
      formattedParams.issueStatusId = jsonToArray(params['issueStatusId[]']);
    }
    if (params['dtPriority[]']) {
      formattedParams.dtPriority = jsonToArray(params['dtPriority[]']);
    }

    setLastSubmitFilterData(formattedParams);
  }, []);

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };
  const onChangeAssignee = (originatorId: string) => {
    if (!originatorId) {
      form.setFieldValue('companyRep', null);
      return;
    }
    setOriginatorId(originatorId);
    const selectedUser = users?.find((user) => user.id === originatorId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    if (companies[existingCompany]?.id) {
      setSelectedCompanyId(companies[existingCompany]?.id);
    }
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setDisableGroup(e.target.value === 'all' ? false : true);
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };
  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    handleFilter(values);
    setIsOpenFilter(false);
  };
  const onResetFilter = () => {
    form.resetFields();
    setDisableGroup(false);
    setLastSubmitFilterData({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      dtPriority: [],
    });
    handleFilter({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      dtPriority: [],
      issueStatusId: [],
      issueTypeId: undefined,
      startDate: [],
    });
    setIsOpenFilter(false);
  };
  useEffect(() => {
    const filterUsers = users?.filter(
      (user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId
    );
    setFilterUsers(filterUsers);
  }, [selectedCompanyId, users]);
  useEffect(() => {
    if (isOpenFilter) {
      setUpData(lastSubmitFilterData);
    }
  }, [isOpenFilter, lastSubmitFilterData]);
  useEffect(() => {
    handleFilter({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      dtPriority: [],
    });
  }, [isOwnerCompany]);
  useEffect(() => {
    if (isReset) {
      form.resetFields();
      setDisableGroup(false);
      setLastSubmitFilterData({
        assigneeId: undefined,
        companyId: undefined,
        groupIssues: isOwnerCompany ? 'all' : 'myCompany',
        issueStatusId: [],
        startDate: [],
        dtPriority: [],
      });
      setIsOpenFilter(false);
      setReset(false);
    }
  }, [isReset]);
  useEffect(() => {
    setLastSubmitFilterData({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      dtPriority: [],
    });
  }, [activeTab]);

  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}
              className='text-uppercase'
            >
              {t('Filter & Sort')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                // console.log(lastSubmitFilterData);
                // form.setFieldsValue(lastSubmitFilterData);
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            // eslint-disable-next-line react/jsx-key
            <Flex justify='end' className='mx-5'>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='groupIssues' initialValue={'myCompany'}>
                  <Radio.Group defaultValue={'myCompany'} onChange={onChangeGroupIssues}>
                    <Space direction='vertical'>
                      <Radio disabled={!isOwnerCompany} style={{ lineHeight: '2.4' }} value='all'>
                        {t('All TRAs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myBaseIssue'>
                        {t('My TRAs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myCompany'>
                        {t('My company TRAs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='watched'>
                        {t('Watched TRAs')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeCompany}
                    options={companies?.map((option) => ({ value: option.id, label: option.name }))}
                  />
                </Form.Item>
                <Form.Item className='filter-form__item' name='assigneeId' label={t('Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeAssignee}
                    options={filterUsers?.map((option) => ({ value: option.id, label: option.fullName }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={24}>
                <Form.Item
                  className='filter-form__item'
                  name='startDate'
                  label={t('Material Approval Requests date range')}
                >
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            {activeTab === 'incoming' ? (
              <Row>
                <Col span={24}>
                  <Form.Item className='filter-form__item' name='dtPriority' label={t('Priority')}>
                    <Select
                      allowClear
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issuePriorities?.map((option) => {
                        const color = option.code as PriorityCode;
                        return {
                          value: option.id,
                          label: (
                            <span style={{ color: TRA_PRIORITIES_COLOR[color] || 'defaultColor' }}>
                              {t(option.name)}
                            </span>
                          ),
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={16} align='stretch' className='mb-5'>
                <Col span={12}>
                  <Form.Item className='filter-form__item' name='dtPriority' label={t('Priority')}>
                    <Select
                      allowClear
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issuePriorities?.map((option) => {
                        const color = option.code as PriorityCode;
                        return {
                          value: option.id,
                          label: (
                            <span style={{ color: TRA_PRIORITIES_COLOR[color] || 'defaultColor' }}>
                              {t(option.name)}
                            </span>
                          ),
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='filter-form__item' name='issueStatusId' label={t('Status')}>
                    <Select
                      allowClear
                      // showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issueStatuses?.map((option) => ({
                        value: option.id,
                        label: <Tag color={TRA_STATUS_COLOR[option.code]}>{t(option.name)}</Tag>,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={() => {
        setIsOpenFilter(!isOpenFilter);
      }}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : '', borderColor: isFiltering ? token.colorPrimary : '' }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
