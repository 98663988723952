import { Form } from 'antd';
import BaseIssueFormHeader from 'components/common/BaseIssueFormHeader';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import { BaseIssue, User } from 'model';
import { RFIFormValues } from './RequestForInformationForm';

type MSSsHeaderProps = {
  profile: User | null;
  selectedBaseIssue?: BaseIssue | null;
  onClose: () => void;
  user?: User | null;
};

export default function RequestForInformationHeader(props: MSSsHeaderProps) {
  const form = useANTDFormInstance<RFIFormValues>();

  return (
    <BaseIssueFormHeader
      name={Form.useWatch('subject') || form.getFieldValue('subject')}
      originatorId={props.selectedBaseIssue?.originatorId}
      user={props?.user}
      profile={props?.profile}
      issueStatus={props.selectedBaseIssue?.IssueStatus}
      onClose={props?.onClose}
      baseIssueNumber={props.selectedBaseIssue?.DocumentTransmission?.dtNumber}
      submittedDate={props.selectedBaseIssue?.BaseValidations?.[0]?.createdAt}
      disciplineId={Form.useWatch('disciplineId') || form.getFieldValue('disciplineId')}
    />
  );
}
