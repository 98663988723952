import CH from 'i18n/translation/CH.json';
import EN from 'i18n/translation/EN.json';
import ES from 'i18n/translation/ES.json';
import FR from 'i18n/translation/FR.json';
import ID from 'i18n/translation/ID.json';
import KM from 'i18n/translation/KM.json';
import PT from 'i18n/translation/PT.json';
import RU from 'i18n/translation/RU.json';
import TH from 'i18n/translation/TH.json';
import VN from 'i18n/translation/VN.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  cn: { translation: CH },
  en: { translation: EN },
  es: { translation: ES },
  fr: { translation: FR },
  id: { translation: ID },
  km: { translation: KM },
  pt: { translation: PT },
  ru: { translation: RU },
  th: { translation: TH },
  vn: { translation: VN },
};

i18next.use(initReactI18next).init({
  debug: false,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
