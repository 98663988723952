import { Col, Layout, Row, Tree, TreeDataNode, TreeProps, Typography } from 'antd';
import { motion } from 'framer-motion';
import { useProjectAttachments } from 'hooks';
import { useProjectZones, useProjectZonesParams } from 'hooks/zone';
import { Attachment, Zone } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { attachmentService } from 'services';
import { QueryParams } from 'types';
import { convertZonesToTreeData } from 'utils';
import { ATTACHMENT_TYPES } from 'utils/contants';
import PlanItem from './InspectionPlans/PlanItems';
const { Content } = Layout;
const { Paragraph } = Typography;

const motionItem = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

type InspectionsOnPlans = {
  workspaceId: string;
  projectId: string;
  module: string;
  onEditIssue: (value: string) => void;
  selectedZoneId?: string;
  isOwnerCompany: boolean;
  loggedCompanyId: string | null;
};

export default function InspectionsOnPlans(props: InspectionsOnPlans) {
  const { workspaceId, projectId, module, selectedZoneId, isOwnerCompany, loggedCompanyId } = props;
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [query] = useProjectZonesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'order,asc',
  });
  const [zones] = useProjectZones(query);
  const generalZone = zones?.find((zone) => !zone.parentId);
  const [zoneId, setZoneId] = useState<string>(selectedZoneId ? selectedZoneId : (generalZone?.id ?? ''));
  const [selectedZone, setSelectedZone] = useState<Zone | null>(zones?.find((zone) => zone.id === zoneId) ?? null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([generalZone?.id ?? '']);

  useEffect(() => {
    setSelectedZone(zones?.find((zone) => zone.id === zoneId) ?? null);
    setSelectedKeys([zoneId ?? '']);
  }, [zoneId]);

  const [attachmentQuery] = useState<QueryParams>({
    projectId: projectId as string,
    workspaceId,
    attachmentType: ATTACHMENT_TYPES.LOCATION_PLAN,
  });

  const navigate = useNavigate();
  const handleShowLocationPlan = (attachment: Attachment) => {
    navigate(`/projects/${projectId}/${module === 'Safety' ? 'safety' : 'quality'}/plan/${attachment.id}`);
  };

  const [attachments, , refreshAttachments] = useProjectAttachments(attachmentQuery);
  const [attachmentIssues, setAttachmentIssues] = useState<{ [key: string]: number }>({});

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    if (selectedKeys && selectedKeys.length > 0) {
      setZoneId(selectedKeys[0] as string);
    }
    setSelectedKeys(selectedKeys as string[]);
    console.log('selected', selectedKeys, info);
  };

  const onExpand: TreeProps['onExpand'] = (expandedKeys) => {
    setExpandedKeys(expandedKeys as string[]);
  };

  const treeData = useMemo(() => {
    if (zones) {
      const zonesTree = convertZonesToTreeData(zones);
      return zonesTree;
    }
  }, [zones]);

  useEffect(() => {
    const collectAllKeys = (nodes: TreeDataNode[]): string[] => {
      let keys: string[] = [];
      nodes.forEach((node) => {
        keys.push(node.key as string);
        if (node.children) {
          keys = [...keys, ...collectAllKeys(node.children)];
        }
      });
      return keys;
    };

    if (treeData) {
      const allKeys = collectAllKeys(treeData);
      setExpandedKeys(allKeys);
    }
  }, [treeData]);

  useEffect(() => {
    const selectedZone = zones?.find((zone) => zone.id === zoneId);
    if (zoneId !== '' && zoneId !== 'root' && selectedZone?.parentId) {
      refreshAttachments({
        projectId: projectId as string,
        workspaceId,
        attachmentType: ATTACHMENT_TYPES.LOCATION_PLAN,
        ...(zoneId ? { zoneId } : {}),
      });
    } else {
      refreshAttachments({
        projectId: projectId as string,
        workspaceId,
        attachmentType: ATTACHMENT_TYPES.LOCATION_PLAN,
      });
    }
  }, [selectedKeys, zoneId, selectedZone]);

  const fetchAttachmentIssuesCount = async () => {
    const attachmentIssues = await Promise.all(
      attachments.map(async ({ id: attachmentId }) => {
        try {
          const query = {
            module: module,
            ...(!isOwnerCompany && loggedCompanyId && { companyId: loggedCompanyId }),
          };
          const { count } = await attachmentService.getAttachmentIssues(workspaceId, projectId, attachmentId, query);
          return { attachmentId, issuesCount: count };
        } catch {
          return null;
        }
      })
    ).then((items) => items.filter((item) => item !== null));
    const newAttachmentIssues = attachmentIssues.reduce((acc, item) => {
      if (item && item.attachmentId && item.issuesCount) {
        return {
          ...acc,
          [item.attachmentId]: item.issuesCount,
        };
      } else {
        return acc;
      }
    }, {});
    setAttachmentIssues(newAttachmentIssues);
  };

  useEffect(() => {
    if (attachments) {
      fetchAttachmentIssuesCount();
    }
  }, [attachments]);

  return (
    <Content className='settings-content px-0 py-0'>
      <Row gutter={24}>
        <Col flex='23%' className='bg-gray px-4 py-4'>
          <div>
            <Paragraph className='px-4 mb-9'>
              {module && module === 'Safety'
                ? t('Select zone to view plans and create inspections')
                : t('Select zone to view plans and create observation')}
            </Paragraph>
            <Tree
              showLine
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={onExpand}
              onSelect={onSelect}
              treeData={treeData}
              className='bg-gray'
            />
          </div>
        </Col>
        <Col flex='77%' className=''>
          <div className='workspaces photos px-4'>
            {selectedZone && (
              <Typography.Title level={5}>
                {t(`${selectedZone?.name}`)} ({attachments?.filter((attachment) => attachment.zoneId)?.length})
              </Typography.Title>
            )}
            <div className='workspaces-list mt-9'>
              <Row gutter={16} style={{ width: '100%' }}>
                {attachments
                  ?.filter((attachment) => attachment.zoneId)
                  ?.map((attachment, index) => (
                    <Col xs={8} sm={8} className='full-width-col' key={attachment.id}>
                      <motion.div
                        key={attachment.id}
                        variants={motionItem}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        transition={{
                          duration: 0.5,
                          ease: 'easeInOut',
                          delay: index * 0.25,
                        }}
                        style={{ marginBottom: '15px' }}
                      >
                        <PlanItem
                          attachment={attachment}
                          issuesCount={attachmentIssues[attachment.id] ?? 0}
                          onClick={() => handleShowLocationPlan(attachment)}
                        />
                      </motion.div>
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
