import { useEffect, useState } from 'react';
import { Col, Form, Row, Button, Typography, Flex, FormInstance } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { BaseIssue, User } from 'model';
import TruncatedAttachmentDocument from 'components/common/TruncatedAttachmentDocument';
import { UploadDocument } from 'components/common';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import { ATTACHMENT_TYPES } from 'utils/contants';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
import { ModalState } from 'types';
import { WIRFileUpload } from 'components/project-work-inspection-request/CreateOrUpdateWIR/WorkInspectionRequestDetail';

const { Title, Text } = Typography;

const buttonStyles: Record<'YES' | 'NO' | 'N/A', { borderColor: string; color: string }> = {
  YES: { borderColor: '#237804', color: '#237804' },
  NO: { borderColor: '#CF1322', color: '#CF1322' },
  'N/A': { borderColor: '#D9D9D9', color: '#001D3D' },
};
const commonButtonStyles = {
  width: '79px',
  height: '32px',
  borderRadius: '16px',
  border: '1px solid',
  padding: '0px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
};
const commonCompliantButtonStyles = {
  width: '150px',
  height: '32px',
  borderRadius: '16px',
  border: '1px solid',
  padding: '0px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
};
interface CustomModalContentProps {
  onOverallCompliancyChange: (value: 'YES' | 'NO') => void;
  form: FormInstance;
  isOpen: boolean;
  isModalOpen: boolean;
  selectedMdiBaseIssue: BaseIssue | null;
  setAttachments: (value: WIRFileUpload[]) => void;
  setAttachmentPhotos: (value: WIRFileUpload[]) => void;
  attachments: WIRFileUpload[];
  attachmentPhotos: WIRFileUpload[];
  user: User | null;
  setSelected: (value: Record<string, string> | ((prev: Record<string, string>) => Record<string, string>)) => void;
  selected: Record<string, string>;
}
export default function CustomModalContent(props: CustomModalContentProps) {
  const {
    onOverallCompliancyChange,
    form,
    isOpen,
    setAttachments,
    setAttachmentPhotos,
    attachmentPhotos,
    attachments,
    setSelected,
    selected,
  } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const { t } = useTranslation();
  const plainOptions: Array<'YES' | 'NO' | 'N/A'> = ['YES', 'NO', 'N/A'];
  const overallOptions: Array<'NO' | 'YES'> = ['NO', 'YES'];

  const [attachmentTypeQuery] = useAttachmentTypesParams();
  useAttachmentTypes(attachmentTypeQuery);
  const validationAttachmentDocument = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.VAD));
  const ValidationPhotoAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.VAA));

  const [openAttachment, setOpenAttachment] = useState(false);
  const handleClick = (name: string, value: string) => {
    setSelected((prev: Record<string, string>) => ({ ...prev, [name]: value }));
    form.setFieldsValue({ [name]: value });
    if (name === 'overallCompliancy' && (value === 'YES' || value === 'NO')) {
      onOverallCompliancyChange(value as 'YES' | 'NO');
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setSelected({
        technicalDocumentCompliancy: '',
        quantityCompliancy: '',
        physicalConditionCompliancy: '',
        overallCompliancy: '',
      });
      form.resetFields();
      setAttachmentPhotos([]);
      setAttachments([]);
    }
  }, [isOpen]);
  const handleAddAttachment = (name: string, response: { fileUrl: string; comment?: string }) => {
    if (!response?.fileUrl) return;
    attachments.push({
      name: name,
      filePath: response?.fileUrl || '',
      attachmentTypeId: validationAttachmentDocument?.id || '',
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
      profilePhoto: props.user?.profilePhoto,
    });
    setAttachments([...attachments]);
  };
  const handleAddPicture = (data: UploadPhotoModalValue) => {
    const pictureData = {
      name: data.fileName,
      filePath: data.fileUrl,
      attachmentTypeId: ValidationPhotoAttachment?.id || '',
      profilePhoto: props.user?.profilePhoto,
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
      comment: data.comment,
    };
    setAttachmentPhotos([...attachmentPhotos, pictureData]);
    setIsOpenUpload(false);
  };

  const handleRemovePictures = (filePatch?: string) => {
    if (!filePatch) return;
    setAttachmentPhotos(attachmentPhotos.filter((attachment) => attachment.filePath !== filePatch));
  };
  const renderPicture = () => {
    const mappedPhotos = attachmentPhotos?.map((picture) => ({
      ...picture,
      picture: picture.profilePhoto,
      username: picture.fullName || '',
      firstName: picture.firstName,
    })) as AttachmentPhoto[];

    return (
      <div className='picture-box'>
        <Typography.Title className='mb-4' level={3}>
          {t('Pictures')}
        </Typography.Title>
        <UploadPhotoModal isModalOpen={!!isOpenUpload} setIsModalOpen={setIsOpenUpload} onSubmit={handleAddPicture} />

        <div className='picture-content'>
          <div className='picture-preview-box'>
            <AttachmentList attachmentPhotos={mappedPhotos} onDelete={handleRemovePictures}>
              <Button
                className={'text-size-12 h-[225px] w-[228px]'}
                onClick={() => setIsOpenUpload(true)}
                type='dashed'
                icon={<PlusOutlined />}
              >
                {t('Add Pictures')}
              </Button>
            </AttachmentList>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Title className={'validation-block'} level={5}>
        {t('Compliancy')}
      </Title>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form.Item name={'technicalDocumentCompliancy'}>
            <Row gutter={24}>
              <Col span={8}>
                <Text>{t('Technical Document')}</Text>
              </Col>
              <Col span={12}>
                <Button.Group style={{ gap: '12px' }}>
                  {plainOptions.map((option) => (
                    <Button
                      key={option}
                      className={`custom-button ${option === 'YES' ? 'yes-button' : option === 'NO' ? 'no-button' : 'na-button'}`}
                      style={{
                        ...commonButtonStyles,
                        ...buttonStyles[option],
                        backgroundColor:
                          selected.technicalDocumentCompliancy === option
                            ? buttonStyles[option].borderColor
                            : 'transparent',
                        color: selected.technicalDocumentCompliancy === option ? 'white' : buttonStyles[option].color,
                        boxShadow:
                          selected.technicalDocumentCompliancy === option ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
                      }}
                      onClick={() => handleClick('technicalDocumentCompliancy', option)}
                    >
                      {selected.technicalDocumentCompliancy === option && (
                        <CheckCircleFilled
                          style={{
                            backgroundColor: buttonStyles[option].borderColor,
                            color: 'white',
                          }}
                        />
                      )}
                      {option}
                    </Button>
                  ))}
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={'quantityCompliancy'}>
            <Row gutter={24}>
              <Col span={8}>
                <Text>{t('Quantity')}</Text>
              </Col>
              <Col span={12}>
                <Button.Group style={{ gap: '12px' }}>
                  {plainOptions.map((option) => (
                    <Button
                      key={option}
                      className={`custom-button ${option === 'YES' ? 'yes-button' : option === 'NO' ? 'no-button' : 'na-button'}`} // Add custom-button class and specific class for yes/no/na
                      style={{
                        ...commonButtonStyles,
                        ...buttonStyles[option],
                        backgroundColor:
                          selected.quantityCompliancy === option ? buttonStyles[option].borderColor : 'transparent',
                        color: selected.quantityCompliancy === option ? 'white' : buttonStyles[option].color,
                        boxShadow: selected.quantityCompliancy === option ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
                      }}
                      onClick={() => handleClick('quantityCompliancy', option)}
                    >
                      {selected.quantityCompliancy === option && (
                        <CheckCircleFilled
                          style={{
                            backgroundColor: buttonStyles[option].borderColor,
                            color: 'white',
                          }}
                        />
                      )}
                      {option}
                    </Button>
                  ))}
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={'physicalConditionCompliancy'}>
            <Row gutter={24}>
              <Col span={8}>
                <Text>{t('Physical Condition')}</Text>
              </Col>
              <Col span={12}>
                <Button.Group style={{ gap: '12px' }}>
                  {plainOptions.map((option) => (
                    <Button
                      key={option}
                      className={`custom-button ${option === 'YES' ? 'yes-button' : option === 'NO' ? 'no-button' : 'na-button'}`}
                      style={{
                        ...commonButtonStyles,
                        ...buttonStyles[option],
                        backgroundColor:
                          selected.physicalConditionCompliancy === option
                            ? buttonStyles[option].borderColor
                            : 'transparent',
                        color: selected.physicalConditionCompliancy === option ? 'white' : buttonStyles[option].color,
                        boxShadow:
                          selected.physicalConditionCompliancy === option ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
                      }}
                      onClick={() => handleClick('physicalConditionCompliancy', option)}
                    >
                      {selected.physicalConditionCompliancy === option && (
                        <CheckCircleFilled
                          style={{
                            backgroundColor: buttonStyles[option].borderColor,
                            color: 'white',
                          }}
                        />
                      )}
                      {option}
                    </Button>
                  ))}
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={'overallCompliancy'}>
            <Row gutter={[24, 24]}>
              <Col span={7}></Col>
              <Col span={17}>
                <Text className='overall-inspection'>{t('Compliant with Material Approval Request')}</Text>
              </Col>
              <Col span={7}></Col>
              <Col span={12}>
                <Button.Group style={{ gap: '12px' }}>
                  {overallOptions.map((option) => (
                    <Button
                      key={option}
                      className={`custom-button ${option === 'YES' ? 'yes-button' : 'no-button'}`}
                      style={{
                        ...commonCompliantButtonStyles,
                        ...buttonStyles[option],
                        backgroundColor:
                          selected.overallCompliancy === option ? buttonStyles[option].borderColor : 'transparent',
                        color: selected.overallCompliancy === option ? 'white' : buttonStyles[option].color,
                        boxShadow: selected.overallCompliancy === option ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
                      }}
                      onClick={() => handleClick('overallCompliancy', option)}
                    >
                      {selected.overallCompliancy === option && (
                        <CheckCircleFilled
                          style={{
                            backgroundColor: buttonStyles[option].borderColor,
                            color: 'white',
                          }}
                        />
                      )}
                      {option}
                    </Button>
                  ))}
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Title level={3} className='mb-4'>
            Documents
          </Typography.Title>
          <Flex gap={8} wrap className='mb-4'>
            {attachments.length > 0 &&
              attachments.map((attachmentDocument) => {
                return (
                  <TruncatedAttachmentDocument
                    key={attachmentDocument.filePath}
                    fileName={attachmentDocument.name}
                    filePath={attachmentDocument.filePath}
                    onRemoveFile={() => {
                      const newAttachments = attachments.filter(
                        (item) => item.filePath !== attachmentDocument.filePath
                      );
                      setAttachments([...newAttachments]);
                    }}
                  />
                );
              })}
            <Button
              onClick={() => setOpenAttachment(true)}
              type='dashed'
              icon={<PlusOutlined />}
              className='text-size-12'
            >
              {t('Add Document')}
            </Button>
            {openAttachment && (
              <UploadDocument
                isModalOpen={openAttachment}
                setIsModalOpen={(value: boolean) => setOpenAttachment(value)}
                handleAddDocument={handleAddAttachment}
              />
            )}
          </Flex>
        </Col>
        <Col span={24}>{renderPicture()}</Col>
        <Col span={24}>
          <Form.Item name={'comment'}>
            <TextArea
              className={'comment'}
              placeholder='Enter your comment here…'
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
