import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notificationService from 'services/notification.service';
import { NotificationModel } from "model"

import { resetAll, RootState } from 'store';
import { Loading, QueryParams } from 'types';

interface NotificationState {
  notificationsUnRead: NotificationModel[];
  notifications: NotificationModel[];
  notificationLoading: Loading;
  notificationCount: number;
}

const name = 'notification';
const initialState: NotificationState = {
  notificationLoading: 'idle',
  notifications: [],
  notificationsUnRead: [],
  notificationCount: 0,
};
export const fetchNotificationsUnRead = createAsyncThunk(
  `${name}/notificationsUnread`,
  async (query: QueryParams & {userId: string}, { rejectWithValue }) => {
  try {
    return await notificationService.getNotifications(query);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchNotifications = createAsyncThunk(
  `${name}/notifications`,
  async (query: QueryParams, { rejectWithValue }) => {
  try {
    return await notificationService.getNotifications(query);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const fetchNotificationsCount = createAsyncThunk(
  `${name}/notificationsCount`,
  async (query: QueryParams & {userId: string}, { rejectWithValue }) => {
  try {
    return await notificationService.getNotificationsCount(query);
  } catch (error) {
    return rejectWithValue(error);
  }
});


export const notificationSlice = createSlice({
  name,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.notificationLoading = 'pending';
      })
      .addCase(fetchNotifications.fulfilled,
        (state, action) => {
          state.notificationLoading = 'succeeded';
          state.notifications = action.payload.rows;
        })
      .addCase(fetchNotifications.rejected, (state) => {
        state.notificationLoading = 'idle';
      });
    builder
      .addCase(fetchNotificationsUnRead.pending, (state) => {
        state.notificationLoading = 'pending';
      })
      .addCase(fetchNotificationsUnRead.fulfilled,
        (state, action) => {
          state.notificationLoading = 'succeeded';
          state.notificationsUnRead = action.payload.rows;
        })
      .addCase(fetchNotificationsUnRead.rejected, (state) => {
        state.notificationLoading = 'idle';
      });
    builder
      .addCase(fetchNotificationsCount.pending, (state) => {
        state.notificationLoading = 'pending';
      })
      .addCase(fetchNotificationsCount.fulfilled,
        (state, action) => {
          state.notificationLoading = 'succeeded';
          state.notificationCount = action.payload.count;
        })
      .addCase(fetchNotificationsCount.rejected, (state) => {
        state.notificationLoading = 'idle';
      });
  },
});

export const selectNotificationUnRead = (state: RootState) => state.notification.notificationsUnRead;
export const selectNotifications = (state: RootState) => state.notification.notifications;
export const selectNotificationCount = (state: RootState) => state.notification.notificationCount;
export const selectNotificationLoading = (state: RootState) => state.notification.notificationLoading;

export default notificationSlice.reducer;
