import { ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';
import { Header } from 'components';

const { Content } = AntdLayout;

const Layout = ({ children, className, showHeader, sidebar }: { children: ReactNode, className?: string, showHeader: boolean, sidebar?: string }) => {


  return (
    <AntdLayout className={className}>
      {showHeader && <Header sidebar={sidebar}  />}
      <Content>{children}</Content>
    </AntdLayout>
  );
};
export default Layout;
