import { Button, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select, TreeSelect } from 'antd';
import {
  designFieldValues,
  EditDesignForm,
  EditDesignFormDayjs,
  RawDesignForm,
} from 'modules/DocumentManagement/models/Design';
import { Design, Zone } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDesignHeader from './UpdateDesignHeader';
import dayjs from 'dayjs';
import { RuleObject } from 'antd/es/form';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import { DataNode } from 'antd/es/tree';
import { convertZoneToTreeData } from 'utils';
import { findPath } from 'components/common/TreeSelectTagRender';

type UpdateDesignDrawerProps = {
  editingDesign: Design;
  setEditingDesign: (design: Design) => void;
  onClose: () => void;
  isOpen: boolean;
  workspaceId: string;
  projectId: string;
  refreshDesigns: () => void;
  fileRegex: RegExp;
  regexToString: string;
  updateItem: (
    workspaceId: string,
    projectId: string,
    designId: string,
    updatedDesign: EditDesignForm
  ) => Promise<Design>;
  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<{ id: string; hasDuplicate: boolean }[]>;
  disciplineMapIdToName: Map<string, string>;
  designRegisterPhases?: DesignRegisterPhase[];
  zones: Zone[];
};

const { Option } = Select;

export default function UpdateDesignDrawer(props: UpdateDesignDrawerProps) {
  const {
    updateItem,
    checkDuplicate,
    onClose,
    isOpen,
    editingDesign,
    projectId,
    workspaceId,
    refreshDesigns,
    fileRegex,
    regexToString,
    setEditingDesign,
    disciplineMapIdToName,
    designRegisterPhases,
    zones,
  } = props;
  const { t } = useTranslation();
  const [hasDuplicate, setHasDuplicate] = useState<boolean>(false);

  const [title, setTitle] = useState<string>(editingDesign.title);
  const [revision, setRevision] = useState(editingDesign.revision);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const formValues = Form.useWatch([], form);
  const [zoneUpdated, setZoneUpdated] = useState<string>();
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<EditDesignFormDayjs>();
  useEffect(() => {
    form
      .validateFields()
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));
  }, [form, formValues, hasDuplicate]);

  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
    }
  }, [zones]);

  const handleDynamicTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasDuplicate(false);
    setTitle(e.target.value);
  };
  const handleDynamicRevisionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasDuplicate(false);
    setRevision(e.target.value);
  };
  useEffect(() => {
    setIsDraft(editingDesign.status === 'draft');
    setTitle(editingDesign.title);
    // put only values used in the form
    const initialFromValues = {
      title: editingDesign.title,
      status: editingDesign.status,
      revision: editingDesign.revision,
      disciplineId: editingDesign.disciplineId,
      documentType: editingDesign.documentType,
      phase: editingDesign.phase,
      issueDate: editingDesign.issueDate ? dayjs(editingDesign.issueDate) : dayjs(editingDesign.createdAt),
      deadlineDate: editingDesign.deadlineDate ? dayjs(editingDesign.deadlineDate) : undefined,
      area: editingDesign.area,
    };
    form.setFieldsValue(initialFromValues);
    setInitialFormValues(initialFromValues);
    setHasDuplicate(false);
  }, [editingDesign]);

  const titleExtension = <div>{'.' + editingDesign.fileExtension}</div>;
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const titleChanged = formValues.title !== editingDesign.title;
      const revisionChanged = formValues.revision !== editingDesign.revision;
      if (titleChanged || revisionChanged) {
        const duplicate = await checkDuplicate(workspaceId, projectId, [
          {
            title: formValues.title.trim(),
            revision: formValues.revision.trim(),
            id: editingDesign.id,
          },
        ]);
        if (!duplicate[0].hasDuplicate || isDraft) {
          //No duplicates
          await updateItem(workspaceId, projectId, editingDesign.id, {
            ...formValues,
            title: formValues.title.trim(),
            revision: formValues.revision.trim(),
            issueDate: formValues.issueDate.$d.toISOString(),
            deadlineDate: formValues.deadlineDate?.$d?.toISOString(),
          });
          setIsLoading(false);
          onClose();
          refreshDesigns();
        } else {
          //There is a duplicate
          setHasDuplicate(true);
          setIsLoading(false);
        }
      } else {
        //no change so no need to check for duplicates
        await updateItem(workspaceId, projectId, editingDesign.id, {
          ...formValues,
          title: formValues.title.trim(),
          revision: formValues.revision.trim(),
          issueDate: formValues.issueDate.$d.toISOString(),
          deadlineDate: formValues.deadlineDate?.$d?.toISOString(),
        });
        setIsLoading(false);
        onClose();
        refreshDesigns();
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const duplicateTitleFieldRule = () => ({
    required: true,
    validator: () =>
      new Promise((resolve, reject) => {
        if (!hasDuplicate || isDraft) {
          resolve('');
        } else {
          reject(t('This title and revision already exists'));
        }
      }),
  });

  const regexFieldRule = () => ({
    required: true,
    validator: (rule: RuleObject, value: string) =>
      new Promise((resolve, reject) => {
        if (fileRegex.test(value)) {
          resolve('');
        } else {
          reject(`${t('File names cannot contain the following characters')}: ${regexToString}`);
        }
      }),
  });

  const onChangeZone = (area: string) => {
    setZoneUpdated(findPath(zonesTreeData, area));
  };
  return (
    <Drawer
      width={800}
      title={<UpdateDesignHeader editingDesign={editingDesign} editingTitle={title} editingRevision={revision} />}
      onClose={onClose}
      open={isOpen}
      closeIcon={false}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      footer={
        <Flex style={{ flexDirection: 'row-reverse' }}>
          <div>
            <Button
              onClick={() => {
                setEditingDesign({ ...editingDesign });
                form.resetFields();
                setHasDuplicate(false);
                onClose();
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              disabled={!isValid || JSON.stringify(initialFormValues) === JSON.stringify(formValues)}
              loading={isLoading}
              type='primary'
              htmlType='submit'
              onClick={form.submit}
            >
              {t('Save')}
            </Button>
          </div>
        </Flex>
      }
    >
      <Form form={form} name='edit-design' layout='vertical' onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='title'
              label={t('Title')}
              rules={[
                { required: true, message: t('Please fill out this field') },
                regexFieldRule,
                duplicateTitleFieldRule,
              ]}
            >
              <Input onChange={handleDynamicTitleChange} placeholder={t('Enter Title')} addonAfter={titleExtension} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='status'
              label={t('Published For')}
              rules={[{ required: true, message: t('Please fill out this field') }]}
            >
              <Select placeholder={t('Enter Published For')}>
                <Option value='information'>{t('For Information')}</Option>
                <Option value='approval'>{t('For Approval')}</Option>
                <Option value='review'>{t('For Review')}</Option>
                <Option value='construction'>{t('For Construction')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='revision' label={t('Revision')} rules={[duplicateTitleFieldRule]}>
              <Input onChange={handleDynamicRevisionChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='disciplineId'
              label={t('Discipline')}
              rules={[{ required: true, message: t('Please fill out this field') }]}
            >
              <Select
                placeholder={t(`
                ${disciplineMapIdToName.get(editingDesign.disciplineId as string)}`)}
              >
                {Array.from(disciplineMapIdToName.entries()).map(([id, value]) => {
                  return <Option key={id}>{t(value)}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='documentType'
              label={t('Document Type')}
              rules={[{ required: true, message: t('Please fill out this field') }]}
            >
              <Select>
                {Object.keys(designFieldValues.documentTypeValues).map((element) => {
                  return <Option key={element}>{t(designFieldValues.documentTypeValues[element])}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='phase'
              label={t('Phase')}
              rules={[{ required: true, message: t('Please fill out this field') }]}
            >
              {designRegisterPhases && (
                <Select>
                  {designRegisterPhases.map((element) => {
                    return <Option key={element.id}>{t(element.name)}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='issueDate'
              label={t('Issue Date')}
              rules={[{ type: 'object' as const, required: true, message: t('Please fill out this field') }]}
            >
              <DatePicker format={'DD/MM/YYYY'} style={{ minWidth: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='deadlineDate'
              label={t('Expected Deadline')}
              rules={[{ type: 'object' as const, required: false }]}
            >
              <DatePicker format={'DD/MM/YYYY'} style={{ minWidth: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name='area' label={t('Area')} rules={[{ required: false, message: t('') }]}>
              <TreeSelect
                value={zoneUpdated}
                treeData={zonesTreeData}
                placeholder={t('Select Zoning')}
                treeDefaultExpandAll
                onChange={onChangeZone}
                popupMatchSelectWidth={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
