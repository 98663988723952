import {
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Flex, MenuProps, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Design } from 'model';
import { UnpublishedDesignForm } from 'modules/DocumentManagement/models/Design';
import { MenuInfo } from 'rc-menu/lib/interface';
import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';

type RevisionsListViewProps = {
  setLocalCurrentRevision: (newDesign: Design) => void;
  newUploadedRevision: UnpublishedDesignForm | null;

  projectId: string;
  workspaceId: string;
  singleDownload: (designId?: string, filename?: string) => Promise<void>;
  revisionList: Design[];
  loading: boolean;
  setDeleteList: Dispatch<SetStateAction<string[]>>;
  deleteList: string[];
  openPDFNewTab: (design: Design) => void;
};

export default function ManageRevisionsInactiveListView(props: RevisionsListViewProps) {
  const {
    loading,
    setLocalCurrentRevision,
    openPDFNewTab,
    projectId,
    singleDownload,
    revisionList,
    setDeleteList,
    deleteList,
  } = props;
  const [, setClickedId] = useState<string>('');
  const deleteStyle: CSSProperties = { textDecoration: 'line-through', color: 'gray' };

  const items: MenuProps['items'] = [
    {
      label: t('Set as current revision'),
      key: 'setCurrent',
      icon: <DoubleLeftOutlined className='rotate-90' style={{ paddingLeft: 2, paddingTop: 2 }} />,
      danger: false,
    },
    {
      label: t('Download'),
      icon: <DownloadOutlined />,
      key: 'download',
      danger: false,
    },
    {
      label: t('Delete'),
      icon: <DeleteOutlined />,
      key: 'delete',
      danger: true,
    },
  ];

  const handleMenuClick = async (
    info: MenuInfo,
    designTitle: string,
    designId: string,
    projectId: string,
    record: Design
  ) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'setCurrent':
          setLocalCurrentRevision(record);
          break;
        case 'download':
          singleDownload(designId, designTitle);
          break;
        case 'delete':
          setDeleteList((currentList) => [...currentList, designId]);
          break;
      }
    }
  };

  const nestedColumns: ColumnsType<Design> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      render: (text, record) => {
        return (
          <Flex justify='space-between'>
            <Flex style={deleteList.includes(record.id) ? { ...deleteStyle, marginRight: 12 } : {}}>
              <FilePdfOutlined style={record?.status === 'draft' ? { color: 'gray' } : { color: 'red' }} />
              <button
                className='invisibleButtonLink'
                onClick={() => {
                  openPDFNewTab(record);
                }}
              >
                <p style={{ marginLeft: 12 }}>{text}</p>
              </button>
            </Flex>
            {record?.initialRevisionId ? (
              <></>
            ) : (
              <Tooltip title={t('This revision will be set as inactive')}>
                <DoubleRightOutlined
                  className='rotate-90'
                  style={deleteList.includes(record.id) ? deleteStyle : { color: 'red' }}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: t('Revision'),
      dataIndex: 'revision',

      render: (text, record) => {
        return (
          <Flex justify='center'>
            <Tag
              color={deleteList.includes(record.id) ? 'default' : 'blue'}
              style={deleteList.includes(record.id) ? deleteStyle : { fontWeight: 'lighter', borderRadius: '20px' }}
              className='normal-case'
            >
              {text}
            </Tag>
          </Flex>
        );
      },
    },
    {
      title: t('Issue Date'),
      dataIndex: 'issueDate',
      render: (value, record) => (
        <p style={deleteList.includes(record.id) ? deleteStyle : {}}>
          {dayjs(dayjs(value).isValid() ? value : record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      title: t('Expected Deadline'),
      dataIndex: 'deadlineDate',
      render: (value, record) => (
        <p style={deleteList.includes(record.id) ? deleteStyle : {}}>
          {dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : null}
        </p>
      ),
    },
    {
      align: 'right',
      dataIndex: 'id',
      fixed: 'right',
      width: '3.5em',
      render: (value, record: Design) => (
        <Flex justify={'flex-end'}>
          <Dropdown
            menu={{
              items,
              onClick: (info) => {
                handleMenuClick(info, `${record.title}.${record.fileExtension}`, value, projectId, record);
              },
            }}
            trigger={['click']}
          >
            <Button
              onClick={() => {
                setClickedId(value);
              }}
              icon={<EllipsisOutlined />}
              type='link'
              className='icon-ellipse'
              style={{ color: 'black' }}
            ></Button>
          </Dropdown>
        </Flex>
      ),
    },
  ];
  return (
    <Table
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined />,
      }}
      pagination={false}
      columns={nestedColumns}
      dataSource={revisionList.sort((a, b) => {
        return (a.issueDate ?? a.createdAt) < (b.issueDate ?? b.createdAt) ? 1 : -1;
      })}
    ></Table>
  );
}
