import { TreeSelect, TreeSelectProps } from 'antd';
import { DataNode } from 'antd/es/tree';
import { findPath } from 'components/common/TreeSelectTagRender';
import { Zone } from 'model';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { convertZoneToTreeData } from 'utils';

export type SelectZoningProps = Omit<TreeSelectProps<string, DataNode>, 'treeData'> & {
  zones?: Zone[];
  id?: string;
};
const SelectZoning = (props: SelectZoningProps) => {
  const { t } = useTranslation();
  const zonesTreeData = useMemo(() => convertZoneToTreeData(props?.zones), [props?.zones]);

  return (
    <TreeSelect
      id={props.id}
      value={props.value ? findPath(zonesTreeData, props.value || '') : undefined}
      onChange={props.onChange}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={zonesTreeData}
      placeholder={t('Select Zoning')}
      treeDefaultExpandAll
      placement='bottomLeft'
      popupMatchSelectWidth={false}
      popupClassName='wir-select-area'
    />
  );
};

export default SelectZoning;
