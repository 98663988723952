import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
  theme,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePickerRange, SearchSelect } from 'components';
import { Company, IssueStatus, User } from 'model';
import { useEffect, useState } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { RFI_STATUS_COLOR } from 'utils/contants';
import dayjs, { Dayjs } from 'dayjs';
import { jsonToArray, parseQueryParams } from 'utils';
import { selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { useAppSelector } from 'store';
import { EWorkBreakDownStructureLevelID } from 'types';
import { mapDataToOptions } from 'utils/object';

type FilterFormData = {
  assigneeId?: string;
  companyId?: string;
  groupIssues?: string;
  disciplineId?: string[];
  issueStatusId?: string[];
  startDate: string[] | string | Dayjs[] | undefined;
  zoneId?: string[] | string;
};

type FilterBaseIssueFormProps = {
  companies: Company[];
  users: User[];
  issueStatuses: IssueStatus[];
  userCompanyId: undefined | string;
  workspaceId: string;
  projectId: string;
  isOpenFilter: boolean;
  setIsOpenFilter: (value: boolean) => void;
  handleFilter: (value: { [key: string]: string | number | string[] }) => void;
  isReset: boolean;
  setReset: (value: boolean) => void;
  module: string;
  isOwnerCompany: boolean;
};

export default function FilterBaseIssueForm(props: FilterBaseIssueFormProps) {
  const {
    companies,
    users,
    issueStatuses,
    handleFilter,
    isReset,
    setReset,
    isOpenFilter,
    setIsOpenFilter,
    isOwnerCompany,
  } = props;

  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formValuesWatch = Form.useWatch([], form);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [filterUsers, setFilterUsers] = useState<User[]>([]);
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));

  const [lastSubmitFilterData, setLastSubmitFilterData] = useState<FilterFormData>({
    assigneeId: undefined,
    companyId: undefined,
    groupIssues: 'all',
    issueStatusId: [],
    disciplineId: [],
    startDate: [],
    zoneId: [],
  });

  useEffect(() => {
    const filterUsers = users?.filter(
      (user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId
    );
    setFilterUsers(filterUsers);
  }, [selectedCompanyId, users]);

  const setUpData = (filterData: FilterFormData) => {
    const groupIssue = filterData.groupIssues ?? 'all';
    form.setFieldsValue(filterData);
    form.setFieldValue('groupIssues', groupIssue);
    setDisableGroup(groupIssue === 'all' ? false : true);
  };

  useEffect(() => {
    const params = parseQueryParams(window.location.search);
    let groupIssues = 'all';
    let assigneeId = params['originatorId'] || undefined;
    let companyId = params['companyOriginatorId'] || undefined;

    if (params['filterType']) {
      groupIssues = params['filterType'];
      if (params['filterType'] === 'myBaseIssue') {
        assigneeId = undefined;
      } else if (params['filterType'] === 'myCompany') {
        companyId = undefined;
      }
    }

    const formattedParams: FilterFormData = {
      assigneeId,
      companyId,
      groupIssues: groupIssues,
      issueStatusId: params['statusId[]'] ? jsonToArray(params['statusId[]']) : [],
      disciplineId: params['disciplineIds[]'] ? jsonToArray(params['disciplineIds[]']) : [],
      startDate: [params['createdAt[gte]'], params['createdAt[lte]']]
        .filter(Boolean)
        .map((date) => dayjs(date.replace('+', ' '))),
    };
    setLastSubmitFilterData(formattedParams);
  }, [isOwnerCompany]);

  useEffect(() => {
    form.resetFields();
    if (isOpenFilter) {
      setUpData(lastSubmitFilterData);
    }
  }, [isOpenFilter, lastSubmitFilterData]);

  useEffect(() => {
    if (isReset) {
      form.resetFields();
      setDisableGroup(false);
      setLastSubmitFilterData({
        assigneeId: undefined,
        companyId: undefined,
        groupIssues: 'all',
        issueStatusId: [],
        disciplineId: [],
        startDate: [],
        zoneId: [],
      });
      setIsOpenFilter(false);
      setReset(false);
    }
  }, [isReset]);

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };
  const onChangeAssignee = (originatorId: string) => {
    if (!originatorId) {
      form.setFieldValue('companyRep', null);
      return;
    }
    const selectedUser = users?.find((user) => user.id === originatorId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    if (companies[existingCompany]?.id) {
      setSelectedCompanyId(companies[existingCompany]?.id);
    }
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };

  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setDisableGroup(e.target.value === 'all' ? false : true);
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };

  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    handleFilter(values);
    setIsOpenFilter(false);
  };
  const onResetFilter = () => {
    form.resetFields();
    setDisableGroup(false);
    setLastSubmitFilterData({
      assigneeId: '',
      companyId: '',
      groupIssues: 'all',
      issueStatusId: [],
      disciplineId: [],
      startDate: '',
    });
    handleFilter({
      assigneeId: '',
      companyId: '',
      groupIssues: 'all',
      issueDisciplineId: '',
      issuePriorityId: '',
      issueStatusId: [],
      disciplineId: [],
      issueTypeId: '',
      startDate: '',
    });
    setIsOpenFilter(false);
  };

  useEffect(() => {
    const isObjectUndefined =
      formValuesWatch &&
      Object.keys(formValuesWatch)
        .filter((key) => key !== 'groupIssues')
        .every((key) => formValuesWatch[key] === undefined || formValuesWatch[key] === null);
    if (
      (isObjectUndefined &&
        (formValuesWatch['groupIssues'] === 'all' || formValuesWatch['groupIssues'] === undefined)) ||
      !formValuesWatch
    ) {
      setIsFiltering(false);
    } else {
      setIsFiltering(true);
    }
  }, [formValuesWatch]);

  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}
              className='text-uppercase'
            >
              {t('Filter')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            <Flex justify='end' className='mx-5' key={'filter-button'}>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='groupIssues' initialValue={'myCompany'}>
                  <Radio.Group defaultValue={'all'} onChange={onChangeGroupIssues}>
                    <Space direction='vertical'>
                      <Radio style={{ lineHeight: '2.4' }} value='all'>
                        {t('All RFIs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myBaseIssue'>
                        {t('My RFIs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myCompany'>
                        {t('My company RFIs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='watched'>
                        {t('Distribution RFIs')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeCompany}
                    options={companies?.map((option) => ({ value: option.id, label: option.name }))}
                  />
                </Form.Item>
                <Form.Item className='filter-form__item' name='assigneeId' label={t('Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeAssignee}
                    options={filterUsers?.map((option) => ({ value: option.id, label: option.fullName }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={24}>
                <Form.Item
                  className='filter-form__item'
                  name='startDate'
                  label={t('Requests for information date range')}
                >
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='disciplineId' label={t('Discipline')}>
                  <Select
                    mode={'multiple'}
                    maxTagCount='responsive'
                    allowClear
                    placeholder={t('Select')}
                    options={mapDataToOptions(disciplines)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {' '}
                <Form.Item className='filter-form__item' name='issueStatusId' label={t('Status')}>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={issueStatuses?.map((option) => ({
                      value: option.id,
                      label: (
                        <Tag className={option.code} color={RFI_STATUS_COLOR[option.code]}>
                          {t(option.name)}
                        </Tag>
                      ),
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={() => {
        setIsOpenFilter(!isOpenFilter);
      }}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : '', borderColor: isFiltering ? token.colorPrimary : '' }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
