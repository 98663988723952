import { useMemo, useState } from 'react';
import { Form, FormInstance, Input, Select, SelectProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { User } from 'model';
import { useTranslation } from 'react-i18next';

const Option = Select.Option;

const NEW_ITEM = 'CREATE_NEW_ITEM';

const InviteUserSelect = (
  props: SelectProps & {
    form: FormInstance;
    formRestField: {
      fieldKey?: number | undefined;
    };
    formFieldName: (string | number)[] | NamePath | undefined;
    users?: User[];
    excludingOptions: string[];
    selectedUser: User | null;
    selectedIds: string[];
    onSelectUser: (value: string) => void;
  }
) => {
  const { form, formRestField, formFieldName, users, excludingOptions, selectedIds, selectedUser, onSelectUser } =
    props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isCreate, setIsCreate] = useState(false);
  const parentFieldName = 'users';
  const userIdField = [formFieldName, 'userId'];
  const emailField = [formFieldName, 'email'];
  const companyField = [formFieldName, 'companyId'];
  const options = useMemo(() => {
    return users
      ?.filter(
        (user) =>
          ![...excludingOptions].includes(user.id) || user.id === form.getFieldValue([parentFieldName, ...userIdField])
      )
      .map((user) => ({ value: user.id, label: user.username, ...user }));
  }, [selectedIds, excludingOptions, users, form, parentFieldName, userIdField]);

  const handleChange = (value: string) => {
    if (value === NEW_ITEM) {
      setIsCreate(true);
      form.setFieldValue([parentFieldName, ...userIdField], null);
      form.setFieldValue([parentFieldName, ...emailField], inputValue);
      form.validateFields([parentFieldName, ...emailField]);
    } else {
      form.setFieldValue([parentFieldName, ...emailField], null);
      form.setFieldValue([parentFieldName, ...userIdField], value);
      if (onSelectUser) {
        onSelectUser(value);
      }
      const currentSelectedUser = users?.find((user) => user.id === value);
      form.setFieldValue([parentFieldName, ...companyField], currentSelectedUser?.Company?.id);
    }
    setInputValue('');
  };
  console.log('options', options);

  return isCreate ? (
    <Form.Item {...formRestField} name={emailField} rules={[{ type: 'email', required: true, message: '' }]}>
      <Input placeholder={t('')} />
    </Form.Item>
  ) : (
    <Form.Item
      {...formRestField}
      name={userIdField}
      rules={[{ required: true, message: '' }]}
      initialValue={selectedUser ? selectedUser.id : null}
    >
      <Select
        showSearch
        optionFilterProp='children'
        placeholder={t('User name')}
        disabled={selectedUser ? true : false}
        onChange={handleChange}
        filterOption={false}
        onSearch={(e) => {
          setInputValue(e);
        }}
        optionLabelProp='label'
        value={selectedUser ? selectedUser.id : undefined}
      >
        {options
          ?.filter(
            (o) =>
              !inputValue ||
              o?.email?.toString().toLowerCase().includes(inputValue) ||
              o?.fullName?.toString().toLowerCase().includes(inputValue)
          )
          .map((o) => (
            <Option key={o.id} value={o.id} label={o.username}>
              <div className='custom-select-input-user'>
                <div className='text-circle'>{o.username?.charAt(0).toUpperCase()}</div>
                <div className='ml-2'>
                  <div className='ttl'>{o.username}</div>
                  <div className='desc'>{o.Company?.name}</div>
                </div>
              </div>
            </Option>
          ))}
        {inputValue &&
          options?.filter((o) => {
            return (
              o?.email?.toString()?.toLowerCase()?.includes(inputValue) ||
              o?.fullName?.toString()?.toLowerCase()?.includes(inputValue)
            );
          }).length === 0 && (
            <Option key={inputValue} value={NEW_ITEM} className='invite-option'>
              {`+ ${t('Invite')} ${inputValue}`}
            </Option>
          )}
      </Select>
    </Form.Item>
  );
};

export default InviteUserSelect;
