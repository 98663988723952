import { useMemo } from 'react';
import { QueryParams } from 'types';
import { DateFormat } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchDateFormats, selectDateFormats } from 'store/common.slice';

export const useDateFormatsParams = (options?: any) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, []);
};

export const useDateFormats = (query: any) => {
  return useFetchByParams<DateFormat[]>({
    action: fetchDateFormats,
    dataSelector: selectDateFormats,
    params: query,
  });
};
