import picture from '../assets/images/pictures/_Image with Fixed Ratio_.png';
import picture1 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 2.png';
import picture2 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 3.png';
import picture3 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 4.png';
import picture4 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 5.png';
import picture5 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 6.png';
import picture6 from '../assets/images/pictures/_Image with Fixed Ratio_ copy.png';
import logo from '../assets/images/pictures/logo.png';
import { User } from '../model';
import { ToolOutlined, SketchOutlined, BankFilled } from '@ant-design/icons';

const userData = [
  {
    id: '1',
    firstName: 'Nikolai',
    lastName: 'Tesla',
    email: 'nikolay.tesla@lightningcorp.com',
    company: {
      id: '1',
      name: 'Skyscraper ltd',
      code: 'MYW',
      picture: picture5,
      icon: ToolOutlined,
      iconColor: '#237804',
    },
    role: {
      id: '1',
      name: 'Architect',
      code: 'fe',
    },
    status: {
      id: '1',
      name: 'active',
      code: 'active',
    },
    number_of_projects: 5,
    picture: picture,
    icon: SketchOutlined,
    iconColor: '#A8071A',
  },
  {
    id: '2',
    firstName: 'Nikolai',
    lastName: 'Tesla',
    email: 'john.doe@lightningcorp.com',
    company: {
      id: '1',
      name: 'Leak&co',
      code: 'MYW',
      picture: picture1,
    },
    role: {
      id: '1',
      name: 'Project manager',
      code: 'fe',
    },
    status: {
      id: '2',
      name: 'Inactive',
      code: 'inactive',
    },
    number_of_projects: 2,
    picture: picture2,
    icon: BankFilled,
    iconColor: '#D48806',
  },
  {
    id: '3',
    firstName: 'Nikolai',
    lastName: 'Tesla',
    email: 'emma@lightningcorp.com',
    company: {
      id: '1',
      name: 'Lightning corp',
      code: 'MYW',
      picture: picture2,
    },
    role: {
      id: '1',
      name: 'Plumbing expert',
      code: 'fe',
    },
    status: {
      id: '3',
      name: 'Pending',
      code: 'pending',
    },
    number_of_projects: 8,
    picture: picture4,
    icon: ToolOutlined,
    iconColor: '#737373',
  },
];

export default userData;
