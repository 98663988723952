import { useProjectUsersParams, useProjectUsers } from 'hooks/user';
import { User } from 'model';
import useAuthContext from 'store/auth-context';

type UseUserEntityProps = {
  projectId?: string;
  workspaceId?: string;
  users?: User[];
};

const useUserEntity = (props: UseUserEntityProps) => {
  const { profile } = useAuthContext();

  const [queryUser] = useProjectUsersParams({ projectId: props.projectId, workspaceId: props.workspaceId });
  const [users] = useProjectUsers(queryUser);

  const currentUser = (users || []).find((u) => u.id === profile?.id) || null;

  return {
    user: currentUser,
    profile,
  };
};

export default useUserEntity;
