import dayjs from 'dayjs';

export type ProjectMinutesOfMeetingFormValues = {
  subject?: string;
  meetingDate?: dayjs.Dayjs;
  meetingLocation?: string;
  category?: number;
  nextMeetingDate?: dayjs.Dayjs;
  nextMeetingLocation?: string;
  attendeeCompanyRecipient: number[];
  attendeeRecipientName: number[];
  otherCompanyRecipient: number[];
  otherRecipientName: number[];
};

export type ProjectMinutesOfMeetingAddItemModalFormValues = {
  titleOfItem: string;
  responsibleCompany: number;
  responsibleName: number;
  dueDate: dayjs.Dayjs;
  category: number | string;
  description: string;
};

export enum EProjectMinutesOfMeetingFieldNames {
  Subject = 'subject',
  MeetingDate = 'meetingDate',
  MeetingLocation = 'meetingLocation',
  Category = 'category',
  NextMeetingDate = 'nextMeetingDate',
  NextMeetingLocation = 'nextMeetingLocation',
  AttendeeCompanyRecipient = 'attendeeCompanyRecipient',
  AttendeeRecipientName = 'attendeeRecipientName',
  OtherCompanyRecipient = 'otherCompanyRecipient',
  OtherRecipientName = 'otherRecipientName',
  CategoriesItems = 'categoriesItems',
}

export enum EProjectMinutesOfMeetingAddItemModalFieldNames {
  TitleOfItem = 'titleOfItem',
  ResponsibleCompany = 'responsibleCompany',
  ResponsibleName = 'responsibleName',
  DueDate = 'dueDate',
  Category = 'category',
  Description = 'description',
}
