import { Avatar, Button, Card, ModalProps } from 'antd';
import Meta from 'antd/es/card/Meta';
import { LocationPlan, Modal } from 'components';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import dayjs from 'dayjs';
import { Attachment, IssueAttachment, User } from 'model';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayerData } from 'types';

type AttachmentDrawingProps = {
  isModalOpen: boolean | number;
  onSubmit: (layerData: LayerData | null) => void;
  setIsModalOpen: (value: boolean) => void;
  loading?: boolean;
  inspectionName?: string;
  disabled?: boolean;
  plan: IssueAttachment | null;
  planImage: Attachment | null;
  isLoading?: boolean;
  module?: string;
  users: User[];
} & ModalProps;

export default function AttachmentDrawing(props: AttachmentDrawingProps) {
  const { isModalOpen, setIsModalOpen, plan, onSubmit, planImage, disabled = false, isLoading, module, users } = props;
  const { t } = useTranslation();
  const [layerData, setLayerData] = useState<LayerData | null>(null);
  const [selectedPlanImage, setSelectedPlanImage] = useState<Attachment | null>(planImage);

  useEffect(() => {
    setTimeout(() => {
      setSelectedPlanImage(planImage);
    }, 100);
  }, [planImage]);

  const initLayerData = useCallback(() => {
    if (plan?.layerData) {
      setLayerData(plan.layerData);
    } else {
      setLayerData(null);
    }
  }, [plan?.layerData]);

  useEffect(() => {
    initLayerData();
  }, [initLayerData, plan]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const locationWidth = 800;
  const aspectRatio =
    selectedPlanImage?.height && selectedPlanImage.width ? selectedPlanImage.height / selectedPlanImage.width : 3 / 4;
  const locationHeight = locationWidth * aspectRatio;

  const userCreated = users.find((user) => user.id === selectedPlanImage?.IssueAttachments?.[0].userCreated);
  return (
    <Modal
      title={''}
      open={!!isModalOpen}
      onCancel={handleCancel}
      width={locationWidth + 96}
      classNames={{
        body: 'pin-inspection-plan-modal',
      }}
      style={{ top: 20 }}
      footer={[
        <Button onClick={handleCancel} key='back'>
          {disabled ? t('Close') : t('Cancel')}
        </Button>,
        ...(!disabled
          ? [
              <Button onClick={() => onSubmit(layerData)} key='submit' type='primary' loading={isLoading}>
                {t('Submit')}
              </Button>,
            ]
          : []),
      ]}
    >
      {selectedPlanImage && userCreated && (
        <Card className='mb-6 attachment-drawing' bordered={false}>
          <Meta
            avatar={
              userCreated?.profilePhoto ? (
                <AvatarItem size={50} src={userCreated?.profilePhoto} />
              ) : (
                <Avatar size={50}>{userCreated?.firstName?.substring(0, 1)}</Avatar>
              )
            }
            title={userCreated?.fullName}
            description={`${dayjs(selectedPlanImage.IssueAttachments?.[0].createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
          />
          <div className='pt-3'>{selectedPlanImage.IssueAttachments?.[0].comment}</div>
        </Card>
      )}
      {selectedPlanImage && (
        <LocationPlan
          width={locationWidth}
          height={locationHeight}
          data={layerData}
          disabled={disabled}
          onChange={setLayerData}
          imageHeight={selectedPlanImage?.height as number}
          imageWidth={selectedPlanImage?.width as number}
          imageUrl={selectedPlanImage?.filePath as string}
          module={module ?? ''}
        />
      )}
    </Modal>
  );
}
