import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Tooltip, Typography, Upload, UploadFile } from 'antd';

import { t } from 'i18next';
import { Design } from 'model';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import ManageRevisionsHeader from './ManageRevisionsHeader';
import ManageRevisionsCurrentListView from './ManageRevisionsCurrentListView';
import ManageRevisionsInactiveListView from './ManageRevisionsInactiveListView';
import { EditDesignForm, RawDesignForm, UnpublishedDesignForm } from 'modules/DocumentManagement/models/Design';
import { useForm, useWatch } from 'antd/es/form/Form';
import { QueryParams } from 'types';

type ManageRevisionDrawerProps = {
  onClose: () => void;
  drawerManagingRevision: { open: boolean; design?: Design; fromListView?: boolean };
  setDrawerManagingRevision: Dispatch<SetStateAction<{ open: boolean; design?: Design; fromListView?: boolean }>>;
  projectId: string;
  workspaceId: string;
  singleDownload: (designId?: string, filename?: string) => Promise<void>;
  handleSingleUpload: (
    uploadFile: UploadFile,
    oldId: string,
    uploadDesign: UnpublishedDesignForm,
    draft: boolean
  ) => Promise<{ id: string }>;
  updateItem: (
    workspaceId: string,
    projectId: string,
    designId: string,
    updatedDesign: EditDesignForm
  ) => Promise<Design>;
  submitLoading: boolean;
  setSubmitLoading: Dispatch<SetStateAction<boolean>>;
  refreshAll: (options?: QueryParams) => void;
  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<{ id: string; hasDuplicate: boolean }[]>;
  getRevisions: (workspaceId: string, projectId: string, shopDrawingId: string) => Promise<Design[]>;
  updateCurrentRevision: (
    workspaceId: string,
    projectId: string,
    oldDesignId: string,
    newDesignid: string
  ) => Promise<{
    id: string;
  }>;
  deleteRevisions: (workspaceId: string, projectId: string, designIds: Key[]) => Promise<Design>;
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  newUploadedRevision: UnpublishedDesignForm | null;
  setNewUploadedRevision: Dispatch<SetStateAction<UnpublishedDesignForm | null>>;
  revisionList: Design[];
  setRevisionList: Dispatch<SetStateAction<Design[]>>;
  newCurrentRevision: Design | null;
  setNewCurrentRevision: Dispatch<SetStateAction<Design | null>>;
  openPDFNewTab: (record: Design) => void;
  revisionDraft: Design | null;
  setRevisionDraft: Dispatch<SetStateAction<Design | null>>;
  setCurrentRevision: Dispatch<SetStateAction<Design | null>>;
  currentRevision: Design | null;
  getDesignById: (workspaceId: string, projectId: string, designId: string) => Promise<Design>;
  fileRegex: RegExp;
  fileRegexToString: string;
  beforeUpload: (file: UploadFile) => Promise<boolean>;
};

export default function ManageRevisionDrawer(props: ManageRevisionDrawerProps) {
  const {
    beforeUpload,
    setCurrentRevision,
    currentRevision,
    revisionDraft,
    setRevisionDraft,
    openPDFNewTab,
    revisionList,
    setRevisionList,
    newUploadedRevision,
    setNewUploadedRevision,
    fileList,
    setFileList,
    submitLoading,
    setSubmitLoading,
    onClose,
    handleSingleUpload,
    drawerManagingRevision,
    setDrawerManagingRevision,
    projectId,
    workspaceId,
    singleDownload,
    refreshAll,
    getRevisions,
    updateCurrentRevision,
    deleteRevisions,
    newCurrentRevision,
    setNewCurrentRevision,
    getDesignById,
    fileRegex,
    fileRegexToString,
  } = props;

  const [inactiveListLoading, setInactiveListLoading] = useState(false);
  const [currentListLoading, setCurrentListLoading] = useState(false);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

  const [form] = useForm();
  const formValues = useWatch([], form);

  const callGetRevisions = (currentId: string) => {
    if (drawerManagingRevision.design) {
      setInactiveListLoading(true);
      getRevisions(workspaceId, projectId, currentId)
        .then((response) => {
          setRevisionList(response);
          setInactiveListLoading(false);
        })
        .then(() => {
          if (drawerManagingRevision.fromListView) {
            delete drawerManagingRevision.fromListView;
            setDrawerManagingRevision(drawerManagingRevision);
            setRevisionList((currentList) => {
              return [...currentList, drawerManagingRevision.design as Design];
            });
          }
        });
    }
  };
  useEffect(() => {
    if (drawerManagingRevision) {
      if (drawerManagingRevision.design?.status === 'draft') {
        setRevisionDraft(drawerManagingRevision.design);
        if (drawerManagingRevision.design.initialRevisionId) {
          try {
            setCurrentListLoading(true);
            getDesignById(workspaceId, projectId, drawerManagingRevision.design.initialRevisionId)
              .then((response) => {
                setCurrentRevision(response);
                setCurrentListLoading(false);
                callGetRevisions(response.id);
              })
              .catch((e) => {
                setCurrentListLoading(false);
                throw new Error(e);
              });
          } catch (e) {
            console.error(e);
          }
        }
        setCurrentRevision(drawerManagingRevision.design as Design);
      } else {
        setCurrentRevision(drawerManagingRevision.design as Design);
        callGetRevisions(drawerManagingRevision.design?.id as string);
      }
    }
  }, [drawerManagingRevision]);

  const resetRevisionList = () => {
    const nonDeletedList = revisionList.filter((des) => !deleteList.includes(des.id));
    if (deleteList.includes(drawerManagingRevision.design?.id as string) && nonDeletedList.length) {
      setLocalCurrentRevision(
        nonDeletedList.sort((a, b) => {
          return (a.issueDate ?? a.createdAt) < (b.issueDate ?? b.createdAt) ? 1 : -1;
        })[0]
      );
    } else if (!newCurrentRevision) {
      setLocalCurrentRevision(drawerManagingRevision.design as Design);
      setRevisionList((currentList) =>
        currentList.filter((design) => {
          return design.initialRevisionId !== null;
        })
      );
    } else {
      setRevisionList(
        revisionList.filter((des) => {
          return des.id !== newCurrentRevision.id;
        })
      );
    }
  };

  const setLocalCurrentRevision = (newDesign: Design) => {
    const newRevisionList = revisionList.filter((design) => {
      return design.id !== newDesign.id;
    });
    if (newCurrentRevision) {
      newRevisionList.push(newCurrentRevision);
    } else if (currentRevision) {
      newRevisionList.push(currentRevision as Design);
    }

    if (newCurrentRevision) {
      setRevisionList(
        newRevisionList.filter((des) => {
          return des.id !== newDesign.id;
        })
      );
    } else {
      setRevisionList(newRevisionList);
    }

    setNewCurrentRevision(newDesign);
  };

  useEffect(() => {
    if (fileList[0]?.response) {
      setFileErrorMessage(fileList[0].response);
    } else {
      setFileErrorMessage(null);
    }

    if (fileList.length) {
      const dotIndex = fileList[0]!.name.lastIndexOf('.');
      const fileName = fileList[0]!.name.slice(0, dotIndex) ?? '';
      const newDesign = Object.assign({
        ...drawerManagingRevision.design,
        id: fileList[0].uid,
        title: fileName,
        revision: '',
        issueDate: '',
        deadlineDate: '',
        initialRevisionId: null,
      });
      delete newDesign.createdAt;
      delete newDesign.deletedAt;
      delete newDesign.updatedAt;
      delete newDesign.issuedBy;
      delete newDesign.issuedByUser;
      delete newDesign.projectId;
      delete newDesign.revisionIndex;
      setNewUploadedRevision(newDesign as Design);
    } else {
      setFileErrorMessage(null);
    }
  }, [fileList]);

  const deleteEvenIfNoneExist = async () => {
    const idsToDelete = [...new Set(deleteList)];
    const filtered = idsToDelete.filter((val) => !!val);
    if (filtered.length) {
      await deleteRevisions(workspaceId, projectId, filtered);
    }
  };

  const onCancel = () => {
    setFileErrorMessage(null);
    setRevisionDraft(null);
    setCurrentRevision(null);
    setNewCurrentRevision(null);
    setNewUploadedRevision(null);
    setDeleteList([]);
    setRevisionList([]);
    setFileList([]);
    setSubmitLoading(false);
    onClose();
    form.resetFields();
  };

  const onSubmit = async () => {
    setSubmitLoading(true);
    try {
      if (newUploadedRevision) {
        const newDesign = {
          ...newUploadedRevision,
          title: formValues.title,
          revision: formValues.revision ?? '',
          issueDate: formValues.issueDate,
          deadlineDate: formValues.deadlineDate,
          status: 'draft',
        };

        setNewCurrentRevision(null);
        handleSingleUpload(fileList[0], currentRevision?.id as string, newDesign as UnpublishedDesignForm, true)
          .then(() => {
            setSubmitLoading(false);
            refreshAll();
            onCancel();
          })
          .then(() => {
            deleteEvenIfNoneExist();
          })
          .catch(() => {
            setSubmitLoading(false);
          });
      } else if (newCurrentRevision) {
        updateCurrentRevision(workspaceId, projectId, currentRevision?.id as string, newCurrentRevision.id)
          .then(() => {
            deleteEvenIfNoneExist();
          })
          .then(() => {
            setSubmitLoading(false);
            onCancel();
            refreshAll();
          });
      } else {
        deleteEvenIfNoneExist().then(() => {
          onCancel();
        });
      }
      if (deleteList.length !== 0) {
        await deleteRevisions(workspaceId, projectId, deleteList);
        refreshAll();
      }
    } catch (e) {
      console.error(e);
      setSubmitLoading(false);
    }
    setSubmitLoading(false);
  };
  return (
    <Drawer
      className='no-text-transform'
      width={1025}
      title={<ManageRevisionsHeader managingRevisionGroup={drawerManagingRevision.design} />}
      onClose={onCancel}
      open={drawerManagingRevision.open}
      closeIcon={false}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      footer={
        <Flex style={{ flexDirection: 'row-reverse' }}>
          <div>
            <Button onClick={onCancel}>{t('Cancel')}</Button>
            <Tooltip title={fileErrorMessage}>
              <Button
                type='primary'
                onClick={onSubmit}
                loading={submitLoading}
                icon={!!fileErrorMessage && <ExclamationCircleOutlined />}
                disabled={!!fileErrorMessage}
              >
                {t('Validate Changes')}
              </Button>
            </Tooltip>
          </div>
        </Flex>
      }
    >
      <Flex
        vertical={true}
        style={{
          backgroundColor: '#fbfbfb',
          borderRadius: 10,
          padding: 20,
          marginTop: 15,
        }}
      >
        <Flex justify={'space-between'}>
          <Typography.Title level={3} style={{ marginBottom: 25, marginTop: 5 }}>
            {newUploadedRevision ? t('New Current Revision') : t('Current Revision')}
          </Typography.Title>
          <Upload
            fileList={fileList}
            onChange={({ fileList: newFileList }) => {
              setFileList(newFileList);
            }}
            accept='application/pdf'
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <Button
              icon={<PlusOutlined />}
              type='primary'
              disabled={fileList.length !== 0 || currentRevision?.status === 'draft' || revisionDraft !== null}
            >
              {t('Upload New Revision')}
            </Button>
          </Upload>
        </Flex>

        <ManageRevisionsCurrentListView
          fileRegex={fileRegex}
          fileRegexToString={fileRegexToString}
          loading={currentListLoading}
          currentRevision={currentRevision}
          revisionDraft={revisionDraft}
          setRevisionDraft={setRevisionDraft}
          openPDFNewTab={openPDFNewTab}
          revisionList={revisionList}
          setLocalCurrentRevision={setLocalCurrentRevision}
          deleteList={deleteList}
          setDeleteList={setDeleteList}
          resetRevisionList={resetRevisionList}
          form={form}
          setNewUploadedRevision={setNewUploadedRevision}
          setFileList={setFileList}
          singleDownload={singleDownload}
          drawerManagingRevision={drawerManagingRevision}
          projectId={projectId}
          workspaceId={workspaceId}
          newUploadedRevision={newUploadedRevision}
          newCurrentRevision={newCurrentRevision}
        />
      </Flex>
      <Flex vertical={true} style={{ backgroundColor: 'transparent', borderRadius: 10, padding: 20, marginTop: 15 }}>
        <Flex justify={'space-between'}>
          <Typography.Title level={3} style={{ marginBottom: 25, marginTop: 5 }}>
            {t('Inactive Revisions')}
          </Typography.Title>
        </Flex>
        <ManageRevisionsInactiveListView
          openPDFNewTab={openPDFNewTab}
          deleteList={deleteList}
          setDeleteList={setDeleteList}
          setLocalCurrentRevision={setLocalCurrentRevision}
          newUploadedRevision={newUploadedRevision}
          loading={inactiveListLoading}
          singleDownload={singleDownload}
          projectId={projectId}
          workspaceId={workspaceId}
          revisionList={revisionList.filter(
            (des) => (!newUploadedRevision || des.id !== drawerManagingRevision.design?.id) && des.status !== 'draft'
          )}
        />
      </Flex>
    </Drawer>
  );
}
