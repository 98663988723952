import { DesignRegisterPhase } from 'model/ProjectDataCustomization'; 
import { Key } from 'react';
import request from 'requesters/core.request';

const designRegisterPhaseService = {

  createOrUpdateDesignRegisterPhases: async (workspaceId: string, projectId: string, data: DesignRegisterPhase[]) => {
    return request.post<DesignRegisterPhase[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/design-register-phases-list`,
      data
    );
  },


  getAllDesignRegisterPhases: async (workspaceId: string, projectId: string) => {
    return request.get<DesignRegisterPhase[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/design-register-phases`
    );
  },


  deleteDesignRegisterPhaseByID: async (workspaceId: string, projectId: string, designRegisterPhasesId: Key[]) => {
    return request.delete(
      `/workspaces/${workspaceId}/projects/${projectId}/design-register-phases/${designRegisterPhasesId}`,
      {
        params: { designRegisterPhasesId }
      }
    );
  },

  

  
};

export default designRegisterPhaseService;
