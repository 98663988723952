import { FileSyncOutlined, FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, message, Typography } from 'antd';
import { t } from 'i18next';
import { CreateOrUpdateMAR, MaterialApprovalRequestsListView } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useMaterialCategories, useMaterialCategoriesParams } from 'hooks/material-categories';
import {
  useFetchProjectDisciplineByFunction,
  useFetchProjectDisciplineByFunctionParams,
  useProjectBaseIssuesParams,
} from 'hooks/base-issue';
import {
  useInspectionTestPlain,
  useInspectionTestPlanParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
} from 'hooks';
import { useSearchDebounce } from 'utils';
import { QueryParams, TableRowsSelected } from 'types';
import FilterMaterialApprovalRequest from './FilterMaterialApprovalRequestForm';
import { User } from 'model';
import dayjs from 'dayjs';
import GenerateReports from './Reports/GenerateReports';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/DisciplineBaseIssueTable';
import { kpiData } from 'types/project';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import BaseIssueKpiStatistic from 'components/common/BaseIssueKpiStatistic';
type MaterialApprovalRequestProps = {
  module: string;
  workspaceId: string;
  isOwnerCompany: boolean;
  projectId: string;
  userCompanyId: undefined | string;
  loggedCompanyRoleId: string;
  profile: User | null;
  users: User[];
};
export default function MaterialApprovalRequest(props: MaterialApprovalRequestProps) {
  const { module, workspaceId, projectId, profile, users, isOwnerCompany, userCompanyId, loggedCompanyRoleId } = props;
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isReviewInProgress, setIsReviewInProgress] = useState(false);
  const [materialCategoriesQuery] = useMaterialCategoriesParams({ projectId, workspaceId });
  const [materialCategories] = useMaterialCategories(materialCategoriesQuery);
  const [baseIssueId, setBaseIssueId] = useState<string | null>(null);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const [isGenerateReports, setIsGenerateReports] = useState<boolean | null>(null);
  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  useInspectionTestPlain(inspectionTestPlanParams);
  const [projectBaseIssueParams, onProjectBaseIssuesParamsChange] = useProjectBaseIssuesParams({
    projectId: projectId as string,
    ...(!isOwnerCompany && { 'companyId[]': `["${loggedCompanyRoleId}"]` }),
    workspaceId,
    module,
    type: 'MAR',
    include:
      'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments',
  });
  // const [baseIssues, loadingBaseIssues, refreshBaseIssues] = useProjectBaseIssues(projectBaseIssueParams);
  const [selectedRowKeys, setSelectedRowKeys] = useState<TableRowsSelected>({});
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const disciplineBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);
  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: props.projectId as string,
    workspaceId: props.workspaceId as string,
    orderBy: 'order',
    mainPhase: true,
  });
  useWorkBreakdownStructures(workBreakdownStructureQuery);

  const [ProjectDisciplineByFunctionParams] = useFetchProjectDisciplineByFunctionParams({
    workspaceId,
    projectId,
    type: 'MAR',
  });
  const [, , refreshDisciplineByFunction] = useFetchProjectDisciplineByFunction(ProjectDisciplineByFunctionParams);
  const [totalKpi, setTotalKpi] = useState<kpiData>({});

  const handleOpenForm = (baseIssueId?: string) => {
    if (baseIssueId) {
      setBaseIssueId(baseIssueId);
      setIsFormOpen(true);
      setCreate(false);
    } else {
      setIsEdit(false);
      setBaseIssueId(null);
      setIsFormOpen(true);
      setCreate(true);
      setIsReviewInProgress(false);
    }
  };

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };
  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleFilter = async (values: QueryParams) => {
    let CompanyOriginatorId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === 'myCompany' && userCompanyId) {
      CompanyOriginatorId = `${userCompanyId}`;
    } else if (values.groupIssues === 'watched' && profile?.id) {
      watcherId = `${profile?.id}`;
    } else {
      if (values.companyId) {
        CompanyOriginatorId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }
    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const zoneId = values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;
    const workPackageId =
      values.workPackageId && values.workPackageId.length > 0
        ? `[${'"' + values.workPackageId.join('","') + '"'}]`
        : undefined;
    const params = {
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      'statusId[]': issueStatusId,

      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : CompanyOriginatorId,
      originatorId: assigneeId,
      'zoneId[]': zoneId,
      'workPackageId[]': workPackageId,
      // 'companyId[]': [companyId],
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };
    // console.log(params);
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
    setSelectedRowKeys({});
  };

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'MAR',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);

  const handleSearch = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const param: { [key: string]: any } = {
        searchBy: '["BaseIssue.name", "Company.name","Zone.name","MaterialApprovalRequest.marNumber"]',
        searchKey: searchValue,
        page: 1,
      };
      onProjectBaseIssuesParamsChange(param);
      setSelectedRowKeys({});
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handlerRowsKeyChange = (keys: React.Key[], distributionId?: string) => {
    const newKeys = { ...selectedRowKeys };
    if (!distributionId) return;
    newKeys[distributionId] = keys;
    setSelectedRowKeys(newKeys);
  };

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  return (
    <Layout.Content className='settings-content'>
      {isFormOpen !== null && (
        <CreateOrUpdateMAR
          baseIssueId={baseIssueId}
          materialCategories={materialCategories}
          onFinish={() => setIsFormOpen(false)}
          isEdit={isEdit}
          isOpen={isFormOpen}
          module={module}
          refreshBaseIssues={(disciplineId) => {
            disciplineBaseIssueTableRef.current?.refreshData(disciplineId, baseIssueId);
          }}
          workspaceId={workspaceId}
          projectId={projectId}
          setOpen={setIsFormOpen}
          isReviewInProgress={isReviewInProgress}
          isCreate={isCreate}
        />
      )}
      <Flex>
        <Typography.Title level={2} className='mr-16 setting-section-header'>
          {' '}
          {t('Material approval requests - MAR')}{' '}
        </Typography.Title>
      </Flex>
      <Flex style={{ justifyContent: 'flex-end' }} className='mt-3 mb-3'>
        <Input
          name='search-input'
          className='search-button mr-3'
          placeholder={t('Type to search')}
          onBlur={(e) => handleInputBlur(e.target.value)}
          onFocus={handleSearchIconClick}
          style={{
            width: showSearchInput ? '250px' : '0px',
            border: showSearchInput ? '1px solid #d9d9d9' : 'none',
          }}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
        />

        {isOpenFilter === null ? (
          <Button
            onClick={() => {
              setIsOpenFilter(!isOpenFilter);
            }}
            icon={<FilterOutlined />}
          >
            {t('Filter')}
          </Button>
        ) : (
          <FilterMaterialApprovalRequest
            setZoneUpdated={setZoneUpdated}
            zoneUpdated={zoneUpdated}
            companies={companies}
            users={users}
            issueStatuses={issueStatuses}
            workspaceId={workspaceId}
            projectId={projectId}
            isOpenFilter={isOpenFilter}
            userCompanyId={userCompanyId}
            setIsOpenFilter={setIsOpenFilter}
            handleFilter={(value: { [key: string]: string | number }) => {
              handleFilter(value);
            }}
            materialCategories={materialCategories}
            isReset={isResetFilter || false}
            setReset={setIsResetFilter}
            module={module}
            isOwnerCompany={isOwnerCompany}
          />
        )}
        {isGenerateReports !== null && (
          <GenerateReports
            isGenerateReports={isGenerateReports === true}
            profile={profile}
            users={users}
            module={module}
            workspaceId={workspaceId}
            projectId={projectId}
            companies={companies}
            issueStatuses={issueStatuses}
            selectedRowKeys={selectedRowKeys}
            isOwnerCompany={isOwnerCompany}
            setIsGenerateReports={(value: boolean) => {
              setIsGenerateReports(value);
              setSelectedRowKeys({});
            }}
          />
        )}
        <Button onClick={() => setIsGenerateReports(true)} icon={<FileSyncOutlined />}>
          {t('Generate report')}
        </Button>
        <Button type='primary' icon={<PlusOutlined />} onClick={() => handleOpenForm()}>
          {t('Add request')}
        </Button>
      </Flex>

      <BaseIssueKpiStatistic
        onFilter={(params) => {
          onProjectBaseIssuesParamsChange(params);
          setIsResetFilter(true);
          setSelectedRowKeys({});
        }}
        kpiData={totalKpi}
        issueStatuses={issueStatuses}
        projectBaseIssueParams={projectBaseIssueParams}
        isResetKPI={isResetFilterByKPI}
        onResetKPI={setIsResetFilterByKPI}
      />
    
      <MaterialApprovalRequestsListView
        workspaceId={workspaceId}
        projectId={projectId}
        users={users}
        profile={profile}
        projectBaseIssueParams={projectBaseIssueParams}
        disciplineBaseIssueTableRef={disciplineBaseIssueTableRef}
        onTotalKpi={setTotalKpi}
        editForm={(id: string) => handleOpenForm(id)}
        handlerRowsKeyChange={handlerRowsKeyChange}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        refreshDisciplineByFunction={refreshDisciplineByFunction}
      />
    </Layout.Content>
  );
}
