import { Design } from 'model';
import {
  DesignAttachmentForm,
  EditDesignForm,
  PublishDesignInput,
  RawDesignForm,
  UnpublishedDesignForm,
} from 'modules/DocumentManagement/models/Design';
import { Key } from 'react';
import request from 'requesters/core.request';
import { AxiosRequestConfig } from 'axios';

const designService = {
  getProjectDesigns: async (workspaceId: string, projectId: string, query: object) => {
    return request.get<{ count: number; rows: Design[] }>(`/workspaces/${workspaceId}/projects/${projectId}/designs`, {
      params: query,
    });
  },

  getProjectDesignsKpis: async (workspaceId: string, projectId: string, query: object) => {
    return request.get<{ count: number; rows: Design[] }>(
      `/workspaces/${workspaceId}/projects/${projectId}/designs/kpis`,
      {
        params: query,
      }
    );
  },

  getDesignRevisions: async (workspaceId: string, projectId: string, designid: string) => {
    return request.get<Design[]>(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designid}/revisions`);
  },
  createDesigns: async (projectId: string, workspaceId: string, data: UnpublishedDesignForm[]) => {
    return request.post<{ id: string; uid: string }[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/designs`,
      data
    );
  },
  findDuplicateDesign: async (workspaceId: string, projectId: string, data: RawDesignForm[]) => {
    return request.post<{ id: string; hasDuplicate: boolean }[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/designs/duplicates`,
      {
        ...data,
      }
    );
  },

  sendDesignAttachment: async (workspaceId: string, projectId: string, data: DesignAttachmentForm) => {
    const formData = new FormData();
    formData.append('files', data.file);
    formData.append('designId', data.designId);
    formData.append('draft', data.draft.toString());
    return request.post<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getDesignById: async (workspaceId: string, projectId: string, designId: string) => {
    return request.get<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}`, {});
  },
  updateDesign: async (workspaceId: string, projectId: string, designId: string, updatedDesign: EditDesignForm) => {
    return request.put<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}`, updatedDesign);
  },
  updateDesignDraftBulk: async (workspaceId: string, projectId: string, updatedDesigns: EditDesignForm[]) => {
    return request.put<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/drafts`, updatedDesigns);
  },
  createDesignRevision: async (workspaceId: string, projectId: string, designId: string, design: EditDesignForm) => {
    return request.post<{ id: string }>(
      `/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}/revisions`,
      design
    );
  },
  updateDesignRevision: async (workspaceId: string, projectId: string, oldDesignId: string, newDesignid: string) => {
    return request.put<{ id: string }>(
      `/workspaces/${workspaceId}/projects/${projectId}/designs/${oldDesignId}/revisions`,
      newDesignid
    );
  },
  publishDesigns: async (workspaceId: string, projectId: string, input: PublishDesignInput) => {
    return request.put<{ id: string }>(`/workspaces/${workspaceId}/projects/${projectId}/designs/publish`, input);
  },
  downloadDesign: async (workspaceId: string, projectId: string, designId: string, config?: AxiosRequestConfig) => {
    return request.get<string>(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}/download`, config);
  },
  downloadDesigns: async (workspaceId: string, projectId: string, designId: string[]) => {
    return request.post<string>(`/workspaces/${workspaceId}/projects/${projectId}/designs/download`, designId);
  },
  deleteDesignsAndRevisions: async (workspaceId: string, projectId: string, designIds: Key[]) => {
    return request.delete<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/revisions`, {
      params: designIds,
    });
  },
  deleteDesignsById: async (workspaceId: string, projectId: string, designIds: Key[]) => {
    return request.delete<Design>(`/workspaces/${workspaceId}/projects/${projectId}/designs/list`, {
      params: designIds,
    });
  },
  startDesignMultipartUpload: async (
    workspaceId: string,
    projectId: string,
    designId: string,
    params: { fileName: string; fileType: string }
  ) => {
    return request.get(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}/upload-start`, {
      params,
    });
  },
  endDesignMultipartUpload: async (
    workspaceId: string,
    projectId: string,
    designId: string,
    params: {
      fileType: string;
      keyFile: string;
      parts: {
        ETag: {
          fileName: string;
          fileUrl: string;
          headers: string;
        };
        PartNumber: number;
      }[];
      uploadId: string;
      draft: boolean;
    }
  ) => {
    return request.post(`/workspaces/${workspaceId}/projects/${projectId}/designs/${designId}/upload-end`, params);
  },
};

export default designService;
