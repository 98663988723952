import { CustomizeRequiredMark, Modal, SearchSelect, UploadFormItem } from 'components';
import {
  useCountries,
  useCountriesParams,
  useMyWorkspaceCompanyCategories,
  useMyWorkspaceCompanyCategoriesParams,
} from 'hooks';
import { useEffect } from 'react';
import { Button, Divider, Flex, Form, Input, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { companyService } from 'services';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { Company, CompanyCategory } from 'model';

type CompanyProfileProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  companyDetails?: Company;
  onSubmit: () => void;
};
export default function CompanyProfile(props: CompanyProfileProps) {
  const { isModalOpen, setIsModalOpen, companyDetails, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;
  const [countryParams] = useCountriesParams();
  const [countries] = useCountries(countryParams);
  const [companyCategoriesParams] = useMyWorkspaceCompanyCategoriesParams({
    workspaceId: workspaceId,
    orderBy: 'name',
  });
  const [companyCategories] = useMyWorkspaceCompanyCategories(companyCategoriesParams);

  useEffect(() => {
    if (companyDetails && isModalOpen) {
      form.setFieldsValue(companyDetails);
    }
  }, [companyDetails, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (companyDetails?.companyCategoryId && companyCategories.length > 0) {
      const existingCompanyCategory = companyCategories?.findIndex(
        (companyCategory) => companyCategory.id === companyDetails?.companyCategoryId
      );
      form.setFieldValue(
        ['companyCategoryId'],
        existingCompanyCategory > -1 ? companyDetails?.companyCategoryId : null
      );
    }
  }, [companyDetails, companyCategories]);

  const handleOk = async () => {
    try {
      const vaild = await form.validateFields();
      if (vaild) {
        try {
          const values = form.getFieldsValue();
          values.workspaceId = workspaceId;
          values.companyId = companyDetails?.id;
          const companyId = companyDetails?.id;
          await companyService.updateCompany(workspaceId, companyId as string, values);
          message.success(t('Update company success!'));
          onSubmit();
        } catch (error) {
          console.log(error);
          message.error(t('Oop! Something wrong'));
        }
      }
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeLogo = async (imageUrl: string) => {
    if (imageUrl) {
      form.setFieldValue('logo', imageUrl);
    }
  };

  return (
    <Modal
      title={t('Edit company profile')}
      open={isModalOpen}
      onOk={handleOk}
      classNames={{ body: 'company-profile-modal' }}
      onCancel={handleCancel}
      footer={[
        <Flex key={'footer'} justify='space-between'>
          <Button key='back' onClick={handleCancel}>
            {t('Setup later')}
          </Button>
          <Button key='submit' type='primary' onClick={handleOk}>
            {t('Save profile')}
          </Button>
        </Flex>,
      ]}
    >
      <Typography.Title level={3} className='section-title'>
        {t('Overview')}
      </Typography.Title>
      <Form requiredMark={CustomizeRequiredMark} form={form} name='create-company' layout='vertical'>
        <Form.Item label={t('Company Name')} name='name' rules={[{ required: true, message: '' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <>
              {' '}
              {t('Code (3 digits)')}
              {':'}
            </>
          }
          name='companyCode'
          rules={[{ message: '', max: 3, min: 3 }]}
        >
          <Input style={{ width: 60 }} />
        </Form.Item>
        <Form.Item label={t('Company category')} name='companyCategoryId' rules={[{ required: true, message: '' }]}>
          <SearchSelect
            placeholder={t('Company category')}
            options={companyCategories?.map((companyCategory: CompanyCategory) => ({
              value: companyCategory.id,
              label: companyCategory.name,
            }))}
          />
        </Form.Item>
        <Form.Item label={t('Logo') + ':'} name='logo'>
          <UploadFormItem
            imgUrl={companyDetails?.logo}
            onChange={handleChangeLogo}
            extraText={t('Upload company logo in JPG, SVG or PNG to identify your workspace and use in reports')}
          />
        </Form.Item>
        <Divider className='mb-3 mt-8' />
        <Typography.Title level={3} className='section-title'>
          {t('Contact')}
        </Typography.Title>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Street') + ':'} name='street'>
          <Input placeholder={t('XXXX')} />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('City') + ':'} name='city'>
          <Input placeholder={t('XXXX')} />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Zipcode') + ':'} name='zipcode'>
          <Input placeholder={t('7000')} />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Country') + ':'} name='countryId'>
          <SearchSelect
            placeholder={t('Country')}
            options={countries?.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
          />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Phone') + ':'} name='phone'>
          <Input placeholder={t('(+45)-4556567812')} />
        </Form.Item>
        <Divider className='mb-3 mt-15' />
        <Typography.Title level={3} className='section-title'>
          {t('Representative')}
        </Typography.Title>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Name') + ':'} name='contactName'>
          <Input placeholder={t('John Doe')} />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Email') + ':'} name='contactEmail'>
          <Input placeholder={t('johh.doe@company.com')} />
        </Form.Item>
        <Form.Item className='form-item-inline' labelCol={{ span: 12 }} label={t('Phone') + ':'} name='contactPhone'>
          <Input placeholder={t('(+45)-4556567812')} />
        </Form.Item>
        {/* <Typography.Title level={3} className='mb-6 mt-16'>
          {t('Optional - Add employees')}
        </Typography.Title>
        <Form.Item
          label={t('Invite users to join the company') + ':'}
          name='invite_users'>
          <Input />
        </Form.Item> */}
      </Form>
    </Modal>
  );
}
