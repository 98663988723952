import React from 'react';
import { Row, Col, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import MDIsListDocumentSection from './MDIsListDocumentSection';
import MDIsListPictureSection from './MDIsListPictureSection';
import { Attachment, AttachmentInfo, BaseIssue, User } from 'model';
import InviteForInspection from './InviteForInspection';
import useUserEntity from 'hooks/common/useUserProfile';
import { useTranslation } from 'react-i18next';
export type MaterialDetailType = 'proposed' | 'specified';

interface MaterialDetailFormProps {
  isEdit: boolean;
  workspaceId: string;
  projectId: string;
  selectedMdiBaseIssue: BaseIssue | null;
  isOpen: boolean;
  attachmentLoading: boolean;
  attachments: Attachment[];
  companyOriginator: string | undefined;
  users: User[];
  form: FormInstance;
  materialLoading: boolean;
  documentsInfo: AttachmentInfo[];
  photosInfo: AttachmentInfo[];
  setDocumentsInfo: (value: AttachmentInfo[]) => void;
  setPhotosInfo: (value: AttachmentInfo[]) => void;
  fetchAttachments: () => void;
}

const MaterialDetailForm: React.FC<MaterialDetailFormProps> = ({
  isEdit,
  workspaceId,
  projectId,
  selectedMdiBaseIssue,
  companyOriginator,
  attachments,
  users,
  materialLoading,
  documentsInfo,
  photosInfo,
  isOpen,
  setDocumentsInfo,
  setPhotosInfo,
  fetchAttachments,
  attachmentLoading,
  form,
}) => {
  const { user } = useUserEntity({ projectId, workspaceId });
  const { t } = useTranslation();

  return (
    <Spin spinning={attachmentLoading}>
      <Row className={!isEdit ? 'read-only-row' : ''}>
        <Col span={24}>
          <MDIsListDocumentSection
            isEdit={isEdit}
            selectedMdiBaseIssue={selectedMdiBaseIssue}
            workspaceId={workspaceId}
            projectId={projectId}
            documentsInfo={documentsInfo}
            attachments={attachments}
            fetchAttachments={() => fetchAttachments()}
            setDocumentsInfo={(value: AttachmentInfo[]) => setDocumentsInfo(value)}
          />
        </Col>
        <Col span={24}>
          <>
            {!isEdit && (
              <Typography.Title className='material-group-title' level={3}>
                {t('Pictures')}
              </Typography.Title>
            )}
            <MDIsListPictureSection
              isEdit={isEdit}
              selectedMdiBaseIssue={selectedMdiBaseIssue}
              workspaceId={workspaceId}
              projectId={projectId}
              attachments={attachments}
              photosInfo={photosInfo}
              fetchAttachments={() => fetchAttachments()}
              setPhotosInfo={(value: AttachmentInfo[]) => setPhotosInfo(value)}
              user={user}
            />
          </>
        </Col>
      </Row>
      <Row className={'read-only-row'}>
        <Col span={24} style={{ marginTop: '15px' }}>
          <InviteForInspection
            users={users}
            isOpen={isOpen}
            isEdit={isEdit}
            companyOriginator={companyOriginator}
            projectId={projectId}
            loading={materialLoading}
            form={form}
            MDI={selectedMdiBaseIssue}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default MaterialDetailForm;
