import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { BaseIssue, User } from 'model';
import { MSS_STATUSES } from 'utils/contants';
import { WorkspaceViewType } from '../CreateOrUpdateMSS';

type MSSsFooterProps = {
  profile: User | null;
  isSubmit?: boolean;
  isSubmitAsDraft?: boolean;
  selectedMssBaseIssue: BaseIssue | null;
  isReadOnly: boolean;
  isDisableStartValidation?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  onEdit?: () => void;
  isSave: boolean;
  setOpenValidationModal: (isOpen: boolean) => void;
  onSubmitAsDraft: () => void;
  activeTab: string;
  setActiveTab: (isOpen: WorkspaceViewType) => void;
  user: User | null;
};

export default function MSSsFooter(props: MSSsFooterProps) {
  const {
    isSubmit,
    isSubmitAsDraft,
    selectedMssBaseIssue,
    isReadOnly,
    isDisableStartValidation,
    isSave,
    onSubmitAsDraft,
    setOpenValidationModal,
    profile,
    setActiveTab,
    activeTab,
  } = props;
  const isRejected = selectedMssBaseIssue?.IssueStatus?.code === MSS_STATUSES.REJECTED;
  const isApproved = selectedMssBaseIssue?.IssueStatus?.code === MSS_STATUSES.APPROVED;
  const isApprovedWithComment = selectedMssBaseIssue?.IssueStatus?.code === MSS_STATUSES.APPROVED_WITH_COMMENT;

  const userLogin = profile?.id;
  const userLoginCompany = props.user?.WorkspaceUser?.Company?.id;
  const originatorCompanyId = selectedMssBaseIssue?.companyOriginatorId;
  const originatorId = selectedMssBaseIssue?.originatorId;
  const baseValidations = selectedMssBaseIssue?.BaseValidations;

  let disabledValidation = false;
  if (!selectedMssBaseIssue) {
    // Check required fields name, originator, originator company, work package
    disabledValidation = isDisableStartValidation ? true : false;
  } else {
    // Not check required fields for readonly
    if (!isReadOnly && isDisableStartValidation) {
      disabledValidation = true;
    }
    if (isRejected || isApproved || isApprovedWithComment) {
      disabledValidation = true;
    } else if (
      (baseValidations === null || (baseValidations || [])?.length < 1) &&
      userLoginCompany !== originatorCompanyId
    ) {
      disabledValidation = true;
    } else if (baseValidations && baseValidations.length > 0 && baseValidations[0].validatorId !== userLogin) {
      disabledValidation = true;
    }
  }

  return (
    <Row gutter={16} justify={'end'} align='middle'>
      <Col span={12} style={{ textAlign: 'right' }}>
        <>
          {isRejected && userLogin === originatorId ? (
            <Button disabled={true}> {t('Create a Revision')} </Button>
          ) : !selectedMssBaseIssue ? (
            <Button
              onClick={() => {
                onSubmitAsDraft();
                if (activeTab !== 'detail') {
                  setActiveTab('detail');
                }
              }}
              loading={isSubmitAsDraft}
            >
              {' '}
              {t(isSave ? 'Save' : 'Save as Draft')}{' '}
            </Button>
          ) : selectedMssBaseIssue?.id ? (
            <Button onClick={onSubmitAsDraft} loading={isSubmitAsDraft} disabled={isReadOnly}>
              {' '}
              {t('Save')}{' '}
            </Button>
          ) : (
            <></>
          )}
          <Button
            type='primary'
            loading={isSubmit}
            onClick={() => {
              if (activeTab !== 'detail') {
                setActiveTab('detail');
              }
              setOpenValidationModal(true);
            }}
            disabled={disabledValidation}
          >
            {t('Next')}
          </Button>
        </>
      </Col>
    </Row>
  );
}
