import { Image, ImageProps } from 'antd';
import { ImagePreviewType } from 'rc-image';
import { CSSProperties, useEffect, useState } from 'react';
import { fileService } from 'services';

export default function ImageItem({
  src,
  style,
  className,
  alt,
  onError,
  preview,
  onClick,
}: {
  src: string | undefined;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  alt?: string | undefined;
  onError?: React.ReactEventHandler<HTMLImageElement> | undefined;
  preview?: boolean | ImagePreviewType | undefined;
  onClick?: ImageProps['onClick'];
}) {
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  useEffect(() => {
    if (src && src.includes('.s3')) {
      fileService.getSignedUrlGet({ keyFile: src.substring(src.indexOf('polaris/') + 8) }).then((r) => {
        setFileUrl(r.preSignedURL);
      });
    } else {
      setFileUrl(src);
    }
  }, [src]);

  return (
    <Image
      onClick={onClick && onClick}
      src={fileUrl}
      style={style}
      className={className}
      alt={alt}
      onError={onError}
      preview={preview}
    />
  );
}
