import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Flex, Row, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { BaseIssue, User } from 'model';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectProjectUsers, selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';
import CustomTooltip from './CustomTooltip';

type BaseIssueFormHeaderProps = {
  disciplineId?: string;
  originatorId?: string;
  profile: User | null;
  onClose?: () => void;
  user?: User | null;
  baseIssueNumber?: string;
  issueStatus: BaseIssue['IssueStatus'];
  submittedDate?: string;
  name?: string;
  hideDiscipline?: boolean;
};

export default function BaseIssueFormHeader(props: BaseIssueFormHeaderProps) {
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const disciplineCode = disciplines.find((discipline) => discipline.id === props?.disciplineId)?.code || '-';
  const code = useMemo(() => {
    const numberSystem = props.baseIssueNumber?.split('-');
    if (!numberSystem) return '';
    const lastPart = numberSystem[numberSystem.length - 1];
    if (lastPart) {
      const trimmedNumber = lastPart.replace(/^0+/, '');
      numberSystem[numberSystem.length - 1] = trimmedNumber || '0';
    }
    return numberSystem.join('-');
  }, [props.baseIssueNumber]);

  const users = useAppSelector(selectProjectUsers);
  const originator = users.find((u) => u.id === (props?.originatorId || props?.profile?.id));

  const renderStatus = () => {
    if (!props.issueStatus) return <Tag className='mr-0'>{t('Unsaved')}</Tag>;

    return (
      <div>
        <Tag className='mr-0'>
          <Flex align='center'>
            <Tag
              className='circle-fill small'
              color={
                TASK_STATUS_HEADER_COLOR?.[props?.issueStatus?.code as keyof typeof TASK_STATUS_HEADER_COLOR] || ''
              }
              bordered={false}
            />
            {t(props.issueStatus?.name)}
          </Flex>
        </Tag>
      </div>
    );
  };

  const renderName = () => {
    return (
      <Flex vertical justify='center' className='ml-2' wrap='nowrap' rootClassName='name-box'>
        <CustomTooltip title={props?.name} />
        <Flex className='name-group'>
          {code && <Typography.Text>{code?.toUpperCase()}</Typography.Text>}
          {originator?.WorkspaceUser?.Company?.name && (
            <Typography.Text>{originator?.WorkspaceUser?.Company?.name}</Typography.Text>
          )}
          {originator?.fullName && <Typography.Text>{originator?.fullName}</Typography.Text>}
          {props?.submittedDate && (
            <Typography.Text>
              {t('Submitted on:')} {dayjs(props?.submittedDate).locale('en').format('DD-MM-YYYY')}
            </Typography.Text>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <div className={`custom-modal small-drawer base-issue-form-header`} style={{ width: '100%', height: '100%' }}>
      {props?.onClose && (
        <Row justify='space-between' className='mb-2'>
          <Col span={12} className='header'>
            <DoubleRightOutlined className='icon' onClick={props.onClose} style={{ color: 'white' }} />
          </Col>
          <Col span={12} className='header text-right'>
            <Button
              size='small'
              type='text'
              onClick={props.onClose}
              icon={<CloseOutlined className='icon' style={{ color: 'white' }} />}
            />
          </Col>
        </Row>
      )}
      <Flex gap={6} align='center' className='text-white'>
        {!props.hideDiscipline && <Avatar className='avt-color'>{disciplineCode?.toString().toUpperCase()}</Avatar>}
        {renderName()}
        {renderStatus()}
      </Flex>
    </div>
  );
}
