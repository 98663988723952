import { Project } from 'model';
import { ProjectList } from 'components';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
type FavoriteListProps = {
  favorites: Project[];
  markProjectAsFavorite: (
    workspaceId: string,
    projectId: string,
    isFavorite: boolean
  ) => void;
};

export default function FavoriteList(props: FavoriteListProps) {
  const { t } = useTranslation();
  const { favorites, markProjectAsFavorite } = props;

  return (
    <div className='workspaces favorite-workspaces'>
      <div className='workspace-header'>
        <Typography.Title level={2} className='workspace-title'>
          {t('Favorites')} ({favorites?.length})
        </Typography.Title>
      </div>
      <ProjectList
        markProjectAsFavorite={markProjectAsFavorite}
        sectionId='favorite'
        projects={favorites}
      />
    </div>
  );
}
