import BaseIssue from 'model/BaseIssue';
import { BaseIssueMessages, EBaseIssueMessagesAction } from 'model/BaseIssueMessages';
import request from 'requesters/core.request';
import reportRequest from 'requesters/report.request';
import { PaginationData, QueryParams } from 'types';

export type ReportPdfPayload = {
  reportName: string;
  reportCode: string;
  createdB?: string;
  userCreated?: string;
  // inspectionDateFrom: dateRange? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
  // inspectionDateTo: dateRange? dayjs(dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : undefined,
  // module: module,
  orderBy: { key: string; direction: string }[];
  executiveSummary: string;
  fields: Record<string, boolean>;
  emails: string[];
  issueIds?: string[];
};
const baseIssueService = {
  createBaseIssue: async <T = unknown>(workspaceId: string, projectId: string, data: T) => {
    return request.post<BaseIssue>(`/workspaces/${workspaceId}/projects/${projectId}/base-issues`, data);
  },
  createBaseIssueMessage: async (
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    data: {
      // IN CASE YOU WANT TO ADD USE TO RFI as distribution:
      // The message should be formatted as follows "{ "from": "23hjh234-2234jnk23-234jn2k3", "to": "jkqwher9 3-234-frf23r-232ff", "toCompany": "asas3few3-wdef23-r" }"
      message: string;
      companyId: string;
      action: EBaseIssueMessagesAction;
    }
  ) => {
    return request.post<BaseIssueMessages>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-issue-messages`,
      data
    );
  },
  updateBaseIssueMessage: async (
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    baseIssueMessageId: string,
    data: {
      // IN CASE YOU WANT TO ADD USE TO RFI as distribution:
      // The message should be formatted as follows "{ "from": "23hjh234-2234jnk23-234jn2k3", "to": "jkqwher9 3-234-frf23r-232ff", "toCompany": "asas3few3-wdef23-r" }"
      message: string;
      companyId: string;
      action: EBaseIssueMessagesAction;
    }
  ) => {
    return request.put<BaseIssueMessages>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-issue-messages/${baseIssueMessageId}`,
      data
    );
  },
  deleteBaseIssueMessage: async (
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    baseIssueMessageId: string
  ) => {
    return request.delete<BaseIssueMessages>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-issue-messages/${baseIssueMessageId}`
    );
  },
  updateBaseIssue: async <T = unknown>(workspaceId: string, projectId: string, baseIssueId: string, data: T) => {
    return request.put<BaseIssue>(`/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}`, data);
  },
  deleteBaseIssueById: async (workspaceId: string, projectId: string, baseIssueId: string) => {
    return request.delete<BaseIssue>(`/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}`);
  },
  deleteMdiInvitations: async (workspaceId: string, projectId: string, mdiInvitationId: string) => {
    return request.delete<BaseIssue>(
      `/workspaces/${workspaceId}/projects/${projectId}/mdi-invitations/${mdiInvitationId}`
    );
  },
  getBaseIssueById: async (workspaceId: string, projectId: string, baseIssueId: string, query?: QueryParams) => {
    return request.get<BaseIssue>(`/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}`, {
      params: query,
    });
  },
  getAllBaseIssues: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<BaseIssue>>(`/workspaces/${workspaceId}/projects/${projectId}/base-issues`, {
      params: query,
    });
  },
  getReportPdf: async (workspaceId: string, projectId: string, data: ReportPdfPayload, query: QueryParams) => {
    return reportRequest.post(`/workspaces/${workspaceId}/projects/${projectId}/base-issues/export-to-pdf`, data, {
      params: query,
    });
  },
  getDisciplineByFunction: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get(`/workspaces/${workspaceId}/projects/${projectId}/discipline-by-function`, {
      params: query,
    });
  },
  getCategoryByFunction: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get(`/workspaces/${workspaceId}/projects/${projectId}/mom-categories`, {
      params: query,
    });
  },
};

export default baseIssueService;
