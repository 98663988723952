import { useMemo } from 'react';
import { QueryParams } from 'types';
import { IssuePriority } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchIssuePriorities, selectIssuePriorities } from 'store/common.slice';

export const useIssuePrioritiesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, []);
};

export const useIssuePriorities = (query: QueryParams) => {
  return useFetchByParams<IssuePriority[]>({
    action: fetchIssuePriorities,
    dataSelector: selectIssuePriorities,
    params: query,
  });
};
