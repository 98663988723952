import { CloseCircleFilled } from '@ant-design/icons';
import { Avatar, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import AvatarItem from './pre-signed-url-items/AvatarItem';
import { Attachment } from 'model';
import ImagePreviewWithComment from './ImagePreviewWithComment';
import { ReactNode } from 'react';
import CustomTooltip from './CustomTooltip';

export type AttachmentPhoto = {
  username?: string;
  firstName?: string;
  hideDelete?: boolean;
  comment?: string;
} & Attachment;
type AttachmentListProps = {
  attachmentPhotos: AttachmentPhoto[];
  onDelete?: (filePatch: string) => void;
  width?: number;
  height?: number;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  avatarSize?: number;
  hideDelete?: boolean;
  children?: ReactNode;
};

const AttachmentList = (props: AttachmentListProps) => {
  return (
    <Flex gap={10} wrap className='attachment-list'>
      {props?.attachmentPhotos.map((attachment) => {
        return (
          <div
            className='attachment-content'
            key={attachment.id}
            style={{
              width: props?.width,
            }}
          >
            <div className='picture' style={{ height: props?.height }}>
              <ImagePreviewWithComment
                comment={attachment?.comment}
                alt={attachment.name}
                src={attachment.filePath}
                style={{ objectFit: props.objectFit }}
              />

              {!(props?.hideDelete || attachment?.hideDelete) && (
                <CloseCircleFilled
                  className='close-icon'
                  onClick={() => props?.onDelete && props.onDelete(attachment?.filePath || '')}
                />
              )}
            </div>

            {attachment?.username && (
              <Flex align='center' className='user' gap={10}>
                {attachment?.picture ? (
                  <AvatarItem
                    size={'large'}
                    style={{ width: props.avatarSize, height: props.avatarSize }}
                    src={attachment?.picture}
                  />
                ) : (
                  <Avatar style={{ width: 34, height: 34 }}>{attachment?.firstName?.substring(0, 1)}</Avatar>
                )}

                <Flex vertical>
                  <Typography.Text className='text-size-16 font-bold'>{attachment?.username}</Typography.Text>
                  <Typography.Text type='secondary' className='text-size-14'>
                    {dayjs(attachment.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Typography.Text>
                </Flex>
              </Flex>
            )}

            {attachment.comment && <CustomTooltip className='comment' title={attachment?.comment} />}
          </div>
        );
      })}
      {props?.children}
    </Flex>
  );
};

AttachmentList.defaultProps = {
  avatarSize: 34,
};

export default AttachmentList;
