import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Modal, Typography, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, SuccessMessage } from "components/common";
import { User } from 'model';
import { authService } from 'services';


interface ChangePasswordProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  profile: null | User;
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, setOpen, profile } = props;
  const [form] = Form.useForm();
  const newPassword = Form.useWatch('newPassword', form);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isOldPasswordWrong, setIsOldPasswordWrong] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setIsFormValid(false);
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      const dataSubmit = {
        email: profile?.email,
        username: profile?.username,
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      }
      console.log(dataSubmit)
      await authService.updatepProfile(dataSubmit);
      message.success('Password changed successfully');
      setIsOldPasswordWrong(false)
      setOpen(false);
    } catch (error) {
      console.error('Validation failed:', error);
      if ((error as Error)?.message === "Old password does not match") {
        setIsOldPasswordWrong(true)
      }
    }
  }

  const updateFormValidationStatus = async () => {
    if (newPassword && newPassword !== '') {
      const errors = form.getFieldError('newPassword');
      console.log(errors)
      const hasErrors = errors.length > 0;
      setIsFormValid(!hasErrors);
    }
    else {
      setIsFormValid(false);
    }
  };

  return (
    <Modal
      title={
        <Flex justify="flex-start">
          <LeftOutlined onClick={handleCancel} className="mr-3" />
          <Typography.Title level={2}>{t('Change password')}</Typography.Title>
        </Flex>
      }
      open={isOpen}
      width={500}
      footer={null}
      closeIcon={null}
      className="change-password-modal"
    >
      <Form
        layout={'vertical'}
        form={form}
        name="change-password-form"
        className=""
        onFinish={onFinish}
        onChangeCapture={updateFormValidationStatus}
      >
        <Form.Item
          label={t('Current Password')}
          className=" mb-10"
          name="oldPassword"
          hasFeedback
          rules={[{ required: true, message: <ErrorMessage message={t("Please enter your current password!")} />, },]}
          {...(isOldPasswordWrong && {
            help: isOldPasswordWrong && (<ErrorMessage message={t("The current password you have provided is incorrect")} />),
          })}
        >
          <Input.Password placeholder={t('Current Password')} className='py-0' />
        </Form.Item>

        <Form.Item
          label={t('New Password')}
          className=" mb-10"
          name="newPassword"
          hasFeedback
          rules={[
            { required: true, message: <ErrorMessage message={t("Please enter your current password!")} /> },
            () => ({
              validator(_, value) {
                if (!value || value.length < 8) {
                  return Promise.reject(
                    <ErrorMessage message={t("Password must be at least 8 characters long!")} />
                  );
                }
                return Promise.resolve();
              },
            }),
            () => ({
              validator(_, value) {
                if (!value || !/\d|[^A-Za-z0-9]/.test(value) || !/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
                  return Promise.reject(<ErrorMessage message={t("Password strength: weak")} />);
                }
                return Promise.resolve();
              },
            }),
            () => ({
              validator(_, value) {
                if (!value || /\d|[^A-Za-z0-9]/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  <ErrorMessage message={t("Contains a number or a symbol")} />
                );
              },
            }),
          ]}
          {...(isFormValid && {
            help: isFormValid && (<SuccessMessage message={t("Password strength: good")} />),
          })}
        >
          <Input.Password placeholder={t('New Password')} className='py-0' />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="mt-6"
            disabled={!isFormValid}
          // onClick={onFinish}
          >
            {t('Change password')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
