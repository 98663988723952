import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Form, Input, Popconfirm, Table, Divider, message, Flex, Breadcrumb, Layout } from 'antd';
import { DeleteOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { ProjectType, EditableRowProps, EditableCellProps } from 'model/DataCustomization';
import { ThunderboltOutlined } from '@ant-design/icons';
import picture from 'assets/images/pictures/_Image with Fixed Ratio_.png';
import { ThumbnailUpload } from 'components/common';
import { useMyWorkspaceProjectTypes, useMyWorkspaceProjectTypesParams } from 'hooks';
import { projectTypeService } from 'services';
import useAuthContext from 'store/auth-context';

const { Text, Title } = Typography;
const { Content } = Layout;

const EditableContext = React.createContext<FormInstance | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ children, ...props }) => {
  const [form] = Form.useForm();
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
          {React.Children.map(children, (child) => {
            if ((child as React.ReactElement).key === 'sort') {
              return React.cloneElement(child as React.ReactElement, {
                children: (
                  <HolderOutlined
                    ref={setActivatorNodeRef}
                    style={{
                      touchAction: 'none',
                      cursor: 'move',
                      color: 'grey',
                      fontSize: 20,
                    }}
                    {...listeners}
                  />
                ),
              });
            }
            return child;
          })}
        </tr>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleChangeShouldSubmit,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (values.errorFields) {
        handleChangeShouldSubmit(false);
      } else {
        handleChangeShouldSubmit(true);
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      handleChangeShouldSubmit(false);
    }
  };

  let childNode = children;
  if (editable && !record.isCore) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        initialValue={record[dataIndex]}
        rules={[
          {
            required: true,
            message: ``,
          },
        ]}
      >
        <Input className='project-text' ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export default function ProjectTypes({ workspaceId }: { workspaceId: string | undefined }) {
  const { profile } = useAuthContext();
  const { t } = useTranslation();
  const [projectTypeQuery] = useMyWorkspaceProjectTypesParams({
    workspaceId,
    limit: 'unlimited',
  });
  const [projectTypes, loading, refreshProjectTypes] = useMyWorkspaceProjectTypes(projectTypeQuery);
  const defaultProjectTypes = projectTypes
    .filter((projectType) => !projectType.isCustom)
    .map((projectType) => ({
      key: projectType.id,
      name: projectType.name,
      picture: projectType.picture,
      isCore: true,
    }));
  const customProjectTypes = projectTypes.filter((projectType) => projectType.isCustom);
  const [count, setCount] = useState(0);
  const [dataSource, setDataSource] = useState<ProjectType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tempNewRecords, setTempNewRecords] = useState<ProjectType[]>([]);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  useEffect(() => {
    setDataSource([
      ...customProjectTypes.map((projectType) => ({
        key: projectType.id,
        name: projectType.name,
        picture: projectType.picture,
        isCore: false,
      })),
      ...tempNewRecords,
    ]);
    setCount(customProjectTypes.length + 1);
    setTempNewRecords([]);
  }, [projectTypes, loading]);

  const handleDelete = async (key: string, isNewRecord?: boolean) => {
    if (isNewRecord) {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
      return;
    }
    try {
      if (!workspaceId) return;
      await projectTypeService.deleteProjectType(workspaceId, key);
      message.success(t('Deleted project type success!'));
      await refreshProjectTypes();
      setTempNewRecords(dataSource.filter((data) => data.isNewRecord));
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleAdd = () => {
    const newData: ProjectType = {
      key: count,
      name: `Hotel/Resort ${count}`,
      icon: ThunderboltOutlined,
      iconColor: '#737373',
      isCore: false,
      isNewRecord: true,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
    setShouldSubmit(true);
  };

  const handleSave = (row: ProjectType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    setShouldSubmit(true);
  };

  const normFileLogo = (e: { fileList: FileList }) => {
    console.log('Upload Logo:', e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleChangeShouldSubmit = (shouldSubmit: boolean) => {
    setShouldSubmit(shouldSubmit);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    handleChangeShouldSubmit?: (shouldSubmit: boolean) => void;
  })[] = [
    {
      width: '36px',
      key: 'sort',
      dataIndex: 'operation',
    },
    {
      title: t('Project type'),
      width: '440px',
      dataIndex: 'name',
      editable: true,
      className: 'p-inline-row',
      render: (text: string) => <Text className='project-text'>{text}</Text>,
    },
    {
      title: t('Thumbnail'),
      dataIndex: 'picture',
      align: 'center',
      width: '80px',
      className: 'thumbnail-header d-flex justify-center',
      render: (_, record) => {
        const handleChangeThumbnail = (imageUrl: string) => {
          setDataSource(
            dataSource.map((data) => ({
              ...data,
              ...(data.key === record.key ? { picture: imageUrl } : {}),
            }))
          );
          setShouldSubmit(true);
        };
        return (
          <Form.Item name='logo' valuePropName='logo' getValueFromEvent={normFileLogo} style={{ margin: 0 }}>
            <ThumbnailUpload
              record={{ ...record, picture: record.picture ?? picture }}
              onChange={handleChangeThumbnail}
              disabled={record.isCore}
            />
          </Form.Item>
        );
      },
    },
    // {
    //   dataIndex: 'picture',
    //   render: (_, record: any) => <ImgItem src={record.picture} alt={record.name} className="project-picture" />,
    // },
    {
      width: '40px',
      dataIndex: 'operation',
      align: 'right',
      render: (_, record) =>
        !record.isCore ? (
          <Popconfirm
            title={t('Delete this option?')}
            onConfirm={() => handleDelete(record.key, record?.isNewRecord)}
            okText={t('Yes')}
            cancelText={t('Cancel')}
          >
            <DeleteOutlined className='' style={{ color: 'grey', fontSize: 20 }} />
          </Popconfirm>
        ) : (
          <></>
        ),
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProjectType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
        handleChangeShouldSubmit,
      }),
    };
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const handleSubmitData = async () => {
    try {
      if (!workspaceId || !shouldSubmit) return;
      setIsLoading(true);
      const projectTypesData = dataSource.map((data) => ({
        ...(!data?.isNewRecord ? { id: data.key } : {}),
        name: data.name,
        picture: data.picture,
        userCreated: profile?.id,
        isCustom: !data.isCore,
      }));
      await projectTypeService.multipleCreateOrUpdate(workspaceId, {
        projectTypes: projectTypesData,
      });
      message.success(t('Updated project type success!'));
      await refreshProjectTypes();
      setShouldSubmit(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShouldSubmit(false);
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  return (
    <Content className='project-settings-common-page'>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Title>{t('Project Types')}</Title>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Flex className='setting-section-block'>
        <Flex justify='space-between' align='center'>
          <Title level={3} className={'mb-6'}>
            {t('Project types List')}
          </Title>
          <Button disabled={!shouldSubmit} onClick={handleSubmitData} loading={isLoading}>
            {t('Save')}
          </Button>
        </Flex>
        <div className='categories-container'>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => 'editable-row'}
            dataSource={defaultProjectTypes}
            columns={columns as ColumnTypes}
            pagination={false}
            showHeader={true}
            className='table-project-type'
          />
          <Divider />
          <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
              <Table
                components={{
                  body: {
                    row: EditableRow,
                    cell: EditableCell,
                  },
                }}
                loading={loading === 'pending'}
                rowKey='key'
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={columns as ColumnTypes}
                pagination={false}
                showHeader={false}
              />
            </SortableContext>
          </DndContext>
          <Button onClick={handleAdd} type='dashed' className='my-6' style={{ marginBottom: 16 }}>
            <PlusOutlined />
            {t('Add option')}
          </Button>
        </div>
      </Flex>
    </Content>
  );
}
