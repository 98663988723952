import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DeleteConfirmation } from 'components/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteButtonProps = {
  isDelete?: boolean;
  isDisabled?: boolean;
  onDelete: () => void;
  module: string;
};
const DeleteButton = (props: DeleteButtonProps) => {
  const { t } = useTranslation();
  const { onDelete, isDelete, isDisabled, module } = props;
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState<boolean>(false);
  return (
    <>
      <DeleteConfirmation
        isModalOpen={isDeleteConfirmation}
        title={module && module === 'Safety' ? t('Delete the inspection') : t('Delete the observation')}
        description={
          module && module === 'Safety'
            ? t('Are you sure to delete this inspection?')
            : t('Are you sure to delete this observation?')
        }
        onDelete={() => {
          setIsDeleteConfirmation(false);
          onDelete();
        }}
        onCancel={() => setIsDeleteConfirmation(false)}
      />
      <Button
        loading={isDelete}
        danger
        type='primary'
        icon={<DeleteOutlined />}
        disabled={isDisabled}
        onClick={() => setIsDeleteConfirmation(true)}
      ></Button>
    </>
  );
};
export default DeleteButton;
