import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, Tag, message } from 'antd';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { zoneService } from 'services';

export default function ZoningTitle(props: {
  nodeTitle?: string;
  nodeKey: string;
  zoneParent: string | undefined;
  nodeColor: string;
  workspaceId: string;
  projectId: string;
  order: number | undefined;
  refreshZone: () => void;
  fetchAttachments: () => void;
}) {
  const { nodeColor, nodeTitle, nodeKey, zoneParent, workspaceId, projectId, order, refreshZone, fetchAttachments } =
    props;
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [editedValue, setEditedValue] = useState(nodeTitle);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleSaveChanges = async (value: string) => {
    try {
      if (isAdd && editedValue) {
        const data: { name: string; order?: number | undefined; parentId?: string | null } = {
          name: value,
          order: order,
          parentId: nodeKey,
        };
        await zoneService.createZone(workspaceId, projectId, data);
        message.success(t('Add zone success!'));
      } else if (isEdit) {
        const data = { name: value };
        await zoneService.updateZone(workspaceId, projectId, nodeKey, data);
        message.success(t('Update zone success!'));
      }
      refreshZone();
      setIsEdit(false);
      setIsAdd(false);
      setIsHovered(false);
      setEnterPressed(false);
    } catch (error) {
      setIsAdd(false);
      setIsEdit(false);
      setIsHovered(false);
      setEnterPressed(false);
      message.error(t((error as Error).message));
    }
  };
  const onDelete = async () => {
    try {
      await zoneService.deleteZone(workspaceId, projectId, nodeKey);
      message.success(t('Delete zone success!'));
      refreshZone();
      fetchAttachments();
    } catch (error) {
      message.error(t((error as Error).message));
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, value: string) => {
    if (e.key === 'Enter' && !enterPressed) {
      e.preventDefault();
      setEnterPressed(true);
      handleSaveChanges(value);
    }
  };
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      message.error(t('Zone name cannot exceed 50 characters'));
    } else {
      setEditedValue(value);
    }
  };
  return (
    <Flex
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => (isAdd ? setIsHovered(true) : setIsHovered(false))}
      justify='space-between'
      min-width='400px'
    >
      {isEdit && nodeKey !== 'root' ? (
        <input
          value={editedValue || ''}
          onChange={handleChange}
          onBlur={(e) => handleSaveChanges(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, editedValue as string)} // Check for Enter key
          // maxLength={50}
          autoFocus
        />
      ) : (
        <div style={{ width: '500px' }}>
          {nodeKey.includes('-add-zone') ? (
            <Tag color={nodeColor} className='tag-dashed-btn' icon={<PlusOutlined />}>
              {nodeTitle}
            </Tag>
          ) : (
            <>
              <Tag
                onClick={() => {
                  setEditedValue(nodeTitle);
                  setIsEdit(true);
                }}
                color={nodeColor}
              >
                {nodeTitle}
              </Tag>
              {isHovered && (
                <>
                  {!isAdd ? (
                    <span
                      onClick={() => {
                        setIsEdit(false);
                        setIsAdd(true);
                        setEditedValue('');
                      }}
                    >
                      <PlusOutlined /> {t('Add sub zone')}
                    </span>
                  ) : (
                    <input
                      value={editedValue || ''}
                      onChange={handleChange}
                      onBlur={(e) => handleSaveChanges(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, editedValue as string)} // Check for Enter key
                      maxLength={50}
                      autoFocus
                    />
                  )}
                  {zoneParent ? <DeleteOutlined className='zoning-delete-outlined' onClick={() => onDelete()} /> : null}
                </>
              )}
            </>
          )}
        </div>
      )}
    </Flex>
  );
}
