import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip, Typography } from 'antd';
import { CSSProperties, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileService } from 'services';

export type TruncatedAttachmentDocumentProps = {
  filePath?: string;
  fileName?: string;
  hideDelete?: boolean;
  onRemoveFile?: () => void;
  openPreview?: boolean;
  id?: string;
  style?: CSSProperties;
};
const TruncatedAttachmentDocument = (props: TruncatedAttachmentDocumentProps) => {
  const pdfFileName = useMemo(() => {
    if (!props.fileName) return '';
    const hasPdfExtension = props.fileName.slice(props.fileName.lastIndexOf('.') + 1) === 'pdf';
    const currentFileName = hasPdfExtension ? props.fileName : `${props.fileName}.pdf`;
    return currentFileName;
  }, [props.fileName]);

  const containerRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const [truncatedName, setTruncatedName] = useState(pdfFileName);
  const [openTooltip, setOpenTooltip] = useState(false);

  const isOverflowText = () => {
    if (!containerRef.current) return false;
    return containerRef.current?.scrollWidth > containerRef.current?.offsetWidth;
  };

  useEffect(() => {
    if (isOverflowText()) {
      setOpenTooltip(true);
    }
  }, []);

  useEffect(() => {
    if (!props.fileName || !containerRef?.current) return;
    const extension = pdfFileName.slice(pdfFileName.lastIndexOf('.') + 1);

    const nameWithoutExtension = pdfFileName.substring(0, pdfFileName.length - extension.length - 1);

    if (isOverflowText()) {
      let maxNameLength = nameWithoutExtension.length;
      while (maxNameLength > 0) {
        if (isOverflowText()) {
          const truncated = `${nameWithoutExtension.substring(0, maxNameLength).trim()}[...].${extension}`;
          setTruncatedName(truncated);
          containerRef.current.textContent = truncated;
        } else {
          break;
        }
        maxNameLength--;
      }
    } else {
      setTruncatedName(pdfFileName);
    }
  }, [pdfFileName]);

  return (
    <div className='truncated-attachment-document' key={props.filePath} style={props?.style}>
      <FilePdfOutlined color='primary' className='text-primary' />
      <Tooltip title={openTooltip ? props.fileName : ''} placement='top'>
        <Typography.Link
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (props?.openPreview) {
              fileService.openFile(props?.filePath || '');
              return;
            }
            fileService.downloadFile(props?.filePath || '', `${props?.fileName}.pdf`);
          }}
          ref={containerRef}
          className='file-name'
        >
          {truncatedName}
        </Typography.Link>
      </Tooltip>
      {!props.hideDelete && (
        <Popconfirm
          title={t('Delete this option?')}
          onConfirm={props?.onRemoveFile}
          okText={t('Yes')}
          cancelText={t('Cancel')}
        >
          <DeleteOutlined className='cursor-pointer ml-auto' />
        </Popconfirm>
      )}
    </div>
  );
};

export default TruncatedAttachmentDocument;
