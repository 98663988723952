// src/utils/treeUtils.ts
import React from 'react';
import { Tag } from 'antd';

export const findPath = (nodes: any, value: string): string => {
  let path: string[] = [];
  const search = (nodes: any, value: string): boolean => {
    for (const node of nodes) {
      if (node.value === value) {
        path = [node.title];
        return true;
      }
      if (node.children) {
        const found = search(node.children, value);
        if (found) {
          path = [...path, node.title];
          return true;
        }
      }
    }
    return false;
  };

  search(nodes, value);

  return path.reverse().join(' ▶ ');
  //  if (path.length <= 1) {
  //   return path.join(' ▶ '); 
  // } else {
  //   if (path.length > 1 && path[0]) {
  //     return path.reverse().slice(1).join(' ▶ ');
  //   }
  //   return path.join(' ▶ ');
  // }
};

export const CustomTagRender = (props: any, zonesTreeData: any) => {
  const { label, value, closable, onClose } = props;
  const path = findPath(zonesTreeData, value);  
  
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 29, 61, 0.06)',
        border: '1px solid rgba(0, 29, 61, 0.06)',
        borderRadius: '2px',
        padding: '0 8px',
        margin: '1px',
        color: 'rgba(0, 29, 61, 0.85)',
        fontSize:'14px'
      }}
      closeIcon={<span style={{ color: 'rgba(0, 29, 61, 0.45)' }}>&#10005;</span>}
    >
      {path || label}
    </Tag>
  );
};
