import { useEffect, useState } from 'react';
import { Modal, Form, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomModalContent from './CustomModal';
import CustomFooter from './MDIsValidationFooter';
import { BaseIssue, User } from 'model';
import { baseValidationService } from 'services';
import useUserEntity from 'hooks/common/useUserProfile';
import { WIRFileUpload } from 'components/project-work-inspection-request/CreateOrUpdateWIR/WorkInspectionRequestDetail';
import { QuestionCircleOutlined } from '@ant-design/icons';

type MDIsValidationProps = {
  isModalOpen: boolean;
  isCreate: boolean;
  selectedMdiBaseIssue: BaseIssue | null;
  profile: User | null;
  workspaceId: string;
  projectId: string;
  setIsModalOpen: (isOpen: boolean) => void;
  onClose: () => void;
};

export default function MDIsValidation(props: MDIsValidationProps) {
  const { isModalOpen, selectedMdiBaseIssue, profile, workspaceId, projectId, setIsModalOpen, onClose } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [current] = useState(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [overallCompliancy, setOverallCompliancy] = useState<'YES' | 'NO' | null>(null);
  // const [value, setValue] = useState('validate');
  const [isOpen, setIsOpen] = useState<boolean>(true);
  // const onChange = (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };
  const { user } = useUserEntity({ projectId, workspaceId });
  const [selected, setSelected] = useState<Record<string, string>>({
    technicalDocumentCompliancy: '',
    quantityCompliancy: '',
    physicalConditionCompliancy: '',
    overallCompliancy: '',
  });
  const [attachments, setAttachments] = useState<WIRFileUpload[]>([]);
  const [attachmentPhotos, setAttachmentPhotos] = useState<WIRFileUpload[]>([]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setOverallCompliancy(null);
    setIsOpen(false);
    setIsDisabled(true);
    setAttachments([]);
    setAttachmentPhotos([]);
  };
  const convertCompliancy = (value: string) => {
    switch (value) {
      case 'YES':
        return true;
      case 'NO':
        return false;
      case 'N/A':
        return null;
      default:
        return null;
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const baseValidation = (selectedMdiBaseIssue?.BaseValidations || []).find(
        (val) => val.validatorId === profile?.id
      );
      if (selectedMdiBaseIssue && baseValidation) {
        const attachmentResult = [...attachments].map((attachment) => ({ ...attachment }));
        const values = form.getFieldsValue();
        const submitData = {
          ...baseValidation,
          comment: values.comment,
          mdiValidation: {
            technicalDocumentCompliancy: convertCompliancy(values.technicalDocumentCompliancy),
            quantityCompliancy: convertCompliancy(values.quantityCompliancy),
            physicalConditionCompliancy: convertCompliancy(values.physicalConditionCompliancy),
            overallCompliancy: convertCompliancy(values.overallCompliancy),
            inspectionTime: new Date(),
            attachmentsInfo: attachmentResult.concat(attachmentPhotos),
          },
        };
        await baseValidationService.updateBaseValidation(
          workspaceId,
          projectId,
          selectedMdiBaseIssue.id,
          baseValidation.id,
          submitData
        );
        setLoading(false);
        setIsModalOpen(false);
        form.resetFields();
        onClose();
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };
  const handleOverallCompliancyChange = (value: 'YES' | 'NO') => {
    setOverallCompliancy(value);
    setIsDisabled(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      setIsOpen(true);
    }
  }, [overallCompliancy]);
  useEffect(() => {
    form.resetFields();
    setOverallCompliancy(null);
    setIsOpen(false);
    setIsDisabled(true);
    setAttachments([]);
    setAttachmentPhotos([]);
    setSelected({
      technicalDocumentCompliancy: '',
      quantityCompliancy: '',
      physicalConditionCompliancy: '',
      overallCompliancy: '',
    });
  }, [isModalOpen]);
  const showConfirm = (overallCompliancy: string | null) => {
    Modal.confirm({
      title: t('Do you confirm?'),
      content: (
        <span>
          {t('You are going to')}{' '}
          <span style={{ color: overallCompliancy === 'YES' ? '#237804' : '#CF1322' }}>
            {overallCompliancy === 'YES' ? t('approve') : t('reject')}
          </span>{' '}
          {t('the inspection')}
        </span>
      ),
      icon:
        overallCompliancy === 'YES' ? (
          <QuestionCircleOutlined style={{ color: '#237804' }} />
        ) : (
          <QuestionCircleOutlined style={{ color: '#CF1322' }} />
        ),
      okText: t('Confirm'),
      cancelText: t('Cancel'),
      okButtonProps: {
        style: {
          backgroundColor: overallCompliancy === 'YES' ? '#237804' : '#CF1322',
          color: 'white',
        },
      },
      className: 'mdi-custom-popup-confirm',
      cancelButtonProps: {
        style: {
          color: 'gray',
        },
      },
      width: 450,
      onOk() {
        if (overallCompliancy) {
          handleSubmit();
        }
      },
    });
  };
  return (
    <>
      <Modal
        className={`validation-modal ${overallCompliancy === 'YES' ? 'validation-modal-yes' : overallCompliancy === 'NO' ? 'validation-modal-no' : ''}`}
        width={629}
        title={<span className='modal-title'>{t('Review and Approval Form')}</span>}
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        footer={
          <CustomFooter
            current={current}
            isDisabled={isDisabled}
            loading={loading}
            handleCancel={handleCancel}
            handleSubmit={() => showConfirm(overallCompliancy)}
          />
        }
      >
        <Form form={form}>
          <Spin spinning={loading}>
            <CustomModalContent
              setSelected={setSelected}
              onOverallCompliancyChange={handleOverallCompliancyChange}
              form={form}
              isOpen={isOpen}
              setAttachments={(value: WIRFileUpload[]) => setAttachments(value)}
              isModalOpen={isModalOpen}
              selectedMdiBaseIssue={selectedMdiBaseIssue}
              attachments={attachments}
              user={user}
              setAttachmentPhotos={setAttachmentPhotos}
              attachmentPhotos={attachmentPhotos}
              selected={selected}
            />
          </Spin>
        </Form>
      </Modal>
    </>
  );
}
