import { createSelector } from '@reduxjs/toolkit';
import { useFetchByParams, useQueryParams } from 'hooks';
import { BaseIssue, WorkBreakdownStructure } from 'model';
import BaseIssueMomCategory from 'model/BaseIssueMomCategory';
import { useMemo } from 'react';
import { RootState } from 'store';
import {
  fetchProjectBaseIssues,
  fetchProjectCategoryByFunction,
  fetchProjectDisciplineByFunction,
  selectProjectCategoriesByFunction,
  selectProjectCategoriesByFunctionLoading,
  selectProjectDisciplinesByFunction,
  selectProjectDisciplinesByFunctionLoading,
  selectProjectModules,
} from 'store/my-projects.slice';
import { QueryParams } from 'types';
import { EModuleName } from 'utils/my-project';

const selectProjectBaseIssues = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.baseIssues;

export const selectProjectBaseIssuesCount = (module?: EModuleName) =>
  createSelector([selectProjectModules], (projectByModule) => {
    return projectByModule?.[module as EModuleName]?.baseIssueCount;
  });

export const selectProjectBaseIssuesKpiData = (module?: EModuleName) =>
  createSelector([selectProjectModules], (projectByModule) => projectByModule?.[module as EModuleName]?.baseIssueKpi);

export const selectProjectBaseIssuesLoading = (state: RootState, projectId: string, module?: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module as string]?.baseIssuesLoading;

export const selectProjectBaseIssuesKpi = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.baseIssueKpi;

export type ProjectBaseIssuesParamsType = {
  workspaceId: string;
  projectId: string;
  baseIssueId?: string;
} & QueryParams;

export const useProjectBaseIssuesParams = (
  options: ProjectBaseIssuesParamsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [query: ProjectBaseIssuesParamsType, onParamChange: any] => {
  const [params, onParamChange] = useQueryParams<QueryParams>(true);
  const { ...option } = options;

  const filteredParams = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tab, selectedZone, documentTransmissionId, ...restParams } = params;

    return { ...option, ...restParams };
  }, [params]);

  return [filteredParams, onParamChange];
};

export const useProjectBaseIssueMARsParams = (options: ProjectBaseIssuesParamsType) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useProjectBaseIssues = (query: any) => {
  const { projectId, module } = query;
  return useFetchByParams<BaseIssue[]>({
    action: fetchProjectBaseIssues,
    dataSelector: (state: RootState) => selectProjectBaseIssues(state, projectId, module),
    loadingSelector: (state: RootState) => selectProjectBaseIssuesLoading(state, projectId, module),
    params: query,
  });
};

export const useFetchProjectDisciplineByFunctionParams = (options: {
  workspaceId: string;
  projectId: string;
  type: string;
}) => {
  const [query] = useQueryParams<QueryParams>();
  const { workspaceId, projectId } = options;
  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited', orderBy: 'order,asc' };
    return [queryParams];
  }, [workspaceId, projectId]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetchProjectDisciplineByFunction = (query: any) => {
  return useFetchByParams<WorkBreakdownStructure[]>({
    action: fetchProjectDisciplineByFunction,
    dataSelector: selectProjectDisciplinesByFunction,
    loadingSelector: selectProjectDisciplinesByFunctionLoading,
    params: query,
  });
};
export const useFetchProjectCategoryByFunctionParams = (options: { workspaceId: string; projectId: string }) => {
  const [query] = useQueryParams<QueryParams>();
  const { workspaceId, projectId } = options;
  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited', orderBy: 'order,asc' };
    return [queryParams];
  }, [workspaceId, projectId]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetchProjectCategoryByFunction = (query: any) => {
  return useFetchByParams<BaseIssueMomCategory[]>({
    action: fetchProjectCategoryByFunction,
    dataSelector: selectProjectCategoriesByFunction,
    loadingSelector: selectProjectCategoriesByFunctionLoading,
    params: query,
  });
};
