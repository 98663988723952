import { ReactNode } from 'react';
import { Layout } from 'antd';
import { Footer } from 'components';
const { Content } = Layout;

const VoidSettingLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout hasSider>
      <Layout
        style={{
          marginLeft: 0,
          transition: 'all 0.25s',
          background: '#EAF0F9',
        }}
      >
        <Content className='main-content'>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default VoidSettingLayout;
