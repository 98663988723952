import { Flex, Space, StepProps, Steps, Tag, Typography } from 'antd';
import { ValidationHistory } from 'model';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATION_STATUS } from 'utils/contants';
import marIconInProgress from 'assets/images/icons/mar-in-progress-icon-blue.png';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelector } from 'react-redux';
import { selectProjectUsers } from 'store/my-projects.slice';
import TruncatedAttachmentDocument from 'components/common/TruncatedAttachmentDocument';

type MARsHistoryProps = {
  companyHistories: ValidationHistory[];
};

export default function MARsHistory(props: MARsHistoryProps) {
  const { companyHistories } = props;
  const { t } = useTranslation();
  const form = useFormInstance();
  const distributions = form.getFieldValue('watcherIds');
  const users = useSelector(selectProjectUsers);
  // const [attachmentTypeQuery] = useAttachmentTypesParams();
  // const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  // const marDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MAR_DOCUMENT);
  const formatItems = useMemo(() => {
    const stepItems: StepProps[] | undefined = [];

    companyHistories.forEach((companyHistories) => {
      stepItems.push({
        status: `${
          companyHistories.status === VALIDATION_STATUS.REJECTED
            ? 'error'
            : companyHistories.status === VALIDATION_STATUS.TRANSFERRED
              ? 'process'
              : companyHistories.status === VALIDATION_STATUS.IN_REVIEW
                ? 'wait'
                : 'finish'
        }`,
        icon: (
          <Space>
            {companyHistories.status === VALIDATION_STATUS.IN_REVIEW && (
              <img width={32} src={marIconInProgress} alt={companyHistories.status} />
            )}
            {companyHistories.status === VALIDATION_STATUS.VALIDATED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.SUBMITTED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.APPROVED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.REJECTED && (
              <CloseOutlined style={{ padding: 10 }} className='comments__collapse-icon rejected' />
            )}
            {companyHistories.status === VALIDATION_STATUS.TRANSFERRED && (
              <div className='comments__collapse-icon transferred' />
            )}
          </Space>
        ),
        title: <div className={`status-${companyHistories.status}`}>{companyHistories.company}</div>,

        description: (
          <div>
            {companyHistories.comments.map((comment, index) => {
              return (
                <div className={'mb-1'} key={index}>
                  <Typography.Text
                    style={{ fontWeight: 'bold' }}
                    className={`comments__steps-status ${comment.status.code} `}
                  >
                    {' '}
                    {comment.status.code === 'transferred' && 'Reviewed and Forwarded'}
                    {comment.status.code === 'in-review' && 'Review in Progress'}
                    {comment.status.code === 'submitted' && 'Submitted'}
                    {comment.status.code === 'validated' && 'Approve and forward'}
                    {comment.status.code === 'approved'
                      ? comment.pinComment
                        ? 'APPROVED WITH COMMENTS'
                        : 'APPROVED'
                      : ''}
                    {comment.status.code === 'rejected' && 'REJECTED'}{' '}
                  </Typography.Text>
                  <Typography.Text>{`${t('by')} `}</Typography.Text>
                  <Typography.Text style={{ fontWeight: 'bold' }}>{`${comment.user}`}</Typography.Text>
                  <Typography.Text
                    type='secondary'
                    style={{
                      color: `${
                        comment.status.name === 'rejected'
                          ? 'red'
                          : comment.status.name === VALIDATION_STATUS.APPROVED ||
                              (comment.status.name === VALIDATION_STATUS.VALIDATED && index === 0)
                            ? 'green'
                            : '#747577'
                      }`,
                    }}
                  >
                    {' '}
                    {comment.date}
                  </Typography.Text>
                  <br />
                  <Typography.Text
                    className={`${comment?.pinComment && (comment?.comment || '').trim().length != 0 ? 'approved-with-comment ' : 'pt-1'}`}
                  >
                    {' '}
                    {comment.comment}{' '}
                  </Typography.Text>
                  {comment.attachment && comment.attachment.length > 0 && (
                    <Flex gap={10} wrap>
                      {comment.attachment?.map((attachmentDocument) => {
                        return (
                          <TruncatedAttachmentDocument
                            key={attachmentDocument?.filePath}
                            fileName={attachmentDocument?.name}
                            filePath={attachmentDocument?.filePath}
                            hideDelete={true}
                          />
                        );
                      })}
                    </Flex>
                  )}

                  {comment.status.code === 'submitted' && (
                    <div>
                      {distributions.length > 0 && (
                        <Typography.Title className='text-size-12 font-bold mt-2 mb-2'>
                          {t('Distribution')}
                        </Typography.Title>
                      )}
                      {distributions?.map((userId: string) => {
                        const selectedUser = users.find((user) => user.id === userId);
                        return <Tag key={userId}>{selectedUser?.fullName}</Tag>;
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ),
      });
    });
    return stepItems;
  }, [companyHistories]);

  return (
    <div className='comment-container'>
      <Steps direction='vertical' className={'comment-steps-container comments__steps'} items={formatItems} />
    </div>
  );
}
