import { useMemo } from 'react';
import { QueryParams } from 'types';
import { IssueType } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchIssueTypes, selectIssueTypes } from 'store/common.slice';

export const useIssueTypesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, []);
};

export const useIssueTypes = (query: QueryParams) => {
  return useFetchByParams<IssueType[]>({
    action: fetchIssueTypes,
    dataSelector: selectIssueTypes,
    params: query,
  });
};
