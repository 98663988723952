import { DeleteOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Modal,
  ModalProps,
  Row,
  Form,
  Input,
  Typography,
  Checkbox,
  GetProp,
  Divider,
  message,
  Select,
  Radio,
  Space,
  RadioChangeEvent,
  Tag,
  TreeSelect,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DataNode } from 'antd/es/tree';
import { CustomizeRequiredMark, CustomTagRender, DatePickerRange, SearchSelect } from 'components';
import dayjs from 'dayjs';
import {
  useMyWorkspaceWorkPackagesParams,
  useProjectWorkPackages,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { Company, IssueStatus, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import baseIssueService from 'services/base-issue.services';
import fileService from 'services/file.service';
import { useAppSelector } from 'store';
import { selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { TableRowsSelected } from 'types';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import { convertZoneToTreeData } from 'utils';
import { WIR_STATUS_COLOR, WIR_STATUSES } from 'utils/contants';
const { Title, Text } = Typography;
const Option = Select.Option;

const NEW_ITEM = 'CREATE_NEW_ITEM';

type GenerateReportsProps = {
  isGenerateReports: boolean;
  profile: User | null;
  users: User[];
  module: string;
  workspaceId: string;
  projectId: string;
  companies: Company[];
  issueStatuses: IssueStatus[];
  selectedRowKeys: TableRowsSelected;
  isOwnerCompany: boolean;
  setIsGenerateReports: (value: boolean) => void;
} & ModalProps;
export default function GenerateReports(props: GenerateReportsProps) {
  const {
    isGenerateReports,
    setIsGenerateReports,
    profile,
    module,
    workspaceId,
    projectId,
    users,
    companies,
    issueStatuses,
    selectedRowKeys,
    isOwnerCompany,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [orderBy, setOrderBy] = useState<{ key: string; direction: string }[]>([
    {
      key: 'createdAt',
      direction: 'DESC',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const [workPackagesQuery] = useMyWorkspaceWorkPackagesParams({
    projectId,
    workspaceId,
  });

  const [workPackages] = useProjectWorkPackages(workPackagesQuery);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [groupIssues, setGroupIssues] = useState('all');
  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [isGenerateReports, selectedCompanyId]);
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const handleSortChange = (index: number, sortBy: string, order: string) => {
    const newOrderBy = [...orderBy];
    newOrderBy[index] = { key: sortBy, direction: order };
    setOrderBy(newOrderBy);
  };

  const handleDeleteSort = (index: number) => {
    const newOrderBy = [...orderBy];
    newOrderBy.splice(index, 1);
    setOrderBy(newOrderBy);
  };
  const defaultReportName = useMemo(
    () => `${module} ${t('Work Inspection')} - Report ${dayjs().format('DD/MM/YYYY').toString()}`,
    [isGenerateReports]
  );
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const defaultStatusIds = issueStatuses
    .filter((issue) => issue.code === WIR_STATUSES.REVIEW_IN_PROGRESS || issue.code === WIR_STATUSES.APPROVED)
    .map((issue) => issue.id);
  const rowKeys: React.Key[] = Object.values(selectedRowKeys).flat();

  function handleCancel(): void {
    form.resetFields();
    setOrderBy([
      {
        key: 'createdAt',
        direction: 'DESC',
      },
    ]);
    setIsLoading(false);
    setIsGenerateReports(false);
    setIsDisable(false);
    setSelectedCompanyId('');
    setGroupIssues('all');
    setDisableGroup(false);
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const fields: { [key: string]: boolean } = {};
      checkedValues.checkedOptions1.forEach((value) => (fields[value] = true));
      checkedValues.checkedOptions2.forEach((value) => (fields[value] = true));

      const data = {
        reportName: form.getFieldValue('reportName'),
        reportCode: form.getFieldValue('code'),
        createdBy: form.getFieldValue('createdBy'),
        userCreated: profile?.id,
        reportType: 'detail',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // inspectionDateFrom: dateRange? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
        // inspectionDateTo: dateRange? dayjs(dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : undefined,
        // module: module,
        orderBy: orderBy,
        executiveSummary: form.getFieldValue('executiveSummary'),
        fields: fields,
        emails: form.getFieldValue('emails'),
        baseIssueIds: rowKeys.length > 0 ? rowKeys : undefined,
      };

      // Filters
      const values = await form.getFieldsValue();
      let CompanyOriginatorId = undefined;
      let originatorId = undefined;
      let watcherId = undefined;

      if (values.groupIssues === 'myBaseIssue') {
        originatorId = `${profile?.id}`;
      } else if (values.groupIssues === 'myCompany' && userCompanyId) {
        CompanyOriginatorId = `${userCompanyId}`;
      } else if (values.groupIssues === 'watched' && profile?.id) {
        watcherId = `${profile?.id}`;
      } else {
        if (values.CompanyOriginatorId) {
          CompanyOriginatorId = `${values.CompanyOriginatorId}`;
        }
        if (values.originatorId) {
          originatorId = `${values.originatorId}`;
        }
      }
      const disciplineId =
        values.disciplineId && values.disciplineId.length > 0
          ? `[${'"' + values.disciplineId.join('","') + '"'}]`
          : undefined;
      const issueStatusId =
        values.issueStatusId && values.issueStatusId.length > 0
          ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
          : undefined;
      const zoneId =
        values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;
      const workPackageId =
        values.workPackageId && values.workPackageId.length > 0
          ? `[${'"' + values.workPackageId.join('","') + '"'}]`
          : undefined;
      const params = {
        'createdAt[gte]':
          values.startDate && values.startDate[0]
            ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
            : undefined,
        'createdAt[lte]':
          values.startDate && values.startDate[1]
            ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
            : undefined,
        'workPackageId[]': workPackageId,
        'statusId[]': issueStatusId,
        'zoneId[]': zoneId,
        'disciplineId[]': disciplineId,
        originatorId: originatorId,
        companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : CompanyOriginatorId,
        filterType: values.groupIssues,
        type: 'WIR',
        watcherId: watcherId,
        include:
          'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments',
      };
      const reportResponse = await baseIssueService.getReportPdf(workspaceId, projectId, data, params);
      await fileService.downloadFile(reportResponse.url, `${data.reportName}.pdf`);

      setOrderBy([
        {
          key: 'createdAt',
          direction: 'DESC',
        },
      ]);
      setIsLoading(false);
      setIsGenerateReports(false);
      setSelectedCompanyId('');
      setGroupIssues('all');
      setDisableGroup(false);
      setIsDisable(false);
      message.success(t('Your report has been generated!'));
    } catch (error) {
      console.log(error);
      message.error(t(`${(error as Error).message}`));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isGenerateReports) {
      form.resetFields();
      form.setFieldValue('createdBy', profile?.fullName);
      form.setFieldValue('reportName', defaultReportName);
      form.setFieldValue('issueStatusId', defaultStatusIds);
      setCheckedValues({
        checkedOptions1: optionalOptions.options1.map((item) => item.value),
        checkedOptions2: optionalOptions.options2.map((item) => item.value),
      });
      form.setFieldValue('startDate', [dayjs().subtract(15, 'day'), dayjs()]);
      form.setFieldValue('groupIssues', groupIssues);
    }
  }, [isGenerateReports]);

  const mandatoryOptions = [
    { label: t('WIR name'), value: 'name' },
    { label: t('Company Originator'), value: 'CompanyOriginatorId' },
    { label: t('Status'), value: 'status' },
    { label: t('Work Package'), value: 'WorkPackage' },
    { label: t('WIR Number'), value: 'WorkInspectionRequest.wirNumber' },
  ];

  const optionalOptions = {
    options1: [
      { label: t('Discipline  '), value: 'discipline' },
      { label: t('Sub-activity'), value: 'subActivity' },
      { label: t('Zoning'), value: 'zone' },
    ],
    options2: [
      { label: t('Activity  '), value: 'activity' },
      { label: t('Control point'), value: 'controlPoint' },
    ],
  };

  const [checkedValues, setCheckedValues] = useState<{
    checkedOptions1: string[];
    checkedOptions2: string[];
  }>({
    checkedOptions1: optionalOptions.options1.map((item) => item.value),
    checkedOptions2: optionalOptions.options2.map((item) => item.value),
  });

  const fieldOptions = [
    { label: t('WIR name'), value: 'name' },
    { label: t('Company Originator'), value: 'Company.name' },
    { label: t('Status'), value: 'IssueStatus.orderNumber' },
    { label: t('Originator'), value: 'originator' },
    { label: t('Validator'), value: 'validator' },
    { label: t('Zoning'), value: 'Zone.name' },
    { label: t('WIR Number'), value: 'WorkInspectionRequest.wirNumber' },
  ];

  const orderOptions = [
    { label: t('Ascending'), value: 'ASC' },
    { label: t('Descending'), value: 'DESC' },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkBoxCustom = (label: string, key: any) => {
    return (
      <div className='checkbox-wrapper' key={key}>
        <label style={{ display: 'flex' }}>
          <span style={{ opacity: 0.5 }} className='mr-2'>
            <LockOutlined />
          </span>
          <span style={{ opacity: 0.5, minWidth: '90%' }}>{label}</span>
        </label>
      </div>
    );
  };

  const onChangeOptions1: GetProp<typeof Checkbox.Group, 'onChange'> = (values) => {
    setCheckedValues({
      ...checkedValues,
      checkedOptions1: values as string[],
    });
  };

  const onChangeOptions2: GetProp<typeof Checkbox.Group, 'onChange'> = (values) => {
    setCheckedValues({ ...checkedValues, checkedOptions2: values as string[] });
  };

  const onSelectAll = () => {
    setCheckedValues({
      checkedOptions1: optionalOptions.options1.map((item) => item.value),
      checkedOptions2: optionalOptions.options2.map((item) => item.value),
    });
  };

  const onClearAll = () => {
    setCheckedValues({ checkedOptions1: [], checkedOptions2: [] });
  };

  const handleChange = () => {
    setInputValue('');
  };

  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setGroupIssues(e.target.value);
    setDisableGroup(e.target.value === 'all' ? false : true);
    form.setFieldValue('originatorId', null);
    form.setFieldValue('CompanyOriginatorId', null);
  };

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('originatorId', null);
  };

  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = users?.find((user) => user.id === assigneeId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    form.setFieldValue(['CompanyOriginatorId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onFormValuesChange = () => {
    const checkFormValues = async () => {
      const reportName = form.getFieldValue('reportName');

      if (reportName) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    };
    checkFormValues();
  };
  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
      setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);

  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(zoneId);
  };

  const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
    return data?.map((value) => ({
      value: value.id,
      label: value[labelKey as keyof typeof value],
    }));
  };
  return (
    <Modal
      title={
        <Typography.Text style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }} className='text-uppercase'>
          {t('New Report')}
        </Typography.Text>
      }
      open={isGenerateReports}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button loading={isLoading} onClick={handleSubmit} disabled={isDisable} key='submit' type='primary'>
          {t('Generate report')}
        </Button>,
      ]}
      width={723}
    >
      <div className='generate-report-form'>
        <Form
          form={form}
          name='report'
          layout='vertical'
          requiredMark={CustomizeRequiredMark}
          initialValues={{
            mandatory: mandatoryOptions,
            optional: optionalOptions,
            field: fieldOptions,
            order: orderOptions,
            // reportType: 'detail',
          }}
          onValuesChange={onFormValuesChange}
        >
          {/* <Title level={2}>{t('Report type')}</Title>
          <Form.Item name='reportType'>
            <Radio.Group style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Radio value={'basic'}>Basic Report</Radio>
              <Radio  value={'detail'}>
                Detailed Report
              </Radio>
              <Radio  value={'summary'}>
                Summary Report
              </Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item
            name={'reportName'}
            label={t('Report name')}
            rules={[
              { required: true, message: '' },
              {
                max: 50,
                message: t('Report name cannot exceed 50 characters'),
              },
            ]}
          >
            <Input
              count={{
                show: true,
                max: 50,
              }}
              allowClear
              placeholder={t('Please enter')}
            />
          </Form.Item>
          <Row gutter={24} className='mb-0'>
            <Col span={12}>
              <Form.Item name={'createdBy'} label={t('Created by')} rules={[{ required: true, message: '' }]}>
                <Input disabled={true} placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'code'} label={t('Code')}>
                <Input placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={'executiveSummary'} label={t('Executive summary')}>
            <TextArea placeholder={t('Please enter')} />
          </Form.Item>
          <Form.Item name={'emails'} label={t('Recipients list')}>
            <Select
              mode='tags'
              showSearch
              allowClear
              maxTagCount='responsive'
              placeholder={t('Please select')}
              onSearch={(e) => setInputValue(e)}
              onChange={handleChange}
              optionLabelProp='label'
              filterOption={false}
            >
              {users
                ?.filter(
                  (user) =>
                    !inputValue ||
                    user?.email?.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                    user?.fullName?.toString().toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((user) => (
                  <Option key={user.id} value={user.email} label={user.email}>
                    <div className='custom-select-input-user'>
                      <div className='text-circle'>{user.email?.charAt(0).toUpperCase()}</div>
                      <div className='ml-2'>
                        <div className='ttl'>{user.fullName}</div>
                        <div className='desc'>{user.email}</div>
                      </div>
                    </div>
                  </Option>
                ))}

              {inputValue && users?.filter((o) => o.fullName === inputValue).length === 0 && (
                <Option key={NEW_ITEM} value={inputValue} className='invite-option'>
                  {`+ ${t('Send to')} ${inputValue}`}
                </Option>
              )}
            </Select>
          </Form.Item>
          {/* <Row gutter={16} align='bottom'>
            <Col span={24}>
              <Form.Item
                name='plannedStartDate'
                label={t('Inspection date range')}
              >
                <DatePickerRange style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row> */}
          {rowKeys.length <= 0 && (
            <>
              <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
              <Title level={2}>{t('Filters')}</Title>
              <Row gutter={16} align='stretch' className='mb-0'>
                <Col span={12}>
                  <Form.Item name='groupIssues' initialValue={groupIssues}>
                    <Radio.Group onChange={onChangeGroupIssues}>
                      <Space direction='vertical'>
                        <Radio style={{ lineHeight: '2.4' }} value='all'>
                          {t('All WIRs')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='myBaseIssue'>
                          {t('My WIRs')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='myCompany'>
                          {t('My company WIRs')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='watched'>
                          {t('Watched WIRs')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className='mb-0'>
                  <Form.Item
                    name='CompanyOriginatorId'
                    label={t('Company Originator')}
                    // rules={[
                    //   { required: true, message: '' },
                    // ]}
                  >
                    <SearchSelect
                      disabled={isDisableGroup}
                      allowClear
                      placeholder={t('Select')}
                      onChange={onChangeCompany}
                      options={companies?.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item name='originatorId' label={t('Originator')}>
                    <SearchSelect
                      disabled={isDisableGroup}
                      allowClear
                      placeholder={t('Select')}
                      onChange={onChangeAssignee}
                      options={assigneeFilter?.map((option) => ({
                        value: option.id,
                        label: option.fullName,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={24}>
                  <Form.Item name='startDate' label={t('Work Inspection Requests date range')}>
                    <DatePickerRange style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={12}>
                  <Form.Item name='workPackageId' label={t('Work Package')}>
                    <SearchSelect
                      allowClear
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={workPackages?.map((option) => ({
                        value: option.id,
                        label: t(option.name),
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='disciplineId'
                    label={t('Discipline')}
                    // rules={[{ required: true, message: 'Please select discipline' }]}
                  >
                    <SearchSelect
                      placeholder={t('Select discipline')}
                      mode='multiple'
                      maxTagCount='responsive'
                      options={mapDataToOptions(disciplines)}
                      breakText
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={12}>
                  <Form.Item
                    name='zoneId'
                    className='filter-form__item'
                    label={t('WIR Zoning')}
                    rules={[{ required: false, message: '' }]}
                  >
                    <TreeSelect
                      multiple
                      maxTagCount='responsive'
                      style={{ width: '100%' }}
                      value={zoneUpdated}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={zonesTreeData}
                      placeholder={t('Select Zoning')}
                      treeDefaultExpandAll
                      onChange={onChangeZone}
                      tagRender={(props) => CustomTagRender(props, zonesTreeData)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='issueStatusId'
                    label={t('Status')}
                    // rules={[
                    //   { required: true, message: '' },
                    // ]}
                  >
                    <Select
                      allowClear
                      // showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issueStatuses
                        ?.filter((option) => option.code !== 'WIR_TBR')
                        .map((option) => ({
                          value: option.id,
                          label: (
                            <Tag className={option.code} color={WIR_STATUS_COLOR[option.code]}>
                              {t(option.name)}
                            </Tag>
                          ),
                        }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
          <div>
            <Title level={2}>{t('Fields')}</Title>
            <Row gutter={24}>
              <Col span={7}>
                <Text>{t('Mandatory')}</Text>
                <div className='mt-3'>
                  {mandatoryOptions?.map((option, index) => checkBoxCustom(option.label, index))}
                </div>
              </Col>
              <Col className='px-0' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Divider type='vertical' style={{ height: '100%' }} />
              </Col>
              <Col span={16}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text>{t('Optional')}</Text>
                  <Button onClick={() => onSelectAll()} className='select-all-btn' style={{ right: 62 }}>
                    {t('Select All')}
                  </Button>
                  <Text className='select-all-btn' style={{ right: 50 }}>
                    -
                  </Text>
                  <Button
                    onClick={() => onClearAll()}
                    className='select-all-btn'
                    style={{
                      position: 'absolute',
                      right: 0,
                      marginRight: '12px',
                    }}
                  >
                    {t('Clear')}
                  </Button>
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className='mt-3'>
                      <Checkbox.Group
                        name='optional'
                        options={optionalOptions.options1}
                        onChange={onChangeOptions1}
                        value={checkedValues.checkedOptions1}
                        className='check-box-column'
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='mt-3'>
                      <Checkbox.Group
                        name='optional'
                        options={optionalOptions.options2}
                        onChange={onChangeOptions2}
                        value={checkedValues.checkedOptions2}
                        className='check-box-column'
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
          <div className='mt-6'>
            <Title level={2}>{t('Sort')}</Title>
            <Form.List name='sort'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Row gutter={24}>
                        <Col span={22}>
                          <Row gutter={20} key={key} align='middle' className='report-filter'>
                            <Col span={3}>
                              <Text>{t('Sort by')}</Text>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                {...restField}
                                name={[name, 'sortBy']}
                                rules={[{ required: true, message: '' }]}
                              >
                                <SearchSelect
                                  allowClear={false}
                                  placeholder={t('Please select')}
                                  options={fieldOptions?.map((field) => ({
                                    value: field.value,
                                    label: field.label,
                                    disabled:
                                      orderBy.some((item) => item.key === field.value) ||
                                      field.value === 'originator' ||
                                      field.value === 'validator',
                                  }))}
                                  onChange={(value) => handleSortChange(index, value, orderBy[index]?.direction)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Text>{t('in')}</Text>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                {...restField}
                                name={[name, 'order']}
                                rules={[{ required: true, message: '' }]}
                              >
                                <SearchSelect
                                  placeholder={t('Please select')}
                                  options={orderOptions?.map((order) => ({
                                    value: order.value,
                                    label: order.label,
                                  }))}
                                  onChange={(value) => handleSortChange(index, orderBy[index]?.key, value)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Text>{t('order')}</Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            className='mt-5'
                            onClick={() => {
                              remove(name);
                              handleDeleteSort(index);
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Form.Item>
                    <Button className='select-all-btn mb-16' onClick={() => add()} icon={<PlusOutlined />}>
                      {t('Add sort')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
