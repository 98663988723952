import { Drawer, Form, Layout, message, Spin } from 'antd';
import { ProjectMinutesOfMeetingHeader, ProjectMinutesOfMeetingFooter, CustomizeRequiredMark } from 'components';
import { useEffect, useState } from 'react';
import useAuthContext from '../../store/auth-context';
import { mockProjectMinutesOfMeetingData } from 'mock-data/project-data';
import dayjs, { Dayjs } from 'dayjs';
import {
  ProjectMinutesOfMeetingContentDetails,
  ProjectMinutesOfMeetingInformation,
  ProjectMinutesOfMeetingInformationNextMeeting,
} from './ProjectMinutesOfMeetingCreateOrUpdate/content';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import useUserEntity from 'hooks/common/useUserProfile';
import { MOMItemDataSource } from './ProjectMinutesOfMeetingCreateOrUpdate/content/ProjectMinutesOfMeetingContentDetails';
import { baseIssueService } from 'services';
import { BaseIssue, BaseIssueWatcher, Company, IssueStatus, User } from 'model';
import {
  ATTACHMENT_TYPES,
  INVITATION_ROLE,
  MODULE_FUNCTIONS,
  MOM_STATUSES,
  VALIDATION_ACTION,
  VALIDATION_STATUS,
  VALIDATION_STEP_TYPE,
} from 'utils/contants';
import BaseIssueMomItem from 'model/BaseIssueMomItem';
import momItemService from 'services/momItem.service';
import { useAttachmentTypes, useAttachmentTypesParams, useModuleFunctionParams, useModuleFunctions } from 'hooks';
import BaseIssueMomCategory from 'model/BaseIssueMomCategory';
import UserAttendees from 'components/common/UserAttendees';
import { useMomItemStatuses, useMomItemStatusesParams } from 'hooks/momItemStatus';
import ProjectMinutesOfMeetingDetailValidationPopup from './ProjectMinutesOfMeetingCreateOrUpdate/content/ProjectMinutesOfMeetingDetailValidationPopup';
import ProjecetMinutesOfMeetingUserDistribution from './ProjectMinutesOfMeetingCreateOrUpdate/content/ProjectMinutesOfMeetingUserDistribution';
import { mapBaseIssueAttachments } from 'utils/my-project';
export type MomValidationType = {
  validatorId: string | null;
  validatorCompanyId: string | null;
  prevStepType: string | null;
  prvValidatorCompanyId: string | null;
  prvValidatorId: string | null;
  validationAction: string;
  validationStatus: string;
  revision: number;
};
export type MOMFormValues = {
  subject?: string;
  attendees: {
    companyId?: string | null;
    inspectorIds?: string[];
  }[];
  distribution: {
    companyId?: string | null;
    inspectorIds?: string[];
  }[];
  category: string;
  meetingDate: string | Date | Dayjs | null;
  meetingLocation?: string;
  items: MOMItemDataSource[];
  nextMeetingDate?: string | Date | Dayjs | null;
  nextMeetingLocation?: string;
};
type ProjectMinutesOfMeetingCreateOrUpdateProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  meetingId?: string | null;
  projectId: string;
  categoryByFunction: BaseIssueMomCategory[];
  workspaceId: string;
  onClose: (categoryId: string) => void;
  issueStatuses: IssueStatus[];
  users: User[];
  setBaseIssueId: (value: string | null) => void;
  companies: Company[];
  refreshBaseIssues?: () => void;
};
export default function ProjectMinutesOfMeetingCreateOrUpdate(props: ProjectMinutesOfMeetingCreateOrUpdateProps) {
  const { isFormOpen, setIsFormOpen, issueStatuses } = props;
  const [form] = Form.useForm<MOMFormValues>();
  const users = useAppSelector(selectProjectUsers);
  const { user } = useUserEntity({ projectId: props.projectId, workspaceId: props.workspaceId });
  const [openValidationPopup, setOpenValidationPopup] = useState(false);
  const [, setDataFormMomsDetails] = useState<MOMFormValues | null>(null);

  const { profile } = useAuthContext();
  const currentUser = (users || []).find((u) => u.id === profile?.id);
  const [isLoading, setIsLoading] = useState(false);

  const [, setRerender] = useState(false);
  const [selectedBaseIssue, setSelectedBaseIssue] = useState<BaseIssue | null>(null);
  const isOriginator = profile?.id === selectedBaseIssue?.originatorId;
  const [moduleFunctionsParams] = useModuleFunctionParams();
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsParams);
  const [momItem, setMomItem] = useState<BaseIssueMomItem[]>([]);
  // const originator = users.find((u) => u.id === selectedBaseIssue?.originatorId);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const onClose = (categoryId?: string) => {
    setIsFormOpen(false);
    setDataFormMomsDetails(null);
    setSelectedBaseIssue(null);
    props.setBaseIssueId(null);
    form.resetFields();
    props.onClose(categoryId ? categoryId : '');
    props.refreshBaseIssues?.();
  };
  const [momItemStatusesParams] = useMomItemStatusesParams();
  const [momItemStatuses] = useMomItemStatuses(momItemStatusesParams);

  const onChangeForm = () => {};
  const groupByCompany = (distributions?: BaseIssueWatcher[]) => {
    if (!distributions || distributions?.length === 0) return [{ companyId: null, inspectorIds: [] }];
    const result = distributions.reduce<Record<string, { companyId: string; inspectorIds: string[] }>>(
      (init, distribution) => {
        if (!init[distribution.companyId]) {
          init[distribution.companyId] = { companyId: distribution.companyId, inspectorIds: [] };
        }
        init[distribution.companyId].inspectorIds.push(distribution.watcherId);
        return init;
      },
      {}
    );

    return Object.values(result);
  };
  const handleDeleteMomItem = async (momItemId: string) => {
    setIsLoading(true);
    try {
      if (props?.meetingId && selectedBaseIssue?.MinutesOfMeeting?.id) {
        await momItemService.deleteMomItemsById(
          props.workspaceId,
          props.projectId,
          props?.meetingId,
          selectedBaseIssue?.MinutesOfMeeting?.id,
          momItemId
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const updateDefaultValue = (selectedBaseIssue?: BaseIssue | null, momItem?: BaseIssueMomItem[] | null) => {
    const attendees =
      selectedBaseIssue?.BaseIssueWatchers?.filter((receiver) => receiver.role === INVITATION_ROLE.RECEIVER) || [];
    const distributions =
      selectedBaseIssue?.BaseIssueWatchers?.filter(
        (distribution) => distribution.role === INVITATION_ROLE.DISTRIBUTION
      ) || [];
    const momDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MOM_DOCUMENT);
    const momPhotoType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MOM_PHOTO);
    form.setFieldsValue({
      meetingDate: selectedBaseIssue?.MinutesOfMeeting?.meetingDate
        ? dayjs(selectedBaseIssue?.MinutesOfMeeting?.meetingDate)
        : dayjs(),
      subject: selectedBaseIssue?.name || '',
      meetingLocation: selectedBaseIssue?.MinutesOfMeeting?.meetingLocation || '',
      category: selectedBaseIssue?.MinutesOfMeeting?.categoryId || '',
      nextMeetingDate: selectedBaseIssue?.MinutesOfMeeting?.nextMeetingDate
        ? dayjs(selectedBaseIssue?.MinutesOfMeeting?.nextMeetingDate)
        : null,
      nextMeetingLocation: selectedBaseIssue?.MinutesOfMeeting?.nextMeetingLocation || '',
      attendees: groupByCompany(attendees),
      distribution: groupByCompany(distributions),
      items:
        momItem?.map((item) => ({
          status: item.statusId,
          id: item.id,
          subject: item.name,
          description: item.description,
          responsibleCompany: item.companyId ? item.companyId : undefined,
          personInCharge: item.assigneeId ? item.assigneeId : undefined,
          dueDate: item.dueDate ? dayjs(item.dueDate) : '',
          documents: item?.MomItemAttachments.filter((i) => i.attachmentTypeId === momDocumentType?.id).map((i) => ({
            id: i.id,
            name: i.name,
            filePath: i.filePath,
            attachmentTypeId: i?.attachmentTypeId,
          })),
          pictures: item?.MomItemAttachments.filter((i) => i.attachmentTypeId === momPhotoType?.id).map((i) => ({
            id: i.id,
            name: i.name,
            filePath: i.filePath,
            attachmentTypeId: i?.attachmentTypeId,
          })),
        })) || [],
    });
    setRerender((prev) => !prev);
  };
  const onSubmitAsDraft = async () => {
    try {
      const values = form.getFieldsValue(true) as MOMFormValues;

      const itemIndex = values.items?.findIndex((item) => !item.subject);
      const itemsValidatePath = ['items', itemIndex, 'subject'];

      const valid = await form.validateFields(['subject', 'category', itemsValidatePath]);

      if (valid) {
        try {
          setIsLoading(true);
          const values = form.getFieldsValue(true) as MOMFormValues;

          let statusObj = {} as IssueStatus | undefined;
          if (selectedBaseIssue) {
            statusObj = issueStatuses?.find((status) => status.code === selectedBaseIssue.IssueStatus?.code);
          } else {
            statusObj = issueStatuses?.find((status) => status.code === MOM_STATUSES.DRAFT);
          }

          const submitData = {
            isSaveAsDraft: true,
            statusId: statusObj?.id,
            originatorId: user?.id,
            name: values.subject,
            companyOriginatorId: user?.WorkspaceUser?.Company?.id,
            moduleFunctionId:
              moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.WIR)?.id || null,

            mom: {
              categoryId: values.category,
              meetingLocation: values.meetingLocation,
              meetingDate: values.meetingDate ? dayjs(values.meetingDate).format('YYYY-MM-DD') : null,
              nextMeetingDate: values.nextMeetingDate ? dayjs(values.nextMeetingDate).format('YYYY-MM-DD') : null,
              nextMeetingLocation: values.nextMeetingLocation,
              momDistributions:
                getDistributionAttachment(values).length > 0 ? getDistributionAttachment(values) : undefined,
              momItems: values.items?.map((item) => ({
                companyId: item?.responsibleCompany ? item?.responsibleCompany : undefined,
                assigneeId: item.personInCharge ? item?.personInCharge : undefined,
                status: item.status,
                dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
                name: item.subject,
                description: item.description,
                momItemAttachmentInfo: [
                  ...mapBaseIssueAttachments(item.documents || []),
                  ...(item.pictures?.map((picture) => ({
                    id: picture?.id.includes('local-id') ? undefined : picture.id,
                    filePath: picture?.filePath,
                    name: picture?.name,
                    attachmentTypeId: picture.attachmentTypeId,
                  })) || []),
                ],
              })),
            },
          };
          const data: unknown[] = [];

          values.items?.forEach((item, index) => {
            data.push({
              id: momItem[index]?.id || undefined,
              companyId: item.responsibleCompany,
              assigneeId: item.personInCharge,
              dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
              name: item.subject,
              momId: selectedBaseIssue?.MinutesOfMeeting?.id,
              description: item.description,
              statusId: item.status,
              momItemAttachmentInfo: [
                ...mapBaseIssueAttachments(item.documents || []),
                ...(item.pictures?.map((picture) => ({
                  id: picture?.id.includes('local-id') ? undefined : picture.id,
                  filePath: picture?.filePath,
                  name: picture?.name,
                  attachmentTypeId: picture.attachmentTypeId,
                })) || []),
              ],
            });
          });

          if (props.meetingId && selectedBaseIssue) {
            await baseIssueService.updateBaseIssue(props.workspaceId, props.projectId, props.meetingId, {
              ...submitData,
            });
            if (selectedBaseIssue?.MinutesOfMeeting)
              await momItemService.updateMomItemsById(
                props.workspaceId,
                props.projectId,
                props.meetingId,
                selectedBaseIssue?.MinutesOfMeeting?.id,
                data
              );
          } else {
            await baseIssueService.createBaseIssue(props.workspaceId, props.projectId, submitData);
            onClose(values.category);
          }
          onClose(selectedBaseIssue?.MinutesOfMeeting?.categoryId);
        } catch (error) {
          console.log(error);
          message.error((error as Error).message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getDistributionAttachment = (formValues: MOMFormValues) => {
    const attendees =
      formValues?.attendees
        ?.filter((invitation) => invitation?.companyId)
        ?.map((invitation) => {
          return {
            companyId: invitation?.companyId as string,
            inspectorIds: invitation?.inspectorIds,
            role: INVITATION_ROLE.RECEIVER,
          };
        }) || [];

    const distributions =
      formValues?.distribution
        ?.filter((invitation) => invitation?.companyId)
        ?.map((invitation) => {
          return {
            companyId: invitation?.companyId,
            inspectorIds: invitation?.inspectorIds,
            role: INVITATION_ROLE.DISTRIBUTION,
          };
        }) || [];
    if (
      attendees.some((invitation) => invitation.companyId) &&
      distributions.some((invitation) => invitation.companyId)
    ) {
      return [...attendees, ...distributions];
    } else if (attendees.some((invitation) => invitation.companyId)) {
      return attendees;
    } else if (distributions.some((invitation) => invitation.companyId)) {
      return distributions;
    }
    return [];
  };
  const onSubmitWithStartValidation = async (momValidationData: MomValidationType) => {
    try {
      if (momValidationData) {
        try {
          const values = form.getFieldsValue(true) as MOMFormValues;

          if (!selectedBaseIssue) {
            let statusCode = MOM_STATUSES.READY_FOR_REVIEW;
            let isSaveAsDraft = false;
            switch (momValidationData.validationStatus) {
              case VALIDATION_STATUS.INTERNAL_REVIEW:
                statusCode = MOM_STATUSES.DRAFT;
                isSaveAsDraft = true;
                break;
              case VALIDATION_STATUS.EXTERNAL_REVIEW:
                statusCode = MOM_STATUSES.READY_FOR_REVIEW;
                isSaveAsDraft = false;
                break;
              case VALIDATION_STATUS.SEND_TO_RECIPIENTS:
                statusCode = MOM_STATUSES.ISSUED;
                isSaveAsDraft = false;
                break;
              default:
                break;
            }
            const submitData = {
              name: values?.subject,
              isSaveAsDraft: isSaveAsDraft,
              originatorId: profile?.id,
              companyOriginatorId: user?.WorkspaceUser?.Company?.id,
              moduleFunctionId: moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.WIR)?.id,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id,
              mom: {
                categoryId: values?.category,
                meetingLocation: values?.meetingLocation,
                meetingDate: values?.meetingDate ? dayjs(values?.meetingDate).format('YYYY-MM-DD') : null,
                nextMeetingDate: values?.nextMeetingDate ? dayjs(values?.nextMeetingDate).format('YYYY-MM-DD') : null,
                nextMeetingLocation: values?.nextMeetingLocation,
                momDistributions:
                  values && getDistributionAttachment(values).length > 0
                    ? getDistributionAttachment(values)
                    : undefined,
                momItems: values?.items?.map((item) => ({
                  companyId: item?.responsibleCompany ? item?.responsibleCompany : undefined,
                  assigneeId: item.personInCharge ? item.personInCharge : undefined,
                  status: item.status,
                  dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
                  name: item.subject,
                  description: item.description,
                  momItemAttachmentInfo: [
                    ...mapBaseIssueAttachments(item.documents || []),
                    ...(item.pictures?.map((picture) => ({
                      id: picture?.id,
                      filePath: picture?.filePath,
                      name: picture?.name,
                      attachmentTypeId: picture.attachmentTypeId,
                    })) || []),
                  ],
                })),
                momValidation: momValidationData,
              },
            };
            await baseIssueService.createBaseIssue(props.workspaceId, props.projectId, submitData);
            onClose(values?.category);
          } else {
            let statusCode = MOM_STATUSES.READY_FOR_REVIEW;
            let isSaveAsDraft = false;
            switch (momValidationData.validationStatus) {
              case VALIDATION_STATUS.INTERNAL_REVIEW:
                statusCode = selectedBaseIssue.IssueStatus?.code
                  ? selectedBaseIssue.IssueStatus?.code
                  : MOM_STATUSES.DRAFT;
                if (selectedBaseIssue.IssueStatus?.code === MOM_STATUSES.READY_FOR_REVIEW) {
                  isSaveAsDraft = false;
                } else {
                  isSaveAsDraft = true;
                }
                break;
              case VALIDATION_STATUS.EXTERNAL_REVIEW:
                statusCode = MOM_STATUSES.READY_FOR_REVIEW;
                isSaveAsDraft = false;
                break;
              case VALIDATION_STATUS.SEND_TO_RECIPIENTS:
                statusCode = MOM_STATUSES.ISSUED;
                isSaveAsDraft = false;
                break;
              default:
                break;
            }

            const submitData = {
              isSaveAsDraft: isSaveAsDraft,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id,
              originatorId: selectedBaseIssue.originatorId,
              name: values.subject,
              companyOriginatorId: selectedBaseIssue.companyOriginatorId,
              moduleFunctionId:
                moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.WIR)?.id || null,

              mom: {
                categoryId: values.category,
                meetingLocation: values.meetingLocation,
                meetingDate: values.meetingDate ? dayjs(values.meetingDate).format('YYYY-MM-DD') : null,
                nextMeetingDate: values.nextMeetingDate ? dayjs(values.nextMeetingDate).format('YYYY-MM-DD') : null,
                nextMeetingLocation: values.nextMeetingLocation,
                momDistributions:
                  getDistributionAttachment(values).length > 0 ? getDistributionAttachment(values) : undefined,
                momValidation: momValidationData,
              },
            };
            const data: unknown[] = [];

            values.items?.forEach((item, index) => {
              data.push({
                id: momItem[index]?.id || undefined,
                companyId: item.responsibleCompany,
                assigneeId: item.personInCharge,
                dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
                name: item.subject,
                momId: selectedBaseIssue?.MinutesOfMeeting?.id,
                description: item.description,
                statusId: item.status,
                momItemAttachmentInfo: [
                  ...mapBaseIssueAttachments(item.documents || []),
                  ...(item.pictures?.map((picture) => ({
                    id: picture?.id,
                    filePath: picture?.filePath,
                    name: picture?.name,
                    attachmentTypeId: picture.attachmentTypeId,
                  })) || []),
                ],
              });
            });
            await baseIssueService.updateBaseIssue(
              props.workspaceId,
              props.projectId,
              selectedBaseIssue.id,
              submitData
            );
            if (selectedBaseIssue?.MinutesOfMeeting)
              await momItemService.updateMomItemsById(
                props.workspaceId,
                props.projectId,
                selectedBaseIssue.id,
                selectedBaseIssue?.MinutesOfMeeting?.id,
                data
              );
            onClose(selectedBaseIssue.MinutesOfMeeting?.categoryId);
          }
        } catch (error) {
          console.log(error);
          message.error((error as Error).message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBaseIssueById = async (baseIssueId: string) => {
    setIsLoading(true);
    try {
      const baseIssue = await baseIssueService.getBaseIssueById(props.workspaceId, props.projectId, baseIssueId, {
        type: 'MOM',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      return baseIssue;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const fetchMomItemById = async (baseIssueId: string, momItemId: string) => {
    setIsLoading(true);
    try {
      const momItem = await momItemService.getMomItemsById(props.workspaceId, props.projectId, baseIssueId, momItemId, {
        orderBy: 'order',
        include: 'MomItemAttachments',
      });
      setMomItem(momItem?.rows);
      return momItem;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const initializeDTForm = async () => {
    if (props?.meetingId) {
      const baseIssue = await fetchBaseIssueById(props?.meetingId);
      if (baseIssue?.MinutesOfMeeting?.id) {
        const momItems = await fetchMomItemById(props?.meetingId, baseIssue?.MinutesOfMeeting?.id);
        updateDefaultValue(baseIssue, momItems?.rows);
      }
      setSelectedBaseIssue(baseIssue);
    } else {
      updateDefaultValue();
    }
  };
  useEffect(() => {
    if (!isFormOpen) return;
    initializeDTForm();
  }, [isFormOpen, props?.meetingId]);
  const momValidate = async (selection: string, validatorId: string | null, validatorCompanyId: string | null) => {
    try {
      let validationAction = '';
      let validationStatus = '';
      switch (selection) {
        case VALIDATION_STATUS.INTERNAL_REVIEW:
          validationAction = VALIDATION_ACTION.IN_REVIEW;
          validationStatus = VALIDATION_STATUS.INTERNAL_REVIEW;
          break;
        case VALIDATION_STATUS.SEND_TO_RECIPIENTS:
          validationAction = VALIDATION_ACTION.SENT_TO_RECIPIENT;
          validationStatus = VALIDATION_STATUS.SEND_TO_RECIPIENTS;
          break;
        case VALIDATION_STATUS.SEND_FOR_VALIDATION:
          validationAction = VALIDATION_ACTION.IN_REVIEW;
          validationStatus = VALIDATION_STATUS.EXTERNAL_REVIEW;
          break;
        default:
          break;
      }
      const prvValidatorCompanyId =
        (selectedBaseIssue?.BaseValidations || [])[0]?.validatorCompanyId ?? selectedBaseIssue?.companyOriginatorId;
      const prvValidatorId = (selectedBaseIssue?.BaseValidations || [])[0]?.validatorId ?? profile?.id;
      const prevStepType: string =
        selectedBaseIssue?.companyOriginatorId === validatorCompanyId
          ? VALIDATION_STEP_TYPE.INTERNAL
          : VALIDATION_STEP_TYPE.EXTERNAL;
      const momValidationData: MomValidationType = {
        validatorId:
          selection === VALIDATION_STATUS.SEND_TO_RECIPIENTS
            ? profile?.id
              ? profile.id
              : prvValidatorId
            : validatorId,
        validatorCompanyId:
          selection === VALIDATION_STATUS.SEND_TO_RECIPIENTS
            ? currentUser?.WorkspaceUser?.Company
              ? currentUser?.WorkspaceUser?.Company?.id
              : prvValidatorCompanyId
            : validatorCompanyId,
        prevStepType: prevStepType,
        prvValidatorCompanyId:
          selection === VALIDATION_STATUS.SEND_TO_RECIPIENTS
            ? null
            : currentUser?.WorkspaceUser?.Company
              ? currentUser?.WorkspaceUser?.Company?.id
              : null,
        prvValidatorId: selection === VALIDATION_STATUS.SEND_TO_RECIPIENTS ? null : profile?.id ? profile.id : null,
        validationAction: validationAction,
        validationStatus: validationStatus,
        revision: 0,
      };
      onSubmitWithStartValidation(momValidationData);
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
    } finally {
      setIsFormOpen(false);
    }
  };
  // const handleSubmit = () => {
  //   // TODO-POM-920: integrate with API
  //   const fakeMeetingId = 1;
  //   navigate(`/project-settings/${props.projectId}/document-management/minutes-of-meeting/${fakeMeetingId}`);
  //   setIsFormOpen(false);
  // };

  return (
    <>
      <ProjectMinutesOfMeetingDetailValidationPopup
        isOpen={openValidationPopup}
        onCancel={() => setOpenValidationPopup(false)}
        startValidation={(selection: string, validatorId: string | null, validatorCompanyId: string | null) =>
          momValidate(selection, validatorId, validatorCompanyId)
        }
        setIsModalOpen={setOpenValidationPopup}
        currentCompany={
          (selectedBaseIssue?.Company ? selectedBaseIssue?.Company : currentUser?.WorkspaceUser?.Company) || null
        }
        Form={form}
        isEdit
        companies={props.companies}
        profile={profile}
        selectedBaseIssue={selectedBaseIssue}
      />
      <Layout.Content className='material-approval-request-create-modal'>
        <Form
          id='normal_login'
          form={form}
          // onFinish={handleSubmit}
          labelCol={{ span: 24 }}
          layout='vertical'
          onValuesChange={onChangeForm}
          scrollToFirstError
          requiredMark={CustomizeRequiredMark}
        >
          <Drawer
            styles={{
              header: {
                backgroundColor: '#405D72',
                boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
              },
            }}
            placement={'right'}
            width={`calc(100% - 195px)`}
            className={'project-minutes-of-meeting form-details'}
            onClose={() => onClose()}
            closeIcon={false}
            open={isFormOpen}
            maskClosable={false}
            destroyOnClose={false}
            title={
              <ProjectMinutesOfMeetingHeader
                user={user}
                profile={profile}
                onClose={() => {
                  onClose(selectedBaseIssue?.MinutesOfMeeting?.categoryId);
                }}
                selectedBaseIssue={selectedBaseIssue}
              />
            }
            footer={
              <ProjectMinutesOfMeetingFooter
                onSubmitAsDraft={onSubmitAsDraft}
                selectedBaseIssue={selectedBaseIssue}
                setOpenValidationModal={async (value) => {
                  try {
                    const values = await form.getFieldsValue();
                    setDataFormMomsDetails(values);
                    setOpenValidationPopup(value);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            }
          >
            <Spin spinning={isLoading}>
              <section className='form-details-section'>
                <ProjectMinutesOfMeetingInformation category={props.categoryByFunction} />
              </section>
              <section className='form-details-section'>
                <UserAttendees
                  form={form}
                  nameLabel='Names'
                  companyLabel='Company'
                  formName='attendees'
                  title='Attendees'
                  projectId={props?.projectId || ''}
                  // companyOriginator={userCompanyId}
                  // companyRecipient={companyReceiverId}
                  users={users}
                  isOpen={isFormOpen}
                  isReadOnly={false}
                  maxCompanies={3}
                  showTopButton
                  selectedBaseIssue={selectedBaseIssue}
                />
              </section>
              <section className='form-details-section'>
                <ProjecetMinutesOfMeetingUserDistribution
                  formName='distribution'
                  nameLabel='Names'
                  companyLabel='Company'
                  title='Distribution'
                  form={form}
                  projectId={props?.projectId || ''}
                  // companyOriginator={userCompanyId}
                  // companyRecipient={companyReceiverId}
                  selectedBaseIssue={selectedBaseIssue}
                  users={users}
                  isOpen={isFormOpen}
                  isReadOnly={false}
                  maxCompanies={3}
                  showTopButton
                />
              </section>
              <section className='form-details-section'>
                <ProjectMinutesOfMeetingContentDetails
                  title={'Minutes'}
                  categories={mockProjectMinutesOfMeetingData.categories}
                  form={form}
                  isFormOpen={isFormOpen}
                  users={props.users}
                  companies={props.companies}
                  momItem={momItem}
                  selectedBaseIssue={selectedBaseIssue}
                  momItemStatuses={momItemStatuses}
                  onDeleteMomItem={(value) => {
                    handleDeleteMomItem(value);
                  }}
                  isOriginator={isOriginator}
                  workspaceId={props.workspaceId}
                  projectId={props.projectId}
                />
              </section>
              <section className='form-details-section'>
                <ProjectMinutesOfMeetingInformationNextMeeting category={mockProjectMinutesOfMeetingData.category} />
              </section>
            </Spin>
          </Drawer>
        </Form>
      </Layout.Content>
    </>
  );
}
