import { Button, ButtonProps, Row, Space } from 'antd';
import { AddCommentModal } from 'components';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type WorkspaceViewType = 'detail' | 'controlStatus';

export enum EActionType {
  SaveAsDraft = 'Save as Draft',
  InviteForInspection = 'Invite For Inspection',
  Delete = 'Delete',
  Save = 'Save',
  Submit = 'Submit',
  Cancel = 'Cancel',
  StartInspection = 'Start Inspection',
  OpenStartInspectionModal = 'Open Start Inspection Modal',
  Transfer = 'Transfer',
}
export type FooterProps = {
  buttons?: (ButtonProps & {
    label?: string;
    actionType?: EActionType;
    position?: string;
  })[];
  onActionClick: (type: EActionType) => void;
};

export default function PermitToWorkFooter(props: FooterProps) {
  const { t } = useTranslation();
  const [isOpenCommentModal, setIsOpenCommentModal] = useState<boolean>(false);

  const buttons = props?.buttons?.reduce<{ left: ReactNode[]; right: ReactNode[] }>(
    (init, button) => {
      const result = { left: init.left, right: init.right };
      if (button.position === 'left') {
        result.left.push(
          <Button {...button} onClick={() => button?.actionType && props.onActionClick(button?.actionType)}>
            {button.label}
          </Button>
        );
      } else {
        result.right.push(
          <Button
            type={'primary'}
            onClick={() => button?.actionType && props.onActionClick(button?.actionType)}
            {...button}
          >
            {button.label}
          </Button>
        );
      }
      return result;
    },
    { left: [], right: [] }
  );

  return (
    <Row>
      <AddCommentModal isOpen={isOpenCommentModal} setOpen={setIsOpenCommentModal} />
      <Button
        type='dashed'
        ghost
        onClick={() => {
          setIsOpenCommentModal(true);
        }}
      >
        {t('Comment')}
      </Button>
      <Space>{buttons?.left}</Space>
      <Space style={{ marginLeft: 'auto' }}>{buttons?.right}</Space>
    </Row>
  );
}
