import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
const { Text } = Typography;
type ReadOnlyDataFieldProps = {
  title?: string;
  content?: string | React.ReactNode | null;
  isBorder?: boolean;
};
export default function ReadOnlyDataField(props: ReadOnlyDataFieldProps) {
  const { content, title, isBorder } = props;
  const { t } = useTranslation();

  return (
    <div className={'read-only-column-data'}>
      {title && <Text className='read-only-field-name'>{t(title || '')}</Text>}
      <Text className={`read-only-text-content ${isBorder && 'border'}`}>{content || ''}</Text>
    </div>
  );
}
