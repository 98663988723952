import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContent } from 'components';
import { Breadcrumb, Layout, notification, Typography, Flex, Button, Tag, Col, Row } from 'antd';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types/index';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';

const { Title, Text } = Typography;
const { Content } = Layout;

type CustomDocumentTransmissionProps = {
  keyString: string;
};
interface Item {
  id: number;
  text: string;
}

interface DraggableTagProps {
  tag: Item;
}

const commonStyle: React.CSSProperties = {
  cursor: 'move',
  transition: 'unset', // Prevent element from shaking after drag
};

const DraggableTag: React.FC<DraggableTagProps> = (props) => {
  const { tag } = props;
  const { listeners, transform, transition, isDragging, setNodeRef } = useSortable({ id: tag.id });

  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? 'unset' : transition, // Improve performance/visual effect when dragging
      }
    : commonStyle;

  return (
    <Tag style={style} ref={setNodeRef} {...listeners}>
      {tag.text}
    </Tag>
  );
};

export default function DocumentTransmission(props: CustomDocumentTransmissionProps) {
  const { keyString } = props;
  const { t } = useTranslation();
  const [, contextHolder] = notification.useNotification();

  const [items, setItems] = useState<Item[]>([
    { id: 1, text: 'Project Name' },
    { id: 2, text: 'Document Name' },
    { id: 3, text: 'Originator Company' },
  ]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      setItems((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
  };
  return (
    <ProjectContent>
      {contextHolder}
      <Content className='project-settings-common-page'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Title>
              <span className={'inspection-types-key-title'}>{t(keyString)}</span>
              {t(' - Document Transmission')}
            </Title>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content className='settings-content'>
          <Flex className='setting-section-block'>
            <Flex className='setting-section-header' justify='space-between' align='center'>
              <Typography.Title level={3}>{t('Email Notification Content')}</Typography.Title>
            </Flex>
            <Row className='document-transmission-content'>
              <Col span={3}>
                <Typography.Title className='subject' level={3}>
                  {t('Subject')}
                </Typography.Title>
              </Col>
              <Col>
                <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
                  <SortableContext items={items} strategy={horizontalListSortingStrategy}>
                    <Flex gap='4px 0' className='email-title'>
                      {items.map<React.ReactNode>((item, index) => (
                        <>
                          <DraggableTag tag={item} key={item.id} />
                          {index !== items.length - 1 && <Typography.Text className='tag-line'> - </Typography.Text>}
                        </>
                      ))}
                    </Flex>
                  </SortableContext>
                </DndContext>
              </Col>
            </Row>
            <Row className='document-transmission-content'>
              <Col span={3}>
                <Typography.Title className='subject' level={3}>
                  {t('Content')}
                </Typography.Title>
              </Col>
              <Col span={21}>
                <Content className='email-content'>
                  <Flex>
                    <Text>{t('Dear')}</Text>
                    <Tag className='email-tag'>{t('Recipient Name')}</Tag>
                    <Typography.Text className='tag-line'> , </Typography.Text>
                  </Flex>
                  <Flex>
                    <Text>{t('Please find here attached the Document: ')}</Text>
                    <Tag className='email-tag'>{t('Document Name List')}</Tag>
                  </Flex>
                  <Flex>
                    <Text>{t('Thank you and best regards,')}</Text>
                  </Flex>
                  <Tag>{t('Originator Name')}</Tag>
                </Content>
              </Col>
            </Row>
            <Flex className='email-content-btn'>
              <Button type='dashed'>{t('Reset')}</Button>
              <Button type='default'>{t('Save')}</Button>
            </Flex>
          </Flex>
        </Content>
      </Content>
    </ProjectContent>
  );
}
