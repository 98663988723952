import { QueryParams } from 'types';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchMomItemStatuses, selectMomItemStatus } from 'store/common.slice';
import { useMemo } from 'react';
import MomStatus from 'model/MomStatus';

export const useMomItemStatusesParams = () => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query };

    return [queryParams];
  }, []);
};

export const useMomItemStatuses = (query: unknown) => {
  return useFetchByParams<MomStatus[]>({
    action: fetchMomItemStatuses,
    dataSelector: selectMomItemStatus,
    params: query,
  });
};
