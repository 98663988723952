import { lazy, Suspense } from 'react';
import { Routes, Route, useLocation, matchPath } from 'react-router-dom';
import { AppRouteType } from 'types';
import { AnimatePresence } from 'framer-motion';
import { Layout, BasicLayout, WorkspaceSettingLayout, ProjectSettingLayout } from 'components';
import useAuthContext from 'store/auth-context';
import envService, { HiddenFeatures } from 'services/env.service';
import VoidSettingLayout from 'components/layout/AssetLayout/AssetSettingLayout';
import NotificationLayout from 'components/layout/Notification/NotificationLayout';
import { documentManagementRoutes } from 'modules/DocumentManagement/routes';

// Construction
import { constructionRoutes } from 'modules/construction/routes';

const NotFound = lazy(() => import('pages/404'));
const Home = lazy(() => import('pages/Home/Home'));
const Modules = lazy(() => import('pages/Modules/Modules'));
const MyTasks = lazy(() => import('pages/MyTasks/MyTasks'));
const GeneralSettings = lazy(() => import('pages/WorkspaceSettings/GeneralSettings'));
const UserSettings = lazy(() => import('pages/WorkspaceSettings/UserSettings'));
const CompanySettings = lazy(() => import('pages/WorkspaceSettings/CompanySettings'));
const Auth = lazy(() => import('pages/Auth/Auth'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const UpdatePassword = lazy(() => import('pages/UpdatePassword/UpdatePassword'));
const ProjectConfiguration = lazy(() => import('pages/ProjectSettings/ProjectConfiguration'));
const DataCustomization = lazy(() => import('pages/WorkspaceSettings/DataCustomization'));
const ModulesConfiguration = lazy(() => import('pages/Modules/ModulesConfiguration'));
const ProjectUserSettings = lazy(() => import('pages/ProjectSettings/ProjectUserSettings'));
const ProjectCompanySettings = lazy(() => import('pages/ProjectSettings/ProjectCompanySettings'));
const ProjectPhotos = lazy(() => import('pages/ProjectSettings/ProjectPhotos'));
const ProjectWorkPackage = lazy(() => import('pages/ProjectSettings/ProjectWorkPackage'));
const ProjectQuality = lazy(() => import('pages/ProjectSettings/ProjectQuality'));
const ProjectZoning = lazy(() => import('pages/ProjectSettings/ProjectZoning'));
const ProjectWorkBreakdownStructure = lazy(() => import('pages/ProjectSettings/ProjectWorkBreakdownStructure'));
const Folders = lazy(() => import('pages/FoldersTree/Folders'));
const ProjectInspections = lazy(() => import('pages/ProjectSettings/ProjectInspections'));
const ProjectWorkInspectionRequest = lazy(() => import('pages/ProjectSettings/ProjectWorkInspectionRequest'));
const ProjectInspectionPlan = lazy(() => import('pages/ProjectSettings/ProjectInspectionPlan'));
const ProjectDashboard = lazy(() => import('pages/ProjectSettings/ProjectDashboard'));
const ProjectMARs = lazy(() => import('pages/ProjectSettings/ProjectMARs'));
const ProjectMaterialDeliveryInspection = lazy(() => import('pages/ProjectSettings/ProjectMaterialDeliveryInspection'));
const ProjectMethodStatements = lazy(() => import('pages/ProjectSettings/ProjectMethodStatements'));
const ProjectShopDrawings = lazy(() => import('pages/ProjectSettings/ProjectShopDrawings'));
const ProjectEquipmentList = lazy(() => import('pages/ProjectSettings/ProjectEquipmentList'));

const ProjectQualityITP = lazy(() => import('pages/ProjectSettings/ProjectQualityITP'));
const ProjectViolations = lazy(() => import('pages/ProjectSettings/ProjectViolations'));
const ProjectNearMisses = lazy(() => import('pages/ProjectSettings/ProjectNearMisses'));
const ProjectAccidents = lazy(() => import('pages/ProjectSettings/ProjectAccidents'));
const ProjectPermitToWork = lazy(() => import('pages/ProjectSettings/ProjectPermitToWork'));
const ProjectNonConformityReport = lazy(() => import('pages/ProjectSettings/ProjectNonConformityReport'));
const DirectoryGeneral = lazy(() => import('pages/Directory/DirectoryGeneral'));
const ProjectToolboxRequestForInformation = lazy(
  () => import('pages/ProjectSettings/ProjectToolboxRequestForInformation')
);

// Document Management
const ProjectDocumentsTransmission = lazy(() => import('pages/ProjectSettings/ProjectDocumentsTransmission'));
const ProjectMinutesOfMeeting = lazy(() => import('pages/ProjectSettings/ProjectMinutesOfMeeting'));
const ProjectMinutesOfMeetingDetailView = lazy(() => import('pages/ProjectSettings/ProjectMinutesOfMeetingDetailView'));
const ProjectNumberingSystem = lazy(() => import('pages/ProjectSettings/ProjectNumberingSystem'));
const DocumentManagementWBS = lazy(() => import('pages/DocumentManagement/DocumentManagementWorkBreakdownStructure'));
const ProjectLetters = lazy(() => import('pages/ProjectSettings/ProjectLetters'));
const ProjectLetterDetail = lazy(() => import('pages/ProjectSettings/ProjectLetterDetail'));
const TicketingGeneral = lazy(() => import('pages/Tickecting/TicketingGeneral'));
const Notification = lazy(() => import('pages/Notification/Notification'));
const ProjectAssetRegister = lazy(() => import('pages/ProjectSettings/ProjectAssetRegister'));

// Construction

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={null}>
    <Tag />
  </Suspense>
);

const projectRouteProps = {
  auth: true,
  isPublic: true,
  showHeader: true,
  sidebar: 'project-settings',
};

const createRoutes = (parentPath: string, routes: AppRouteType[], defaultProps: Record<string, unknown> = {}) => {
  return routes?.map(({ path, ...route }) => {
    return {
      parentPath,
      path: `${parentPath}${path}`,
      ...defaultProps,
      ...route,
    };
  });
};

export const routes: AppRouteType[] = [
  {
    name: 'Auth',
    auth: false,
    path: '/auth',
    component: Auth,
    isPublic: true,
    showHeader: false,
  },
  {
    name: 'ForgotPassword',
    auth: false,
    path: '/auth/forgot-password',
    component: ForgotPassword,
    isPublic: true,
    showHeader: false,
  },
  {
    name: 'UpdatePassword',
    auth: false,
    path: '/auth/update-password',
    component: UpdatePassword,
    isPublic: true,
    showHeader: false,
  },
  {
    name: 'Projects',
    auth: true,
    path: '/',
    component: Home,
    isPublic: true,
    showHeader: true,
  },
  {
    name: 'Modules',
    auth: true,
    path: '/modules',
    component: Modules,
    isPublic: true,
    showHeader: true,
  },
  {
    name: 'My Tasks',
    auth: true,
    path: '/my-tasks',
    component: MyTasks,
    isPublic: true,
    showHeader: true,
  },
  {
    name: 'General settings',
    auth: true,
    path: '/workspace-settings/general',
    component: GeneralSettings,
    isPublic: true,
    showHeader: true,
    sidebar: 'workspace-settings',
  },
  {
    name: 'General settings',
    auth: true,
    path: '/workspace-settings/users',
    component: UserSettings,
    isPublic: true,
    showHeader: true,
    sidebar: 'workspace-settings',
  },
  {
    name: 'Company Settings',
    auth: true,
    path: '/workspace-settings/companies',
    component: CompanySettings,
    isPublic: true,
    showHeader: true,
    sidebar: 'workspace-settings',
  },
  {
    name: 'Data Customization',
    auth: true,
    path: '/workspace-settings/data-customization',
    component: DataCustomization,
    isPublic: true,
    showHeader: true,
    sidebar: 'workspace-settings',
  },
  {
    name: 'Modules Configuration',
    auth: true,
    path: '/workspace-settings/modules-configuration',
    component: ModulesConfiguration,
    isPublic: true,

    showHeader: true,
    sidebar: 'workspace-settings',
  },
  ...createRoutes(
    '/projects/:projectId/quality',
    [
      {
        name: 'Project Quality',
        path: '',
        component: ProjectQuality,
      },
      {
        name: 'Project Dashboard',
        path: '/dashboard',
        component: ProjectDashboard,
      },
      {
        name: 'Project Observations',
        path: '/observations',
        component: ProjectInspections,
      },
      ...(!envService.isFeatureHidden(HiddenFeatures.ITP)
        ? [
            {
              name: 'Project Quality ITP',
              path: '/itp',
              component: ProjectQualityITP,
            },
          ]
        : []),
      {
        name: 'Project Inspections',
        path: '/inspections',
        component: ProjectInspections,
      },
      {
        name: 'Project Inspections',
        path: '/work-inspections',
        component: ProjectWorkInspectionRequest,
      },
      {
        name: 'Project Inspections',
        path: '/non-conformity-report',
        component: ProjectNonConformityReport,
      },
      ...(!envService.isFeatureHidden(HiddenFeatures.MAR)
        ? [
            {
              name: 'Project Material Approval Requests',
              path: '/material-approvals',
              component: ProjectMARs,
            },
          ]
        : []),
      ...(!envService.isFeatureHidden(HiddenFeatures.MDI)
        ? [
            {
              name: 'Project Material Delivery Inspections',
              path: '/material-deliveries',
              component: ProjectMaterialDeliveryInspection,
            },
          ]
        : []),
      ...(!envService.isFeatureHidden(HiddenFeatures.MS)
        ? [
            {
              name: 'Project Method Statements',
              path: '/method-statements',
              component: ProjectMethodStatements,
            },
          ]
        : []),
      {
        name: 'Project Shop Drawings',
        path: '/shop-drawings',
        component: ProjectShopDrawings,
      },
      {
        name: 'Project Inspection Plan',
        path: '/plan/:planId',
        component: ProjectInspectionPlan,
      },
    ],
    projectRouteProps
  ),
  {
    name: 'Project Zoning',
    path: '/zoning',
    component: ProjectZoning,
  },
  ...createRoutes(
    '/projects/:projectId/settings',
    [
      {
        name: 'Project Setting Companies Management',
        path: '/companies',
        component: ProjectCompanySettings,
      },
      {
        name: 'Project Setting Users Management',
        path: '/users',
        component: ProjectUserSettings,
      },
      {
        name: 'Project Setting Work Packages Management',
        path: '/work-packages',
        component: ProjectWorkPackage,
      },
      {
        name: 'Project Setting Zoning',
        path: '/zoning',
        component: ProjectZoning,
      },
      ...(!envService.isFeatureHidden(HiddenFeatures.WBS)
        ? [
            {
              name: 'Project Work Breakdown Structure',
              path: '/work-breakdown-structure',
              component: ProjectWorkBreakdownStructure,
            },
          ]
        : []),
      {
        name: 'Project Configuration',
        path: '/configuration',
        component: ProjectConfiguration,
      },
      {
        name: 'Modules Configuration',
        path: '/modules-configuration',
        component: ModulesConfiguration,
      },
    ],
    projectRouteProps
  ),
  {
    name: 'Project Photos',
    auth: true,
    path: '/projects/:projectId/photos',
    component: ProjectPhotos,
    isPublic: true,
    sidebar: 'project-settings',
  },
  ...createRoutes(
    '/projects/:projectId/safety',
    [
      {
        name: 'Project Safety',
        path: '',
        component: ProjectQuality,
      },
      {
        name: 'Project Zoning',
        path: '/zoning',
        component: ProjectZoning,
      },
      {
        name: 'Project Inspections',
        path: '/inspections',
        component: ProjectInspections,
      },
      {
        name: 'Project Safety Violations',
        path: '/safety-violations',
        component: ProjectViolations,
      },
      {
        name: 'Project Near Misses',
        path: '/near-misses',
        component: ProjectNearMisses,
      },
      ...(!envService.isFeatureHidden(HiddenFeatures.PTW)
        ? [
            {
              name: 'Project Safety Permit To Work',
              path: '/permit-to-work',
              component: ProjectPermitToWork,
            },
          ]
        : []),
      ...(!envService.isFeatureHidden(HiddenFeatures.ACD)
        ? [
            {
              name: 'Project Accidents',
              path: '/accidents',
              component: ProjectAccidents,
            },
          ]
        : []),
      {
        name: 'Project Inspection Plan',
        path: '/plan/:planId',
        component: ProjectInspectionPlan,
      },
      {
        name: 'Project Dashboard',
        path: '/dashboard',
        component: ProjectDashboard,
      },

      ...(!envService.isFeatureHidden(HiddenFeatures.EQL)
        ? [
            {
              name: 'Equipment List',
              path: '/equipment-list',
              component: ProjectEquipmentList,
            } as AppRouteType,
          ]
        : []),
    ],
    projectRouteProps
  ),
  ...(!envService.isFeatureHidden(HiddenFeatures.TOOLBOX)
    ? createRoutes(
        '/projects/:projectId/toolbox',
        [
          {
            name: 'Directory',
            path: '/directory',
            component: DirectoryGeneral,
          },
          {
            name: 'Request For Information Detail',
            path: '/request-for-information/:rfiId',
            component: ProjectToolboxRequestForInformation,
          },
          {
            name: 'Documents Register',
            path: '/documents-register',
            component: Folders,
          },
          {
            name: 'Request For Information',
            path: '/request-for-information',
            component: ProjectToolboxRequestForInformation,
          },
        ],
        projectRouteProps
      )
    : []),

  ...(!envService.isFeatureHidden(HiddenFeatures.RFI)
    ? createRoutes(
        '/projects/:projectId/toolbox',
        [
          {
            name: 'Request For Information Detail',
            path: '/request-for-information/:rfiId',
            component: ProjectToolboxRequestForInformation,
          },
          {
            name: 'Request For Information',
            path: '/request-for-information',
            component: ProjectToolboxRequestForInformation,
          },
        ],
        projectRouteProps
      )
    : []),

  ...(!envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT)
    ? createRoutes(
        '/projects/:projectId/document-management',
        [
          {
            name: 'Work Breakdown Structure',
            path: '/dm-work-breakdown-structure',
            component: DocumentManagementWBS,
          },
          {
            name: 'Numbering System',
            path: '/numbering-system',
            component: ProjectNumberingSystem,
          },
          ...(!envService.isFeatureHidden(HiddenFeatures.DOC_HUB)
            ? [
                {
                  name: 'Document Hub',
                  path: '/documents-hub',
                  component: Folders,
                },
              ]
            : []),
          ...documentManagementRoutes,
          {
            name: 'Documents Transmission',
            path: '/documents-transmission',
            component: ProjectDocumentsTransmission,
          },
          {
            name: 'Minutes Of Meeting',
            path: '/minutes-of-meeting',
            component: ProjectMinutesOfMeeting,
          },
          {
            name: 'Minutes Of Meeting Detail',
            path: '/minutes-of-meeting/:meetingId',
            component: ProjectMinutesOfMeetingDetailView,
          },
          {
            name: 'Letters',
            path: '/letters',
            component: ProjectLetters,
          },
          {
            name: 'Letter Detail',
            path: '/letters/:letterId',
            component: ProjectLetterDetail,
          },
        ],
        projectRouteProps
      )
    : []),
  {
    name: 'Ticketing',
    auth: true,
    path: '/ticketing',
    component: TicketingGeneral,
    isPublic: true,
    showHeader: true,
    sidebar: 'void',
  },
  ...(!envService.isFeatureHidden(HiddenFeatures.NOTIFICATION)
    ? [
        {
          name: 'Notification',
          auth: true,
          path: '/notification',
          component: Notification,
          isPublic: true,
          showMenu: false,
          showHeader: true,
          sidebar: 'notification',
        } as AppRouteType,
      ]
    : []),
  ...(!envService.isFeatureHidden(HiddenFeatures.ASSET)
    ? [
        {
          name: 'Project Asset Register',
          auth: true,
          path: '/assets/:workspaceId',
          component: ProjectAssetRegister,
          isPublic: true,

          showHeader: true,
          sidebar: 'void',
        } as AppRouteType,
      ]
    : []),
  ...(!envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT) ? constructionRoutes : []),
  {
    name: '404',
    path: '*',
    component: NotFound,
    isPublic: true,
  },
];

export default function RoutesAppRoutes() {
  const { profile } = useAuthContext();
  const location = useLocation();
  const publicRoutes = routes
    .filter((route) => !route.auth || route.isPublic)
    .map((route) => (
      <Route key={route.path} path={route.path} element={waitFor(route.component)}>
        {route.children}
      </Route>
    ));

  // public routes
  if (!profile) {
    return (
      <AnimatePresence mode='wait'>
        <Routes key={location.pathname} location={location}>
          {publicRoutes}
        </Routes>
      </AnimatePresence>
    );
  }

  // authenticated routes
  const authenticatedRoutes = routes
    .filter((route) => route.auth || route.isPublic)
    .map((route) => <Route key={route.path} path={route.path} element={waitFor(route.component)} />);

  const currentRoute = routes.find((route) => (matchPath(route.path, location.pathname) ? true : false));

  const RouterLayout = !currentRoute?.sidebar
    ? BasicLayout
    : currentRoute?.sidebar === 'workspace-settings'
      ? WorkspaceSettingLayout
      : currentRoute?.sidebar === 'project-settings'
        ? ProjectSettingLayout
        : currentRoute?.sidebar === 'notification'
          ? NotificationLayout
          : VoidSettingLayout;

  return (
    <Layout showHeader={currentRoute?.showHeader ?? true} sidebar={currentRoute?.sidebar}>
      <RouterLayout>
        <AnimatePresence mode='wait'>
          <Routes key={location.pathname} location={location}>
            {authenticatedRoutes}
          </Routes>
        </AnimatePresence>
      </RouterLayout>
    </Layout>
  );
}
