import {
  DesignRegisterPhase,
  ProjectDataCustomizationType,
  InstructionType,
  MaterialCategoriesType,
  DesignRegisterPhaseType,
} from 'model/ProjectDataCustomization';

export const discipline: ProjectDataCustomizationType[] = [
  {
    key: '1',
    name: 'General',
    code: 'GN',
    isCore: true,
  },
  {
    key: '2',
    name: 'Architectural',
    code: 'AR',
    isCore: true,
  },
  {
    key: '3',
    name: 'Interior decoration',
    code: 'ID',
    isCore: true,
  },
  {
    key: '4',
    name: 'Civil',
    code: 'CV',
    isCore: true,
  },
  {
    key: '5',
    name: 'Structual',
    code: 'ST',
    isCore: true,
  },
  {
    key: '6',
    name: 'Plumbing',
    code: 'PL',
    isCore: true,
  },
  {
    key: '7',
    name: 'Fire fighting',
    code: 'FF',
    isCore: true,
  },
  {
    key: '8',
    name: 'Electrical high voltage',
    code: 'HV',
    isCore: true,
  },
  {
    key: '9',
    name: 'Electrical low voltage',
    code: 'LV',
    isCore: true,
  },
];

export const statusList: ProjectDataCustomizationType[] = [
  {
    key: '1',
    name: 'Status 1',
    isCore: true,
  },
  {
    key: '2',
    name: 'Status 2',
    isCore: true,
  },
  {
    key: '3',
    name: 'Status 3',
    isCore: true,
  },
];

export const typeList: ProjectDataCustomizationType[] = [
  {
    key: '1',
    name: 'Quality observation / NCR',
    code: '',
    isCore: true,
  },
  {
    key: '2',
    name: 'Safety observation / Violation',
    code: '',
    isCore: true,
  },
];
export const workspaceTypeList: ProjectDataCustomizationType[] = [
  {
    key: '1',
    name: 'Accident',
    code: '',
    isCore: true,
  },
  {
    key: '2',
    name: 'Hazard',
    code: '',
    isCore: true,
  },
  {
    key: '3',
    name: 'Safety Violation',
    code: '',
    isCore: true,
  },
];

export const defaultInstructions: InstructionType[] = [
  {
    order: 1,
    name: 'Design Modifications',
    isCore: true,
  },
  {
    order: 2,
    name: 'Additional Work',
    isCore: true,
  },
  {
    order: 3,
    name: 'Reduced Work',
    isCore: true,
  },
  {
    order: 4,
    name: 'Work Sequence',
    isCore: true,
  },
  {
    order: 5,
    name: 'Other',
    isCore: true,
  },
];


export const materialCategories: MaterialCategoriesType[] = [
  {
    key: '1',
    name: 'General',
    code: 'GN',
    isCore: true,
  },
  {
    key: '2',
    name: 'Architectural',
    code: 'AR',
    isCore: true,
  },
  {
    key: '3',
    name: 'Interior decoration',
    code: 'ID',
    isCore: true,
  },
  {
    key: '4',
    name: 'Civil',
    code: 'CV',
    isCore: true,
  },
  {
    key: '5',
    name: 'Structual',
    code: 'ST',
    isCore: true,
  },
  {
    key: '6',
    name: 'Plumbing',
    code: 'PL',
    isCore: true,
  },
  {
    key: '7',
    name: 'Fire fighting',
    code: 'FF',
    isCore: true,
  },
  {
    key: '8',
    name: 'Electrical high voltage',
    code: 'HV',
    isCore: true,
  },
  {
    key: '9',
    name: 'Electrical low voltage',
    code: 'LV',
    isCore: true,
  },
];
