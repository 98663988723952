import dayjs from 'dayjs';
import { QueryParams } from 'types';
type objectKeyString = {
  [key: string]: string;
};
type designSelectType = {
  disciplineValues: { [key: string]: { value: string; code: string } };
  documentTypeValues: objectKeyString;
  statusValues: { [key: string]: { value: string; color: string} };
};
export const designFieldValues: designSelectType = {
  disciplineValues: {
    architectural: { value: 'Architectural', code: 'AR' },
    civil: { value: 'Civil', code: 'CV' },
    construction: { value: 'Construction', code: 'CT' },
    costManagement: { value: 'Cost Management', code: 'CM' },
    electricalHighVoltage: { value: 'Electrical High Voltage', code: 'EH' },
    electricalLowVoltage: { value: 'Electrical Low Voltage', code: 'LV' },
    extremeLowVoltage: { value: 'Extreme Low Voltage', code: 'EL' },
    environment: { value: 'Environment', code: 'EV' },
    fireAlarm: { value: 'Fire Alarm', code: 'FA' },
    fireFighting: { value: 'Fire Fighting', code: 'FF' },
    healthAndSafety: { value: 'Health & Safety', code: 'HS' },
    hvac: { value: 'HVAC', code: 'AC' },
    housekeeping: { value: 'Housekeeping', code: 'HK' },
    interiorDecoration: { value: 'Interior Decoration', code: 'ID' },
    liftingOperations: { value: 'Lifting Operations', code: 'LO' },
    landscape: { value: 'Landscape', code: 'LS' },
    mechanical: { value: 'Mechanical', code: 'MC' },
    mediumHighVoltage: { value: 'Medium High Voltage', code: 'MH' },
    plumbing: { value: 'Plumbing', code: 'PL' },
    procurement: { value: 'Procurement', code: 'PC' },
    projectManagement: { value: 'Project Management', code: 'PM' },
    rainwater: { value: 'Rainwater', code: 'RW' },
    stormWater: { value: 'Storm Water', code: 'SW' },
    structural: { value: 'Structural', code: 'ST' },
    workingAtHeight: { value: 'Working at Height', code: 'WH' },
  },
  documentTypeValues: {
    model: '3D Model',
    calculationNote: 'Calculation Note',
    diagram: 'Diagram',
    drawing: 'Drawing',
    procedure: 'Procedure',
    specs: 'Specs',
  },
  statusValues: {
    information: { value: 'For Information', color: 'default'},
    review: { value: 'For Review', color: 'blue' },
    approval: { value: 'For Approval', color: 'green' },
    construction: { value: 'For Construction', color: 'brown' },
    approved: { value: 'Approved', color: 'green' },
    uploading: { value: 'Uploading', color: 'default' },
    draft: { value: 'Unpublished', color: 'default' },
    failed: { value: 'Upload Failed', color: 'red' },
  },
};

export const designApprovalStatusKeys = ['approval', 'review'];

export const designPublishStatusKeys = ['information', 'review', 'approval', 'construction'];

export interface designQueryParams {
  GeneralFilterQuery: QueryParams;
  nestedPaginationParams: { [discipline: string]: QueryParams };
  nestedOrderParams: { [discipline: string]: string };
  projectId: string;
  workspaceId: string;
  disciplineMapNameToId: Map<string, string>;
}

export interface DesignsKpisParams {
  projectId: string;
  workspaceId: string;
  kpiType: string;
  column: string;
}

export interface RawDesignForm {
  title: string;
  revision?: string;
  id: string;
}
export interface DesignResponse {
  [discipline: string]: { count: number; rows: Design[] };
}

export interface DesignKpiResponse {
  count?: number;
  phase?: string;
}

export interface DesignForm {
  title: string;
  number?: string;
  revision: string;
  discipline: string; //value choice: [Discipline in project settings]
  phase?: string; //value choice: [Phase in project settings]
  issueDate: string; //date
  deadlineDate: string; //date
  documentType?: string; // Drawing, Diagram, Specs, 3D model, [Drawing in project settings]
  area?: string; //value choice: [Zoning in project settings]
  issuedBy?: string; //value choice: [Companies in project settings]
  status: string; //published, unpublished
  fileExtension: string;
  initialRevisionId?: string | null;
  disciplineId?: string;
}
export interface EditDesignForm {
  title?: string;
  number?: string;
  revision?: string;
  discipline?: string;
  phase?: string;
  issueDate?: string;
  deadlineDate?: string;
  documentType?: string;
  area?: string;
  issuedBy?: string;
  status?: string;
  initialRevisionId?: string | null;
  disciplineId?: string;
}

export interface EditDesignFormDayjs extends Omit<EditDesignForm, 'issueDate' | 'deadlineDate'> {
  issueDate?: dayjs.Dayjs;
  deadlineDate?: dayjs.Dayjs;
}
export interface DesignAttachmentForm {
  designId: string;
  file: File;
  draft: boolean;
}
export interface UnpublishedDesignForm extends DesignForm {
  id: string;
}

export interface Design extends UnpublishedDesignForm {
  Company: { name: string };
  createdAt: string;
}

export interface PublishDesignInput {
  approval: boolean;
  designs: Design[];
  users: { id: string; email: string; firstName: string; lastName: string }[];
}
