import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Segmented,
  Select,
  TreeSelect,
  Typography,
} from 'antd';
import { CustomizeRequiredMark, ReadOnlyDataField, SearchSelect } from 'components/common';
import {
  Attachment,
  AttachmentInfo,
  BaseIssue,
  Company,
  Country,
  IssueDiscipline,
  IssuePriority,
  IssueType,
  MARDescription,
  MaterialCategory,
  Phase,
  User,
  WorkPackage,
  Zone,
} from 'model';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialDetailForm, { MaterialDetailType } from './MaterialDetail';
import dayjs from 'dayjs';
import { attachmentService } from 'services';
import { DataNode } from 'antd/es/tree';
import { findPath } from 'components/common/TreeSelectTagRender';
import {
  selectActivitiesByDiscipline,
  selectControlPointsBySubActivity,
  selectSubActivitiesByActivity,
  selectWorkBreakDownStructureByLevel,
} from 'store/my-projects.slice';
import { useAppSelector } from 'store';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import TextArea from 'antd/es/input/TextArea';

const { Option } = Select;

type MARsDetailProps = {
  form: FormInstance;
  isReadOnly: boolean;
  companies: Company[];
  users: User[];
  issuePriorities: IssuePriority[];
  issueDisciplines: IssueDiscipline[];
  issueTypes: IssueType[];
  profile?: User | null;
  countries: Country[];
  selectedMarBaseIssue: BaseIssue | null;
  workPackages?: WorkPackage[];
  materialCategories?: MaterialCategory[];
  zones: Zone[];
  phases: Phase[];
  isOpen: boolean;
  projectId: string;
  workspaceId: string;
  onValuesChange?: (values: BaseIssue) => void;
  documentsInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  setDocumentsInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
  photosInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  setPhotosInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
  zonesTreeData: DataNode[];
  zoneUpdated: string;
  setZoneUpdated: (value: string) => void;
  setAttachments: (value: Attachment[]) => void;
  attachments: Attachment[];
};

const { Title } = Typography;
export default function MARsDetail(props: MARsDetailProps) {
  const {
    form,
    isReadOnly,
    users,
    workPackages,
    profile,
    countries,
    isOpen,
    onValuesChange,
    projectId,
    workspaceId,
    companies,
    selectedMarBaseIssue,
    documentsInfo,
    setDocumentsInfo,
    photosInfo,
    setPhotosInfo,
    zoneUpdated,
    setZoneUpdated,
    zonesTreeData,
    setAttachments,
    attachments,
  } = props;
  const { t } = useTranslation();
  const [position, setPosition] = useState<MaterialDetailType>('proposed');
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const [value, setValue] = useState('');
  const [selectValue, setSelectValue] = useState('Days');
  const [marDescription, setMarDescription] = useState<MARDescription | null>(null);
  const [materialLoading, setMaterialLoading] = useState<boolean>(false);
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const formValues = form.getFieldsValue(true);
  const watchedDiscipline = Form.useWatch('disciplineId') || formValues?.disciplineId;
  const activities = useAppSelector(selectActivitiesByDiscipline(watchedDiscipline));
  const subActivities = useAppSelector(
    selectSubActivitiesByActivity(Form.useWatch('activityId') || formValues.activityId)
  );
  const controlPoints = useAppSelector(
    selectControlPointsBySubActivity(Form.useWatch('subActivityId') || formValues.subActivityId)
  );
  const hasMARChangedRef = useRef<boolean>(false);
  const [isExpectedDeliveryBeforeRequiredDate, setIsExpectedDeliveryBeforeRequiredDate] = useState(false);

  const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
    return data?.map((value) => ({
      value: value.id,
      label: value[labelKey as keyof typeof value],
    }));
  };
  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(findPath(zonesTreeData, zoneId));
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue) && Number(inputValue) <= 999) {
      setValue(inputValue);
    }
  };
  const handleSelectChange = (value: string) => {
    setSelectValue(value);
  };

  const fetchMarAttachments = async (currentPosition?: string) => {
    try {
      setMaterialLoading(true);
      const materialProposed = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
        (material: { isProposed: boolean }) => material.isProposed === true
      );
      let marDescriptionId = materialProposed?.id;
      if (currentPosition === 'specified') {
        const materialSpecified = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
          (material: { isProposed: boolean }) => material.isProposed === false
        );
        marDescriptionId = materialSpecified?.id;
      }
      if (selectedMarBaseIssue) {
        const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
          projectId,
          baseIssueId: selectedMarBaseIssue.id,
          marDescriptionId: marDescriptionId,
          limit: 'unlimited',
        });
        setAttachments(rows);
      }
      setMaterialLoading(false);
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setMaterialLoading(false);
    }
  };

  const selectAfter = (
    <Form.Item name='productionPeriodUnit' noStyle>
      <Select style={{ width: '90px' }} defaultValue='Days' value={selectValue} onChange={handleSelectChange}>
        <Option value='Days'>Days</Option>
        <Option value='Weeks'>Weeks</Option>
        <Option value='Months'>Months</Option>
      </Select>
    </Form.Item>
  );

  const options = [
    { label: t('Material Proposed'), value: 'proposed' },
    { label: t('Material Specified'), value: 'specified' },
  ];

  const handlePositionChange = (value: string) => {
    setPosition(value as MaterialDetailType);
    const materialProposed = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
      (material: { isProposed: boolean }) => material.isProposed === true
    );
    const materialSpecified = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
      (material: { isProposed: boolean }) => material.isProposed === false
    );
    if (value === 'specified') {
      setMarDescription(materialSpecified || null);
    } else {
      setMarDescription(materialProposed || null);
    }
    fetchMarAttachments(value);
  };
  useEffect(() => {
    if (isOpen) {
      setPosition('proposed');
      if (selectedMarBaseIssue) {
        form.setFieldValue('companyOriginatorId', selectedMarBaseIssue.companyOriginatorId);
        form.setFieldValue('originatorId', selectedMarBaseIssue.originatorId);
      } else {
        form.setFieldValue('originatorId', profile?.id);
        form.setFieldValue('companyOriginatorId', userCompanyId);
      }
      const materialProposed = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
        (material: { isProposed: boolean }) => material.isProposed === true
      );
      const materialSpecified = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
        (material: { isProposed: boolean }) => material.isProposed === false
      );
      if (materialProposed) {
        Object.keys(materialProposed).forEach((key) => {
          const values = form.getFieldValue([key, 'proposed']);
          if (!values) {
            form.setFieldValue([key, 'proposed'], materialProposed[key as keyof MARDescription]);
          } else {
            form.setFieldValue([key, 'proposed'], values);
          }
        });
      }
      if (materialSpecified) {
        Object.keys(materialSpecified).forEach((key) => {
          form.setFieldValue([key, 'specified'], materialSpecified[key as keyof MARDescription]);
        });
      }
      if (position === 'specified') {
        setMarDescription(materialSpecified || null);
      } else {
        setMarDescription(materialProposed || null);
      }
      fetchMarAttachments();
    } else {
      setAttachments([]);
    }
    setIsExpectedDeliveryBeforeRequiredDate(false);
  }, [isOpen, selectedMarBaseIssue]);

  return (
    <>
      <Form requiredMark={CustomizeRequiredMark} form={form} layout='vertical' onValuesChange={onValuesChange}>
        <div className='create-mar-form'>
          <Row className='ready-only-mar-container' gutter={16}>
            <Col span={11}>
              <Title
                className='read-only-mar-title read-only-row'
                level={3}
                style={{ lineHeight: '40px', marginBottom: '12px' }}
              >
                {t('General Information')}
              </Title>
              <Row className={isReadOnly ? 'read-only-row' : ''}>
                <Col span={24}>
                  {isReadOnly ? (
                    <ReadOnlyDataField title={'Material Name'} content={selectedMarBaseIssue?.name} />
                  ) : (
                    <Form.Item name='name' label={t('Material Name')} rules={[{ required: true, message: '' }]}>
                      <Input showCount maxLength={100} allowClear={true} placeholder={t('Enter Material Name')} />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Company Originator'}
                      content={
                        companies?.find((company) => company.id == selectedMarBaseIssue?.companyOriginatorId)?.name
                      }
                    />
                  ) : (
                    <Form.Item
                      name='companyOriginatorId'
                      label={t('Company Originator')}
                      rules={[{ required: true, message: '' }]}
                    >
                      <SearchSelect
                        placeholder={t('Select Company Originator')}
                        disabled={true}
                        options={(users || []).map((user) => {
                          return {
                            value: user.WorkspaceUser?.Company?.id,
                            label: user.WorkspaceUser?.Company?.name,
                          };
                        })}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Originator Name'}
                      content={users?.find((user) => user.id == selectedMarBaseIssue?.originatorId)?.fullName}
                    />
                  ) : (
                    <Form.Item
                      name='originatorId'
                      label={t('Originator Name')}
                      rules={[{ required: true, message: '' }]}
                    >
                      <SearchSelect
                        disabled={true}
                        placeholder={t('Enter Originator name')}
                        options={(users || []).map((user) => {
                          return {
                            value: user.id,
                            label: user.fullName,
                          };
                        })}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row className={isReadOnly ? 'read-only-row' : ''}>
                <Col span={24}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Work Package'}
                      content={
                        workPackages?.find(
                          (ws) => ws.id === selectedMarBaseIssue?.MaterialApprovalRequest?.workPackageId
                        )?.name
                      }
                    />
                  ) : (
                    <Form.Item name='workPackageId' label={t('Work Package')} rules={[{ required: true, message: '' }]}>
                      <SearchSelect
                        placeholder={t('Select Work Package')}
                        options={workPackages?.map((workPackage) => ({
                          value: workPackage.id,
                          label: workPackage.name,
                        }))}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Discipline'}
                      content={
                        disciplines.find((discipline) => discipline.id === formValues?.disciplineId)?.name || '--'
                      }
                    />
                  ) : (
                    <Form.Item
                      name='disciplineId'
                      label={t('Discipline')}
                      rules={[{ required: true, message: 'Please select discipline' }]}
                    >
                      <SearchSelect
                        onChange={() => {
                          form.setFieldValue('activityId', null);
                          form.setFieldValue('subActivityId', null);
                          form.setFieldValue('controlPointId', null);
                          hasMARChangedRef.current = true;
                        }}
                        placeholder={t('Select discipline')}
                        options={mapDataToOptions(disciplines)}
                        breakText
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Activity'}
                      content={activities?.find((activity) => activity.id === formValues?.activityId)?.name || '--'}
                    />
                  ) : (
                    <Form.Item
                      name='activityId'
                      label={t('Activity')}
                      rules={[{ required: false, message: 'Please select activity' }]}
                    >
                      <SearchSelect
                        onChange={() => {
                          hasMARChangedRef.current = true;
                        }}
                        placeholder={t('Select activity')}
                        options={mapDataToOptions(activities)}
                        breakText
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Sub-activity'}
                      content={
                        subActivities?.find((subActivity) => subActivity.id === formValues?.subActivityId)?.name || '--'
                      }
                    />
                  ) : (
                    <Form.Item
                      name='subActivityId'
                      label={t('Sub-activity')}
                      rules={[{ required: false, message: '' }]}
                    >
                      <SearchSelect
                        onChange={() => {
                          hasMARChangedRef.current = true;
                        }}
                        placeholder={t('Select Sub-activity')}
                        options={mapDataToOptions(subActivities)}
                        breakText
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={12}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Control Point'}
                      content={
                        controlPoints?.find((controlPoint) => controlPoint.id === formValues?.controlPointId)?.name ||
                        '--'
                      }
                    />
                  ) : (
                    <Form.Item
                      name='controlPointId'
                      label={t('Control Point')}
                      rules={[{ required: false, message: '' }]}
                    >
                      <SearchSelect
                        breakText
                        placeholder={t('Select Control Point')}
                        options={mapDataToOptions(controlPoints)}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row className={isReadOnly ? 'read-only-row' : ''}>
                <Col span={24}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Zoning'}
                      content={
                        selectedMarBaseIssue?.zoneId ? findPath(zonesTreeData, selectedMarBaseIssue.zoneId) : '--'
                      }
                    />
                  ) : (
                    <Form.Item name='zoneId' label={t('Zoning')} rules={[{ required: false, message: '' }]}>
                      {/*
                      <SearchSelect
                        placeholder={t('Select Zoning')}
                        defaultValue={zones?.find((zone) => zone.parentId === undefined)?.id || ''}
                        options={zones?.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                      />
                      */}
                      <TreeSelect
                        style={{ width: '100%' }}
                        value={zoneUpdated}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={zonesTreeData}
                        placeholder={t('Select Zoning')}
                        treeDefaultExpandAll
                        onChange={onChangeZone}
                        placement='bottomLeft'
                        popupMatchSelectWidth={false}
                        popupClassName='mar-select-zoning'
                      />
                      <div style={{ display: 'none' }}>{zoneUpdated}</div>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Title className='read-only-mar-title read-only-row mt-5' level={3}>
                {t('Material Delivery')}
              </Title>
              <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
                <Col span={8} className='production-period'>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Production Period'}
                      content={
                        selectedMarBaseIssue?.MaterialApprovalRequest?.productionPeriod === null
                          ? '--'
                          : selectedMarBaseIssue?.MaterialApprovalRequest?.productionPeriod[0] === 'n'
                            ? '--'
                            : selectedMarBaseIssue?.MaterialApprovalRequest?.productionPeriod
                      }
                    />
                  ) : (
                    <Form.Item className='mar-date-title' name='productionPeriod' label={t('Production Period')}>
                      <Input
                        className='custom-input'
                        addonAfter={selectAfter}
                        value={value}
                        onChange={handlePeriodChange}
                        maxLength={3}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={8} className='required-on-site'>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Required on Site'}
                      content={
                        selectedMarBaseIssue?.MaterialApprovalRequest?.requiredOnSiteDate === null
                          ? '--'
                          : dayjs(selectedMarBaseIssue?.MaterialApprovalRequest?.requiredOnSiteDate)?.format(
                              'YYYY-MM-DD'
                            )
                      }
                    />
                  ) : (
                    <Form.Item className='mar-date-title' name='requiredOnSiteDate' label={t('Required on Site')}>
                      <DatePicker
                        placeholder={t('Select date')}
                        style={{ width: '100%' }}
                        format='YYYY-MM-DD'
                        disabledDate={(date) => date.isBefore(dayjs(), 'day')}
                        onChange={(value) => {
                          const deliveryDate = form.getFieldValue('deliveryDate');
                          if (deliveryDate && dayjs(value).isBefore(deliveryDate, 'day')) {
                            setIsExpectedDeliveryBeforeRequiredDate(true);
                          } else {
                            setIsExpectedDeliveryBeforeRequiredDate(false);
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={8} className='expected-delivery-date'>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={'Expected Delivery Date'}
                      content={
                        selectedMarBaseIssue?.MaterialApprovalRequest?.deliveryDate === null
                          ? '--'
                          : dayjs(selectedMarBaseIssue?.MaterialApprovalRequest?.deliveryDate)?.format('YYYY-MM-DD')
                      }
                    />
                  ) : (
                    <div>
                      <Form.Item
                        className={`expected-delivery-date-item ${isExpectedDeliveryBeforeRequiredDate ? 'error' : ''}`}
                        name='deliveryDate'
                        label={t('Expected Delivery Date')}
                      >
                        <DatePicker
                          placeholder={t('Select date')}
                          style={{ width: '100%' }}
                          format='YYYY-MM-DD'
                          onChange={(value) => {
                            const requiredOnSiteDate = form.getFieldValue('requiredOnSiteDate');
                            if (requiredOnSiteDate && dayjs(value).isAfter(requiredOnSiteDate, 'day')) {
                              setIsExpectedDeliveryBeforeRequiredDate(true);
                            } else {
                              setIsExpectedDeliveryBeforeRequiredDate(false);
                            }
                          }}
                          disabledDate={(date) => date.isBefore(dayjs(), 'day')}
                        />
                      </Form.Item>
                      {isExpectedDeliveryBeforeRequiredDate && (
                        <span style={{ color: 'red' }}>
                          {t('Expected Delivery Date should not be later than Required On Site date')}
                        </span>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className={isReadOnly ? 'read-only-row mb-3' : 'mb-3'}>
                <Col span={24}>
                  {isReadOnly ? (
                    <ReadOnlyDataField title={'Comments'} content={selectedMarBaseIssue?.description || '--'} />
                  ) : (
                    <>
                      <Form.Item
                        name={'comments'}
                        label={t('Comments')}
                        rules={[
                          {
                            max: 300,
                            message: t('Comment cannot exceed 300 characters'),
                          },
                        ]}
                      >
                        <TextArea
                          style={{ textTransform: 'none' }}
                          allowClear
                          placeholder={t('Add comments here...')}
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
              {/* <Title className='read-only-mar-title read-only-row mt-5' level={3}>
                {t('Distribution')}
              </Title> */}
              {/* <Row className={isReadOnly ? 'read-only-row' : ''}>
                <Col span={24}>
                  {isReadOnly ? (
                    <ReadOnlyDataField
                      title={t('Watcher Names or Emails')}
                      content={
                        (selectedMarBaseIssue?.BaseIssueWatchers || []).length > 0
                          ? selectedMarBaseIssue?.BaseIssueWatchers?.map((baseIssueWatcher, index) => {
                            const watcher = (users || []).find((c) => c.id === baseIssueWatcher.watcherId);
                            return watcher
                              ? watcher.fullName +
                              (index !== (selectedMarBaseIssue?.BaseIssueWatchers || []).length - 1 ? ', ' : '')
                              : '';
                          })
                          : '--'
                      }
                    />
                  ) : (
                    <Form.Item name='watcherIds' label={t('Watcher Names or Emails')}>
                      <Select
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        placeholder={t('Select watcher')}
                        options={(users || []).map((user) => {
                          return {
                            value: user.id,
                            label: user.fullName,
                          };
                        })}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row> */}
            </Col>
            <Col span={1}>
              <Divider type='vertical' style={{ height: '100%' }} />
            </Col>
            <Col span={12}>
              {/* Material description */}
              <div className='toggle-button-group'>
                <Segmented
                  className='funtion-segmented mb-3'
                  options={options}
                  value={position}
                  onChange={handlePositionChange}
                />
                <MaterialDetailForm
                  isReadOnly={isReadOnly}
                  position={position}
                  countries={countries}
                  projectId={projectId}
                  workspaceId={workspaceId}
                  selectedMarBaseIssue={selectedMarBaseIssue}
                  isOpen={isOpen}
                  marDescription={marDescription}
                  attachments={attachments}
                  fetchMarAttachments={(position?: string) => fetchMarAttachments(position)}
                  materialLoading={materialLoading}
                  documentsInfo={documentsInfo}
                  setDocumentsInfo={(value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) =>
                    setDocumentsInfo(value)
                  }
                  photosInfo={photosInfo}
                  setPhotosInfo={(value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) =>
                    setPhotosInfo(value)
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
}
