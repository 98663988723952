import { Button, Modal, message, Upload, ModalProps, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { fileService } from 'services';
import { formatUrlAttachments } from 'utils';

const { Dragger } = Upload;

type UploadDocumentProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleAddDocument: (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => void;
} & ModalProps;

export default function UploadDocument(props: UploadDocumentProps) {
  const { isModalOpen, setIsModalOpen, handleAddDocument } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [, setFileType] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewFile, setPreviewFile] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields();
      setFile(null);
      setPreviewFile(null);
    }
  }, [isModalOpen]);

  //   const onUploadPlan = async () => {
  //     try {
  //       await form.validateFields();
  //       const formValues = await form.getFieldsValue();
  //       if (formValues.name ) {
  //         handleAddDocument(formValues.name, formValues.documentType);
  //         setIsLoading(false);
  //         setIsModalOpen(false);
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log(error);
  //     }
  //   };
  const handleOk = async () => {
    if (!file) {
      setIsError(true);
      return;
    }
    setIsLoading(true);
    try {
      await form.validateFields();
      const name = form.getFieldValue('name');
      if (name) {
        const response = await fileService.uploadFileAdvanced(file, fileName, 'application/pdf', 10);
        handleAddDocument(name, response);
        setIsLoading(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onBeforeUpload = async (uploadFile: File) => {
    const isJpgOrPng = uploadFile.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error(t('You can only upload PDF file!'));
      return false;
    }
    setIsLoading(true);
    const { imagePreview } = await fileService.pdfToImage(uploadFile);
    setFile(uploadFile);
    setFileType(uploadFile.type);
    setFileName(uploadFile.name);
    setPreviewFile(imagePreview);
    setIsLoading(false);
    form.setFieldValue('name', formatUrlAttachments(uploadFile.name, true));
    return false;
  };

  return (
    <Modal
      className='mar-modal'
      title={t('Add a Document')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk} loading={isLoading} className='mar-add-document-text'>
          {t('Add Document')}
        </Button>,
      ]}
      width={468}
    >
      <Form form={form} layout='vertical'>
        <Form.Item>
          <Dragger
            name='file'
            multiple={false}
            accept='application/pdf'
            showUploadList={false}
            className={isError ? 'error' : ''}
            beforeUpload={onBeforeUpload}
          >
            {previewFile ? (
              <div className='preview-container'>
                <img src={previewFile} alt='' />
              </div>
            ) : (
              <>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
                <p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
              </>
            )}
          </Dragger>
        </Form.Item>
        <Form.Item name='name' label={t('Document Name or Reference')} rules={[{ required: true, message: '' }]}>
          <Input placeholder={t('Enter document name or reference')} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
