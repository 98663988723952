import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Language } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchLanguages, selectLanguages } from 'store/common.slice';

export const useLanguagesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, []);
};

export const useLanguages = (query: QueryParams) => {
  return useFetchByParams<Language[]>({
    action: fetchLanguages,
    dataSelector: selectLanguages,
    params: query,
  });
};
