import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Company } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchCompanies, selectMyWorkspaceCompanies } from 'store/my-workspace.slice';
import { fetchProjectCompanies } from 'store/my-projects.slice';
import { RootState } from 'store';

export const selectProjectCompanies = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.companies;
const selectProjectCompaniesCount = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.companiesCount;

const selectProjectCompaniesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.companiesLoading;
const selectMyWorkspaceCount = (state: RootState) => state.myWorkspace.companiesCount;
const selectMyWorkspaceLoading = (state: RootState) => state.myWorkspace.myWorkspaceLoading;
export const useMyWorkspaceParams = (
  options: {
    workspaceId: string;
  } & QueryParams
): [query: { workspaceId: string }, onParamChange: (params: QueryParams) => void] => {
  const { workspaceId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const { ...restParams } = params;
    delete restParams.tab;
    delete restParams.selectedZone;
    return { ...restParams, ...options };
  }, [workspaceId, params]);

  return [query, onParamChange];
};
export const useMyWorkspaceCompaniesParams = (
  options: {
    workspaceId?: string;
  } & QueryParams
) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId]);
};

export const useMyWorkspaceCompanies = (query: QueryParams) => {
  return useFetchByParams<Company[]>({
    action: fetchCompanies,
    dataSelector: selectMyWorkspaceCompanies,
    loadingSelector: selectMyWorkspaceLoading,
    params: query,
  });
};
type ProjectIssuesParamsType = {
  workspaceId: string;
  projectId: string;
} & QueryParams;

export const useProjectCompaniesSettingParams = (
  options: ProjectIssuesParamsType
): [query: ProjectIssuesParamsType, onParamChange: (params: QueryParams) => void] => {
  const { workspaceId, projectId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const { ...restParams } = params;
    delete restParams.tab;
    delete restParams.selectedZone;
    return { ...restParams, ...options };
  }, [workspaceId, projectId, params]);

  return [query, onParamChange];
};
export const useProjectCompaniesParams = (
  options: {
    workspaceId: string;
    projectId: string;
  } & QueryParams
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId, projectId]);
};
export const useProjectCompanies = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<Company[]>({
    action: fetchProjectCompanies,
    dataSelector: (state: RootState) => selectProjectCompanies(state, projectId),
    loadingSelector: (state: RootState) => selectProjectCompaniesLoading(state, projectId),
    params: query,
  });
};
export const useProjectCountCompanies = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<Company[]>({
    action: fetchProjectCompanies,
    dataSelector: (state: RootState) => selectProjectCompaniesCount(state, projectId),
    loadingSelector: (state: RootState) => selectProjectCompaniesLoading(state, projectId),
    params: query,
  });
};
export const useMyWorkspaceCountCompanies = (query: QueryParams) => {
  return useFetchByParams<number>({
    action: fetchCompanies,
    dataSelector: (state: RootState) => selectMyWorkspaceCount(state),
    loadingSelector: (state: RootState) => selectMyWorkspaceLoading(state),
    params: query,
  });
};
