import { useMemo } from 'react';
import { QueryParams } from 'types';
import { User, UserInvitation } from 'model';
import { useFetchByParams, useQueryParams, useRequestByParams } from 'hooks';
import {
  fetchUsers,
  selectMyWorkspaceUsers,
  selectMyWorkspaceUsersLoading,
} from 'store/my-workspace.slice';
import { fetchProjectUsers } from 'store/my-projects.slice';
import { RootState } from 'store';

export const selectMyProjectUsers = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.users;

const selectMyProjectUsersCount = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.usersCount;

const selectMyProjectUsersLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.usersLoading;

export const useMyWorkspaceUsersParams = (
  options: { workspaceId?: string; projectId?: string } & QueryParams
) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId]);
};

export const useMyWorkspaceUsers = (query: any) => {
  return useFetchByParams<User[]>({
    action: fetchUsers,
    dataSelector: selectMyWorkspaceUsers,
    loadingSelector: selectMyWorkspaceUsersLoading,
    params: query,
  });
};

export const useProjectUsersParams = (
  options: { workspaceId?: string; projectId?: string } & QueryParams
): [
  query: { workspaceId?: string; projectId?: string } & QueryParams,
  onParamChange: (value: { [key: string]: string | number | undefined }) => void
] => {
  const { workspaceId, projectId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const queryParams = { ...params, ...options };

    return queryParams;
  }, [workspaceId, projectId, params]);

  return [
    query,
    onParamChange as (value: {
      [key: string]: string | number | undefined;
    }) => void,
  ];
};

export const useProjectUsers = (
  query: { workspaceId?: string; projectId?: string } & QueryParams
) => {
  const { projectId } = query;
  return useFetchByParams<User[]>({
    action: fetchProjectUsers,
    dataSelector: (state: RootState) =>
      selectMyProjectUsers(state, projectId as string),
    loadingSelector: (state: RootState) =>
      selectMyProjectUsersLoading(state, projectId as string),
    params: query,
  });
};

export const useProjectUsersCount = (
  query: { workspaceId?: string; projectId?: string } & QueryParams
) => {
  const { projectId } = query;
  return useFetchByParams<User[]>({
    action: fetchProjectUsers,
    dataSelector: (state: RootState) =>
      selectMyProjectUsersCount(state, projectId as string),
    loadingSelector: (state: RootState) =>
      selectMyProjectUsersLoading(state, projectId as string),
    params: query,
  });
};
