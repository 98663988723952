import { DashboardKpisParamsType } from 'hooks/dashboard';
import request from 'requesters/report.request';
import { ResponseBaseIssueKpis, ResponseIssueKpis } from 'types';

const dashboardService = {
  getQualityDashboardKpis: async (query: DashboardKpisParamsType) => {
    const { workspaceId, projectId, ...params } = query;
    return request.get<ResponseBaseIssueKpis & ResponseIssueKpis>(
      `/workspaces/${workspaceId}/projects/${projectId}/dashboards/quality/general-kpis`,
      {
        params: params,
      }
    );
  },
};

export default dashboardService;
