import { DefaultOptionType } from 'antd/es/select';
import { SearchSelect } from 'components/common';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InspectionTestPlanTable from 'components/settings/project/DataCustomization/table/InspectionTestPlan/InspectionTestPlanTable';
import { EInspectionTestPlanColumnKey } from 'types/general-setting';
import { PlusOutlined } from '@ant-design/icons';
import { Form, message, Tag, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { EWorkBreakDownStructureLevelID, QualityITPData } from 'types/project';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { projectService } from 'services';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { useInspectionTestPlain, useInspectionTestPlanParams } from 'hooks';
import { WorkBreakdownStructure } from 'model';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
import { Key } from 'antd/es/table/interface';
import workBreakdownStructureService from 'services/work-breakdown-structure.services';

const getIncrementId = (() => {
  let id = 0;
  return () => {
    return id++;
  };
})();

type InspectionTestPlanProps = {
  onLoading: (loading: boolean) => void;
  onChangedData: (hasChange: boolean) => void;
};

const InspectionTestPlan = (props: InspectionTestPlanProps) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<QualityITPData[]>([]);
  const [form] = useForm();
  const { projectId } = useParams();
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;
  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    orderBy: 'itpOrder',
    mainPhase: true,
  });
  const [workBreakDownStructures, wbsLoading, refreshWbs] = useWorkBreakdownStructures(workBreakdownStructureQuery);
  const wbsDiscipline = useMemo(
    () => workBreakDownStructures?.filter((item) => item.level === EWorkBreakDownStructureLevelID.Discipline) || [],
    [workBreakDownStructures]
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [formSubmit, setFormSubmit] = useState({});

  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  const [inspectionTestPlan, itpLoading, refreshInspectionTestPlain] = useInspectionTestPlain(inspectionTestPlanParams);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const disciplineOptions = useMemo(() => {
    return wbsDiscipline.map((discipline) => {
      return {
        label: discipline.name?.toUpperCase(),
        value: discipline.id,
        disabled: dataSource.some((data) => data.key === discipline.id),
      };
    });
  }, [wbsDiscipline, dataSource]);

  const mainPhase = useMemo(() => {
    return workBreakDownStructures?.find((wbs) => wbs?.isMain);
  }, [workBreakDownStructures]);

  const handleAddActivity = (option: DefaultOptionType) => {
    setDataSource([
      ...dataSource,
      {
        orderText: `${dataSource.length + 1}`,
        key: option.value as string,
        name: option.label as string,
        type: EInspectionTestPlanColumnKey.Discipline,
        changed: true,
      },
    ]);
  };

  const validation = (controlPoints: QualityITPData[]) => {
    const isEmptyControlName = controlPoints.some((control) => !control.name);
    return isEmptyControlName;
  };

  const groupDataByType = (
    dataSource: QualityITPData[],
    result = {
      controlPoint: [] as QualityITPData[],
      subActivity: [] as QualityITPData[],
      activity: [] as QualityITPData[],
      discipline: [] as QualityITPData[],
    },
    parentKey = ''
  ) => {
    dataSource.forEach((data) => {
      if (data.type === EInspectionTestPlanColumnKey.ControlPoint) {
        result.controlPoint.push({ ...data, parentKey });
      } else if (data.type === EInspectionTestPlanColumnKey.SubActivity) {
        result.subActivity.push({ ...data, parentKey });
      } else if (data.type === EInspectionTestPlanColumnKey.Discipline) {
        result.discipline.push({ ...data });
      } else if (data.type === EInspectionTestPlanColumnKey.Activity) {
        result.activity.push({ ...data });
      }
      if (data.children) {
        groupDataByType(data.children, result, data.key);
      }
    });
    return result;
  };

  const createSubActivity = async (subActivity: QualityITPData[]) => {
    return await Promise.all(
      subActivity.map(async (subActivity, index: number) => {
        const payload = {
          name: subActivity.name,
          type: 'sub-activity',
          activityId: subActivity.parentKey,
          order: subActivity.order || index + 1,
        };

        let activityResult = { id: subActivity?.id || '' };

        if (subActivity.id && subActivity.changed) {
          activityResult = await projectService.updateInspectionTestPlans(
            workspaceId as string,
            projectId as string,
            subActivity.id,
            payload
          );
        } else if (!subActivity.id && subActivity.changed) {
          activityResult = await projectService.createInspectionTestPlans(
            workspaceId as string,
            projectId as string,
            payload
          );
        }

        return {
          data: subActivity?.children || [],
          parentKey: activityResult?.id,
        };
      })
    );
  };

  const mapFileToAttachment = (attachment?: QualityITPData['specs']) => {
    return attachment
      ? [
          {
            name: attachment?.name,
            filePath: attachment?.url,
            attachmentTypeId: attachment?.attachmentTypeId,
          },
        ]
      : null;
  };

  const mapAttachmentToFile = (attachment?: InspectionTestPlanModel['SpecAttachment']) => {
    return attachment
      ? {
          uid: attachment?.id,
          url: attachment.filePath,
          name: attachment?.name,
          attachmentTypeId: attachment.attachmentTypeId,
        }
      : null;
  };

  const createControlPoint = async (subActivityResult: { data: QualityITPData[]; parentKey: string }[]) => {
    return await Promise.all(
      subActivityResult.map(async (subActivity) => {
        return await Promise.all(
          subActivity.data.map((controlPoint, index: number) => {
            const payload = {
              name: controlPoint.name,
              type: 'control-point',
              parentId: subActivity.parentKey,
              inspectionTypeId: controlPoint.controlType,
              frequencyId: controlPoint.frequency,
              templateAttachmentInfo: mapFileToAttachment(controlPoint?.template),
              specAttachmentInfo: mapFileToAttachment(controlPoint?.specs),
              qualityControlChecker1: controlPoint.checker1?.qualityControlMatrix,
              companyChecker1: controlPoint.checker1?.company,
              qualityControlChecker2: controlPoint.checker2?.qualityControlMatrix,
              companyChecker2: controlPoint.checker2?.company,
              qualityControlChecker3: controlPoint.checker3?.qualityControlMatrix,
              companyChecker3: controlPoint.checker3?.company,
              order: controlPoint.order || index + 1,
            };

            if (controlPoint.id && controlPoint.changed) {
              return projectService.updateInspectionTestPlans(
                workspaceId as string,
                projectId as string,
                controlPoint.id,
                payload
              );
            } else if (!controlPoint.id && controlPoint.changed) {
              return projectService.createInspectionTestPlans(workspaceId as string, projectId as string, payload);
            }
          })
        );
      })
    );
  };

  const resetChangedValue = (data: QualityITPData[]): QualityITPData[] => {
    return data.map(({ ...value }) => {
      delete value.changed;
      if (value.children) {
        return { ...value, children: resetChangedValue(value.children) };
      }
      return { ...value };
    });
  };

  const watchChangedData = (data: QualityITPData[]): boolean => {
    return data.some((qualityData) => {
      if (qualityData?.changed) return true;
      if (qualityData?.children) {
        return watchChangedData(qualityData.children);
      }
    });
  };

  const updateExpandKey = (data: InspectionTestPlanModel[][]) => {
    const updatedITPKey = data
      .reduce((result: InspectionTestPlanModel[], inspectionTestPlain: InspectionTestPlanModel[]) => {
        if (inspectionTestPlain.filter(Boolean).length > 0) {
          result = [...result, ...inspectionTestPlain];
        }
        return result;
      }, [])
      ?.map((itp) => itp?.parentId);

    setExpandedRowKeys([...expandedRowKeys, ...updatedITPKey]);
  };

  const showError = (controlPoints: QualityITPData[]) => {
    const result = {} as Record<string, boolean>;
    controlPoints.forEach((controlPoint) => {
      result[controlPoint.key] = true;
    });
    setFormSubmit(result);
  };

  const updateDisciplineAndActivity = async (itpData: QualityITPData[]): Promise<void> => {
    for (let index = 0; index < itpData?.length; index++) {
      const itp = itpData[index];
      itp.order = itp.order || index + 1;
      if (!itp.changed) continue;
      await workBreakdownStructureService.updateWorkBreakdownStructure(workspaceId, projectId as string, itp.key, {
        name: itp.name || '',
        itpOrder: itp.order,
      });
    }
  };

  const handleSave = async () => {
    const result = groupDataByType(dataSource);

    if (validation(result.controlPoint)) {
      showError(result.controlPoint);
      return;
    }
    props.onLoading(true);
    setIsTableLoading(true);
    try {
      await updateDisciplineAndActivity(result.discipline);
      await updateDisciplineAndActivity(result.activity);
      const subActivityResult = await createSubActivity(result.subActivity);
      const controlPointResult = await createControlPoint(subActivityResult);
      setDataSource(resetChangedValue(dataSource));
      refreshInspectionTestPlain();
      refreshWbs();
      updateExpandKey(controlPointResult);
      message.success(t('Inspection test plan successfully updated'));
    } catch (error) {
      console.error(error);
      message.error(t('Oop! Something wrong'));
    } finally {
      props.onLoading(false);
      setIsTableLoading(false);
    }
  };

  const updateValue = (
    dataSource: QualityITPData[],
    updatedData: {
      value: string | Record<string, string | number | null> | null;
      key: string;
      column: keyof QualityITPData;
    }
  ): QualityITPData[] => {
    return dataSource.map((data) => {
      if (data.key === updatedData.key) {
        let value = updatedData.value;
        if (value && typeof value === 'object' && data?.[updatedData.column]) {
          value = {
            ...((data?.[updatedData.column] as Record<string, string>) || {}),
            ...(updatedData.value as Record<string, string>),
          };
        }
        return { ...data, [updatedData.column]: value, changed: true };
      } else if (data.children) {
        return { ...data, children: updateValue(data.children, updatedData) };
      }
      return data;
    });
  };

  const handleUpdateSource = (updatedData: {
    value: string | Record<string, string | number | null> | null;
    key: string;
    column: keyof QualityITPData;
  }) => {
    const newDataSource = updateValue(dataSource, updatedData);
    setDataSource(newDataSource);
  };

  const buildControlPoint = (subActivity: InspectionTestPlanModel): QualityITPData[] | undefined => {
    let orderText = 0;
    const controlPoints = inspectionTestPlan?.filter((ips) => ips.parentId === subActivity.id);
    const result = controlPoints.map((controlPoint) => {
      return {
        id: controlPoint.id,
        orderText: `${++orderText}`,
        key: controlPoint.id,
        name: controlPoint.name,
        controlType: controlPoint.inspectionTypeId,
        frequency: controlPoint.frequencyId,
        template: mapAttachmentToFile(controlPoint?.TemplateAttachment),
        specs: mapAttachmentToFile(controlPoint?.SpecAttachment),
        checker1: {
          qualityControlMatrix: controlPoint.qualityControlChecker1,
          company: controlPoint.companyChecker1,
        },
        checker2: {
          qualityControlMatrix: controlPoint.qualityControlChecker2,
          company: controlPoint.companyChecker2,
        },
        checker3: {
          qualityControlMatrix: controlPoint.qualityControlChecker3,
          company: controlPoint.companyChecker3,
        },
        type: EInspectionTestPlanColumnKey.ControlPoint,
        order: controlPoint.order,
      };
    });

    if (result.length > 0) return result;
    return undefined;
  };

  const buildSubActivity = (activityId: string, parentOrderText: string) => {
    const subActivities = inspectionTestPlan?.filter((ips) => ips.activityId === activityId);
    let orderText = 0;

    return subActivities?.map((subActivity) => {
      const children = buildControlPoint(subActivity);

      return {
        key: subActivity?.id,
        name: subActivity?.name,
        orderText: `${parentOrderText} ${++orderText}`,
        children: children?.length ? children : undefined,
        id: subActivity.id,
        type: EInspectionTestPlanColumnKey.SubActivity,
        order: subActivity.order,
      };
    });
  };
  const buildActivity = (discipline: WorkBreakdownStructure, parentText: string) => {
    const activities = workBreakDownStructures
      ?.filter((wbs) => wbs.parentId === discipline.id)
      .filter(
        (activity) =>
          (activity.itpOrder && activity.level === EWorkBreakDownStructureLevelID.Activity) ||
          inspectionTestPlan?.some((ips) => ips.activityId === activity.id)
      );

    return activities.map((activity, index) => {
      const orderText = `${parentText} ${index + 1}`;
      const children = buildSubActivity(activity.id, orderText);
      return {
        id: activity.id,
        key: activity?.id,
        name: activity?.name,
        orderText,
        type: EInspectionTestPlanColumnKey.Activity,
        children: children?.length > 0 ? children : undefined,
        order: activity?.itpOrder,
      };
    });
  };

  const buildTree = async (disciplines: WorkBreakdownStructure[]) => {
    return (
      disciplines?.map((discipline, index) => {
        const orderText = `${index + 1}`;
        return {
          orderText,
          key: discipline.id,
          name: discipline.name,
          id: discipline.id,
          children: buildActivity(discipline, orderText).length > 0 ? buildActivity(discipline, orderText) : undefined,
          type: EInspectionTestPlanColumnKey.Discipline,
          order: discipline?.itpOrder,
        };
      }) || []
    );
  };
  useEffect(() => {
    (async () => {
      if (wbsLoading === 'pending' || itpLoading === 'pending') return;
      const activities = workBreakDownStructures?.filter((wbs) => {
        return inspectionTestPlan?.some((ips) => ips.activityId === wbs.id);
      });

      const discipline = workBreakDownStructures?.filter((wbs) => {
        return (
          (wbs.itpOrder && wbs.level === EWorkBreakDownStructureLevelID.Discipline) ||
          activities?.some((activity) => activity.parentId === wbs.id)
        );
      });

      const tree = await buildTree(discipline);
      setDataSource(tree);
    })();
  }, [itpLoading, wbsLoading]);

  const removeDataByKey = (dataSource: QualityITPData[], key: string) => {
    const result = [...dataSource].filter((data) => {
      if (data.children) {
        data.children = removeDataByKey(data.children, key);
      }
      return data.key !== key;
    });

    return result;
  };
  const handleDelete = async (ids: string[], key: string) => {
    try {
      if (ids.length > 0) {
        props.onLoading(true);
        setIsTableLoading(true);
        const wbsToDelete = ids
          ?.map((id) => {
            const wbsData = workBreakDownStructures.find((wbs) => wbs.id === id);
            if (!wbsData) return null;
            return {
              id: wbsData.id,
              name: wbsData?.name || '',
              itpOrder: null,
            };
          })
          .filter(Boolean);

        // Delete in WBS. The API needs to be improved to handle the deletion with a single request
        for (let index = 0; index < wbsToDelete.length; index++) {
          const wbs = wbsToDelete[index];
          await workBreakdownStructureService.updateWorkBreakdownStructure(
            workspaceId,
            projectId as string,
            wbs?.id as string,
            {
              name: wbs?.name || '',
              itpOrder: null,
            }
          );
        }

        // delete in ITP. The API needs to be improved to handle the deletion with a single request
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          await projectService.deleteInspectionTestPlans(workspaceId as string, projectId as string, id);
        }
      }
      const removedData = removeDataByKey(dataSource, key);
      setDataSource(removedData);
      message.success(t('Inspection test plan successfully deleted'));
    } catch (error) {
      console.error(error);
      message.error(t('Oop! Something wrong'));
    } finally {
      props.onLoading(false);
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    props.onChangedData(watchChangedData(dataSource));
  }, [dataSource]);

  return (
    <Form
      form={form}
      onFinish={handleSave}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      id='inspection-test-plan'
    >
      <div className='inspection-test-plan'>
        <Typography.Title className='text-size-14 mt-[-20px] !mb-[10px]' level={2}>
          {t(`Current Phase: `)}
          <Tag color={'orange'} className='tag-dashed-btn font-normal'>
            {mainPhase?.name?.toUpperCase() || ''}
          </Tag>
        </Typography.Title>
        <Form.Item name={'discipline'}>
          <SearchSelect
            className='mb-2 select'
            placeholder={
              <div>
                <PlusOutlined /> {t('Add Discipline')}
              </div>
            }
            options={disciplineOptions}
            onChange={(_, option) => {
              handleAddActivity(option as DefaultOptionType);
              form.setFieldValue('discipline', null);
            }}
            dynamicWidth
            loading={wbsLoading === 'pending'}
          />
        </Form.Item>

        <InspectionTestPlanTable
          onChangeValue={handleUpdateSource}
          dataSource={dataSource}
          onAddDataSource={setDataSource}
          getIncrementId={getIncrementId}
          isLoading={itpLoading === 'pending' || isTableLoading}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowKeys={setExpandedRowKeys}
          onDelete={handleDelete}
          formSubmit={formSubmit}
          onDragEnd={() => {
            handleSave();
          }}
        />
      </div>
    </Form>
  );
};

export default InspectionTestPlan;
