import { Company } from '../model';
import { BankFilled, SketchOutlined, ToolOutlined } from '@ant-design/icons';

const projectCompanyData: Company[] = [
  {
    id: '1',
    name: 'Lead tools corp',
    role: 'Plumbing',
    category: {
      key: '2',
      name: 'General contractor',
      code: '952',
    },
    representative: {
      id: '1',
      firstName: 'Nikolai',
      lastName: 'Tesla',
      email: 'nikolay.tesla@lightningcorp.com',
    },
    number_of_projects: 1,
    number_of_members: 4,
    icon: BankFilled,
    iconColor: '#237804',
  },
  {
    id: '2',
    name: 'Leading inc',
    role: 'Electricity and cleaning',
    category: {
      key: '3',
      name: 'Design & build contractor',
      code: '148',
    },
    representative: {
      id: '1',
      firstName: 'Nikolai',
      lastName: 'Tesla',
      email: 'nikolay.tesla@lightningcorp.com',
    },
    number_of_projects: 3,
    number_of_members: 7,
    icon: SketchOutlined,
    iconColor: '#D48806',
  },
  {
    id: '3',
    name: 'Leader',
    role: 'Steel strucure',
    category: {
      key: '1',
      name: 'MEP contractor',
      code: '236',
    },
    representative: {
      id: '1',
      firstName: 'Nikolai',
      lastName: 'Tesla',
      email: 'nikolay.tesla@lightningcorp.com',
    },
    number_of_projects: 2,
    number_of_members: 4,
    icon: ToolOutlined,
    iconColor: '#737373',
  },
];

export default projectCompanyData;
