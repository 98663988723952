import { Drawer, Form, Layout, Spin } from 'antd';
import {
  ProjectDocumentsTransmissionHeader,
  ProjectDocumentsTransmissionFooter,
  ProjectDocumentsTransmissionForm,
} from 'components';
import { useEffect, useMemo, useState } from 'react';
import useAuthContext from 'store/auth-context';
import { BaseIssue, BaseIssueWatcher, User } from 'model';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useProjectCompanies,
  useProjectCompaniesParams,
} from 'hooks';
import { TRA_STATUSES, INVITATION_ROLE, MODULE_FUNCTIONS, VALIDATION_STATUS } from 'utils/contants';
import { EDocumentsTransmissionActionType } from './ProjectDocumentsTransmissionCreateOrUpdate/ProjectDocumentsTransmissionFooter';
import { DocumentsTransmissionFormValues } from './ProjectDocumentsTransmissionCreateOrUpdate/ProjectDocumentsTransmissionForm';
import useUserEntity from 'hooks/common/useUserProfile';
import { baseIssueService } from 'services';
import { formatUrlAttachments } from 'utils';
import dayjs from 'dayjs';
import { useAppSelector } from 'store';
import { selectIssueStatuses } from 'store/common.slice';
import { useTranslation } from 'react-i18next';
import { useDeleteBaseIssue } from 'hooks/common/useDeleteBaseissue';
import { useNavigate } from 'react-router-dom';

type CreateOrUpdateDocumentsTransmissionProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  workspaceId: string;
  users: User[];
  projectId: string;
  baseIssueId?: string | null;
};

export default function CreateOrUpdateDocumentsTransmission(props: CreateOrUpdateDocumentsTransmissionProps) {
  const { isFormOpen, setIsFormOpen, workspaceId, users, projectId } = props;
  const [form] = Form.useForm<DocumentsTransmissionFormValues>();
  const { profile } = useAuthContext();
  const { user } = useUserEntity({ projectId, workspaceId });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBaseIssue, setSelectedBaseIssue] = useState<BaseIssue | null>();
  const [attachmentsInfo, setAttachmentsInfo] = useState<{ name: string; revision: string; filePath: string }[]>([]);

  const [companiesParams] = useProjectCompaniesParams({ projectId, workspaceId });
  const [companies] = useProjectCompanies(companiesParams);

  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.TRA,
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);

  const [attachmentTypeQuery] = useAttachmentTypesParams();
  useAttachmentTypes(attachmentTypeQuery);

  const issueStatuses = useAppSelector(selectIssueStatuses);

  const [moduleFunctionsParams] = useModuleFunctionParams();
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsParams);

  const { openDeleteConfirm } = useDeleteBaseIssue({
    title: t('Delete the Documents Transmission'),
    description: t('Are you sure to delete this Documents Transmission?'),
  });

  const isReadOnly = useMemo(() => {
    return selectedBaseIssue?.IssueStatus?.code === TRA_STATUSES.ISSUED;
  }, [selectedBaseIssue]);

  useEffect(() => {
    if (!isFormOpen) return;
    initializeDTForm();
  }, [isFormOpen, props?.baseIssueId]);

  const initializeDTForm = async () => {
    let baseIssue = null;
    if (props?.baseIssueId) {
      baseIssue = await fetchBaseIssueById(props?.baseIssueId);
      setSelectedBaseIssue(baseIssue);
      setDefaultValueDT(baseIssue);
    } else {
      setDefaultValueDT();
    }
  };

  const fetchBaseIssueById = async (baseIssueId: string) => {
    setIsLoading(true);
    try {
      const baseIssue = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'TRA',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      return baseIssue;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const groupByCompany = (distributions?: BaseIssueWatcher[]) => {
    if (!distributions || distributions?.length === 0) return [{ companyId: null, inspectorIds: [] }];
    const result = distributions.reduce<Record<string, { companyId: string; inspectorIds: string[] }>>(
      (init, distribution) => {
        if (!init[distribution.companyId]) {
          init[distribution.companyId] = { companyId: distribution.companyId, inspectorIds: [] };
        }
        init[distribution.companyId].inspectorIds.push(distribution.watcherId);
        return init;
      },
      {}
    );

    return Object.values(result);
  };

  const setDefaultValueDT = (selectedBaseIssue?: BaseIssue | null) => {
    const receiverData = selectedBaseIssue?.BaseIssueWatchers?.filter(
      (issueWatcher) => issueWatcher.role === INVITATION_ROLE.RECEIVER
    );
    const distributionData = selectedBaseIssue?.BaseIssueWatchers?.filter(
      (issueWatcher) => issueWatcher.role === INVITATION_ROLE.DISTRIBUTION
    );

    form.setFieldsValue({
      companyIssuer: selectedBaseIssue?.companyOriginatorId || user?.WorkspaceUser?.Company?.id,
      issuerName: selectedBaseIssue?.originatorId || user?.id,
      assigneeIds: receiverData?.map((receiver) => receiver.watcherId) || [],
      companyId: receiverData && receiverData?.length > 0 ? receiverData[0].companyId : null,
      comments: selectedBaseIssue?.description || '',
      invitations: groupByCompany(distributionData),
      subject: selectedBaseIssue?.name || '',
      issuePriorityId: selectedBaseIssue?.DocumentTransmission?.priorityId || issuePriorities[0]?.id,
      transmissionDate: selectedBaseIssue?.DocumentTransmission?.transmissionDate
        ? dayjs(selectedBaseIssue?.DocumentTransmission?.transmissionDate)
        : dayjs(),
      transmittalDocuments:
        selectedBaseIssue?.DocumentTransmission?.DtAttachment?.map((dtAttachment) => {
          return {
            documentName: dtAttachment?.name,
            filePath: dtAttachment?.filePath,
            name: formatUrlAttachments(dtAttachment?.filePath || ''),
            revision: dtAttachment?.RefAttachment?.revision,
            id: dtAttachment.id,
          };
        }) || [],
    });
  };

  const onClose = () => {
    setIsFormOpen(false);
    setSelectedBaseIssue(null);
    form.resetFields();
  };
  const generateDataByStatus = (isSaveAsDraft: boolean) => {
    if (isSaveAsDraft) {
      return {
        isSaveAsDraft: true,
        statusId: issueStatuses.find((status) => status.code === TRA_STATUSES.DRAFT)?.id,
      };
    }

    return {
      isSaveAsDraft: false,
      statusId: issueStatuses.find((status) => status.code === TRA_STATUSES.ISSUED)?.id,
      dtValidation: {
        validatorId: user?.id,
        validatorCompanyId: user?.WorkspaceUser?.Company?.id,
        validationAction: VALIDATION_STATUS.SEND_TO_RECIPIENT,
        validationStatus: VALIDATION_STATUS.SEND_TO_RECIPIENT,
        revision: 0,
      },
    };
  };

  const getDistributionAttachment = (formValues: DocumentsTransmissionFormValues) => {
    const receivers = {
      companyId: formValues.companyId as string,
      inspectorIds: formValues.assigneeIds,
      role: INVITATION_ROLE.RECEIVER,
    };
    const distributions =
      formValues?.invitations
        ?.filter((invitation) => invitation?.companyId)
        ?.map((invitation) => {
          return {
            companyId: invitation?.companyId,
            inspectorIds: invitation?.inspectorIds,
            role: INVITATION_ROLE.DISTRIBUTION,
          };
        }) || [];
    if (receivers?.companyId) {
      return [receivers, ...distributions];
    }
    return distributions;
  };

  const generateIssuesPayload = (formValues: DocumentsTransmissionFormValues, isSaveAsDraft: boolean) => {
    const moduleFunctionId =
      moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.WIR)?.id || null;
    const statusData = generateDataByStatus(isSaveAsDraft);

    return {
      name: formValues.subject,
      isSaveAsDraft: statusData?.isSaveAsDraft,
      originatorId: user?.id,
      companyOriginatorId: user?.WorkspaceUser?.Company?.id,
      statusId: statusData?.statusId,
      moduleFunctionId: moduleFunctionId,
      description: formValues?.comments,
      dt: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        transmissionDate: formValues?.transmissionDate,
        priorityId: formValues.issuePriorityId,
        dtAttachmentInfo:
          formValues.transmittalDocuments?.length > 0
            ? formValues.transmittalDocuments?.map((document) => {
                return {
                  name: document?.documentName,
                  filePath: document?.filePath,
                  revision: document?.revision,
                  id: document?.id.includes('rc') ? undefined : document?.id,
                };
              })
            : undefined,
        dtDistributions:
          getDistributionAttachment(formValues).length > 0 ? getDistributionAttachment(formValues) : undefined,
        dtValidation: statusData.dtValidation,
      },
    };
  };

  const submitDocumentTransmission = async (isSaveAsDraft: boolean) => {
    try {
      setIsLoading(true);
      await form.validateFields(isSaveAsDraft ? ['subject'] : undefined);
      const formValues = form.getFieldsValue(true) as DocumentsTransmissionFormValues;
      const result = generateIssuesPayload(formValues, isSaveAsDraft);

      if (selectedBaseIssue?.id) {
        await baseIssueService.updateBaseIssue(workspaceId, projectId, selectedBaseIssue.id, result);
      } else {
        await baseIssueService.createBaseIssue(workspaceId, projectId, result);
      }
      onClose();
      navigate('?tab=outgoing');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionClick = (actionType: EDocumentsTransmissionActionType) => {
    switch (actionType) {
      case EDocumentsTransmissionActionType.SaveAsDraft:
        submitDocumentTransmission(true);
        break;
      case EDocumentsTransmissionActionType.SendToRecipient:
        submitDocumentTransmission(false);
        break;
      case EDocumentsTransmissionActionType.Delete:
        openDeleteConfirm(workspaceId, projectId, selectedBaseIssue?.id || '').then(onClose);
        break;
      default:
        break;
    }
  };

  return (
    <Layout.Content className='material-approval-request-create-modal'>
      <Form
        initialValues={{
          transmittalDocuments: [],
        }}
        form={form}
        labelCol={{ span: 24 }}
        layout='vertical'
      >
        <Drawer
          styles={{
            header: {
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={800}
          className={'document-management-form-drawer'}
          onClose={onClose}
          closeIcon={false}
          open={isFormOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <ProjectDocumentsTransmissionHeader
              selectedBaseIssue={selectedBaseIssue}
              user={user}
              isUnSaveStatus={true}
              onClose={onClose}
              profile={profile}
            />
          }
          footer={
            <ProjectDocumentsTransmissionFooter
              issueStatus={selectedBaseIssue?.IssueStatus}
              onActionClick={handleActionClick}
              isReadOnly={isReadOnly}
            />
          }
        >
          <Spin spinning={isLoading}>
            <ProjectDocumentsTransmissionForm
              isOpen={isFormOpen}
              workspaceId={workspaceId}
              projectId={projectId}
              users={users}
              profile={profile}
              companies={companies}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={(value: { name: string; revision: string; filePath: string }[]) =>
                setAttachmentsInfo(value)
              }
              issuePriorities={issuePriorities}
              isReadOnly={isReadOnly}
            />
          </Spin>
        </Drawer>
      </Form>
    </Layout.Content>
  );
}
