import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import type { TimeRangePickerProps } from 'antd';
import { useTranslation } from 'react-i18next';

export default function DatePickerRange(props: any) {
  const { ...otherProps } = props;
  const { t } = useTranslation();

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: t('Yesterday'), value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: t('Last 7 Days'), value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: t('Last 14 Days'), value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: t('Last 30 Days'), value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: t('Last 90 Days'), value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <DatePicker.RangePicker
      placeholder={[t('From date'), t('To date')]}
      presets={rangePresets}
      {...otherProps}
    />
  );
}
