import { Category } from 'model';
import request from 'requesters/core.request';
import { PaginationData } from 'types';

const categoryService = {
  getProjectCategories: async (workspaceId: string, projectId: string, query: any) => {
    return request.get<PaginationData<Category>>(`/workspaces/${workspaceId}/projects/${projectId}/categories`, {
      params: query,
    });
  },
};

export default categoryService;
