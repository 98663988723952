import { Typography } from 'antd';
import EditableFormField from 'components/common/EditableFormField';
import { t } from 'i18next';
import BaseIssueMomCategory from 'model/BaseIssueMomCategory';
import React, { useMemo } from 'react';
import { EProjectMinutesOfMeetingFieldNames } from 'types/toolbox';

type ProjectMinutesOfMeetingInformationProps = {
  isReadonly?: boolean;
  category: BaseIssueMomCategory[];
};
const ProjectMinutesOfMeetingInformation: React.FC<ProjectMinutesOfMeetingInformationProps> = (
  props: ProjectMinutesOfMeetingInformationProps
) => {
  const categoryOptions = useMemo(() => {
    return props.category.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [props.category]);
  return (
    <React.Fragment>
      <Typography.Title level={3} className='form-title'>
        {t('Meeting General Information')}
      </Typography.Title>
      <EditableFormField
        isReadonly={props.isReadonly}
        columns={[
          {
            label: t('Subject'),
            name: EProjectMinutesOfMeetingFieldNames.Subject,
            rules: [{ required: true, message: '' }],
            type: 'input',
            editableProps: {
              showCount: true,
              maxLength: 100,
              allowClear: true,
              placeholder: t('Please enter subject'),
            },
          },
          {
            label: t('Category'),
            name: EProjectMinutesOfMeetingFieldNames.Category,
            rules: [{ required: true, message: '' }],

            type: 'select',
            editableProps: {
              placeholder: t('Select Category'),
              options: categoryOptions,
            },
          },
          {
            label: `Meeting Date`,
            name: EProjectMinutesOfMeetingFieldNames.MeetingDate,
            rules: [{ required: true, message: '' }],
            type: 'date',
            editableProps: {
              placeholder: t('Please select date'),
            },
          },
          {
            label: t('Meeting Location'),
            name: EProjectMinutesOfMeetingFieldNames.MeetingLocation,
            type: 'input',
            editableProps: {
              allowClear: true,
              placeholder: t('Please enter location'),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};

export default ProjectMinutesOfMeetingInformation;
