import { Button, Col, Form, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RFIFormValues } from './RequestForInformationForm';
import { BaseIssue, User } from 'model';
import { RFI_STATUSES } from 'utils/contants';

export enum ERFIActionType {
  SendToRecipient = 'SendToRecipient',
  SaveAsDraft = 'SaveAsDraft',
  DeleteForm = 'DeleteForm',
  Submit = 'Submit',
  Close = 'Close',
  OpenTransfer = 'OpenTransfer',
  AddUser = 'AddUser',
  EditForm = 'EditForm',
  EditThread = 'EditThread',
  Cancel = 'Cancel',
  Save = 'Save',
  DeleteThread = 'DeleteThread',
}

type Props = {
  onActionClick: (type: ERFIActionType) => void;
  user?: User | null;
  selectedBaseIssue?: BaseIssue | null;
};

export default function RequestForInformationFooter(props: Props) {
  const { t } = useTranslation();
  const formWatchedValue = Form.useWatch<RFIFormValues>([]);

  const openOrCloseStatus = useMemo(
    () => ![RFI_STATUSES.OPEN, RFI_STATUSES.CLOSED].includes(props.selectedBaseIssue?.IssueStatus?.code || ''),
    [props.selectedBaseIssue]
  );

  const isOpenStatus = useMemo(() => {
    return props.selectedBaseIssue?.IssueStatus?.code === RFI_STATUSES.OPEN;
  }, [props.selectedBaseIssue]);

  const isEnableSendButton = useMemo(() => {
    return !(
      formWatchedValue?.subject &&
      formWatchedValue?.priorityId &&
      formWatchedValue?.disciplineId &&
      formWatchedValue?.receiver?.companyId &&
      formWatchedValue?.receiver?.userIds &&
      formWatchedValue?.receiver?.userIds?.length > 0
    );
  }, [formWatchedValue]);

  return (
    <Row gutter={16} justify={'end'} align='middle'>
      <Col span={12} style={{ textAlign: 'right' }}>
        {openOrCloseStatus && (
          <Button onClick={() => props.onActionClick(ERFIActionType.SaveAsDraft)}>{t('Save as Draft')}</Button>
        )}
        {openOrCloseStatus && (
          <Button
            disabled={isEnableSendButton}
            onClick={() => props.onActionClick(ERFIActionType.SendToRecipient)}
            type='primary'
          >
            {t('Send to Recipient(s)')}
          </Button>
        )}
        {isOpenStatus && (
          <div>
            <Button onClick={() => props.onActionClick(ERFIActionType.Cancel)}>{t('Cancel')}</Button>
            <Button type='primary' onClick={() => props.onActionClick(ERFIActionType.Save)}>
              {t('Save')}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
}
