import * as d3 from 'd3';
import { FC, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from './useResizeObserver';
import { useTranslation } from 'react-i18next';

const PieChart: FC<IChart> = ({ data, svgWrapperRef, padding, loading }) => {
  const { t } = useTranslation();
  const dimensions: any = useResizeObserver(svgWrapperRef);
  const svgRef = useRef<SVGSVGElement>(null);

  const svg = d3.select(svgRef?.current);
  const DEFAULT_OPACITY = 1;

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', 'rgba(0,0,0,0.6)')
    .style('color', '#fff')
    .style('padding', '10px')
    .style('border-radius', '5px')
    .style('pointer-events', 'none');

    const pieGenerator = d3
      .pie<IData>()
      .value(({ count }) => count)
      .sort(null);

    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.name))
      .range(d3.quantize((t) => d3.interpolateBlues(t * 0.8 + 0.2), data.length).reverse());
    function getBrightness(color: any) {
      const rgb = (d3 as any).color(color).rgb();
      return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    }

  // Add animation when data changed
  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;

    const innerWidth = dimensions?.width / 1.5;
    const innerHeight = dimensions?.height;
    const radius = Math.min(innerWidth, innerHeight) / 1.7;

    svg.selectAll('*').remove();

    const arcGenerator = d3
      .arc<d3.PieArcDatum<IData>>()
      .innerRadius(0)
      .outerRadius(radius - padding);

    const slices = pieGenerator([...data]);

    // Draw the pie
    if (loading === 'idle') {
      svg
        .attr('width', innerWidth)
        .attr('height', innerHeight)
        .append('g')
        .attr('transform', `translate(${innerWidth / 2}, ${innerHeight / 2})`)
        .selectAll('path')
        .data(slices)
        .join('path')
        // .attr('fill', (d) => d?.data?.fillColor)
        .attr('fill', (d: any) => color((d as unknown as any).data.name) as any)
        .attr('d', arcGenerator)
        // Add animation for path
        .transition()
        .duration(1000)
        .attrTween('d', function (d) {
          const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
          return function (t) {
            return arcGenerator(interpolate(t))!;
          };
        });
      // Draw the arc text
      svg
        .append('g')
        .attr('transform', `translate(${innerWidth / 2.05}, ${innerHeight / 1.95})`)
        .selectAll('text')
        .data(slices)
        .join('text')
        .attr('transform', (d) => {
          const [x, y] = arcGenerator.centroid(d);
          const offset = 1.5; // Adjust this value to move the text farther or closer
          return `translate(${x * offset}, ${y * offset})`;
        })
        .style('font-size', 14)
        .attr('dy', '5px')
        .attr('fill', (d) => {
          const backgroundColor = color((d.data as any).name);
          return getBrightness(backgroundColor) > 128 ? 'black' : 'white';
        })
        .text((d) => d?.data?.count)
        .attr('opacity', 0)
        // Add animation for text
        .transition()
        .delay(500)
        .duration(1000)
        .attr('opacity', 1);
    }

    // Draw the legends box
    svg
      .selectAll('.legends')
      .data(slices)
      .join('rect')
      .attr('transform', `translate(${innerWidth}, ${-padding})`)
      .attr('y', (d, i) => i * 15 + 10)
      .attr('width', 12)
      .attr('height', 12)
      .attr('fill', (d: any) => color((d as unknown as any).data.name) as any);
      // .style('fill', (d) => d?.data?.fillColor);

    // Draw the legends text
    svg
      .selectAll('.legends-text')
      .data(slices)
      .join('text')
      .attr('transform', `translate(${innerWidth + 20}, ${-padding})`)
      .attr('y', (d, i) => i * 15 + 20)
      .text((d) => t(d?.data?.name)+` (${d?.data?.count})`)
      .style('font-size', 10)
      .style('fill', 'black');
  }, [data]);

  // Responsive chart when window is resized
  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;

    const innerWidth = dimensions?.width / 1.5;
    const innerHeight = dimensions?.height;
    const radius = Math.min(innerWidth, innerHeight) / 1.8;

    svg.selectAll('*').remove();

    const arcGenerator = d3
      .arc<d3.PieArcDatum<IData>>()
      .innerRadius(0)
      .outerRadius(radius - padding);

    const slices = pieGenerator([...data]);

    // Draw the pie
    if (loading === 'idle') {
      svg
        .attr('width', innerWidth)
        .attr('height', innerHeight)
        .append('g')
        .attr('transform', `translate(${innerWidth / 2}, ${innerHeight / 2})`)
        .selectAll('path')
        .data(slices)
        .join('path')
        // .attr('fill', (d) => d?.data?.fillColor)
        .attr('fill', (d: any) => color((d as unknown as any).data.name) as any)
        .attr('d', arcGenerator);
    }
    // Draw the arc text
    svg
      .append('g')
      .attr('transform', `translate(${innerWidth / 2.05}, ${innerHeight / 1.95})`)
      .selectAll('text')
      .data(slices)
      .join('text')
      .attr('transform', (d) => {
        const [x, y] = arcGenerator.centroid(d);
        const offset = 1.5; // Adjust this value to move the text farther or closer
        return `translate(${x * offset}, ${y * offset})`;
      })
      .style('font-size', 14)
      .attr('dy', '5px')
      .attr('fill', (d) => {
        const backgroundColor = color((d.data as any).name);
        return getBrightness(backgroundColor) > 128 ? 'black' : 'white';
      })
      .text((d) => d?.data?.count);

    // Draw the legends box
    svg
      .selectAll('.legends')
      .data(slices)
      .join('rect')
      .attr('transform', `translate(${innerWidth}, ${-padding})`)
      .attr('y', (d, i) => i * 15 + 10)
      .attr('width', 12)
      .attr('height', 12)
      .attr('fill', (d: any) => color((d as unknown as any).data.name) as any);
    // .style('fill', (d) => d?.data?.fillColor);

    // Draw the legends text
    svg
      .selectAll('.legends-text')
      .data(slices)
      .join('text')
      .attr('transform', `translate(${innerWidth + 20}, ${-padding})`)
      .attr('y', (d, i) => i * 15 + 20)
      .text((d) => t(d?.data?.name) + ` (${d?.data?.count})`)
      .style('font-size', 10)
      .style('fill', 'black');
  }, [dimensions]);

  return (
    <div className='d3js'>
      <svg ref={svgRef} />
    </div>
  );
};

interface IData {
  name: string;
  count: number;
  fillColor: string;
}

interface IChart {
  data: IData[];
  svgWrapperRef: any;
  padding: number;
  loading?: any;
}

export default PieChart;
