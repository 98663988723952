import { DeleteOutlined, PlusOutlined, PushpinFilled } from '@ant-design/icons';
import { Button, Flex, Tag, message, Modal, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import workBreakdownStructureService from 'services/work-breakdown-structure.services';
import { Typography } from 'antd';

export default function ProjectWorkBreakdownStructureTreeTitle(props: {
  nodeTitle: string;
  nodeKey: string;
  nodeCode: string;
  nodeColor: string;
  workspaceId: string;
  projectId: string;
  order: number | undefined;
  workBreakdownParent: string | null;
  currentLevel: number;
  isCustom: boolean;
  isMain: boolean;
  refreshWorkBreakdownStructure: () => void;
}) {
  const {
    nodeColor,
    nodeTitle,
    nodeCode,
    nodeKey,
    order,
    workspaceId,
    projectId,
    refreshWorkBreakdownStructure,
    currentLevel,
    isCustom,
    isMain,
  } = props;
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const levels = {
    0: 'Phase',
    1: 'Discipline',
    2: 'Activity',
  };

  const handleSaveChanges = async () => {
    try {
      const values = await form.validateFields();
      const { name, code } = values;

      if (isAdd) {
        if (currentLevel >= 2) {
          message.error(t(`Can't add more levels!`));
          setIsAdd(false);
          setIsHovered(false);
          setModalVisible(false);
          return;
        }
        const data: { name: string; code: string; order?: number | undefined; parentId?: string | null } = {
          name,
          code,
          order: order,
          parentId: nodeKey === 'root' ? null : nodeKey,
        };
        await workBreakdownStructureService.createWorkBreakdownStructure(workspaceId, projectId, data);
        message.success(t(`Add ${levels[(currentLevel + 1) as keyof typeof levels]} success!`));
      } else if (isEdit) {
        const data = { name, code };
        await workBreakdownStructureService.updateWorkBreakdownStructure(workspaceId, projectId, nodeKey, data);
        message.success(t(`Update ${levels[currentLevel as keyof typeof levels]} success!`));
      }
      refreshWorkBreakdownStructure();
      form.resetFields();
      setIsEdit(false);
      setIsAdd(false);
      setIsHovered(false);
      setModalVisible(false);
    } catch (error) {
      setIsAdd(false);
      setIsEdit(false);
      setIsHovered(false);
      setModalVisible(false);
      message.error(t((error as Error).message));
    }
  };

  const onDelete = async () => {
    try {
      await workBreakdownStructureService.deleteWorkBreakdownStructure(workspaceId, projectId, nodeKey);
      message.success(t(`Delete ${levels[currentLevel as keyof typeof levels]} success!`));
      refreshWorkBreakdownStructure();
    } catch (error) {
      message.error(t((error as Error).message));
    }
  };

  const onSetMain = async () => {
    try {
      const data = {
        name: nodeTitle,
        isMain: true,
      };
      await workBreakdownStructureService.updateWorkBreakdownStructure(workspaceId, projectId, nodeKey, data);
      message.success(t(`Set ${nodeTitle} as main phase success!`));
      refreshWorkBreakdownStructure();
    } catch (error) {
      message.error(t((error as Error).message));
    }
  };

  const getAddText = () => {
    if (nodeKey === 'root') {
      return t('Add Phase');
    } else if (currentLevel === 0) {
      return t('Add Discipline');
    } else if (currentLevel === 1) {
      return t('Add Activity');
    } else {
      return t('');
    }
  };

  const openModal = (isEditMode: boolean) => {
    setIsEdit(isEditMode);
    setIsAdd(!isEditMode);
    setModalVisible(true);

    if (isEditMode) {
      form.setFieldsValue({ name: nodeTitle, code: nodeCode }); // Populate the form with existing data (assuming code is empty for existing)
    } else {
      form.resetFields(); // Reset the form for adding new item
    }
  };

  const handleFormChange = () => {
    const { name, code } = form.getFieldsValue();
    // Check if both fields are filled
    if (name && ((code && code.length === 2) || (isAdd && currentLevel === -1) || (isEdit && currentLevel === 0))) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Flex
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => (isAdd ? setIsHovered(true) : setIsHovered(false))}
      justify='space-between'
      min-width='400px'
    >
      <div style={{ width: '700px' }}>
        <Tag
          onClick={() => {
            if (isCustom) {
              openModal(true);
            }
          }}
          color={nodeColor}
        >
          {nodeTitle}
          {isMain && <PushpinFilled style={{ marginLeft: '5px' }} />}
        </Tag>
        {isMain ? (
          <span>
            <Typography.Text type='secondary' style={{ textTransform: 'none', fontStyle: 'italic', fontWeight: '400' }}>
              {'Main'}
            </Typography.Text>
          </span>
        ) : (
          <span>
            <Typography.Text style={{ textTransform: 'none', fontStyle: 'italic', fontWeight: '400' }}>
              {nodeCode}
            </Typography.Text>
          </span>
        )}
        {isHovered && (
          <span style={{ width: '100%' }}>
            {currentLevel === 0 && !isMain && (
              <Button className='set-main-btn' onClick={onSetMain}>
                <PushpinFilled />
                {t('Set as main')}
              </Button>
            )}
            {currentLevel < 2 && (
              <span
                onClick={() => openModal(false)}
                style={{ textTransform: 'capitalize', fontSize: '14px', alignItems: 'center', marginLeft: '5px' }}
              >
                <PlusOutlined /> {getAddText()}
              </span>
            )}
            {isCustom && !isMain && (
              <DeleteOutlined className='wbs-delete-outlined' onClick={onDelete} style={{ fontSize: '16px' }} />
            )}
          </span>
        )}
      </div>

      <Modal
        title={
          isEdit
            ? t(`Edit ${levels[currentLevel as keyof typeof levels]}`)
            : t(`Add ${levels[(currentLevel + 1) as keyof typeof levels]}`)
        }
        open={modalVisible}
        onOk={handleSaveChanges}
        okButtonProps={{ disabled: isSubmitDisabled }}
        onCancel={() => {
          setModalVisible(false);
          setIsAdd(false);
          setIsEdit(false);
        }}
      >
        <Form
          form={form}
          layout='inline'
          onValuesChange={handleFormChange} // Listen to changes in form fields
        >
          <Form.Item
            label={
              isEdit
                ? t(`${levels[currentLevel as keyof typeof levels]} name`)
                : t(`${levels[(currentLevel + 1) as keyof typeof levels]} name`)
            }
            name='name'
            rules={[{ required: true, message: t('Please input the name!') }]}
          >
            <Input maxLength={50} style={{ width: '180px' }} />
          </Form.Item>
          {((isEdit && currentLevel !== 0) || (isAdd && currentLevel !== -1)) && (
            <Form.Item
              label={
                isEdit
                  ? t(`${levels[currentLevel as keyof typeof levels]} code`)
                  : t(`${levels[(currentLevel + 1) as keyof typeof levels]} code`)
              }
              name='code'
              rules={[
                { required: true, message: t('Please input the code!') },
                { len: 2, message: t('Must be 2 characters!') },
              ]}
            >
              <Input
                maxLength={2}
                style={{ width: '100px', textTransform: 'uppercase' }}
                onChange={(e) => {
                  const upperCaseValue = e.target.value.toUpperCase();
                  form.setFieldsValue({ code: upperCaseValue });
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Flex>
  );
}
