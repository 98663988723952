import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

dayjs.extend(relativeTime)


/**
 * Calculates the time ago from the given date.
 *
 * @param {string} date - The date to calculate the time ago from.
 * @return {string} The time ago from the given date.
 */
export const getTimeAgoFromNow = (date:string) => {
  return dayjs(date).fromNow()
};
