import { useMemo } from 'react';
import { QueryParams } from 'types';
import { IssueStatus } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchIssueStatuses, selectIssueStatuses } from 'store/common.slice';

export const useIssueStatusesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();
  const moduleFunction = options?.moduleFunction;
  return useMemo(() => {
    const queryParams = { ...query, ...options, orderBy: 'orderNumber', limit: 'unlimited' };

    return [queryParams];
  }, [moduleFunction]);
};

export const useIssueStatuses = (query: QueryParams) => {
  return useFetchByParams<IssueStatus[]>({
    action: fetchIssueStatuses,
    dataSelector: selectIssueStatuses,
    params: query,
  });
};
