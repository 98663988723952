import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Layout, ModalProps, Row, message, Typography, Flex } from 'antd';
import { LocationPlan, Modal, ZoneTree, MotionCard, PlanItem } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { attachmentService } from 'services';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { Attachment, IssueAttachment, IssueStatus, Zone } from 'model';
import { LeftOutlined } from '@ant-design/icons';
import noPlan from 'assets/images/icons/no-plan.png';
import { LayerData } from 'types';
import { getLayerType } from 'utils';

const { Content, Sider } = Layout;
const { Text } = Typography;

type PinInspectionProps = {
  isModalOpen: boolean | number;
  zoneId: string;
  onSubmit: (zoneId: string | null, plan: IssueAttachment | null, planImageData: Attachment | null) => void;
  setIsModalOpen: (value: boolean) => void;
  loading?: boolean;
  inspectionName?: string;
  plan: IssueAttachment | null;
  planImage: Attachment | null;
  module?: string;
  issueStatuses: IssueStatus[];
  zones: Zone[];
} & ModalProps;

export default function PinInspection(props: PinInspectionProps) {
  const { isModalOpen, setIsModalOpen, plan, onSubmit, planImage, inspectionName, module, issueStatuses, zones } =
    props;
  const { t } = useTranslation();
  const params = useParams();
  const projectId = params.projectId as string;
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;
  const [currentStep, setCurrentStep] = useState(0);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [zoneId, setZoneId] = useState<string | null>(null);
  const [layerData, setLayerData] = useState<LayerData | null>(null);
  const [selectedPlanImage, setSelectedPlanImage] = useState<Attachment | null>(planImage);

  useEffect(() => {
    setSelectedPlanImage(planImage);
  }, [planImage]);

  const initLayerData = () => {
    if (plan?.layerData) {
      setLayerData(plan.layerData);
    } else {
      setLayerData(null);
    }
  };

  useEffect(() => {
    initLayerData();
  }, [plan]);

  const getAttachments = async () => {
    try {
      const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
        attachmentType: ATTACHMENT_TYPES.LOCATION_PLAN,
        limit: 'unlimited',
      });
      setAttachments(rows);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  useEffect(() => {
    getAttachments();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setZoneId(props?.zoneId);
      if (isModalOpen === 1) {
        setSelectedPlanImage(planImage);
        initLayerData();
        setTimeout(() => {
          setCurrentStep(1);
        });
      }
    } else {
      setCurrentStep(0);
    }
  }, [isModalOpen, props?.zoneId]);

  const onSave = (isSkipPlan = false) => {
    if (currentStep === 0 && selectedPlanImage) {
      setCurrentStep(currentStep + 1);
    } else {
      onSubmit(
        zoneId,
        selectedPlanImage
          ? {
              attachmentId: selectedPlanImage?.id,
              layerType: isSkipPlan || !layerData ? null : getLayerType(layerData),
              layerData: isSkipPlan || !layerData ? null : layerData,
            }
          : null,
        selectedPlanImage
      );
      setIsModalOpen(false);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    setCurrentStep(0);
    setIsModalOpen(false);
  };

  const onChangeZone = (zoneId: string | null) => {
    setZoneId(zoneId);
    setLayerData(null);
    setSelectedPlanImage(null);
  };

  const onChangePlan = (plan: Attachment | null) => {
    setSelectedPlanImage(plan);
    setLayerData(null);
  };

  const attachmentOfZone = useMemo(() => {
    return attachments?.filter((attachment) => attachment.zoneId === zoneId);
  }, [attachments, zoneId]);

  const locationWidth = 800;
  const aspectRatio =
    selectedPlanImage?.height && selectedPlanImage.width ? selectedPlanImage.height / selectedPlanImage.width : 3 / 4;
  const locationHeight = locationWidth * aspectRatio;

  return (
    <Modal
      title={
        currentStep === 0 ? (
          t('Set zoning + add location plan')
        ) : (
          <>
            <LeftOutlined onClick={prevStep} style={{ marginRight: 15, fontSize: 16 }} />
            {t('Pin inspection on plan')}
          </>
        )
      }
      open={!!isModalOpen}
      onCancel={handleCancel}
      width={locationWidth + 96}
      classNames={{
        body: 'pin-inspection-plan-modal',
        content: module && module === 'Safety' ? 'safety-light' : module === 'Quality' ? 'quality-light' : '',
      }}
      style={{ top: 20 }}
      footer={[
        <Flex key='submit' justify={currentStep === 0 ? 'flex-end' : 'space-between'}>
          {currentStep > 0 ? (
            <Button type='dashed' onClick={() => onSave(true)} key='skip'>
              {t('Skip')}
            </Button>
          ) : null}
          <div>
            <Button onClick={handleCancel} key='back'>
              {t('Cancel')}
            </Button>
            <Button onClick={() => onSave(false)} key='submit' type='primary'>
              {currentStep === 0 ? t('Select') : t('Validate Location')}
            </Button>
          </div>
        </Flex>,
      ]}
    >
      <div className='header-information'>
        {currentStep === 0 ? (
          <Text>{t('Select a zone and a plan')}</Text>
        ) : (
          <>
            <Trans
              i18nKey={`Click on the <b>South section</b> plan to pin the <b>${inspectionName}</b> ${module && module === 'Safety' ? t('inspection') : t('observation')}`}
              components={{ b: <strong /> }}
            />
          </>
        )}
      </div>
      {currentStep === 0 ? (
        <Layout className='bg-white'>
          <Sider className='pa-3 mt-6 mb-3 zoning-settings-sider border-right'>
            <ZoneTree selectedZoneId={zoneId} zones={zones} onSelectZone={onChangeZone} />
          </Sider>
          <Content>
            <div className='workspaces photos mt-6 pl-4 pt-0'>
              <Row gutter={16} style={{ width: '100%' }}>
                {attachmentOfZone?.map((attachment, index) => (
                  <Col xs={24} sm={12} className='full-width-col' key={attachment.id}>
                    <MotionCard index={index} style={{ marginBottom: '15px' }} onClick={() => onChangePlan(attachment)}>
                      <PlanItem
                        module={module}
                        isHidePin={true}
                        size='sm'
                        isActive={selectedPlanImage?.id === attachment.id}
                        attachment={attachment}
                      />
                    </MotionCard>
                  </Col>
                ))}
                <Col xs={24} sm={12}>
                  <Card
                    hoverable
                    className={`location-item create-btn ${selectedPlanImage === null ? 'active' : ''} ${module && module === 'Safety' ? 'safety' : module === 'Quality' ? 'quality' : ''}`}
                    onClick={() => onChangePlan(null)}
                  >
                    <img src={noPlan} alt='' width={22} />
                    {t('No plan')}
                  </Card>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      ) : (
        <LocationPlan
          width={locationWidth}
          height={locationHeight}
          data={layerData}
          onChange={setLayerData}
          issueStatuses={issueStatuses}
          imageHeight={selectedPlanImage?.height as number}
          imageWidth={selectedPlanImage?.width as number}
          imageUrl={selectedPlanImage?.filePath as string}
          module={module ? module : ''}
        />
      )}
    </Modal>
  );
}
