import { CommentOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Form, message, Spin, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from 'store/auth-context';
import { baseIssueService, baseValidationService } from 'services';
import { BaseIssue, IssueStatus, MethodStatementSubmission, ValidationHistory } from 'model';
import {
  MSS_STATUSES,
  MODULE_FUNCTIONS,
  TASK_STATUS_HEADER_COLOR,
  VALIDATION_ACTION,
  VALIDATION_STATUS,
  VALIDATION_STEP_TYPE,
} from 'utils/contants';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueTypes,
  useIssueTypesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useMyWorkspaceWorkPackagesParams,
  useProjectCategories,
  useProjectCategoriesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectPhases,
  useProjectPhasesParams,
  useProjectSafetyRiskActivities,
  useProjectSafetyRiskActivitiesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectWorkPackages,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { MSSsDetail, MSSsFooter, MSSsHeader, MSSsHistory, ValidationHistoryStep } from 'components';
import ValidationPopup from '../common/ValidationPopup';
import { DataNode } from 'antd/es/tree';
import { convertZoneToTreeData } from 'utils';
import { findPath } from 'components/common/TreeSelectTagRender';
import useUserEntity from 'hooks/common/useUserProfile';

type MssValidationType = {
  validatorId: string | null;
  validatorCompanyId: string | null;
  prevStepId: string | null;
  prevStepType: string | null;
  prvValidatorCompanyId: string;
  prvValidatorId: string;
  validationAction: string;
  validationStatus: string;
  comment: string | null;
  revision: number;
};

type CreateOrUpdateMSSsProps = {
  isOpen: boolean;
  module: string;
  workspaceId: string;
  projectId: string;
  baseIssueId: string | null;
  issueStatuses: IssueStatus[] | null;
  setOpen: (value: boolean) => void;
  refreshBaseIssues: (disciplineId: string) => void;
  setBaseIssueId: (value: string | null) => void;
};
export type WorkspaceViewType = 'detail' | 'comments';

export default function CreateOrUpdateMSS(props: CreateOrUpdateMSSsProps) {
  const { t } = useTranslation();
  const {
    isOpen,
    module,
    workspaceId,
    projectId,
    // users,
    baseIssueId,
    issueStatuses,
    setOpen,
    refreshBaseIssues,
    setBaseIssueId,
  } = props;
  const { profile } = useAuthContext();
  const [form] = Form.useForm();

  const [issueTypesQuery] = useIssueTypesParams({ module });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  useAttachmentTypes(attachmentTypeQuery);
  const { user } = useUserEntity({ projectId, workspaceId });

  const [zones] = useProjectZones(zonesParams);
  const [zoneUpdated, setZoneUpdated] = useState<string>(zones?.[0]?.id);
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [phasesParams] = useProjectPhasesParams({ workspaceId, projectId });
  const [phases] = useProjectPhases(phasesParams);
  const [workPackageParams] = useMyWorkspaceWorkPackagesParams({ workspaceId, projectId });
  const [workPackages] = useProjectWorkPackages(workPackageParams);
  const [categoryParams] = useProjectCategoriesParams({ workspaceId, projectId, orderBy: 'order', limit: 'unlimited' });
  const [categories] = useProjectCategories(categoryParams);
  const [safetyRiskActivitiesParams] = useProjectSafetyRiskActivitiesParams({
    workspaceId,
    projectId,
    orderBy: 'order,asc',
  });
  const [safetyRiskActivities] = useProjectSafetyRiskActivities(safetyRiskActivitiesParams);
  const [moduleFunctionsParams] = useModuleFunctionParams();
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsParams);
  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(queryUser);

  const currentUser = (users || []).find((u) => u.id === profile?.id);

  const [selectedMssBaseIssue, setSelectedMssBaseIssue] = useState<BaseIssue | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [isDisableStartValidation, setIsDisableStartValidation] = useState<boolean>(true);
  const formValuesWatch = Form.useWatch([], form);
  const [dataFormMSSsDetails, setDataFormMSSsDetails] = useState<(MethodStatementSubmission & BaseIssue) | null>(null);
  const [requestName, setRequestName] = useState('');
  const [isMSSsModalOpen, setIsMSSsModalOpen] = useState(false);
  const [attachmentsInfo, setAttachmentsInfo] = useState<
    { id: string; name: string; filePath: string; userCreated: string }[]
  >([]);
  const currentBaseIssuesCompany = (selectedMssBaseIssue?.BaseValidations || [])[0]?.ValidatorCompany;
  const originator = users.find((u) => u.id === selectedMssBaseIssue?.originatorId);
  const currentValidator = users.find((u) => u.id === (selectedMssBaseIssue?.BaseValidations || [])[0]?.validatorId);

  useEffect(() => {
    const tree = convertZoneToTreeData(zones);
    setZonesTreeData(tree);
  }, [zones]);

  const companyHistories: ValidationHistory[] = useMemo(() => {
    if (!selectedMssBaseIssue?.BaseValidations) return [];

    const result: ValidationHistory[] = [];

    let currentActions: ValidationHistory = {
      id: '',
      company: '',
      code: '',
      status: '',
      comments: [],
    };

    for (let i = 0; i < selectedMssBaseIssue?.BaseValidations.length; i++) {
      const item = selectedMssBaseIssue?.BaseValidations[i];

      const prvUser = users.find((u) => u.id === item.prvValidatorId);
      const User = users.find((u) => u.id === item.validatorId);

      if (
        i === 0 &&
        item?.validationStatus !== VALIDATION_STATUS.REJECTED &&
        item?.validationStatus !== VALIDATION_STATUS.APPROVED
      ) {
        currentActions = {
          id: item.validatorCompanyId,
          company: item.ValidatorCompany?.name || '',
          code: item.ValidatorCompany?.companyCode || '',
          status: VALIDATION_STATUS.IN_REVIEW,
          comments: [
            {
              id: item.id,
              comment: '',
              user: User?.fullName || '',
              userId: item?.validatorId || '',
              date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              company: item.PrvValidatorCompany?.name || '',
              status: {
                name: VALIDATION_STATUS.IN_REVIEW,
                code: VALIDATION_STATUS.IN_REVIEW,
              },
              pinComment: item.pinComment,
            },
          ],
        };
      }

      if (!currentActions || item.prvValidatorCompanyId !== currentActions.id) {
        if (currentActions.comments.length > 0) {
          result.push(currentActions);
        }
        currentActions = {
          id: item.prvValidatorCompanyId,
          company: item.PrvValidatorCompany?.name || '',
          code: item.PrvValidatorCompany?.companyCode || '',
          status: item.validationStatus,
          comments: [
            {
              id: item.id,
              comment: item.comment,
              user: prvUser?.fullName || '',
              userId: item.prvValidatorId || '',
              date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              company: item.PrvValidatorCompany?.name || '',
              status: {
                name: item.validationStatus,
                code: item.validationStatus,
              },
              pinComment: item.pinComment,
            },
          ],
        };
      } else {
        currentActions.comments.push({
          id: item.id,
          comment: item.comment,
          user: prvUser?.fullName || '',
          userId: item.prvValidatorId || '',
          date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
          company: item.PrvValidatorCompany?.name || '',
          pinComment: item.pinComment,
          status: {
            name: item.validationStatus,
            code: item.validationStatus,
          },
        });
      }
    }

    if (currentActions.comments.length > 0) {
      result.push(currentActions);
    }
    const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
    const isRejected = selectedMssBaseIssue.IssueStatus?.code === MSS_STATUSES.REJECTED;
    const isApproved = selectedMssBaseIssue.IssueStatus?.code === MSS_STATUSES.APPROVED;
    const isApprovedWithComment = selectedMssBaseIssue.IssueStatus?.code === MSS_STATUSES.APPROVED_WITH_COMMENT;

    const draft = selectedMssBaseIssue.IssueStatus?.code === MSS_STATUSES.DRAFT;

    if (isRejected || isApproved || isApprovedWithComment) {
      setIsReadOnly(true);
    } else {
      if (
        (draft && userCompanyId === selectedMssBaseIssue.Company?.id) ||
        (userCompanyId === selectedMssBaseIssue.companyOriginatorId &&
          currentBaseIssuesCompany?.id === selectedMssBaseIssue.companyOriginatorId)
      ) {
        setIsReadOnly(false);
      } else {
        setIsReadOnly(true);
      }
    }

    return result;
  }, [selectedMssBaseIssue]);

  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: t('Method Statement Details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'comments',
      label: t('Validation History'),
      // disabled: isCreate,
      disabled: companyHistories.length <= 0,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  const onChangeForm = (value: BaseIssue) => {
    if (Object.keys(value)?.[0] === 'name') {
      setRequestName(value.name ?? '');
    }
  };
  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
  };

  const onClose = (disciplineId?: string) => {
    setOpen(false);
    setSelectedMssBaseIssue(null);
    setBaseIssueId(null);
    refreshBaseIssues(disciplineId ? disciplineId : '');
    setActiveTab('detail');
    setDataFormMSSsDetails(null);
    form.resetFields();
    setAttachmentsInfo([]);
  };

  const onSubmitWithStartValidation = async (mssValidationData: MssValidationType, hasApproveWithComment?: boolean) => {
    try {
      if (mssValidationData) {
        try {
          const values = dataFormMSSsDetails;
          const watcherIds = form.getFieldValue('watcherIds');
          const validationAttachments = form.getFieldValue('validationAttachments');

          let submitData = {
            ...values,
            moduleFunctionId:
              moduleFunctions?.find(
                (functionItem) => functionItem.code === MODULE_FUNCTIONS.METHOD_STATEMENT_SUBMISSION
              )?.id || null,
            endDate: values?.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
            watcherIds: watcherIds || [],
            mss: {
              ...values,
              revision: 0,
              mssValidation: {
                ...mssValidationData,
                pinComment: hasApproveWithComment,
                prvValidatorCompanyId: mssValidationData?.prvValidatorCompanyId || values?.companyOriginatorId,
                prvValidatorId: mssValidationData?.prvValidatorId || values?.originatorId,
                prevStepType:
                  values?.companyOriginatorId === mssValidationData.validatorCompanyId
                    ? 'internal-validation'
                    : 'external-validation',
                validationAttachmentsInfo: validationAttachments,
              },
            },
          };

          if (!selectedMssBaseIssue) {
            const statusCode = MSS_STATUSES.REVIEW_IN_PROGRESS;
            submitData = {
              ...submitData,
              isSaveAsDraft: statusCode === MSS_STATUSES.DRAFT,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id,
              attachmentsInfo: attachmentsInfo,
            };
            await baseIssueService.createBaseIssue(workspaceId, projectId, submitData);
          } else {
            let statusCode = MSS_STATUSES.REVIEW_IN_PROGRESS;
            switch (mssValidationData.validationAction) {
              case VALIDATION_ACTION.VALIDATE:
                if (
                  statusCode === MSS_STATUSES.DRAFT &&
                  values?.companyOriginatorId !== mssValidationData.validatorCompanyId
                ) {
                  statusCode = MSS_STATUSES.REVIEW_IN_PROGRESS;
                }
                break;
              case VALIDATION_ACTION.TRANSFER:
                break;
              case VALIDATION_ACTION.REJECT:
                statusCode = MSS_STATUSES.REJECTED;
                break;
              case VALIDATION_ACTION.FINAL_VALIDATION:
                if (hasApproveWithComment) {
                  statusCode = MSS_STATUSES.APPROVED_WITH_COMMENT;
                } else {
                  statusCode = MSS_STATUSES.APPROVED;
                }
                break;

              default:
                break;
            }

            submitData = {
              ...submitData,
              isSaveAsDraft: statusCode === MSS_STATUSES.DRAFT ? true : false,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id,
              attachmentsInfo: [],
              mss: { ...submitData.mss, id: selectedMssBaseIssue?.MethodStatementSubmission?.id },
            };
            await baseIssueService.updateBaseIssue(workspaceId, projectId, selectedMssBaseIssue.id, submitData);
          }
          onClose(values?.disciplineId);
        } catch (error) {
          console.log(error);
          message.error((error as Error).message);
        } finally {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitAsDraft = async () => {
    try {
      const valid = await form.validateFields(['name', 'disciplineId']);

      if (valid) {
        try {
          setIsLoading(true);
          const { ...values } = form.getFieldsValue();
          let statusObj = {} as IssueStatus | undefined;
          if (selectedMssBaseIssue) {
            statusObj = issueStatuses?.find((status) => status.code === selectedMssBaseIssue.IssueStatus?.code);
          } else {
            statusObj = issueStatuses?.find((status) => status.code === 'MSS_DR');
          }
          const submitData = {
            ...values,
            isSaveAsDraft: true,
            zoneId: values.zoneId || null,
            statusId: statusObj?.id,
            moduleFunctionId:
              moduleFunctions?.find(
                (functionItem) => functionItem.code === MODULE_FUNCTIONS.METHOD_STATEMENT_SUBMISSION
              )?.id || null,
            endDate: values?.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
            attachmentsInfo: attachmentsInfo,
            mss: {
              ...values,
              safetyRiskId: values.safetyRiskId || null,
              workspaceId: values.workspaceId || null,
              revision: 0,
            },
          };
          if (baseIssueId && selectedMssBaseIssue) {
            await baseIssueService.updateBaseIssue(workspaceId, projectId, baseIssueId, {
              ...submitData,
              attachmentsInfo: [],
              mss: { ...submitData.mss, id: selectedMssBaseIssue?.MethodStatementSubmission?.id },
            });
          } else {
            await baseIssueService.createBaseIssue(workspaceId, projectId, submitData);
          }
          onClose(values.disciplineId);
        } catch (error) {
          console.log(error);
          message.error((error as Error).message);
        } finally {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBaseIssue = async (baseIssueId: string, isLoading: boolean = true) => {
    try {
      if (isLoading) {
        setIsLoading(true);
      }
      const baseIssueData = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'MSS',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });

      setSelectedMssBaseIssue(baseIssueData);
      if (baseIssueData.startDate) {
        baseIssueData.startDate = dayjs(baseIssueData.startDate) as unknown as string;
      }
      if (baseIssueData.endDate) {
        baseIssueData.endDate = dayjs(baseIssueData.endDate) as unknown as string;
      }

      form.setFieldsValue(baseIssueData);
      form.setFieldsValue(baseIssueData.MethodStatementSubmission);
      setZoneUpdated(findPath(zonesTreeData, baseIssueData.zoneId));
      setRequestName(baseIssueData.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(t((error as Error).message));
    }
  };

  const mssValidate = async (
    selection: string,
    isFinal: boolean,
    validatorId: string | null,
    validatorCompanyId: string | null,
    hasApproveWithComment?: boolean
  ) => {
    try {
      // Validate for external company (read only)
      let validationAction = '';
      let validationStatus = '';
      setIsLoading(true);

      switch (selection) {
        case VALIDATION_ACTION.SUBMIT:
          validationAction = VALIDATION_ACTION.SUBMIT;
          validationStatus = VALIDATION_STATUS.SUBMITTED;
          break;
        case VALIDATION_ACTION.VALIDATE:
          if (isFinal) {
            validationAction = VALIDATION_ACTION.FINAL_VALIDATION;
            validationStatus = VALIDATION_STATUS.APPROVED;
            break;
          }
          validationAction = VALIDATION_ACTION.VALIDATE;
          validationStatus = VALIDATION_STATUS.VALIDATED;
          break;
        case VALIDATION_ACTION.REJECT:
          validationAction = VALIDATION_ACTION.REJECT;
          validationStatus = VALIDATION_STATUS.REJECTED;
          break;
        case VALIDATION_ACTION.TRANSFER:
          validationAction = VALIDATION_ACTION.TRANSFER;
          validationStatus = VALIDATION_STATUS.TRANSFERRED;
          break;
        default:
          break;
      }
      const comment: string = form.getFieldValue('validation-comment');
      const validationAttachments = form.getFieldValue('validationAttachments');

      const prvValidatorCompanyId =
        (selectedMssBaseIssue?.BaseValidations || [])[0]?.validatorCompanyId ??
        selectedMssBaseIssue?.companyOriginatorId;
      const prvValidatorId = (selectedMssBaseIssue?.BaseValidations || [])[0]?.validatorId ?? profile?.id;
      const prevStepType: string =
        (selectedMssBaseIssue?.BaseValidations || [])[0]?.validatorCompanyId === validatorCompanyId
          ? VALIDATION_STEP_TYPE.INTERNAL
          : VALIDATION_STEP_TYPE.EXTERNAL;
      const mssValidationData: MssValidationType = {
        validatorId: validatorId,
        validatorCompanyId: validatorCompanyId,
        prevStepId: null,
        prevStepType: prevStepType,
        prvValidatorCompanyId: prvValidatorCompanyId,
        prvValidatorId: prvValidatorId,
        validationAction: validationAction,
        validationStatus: validationStatus,
        comment: comment,
        revision: 0,
      };

      if (baseIssueId) {
        if (isReadOnly) {
          const dataSubmit = {
            baseIssueId: baseIssueId,
            validatorId: validatorId,
            validatorCompanyId: validatorCompanyId,
            prevStepId: (selectedMssBaseIssue?.BaseValidations || [])[0]?.id || null,
            prevStepType: prevStepType,
            prvValidatorCompanyId: prvValidatorCompanyId || null,
            prvValidatorId: prvValidatorId || null,
            validationAction: validationAction,
            validationStatus: validationStatus,
            comment: comment,
            revision: 0,
            pinComment: hasApproveWithComment,
            validationAttachmentsInfo: validationAttachments,
          };
          const params = {
            type: 'MSS',
          };

          await baseValidationService.createBaseValidation(workspaceId, projectId, baseIssueId, dataSubmit, params);
          onClose(selectedMssBaseIssue?.MethodStatementSubmission?.disciplineId || '');
        } else {
          onSubmitWithStartValidation(mssValidationData, hasApproveWithComment);
        }
      } else {
        onSubmitWithStartValidation(mssValidationData, hasApproveWithComment);
      }
    } catch (error) {
      console.log(error);
      message.error(t((error as Error).message));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (baseIssueId) {
        getBaseIssue(baseIssueId);
      } else {
        const currentUser = users.find((user) => user.id === profile?.id);
        form.setFieldValue('companyOriginatorId', currentUser?.WorkspaceUser?.Company?.id);
        form.setFieldValue('originatorId', currentUser?.id);
        form.setFieldValue('name', '');
        if (zones) {
          form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
          setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
        }
        setRequestName('');
        setIsReadOnly(false);
        setAttachmentsInfo([]);
      }
    }
  }, [baseIssueId, zones, isOpen, users]);

  useEffect(() => {
    if (!isOpen) return;

    const name = form.getFieldValue('name');
    const workPackageId = form.getFieldValue('workPackageId');
    const disciplineId = form.getFieldValue('disciplineId');

    const isNameValid = name?.length >= 1;
    const isWorkPackageIdValid = !!workPackageId;

    setIsDisableStartValidation(!(isNameValid && isWorkPackageIdValid && disciplineId));
  }, [formValuesWatch, isOpen]);

  return (
    <>
      <ValidationPopup
        key={`${isMSSsModalOpen}`}
        profile={profile}
        startFor={'MSS'}
        isModalOpen={isMSSsModalOpen}
        setIsModalOpen={setIsMSSsModalOpen}
        companies={companies}
        users={users || []}
        form={form}
        isExternal={companyHistories.length > 1 ? true : false}
        originator={originator}
        startValidation={(
          selection: string,
          isFinal: boolean,
          validatorId: string | null,
          validatorCompanyId: string | null,
          hasApproveWithComment?: boolean
        ) => mssValidate(selection, isFinal, validatorId, validatorCompanyId, hasApproveWithComment)}
        currentCompany={
          (currentBaseIssuesCompany
            ? currentBaseIssuesCompany
            : selectedMssBaseIssue?.Company
              ? selectedMssBaseIssue?.Company
              : currentUser?.WorkspaceUser?.Company) || null
        }
        currentValidator={(currentValidator ? currentValidator : currentUser ? currentUser : originator) || null}
      />
      <Form form={form} labelCol={{ span: 24 }} layout='vertical' onValuesChange={onChangeForm}>
        <Drawer
          className={`mss-drawer ${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
          styles={{
            header: {
              backgroundColor: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'],
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={800}
          onClose={() => onClose()}
          closeIcon={false}
          open={isOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <MSSsHeader
              requestName={requestName}
              profile={profile}
              isUnSaveStatus={true}
              selectedMssBaseIssue={selectedMssBaseIssue}
              companyHistories={companyHistories}
              onClose={() => {
                onClose(selectedMssBaseIssue?.MethodStatementSubmission?.disciplineId || '');
              }}
              user={user}
            />
          }
          footer={
            !isLoading && (
              <MSSsFooter
                user={user}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isSave={companyHistories.length > 0}
                profile={profile}
                selectedMssBaseIssue={selectedMssBaseIssue}
                isReadOnly={isReadOnly}
                onClose={onClose}
                onSubmitAsDraft={onSubmitAsDraft}
                setOpenValidationModal={async (value) => {
                  try {
                    const valid = await form.validateFields([
                      'name',
                      'workPackageId',
                      'companyOriginatorId',
                      'originatorId',
                    ]);
                    if (valid) {
                      const values = await form.getFieldsValue();
                      setDataFormMSSsDetails(values);
                      setIsMSSsModalOpen(value);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
                isDisableStartValidation={isDisableStartValidation}
              />
            )
          }
        >
          <Spin spinning={isLoading}>
            <Tabs
              className='mss-container'
              defaultActiveKey='detail'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
              tabBarExtraContent={
                <Flex justify={'space-between'} align={'center'}>
                  <Button type='text' size='small' key={'detail'} onClick={() => handleTabChange('detail')}></Button>
                  <Button
                    type='text'
                    size='small'
                    key={'comments'}
                    onClick={() => handleTabChange('comments')}
                  ></Button>
                </Flex>
              }
            />
            {companyHistories.length > 0 && (
              <ValidationHistoryStep isMAR={false} items={companyHistories} className='mss-step-bar-container' />
            )}
            {activeTab === 'detail' && (
              <MSSsDetail
                form={form}
                companies={companies}
                users={users || []}
                issuePriorities={issuePriorities}
                issueDisciplines={issueDisciplines}
                issueTypes={issueTypes}
                zones={zones}
                zonesTreeData={zonesTreeData}
                zoneUpdated={zoneUpdated}
                setZoneUpdated={setZoneUpdated}
                phases={phases}
                profile={profile}
                workPackages={workPackages}
                categories={categories}
                safetyRiskActivities={safetyRiskActivities}
                isReadOnly={isReadOnly}
                selectedMssBaseIssue={selectedMssBaseIssue}
                workspaceId={workspaceId}
                projectId={projectId}
                handleChangeRequestName={(name: string) => {
                  setRequestName(name);
                }}
                attachmentsInfo={attachmentsInfo}
                setAttachmentsInfo={(value) => setAttachmentsInfo(value)}
                refreshBaseIssues={(baseIssueId: string, isLoading?: boolean) => {
                  getBaseIssue(baseIssueId, isLoading);
                }}
              />
            )}
            {activeTab === 'comments' && <MSSsHistory companyHistories={companyHistories} />}
          </Spin>
        </Drawer>
      </Form>
    </>
  );
}
