import { Carousel, StepProps, Steps } from 'antd';
import { ValidationHistory } from 'model';
import { useMemo } from 'react';
import { VALIDATION_STATUS } from 'utils/contants';
import CustomStepsBarIcon from './CustomStepsBarIcon';

type ValidationHistoryStepProps = {
  items: ValidationHistory[];
  isMAR: boolean;
  className?: string;
};
const processDescription = (description: string) => {
  const [company, ...rest] = description.split(':');
  return { company: company.trim(), date: rest.join(':').trim() };
};

export default function ValidationHistoryStep(props: ValidationHistoryStepProps) {
  const { items, isMAR } = props;
  const reversedItems = [...items].reverse();

  const formatItems = useMemo(() => {
    const stepItems: StepProps[] | undefined = [];
    (reversedItems || []).forEach((item: ValidationHistory, index: number) => {
      const description = processDescription(`${item.company}:${item.comments[0]?.date ?? ''}`);
      if ((reversedItems || [])[index + 1]) {
        if (item.comments[0]?.status.code !== VALIDATION_STATUS.TRANSFERRED) {
          stepItems.push({
            status: 'finish',
            icon: <CustomStepsBarIcon status='finish' CompanyName={item.code} />,
            description: (
              <>
                <div className='validation-step-company'>{description.company}</div>
                <div className={`validation-step-date-time__${item.status}`}>{description.date}</div>
              </>
            ),
          });
        }
      } else {
        let isReject = false;
        let isApprove = false;
        item.comments.forEach((comment) => {
          if (comment.status.code === VALIDATION_STATUS.REJECTED) {
            isReject = true;
          }
          if (comment.status.code === VALIDATION_STATUS.APPROVED) {
            isApprove = true;
          }
        });
        if (isReject) {
          stepItems.push({
            status: 'error',
            icon: <CustomStepsBarIcon status='error' CompanyName={item.code} />,
            description: (
              <>
                <div className='validation-step-company'>{description.company}</div>
                <div className={`validation-step-date-time__${item.status}`}>{description.date}</div>
              </>
            ),
          });
        } else if (isApprove) {
          stepItems.push({
            status: 'finish',
            icon: <CustomStepsBarIcon status='finish' CompanyName={item.code} />,
            description: (
              <>
                <div className='validation-step-company'>{description.company}</div>
                <div className={`validation-step-date-time__${item.status}`}>{description.date}</div>
              </>
            ),
          });
        } else {
          stepItems.push({
            status: 'process',
            icon: <CustomStepsBarIcon status={isMAR ? 'process' : 'review-in-progress'} CompanyName={item.code} />,
            description: (
              <>
                <div className='validation-step-company'>{description.company}</div>
                <div className={`validation-step-date-time__${item.status}`}>{description.date}</div>
              </>
            ),
          });
        }
      }
    });
    return stepItems;
  }, [reversedItems]);

  function chunkArray(array: StepProps[], lessThan: number) {
    const result = [];
    for (let i = 0; i < array.length; i += lessThan) {
      result.push(array.slice(i, i + lessThan));
    }
    return result;
  }
  return (
    <Carousel
      className={'carousel-container-validation-history'}
      initialSlide={chunkArray(formatItems, isMAR ? 5 : 4).length - 1}
      infinite={false}
    >
      {chunkArray(formatItems, isMAR ? 5 : 4).map((items, index) => {
        return (
          <div key={index} className={`steps-bar-container ${props?.className || ''}`}>
            <Steps
              className={`steps-bar__${isMAR ? 'mar-step-bar' : 'mss-step-bar'}`}
              labelPlacement={'vertical'}
              items={items}
            />
          </div>
        );
      })}
    </Carousel>
  );
}
