import { BaseIssueWatcher, ParameterSetting } from 'model';
import { convertDateFormat } from 'utils';
import { INVITATION_ROLE } from './contants';
import { UserCompanyData } from 'components/common/UserCompany';
import store from 'store';
import { AttachmentMapper } from 'types';
import { UserDistributionValue } from 'components/common/UserDistribution';

/**
 * Retrieves the date format from the given settings.
 *
 * @param {ParameterSetting[]} settings - An array of parameter settings.
 * @return {string} The date format retrieved from the settings, or 'YYYY-MM-DD' if not found.
 */
export const getDateFormatFromSetting = (settings: ParameterSetting[]) => {
  const setting = settings.find((setting) => setting.code === 'date_format');
  return setting ? convertDateFormat(setting.value) : 'YYYY-MM-DD';
};

export enum EModuleName {
  Quality = 'Quality',
  Safety = 'Safety',
  DocumentManagement = 'Document Management',
  Construction = 'Construction',
}
/**
 * Retrieves the name of the module based on the current location path.
 *
 * @return {EModuleName} The name of the module. Possible values are 'Quality', 'Safety', or 'Document Management'.
 */
export const getModuleName = () => {
  const [type] = window.location.pathname.match('quality|safety|document-management|construction') || [];
  switch (type) {
    case 'quality':
      return EModuleName.Quality;
    case 'safety':
      return EModuleName.Safety;
    case 'document-management':
      return EModuleName.DocumentManagement;
    case 'construction':
      return EModuleName.Construction;
    default:
      return '';
  }
};

/**
 * Retrieves the path for a given module.
 *
 * @param {string} module - The name of the module.
 * @return {string} The path for the given module.
 */
export const getModulePath = (module?: string) => {
  switch (module) {
    case EModuleName.Quality:
      return 'quality';
    case EModuleName.Safety:
      return 'safety';
    case EModuleName.DocumentManagement:
      return 'document-management';
    case EModuleName.Construction:
      return 'construction';
    default:
      return 'quality';
  }
};

export const getBaseIssueWatcher = (baseIssueWatchers?: BaseIssueWatcher[]) => {
  const result = baseIssueWatchers?.reduce<{
    distribution: UserCompanyData[];
    issuedTo: UserCompanyData;
  }>(
    (result, baseIssueWatcher) => {
      const state = store.getState();
      if (!state?.myProjects?.selectedProjectId) return { distribution: [], issuedTo: {} };
      const project = state.myProjects.projects[state?.myProjects?.selectedProjectId];
      const user = project.users?.find((user) => user.id === baseIssueWatcher.watcherId);
      const data = {
        companyName: user?.WorkspaceUser?.Company?.name || '',
        userName: user?.fullName || '',
        id: user?.id,
      };
      if (baseIssueWatcher?.role === INVITATION_ROLE.RECEIVER) {
        result.issuedTo = data;
      } else if (baseIssueWatcher?.role === INVITATION_ROLE.DISTRIBUTION) {
        result.distribution.push({ ...data, color: 'default' });
      }
      return result;
    },
    { distribution: [], issuedTo: {} }
  );
  return result;
};

export const mapBaseIssueAttachments = (attachments?: AttachmentMapper[]) => {
  return attachments?.length
    ? attachments?.map((document) => {
        return {
          name: document?.name,
          filePath: document?.filePath,
          revision: document?.revision || undefined,
          id: document?.id?.includes('rc') ? undefined : document?.id,
        };
      })
    : [];
};

export const mapBaseIssueDistributions = ({
  receiver,
  distributions,
}: {
  receiver?: {
    companyId?: string | null;
    userIds?: string[];
  };
  distributions?: UserDistributionValue[];
}) => {
  const result = [];
  const receiverResult = {
    companyId: receiver?.companyId as string,
    inspectorIds: receiver?.userIds,
    role: INVITATION_ROLE.RECEIVER,
  };
  const distributionsResult =
    distributions
      ?.filter((invitation) => invitation?.companyId)
      ?.map((invitation) => {
        return {
          companyId: invitation?.companyId,
          inspectorIds: invitation?.inspectorIds,
          role: INVITATION_ROLE.DISTRIBUTION,
        };
      }) || [];
  if (receiverResult?.companyId && receiverResult.inspectorIds?.length) {
    result.unshift(receiverResult);
    return [receiverResult, ...distributionsResult];
  }
  if (distributionsResult.length) {
    result.push(...distributionsResult);
  }
  return result.length ? result : undefined;
};

export const groupDistributionByCompany = (distributions?: BaseIssueWatcher[]) => {
  if (!distributions || distributions?.length === 0) return [{ companyId: null, inspectorIds: [] }];
  const result = distributions.reduce<Record<string, { companyId: string; inspectorIds: string[] }>>(
    (init, distribution) => {
      if (!init[distribution.companyId]) {
        init[distribution.companyId] = { companyId: distribution.companyId, inspectorIds: [] };
      }
      init[distribution.companyId].inspectorIds.push(distribution.watcherId);
      return init;
    },
    {}
  );

  return Object.values(result);
};

export const mapDistributionBaseIssueWatcher = (baseIssueWatcher?: BaseIssueWatcher[]) => {
  const receiverData = baseIssueWatcher?.filter((issueWatcher) => issueWatcher.role === INVITATION_ROLE.RECEIVER);
  const distributionData = baseIssueWatcher?.filter(
    (issueWatcher) => issueWatcher.role === INVITATION_ROLE.DISTRIBUTION
  );

  return {
    receiver: {
      userIds: receiverData?.map((receiver) => receiver.watcherId) || [],
      companyId: receiverData?.length ? receiverData[0].companyId : null,
    },
    distributions: groupDistributionByCompany(distributionData),
  };
};
