import { ReactNode } from 'react';
import { EInspectionTestPlanColumnKey } from './general-setting';

export type QualityControlMatrix = {
  code: string;
  name: string;
};
export type QualityITPChecker = {
  qualityControlMatrix: string | QualityControlMatrix;
  company: string;
};

type Attachment = {
  attachmentTypeId?: string;
  name?: string;
  url?: string;
  uid?: string;
};

export type QualityITPData = {
  key: string;
  name?: string | null;
  id?: string | null;
  controlType?: string;
  parentKey?: string;
  frequency?: string;
  template?: Attachment | null;
  specs?: Attachment | null;
  controlPoint?: string;
  checker1?: QualityITPChecker;
  checker2?: QualityITPChecker;
  checker3?: QualityITPChecker;
  children?: QualityITPData[];
  orderText?: string;
  changed?: boolean;
  type?: EInspectionTestPlanColumnKey;
  order?: string | number | null;
};

export enum EWorkBreakDownStructureLevelID {
  Phase = '0',
  Discipline = '1',
  Activity = '2',
}

export enum EControlTypeCode {
  DR = 'DR',
  Mar = 'MAR',
  Mdi = 'MDI',
  Ms = 'MS',
  Wir = 'WIR',
  Custom = 'CUSTOM',
}

export type kpiData = {
  AP?: number;
  IC?: number;
  CA?: number;
  IS?: number;
  TR?: number;
  DR?: number;
  RE?: number;
  RIP?: number;
  APC?: number;
  RIPO?: number;
  OP?: number;
  CL?: number;
  ISD?: number;
  icon?: ReactNode;
  className?: string;
  value?: number;
  title?: string;
};
export type kpiDTData = {
  ISD: number;
  DR: number;
};

export type AttachmentMapper = {
  id?: string;
  attachmentTypeId?: string | null;
  name?: string;
  revision?: string;
  filePath?: string;
};
