import { Form, Popconfirm, Table, TableProps, UploadFile } from 'antd';
import React, { Dispatch, Key, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchSelect from '../../../../../common/SearchSelect';
import { EInspectionTestPlanColumnKey } from 'types/general-setting';
import InspectionTestPlanTableActive from './InspectionTestPlanTableActive';
import { DefaultOptionType } from 'antd/es/select';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useControlTypeParams,
  useControlTypes,
  useFrequencies,
  useFrequenciesParams,
  useOtherProjects,
  useOtherProjectsParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useQualityControlMatrices,
  useQualityControlMatricesParams,
} from 'hooks';
import InspectionTestPlanTableUpdate from './InspectionTestPlanTableUpdate';
import InspectionTestPlanTableControlType from './InspectionTestPlanTableControlType';
import { QualityITPData } from 'types/project';
import { useParams } from 'react-router-dom';
import TestInput from './InspectionTestPlanInput';
import { DeleteOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { DndContext, DragEndEvent, DragOverEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import SortableInspectionTestPlanRow, { DRAGGABLE_ALLOWED_KEY } from './InspectionTestPlanSortableRow';

type InspectionTestPlanTableProps = {
  isLoading: boolean;
  dataSource: QualityITPData[];
  onAddDataSource: Dispatch<SetStateAction<QualityITPData[]>>;
  getIncrementId: () => number;
  onChangeValue: (data: {
    value: string | Record<string, string | number | null> | null;
    key: string;
    column: keyof QualityITPData;
  }) => void;
  expandedRowKeys: Key[];
  onExpandedRowKeys: (expandedRowKeys: Key[]) => void;
  onDelete: (ids: string[], key: string) => void;
  formSubmit: Record<string, string | boolean>;
  onDragEnd: () => void;
};

type Checker = {
  suffix: string;
};

const InspectionTestPlanTable = (props: InspectionTestPlanTableProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [triggerDefaultExpand, setTriggerDefaultExpand] = useState(false);
  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);
  const [companiesParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
  });

  const [companies] = useProjectCompanies(companiesParams);
  const [controlTypes] = useControlTypes(useControlTypeParams({ projectId: projectId as string, workspaceId })[0]);
  const [frequencies] = useFrequencies(useFrequenciesParams({ projectId: projectId as string, workspaceId })[0]);
  const [qualityControlMatrices] = useQualityControlMatrices(useQualityControlMatricesParams()[0]);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const [isDragEnd, setDragEnd] = useState(false);
  const overRef = useRef<HTMLElement | null>(null);

  const companyOptions = useMemo(
    () => companies?.map((company) => ({ label: company?.name, value: company?.id })),
    [companies]
  );

  const controlTypeOptions = useMemo(
    () =>
      controlTypes?.map((controlType) => ({
        label: `${controlType?.code} - ${controlType?.name}`,
        value: controlType?.id,
      })),
    [controlTypes]
  );

  const frequenciesOptions = useMemo(
    () => frequencies?.map((frequency) => ({ label: frequency?.name, value: frequency?.id })),
    [frequencies]
  );

  const qualityControlMatricesOptions = useMemo(
    () => qualityControlMatrices?.map((matrix) => ({ label: `${matrix?.code} - ${matrix?.name}`, value: matrix?.id })),
    [qualityControlMatrices]
  );

  const dragData = useMemo(() => {
    const traverse = (
      data: QualityITPData[],
      parentKey?: string,
      result: (QualityITPData & { parentKey?: string })[] = []
    ): (QualityITPData & { parentKey?: string })[] => {
      data.forEach((item) => {
        if (item.children) {
          traverse(item.children, item.key, result);
        }
        result.push({ ...item, parentKey });
      });

      return result;
    };
    return traverse(props.dataSource);
  }, [props.dataSource]);

  const shortedMatrixLabel = (label: string): string | null => {
    if (!label) return '';
    return label.slice(0, 1);
  };

  const getDeleteEntityName = (record: QualityITPData) => {
    switch (record.type) {
      case EInspectionTestPlanColumnKey.Discipline:
        return t('discipline');
      case EInspectionTestPlanColumnKey.Activity:
        return t('activity');
      case EInspectionTestPlanColumnKey.SubActivity:
        return t('sub-activity');
      case EInspectionTestPlanColumnKey.ControlPoint:
        return t('control point');
      default:
        return '';
    }
  };

  const getAllValueIdById = (qualityItpList: QualityITPData[], result: string[] = []) => {
    qualityItpList.forEach((itpData) => {
      if (itpData.children && itpData.type !== EInspectionTestPlanColumnKey.SubActivity) {
        getAllValueIdById(itpData.children, result);
      }
      if (itpData?.id) {
        result.push(itpData?.id);
      }
    });
    return result.filter(Boolean);
  };

  const addChildNode = (child: {
    tableData: QualityITPData[];
    level: number;
    type: EInspectionTestPlanColumnKey;
    currentRecord: QualityITPData;
    selectedData?: DefaultOptionType | string;
  }) => {
    child.tableData.forEach((data) => {
      if (data.key === child.currentRecord.key) {
        if (!data.children) {
          data.children = [];
        }
        const addedData = {
          changed: true,
          orderText: `${data.orderText} ${data.children.length + 1}`,
          key: ((child?.selectedData as DefaultOptionType)?.value as string) || `${props.getIncrementId()}`,
          type: child.type,
          name: ((child?.selectedData as DefaultOptionType)?.label as string) || (child?.selectedData as string) || '',
        };

        if (data.type === EInspectionTestPlanColumnKey.SubActivity) {
          data.children = [...(data?.children ? data.children : []), addedData];
        } else {
          data.children = [...(data?.children || []), addedData];
        }
      } else if (data.children) {
        addChildNode({
          tableData: data.children,
          currentRecord: child.currentRecord,
          type: child.type,
          level: child.level,
          selectedData: child?.selectedData,
        });
      }
      return data;
    });
  };

  const handleAddChild = (params: {
    record: QualityITPData;
    level: number;
    type: EInspectionTestPlanColumnKey;
    selectedData?: DefaultOptionType | string;
  }) => {
    if (!props.dataSource) return;
    const newData = [...props.dataSource];

    addChildNode({
      tableData: newData,
      selectedData: params?.selectedData,
      type: params.type,
      level: params.level,
      currentRecord: params.record,
    });
    props.onAddDataSource(newData);
    props.onExpandedRowKeys([...props.expandedRowKeys, params.record.key]);
  };

  const getDefaultExpandRow = (item: QualityITPData[], rowExpand = [] as Key[]) => {
    item?.forEach((data) => {
      if (data.children) {
        rowExpand.push(data.key);
        getDefaultExpandRow(data?.children, rowExpand);
      }
    });
    return rowExpand;
  };

  const generateCheckerColumn = (checker: Checker) => {
    return [
      {
        title: t(`Checker ${checker.suffix}`),
        dataIndex: `checker${checker.suffix}`,
        colSpan: 2,
        render: (value: QualityITPData['checker1'], record: QualityITPData) => {
          if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
            const label = qualityControlMatricesOptions?.find(
              (option) => option?.value === value?.qualityControlMatrix
            )?.label;

            return (
              <SearchSelect
                allowClear
                value={value?.qualityControlMatrix}
                onChange={(_, option) => {
                  const currentOption = option as DefaultOptionType;
                  props.onChangeValue({
                    value: { qualityControlMatrix: currentOption?.value || null },
                    key: record.key,
                    column: `checker${checker.suffix}` as keyof QualityITPData,
                  });
                }}
                selectedLabel={shortedMatrixLabel(label || '') || ''}
                labelRender={(data) => <span>{shortedMatrixLabel(data.label as string)}</span>}
                options={qualityControlMatricesOptions}
                placeholder={t('Control Matrix')}
                dynamicWidth
                className='quality-control-matrix'
              />
            );
          }
        },
      },
      {
        dataIndex: `checker${checker.suffix}`,
        colSpan: 0,
        render: (value: QualityITPData['checker1'], record: QualityITPData) => {
          if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
            return (
              <SearchSelect
                popupClassName='inspection-company-select'
                options={companyOptions}
                placeholder={t(`Company`)}
                dynamicWidth
                value={value?.company}
                onChange={(value) => {
                  props.onChangeValue({
                    value: { company: value || null },
                    key: record.key,
                    column: `checker${checker.suffix}` as keyof QualityITPData,
                  });
                }}
              />
            );
          }
        },
      },
    ];
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const rebuildDataSource = (
    dragData: (QualityITPData & { parentKey?: string })[] = [],
    parentKey?: string
  ): QualityITPData[] => {
    return dragData
      .filter((data) => data.parentKey === parentKey)
      .map((data, index) => ({
        ...data,
        order: index + 1,
        children:
          rebuildDataSource(dragData, data.key)?.length === 0 ? undefined : rebuildDataSource(dragData, data.key),
      }));
  };

  const checkDragPermission = (elementId?: string | number) => {
    const activeElement = document.getElementById(elementId as string);
    const allowDrag = activeElement?.getAttribute(DRAGGABLE_ALLOWED_KEY);
    return allowDrag === 'false';
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      if (checkDragPermission(active?.id)) return;

      const oldIndex = dragData?.findIndex((item) => item.key === active.id);
      const newIndex = dragData?.findIndex((item) => item.key === over?.id);
      const isSameITPType = dragData[newIndex]?.type === dragData[oldIndex]?.type;
      const isTheSameParent = dragData[newIndex]?.parentKey === dragData[oldIndex]?.parentKey;

      if (isTheSameParent && isSameITPType && dragData[oldIndex] && dragData[newIndex]) {
        dragData[oldIndex] = {
          ...dragData[oldIndex],
          changed: true,
        };
        const movedDragData = arrayMove(dragData, oldIndex, newIndex);

        const result = rebuildDataSource(movedDragData);

        props.onAddDataSource(result);
        setDragEnd(true);
      }
      removeOverStyle();
    }
  };

  const removeOverStyle = () => {
    overRef.current?.classList.forEach((className) => {
      if (className === 'top' || className === 'bottom') {
        overRef.current?.classList.remove(className);
      }
    });
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const activeItem = dragData?.find((item) => item.key === active.id);
    const overItem = dragData?.find((item) => item.key === over?.id);
    const isTheSameType = activeItem?.type === overItem?.type;
    const isTheSameParent = activeItem?.parentKey === overItem?.parentKey;

    if (
      isTheSameParent &&
      isTheSameType &&
      active?.rect?.current?.translated &&
      active?.rect?.current?.initial &&
      over?.id !== active.id
    ) {
      if (checkDragPermission(active?.id)) return;
      removeOverStyle();
      const movedActiveY = active?.rect?.current?.translated?.top;
      const initialActiveY = active?.rect?.current?.initial?.top;

      // Using JS to query DOM and update styles with classList to avoid re-rendering the entire component and prevent layout thrashing
      const currentOverElement = document.getElementById(over?.id as string);

      if (initialActiveY - movedActiveY > 0) {
        currentOverElement?.classList.add('top');
      } else {
        currentOverElement?.classList.add('bottom');
      }
      overRef.current = currentOverElement;
    } else {
      removeOverStyle();
    }
  };

  const columns: TableProps<QualityITPData>['columns'] = [
    {
      title: t('Control Point'),
      dataIndex: 'name',
      render: (value: string, record: QualityITPData) => {
        if (record.type === EInspectionTestPlanColumnKey.SubActivity) {
          return (
            <InspectionTestPlanTableActive
              orderText={record.orderText}
              value={value}
              type='button'
              placeholder={t('Add Control Point')}
              onClick={() => {
                handleAddChild({
                  level: 3,
                  type: EInspectionTestPlanColumnKey.ControlPoint,
                  record: record,
                });
              }}
            />
          );
        }

        if (record?.type === EInspectionTestPlanColumnKey.Discipline) {
          return (
            <InspectionTestPlanTableActive
              name={'activity'}
              level={2}
              onChange={(selectedData) =>
                handleAddChild({
                  level: 2,
                  type: EInspectionTestPlanColumnKey.Activity,
                  selectedData,
                  record,
                })
              }
              value={value}
              type='select'
              placeholder={t('Add Activity')}
              selectedActivityList={record.children}
              disciplineId={record.key}
              orderText={record.orderText}
            />
          );
        }

        if (record.type === EInspectionTestPlanColumnKey.Activity) {
          return (
            <InspectionTestPlanTableActive
              name='sub-activity'
              level={3}
              onChange={(value) =>
                handleAddChild({
                  record,
                  level: 3,
                  type: EInspectionTestPlanColumnKey.SubActivity,
                  selectedData: value,
                })
              }
              value={value}
              type='input'
              placeholder={t('Add Sub Activity')}
              orderText={record.orderText}
            />
          );
        }

        if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
          return (
            <Form.Item
              style={{ margin: 0, position: 'relative', bottom: 0 }}
              validateStatus={!value && props?.formSubmit?.[record.key] ? 'error' : ''}
              help={!value && props?.formSubmit?.[record.key] ? 'Please enter your control point' : ''}
            >
              <TestInput
                value={value}
                onChange={(value: string) => {
                  props.onChangeValue({
                    value,
                    key: record.key,
                    column: 'name',
                  });
                  props.formSubmit[record.key] = true;
                }}
              />
            </Form.Item>
          );
        }
        return <div>{value}</div>;
      },
    },
    {
      title: t('Control Type'),
      dataIndex: 'controlType',
      width: 100,
      render: (value, record) => {
        if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
          return (
            <InspectionTestPlanTableControlType
              value={value}
              onChange={(value) =>
                props.onChangeValue({
                  value,
                  key: record.key,
                  column: 'controlType',
                })
              }
              options={controlTypeOptions}
            />
          );
        }
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      render: (value, record) => {
        if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
          return (
            <SearchSelect
              dynamicWidth
              popupClassName='frequency-popup'
              placeholder={t('Frequency')}
              options={frequenciesOptions}
              value={value}
              onChange={(value) => {
                props.onChangeValue({
                  value,
                  key: record.key,
                  column: 'frequency',
                });
              }}
            />
          );
        }
      },
    },
    {
      title: t('Inspection Checklist'),
      dataIndex: 'template',
      render: (value: UploadFile, record) => {
        if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
          return (
            <InspectionTestPlanTableUpdate
              placeholder={t('Template')}
              onChange={(value) => {
                props.onChangeValue({
                  value,
                  key: record.key,
                  column: 'template',
                });
              }}
              attachmentType={ATTACHMENT_TYPES.INSPECTION_TEST_PLAN_TEMPLATE}
              value={value}
              attachmentTypes={attachmentTypes}
            />
          );
        }
      },
    },
    {
      title: 'Specs',
      dataIndex: 'specs',
      render: (value: UploadFile, record) => {
        if (record.type === EInspectionTestPlanColumnKey.ControlPoint) {
          return (
            <InspectionTestPlanTableUpdate
              placeholder={t('Specs')}
              onChange={(value) => {
                props.onChangeValue({
                  value,
                  key: record.key,
                  column: 'specs',
                });
              }}
              value={value}
              attachmentType={ATTACHMENT_TYPES.INSPECTION_TEST_PLAN_SPECS}
              attachmentTypes={attachmentTypes}
            />
          );
        }
      },
    },
    {
      title: t(''),
      children: [
        ...generateCheckerColumn({
          suffix: '1',
        }),
        ...generateCheckerColumn({
          suffix: '2',
        }),
        ...generateCheckerColumn({
          suffix: '3',
        }),
      ],
    },
    {
      align: 'right',
      dataIndex: 'id',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (id: string, record) => {
        const entityName = getDeleteEntityName(record);
        return (
          <Popconfirm
            title={t(`Delete the ${entityName}`)}
            description={`Are you sure to delete this ${entityName}?`}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            placement='bottomRight'
            onConfirm={() => {
              const deleteIds = id ? [id] : [];
              if (
                record.type === EInspectionTestPlanColumnKey.Discipline ||
                record.type === EInspectionTestPlanColumnKey.Activity
              ) {
                const childIds = getAllValueIdById(record?.children || []);
                deleteIds.push(...childIds);
              }
              props.onDelete(deleteIds, record.key);
            }}
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  const renderExpandIcon = (props: {
    expanded: boolean;
    onExpand: (expanded: QualityITPData, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    record: QualityITPData;
  }) => {
    return (
      <span className='w-[18px]'>
        &nbsp;
        {props.record.children ? (
          <RightOutlined
            className={`expand-icon ${props.expanded ? 'expanded' : ''}`}
            onClick={(e) => props.onExpand(props.record, e)}
          />
        ) : null}
      </span>
    );
  };

  useEffect(() => {
    const defaultExpandRow = getDefaultExpandRow(props.dataSource);
    if (defaultExpandRow.length > 0 && !triggerDefaultExpand) {
      props.onExpandedRowKeys(defaultExpandRow);
      setTriggerDefaultExpand(true);
    }
  }, [props.dataSource?.length, triggerDefaultExpand]);

  useEffect(() => {
    if (isDragEnd) {
      props.onDragEnd();
      setDragEnd(false);
    }
  }, [isDragEnd]);

  return (
    <>
      <DndContext
        onDragOver={handleDragOver}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={dragData.map((data) => data.key)}>
          <Table
            loading={props.isLoading}
            columns={columns}
            dataSource={props.dataSource}
            components={{
              body: { row: SortableInspectionTestPlanRow },
            }}
            expandable={{
              defaultExpandAllRows: true,
              expandedRowKeys: props.expandedRowKeys,
              expandIcon: renderExpandIcon,
              onExpand: (hasExpand, record) => {
                props.onExpandedRowKeys(
                  hasExpand
                    ? [...props.expandedRowKeys, record.key]
                    : props.expandedRowKeys.filter((key) => key !== record.key)
                );
              },
            }}
            scroll={{ x: 'max-content' }}
            bordered
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default InspectionTestPlanTable;
