import { CloseCircleOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Flex, Layout, MenuProps, Table, Typography } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { User, WorkPackage } from 'model';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SorterResult } from 'antd/es/table/interface';
import { TablePagination } from 'components/common';
import { QueryParams } from 'types';
const { Text } = Typography;

type ProjectWorkPackageProps = {
  projectId: string;
  workspaceId: string;
  projectWorkPackages: WorkPackage[];
  loading?: boolean;
  isFormOpen: boolean | null;
  projectWorkPackagesCount: number;
  projectWorkPackageParams: QueryParams;
  users: User[];
  editForm: (id: string) => void;
  onFilter: (value: QueryParams) => void;
};

export default function ProjectWorkPackageListView(props: ProjectWorkPackageProps) {
  const { t } = useTranslation();
  const {
    projectWorkPackages,
    loading,
    projectWorkPackagesCount,
    projectWorkPackageParams,
    users,
    editForm,
    onFilter,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection: TableProps['rowSelection'] = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({ name: record.name }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            return index % 2 !== 0 ? false : true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            return index % 2 !== 0 ? true : false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleMenuClick = (info: MenuInfo, workPackageId: string) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'edit':
          editForm(workPackageId);
          break;
        case 'delete':
          console.log('delete');
          break;
      }
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('Edit Work Package'),
      key: 'edit',
      icon: <EditOutlined />,
      danger: false,
    },
    {
      label: t('Delete Work Package'),
      key: 'delete',
      icon: <CloseCircleOutlined />,
      danger: true,
    },
  ];
  const menuProps: MenuProps = { items };

  const columns: ColumnsType = [
    {
      sorter: true,
      title: t('ID'),
      dataIndex: 'code',
      key: 'code',
      render: (code) => {
        return (
          <Flex align='center'>
            <span className='mr-1 column-name'> {code ? code : ''} </span>
          </Flex>
        );
      },
      width: '100px',
    },
    {
      sorter: true,
      title: t('Work Package Name'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return (
          <Flex align='center'>
            <span className='mr-1 column-name'> {name ? name : ''} </span>
          </Flex>
        );
      },
      width: '300px',
    },
    {
      sorter: true,
      title: t('Company'),
      dataIndex: 'companyId',
      key: 'companyId',
      render: (name, record) => {
        return (
          <Flex align='center'>
            <span className='mr-1 column-name'>{record?.Company ? record.Company.name : ''}</span>
          </Flex>
        );
      },
    },
    {
      sorter: true,
      title: t('Company Representative'),
      dataIndex: 'companyRep',
      key: 'companyRep',
      render: (_, record) => {
        const companyRepName =
          users && users.length > 0 ? users.find((company) => company.id === record.companyRep)?.fullName : '-';
        return (
          <div className='item-info'>
            <div className='title'>{companyRepName || '-'}</div>
            <Text type='secondary'>{record.companyRepEmail}</Text>
          </div>
        );
      },
    },
    {
      align: 'right',
      dataIndex: 'id',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (value) => (
        <Flex justify={'flex-end'}>
          <Dropdown
            menu={{
              ...menuProps,
              onClick: (info) => handleMenuClick(info, value),
            }}
            trigger={['click']}
          >
            <EllipsisOutlined className='icon-ellipse' />
          </Dropdown>
        </Flex>
      ),
    },
  ];

  const handleTableChange = (sorter: SorterResult<unknown>) => {
    if (sorter) {
      const sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        if (sorter.columnKey === 'companyId') {
          orderBy = `Company.name,${sorterOrder}`;
        } else {
          orderBy = `${sorter.columnKey},${sorterOrder}`;
        }
      }
      onFilter({ orderBy });
    }
  };

  return (
    <Layout.Content className='settings-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={projectWorkPackageParams}
        issuesCount={projectWorkPackagesCount}
      />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={false}
        loading={loading}
        className='workspaces-list-table my-4'
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={projectWorkPackages}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: () => editForm(record.id),
        })}
        onChange={(pagination, filter, sorter) => handleTableChange(sorter as SorterResult<unknown>)}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={projectWorkPackageParams}
        issuesCount={projectWorkPackagesCount}
      />
    </Layout.Content>
  );
}
