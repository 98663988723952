import { CloseCircleOutlined, CopyOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Flex, MenuProps, message } from 'antd';
import { DeleteConfirmation } from 'components';
import DisciplineBaseIssueTable, {
  DisciplineBaseIssueTableRefHandle,
} from 'components/common/DisciplineBaseIssueTable';
import { DynamicCollapseTableProps } from 'components/common/DynamicCollapseTable';
import { CustomColumnType, InspectorsRepliedWithStatus, StatusData, TooltipData } from 'components/common/DynamicTable';
import { findPath } from 'components/common/TreeSelectTagRender';
import { Dayjs } from 'dayjs';
import { BaseIssue, BaseValidation, Company, User } from 'model';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { RefObject, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { baseIssueService } from 'services';
import { useAppSelector } from 'store';
import {
  selectNewProjectWorkBreakdownStructures,
  selectProjectInspectionTestPlan,
  selectProjectUsers,
  selectProjectZones,
} from 'store/my-projects.slice';
import { QueryParams, TableRowsSelected } from 'types';
import { kpiData } from 'types/project';
import { convertZoneToTreeData } from 'utils';
import { ISSUE_STATUSES, VALIDATION_STATUS } from 'utils/contants';

export type WorkInspectionRequestData = {
  id: string;
  number: string;
  workInspectionName: TooltipData;
  activity?: TooltipData;
  subActivity?: TooltipData;
  inspectionDate?: string | null;
  approvalDate?: string | null | Date | Dayjs;
  zoning?: string;
  originatorId?: string;
  submittedBy?: {
    companyName?: string;
    userName?: string;
  };
  submittedTo?: {
    companyName?: string;
    userName?: string;
  }[];
  status?: StatusData;
  discipline?: string;
  inspectorsRepliedWithStatus?: InspectorsRepliedWithStatus;
};
type MethodStatementsProps = {
  users: User[];
  projectBaseIssueParams: QueryParams;
  onActionClick: DynamicCollapseTableProps<WorkInspectionRequestData>['onActionClick'];
  onPaginationChange?: DynamicCollapseTableProps<WorkInspectionRequestData>['onPaginationChange'];
  onTotalKpi: (kpi: kpiData) => void;
  companies?: Company[];
  profile?: User | null;
  isFormOpen: boolean | null;
  projectId: string;
  workspaceId: string;
  editForm: (id: string) => void;
  duplicateForm: (id: string) => void;
  disciplineBaseIssueTableRef: RefObject<DisciplineBaseIssueTableRefHandle>;
  refreshBaseIssues: (disciplineId: string, baseIssueId?: string) => void;
  refreshDisciplineByFunction: () => void;
  selectedRowKeys?: TableRowsSelected;
  handlerRowsKeyChange: (value: React.Key[], id?: string) => void;
};

export default function WorkInspectionRequestListsView(props: MethodStatementsProps) {
  const { projectId, workspaceId, editForm, duplicateForm, selectedRowKeys, handlerRowsKeyChange } = props;
  const { t } = useTranslation();
  const users = useAppSelector(selectProjectUsers);
  const workBreakDownStructures = useAppSelector(selectNewProjectWorkBreakdownStructures);
  const inspectionTestPlan = useAppSelector(selectProjectInspectionTestPlan);
  const zones = useSelector(selectProjectZones);
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);
  const [isDelete, setIsDelete] = useState<{
    open: boolean;
    baseIssueId?: string;
    disciplineId?: string;
  }>({
    open: false,
    baseIssueId: undefined,
    disciplineId: undefined,
  });

  const handleMenuClick = (info: MenuInfo, record: WorkInspectionRequestData) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'edit':
          editForm(record.id);
          break;
        case 'duplicate':
          duplicateForm(record.id);
          break;
        case 'delete':
          setIsDelete({ open: true, baseIssueId: record.id, disciplineId: record.discipline });
          break;
      }
    }
  };
  const handleDeleteBaseIssue = async () => {
    try {
      if (isDelete.baseIssueId && isDelete.disciplineId) {
        await baseIssueService.deleteBaseIssueById(workspaceId, projectId, isDelete.baseIssueId);
        setIsDelete({ open: false, baseIssueId: undefined });
        props.refreshBaseIssues(isDelete.disciplineId, isDelete.baseIssueId);
      } else {
        message.error(t('Work Inspection Request not existed!'));
      }
    } catch (error) {
      console.log(error);
      setIsDelete({ open: false, baseIssueId: undefined });
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('Edit request'),
      key: 'edit',
      icon: <EditOutlined />,
      danger: false,
    },
    {
      label: t('Duplicate request'),
      key: 'duplicate',
      icon: <CopyOutlined />,
      danger: false,
    },
    {
      label: t('Delete request'),
      key: 'delete',
      icon: <CloseCircleOutlined />,
      danger: true,
    },
  ];
  const menuProps: MenuProps = { items };
  const columns: CustomColumnType<WorkInspectionRequestData>[] = [
    { title: 'Number', dataIndex: 'number', sortKey: 'WorkInspectionRequest.wirNumber' },
    {
      title: 'Name',
      dataIndex: 'workInspectionName',
      sortKey: 'name',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            width: '300px',
          },
        };
      },
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
    },
    {
      title: 'Sub-activity',
      dataIndex: 'subActivity',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
    },
    {
      title: 'Zoning',
      dataIndex: 'zoning',
      sortKey: 'Zone.name',
      onCell: () => {
        return {
          style: {
            maxWidth: '260px',
          },
        };
      },
    },
    {
      title: 'Submitted By',
      dataIndex: 'submittedBy',
      component: {
        type: 'user-company',
      },
      width: 120,
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
    },
    {
      title: 'Submitted To',
      dataIndex: 'submittedTo',
      width: 125,
      onCell: () => {
        return {
          style: {
            maxWidth: '125px',
          },
        };
      },
      component: {
        type: 'user-company',
      },
    },
    {
      title: 'Inspection Date',
      dataIndex: 'inspectionDate',
      sortKey: 'WorkInspectionRequest.inspectionDate',
      width: 120,
      component: {
        type: 'datetime',
      },
    },
    {
      title: 'Approval Date',
      dataIndex: 'approvalDate',
      sortKey: 'updatedAt',
      width: 120,
      component: {
        type: 'date',
      },
    },
    {
      title: t('Status'),
      dataIndex: 'inspectorsRepliedWithStatus',
      sortKey: 'IssueStatus.orderNumber',
      component: {
        type: 'status-validation',
      },
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit',
            key: 'edit',
          },
          {
            label: 'Duplicate',
            key: 'duplicate',
          },
          {
            label: 'Delete',
            key: 'delete',
          },
        ],
      },
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (value, record: WorkInspectionRequestData) => {
        // const userCompanyId = users?.find((user: User) => user.id === props.profile?.id)?.WorkspaceUser?.Company?.id;
        // const isDeleteRecord =
        //   userCompanyId === users.find((user: User) => user.id === record?.originatorId)?.WorkspaceUser?.Company?.id;
        const isOriginator = props.profile?.id === record?.originatorId;
        // Disable delete button
        let updatedMenuProps = { ...menuProps };
        if (menuProps.items) {
          const updatedItems = menuProps.items.map((item) => {
            if (item?.key === 'delete') {
              return {
                ...item,
                disabled: !isOriginator,
              };
            }
            return item;
          });
          updatedMenuProps = { ...menuProps, items: updatedItems };
        }

        return (
          <>
            <Flex justify={'flex-end'}>
              <Dropdown
                menu={{
                  ...updatedMenuProps,
                  onClick: (info) => {
                    info.domEvent.stopPropagation();
                    handleMenuClick(info, record);
                  },
                }}
                trigger={['click']}
              >
                <EllipsisOutlined className='icon-ellipse' onClick={(event) => event.stopPropagation()} />
              </Dropdown>
            </Flex>
          </>
        );
      },
    },
  ];

  const generateDataSource = (baseIssues?: BaseIssue) => {
    if (!baseIssues) return {} as WorkInspectionRequestData;
    const activity = workBreakDownStructures?.find((wbs) => wbs.id === baseIssues?.WorkInspectionRequest?.activityId);
    const subActivity = inspectionTestPlan?.find((itp) => itp.id === baseIssues?.WorkInspectionRequest?.subActivityId);
    const user = users?.find((user) => user.id === baseIssues.originatorId);
    const [, code] = baseIssues.IssueStatus?.code?.split('_') || [];
    const zoneString = findPath(zonesTreeData, baseIssues.zoneId || '');
    const zoneName = zoneString.split('▶').length === 1 ? zoneString : zoneString.split('▶').slice(1).join('▶');

    const inspectorsReplied =
      baseIssues.BaseValidations &&
      baseIssues.BaseValidations.reduce((result: { [key: string]: BaseValidation[] }, item) => {
        (result[item['validationStatus']] = result[item['validationStatus']] || []).push(item);
        return result;
      }, {});
    const reviewInProgressCount = inspectorsReplied?.[VALIDATION_STATUS.REVIEW_IN_PROGRESS]?.length ?? 0;
    const approvedCount =
      (inspectorsReplied?.[VALIDATION_STATUS.APPROVED]?.length ?? 0) +
      (inspectorsReplied?.[VALIDATION_STATUS.APPROVED_WITH_COMMENT]?.length ?? 0);
    const rejectedCount = inspectorsReplied?.[VALIDATION_STATUS.REJECTED]?.length ?? 0;

    return {
      id: baseIssues.id,
      workInspectionName: {
        title: baseIssues.name?.trim() || '',
      },
      number: baseIssues?.WorkInspectionRequest?.wirNumber,
      zoning: zoneName,
      originatorId: baseIssues?.originatorId,
      submittedBy: {
        companyName: user?.WorkspaceUser?.Company?.name || '',
        userName: user?.fullName || '',
      },
      submittedTo: baseIssues.WorkInspectionRequest?.WirInvitations?.map((invitation) => {
        const user = users?.find((user) => user.id === invitation.inspectorId);
        const company = props?.companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
        return {
          companyName: company?.name || '',
          userName: user?.fullName || '',
          color: 'default',
        };
      }),
      inspectionDate: baseIssues.WorkInspectionRequest?.inspectionDate,
      approvalDate: baseIssues?.updatedAt,
      status: { code: code || '', name: baseIssues.IssueStatus?.name },
      activity: {
        title: activity?.name || '',
      },
      subActivity: {
        title: subActivity?.name || '',
      },
      discipline: baseIssues?.WorkInspectionRequest.disciplineId,
      inspectorsRepliedWithStatus: {
        inspectorsReplied: {
          'review-in-progress': reviewInProgressCount,
          approved: approvedCount,
          rejected: rejectedCount,
        },
        status: { code: code || '', name: baseIssues.IssueStatus?.name },
      },
    };
  };

  return (
    <React.Fragment>
      <DeleteConfirmation
        isModalOpen={isDelete.open}
        title={t('Delete the Work Inspection Request')}
        description={t('Are you sure to delete this Work Inspection Request?')}
        onDelete={handleDeleteBaseIssue}
        onCancel={() => setIsDelete({ open: false, baseIssueId: undefined })}
      />
      <DisciplineBaseIssueTable
        ref={props.disciplineBaseIssueTableRef}
        columns={columns}
        header={t('Discipline')}
        onActionClick={props?.onActionClick}
        onRowClick={(record) => {
          props?.onActionClick?.('open-detail', record);
        }}
        onMapDataSource={(baseIssue) => generateDataSource(baseIssue)}
        projectBaseIssueParams={props.projectBaseIssueParams}
        loadAllListTrigger={props.projectBaseIssueParams}
        onTotalKpi={props.onTotalKpi}
        refreshDiscipline={props.refreshDisciplineByFunction}
        selectedRowKeys={selectedRowKeys}
        handlerRowsKeyChange={handlerRowsKeyChange}
        excludeKpiField={[ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE]}
      />
    </React.Fragment>
  );
}
