import { IssuesCloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CloseConfirmation } from 'components/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type CloseButtonProps = {
  isClosing?: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  module: string;
};
const CloseButton = (props: CloseButtonProps) => {
  const { t } = useTranslation();
  const { onClose, isClosing, isDisabled, module } = props;
  const [isCloseConfirmation, setIsCloseConfirmation] = useState<boolean>(false);
  return (
    <>
      <CloseConfirmation
        isModalOpen={isCloseConfirmation}
        title={module && module === 'Safety' ? t('Close the inspection') : t('Close the observation')}
        description={
          module && module === 'Safety'
            ? t('Are you sure to close this inspection?')
            : t('Are you sure to close this observation?')
        }
        onClose={() => {
          setIsCloseConfirmation(false);
          onClose();
        }}
        onCancel={() => setIsCloseConfirmation(false)}
      />
      <Button
        loading={isClosing}
        ghost
        type='primary'
        className='close-btn'
        icon={<IssuesCloseOutlined />}
        disabled={isDisabled}
        onClick={() => setIsCloseConfirmation(true)}
      >
        {t('Close')}
      </Button>
    </>
  );
};
export default CloseButton;
