import { Button, Form, ModalProps, Row, Col, Typography, Tooltip, Select } from 'antd';
import { InviteUserSelect, Modal, SearchSelect } from 'components';
import { useTranslation } from 'react-i18next';

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  useMyWorkspaceCompanies,
  useMyWorkspaceCompaniesParams,
  useMyWorkspaceUsers,
  useMyWorkspaceUsersParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useMyWorkspaceParams,
} from 'hooks';
import { useEffect, useState } from 'react';
import { Company, Project, User } from 'model';
import { CreateCompany } from 'components';
import { useParams } from 'react-router-dom';
import { useMyWorkspace, useWorkspaceById, useWorkspaceByIdParams } from 'hooks/workspace';

export type AddUserFormValue = {
  users: {
    userId: string;
    companyId: string;
    email: string | null;
    userRole?: string;
    roleId?: string;
  }[];
};

type ProjectUserInvitationProps = {
  isModalOpen: boolean;
  onSubmit: (value: AddUserFormValue['users']) => void;
  setIsModalOpen: (value: boolean) => void;
  projectId: string;
  project?: Project | null;
  workspaceId: string;
  excludeUserIds?: string[];
  loading?: boolean;
  selectedUser: User | null;
  setSelectedUser: (value: User | null) => void;
} & ModalProps;

export default function ProjectUserInvitation(props: ProjectUserInvitationProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, workspaceId, excludeUserIds, project, selectedUser, setSelectedUser } =
    props;
  const { t } = useTranslation();
  const params = useParams();
  const projectId = params.projectId as string;

  const [form] = Form.useForm();
  const [userQuery] = useMyWorkspaceUsersParams({ workspaceId });
  const [users, , refreshUsers] = useMyWorkspaceUsers(userQuery);
  const [companyQuery] = useMyWorkspaceCompaniesParams({ workspaceId });
  const [projectCompaniesQuery] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
  });
  const [projectCompanies] = useProjectCompanies(projectCompaniesQuery);
  const [ownerCompaniesIds] = useState<string[]>(
    projectCompanies?.map((company) =>
      company.ProjectCompanies?.find((projectCompany) => projectCompany.projectId === projectId)?.companyRole ===
      'owner'
        ? company.id
        : ''
    )
  );

  const [projectWorkspaceParams] = useWorkspaceByIdParams({ workspaceId });
  const [projectWorkspace] = useWorkspaceById(projectWorkspaceParams);
  const projectOwner = users?.find((user) => user.id === projectWorkspace?.ownerId);

  const projectRoles = [
    {
      id: 'admin',
      name: 'Admin',
      tooltip: t(
        `This user isn't in the workspace of the project owner (${projectOwner?.fullName}) yet and can only be invited as a Member. You can change their role to Admin after they have been successfully added to the workspace.`
      ),
    },
    {
      id: 'member',
      name: 'Member',
      tooltip: null,
    },
    {
      id: 'guest',
      name: 'Guest',
      tooltip: null,
    },
  ];

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [indexOfNewCompany, setIndexOfNewCompany] = useState<number | null>(null);

  const [companies, loading, refershCompanies] = useMyWorkspaceCompanies(companyQuery);

  //const [isCreateOpen, setIsCreateOpen] = useState<boolean | null>(null);
  const [, setIsCompanyProfile] = useState<boolean | null>(null);

  const [isCreateOpen, setIsCreateOpen] = useState<boolean | null>(null);
  const [workspaceParams] = useMyWorkspaceParams({
    workspaceId,
  });
  const [workspace] = useMyWorkspace(workspaceParams);
  const isWorkspaceOwner = workspace?.id === workspaceId;

  useEffect(() => {
    form.resetFields();
    setSelectedIds([]);
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    if (selectedUser) {
      form.setFieldsValue({
        users: form.getFieldValue('users')?.map((user: AddUserFormValue['users'], i: number) =>
          i === 0
            ? {
                ...user,
                companyId: selectedUser.WorkspaceUser?.Company?.id || null,
                userId: selectedUser.id,
                userRole: selectedUser.userRole || 'member',
              }
            : user
        ),
      });
    }
  }, [isModalOpen, selectedUser, form]);

  const handleOk = async () => {
    setIsSubmitted(true);
    try {
      await form.validateFields();
      const values = form.getFieldValue('users');
      console.log(values);
      onSubmit(values);
      refreshUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
    form.resetFields();
  };

  const removeItem = (index: number) => {
    const removeUserId = form.getFieldValue(['users', index, 'userId']);
    if (removeUserId) {
      setSelectedIds((prev) => prev.filter((i) => i !== removeUserId));
    }
    // Remove item from the list
    const users = form.getFieldValue('users') || [];
    users.splice(index, 1);
    form.setFieldsValue({ users });
  };

  const onCompanyCreate = (value: Company) => {
    refershCompanies();
    setIsCreateOpen(false);
    setIsCompanyProfile(true);
    const users = form.getFieldValue('users') || [];
    let userAddingNewCompany = form.getFieldValue(['users', indexOfNewCompany]);
    if (userAddingNewCompany && indexOfNewCompany !== null) {
      Object.assign(users[indexOfNewCompany], { companyId: value.id });
    } else {
      userAddingNewCompany = {
        userId: null,
        email: null,
        companyId: value.id,
      };
      users.splice(indexOfNewCompany, 1, userAddingNewCompany);
    }
    form.setFieldsValue({ users });
  };

  const getCompanyIdValue = (index: number) => {
    return form.getFieldValue(['users', index, 'companyId']);
  };

  const handleRolesSelect = (index: number) => {
    const companyId =
      getCompanyIdValue(index) !== null ? getCompanyIdValue(index) : selectedUser ? selectedUser.Company?.id : null;
    return ownerCompaniesIds.includes(companyId) &&
      (users?.find((user) => user.id == selectedIds[index]) || selectedUser)
      ? projectRoles?.map((option) => ({
          value: option.id,
          label: option.name,
          disabled: false,
          tooltip: null,
        }))
      : projectRoles?.map((option) => ({
          value: option.id,
          label: option.name,
          disabled: option.id === 'admin' || option.id === 'guest',
          tooltip:
            !(users?.find((user) => user.id == selectedIds[index]) || selectedUser) &&
            ownerCompaniesIds.includes(companyId)
              ? option.tooltip
              : null,
        }));
  };

  return (
    <>
      {isCreateOpen !== null && (
        <CreateCompany isModalOpen={isCreateOpen} setIsModalOpen={setIsCreateOpen} onSubmit={onCompanyCreate} />
      )}
      <Modal
        title={
          selectedUser
            ? `${t('Edit user')} ${selectedUser?.fullName}`
            : `${t('Add users to')} ${project?.name} ${t('project')}`
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={880}
        classNames={{ body: 'project-user-invitation-modal' }}
        footer={[
          <Button onClick={handleCancel} key='back'>
            {t('Cancel')}
          </Button>,
          <Button onClick={handleOk} loading={loading === 'pending' ? true : false} key='submit' type='primary'>
            {t('Validate')}
          </Button>,
        ]}
      >
        <Form form={form} name='add-user' layout='vertical' validateTrigger={isSubmitted ? 'onChange' : 'onSubmit'}>
          <Typography.Title level={3} className='section-title'>
            {t('Users')}
          </Typography.Title>
          <Form.List name='users' initialValue={[{}]}>
            {(fields, { add }) => (
              <>
                {fields?.map(({ key, name, ...restField }, index) => (
                  <Row gutter={16} key={key}>
                    <Col span={10}>
                      <InviteUserSelect
                        form={form}
                        users={users}
                        formRestField={restField}
                        formFieldName={name}
                        excludingOptions={excludeUserIds ?? []}
                        selectedIds={selectedIds ?? []}
                        selectedUser={selectedUser}
                        onSelectUser={(value) => {
                          const copyIds = [...selectedIds];
                          copyIds[index] = value;
                          setSelectedIds(copyIds);
                        }}
                      />
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        className='form-item-inline'
                        labelCol={{ span: 12 }}
                        name={[name, 'companyId']}
                        initialValue={selectedUser ? selectedUser?.WorkspaceUser?.Company?.id : null}
                        rules={[{ required: true, message: '' }]}
                      >
                        <>
                          <SearchSelect
                            placeholder={t('Company Name')}
                            disabled={!!selectedIds[index] || !!selectedUser}
                            options={companies?.map((option) => ({
                              value: option.id,
                              label: option.name,
                            }))}
                            onChange={(value) => {
                              form.setFieldsValue({
                                users: form
                                  .getFieldValue('users')
                                  ?.map((user: AddUserFormValue['users'], i: number) =>
                                    i === index ? { ...user, companyId: value } : user
                                  ),
                              });
                            }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                {isWorkspaceOwner && (
                                  <Button
                                    type='link'
                                    onClick={() => {
                                      setIsCreateOpen(true);
                                      setIndexOfNewCompany(index);
                                    }}
                                    icon={<PlusOutlined />}
                                  >
                                    {t('Register company')}
                                  </Button>
                                )}
                              </>
                            )}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        className='form-item-inline'
                        labelCol={{ span: 12 }}
                        name={[name, 'userRole']}
                        rules={[{ required: true, message: '' }]}
                        initialValue={'member'}
                      >
                        <>
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              (option?.label ?? '').toString().toLowerCase().includes(input?.toLowerCase())
                            }
                          >
                            {handleRolesSelect(index)?.map((option) => (
                              <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
                                {option.tooltip ? (
                                  <Tooltip title={option.tooltip}>{option.label}</Tooltip>
                                ) : (
                                  option.label
                                )}
                              </Select.Option>
                            ))}
                          </Select>
                        </>
                      </Form.Item>
                    </Col>
                    {index > 0 && (
                      <Col span={1}>
                        <DeleteOutlined
                          style={{
                            color: 'grey',
                            fontSize: 20,
                            marginTop: '5px',
                          }}
                          onClick={() => removeItem(index)}
                        />
                      </Col>
                    )}
                  </Row>
                ))}
                {!selectedUser && (
                  <Row>
                    <Col span={24}>
                      <Button
                        className='primary-dashed-btn'
                        icon={<PlusOutlined />}
                        type='dashed'
                        onClick={() => add()}
                      >
                        {t('Add user')}
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}
