import { Avatar, Button, Card } from 'antd';
import { MiniPlan } from 'components/common';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { Attachment, User } from 'model';
import React, { useMemo } from 'react';
import { CloseOutlined } from '@ant-design/icons';

const { Meta } = Card;

interface PhotoItemProps {
  attachment: Attachment;
  onDelete: (photoId: string) => void;
  onDrawing: (attachment: Attachment) => void;
  isClosedStatus: boolean;
  isAttachmentDisable: boolean;
  users: User[];
}
const AttachmentItem: React.FC<PhotoItemProps> = ({ attachment, onDelete, isAttachmentDisable, users, onDrawing }) => {
  const userCreated = users.find((user) => user.id === attachment.IssueAttachments?.[0].userCreated);
  const maxWidth = 320;
  const maxHeight = 215;

  const rate = useMemo(() => {
    if (attachment) {
      return Math.min(maxWidth / (attachment?.width ?? 1), maxHeight / (attachment?.height ?? 1));
    }
    return 1;
  }, [attachment]);

  const width = (attachment?.width ?? 1) * rate;
  return (
    <motion.div whileHover={{ scale: 1.05, transition: { duration: 0.35 } }}>
      <div className='attachment-card-outline'>
        <Card
          hoverable
          className='attachment-card'
          onClick={() => onDrawing(attachment)}
          cover={
            <div className='attachment-cover-wrapper'>
              <MiniPlan
                width={width}
                filePath={attachment?.filePath as string}
                imgWidth={attachment?.width as number}
                imgHeight={attachment?.height as number}
                onClick={() => {}}
                layers={
                  attachment.IssueAttachments?.[0]?.layerData ? [attachment.IssueAttachments?.[0]?.layerData] : []
                }
                module={''}
                plan={attachment.IssueAttachments?.[0] ?? null}
                inspectionName={''}
                planImage={attachment}
              />
            </div>
          }
        >
          <Meta
            className='attachment-meta'
            avatar={
              userCreated?.profilePhoto ? (
                <AvatarItem size={50} src={userCreated?.profilePhoto} />
              ) : (
                <Avatar size={50}>{userCreated?.firstName?.substring(0, 1)}</Avatar>
              )
            }
            title={userCreated?.fullName}
            description={`${dayjs(attachment.IssueAttachments?.[0].createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
          />
          <div className='attachment-text pt-3'>{attachment.IssueAttachments?.[0].comment}</div>
        </Card>
        {!isAttachmentDisable && (
          <Button
            className='attachment-btclose'
            type='primary'
            shape='circle'
            danger
            icon={<CloseOutlined size={15} />}
            onClick={() => onDelete(attachment.id)}
          />
        )}
      </div>
    </motion.div>
  );
};

export default AttachmentItem;
