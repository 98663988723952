import { BarsOutlined, FileSyncOutlined, FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, message, Segmented, Typography } from 'antd';
import SVGFloorplan_quality from 'assets/images/carbon_floorplan_quality.svg';
import SVGFloorplan_safety from 'assets/images/carbon_floorplan_safety.svg';
import {
  CreateOrUpdate,
  FilterIssueForm,
  InspectionsListView,
  InspectionsOnPlans,
  InspectionsStatistic,
} from 'components';
import dayjs from 'dayjs';
import {
  useIssueStatuses,
  useIssueStatusesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectIssueKpis,
  useProjectIssueKpisParams,
} from 'hooks';
import { useProjectIssues, useProjectIssuesCount, useProjectIssuesParams } from 'hooks/issue';
import { User } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { QueryParams } from 'types';
import { parseQueryParams, titleCase, useSearchDebounce } from 'utils';
import { ISSUE_STATUSES } from 'utils/contants';
import GenerateReports from './Reports/GenerateReports';

type InspectionsProps = {
  module: string;
  workspaceId: string;
  isOwnerCompany: boolean;
  loggedCompanyRoleId: string;
  userCompanyId: undefined | string;
  profile: User | null;
  users: User[];
  refreshUser: (options?: QueryParams | undefined) => void;
};

export default function Inspections(props: InspectionsProps) {
  const { module, workspaceId, isOwnerCompany, loggedCompanyRoleId, userCompanyId, profile, users, refreshUser } =
    props;
  const location = useLocation();
  const stateValue = location.state?.id;
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const [issueId, setIssueId] = useState<string | null>(null);
  const [activeTabView, setActiveTabView] = useState('listView');
  const [activeZoneView, setActiveZoneView] = useState<string>('');
  const [tabParamValue, setTabParamValue] = useState<string | null>(searchParams.get('tab'));
  const [zoneParamValue, setZoneParamValue] = useState<string | null>(searchParams.get('selectedZone'));
  const [isGenerateReports, setIsGenerateReports] = useState<boolean | null>(null);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const params = useParams();
  const projectId = params.projectId as string;

  const [issueKpisquery, issueKpisParamsChange] = useProjectIssueKpisParams({
    projectId: projectId as string,
    ...(!isOwnerCompany && { 'companyId[]': `["${loggedCompanyRoleId}"]` }),
    workspaceId,
    module,
    include: 'IssueType|Company|Zone|IssueWatchers',
  });
  const [issueKpis, , refreshIssuesKpis] = useProjectIssueKpis(issueKpisquery);

  const [projectIssueParams, onProjectIssuesParamsChange] = useProjectIssuesParams({
    projectId: projectId as string,
    ...(!isOwnerCompany && { 'companyId[]': `["${loggedCompanyRoleId}"]` }),
    workspaceId,
    module,
    include:
      'IssuePriority|IssueStatus|IssueType|Company|Zone|IssueDiscipline|Plans|Photos|IssueWatchers|Discipline|Activity',
  });
  const [issuesCount] = useProjectIssuesCount(projectIssueParams);

  const [issues, loadingIssues, refreshIssues] = useProjectIssues(projectIssueParams);

  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'INS',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);

  useEffect(() => {
    if (isFormOpen !== null && !isFormOpen) {
      refreshIssues();
      refreshIssuesKpis();
    }
  }, [isFormOpen]);

  useEffect(() => {
    const params = parseQueryParams(window.location.search);
    if (params.searchKey) {
      setSearchValue('');
      setShowSearchInput(false);
    }
  }, []);

  const onOpenIssueForm = (issueId: string | null) => {
    setIsFormOpen(true);
    setIssueId(issueId);
  };

  const onSubmitCreate = () => {
    setIsFormOpen(false);
    setTimeout(() => {
      setIssueId(null);
    }, 200);
  };

  useEffect(() => {
    if (tabParamValue !== null) {
      setActiveTabView(tabParamValue);
      if (zoneParamValue !== null) {
        setActiveZoneView(zoneParamValue);
        searchParams.delete('selectedZone');
        setZoneParamValue(null);
      }
      searchParams.delete('tab');
      window.history.replaceState({}, '', `${location.pathname}`);
      setTabParamValue(null);
    }
  }, [tabParamValue]);

  const handleFilter = async (values: QueryParams) => {
    let companyId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;
    let profileId = undefined;

    if (values.groupIssues === 'myIssues') {
      profileId = `${profile?.id}`;
    } else if (values.groupIssues === 'companyIssues' && userCompanyId) {
      companyId = `["${userCompanyId}"]`;
    } else if (values.groupIssues === 'watchedIssues' && profile?.id) {
      watcherId = `${profile?.id}`;
    } else {
      if (values.companyId) {
        companyId = `["${values.companyId}"]`;
      }
      if (values.assigneeId) {
        assigneeId = `["${values.assigneeId}"]`;
      }
    }
    const issueDisciplineId =
      module !== 'Quality' && values.issueDisciplineId && values.issueDisciplineId.length > 0
        ? `[${'"' + values.issueDisciplineId.join('","') + '"'}]`
        : undefined;
    const disciplineId =
      module === 'Quality' && values.disciplineId && values.disciplineId.length > 0
        ? `[${'"' + values.disciplineId.join('","') + '"'}]`
        : undefined;
    const issuePriorityId =
      values.issuePriorityId && values.issuePriorityId.length > 0
        ? `[${'"' + values.issuePriorityId.join('","') + '"'}]`
        : undefined;
    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const zoneId = values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;
    const params = {
      'plannedStartDate[gte]':
        values.plannedStartDate && values.plannedStartDate[0]
          ? dayjs(values.plannedStartDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'plannedStartDate[lte]':
        values.plannedStartDate && values.plannedStartDate[1]
          ? dayjs(values.plannedStartDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      issueTypeId: values.issueTypeId ? values.issueTypeId : undefined,
      'companyId[]': companyId,
      'assigneeId[]': assigneeId,
      'issueDisciplineId[]': issueDisciplineId,
      'disciplineId[]': disciplineId,
      'issuePriorityId[]': issuePriorityId,
      'issueStatusId[]': issueStatusId,
      'issueOverdueStatusId[]': undefined,
      'zoneId[]': zoneId,
      profileId: profileId,
      watcherId: watcherId,
      page: 1,
    };
    setIsResetFilterByKPI(true);
    onProjectIssuesParamsChange(params);
    issueKpisParamsChange(params);
    // console.log(params);
  };

  const handleFilterByKPI = async (code: string) => {
    let issueStatusId;
    let issueOverdueStatusId;

    if (code === ISSUE_STATUSES.READY_FOR_INSPECTION_OVERDUE) {
      issueOverdueStatusId = issueStatuses.find((value) => value.code === ISSUE_STATUSES.READY_FOR_INSPECTION)?.id;
    } else if (code === ISSUE_STATUSES.OPEN_OVERDUE) {
      issueOverdueStatusId = issueStatuses.find((value) => value.code === ISSUE_STATUSES.OPEN)?.id;
    } else {
      issueStatusId = issueStatuses.find((value) => value.code === code)?.id;
    }
    const params = {
      'plannedStartDate[gte]': undefined,
      'plannedStartDate[lte]': undefined,
      'issueStatusId[]': issueStatusId ? `[${'"' + issueStatusId + '"'}]` : undefined,
      'issueOverdueStatusId[]': issueOverdueStatusId ? `[${'"' + issueOverdueStatusId + '"'}]` : undefined,
      issueTypeId: undefined,
      'companyId[]': undefined,
      'assigneeId[]': undefined,
      'issueDisciplineId[]': undefined,
      'disciplineId[]': undefined,
      'issuePriorityId[]': undefined,
      'zoneId[]': undefined,
      profileId: undefined,
      watcherId: undefined,
      page: 1,
    };
    onProjectIssuesParamsChange(params);
    issueKpisParamsChange(params);
    setIsResetFilter(true);
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };

  const handleSearch = async () => {
    try {
      const param = {
        searchBy: '["Issue.name","Company.name","IssueType.name","Zone.name"]',
        searchKey: searchValue,
        page: 1,
      };
      onProjectIssuesParamsChange(param);
      issueKpisParamsChange(param);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  useEffect(() => {
    if (stateValue) {
      setIsFormOpen(true);
      setIssueId(stateValue);
    }
  }, [stateValue]);
  return (
    <Layout.Content className='settings-content'>
      {isFormOpen !== null && (
        <CreateOrUpdate
          isOpen={isFormOpen}
          setOpen={setIsFormOpen}
          onFinish={onSubmitCreate}
          module={module}
          issueId={issueId}
          refreshIssues={refreshIssues}
          workspaceId={workspaceId}
        />
      )}
      {isGenerateReports !== null && (
        <GenerateReports
          isGenerateReports={isGenerateReports === true}
          profile={profile}
          users={users}
          module={module}
          workspaceId={workspaceId}
          projectId={projectId}
          companies={companies}
          issueStatuses={issueStatuses}
          selectedRowKeys={selectedRowKeys}
          setIsGenerateReports={(value) => setIsGenerateReports(value)}
        />
      )}
      <Flex>
        <Typography.Title level={2} className='mr-4' style={{ height: '32px' }}>
          {module && module === 'Safety' ? t('Inspections') : t('Observations')}
        </Typography.Title>

        <Segmented
          size='large'
          className='inspection-segmented mr-3'
          options={[
            {
              label: titleCase(t('As a list')),
              value: 'listView',
              icon: <BarsOutlined />,
            },
            {
              label: titleCase(t('On zoning plans')),
              value: 'plansView',
              icon: (
                <img
                  src={module && module === 'Safety' ? SVGFloorplan_safety : SVGFloorplan_quality}
                  alt=''
                  width={16}
                  height={16}
                />
              ),
            },
          ]}
          defaultValue={tabParamValue ? tabParamValue : activeTabView}
          onChange={(value) => setActiveTabView(value as string)}
        />
      </Flex>
      <Flex style={{ justifyContent: 'flex-end' }} className='mt-3 mb-3'>
        <Input
          name='search-input'
          className='search-button mr-3'
          placeholder={t('Type to search')}
          onBlur={(e) => handleInputBlur(e.target.value)}
          onFocus={handleSearchIconClick}
          style={{
            width: showSearchInput ? '250px' : '0px',
            border: showSearchInput ? '1px solid #d9d9d9' : 'none',
          }}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
        />
        {isOpenFilter === null ? (
          <Button
            onClick={() => {
              setIsOpenFilter(!isOpenFilter);
            }}
            icon={<FilterOutlined />}
          >
            {t('Filter')}
          </Button>
        ) : (
          <FilterIssueForm
            handleFilter={(value) => {
              handleFilter(value);
            }}
            isOpenFilter={isOpenFilter === true}
            setIsOpenFilter={setIsOpenFilter}
            projectId={projectId}
            workspaceId={workspaceId}
            isReset={isResetFilter || false}
            setReset={setIsResetFilter}
            companies={companies}
            users={users}
            isOwnerCompany={isOwnerCompany}
            issueStatuses={issueStatuses}
            userCompanyId={userCompanyId}
            module={module}
          />
        )}
        <Button onClick={() => setIsGenerateReports(true)} icon={<FileSyncOutlined />}>
          {t('Generate report')}
        </Button>
        <Button type='primary' icon={<PlusOutlined />} onClick={() => onOpenIssueForm(null)}>
          {module && module === 'Safety' ? t('Add inspection') : t('Add observation')}
        </Button>
      </Flex>
      <InspectionsStatistic
        kpiData={issueKpis?.kpiData}
        handleFilterByKPI={handleFilterByKPI}
        issueStatuses={issueStatuses}
        isResetKPI={isResetFilterByKPI}
        setResetKPI={setIsResetFilterByKPI}
      />
      {activeTabView === 'listView' ? (
        <InspectionsListView
          refreshUser={refreshUser}
          users={users}
          module={module}
          projectId={projectId}
          workspaceId={workspaceId}
          issues={issues}
          onFilter={(value) => {
            onProjectIssuesParamsChange(value);
          }}
          loading={loadingIssues === 'pending' && !issues?.length}
          onEditIssue={onOpenIssueForm}
          refreshIssues={refreshIssues}
          issuesCount={issuesCount}
          issueParams={projectIssueParams}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ) : (
        <InspectionsOnPlans
          workspaceId={workspaceId}
          projectId={projectId}
          module={module}
          onEditIssue={onOpenIssueForm}
          selectedZoneId={activeZoneView}
          isOwnerCompany={isOwnerCompany}
          loggedCompanyId={userCompanyId || null}
        />
      )}
    </Layout.Content>
  );
}
