import React from 'react';
import { Col, Form, Input, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import MARsListPicture from './MARsListPicture';
import { ReadOnlyDataField, SearchSelect } from 'components/common';
import MARsListDocumentSection from './MARsListDocument';
import { Attachment, AttachmentInfo, BaseIssue, Country, MARDescription } from 'model';
import useUserEntity from 'hooks/common/useUserProfile';

const { TextArea } = Input;
export type MaterialDetailType = 'proposed' | 'specified';

interface MaterialDetailFormProps {
  isReadOnly: boolean;
  position: MaterialDetailType;
  countries: Country[];
  workspaceId: string;
  projectId: string;
  selectedMarBaseIssue: BaseIssue | null;
  isOpen: boolean;
  marDescription: MARDescription | null;
  attachments: Attachment[];
  materialLoading: boolean;
  documentsInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  photosInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  setDocumentsInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
  setPhotosInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
  fetchMarAttachments: (position?: string) => void;
  // form: FormInstance<WIRFormValues>;
}

const MaterialDetailForm: React.FC<MaterialDetailFormProps> = ({
  isReadOnly,
  position,
  countries,
  workspaceId,
  projectId,
  selectedMarBaseIssue,
  marDescription,
  attachments,
  materialLoading,
  documentsInfo,
  photosInfo,
  setDocumentsInfo,
  setPhotosInfo,
  fetchMarAttachments,
  // form
}) => {
  const { t } = useTranslation();
  const { user } = useUserEntity({ projectId, workspaceId });
  return (
    <Spin spinning={materialLoading}>
      <div className='form-container'>
        <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField title={'Manufacturer'} content={marDescription?.manufacturer} />
            ) : (
              <Form.Item
                // name={['manufacturer', 'proposed']}
                name={position === 'proposed' ? ['manufacturer', 'proposed'] : ['manufacturer', 'specified']}
                label={t('Manufacturer')}
                rules={[{ required: position === 'proposed', message: '' }]}
              >
                <Input allowClear={true} placeholder={t('Enter Manufacturer')} />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField title={'Material Trade Name'} content={marDescription?.tradeName} />
            ) : (
              <Form.Item
                // name={'tradeName'}
                name={position === 'proposed' ? ['tradeName', 'proposed'] : ['tradeName', 'specified']}
                // name={position === 'proposed' ? 'tradeName' : 'tradeName-specified'}
                label={t('Material Trade Name')}
                rules={[{ required: false, message: '' }]}
              >
                <Input allowClear={true} placeholder={t('Enter Trade Name')} />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField title={'Type'} content={marDescription?.type} />
            ) : (
              <Form.Item
                // name={'type'}
                name={position === 'proposed' ? ['type', 'proposed'] : ['type', 'specified']}
                // name={position === 'proposed' ? 'type' : 'type-specified'}
                label={t('Type')}
                rules={[{ required: false, message: '' }]}
              >
                <Input allowClear={true} placeholder={t('Enter type')} />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField title={'Model'} content={marDescription?.model} />
            ) : (
              <Form.Item
                // name={'model'}
                name={position === 'proposed' ? ['model', 'proposed'] : ['model', 'specified']}
                // name={position === 'proposed' ? 'model' : 'model-specified'}
                label={t('Model')}
                rules={[{ required: false, message: '' }]}
              >
                <Input allowClear={true} placeholder={t('Enter Model')} />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={'Country of origin'}
                content={countries.find((country) => country.id === marDescription?.countryId)?.name}
              />
            ) : (
              <Form.Item
                // name={'countryId'}
                name={position === 'proposed' ? ['countryId', 'proposed'] : ['countryId', 'specified']}
                // name={position === 'proposed' ? 'countryId' : 'countryId-specified'}
                label={t('Country of origin')}
                rules={[{ required: false, message: '' }]}
              >
                <SearchSelect
                  placeholder={t('Select country of origin')}
                  options={countries.map((country) => ({ value: country.id, label: country.name }))}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <MARsListDocumentSection
              isReadOnly={isReadOnly}
              selectedMarBaseIssue={selectedMarBaseIssue}
              workspaceId={workspaceId}
              projectId={projectId}
              position={position}
              attachments={attachments}
              documentsInfo={documentsInfo}
              fetchMarAttachments={(position?: string) => fetchMarAttachments(position)}
              setDocumentsInfo={(value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) =>
                setDocumentsInfo(value)
              }
            />
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row mb-3' : 'mb-3'}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField title={'Description'} content={marDescription?.description} />
            ) : (
              <>
                {/* <Form.Item
                // name={'description'}
                // name={position === 'proposed' ? 'description' : 'description-specified'}
                label={t('Description')}
              >
                <TextArea allowClear={true} placeholder={t('Add description text here...')} disabled={false} />
              </Form.Item> */}
                <Form.Item
                  name={position === 'proposed' ? ['description', 'proposed'] : ['description', 'specified']}
                  label={t('Description of the Material')}
                  rules={[
                    {
                      max: 300,
                      message: t('Description cannot exceed 300 characters'),
                    },
                  ]}
                >
                  <TextArea style={{ textTransform: 'none' }} allowClear placeholder={t('Add description here...')} />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            <MARsListPicture
              isReadOnly={isReadOnly}
              selectedMarBaseIssue={selectedMarBaseIssue}
              workspaceId={workspaceId}
              projectId={projectId}
              position={position}
              attachments={attachments}
              photosInfo={photosInfo}
              setPhotosInfo={(value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) =>
                setPhotosInfo(value)
              }
              fetchMarAttachments={(position: string) => fetchMarAttachments(position)}
              materialLoading={materialLoading}
              user={user}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default MaterialDetailForm;
