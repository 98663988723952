import { CompanyCategory } from 'model';
import request from 'requesters/core.request';
import { QueryParams } from 'types';

const companyCategoryService = {
  getCompanyCategories: async (_workspaceId: string, query: QueryParams) => {
    return request.get<CompanyCategory[]>(`/public-company-categories`, {
      params: query,
    });
  },
  deleteCompanyCategory: async (workspaceId: string, companyCategoryId: string) => {
    return request.delete(`/workspaces/${workspaceId}/public-company-categories/${companyCategoryId}`);
  },
  multipleCreateOrUpdate: async (workspaceId: string, data: Record<string, unknown>) => {
    return request.put(`/workspaces/${workspaceId}/public-company-categories/multiple-create-or-update`, data);
  },
};

export default companyCategoryService;
