import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, message, Typography } from 'antd';
import { t } from 'i18next';
import MethodStatementsStatistic from './MethodStatementsStatistic';
import { MethodStatementsListView } from '.';
import { useEffect, useRef, useState } from 'react';
import CreateOrUpdateMSS from './CreateOrUpdateMSS';
import { useFetchProjectDisciplineByFunction, useFetchProjectDisciplineByFunctionParams } from 'hooks/base-issue';
import {
  useInspectionTestPlain,
  useInspectionTestPlanParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
} from 'hooks';
import { MODULE_FUNCTIONS, MSS_STATUSES } from 'utils/contants';
import { parseQueryParams, useSearchDebounce } from 'utils';
import FilterMethodStatementForm from './FilterMethodStatementForm';
import { QueryParams } from 'types';
import dayjs from 'dayjs';
import { User } from 'model';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/DisciplineBaseIssueTable';
import { kpiData } from 'types/project';
import BaseIssueKpiStatistic from 'components/common/BaseIssueKpiStatistic';

type MethodStatementProps = {
  module: string;
  workspaceId: string;
  projectId: string;
  isOwnerCompany: boolean;
  users: User[];
  profile: User | null;
  loggedCompanyRoleId: string;
  userCompanyId: string | undefined;
};
export default function MethodStatements(props: MethodStatementProps) {
  const { module, workspaceId, projectId, users, profile, userCompanyId, loggedCompanyRoleId, isOwnerCompany } = props;
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [baseIssueId, setBaseIssueId] = useState<string | null>(null);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [projectBaseIssueParams, onProjectBaseIssuesParamsChange] = useState<QueryParams>({
    projectId: projectId as string,
    ...(!isOwnerCompany && { 'companyId[]': `["${loggedCompanyRoleId}"]` }),
    workspaceId,
    module,
    type: 'MSS',
    limit: 10,
    page: 1,
    include:
      'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments|Attachments',
  });

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.METHOD_STATEMENT_SUBMISSION,
  });
  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: props.projectId as string,
    workspaceId: props.workspaceId as string,
    orderBy: 'order',
    mainPhase: true,
  });
  useWorkBreakdownStructures(workBreakdownStructureQuery);
  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  useInspectionTestPlain(inspectionTestPlanParams);
  const disciplineBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);

  const [ProjectDisciplineByFunctionParams] = useFetchProjectDisciplineByFunctionParams({
    workspaceId,
    projectId,
    type: 'MSS',
  });
  const [, , refreshDisciplineByFunction] = useFetchProjectDisciplineByFunction(ProjectDisciplineByFunctionParams);

  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const [totalKpi, setTotalKpi] = useState<kpiData>({});
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const handleEditForm = (id: string) => {
    try {
      setBaseIssueId(id ?? null);
      setIsFormOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFilter = async (values: QueryParams) => {
    let companyId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === 'myCompany' && userCompanyId) {
      companyId = `${userCompanyId}`;
    } else if (values.groupIssues === 'watched' && profile?.id) {
      watcherId = `${profile?.id}`;
    } else {
      if (values.companyId) {
        companyId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }
    const zoneId = values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;

    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const workPackageId =
      values.workPackageId && values.workPackageId.length > 0
        ? `[${'"' + values.workPackageId.join('","') + '"'}]`
        : undefined;

    const params = {
      ...projectBaseIssueParams,
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      'statusId[]': issueStatusId,
      'workPackageId[]': workPackageId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : companyId,
      originatorId: assigneeId,
      'zoneId[]': zoneId,
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };
  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };
  const handleSearch = async () => {
    try {
      const param = {
        ...projectBaseIssueParams,
        searchBy: '["BaseIssue.name","Company.name","MethodStatementSubmission.mssNumber","Zone.name"]',
        searchKey: searchValue,
        page: 1,
      };
      onProjectBaseIssuesParamsChange(param);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchValue]);
  useEffect(() => {
    const params = parseQueryParams(window.location.search);
    if (params.searchKey) {
      setSearchValue('');
      setShowSearchInput(false);
    }
  }, []);

  return (
    <Layout.Content className='settings-content'>
      {isFormOpen !== null && (
        <CreateOrUpdateMSS
          isOpen={isFormOpen}
          module={module}
          workspaceId={workspaceId}
          projectId={projectId}
          baseIssueId={baseIssueId}
          issueStatuses={issueStatuses}
          setOpen={setIsFormOpen}
          refreshBaseIssues={(disciplineId) => {
            disciplineBaseIssueTableRef.current?.refreshData(disciplineId, baseIssueId);
          }}
          setBaseIssueId={setBaseIssueId}
        />
      )}
      <Flex>
        <Typography.Title level={2} className='mr-16'>
          {' '}
          {t('Method statements - MSS')}
        </Typography.Title>
      </Flex>
      <Flex style={{ justifyContent: 'flex-end' }} className='mt-3 mb-3'>
        <Input
          name='search-input'
          className='search-button mr-3'
          placeholder={t('Type to search')}
          onBlur={(e) => handleInputBlur(e.target.value)}
          onFocus={handleSearchIconClick}
          style={{
            width: showSearchInput ? '250px' : '0px',
            border: showSearchInput ? '1px solid #d9d9d9' : 'none',
          }}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
        />
        {isOpenFilter === null ? (
          <Button
            onClick={() => {
              setIsOpenFilter(!isOpenFilter);
            }}
            icon={<FilterOutlined />}
          >
            {t('Filter')}
          </Button>
        ) : (
          <FilterMethodStatementForm
            setZoneUpdated={setZoneUpdated}
            zoneUpdated={zoneUpdated}
            companies={companies}
            users={users}
            issueStatuses={issueStatuses}
            workspaceId={workspaceId}
            userCompanyId={userCompanyId}
            projectId={projectId}
            isOpenFilter={isOpenFilter}
            setIsOpenFilter={setIsOpenFilter}
            handleFilter={(value) => {
              handleFilter(value);
            }}
            isReset={isResetFilter || false}
            setReset={setIsResetFilter}
            module={module}
            isOwnerCompany={isOwnerCompany}
          />
        )}
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            setIsFormOpen(true);
          }}
        >
          {t('Add method statement')}
        </Button>
      </Flex>

      <BaseIssueKpiStatistic
        onFilter={(params) => {
          onProjectBaseIssuesParamsChange(params);
          setIsResetFilter(true);
        }}
        kpiData={totalKpi}
        issueStatuses={issueStatuses}
        projectBaseIssueParams={projectBaseIssueParams}
        isResetKPI={isResetFilterByKPI}
        onResetKPI={setIsResetFilterByKPI}
      />

      <MethodStatementsListView
        projectId={projectId}
        workspaceId={workspaceId}
        profile={profile}
        users={users || []}
        projectBaseIssueParams={projectBaseIssueParams}
        editForm={(id: string) => handleEditForm(id)}
        onFilter={(value) => {
          onProjectBaseIssuesParamsChange({ ...projectBaseIssueParams, ...value });
        }}
        disciplineBaseIssueTableRef={disciplineBaseIssueTableRef}
        onTotalKpi={setTotalKpi}
        refreshDisciplineByFunction={refreshDisciplineByFunction}
      />
    </Layout.Content>
  );
}
