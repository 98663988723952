import { Company } from 'model';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const companyService = {
  getCompanies: async (workspaceId: string, query?: QueryParams) => {
    return request.get<PaginationData<Company>>(`/workspaces/${workspaceId}/companies`, {
      params: query,
    });
  },
  createCompany: async (workspaceId: string, data: { companyCode: string; name: string; workspaceId: string }) => {
    return request.post<Company>(`/workspaces/${workspaceId}/companies`, data);
  },
  updateCompany: async (
    workspaceId: string,
    companyId: string,
    data: { companyCode: string; name: string; workspaceId: string }
  ) => {
    return request.put(`/workspaces/${workspaceId}/companies/${companyId}`, data);
  },
  getProjectCompanies: async (workspaceId: string, projectId: string, query?: QueryParams) => {
    return request.get<PaginationData<Company>>(`/workspaces/${workspaceId}/projects/${projectId}/companies`, {
      params: query,
    });
  },
  addCompaniesToProject: async (
    workspaceId: string,
    projectId: string,
    data: { companyId: string; companyRole: string; companyType?: string }[]
  ) => {
    return request.post<PaginationData<Company>>(
      `/workspaces/${workspaceId}/projects/${projectId}/register-companies`,
      data
    );
  },
  deleteCompanies: async (workspaceId: string, companyId: string) => {
    return request.delete<PaginationData<Company>>(`/workspaces/${workspaceId}/companies/${companyId}`);
  },
  getCompanyInfoToRevoke: async (workspaceId: string, projectId: string, companyId: string) => {
    return request.get<{
      isAssignedToCompany: boolean;
      isCompanyCreated: boolean;
      isRelatedWorkPackage: boolean;
      isRelatedBaseIssues: boolean;
    }>(`/workspaces/${workspaceId}/projects/${projectId}/company-revoke-access-info/${companyId}`);
  },
};

export default companyService;
