import { FilePdfOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import { useMemo, useRef } from 'react';
import { fileService } from 'services';
import { formatUrlAttachments } from 'utils';

type ProjectQualityITPTableAttachmentCellProps = {
  url?: string;
};

const ProjectQualityITPTableAttachmentCell = (props: ProjectQualityITPTableAttachmentCellProps) => {
  const elementRef = useRef<HTMLAnchorElement>(null);

  const isTextOverflow = useMemo(() => {
    if (!elementRef.current) return false;
    return elementRef.current?.scrollWidth > elementRef?.current?.clientWidth;
  }, [elementRef.current]);

  return (
    <Flex>
      <div className='flex items-center gap-1 w-full'>
        <FilePdfOutlined className='text-primary' />
        <Tooltip placement='top' title={isTextOverflow ? formatUrlAttachments(props?.url || '') : ''}>
          <a
            ref={elementRef}
            className='text-primary break-all text'
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={(e) => {
              e.preventDefault();
              fileService.downloadFile(props?.url || '', formatUrlAttachments(props?.url || ''));
            }}
            href={props.url}
          >
            {formatUrlAttachments(props?.url || '')}
          </a>
        </Tooltip>
      </div>
    </Flex>
  );
};

export default ProjectQualityITPTableAttachmentCell;
