import { Typography, Row, Col, Input, DatePicker, Form } from 'antd';
import { t } from 'i18next';
import { mockProjectMinutesOfMeetingData } from 'mock-data/project-data';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectParametersSetting } from 'store/my-projects.slice';
import { EProjectMinutesOfMeetingFieldNames } from 'types/toolbox';
import { getDateFormatFromSetting } from 'utils/my-project';

type ProjectMinutesOfMeetingInformationNextMeetingProps = Pick<typeof mockProjectMinutesOfMeetingData, 'category'>;
const ProjectMinutesOfMeetingInformationNextMeeting: React.FC<
  ProjectMinutesOfMeetingInformationNextMeetingProps
> = () => {
  const parametersSettings = useSelector(selectParametersSetting);

  return (
    <React.Fragment>
      <Typography.Title level={3} className='form-title'>
        {t('Information Next Meeting')}
      </Typography.Title>
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item name={EProjectMinutesOfMeetingFieldNames.NextMeetingDate} label={t('Next Meeting Date')}>
            <DatePicker
              className='w-full'
              disabled={false}
              placeholder={t('Please select date')}
              format={getDateFormatFromSetting(parametersSettings)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={EProjectMinutesOfMeetingFieldNames.NextMeetingLocation} label={t('Next Meeting Location')}>
            <Input allowClear={true} placeholder={t('Please enter location')} />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProjectMinutesOfMeetingInformationNextMeeting;
