import { Flex, FormInstance, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseIssue, Company, User } from 'model';
import { VALIDATION_ACTION } from 'utils/contants';

const { Text } = Typography;

type ThirdStepContentProps = {
  form: FormInstance;
  users: User[];
  companies: Company[];
  companyId: string;
  companyRep: string;
  isFinal: boolean;
  originator: User | undefined
};

export default function ThirdStepContent(props: ThirdStepContentProps) {
  const { t } = useTranslation();
  const { form, users, companies, companyId, companyRep, isFinal, originator } = props;

  const selectionForm: string = form.getFieldValue('validation-option');
  const selection: string =
    selectionForm !== VALIDATION_ACTION.VALIDATE
      ? selectionForm
      : isFinal
      ? VALIDATION_ACTION.FINAL_VALIDATION
      : selectionForm;
  console.log(selection);
  const comments: string = form.getFieldValue('first-step-comment');
  // const companyId: string = form.getFieldValue('companyId');
  // const companyRep: string = form.getFieldValue('validatorName');

  const username = users?.find((user) => user.id === companyRep);
  const company = companies?.find((company) => company.id === companyId);

  return (
    <>
      <Flex vertical>
        <Text className={'validation-text'}>
          {t('You are about to')}{' '}
          <span className={`validation-${selection}`}>
            {selection === VALIDATION_ACTION.FINAL_VALIDATION ? t('FINAL VALIDATE') : t((selection || '').toUpperCase())}
          </span>
          {comments ? ` ${t('the MSS with the comments:')}` : ` ${t('the MSS with no comments.')}`}
        </Text>
        <Text className={'validation-block validation-comments'}>{comments && `"${comments}"`}</Text>
        <Text className={'validation-text mt-3'}>
          {selection === VALIDATION_ACTION.VALIDATE && (
            <>
              {t(`Next validator will be`)}{' '}
              <span className={'validator'}>{` ${username?.fullName} `}</span>
              {t(`of`)}
              <span className={'validator'}>{` ${company?.name} `}</span>
              {t(`company`)}
            </>
          )}
          {selection === VALIDATION_ACTION.TRANSFER && (
            <>
              {t(`It will be transferred to`)}
              <span className={'validator'}>{` ${username?.fullName} `}</span>
              {t(`of`)}
              <span className={'validator'}>{` ${company?.name} `}</span>
              {t(`company`)}
            </>
          )}
          {selection === VALIDATION_ACTION.REJECT && (
            <>
              {t(`It will be notified to its Originator`)}
              <span className={'validator'}>{` ${originator?.fullName} `}</span>
              {t(`of`)}
              <span className={'validator'}>{` ${originator?.WorkspaceUser?.Company?.name || ''} `}</span>
              {t(`company for revision`)}
            </>
          )}
          {selection === VALIDATION_ACTION.FINAL_VALIDATION && (
            <>
              {t(`Final validator is`)}
              <span className={'validator'}>{` ${username?.fullName} `}</span>
              {t(`of`)}
              <span className={'validator'}>{` ${company?.name} `}</span>
              {t(`company`)}
            </>
          )}
        </Text>
      </Flex>
    </>
  );
}
