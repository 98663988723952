import { Drawer, Form, Layout, Spin } from 'antd';
import { RequestForInformationFooter, RequestForInformationHeader } from 'components';
import { useEffect, useMemo, useState } from 'react';
import useAuthContext from 'store/auth-context';
import RequestForInformationForm, {
  RFIFormValues,
} from './CreateOrUpdateRequestForInformation/RequestForInformationForm';
import {
  useIssuePriorities,
  useIssuePrioritiesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useProjectCompanies,
  useProjectCompaniesParams,
} from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { ERFIActionType } from './CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import { selectIssueStatuses, selectModuleFunctionByCode } from 'store/common.slice';
import { MODULE_FUNCTIONS, RFI_STATUSES } from 'utils/contants';
import { baseIssueService } from 'services';
import useUserEntity from 'hooks/common/useUserProfile';
import { mapBaseIssueAttachments, mapBaseIssueDistributions, mapDistributionBaseIssueWatcher } from 'utils/my-project';
import dayjs from 'dayjs';
import { BaseIssue } from 'model';
import { useForm } from 'antd/es/form/Form';
import { createRFIPermissionChecker } from 'utils/rfiHelper';

type CreateOrUpdateRequestForInformationProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  projectId: string;
  workspaceId: string;
  baseIssueId?: string;
  onClose: () => void;
};


const RequestForInformationCreateOrUpdate = (props: CreateOrUpdateRequestForInformationProps) => {
  const { isFormOpen, setIsFormOpen, projectId, workspaceId } = props;
  const [form] = useForm<RFIFormValues>();
  const { profile } = useAuthContext();
  const { user } = useUserEntity({ projectId, workspaceId });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attachmentsInfo, setAttachmentsInfo] = useState<{ name: string; filePath: string }[]>([]);
  const [selectedBaseIssue, setSelectedBaseIssue] = useState<BaseIssue | null>();

  const issueStatuses = useAppSelector(selectIssueStatuses);

  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const users = useAppSelector(selectProjectUsers);

  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);

  const [moduleFunctionsParams] = useModuleFunctionParams();
  useModuleFunctions(moduleFunctionsParams);
  const moduleFunction = useAppSelector(selectModuleFunctionByCode(MODULE_FUNCTIONS.RFI));

  const isReadonly = useMemo(() => {
    if (!selectedBaseIssue) return false;
    const checkPermission = createRFIPermissionChecker(
      selectedBaseIssue?.originatorId,
      user,
      selectedBaseIssue?.BaseIssueWatchers,
      selectedBaseIssue?.IssueStatus?.code || ''
    );
    return !checkPermission(ERFIActionType.EditForm);
  }, [selectedBaseIssue, user]);

  const handleClose = () => {
    setIsFormOpen(false);
    form.resetFields();
    props.onClose();
  };

  useEffect(() => {
    if (!isFormOpen) return;
    initializeForm();
  }, [isFormOpen, props?.baseIssueId]);

  const initializeForm = async () => {
    let baseIssue = null;
    if (props?.baseIssueId) {
      baseIssue = baseIssue = await fetchBaseIssueById(props?.baseIssueId);
    }
    setSelectedBaseIssue(baseIssue);
    setDefaultValue(baseIssue);
  };

  const setDefaultValue = (baseIssue?: BaseIssue | null) => {
    const watcher = mapDistributionBaseIssueWatcher(baseIssue?.BaseIssueWatchers);
    form.setFieldsValue({
      issuer: {
        userId: baseIssue?.originatorId || user?.id,
        companyId: baseIssue?.companyOriginatorId || user?.WorkspaceUser?.Company?.id,
      },
      receiver: watcher.receiver,
      distributions: watcher.distributions,
      subject: baseIssue?.name,
      priorityId: baseIssue?.InformationRequest?.priorityId,
      content: baseIssue?.InformationRequest?.content || '',
      deadline: baseIssue?.endDate ? dayjs(baseIssue?.endDate) : undefined,
      disciplineId: baseIssue?.InformationRequest?.disciplineId,
      attachments:
        baseIssue?.InformationRequest?.InformationRequestAttachments?.map((attachment) => {
          return {
            documentName: attachment?.name,
            filePath: attachment?.filePath,
            name: attachment?.name,
            revision: attachment?.RefAttachment?.revision,
            id: attachment.id,
          };
        }) || [],
    });
  };

  const fetchBaseIssueById = async (baseIssueId: string) => {
    setIsLoading(true);
    try {
      const baseIssue = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'RFI',
        include:
          'IssueStatus|BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      return baseIssue;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const generateRFIPayload = (
    formValues: RFIFormValues,
    formStatusCode: (typeof RFI_STATUSES)[keyof typeof RFI_STATUSES]
  ) => {
    const isSaveAsDraft = formStatusCode === RFI_STATUSES.DRAFT;
    const rfiDistributions = mapBaseIssueDistributions({
      receiver: formValues.receiver,
      distributions: formValues?.distributions,
    });
    console.log("rfiDistributions", rfiDistributions);
    
    const sender = users.find((user) => user.id === profile?.id);
    const originator = users.find((user) => user.id === formValues?.issuer?.userId);
    const listOfNames: string[] = [];
    const recipients: string[] = [];
    const cc: string[] = [];
    users?.forEach((user) => {
      if (formValues.receiver.userIds.includes(user.id)) {
        recipients.push(user?.email as string);
        listOfNames.push(user?.fullName as string);
      }
      if (
        formValues.distributions?.find((distribution) => (distribution?.inspectorIds || []).includes(user.id)) &&
        !recipients.includes(user.email as string)
      ) {
        cc.push(user?.email as string);
        listOfNames.push(user?.fullName as string);
      }
    });
    if (!cc.includes(originator?.email as string)) {
      cc.push(originator?.email as string);
    }
    return {
      name: formValues.subject,
      isSaveAsDraft: isSaveAsDraft,
      originatorId: formValues?.issuer?.userId,
      companyOriginatorId: formValues?.issuer?.companyId,
      statusId: issueStatuses.find((issueStatus) => issueStatus.code === formStatusCode)?.id,
      moduleFunctionId: moduleFunction?.id,
      endDate: formValues.deadline ? dayjs(formValues.deadline).format('YYYY-MM-DD') : null,
      rfi: {
        content: formValues?.content,
        disciplineId: formValues?.disciplineId,
        priorityId: formValues?.priorityId,
        rfiAttachmentInfo: mapBaseIssueAttachments(formValues.attachments),
        rfiDistributions: rfiDistributions,
      },
      emailsData: {
        recipients,
        cc,
        senderName: sender?.fullName,
        senderTitle: sender?.jobTitle,
        senderCompany: sender?.WorkspaceUser?.Company?.name,
        listOfNames,
      },
    };
  };

  const submitRFI = async (formStatusCode: (typeof RFI_STATUSES)[keyof typeof RFI_STATUSES]) => {
    try {
      setIsLoading(true);
      await form.validateFields(formStatusCode === RFI_STATUSES.DRAFT ? ['subject'] : undefined);
      const formValues = form.getFieldsValue(true) as RFIFormValues;
      const payload = generateRFIPayload(formValues, formStatusCode);

      if (props?.baseIssueId) {
        await baseIssueService.updateBaseIssue(workspaceId, projectId, props?.baseIssueId, payload);
        navigate(`/projects/${projectId}/toolbox/request-for-information/${props?.baseIssueId}`);
      } else {
        const createdBaseIssue = await baseIssueService.createBaseIssue(workspaceId, projectId, payload);
        navigate(`/projects/${projectId}/toolbox/request-for-information/${createdBaseIssue.id}`);
      }
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionClick = (type: ERFIActionType) => {
    switch (type) {
      case ERFIActionType.SaveAsDraft:
        submitRFI(RFI_STATUSES.DRAFT);
        break;
      case ERFIActionType.SendToRecipient:
        submitRFI(RFI_STATUSES.OPEN);
        break;
      case ERFIActionType.Save:
        submitRFI(selectedBaseIssue?.IssueStatus?.code as string);
        break;
      case ERFIActionType.Cancel:
        handleClose();
        break;
      default:
        break;
    }
  };

  return (
    <Layout.Content className='material-approval-request-create-modal'>
      <Form form={form} labelCol={{ span: 24 }} layout='vertical'>
        <Drawer
          styles={{
            header: {
              width: '100%',
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={800}
          className={'request-for-information'}
          onClose={handleClose}
          closeIcon={false}
          open={isFormOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <RequestForInformationHeader
              selectedBaseIssue={selectedBaseIssue}
              onClose={handleClose}
              profile={profile}
            />
          }
          footer={
            <RequestForInformationFooter
              user={user}
              selectedBaseIssue={selectedBaseIssue}
              onActionClick={handleActionClick}
            />
          }
        >
          <Spin spinning={isLoading}>
            {isFormOpen && (
              <RequestForInformationForm
                isReadonly={isReadonly}
                companies={companies}
                issuePriorities={issuePriorities}
                profile={profile}
                isOpen={isFormOpen}
                projectId={projectId}
                workspaceId={workspaceId}
                users={users}
                attachmentsInfo={attachmentsInfo}
                setAttachmentsInfo={(value: { name: string; filePath: string }[]) => setAttachmentsInfo(value)}
              />
            )}
          </Spin>
        </Drawer>
      </Form>
    </Layout.Content>
  );
};

RequestForInformationCreateOrUpdate.displayName = 'RequestForInformationCreateOrUpdate';
export default RequestForInformationCreateOrUpdate;
