import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Flex, Form, Input, Popconfirm, Table, Divider } from 'antd';
import { DeleteOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { MaterialCategoriesType, EditableRowProps, EditableCellProps } from 'model/ProjectDataCustomization';
import { materialCategories } from 'mock-data/project-data-customization';

const { Text } = Typography;

const EditableContext = React.createContext<FormInstance | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ children, ...props }) => {
  const [form] = Form.useForm();
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
          {React.Children.map(children, (child) => {
            if ((child as React.ReactElement).key === 'sort') {
              return React.cloneElement(child as React.ReactElement, {
                children: (
                  <HolderOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    ref={setActivatorNodeRef}
                    style={{
                      touchAction: 'none',
                      cursor: 'move',
                      color: 'grey',
                      fontSize: 20,
                    }}
                    {...listeners}
                  />
                ),
              });
            }
            return child;
          })}
        </tr>
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable && !record.isCore) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        initialValue={record[dataIndex]}
        rules={[
          {
            required: true,
            message: ``,
          },
        ]}
      >
        <Input className='categories-text' ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export default function MaterialCategories() {
  const { t } = useTranslation();
  const [count, setCount] = useState(6);
  const [dataSource, setDataSource] = useState<MaterialCategoriesType[]>([
    {
      key: '4',
      name: 'Custom Material Categories 4',
      code: 'XX',
      isCore: false,
    },
    {
      key: '5',
      name: 'Custom  Material Categories 5',
      code: 'XX',
      isCore: false,
    },
  ]);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData: MaterialCategoriesType = {
      key: count,
      name: `Custom Material Categories ${count}`,
      code: 'XX',
      isCore: false,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: MaterialCategoriesType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      width: '36px',
      key: 'sort',
      dataIndex: 'operation',
    },
    {
      width: '520px',
      title: t('Material Categories'),
      dataIndex: 'name',
      editable: true,
      render: (text: string) => (
        <Flex justify={'flex-start'} align={'center'} gap={10}>
          <Text className='categories-text'>{text}</Text>
        </Flex>
      ),
    },
    {
      width: '70px',
      dataIndex: 'code',
      title: t('Code'),
      editable: true,
      render: (text: string) => <Text className='categories-text'>{text}</Text>,
    },
    {
      dataIndex: 'operation',
      align: 'right',
      render: (_, record) =>
        !record.isCore ? (
          <Popconfirm
            title={t('Delete this option?')}
            onConfirm={() => handleDelete(record.key)}
            okText={t('Yes')}
            cancelText={t('Cancel')}
          >
            <DeleteOutlined
              className=''
              style={{ color: 'grey', fontSize: 20 }}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          </Popconfirm>
        ) : null,
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: MaterialCategoriesType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
      }),
    };
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <div className='categories-container table-project-status-list'>
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowClassName={() => 'editable-row'}
        dataSource={materialCategories}
        columns={columns as ColumnTypes}
        pagination={false}
        showHeader={true}
      />
      <Divider />
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
          <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            rowKey='key'
            rowClassName={() => 'editable-row'}
            dataSource={dataSource}
            columns={columns as ColumnTypes}
            pagination={false}
            showHeader={false}
          />
        </SortableContext>
      </DndContext>
      <Button
        onClick={handleAdd}
        type='dashed'
        className='primary-dashed-btn my-6'
        style={{ marginBottom: 16, marginLeft: 45 }}
      >
        <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        {t('Add option')}
      </Button>
    </div>
  );
}
