import { Button, Form, Modal, Row, Col, message } from 'antd';
import { SearchSelect } from 'components/common';
import { useProjectUsers, useProjectUsersParams } from 'hooks';
import { BaseValidation, User } from 'model';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TransferProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  onTransfer: (newValidatorId: string) => void;
  baseValidation: BaseValidation | null;
  projectId: string;
  workspaceId: string;
  user: User | null;
  validators: string[] | undefined;
}

const TransferModal: React.FC<TransferProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, setOpen, onTransfer, baseValidation, projectId, user, workspaceId } = props;
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [usersParams] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(usersParams);
  const companyName = useMemo(() => {
    return user?.WorkspaceUser?.Company?.name;
  }, [user]);
  const companyUsers = useMemo(() => {
    return users?.filter(
      (user) =>
        !baseValidation?.validatorCompanyId || user.WorkspaceUser?.Company?.id === baseValidation?.validatorCompanyId
    );
  }, [baseValidation, isOpen]);

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleOk = async () => {
    try {
      setIsSubmit(true);
      await onTransfer(form.getFieldValue('transfer-user'));
      setIsSubmit(false);
      setOpen(false);
    } catch (error) {
      setIsSubmit(false);
      setOpen(false);
      console.log(error);
      message.error(t('Oops! Something wrong'));
    }
  };

  return (
    <>
      <Modal
        title={t('Transfer')}
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={
          <>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type='default' onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button type='primary' onClick={handleOk} loading={isSubmit}>
                {t('Transfer')}
              </Button>
            </Col>
          </>
        }
      >
        <Form form={form} name='transfer-request' layout='vertical'>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name='transfer-user'
                label={
                  <span style={{ textTransform: 'none' }}>{t(`Transfer to another user from ${companyName}`)}</span>
                }
              >
                <SearchSelect
                  placeholder={t('Select')}
                  options={companyUsers?.map((option) => {
                    if (option.id === user?.id || props.validators?.includes(option.id)) {
                      return {
                        value: option.id,
                        label: option.fullName,
                        disabled: true,
                      };
                    }
                    return {
                      value: option.id,
                      label: option.fullName,
                    };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TransferModal;
