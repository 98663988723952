import { useState } from 'react';
import { Button, Flex, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { baseIssueService } from 'services';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type useDeleteBaseIssueProps = {
  cantUndo?: boolean;
  title?: string;
  description?: string;
};

const useDeleteBaseIssue = (props?: useDeleteBaseIssueProps) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOk = async (
    workSpaceId: string,
    projectId: string,
    baseIssueId: string | null,
    resolve: (value: boolean) => void,
    reject: (error: unknown) => void
  ) => {
    try {
      if (!baseIssueId) {
        message.success(t('Invalid ID'));
        return;
      }

      setIsDeleting(true);
      await baseIssueService.deleteBaseIssueById(workSpaceId, projectId, baseIssueId);
      message.success(t('Delete success!'));
      resolve(true);
    } catch (error) {
      console.error(error);
      message.error(t('Oop! Something wrong'));
      reject(error);
    } finally {
      setIsDeleting(false);
      Modal.destroyAll();
    }
  };

  const openDeleteConfirm = async (workSpaceId: string, projectId: string, baseIssueId: string) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        className: 'open-delete-confirm',
        icon: null,
        title: null,
        width: 480,
        style: { minWidth: 480 },
        footer: (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <Button
                onClick={() => {
                  Modal.destroyAll();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                loading={isDeleting}
                danger
                onClick={() => {
                  handleOk(workSpaceId, projectId, baseIssueId, resolve, reject);
                }}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        ),
        content: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <p className='mb-4 text-size-20'>
                <ExclamationCircleOutlined style={{ color: 'red' }} /> {props?.title}
              </p>
            </div>
            <p className='mb-4 text-size-14' style={{ justifyContent: 'center' }}>
              {props?.description}
            </p>
            {props?.cantUndo && (
              <Flex justify='space-around' style={{ marginBottom: '2em', color: '#aaa' }}>
                {t('You cannot undo this action')}
              </Flex>
            )}
          </div>
        ),
      });
    });
  };

  return { openDeleteConfirm, isDeleting };
};

export { useDeleteBaseIssue };
