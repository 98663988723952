import { Button, Card, Col, Flex, Form, Popover, Row, Select, Tag, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchSelect } from 'components';
import { Company, User } from 'model';
import { useState } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';

export type ProjectUserFilterFormValue = {
  companyId?: string;
  userRole?: string;
  statusId?: string;
};
type FilterIssueFormProps = {
  companies: Company[];
  users: User[];
  handleFilter: (value: ProjectUserFilterFormValue) => void;
};

export default function ProjectUserFilter(props: FilterIssueFormProps) {
  const { companies, handleFilter } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm<ProjectUserFilterFormValue>();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [, setDisableGroup] = useState<boolean>(false);
  const [lastSubmitFilterData, setLastSubmitFilterData] = useState({});
  // const [query] = useProjectRolesQueryParams();
  // const [roles] = useProjectRoles(query);
  const roles = [
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'member',
      name: 'Member',
    },
  ];
  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    console.log('onApplyFilter', values);

    handleFilter(values);
    setIsOpenFilter(false);
    setIsFiltering(true);
  };
  const onResetFilter = () => {
    form.resetFields();
    setDisableGroup(false);
    setLastSubmitFilterData({});
    handleFilter({});
    setIsOpenFilter(false);
    setIsFiltering(false);
  };
  const handlePopoverOpenChange = (open: boolean) => {
    if (open) {
      form.setFieldsValue(lastSubmitFilterData);
    } else {
      form.resetFields();
    }
    setIsOpenFilter(open);
  };

  const statusColors: Record<string, string> = {
    active: 'green',
    inactive: 'magenta',
    pending: 'orange',
  };
  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}
              className='text-uppercase'
            >
              {t('Filter')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            <Flex justify='end' className='mx-5' key={0}>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={24}>
                <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company')}>
                  <SearchSelect
                    allowClear
                    placeholder={t('Select')}
                    options={companies?.map((option) => ({ value: option.id, label: option.name }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='userRole' label={t('Role')}>
                  <Select
                    showSearch
                    allowClear
                    placeholder={t('Select')}
                    options={roles?.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {' '}
                <Form.Item className='filter-form__item' name='statusId' label={t('Status')}>
                  <Select
                    allowClear
                    placeholder={t('Select')}
                    options={['active', 'pending']?.map((option) => ({
                      value: option,
                      label: <Tag color={statusColors[option]}>{option}</Tag>,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={handlePopoverOpenChange}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : '', borderColor: isFiltering ? token.colorPrimary : '' }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
