import dayjs from 'dayjs';
import { Button, Flex, Layout, Tabs } from 'antd';
import { DownloadOutlined, FilterOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSearchDebounce, parseQueryParams } from 'utils';
import { QueryParams } from 'types';
import { User } from 'model';
import {
  ProjectDocumentsTransmissionCreateOrUpdate,
  ProjectDocumentsTransmissionStatistic,
  ProjectDocumentsTransmissionListViewIncoming,
  ProjectDocumentsTransmissionListViewOutgoing,
} from 'components';
import {
  useProjectCompaniesParams,
  useProjectCompanies,
  useIssueStatusesParams,
  useIssueStatuses,
  useIssuePrioritiesParams,
  useIssuePriorities,
} from 'hooks';
import {
  selectProjectBaseIssuesCount,
  selectProjectBaseIssuesKpiData,
  useProjectBaseIssues,
  useProjectBaseIssuesParams,
} from 'hooks/base-issue';
import FilterDocumentsTransmission from './FilterDocumentsTransmission';
import SearchText from 'components/common/SearchText';
import { useAppSelector } from 'store';

type ProjectDocumentsTransmissionProps = {
  module: string;
  workspaceId: string;
  isOwnerCompany: boolean;
  loggedCompanyRoleId: string;
  userCompanyId: undefined | string;
  profile: User | null;
  users: User[];
  refreshUser: (options?: QueryParams | undefined) => void;
};

export default function ProjectDocumentsTransmission(props: ProjectDocumentsTransmissionProps) {
  const { module, workspaceId, isOwnerCompany, loggedCompanyRoleId, userCompanyId, profile, users, refreshUser } =
    props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeTab = searchParams.get('tab') || 'incoming';

  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [baseIssueId, setIssueId] = useState<string | null>(searchParams.get('documentTransmissionId'));
  const [, setShowSearchInput] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [, setSearchValue] = useSearchDebounce();
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const params = useParams();
  const projectId = params.projectId as string;
  const [, setIsReadOnly] = useState(false);
  const baseIssueParamId = searchParams.get('documentTransmissionId');
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'TRA',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'TRA',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [projectBaseIssueParams, onProjectBaseIssuesParamsChange] = useProjectBaseIssuesParams({
    projectId: projectId as string,
    'companyId[]': `["${loggedCompanyRoleId}"]`,
    workspaceId,
    // module,
    type: 'TRA',
    include:
      'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments|Attachments',
    activeTab,
  });

  const [baseIssues, loadingBaseIssues, refreshBaseIssues] = useProjectBaseIssues(projectBaseIssueParams);
  const baseIssueKpi = useAppSelector(selectProjectBaseIssuesKpiData());
  const baseIssueCount = useAppSelector(selectProjectBaseIssuesCount());

  const kpiData = {
    totalKpi: baseIssueCount,
    draft: baseIssueKpi?.DR as number,
    issued: baseIssueKpi?.ISD as number,
  };
  useEffect(() => {
    if (!isFormOpen) {
      setIssueId(null);
    }
    refreshBaseIssues();
  }, [isFormOpen]);

  useEffect(() => {
    if (baseIssueParamId) {
      setIssueId(baseIssueParamId);
      setIsFormOpen(true);
    }
    const params = parseQueryParams(window.location.search);
    if (params.searchKey) {
      setSearchValue('');
      setShowSearchInput(false);
    }
  }, []);

  const handleOpenForm = (issueId: string | null) => {
    setIsFormOpen(true);
    setIssueId(issueId);
  };
  const handleOpenFormFromListView = (issueId: string | null) => {
    setIsFormOpen(true);
    setIssueId(issueId);
    setIsReadOnly(true);
  };

  const handleFilter = async (values: QueryParams) => {
    let CompanyOriginatorId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === 'myCompany' && userCompanyId) {
      CompanyOriginatorId = `${userCompanyId}`;
    } else if (values.groupIssues === 'watched' && profile?.id) {
      watcherId = `${profile?.id}`;
    } else {
      if (values.companyId) {
        CompanyOriginatorId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }
    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const dtPriority =
      values.dtPriority && values.dtPriority.length > 0 ? `[${'"' + values.dtPriority.join('","') + '"'}]` : undefined;
    const params = {
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      'statusId[]': issueStatusId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : CompanyOriginatorId,
      originatorId: assigneeId,
      'dtPriority[]': dtPriority,
      // 'companyId[]': [companyId],
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };
    // console.log(params);
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
  };

  const handleFilterByKPI = async (code: string) => {
    const issueStatusId = issueStatuses.find((value) => value.code === code)?.id;
    const params = {
      'createdAt[lte]': undefined,
      'createdAt[gte]': undefined,
      'statusId[]': issueStatusId ? `[${'"' + issueStatusId + '"'}]` : undefined,
      companyOriginatorId: undefined,
      originatorId: undefined,
      'dtPriority[]': undefined,
      filterType: undefined,
      watcherId: undefined,
      page: 1,
    };
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilter(true);
  };

  const handleSearch = (searchValue: string) => {
    const param = {
      ...projectBaseIssueParams,
      searchBy: '["BaseIssue.name","Company.name","DocumentTransmission.dtNumber"]',
      searchKey: searchValue,
      page: 1,
    };
    onProjectBaseIssuesParamsChange(param);
  };

  return (
    <Layout.Content className='settings-content'>
      <ProjectDocumentsTransmissionCreateOrUpdate
        isFormOpen={!!isFormOpen}
        setIsFormOpen={setIsFormOpen}
        users={users}
        projectId={projectId}
        workspaceId={workspaceId}
        baseIssueId={baseIssueId}
      />
      <Flex className='mb-4' align='center' justify='space-between'>
        <div>
          <h1 className='leading-[46px]'>{t('Documents Transmission')}</h1>
          <div className='sub-title'>{t('View and manage documents transmission')}</div>
        </div>
        <div>
          <SearchText onSearch={handleSearch} />
          {isOpenFilter === null ? (
            <Button
              onClick={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
              icon={<FilterOutlined />}
            >
              {t('Filter')}
            </Button>
          ) : (
            <FilterDocumentsTransmission
              companies={companies}
              users={users}
              issueStatuses={issueStatuses}
              workspaceId={workspaceId}
              projectId={projectId}
              isReset={isResetFilter || false}
              userCompanyId={userCompanyId}
              setIsOpenFilter={setIsOpenFilter}
              issuePriorities={issuePriorities}
              isOpenFilter={isOpenFilter}
              handleFilter={(value: { [key: string]: unknown }) => {
                handleFilter(value);
              }}
              setReset={setIsResetFilter}
              module={module}
              activeTab={activeTab}
              isOwnerCompany={isOwnerCompany}
            />
          )}
          <Button type='primary' icon={<PlusOutlined />} onClick={() => handleOpenForm(null)}>
            {t('Doc Transmission')}
          </Button>
        </div>
      </Flex>
      <Tabs
        activeKey={activeTab}
        onChange={(key: string) => {
          navigate(`?tab=${key}`);
        }}
        items={[
          {
            icon: <DownloadOutlined />,
            label: t('Incoming'),
            key: 'incoming',
          },
          {
            icon: <UploadOutlined />,
            label: t('Outgoing'),
            key: 'outgoing',
          },
        ]}
      />
      <ProjectDocumentsTransmissionStatistic
        kpiData={kpiData}
        activeKey={activeTab}
        handleFilterByKPI={handleFilterByKPI}
        isResetKPI={isResetFilterByKPI}
        setResetKPI={setIsResetFilterByKPI}
      />
      {activeTab === 'incoming' && (
        <ProjectDocumentsTransmissionListViewIncoming
          users={users}
          module={module}
          profile={profile}
          projectId={projectId}
          workspaceId={workspaceId}
          dataList={baseIssues}
          dataListCount={baseIssueCount}
          companies={companies}
          dataListParams={projectBaseIssueParams}
          loading={loadingBaseIssues === 'pending'}
          refreshUser={refreshUser}
          onFilter={(value) => {
            onProjectBaseIssuesParamsChange({ ...projectBaseIssueParams, ...value });
          }}
          onEditIssue={handleOpenFormFromListView}
          refreshBaseIssues={refreshBaseIssues}
        />
      )}
      {activeTab === 'outgoing' && (
        <ProjectDocumentsTransmissionListViewOutgoing
          users={users}
          module={module}
          profile={profile}
          projectId={projectId}
          workspaceId={workspaceId}
          dataList={baseIssues}
          dataListCount={baseIssueCount}
          companies={companies}
          dataListParams={projectBaseIssueParams}
          loading={loadingBaseIssues === 'pending'}
          refreshUser={refreshUser}
          onFilter={(value) => {
            onProjectBaseIssuesParamsChange({ ...projectBaseIssueParams, ...value });
          }}
          onEditIssue={handleOpenFormFromListView}
          refreshBaseIssues={refreshBaseIssues}
        />
      )}
    </Layout.Content>
  );
}
