export enum NotificationPlatform {
  Web = 'web',
  Android = 'android',
  Ios = 'ios',
}
export default interface NotificationToken {
  id?: string;
  userId?: string;
  deviceId: string;
  deviceName?: string;
  deviceToken: string;
  platform: NotificationPlatform;
  oldToken?: string;
}
