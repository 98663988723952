import { useMemo, useEffect, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Attachment, IssueAttachment } from 'model';
import { Modal, LocationPlan } from 'components';

import markerIcon from 'assets/images/leaflet/marker-tool-pin-fill.svg';
import ImgItem from './pre-signed-url-items/ImgItem';
import {
  CircleCoordinate,
  Geometry,
  LayerData,
  LineStringCoordinate,
  PointCoordinate,
  RectangleCoordinate,
} from 'types';

type MiniPlanProps = {
  filePath: string;
  width?: number;
  imgWidth: number;
  imgHeight: number;
  isDisabled?: boolean;
  layers?: LayerData[];
  onClick: () => void;
  onDelete?: () => void;
  module?: string;
  plan: IssueAttachment | null;
  planImage: Attachment | null;
  inspectionName?: string;
};

export default function MiniPlan(props: MiniPlanProps) {
  const {
    filePath,
    width = 246,
    imgWidth,
    layers,
    onClick,
    onDelete,
    isDisabled,
    module,
    plan,
    planImage,
    inspectionName,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const markerWidth = 18;
  const rate = width / imgWidth;

  const renderMarker = (geometry: Geometry, index: number) => {
    const { coordinates } = geometry;
    const [lat, lng] = coordinates as PointCoordinate;
    const top = lat * rate;
    const left = lng * rate;
    return (
      <img
        className='marker'
        key={`${index}-${lat}-${lng}`}
        src={markerIcon}
        width={markerWidth}
        alt=''
        style={{ left: left - markerWidth / 2, bottom: top }}
      />
    );
  };

  const renderRectangle = (layer: LayerData, index: number) => {
    const { geometry, properties } = layer;
    const coordinates = geometry?.coordinates as RectangleCoordinate;
    const [topLeft, topRight, bottomLeft] = coordinates[0] as number[][];

    // Calculate width and height of the rectangle
    const rectHeight = Math.abs((topLeft[0] - bottomLeft[0]) * rate);
    const rectWidth = Math.abs((topLeft[1] - topRight[1]) * rate);
    return (
      <div
        key={`${index}-${topLeft[0]}-${topLeft[1]}`}
        className='rectangle'
        style={{
          width: rectWidth,
          height: rectHeight,
          left: topLeft[1] * rate,
          bottom: bottomLeft[0] * rate,
          border: `${(properties?.weight || 1) * rate}px solid ${properties?.color ?? 'red'}`,
        }}
      />
    );
  };

  const renderCirCle = (layer: LayerData, index: number) => {
    const { geometry, properties } = layer;
    const coordinates = geometry?.coordinates as CircleCoordinate;
    const [lat, lng] = coordinates;
    const radius = geometry?.radius as number;
    return (
      <div
        className='circle'
        key={`${index}-${lat}-${lng}`}
        style={{
          left: (lng - radius) * rate,
          bottom: (lat - radius) * rate,
          width: 2 * radius * rate,
          height: 2 * radius * rate,
          border: `${(properties?.weight || 1) * rate}px solid ${properties?.color ?? 'red'}`,
        }}
      />
    );
  };

  const renderLineString = (layer: LayerData, index: number) => {
    const { geometry, properties } = layer;
    const coordinates = geometry?.coordinates as LineStringCoordinate;
    const lines = [];
    for (let i = 0; i < coordinates.length - 1; i++) {
      const startPixel = {
        x: coordinates[i][1] * rate,
        y: coordinates[i][0] * rate,
      };
      const endPixel = {
        x: coordinates[i + 1][1] * rate,
        y: coordinates[i + 1][0] * rate,
      };

      const deltaX = endPixel.x - startPixel.x;
      const deltaY = endPixel.y - startPixel.y;
      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      lines.push(
        <div
          className='line-string'
          key={`${index}-${startPixel.x}-${startPixel.y}-${endPixel.x}-${endPixel.y}`}
          style={{
            width: length,
            transform: `rotate(${-angle}deg)`,
            left: startPixel.x,
            bottom: startPixel.y,
            transformOrigin: `top left`,
            border: `${(properties?.weight || 1) * rate}px solid ${properties?.color ?? 'red'}`,
          }}
        />
      );
    }
    return lines;
  };

  const renderLayers = useMemo(
    () =>
      layers?.map((layer, index) => {
        const { geometry } = layer;
        if (geometry) {
          const { type, radius } = geometry;
          if (type === 'Point') {
            return renderMarker(geometry, index);
          }
          if (type === 'LineString') {
            return renderLineString(layer, index);
          }
          if (type === 'Polygon') {
            if (radius) return renderCirCle(layer, index);
            return renderRectangle(layer, index);
          }
        }
        return null;
      }),
    [layers, planImage]
  );

  const [layerData, setLayerData] = useState<LayerData | null>(null);
  const [selectedPlanImage, setSelectedPlanImage] = useState<Attachment | null>(planImage);
  useEffect(() => {
    setSelectedPlanImage(planImage);
  }, [planImage]);

  const initLayerData = () => {
    if (plan?.layerData) {
      setLayerData(plan.layerData);
    } else {
      setLayerData(null);
    }
  };

  useEffect(() => {
    initLayerData();
  }, [plan]);

  useEffect(() => {
    if (isModalOpen) {
      setSelectedPlanImage(planImage);
      initLayerData();
      setTimeout(() => {
        setCurrentStep(1);
      });
    } else {
      setCurrentStep(0);
    }
  }, [isModalOpen]);

  const locationWidth = 800;
  const aspectRatio =
    selectedPlanImage?.height && selectedPlanImage.width ? selectedPlanImage.height / selectedPlanImage.width : 3 / 4;
  const locationHeight = locationWidth * aspectRatio;

  if (!filePath) return null;

  return (
    <div className='mini-plan' style={{ width: width }}>
      {isDisabled || (onDelete && <CloseCircleFilled onClick={onDelete} className='delete-icon' />)}
      <div onClick={!isDisabled ? onClick : () => setIsModalOpen(true)}>
        <ImgItem
          onReady={() => setTimeout(() => setIsLoadingImage(false), 350)}
          src={filePath}
          alt=''
          style={{ width: width }}
        />
        {!isLoadingImage && renderLayers}
      </div>

      {isModalOpen !== null && (
        <Modal
          title={inspectionName}
          open={isModalOpen}
          width={locationWidth + 96}
          classNames={{
            body: 'pin-inspection-plan-modal',
            content: module && module === 'Safety' ? 'safety-light' : module === 'Quality' ? 'quality-light' : '',
          }}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
        >
          {currentStep && (
            <LocationPlan
              width={locationWidth}
              height={locationHeight}
              disabled
              imageUrl={filePath}
              imageHeight={selectedPlanImage?.height as number}
              imageWidth={selectedPlanImage?.width as number}
              data={layerData}
              onChange={setLayerData}
              module={module ?? ''}
            />
          )}
        </Modal>
      )}
    </div>
  );
}
