import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Flex, Form, Modal, Typography } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { CustomizeRequiredMark, SearchSelect } from 'components/common';
import { MOMFormValues } from 'components/project-minutes-of-meeting/ProjectMinutesOfMeetingCreateOrUpdate';
import { BaseIssue, Company, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { INVITATION_ROLE, VALIDATION_STATUS } from 'utils/contants';

type ProjectMinutesOfMeetingDetailValidationPopupProps = {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onCancel: () => void;
  startValidation: (selection: string, validatorId: string | null, validatorCompanyId: string | null) => void;
  currentCompany: Company | null;
  companies: Company[];
  profile: User | null;
  selectedBaseIssue: BaseIssue | null;
  Form: FormInstance;
  isEdit?: boolean;
};
const ProjectMinutesOfMeetingDetailValidationPopup = (props: ProjectMinutesOfMeetingDetailValidationPopupProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const formValuesWatch = Form.useWatch([], form);
  const formValuesDetailWatch = Form.useWatch([], props.Form) as MOMFormValues;
  const [selectedStatus, setStatus] = useState('');
  const users = useAppSelector(selectProjectUsers);
  const [value, setValue] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [listUsersByCompany, setListUsersByCompany] = useState<User[]>([]);
  const [attendeesCompanies, setAttendeesCompanies] = useState<Company[]>(props.companies || []);
  const [listCompanies, setListCompanies] = useState<Company[]>(props.companies || []);

  useEffect(() => {
    if (!props.isOpen) {
      onResetForm();
    }
    if (props?.isEdit) {
      if (props.isOpen && formValuesDetailWatch?.attendees?.length) {
        const companiesReceiver = Array.from(
          formValuesDetailWatch?.attendees?.reduce((acc: Set<Company>, watcher) => {
            const company = props.companies.find((c) => c.id === watcher.companyId);
            if (company) {
              acc.add(company);
            }
            return acc;
          }, new Set<Company>())
        ).map((company) => company);

        setAttendeesCompanies(companiesReceiver);
      }
    } else {
      if (props.isOpen && props.selectedBaseIssue?.BaseIssueWatchers?.length) {
        const companiesReceiver = Array.from(
          props.selectedBaseIssue?.BaseIssueWatchers?.filter(
            (watcher) => watcher.role === INVITATION_ROLE.RECEIVER
          ).reduce((acc: Set<Company>, watcher) => {
            const company = props.companies.find((c) => c.id === watcher.companyId);
            if (company) {
              acc.add(company);
            }
            return acc;
          }, new Set<Company>())
        ).map((company) => company);

        setAttendeesCompanies(companiesReceiver);
      }
    }
  }, [props.isOpen, props.selectedBaseIssue]);

  const onResetForm = () => {
    form.resetFields();
    setValue('');
    setStatus('');
    setSelectedCompanyId('');
    setSelectedUserId('');
  };

  const handleSubmitModal = async () => {
    try {
      const valid = await form.validateFields();
      if (valid) {
        props.startValidation(value, selectedUserId, selectedCompanyId);
        props.setIsModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeSelectedCompanyId = (id: string) => {
    if (!id) {
      form.setFieldValue('selectedCompanyId', null);
      setSelectedCompanyId('');
      return;
    }
    setSelectedCompanyId(id);

    if (props.isEdit) {
      const attendees = formValuesDetailWatch?.attendees;
      const inspectors = attendees?.find((attendee) => attendee.companyId === id);
      const inspectorIds = inspectors?.inspectorIds;
      const usersByCompany = users?.filter((user) => inspectorIds?.includes(user.id));
      setListUsersByCompany(usersByCompany);
    } else {
      if (selectedStatus === VALIDATION_STATUS.SEND_FOR_VALIDATION) {
        const watchersByCompany = props.selectedBaseIssue?.BaseIssueWatchers?.filter(
          (watcher) => watcher.companyId === id && watcher.role === INVITATION_ROLE.RECEIVER
        );
        if (watchersByCompany?.length) {
          const usersByCompany = watchersByCompany
            .map((watcher) => {
              const user = users.find((user) => user.id === watcher.watcherId);
              return user;
            })
            .filter((user): user is User => user !== undefined);

          setListUsersByCompany(usersByCompany);
          setSelectedUserId(watchersByCompany?.[0].watcherId ?? '');
          form.setFieldValue(['selectedUserId'], watchersByCompany?.[0].watcherId);
        }
      } else {
        const usersByCompany = users?.filter((user) => user.WorkspaceUser?.Company?.id === id);
        setListUsersByCompany(usersByCompany);
        setSelectedUserId(usersByCompany[0]?.id ?? '');
        form.setFieldValue(['selectedUserId'], usersByCompany[0]?.id);
      }
    }
  };

  const onChangeSelectedUserId = (id: string) => {
    if (!id) {
      form.setFieldValue('selectedUserId', null);
      setSelectedUserId('');
      return;
    }
    setSelectedUserId(id);
    const selectedUser = users?.find((user) => user.id === id);
    const existingCompany = props.companies?.findIndex(
      (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
    );
    if (props.companies[existingCompany]?.id) {
      setSelectedCompanyId(props.companies[existingCompany]?.id);
    }
    form.setFieldValue(['selectedCompanyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
    form.validateFields(['selectedUserId']);
    form.validateFields(['selectedCompanyId']);
  };

  const isDisabledCompany = (company: Company) => {
    const isUserEmpty = company.WorkspaceUsers?.length === 0;
    const hasCurrentCompany = company.id === props.currentCompany?.id;
    return isUserEmpty || (selectedStatus.includes(VALIDATION_STATUS.SEND_FOR_VALIDATION) && hasCurrentCompany);
  };

  const renderActionButton = (status: string, name: string) => {
    return (
      <Button
        className={`action-button ${selectedStatus === status ? 'selected' : ''} ${status}`}
        onClick={() => setStatus(status)}
      >
        {selectedStatus === status && <CheckCircleFilled height={14} width={14} />}
        {t(name)}
      </Button>
    );
  };

  useEffect(() => {
    switch (selectedStatus) {
      case VALIDATION_STATUS.INTERNAL_REVIEW: {
        setValue(VALIDATION_STATUS.INTERNAL_REVIEW);

        //Reset data listCompanies & selectedCompanyId
        setListCompanies(props.companies);
        setSelectedCompanyId(props.currentCompany?.id || '');
        form.setFieldValue(['selectedCompanyId'], props.currentCompany?.id);

        //Reset data listUsersByCompany & selectedUserId
        const usersByCompany = users?.filter(
          (user) => user.WorkspaceUser?.Company?.id === props.currentCompany?.id && user.id !== props.profile?.id
        );
        setListUsersByCompany(usersByCompany);
        setSelectedUserId('');
        form.setFieldValue(['selectedUserId'], null);

        break;
      }
      case VALIDATION_STATUS.SEND_FOR_VALIDATION: {
        setValue(VALIDATION_STATUS.SEND_TO_RECIPIENT);

        //Reset data listCompanies & selectedCompanyId
        setListCompanies(attendeesCompanies);
        setSelectedCompanyId('');
        form.setFieldValue(['selectedCompanyId'], null);

        //Reset data listUsersByCompany & selectedUserId
        if (props.isEdit) {
          const usersByCompany = users?.filter((user) => {
            const watcher = formValuesDetailWatch?.attendees?.find((watcher) =>
              watcher.inspectorIds?.includes(user.id)
            );
            return (
              watcher?.inspectorIds?.includes(user.id) &&
              attendeesCompanies.some((company) => company.id === user.WorkspaceUser?.Company?.id) &&
              user.WorkspaceUser?.Company?.id !== props.currentCompany?.id
            );
          });
          setListUsersByCompany(usersByCompany);
        } else {
          const usersByCompany = users?.filter((user) => {
            const watcher = props.selectedBaseIssue?.BaseIssueWatchers?.find(
              (watcher) => watcher.watcherId === user.id
            );
            const isInAttendeesCompanies = attendeesCompanies.some(
              (company) => company.id === user.WorkspaceUser?.Company?.id
            );
            const isNotCurrentCompany = user.WorkspaceUser?.Company?.id !== props.currentCompany?.id;
            const isReceiver = watcher?.role === INVITATION_ROLE.RECEIVER;
            return isInAttendeesCompanies && isNotCurrentCompany && isReceiver;
          });
          setListUsersByCompany(usersByCompany);
        }
        setSelectedUserId('');
        form.setFieldValue(['selectedUserId'], null);

        break;
      }
      case VALIDATION_STATUS.SEND_TO_RECIPIENTS:
        setValue(VALIDATION_STATUS.SEND_TO_RECIPIENTS);
        break;
      default: {
        break;
      }
    }
  }, [selectedStatus]);

  const isDisabled = useMemo(() => {
    let disabled = true;
    if (
      selectedStatus === VALIDATION_STATUS.INTERNAL_REVIEW ||
      selectedStatus === VALIDATION_STATUS.SEND_FOR_VALIDATION
    ) {
      if (formValuesWatch.selectedUserId != null && formValuesWatch.selectedCompanyId != null) {
        disabled = false;
      }
    }
    if (selectedStatus === VALIDATION_STATUS.SEND_TO_RECIPIENTS) {
      disabled = false;
    }
    return disabled;
  }, [selectedStatus, formValuesWatch]);

  // useEffect(() => {
  //   if (selectedStatus === VALIDATION_STATUS.SEND_FOR_VALIDATION) {
  //     const filterUsers = users?.filter((user) => user.WorkspaceUser?.Company?.id !== selectedCompanyId);
  //     setListUsersByCompany(filterUsers);
  //   } else {
  //     const filterUsers = users?.filter(
  //       (user) =>
  //         !selectedCompanyId || (user.WorkspaceUser?.Company?.id === selectedCompanyId && user.id !== props.profile?.id)
  //     );
  //     setListUsersByCompany(filterUsers);
  //   }
  // }, [selectedCompanyId, users, selectedStatus]);

  return (
    <Modal
      footer={[
        <Button key='back' onClick={props.onCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' onClick={handleSubmitModal} disabled={isDisabled} type='primary' htmlType='submit'>
          {t('Submit')}
        </Button>,
      ]}
      rootClassName='project-minutes-of-meeting-validation-popup'
      title={t('Review and Issue Form')}
      open={props?.isOpen}
      onCancel={props.onCancel}
    >
      <Form form={form} layout='vertical' requiredMark={CustomizeRequiredMark}>
        <Typography.Text className='text-size-14 block mb-3'>
          Forward to a colleague <b>within your company for review</b> or <b>issue</b> the meeting minutes
        </Typography.Text>
        <Flex justify='space-around' className='mb-5'>
          {renderActionButton(VALIDATION_STATUS.INTERNAL_REVIEW, 'Internal Review')}
          {renderActionButton(VALIDATION_STATUS.SEND_TO_RECIPIENTS, 'Issue to all Attendees')}
        </Flex>
        <Typography.Text className='text-size-14 block mb-3'>
          Send to <b>other company</b> for validation
        </Typography.Text>
        <Flex justify='center' className='mb-6'>
          {renderActionButton(VALIDATION_STATUS.SEND_FOR_VALIDATION, 'Send for Validation')}
        </Flex>

        {selectedStatus === VALIDATION_STATUS.SEND_TO_RECIPIENTS ? (
          <Typography.Text className='block text-size-16 italic'>
            You are about to <b>ISSUE</b> the report MOM. It will be sent only to all Attendees and Other Recipients
          </Typography.Text>
        ) : (
          <Flex vertical gap={10}>
            <Typography.Title className='text-size-16'>{t('Select the next Reviewer/Validator')}</Typography.Title>
            <Form.Item name='selectedCompanyId' label={t('Company')} rules={[{ required: true }]}>
              <SearchSelect
                options={listCompanies.map((company) => ({
                  value: company.id,
                  label: company.name,
                  disabled: isDisabledCompany(company),
                }))}
                disabled={selectedStatus === VALIDATION_STATUS.INTERNAL_REVIEW || selectedStatus.length === 0}
                placeholder={t('Select Company')}
                onChange={(value) => onChangeSelectedCompanyId(value)}
              />
            </Form.Item>
            <Form.Item name='selectedUserId' label={t('Name')} rules={[{ required: true }]}>
              <SearchSelect
                options={(listUsersByCompany ?? []).map((user) => ({
                  value: user.id,
                  label: user.fullName,
                }))}
                disabled={selectedStatus.length === 0}
                onChange={(value) => onChangeSelectedUserId(value)}
                placeholder={t('Select Name')}
              />
            </Form.Item>
          </Flex>
        )}
      </Form>
    </Modal>
  );
};

export default ProjectMinutesOfMeetingDetailValidationPopup;
