import { BaseIssueWatcher, User } from 'model';
import { getBaseIssueWatcher } from './my-project';
import { ERFIActionType } from 'components/toolbox-request-for-information/CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import store from 'store';
import { RFI_STATUSES } from './contants';

const getRFIUserRole = (
  originatorId: string | undefined,
  loggedUser: User | null,
  recipientId: string | undefined,
  distributionIds: string[] | undefined
) => {
  const state = store.getState();
  if (!state.myProjects?.selectedProjectId) return '';
  const users = state.myProjects.projects[state.myProjects.selectedProjectId].users;
  const originator = users?.find((user) => user.id === originatorId);

  if (originator?.id === loggedUser?.id) return 'originator';

  const hasInternalCompany = originator?.WorkspaceUser?.Company?.id === loggedUser?.WorkspaceUser?.Company?.id;

  const recipient = users.find((user) => user.id === recipientId);
  const isRecipient = recipient?.id === loggedUser?.id;
  const isUserInRecipientCompany = recipient?.WorkspaceUser?.Company?.id === loggedUser?.WorkspaceUser?.Company?.id;
  const isDistribution = distributionIds?.includes(loggedUser?.id || '');

  if (hasInternalCompany) {
    if (isDistribution) return 'internalDistribution';
    else if (isRecipient) return 'internalRecipient';
    return 'internalUser';
  } else {
    if (isDistribution) return 'externalDistribution';
    else if (isRecipient) return 'externalRecipient';
    else if (isUserInRecipientCompany) return 'externalUserInRecipientCompany';
    return 'externalUser';
  }
};

const getPermissionByStatus = (status: string, userRole: string, action: ERFIActionType) => {
  let result: Record<string, ERFIActionType[]> = {};
  switch (status) {
    case RFI_STATUSES.DRAFT:
      result = {
        originator: [
          ERFIActionType.EditForm,
          ERFIActionType.SendToRecipient,
          ERFIActionType.DeleteForm,
          ERFIActionType.Submit,
        ],
        internalDistribution: [ERFIActionType.EditForm, ERFIActionType.SendToRecipient],
        internalRecipient: [ERFIActionType.EditForm, ERFIActionType.SendToRecipient],
        internalUser: [ERFIActionType.EditForm, ERFIActionType.SendToRecipient],
        externalDistribution: [],
        externalRecipient: [],
        externalUserInRecipientCompany: [],
      };
      break;
    case RFI_STATUSES.OPEN:
      result = {
        originator: [ERFIActionType.EditForm, ERFIActionType.Submit, ERFIActionType.DeleteForm, ERFIActionType.Close],
        internalUser: [],
        internalRecipient: [ERFIActionType.Submit, ERFIActionType.AddUser],
        internalDistribution: [ERFIActionType.AddUser, ERFIActionType.Submit],
        externalDistribution: [ERFIActionType.Submit, ERFIActionType.AddUser],
        externalRecipient: [ERFIActionType.Submit, ERFIActionType.AddUser],
        externalUserInRecipientCompany: [],
      };
      break;
    case RFI_STATUSES.CLOSED:
      result = {
        originator: [ERFIActionType.DeleteForm],
        internalUser: [],
        internalRecipient: [],
        internalDistribution: [],
        externalDistribution: [],
        externalRecipient: [],
        externalUserInRecipientCompany: [],
      };
      break;
    default:
      break;
  }

  return result[userRole]?.includes(action);
};

export const createRFIPermissionChecker = (
  originatorId: string | undefined,
  loggedUser: User | null,
  BaseIssueWatchers: BaseIssueWatcher[] | undefined,
  status: string
) => {
  const watcher = getBaseIssueWatcher(BaseIssueWatchers);

  const userRole = getRFIUserRole(
    originatorId,
    loggedUser,
    watcher?.issuedTo?.id,
    watcher?.distribution?.map((distribution) => distribution.id as string) || []
  );

  return (action: ERFIActionType) => {
    return getPermissionByStatus(status, userRole, action);
  };
};
