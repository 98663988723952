import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { ModalState } from 'types';
import { UploadDocument } from 'components/common';
import { Attachment, AttachmentInfo, BaseIssue } from 'model';
import { attachmentService } from 'services';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { selectProjectUsers } from 'store/my-projects.slice';
import useAuthContext from 'store/auth-context';
import AttachmentDocumentList, { AttachmentDocumentData } from 'components/common/AttachmentDocumentList';
import { useAppSelector } from 'store';
type MDIsListDocumentSectionProps = {
  isEdit: boolean;
  selectedMdiBaseIssue: BaseIssue | null;
  workspaceId: string;
  projectId: string;
  attachments: Attachment[];
  documentsInfo: AttachmentInfo[];
  fetchAttachments: () => void;
  setDocumentsInfo: (value: AttachmentInfo[]) => void;
};
export default function MDIsListDocumentSection(props: MDIsListDocumentSectionProps) {
  const { t } = useTranslation();
  const {
    isEdit,
    selectedMdiBaseIssue,
    workspaceId,
    projectId,
    documentsInfo,
    attachments,
    fetchAttachments,
    setDocumentsInfo,
  } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const users = useAppSelector(selectProjectUsers);
  const { profile } = useAuthContext();
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const mdiDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MDI_DOCUMENT);

  const attachmentResult: AttachmentDocumentData[] = useMemo(() => {
    setLoading(true);
    const newAttachments = attachments?.map((attachment) => ({
      ...attachment,
      userCreated: attachment?.BaseIssueAttachments?.[0]?.userCreated || '',
    }));
    const currentAttachments = selectedMdiBaseIssue ? newAttachments : documentsInfo;
    const userIds: (string | undefined)[] = [
      ...new Set(currentAttachments?.map((attachment) => attachment?.userCreated)),
    ];
    const userList = users?.filter((user) => userIds.includes(user.id));
    setLoading(false);
    return userList.map((user) => {
      const userAttachments = (currentAttachments as AttachmentInfo[])?.filter(
        (attachment) => attachment?.userCreated === user?.id
      );
      const isUploadedUser = user?.id === profile?.id;
      const currentUser = users?.find((user) => user.id === profile?.id);
      const isCompanyOriginator = currentUser?.WorkspaceUser?.Company?.id === selectedMdiBaseIssue?.companyOriginatorId;
      const createdAt = userAttachments[userAttachments.length - 1]?.createdAt || new Date().toISOString();
      return {
        attachmentDocuments: userAttachments
          ?.filter((i) => i.attachmentTypeId === mdiDocumentType?.id)
          .map((attachment) => {
            return {
              filePath: attachment.filePath,
              fileName: attachment?.name,
              hideDelete: !isCompanyOriginator && !isUploadedUser,
            };
          }),
        userInfo: {
          fullName: user?.fullName,
          firstName: user?.firstName,
          profilePhoto: user?.profilePhoto,
          createdAt: createdAt,
        },
      };
    });
  }, [documentsInfo?.length, attachments, selectedMdiBaseIssue, users]);

  useEffect(() => {
    if (selectedMdiBaseIssue) {
      fetchAttachments();
    }
  }, [selectedMdiBaseIssue]);
  const isDisable = useMemo(() => {
    if (attachmentResult?.length === 0) {
      return false;
    }
    return attachmentResult[0].attachmentDocuments.length >= 3;
  }, [attachmentResult]);
  const handleAddDocument = async (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => {
    try {
      if (selectedMdiBaseIssue) {
        setLoading(true);
        await attachmentService.createAttachment(workspaceId, projectId, {
          filePath: response.fileUrl,
          name: name,
          attachmentTypeId: mdiDocumentType?.id as string,
          baseIssueId: selectedMdiBaseIssue.id,
        });
        fetchAttachments();
        setLoading(false);
      } else {
        const data = documentsInfo;
        data.push({
          id: response.fileUrl,
          name,
          filePath: response.fileUrl,
          attachmentTypeId: mdiDocumentType?.id,
          userCreated: profile?.id || '',
        });
        setDocumentsInfo(data);
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };

  const handleRemoveDocument = async (filePath: string) => {
    try {
      setLoading(true);
      if (selectedMdiBaseIssue) {
        const id = attachments.find((attachment) => attachment.filePath === filePath)?.id || '';
        await attachmentService.deleteAttachment(workspaceId, projectId, id);
        // props?.refreshBaseIssues?.(selectedMssBaseIssue.id || '', false);
        fetchAttachments();
      } else {
        const data = [...documentsInfo];
        const index = data.findIndex((attachment) => attachment.filePath === filePath);
        data.splice(index, 1);
        setDocumentsInfo(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };
  return (
    <div className='mdi-list-document-popup-container'>
      <Row gutter={24}>
        {isOpenUpload !== null && (
          <UploadDocument
            isModalOpen={isOpenUpload}
            setIsModalOpen={(value: boolean) => setIsOpenUpload(value)}
            handleAddDocument={(name: string, response) => handleAddDocument(name, response)}
          />
        )}
        <div className='mar-document-container'>
          <Typography.Title className='material-group-title' level={3}>
            {t('Material Delivery Inspection Documentation')}
          </Typography.Title>
          {isEdit && (
            <Button
              type='dashed'
              icon={<PlusOutlined />}
              onClick={() => setIsOpenUpload(true)}
              disabled={isDisable}
              className='text-size-12'
            >
              {t('Add Document')}
            </Button>
          )}
        </div>
        {attachmentResult?.length > 0 ? (
          <AttachmentDocumentList
            isLoading={loading}
            data={attachmentResult}
            hideDelete={!props.isEdit}
            onRemoveFile={handleRemoveDocument}
          />
        ) : (
          <Empty style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        )}
      </Row>
    </div>
  );
}
