import { Button, Col, Modal, ModalProps, Row, Form, Input, Typography, message } from 'antd';
import { CustomizeRequiredMark, SearchSelect } from 'components';
import { Company, User } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { workPackageService } from 'services';
import { isValidEmail } from 'utils';
type CreateOrUpdatePWPProps = {
  selectedPWP: string | null;
  isOpenForm: boolean;
  profile: User | null;
  workspaceId: string;
  projectId: string;
  companies: Company[];
  workspaceCompanies: Company[];
  users: User[];
  workspaceUsers: User[];
  setIsOpenForm: (value: boolean) => void;
  onFinish: () => void;
  refreshProjectWorkPackages: () => void;
} & ModalProps;
export default function CreateOrUpdatePWP(props: CreateOrUpdatePWPProps) {
  const {
    selectedPWP,
    isOpenForm,
    workspaceId,
    projectId,
    companies,
    users,
    workspaceCompanies,
    workspaceUsers,
    setIsOpenForm,
    onFinish,
    refreshProjectWorkPackages,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [selectedContractCompany, setSelectedContractCompany] = useState<string>('');
  const [filterCompanyReps, setFilterCompanyReps] = useState<User[]>([]);
  const [filterContractCompanyReps, setFilterContractCompanyReps] = useState<User[]>([]);

  const formValuesWatch = Form.useWatch([], form);

  const getWorkPackage = async (WorkPackageId: string) => {
    try {
      setIsLoading(true);
      const workPackage = await workPackageService.getProjectWorkPackageById(workspaceId, projectId, WorkPackageId, {
        include: 'Company',
      });
      // Set list users for company
      if (workPackage.companyId) {
        const filterUsers = users?.filter(
          (user) => !workPackage.companyId || user.WorkspaceUser?.Company?.id === workPackage.companyId
        );
        setFilterCompanyReps(filterUsers);
      }
      // Set list users for company entity
      if (workPackage.contractEntity) {
        const filterUsers = workspaceUsers?.filter(
          (user) => !workPackage.contractEntity || user?.Company?.id === workPackage.contractEntity
        );
        setFilterContractCompanyReps(filterUsers);
      }
      form.setFieldsValue(workPackage);
      if (workPackage?.Company?.id) form.setFieldValue('companyCode', workPackage.Company.companyCode);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.error(t((error as Error).message));
    }
  };

  function handleCancel(): void {
    setIsLoading(false);
    setIsOpenForm(false);
    setIsDisable(false);
    setSelectedCompany('');
    setSelectedContractCompany('');
    onFinish();
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const values = form.getFieldValue([]);
      if (values && selectedPWP) {
        await workPackageService.updateProjectWorkPackage(workspaceId, projectId, selectedPWP, values);
      } else {
        await workPackageService.createProjectWorkPackage(workspaceId, projectId, values);
      }
      setIsOpenForm(false);
      setIsLoading(false);
      setSelectedCompany('');
      setSelectedContractCompany('');
      refreshProjectWorkPackages();
      onFinish();
    } catch (error) {
      console.log(error);
      message.error(t(`${(error as Error).message}`));
      setIsLoading(false);
    }
  };

  const onChangeCompany = (companyId: string) => {
    if (!companyId) {
      setSelectedCompany('');
      form.setFieldValue('companyId', null);
    }
    setSelectedCompany(companyId);
    // Automatically set CompanyRep and CompanyRepEmail
    const autoFillUsers = users?.filter((user) => !companyId || user.WorkspaceUser?.Company?.id === companyId);
    if (companyId && autoFillUsers[0]?.id) {
      form.setFieldValue('companyRep', autoFillUsers[0]?.id);
      form.setFieldValue('companyRepEmail', autoFillUsers[0]?.email);
    } else {
      form.setFieldValue('companyRep', null);
      form.setFieldValue('companyRepEmail', null);
    }
  };

  const onChangeCompanyRep = (companyRep: string) => {
    if (!companyRep) {
      form.setFieldValue('companyRep', null);
      form.setFieldValue('companyRepEmail', null);
      return;
    }
    const selectedUser = users?.find((user) => user.id === companyRep);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    // Set Company field
    if (companies[existingCompany]?.id) setSelectedCompany(companies[existingCompany]?.id);
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
    // Set Company Representative Email field
    form.setFieldValue('companyRepEmail', selectedUser?.email);
  };

  const onChangeContractCompany = (contractCompanyId: string) => {
    if (!contractCompanyId) {
      setSelectedContractCompany('');
      form.setFieldValue('contractEntity', null);
    }
    setSelectedContractCompany(contractCompanyId);
    // Automatically set CompanyRep and CompanyRepEmail
    const autoFillUsers = workspaceUsers?.filter(
      (user) => !contractCompanyId || user.Company?.id === contractCompanyId
    );
    if (contractCompanyId && autoFillUsers[0]?.id) {
      form.setFieldValue('contractRepName', autoFillUsers[0]?.id);
      form.setFieldValue('contractRepEmail', autoFillUsers[0]?.email);
    } else {
      form.setFieldValue('contractRepName', null);
      form.setFieldValue('contractRepEmail', null);
    }
  };

  const onChangeContractCompanyRep = (contractCompanyRepId: string) => {
    if (!contractCompanyRepId) {
      form.setFieldValue('contractRepName', null);
      form.setFieldValue('contractRepEmail', null);
      return;
    }
    const selectedUser = workspaceUsers?.find((user) => user.id === contractCompanyRepId);
    const existingCompany = workspaceCompanies?.findIndex((company) => company.id === selectedUser?.Company?.id);
    // Set Company field
    if (workspaceCompanies[existingCompany]?.id) setSelectedContractCompany(workspaceCompanies[existingCompany]?.id);
    form.setFieldValue(['contractEntity'], existingCompany > -1 ? selectedUser?.Company?.id : null);
    // Set Company Representative Email field
    form.setFieldValue('contractRepEmail', selectedUser?.email);
  };

  useEffect(() => {
    setIsDisable(true);
    if (isOpenForm && selectedPWP) {
      getWorkPackage(selectedPWP);
    } else form.resetFields();
  }, [isOpenForm]);

  useEffect(() => {
    if (isOpenForm) {
      if (
        formValuesWatch?.name &&
        formValuesWatch?.name?.length <= 50 &&
        formValuesWatch?.companyId &&
        formValuesWatch?.companyRole &&
        formValuesWatch?.code
      ) {
        setIsDisable(false);
      } else setIsDisable(true);
      if (formValuesWatch?.contractRepEmail && !isValidEmail(formValuesWatch?.contractRepEmail)) setIsDisable(true);
    }
  }, [formValuesWatch]);

  useEffect(() => {
    // set company code
    const findCompany = companies?.find((company) => company.id === selectedCompany);
    form.setFieldValue('companyCode', findCompany?.companyCode);

    const filterUsers = users?.filter(
      (user) => !selectedCompany || user.WorkspaceUser?.Company?.id === selectedCompany
    );
    setFilterCompanyReps(filterUsers);
  }, [selectedCompany, isOpenForm]);

  useEffect(() => {
    const filterUsers = workspaceUsers?.filter(
      (user) => !selectedContractCompany || user?.Company?.id === selectedContractCompany
    );
    setFilterContractCompanyReps(filterUsers);
  }, [selectedContractCompany, isOpenForm]);

  return (
    <Modal
      title={
        <Typography.Text style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}>
          {selectedPWP ? t('Edit Work Package') : t('New Work Package')}
        </Typography.Text>
      }
      open={isOpenForm}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button loading={isLoading} onClick={handleSubmit} disabled={isDisable} key='submit' type='primary'>
          {t('Save')}
        </Button>,
      ]}
      width={723}
    >
      <div className='work-package-form'>
        <Form form={form} name='create-or-update-pwp' layout='vertical' requiredMark={CustomizeRequiredMark}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={'name'} label={t('Work Package Name')} rules={[{ required: true, message: '' }]}>
                <Input allowClear placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'code'} label={t('Work Package ID')} rules={[{ required: true, message: '' }]}>
                <Input allowClear placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item rules={[{ required: true, message: '' }]} name={'companyId'} label={t('Company')}>
            <SearchSelect
              placeholder={t('Select')}
              value={selectedCompany}
              options={(companies ?? []).map((company) => ({
                value: company.id,
                label: company.name,
              }))}
              onChange={(value) => onChangeCompany(value)}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={'companyRole'} label={t('Company Role')} rules={[{ required: true, message: '' }]}>
                <Input allowClear placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'companyCode'} label={t('Company Code')} rules={[{ required: true, message: '' }]}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={'companyRep'} label={t('Company Representative')}>
                <SearchSelect
                  placeholder={t('Select')}
                  options={(filterCompanyReps ?? []).map((user) => ({
                    value: user.id,
                    label: user.fullName,
                  }))}
                  onChange={(value) => onChangeCompanyRep(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'companyRepEmail'} label={t('Company Representative Email')}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={'contractEntity'} label={t('Contracting Entity')}>
            <SearchSelect
              placeholder={t('Select')}
              value={selectedContractCompany}
              options={(workspaceCompanies ?? []).map((company) => ({
                value: company.id,
                label: company.name,
              }))}
              onChange={(value) => onChangeContractCompany(value)}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={'contractRepName'} label={t('Contracting Representative')}>
                <SearchSelect
                  placeholder={t('Select')}
                  options={(filterContractCompanyReps ?? []).map((user) => ({
                    value: user.id,
                    label: user.fullName,
                    disabled: user?.Company ? user?.Company.id === selectedCompany : false,
                  }))}
                  onChange={(value) => onChangeContractCompanyRep(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'contractRepEmail'} label={t('Contracting Email')}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
