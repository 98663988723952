import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

type customFooterProps = {
  current: number;
  isDisabled: boolean;
  loading: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
};

export default function CustomFooter(props: customFooterProps) {
  const { current, isDisabled, loading, handleCancel, handleSubmit } = props;
  const { t } = useTranslation();
  return (
    <Flex className={'validation-footer'} justify={current > 0 ? 'space-between' : 'end'}>
      <div className='main-validation-btn'>
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button 
          loading={loading}
          disabled={isDisabled} 
          key='submit' 
          type={'primary'} 
          className='validation-footer-button'
          onClick={handleSubmit}>
          {t('Submit')}
        </Button>
      </div>
    </Flex>
  );
}
