import { Flex, Input, InputProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import SearchSelect from './SearchSelect';
import { useTranslation } from 'react-i18next';

type SelectTimePeriodValue = { periodNumber?: number | null; timePeriod?: string };
export type SelectTimePeriodProps = {
  value?: SelectTimePeriodValue;
  onChange?: (value: SelectTimePeriodValue) => void;
  placeholder?: string;
};

const SelectTimePeriod = (props: SelectTimePeriodProps) => {
  const { t } = useTranslation();
  const [periodNumber, setPeriodNumber] = useState<SelectTimePeriodValue['periodNumber']>(null);
  const [timePeriod, setTimePeriod] = useState('Days');

  const options = useMemo(
    () => [
      {
        label: t('Days'),
        value: 'Days',
      },
      {
        label: t('Weeks'),
        value: 'Weeks',
      },
      {
        label: t('Months'),
        value: 'Months',
      },
    ],
    []
  );

  useEffect(() => {
    if (!props?.value) return;
    setPeriodNumber(props?.value?.periodNumber);
  }, [props?.value?.periodNumber]);

  useEffect(() => {
    if (!props?.value?.timePeriod) return;
    setTimePeriod(props?.value?.timePeriod);
  }, [props?.value?.timePeriod]);

  return (
    <Flex className='select-time-period'>
      <Input
        placeholder={props?.placeholder}
        type='number'
        value={String(periodNumber)}
        onChange={(e) => {
          if (e.target.value.length > 3) return;
          const value = e.target.value ? Number(e.target.value) : null;
          setPeriodNumber(value);
          props.onChange && props?.onChange({ periodNumber: value, timePeriod });

        }}
        addonAfter={
          <SearchSelect
            value={timePeriod}
            onChange={(value) => {
              setTimePeriod(value);
              props.onChange && props?.onChange({ periodNumber, timePeriod: value });
            }}
            options={options}
          />
        }
      />
    </Flex>
  );
};

export default SelectTimePeriod;
