import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Collapse, CollapseProps, Flex, theme } from 'antd';
import { CaretUpOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { KpiStatistic } from 'components/common';
import { ISSUE_STATUSES, ISSUE_STATUSES_TITLE } from 'utils/contants';
import { kpiData } from 'types/project';

export type ListViewKpiStatisticProps = {
  handleFilterByKPI?: (value: string, isOverdue?: boolean) => void;
  kpiData: kpiData;
  isResetKPI?: boolean;
  setResetKPI?: (value: boolean) => void;
};

export default function ListViewKpiStatistic(props: ListViewKpiStatisticProps) {
  const { handleFilterByKPI, isResetKPI, setResetKPI } = props;
  const [activeKpi, setActiveKpi] = useState<string | null>(null);
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const onApplyFilter = (status: string, code: string, isOverdue?: boolean) => {
    if (!handleFilterByKPI) return;

    if (activeKpi === status) {
      setActiveKpi(null);
      handleFilterByKPI('');
    } else {
      setActiveKpi(status);
      handleFilterByKPI(code, isOverdue);
    }
  };
  useEffect(() => {
    if (isResetKPI && setResetKPI) {
      setActiveKpi(null);
      setResetKPI(false);
    }
  }, [isResetKPI]);

  const getDataByCode = (code: string) => {
    switch (code) {
      case ISSUE_STATUSES.DRAFT:
        return {
          title: ISSUE_STATUSES_TITLE.DRAFT,
          status: 'draft',
        };
      case ISSUE_STATUSES.REVIEW_IN_PROGRESS:
        return {
          title: ISSUE_STATUSES_TITLE.REVIEW_IN_PROGRESS,
          status: 'review-in-progress',
        };
      case ISSUE_STATUSES.ACTION_AGREE:
        return {
          title: ISSUE_STATUSES_TITLE.ACTION_AGREE,
          status: 'action-agree',
        };
      case ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE:
        return {
          title: ISSUE_STATUSES_TITLE.REVIEW_IN_PROGRESS_OVERDUE,
          status: 'inspection-overdue',
          isOverdue: true,
          code: ISSUE_STATUSES.REVIEW_IN_PROGRESS,
        };
      case ISSUE_STATUSES.APPROVED:
        return {
          title: ISSUE_STATUSES_TITLE.APPROVED,
          status: 'approved',
        };
      case ISSUE_STATUSES.ISSUED:
        return {
          title: ISSUE_STATUSES_TITLE.ISSUED,
          status: 'issued',
        };
      case ISSUE_STATUSES.APPROVED_WITH_COMMENT:
        return {
          title: ISSUE_STATUSES_TITLE.APPROVED_WITH_COMMENT,
          status: 'approved-with-comment',
        };
      case ISSUE_STATUSES.REJECT:
        return {
          title: ISSUE_STATUSES_TITLE.REJECT,
          status: 'rejected',
        };
      case ISSUE_STATUSES.READY_FOR_INSPECTION:
        return {
          title: ISSUE_STATUSES_TITLE.READY_FOR_INSPECTION,
          status: 'inspection',
        };
      case ISSUE_STATUSES.READY_FOR_INSPECTION_OVERDUE:
        return {
          title: ISSUE_STATUSES_TITLE.READY_FOR_INSPECTION_OVERDUE,
          status: 'inspection-overdue',
          isOverdue: true,
          code: ISSUE_STATUSES.READY_FOR_INSPECTION,
        };
      case ISSUE_STATUSES.OPEN:
        return {
          title: ISSUE_STATUSES_TITLE.OPEN,
          status: 'open',
        };
      case ISSUE_STATUSES.OPEN_OVERDUE:
        return { title: 'Open', status: 'overdue', isOverdue: true, code: ISSUE_STATUSES.OPEN };
      case ISSUE_STATUSES.CLOSED:
        return {
          title: ISSUE_STATUSES_TITLE.CLOSED,
          status: 'closed',
        };
      case ISSUE_STATUSES.OPEN_START:
        return {
          title: ISSUE_STATUSES_TITLE.OPEN_START,
          status: 'open-start',
          isStartDate: true,
        };
      case ISSUE_STATUSES.CLOSED_END:
        return {
          title: ISSUE_STATUSES_TITLE.CLOSED_END,
          status: 'closed-end',
          isEndDate: true,
        };
      case ISSUE_STATUSES.AVERAGE_CLOSURE_TIME:
        return {
          title: 'Average closure time',
          status: 'average-closure-time',
          customIcon: <IssuesCloseOutlined style={{ color: '#FFFFFF' }} />,
          suffix: <span className='text-size-14'>Day(s)</span>,
        };
      case ISSUE_STATUSES.TOTAL:
        return { title: 'Total', status: 'total' };
      default:
        return {
          title: '',
          status: '',
          isOverdue: false,
        };
    }
  };

  const sortByStatus = (nextCode: string, code: string) => {
    const statusValues = Object.values(ISSUE_STATUSES);
    return statusValues.indexOf(nextCode) - statusValues.indexOf(code);
  };

  const kpiList = useMemo(() => {
    return Object.keys(props.kpiData)
      .map((kpiCode) => {
        const { title, ...data } = getDataByCode(kpiCode);
        const currentData = props?.kpiData?.[kpiCode as keyof kpiData] as kpiData;
        return {
          code: kpiCode,
          value: currentData.value,
          icon: currentData.icon,
          className: currentData.className,
          title: currentData?.title || title,
          ...data,
        };
      })
      .sort((a, b) => sortByStatus(a.code, b.code));
  }, [props.kpiData]);

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      children: (
        <Flex wrap='wrap' gap={20}>
          {kpiList.map((kpiData) => {
            return (
              <KpiStatistic
                key={kpiData.status}
                value={kpiData?.value ? Math.ceil(kpiData?.value as number) : kpiData?.value}
                status={kpiData.status}
                title={kpiData.title}
                isOverdue={kpiData?.isOverdue}
                isStartDate={kpiData?.isStartDate}
                isEndDate={kpiData?.isEndDate}
                isActive={activeKpi === kpiData.status}
                onClick={() => onApplyFilter(kpiData.status, kpiData?.code, kpiData?.isOverdue)}
                className={kpiData.className}
                icon={kpiData.icon}
                customIcon={kpiData?.customIcon}
                suffix={kpiData.suffix}
              />
            );
          })}
        </Flex>
      ),
      style: panelStyle,
    },
  ];

  return (
    <Collapse
      size={'small'}
      className='inspection-collapse base-issue-kpi-statistic ma-0'
      ghost
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretUpOutlined
          rotate={isActive ? 0 : 180}
          style={{
            color: 'rgba(0, 0, 0, 0.45)',
            fontSize: '20px',
            width: '20px',
          }}
        />
      )}
      expandIconPosition='end'
      style={{ background: 'transparent' }}
      items={getItems(panelStyle)}
    />
  );
}
