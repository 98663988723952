import NotificationListAllTab from './NotificationListAllTab';
import React from 'react';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Issue } from 'model';


type NotificationListTabsProps = {
  showMoreOption?: boolean;
  isFetching: boolean;
  setOpen?: (value: boolean) => void;
  setIsFetching: (value: boolean) => void;

}

const NotificationListTabs = (props: NotificationListTabsProps) => {
  const { t } = useTranslation();
  const {
    setOpen,
    isFetching,
    setIsFetching,
  } = props;

  const getItems = (): TabsProps['items'] => [
    {
      key: '1',
      label: t('All'),
      children: <NotificationListAllTab
        setOpen={setOpen}
        isAll={true}
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        showMoreOption={props.showMoreOption} />,
    },
    {
      key: '2',
      label: t('Unread'),
      // Todo in the futures
      children: <NotificationListAllTab
        setOpen={setOpen}
        isAll={false}
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        showMoreOption={props.showMoreOption} />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return <Tabs defaultActiveKey='1' items={getItems()} onChange={onChange} />;
};

export default NotificationListTabs;
