import { Avatar, Form } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd';
import {
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Upload } from 'components';
import ImgItem from './pre-signed-url-items/ImgItem';

export default function UploadProfile({
  imgUrl,
  onChange,
}: {
  imgUrl?: string | null;
  onChange: (values: string) => void;
}) {
  const [uploadedFileUrl, setUploadedFileUrl] = useState(imgUrl ?? '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUploadedFileUrl(imgUrl ?? '');
  }, [imgUrl]);

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const url = info.file.response.imageURL;
      setLoading(false);
      setUploadedFileUrl(url);
      onChange && onChange(url);
    }
  };

  return (
    <Form.Item>
      <Upload
        listType='picture-card'
        showUploadList={false}
        maxCount={1}
        onChange={handleChange}
      >
        {uploadedFileUrl ? (
          <ImgItem src={uploadedFileUrl} alt='avatar' style={{ width: '100%' }} />
        ) : (
          <div>
            {loading ? (<LoadingOutlined />) : (<Avatar size={100} icon={<UserOutlined />} />)}
          </div>
        )}
      </Upload>
    </Form.Item>
  );
}
