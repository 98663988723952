import { CheckCircleFilled } from '@ant-design/icons';
import { Button, ButtonProps, Flex } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

export type ChoiceToggleProps = {
  options?: (ButtonProps & {
    label: string;
    value: string;
    color?: 'green' | 'red';
  })[];
  value?: string;
  onChange?: (value: string) => void;
};

const ChoiceToggle = (props: ChoiceToggleProps) => {
  const [checkedValue, setCheckedValue] = useState('');
  const labelRef = useRef<HTMLSpanElement>(null);
  const [containerWidth, setContainerWith] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (labelRef.current) {
      const additionalWidth = 39;
      const width = labelRef.current.offsetWidth + labelRef.current.offsetLeft + additionalWidth;
      setContainerWith(width);
    }
  }, [labelRef]);

  useEffect(() => {
    if (props?.value) {
      setCheckedValue(props?.value);
    }
  }, [props?.value]);

  return (
    <Flex className={`choice-toggle`}>
      {props?.options?.map(({ label, value, color, ...option }) => (
        <Button
          key={value}
          onClick={() => {
            if (props?.onChange) {
              props?.onChange(value);
            }
            setCheckedValue(checkedValue !== value ? value : '');
          }}
          type='default'
          shape='round'
          {...option}
          style={{ width: containerWidth, ...option.style }}
          className={`${option?.className || ''} ${checkedValue === value ? 'checked' : ''} ${color || ''}`}
          icon={checkedValue === value ? <CheckCircleFilled height={14} width={14} /> : ''}
        >
          <span ref={labelRef}>{label}</span>
        </Button>
      ))}
    </Flex>
  );
};

export default ChoiceToggle;
