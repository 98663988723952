import { Collapse, Flex, Space, TableProps, Tag, Typography } from 'antd';
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import DynamicTable, { DynamicTableDataSource, DynamicTableProps } from './DynamicTable';
import { TablePaginationProps } from './TablePagination';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ISSUE_STATUSES } from 'utils/contants';
import { TableRowsSelected } from 'types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const statusCode = ['dr', 'op', 'rfi', 'ripo', 'cl'];
type TagProps = {
  code: (typeof statusCode)[number];
  id: string;
  value: number;
  isOverdue?: boolean;
};

export type Data<T extends object> = {
  id: string;
  name?: string;
  tags?: TagProps[];
  dataSource?: DynamicTableDataSource<T>[];
  pagination?: Omit<TablePaginationProps, 'onSubmit'>;
  isLoading?: boolean;
  orderBy?: string;
};

export type DynamicCollapseTableProps<T extends object> = Omit<
  DynamicTableProps<T>,
  'dataSource' | 'pagination' | 'onSort'
> & {
  data: Data<T>[];
  header: ReactNode;
  module: 'Quality' | 'Safety' | 'Construction';
  onPaginationChange?: (value: { page: string; limit: string }, id?: string) => void;
  expandNewItem?: boolean;
  onSort?: (itemId?: string) => void;
  tableProps?: TableProps<T>;
  handlerRowsKeyChange?: (value: React.Key[], id?: string) => void;
  selectedRowKeys?: TableRowsSelected;
};

export type DynamicCollapseTableRefHandle = {
  updateActiveKey: (key: string) => void;
};

const DynamicCollapseTable = <T extends object>(
  props: DynamicCollapseTableProps<T>,
  ref: React.Ref<DynamicCollapseTableRefHandle>
) => {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const { handlerRowsKeyChange, selectedRowKeys } = props;

  useImperativeHandle(ref, () => ({
    updateActiveKey: (key: string) => {
      if (!activeKey.includes(key)) {
        setActiveKey((prev) => [...prev, key]);
      }
    },
  }));

  useEffect(() => {
    if (props?.data[0]?.id && !activeKey.length) {
      setActiveKey((prev) => [...prev, props?.data[0]?.id || '']);
    }
  }, [props?.data[0]?.id]);

  const sortByStatus = (code: string, nextCode: string) => {
    const customOrder = Object.values(ISSUE_STATUSES);
    return customOrder.indexOf(code) - customOrder.indexOf(nextCode);
  };

  const renderHeader = ({ item }: { item: Data<T> }) => {
    return (
      <Flex className='tag-box' align='center'>
        <span style={{ flex: 1, fontWeight: 'bold' }}>{item?.name?.toUpperCase()}</span>
        {item?.tags && (
          <Space className='tag-list' size={'small'}>
            {item.tags
              ?.sort((a, b) => sortByStatus(a?.code, b?.code))
              ?.map((tag) => {
                return (
                  <Tag className={`${tag?.code?.toLowerCase()}`} key={`${tag?.code?.toLocaleLowerCase()}-${tag?.id}`}>
                    {tag?.value}
                    {tag?.isOverdue && <ClockCircleOutlined />}
                  </Tag>
                );
              })}
          </Space>
        )}
      </Flex>
    );
  };

  return (
    <div className={`dynamic-collapse-table ${props?.module?.toLowerCase() || 'quality'}`}>
      <Typography.Title className='title'>{props.header}</Typography.Title>
      {props?.data && props?.data?.length > 0 && (
        <Collapse
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key as string[]);
          }}
          defaultActiveKey={props?.data[0]?.id}
          ghost
        >
          {props.data?.map((item) => {
            return (
              <Collapse.Panel header={renderHeader({ item })} key={item?.id || ''} showArrow={true}>
                {item?.dataSource && (
                  <DynamicTable
                    {...props.tableProps}
                    columns={props.columns}
                    onRowClick={props.onRowClick}
                    pagination={{
                      issueParams: item?.pagination?.issueParams,
                      issuesCount: item?.pagination?.issuesCount,
                      onSubmit: (value) => {
                        if (props?.onPaginationChange) {
                          props?.onPaginationChange(value as { limit: string; page: string }, item?.id);
                        }
                      },
                    }}
                    onSort={(sortKey) => {
                      item.orderBy = sortKey;
                      if (props.onSort) {
                        props.onSort(item?.id);
                      }
                    }}
                    isLoading={item?.isLoading}
                    module={props.module}
                    onActionClick={props.onActionClick}
                    dataSource={item?.dataSource}
                    setSelectedRowKeys={(keys: React.Key[]) => handlerRowsKeyChange?.(keys, item?.id)}
                    rowKeys={selectedRowKeys?.[item?.id] ?? []}
                  />
                )}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};

const DynamicCollapseTableForwardRef = forwardRef(DynamicCollapseTable) as <T extends object>(
  props: DynamicCollapseTableProps<T> & { ref?: React.Ref<DynamicCollapseTableRefHandle> }
) => ReturnType<typeof DynamicCollapseTable>;

export default DynamicCollapseTableForwardRef;
