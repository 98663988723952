import { Layout, Table, Tag, Dropdown, Typography } from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  MailOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { User } from 'model';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useState } from 'react';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { TablePagination } from 'components';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';
import { QueryParams } from 'types';

const { Text } = Typography;

type ProjectUserListProps = {
  users: User[];
  loading?: boolean;
  usersCount: number;
  pageSize: number;
  currentPage: number;
  onFilter: (value: QueryParams) => void;
  selectedUser: User | null;
  setSelectedUser: (value: User | null) => void;
};
export default function ProjectUserList(props: ProjectUserListProps) {
  const { users, loading, usersCount, pageSize, currentPage, setSelectedUser, onFilter } = props;
  const { t } = useTranslation();
  const statusColors: Record<string, string> = {
    active: 'green',
    inactive: 'magenta',
    pending: 'orange',
  };

  const handleEditUser = (record: User) => {
    console.log(record);
    setSelectedUser(record);
  };

  const columns: ColumnsType<User> = [
    {
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      title: t('Name'),
      dataIndex: 'firstName',
      render: (_, record: User) => (
        <div className='line-item-with-avatar'>
          <div className='item-avatar'>{record.picture && <ImgItem src={record.picture} alt={record.firstName} />}</div>
          <div className='item-info'>
            <div className='title'>{[record.firstName, record.lastName].filter((i) => i).join(' ')}</div>
            <Text type='secondary'>{record.email}</Text>
          </div>
        </div>
      ),
    },
    {
      sorter: (a, b) =>
        a?.WorkspaceUser?.Company?.name.localeCompare(b?.WorkspaceUser?.Company?.name as string) as number,
      title: t('Company'),
      dataIndex: 'WorkspaceUser',
      render: (value) => <span>{value?.Company?.name}</span>,
    },
    {
      sorter: true,
      title: t('Job title'),
      dataIndex: 'job',
    },
    {
      sorter: (a, b) =>
        (a?.RoleMapping?.Role?.name?.toLowerCase() || '').localeCompare(
          b?.RoleMapping?.Role?.name?.toLowerCase() as string
        ),
      title: t('Project Role'),
      dataIndex: '',
      render: (value) => (
        <span>{value?.userRole?.charAt(0).toUpperCase() + value?.userRole?.slice(1) || t('No role')}</span>
      ),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      width: 20,
      render: (value) => <Tag color={statusColors[value]}>{value}</Tag>,
    },
    {
      title: '',
      width: 10,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              ...(record.UserInvitations?.length
                ? [
                    {
                      label: t('Copy invitation link'),
                      key: '0',
                      icon: <UsergroupAddOutlined />,
                      onClick: () =>
                        navigator.clipboard.writeText(
                          `${window.location.hostname}/auth?invitationToken=${record.UserInvitations?.[0]?.token}`
                        ),
                    },
                  ]
                : []),
              {
                label: t('Resend invitation'),
                key: '1',
                icon: <MailOutlined />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Revoke invitation')}</span>,
                key: '2',
                icon: <CloseCircleOutlined style={{ color: '#FF4D4F' }} />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Revoke access')}</span>,
                key: '3',
                icon: <CloseCircleOutlined style={{ color: '#FF4D4F' }} />,
              },
              {
                label: <span>{t('Edit User')}</span>,
                key: '4',
                icon: <EditOutlined />,
              },
            ],
            onClick: (e) => {
              if (e.key === '4') {
                handleEditUser(record);
              }
            },
          }}
        >
          <EllipsisOutlined className='cursor-pointer' />
        </Dropdown>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableProps['rowSelection'] = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: User[]) => {
      console.log(`newSelectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: User) => ({
      name: record.firstName,
    }),

    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ) => {
    const { current, pageSize } = pagination;

    if (Array.isArray(sorter)) {
      const [sortItem] = sorter;
      const { order, column } = sortItem;
      if (order && column) {
        const sorterOrder = order === 'ascend' ? 'asc' : 'desc';
        const orderBy = `${column.dataIndex},${sorterOrder}`;
        onFilter({ orderBy, page: current, limit: pageSize });
      } else {
        onFilter({ page: current, limit: pageSize });
      }
    } else {
      const { order, field } = sorter;

      if (order && field) {
        const sorterOrder = order === 'ascend' ? 'asc' : 'desc';
        const orderBy = `${field},${sorterOrder}`;
        onFilter({ orderBy, page: current, limit: pageSize });
      } else {
        onFilter({ page: current, limit: pageSize });
      }
    }
  };

  return (
    <Layout.Content className='settings-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={{
          limit: pageSize,
          page: currentPage,
        }}
        issuesCount={usersCount}
      />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading}
        pagination={{
          pageSize: pageSize,
          total: usersCount,
          current: currentPage,
        }}
        className='workspaces-list-table project-user-table my-6'
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={users}
        onChange={handleTableChange}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={{
          limit: pageSize,
          page: currentPage,
        }}
        issuesCount={usersCount}
      />
    </Layout.Content>
  );
}
