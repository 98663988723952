import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import App from 'App';
import store from 'store';
import 'assets/styles/d3js.css';
import 'assets/styles/main.scss';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n/config';
import { AuthContextProvider } from 'store/auth-context';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

i18next.init({
  interpolation: { escapeValue: false },
});

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID as string,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
    <MsalProvider instance={msalInstance}>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter basename={`${process.env.REACT_APP_BASE_PATH}`}>
            <AuthContextProvider>
              <I18nextProvider i18n={i18next}>
                <App />
              </I18nextProvider>
            </AuthContextProvider>
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </MsalProvider>
  </GoogleOAuthProvider>
);
