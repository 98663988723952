import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, message, Typography } from 'antd';
import { t } from 'i18next';
import FilterMaterialDeliveryInspectionForm from './FilterMaterialDeliveryInspectionForm';
import { CreateOrUpdateMDI, MaterialDeliveryInspectionsListView } from '.';
import { useEffect, useRef, useState } from 'react';
import {
  useFetchProjectDisciplineByFunction,
  useFetchProjectDisciplineByFunctionParams,
  useProjectBaseIssueMARsParams,
  useProjectBaseIssues,
  useProjectBaseIssuesParams,
} from 'hooks/base-issue';
import { MAR_STATUSES } from 'utils/contants';
import {
  useIssueStatuses,
  useIssueStatusesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useProjectCompanies,
  useProjectCompaniesParams,
} from 'hooks';
import { User } from 'model';
import { useSearchDebounce } from 'utils';
import { QueryParams } from 'types';
import dayjs from 'dayjs';
import { kpiData } from 'types/project';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/DisciplineBaseIssueTable';
import BaseIssueKpiStatistic from 'components/common/BaseIssueKpiStatistic';

type MaterialDeliveryInspectionProps = {
  module: string;
  workspaceId: string;
  projectId: string;
  isOwnerCompany: boolean;
  users: User[];
  profile: User | null;
  loggedCompanyRoleId: string;
  userCompanyId: string | undefined;
};
export default function MaterialDeliveryInspections(props: MaterialDeliveryInspectionProps) {
  const { module, workspaceId, projectId, users, profile, userCompanyId, loggedCompanyRoleId, isOwnerCompany } = props;

  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [idRecord] = useState<string>();
  const [baseIssueId, setBaseIssueId] = useState<string | null>(null);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  // const [selectedRowKeys] = useState<string[]>([]);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'MDI',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [moduleFunctionsQuery] = useModuleFunctionParams({
    orderBy: 'name',
  });
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsQuery);
  // const [isGenerateReports, setIsGenerateReports] = useState<boolean | null>(null);
  const [projectBaseIssueParams, onProjectBaseIssuesParamsChange] = useProjectBaseIssuesParams({
    projectId: projectId as string,
    ...(!isOwnerCompany && { 'companyId[]': `["${loggedCompanyRoleId}"]` }),
    workspaceId,
    module,
    type: 'MDI',
    include:
      'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments|Attachments',
  });
  const [totalKpi, setTotalKpi] = useState<kpiData>({});

  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: props.projectId as string,
    workspaceId: props.workspaceId as string,
    orderBy: 'order',
    mainPhase: true,
  });
  useWorkBreakdownStructures(workBreakdownStructureQuery);
  const disciplineBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);
  const statusCode =
    MAR_STATUSES.APPROVED && MAR_STATUSES.APPROVED_WITH_COMMENT
      ? `[${'"' + [MAR_STATUSES.APPROVED, MAR_STATUSES.APPROVED_WITH_COMMENT].join('","') + '"'}]`
      : undefined;
  const [MARsParams] = useProjectBaseIssueMARsParams({
    projectId: projectId as string,
    workspaceId,
    type: 'MAR',
    originatorId: profile?.id,
    companyOriginatorId: userCompanyId,
    'statusCode[]': statusCode,
    include:
      'BaseIssueWatchers|IssueStatus|ModuleFunction|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments',
  });
  const [ProjectDisciplineByFunctionParams] = useFetchProjectDisciplineByFunctionParams({
    workspaceId,
    projectId,
    type: 'MDI',
  });
  const [, , refreshDisciplineByFunction] = useFetchProjectDisciplineByFunction(ProjectDisciplineByFunctionParams);

  const [MARs, , refreshMARs] = useProjectBaseIssues(MARsParams);
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  useEffect(() => {
    if (userCompanyId) {
      refreshMARs({
        projectId: projectId as string,
        workspaceId,
        type: 'MAR',
        originatorId: profile?.id,
        companyOriginatorId: userCompanyId,
        statusCode: MAR_STATUSES.APPROVED,
        include:
          'BaseIssueWatchers|IssueStatus|ModuleFunction|Zone|Company|BaseValidations|BaseIssueCategories|BaseIssueAttachments',
      });
    }
  }, [userCompanyId]);

  // const handleEditForm = (id: string, status?: string) => {
  //   setIdRecord(id)
  //   if (status === 'Draft') {
  //     setIsEdit(true);
  //     setIsCreate(true)
  //     setIsReject(false)
  //   }
  //   else if (status === 'Rejected') {
  //     setIsEdit(false);
  //     setIsCreate(false)
  //     setIsReject(true)
  //   }
  //   else {
  //     setIsEdit(false);
  //     setIsCreate(false)
  //     setIsReject(false)
  //   }
  //   setBaseIssueId(id ?? null);
  //   setIsFormOpen(true);
  // }
  const handleEditForm = (baseIssueId?: string, status?: string) => {
    if (status === 'Draft') {
      setIsEdit(true);
      setIsCreate(true);
      setIsReject(false);
      setIsDraft(true);
    } else if (baseIssueId && status === 'Rejected') {
      setIsEdit(false);
      setIsCreate(false);
      setIsReject(true);
      setIsDraft(false);
    } else {
      setIsCreate(false);
      setIsEdit(false);
      setIsReject(false);
      setIsDraft(false);
    }
    setBaseIssueId(baseIssueId ?? null);

    setIsFormOpen(true);
  };
  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };
  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleFilter = async (values: QueryParams) => {
    let CompanyOriginatorId = undefined;

    let assigneeId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === 'myCompany' && userCompanyId) {
      CompanyOriginatorId = `${userCompanyId}`;
    } else if (values.groupIssues === 'watchedIssues' && profile?.id) {
      CompanyOriginatorId = undefined;
      assigneeId = undefined;
    } else {
      if (values.companyId) {
        CompanyOriginatorId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }
    const zoneId = values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;

    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const params = {
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      'statusId[]': issueStatusId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : CompanyOriginatorId,
      originatorId: assigneeId,
      'zoneId[]': zoneId,
      filterType: values.groupIssues,
      // watcherIds: `['${watcherId}']`,
      page: 1,
    };
    // console.log(params);
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
  };

  const handleSearch = async () => {
    try {
      const param: Record<string, string> = {
        searchBy: '["BaseIssue.name", "Company.name","Zone.name","MaterialDeliveryInspection.mdiNumber"]',
        searchKey: searchValue,
        page: '1',
      };
      onProjectBaseIssuesParamsChange(param);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  return (
    <Layout.Content className='settings-content'>
      {isFormOpen !== null && (
        <CreateOrUpdateMDI
          users={users}
          baseIssueId={baseIssueId}
          setBaseIssueId={setBaseIssueId}
          moduleFunction={moduleFunctions || []}
          companyOriginator={userCompanyId}
          MARs={MARs}
          isOpen={isFormOpen}
          module={module}
          workspaceId={workspaceId}
          projectId={projectId}
          setOpen={setIsFormOpen}
          isEdit={isEdit}
          setEdit={setIsEdit}
          isReject={isReject}
          idRecord={idRecord}
          isCreate={isCreate}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          setCreate={setIsCreate}
          refreshBaseIssues={(disciplineId) => {
            disciplineBaseIssueTableRef.current?.refreshData(disciplineId, baseIssueId);
          }}
          profile={profile}
        />
      )}
      <Flex>
        <Typography.Title level={2} className='mr-16 setting-section-header'>
          {' '}
          {t('Material delivery inspections - MDI')}
        </Typography.Title>
      </Flex>
      <Flex style={{ justifyContent: 'flex-end' }} className='mt-3 mb-3'>
        <Input
          name='search-input'
          className='search-button mr-3'
          placeholder={t('Type to search')}
          onBlur={(e) => handleInputBlur(e.target.value)}
          onFocus={handleSearchIconClick}
          style={{
            width: showSearchInput ? '250px' : '0px',
            border: showSearchInput ? '1px solid #d9d9d9' : 'none',
          }}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
        />

        {isOpenFilter === null ? (
          <Button
            onClick={() => {
              setIsOpenFilter(!isOpenFilter);
            }}
            icon={<FilterOutlined />}
          >
            {t('Filter')}
          </Button>
        ) : (
          <FilterMaterialDeliveryInspectionForm
            setZoneUpdated={setZoneUpdated}
            zoneUpdated={zoneUpdated}
            companies={companies}
            users={users}
            issueStatuses={issueStatuses}
            workspaceId={workspaceId}
            projectId={projectId}
            isOpenFilter={isOpenFilter}
            setIsOpenFilter={setIsOpenFilter}
            handleFilter={(value) => {
              handleFilter(value);
            }}
            isReset={isResetFilter || false}
            setReset={setIsResetFilter}
            module={module}
            isOwnerCompany={isOwnerCompany}
          />
        )}
        {/* {isGenerateReports !== null && (
          <GenerateMdiReports
            isGenerateReports={isGenerateReports === true}
            profile={profile}
            users={users}
            module={module}
            workspaceId={workspaceId}
            projectId={projectId}
            companies={companies}
            issueStatuses={issueStatuses}
            selectedRowKeys={selectedRowKeys}
            isOwnerCompany={isOwnerCompany}
            setIsGenerateReports={(value) => setIsGenerateReports(value)}
          />
        )}
        <Button onClick={() => setIsGenerateReports(true)} icon={<FileSyncOutlined />}>
          {t('Generate report')}
        </Button> */}
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            setIsEdit(true);
            setIsCreate(true);
            setIsFormOpen(true);
          }}
        >
          {t('Add inspection')}
        </Button>
      </Flex>
      <BaseIssueKpiStatistic
        onFilter={(params) => {
          onProjectBaseIssuesParamsChange(params);
          setIsResetFilter(true);
        }}
        kpiData={totalKpi}
        issueStatuses={issueStatuses}
        projectBaseIssueParams={projectBaseIssueParams}
        isResetKPI={isResetFilterByKPI}
        onResetKPI={setIsResetFilterByKPI}
      />

      <MaterialDeliveryInspectionsListView
        module={module}
        workspaceId={workspaceId}
        projectId={projectId}
        users={users}
        profile={profile}
        companies={companies}
        projectBaseIssueParams={projectBaseIssueParams}
        isFormOpen={isFormOpen}
        editForm={(id: string, status?: string) => handleEditForm(id, status)}
        disciplineBaseIssueTableRef={disciplineBaseIssueTableRef}
        onTotalKpi={setTotalKpi}
        refreshDisciplineByFunction={refreshDisciplineByFunction}
      />
    </Layout.Content>
  );
}
