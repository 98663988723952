import SafetyRiskActivity from 'model/SafetyRiskActivity';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const safetyRiskActivityService = {
  getProjectSafetyRiskActivities: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<SafetyRiskActivity>>(
      `/workspaces/${workspaceId}/projects/${projectId}/safety-risk-activities`,
      {
        params: query,
      }
    );
  },
};

export default safetyRiskActivityService;
