import { Carousel, Steps } from 'antd';
import CustomStepsBarIcon from 'components/common/CustomStepsBarIcon';
import { BaseIssue, MDIsValidationHistory } from 'model';
import { useMemo } from 'react';
import { VALIDATION_STATUS } from 'utils/contants';

type MDIsValidationHistoryStepProps = {
  items: MDIsValidationHistory[];
  baseIssues?: BaseIssue;
};
// const processDescription = (description: string) => {
//   const [company, ...rest] = description.split(':');
//   return { company: company.trim(), date: rest.join(':').trim()};
// };
export default function MDIsValidationHistoryStep(props: MDIsValidationHistoryStepProps) {
  const mapValidationStatusToStep = (
    status: string
  ): 'finish' | 'error' | 'review-in-progress' | 'submitted' | undefined => {
    switch (status) {
      case VALIDATION_STATUS.VALIDATED:
      case VALIDATION_STATUS.APPROVED:
        return 'finish';
      case VALIDATION_STATUS.REJECTED:
        return 'error';
      case VALIDATION_STATUS.IN_REVIEW:
        return 'review-in-progress';
      case VALIDATION_STATUS.SUBMITTED:
        return 'submitted';
      default:
        return undefined;
    }
  };
  const validationStep = useMemo(() => {
    return props?.items?.map((validation) => {
      const status = mapValidationStatusToStep(validation?.status || '');
      return {
        status: status === 'review-in-progress' ? 'process' : status,
        className: `ant-steps-item-${status}`,
        icon: status ? <CustomStepsBarIcon status={status} CompanyName={validation?.code || ''} /> : null,
        description: (
          <>
            <div className='font-size-14 text-color-polaris-black'>{validation?.company}</div>
            <div className='validation-date font-size-12 text-color-dark-grayish-blue'>
              {validation?.comments[0].date}
            </div>
          </>
        ),
      };
    });
  }, [props.baseIssues]);
  const carouseItems = useMemo(() => {
    if (!validationStep?.length) return [];
    const SIZE = 5;
    const totalSlide = Math.ceil(validationStep?.length / SIZE);

    return Array.from({ length: totalSlide }).map((_, index) => {
      const startIndex = index * SIZE;
      return validationStep?.slice(startIndex, startIndex + SIZE);
    });
  }, [validationStep]);

  if (!validationStep) return null;
  // const formatItems = useMemo(() => {
  //   const stepItems: StepProps[] | undefined = [];
  //   (items || []).forEach((item: MDIsValidationHistory, index: number) => {
  //     const description = processDescription(`${item.company}:${item.comments[0].date ?? ''}`);
  //     let status: 'finish' | 'error' | 'process' = 'process';
  //     item.comments.forEach((comment) => {
  //       if (comment.status.code === VALIDATION_STATUS.REJECTED) {
  //         status = 'error';
  //       } else if (comment.status.code === VALIDATION_STATUS.APPROVED) {
  //         status = 'finish';
  //       } else if (comment.status.code === VALIDATION_STATUS.IN_REVIEW) {
  //         status = 'process';
  //       } else if (comment.status.code === VALIDATION_STATUS.VALIDATED) {
  //         status = 'finish';
  //       } else if (comment.status.code === VALIDATION_STATUS.TRANSFERRED) {
  //         status = 'finish';
  //       }
  //     });
  //     stepItems.push({
  //       status,
  //       icon: <CustomStepsBarIcon status={status} CompanyName={item.code} />,
  //       description: (
  //         <>
  //           <div className='font-size-14 text-color-polaris-black'>{description.company}</div>
  //            <div className='font-size-12 text-color-dark-grayish-blue'>{description.date}</div>
  //         </>
  //       )
  //     });
  //   });
  //   return stepItems;
  // }, [items]);
  return (
    <Carousel infinite={false} className='wir-carousel-container'>
      {carouseItems.map((items) => {
        return (
          <div key={''} className='steps-bar-container wir-step-bar-container'>
            <Steps
              current={1}
              status='error'
              className='steps-bar__wir-step-bar'
              items={items.map((item) => ({
                ...item,
                status: item.status as 'finish' | 'error' | 'process' | undefined,
              }))}
              labelPlacement='vertical'
            />
          </div>
        );
      })}
    </Carousel>
  );
}
