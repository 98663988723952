import WirInvitation from 'model/WirInvitation';
import request from 'requesters/core.request';

const refAttachmentService = {
  deleteRefAttachment: async (workspaceId: string, projectId: string, refAttachmentId: string) => {
    return request.delete<WirInvitation>(
      `/workspaces/${workspaceId}/projects/${projectId}/ref-attachments/${refAttachmentId}`
    );
  },
};
export default refAttachmentService;
