import { Layout, Col, Row, Button, Spin, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { AnimatedPage } from 'components';
import { Attachment, Issue } from 'model';
import { motion } from 'framer-motion';
import { PlusOutlined } from '@ant-design/icons';
import { ISSUE_STATUSES } from 'utils/contants';

const { Content } = Layout;

const motionItem = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

type MARsAttachmentProps = {
  onAddAttachment: (value: boolean) => void;
  attachments?: Attachment[];
  onDelete: (attachmentId: string) => void;
};

export default function MARsAttachment(props: MARsAttachmentProps) {
  const { onAddAttachment, attachments, onDelete } = props;
  const { t } = useTranslation();

  return (
    <>
      <Form.Item name='attachments' label={t('Attachments')}>
        <AnimatedPage>
          <Content className='main-content edit-attachment'>
            <div className='workspaces photos attachments'>
              <div className='workspaces-list'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button
                      type='dashed'
                      className='add-attachment-action'
                      onClick={() => onAddAttachment(true)}
                    >
                      <PlusOutlined />
                      <p>{t('Add attachment')}</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </AnimatedPage>
      </Form.Item>
    </>
  );
}
