import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  FilePdfOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Avatar, Collapse, CollapseProps, Divider, Flex, Space, Steps, Tag, Timeline, Typography } from 'antd';
import IconDot from 'assets/images/icon-dot.svg';
import ProgressBlueIcon from 'assets/images/progress-blue.png';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import ImageItem from 'components/common/pre-signed-url-items/ImageItem';
import dayjs from 'dayjs';
import { mockPTWHistory } from 'mock-data/project-data';
import { BaseIssue, User } from 'model';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fileService } from 'services';
import useAuthContext from 'store/auth-context';
import { PTW_STATUSES_TITLE, VALIDATION_STATUS } from 'utils/contants';
import { PTWValidationData } from './PermitToWorkValidationStep';

type PermitToWorkHistoryProps = {
  baseIssues?: BaseIssue;
  validationData: PTWValidationData[];
  users: User[];
};

const PermitToWorkHistory = (props: PermitToWorkHistoryProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const originator = props.users.find((u) => u.id === (props.baseIssues?.originatorId || profile?.id));
  const getStatusNameAndIcon = (status: string) => {
    switch (status) {
      case VALIDATION_STATUS.APPROVED_WITH_COMMENT:
        return {
          name: t(PTW_STATUSES_TITLE.APPROVED_WITH_COMMENT),
          icon: <CheckCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.APPROVED:
        return {
          name: t(PTW_STATUSES_TITLE.APPROVED),
          icon: <CheckCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.TO_BE_REVISED:
        return {
          name: t(PTW_STATUSES_TITLE.TO_BE_REVISED),
          icon: <CloseCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.REJECTED:
        return {
          name: t(PTW_STATUSES_TITLE.REJECTED),
          icon: <CloseCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.REVIEW_IN_PROGRESS:
        return {
          name: t(PTW_STATUSES_TITLE.REVIEW_IN_PROGRESS),
          icon: <img width={24} src={ProgressBlueIcon} alt='' />,
        };
      case VALIDATION_STATUS.SUBMITTED:
        return {
          name: t(PTW_STATUSES_TITLE.SUBMITTED),
          icon: <SendOutlined width={24} />,
        };
      default:
        return null;
    }
  };

  const controlStatus = useMemo(() => {
    return props?.validationData?.map((validation) => {
      const mappedPhotos = validation?.validationPictures?.map((picture) => ({
        ...picture,
        picture: picture.profilePhoto,
        username: picture.fullName || '',
        hideDelete: true,
        firstName: picture.firstName,
      })) as AttachmentPhoto[];

      return {
        className: `ant-steps-item-${validation.status}`,
        icon: getStatusNameAndIcon(validation?.status || '')?.icon,
        description: (
          <>
            <div className='company-name'>{validation?.companyName}</div>

            <Space className='user-information' size={4}>
              <span className='status'>{getStatusNameAndIcon(validation?.status || '')?.name}</span>
              <span className='text-color-polaris-black'>by</span>
              <span className='username'>{validation?.userName}</span>
              <span className={`date`}>{dayjs(validation?.updatedAt).format('DD-MM-YYYY HH:mm:ss')}</span>
            </Space>

            {validation?.comment && (
              <div
                className={`comment ${validation?.status === VALIDATION_STATUS.APPROVED_WITH_COMMENT ? 'approved-with-comment' : ''}`}
              >
                {validation?.comment}
              </div>
            )}

            {validation?.attachments && validation?.attachments?.length > 0 && (
              <Flex wrap gap={8}>
                {validation.attachments.map((attachment) => {
                  return (
                    <div className='attachment' key={attachment?.filePath}>
                      <FilePdfOutlined color='primary' className='color-primary' />

                      <Typography.Link
                        onClick={() => {
                          fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
                        }}
                      >
                        {`${attachment?.name}.pdf`}
                      </Typography.Link>
                    </div>
                  );
                })}
              </Flex>
            )}

            {validation?.validationPictures ? (
              <div className='mt-2'>
                <AttachmentList hideDelete={true} attachmentPhotos={mappedPhotos} />
              </div>
            ) : null}
          </>
        ),
      };
    });
  }, [props.baseIssues]);
  const controlStatusTabData = [
    ...controlStatus,
    {
      className: `ant-steps-item-${VALIDATION_STATUS.SUBMITTED}`,
      icon: getStatusNameAndIcon(VALIDATION_STATUS.SUBMITTED || '')?.icon,
      description: (
        <>
          <Typography.Title className='company-name font-weight-bold' level={3}>
            {originator?.WorkspaceUser?.Company?.name}
          </Typography.Title>
          <Space className='user-information' size={4}>
            <Typography.Text className='status'>
              {getStatusNameAndIcon(VALIDATION_STATUS.SUBMITTED || '')?.name}
            </Typography.Text>
            <Typography.Text className='text-color-polaris-black'>by</Typography.Text>
            <Typography.Text className='username'>{originator?.fullName}</Typography.Text>
            <Typography.Text className={`date`}>
              {dayjs(props.baseIssues?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
            </Typography.Text>
          </Space>
        </>
      ),
    },
  ];

  const historyTabData: CollapseProps['items'] = [
    {
      key: '1',
      label: t('History'),
      children: (
        <Flex wrap='wrap' vertical>
          {mockPTWHistory.map((groupHistories, index) => {
            return (
              <Flex wrap='wrap' vertical key={index}>
                <Space>
                  <Tag icon={<ClockCircleFilled />} className='timeline-time-tag'>
                    {moment(groupHistories.createdAt).format('DD/MM/YYYY')}
                  </Tag>
                </Space>
                <Flex wrap='wrap' vertical className='ml-6'>
                  <Timeline mode='left' style={{ marginTop: '20px' }}>
                    {groupHistories.history.map((item) => {
                      const userCreatedByHistory = props.users.find((user) => user.id === item.userCreatedId);
                      let status = '';
                      let actionDescribe = '';
                      let description = '';
                      switch (item.action) {
                        case 'uploaded-attachment':
                          actionDescribe = 'The Reference Documents has been uploaded by';
                          break;
                        case 'uploaded-photo':
                          actionDescribe = 'The Pictures has been uploaded by';
                          break;
                        case 'transferred-the-inspection':
                          actionDescribe = 'The Inspector has been changed by';
                          break;
                        case 'deleted-description':
                          actionDescribe = 'The Work Inspection Description has been deleted by';
                          description =
                            'This form is used by the contractor to request an inspection of the completed construction work to ensure quality and compliance with standards.';
                          status = 'was deleted';
                          break;
                        case 'changed-discipline':
                          actionDescribe = 'The Discipline has been changed by';
                          break;

                        default:
                          break;
                      }
                      return (
                        <Timeline.Item key={item.id} dot={<img src={IconDot} alt='history-timeline' width='8px' />}>
                          <div style={{ marginLeft: '10px', width: '100%' }}>
                            <Flex wrap='wrap'>
                              <Flex vertical>
                                <Flex wrap='wrap'>
                                  <Typography.Text className='mr-1' italic>
                                    {t(actionDescribe)}
                                  </Typography.Text>
                                  <strong className='mr-1 font-weight-bold'>{userCreatedByHistory?.fullName}</strong>
                                  <Typography.Text italic>{t('at')}</Typography.Text>
                                  <Typography.Text className='ml-2 text-grey'>
                                    {moment(item.createdAt).format('HH:mm:ss')}
                                  </Typography.Text>
                                </Flex>

                                {item.action === 'deleted-description' && (
                                  <Flex>
                                    <Typography.Text italic>
                                      {`"${t(description)}"`}
                                      <Typography.Text className='ml-1 text-grey' italic>
                                        {t(status)}
                                      </Typography.Text>
                                    </Typography.Text>
                                  </Flex>
                                )}

                                {item.action === 'transferred-the-inspection' && (
                                  <Flex>
                                    <Typography.Text>
                                      <Typography.Text className='mr-1 text-grey' italic>
                                        {t('From')}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1' italic>
                                        {`"${t('John DOE')} ${t('of')} ${t('Elec Builder')} ${t('company')}"`}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1 text-grey' italic>
                                        {t('to')}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1' italic>
                                        {`"${t('John Jayson')} ${t('of')} ${t('Elec Builder')} ${t('company')}"`}
                                      </Typography.Text>
                                    </Typography.Text>
                                  </Flex>
                                )}
                                {item.action === 'changed-discipline' && (
                                  <Flex>
                                    <Typography.Text>
                                      <Typography.Text className='mr-1 text-grey' italic>
                                        {t('From')}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1' italic>
                                        {`"${t('GENERAL')}"`}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1 text-grey' italic>
                                        {t('to')}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1' italic>
                                        {`"${t('SUBSTRUCTURE')}"`}
                                      </Typography.Text>
                                    </Typography.Text>
                                  </Flex>
                                )}
                                {item.action === 'uploaded-photo' && item.Attachment && (
                                  <Flex>
                                    <ImageItem className='history-image' src={item.Attachment.filePath} alt='Image' />
                                  </Flex>
                                )}
                                {item.action === 'uploaded-attachment' && item.Attachment && (
                                  <Flex>
                                    <div className='history-attachment' key={item.Attachment?.filePath}>
                                      <FilePdfOutlined color='primary' className='color-primary' />
                                      <Typography.Link
                                        onClick={() => {
                                          fileService.downloadFile(
                                            item.Attachment?.filePath || '',
                                            `${item.Attachment?.name}.pdf`
                                          );
                                        }}
                                      >
                                        {`${item.Attachment?.name}.pdf`}
                                      </Typography.Link>
                                    </div>
                                  </Flex>
                                )}
                              </Flex>
                            </Flex>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      ),
    },
  ];

  const commentsTabData: CollapseProps['items'] = [
    {
      key: '2',
      label: t('Comments'),
      children: (
        <Flex vertical className='pa-5'>
          {mockPTWHistory.map((item, index) => {
            const userCreatedByHistory = props.users.find((user) => user.id === item.userCreatedId);
            return (
              <Timeline mode='left' style={{ marginTop: '20px' }} key={index}>
                <Timeline.Item
                  dot={
                    userCreatedByHistory?.profilePhoto ? (
                      <AvatarItem size={32} src={userCreatedByHistory?.profilePhoto} />
                    ) : (
                      <Avatar size={32}>{userCreatedByHistory?.firstName?.substring(0, 1)}</Avatar>
                    )
                  }
                >
                  <Flex vertical>
                    <Flex justify='space-between'>
                      <Typography.Text>
                        <strong className='mr-1 font-weight-bold'>{userCreatedByHistory?.fullName}</strong>
                        <Typography.Text className='text-grey' italic>
                          {t('left the comment')}
                        </Typography.Text>
                      </Typography.Text>
                      <Typography.Text className='text-grey'>
                        {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </Typography.Text>
                    </Flex>
                    <Flex wrap='wrap' className='mb-2 '>
                      <Typography.Text className='text-grey text-size-12'>
                        {userCreatedByHistory?.WorkspaceUser?.Company?.name}
                      </Typography.Text>
                    </Flex>
                    <Flex wrap='wrap'>
                      <Typography.Text>{item.comment}</Typography.Text>
                    </Flex>
                  </Flex>
                </Timeline.Item>
              </Timeline>
            );
          })}
        </Flex>
      ),
    },
  ];
  return (
    <div className='validation-history-container'>
      <Steps direction='vertical' items={controlStatusTabData} labelPlacement='vertical' className='mt-6' />
      <Divider orientation='left'>{t('Control History')}</Divider>
      <Collapse defaultActiveKey={['1']} items={historyTabData} className='mt-6' />
      <Collapse defaultActiveKey={['2']} items={commentsTabData} className='mt-6' />
    </div>
  );
};

export default PermitToWorkHistory;
