export enum HiddenFeatures {
  DOC_HUB = 'DOC_HUB',
  MAR = 'MAR',
  WIR = 'WIR',
  MDI = 'MDI',
  MS = 'MS',
  SD = 'SD',
  ASSET = 'ASSET',
  OTP = 'OTP',
  TOOLBOX = 'TOOLBOX',
  NOTIFICATION = 'NOTIFICATION',
  DOCS_MANAGEMENT = 'DOCS_MANAGEMENT',
  CONSTRUCTION_MANAGEMENT = 'CONSTRUCTION_MANAGEMENT',
  MODULE_FINANCE = 'MODULE_FINANCE',
  EQL = 'EQL',
  ITP = 'ITP',
  WBS = 'WBS',
  NCR = 'NCR',
  HZ = 'HZ',
  SFV = 'SFV',
  NM = 'NM',
  ACD = 'ACD',
  MOM = 'MOM',
  LETTERS = 'LETTERS',
  PTW = 'PTW',
  RFI = 'RFI',
}

const envService = {
  isFeatureHidden: (feature: HiddenFeatures): boolean =>
    !!process.env.REACT_APP_HIDDEN_FEATURES &&
    process.env.REACT_APP_HIDDEN_FEATURES.split(',')
      .map((f) => f.trim().toUpperCase())
      .includes(feature),
};

export default envService;
