import { Alert, Checkbox, CheckboxProps, Collapse, Flex, Space, Spin, Tag, Typography, UploadFile } from 'antd';
import { Design, Zone } from 'model';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import { QueryParams } from 'types';
import { DesignResponse, RawDesignForm } from 'modules/DocumentManagement/models/Design';
import DesignsListView from './DesignsListView';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import { t } from 'i18next';

type DesignListViewProps = {
  disciplines: { discipline: string }[];
  discLoading: boolean;

  onFilter: (value: QueryParams) => void;
  designTableData: DesignResponse;
};
type DesignsDisciplineListViewProps = {
  setDrawerManagingRevision: Dispatch<SetStateAction<{ open: boolean; design?: Design; fromListView?: boolean }>>;
  maxRowAmount: number;
  openPDFNewTab: (design: Design) => void;
  setDesignTablesSelectedKeys: Dispatch<SetStateAction<{ [discipline: string]: Key[] }>>;
  designTablesSelectedKeys: { [discipline: string]: Key[] };
  singleDownload: (filename: string, designId?: string) => void;
  setDrawerEditingDesign: (design: Design) => void;
  showDrawer: (editing: boolean) => void;
  setIsDelete: Dispatch<
    SetStateAction<{
      open: boolean;
      designId?: string;
      discipline?: string;
      initialRevisionId?: string;
    }>
  >;
  projectId: string;
  workspaceId: string;
  orderParams: { [discipline: string]: string };
  setOrderParams: Dispatch<SetStateAction<{ [discipline: string]: string }>>;
  paginationParams: { [discipline: string]: QueryParams };
  setPaginationParams: Dispatch<SetStateAction<{ [discipline: string]: QueryParams }>>;
  revisionFileList: UploadFile[];
  setRevisionFileList: Dispatch<SetStateAction<UploadFile[]>>;
  revisionList: Design[];
  setRevisionList: Dispatch<SetStateAction<Design[]>>;
  getRevisions: (workspaceId: string, projectId: string, designId: string) => Promise<Design[]>;
  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<
    {
      id: string;
      hasDuplicate: boolean;
    }[]
  >;
  unpublishedModalDesigns: Design[];
  setUnpublishedModalDesigns: Dispatch<SetStateAction<Design[]>>;
  setUnpublishedModalIsOpen: Dispatch<SetStateAction<boolean>>;
  beforeUpload: (file: UploadFile) => Promise<boolean>;
  module: string;
  designRegisterPhases?: DesignRegisterPhase[];
  zones: Zone[];
};

export default function DesignsDisciplineListView(props: DesignListViewProps & DesignsDisciplineListViewProps) {
  const {
    beforeUpload,
    setUnpublishedModalIsOpen,
    revisionList,
    setRevisionList,
    setDrawerManagingRevision,
    discLoading,
    disciplines,
    setDesignTablesSelectedKeys,
    designTablesSelectedKeys,
    maxRowAmount,
    openPDFNewTab,
    singleDownload,
    setDrawerEditingDesign,
    showDrawer,
    setIsDelete,
    projectId,
    workspaceId,
    designTableData,
    orderParams,
    paginationParams,
    setPaginationParams,
    revisionFileList,
    setRevisionFileList,
    getRevisions,
    setOrderParams,
    checkDuplicate,
    unpublishedModalDesigns: unpublishedModal,
    setUnpublishedModalDesigns: setUnpublishedModal,
    module,
    designRegisterPhases,
    zones,
  } = props;

  const [allDisciplineRowsCount, setAllDisciplineRowsCount] = useState<number>(0);
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);

  useEffect(() => {
    setAllDisciplineRowsCount(
      designTableData
        ? Object.values(designTableData)
            .map((d) => d.count)
            .reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
            }, 0)
        : 0
    );
  }, [designTableData]);

  useEffect(() => {
    setSelectedRowsCount(Object.values(designTablesSelectedKeys).flat().length);
  }, [designTablesSelectedKeys, allDisciplineRowsCount]);

  const onCheckAllDisciplinesRowsChange: CheckboxProps['onChange'] = (e) => {
    const disciplineKeys = Object.keys(designTableData);
    if (!e.target.checked) {
      const allDesignTablesUnSelected: { [discipline: string]: Key[] } = {};
      disciplineKeys.forEach((discipline) => {
        if (designTableData[discipline].count > 0) {
          allDesignTablesUnSelected[discipline] = [];
        }
      });
      setDesignTablesSelectedKeys(allDesignTablesUnSelected);
      return;
    }
    const allDesignTablesSelected: { [discipline: string]: Key[] } = {};
    disciplineKeys.forEach((discipline) => {
      if (designTableData[discipline].count > 0) {
        allDesignTablesSelected[discipline] = designTableData[discipline].rows.map((r) => r.id);
      }
    });
    setDesignTablesSelectedKeys(allDesignTablesSelected);
  };

  const setNestedSelectedKeys = (discipline: string, data: Key[]) => {
    setDesignTablesSelectedKeys({ ...designTablesSelectedKeys, [discipline]: data });
  };
  const setNestedOrderParams = (discipline: string, disciplineParams: string) => {
    setOrderParams({ ...orderParams, [discipline]: disciplineParams });
  };
  const setNestedPaginationParams = (discipline: string, disciplinePaginationParams: QueryParams) => {
    setPaginationParams({ ...paginationParams, [discipline]: disciplinePaginationParams });
  };

  const renderCollapsableHeader = (discipline: string, count: number) => {
    return (
      <Flex className='tag-box' align='center'>
        <span style={{ flex: 1, fontWeight: 'bold' }}>{discipline}</span>
        <Space className='tag-list' size={'small'}>
          <Tag className={module} key={discipline}>
            {count}
          </Tag>
        </Space>
      </Flex>
    );
  };

  return (
    <div className={`dynamic-collapse-table ${module}`}>
      {!discLoading && (!designTableData || Object.values(designTableData).every((d) => d.count === 0)) && (
        <Alert
          message={t(`No results. Update filters or create a new document by clicking on the 'Add Documents' button`)}
          type='warning'
          showIcon
        />
      )}
      {!discLoading && designTableData && Object.values(designTableData).some((d) => d.count > 0) && (
        <Spin spinning={discLoading}>
          <Typography.Title className='title'>{t('Discipline')}</Typography.Title>
          <Checkbox
            style={{ marginBottom: '8px' }}
            indeterminate={selectedRowsCount > 0 && selectedRowsCount < allDisciplineRowsCount}
            onChange={onCheckAllDisciplinesRowsChange}
            checked={selectedRowsCount > 0 && selectedRowsCount === allDisciplineRowsCount}
          >
            <Typography.Text className='text-size-14'>{t('Select all')}</Typography.Text>
          </Checkbox>
          <Collapse ghost>
            {[...disciplines]
              .filter((d) => designTableData?.[d.discipline]?.count)
              ?.map((d) => {
                return (
                  <Collapse.Panel
                    header={renderCollapsableHeader(d.discipline, designTableData?.[d.discipline]?.count)}
                    key={d.discipline}
                    showArrow={true}
                  >
                    {designTableData?.[d.discipline]?.count && (
                      <DesignsListView
                        beforeUpload={beforeUpload}
                        unpublishedModalDesigns={unpublishedModal}
                        setUnpublishedModalDesigns={setUnpublishedModal}
                        checkDuplicate={checkDuplicate}
                        setRevisionList={setRevisionList}
                        setRevisionFileList={setRevisionFileList}
                        revisionFileList={revisionFileList}
                        setDrawerManagingRevision={setDrawerManagingRevision}
                        maxRowAmount={maxRowAmount}
                        setNestedSelectedKeys={setNestedSelectedKeys}
                        designTablesSelectedKeys={designTablesSelectedKeys}
                        openPDFNewTab={openPDFNewTab}
                        singleDownload={singleDownload}
                        setDrawerEditingDesign={setDrawerEditingDesign}
                        showDrawer={showDrawer}
                        setIsDelete={setIsDelete}
                        projectId={projectId}
                        data={designTableData}
                        workspaceId={workspaceId}
                        key={d.discipline}
                        discipline={d.discipline}
                        setNestedOrderParams={setNestedOrderParams}
                        setNestedPaginationParams={setNestedPaginationParams}
                        getRevisions={getRevisions}
                        revisionList={revisionList}
                        setUnpublishedModalIsOpen={setUnpublishedModalIsOpen}
                        expanded={false}
                        designRegisterPhases={designRegisterPhases}
                        zones={zones}
                      />
                    )}
                  </Collapse.Panel>
                );
              })}
          </Collapse>
        </Spin>
      )}
    </div>
  );
}
