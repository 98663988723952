import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Flex, ModalProps, message } from 'antd';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationProps = {
  isModalOpen: boolean;
  title: string;
  description: string;
  onDelete: () => void;
  onCancel: () => void;
  cantUndo?: boolean;
  relation?: boolean;
} & ModalProps;
export default function DeleteConfirmation(props: DeleteConfirmationProps) {
  const { isModalOpen, title, description, onCancel, onDelete, cantUndo, relation } = props;
  const { t } = useTranslation();

  const handleOk = async () => {
    try {
      if (!relation) {
        onDelete();
        message.success(t('Delete success!'));
      } else {
        onCancel();
        message.error(t('MAR cannot be deleted as it is associated with a MDI!'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal className='delete-confirmation' open={isModalOpen} closable={false} footer={null}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <p className='mb-4 title'>
          <ExclamationCircleOutlined style={{ color: 'red' }} /> {title}
        </p>
      </div>
      <p className='mb-4 description' style={{ justifyContent: 'center' }}>
        {description}
      </p>
      {cantUndo && (
        <Flex justify='space-around' style={{ marginBottom: '2em', color: '#aaa' }}>
          {t('You cannot undo this action')}
        </Flex>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div>
          <Button key='back' onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button danger key='submit' onClick={handleOk}>
            {t('Delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
