import { CSSProperties, useEffect, useState } from 'react';
import { Collapse, CollapseProps, Flex, theme } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { KpiStatistic } from 'components/common';
import { IssueStatus } from 'model';
import { ISSUE_STATUSES } from 'utils/contants';

type KpiData = {
  draft: number;
  open: number;
  closed: number;
  readyForInspection: number;
  readyForInspectionOverdue: number;
  openOverdue: number;
};
type InspectionsStatisticProps = {
  handleFilterByKPI: (value: string) => void;
  kpiData: KpiData;
  issueStatuses: IssueStatus[];
  isResetKPI: boolean;
  setResetKPI: (value: boolean) => void;
};

export default function InspectionsStatistic(props: InspectionsStatisticProps) {
  const { kpiData, handleFilterByKPI, isResetKPI, setResetKPI } = props;
  const [activeKpi, setActiveKpi] = useState<string | null>(null);
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const onApplyFilter = (code: string) => {
    if (activeKpi === code) {
      setActiveKpi(null);
      handleFilterByKPI('');
    } else {
      setActiveKpi(code);
      handleFilterByKPI(code);
    }
  };
  useEffect(() => {
    if (isResetKPI) {
      setActiveKpi(null);
      setResetKPI(false);
    }
  }, [isResetKPI]);

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
      {
        key: '1',
        children: (
          <Flex wrap='wrap' gap={20}>
            <KpiStatistic
              onClick={() => onApplyFilter(ISSUE_STATUSES.DRAFT)}
              status='draft'
              value={kpiData?.draft}
              title='Draft'
              isActive={activeKpi === ISSUE_STATUSES.DRAFT}
            />
            <KpiStatistic
              onClick={() => onApplyFilter(ISSUE_STATUSES.OPEN)}
              status='open'
              value={kpiData?.open}
              title='Open'
              isActive={activeKpi === ISSUE_STATUSES.OPEN}
            />
            <KpiStatistic
              isOverdue={true}
              onClick={() => onApplyFilter(ISSUE_STATUSES.OPEN_OVERDUE)}
              status='overdue'
              value={kpiData?.openOverdue}
              title='Open'
              isActive={activeKpi === ISSUE_STATUSES.OPEN_OVERDUE}
            />
            <KpiStatistic
              onClick={() => onApplyFilter(ISSUE_STATUSES.READY_FOR_INSPECTION)}
              status='inspection'
              value={kpiData?.readyForInspection}
              title='Ready for review'
              isActive={activeKpi === ISSUE_STATUSES.READY_FOR_INSPECTION}
            />
            <KpiStatistic
              isOverdue={true}
              onClick={() =>
                onApplyFilter(ISSUE_STATUSES.READY_FOR_INSPECTION_OVERDUE)
              }
              status='inspection-overdue'
              value={kpiData?.readyForInspectionOverdue}
              title='Ready for review'
              isActive={activeKpi === ISSUE_STATUSES.READY_FOR_INSPECTION_OVERDUE}
            />
            <KpiStatistic
              onClick={() => onApplyFilter(ISSUE_STATUSES.CLOSED)}
              status='closed'
              value={kpiData?.closed}
              title='Closed'
              isActive={activeKpi === ISSUE_STATUSES.CLOSED}
            />
          </Flex>
        ),
        style: panelStyle,
      },
    ];
  return (
    <Collapse
      size={'small'}
      className='inspection-collapse ma-0'
      ghost
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretUpOutlined
          rotate={isActive ? 0 : 180}
          style={{
            color: 'rgba(0, 0, 0, 0.45)',
            fontSize: '20px',
            width: '20px',
          }}
        />
      )}
      expandIconPosition='end'
      style={{ background: 'transparent' }}
      items={getItems(panelStyle)}
    />
  );
}
