import { Button, Flex, Form, ModalProps, Steps, UploadFile } from 'antd';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';
import { FireOutlined, InboxOutlined } from '@ant-design/icons';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import { RawDesignForm, UnpublishedDesignForm } from 'modules/DocumentManagement/models/Design';
import EditUnpublishedDesign from './EditUnpublishedDesign';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from 'dayjs';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import { Zone } from 'model';

type DesignUploadModalProps = {
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  workspaceId: string;
  projectId: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  fileRegex: RegExp;
  fileRegexToString: string;
  handleUpload: (fileList: UploadFile[], designList: UnpublishedDesignForm[], draft: boolean) => void;
  titleStep2: string;
  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<
    {
      id: string;
      hasDuplicate: boolean;
    }[]
  >;
  beforeUpload: (file: UploadFile) => Promise<boolean>;
  disciplines: { discipline: string }[];
  disciplineMapIdToName: Map<string, string>;
  designRegisterPhases?: DesignRegisterPhase[];
  zones: Zone[];
} & ModalProps;

export default function DesignUploadModal(props: DesignUploadModalProps) {
  const {
    beforeUpload,
    fileList,
    setFileList,
    isModalOpen,
    setIsModalOpen,
    fileRegex,
    fileRegexToString,
    handleUpload,
    projectId,
    workspaceId,
    titleStep2,
    checkDuplicate,
    disciplines,
    disciplineMapIdToName,
    designRegisterPhases,
    zones,
  } = props;

  const { t } = useTranslation();
  const [designFormList, setDesignFormList] = useState<UnpublishedDesignForm[]>([]);
  const [uploadButtonActive, setUploadButtonActive] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const [modalWidth, setModalWidth] = useState('50%');
  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const [draftLoading, setDraftLoading] = useState<boolean>(false);
  const [infoIsValid, setInfoIsValid] = useState<boolean>(true);
  const [hasAttemptedUpload, setHasAttemptedUpload] = useState<boolean>(false);
  const [titleRevisionErrors, setTitleRevisionErrors] = useState<{
    [key: string]: boolean;
  }>({});
  const [duplicateTitleFieldRuleEnabled, setDuplicateTitleFieldRuleEnabled] = useState<boolean>(true);
  const [duplicateIdsPerGroup, setDuplicateIdsPerGroup] = useState<{ [key: string]: string[] }>({});
  const [selectedUnpublished, setSelectedUnpublished] = useState<Key[]>([]);
  const [fieldErrors, setFieldErrors] = useState<Array<object>>([]);

  const [form] = Form.useForm();

  const customValidateFields = async () => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setInfoIsValid(true);
      })
      .catch(() => {
        setInfoIsValid(false);
      });
  };
  useEffect(() => {
    const filesWithErrors = fileList.filter((fileToKeep) => {
      const uf = fileToKeep as UploadFile;
      return uf.status === 'error';
    });
    setUploadButtonActive(filesWithErrors.length === 0 && fileList.length !== 0);
  }, [fileList]);

  const nextPage = () => {
    setCurrent(current + 1);
  };
  //DEFAULT TABLE VALUES
  const createDesignFormTemplate = (fileTitle: string, uid: string, fileExtension?: string): UnpublishedDesignForm => {
    return {
      title: fileTitle,
      revision: '',
      discipline: '',
      phase: designRegisterPhases?.[0].id,
      issueDate: '',
      area: '',
      deadlineDate: '',
      status: 'uploading',
      documentType: 'drawing',
      fileExtension: fileExtension ?? 'null',
      id: uid,
    };
  };

  const generateTableData = () => {
    const newDesignFormList: UnpublishedDesignForm[] = fileList.map((file) => {
      const dotIndex = file.name.lastIndexOf('.');
      const fileExtension = file.name.slice(dotIndex + 1);
      const fileTitle = file.name.slice(0, dotIndex);
      return createDesignFormTemplate(fileTitle, file.uid, fileExtension);
    });
    setDesignFormList(newDesignFormList);
    return newDesignFormList;
  };

  const dragger = (
    <Dragger
      name='file'
      multiple={true}
      accept='application/pdf'
      showUploadList={true}
      beforeUpload={beforeUpload}
      fileList={fileList}
      onChange={({ fileList: newFileList }) => {
        setFileList(newFileList);
      }}
    >
      <div>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
        <p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
      </div>
    </Dragger>
  );
  const findGroupDuplicates = (items: RawDesignForm[]) => {
    const IDsPerTitleRev = items.reduce((groups: { [key: string]: string[] }, item: RawDesignForm) => {
      const key = `${item.title}-${item.revision}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item.id);
      return groups;
    }, {});
    const tempDuplicateIdsPerGroup: { [key: string]: string[] } = {};
    Object.keys(IDsPerTitleRev).forEach((item) => {
      if (IDsPerTitleRev[item].length > 1) {
        tempDuplicateIdsPerGroup[item] = IDsPerTitleRev[item];
      }
    });
    setDuplicateIdsPerGroup(tempDuplicateIdsPerGroup);
  };

  const editUnpublishedDesign = (
    <EditUnpublishedDesign
      fieldErrors={fieldErrors}
      setFieldErrors={setFieldErrors}
      duplicateTitleFieldRuleEnabled={duplicateTitleFieldRuleEnabled}
      customValidateFields={customValidateFields}
      hasAttemptedUpload={hasAttemptedUpload}
      titleRevisionErrors={titleRevisionErrors}
      setTitleRevisionErrors={setTitleRevisionErrors}
      form={form}
      designs={designFormList}
      selectedUnpublished={selectedUnpublished}
      setSelectedUnpublished={setSelectedUnpublished}
      useBulkEdit={false}
      fileRegex={fileRegex}
      fileRegexToString={fileRegexToString}
      duplicateIdsPerGroup={duplicateIdsPerGroup}
      setDuplicateIdsPerGroup={setDuplicateIdsPerGroup}
      disciplines={disciplines}
      disciplineMapIdToName={disciplineMapIdToName}
      type='upload'
      users={[]}
      projectId={projectId}
      isOpen={isModalOpen}
      designRegisterPhases={designRegisterPhases}
      zones={zones}
    />
  );
  const buttonOnClickPage_1 = async () => {
    form.resetFields();
    generateTableData();
    setModalWidth('75%');
    nextPage();
    setInfoIsValid(true);
  };
  const buttonOnClickPage_2 = async (draft: boolean) => {
    selectedUnpublished.forEach((id) => {
      form.setFields([
        {
          name: ['columns', id, 'revision'],
          touched: false,
        },
      ]);
    });
    setHasAttemptedUpload(true);
    const designIds = Object.keys(form.getFieldsValue()['columns']);
    setFieldErrors(form.getFieldsError());
    const fieldsValue = form.getFieldsValue()['columns'];
    const updatedDesignFormList: UnpublishedDesignForm[] = designIds.map((id) => ({
      ...fieldsValue[id],
      id: id,
      issueDate: fieldsValue[id].issueDate ? fieldsValue[id].issueDate.$d.toISOString() : dayjs(),
      deadlineDate: fieldsValue[id].deadlineDate ? fieldsValue[id].deadlineDate.$d.toISOString() : undefined,
    }));
    const selectedUploadFiles = fileList.filter((file) => selectedUnpublished.includes(file.uid));
    const selectedDesignFormList = updatedDesignFormList.filter((design) => selectedUnpublished.includes(design.id));
    const titleRevisionData: RawDesignForm[] = selectedDesignFormList.map((item) => ({
      title: item.title.trim(),
      revision: item.revision.trim(),
      id: item.id,
    }));
    findGroupDuplicates(titleRevisionData);
    try {
      await customValidateFields();
      if (draft) {
        setDraftLoading(true);
      } else {
        setPublishLoading(true);
      }
      checkDuplicate(
        workspaceId,
        projectId,
        titleRevisionData.filter((item) => {
          return selectedUnpublished.includes(item.id);
        })
      ).then((response) => {
        const tempErrors: { [key: string]: boolean } = titleRevisionErrors;
        response.map((validation) => (tempErrors[validation.id] = validation.hasDuplicate));
        setTitleRevisionErrors(tempErrors);

        form
          .validateFields()
          .then(() => {
            handleUpload(selectedUploadFiles, selectedDesignFormList, draft);
            setDuplicateIdsPerGroup({});
            setSelectedUnpublished([]);
            setHasAttemptedUpload(false);
            setTitleRevisionErrors({});
            setFileList([]);
            setDraftLoading(false);
            setPublishLoading(false);
            setModalWidth('25%');
            nextPage();
          })
          .catch(() => {
            setInfoIsValid(false);
            setDraftLoading(false);
            setPublishLoading(false);
            form.validateFields();
          });
      });
    } catch (e) {
      console.error(e);
      setDraftLoading(false);
      setPublishLoading(false);
    }
  };
  const buttonOnClickPage_3 = () => {
    setUploadButtonActive(false);
    setIsModalOpen(false);
    setCurrent(0);
  };

  const items = [
    {
      title: t('Select Files'),
      key: 'page-1',
      content: dragger,
    },
    {
      title: t(titleStep2),
      key: 'page-2',
      content: editUnpublishedDesign,
    },
    {
      title: t('Upload'),
      key: 'page-3',
      content: (
        <Flex justify='center' align='center' vertical>
          <FireOutlined
            style={{
              marginBottom: '0.4em',
              fontSize: 34,
              color: '#FFAD39',
            }}
          />
          <p>
            {t(`Your files are uploading, you can close this window and resume
            your work.`)}
          </p>
        </Flex>
      ),
    },
  ];

  const handleCancel = () => {
    setDuplicateIdsPerGroup({});
    setInfoIsValid(false);
    setIsModalOpen(false);
    setSelectedUnpublished([]);
    setDesignFormList([]);
    setFileList([]);
    setCurrent(0);
    setModalWidth('50%');
    form.resetFields();
    setHasAttemptedUpload(false);
  };

  const footer_1 = (
    <Flex key='back' justify='space-between'>
      <div></div>
      <Flex justify='right'>
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button
          disabled={!uploadButtonActive}
          onClick={buttonOnClickPage_1}
          loading={publishLoading}
          key='submit'
          type='primary'
        >
          {t('Next')}
        </Button>
      </Flex>
    </Flex>
  );
  const footer_2 = (
    <Flex key='back' justify='space-between'>
      <Flex className='invisible-field' style={{ fontWeight: 'bold' }}>
        {`${selectedUnpublished.length}/${fileList.length}` + t(`Files to upload`)}
      </Flex>
      <Flex justify='right'>
        {!infoIsValid && hasAttemptedUpload && (
          <p style={{ color: 'red', padding: '5px', paddingRight: '10px' }}>
            {t('At least one field contains an error')}
          </p>
        )}
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button
          loading={draftLoading}
          onClick={() => {
            setDuplicateTitleFieldRuleEnabled(false);
            buttonOnClickPage_2(true);
          }}
          type='primary'
        >
          {t('Upload as Unpublished')}
        </Button>
      </Flex>
    </Flex>
  );
  const footer_3 = (
    <Flex key='back' justify='space-between'>
      <div></div>
      <Flex justify='right'>
        <Button onClick={buttonOnClickPage_3} loading={publishLoading} key='submit' type='primary'>
          {t('Close')}
        </Button>
      </Flex>
    </Flex>
  );

  const footers = [footer_1, footer_2, footer_3];

  return (
    <Modal
      title={`${t('Upload files')}`}
      open={isModalOpen}
      onCancel={handleCancel}
      width={modalWidth}
      classNames={{ body: 'upload-photo-modal' }}
      footer={[footers[current]]}
    >
      <Steps current={current} items={items} />
      <div style={{ marginTop: '3em' }}> {items[current].content}</div>
    </Modal>
  );
}
