import { useRef } from 'react';
import PieChart from './features/PieChart';
import { IssueByPriority } from 'model';


type GraphByPriorityProps = {
  issueByPriority: IssueByPriority[];
  loading?: any;
};
export const GraphByPriority = (props: GraphByPriorityProps) => {
  const { issueByPriority, loading } = props;
  const svgLargeRef: any = useRef<SVGSVGElement>();

  const fillColors = [
    { code: 'HIGH', color: 'rgba(215, 38, 61, 0.7)', },
    { code: 'MEDIUM', color: 'rgba(243, 175, 61, 0.7)', },
    { code: 'LOW', color: 'rgba(42, 100, 246, 0.7)', },
  ]
  let chartData: any[] = [];
  if (issueByPriority && issueByPriority.length > 0) {
    chartData = issueByPriority.map((item, index) => {
      const color = fillColors.find((color) => color.code === item.code)?.color
      return {
        name: item.name,
        count: item.count,
        fillColor: color
      };
    });
  }

  return (
    <div ref={svgLargeRef} className="w-12/12 h-72">
      <PieChart data={chartData} svgWrapperRef={svgLargeRef} padding={10} loading={loading} />
    </div>
  );
};

