// import { ClockCircleFilled, LockFilled } from '@ant-design/icons';
import {
  ListViewKpiStatistic,
  ListViewLayout,
  PermitToWorkForm,
  PermitToWorkListsView,
  PTWTableData,
} from 'components';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/DisciplineBaseIssueTable';
import {
  useInspectionTestPlain,
  useInspectionTestPlanParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { useFetchProjectDisciplineByFunction, useFetchProjectDisciplineByFunctionParams } from 'hooks/base-issue';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import useAuthContext from 'store/auth-context';
import { EIncidentEntityName } from 'types/incident-form';
import { ISSUE_STATUSES, MODULE_FUNCTIONS } from 'utils/contants';

export default function PermitToWork(props: { module: string; workspaceId: string; projectId: string }) {
  const { module, workspaceId, projectId } = props;
  const { profile } = useAuthContext();
  const [selectedId, setSelectedId] = useState<string>('');
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [selectedKpiCode, setSelectedKpiCode] = useState<string | null>(null);
  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(queryUser);
  const [companyParams] = useProjectCompaniesParams({ projectId, workspaceId, orderBy: 'name' });
  const [companies] = useProjectCompanies(companyParams);
  const [issueStatusesQuery] = useIssueStatusesParams({ orderBy: 'orderNumber', moduleFunction: MODULE_FUNCTIONS.WIR });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [zonesParams] = useProjectZonesParams({ projectId, workspaceId, orderBy: 'name' });
  useProjectZones(zonesParams);
  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId,
    workspaceId,
    orderBy: 'order',
    mainPhase: true,
  });
  useWorkBreakdownStructures(workBreakdownStructureQuery);
  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId,
    workspaceId,
    include: 'TemplateAttachment|SpecAttachment',
  });
  useInspectionTestPlain(inspectionTestPlanParams);
  const disciplineBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);
  const [ProjectDisciplineByFunctionParams] = useFetchProjectDisciplineByFunctionParams({
    workspaceId,
    projectId,
    type: 'WIR',
  });
  const [, , refreshDisciplineByFunction] = useFetchProjectDisciplineByFunction(ProjectDisciplineByFunctionParams);
  const [projectBaseIssueParams, setProjectParams] = useState<Record<string, string | number | undefined>>({
    projectId: projectId as string,
    limit: 10,
    page: 1,
    workspaceId,
    module,
    type: 'WIR',
    include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
  });
  useEffect(() => {
    const issueStatusId = issueStatuses.find((status) => status.code === selectedKpiCode)?.id;
    setProjectParams({
      ...projectBaseIssueParams,
      'statusId[]': issueStatusId ? `[${'"' + issueStatusId + '"'}]` : undefined,
    });
  }, [selectedKpiCode, issueStatuses.length]);

  const handleRefreshDiscipline = (disciplineId?: string) => {
    disciplineBaseIssueTableRef.current?.refreshData(disciplineId, selectedId);
  };

  const kpiData = {
    [ISSUE_STATUSES.DRAFT]: { value: 125 },
    [ISSUE_STATUSES.REVIEW_IN_PROGRESS]: { value: 78 },
    [ISSUE_STATUSES.APPROVED]: { value: 19 },
    [ISSUE_STATUSES.APPROVED_WITH_COMMENT]: { value: 19 },
    [ISSUE_STATUSES.REJECTED]: { value: 19 },
    [ISSUE_STATUSES.CLOSED_END]: { value: 125 },
    [ISSUE_STATUSES.OPEN_START]: { value: 213 },
  };

  const handleMenuClick = (type: string, data: PTWTableData) => {
    switch (type) {
      case 'selected-row':
      case 'edit':
        setSelectedId(data.id);
        setIsFormOpen(true);
        break;
      case 'delete':
        break;
      default:
        break;
    }
  };

  return (
    <ListViewLayout
      addBtnProps={{
        onClick: () => {
          setSelectedId('');
          setIsFormOpen(true);
        },
        text: t('Add Permit To Work'),
      }}
      header={t('Permit To Work - PTW')}
    >
      <ListViewKpiStatistic
        kpiData={kpiData}
        handleFilterByKPI={setSelectedKpiCode}
        isResetKPI={false}
        setResetKPI={() => {}}
      />
      <PermitToWorkListsView entityName={EIncidentEntityName.PermitToWork} onActionClick={handleMenuClick} />

      {isFormOpen && (
        <PermitToWorkForm
          module={module}
          profile={profile}
          projectId={projectId}
          workspaceId={workspaceId}
          isOpen={isFormOpen}
          setOpen={setIsFormOpen}
          selectedId={selectedId}
          companies={companies}
          users={users}
          refreshBaseIssues={handleRefreshDiscipline}
          refreshDisciplineByFunction={refreshDisciplineByFunction}
        />
      )}
    </ListViewLayout>
  );
}
