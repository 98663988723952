import { Button, message, ModalProps, Upload } from 'antd';
import { Modal } from 'components';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { attachmentService } from 'services';

const { Dragger } = Upload;

export type UploadPictureData = { imageURL: string; fileName: string; width: number; height: number };

type UploadPictureProps = {
  isModalOpen: boolean;
  isLoading?: boolean;
  setIsLoading: (value: boolean) => void;
  setIsModalOpen: (value: boolean) => void;
  loading?: boolean;
  handleAddPicture: (response: UploadPictureData) => void;
} & ModalProps;

export default function UploadPicture(props: UploadPictureProps) {
  const { isModalOpen, setIsModalOpen, isLoading, setIsLoading, handleAddPicture } = props;
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);

  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOk = async () => {
    if (!image) {
      setIsError(true);
      return;
    }
    try {
      setIsLoading(true);
      const response = await attachmentService.uploadFile(image);
      handleAddPicture(response);
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onBeforeUpload = async (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('You can only upload JPG/PNG file!'));
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Image must smaller than 1MB!'));
      return false;
    }
    const filePreview = await getBase64(file);
    setImage(file);
    setPreviewImage(filePreview as string);
    return false;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setImage(null);
    setPreviewImage(null);
  }, [isModalOpen]);

  return (
    <>
      <Modal
        title={`${t('Upload a photo')}`}
        open={isModalOpen}
        onCancel={handleCancel}
        width={723}
        classNames={{ body: 'upload-photo-modal' }}
        footer={[
          <Button onClick={handleCancel} key='back'>
            {t('Cancel')}
          </Button>,
          <Button disabled={!image} onClick={handleOk} loading={isLoading} key='submit' type='primary'>
            {t('Upload')}
          </Button>,
        ]}
      >
        <Dragger
          name='file'
          multiple={false}
          accept='image/*'
          showUploadList={false}
          className={isError ? 'error' : ''}
          beforeUpload={onBeforeUpload}
        >
          {previewImage ? (
            <div className='preview-container'>
              <img src={previewImage} alt='' />
            </div>
          ) : (
            <>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
              <p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
            </>
          )}
        </Dragger>
      </Modal>
    </>
  );
}
