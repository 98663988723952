import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { UploadDocument } from 'components/common';
import AttachmentDocumentList, { AttachmentDocumentData } from 'components/common/AttachmentDocumentList';
import { mockPTWDetails } from 'mock-data/project-data';
import { Attachment, AttachmentInfo } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import useAuthContext from 'store/auth-context';
import { selectProjectUsers } from 'store/my-projects.slice';
import { ModalState } from 'types';

type PermitToWorkSectionCheckListProps = {
  isReadOnly: boolean;
  selectedBaseIssue: typeof mockPTWDetails | null;
  workspaceId: string;
  projectId: string;
  attachmentsInfo: { id: string; name: string; filePath: string; userCreated: string }[];
  setAttachmentsInfo: (value: { id: string; name: string; filePath: string; userCreated: string }[]) => void;
};
export default function PermitToWorkSectionCheckList(props: PermitToWorkSectionCheckListProps) {
  const { t } = useTranslation();
  const { isReadOnly, selectedBaseIssue, attachmentsInfo, setAttachmentsInfo } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [loading] = useState<boolean>(false);
  const users = useAppSelector(selectProjectUsers);
  const { profile } = useAuthContext();

  useEffect(() => {
    if (selectedBaseIssue) {
      setAttachments(selectedBaseIssue.Attachments);
    }
  }, [selectedBaseIssue]);

  const attachmentResult: AttachmentDocumentData[] = useMemo(() => {
    const newAttachments = attachments?.map((attachment) => ({
      ...attachment,
      userCreated: attachment?.BaseIssueAttachments?.[0]?.userCreated || '',
    }));
    const currentAttachments = selectedBaseIssue ? newAttachments : attachmentsInfo;
    const userIds: string[] = [...new Set(currentAttachments?.map((attachment) => attachment.userCreated))];
    const userList = users?.filter((user) => userIds.includes(user.id));

    return userList.map((user) => {
      const userAttachments = (currentAttachments as AttachmentInfo[])?.filter(
        (attachment) => attachment?.userCreated === user?.id
      );
      const isUploadedUser = user?.id === profile?.id;
      const currentUser = users?.find((user) => user.id === profile?.id);
      const isCompanyOriginator = currentUser?.WorkspaceUser?.Company?.id === selectedBaseIssue?.companyOriginatorId;
      const createdAt = userAttachments[userAttachments.length - 1]?.createdAt || new Date().toISOString();
      return {
        attachmentDocuments: userAttachments?.map((attachment) => {
          return {
            filePath: attachment.filePath,
            fileName: attachment?.name,
            hideDelete: !isCompanyOriginator && !isUploadedUser,
          };
        }),
        userInfo: {
          fullName: user?.fullName,
          firstName: user?.firstName,
          profilePhoto: user?.profilePhoto,
          createdAt: createdAt,
        },
      };
    });
  }, [attachmentsInfo?.length, attachments, selectedBaseIssue, users]);
  console.log(attachmentResult);
  const handleAddDocument = (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => {
    try {
      const data = attachmentsInfo;
      data.push({ id: response.fileUrl, name, filePath: response.fileUrl, userCreated: profile?.id || '' });
      setAttachmentsInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveDocument = (filePath: string) => {
    try {
      const data = [...attachmentsInfo];
      const index = data.findIndex((attachment) => attachment.filePath === filePath);
      data.splice(index, 1);
      setAttachmentsInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Flex align='center' justify='space-between' className='inspection-checklist-box'>
        <Typography.Title className='read-only-mar-title font-weight-bold' level={3}>
          {t('Permit To Work Checklist ')}
        </Typography.Title>
        {!isReadOnly && (
          <Button
            className='document-text-group'
            type='dashed'
            icon={<PlusOutlined />}
            onClick={() => setIsOpenUpload(true)}
          >
            {t('Upload Checklist')}
          </Button>
        )}
        {isOpenUpload !== null && (
          <UploadDocument
            isModalOpen={isOpenUpload}
            setIsModalOpen={(value: boolean) => setIsOpenUpload(value)}
            handleAddDocument={(name: string, response) => handleAddDocument(name, response)}
          />
        )}
      </Flex>
      <Flex gap={16} vertical>
        <AttachmentDocumentList
          isLoading={loading}
          data={attachmentResult}
          hideDelete={props.isReadOnly}
          onRemoveFile={handleRemoveDocument}
        />
      </Flex>
    </>
  );
}
