import { Dropdown, Layout, message, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Company, ProjectCompany } from 'model';
import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  CloseCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { RevokeCompanyConfirmation, TablePagination } from 'components';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { companyService } from 'services';
import useAuthContext from 'store/auth-context';
import { useProjectUsers, useProjectUsersParams } from 'hooks';
import FormItem from 'antd/es/form/FormItem';
import EditCompanyInProject from './EditProjectCompany';

type ProjectCompanyListProps = {
  companies: Company[];
  refreshCompanies: () => void;
  loading?: boolean;
  issuesCount: any;
  projectId: string;
  issueParams: any;
  onFilter: (value: { [key: string]: any }) => void;
  workspaceId?: string;
  projectOwner?: string;
  handleEditCompany: (company: Company) => void;
};

export default function ProjectCompanyList(props: ProjectCompanyListProps) {
  const {
    companies,
    refreshCompanies,
    loading,
    issuesCount,
    issueParams,
    onFilter,
    projectId,
    workspaceId,
    projectOwner,
    handleEditCompany
  } = props;
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  // const [form] = Form.useForm();

  const [isRevoke, setIsRevoke] = useState<boolean>(false);
  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users, loadingUser, refreshUser, isError] = useProjectUsers(queryUser);

  const [isAssignedToCompany, setIsAssignedToCompany] = useState<boolean>(false);
  const [isCompanyCreated, setIsCompanyCreated] = useState<boolean>(true);
  const [isRelatedWorkPackage, setIsRelatedWorkPackage] = useState<boolean>(true);
  const [isRelatedBaseIssues, setIsRelatedBaseIssues] = useState<boolean>(true);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [allCompany, setAllCompany] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const profileUser = (users || []).find((user) => user.id === profile?.id);

  const handleRevokeCompany = async (company: Company) => {
    try {
      setIsLoading(true);
      const [response, allCompany] = await Promise.all([
        companyService.getCompanyInfoToRevoke(workspaceId || '', projectId, company?.id || ''),
        companyService.getProjectCompanies(workspaceId || '', projectId, { limit: 'unlimited' }),
      ])
      allCompany.rows.sort((a, b) => (b?.WorkspaceUsers || []).length - (a?.WorkspaceUsers || []).length);
      setAllCompany(allCompany.rows);
      // Check if company is related to any work package
      setIsRelatedWorkPackage(response.isRelatedWorkPackage);
      // Check if company is related to any MSS/MDI/MAR
      setIsRelatedBaseIssues(response.isRelatedBaseIssues);
      // Check if company was assigned any inspections
      setIsAssignedToCompany(response.isAssignedToCompany);
      // Check if user of company has been created any inspections 
      setIsCompanyCreated(response.isCompanyCreated);
      setSelectedCompany(company?.id || null);
      setIsRevoke(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.error((error as Error).message);
      return;
    }
  };

  const columns: ColumnsType<Company> = [
    {
      sorter: true,
      title: t('Company'),
      dataIndex: 'name',
      key: 'name',
      render: (value) => <span>{value}</span>,
    },
    {
      sorter: true,
      title: t('Code'),
      key: 'companyCode',
      dataIndex: 'companyCode',
      render: (value) => <span>{value}</span>,
    },
    {
      sorter: true,
      title: t('Category'),
      key: 'CompanyCategory.name',
      dataIndex: 'CompanyCategory',
      render: (value) => <span>{value?.name}</span>,
    },
    {
      title: t('Company roles'),
      dataIndex: 'ProjectCompanies',
      width: '15%',
      render: (value: ProjectCompany[]) => (
        <span>
          {value?.[0]?.companyRole && `${value?.[0]?.companyRole?.charAt(0).toUpperCase()}${value?.[0]?.companyRole?.slice(1)}`}
        </span>
      ),
    },
    {
      title: t('Members'),
      dataIndex: 'WorkspaceUsers',
      width: '15%',
      render: (value) => (
        <span>
          {value?.length} {t('member(s)')}
        </span>
      ),
    },
    {
      title: '',
      width: 10,
      align: 'center',
      render: (company: Company) => (
        <Dropdown
          menu={{
            items: [
              {
                label: t('Edit Company'),
                key: '1',
                icon: <EditOutlined />,
                onClick: () => handleEditCompany(company),
              },
              {
                label: t('Invite members'),
                key: '2',
                icon: <UsergroupAddOutlined />,
              },
              {
                label: t('Revoke access'),
                danger: true,
                disabled:
                  company?.id === profileUser?.WorkspaceUser?.Company?.id
                    ? true
                    : false,
                onClick: () => handleRevokeCompany(company),
                key: '3',
                icon: <CloseCircleOutlined />,
              },
            ],
            onClick: (e) => console.log('click', e),
          }}
        >
          <EllipsisOutlined className='cursor-pointer' />
        </Dropdown>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Company[]) => {
      console.log(`newSelectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: Company) => ({
      name: record.name,
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys: any[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys: any[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Company> | SorterResult<Company>[] | any
  ) => {
    if (sorter) {
      const sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        orderBy = `${sorter.columnKey},${sorterOrder}`;
      }
      onFilter({ orderBy });
    }
  };
  return (
    <Spin spinning={isLoading}>
      <Layout.Content className='settings-content'>
        {isRevoke && workspaceId && (
          <RevokeCompanyConfirmation
            isRevoke={isRevoke}
            workspaceId={workspaceId}
            projectId={projectId}
            companyId={selectedCompany}
            companies={allCompany}
            profile={profile}
            users={users}
            isAssignedToCompany={isAssignedToCompany}
            isCompanyCreated={isCompanyCreated}
            isRelatedWorkPackage={isRelatedWorkPackage}
            isRelatedBaseIssues={isRelatedBaseIssues}
            refreshCompanies={refreshCompanies}
            setIsRevoke={(value: boolean) => setIsRevoke(value)}
          />
        )}

        <TablePagination
          onSubmit={(value) => {
            onFilter(value);
          }}
          issueParams={issueParams}
          issuesCount={issuesCount}
        />
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          loading={loading}
          pagination={false}
          className='workspaces-list-table my-6'
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={companies}
          onChange={handleTableChange}
        />
        <TablePagination
          onSubmit={(value) => {
            onFilter(value);
          }}
          issueParams={issueParams}
          issuesCount={issuesCount}
        />
      </Layout.Content>
    </Spin>
  );
}
