import { useMemo } from 'react';
import { QueryParams } from 'types';
import { useFetchByParams, useQueryParams, useRequestByParams } from 'hooks';
import { fetchProjectAttachments } from 'store/my-projects.slice';
import { RootState } from 'store';
import { Attachment } from 'model';
import { attachmentService } from 'services';

const selectProjectAttachments = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.attachments;
const selectProjectAttachmentsLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.attachmentsLoading;

export const useProjectAttachments = (query: any) => {
  const { projectId } = query;
  return useFetchByParams<Attachment[]>({
    action: fetchProjectAttachments,
    dataSelector: (state: RootState) =>  
      selectProjectAttachments(state, projectId),
    loadingSelector: (state: RootState) =>
      selectProjectAttachmentsLoading(state, projectId),
    params: query,
  });
};

export const useProjectAttachmentsParams = (options: {
  workspaceId: string;
  projectId: string;
} & QueryParams) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId, projectId]);
};

export const useIssueAttachmentsParams = (options?: QueryParams & { [key: string]: any }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { limit: 'unlimited', ...query, ...options };

    return [queryParams];
  }, []);
};

export const useIssueAttachments = (query: any) => {
  const { workspaceId, projectId, issueId, ...params } = query;
  const action = async () => {
    if (!issueId) return [];
    return [];
    // const response = await attachmentService.getPhotos(workspaceId, projectId, params);
    // return response;
  };
  return useRequestByParams<Attachment[]>({
    action,
    params: query,
    default: [],
  });
};
