import { Row, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { MOMFormValues } from '../ProjectMinutesOfMeetingCreateOrUpdate';
import { BaseIssue } from 'model';
import { MOM_STATUSES } from 'utils/contants';
import { useMemo } from 'react';

export enum EMinutesOfMeetingActionType {
  SaveAsDraft = 'SaveAsDraft',
  ReadyForReview = 'ReadyForReview',
  SendToRecipient = 'SendToRecipient',
  Delete = 'Delete',
}
type ProjectMinutesOfMeetingFooterProps = {
  onSubmitAsDraft: () => void;
  setOpenValidationModal: (isOpen: boolean) => void;
  selectedBaseIssue: BaseIssue | null;
};
export default function ProjectMinutesOfMeetingFooter(props: ProjectMinutesOfMeetingFooterProps) {
  const { onSubmitAsDraft, setOpenValidationModal } = props;
  const { t } = useTranslation();
  const watchedValue = Form.useWatch([]) as MOMFormValues;
  const isDraft = useMemo(
    () =>
      props.selectedBaseIssue?.IssueStatus?.code === MOM_STATUSES.DRAFT ||
      props.selectedBaseIssue?.IssueStatus?.code === MOM_STATUSES.READY_FOR_REVIEW,
    [props.selectedBaseIssue]
  );
  const isDisabledNextButton =
    watchedValue?.attendees.length === 0 ||
    !watchedValue?.subject ||
    !watchedValue?.category ||
    !watchedValue?.meetingDate ||
    watchedValue?.attendees?.[0]?.companyId === null ||
    !watchedValue?.attendees?.[0] ||
    watchedValue?.attendees?.[0]?.inspectorIds?.length === 0 ||
    watchedValue?.items?.some((item) => {
      return !item.subject;
    });
  return (
    <>
      <Row gutter={16} justify={'end'} align='middle'>
        <Form.Item>
          <Button onClick={onSubmitAsDraft}>{t(isDraft ? 'Save' : 'Save as Draft')}</Button>
          <Button
            disabled={isDisabledNextButton}
            form='normal_login'
            type='primary'
            onClick={() => {
              setOpenValidationModal(true);
            }}
            htmlType='submit'
          >
            {t('Next')}
          </Button>
        </Form.Item>
      </Row>
    </>
  );
}
