import { Button, Modal, Tree, TreeProps, Typography, ModalProps, TreeDataNode, message } from 'antd';
import { DataNode } from 'antd/es/tree';
import Zone from 'model/Zone';
import { useEffect, useState } from 'react';
import ZoningTitle from './ZoningTitle';
import { useTranslation } from 'react-i18next';

type ProjectZoningTreeProps = {
  zones: Zone[];
  workspaceId: string;
  projectId: string;
  refreshZone: () => void;
  isModalOpen: boolean;
  updateZones: Zone[];
  onSubmit: (zones: Zone[]) => void;
  setIsModalOpen: (value: boolean) => void;
  fetchAttachments: () => void;
  setUpdateZones: (zones: Zone[]) => void;
} & ModalProps;
export default function ProjectZoningTree(props: ProjectZoningTreeProps) {
  const {
    isModalOpen,
    zones,
    workspaceId,
    projectId,
    updateZones,
    onSubmit,
    setIsModalOpen,
    fetchAttachments,
    refreshZone,
    setUpdateZones,
  } = props;
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<string[]>(['root']);
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const handleExpand: TreeProps['onExpand'] = (selectedKeys, info) => {
    const zoneId = info.node.key.toString();
    const isExpanded = expandedKeys.includes(zoneId);
    setExpandedKeys((prevKeys) => (isExpanded ? prevKeys.filter((key) => key !== zoneId) : [...prevKeys, zoneId]));
  };

  const calculateAddOrder = (parentId: string): number => {
    const children = zones.filter((zone) => zone.parentId === parentId);
    let maxOrder: number = 0;

    children.forEach((zone) => {
      if (Number(zone.order) > maxOrder) {
        maxOrder = Number(zone.order);
      }
    });

    return maxOrder + 1;
  };

  const levelColor = ['orange', 'pink', 'purple', 'blue', 'red'];
  const convertDataToTree = (data: Zone[]): DataNode[] => {
    const buildTree = (items: Zone[], parentId: string | null = null, level: number = 0): DataNode[] => {
      const tree: DataNode[] = [];
      items?.forEach((item: Zone) => {
        if (item.parentId === parentId) {
          const children = buildTree(items, item.id, level + 1);
          const node: DataNode = {
            // title: renderTitle(item.name, item.id, levelColor[level]),
            title: (
              <ZoningTitle
                nodeTitle={item.name}
                nodeKey={item.id}
                zoneParent={item.parentId}
                nodeColor={levelColor[level]}
                workspaceId={workspaceId}
                projectId={projectId}
                order={calculateAddOrder(item.id)}
                refreshZone={refreshZone}
                fetchAttachments={fetchAttachments}
              />
            ),
            // renderTitle(item.name, item.id, item.parentId, levelColor[level]),
            key: item.id,
            children: children.length > 0 ? children : undefined,
          };
          setColorForNode(node, 0);
          tree.push(node);
        }
      });
      return tree;
    };

    const setColorForNode = (node: DataNode, level: number) => {
      if (node.children) {
        node.children.forEach((child) => setColorForNode(child, level + 1));
      }
    };
    const zoneNodes: DataNode[] = buildTree(data);
    return zoneNodes;
  };
  // const treeData = useMemo(() => {
  //   const zoneIds: string[] = [];
  //   zones?.map((zone) => zoneIds.push(zone.id.toString()));
  //   setExpandedKeys((prevKeys) => [...prevKeys, ...zoneIds]);
  //   return convertDataToTree(zones);
  // }, [zones]);
  useEffect(() => {
    const zoneIds: string[] = [];
    zones?.map((zone) => zoneIds.push(zone.id.toString()));
    setExpandedKeys((prevKeys) => [...prevKeys, ...zoneIds]);
    const tree = convertDataToTree(zones);
    setTreeData(tree);
  }, [zones]);

  const handleCancel = () => {
    setUpdateZones([]);
    refreshZone();
    setIsModalOpen(false);
  };

  const onDragEnter: TreeProps['onDragEnter'] = () => {
    // console.log(info.node.key);
    // console.log(info);
    // expandedKeys, set it when controlled is needed
    // setExpandedKeys(info.expandedKeys)
  };
  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dragPos = info.dragNode.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    const isSameLevel = (dragPosition: string[], dropPosition: string[]) => {
      //Case that drop on same level but under parent node
      //ex '0-0-3' drop on '0-0'
      if (dropPosition[dropPosition.length - 1] === '0' && dragPosition.length - 1 === dropPosition.length) {
        dragPosition.pop();
        return (
          info.dropToGap === false && //Not drop on the content
          info.dropPosition.toString() === '0' && //Drop at position 0 (parent node)
          dragPosition.join('') === dropPosition.join('') //Drag position and drop position are the same
        );
      }

      //Case that drop on same level
      //ex '0-0-3' drop on '0-0-1'
      dragPosition.pop();
      dropPosition.pop();
      return (
        info.dropToGap === true &&
        dragPosition.length === dropPosition.length &&
        dragPosition.join('') === dropPosition.join('')
      );
    };

    if (isSameLevel(dragPos, dropPos)) {
      console.log(info);
      const data = [...treeData];

      // Find dragObject
      let dragObj: TreeDataNode;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
          item.children.unshift(dragObj);
        });
      } else {
        let ar: TreeDataNode[] = [];
        let i: number;
        loop(data, dropKey, (_item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          // Drop on the top of the drop node
          ar.splice(i!, 0, dragObj!);
        } else {
          // Drop on the bottom of the drop node
          ar.splice(i! + 1, 0, dragObj!);
        }
      }

      // Get all zones in the same level
      const dragZone = zones.find((z) => z.id === dragKey);
      const zonesLevel: Zone[] = updateZones.find((z) => z.id === dragZone?.id)
        ? updateZones.filter((z) => z.parentId === dragZone?.parentId)
        : zones.filter((z) => z.parentId === dragZone?.parentId);

      if (dragZone?.order) {
        const dragZoneIndex = Number(info.dragNode.pos.split('-').pop());
        const dropZoneIndex = info.dropPosition;
        // Drop on top node
        if (dragZoneIndex > dropZoneIndex) {
          // Assign drop position to drag position
          zonesLevel[dragZoneIndex] = {
            ...zonesLevel[dragZoneIndex],
            order: Number(zonesLevel[dropZoneIndex].order),
          };
          const draggedZone = zonesLevel[dragZoneIndex];

          for (let i = dropZoneIndex; i < dragZoneIndex; i++) {
            zonesLevel[i] = {
              ...zonesLevel[i],
              order: Number(zonesLevel[i].order) + 1,
            };
          }

          //Split element from dragZoneIndex to dropZoneIndex
          zonesLevel.splice(dragZoneIndex, 1);
          zonesLevel.splice(dropZoneIndex, 0, draggedZone);
        } else if (dragZoneIndex !== dropZoneIndex) {
          // Drag down below node
          //assign drop position to drag position
          zonesLevel[dragZoneIndex] = {
            ...zonesLevel[dragZoneIndex],
            order: Number(zonesLevel[dropZoneIndex - 1].order),
          };
          const draggedZone = zonesLevel[dragZoneIndex];

          for (let i = dragZoneIndex + 1; i < dropZoneIndex; i++) {
            zonesLevel[i] = {
              ...zonesLevel[i],
              order: Number(zonesLevel[i].order) - 1,
            };
          }

          //Split element from dragZoneIndex to dropZoneIndex
          zonesLevel.splice(dragZoneIndex, 1);
          zonesLevel.splice(dropZoneIndex - 1, 0, draggedZone);
        }

        // Update zones but insert if not existed
        const updatedZones = [...zonesLevel];
        updateZones.forEach((zone) => {
          const foundZone = zonesLevel.find((z) => z.id === zone.id);
          if (!foundZone) {
            updatedZones.push(zone);
          }
        });
        setUpdateZones(updatedZones);
      }
      setTreeData(data);
    } else {
      message.error('Cannot move zone to a different level');
    }
  };

  return (
    <Modal
      title={t('Edit zoning')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Close')}
        </Button>,
        <Button disabled={updateZones.length === 0} key='submit' onClick={() => onSubmit(updateZones)} type='primary'>
          {t('Save')}
        </Button>,
      ]}
      width={723}
    >
      <Typography.Text className='section-title'>{t('Define zones to organize your locations plans')}</Typography.Text>
      <Tree
        style={{ minHeight: '250px' }}
        className='my-5'
        showLine
        defaultExpandAll
        defaultExpandedKeys={['root']}
        onExpand={handleExpand}
        expandedKeys={expandedKeys}
        treeData={treeData}
        blockNode={false}
        draggable={{
          icon: null,
        }}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
      />
    </Modal>
  );
}
