import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Uom } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchUoms, selectUoms } from 'store/common.slice';

export const useUomsParams = (options?: any) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, []);
};

export const useUoms = (query: any) => {
  return useFetchByParams<Uom[]>({
    action: fetchUoms,
    dataSelector: selectUoms,
    params: query,
  });
};
