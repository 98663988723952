import { Card, Image, Modal, Tooltip } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import ImageItem from './pre-signed-url-items/ImageItem';

type ImagePreviewWithCommentProps = React.ComponentProps<typeof ImageItem> & {
  comment?: string;
};
const ImagePreviewWithComment = (props: ImagePreviewWithCommentProps) => {
  const { comment, ...restProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Image.PreviewGroup
      previewPrefixCls='image-preview-with-comment'
      preview={{
        countRender: () => {
          const scrollWidth = containerRef?.current?.scrollWidth || 0;
          const clientWidth = containerRef?.current?.clientWidth || 0;
          return (
            <Tooltip title={scrollWidth > clientWidth ? props?.comment : ''} placement='top'>
              {props?.comment && (
                <div className='preview-with-comment' ref={containerRef}>
                  {props?.comment || ''}
                </div>
              )}
            </Tooltip>
          );
        },
      }}
    >
      <ImageItem {...restProps} />
    </Image.PreviewGroup>
  );
};

export default ImagePreviewWithComment;
