import {
  AppstoreOutlined,
  BankOutlined,
  BlockOutlined,
  BuildOutlined,
  ContainerOutlined,
  ControlOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
  FieldNumberOutlined,
  FileTextOutlined,
  GatewayOutlined,
  GlobalOutlined,
  GroupOutlined,
  HomeOutlined,
  MailOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  ToolOutlined,
  WarningOutlined,
  BarChartOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import type { MenuProps } from 'antd';
import { Button, Flex, Layout, Menu, Select } from 'antd';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMyWorkspaceProjects, useMyWorkspaceProjectsParams, useOtherProjects, useOtherProjectsParams } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { selectSelectedProjectId, setSelectedProject } from 'store/my-projects.slice';
import { useEffect, useState } from 'react';
import envService, { HiddenFeatures } from 'services/env.service';
import { ModuleIcon } from 'components/common';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  disabled?: boolean,
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}
interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}
type SidebarProps = {
  collapsed: boolean;
  toggleCollapsed: () => void;
};

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const ProjectSidebar = (props: SidebarProps) => {
  const { collapsed, toggleCollapsed } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectSelectedProjectId) as string;

  const path = location.pathname.split('/');
  const lastSegment =
    path.length === 5 ? path[3] + `/` + path[4] : location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const [projectQuery] = useMyWorkspaceProjectsParams();
  const [myWorkspaceProjects] = useMyWorkspaceProjects(projectQuery);
  const projects = [...myWorkspaceProjects, ...otherProjects];
  const [stateOpenKeys, setStateOpenKeys] = useState(['modules', path[3]]);
  const selectedProject = projects?.find((item) => item.id === projectId);
  const isAdmin = selectedProject?.ProjectUser?.userRole === 'admin';

  useEffect(() => {
    if (projectId && projects?.length) {
      const selectedProject = projects.find((item) => item.id === projectId);
      dispatch(setSelectedProject(selectedProject));
    }
  }, [projectId, projects]);

  let itemsProject: MenuProps['items'] = [
    getItem(t('Home'), '/', <HomeOutlined />),
    getItem(
      t('Toolbox'),
      'toolbox',
      <ToolOutlined />,
      [
        getItem(
          t('Requests for information'),
          'toolbox/request-for-information',
          <QuestionCircleOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.RFI)
        ),
        getItem(t('Directory'), 'toolbox/directory', <ReadOutlined />, null),
        getItem(
          t('Risks management'),
          '#26',
          <WarningOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.TOOLBOX)
        ),
        getItem(
          t('Tasks management'),
          '#27',
          <PushpinOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.TOOLBOX)
        ),
      ],
      false
    ),
    getItem(
      t('Document Management'),
      'document-management',
      <ModuleIcon color={'#405D72'} style={{ marginRight: '10px' }} />,
      [
        getItem(
          t('Work Breakdown Structure'),
          'document-management/dm-work-breakdown-structure',
          <SolutionOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT)
        ),
        getItem(
          t('Numbering System'),
          'document-management/numbering-system',
          <FieldNumberOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT)
        ),
        getItem(t('Design register'), 'document-management/designs', <EditOutlined />, null),
        ...(!envService.isFeatureHidden(HiddenFeatures.DOC_HUB)
          ? [
              getItem(
                t('Document Hub'),
                'document-management/documents-hub',
                <FileTextOutlined />,
                null,
                envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT)
              ),
            ]
          : []),
        getItem(
          t('Doc Transmission'),
          'document-management/documents-transmission',
          <FileTextOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT)
        ),
        getItem(
          t('Minutes Of Meeting'),
          'document-management/minutes-of-meeting',
          <FileTextOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.MOM)
        ),
        getItem(
          t('Letters'),
          'document-management/letters',
          <MailOutlined />,
          null,
          envService.isFeatureHidden(HiddenFeatures.LETTERS)
        ),
      ],
      false
    ),
    getItem(t('Quality'), 'quality', <ModuleIcon color='#3069C4' style={{ marginRight: '10px' }} />, [
      getItem(t('Dashboard'), 'quality/dashboard'),
      getItem(t('ITP'), 'quality/itp', null, null, envService.isFeatureHidden(HiddenFeatures.ITP)),
      getItem(t('Observations'), 'quality/observations'),
      getItem(
        t('Material Approvals'),
        'quality/material-approvals',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.MAR)
      ),
      getItem(
        t('Material Deliveries'),
        'quality/material-deliveries',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.MDI)
      ),
      getItem(
        t('Method statements'),
        'quality/method-statements',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.MS)
      ),
      getItem(t('Shop drawings'), 'quality/shop-drawings', null, null),
      getItem(
        t('Work Inspections'),
        'quality/work-inspections',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.WIR)
      ),
      getItem(
        t('Non Conformity'),
        'quality/non-conformity-report',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.NCR)
      ),
    ]),
    getItem(t('Safety'), 'safety', <ModuleIcon color='#D37F07' style={{ marginRight: '10px' }} />, [
      getItem(t('Dashboard'), 'safety/dashboard'),
      getItem(t('Inspections'), 'safety/inspections'),
      getItem(
        t('Safety Violations'),
        'safety/safety-violations',
        null,
        null,
        envService.isFeatureHidden(HiddenFeatures.SFV)
      ),
      getItem(t('Near Misses'), 'safety/near-misses', null, null, envService.isFeatureHidden(HiddenFeatures.NM)),
      getItem(t('Accidents'), 'safety/accidents', null, null, envService.isFeatureHidden(HiddenFeatures.ACD)),
      getItem(t('Equipment List'), 'safety/equipment-list', null, null, envService.isFeatureHidden(HiddenFeatures.EQL)),
      getItem(t('Permit to Work'), 'safety/permit-to-work', null, null, envService.isFeatureHidden(HiddenFeatures.PTW)),
      getItem(t('Accident'), '#4', null, null, true),
      getItem(t('Hazardous Material'), '#6', null, null, true),
      getItem(t('Equipement'), '#7', null, null, true),
      getItem(t('Manpower'), '#8', null, null, true),
      getItem(t('Induction'), '#9', null, null, true),
      getItem(t('Training'), '#10', null, null, true),
      getItem(t('Audit'), '#11', null, null, true),
      getItem(t('Reporting'), '#12', null, null, true),
    ]),
    getItem(t('Finance'), 'finance', <ModuleIcon color='#B74E06' style={{ marginRight: '10px' }} />, [
      getItem(
        t('Dashboard'),
        '#51',
        <AppstoreOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('Work Packages'),
        '#52',
        <BuildOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('Change Orders'),
        '#53',
        <ControlOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('Budget View'),
        '#54',
        <GatewayOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('Invoicing'),
        '#55',
        <ContainerOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('Payments'),
        '#56',
        <DollarOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
      getItem(
        t('CashFlow'),
        '#57',
        <BarChartOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.MODULE_FINANCE)
      ),
    ]),
    getItem(t('Construction'), 'construction', <ModuleIcon color='#741C47' style={{ marginRight: '10px' }} />, [
      getItem(
        t('Work packages & Contracts'),
        '#41',
        <SolutionOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
      getItem(
        t('Procurement Monitoring'),
        '#42',
        <MailOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
      getItem(
        t('Construction Budget Monitoring register'),
        '#46',
        <EditOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
      getItem(
        t('Site Instructions'),
        'construction/site-instructions',
        <FileTextOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
      getItem(
        t('Claims'),
        'construction/change-orders',
        <SolutionOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
      getItem(
        t('Invoicing'),
        '#45',
        <MailOutlined />,
        null,
        envService.isFeatureHidden(HiddenFeatures.CONSTRUCTION_MANAGEMENT)
      ),
    ]),
    getItem(t('Environment'), 'environment', <ModuleIcon color='#8D9E0D' style={{ marginRight: '10px' }} />, [
      getItem(t('Environment Plan'), '#11', null, null, true),
      getItem(t('Erosion & Sedimentation'), '#12', null, null, true),
      getItem(t('Construction Waste'), '#13', null, null, true),
      getItem(t('Indoor Air Quality'), '#14', null, null, true),
      getItem(t('Material Validation'), '#15', null, null, true),
      getItem(t('Consumption Monitoring'), '#16', null, null, true),
    ]),
  ];

  itemsProject = isAdmin
    ? [
        ...itemsProject,
        getItem(t('Project settings'), 'Project settings', <SettingOutlined />, [
          getItem(t('Companies Management'), 'settings/companies', <BankOutlined />),
          getItem(t('Users Management'), 'settings/users', <TeamOutlined />),
          getItem(t('Work Packages Management'), 'settings/work-packages', <GroupOutlined />),
          getItem(t('Zoning'), 'settings/zoning', <BlockOutlined />),
          getItem(
            t('Work Breakdown Structure'),
            'settings/work-breakdown-structure',
            <BlockOutlined />,
            null,
            envService.isFeatureHidden(HiddenFeatures.WBS)
          ),
          getItem(t('Project Configuration'), 'settings/configuration', <GlobalOutlined />),
          getItem(t('Modules Configuration'), 'settings/modules-configuration', <ControlOutlined />),
        ]),
      ]
    : itemsProject;

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case '/':
        navigate('/');
        break;
      case '#':
        break;
      case 'designs':
      case 'documents-register':
      case 'documents-transmission':
      case 'minutes-of-meeting':
      case 'request-for-information':
      case 'directory':
        navigate(`/projects/${projectId}/toolbox/${e.key}`);
        break;
      default:
        if (!e.key.startsWith('#')) navigate(`/projects/${projectId}/${e.key}`);
        break;
    }
  };
  const onChangeProject = (itemId: string) => {
    const pathParts = location.pathname.split('/');
    pathParts[2] = itemId;
    const newPath = pathParts.join('/');
    navigate(newPath);
  };
  const levelKeys = getLevelKeys(itemsProject as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <Sider className={'siderbar project-sidebar'} breakpoint='lg' collapsible collapsed={collapsed} trigger={null}>
      <Flex gap={5} vertical align={'flex-end'} className={'siderbar-header'}>
        <Button
          type='link'
          icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          onClick={() => toggleCollapsed()}
        />
        {!collapsed && (
          <Select
            className={'project-name'}
            value={projectId}
            variant={'borderless'}
            style={{ width: '100%' }}
            onChange={onChangeProject}
            options={projects?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        )}
      </Flex>
      <Menu
        className={'project-menu'}
        onClick={onClick}
        selectedKeys={[lastSegment, 'request-for-information']}
        defaultSelectedKeys={['safety/inspections']}
        mode='inline'
        items={itemsProject}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
      />
    </Sider>
  );
};

export default ProjectSidebar;
