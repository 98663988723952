import { DataNode } from 'antd/es/tree';
import { SHOULD_NOT_CAPITALIZE } from './contants';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Zone } from 'model';
import { Coordinate, Geometry, LayerData, LayerTypes, QueryParams } from 'types';

export function mergeArraysByKey<T1, T2>(array1: T1[], array2: T2[], key = 'id'): Array<T1 & T2> {
  const mergedArray: Record<string, T1 | T2> = {};

  array1.forEach((item) => {
    const id = item[key as keyof T1] as string;
    mergedArray[id] = item;
  });

  array2.forEach((item) => {
    const id = item[key as keyof T2] as string;
    if (!mergedArray[id]) {
      mergedArray[id] = item;
    } else {
      mergedArray[id] = { ...mergedArray[id], ...item };
    }
  });

  return Object.values(mergedArray) as Array<T1 & T2>;
}

export function sortByName<T>(array: T[], key: string) {
  return array.sort(function (a, b) {
    const x = (a[key as keyof typeof a] as string)?.toLowerCase();
    const y = (b[key as keyof typeof b] as string)?.toLowerCase();

    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
  });
}

// Function to get current date in Year/Month/Day format
export const getCurrentDatePath = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
};

export function stringToBoolean(str: string): boolean {
  return str.toLowerCase() === 'true';
}

export const convertZonesToTreeData = (
  items: Zone[],
  parentId: string | null = null,
  level: number = 0
): DataNode[] => {
  const tree: DataNode[] = [];
  items?.forEach((item) => {
    if (item.parentId === parentId) {
      const children = convertZonesToTreeData(items, item.id, level + 1);
      const node: DataNode = {
        title: item.name,
        key: item.id,
        children: children.length > 0 ? children : undefined,
      };
      tree.push(node);
    }
  });
  return tree;
};

export const getExistProp = (data: QueryParams) => {
  const result = Object.keys(data).reduce((nextData, key) => {
    if (data[key]) {
      return {
        ...nextData,
        [key]: data[key],
      };
    }

    return nextData;
  }, {});

  return result;
};

export const jsonToArray = (json: string) => {
  const jsonArray = JSON.parse(json);
  const normalArray: string[] = jsonArray.map((item: string) => item.replace(/"/g, ''));
  return normalArray;
};
export const parseQueryParams = (query: string) => {
  const params: Record<string, string | string[]> = {};
  const queryStr = query.substring(1);
  queryStr.split('&').forEach((param) => {
    const keyValue = param.split('=');
    const key = decodeURIComponent(keyValue[0]);
    const value = decodeURIComponent(keyValue[1]);
    if (value.includes(',')) {
      params[key] = value.split(',').map((v) => decodeURIComponent(v));
    } else {
      params[key] = value;
    }
  });
  return params as Record<string, string>;
};

export const titleCase = (str: string) => {
  const words = str.split(' ');
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  let result = capitalize(words[0].toLowerCase());
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    if (SHOULD_NOT_CAPITALIZE.includes(word.toLowerCase())) {
      result += ' ' + word.toLowerCase();
    } else {
      result += ' ' + capitalize(word);
    }
  }
  return result;
};

export function useSearchDebounce(delay = 350): [string, Dispatch<SetStateAction<string>>] {
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}
export function convertDateFormat(input: string) {
  if (input.includes('Month')) {
    return input.replace('Month', 'MMMM');
  } else if (input.includes('Mon')) {
    return input.replace('Mon', 'MMM');
  } else if (input.includes('b')) {
    return input.replaceAll('b', ' ');
  } else {
    return input;
  }
}

export const loadEnLocale = () => import('dayjs/locale/en');
export const loadFrLocale = () => import('dayjs/locale/fr');
export const loadViLocale = () => import('dayjs/locale/vi');
export const loadZhCnLocale = () => import('dayjs/locale/zh-cn');
export const loadIdLocale = () => import('dayjs/locale/id');
export const loadKmLocale = () => import('dayjs/locale/km');
export const loadPtLocale = () => import('dayjs/locale/pt');
export const loadEsLocale = () => import('dayjs/locale/es');

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const convertCoordinates = (coordinates: Coordinate, zoomRatio: number, isRevert = false): Coordinate => {
  if (isRevert) {
    return coordinates
      .map((coord) => {
        if (Array.isArray(coord)) {
          return coord
            .map((c) => {
              if (Array.isArray(c)) {
                return c
                  .map((d) => d * zoomRatio)
                  .slice()
                  .reverse();
              } else {
                return c * zoomRatio;
              }
            })
            .slice()
            .reverse();
        } else {
          return coord * zoomRatio;
        }
      })
      .slice()
      .reverse() as Coordinate;
  }
  return coordinates.map((coord) => {
    if (Array.isArray(coord)) {
      return coord.map((c) => {
        if (Array.isArray(c)) {
          return c.map((d) => d * zoomRatio);
        } else {
          return c * zoomRatio;
        }
      });
    } else {
      return coord * zoomRatio;
    }
  }) as Coordinate;
};

export const convertGeometry = (geometry: Geometry, zoomRatio: number, isRevert = false): Geometry => {
  const result = structuredClone(geometry);
  if (result.coordinates) {
    result.coordinates = convertCoordinates(result.coordinates, zoomRatio, isRevert);
  }
  if (result.radius) {
    result.radius = result.radius * zoomRatio;
  }
  return result;
};

export const getLayerType = (layer: LayerData | null): LayerTypes | null => {
  if (layer) {
    const type = layer.geometry?.type;
    if (type === 'Point') return LayerTypes.MARKER;
    if (type === 'LineString') return LayerTypes.PAINT;
    if (type === 'Polygon') {
      if (layer.geometry?.radius) return LayerTypes.CIRCLE;
      return LayerTypes.RECTANGLE;
    }
  }
  return null;
};

export const formatUrlAttachments = (url: string, noExtension?: boolean): string => {
  if (url) {
    const filenameWithPrefix = url.split('/').pop();
    if (filenameWithPrefix) {
      if (noExtension) {
        return filenameWithPrefix
          .replace(/^\d+-/, '')
          .slice(0, filenameWithPrefix.replace(/^\d+-/, '').lastIndexOf('.'));
      }
      return filenameWithPrefix.replace(/^\d+-/, '');
    }
  }
  return '';
};

export const convertZoneToTreeData = (data?: Zone[]): DataNode[] => {
  const buildTree = (items?: Zone[], parentId: string | null = null, level: number = 0): DataNode[] => {
    const tree: { title?: string; value?: string; children?: DataNode[]; key?: string }[] = [];
    items?.forEach((item: Zone) => {
      if (item.parentId === parentId) {
        const children = buildTree(items, item.id, level + 1);
        const node = {
          title: item.name,
          value: item.id,
          children: children.length > 0 ? children : undefined,
        };
        tree.push(node);
      }
    });
    return tree as DataNode[];
  };
  const zoneNodes: DataNode[] = buildTree(data);
  return zoneNodes;
};
