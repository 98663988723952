import { Button, Flex, Typography } from 'antd';
import { ReactNode, useState } from 'react';
import TruncatedAttachmentDocument from './TruncatedAttachmentDocument';
import { PlusOutlined } from '@ant-design/icons';
import UploadDocument from './UploadDocument';
import { selectAttachmentByCode } from 'store/common.slice';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { useAppSelector } from 'store';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { AttachmentMapper } from 'types';

export type AttachmentBaseIssue = {
  id?: string;
  attachmentTypeId?: string | null;
  name?: string;
  filePath?: string;
};

type DocumentAdderProps = {
  title?: ReactNode;
  attachmentType: (typeof ATTACHMENT_TYPES)[keyof typeof ATTACHMENT_TYPES];
  onChange: (attachments: AttachmentMapper[]) => void;
  documentsInfo: AttachmentMapper[];
  layout?: 'inline' | 'break-line';
  readOnlyTitle?: ReactNode;
  isReadonly?: boolean;
  hideResult?: boolean;
};

const DocumentAdder = (props: DocumentAdderProps) => {
  const { documentsInfo } = props;
  const { t } = useTranslation();
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  useAttachmentTypes(attachmentTypeQuery);
  const validationAttachmentDocument = useAppSelector(selectAttachmentByCode(props.attachmentType));

  const handleAddAttachment = (name: string, response: { fileUrl: string; id?: string }) => {
    if (!response?.fileUrl) return;
    const result = [...documentsInfo];
    result.push({
      id: response.id,
      name: name,
      filePath: response?.fileUrl || '',
      attachmentTypeId: validationAttachmentDocument?.id || '',
    });
    props.onChange(result);
  };

  const renderTitle = () => {
    if (props.readOnlyTitle) {
      return documentsInfo?.length > 0 && <Typography.Text className='mb-2'>{props.readOnlyTitle}</Typography.Text>;
    } else {
      return <Typography.Title level={3}>{props.title}</Typography.Title>;
    }
  };

  const renderAttachment = () => {
    return (
      documentsInfo?.length > 0 &&
      documentsInfo?.map((attachmentDocument) => {
        return (
          <TruncatedAttachmentDocument
            key={attachmentDocument.filePath}
            fileName={attachmentDocument.name}
            filePath={attachmentDocument.filePath}
            hideDelete={!!props.readOnlyTitle || props.isReadonly}
            onRemoveFile={() => {
              const newAttachments = documentsInfo.filter((item) => item.filePath !== attachmentDocument.filePath);
              props.onChange?.([...newAttachments]);
            }}
          />
        );
      })
    );
  };

  const renderDocumentList = () => {
    if (props.layout === 'break-line') {
      return (
        <div>
          {renderTitle()}
          <Flex gap={8} wrap className='mb-4'>
            {!props.hideResult && renderAttachment()}
            {!props.readOnlyTitle && !props.isReadonly && (
              <Button
                onClick={() => setOpenAttachment(true)}
                type='dashed'
                icon={<PlusOutlined />}
                className='text-size-12'
              >
                {t('Add Document')}
              </Button>
            )}
          </Flex>
        </div>
      );
    } else {
      return (
        <div>
          <Flex justify='space-between' align='center'>
            {renderTitle()}
            {!props.readOnlyTitle && !props.isReadonly && (
              <Button
                onClick={() => setOpenAttachment(true)}
                type='dashed'
                icon={<PlusOutlined />}
                className='text-size-12'
              >
                {t('Add Document')}
              </Button>
            )}
          </Flex>

          <Flex gap={10} className='mt-2' wrap>
            {!props.hideResult && renderAttachment()}
          </Flex>
        </div>
      );
    }
  };

  return (
    <div className='mt-4 mb-4'>
      {renderDocumentList()}
      {openAttachment && (
        <UploadDocument
          isModalOpen={openAttachment}
          setIsModalOpen={(value: boolean) => setOpenAttachment(value)}
          handleAddDocument={handleAddAttachment}
        />
      )}
    </div>
  );
};

DocumentAdder.defaultProps = {
  layout: 'break-line',
};

export default DocumentAdder;
