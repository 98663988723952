import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, resetAll } from 'store';
import {
  Company,
  Design,
  Issue,
  IssueByDiscipline,
  IssueByPriority,
  IssueStatusByCompany,
  IssueStatusByZone,
  Phase,
  Project,
  ProjectType,
  User,
  WorkspaceUser,
  Zone,
  ParameterSetting,
  InstructionCauseInput,
  WorkPackage,
  MaterialCategory,
  SafetyRiskActivity,
  Category,
  WorkBreakdownStructure,
} from 'model';
import { Loading, QueryParams, ResponseBaseIssueKpis, ResponseIssueKpis } from 'types';
import { mergeArraysByKey } from 'utils';
import {
  attachmentService,
  baseIssueService,
  categoryService,
  companyService,
  phaseService,
  projectService,
  safetyRiskActivityService,
  userService,
  workPackageService,
  workspaceService,
  zoneService,
  materialCategoryService,
} from 'services';
import issueService from 'services/issue.services';
import designService from 'modules/DocumentManagement/services/design.services';
import instructionService from 'services/instruction.services';
import designRegisterPhaseService from 'services/design-register-phase.service';
import assetService from 'services/asset.services';
import { DesignResponse, designQueryParams } from 'modules/DocumentManagement/models/Design';
import BaseIssue from 'model/BaseIssue';
import shopDrawingService from 'services/shop-drawing.services';
import workBreakdownStructureService from 'services/work-breakdown-structure.services';
import AssetType from 'model/AssetType';
import { ControlType } from 'model/ControlType';
import { Frequencies } from 'model/Frequencies';
import { QualityControlMatrices } from 'model/QualityControlMatrix';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import { createSelector } from '@reduxjs/toolkit';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import Folder from 'model/Folder';
import foldersService from 'services/folders.services';
import dashboardService from 'services/dashboard.service';
import { DashboardKpisParamsType } from 'hooks/dashboard';
import BaseIssueMomCategory from 'model/BaseIssueMomCategory';
import BaseIssueMomItem from 'model/BaseIssueMomItem';
import momItemService from 'services/momItem.service';

type ActionPayload = {
  meta: {
    arg: {
      projectId: string;
      module?: string;
    };
  };
};

interface MyProjectsState {
  selectedProjectId: string | null;
  selectedProject: Project | null;
  projects: {
    [projectId: string]: {
      designRegisterPhasesLoading: Loading;
      designRegisterPhases: DesignRegisterPhase[];
      instructionCausesLoading: Loading;
      instructionCauses: InstructionCauseInput[];
      projectData: Project | null;
      projectDataLoading: Loading;
      users: User[];
      usersCount: number;
      usersLoading: Loading;
      companiesCount: number;
      companies: Company[];
      companiesLoading: Loading;
      zones: Zone[];
      zonesLoading: Loading;
      workBreakdownStructures: WorkBreakdownStructure[];
      workBreakdownStructuresLoading: Loading;
      workDefaultBreakdownStructures: WorkBreakdownStructure[];
      workDefaultBreakdownStructuresLoading: Loading;
      folder: Folder[];
      folderLoading: Loading;
      defaultFolder: Folder[];
      defaultFolderLoading: Loading;
      controlType: ControlType[];
      controlTypesLoading: Loading;
      frequencies: Frequencies[];
      frequenciesLoading: Loading;
      qualityControlMatrices: QualityControlMatrices[];
      qualityControlMatricesLoading: Loading;
      inspectionTestPlan: InspectionTestPlanModel[];
      inspectionTestPlanLoading: Loading;
      inspectionTestPlanCount: number;
      projectTypes: ProjectType[];
      projectTypesLoading: Loading;
      attachments: ProjectType[];
      attachmentsLoading: Loading;
      phases: Phase[];
      phasesLoading: Loading;
      designs: DesignResponse;
      designsLoading: Loading;
      designsKpis: Map<string, string>[];
      designsKpisLoading: Map<string, string>[];
      shopDrawings: DesignResponse;
      shopDrawingsLoading: Loading;
      designRegDisciplines: { discipline: string }[];
      designRegDisciplinesLoading: Loading;
      shopDrawingDisciplines: { discipline: string }[];
      shopDrawingDisciplinesLoading: Loading;
      workPackages: WorkPackage[];
      workPackagesCount: number;
      workPackagesLoading: Loading;
      materialCategories: MaterialCategory[];
      materialCategoriesLoading: Loading;
      safetyRiskActivities: SafetyRiskActivity[];
      safetyRiskActivitiesLoading: Loading;
      categories: Category[];
      categoriesLoading: Loading;
      equipmentList: AssetType[];
      equipmentListCount: number;
      equipmentListLoading: Loading;
      functionDisciplines: WorkBreakdownStructure[];
      functionDisciplinesLoading: Loading;
      functionCategories: BaseIssueMomCategory[];
      functionCategoriesLoading: Loading;
      modules: {
        [module: string]: {
          issues: Issue[];
          issuesCount: number;
          issuesLoading: Loading;
          issueKpis: {
            kpiData: {
              draft: number;
              open: number;
              closed: number;
              readyForInspection: number;
              readyForInspectionOverdue: number;
              openOverdue: number;
            };
          };
          issueKpisLoading: Loading;

          issueStatusByCompany: IssueStatusByCompany[];
          issueStatusByCompanyTotal: number;
          issueStatusByCompanyLoading: Loading;

          issueByDiscipline: IssueByDiscipline[];
          issueByDisciplineTotal: number;
          issueByDisciplineLoading: Loading;

          issueByPriority: IssueByPriority[];
          issueByPriorityTotal: number;
          issueByPriorityLoading: Loading;

          issueStatusByZone: IssueStatusByZone[];
          issueStatusByZoneTotal: number;
          issueStatusByZoneLoading: Loading;

          baseIssues: BaseIssue[];
          baseIssueCount: number;
          kpiData: { AP: number; DR: number; RE: number; RIP: number; ISD: number };
          baseIssueKpi?: Record<string, number>;
          baseIssuesLoading: Loading;
          baseIssuesMomItems: BaseIssueMomItem[];
          baseIssuesMomItemsLoading: Loading;
          dashboardKpis: ResponseBaseIssueKpis & ResponseIssueKpis;
          dashboardKpisLoading: Loading;
        };
      };
    };
  };
  parametersSetting: ParameterSetting[];
  parametersLoading: Loading;
}

const name = 'my-project';
const initialState: MyProjectsState = {
  selectedProjectId: null,
  selectedProject: null,
  projects: {},
  parametersSetting: [],
  parametersLoading: 'idle',
};

export const fetchProjectUsers = createAsyncThunk(
  `${name}/list-of-users-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;

    if (workspaceId && projectId) {
      try {
        const workspaceUsersParams = { limit: 'unlimited' };
        const usersParams = undefined;
        const [workspaceUsers, users] = await Promise.all([
          workspaceService.getProjectUsers(workspaceId, projectId, workspaceUsersParams),
          userService.getProjectUsers(workspaceId, projectId, usersParams),
        ]);

        users.forEach((_, index) => {
          delete users[index].status;
        });
        const updatedWorkspaceUsers = workspaceUsers.rows.map((user) => ({
          ...user,
          id: user.userId,
        }));
        let response = mergeArraysByKey(updatedWorkspaceUsers, users);
        response = filterAndSortUsers(response, params);

        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return [];
    }
  }
);

const filterAndSortUsers = (
  response: ({
    id: string;
    userId: string;
    status: string;
    Company: Company;
    ProjectUsers: { id: string; Project: Project }[];
  } & User)[],
  params: QueryParams
) => {
  response = response.filter(
    (u) =>
      (!params['companyId'] || params['companyId'] === u.WorkspaceUser?.Company?.id) &&
      (!params['fullName'] || u.fullName?.toLowerCase().includes(params['fullName'].toLowerCase())) &&
      (!params['job'] || u.job?.toLowerCase().includes(params['job'].toLowerCase())) &&
      (!params['email'] || u.email?.toLowerCase().includes(params['email'].toLowerCase())) &&
      (!params['phone'] || u.phone?.toString().toLowerCase().includes(params['phone'].toString().toLowerCase()))
  );

  response = response.filter(
    (u) =>
      !params['searchBy']?.includes('User.fullName') ||
      !params['searchKey'] ||
      u.fullName?.toLowerCase().includes(params['searchKey'].toLowerCase())
  );

  if (params.orderBy) {
    const [key, direction] = params.orderBy.split(',');
    let k: keyof ((WorkspaceUser & User) | 'company') = key as keyof ((WorkspaceUser & User) | 'company');
    k = k === 'fullName' ? ('lastName' as keyof ((WorkspaceUser & User) | 'company')) : k;
    response.sort((a, b) => {
      const va = (k === 'company' ? a.WorkspaceUser?.Company?.name : a[k]) ?? '';
      const vb = (k === 'company' ? b.WorkspaceUser?.Company?.name : b[k]) ?? '';

      return (direction !== 'desc' ? 1 : -1) * va.localeCompare(vb);
    });
  }
  return response;
};

export const fetchProjectCompanies = createAsyncThunk(
  `${name}/list-of-companies-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await companyService.getProjectCompanies(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchProjectZones = createAsyncThunk(
  `${name}/list-of-zones-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await zoneService.getProjectZones(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectWorkBreakdownStructures = createAsyncThunk(
  `${name}/list-of-work-breakdown-structure-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await workBreakdownStructureService.getProjectWorkBreakdownStructures(
        workspaceId,
        projectId,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchFolders = createAsyncThunk(
  `${name}/list-of-folder`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await foldersService.getFolders(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectDefaultWorkBreakdownStructures = createAsyncThunk(
  `${name}/list-of-default-work-breakdown-structure-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await workBreakdownStructureService.getProjectDefaultWorkBreakdownStructures(
        workspaceId,
        projectId,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchProjectAttachments = createAsyncThunk(
  `${name}/list-of-attachments-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await attachmentService.getAttachments(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectById = createAsyncThunk(
  `${name}/get-project-byid-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    if (!workspaceId) return null;
    try {
      if (!projectId) return null;
      const response = await projectService.getProjectById(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssues = createAsyncThunk(
  `${name}/list-of-issues-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getAllIssues(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssueKpis = createAsyncThunk(
  `${name}/list-of-issue-kpis-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getIssueKpis(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectPhases = createAsyncThunk(
  `${name}/list-of-phases-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await phaseService.getProjectPhases(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectDesignsKpis = createAsyncThunk(
  `${name}/list-of-design-kpis-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await designService.getProjectDesignsKpis(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectDesigns = createAsyncThunk(
  `${name}/list-of-designs-in-my-project`,
  async (query: designQueryParams, { rejectWithValue }) => {
    try {
      const {
        GeneralFilterQuery,
        nestedPaginationParams,
        nestedOrderParams,
        workspaceId,
        projectId,
        disciplineMapNameToId,
      } = query;
      const designTablesData: { [discipline: string]: { count: number; rows: Design[] } } = {};
      const promises: Promise<void>[] = [];
      const disciplinesToLoad = Array.from(disciplineMapNameToId.keys());
      if (disciplinesToLoad.length) {
        disciplinesToLoad.forEach((discipline) => {
          const designQuery: QueryParams = Object.assign(
            GeneralFilterQuery,
            nestedPaginationParams[discipline as string],
            {
              orderBy: nestedOrderParams[discipline as string],
              disciplineId: disciplineMapNameToId.get(discipline as string),
            }
          );
          const promise = designService.getProjectDesigns(workspaceId, projectId, designQuery).then((response) => {
            designTablesData[discipline as string] = response;
          });
          promises.push(promise);
        });
        const response = await Promise.all(promises).then(() => designTablesData);
        return response;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectShopDrawings = createAsyncThunk(
  `${name}/list-of-shop-drawings-in-my-project`,
  async (query: designQueryParams, { rejectWithValue }) => {
    try {
      const {
        GeneralFilterQuery,
        nestedPaginationParams,
        nestedOrderParams,
        workspaceId,
        projectId,
        disciplineMapNameToId,
      } = query;
      const designTablesData: { [discipline: string]: Design[] } = {};
      const promises: Promise<void>[] = [];
      const disciplinesToLoad = Array.from(disciplineMapNameToId.keys());
      if (disciplinesToLoad.length) {
        disciplinesToLoad.forEach((discipline) => {
          const designQuery: QueryParams = Object.assign(
            GeneralFilterQuery,
            nestedPaginationParams[discipline as string],
            {
              orderBy: nestedOrderParams[discipline as string],
              disciplineId: disciplineMapNameToId.get(discipline as string),
            }
          );
          const promise = shopDrawingService
            .getProjectShopDrawings(workspaceId, projectId, designQuery)
            .then((response) => {
              designTablesData[discipline as string] = response;
            });
          promises.push(promise);
        });
        const response = await Promise.all(promises).then(() => designTablesData);
        return response;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectShopDrawingsDisciplines = createAsyncThunk(
  `${name}/list-of-shop-drawings-disciplines-in-my-project`,
  async (query: QueryParams & { projectId: string }, { rejectWithValue }) => {
    try {
      const { workspaceId, projectId } = query;
      const response = await shopDrawingService.getProjectShopDrawingsDisciplines(workspaceId, projectId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssueStatusByCompany = createAsyncThunk(
  `${name}/list-of-issue-status-by-company-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getIssueStatusByCompany(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssueByDiscipline = createAsyncThunk(
  `${name}/list-of-issue-by-discipline-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getIssueByDiscipline(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssueByPriority = createAsyncThunk(
  `${name}/list-of-issue-by-priority-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getIssueByPriority(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectIssueStatusByZone = createAsyncThunk(
  `${name}/list-of-issue-status-by-zone-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await issueService.getIssueStatusByZone(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setProjectState = <T = unknown>(
  state: RootState['myProjects'],
  action: ActionPayload,
  field: string,
  value: T
) => {
  const { projectId } = action.meta.arg;
  state.projects[projectId] = {
    ...(state.projects[projectId] ?? {}),
    [field]: value,
  };
};

export const fetchProjectDesignRegisterPhases = createAsyncThunk(
  'designRegisterPhases/list-of-design-register-phases-in-my-project',
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId } = query;
    try {
      const response = await designRegisterPhaseService.getAllDesignRegisterPhases(workspaceId, projectId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectInstructionCauses = createAsyncThunk(
  `${name}/list-of-instruction-causes-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId } = query;
    try {
      const response = await instructionService.getAllInstructionCauses(workspaceId, projectId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMyParametersSettingByProjectId = createAsyncThunk(
  `${name}/list-of-parameters-setting-by-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { projectId } = query;
    try {
      return await projectService.getProjectSettings(projectId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchProjectWorkPackages = createAsyncThunk(
  `${name}/list-of-work-package-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await workPackageService.getProjectWorkPackages(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectBaseIssues = createAsyncThunk(
  `${name}/list-of-base-issue-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module?: string;
      type: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    delete params.module;
    try {
      const response = await baseIssueService.getAllBaseIssues(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchProjectBaseIssueById = createAsyncThunk(
  `${name}/list-of-base-issue-by-id-in-my-project`,
  async (
    query: QueryParams & {
      projectId: string;
      workspaceId: string;
      baseIssueId: string;
      module: string;
      type: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, module, baseIssueId, ...params } = query;
    console.log('module', module);
    const response = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, params);
    try {
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectSafetyRiskActivities = createAsyncThunk(
  `${name}/list-of-project-safety-risk-activity-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await safetyRiskActivityService.getProjectSafetyRiskActivities(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectCategories = createAsyncThunk(
  `${name}/list-of-project-category-in-my-project`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await categoryService.getProjectCategories(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setModuleState = <T = unknown>(
  state: RootState['myProjects'],
  action: ActionPayload,
  field: string,
  value: T
) => {
  const { projectId, module } = action.meta.arg;
  state.projects[projectId] = {
    ...(state.projects[projectId] ?? {}),
    modules: {
      ...(state.projects?.[projectId]?.modules ?? {}),
      [module as string]: {
        ...(state.projects?.[projectId]?.modules?.[module as string] ?? {}),
        [field]: value,
      },
    },
  };
};
export const fetchMaterialCategories = createAsyncThunk(
  `${name}/list-of-material-categories-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await materialCategoryService.getMaterialCategories(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEquipmentList = createAsyncThunk(
  `${name}/list-of-assets-in-my-project`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId } = query;
    try {
      const response = await assetService.getProjectEquipmentList(workspaceId, projectId, query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchInspectionTestPlain = createAsyncThunk(
  `${name}/list-of-inspection-test-plain`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await projectService.getInspectionTestPlans(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFrequencies = createAsyncThunk(
  `${name}/list-of-frequencies`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await projectService.getFrequencies(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchControlType = createAsyncThunk(
  `${name}/list-of-control-type`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await projectService.getControlType(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchQualityControlMatrices = createAsyncThunk(
  `${name}/list-of-quality-control-matrices`,
  async (query: QueryParams & { workspaceId: string; projectId: string }, { rejectWithValue }) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await projectService.getQualityControlMatrices(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectDisciplineByFunction = createAsyncThunk(
  `${name}/fetch-discipline-by-function`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      module: string;
      type: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, module, ...params } = query;
    console.log('module', module);
    try {
      const response = await baseIssueService.getDisciplineByFunction(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchProjectCategoryByFunction = createAsyncThunk(
  `${name}/fetch-category-by-function`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, ...params } = query;
    try {
      const response = await baseIssueService.getCategoryByFunction(workspaceId, projectId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchProjectMomItem = createAsyncThunk(
  `${name}/fetch-category-by-function`,
  async (
    query: QueryParams & {
      workspaceId: string;
      projectId: string;
      baseIssueId: string;
      momItemId: string;
    },
    { rejectWithValue }
  ) => {
    const { workspaceId, projectId, baseIssueId, momItemId, ...params } = query;
    try {
      const response = await momItemService.getMomItemsById(workspaceId, projectId, baseIssueId, momItemId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchDashboardKpis = createAsyncThunk(
  `${name}/fetch-dashboard-kpis`,
  async (query: DashboardKpisParamsType, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getQualityDashboardKpis(query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const myProjectsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchProjectUsers.pending, (state, action) => setProjectState(state, action, 'usersLoading', 'pending'))
      .addCase(fetchProjectUsers.fulfilled, (state, action) => {
        setProjectState(state, action, 'usersLoading', 'idle');
        setProjectState(state, action, 'users', action.payload);
      })
      .addCase(fetchProjectUsers.rejected, (state, action) => setProjectState(state, action, 'usersLoading', 'idle'));
    builder
      .addCase(fetchProjectCompanies.pending, (state, action) =>
        setProjectState(state, action, 'companiesLoading', 'pending')
      )
      .addCase(fetchProjectCompanies.fulfilled, (state, action) => {
        setProjectState(state, action, 'companiesLoading', 'idle');
        setProjectState(state, action, 'companies', action.payload.rows);
        setProjectState(state, action, 'companiesCount', action.payload.count);
      })
      .addCase(fetchProjectCompanies.rejected, (state, action) =>
        setProjectState(state, action, 'companiesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectById.pending, (state, action) =>
        setProjectState(state, action, 'projectDataLoading', 'pending')
      )
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        setProjectState(state, action, 'projectDataLoading', 'idle');
        setProjectState(state, action, 'projectData', action.payload);
      })
      .addCase(fetchProjectById.rejected, (state, action) =>
        setProjectState(state, action, 'projectDataLoading', 'idle')
      );
    builder
      .addCase(fetchProjectZones.pending, (state, action) => setProjectState(state, action, 'zonesLoading', 'pending'))
      .addCase(fetchProjectZones.fulfilled, (state, action) => {
        setProjectState(state, action, 'zonesLoading', 'idle');
        setProjectState(state, action, 'zones', action.payload.rows);
      })
      .addCase(fetchProjectZones.rejected, (state, action) => setProjectState(state, action, 'zonesLoading', 'idle'));
    builder
      .addCase(fetchProjectWorkBreakdownStructures.pending, (state, action) => {
        setProjectState(state, action, 'workBreakdownStructuresLoading', 'pending');
      })
      .addCase(fetchProjectWorkBreakdownStructures.fulfilled, (state, action) => {
        setProjectState(state, action, 'workBreakdownStructuresLoading', 'idle');
        setProjectState(state, action, 'workBreakdownStructures', action.payload.rows);
      })
      .addCase(fetchProjectWorkBreakdownStructures.rejected, (state, action) =>
        setProjectState(state, action, 'workBreakdownStructuresLoading', 'idle')
      );
    builder
      .addCase(fetchProjectDefaultWorkBreakdownStructures.pending, (state, action) => {
        setProjectState(state, action, 'workDefaultBreakdownStructuresLoading', 'pending');
      })
      .addCase(fetchProjectDefaultWorkBreakdownStructures.fulfilled, (state, action) => {
        setProjectState(state, action, 'workDefaultBreakdownStructuresLoading', 'idle');
        setProjectState(state, action, 'workDefaultBreakdownStructures', action.payload.rows);
      })
      .addCase(fetchProjectDefaultWorkBreakdownStructures.rejected, (state, action) =>
        setProjectState(state, action, 'workDefaultBreakdownStructuresLoading', 'idle')
      );
    builder
      .addCase(fetchFolders.pending, (state, action) => {
        setProjectState(state, action, 'folderLoading', 'pending');
      })
      .addCase(fetchFolders.fulfilled, (state, action) => {
        setProjectState(state, action, 'folderLoading', 'idle');
        setProjectState(state, action, 'folder', action.payload);
      })
      .addCase(fetchFolders.rejected, (state, action) => setProjectState(state, action, 'folderLoading', 'idle'));

    builder
      .addCase(fetchProjectPhases.pending, (state, action) => {
        setProjectState(state, action, 'phasesLoading', 'pending');
      })
      .addCase(fetchProjectPhases.fulfilled, (state, action) => {
        setProjectState(state, action, 'phasesLoading', 'idle');
        setProjectState(state, action, 'phases', action.payload.rows);
      })
      .addCase(fetchProjectPhases.rejected, (state, action) => setProjectState(state, action, 'phasesLoading', 'idle'));
    builder
      .addCase(fetchProjectAttachments.pending, (state, action) =>
        setProjectState(state, action, 'attachmentsLoading', 'pending')
      )
      .addCase(fetchProjectAttachments.fulfilled, (state, action) => {
        setProjectState(state, action, 'attachmentsLoading', 'idle');
        setProjectState(state, action, 'attachments', action.payload.rows);
      })
      .addCase(fetchProjectAttachments.rejected, (state, action) =>
        setProjectState(state, action, 'attachmentsLoading', 'idle')
      );
    builder
      .addCase(fetchProjectIssues.pending, (state, action) => {
        setModuleState(state, action, 'issuesLoading', 'pending');
      })
      .addCase(fetchProjectIssues.fulfilled, (state, action) => {
        setModuleState(state, action, 'issuesLoading', 'idle');
        setModuleState(state, action, 'issues', action.payload.rows);
        setModuleState(state, action, 'issuesCount', action.payload.count);
      })
      .addCase(fetchProjectIssues.rejected, (state, action) => setModuleState(state, action, 'issuesLoading', 'idle'));
    builder
      .addCase(fetchProjectIssueKpis.pending, (state, action) => {
        setModuleState(state, action, 'issueKpisLoading', 'pending');
      })
      .addCase(fetchProjectIssueKpis.fulfilled, (state, action) => {
        setModuleState(state, action, 'issueKpisLoading', 'idle');
        setModuleState(state, action, 'issueKpis', action.payload);
      })
      .addCase(fetchProjectIssueKpis.rejected, (state, action) =>
        setModuleState(state, action, 'issueKpisLoading', 'idle')
      );
    builder
      .addCase(fetchProjectDesigns.pending, (state, action) =>
        setProjectState(state, action, 'designsLoading', 'pending')
      )
      .addCase(fetchProjectDesigns.fulfilled, (state, action) => {
        setProjectState(state, action, 'designsLoading', 'idle');
        setProjectState(state, action, 'designs', action.payload);
      })
      .addCase(fetchProjectDesigns.rejected, (state, action) =>
        setProjectState(state, action, 'designsLoading', 'idle')
      );
    builder
      .addCase(fetchProjectDesignsKpis.pending, (state, action) =>
        setProjectState(state, action, 'designsKpisLoading', 'pending')
      )
      .addCase(fetchProjectDesignsKpis.fulfilled, (state, action) => {
        setProjectState(state, action, 'designsKpisLoading', 'idle');
        setProjectState(state, action, 'designsKpis', action.payload);
      })
      .addCase(fetchProjectDesignsKpis.rejected, (state, action) =>
        setProjectState(state, action, 'designsKpisLoading', 'rejected')
      );
    builder
      .addCase(fetchProjectShopDrawings.pending, (state, action) =>
        setProjectState(state, action, 'shopDrawingsLoading', 'pending')
      )
      .addCase(fetchProjectShopDrawings.fulfilled, (state, action) => {
        setProjectState(state, action, 'shopDrawingsLoading', 'idle');
        setProjectState(state, action, 'shopDrawings', action.payload);
      })
      .addCase(fetchProjectShopDrawings.rejected, (state, action) =>
        setProjectState(state, action, 'shopDrawingsLoading', 'idle')
      );
    builder
      .addCase(fetchProjectShopDrawingsDisciplines.pending, (state, action) =>
        setProjectState(state, action, 'shopDrawingDisciplinesLoading', 'pending')
      )
      .addCase(fetchProjectShopDrawingsDisciplines.fulfilled, (state, action) => {
        setProjectState(state, action, 'shopDrawingDisciplinesLoading', 'idle');
        setProjectState(state, action, 'shopDrawingDisciplines', action.payload);
      })
      .addCase(fetchProjectShopDrawingsDisciplines.rejected, (state, action) =>
        setProjectState(state, action, 'shopDrawingDisciplinesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectIssueStatusByCompany.pending, (state, action) => {
        setModuleState(state, action, 'issueStatusByCompanyLoading', 'pending');
      })
      .addCase(fetchProjectIssueStatusByCompany.fulfilled, (state, action) => {
        setModuleState(state, action, 'issueStatusByCompanyLoading', 'idle');
        setModuleState(state, action, 'issueStatusByCompany', action.payload.chartData);
        setModuleState(state, action, 'issueStatusByCompanyTotal', action.payload.total);
      })
      .addCase(fetchProjectIssueStatusByCompany.rejected, (state, action) =>
        setModuleState(state, action, 'issueStatusByCompanyLoading', 'idle')
      );

    builder
      .addCase(fetchProjectIssueByDiscipline.pending, (state, action) => {
        setModuleState(state, action, 'issueByDisciplineLoading', 'pending');
      })
      .addCase(fetchProjectIssueByDiscipline.fulfilled, (state, action) => {
        setModuleState(state, action, 'issueByDisciplineLoading', 'idle');
        setModuleState(state, action, 'issueByDiscipline', action.payload.chartData);
        setModuleState(state, action, 'issueByDisciplineTotal', action.payload.total);
      })
      .addCase(fetchProjectIssueByDiscipline.rejected, (state, action) =>
        setModuleState(state, action, 'issueByDisciplineLoading', 'idle')
      );
    builder
      .addCase(fetchProjectIssueByPriority.pending, (state, action) => {
        setModuleState(state, action, 'issueByPriorityLoading', 'pending');
      })
      .addCase(fetchProjectIssueByPriority.fulfilled, (state, action) => {
        setModuleState(state, action, 'issueByPriorityLoading', 'idle');
        setModuleState(state, action, 'issueByPriority', action.payload.chartData);
        setModuleState(state, action, 'issueByPriorityTotal', action.payload.total);
      })
      .addCase(fetchProjectIssueByPriority.rejected, (state, action) =>
        setModuleState(state, action, 'issueByPriorityLoading', 'idle')
      );

    builder
      .addCase(fetchProjectIssueStatusByZone.pending, (state, action) => {
        setModuleState(state, action, 'issueStatusByZoneLoading', 'pending');
      })
      .addCase(fetchProjectIssueStatusByZone.fulfilled, (state, action) => {
        setModuleState(state, action, 'issueStatusByZoneLoading', 'idle');
        setModuleState(state, action, 'issueStatusByZone', action.payload.chartData);
        setModuleState(state, action, 'issueStatusByZoneTotal', action.payload.total);
      })
      .addCase(fetchProjectIssueStatusByZone.rejected, (state, action) =>
        setModuleState(state, action, 'issueStatusByZoneLoading', 'idle')
      );
    builder
      .addCase(fetchProjectInstructionCauses.pending, (state, action) =>
        setModuleState(state, action, 'instructionCausesLoading', 'pending')
      )
      .addCase(fetchProjectInstructionCauses.fulfilled, (state, action) => {
        setProjectState(state, action, 'instructionCausesLoading', 'idle');
        setProjectState(state, action, 'instructionCauses', action.payload);
      })
      .addCase(fetchProjectInstructionCauses.rejected, (state, action) =>
        setProjectState(state, action, 'instructionCausesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectDesignRegisterPhases.pending, (state, action) =>
        setModuleState(state, action, 'designRegisterPhasesLoading', 'pending')
      )
      .addCase(fetchProjectDesignRegisterPhases.fulfilled, (state, action) => {
        setProjectState(state, action, 'designRegisterPhasesLoading', 'idle');
        setProjectState(state, action, 'designRegisterPhases', action.payload);
      })
      .addCase(fetchProjectDesignRegisterPhases.rejected, (state, action) =>
        setProjectState(state, action, 'designRegisterPhasesLoading', 'idle')
      );

    builder
      .addCase(fetchMyParametersSettingByProjectId.pending, (state) => {
        state.parametersLoading = 'pending';
      })
      .addCase(fetchMyParametersSettingByProjectId.fulfilled, (state, action) => {
        state.parametersLoading = 'idle';
        state.parametersSetting = action.payload;
      })
      .addCase(fetchMyParametersSettingByProjectId.rejected, (state) => {
        state.parametersLoading = 'idle';
      });
    builder
      .addCase(fetchProjectWorkPackages.pending, (state, action) =>
        setProjectState(state, action, 'workPackagesLoading', 'pending')
      )
      .addCase(fetchProjectWorkPackages.fulfilled, (state, action) => {
        setProjectState(state, action, 'workPackagesLoading', 'succeeded');
        setProjectState(state, action, 'workPackages', action.payload.rows);
        setProjectState(state, action, 'workPackagesCount', action.payload.count);
      })
      .addCase(fetchProjectWorkPackages.rejected, (state, action) =>
        setProjectState(state, action, 'workPackagesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectBaseIssues.pending, (state, action) => {
        setModuleState(state, action, 'baseIssuesLoading', 'pending');
      })
      .addCase(fetchProjectBaseIssues.fulfilled, (state, action) => {
        setModuleState(state, action, 'baseIssuesLoading', 'idle');
        setModuleState(state, action, 'baseIssues', action.payload.rows);
        setModuleState(state, action, 'baseIssueCount', action.payload.count);
        setModuleState(state, action, 'kpiData', action.payload.kpiData);
        setModuleState(state, action, 'baseIssueKpi', action.payload.kpiData);
      })
      .addCase(fetchProjectBaseIssues.rejected, (state, action) =>
        setModuleState(state, action, 'baseIssuesLoading', 'idle')
      );
    builder
      .addCase(fetchMaterialCategories.pending, (state, action) =>
        setProjectState(state, action, 'materialCategoriesLoading', 'pending')
      )
      .addCase(fetchMaterialCategories.fulfilled, (state, action) => {
        setProjectState(state, action, 'materialCategoriesLoading', 'idle');
        setProjectState(state, action, 'materialCategories', action.payload.rows);
      })
      .addCase(fetchMaterialCategories.rejected, (state, action) =>
        setProjectState(state, action, 'materialCategoriesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectCategories.pending, (state, action) => {
        setProjectState(state, action, 'categoriesLoading', 'pending');
      })
      .addCase(fetchProjectCategories.fulfilled, (state, action) => {
        setProjectState(state, action, 'categoriesLoading', 'idle');
        setProjectState(state, action, 'categories', action.payload.rows);
      })
      .addCase(fetchProjectCategories.rejected, (state, action) =>
        setProjectState(state, action, 'categoriesLoading', 'idle')
      );
    builder
      .addCase(fetchProjectSafetyRiskActivities.pending, (state, action) => {
        setProjectState(state, action, 'safetyRiskActivitiesLoading', 'pending');
      })
      .addCase(fetchProjectSafetyRiskActivities.fulfilled, (state, action) => {
        setProjectState(state, action, 'safetyRiskActivitiesLoading', 'idle');
        setProjectState(state, action, 'safetyRiskActivities', action.payload.rows);
      })
      .addCase(fetchProjectSafetyRiskActivities.rejected, (state, action) =>
        setProjectState(state, action, 'safetyRiskActivitiesLoading', 'idle')
      );
    builder
      .addCase(fetchEquipmentList.pending, (state, action) => {
        setProjectState(state, action, 'equipmentListLoading', 'pending');
      })
      .addCase(fetchEquipmentList.fulfilled, (state, action) => {
        setProjectState(state, action, 'equipmentListLoading', 'idle');
        setProjectState(state, action, 'equipmentList', action.payload.rows);
        setProjectState(state, action, 'equipmentListCount', action.payload.count);
      })
      .addCase(fetchEquipmentList.rejected, (state, action) =>
        setProjectState(state, action, 'equipmentListLoading', 'idle')
      );
    builder
      .addCase(fetchInspectionTestPlain.pending, (state, action) =>
        setProjectState(state, action, 'inspectionTestPlanLoading', 'pending')
      )
      .addCase(fetchInspectionTestPlain.fulfilled, (state, action) => {
        setProjectState(state, action, 'inspectionTestPlanLoading', 'succeeded');
        setProjectState(state, action, 'inspectionTestPlan', action.payload.rows);
        setProjectState(state, action, 'inspectionTestPlanCount', action.payload.count);
      })
      .addCase(fetchInspectionTestPlain.rejected, (state, action) =>
        setProjectState(state, action, 'inspectionTestPlanLoading', 'idle')
      );
    builder
      .addCase(fetchControlType.pending, (state, action) =>
        setProjectState(state, action, 'controlTypeLoading', 'pending')
      )
      .addCase(fetchControlType.fulfilled, (state, action) => {
        setProjectState(state, action, 'controlTypeLoading', 'succeeded');
        setProjectState(state, action, 'controlType', action.payload.rows);
      })
      .addCase(fetchControlType.rejected, (state, action) =>
        setProjectState(state, action, 'controlTypeLoading', 'idle')
      );
    builder
      .addCase(fetchFrequencies.pending, (state, action) =>
        setProjectState(state, action, 'frequenciesLoading', 'pending')
      )
      .addCase(fetchFrequencies.fulfilled, (state, action) => {
        setProjectState(state, action, 'frequenciesLoading', 'succeeded');
        setProjectState(state, action, 'frequencies', action.payload.rows);
      })
      .addCase(fetchFrequencies.rejected, (state, action) =>
        setProjectState(state, action, 'frequenciesLoading', 'idle')
      );
    builder
      .addCase(fetchQualityControlMatrices.pending, (state, action) =>
        setProjectState(state, action, 'qualityControlMatricesLoading', 'pending')
      )
      .addCase(fetchQualityControlMatrices.fulfilled, (state, action) => {
        setProjectState(state, action, 'qualityControlMatricesLoading', 'succeeded');
        setProjectState(state, action, 'qualityControlMatrices', action.payload.rows);
      })
      .addCase(fetchQualityControlMatrices.rejected, (state, action) =>
        setProjectState(state, action, 'qualityControlMatricesLoading', 'idle')
      );

    builder
      .addCase(fetchProjectDisciplineByFunction.pending, (state, action) =>
        setProjectState(state, action, 'functionDisciplinesLoading', 'pending')
      )
      .addCase(fetchProjectDisciplineByFunction.fulfilled, (state, action) => {
        setProjectState(state, action, 'functionDisciplinesLoading', 'succeeded');
        setProjectState(state, action, 'functionDisciplines', action.payload);
      })
      .addCase(fetchProjectDisciplineByFunction.rejected, (state, action) =>
        setProjectState(state, action, 'functionDisciplinesLoading', 'idle')
      );

    builder
      .addCase(fetchDashboardKpis.pending, (state, action) =>
        setModuleState(state, action, 'dashboardKpisLoading', 'pending')
      )
      .addCase(fetchDashboardKpis.fulfilled, (state, action) => {
        setModuleState(state, action, 'dashboardKpisLoading', 'succeeded');
        setModuleState(state, action, 'dashboardKpis', action.payload);
      })
      .addCase(fetchDashboardKpis.rejected, (state, action) =>
        setModuleState(state, action, 'dashboardKpisLoading', 'idle')
      );

    builder
      .addCase(fetchProjectCategoryByFunction.pending, (state, action) =>
        setProjectState(state, action, 'functionCategoriesLoading', 'pending')
      )
      .addCase(fetchProjectCategoryByFunction.fulfilled, (state, action) => {
        setProjectState(state, action, 'functionCategoriesLoading', 'succeeded');
        setProjectState(state, action, 'functionCategories', action.payload);
      })
      .addCase(fetchProjectCategoryByFunction.rejected, (state, action) =>
        setProjectState(state, action, 'functionCategoriesLoading', 'idle')
      );
  },
});

export const selectSelectedProjectId = (state: RootState) => state.myProjects.selectedProjectId;
export const selectSelectedProject = (state: RootState) => state.myProjects.selectedProject;

export const selectParametersSetting = (state: RootState) => state.myProjects.parametersSetting;

export const selectParametersLoading = (state: RootState) => state.myProjects.parametersLoading;

export const selectProjectById = createSelector(
  [(state: RootState) => state?.myProjects?.projects, selectSelectedProjectId],
  (projects, selectedProjectId) => {
    return selectedProjectId ? projects[selectedProjectId] : null;
  }
);

export const selectProjectData = createSelector([selectProjectById], (project) => {
  return project?.projectData;
});

export const selectProjectWorkBreakdownStructures = (state: RootState, projectId: string) => {
  return state.myProjects.projects[projectId]?.workBreakdownStructures;
};
export const selectProjectWorkBreakdownStructuresLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workBreakdownStructuresLoading;

export const selectDefaultProjectWorkBreakdownStructures = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workDefaultBreakdownStructures;
export const selectDefaultProjectWorkBreakdownStructuresLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workDefaultBreakdownStructuresLoading;
export const selectFolders = (state: RootState, projectId: string) => {
  return state.myProjects.projects[projectId]?.folder;
};

export const selectFoldersLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.folderLoading;

export const selectDefaultFolders = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.defaultFolder;

export const selectDefaultFoldersLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.defaultFolderLoading;
export const selectProjectControlType = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.controlType;
export const selectProjectControlTypeLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.controlTypesLoading;

export const selectProjectFrequencies = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.frequencies;
export const selectProjectFrequenciesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.frequenciesLoading;

export const selectProjectInspectionTestPlan = createSelector([selectProjectById], (project) => {
  return project?.inspectionTestPlan;
});

export const selectProjectInspectionTestPlanLoading = createSelector(
  [selectProjectById],
  (project) => project?.inspectionTestPlanLoading
);

export const selectProjectQualityControlMatrices = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.qualityControlMatrices;
export const selectProjectQualityControlMatricesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.qualityControlMatricesLoading;

export const selectNewProjectWorkBreakdownStructures = createSelector([selectProjectById], (project) => {
  return project?.workBreakdownStructures;
});
export const selectNewProjectWorkBreakdownStructuresLoading = createSelector([selectProjectById], (project) => {
  return project?.workBreakdownStructuresLoading;
});

export const selectWorkBreakDownStructureByLevel = (type: EWorkBreakDownStructureLevelID) =>
  createSelector([selectNewProjectWorkBreakdownStructures], (workBreakDownStructures) => {
    return workBreakDownStructures?.filter((item) => item.level === type) || [];
  });
export const selectProjectCompanies = createSelector([selectProjectById], (project) => project?.companies || []);

export const selectProjectUsers = createSelector([selectProjectById], (project) => project?.users || []);
export const selectProjectUsersLoading = createSelector([selectProjectById], (project) => project?.usersLoading);

export const selectProjectZones = createSelector([selectProjectById], (project) => project?.zones || []);

export const selectActivitiesByDiscipline = (disciplineId: string) =>
  createSelector([selectNewProjectWorkBreakdownStructures], (workBreakDownStructures) => {
    if (!disciplineId) return [];
    return workBreakDownStructures?.filter((wbs) => wbs?.parentId === disciplineId) || [];
  });

export const selectSubActivitiesByActivity = (selectedActivityId: string) =>
  createSelector([selectProjectInspectionTestPlan], (inspectionTestPlan) => {
    if (!selectedActivityId) return [];
    return inspectionTestPlan?.filter((ips) => ips.activityId === selectedActivityId) || [];
  });

export const selectControlPointsBySubActivity = (subActivityId: string) =>
  createSelector([selectProjectInspectionTestPlan], (inspectionTestPlan) => {
    if (!subActivityId) return [];
    return inspectionTestPlan?.filter((ips) => ips.parentId === subActivityId) || [];
  });

export const selectProjectDisciplinesByFunctionLoading = createSelector(
  [selectProjectById],
  (project) => project?.functionDisciplinesLoading || []
);
export const selectProjectWorkPackages = createSelector([selectProjectById], (project) => project?.workPackages || []);
export const selectProjectWorkPackagesLoading = createSelector(
  [selectProjectById],
  (project) => project?.workPackagesLoading || []
);

export const selectProjectDisciplinesByFunction = createSelector([selectProjectById], (project) => {
  return project?.functionDisciplines || [];
});
export const selectProjectCategoriesByFunctionLoading = createSelector(
  [selectProjectById],
  (project) => project?.functionCategoriesLoading || []
);

export const selectProjectCategoriesByFunction = createSelector([selectProjectById], (project) => {
  return project?.functionCategories || [];
});

export const selectProjectModules = createSelector(
  [selectProjectById],
  (selectedProject) => selectedProject?.modules || {}
);

export const { setSelectedProject, setSelectedProjectId } = myProjectsSlice.actions;

export default myProjectsSlice.reducer;
