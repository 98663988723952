import { Layout } from 'antd';
import NotificationLayoutSidebar from './NotificationLayoutSidebar';
import { ReactNode } from 'react';
import { Content } from 'antd/es/layout/layout';

const NotificationLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout hasSider>
      <NotificationLayoutSidebar />
      <Layout
        style={{
          marginLeft: 300,
          transition: 'all 0.25s',
          background: '#FFFFFF',
        }}
      >
        <Content className='main-content'>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default NotificationLayout;
