import { Carousel, StepProps, Steps } from 'antd';
import { CustomStepsBarIcon } from 'components/common';
import dayjs from 'dayjs';
import { mockPTWDetails } from 'mock-data/project-data';
import { useMemo } from 'react';
import { VALIDATION_STATUS } from 'utils/contants';
import { PTWFileUpload } from './PermitToWorkDetail';

export type PTWValidationData = {
  companyName?: string;
  companyCode?: string;
  status?: string;
  id?: string;
  userName?: string;
  updatedAt?: string;
  comment?: string;
  attachments?: PTWFileUpload[] | null;
  validationPictures?: PTWFileUpload[];
};
type PermitToWorkValidationStepProps = {
  baseIssues?: typeof mockPTWDetails;
  validationData: PTWValidationData[];
};

const PermitToWorkValidationStep = (props: PermitToWorkValidationStepProps) => {
  const mapValidationStatusToStep = (status: string) => {
    switch (status) {
      case VALIDATION_STATUS.APPROVED_WITH_COMMENT:
      case VALIDATION_STATUS.APPROVED:
        return 'finish';
      case VALIDATION_STATUS.REJECTED:
        return 'error';
      case VALIDATION_STATUS.REVIEW_IN_PROGRESS:
        return 'review-in-progress';
      default:
        return undefined;
    }
  };

  const validationStep = useMemo(() => {
    return props?.validationData?.map((validation) => {
      const status = mapValidationStatusToStep(validation?.status || '');
      return {
        status: (status === 'review-in-progress' ? 'process' : status) as StepProps['status'],
        className: `ant-steps-item-${status}`,
        icon: status ? <CustomStepsBarIcon status={status} CompanyName={validation?.companyCode || ''} /> : null,
        description: (
          <>
            <div className='font-size-14 text-color-polaris-black'>{validation?.companyName}</div>
            <div className='font-size-12 text-color-dark-grayish-blue'>
              {dayjs(validation?.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
            </div>
          </>
        ),
      };
    });
  }, [props.baseIssues]);

  const carouseItems = useMemo(() => {
    // if (!validationStep?.length) return [];
    const newValidationStep = [
      {
        status: 'submitted' as StepProps['status'],
        className: `ant-steps-item-submitted`,
        icon: <CustomStepsBarIcon status={'submitted'} CompanyName={props.baseIssues?.Company.companyCode || ''} />,
        description: (
          <>
            <div className='font-size-14 text-color-polaris-black'>{props.baseIssues?.Company.name || ''}</div>
            <div className='font-size-12 text-color-date-green'>
              {dayjs(props.baseIssues?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
            </div>
          </>
        ),
      },
      ...validationStep,
    ];

    const SIZE = 5;
    const totalSlide = Math.ceil(newValidationStep?.length / SIZE);

    return Array.from({ length: totalSlide }).map((_, index) => {
      const startIndex = index * SIZE;
      return newValidationStep?.slice(startIndex, startIndex + SIZE);
    });
  }, [validationStep]);

  if (!validationStep) return null;
  return (
    <Carousel infinite={false} className='wir-carousel-container'>
      {carouseItems.map((items, index) => {
        return (
          <div className='steps-bar-container wir-step-bar-container' key={index}>
            <Steps
              current={1}
              status='error'
              className='steps-bar__wir-step-bar'
              items={items}
              labelPlacement='vertical'
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default PermitToWorkValidationStep;
