import React, { useState } from 'react';
import { Flex, Button, Typography, Menu, Avatar } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UserProfile from './UserProfile';
import useAuthContext from 'store/auth-context';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';

interface UserSettingsProps {
}

const UserSettings: React.FC<UserSettingsProps> = (props) => {
  const { t } = useTranslation();
  const { onSignOut, profile } = useAuthContext();

  const [isOpenUserProfile, setIsOpenUserProfile] = useState(false);

  const handleUserProfileClick = () => {
    setIsOpenUserProfile(true);
  };

  return (
    <>
      <UserProfile
        isOpenUserProfile={isOpenUserProfile}
        setIsOpenUserProfile={setIsOpenUserProfile}
      />
      <div className="custom-user-menu">
        <Menu className="btn-settings">
          <Menu.Item key="avatar" icon="">
            <Flex justify="center" style={{ textAlign: 'center' }}>
              <div className="avatar pb-5">
                {profile?.profilePhoto ? (
                  <ImgItem src={profile?.profilePhoto} alt='' className='my-3 avatar-img'/>
                ) : (
                  <Avatar className='my-3'>
                    {profile?.firstName?.substring(0, 1)}
                  </Avatar>
                )}
                <Typography.Title level={5}>{profile?.fullName}</Typography.Title>
                <div className="text-small">{profile?.email}</div>
              </div>
            </Flex>
          </Menu.Item>
          <Menu.Item key="user-profile" icon="">
            <Button
              type="link"
              icon={<SettingOutlined />}
              onClick={handleUserProfileClick}
            >
              {t('User profile')}
            </Button>
          </Menu.Item>
          <Menu.Item key="logout" icon="">
            <Button
              type="link"
              icon={<LogoutOutlined />}
              onClick={onSignOut}
              className='btn-logout'
            >
              {t('Log out')}
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
};

export default UserSettings;
