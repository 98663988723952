import {
  useInspectionTestPlanParams,
  useInspectionTestPlain,
  useControlTypeParams,
  useControlTypes,
  useFrequencies,
  useFrequenciesParams,
  useQualityControlMatrices,
  useQualityControlMatricesParams,
  useProjectCompaniesParams,
  useProjectCompanies,
} from 'hooks';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { useMemo } from 'react';
import { QualityITPData } from 'types/project';
import ProjectQualityITPTable from './ProjectQualityITPTable/ProjectQualityITPTable';
import { WorkBreakdownStructure } from 'model';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
import { Omit } from '@reduxjs/toolkit/dist/tsHelpers';
import { Flex, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EInspectionTestPlanColumnKey } from 'types/general-setting';

type QualityITPListViewProps = {
  workspaceId: string;
  projectId: string;
};

const QualityITPListView = (props: QualityITPListViewProps) => {
  const { projectId, workspaceId } = props;
  const { t } = useTranslation();
  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    orderBy: 'itpOrder',
    mainPhase: true,
  });
  const [workBreakDownStructures] = useWorkBreakdownStructures(workBreakdownStructureQuery);

  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  const [inspectionTestPlan, isLoading] = useInspectionTestPlain(inspectionTestPlanParams);

  const [companiesParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);

  const [controlTypeParams] = useControlTypeParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
  });
  const [controlTypes] = useControlTypes(controlTypeParams);

  const [frequencyParams] = useFrequenciesParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
  });
  const [frequencies] = useFrequencies(frequencyParams);

  const [qualityControlMatricesParams] = useQualityControlMatricesParams();
  const [qualityControlMatrices] = useQualityControlMatrices(qualityControlMatricesParams);

  const mainPhase = useMemo(() => {
    return workBreakDownStructures?.find((wbs) => wbs?.isMain);
  }, [workBreakDownStructures]);

  const getChecker = (currentCompany: string, currentQualityControlMatrix: string) => {
    const companyResult = companies?.find((company) => company.id === currentCompany)?.name;
    const qualityControlMatrixResult = qualityControlMatrices?.find(
      (qualityControlMatrix) => qualityControlMatrix.id === currentQualityControlMatrix
    );

    if (!companyResult && !qualityControlMatrixResult) return undefined;

    return {
      company: companyResult,
      qualityControlMatrix: {
        code: qualityControlMatrixResult?.code,
        name: qualityControlMatrixResult?.name,
      },
    };
  };

  const buildChild = (
    parentId: string,
    children: (Omit<InspectionTestPlanModel, 'level' | 'order'> & { activityId: string })[],
    parentOrderText: string | number,
    level: number,
    result: QualityITPData[] = []
  ): QualityITPData[] => {
    let count = 0;
    children.forEach((child) => {
      if ((child.parentId || child.activityId) === parentId) {
        if (child.type === EInspectionTestPlanColumnKey.SubActivity) {
          const hasControlPoint = inspectionTestPlan.some((itp) => child.id === itp.parentId);
          if (!hasControlPoint) return;
        }
        const orderText = `${parentOrderText} ${++count}`;
        const children = buildChild(child?.id, inspectionTestPlan, orderText, level + 1);
        const currentControlType = controlTypes?.find((value) => value.id === child.inspectionTypeId) || undefined;

        const cloneData = JSON.parse(
          JSON.stringify({
            orderText: level < 3 ? orderText : '',
            name: child?.name,
            key: child?.id,
            controlType: {
              id: currentControlType?.id,
              label: currentControlType?.code,
              name: currentControlType?.name,
            },
            frequency: frequencies?.find((frequency) => frequency.id === child.frequencyId)?.name,
            template: {
              name: child?.TemplateAttachment?.name,
              url: child?.TemplateAttachment?.filePath,
              id: child?.TemplateAttachment?.id,
            },
            specs: {
              name: child?.SpecAttachment?.name,
              url: child?.SpecAttachment?.filePath,
              id: child?.SpecAttachment?.id,
            },
            checker1: getChecker(child.companyChecker1, child.qualityControlChecker1),
            checker2: getChecker(child.companyChecker2, child.qualityControlChecker2),
            checker3: getChecker(child.companyChecker3, child.qualityControlChecker3),
            children: children.length > 0 ? children : undefined,
          })
        );
        result.push(cloneData);
      }
    });

    return result;
  };
  const buildRoot = (
    disciplines: WorkBreakdownStructure[],
    activities: InspectionTestPlanModel[] | WorkBreakdownStructure[]
  ) => {
    return disciplines.map((discipline, index) => {
      const orderText = `${index + 1}`;
      return {
        orderText,
        name: discipline.name,
        key: discipline.id,
        children: buildChild(discipline.id, activities as InspectionTestPlanModel[], orderText, 1) || undefined,
      };
    });
  };
  const tableData = useMemo(() => {
    if (!workBreakDownStructures?.length || !inspectionTestPlan?.length) return;

    const activities = workBreakDownStructures.filter((wbs) => {
      return inspectionTestPlan.some((currentIps) => {
        const hasControlPoint = inspectionTestPlan.some((ips) => ips.parentId === currentIps.id);
        return currentIps.activityId === wbs.id && hasControlPoint;
      });
    });

    const discipline = workBreakDownStructures.filter((wbs) => {
      return activities.some((activity) => activity.parentId === wbs.id);
    });

    const result = buildRoot(discipline, activities);
    return result;
  }, [inspectionTestPlan, workBreakDownStructures, companies, controlTypes, frequencies, qualityControlMatrices]);

  const renderLegend = () => {
    return (
      <Flex className='legend'>
        <Typography.Title className='!text-[16px] shrink-0 font-bold mr-[20px]'>{t('Legend')}</Typography.Title>
        {qualityControlMatrices?.map((qualityControlMatrix) => (
          <Flex key={qualityControlMatrix.id} gap={8} align='center' className='checker-item'>
            <span className={`quality-control-matrix ${qualityControlMatrix?.code}`}>{qualityControlMatrix?.code}</span>
            <span>{qualityControlMatrix?.name}</span>
          </Flex>
        ))}
      </Flex>
    );
  };
  return (
    <div className='quality-itp-list-view'>
      <Typography.Title level={2} className='setting-section-header'>
        {t('Inspection & Test Plan - ITP')}
      </Typography.Title>
      <Flex justify='space-between' className='mb-2' gap={10}>
        <Flex className='shrink-0' align='center'>
          <Typography.Title className='!text-[16px]' level={2}>
            {t(`Current Phase: `)}
            <Tag color={'orange'} className='tag-dashed-btn font-normal'>
              {mainPhase?.name?.toUpperCase() || ''}
            </Tag>
          </Typography.Title>
        </Flex>
        <div>{renderLegend()}</div>
      </Flex>
      <ProjectQualityITPTable isLoading={isLoading !== 'succeeded' && isLoading !== 'failed'} data={tableData} />
    </div>
  );
};

export default QualityITPListView;
