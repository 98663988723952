import { Button, Form, Input, message, ModalProps, Typography } from 'antd';
import { CustomizeRequiredMark, Modal } from 'components';
import { Company } from 'model';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { companyService } from 'services';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';

type CreateCompanyProps = {
  isModalOpen: boolean;
  onSubmit: (values: Company) => void;
  setIsModalOpen: (value: boolean) => void;
} & ModalProps;
export default function CreateCompany(props: CreateCompanyProps) {
  const { isModalOpen, setIsModalOpen, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;

  const handleOk = async () => {
    try {
      const valid = await form.validateFields();
      if (valid) {
        const values = form.getFieldsValue();
        values.workspaceId = workspaceId;
        const res = await companyService.createCompany(workspaceId, values);
        onSubmit(res);
      }
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('Register a company')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk}>
          {t('Register company')}
        </Button>,
      ]}
    >
      <Typography.Title level={3} className='section-title'>
        {t('Overview')}
      </Typography.Title>
      <Form requiredMark={CustomizeRequiredMark} form={form} name='create-company' layout='vertical'>
        <Form.Item label={t('Company Name')} name='name' rules={[{ required: true, message: '' }]}>
          <Input placeholder={t('Company Name')} />
        </Form.Item>
        <Form.Item label={<>{t('Code (3 digits)')}</>} name='companyCode' rules={[{ message: '', max: 3, min: 3 }]}>
          <Input style={{ width: 60 }} placeholder={t('XXX')} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
