import { Issue } from 'model';
import { Key } from 'react';
import request from 'requesters/core.request';
import reportRequest from 'requesters/report.request';
import { PaginationData, QueryParams } from 'types';

type ReportPdfData = {
  reportName: string;
  reportCode: string;
  createdBy: string;
  userCreated?: string;
  orderBy: { key: string; direction: string }[];
  executiveSummary: string;
  fields: { [key: string]: boolean };
  emails: string[];
  issueIds?: Key[];
};
const issueService = {
  createIssue: async <T>(workspaceId: string, projectId: string, data: T) => {
    return request.post<Issue>(`/workspaces/${workspaceId}/projects/${projectId}/issues`, data);
  },
  updateIssue: async <T>(workspaceId: string, projectId: string, issueId: string, data: T) => {
    return request.put<Issue>(`/workspaces/${workspaceId}/projects/${projectId}/issues/${issueId}`, data);
  },
  getIssueById: async (workspaceId: string, projectId: string, issueId: string, query?: QueryParams) => {
    return request.get<Issue>(`/workspaces/${workspaceId}/projects/${projectId}/issues/${issueId}`, {
      params: query,
    });
  },
  deleteIssueById: async (workspaceId: string, projectId: string, issueId: string) => {
    return request.delete(`/workspaces/${workspaceId}/projects/${projectId}/issues/${issueId}`);
  },

  getAllIssues: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<Issue>>(`/workspaces/${workspaceId}/projects/${projectId}/issues`, {
      params: query,
    });
  },
  getIssueKpis: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<{
      kpiData: {
        draft: number;
        open: number;
        closed: number;
        readyForInspection: number;
        readyForInspectionOverdue: number;
        openOverdue: number;
      };
    }>(`/workspaces/${workspaceId}/projects/${projectId}/issues/kpis`, {
      params: query,
    });
  },
  getReportPdf: async (workspaceId: string, projectId: string, data: ReportPdfData, query: QueryParams) => {
    return reportRequest.post(`/workspaces/${workspaceId}/projects/${projectId}/issues/export-to-pdf`, data, {
      params: query,
    });
  },
  getDetailedReportPdf: async (workspaceId: string, projectId: string, data: ReportPdfData, query: QueryParams) => {
    return reportRequest.post(`/workspaces/${workspaceId}/projects/${projectId}/issues/export-to-pdf-details`, data, {
      params: query,
    });
  },
  getSummaryReportPdf: async (workspaceId: string, projectId: string, data: ReportPdfData, query: QueryParams) => {
    return reportRequest.post(`/workspaces/${workspaceId}/projects/${projectId}/issues/export-to-pdf-summary`, data, {
      params: query,
    });
  },
  getIssueStatusByCompany: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return reportRequest.get(`/workspaces/${workspaceId}/projects/${projectId}/issues/inspections-status-by-company`, {
      params: query,
    });
  },
  getIssueByDiscipline: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return reportRequest.get(`/workspaces/${workspaceId}/projects/${projectId}/issues/inspections-by-discipline`, {
      params: query,
    });
  },
  getIssueByPriority: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return reportRequest.get(`/workspaces/${workspaceId}/projects/${projectId}/issues/inspections-by-priority`, {
      params: query,
    });
  },
  getIssueStatusByZone: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return reportRequest.get(`/workspaces/${workspaceId}/projects/${projectId}/issues/inspections-status-by-zone`, {
      params: query,
    });
  },
  getIssueHistoryById: async (workspaceId: string, projectId: string, issueId: string, query?: QueryParams) => {
    return request.get(`/workspaces/${workspaceId}/projects/${projectId}/issues/${issueId}/issue-histories`, {
      params: query,
    });
  },
  createIssueHistory: async (
    workspaceId: string,
    projectId: string,
    issueId: string,
    data: {
      userCreatedId: string | undefined;
      title: string;
      comment: string;
      action: string;
    }
  ) => {
    return request.post(`/workspaces/${workspaceId}/projects/${projectId}/issues/${issueId}/issue-histories`, data);
  },
  getWorkspaceIssueIds: async (workspaceId: string, issueIds: string[], query?: QueryParams | null) => {
    return request.post<Issue[]>(
      `/workspaces/${workspaceId}/issues/issue-ids`,
      {
        issueIds: issueIds,
      },
      {
        params: query,
      }
    );
  },
};

export default issueService;
