import React from "react";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Card, Col, Dropdown, MenuProps, Row, Typography } from "antd";
import { motion } from "framer-motion";
import closeButton from "../../assets/images/icons/close-circle-filled.png";
import { Photo } from "model";
import { t } from "i18next";
import MotionImgItem from "components/common/pre-signed-url-items/MotionImgItem";

const { Text } = Typography;

interface PhotoItemProps {
  photo: Photo;
  onDelete: (photoId: string) => void;
  deletedPhotos: string[];
}

const handleMenuClick: MenuProps["onClick"] = (e) => {
  console.log("click", e);
};

const PhotoItem: React.FC<PhotoItemProps> = ({
  photo,
  onDelete,
  deletedPhotos,
}) => {
  if (deletedPhotos.includes(photo.id)) {
    return null;
  }

  const handleDelete = () => {
    onDelete(photo.id);
  };

  const items: MenuProps["items"] = [
    {
      label: t('Delete image'),
      key: "1",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => handleDelete(),
    },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <motion.div whileHover={{ scale: 1.05, transition: { duration: 0.35 } }}>
      <Card
        hoverable
        className={`project-card ${
          deletedPhotos.includes(photo.id) ? "hidden" : ""
        }`}
        style={{ width: "100%" }}
        cover={<MotionImgItem alt="" src={photo.picture} />}
      >
        <div className="project-star">
          <img src={closeButton} alt="" onClick={handleDelete} />
        </div>
        <Row className="project-card-footer">
          <Col flex={13} className="project-info">
            <div className="title">{photo.name}</div>
            <Text type="secondary">{photo.date}</Text>
          </Col>
          <Col flex={2} className="project-actions">
            <Dropdown menu={menuProps} trigger={["click"]}>
              <EllipsisOutlined
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  color: "rgba(0,0,0,0.45)",
                }}
              />
            </Dropdown>
          </Col>
        </Row>
      </Card>
    </motion.div>
  );
};

export default PhotoItem;
