import { Tag } from 'antd';
import { PushpinFilled } from '@ant-design/icons';
import { Flex } from 'antd';
import { Typography } from 'antd';

export default function ProjectWorkBreakdownStructureDefaultTree(props: {
  nodeColor: string;
  nodeTitle?: string;
  nodeKey: string;
  nodeCode: string;
}) {
  const { nodeColor, nodeTitle, nodeKey, nodeCode } = props;

  return (
    <Flex justify='space-between'>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ flexShrink: 0 }}>
          <Tag color={nodeColor}>
            {nodeTitle}
            {nodeKey === 'main' && <PushpinFilled style={{ marginLeft: '5px' }} />}
          </Tag>
        </div>
        <Typography.Text
          style={{
            textTransform: 'none',
            fontStyle: 'italic',
            fontWeight: '400',
            whiteSpace: 'nowrap',
          }}
        >
          {nodeKey === 'main' ? 'Main' : nodeCode}
        </Typography.Text>
      </div>
    </Flex>
  );
}
