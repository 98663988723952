import { Flex, Table, TableColumnsType, Tooltip, UploadFile } from 'antd';
import { Key } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QualityControlMatrix, QualityITPChecker, QualityITPData } from 'types/project';
import ProjectQualityITPTableAttachmentCell from './ProjectQualityITPTableAttachmentCell';
import { RightOutlined } from '@ant-design/icons';

type ProjectQualityITPTableProps = {
  data?: QualityITPData[];
  isLoading?: boolean;
};

const ProjectQualityITPTable = (props: ProjectQualityITPTableProps) => {
  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const getDefaultExpandRow = (item?: QualityITPData[], rowExpand = [] as Key[]) => {
    item?.forEach((data) => {
      if (data.children) {
        rowExpand.push(data.key);
        getDefaultExpandRow(data?.children, rowExpand);
      }
    });
    return rowExpand;
  };

  const renderExpandIcon = (props: {
    expanded: boolean;
    onExpand: (expanded: QualityITPData, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    record: QualityITPData;
  }) => {
    return (
      <span className='w-[18px]' style={{ position: 'absolute', top: '2px' }}>
        &nbsp;
        {props.record.children ? (
          <RightOutlined
            className={`expand-icon ${props.expanded ? 'expanded' : ''}`}
            onClick={(e) => props.onExpand(props.record, e)}
          />
        ) : null}
      </span>
    );
  };

  const renderChecker = (checker: { key: string; title: string }) => {
    return {
      title: checker.title,
      dataIndex: checker.key,
      className: 'checker-header',
      // width: '100px',
      // width: '50px',
      render: (value: QualityITPChecker) => {
        if (!value) return;
        const qualityControlMatrix = value?.qualityControlMatrix as QualityControlMatrix;
        const result = [qualityControlMatrix?.code, value?.company].filter(Boolean) as string[];
        return (
          <div>
            {result && result?.length === 2 ? (
              <Flex>
                <div>
                  <Tooltip title={`${result[0]}-${qualityControlMatrix.name}`} placement='top'>
                    <span className={`quality-control-matrix ${result[0]}`}>{result[0]}</span>
                  </Tooltip>
                  <span style={{ wordBreak: 'break-all' }}> - {result[1]}</span>
                </div>
              </Flex>
            ) : qualityControlMatrix?.code ? (
              <Tooltip title={`${result[0]}-${qualityControlMatrix.name}`} placement='top'>
                <span className={`quality-control-matrix ${result[0]}`}>{result[0]}</span>
              </Tooltip>
            ) : (
              <span style={{ wordBreak: 'break-all' }}>{result[0]}</span>
            )}
          </div>
        );
      },
    };
  };

  const columns: TableColumnsType<QualityITPData> = [
    {
      title: t('Control Point'),
      dataIndex: 'name',
      width: '20%',
      className: 'control-point',
      render: (value: string, record: QualityITPData) => {
        return (
          <div>
            {record?.orderText?.split(' ').join('.')} {value || ''}
          </div>
        );
      },
    },
    {
      title: t('Control Type'),
      dataIndex: 'controlType',
      align: 'center',
      render: (value) => {
        if (!value) return null;
        return (
          <div className={`status-box`}>
            <Tooltip title={value.name} placement='top'>
              <span className={`status-${value?.label}`}>{value?.label}</span>
            </Tooltip>
          </div>
        );
      },
      className: 'control-type',
    },
    {
      title: t('Frequency'),
      dataIndex: 'frequency',
      align: 'center',
      // width: 50,
      render: (value: string) => {
        return <div>{value || ''}</div>;
      },
    },
    {
      title: t('Inspection Checklist'),
      dataIndex: 'template',
      align: 'center',
      width: 110,
      className: 'ellipsis-text-cell',
      render: (file: UploadFile) => {
        if (!file?.url) return '';
        return <ProjectQualityITPTableAttachmentCell url={file?.url} />;
      },
    },
    {
      title: t('Specs'),
      align: 'center',
      width: 110,
      dataIndex: 'specs',
      render: (file: UploadFile) => {
        if (!file?.url) return '';
        return <ProjectQualityITPTableAttachmentCell url={file?.url} />;
      },
    },
    {
      title: t(''),
      children: [
        renderChecker({
          title: t('checker 1'),
          key: 'checker1',
        }),
        renderChecker({
          title: t('checker 2'),
          key: 'checker2',
        }),
        renderChecker({
          title: t('checker 3'),
          key: 'checker3',
        }),
      ],
    },
  ];

  useEffect(() => {
    const defaultExpandRow = getDefaultExpandRow(props?.data);
    setExpandedRowKeys(defaultExpandRow);
  }, [props.data?.length]);

  return (
    <div>
      <Table
        tableLayout='fixed'
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: expandedRowKeys,
          expandIcon: renderExpandIcon,
          onExpand: (hasExpand, record) => {
            setExpandedRowKeys(
              hasExpand ? [...expandedRowKeys, record.key] : expandedRowKeys.filter((key) => key !== record.key)
            );
          },
        }}
        bordered
        dataSource={props?.data}
        columns={columns}
        loading={props.isLoading}
        pagination={false}
      />
    </div>
  );
};

export default ProjectQualityITPTable;
