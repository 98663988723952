import { useEffect, useState } from 'react';
import { Col, Row, Segmented, Spin, Typography } from 'antd';
import { ReadOnlyDataField } from 'components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Attachment, AttachmentInfo, AttachmentType, BaseIssue, User, WorkPackage, Zone } from 'model';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { convertZoneToTreeData } from 'utils';
import { MaterialDetailType } from 'components/project-material-approval-requests/CreateOrUpdateMAR/MaterialDetail';
import { DataNode } from 'antd/es/tree';
import { findPath } from 'components/common/TreeSelectTagRender';
import { selectActivitiesByDiscipline, selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { useAppSelector } from 'store';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import MARsListDocumentSection from 'components/project-material-approval-requests/CreateOrUpdateMAR/MARsListDocument';
import MARsListPicture from 'components/project-material-approval-requests/CreateOrUpdateMAR/MARsListPicture';
import useUserEntity from 'hooks/common/useUserProfile';
import { useInspectionTestPlain, useInspectionTestPlanParams } from 'hooks';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';

type MARsInformationProps = {
  users: User[];
  MAR: BaseIssue | null;
  loading?: boolean;
  attachmentTypes: AttachmentType[];
  workPackages?: WorkPackage[];
  zones?: Zone[];
  projectId: string;
  workspaceId: string;
  attachments: Attachment[];
  fetchMarAttachments: (position?: string) => void;
};
// const { Title } = Typography;

export default function MARsInformation(props: MARsInformationProps) {
  const { t } = useTranslation();
  const {
    users,
    MAR,
    loading,
    attachmentTypes,
    workPackages,
    zones,
    projectId,
    workspaceId,
    fetchMarAttachments,
    attachments,
  } = props;
  // const [documents, setDocuments] = useState<{proposed: AttachmentInfo[], specified: AttachmentInfo[]}>({proposed: [], specified: []});
  // const [imageUrls, ] = useState<{proposed: string[], specified: string[]}>({proposed: [], specified: []});
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [documentsInfo, setDocumentsInfo] = useState<{ proposed: AttachmentInfo[]; specified: AttachmentInfo[] }>({
    proposed: [],
    specified: [],
  });
  const marPhoto = attachmentTypes.find((attachmentType) => attachmentType.code === ATTACHMENT_TYPES.MAR_PHOTO);
  const marDoc = attachmentTypes.find((attachmentType) => attachmentType.code === ATTACHMENT_TYPES.MAR_DOCUMENT);
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const watchedDiscipline = MAR?.MaterialApprovalRequest?.disciplineId ?? '';
  const activities = useAppSelector(selectActivitiesByDiscipline(watchedDiscipline));
  const [position, setPosition] = useState<MaterialDetailType>('proposed');
  const { user } = useUserEntity({ projectId, workspaceId });
  const watchedsubAtivities = MAR?.MaterialApprovalRequest?.subActivityId ?? '';
  const watchedControlPoint = MAR?.MaterialApprovalRequest?.controlPointId ?? '';
  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  const inspectionTestPlan = useInspectionTestPlain(inspectionTestPlanParams);
  const subActivities = inspectionTestPlan[0]?.filter(
    (subActivity: InspectionTestPlanModel) => subActivity.id === watchedsubAtivities
  );
  const controlPoints = inspectionTestPlan[0]?.filter(
    (subActivity: InspectionTestPlanModel) => subActivity.id === watchedControlPoint
  );
  const materialProposed = (MAR?.MaterialApprovalRequest?.MarDescriptions || []).find(
    (material: { isProposed: boolean }) => material.isProposed === true
  );
  const materialSpecified = (MAR?.MaterialApprovalRequest?.MarDescriptions || []).find(
    (material: { isProposed: boolean }) => material.isProposed === false
  );
  useEffect(() => {
    const attachmentDocs: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] } = { proposed: [], specified: [] };
    const attachmentPhotos: { proposed: string[]; specified: string[] } = { proposed: [], specified: [] };

    (materialProposed?.Attachments || []).forEach((attachment: Attachment) => {
      if (attachment.attachmentTypeId === marDoc?.id) {
        attachmentDocs.proposed.push({
          name: attachment?.name || '',
          filePath: attachment?.filePath || '',
          type: 'Document',
          attachmentTypeId: marPhoto?.id || '',
        });
      } else {
        attachmentPhotos.proposed.push(attachment?.filePath || '');
      }
    });

    (materialSpecified?.Attachments || []).forEach((attachment: Attachment) => {
      if (attachment.attachmentTypeId === marDoc?.id) {
        attachmentDocs.specified.push({
          name: attachment?.name || '',
          filePath: attachment?.filePath || '',
          type: 'Document',
          attachmentTypeId: marPhoto?.id || '',
        });
      } else {
        attachmentPhotos.specified.push(attachment?.filePath || '');
      }
    });
  }, [materialProposed, materialSpecified, marDoc, marPhoto]);
  const options = [{ label: t('Material Proposed'), value: 'proposed' }];
  const handlePositionChange = (value: string) => {
    setPosition(value as MaterialDetailType);
  };
  useEffect(() => {
    const tree = convertZoneToTreeData(zones);
    setZonesTreeData(tree);
  }, [zones]);
  useEffect(() => {
    fetchMarAttachments();
  }, [MAR]);
  return (
    <Spin spinning={loading} delay={500}>
      {MAR && (
        <>
          <Row className={'read-only-row'} style={{ margin: '15px 0' }}>
            <Col span={24}>
              <ReadOnlyDataField title={'MAR name'} content={MAR.name} />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField title={'MAR Number'} content={MAR.MaterialApprovalRequest?.marNumber} />
            </Col>
            <Col span={12}>
              <ReadOnlyDataField title={'Submitted on'} content={dayjs(MAR.createdAt).format('YYYY-MM-DD')} />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField title={'Company Originator'} content={MAR.Company?.name} />
            </Col>
            <Col span={12}>
              <ReadOnlyDataField
                title={'Originator Name'}
                content={users.find((user) => user.id == MAR.originatorId)?.fullName}
              />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={24}>
              <ReadOnlyDataField
                title={'Work Package'}
                content={workPackages?.find((ws) => ws.id === MAR?.MaterialApprovalRequest?.workPackageId)?.name}
              />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField
                title={'Discipline'}
                content={
                  disciplines.find((discipline) => discipline.id === MAR?.MaterialApprovalRequest?.disciplineId)
                    ?.name || '--'
                }
              />
            </Col>
            <Col span={12}>
              <ReadOnlyDataField
                title={'Activity'}
                content={
                  activities?.find((activity) => activity.id === MAR?.MaterialApprovalRequest?.activityId)?.name || '--'
                }
              />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField title={'Sub-activity'} content={subActivities?.[0]?.name || '--'} />
            </Col>
            <Col span={12}>
              <ReadOnlyDataField title={'Control Point'} content={controlPoints?.[0]?.name || '--'} />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField
                title={'Zoning'}
                content={MAR.Zone?.id ? findPath(zonesTreeData, MAR?.Zone?.id) : '--'}
              />
            </Col>
          </Row>
          <div className={'read-only-row'}>
            <Segmented
              className={'funtion-segmented'}
              options={options}
              value={position}
              onChange={handlePositionChange}
            />
          </div>
          <Row className={'read-only-row'}>
            <Col span={24}>
              <MARsListDocumentSection
                isReadOnly={true}
                selectedMarBaseIssue={MAR}
                workspaceId={workspaceId}
                projectId={projectId}
                position={position}
                documentsInfo={documentsInfo}
                setDocumentsInfo={setDocumentsInfo}
                attachments={attachments}
                fetchMarAttachments={(position?: string) => fetchMarAttachments(position)}
              />
            </Col>
          </Row>
          <Row className={'read-only-row'}>
            <ReadOnlyDataField
              title={'Description Of The Material'}
              content={
                MAR?.MaterialApprovalRequest?.MarDescriptions?.[0]?.description
                  ? MAR.MaterialApprovalRequest.MarDescriptions[0].description
                  : '--'
              }
            />
          </Row>
          <Row className={'read-only-row'}>
            <Col span={24}>
              <Typography.Title className='material-group-title' level={3}>
                {t('Pictures')}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <MARsListPicture
                isReadOnly={true}
                selectedMarBaseIssue={MAR}
                workspaceId={workspaceId}
                projectId={projectId}
                position={position}
                materialLoading={false}
                attachments={attachments}
                photosInfo={documentsInfo}
                setPhotosInfo={setDocumentsInfo}
                fetchMarAttachments={(position: string) => fetchMarAttachments(position)}
                user={user}
              />
            </Col>
          </Row>
        </>
      )}
    </Spin>
  );
}
