import { useMemo } from 'react';
import { QueryParams } from 'types';
import { WorkPackage } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchProjectWorkPackages } from 'store/my-projects.slice';
import { RootState } from 'store';

type WorkPackagesParamsType = {
  workspaceId: string;
  projectId: string;
} & QueryParams;

const selectProjectWorkPackages = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workPackages;

const selectProjectWorkPackagesCount = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workPackagesCount;

const selectProjectWorkPackagesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.workPackagesLoading;

export const useMyWorkspaceWorkPackagesParams = (
  options: {
    workspaceId?: string;
  } & QueryParams
) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId]);
};

export const useProjectWorkPackagesParams = (
  options: WorkPackagesParamsType
): [query: WorkPackagesParamsType, onParamChange: (param?: QueryParams) => void] => {
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const filteredParams = useMemo(() => {
    return { ...params, ...options };
  }, [params]);

  return [filteredParams, onParamChange];
};

export const useProjectWorkPackages = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<WorkPackage[]>({
    action: fetchProjectWorkPackages,
    dataSelector: (state: RootState) => selectProjectWorkPackages(state, projectId),
    loadingSelector: (state: RootState) => selectProjectWorkPackagesLoading(state, projectId),
    params: query,
  });
};

export const useProjectWorkPackagesCount = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<number>({
    action: fetchProjectWorkPackages,
    dataSelector: (state: RootState) => selectProjectWorkPackagesCount(state, projectId),
    loadingSelector: (state: RootState) => selectProjectWorkPackagesLoading(state, projectId),
    params: query,
  });
};
