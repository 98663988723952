
import { Workspace } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import {
  fetchMyWorkspace,
  selectMyWorkspace,
  selectMyWorkspaceLoading,
} from 'store/my-workspace.slice';
import { useMemo } from 'react';
import { QueryParams } from 'types';
import { fetchWorkspaceById, selectProjectWorkspace, selectProjectWorkspaceLoading } from 'store/other-workspaces.slice';

export const useMyWorkspaceParams = (
  options?: QueryParams & { [key: string]: any }
) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
    };

    return [queryParams];
  }, []);
};
export const useMyWorkspace = (query: any) => {
  return useFetchByParams<Workspace>({
    action: fetchMyWorkspace,
    dataSelector: selectMyWorkspace,
    loadingSelector: selectMyWorkspaceLoading,
    params: query,
  });
};

export const useWorkspaceByIdParams = (
  options?: QueryParams & { [key: string]: any }
) => {
  const [query] = useQueryParams<QueryParams>();
  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
    };
    return [queryParams];
  }, []);
};
export const useWorkspaceById = (query: any) => {
  return useFetchByParams<Workspace>({
    action: fetchWorkspaceById,
    dataSelector: selectProjectWorkspace,
    loadingSelector: selectProjectWorkspaceLoading,
    params: query,
  });
};

