import { CustomizeRequiredMark } from 'components/common';
import { Col, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Company, IssuePriority, User } from 'model';
import { useMemo, useState } from 'react';
import { ProjectDocumentsTransmissionListDocument } from 'components';
import { UploadMultipleDocumentFileValue } from '../../common/UploadMultipleDocument';
import dayjs, { Dayjs } from 'dayjs';
import EditableFormField from 'components/common/EditableFormField';
import { mapDataToOptions } from 'utils/object';
import { useAppSelector } from 'store';
import { selectIssuePriorities } from 'store/common.slice';
import UserDistribution from '../../common/UserDistribution';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';

const { Title, Text } = Typography;

export type DocumentsTransmissionFormValues = {
  companyId: string | null;
  assigneeIds: string[] | null;
  description?: string;
  invitations?: {
    companyId?: string | null;
    inspectorIds?: string[];
  }[];
  subject: string;
  issuePriorityId: string;
  transmissionDate?: string | Dayjs;
  comments: string;
  companyIssuer: string;
  issuerName: string;
  transmittalDocuments: UploadMultipleDocumentFileValue[];
};

type ProjectDocumentsTransmissionFormProps = {
  onValuesChange?: (values: DocumentsTransmissionFormValues) => void;
  isOpen: boolean;
  workspaceId: string;
  projectId: string;
  users: User[];
  companies: Company[];
  profile?: User | null;
  attachmentsInfo: { name: string; revision: string; filePath: string }[];
  setAttachmentsInfo: (value: { name: string; revision: string; filePath: string }[]) => void;
  issuePriorities: IssuePriority[];
  isReadOnly: boolean;
};
export default function ProjectDocumentsTransmissionForm(props: ProjectDocumentsTransmissionFormProps) {
  const { t } = useTranslation();
  const {
    onValuesChange,
    isOpen,
    workspaceId,
    projectId,
    users,
    attachmentsInfo,
    setAttachmentsInfo,
    companies,
    isReadOnly,
  } = props;
  const form = useANTDFormInstance<DocumentsTransmissionFormValues>();
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const issuePriorities = useAppSelector(selectIssuePriorities);
  const watchedAssigneeIds = Form.useWatch('assigneeIds');
  const watchedInvitations = Form.useWatch('invitations');
  const issuerName = Form.useWatch('issuerName') || form.getFieldValue('issuerName');

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeIds', null);
  };

  const onChangeAssignee = (assigneeIds: string[]) => {
    if (!assigneeIds) {
      form.setFieldValue('assigneeIds', null);
    } else {
      const selectedUser = users?.find((user) => user.id === assigneeIds[0]);
      const existingCompany = companies?.findIndex(
        (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
      );
      if (companies[existingCompany]?.id) setSelectedCompanyId(companies[existingCompany]?.id);
      if (existingCompany > -1) {
        form.setFieldValue('companyId', selectedUser?.WorkspaceUser?.Company?.id || '');
      } else {
        form.setFieldValue('companyId', null);
      }
    }
    form.validateFields(['companyId']);
  };

  const assigneeFilter = useMemo(() => {
    const distributions = (watchedInvitations as DocumentsTransmissionFormValues['invitations'])?.flatMap(
      (invitation) => invitation?.inspectorIds
    );
    const newUser = users.filter((user) => !distributions?.includes(user.id));

    return newUser?.filter(
      (user) => (!selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId) && user.id !== issuerName
    );
  }, [selectedCompanyId, isOpen, watchedInvitations, issuerName]);

  const distributionFilter = useMemo(() => {
    return users.filter((user) => !watchedAssigneeIds?.includes(user.id));
  }, [watchedAssigneeIds]);

  return (
    <Form
      requiredMark={CustomizeRequiredMark}
      form={form}
      layout='vertical'
      className={'request-for-information-form'}
      onValuesChange={onValuesChange}
      style={{ paddingTop: '10px' }}
    >
      <Row>
        <Col span={24}>
          <Title className='form-title' level={3}>
            {t('From')}
          </Title>
          <EditableFormField
            isReadonly={isReadOnly}
            columns={[
              {
                label: t('Company Issuer'),
                name: 'companyIssuer',
                type: 'select',
                rules: [{ required: true, message: '' }],
                editableProps: {
                  options: mapDataToOptions(companies),
                  placeholder: t('Select Company Originator'),
                  disabled: true,
                },
              },
              {
                label: t('Issuer Name'),
                name: 'issuerName',
                type: 'select',
                rules: [{ required: true, message: '' }],
                editableProps: {
                  options: mapDataToOptions(users, 'fullName'),
                  placeholder: t('Enter Originator name'),
                  disabled: true,
                },
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Title className='form-title' level={3}>
            {t('To')}
          </Title>

          <EditableFormField
            isReadonly={isReadOnly}
            columns={[
              {
                label: t('Receiver Company'),
                name: 'companyId',
                type: 'select',
                rules: [{ required: true, message: '' }],
                editableProps: {
                  options: companies?.map((option) => ({
                    value: option.id,
                    label: option.name,
                  })),
                  placeholder: t('Select Receiver Company'),
                  onChange: onChangeCompany,
                },
              },
              {
                label: t('Recipient Names'),
                name: 'assigneeIds',
                type: 'multiple',
                rules: [{ required: true, message: '' }],
                editableProps: {
                  options: assigneeFilter?.map((option) => {
                    return {
                      value: option.id,
                      label: option.fullName,
                    };
                  }),
                  placeholder: t('Select Recipient Names'),
                  onChange: onChangeAssignee,
                },
              },
            ]}
          />
        </Col>
        <Col span={24}>
          {isReadOnly ? (
            <>
              <Title className='form-title' level={3}>
                {t('Distribution')}
              </Title>
              <Row gutter={16}>
                <Col span={12}>
                  <span className='text-size-12 text-color-secondary'>{t('Company Name')}</span>
                </Col>
                <Col span={12}>
                  <span className='text-size-12 text-color-secondary'>{t('Emails / Names')}</span>
                </Col>
              </Row>
              {form.getFieldValue('invitations')?.map((item, index: number) => {
                const company = companies?.find((company) => company?.id === item.companyId);
                return (
                  <Row gutter={16} key={index} className='mb-2'>
                    <Col span={12}>
                      <Text>{company?.name}</Text>
                    </Col>
                    <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      {item.inspectorIds?.map((userId, index) => {
                        const selectedUser = users.find((user) => user.id === userId);
                        return <Text key={index}>{selectedUser?.fullName}</Text>;
                      })}
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : (
            <UserDistribution
              form={form}
              projectId={projectId}
              companyOriginator={''}
              users={distributionFilter}
              isOpen={isOpen}
              isReadOnly={isReadOnly}
              maxCompanies={3}
            />
          )}
        </Col>
        <Col span={24}>
          <Title className='form-title' level={3}>
            {t('Document Details')}
          </Title>
          <EditableFormField
            isReadonly={isReadOnly}
            columns={[
              {
                name: 'subject',
                label: t('Subject'),
                type: 'input',
                rules: [
                  { required: true, message: '' },
                  { max: 100, message: '' },
                ],
                editableProps: {
                  showCount: true,
                  maxLength: 100,
                  allowClear: true,
                  placeholder: t('Enter transmittal name'),
                },
              },
            ]}
          />

          <EditableFormField
            isReadonly={isReadOnly}
            columns={[
              {
                name: 'issuePriorityId',
                rules: [{ required: true, message: '' }],
                label: t('Priority'),
                type: 'select',
                editableProps: {
                  options: mapDataToOptions(issuePriorities),
                  placeholder: t('Select priority'),
                },
              },
              {
                name: 'transmissionDate',
                label: t('Transmission Date'),
                type: 'date',
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !getFieldValue('transmissionDate') ||
                        (value &&
                          getFieldValue('transmissionDate') &&
                          value > getFieldValue('transmissionDate')?.subtract(1, 'day'))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error());
                    },
                  }),
                ],
                editableProps: {
                  disabled: true,
                  placeholder: t('Select date'),
                  format: 'YYYY-MM-DD',
                  disabledDate: (date) => date && date.isBefore(dayjs(), 'day'),
                },
              },
            ]}
          />
        </Col>

        <Col span={24} className={'mt-4'}>
          <ProjectDocumentsTransmissionListDocument
            isReadOnly={isReadOnly}
            workspaceId={workspaceId}
            projectId={projectId}
            attachmentsInfo={attachmentsInfo}
            setAttachmentsInfo={setAttachmentsInfo}
          />
        </Col>
        <Col span={24} className={'mt-5'}>
          <Title className='form-title' level={3}>
            {t('Comments')}
          </Title>
          <EditableFormField
            isReadonly={isReadOnly}
            columns={[
              {
                name: 'comments',
                type: 'textarea',
                editableProps: {
                  rows: 3,
                  placeholder: t('Add comment here...'),
                },
              },
            ]}
          />
        </Col>
      </Row>
    </Form>
  );
}
