import React, { useState, useEffect } from 'react';
import { Button, Layout, Checkbox, Col, Form, Radio, Row, Select, Flex, Typography, message, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
// import { parametersService } from 'services';
import { useDateFormats, useDateFormatsParams, useParametersSetting, useTimeZones, useTimeZonesParams } from 'hooks';
import { projectService } from 'services';
import { useParams } from 'react-router-dom';
import { useMyWorkspaceParams } from '../../../hooks/workspace';
import { ProjectContent } from 'components';
const { Content } = Layout;
const { Option } = Select;
interface SubmitDataSettings {
  [key: string]: unknown;
  lang?: string;
  date_format?: string;
  currency?: string;
  metric?: string;
  working_days?: string | string[];
  manpower_unit?: string;
  mfa?: string | boolean;
  session_time_out?: string;
  timezone?: string;
}
const { Title } = Typography;

const handleChangeDateFormat = (value: string) => {
  console.log(`selected ${value}`);
};

export default function LocalSettings() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const { projectId } = params;
  const [parametersParams] = useMyWorkspaceParams({ projectId });
  const [parametersSettings, parametersLoading, refreshMyParameters] = useParametersSetting(parametersParams);

  const [dateFormatsParams] = useDateFormatsParams();
  const [dateFormats] = useDateFormats(dateFormatsParams);
  const [timeZonesParams] = useTimeZonesParams();
  const [timeZones] = useTimeZones(timeZonesParams);

  const plainOptions = [
    { label: t('Monday'), value: 'A' },
    { label: t('Tuesday'), value: 'B' },
    { label: t('Wednesday'), value: 'C' },
    { label: t('Thursday'), value: 'D' },
    { label: t('Friday'), value: 'E' },
    { label: t('Saturday'), value: 'F' },
    { label: t('Sunday'), value: 'G' },
  ];

  const [checkedList, setCheckedList] = useState<string[]>([]);

  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  useEffect(() => {
    // Set the default values after the component is mounted
    setCheckedList(['A', 'B', 'C', 'D', 'E', 'F']);
  }, []);

  // function handleUpdateParametersSettings(arg0: string): void {
  //   throw new Error("Function not implemented.");
  // }
  useEffect(() => {
    if (parametersLoading !== 'pending') {
      getParametersSetting();
    }
  }, [parametersLoading]);

  const getParametersSetting = () => {
    if (parametersSettings && parametersSettings?.length > 0) {
      parametersSettings.forEach((setting) => {
        switch (setting.code) {
          case 'date_format':
            form.setFieldValue('date_format', setting.value);
            break;
          case 'timezone':
            form.setFieldValue('timezone', setting.value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleUpdateParametersSettings = async (field?: string) => {
    try {
      const vaild = await form.validateFields();
      if (vaild) {
        try {
          const fieldsValue = form.getFieldsValue();
          let values: SubmitDataSettings = {};
          switch (field) {
            case 'Time & date':
              values = {
                date_format: fieldsValue.date_format,
                timezone: fieldsValue.timezone,
              };
              break;
          }
          const updatedSettings = Object.keys(values)
            .filter((key) => values[key] !== null && values[key] !== undefined) // Filter out null/undefined
            .map((key) => {
              return { code: key, value: values[key] };
            });
          if (projectId) {
            await projectService.updateProjectSettings(projectId, {
              projectSettings: updatedSettings,
            });
          }
          refreshMyParameters();
          message.success(t('Update parameters success!'));
        } catch (error) {
          console.log('handleChangeParameters error', error);
          message.error(t('Oop! Something wrong'));
        }
      }
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };
  return (
    <ProjectContent>
      <Content className='project-settings-common-page'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Title>{t('Local Settings')}</Title>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content className='settings-content'>
          <Form form={form} name='validate_other' layout='vertical'>
            <Flex className='setting-section-block'>
              <Flex className='setting-section-header' justify='space-between' align='center'>
                <Typography.Title level={3}>{t('Time & date')}</Typography.Title>
                <Button onClick={() => handleUpdateParametersSettings('Time & date')}>{t('Save')}</Button>
              </Flex>
              <Row gutter={24} align='middle'>
                <Col sm={24} md={12}>
                  <Form.Item label={t('Date format')} name='date_format'>
                    <Select
                      placeholder={t('Please select the date format')}
                      onChange={handleChangeDateFormat}
                      options={dateFormats.map((value) => ({
                        value: value.dateFormat,
                        label: (
                          <Flex justify='space-between' align='middle'>
                            <Typography.Text>{value.dateFormat}</Typography.Text>
                            <Typography.Text>{value.example}</Typography.Text>
                          </Flex>
                        ),
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Typography.Text className='ant-form-item-extra'>{t('Applies to all the dates')}</Typography.Text>
                </Col>
              </Row>

              <Row gutter={24} align='middle'>
                <Col sm={24} md={12}>
                  <Form.Item label={t('Time zone')} name='timezone'>
                    <Select
                      placeholder={t('Please select the time zone')}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={timeZones.map((value) => ({
                        value: value.name,
                        label: `(${value.abbrev} ${value.utcOffset}) ${value.name}`,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Typography.Text className='ant-form-item-extra'>
                    {t(
                      'Time zones are standadized in UTC. Setting time zone here will apply to all date/ time related features'
                    )}
                  </Typography.Text>
                </Col>
              </Row>

              <Button style={{ width: 'fit-content' }} type='dashed' className='primary-dashed-btn'>
                {t('Detect my time zone')}
              </Button>
            </Flex>
            <Flex className='setting-section-block' hidden>
              <Flex className='setting-section-header' justify='space-between' align='center'>
                <Typography.Title level={3}>{t('Units')}</Typography.Title>
                <Button disabled>{t('Save')}</Button>
              </Flex>
              <Form.Item label={t('Measurement units system')}>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Radio.Group>
                      <Radio value='a'>{t('Metric/SI')}</Radio>
                      <Radio value='b'>{t('US/Imperial')}</Radio>
                    </Radio.Group>
                  </Col>
                  <Col sm={24} md={12}>
                    <Typography.Text className='ant-form-item-extra'>
                      {t('Measurement units for distance, weight...')}
                    </Typography.Text>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label={t('Currency') + ':'}>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Select placeholder={t('Please select currency')}>
                      <Option value='dollars'>$ - American dollar (usd)</Option>
                      <Option value='euro'>€ - European dollars (euro)</Option>
                    </Select>
                  </Col>
                  <Col sm={24} md={12}>
                    <Typography.Text className='ant-form-item-extra'>
                      {t('Currencies are used in cost and budgeting')}
                    </Typography.Text>
                  </Col>
                </Row>
              </Form.Item>
            </Flex>
            <Flex className='setting-section-block' hidden>
              <Flex className='setting-section-header' justify='space-between' align='center'>
                <Typography.Title level={3}>{t('Workload')}</Typography.Title>
                <Button disabled>{t('Save')}</Button>
              </Flex>
              <Form.Item label={t('Manpower units')}>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Radio.Group>
                      <Radio value='a'>{t('Hour')}</Radio>
                      <Radio value='b'>{t('Day')}</Radio>
                      <Radio value='c'>{t('Month')}</Radio>
                    </Radio.Group>
                  </Col>
                  <Col sm={24} md={12}>
                    <Typography.Text className='ant-form-item-extra'>
                      {t('This setting is applied to duration and time estimation')}
                    </Typography.Text>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name='checkbox-group' label={t('Define the usual working days of the week')}>
                <Checkbox.Group options={plainOptions} value={checkedList} onChange={onChange} />
              </Form.Item>
            </Flex>
            {/* <Divider />
            <Flex
              className="setting-section-header"
              justify="space-between"
              align="center"
            >
              <Typography.Title level={3}>{t("Danger zone")}</Typography.Title>
            </Flex>
            <Row gutter={24} align="middle">
              <Col sm={24} md={12}>
                <Button type="primary" danger icon={<WarningOutlined />}>
                  {t("Delete project")}
                </Button>
              </Col>
              <Col sm={24} md={12}>
                <Typography.Text className="ant-form-item-extra">{t("This operation can’t be undone")}</Typography.Text>
              </Col>
            </Row> */}
          </Form>
        </Content>
      </Content>
    </ProjectContent>
  );
}
