import { Button, Form, Modal, Row, Col, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { issueService } from 'services';
import { HISTORY_ACTION } from 'utils/contants';

interface CommentProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  issueId: string;
  projectId: string;
  workspaceId: string;
  userCreated: string | undefined;
}

const Comment: React.FC<CommentProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, setOpen, projectId, workspaceId, issueId, userCreated } = props;
  const [form] = Form.useForm();

  const [messageInput, setMessageInput] = useState('');

  const handleCancel = () => {
    setMessageInput('');
    setOpen(false);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    if (inputText.length <= 300) {
      setMessageInput(inputText);
    } else {
      message.warning(t('You have reached the character limit of 300.'));
    }
  };

  const handleOk = async () => {
    try {
      const data: {
        userCreatedId: string | undefined;
        title: string;
        comment: string;
        action: string;
      } = {
        userCreatedId: userCreated,
        title: t('left the comment'),
        comment: messageInput,
        action: HISTORY_ACTION.LEFT_THE_COMMENT,
      };
      await issueService.createIssueHistory(workspaceId, projectId, issueId, data);
      setMessageInput('');
      setOpen(false);
    } catch (error) {
      setMessageInput('');
      setOpen(false);
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  return (
    <>
      <Modal
        title={t('Comment')}
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        width={675}
        className='comment-modal'
        footer={null}
      >
        <Form form={form} name='comment' layout='vertical'>
          <div className=''>
            <Form className='mb-4'>
              <Row gutter={16} justify='space-between' align='middle' className='row-comment'>
                <Col span={22}>
                  <TextArea
                    value={messageInput}
                    name='messageInput'
                    onChange={handleMessageChange}
                    placeholder={t('Leave a comment')}
                    allowClear
                    autoSize={{ minRows: 1, maxRows: 4 }}
                  />
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Button type='primary' onClick={handleOk}>
                    {t('Send')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Comment;
