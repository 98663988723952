import { Breadcrumb, Button, Flex, notification, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ProjectContent } from 'components/layout';
import { InspectionTestPlan } from 'components/settings/project';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type InspectionTestPlanProps = {
  keyString: string;
};
const InspectionTestPlanLayout = (props: InspectionTestPlanProps) => {
  const [, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  return (
    <ProjectContent>
      {contextHolder}
      <Content className='project-settings-common-page'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Typography.Title>
              <span>{t(props.keyString)}</span> - {t('ITP')}
            </Typography.Title>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Content className='settings-content'>
          <Flex className='setting-section-block'>
            <Flex className='setting-section-header' justify='space-between' align='center'>
              <Typography.Title level={3}>{t('Inspection & Test Plan - ITP')}</Typography.Title>
              <Button type='primary' form='inspection-test-plan' disabled={disabledSubmit} loading={loadingButton} htmlType='submit'>
                {t('Save')}
              </Button>
            </Flex>
            <InspectionTestPlan onLoading={setLoadingButton} onChangedData={(hasChange) => setDisabledSubmit(!hasChange)} />
          </Flex>
        </Content>
      </Content>
    </ProjectContent>
  );
};

export default InspectionTestPlanLayout;
