import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ModalState } from 'types';
import { Attachment, AttachmentInfo, BaseIssue, User } from 'model';
import { attachmentService } from 'services';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
type MDIsListPictureSectionProps = {
  isEdit: boolean;
  selectedMdiBaseIssue: BaseIssue | null;
  workspaceId: string;
  projectId: string;
  photosInfo: AttachmentInfo[];
  attachments: Attachment[];
  setPhotosInfo: (value: AttachmentInfo[]) => void;
  fetchAttachments: () => void;
  user?: User | null;
};
export default function MDIsListPictureSection(props: MDIsListPictureSectionProps) {
  const { t } = useTranslation();
  const {
    isEdit,
    selectedMdiBaseIssue,
    workspaceId,
    projectId,
    photosInfo,
    attachments,
    fetchAttachments,
    setPhotosInfo,
  } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [attachmentPhotos, setAttachmentPhotos] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const mdiPhotoType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MDI_PHOTO);
  const users = useAppSelector(selectProjectUsers);

  const handleAddPicture = async (response: UploadPhotoModalValue) => {
    try {
      if (selectedMdiBaseIssue) {
        setLoading(true);
        await attachmentService.createAttachment(workspaceId, projectId, {
          filePath: response.fileUrl,
          name: response.fileName,
          attachmentTypeId: mdiPhotoType?.id as string,
          zoneId: selectedMdiBaseIssue.zoneId,
          baseIssueId: selectedMdiBaseIssue.id,
          width: response.width,
          height: response.height,
          comment: response?.comment,
        });
        fetchAttachments();
        setIsOpenUpload(false);
        setLoading(false);
      } else {
        if (mdiPhotoType) {
          const data: AttachmentInfo[] = photosInfo;
          data.push({
            name: response.fileName,
            filePath: response.fileUrl,
            attachmentTypeId: mdiPhotoType.id,
            comment: response.comment,
          });

          setPhotosInfo(data);
          setIsOpenUpload(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };

  const handleRemovePicture = async (filePath: string) => {
    try {
      if (selectedMdiBaseIssue) {
        setLoading(true);
        const id = attachments.find((attachment) => attachment.filePath === filePath)?.id || '';
        await attachmentService.deleteAttachment(workspaceId, projectId, id);
        fetchAttachments();
        setLoading(false);
      } else {
        const data: AttachmentInfo[] = [...photosInfo];
        data.splice(0, 1);
        setPhotosInfo(data);
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };
  const renderPictures = () => {
    let mappedPhotos = [] as AttachmentPhoto[];
    if (attachmentPhotos.length > 0) {
      mappedPhotos = attachmentPhotos?.map((picture) => {
        const user = users.find((user) => user.id === picture?.BaseIssueAttachments?.[0].userCreated);
        return {
          ...picture,
          picture: user?.profilePhoto,
          username: user?.fullName || '',
          hideDelete: !props?.isEdit,
          firstName: user?.firstName,
          comment: picture?.comment || picture?.BaseIssueAttachments?.[0].comment || '',
        };
      });
    }
    // if (photosInfo.length >0){
    //   mappedPhotos = photosInfo?.map((picture) => ({
    //     ...picture,
    //     picture: props.user?.profilePhoto,
    //     username: props.user?.fullName || '',
    //     hideDelete: !props?.isEdit,
    //     firstName: props.user?.firstName,
    //     comment: picture?.comment || '',
    //   })) as AttachmentPhoto[];
    // }
    else {
      mappedPhotos = photosInfo.map((picture) => ({
        ...picture,
        picture: props.user?.profilePhoto,
        username: props.user?.fullName || '',
        hideDelete: !props?.isEdit,
        firstName: props.user?.firstName,
        comment: picture?.comment || '',
      })) as AttachmentPhoto[];
    }
    return (
      <AttachmentList
        attachmentPhotos={mappedPhotos}
        hideDelete={!props.isEdit}
        onDelete={(filePath) => {
          handleRemovePicture(filePath);
        }}
      />
    );
  };
  useEffect(() => {
    const photos = attachments.filter((i) => i.attachmentTypeId === mdiPhotoType?.id);
    setAttachmentPhotos(photos);
  }, [attachments]);
  useEffect(() => {
    if (selectedMdiBaseIssue) {
      fetchAttachments();
    }
  }, [selectedMdiBaseIssue]);
  return (
    <div className='picture-popup-container mt-5'>
      <Spin spinning={loading}>
        {isEdit && (
          <>
            <Row gutter={24}>
              {isOpenUpload !== null && (
                <UploadPhotoModal
                  isModalOpen={isOpenUpload}
                  setIsModalOpen={setIsOpenUpload}
                  onSubmit={handleAddPicture}
                />
              )}
            </Row>
            <Row>
              <div className='mar-picture-container'>
                <Typography.Title className='material-group-title' level={3}>
                  {t('Pictures')}
                </Typography.Title>
                <Button
                  className='picture-group-text'
                  type='dashed'
                  icon={<PlusOutlined />}
                  onClick={() => setIsOpenUpload(true)}
                >
                  {t('Add Picture')}
                </Button>
              </div>
            </Row>
          </>
        )}
        <div className='picture-box'>{renderPictures()}</div>
      </Spin>
    </div>
  );
}
