import {
  Button,
  Card, Col,
  Flex,
  Form,
  Popover,
  Row,
  Typography,
  theme
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchSelect } from 'components';
import { CompanyCategory, } from 'model';
import { useState, useEffect } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';

type FilterIssueFormProps = {
  companyCategories: CompanyCategory[];
  handleFilter: (value: { [key: string]: any }) => void;
  isReset: boolean;
  setReset: (value: boolean) => void;
}



export default function ProjectCompanyFilter(props: FilterIssueFormProps) {
  const {
    companyCategories,
    handleFilter,
    isReset,
    setReset
  } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [lastSubmitFilterData, setLastSubmitFilterData] = useState({});
  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    handleFilter(values);
    setIsOpenFilter(false);
    setIsFiltering(true);

  };
  const onResetFilter = () => {
    form.resetFields()
    setDisableGroup(false)
    setLastSubmitFilterData({});
    handleFilter({});
    setIsOpenFilter(false);
    setIsFiltering(false);

  }
  const handlePopoverOpenChange = (open: any) => {
    if (open) {
      form.setFieldsValue(lastSubmitFilterData);
    } else {
      form.resetFields();
    }
    setIsOpenFilter(open);
  };

  useEffect(() => {
    if (isReset) {
      form.resetFields()
      setDisableGroup(false)
      setLastSubmitFilterData({});
      setIsOpenFilter(false);
      setReset(false);
    }
  }, [isReset]);

  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={<Typography.Text style={{ fontSize: "20px", fontWeight: '400', lineHeight: '38px' }} className='text-uppercase'>{t('Filter')}</Typography.Text>}
          extra={
            <Button size='small' type='text'
              onClick={() => {
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            <Flex justify='end' className='mx-5'>
              <Button onClick={onResetFilter} className='px-4'>{t('Reset')}</Button>
              <Button onClick={onApplyFilter} className='px-4' type="primary">{t('Apply')}</Button>
            </Flex>
          ]}
        >
          <Form
            labelCol={{ span: 24 }}
            layout='vertical'
            form={form}
            className='filter-form'
          >
            <Row gutter={16} align="stretch" className='mb-5'>
              <Col span={24}>
                <Form.Item name="companyCategoryId" label={t('Company categories')}>
                  <SearchSelect
                    placeholder={t('Company categories')}
                    style={{ width: '400' }}
                    options={companyCategories?.map((companyCategory: CompanyCategory) => ({
                      value: companyCategory.id,
                      label: companyCategory.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item className='filter-form__item' name='codeId' label={t('Code')}>
                <Select
                    showSearch
                    allowClear
                    placeholder={t('Select')}
                    options={companies?.map((option) => ({
                      value: option.id,
                      label: option.companyCode,
                    }))}
                />
                </Form.Item>
              </Col> */}
            </Row>


          </Form>
        </Card>
      }
      trigger="click"
      placement="bottomRight"
      open={isOpenFilter}
      onOpenChange={handlePopoverOpenChange}
    >
      <Button
        onClick={() => { setIsOpenFilter(!isOpenFilter); }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : "", borderColor: isFiltering ? token.colorPrimary : "" }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
