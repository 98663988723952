import { WorkPackage } from 'model';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const WorkPackageService = {
  getProjectWorkPackages: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<WorkPackage[]>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-packages`,
      {
        params: query,
      }
    );
  },
  getProjectWorkPackageById: async (
    workspaceId: string,
    projectId: string,
    workPackageId: string,
    query?: QueryParams
  ) => {
    return request.get<WorkPackage>(`/workspaces/${workspaceId}/projects/${projectId}/work-packages/${workPackageId}`, {
      params: query,
    });
  },
  createProjectWorkPackage: async (workspaceId: string, projectId: string, data: WorkPackage[]) => {
    return request.post(`/workspaces/${workspaceId}/projects/${projectId}/work-packages`, data);
  },
  updateProjectWorkPackage: async (
    workspaceId: string,
    projectId: string,
    workPackageId: string,
    data: WorkPackage
  ) => {
    return request.put(`/workspaces/${workspaceId}/projects/${projectId}/work-packages/${workPackageId}`, data);
  },
  deleteProjectWorkPackage: async (workspaceId: string, projectId: string, workPackageId: string) => {
    return request.delete(`/workspaces/${workspaceId}/projects/${projectId}/work-packages/${workPackageId}`);
  },
};

export default WorkPackageService;
