import { GlobalOutlined, ProjectOutlined } from '@ant-design/icons';
import { Typography, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotificationLayoutSidebar = () => {
  const { t } = useTranslation();
  return (
    <Sider width={260} className='siderbar notification-sidebar'>
      <Typography.Title className='mb-4 pl-[20px] pt-[20px] pr-[20px] mb-6'>{t('Notifications')}</Typography.Title>
      <Typography.Text className='text-size-14 ml-[36px] uppercase' type='secondary'>
        {t('Projects')}
      </Typography.Text>
      <Menu defaultSelectedKeys={['1']} mode='vertical' getPopupContainer={(node) => node.parentNode as HTMLElement}>
        <Menu.Item key={'1'} icon={<GlobalOutlined />}>
          <Link to='/notification'>{t('All Projects')}</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default NotificationLayoutSidebar;
