import ListViewKpiStatistic from 'components/layout/ListViewLayout/ListViewKpiStatistic';
import { IssueStatus } from 'model';
import React from 'react';
import { QueryParams } from 'types';
import { kpiData } from 'types/project';

type BaseIssueKpiStatisticProps = {
  kpiData: kpiData;
  projectBaseIssueParams?: QueryParams;
  issueStatuses: IssueStatus[] | null;
  onFilter?: (params: QueryParams) => void;
  isResetKPI?: boolean;
  onResetKPI?: (value: boolean) => void;
};

const BaseIssueKpiStatistic = (props: BaseIssueKpiStatisticProps) => {
  const handleFilterByKPI = async (code: string, isOverdue?: boolean) => {
    const statusId = props?.issueStatuses?.find((value) => {
      const statusCode = value.code.includes('_') ? value.code.split('_')[1] : value.code;
      return statusCode === code;
    })?.id;

    const resetFields = {
      filterType: undefined,
      'zoneId[]': undefined,
      'workPackageId[]': undefined,
      originatorId: undefined,
      watcherId: undefined,
      'createdAt[lte]': undefined,
      'createdAt[gte]': undefined,
      'disciplineIds[]': undefined,
      'inspectionDate[lte]': undefined,
      'inspectionDate[gte]': undefined,
      companyOriginatorId: undefined,
      searchBy: '',
    };

    const params = {
      ...props.projectBaseIssueParams,
      ...resetFields,
      page: 1,
      limit: 10,
      'overdueStatusId[]': isOverdue && statusId ? `[${'"' + statusId + '"'}]` : undefined,
      'statusId[]': !isOverdue && statusId ? `[${'"' + statusId + '"'}]` : undefined,
    };
    props?.onFilter?.(params);
  };

  return (
    <ListViewKpiStatistic
      kpiData={props.kpiData}
      handleFilterByKPI={props.onFilter ? handleFilterByKPI : undefined}
      isResetKPI={props?.isResetKPI}
      setResetKPI={props?.onResetKPI}
    />
  );
};

export default BaseIssueKpiStatistic;
