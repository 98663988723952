import { Breadcrumb, Layout, notification, Typography, Flex, Button } from 'antd';
import { ProjectContent, MaterialCategories } from 'components';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { Content } = Layout;

type MaterialCategoriesLayoutProps = {
  keyString: string;
};

export default function MaterialCategoriesLayout(props: MaterialCategoriesLayoutProps) {
  const { keyString } = props;
  const { t } = useTranslation();
  const [, contextHolder] = notification.useNotification();

  return (
    <ProjectContent>
      {contextHolder}
      <Content className='project-settings-common-page'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Title>
              <span className={'inspection-types-key-title'}>{t(keyString)}</span>
              {t(' - Material Categories')}
            </Title>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Content className='settings-content'>
          <Flex className='setting-section-block'>
            <Flex className='setting-section-header' justify='space-between' align='center'>
              <Typography.Title level={3}>{t('Material Categories List')}</Typography.Title>
              <Button disabled>{t('Save')}</Button>
            </Flex>
            <MaterialCategories />
          </Flex>
        </Content>
      </Content>
    </ProjectContent>
  );
}
