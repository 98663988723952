import { Layout, message, Tag, Button, Flex } from 'antd';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_TYPES, ISSUE_STATUSES, MDI_STATUS_COLOR, MDI_STATUSES } from 'utils/contants';
import { Attachment, BaseIssue, BaseValidation, Company, User } from 'model';
import { DeleteConfirmation } from 'components';
import dayjs from 'dayjs';
import { baseIssueService, fileService } from 'services';
import { kpiData } from 'types/project';
import DisciplineBaseIssueTable, {
  DisciplineBaseIssueTableRefHandle,
} from 'components/common/DisciplineBaseIssueTable';
import { CustomColumnType } from 'components/common/DynamicTable';
import { ProjectBaseIssuesParamsType } from 'hooks/base-issue';
import CustomTooltip from 'components/common/CustomTooltip';
import { useAppSelector } from 'store';
import { selectNewProjectWorkBreakdownStructures } from 'store/my-projects.slice';
import UserCompany from 'components/common/UserCompany';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useInspectionTestPlain,
  useInspectionTestPlanParams,
} from 'hooks';
import { formatUrlAttachments } from 'utils';
import { FilePdfOutlined } from '@ant-design/icons';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
type MaterialDeliveryInspectionsProps = {
  module: string;
  projectId: string;
  workspaceId: string;
  users: User[];
  companies: Company[];
  profile: User | null;
  projectBaseIssueParams: ProjectBaseIssuesParamsType;
  isFormOpen: boolean | null;
  editForm: (id: string, status: string) => void;
  onTotalKpi: (kpi: kpiData) => void;
  disciplineBaseIssueTableRef?: RefObject<DisciplineBaseIssueTableRefHandle>;
  refreshDisciplineByFunction: () => void;
};

export default function MaterialDeliveryInspectionsListView(props: MaterialDeliveryInspectionsProps) {
  const { t } = useTranslation();
  const { users, projectBaseIssueParams, projectId, workspaceId, companies, editForm } = props;
  const workBreakDownStructures = useAppSelector(selectNewProjectWorkBreakdownStructures);
  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  const inspectionTestPlan = useInspectionTestPlain(inspectionTestPlanParams);

  const [isDelete, setIsDelete] = useState<{
    open: boolean;
    materialDeliveryInspectionId?: string;
    disciplineId?: string;
  }>({
    open: false,
    materialDeliveryInspectionId: undefined,
    disciplineId: '',
  });
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const handleMenuClick = (key: string, record: BaseIssue) => {
    if (key) {
      switch (key) {
        case 'edit':
          editForm(record.id, record.IssueStatus?.name || 'Draft');
          break;
        case 'delete':
          setIsDelete({
            open: true,
            materialDeliveryInspectionId: record.id,
            disciplineId: record.MaterialDeliveryInspection?.MaterialApprovalRequest?.disciplineId,
          });
          break;
      }
    }
  };
  const handleDeleteBaseIssue = async () => {
    try {
      const materialDeliveryInspectionId = isDelete.materialDeliveryInspectionId;
      if (materialDeliveryInspectionId) {
        await baseIssueService.deleteBaseIssueById(workspaceId, projectId, materialDeliveryInspectionId);
        setIsDelete({ open: false, materialDeliveryInspectionId: undefined });
        props.disciplineBaseIssueTableRef?.current?.refreshData(isDelete?.disciplineId, materialDeliveryInspectionId);
      } else {
        message.error(t('Material Approval Request not existed!'));
      }
    } catch (error) {
      console.log(error);
      setIsDelete({ open: false, materialDeliveryInspectionId: undefined });
    }
  };
  // const items: MenuProps['items'] = [
  //   {
  //     label: t('Edit inspection'),
  //     key: 'edit',
  //     icon: <EditOutlined />,
  //     danger: false,
  //   },
  //   {
  //     label: t('Delete inspection'),
  //     key: 'delete',
  //     icon: <CloseCircleOutlined />,
  //     danger: true,
  //   },
  // ];
  // const menuProps: MenuProps = { items };
  const columns: CustomColumnType<BaseIssue>[] = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'MaterialDeliveryInspection',
      key: 'MaterialDeliveryInspection.mdiNumber',
      render: (MaterialDeliveryInspection) => {
        return <span>{MaterialDeliveryInspection?.mdiNumber || ''}</span>;
      },
    },
    {
      sorter: true,
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
      render: (name) => {
        return (
          <Flex align='center'>
            <span className='mr-1 column-name'>{name || ''}</span>
          </Flex>
        );
      },
    },
    {
      sorter: true,
      title: t('Activity'),
      dataIndex: 'MaterialDeliveryInspection',
      key: 'MaterialDeliveryInspection.MaterialApprovalRequest.activityId',
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
      render: (MaterialDeliveryInspection) => {
        const activity = workBreakDownStructures?.find(
          (wbs) => wbs.id === MaterialDeliveryInspection?.MaterialApprovalRequest?.activityId
        );
        return <CustomTooltip title={activity?.name || ''} />;
      },
      width: 100,
    },
    {
      title: 'Sub-activity',
      dataIndex: 'MaterialDeliveryInspection',
      key: 'MaterialDeliveryInspection.MaterialApprovalRequest.subActivityId',
      render: (MaterialDeliveryInspection) => {
        const subActivities = inspectionTestPlan[0]?.filter(
          (subActivity: InspectionTestPlanModel) =>
            subActivity.id === MaterialDeliveryInspection?.MaterialApprovalRequest?.subActivityId
        );
        return <CustomTooltip title={subActivities?.[0]?.name || ''} />;
      },
      width: 100,
    },
    {
      title: t('Submitted By'),
      dataIndex: 'originatorId',
      key: 'originatorId',
      onCell: () => {
        return {
          style: {
            maxWidth: '150px',
          },
        };
      },
      render: (originatorId) => {
        const user = (users || []).find((user) => user.id === originatorId);
        return (
          <UserCompany
            data={{
              companyName: user?.WorkspaceUser?.Company?.name,
              userName: user?.fullName,
            }}
          />
        );
      },
      width: 150,
    },
    {
      title: t('Submitted To'),
      dataIndex: 'BaseValidations',
      key: 'inspectionCompany',
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
      render: (BaseValidations: BaseValidation[]) => {
        const validators = BaseValidations?.map((validator) => {
          const user = users?.find((user) => user.id === validator.validatorId);
          const company = companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
          return {
            companyName: company?.name || '',
            userName: user?.fullName || '',
            color: 'default',
          };
        });
        return <UserCompany data={validators} />;
      },
    },
    {
      sorter: true,
      title: t('Issue Date'),
      width: 150,
      dataIndex: 'MaterialDeliveryInspection',
      key: 'MaterialDeliveryInspection.inspectionDate',
      render: (MaterialDeliveryInspection) => {
        const inspectionDate = MaterialDeliveryInspection
          ? dayjs(MaterialDeliveryInspection.inspectionDate).isValid()
            ? dayjs(MaterialDeliveryInspection.inspectionDate).format('YYYY-MM-DD')
            : ''
          : '';
        return <span>{inspectionDate}</span>;
      },
    },
    {
      title: t('Inspection Time'),
      width: 80,
      dataIndex: 'MaterialDeliveryInspection',
      key: 'inspectionDate',
      render: (MaterialDeliveryInspection) => {
        let inspectionTime = '';
        let hours = 0;
        let suffix = '';
        if (
          MaterialDeliveryInspection?.inspectionDate &&
          MaterialDeliveryInspection?.inspectionDate !== null &&
          MaterialDeliveryInspection?.inspectionDate !== ''
        ) {
          inspectionTime = dayjs(MaterialDeliveryInspection.inspectionDate).format('h:mm');
          hours = dayjs(MaterialDeliveryInspection.inspectionDate).hour();
          suffix = hours && hours >= 12 ? ' PM' : ' AM';
        }
        return (
          <span>
            {inspectionTime} {suffix}{' '}
          </span>
        );
      },
    },
    {
      sorter: true,
      title: t('Status'),
      dataIndex: 'statusId',
      key: 'IssueStatus.orderNumber',
      width: 130,
      render: (statusId, record) => {
        return (
          <>
            {record.IssueStatus?.name && record.IssueStatus?.code ? (
              <Tag color={MDI_STATUS_COLOR[record.IssueStatus?.code || 'DR']}> {t(record.IssueStatus?.name)} </Tag>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: t('Attachments'),
      dataIndex: 'Attachments',
      key: 'Attachments',
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
      render: (record: Attachment[]) => {
        const Attachments = record || [];
        const mdiDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MDI_DOCUMENT);
        const docs = Attachments.filter((i) => i.attachmentTypeId === mdiDocumentType?.id);
        return (
          <>
            {docs?.length > 0 && (
              <Flex vertical justify='flex-start'>
                {docs.map((opt) => (
                  <Button
                    key={opt?.id}
                    type='link'
                    icon={<FilePdfOutlined />}
                    className='ml-0 pa-0 d-flex'
                    style={{ height: 'auto', justifyContent: 'normal' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      fileService.openFile(opt?.filePath || '');
                    }}
                  >
                    <CustomTooltip title={formatUrlAttachments(opt?.filePath || '')} />
                  </Button>
                ))}
              </Flex>
            )}
          </>
        );
      },
      width: 120,
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit Material Delivery Inspection',
            key: 'edit',
          },
          {
            label: 'Delete Material Delivery Inspection',
            key: 'delete',
            isDisabled: (record) => {
              const userCompanyId = users?.find((user: User) => user.id === props.profile?.id)?.WorkspaceUser?.Company
                ?.id;
              const isDeleteRecord =
                userCompanyId ===
                users.find((user: User) => user.id === record?.originatorId)?.WorkspaceUser?.Company?.id;
              const isDisable =
                record?.IssueStatus?.code === MDI_STATUSES.APPROVED ||
                record?.IssueStatus?.code === MDI_STATUSES.REJECTED;
              return !isDeleteRecord || isDisable;
            },
          },
        ],
      },
    },
  ];
  return (
    <Layout.Content className='settings-content'>
      <DeleteConfirmation
        isModalOpen={isDelete.open}
        title={t('Delete the Material Delivery Inspection')}
        description={t('Are you sure to delete this Material Delivery Inspection?')}
        onDelete={handleDeleteBaseIssue}
        onCancel={() => setIsDelete({ open: false, materialDeliveryInspectionId: undefined })}
      />
      <DisciplineBaseIssueTable
        ref={props.disciplineBaseIssueTableRef}
        columns={columns}
        header={t('Discipline')}
        onActionClick={handleMenuClick}
        onRowClick={(record) => {
          editForm(record.id, record.IssueStatus?.name || 'Draft');
        }}
        onMapDataSource={(baseIssue: BaseIssue) => {
          return {
            ...baseIssue,
            activity: baseIssue?.MaterialApprovalRequest?.Activity?.name,
            subActivity: baseIssue?.MaterialApprovalRequest?.SubActivity?.name,
          };
        }}
        projectBaseIssueParams={projectBaseIssueParams}
        loadAllListTrigger={projectBaseIssueParams}
        onTotalKpi={props.onTotalKpi}
        refreshDiscipline={props?.refreshDisciplineByFunction}
        excludeKpiField={[ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE, ISSUE_STATUSES.APPROVED_WITH_COMMENT]}
      />
    </Layout.Content>
  );
}
