import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchDebounce } from 'utils';

type SearchTextProps = {
  onSearch: (value: string) => void;
};

const SearchText = (props: SearchTextProps) => {
  const { t } = useTranslation();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useSearchDebounce();

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  useEffect(() => {
    props?.onSearch(searchValue);
  }, [searchValue]);

  return (
    <Input
      className='search-button mr-3'
      placeholder={t('Type to search')}
      onBlur={(e) => handleInputBlur(e.target.value)}
      onFocus={handleSearchIconClick}
      style={{
        width: showSearchInput ? '250px' : '0px',
        border: showSearchInput ? '1px solid #d9d9d9' : 'none',
      }}
      prefix={<SearchOutlined />}
      onChange={(e) => setSearchValue(e.target.value)}
      allowClear
    />
  );
};

export default SearchText;
