import { useState } from 'react';
import { Project, Workspace } from 'model';
import { ProjectItem } from 'components';
import { WorkspaceViewType } from 'pages/Home/Home';
import Table, { ColumnsType } from 'antd/es/table';
import { Button, Flex, Tag, Typography } from 'antd';
import {
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { ProjectModulesModal } from 'components';
import dayjs from 'dayjs';
import PROJECT_DEFAULT_IMAGE from 'assets/images/project-types/demo.jpeg';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';
const { Text } = Typography;

type ProjectListProps = {
  viewType?: WorkspaceViewType;
  projects: Project[];
  workspace?: Workspace | null;
  createAction?: () => void;
  sectionId?: string;
  loading?: boolean;
  markProjectAsFavorite: (
    workspaceId: string,
    projectId: string,
    isFavorite: boolean
  ) => void;
};

const motionContainer = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      // when: 'afterChildren',
    },
  },
};

const motionItem = {
  initial: { opacity: 0, x: 50, scale: 0.8 },
  animate: { opacity: 1, x: 0, scale: 1 },
  exit: { opacity: 0, x: -50 },
  // visible: { opacity: 1, x: 0, transition: { duration: 0.4 } },
  // hidden: { opacity: 0, x: 50 },
};

export default function ProjectList(props: ProjectListProps) {
  const {
    projects,
    workspace,
    viewType,
    sectionId,
    markProjectAsFavorite,
    createAction,
    loading,
  } = props;
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const { t } = useTranslation();
  const statusColors: any = {
    active: 'orange',
    'On hold': 'red',
    Archived: 'green',
    Inactive: 'pink',
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const markAsFavorite = (project: Project, isFavorite: boolean) => {
    markProjectAsFavorite(
      project?.workspaceId as string,
      project?.id as string,
      isFavorite
    );
  };
  const renderProjectImage = (project: Project) =>
    project?.coverPath ??
    project?.ProjectType?.picture ??
    PROJECT_DEFAULT_IMAGE;

  const handleSelectedProject = (project: Project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  }

  const columns: ColumnsType<Project> = [
    {
      title: t('Project name'),
      dataIndex: 'name',
      width: '16%',
      render: (_, record: Project) => (
        <motion.div
          layoutId={`${sectionId}-project-name-${record.id}`}
          className='project-name'
        >
          <div className='project-info'>
            <div className='title'>{record.name}</div>
            <Text type='secondary'>
              {record.Workspace?.name || workspace?.name}
            </Text>
          </div>
        </motion.div>
      ),
    },
    {
      title: t('Favorite'),
      dataIndex: 'is_favorite',
      align: 'center',
      width: '4%',
      render: (_, record: Project) => (
        <Flex justify={'center'} align={'center'}>
          {record.ProjectUser?.isFavorite ? (
            <StarFilled
              onClick={() => markAsFavorite(record, false)}
              style={{ fontSize: 20, color: '#D4B106' }}
            />
          ) : (
            <StarOutlined
              onClick={() => markAsFavorite(record, true)}
              style={{ fontSize: 20, color: '#D4B106' }}
            />
          )}
        </Flex>
      ),
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      width: '3%',
    },
    {
      title: t('Location'),
      dataIndex: 'address',
      width: '16%',
      render: (_, record: Project) =>
        [record.address, record.city, record.country?.name]
          .filter((i) => i)
          .join(', '),
    },
    {
      title: t('Start date'),
      dataIndex: 'plannedStartDate',
      width: 130,
      render: (_, record: Project) => {
        return <span>{record.plannedStartDate ? dayjs(record.plannedStartDate).format('YYYY-MM-DD') : ''}</span>;
      }
    },
    {
      title: t('Finish date'),
      dataIndex: 'plannedEndDate',
      width: 130,
      render: (_, record: Project) => {
        return <span>{record.plannedEndDate ? dayjs(record.plannedEndDate).format('YYYY-MM-DD') : ''}</span>;
      }
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      align: 'center',
      render: (value) => <Tag color={`${statusColors[value]}`}>{t(value)}</Tag>,
    },
    {
      title: t('Picture'),
      dataIndex: 'picture',
      align: 'center',
      render: (_, record: Project) => (
        <motion.div layoutId={`${sectionId}-${record.id}`}>
          <ImgItem
            className='project-image'
            src={renderProjectImage(record)}
            alt=''
          />
        </motion.div>
      ),
    },
    {
      // title: createAction && (
      //   <Buttonmy Workspace
      //     block
      //     type='primary'
      //     onClick={createAction}
      //     icon={<PlusOutlined />}
      //   >
      //     {t('Create project')}
      //   </Button>
      // ),
      dataIndex: 'id',
      width: '5%',
      render: (_, record: Project) => (
        <Button block type='dashed' className='primary-dashed-btn'
          onClick={() => { handleSelectedProject(record); }}
        >
          {t('Go to project')}
        </Button>
      ),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Project[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: Project) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys: any[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_: any, index: number) => { if (index % 2 !== 0) { return false; } return true; });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys: any[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_: any, index: number) => { if (index % 2 !== 0) { return true; } return false; });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <motion.div variants={motionContainer} initial='hidden' animate='visible'>
      <ProjectModulesModal
        selectedProject={selectedProject}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <AnimatePresence mode='wait'>
        {viewType === 'list' ? (
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1, transition: { duration: 0.4 } }}
            exit='exit'
            transition={{
              duration: 0.5,
              ease: 'easeInOut',
            }}
          >
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={false}
              className='workspaces-list-table home-table'
              columns={columns}
              rowKey={(row) => row.id}
              dataSource={projects}
            />
          </motion.div>
        ) : (
          <div className='workspaces-list'>
            <Flex wrap='wrap' gap={15}>
              {projects.map((project, index) => (
                <motion.div
                  key={project.id}
                  variants={motionItem}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                    delay: index * 0.25,
                  }}
                >
                  <ProjectItem
                    onSelectedProject={(record) => { handleSelectedProject(record); }}
                    sectionId={sectionId}
                    key={project.id}
                    project={project}
                    markProjectAsFavorite={markAsFavorite}
                    workspace={workspace}
                    myWorkspaceId={myWorkspace?.id}
                  />
                </motion.div>
              ))}
              {/* 
              {createAction && !loading && (
                <motion.div
                  variants={motionItem}
                  initial='initial'
                  animate='animate'
                  key={'create-project-btn'}
                  layoutId='my-workspace-create-btn'
                  exit='exit'
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                    delay: projects?.length * 0.25,
                  }}
                  whileHover={{ scale: 1.05, transition: { duration: 0.5 } }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button
                    type='dashed'
                    className='create-project-action'
                    onClick={createAction}
                  >
                    <PlusOutlined />
                    <p>{t('Create project')}</p>
                  </Button>
                </motion.div>
              )}
              */}
            </Flex>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
