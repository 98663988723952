import { Form } from 'antd';

type Path<T> = T extends object
  ? {
      [K in keyof T]: [K, ...Path<T[K]>];
    }[keyof T]
  : [];

type PathValue<T, P> = P extends [infer K, ...infer Rest]
  ? K extends keyof T
    ? Rest extends [] | keyof T
      ? T[K]
      : Rest extends number
        ? T[K]
        : PathValue<T[K], Rest>
    : never
  : P extends keyof T
    ? T[P]
    : never;

export const useANTDFormInstance = <TFormValues>() => {
  const form = Form.useFormInstance<TFormValues>();
  return {
    ...form,
    getFieldValue: <TFieldName extends keyof TFormValues | Path<TFormValues>>(
      fieldName: TFieldName
    ): PathValue<TFormValues, TFieldName> => {
      return form.getFieldValue(fieldName);
    },
    setFieldValue: <TPath extends keyof TFormValues | Path<TFormValues>>(
      fieldName: TPath,
      value: PathValue<TFormValues, TPath>
    ): void => {
      return form.setFieldValue(fieldName, value);
    },
  };
};
