import { DatePicker, Flex, Form, Input, Radio, Row, Select, Space, Table, TreeSelect, Typography } from 'antd';
import { Design, User, Zone } from 'model';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import {
  UnpublishedDesignForm,
  designFieldValues,
  designPublishStatusKeys,
} from 'modules/DocumentManagement/models/Design';
import { FormInstance } from 'antd/es/form/Form';
import { RuleObject } from 'antd/es/form';
import dayjs from 'dayjs';
import UserDistribution from 'components/common/UserDistribution';
import { useTranslation } from 'react-i18next';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import { findPath } from 'components/common/TreeSelectTagRender';
import { DataNode } from 'antd/es/tree';
import { convertZoneToTreeData } from 'utils';
import { CheckCircleFilled } from '@ant-design/icons';

const { Option } = Select;
const { Title } = Typography;
type EditUnpublishedDesignProps = {
  designs: (UnpublishedDesignForm | Design)[];
  selectedUnpublished: Key[];
  setSelectedUnpublished: (input: Key[]) => void;
  useBulkEdit: boolean;
  form: FormInstance;
  titleRevisionErrors: { [key: string]: boolean };
  setTitleRevisionErrors: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  customValidateFields: () => void;
  fileRegex: RegExp;
  fileRegexToString: string;
  hasAttemptedUpload: boolean;
  duplicateIdsPerGroup: { [key: string]: string[] };
  setDuplicateIdsPerGroup: Dispatch<SetStateAction<{ [key: string]: string[] }>>;
  duplicateTitleFieldRuleEnabled: boolean;
  setFieldErrors: Dispatch<SetStateAction<object[]>>;
  fieldErrors: object[];
  disciplines: { discipline: string }[];
  disciplineMapIdToName: Map<string, string>;
  type: 'publish' | 'upload' | undefined;
  users: User[];
  projectId: string;
  isOpen: boolean;
  isReadOnly?: boolean;
  designRegisterPhases?: DesignRegisterPhase[];
  zones: Zone[];
};

export default function EditUnpublishedDesign(props: EditUnpublishedDesignProps) {
  const {
    duplicateTitleFieldRuleEnabled,
    designs,
    selectedUnpublished: selectedRowKeys,
    setSelectedUnpublished: setSelectedRowKeys,
    useBulkEdit,
    form,
    customValidateFields,
    titleRevisionErrors,
    setTitleRevisionErrors,
    fileRegex,
    fileRegexToString,
    hasAttemptedUpload,
    duplicateIdsPerGroup,
    setDuplicateIdsPerGroup,
    disciplines,
    disciplineMapIdToName,
    type,
    users,
    projectId,
    isOpen,
    isReadOnly,
    designRegisterPhases,
    zones,
  } = props;

  const { t } = useTranslation();

  const duplicateTitleErrorMessage = t('Title and revision already exists in register');
  const localDuplicateErrorMessage = t(`Title and revision already defined in current upload`);
  const requiredFieldMessage = (column: string) => `${t('Please fill out this field')} : ` + `${column}`;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [zoneUpdated, setZoneUpdated] = useState<string>();
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);

  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
    }
  }, [zones]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setSelectedRowKeys(designs.map((design) => design.id));
  }, []);
  const validateOtherDuplicatesInGroup = (record: UnpublishedDesignForm | Design) => {
    const isDuplicate = Object.values(duplicateIdsPerGroup).some((groupStringList) => {
      return groupStringList.includes(record.id);
    });
    const group = Object.keys(duplicateIdsPerGroup).filter((key) => {
      return duplicateIdsPerGroup[key][duplicateIdsPerGroup[key].indexOf(record.id)] !== undefined;
    });
    let lastId: string | null = null;
    if (isDuplicate && hasAttemptedUpload) {
      const updatedDuplicateList = { ...duplicateIdsPerGroup };
      updatedDuplicateList[group[0]].splice(updatedDuplicateList[group[0]].indexOf(record.id), 1);
      if (duplicateIdsPerGroup[group[0]].length === 1) {
        lastId = updatedDuplicateList[group[0]][0];
        delete updatedDuplicateList[group[0]];
      }
      setDuplicateIdsPerGroup(updatedDuplicateList);
    }
    if (lastId) {
      form.validateFields([
        ['columns', lastId, 'revision'],
        ['columns', lastId, 'title'],
      ]);
    }
  };

  const statusSelection = () => {
    return (
      <Radio.Group optionType='button' size='middle' value={'information'}>
        <Space direction='horizontal'>
          {designPublishStatusKeys.map((s) => {
            return (
              <Radio className={`publish-status-radio ${s}`} key={s} value={s}>
                 <CheckCircleFilled className='status-checkcircle' />
                {t(designFieldValues.statusValues[s].value)}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  const disciplineSelection = (id?: string) => {
    return (
      <Select
        style={{ borderColor: 'transparent' }}
        placeholder={t('Discipline')}
        disabled={(isReadOnly || (id && !selectedRowKeys.includes(id))) as boolean}
        popupMatchSelectWidth={false}
        onChange={(selectedDiscipline) => {
          if (designs[0].id === id) {
            designs.forEach((design) => {
              form.setFieldValue(['columns', design.id, 'discipline'], selectedDiscipline);
              form.validateFields([['columns', design.id, 'discipline']]);
            });
          }
        }}
      >
        {disciplines.map((element) => {
          return <Option key={element.discipline}> {element.discipline}</Option>;
        })}
      </Select>
    );
  };

  const phaseSelection = (id?: string) => {
    return (
      <Select
        placeholder={t('Phase')}
        disabled={(isReadOnly || (id && !selectedRowKeys.includes(id))) as boolean}
        popupMatchSelectWidth={false}
        onChange={(selectedPhase) => {
          if (designs[0].id === id) {
            designs.forEach((design) => {
              form.setFieldValue(['columns', design.id, 'phase'], selectedPhase);
            });
          }
        }}
      >
        {designRegisterPhases?.map((element) => {
          return <Option key={element.id}>{t(element.name)}</Option>;
        })}
      </Select>
    );
  };

  const documentTypeSelection = (id?: string) => {
    return (
      <Select
        placeholder={t('Document type')}
        disabled={(isReadOnly || (id && !selectedRowKeys.includes(id))) as boolean}
        popupMatchSelectWidth={false}
        onChange={(selectedDocumentType) => {
          if (designs[0].id === id) {
            designs.forEach((design) => {
              form.setFieldValue(['columns', design.id, 'documentType'], selectedDocumentType);
            });
          }
        }}
      >
        {Object.keys(designFieldValues.documentTypeValues).map((element) => {
          return <Option key={element}>{t(designFieldValues.documentTypeValues[element])}</Option>;
        })}
      </Select>
    );
  };

  const regexField = (recordId: string) => {
    if (selectedRowKeys.includes(recordId)) {
      return {
        required: true,
        validator: (rule: RuleObject, value: string) =>
          new Promise((resolve, reject) => {
            if (selectedRowKeys.includes(recordId) && fileRegex.test(value)) {
              resolve('');
            } else {
              reject(`${t('File names cannot contain the following characters')}: ${fileRegexToString}`);
            }
          }),
      };
    } else {
      return {};
    }
  };

  const duplicateTitleFieldRule = (recordId: string, enabled: boolean) => {
    if (selectedRowKeys.includes(recordId) && enabled) {
      return {
        required: true,
        validator: () => {
          return new Promise((resolve, reject) => {
            if ((!titleRevisionErrors[recordId] || !hasAttemptedUpload) && selectedRowKeys.includes(recordId)) {
              resolve('');
            } else {
              reject(duplicateTitleErrorMessage);
            }
          });
        },
      };
    } else {
      return {};
    }
  };
  const localDuplicateRule = (recordId: string, recordTitle: string, recordRev: string) => {
    if (selectedRowKeys.includes(recordId)) {
      return {
        required: true,
        validator: () => {
          return new Promise((resolve, reject) => {
            if (
              hasAttemptedUpload &&
              duplicateIdsPerGroup[`${recordTitle}-${recordRev}`]?.includes(recordId) &&
              selectedRowKeys.includes(recordId)
            ) {
              reject(localDuplicateErrorMessage);
            } else {
              resolve('');
            }
          });
        },
      };
    } else {
      return {};
    }
  };

  const main_columns = [
    {
      title: t('Number'),
      dataIndex: 'number',
      key: 'number',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            name={[record.id, `number`]}
            required
            initialValue={record.number ?? record.title}
            className='marginless-form-item'
          >
            <Input
              onBlur={customValidateFields}
              className='invisible-field-light'
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
            ></Input>
          </Form.Item>
        );
      },
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            initialValue={text}
            name={[record.id, `title`]}
            rules={[
              { required: true, message: requiredFieldMessage('title') },
              regexField(record.id),
              duplicateTitleFieldRule(record.id, duplicateTitleFieldRuleEnabled),
              localDuplicateRule(
                record.id,
                form.getFieldValue(['columns', record.id, 'title'])?.trim(),
                form.getFieldValue(['columns', record.id, 'revision'])?.trim()
              ),
            ]}
            required={true}
            className='marginless-form-item'
          >
            <Input
              maxLength={220 - (record.fileExtension.length + 1)}
              onBlur={customValidateFields}
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
              required={true}
              onChange={() => {
                if (titleRevisionErrors[record.id] && hasAttemptedUpload) {
                  const updatedTitleErrors = { ...titleRevisionErrors };
                  updatedTitleErrors[record.id] = false;
                  setTitleRevisionErrors(updatedTitleErrors);
                }
                if (hasAttemptedUpload) {
                  validateOtherDuplicatesInGroup(record);
                  form.validateFields([['columns', record.id, 'revision']]);
                }
              }}
            ></Input>
          </Form.Item>
        );
      },
    },
    {
      title: t('Revision'),
      dataIndex: 'revision',
      key: 'revision',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            name={[record.id, `revision`]}
            required
            initialValue={text}
            rules={[
              duplicateTitleFieldRule(record.id, duplicateTitleFieldRuleEnabled),
              localDuplicateRule(
                record.id,
                form.getFieldValue(['columns', record.id, 'title'])?.trim(),
                form.getFieldValue(['columns', record.id, 'revision'])?.trim()
              ),
            ]}
            className='marginless-form-item'
          >
            <Input
              onBlur={customValidateFields}
              className='invisible-field-light'
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
              onChange={(event) => {
                if (designs[0].id === record.id) {
                  designs.forEach((design) => {
                    form.setFieldValue(['columns', design.id, 'revision'], event.target.value);
                  });
                }
                if (titleRevisionErrors[record.id]) {
                  const updatedTitleErrors = { ...titleRevisionErrors };
                  updatedTitleErrors[record.id] = false;
                  setTitleRevisionErrors(updatedTitleErrors);
                }
                if (hasAttemptedUpload) {
                  validateOtherDuplicatesInGroup(record);
                  form.validateFields([['columns', record.id, 'title']]);
                }
              }}
            ></Input>
          </Form.Item>
        );
      },
    },
    {
      title: t('Discipline'),
      dataIndex: 'discipline',
      key: 'discipline',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            name={[record.id, 'discipline']}
            initialValue={record.disciplineId ? disciplineMapIdToName.get(record.disciplineId) : null}
            className='marginless-form-item'
            rules={[
              {
                required: true,
                message: requiredFieldMessage('Discipline'),
              },
            ]}
          >
            {disciplineSelection(record.id)}
          </Form.Item>
        );
      },
    },
    {
      title: t('Phase'),
      dataIndex: 'phase',
      key: 'phase',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        form.setFieldValue(['columns', record.id, 'phase'], text);
        return (
          <Form.Item
            name={[record.id, 'phase']}
            initialValue={designRegisterPhases?.find((phase) => phase.id === text)?.name ?? undefined}
            className='marginless-form-item'
            rules={[
              {
                required: true,
                message: requiredFieldMessage('Phase'),
              },
            ]}
          >
            {phaseSelection(record.id)}
          </Form.Item>
        );
      },
    },
    {
      title: t('Issue Date'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            className='marginless-form-item'
            initialValue={dayjs(text).isValid() ? dayjs(text) : dayjs((record as Design).createdAt)}
            name={[record.id, `issueDate`]}
          >
            <DatePicker
              format={'DD/MM/YYYY'}
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
              onChange={(date) => {
                if (designs[0].id === record.id) {
                  designs.forEach((design) => {
                    form.setFieldValue(['columns', design.id, 'issueDate'], date);
                  });
                }
                customValidateFields();
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: t('Document Type'),
      dataIndex: 'documentType',
      key: 'documentType',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item name={[record.id, `documentType`]} initialValue={text} className='marginless-form-item'>
            {documentTypeSelection(record.id)}
          </Form.Item>
        );
      },
    },
    {
      title: t('Area'),
      dataIndex: 'area',
      key: 'area',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        if (text) {
          form.setFieldValue(['columns', record.id, 'area'], text);
          setZoneUpdated(findPath(zonesTreeData, text));
        }
        const onChangeZone = (area: string) => {
          form.setFieldValue(['columns', record.id, 'area'], area);
          setZoneUpdated(findPath(zonesTreeData, area));
        };
        return (
          <Form.Item
            className='marginless-form-item'
            name={[record.id, `area`]}
            rules={[{ required: false, message: '' }]}
          >
            <TreeSelect
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
              value={zoneUpdated}
              treeData={zonesTreeData}
              placeholder={t('Select Zoning')}
              treeDefaultExpandAll
              onChange={onChangeZone}
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        );
      },
    },
  ];

  const publish_additional_columns = [
    {
      title: t('Expected Deadline'),
      dataIndex: 'deadlineDate',
      key: 'deadlineDate',
      render: (text: string, record: UnpublishedDesignForm | Design) => {
        return (
          <Form.Item
            className='marginless-form-item'
            initialValue={dayjs(text).isValid() ? dayjs(text) : null}
            name={[record.id, `deadlineDate`]}
          >
            <DatePicker
              format={'DD/MM/YYYY'}
              disabled={isReadOnly || !selectedRowKeys.includes(record.id)}
              onChange={(date) => {
                if (designs[0].id === record.id) {
                  designs.forEach((design) => {
                    form.setFieldValue(['columns', design.id, 'deadlineDate'], date);
                  });
                }
                customValidateFields();
              }}
            />
          </Form.Item>
        );
      },
    },
  ];

  const getFormType = (type: 'publish' | 'upload' | undefined) => {
    switch (type) {
      case 'publish':
        return (
          <div>
            <Form form={form}>
              <Title level={3} style={{marginBottom: '10px'}}>{t('Document to publish')}</Title>
              <Form.List name={'columns'}>
                {() => {
                  return (
                    <Table
                      size='small'
                      scroll={{ y: '25vh' }}
                      pagination={false}
                      dataSource={designs}
                      columns={[...main_columns, ...publish_additional_columns]}
                      sticky={{ offsetHeader: 0 }}
                      rowSelection={{
                        selectedRowKeys,
                        onChange: (keys) => {
                          setSelectedRowKeys(keys);
                          if (hasAttemptedUpload) {
                            form.validateFields();
                          }
                        },
                        type: 'checkbox',
                      }}
                      className='backgroundless-table mb-5'
                      rowKey={(row) => row.id}
                      summary={() =>
                        useBulkEdit &&
                        selectedRowKeys.length !== 0 && (
                          <Table.Summary fixed={'top'}>
                            <Table.Summary.Row style={{ backgroundColor: '#FAFAFB' }}>
                              <Table.Summary.Cell index={0} colSpan={2}>
                                <Flex justify='left' style={{ fontWeight: 'normal' }}>
                                  {t('Edit all rows')}
                                </Flex>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} colSpan={2}></Table.Summary.Cell>
                              <Table.Summary.Cell index={5}>{disciplineSelection(undefined)}</Table.Summary.Cell>
                              <Table.Summary.Cell index={6}>{phaseSelection()}</Table.Summary.Cell>
                              <Table.Summary.Cell index={7}>
                                <DatePicker className='invisible-field' format={'DD/MM/YYYY'} />
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={8}>{documentTypeSelection()}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        )
                      }
                    />
                  );
                }}
              </Form.List>
              <Title level={3}>{t('Reason to publish')}</Title>
              <Row gutter={0} align='bottom'>
                <div>
                  <Form.Item
                    name='status'
                    className='marginless-form-item'
                    initialValue='information'
                    rules={[
                      {
                        required: true,
                        message: requiredFieldMessage('Status'),
                      },
                    ]}
                  >
                    {statusSelection()}
                  </Form.Item>
                </div>
              </Row>
              <div style={{ width: '50%', marginTop: '10px' }}>
                <UserDistribution
                  form={form}
                  projectId={projectId}
                  companyOriginator={''}
                  users={users}
                  isOpen={isModalOpen}
                  isRequired={true}
                  isReadOnly={false}
                  title={t('Publishing to')}
                />
              </div>
            </Form>
          </div>
        );

      case 'upload':
        return (
          <Form form={form}>
            <Form.List name={'columns'}>
              {() => {
                return (
                  <Table
                    size='small'
                    scroll={{ y: '40vh' }}
                    pagination={false}
                    dataSource={designs}
                    columns={main_columns}
                    sticky={{ offsetHeader: 0 }}
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (keys) => {
                        setSelectedRowKeys(keys);
                        if (hasAttemptedUpload) {
                          form.validateFields();
                        }
                      },
                      type: 'checkbox',
                    }}
                    className='backgroundless-table'
                    rowKey={(row) => {
                      const output = row.id;
                      return output;
                    }}
                    summary={() =>
                      useBulkEdit &&
                      selectedRowKeys.length !== 0 && (
                        <Table.Summary fixed={'top'}>
                          <Table.Summary.Row style={{ backgroundColor: '#FAFAFB' }}>
                            <Table.Summary.Cell index={0} colSpan={2}>
                              <Flex justify='left' style={{ fontWeight: 'normal' }}>
                                {t('Edit all rows')}
                              </Flex>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>{disciplineSelection()}</Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>{phaseSelection()}</Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>
                              <DatePicker className='invisible-field' format={'DD/MM/YYYY'} />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>{documentTypeSelection()}</Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }
                  />
                );
              }}
            </Form.List>
          </Form>
        );
      default:
        return 0;
    }
  };

  return <div>{getFormType(type)}</div>;
}
