import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Flex,
  message,
  Space,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword';
import { COUNTRY_CODE, LANGUAGEOPTIONS } from 'utils/contants';
import useAuthContext from 'store/auth-context';
import { authService } from 'services';
import { CustomizeRequiredMark, SearchSelect, UploadProfile } from 'components/common';
import { useMyWorkspaceUsers, useMyWorkspaceUsersParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { CompanyCategory, User } from 'model';
interface UserProfilesProps {
  isOpenUserProfile: boolean;
  setIsOpenUserProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;
const UserProfile: React.FC<UserProfilesProps> = (props) => {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const { language, onChangeLanguage, getProfileAndMyWorkspace } = useAuthContext();
  const { isOpenUserProfile, setIsOpenUserProfile } = props;
  const [form] = Form.useForm();
  const [isUserChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState('');
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;
  const [query] = useMyWorkspaceUsersParams({ workspaceId });
  const [users, loading, refershUsers] = useMyWorkspaceUsers(query);
  useEffect(() => {
    if (profile) {
      form.setFieldsValue(profile);
      form.setFieldValue('languageId', profile?.languageId || language);
      const profileCompany = users.find((value: User) => value.username === profile.username)?.Company?.name
      form.setFieldValue('company', profileCompany || '');
      form.setFieldValue('prefix', profile?.countryCode || '');
      setSelectedPrefix(profile?.countryCode || '');
    }
  }, [profile, isOpenUserProfile]);



  const prefixSelector = (
    <Form.Item  name="prefix" noStyle>
      <Select
        style={{width: "70px"}}
        allowClear={false}
        dropdownStyle={{width: '300px'}}
        optionLabelProp={'label'}
        onChange={(value) => setSelectedPrefix(value)}
      >
        {
          COUNTRY_CODE?.map((item, index) => (
           <Option
             key={index}
             value={item.value}
             label={(
               <Space className={'country-selection'}>
                 <img src={item.icon} alt="" width={24} />
               </Space>
             )}
           >
             <Space className={'country-selection'}>
               <Space className={'country-selection'} role="img" aria-label={item.id}>
                 <img src={item.icon} alt="" width={24} />
                 <span>{t(item.name)}</span>
               </Space>
               <span className={'country-selection-number'} >{`+ ${item.value}`}</span>
             </Space>
           </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const handleSave = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      console.log(values);
      const dataSubmit = {
        email: profile?.email,
        username: profile?.username,
        firstName: values.firstName,
        lastName: values.lastName,
        profilePhoto: values.profilePhoto,
        phone: values.phone,
        jobTitle: values.jobTitle,
        languageId: values.languageId,
        countryCode: values.prefix
      }
      await authService.updatepProfile(dataSubmit);
      await getProfileAndMyWorkspace();
      setIsOpenUserProfile(false);
      message.success('Profile saved successfully');
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    setIsOpenUserProfile(false);
  };

  const handleChangeLogo = async (imageUrl: string) => {
    if (imageUrl) {
      form.setFieldValue('profilePhoto', imageUrl);
    }
  };


  return (
    <>
      <ChangePassword
        profile={profile}
        isOpen={isUserChangePasswordVisible}
        setOpen={setIsChangePasswordVisible}
      />
      <Modal
        title={t('User profile')}
        open={isOpenUserProfile}
        onCancel={handleCancel}
        width={675}
        className="user-profile-modal"
        footer={[
          <Button key="back" onClick={handleCancel}> {t('Cancel')} </Button>,
          <Button key="submit" type="primary" onClick={handleSave}> {t('Save')} </Button>,
        ]}
      >
        <Form requiredMark={CustomizeRequiredMark} form={form} name="user-profile" layout="vertical"
          initialValues={{ prefix: '84' }}>
          <Flex gap={15}>
            <Form.Item
              name="profilePhoto"
              valuePropName="profilePhoto"
            >
              <UploadProfile
                imgUrl={profile?.profilePhoto}
                onChange={handleChangeLogo}
              />
            </Form.Item>
            <div style={{ width: '100%' }}>
              <Form.Item
                label={t('First name')}
                name="firstName"
                rules={[{ required: true, message: '' }]}
              >
                <Input placeholder={t('Please enter')} />
              </Form.Item>
              <Form.Item
                label={t('Last name')}
                name="lastName"
                rules={[{ required: true, message: '' }]}
              >
                <Input placeholder={t('Please enter')} />
              </Form.Item>
            </div>
          </Flex>
          <Form.Item
            label={t('Company')}
            name="company"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={t('Job title')}
            name="jobTitle"
          >
            <Input placeholder={t('Please enter')} />
          </Form.Item>
          <Form.Item
            label={t('Phone')}
            name="phone"
          >
            <Input
              addonBefore={prefixSelector}
              prefix={`( +${selectedPrefix} )`}
              placeholder={t('Please enter')}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Row>
            <Form.Item
              label={<> {t('Email')} <Button type="text" className="btn-success ml-3" icon={<CheckCircleOutlined />} > {t('Verified email')} </Button> </>}
              name="email"
              rules={[{ required: true, message: '' }]}
              style={{ width: '100%' }}
            >
              <Input disabled />
            </Form.Item>
          </Row>
          <Button type="dashed" className="btn-dashed-primary mb-6" onClick={() => { setIsChangePasswordVisible(true) }}>
            {t('Change password')}
          </Button>
          <Form.Item
            name="languageId"
            label={t('Preferred Language')}
            rules={[{ required: false, message: '' }]}
            className='mb-0'
          >
            <Select
              options={LANGUAGEOPTIONS?.map((option) => ({
                value: option.id,
                label: (
                  <Space>
                    <span role="img" aria-label={option.label}>
                      <img src={option.emoji} alt="" width={24} />
                    </span>
                    <span>{t(option.label)}</span>
                  </Space>
                ),
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserProfile;
