import { RightOutlined } from '@ant-design/icons';
import { Col, Collapse, Space, Typography } from 'antd';
import { CompanyHistoryComment } from './CompanyHistoryComment';
import { ValidationHistory } from 'model';
const { Text } = Typography;
export const CompanyCollapsePanel = ({ comments, activateKeys }: { comments: ValidationHistory, activateKeys: string[] }) => (
  <Col span={8}>
    <Collapse
      // size={'small'}
      // className='mar-comments'
      // ghost
      // bordered={false}
      // defaultActiveKey={activateKeys}
      // expandIcon={() => null}
      // expandIcon={({ isActive }) => (
      //   <RightOutlined
      //     rotate={isActive ? 0 : 90}
      //     style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '12px', width: \'12px' }}
      //   />
      // )}
      // expandIconPosition='end'
      // style={{ background: 'transparent' }}
      // items={[CompanyHistoryComment(comments)]}
    />
  </Col>
);
