import { Flex, Tag, Typography } from 'antd';
import React from 'react';
import CustomTooltip from './CustomTooltip';

export type LabelTagProps = {
  tagName?: string;
  label?: string;
  direction?: 'vertical' | 'horizontal';
  color?: 'orange' | 'white' | 'royal-blue' | 'steel-blue' | 'toolbox';
  showTooltip?: boolean;
  id?: string;
};
const LabelTag = (props: LabelTagProps) => {
  return (
    <Flex className={`label-tag ${props?.color || ''}`} vertical={props.direction === 'vertical'}>
      <Tag className='tag'>{props?.tagName || ''}</Tag>
      <div className='label'>
        {props.showTooltip ? (
          <CustomTooltip title={props?.label || ''} />
        ) : (
          <Typography.Text>{props?.label || ''}</Typography.Text>
        )}
      </div>
    </Flex>
  );
};

LabelTag.defaultProps = {
  showTooltip: true,
};

export default LabelTag;
