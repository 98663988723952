import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Category } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchProjectCategories } from 'store/my-projects.slice';
import { RootState } from 'store';

const selectProjectCategories = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.categories;
const selectProjectCategoriesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.categoriesLoading;

export const useProjectCategoriesParams = (options: QueryParams) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, [workspaceId, projectId, query?.limit, query?.page, query?.search, query?.sort]);
};

export const useProjectCategories = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<Category[]>({
    action: fetchProjectCategories,
    dataSelector: (state: RootState) => selectProjectCategories(state, projectId),
    loadingSelector: (state: RootState) => selectProjectCategoriesLoading(state, projectId),
    params: query,
  });
};
