import { BaseIssueMessages } from 'model/BaseIssueMessages';
import BaseIssueMomItem from 'model/BaseIssueMomItem';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const momItemService = {
  getMomItemsById: async (
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    momItemId: string,
    query?: QueryParams
  ) => {
    return request.get<PaginationData<BaseIssueMomItem>>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/minutes-of-meeting/${momItemId}/mom-items`,
      {
        params: query,
      }
    );
  },
  postMomItemsById: async <T = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    momItemId: string,
    data: T
  ) => {
    return request.post<BaseIssueMomItem>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/minutes-of-meeting/${momItemId}/mom-items`,
      data
    );
  },
  updateMomItemsById: async <T = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    momItemId: string,
    data: T
  ) => {
    return request.put<BaseIssueMomItem>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/minutes-of-meeting/${momItemId}/mom-items`,
      data
    );
  },
  deleteMomItemsById: async (
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    momId: string,
    momItemId: string
  ) => {
    return request.delete<BaseIssueMomItem>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/minutes-of-meeting/${momId}/mom-items/${momItemId}`
    );
  },
  getBaseIssueMessage: async (workspaceId: string, projectId: string, baseIssueId: string, query: QueryParams) => {
    return request.get(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-issue-messages`,
      {
        params: query,
      }
    );
  },
  createBaseIssueMessageById: async <T = unknown>(
    workspaceId: string,
    projectId: string,
    baseIssueId: string,
    data: T
  ) => {
    return request.post<BaseIssueMessages>(
      `/workspaces/${workspaceId}/projects/${projectId}/base-issues/${baseIssueId}/base-issue-messages`,
      data
    );
  },
};

export default momItemService;
