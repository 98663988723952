import { ClockCircleOutlined, CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Avatar, Col, Form, FormInstance, Row, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { Issue, IssueDiscipline, User, WorkBreakdownStructure } from 'model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISSUE_STATUSES, NUMBERING_DIGITS, TASK_STATUS_HEADER_COLOR } from 'utils/contants';

type CreateIssueHeaderProps = {
  onClose: () => void;
  visible: boolean;
  formInstance: FormInstance;
  initialHeaderValue?: string;
  isCreateTaskVisible: boolean;
  onToggleContent: () => void;
  onShowDrawerContent: () => void;
  discipline?: WorkBreakdownStructure | IssueDiscipline | null;
  isUnSaveStatus?: boolean;
  selectedIssue: Issue | null;
  inspectionName?: string;
  users: User[];
  profile: User | null;
};

const CreateIssueHeader: React.FC<CreateIssueHeaderProps> = (props) => {
  const { discipline, isUnSaveStatus, selectedIssue, inspectionName, users, profile } = props;
  const { t } = useTranslation();
  const assignerName = isUnSaveStatus
    ? profile?.fullName
    : users.find((user) => user.id === selectedIssue?.reporterId)?.fullName;

  const isReadyForInspection: boolean = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.READY_FOR_INSPECTION;
  const isOpen: boolean = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.OPEN;
  const isExpired: boolean =
    dayjs(selectedIssue?.plannedEndDate) < dayjs().subtract(1, 'day') && selectedIssue?.plannedEndDate;

  const handleToggleDetails = () => {
    props.onToggleContent();
  };

  const renderLabel = (label: string, color?: string) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tag className='circle-fill small' color={color ?? ''} bordered={false} />
      {t(label)}
    </div>
  );

  const renderStatusHeader = () => {
    if (!selectedIssue) {
      return null;
    }
    const { IssueStatus } = selectedIssue;
    if (!IssueStatus) {
      return null;
    }
    const { name, code } = IssueStatus;

    return <Tag>{renderLabel(name, TASK_STATUS_HEADER_COLOR[code])}</Tag>;
  };

  const issueNumber = selectedIssue?.number?.split('-')[selectedIssue?.number?.split('-').length - 1] || '';
  let moduleCode = '';
  if (selectedIssue?.number) {
    if (selectedIssue?.number?.split('-').length > 1) {
      moduleCode = selectedIssue?.number?.split('-')[0] + '-';
    }
  }

  // Return a valid React element
  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='mb-2'>
        <Col span={12} className='header'>
          <DoubleRightOutlined className='icon' onClick={handleToggleDetails} style={{ color: 'white' }} />
        </Col>
        <Col span={12} className='header text-right'>
          <CloseOutlined className='icon' onClick={props.onClose} style={{ color: 'white' }} />
        </Col>
      </Row>
      <Row justify='space-between' className='create-inspection-header'>
        <Col>
          <div className='title-input'>
            <span className='avatar'>
              <Avatar className='my-3 avt-color'>{discipline?.code?.toString().toUpperCase() || '-'}</Avatar>
            </span>
            <Space direction='vertical' style={{ width: '100%', rowGap: 0 }}>
              <div className='title' style={{ color: 'white' }}>
                {inspectionName}
                {(isOpen || isReadyForInspection) && isExpired ? (
                  <ClockCircleOutlined style={{ color: '#FF4D4F' }} />
                ) : (
                  ''
                )}
              </div>
              <div>
                <Typography.Text type='secondary' style={{ paddingLeft: '12px', color: 'white' }}>
                  {issueNumber ? '#' + moduleCode + issueNumber.padStart(NUMBERING_DIGITS, '0') + ' | ' : ''}
                  {assignerName}
                </Typography.Text>
              </div>
            </Space>
          </div>
        </Col>
        {/* Omitted the rest for brevity */}
        <Space wrap>
          <Form.Item name={'issueStatusId'} className='mb-0'>
            {isUnSaveStatus ? <Tag>{t('Unsaved')}</Tag> : <> {renderStatusHeader()}</>}
          </Form.Item>
        </Space>
      </Row>
    </div>
  );
};

export default CreateIssueHeader;
