import { ClockCircleOutlined, FieldTimeOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Card, Row, Statistic, Tag } from 'antd';
import { t } from 'i18next';
import { CSSProperties, ReactNode } from 'react';
import { titleCase } from 'utils';

type KpiStatisticProps = {
  status: string;
  value?: number | string;
  title: string;
  isOverdue?: boolean;
  isStartDate?: boolean;
  isEndDate?: boolean;
  isActive?: boolean;
  customIcon?: ReactNode;
  onClick?: () => void;
  suffix?: ReactNode;
  className?: string;
  icon?: React.ReactNode;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
};

export default function KpiStatistic(props: KpiStatisticProps) {
  const { status, value, title, isOverdue, isActive, onClick, className, isStartDate, isEndDate, style, titleStyle } =
    props;
  return (
    <Card
      bordered={false}
      className={`statistic-kpi ma-0 ${status} ${isActive ? 'active' : 'inactive'} ${className}`}
      onClick={onClick}
      hoverable
      style={style}
    >
      <div style={{ position: 'absolute', top: 5, right: 5, padding: '0px 5px' }}>
        {isOverdue ? <ClockCircleOutlined className={`statistic-kpi-icon overdue`} /> : ''}
        {isStartDate ? <FieldTimeOutlined className={`statistic-kpi-icon ${status}`} /> : ''}
        {isEndDate ? <IssuesCloseOutlined className={`statistic-kpi-icon ${status}`} /> : ''}
        {props?.customIcon}
      </div>

      <Tag style={titleStyle}>{titleCase(t(title))}</Tag>

      <Row justify='space-between' align={'bottom'}>
        <Statistic value={value} suffix={props?.suffix} />
        {props?.icon}
      </Row>
    </Card>
  );
}
