import { Flex, Form, Input, Typography } from 'antd';
import DocumentAdder, { AttachmentBaseIssue } from 'components/common/DocumentAdder';
import ReactQuillCustom from 'components/common/ReactQuillCustom';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_TYPES } from 'utils/contants';
import ProjectMinutesOfMeetingContentDetailsPictures from './ProjectMinutesOfMeetingContentDetailsPictures';
import { AttachmentPhoto } from 'components/common/AttachmentList';

type ProjectMinutesOfMeetingContentDetailsDescriptionProps = {
  onFieldChange: (key: string, value: unknown) => void;
  subject?: string;
  description?: string;
  documents: AttachmentBaseIssue[];
  pictures: AttachmentPhoto[];
  index: number;
  isReadonly?: boolean;
};

const ProjectMinutesOfMeetingContentDetailsDescription = (
  props: ProjectMinutesOfMeetingContentDetailsDescriptionProps
) => {
  const { t } = useTranslation();
  return (
    <Flex vertical className='item-description'>
      {props.isReadonly ? (
        <Typography.Text className='text-size-14 font-bold'>{props.subject}</Typography.Text>
      ) : (
        <Form.Item label='Subject' name={['items', props.index, 'subject']} rules={[{ required: true, message: '' }]}>
          <Input
            showCount
            maxLength={100}
            onChange={(e) => {
              props.onFieldChange('subject', e.target.value);
            }}
          />
        </Form.Item>
      )}
      {props.isReadonly ? (
        <ReactQuillCustom value={props.description as string} readOnly={true} theme={'bubble'} />
      ) : (
        <Form.Item label='Description' name={['items', props.index, 'description']}>
          <ReactQuillCustom
            value={props.description}
            disbileImage={true}
            onChange={(description: string) => {
              props.onFieldChange('description', description);
            }}
          />
        </Form.Item>
      )}

      <DocumentAdder
        title={
          <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px', fontWeight: 'bold' }}>{t('Documents')}</span>
        }
        attachmentType={ATTACHMENT_TYPES.MOM_DOCUMENT}
        onChange={(attachments) => {
          props.onFieldChange('documents', attachments);
        }}
        documentsInfo={props.documents}
        layout='inline'
        readOnlyTitle={
          props.isReadonly ? (
            <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px', fontWeight: 'bold' }}>{t('Documents')}</span>
          ) : (
            ''
          )
        }
      />

      <ProjectMinutesOfMeetingContentDetailsPictures
        pictures={props.pictures}
        onChange={(pictures) => {
          props.onFieldChange('pictures', pictures);
        }}
        isReadonly={props.isReadonly}
        title={<span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px' }}>{t('Pictures')}</span>}
        readOnlyTitle={
          props.isReadonly ? (
            <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px', fontWeight: 'bold' }}>{t('Pictures')}</span>
          ) : (
            ''
          )
        }
      />
    </Flex>
  );
};

export default ProjectMinutesOfMeetingContentDetailsDescription;
