import {
  Button,
  Breadcrumb,
  Typography,
  Layout,
  Col,
  Form,
  Row,
  Input,
  Flex,
  DatePicker,
  Spin,
  notification,
  Modal,
} from 'antd';
import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CustomizeRequiredMark, ProjectContent, SearchSelect, SubmitButton, UploadFormItem } from 'components/index';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectMyWorkspaceProjects } from 'store/my-workspace.slice';
import { projectService } from 'services';
import dayjs from 'dayjs';
import 'dayjs/locale/km';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/vi';
import 'dayjs/locale/th';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/zh';
import 'dayjs/locale/id';
import km_KH from 'antd/es/date-picker/locale/km_KH';
import pt_PT from 'antd/es/date-picker/locale/pt_PT';
import es_ES from 'antd/es/date-picker/locale/es_ES';
import vi_VN from 'antd/es/date-picker/locale/vi_VN';
import th_TH from 'antd/es/date-picker/locale/th_TH';
import en_US from 'antd/es/date-picker/locale/en_US';
import fr_FR from 'antd/es/date-picker/locale/fr_FR';
import zh_CN from 'antd/es/date-picker/locale/zh_CN';
import id_ID from 'antd/es/date-picker/locale/id_ID';
import useAuthContext from 'store/auth-context';
import {
  useCountries,
  useCountriesParams,
  useMyWorkspaceProjectTypes,
  useMyWorkspaceProjectTypesParams,
  useProjectById,
  useProjectByIdParams,
} from 'hooks';
import { selectOtherWorkspacesProjects } from 'store/other-workspaces.slice';

const { Title } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

export default function ProjectsGeneral() {
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();
  const [notify, contextHolder] = notification.useNotification();
  const myWorkspaceProjects = useAppSelector(selectMyWorkspaceProjects);
  const otherWorkspaceProjects = useAppSelector(selectOtherWorkspacesProjects);
  const projectId = params.projectId as string;

  const selectedProject = useMemo(() => {
    return [...myWorkspaceProjects, ...otherWorkspaceProjects].find((project) => project.id === params.projectId);
  }, [myWorkspaceProjects, otherWorkspaceProjects, params.projectId]);

  const workspaceId = selectedProject?.workspaceId as string;
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [projectParams] = useProjectByIdParams({ projectId, workspaceId });
  const [project, loading] = useProjectById(projectParams);
  const [countryParams] = useCountriesParams();
  const [countries] = useCountries(countryParams);
  const [projectTypeQuery] = useMyWorkspaceProjectTypesParams({
    workspaceId: project ? project.workspaceId : workspaceId,
    limit: 'unlimited',
    orderBy: 'name',
  });
  const [projectTypes, , refreshProjectTypes] = useMyWorkspaceProjectTypes(projectTypeQuery);
  const { language } = useAuthContext();
  const locale = switchLocale(language);

  const { confirm } = Modal;

  useEffect(() => {
    if (project) {
      refreshProjectTypes();
      const projectData = { ...project };
      if (projectData.plannedStartDate) {
        projectData.plannedStartDate = dayjs(projectData.plannedStartDate, 'YYYY-MM-DD');
      }
      if (projectData.plannedEndDate) {
        projectData.plannedEndDate = dayjs(projectData.plannedEndDate, 'YYYY-MM-DD');
      }
      form.setFieldsValue(projectData);
      if (projectData?.projectTypeId && projectTypes.length > 0) {
        const existingProjectType = projectTypes?.findIndex(
          (projectType) => projectType.id === projectData?.projectTypeId
        );
        form.setFieldValue(['projectTypeId'], existingProjectType > -1 ? projectData?.projectTypeId : null);
      }
    }
  }, [project]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      try {
        setIsSubmit(true);
        const values = await form.getFieldsValue();
        if (values.plannedStartDate) {
          values.plannedStartDate = dayjs(values.plannedStartDate).format('YYYY-MM-DD');
        }

        if (values.plannedEndDate) {
          values.plannedEndDate = dayjs(values.plannedEndDate).format('YYYY-MM-DD');
        }
        values.workspaceId = project.workspaceId;
        values.projectId = projectId;
        await projectService.updateProject(project.workspaceId, projectId, values);
        setIsSubmit(false);
        setIsChanged(false);
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
        notify.error({
          message: t('Error'),
          description: t((error as Error)?.message),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeCover = (imageUrl: string) => {
    if (imageUrl) {
      form.setFieldValue('coverPath', imageUrl);
      setIsChanged(true);
    }
  };
  const navigate = useNavigate();
  const handleDeleteProject = async () => {
    try {
      await projectService.deleteProject(project.workspaceId, projectId);
      console.log('Project deleted');
      navigate('/');
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: `${t('Delete project')} "${project?.name}"`,
      icon: <ExclamationCircleFilled />,
      content: `${t('Your are about to permanently delete this project and all its  related modules data and attachments.')}`,
      okText: `${t('Delete')}`,
      okType: 'danger',
      cancelText: `${t('Cancel')}`,
      async onOk() {
        await handleDeleteProject();
      },
      onCancel() {
        console.log('Canceled');
      },
    });
  };

  return (
    <ProjectContent>
      {contextHolder}
      <Content className='project-settings-common-page'>
        <Spin spinning={loading === 'pending' && !project}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Title>{t('Project Details')}</Title>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content className='settings-content'>
            <Form
              form={form}
              requiredMark={CustomizeRequiredMark}
              name='project-general-form'
              onValuesChange={() => setIsChanged(true)}
              layout='vertical'
            >
              <Flex className='setting-section-block'>
                <Flex className='setting-section-header' justify='space-between' align='center'>
                  <Typography.Title level={3}>{t('Project')}</Typography.Title>
                  <SubmitButton loading={isSubmit} disabled={!isChanged} formInstance={form} onClick={onSubmit}>
                    {t('Save')}
                  </SubmitButton>
                </Flex>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={t('Project name')}
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <Input placeholder='Project name' />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('Code')} name='code' rules={[{ required: true, message: '', max: 4, min: 4 }]}>
                      <Input placeholder='XXXX' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={t('Project type')}
                      name={'projectTypeId'}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <SearchSelect
                        placeholder={t('Select project type')}
                        options={projectTypes?.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Typography.Text className='ant-form-item-extra'>
                      {t('Options can be added in workspace settings > Data customization')}
                    </Typography.Text>
                  </Col>
                </Row>
                <Form.Item label={t('Project description')} name='description'>
                  <TextArea placeholder={t('Enter a project description')} autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
              </Flex>
              <Flex className='setting-section-block'>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={24}>
                    <Form.Item label={t('Cover image')} name='coverPath'>
                      <UploadFormItem
                        imgUrl={project?.coverPath}
                        onChange={handleChangeCover}
                        extraText={t('Upload a cover image in JPG or PNG')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>

              <Flex className='setting-section-block'>
                <Flex className='setting-section-header' justify='space-between' align='center'>
                  <Typography.Title level={3}>{t('Location')}</Typography.Title>
                  {/* <Button disabled>{t("Save")}</Button> */}
                </Flex>

                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('Address')} name={'address'}>
                      <Input placeholder={t('Building, block, street')} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('City')} name={'city'}>
                      <Input placeholder={t('City')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('Zipcode')} name={'zipCode'}>
                      <Input placeholder={t('Zipcode')} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('Country')} name='countryId'>
                      <SearchSelect
                        placeholder={t('Country')}
                        options={countries?.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
              <Flex className='setting-section-block'>
                <Flex className='setting-section-header' justify='space-between' align='center'>
                  <Typography.Title level={3}>{t('Timeline')}</Typography.Title>
                  {/* <Button disabled>{t("Save")}</Button> */}
                </Flex>

                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Form.Item label={t('Start date')} name={'plannedStartDate'}>
                      <DatePicker locale={locale} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={12}>
                    <Form.Item
                      label={t('Ending date')}
                      name={'plannedEndDate'}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              !getFieldValue('plannedStartDate') ||
                              (value && getFieldValue('plannedStartDate') && value >= getFieldValue('plannedStartDate'))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error());
                          },
                        }),
                      ]}
                    >
                      <DatePicker locale={locale} />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
              <Flex className='setting-section-block'>
                <Flex
                  className='setting-section-header'
                  justify='space-between'
                  align='center'
                  style={{ paddingBottom: 10 }}
                >
                  <Typography.Title level={3}>{t('Danger zone')}</Typography.Title>
                </Flex>
                <Row gutter={24} align='middle'>
                  <Col sm={24} md={12}>
                    <Button onClick={showDeleteConfirm} danger icon={<WarningOutlined />}>
                      {t('Delete project')}
                    </Button>
                  </Col>
                  <Col sm={24} md={12}>
                    <Typography.Text className='ant-form-item-extra'>
                      {t('This operation can’t be undone')}
                    </Typography.Text>
                  </Col>
                </Row>
              </Flex>
            </Form>
          </Content>
        </Spin>
      </Content>
    </ProjectContent>
  );
}
function switchLocale(language: string) {
  switch (language) {
    case 'km':
      return km_KH;
    case 'pt':
      return pt_PT;
    case 'es':
      return es_ES;
    case 'vn':
      return vi_VN;
    case 'th':
      return th_TH;
    case 'fr':
      return fr_FR;
    case 'cn':
      return zh_CN;
    case 'id':
      return id_ID;
    default:
      return en_US;
  }
}
