import { Col, Form, FormProps, Row, Typography } from 'antd';
import { CustomizeRequiredMark } from 'components/common';
import { Company, IssuePriority, User } from 'model';
import { useTranslation } from 'react-i18next';
import { RequestForInformationListDocument } from 'components';
import 'react-quill/dist/quill.snow.css';
import { selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { useAppSelector } from 'store';
import { EWorkBreakDownStructureLevelID } from 'types';
import { mapDataToOptions } from 'utils/object';
import { AttachmentBaseIssue } from 'components/common/DocumentAdder';
import InvitationDistribution, { InvitationDistributionValue } from 'components/common/InvitationDistribution';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import { Dayjs } from 'dayjs';
import EditableFormField from 'components/common/EditableFormField';

const { Title } = Typography;

export type RFIFormValues = InvitationDistributionValue & {
  subject: string;
  deadline?: Dayjs;
  disciplineId?: string;
  priorityId?: string;
  content: string;
  attachments: AttachmentBaseIssue[];
};

type RequestForInformationDetailProps = {
  onValuesChange?: FormProps['onValuesChange'];
  isOpen: boolean;
  workspaceId: string;
  projectId: string;
  users: User[];
  companies: Company[];
  profile?: User | null;
  attachmentsInfo: { name: string; filePath: string }[];
  setAttachmentsInfo: (value: { name: string; filePath: string }[]) => void;
  issuePriorities: IssuePriority[];
  isReadonly?: boolean;
};

export default function RequestForInformationForm(props: RequestForInformationDetailProps) {
  const {
    onValuesChange,
    workspaceId,
    projectId,
    users,
    attachmentsInfo,
    setAttachmentsInfo,
    companies,
    issuePriorities,
  } = props;
  const { t } = useTranslation();
  const content = Form.useWatch('content');
  const form = useANTDFormInstance<RFIFormValues>();

  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));

  return (
    <Form
      requiredMark={CustomizeRequiredMark}
      form={form}
      layout='vertical'
      className={'request-for-information-form'}
      onValuesChange={onValuesChange}
    >
      <Row>
        <InvitationDistribution isReadOnly={props?.isReadonly} companies={companies} users={users} />
        <Col span={24}>
          <Title className='read-only-mar-title read-only-row' level={3} style={{ lineHeight: '32px' }}>
            {t('RFI Content and Details')}
          </Title>

          <EditableFormField
            isReadonly={props.isReadonly}
            columns={[
              {
                label: t('Subject'),
                name: 'subject',
                rules: [{ required: true, message: 'Please Enter Name' }],
                type: 'input',
                editableProps: {
                  placeholder: t('Enter Subject'),
                  allowClear: true,
                  showCount: true,
                  maxLength: 100,
                },
              },
            ]}
          />

          <EditableFormField
            isReadonly={props.isReadonly}
            columns={[
              {
                label: t('Priority'),
                name: 'priorityId',
                rules: [{ required: true, message: '' }],
                type: 'select-icon',
                editableProps: {
                  options: issuePriorities.map((issue) => ({
                    label: issue.name,
                    value: issue.id,
                    icon: issue?.icon,
                  })),
                  placeholder: t('Enter Subject'),
                  allowClear: true,
                },
              },
              {
                label: `Deadline`,
                name: 'deadline',
                type: 'date',
                editableProps: {
                  disabledDate: (date) => date.isBefore(new Date(), 'day'),
                  placeholder: t('Select Date'),
                },
              },
            ]}
          />
          <EditableFormField
            isReadonly={props.isReadonly}
            columns={[
              {
                label: t('Discipline'),
                name: 'disciplineId',
                type: 'select',
                rules: [{ required: true, message: '' }],
                editableProps: {
                  options: mapDataToOptions(disciplines),
                  placeholder: t('Select discipline'),
                },
              },
              {
                type: 'empty',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Col span={24}>
            <Title className='read-only-mar-title read-only-row' level={3} style={{ lineHeight: '32px' }}>
              {t('Content')}
            </Title>
          </Col>
          <EditableFormField
            isReadonly={props.isReadonly}
            columns={[
              {
                label: t(''),
                name: 'content',
                type: 'react-quill',
                editableProps: {
                  value: content,
                  onChange: (content) => {
                    form.setFieldValue('content', content);
                  },
                },
              },
            ]}
          />
        </Col>
        <Col span={24} className={'mt-5'}>
          <RequestForInformationListDocument
            isReadOnly={!!props.isReadonly}
            workspaceId={workspaceId}
            projectId={projectId}
            attachmentsInfo={attachmentsInfo}
            setAttachmentsInfo={setAttachmentsInfo}
          />
        </Col>
      </Row>
    </Form>
  );
}
