import Folder from 'model/Folder';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';
const foldersService = {
  getFolders: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<Folder>>(`/workspaces/${workspaceId}/projects/${projectId}/folders`, {
      params: query,
    });
  },
  createFolder: async (workspaceId: string, projectId: string, data: { name: string; parentId?: string | null }) => {
    return request.post<PaginationData<Folder>>(`/workspaces/${workspaceId}/projects/${projectId}/folders`, data);
  },
  updateFolder: async (
    workspaceId: string,
    projectId: string,
    folderId: string,
    data: { name: string; parentId?: string | null; order?: number; isMain?: boolean }
  ) => {
    return request.put<PaginationData<Folder>>(
      `/workspaces/${workspaceId}/projects/${projectId}/folders/${folderId}`,
      data
    );
  },
  deleteFolder: async (workspaceId: string, projectId: string, folderId: string) => {
    return request.delete<PaginationData<Folder>>(
      `/workspaces/${workspaceId}/projects/${projectId}/folders/${folderId}`
    );
  },
};

export default foldersService;
