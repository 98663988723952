import { TreeSelect } from 'antd';
import { findPath } from 'components/common/TreeSelectTagRender';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProjectZones } from 'store/my-projects.slice';
import { convertZoneToTreeData } from 'utils';

type WorkInspectionRequestDetailZoningProps = {
  onChange?: (value: string) => void;
  value?: string;
  id?: string;
};
const WorkInspectionRequestDetailZoning = (props: WorkInspectionRequestDetailZoningProps) => {
  const zones = useSelector(selectProjectZones);
  const { t } = useTranslation();
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);

  return (
    <TreeSelect
      id={props.id}
      value={props.value ? findPath(zonesTreeData, props.value || '') : undefined}
      onChange={props.onChange}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={zonesTreeData}
      placeholder={t('Select Zoning')}
      treeDefaultExpandAll
      placement='bottomLeft'
      popupMatchSelectWidth={false}
      popupClassName='wir-select-area'
      allowClear
    />
  );
};

export default WorkInspectionRequestDetailZoning;
