import { Avatar, Divider, Flex, Radio, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getTimeAgoFromNow } from 'utils/date';
import { useTranslation } from 'react-i18next';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import { BellFilled } from '@ant-design/icons';
import { Issue, NotificationModel, User } from 'model';
import notificationService from 'services/notification.service';
import {
  CHANGE_BODY_NOTIFICATION_INSPECTION,
  CHANGE_BODY_NOTIFICATION_OBSERVATION,
  NUMBERING_DIGITS,
} from 'utils/contants';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { issueService, userService } from 'services';
import {
  useGetNotification,
  useGetNotificationParams,
  useGetNotifications,
  useNotificationCount,
} from 'hooks/notificaition';
import useAuthContext from 'store/auth-context';

type NotificationListAllTabProps = {
  showMoreOption?: boolean;
  isFetching: boolean;
  setOpen?: (value: boolean) => void;
  setIsFetching: (value: boolean) => void;
  isAll: boolean;
};

const NotificationListAllTab = (props: NotificationListAllTabProps): JSX.Element => {
  const { isAll, setOpen, isFetching, setIsFetching } = props;
  const [users, setUsers] = useState<User[]>([]);
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const [limitItem, setLimitItem] = useState(20);

  const [notificationReadParams] = useGetNotificationParams({
    limit: `${limitItem}`,
  });
  const [notificationUnReadParams] = useGetNotificationParams({
    status: 'unRead',
    limit: `${limitItem}`,
  });
  const navigate = useNavigate();
  const [notifications, , refreshNotifications] = useGetNotifications(notificationReadParams);

  const [notificationUnReads, , refreshNotificationUnReads] = useGetNotification(notificationUnReadParams);
  const [notificationCount, , refreshNotificationCount] = useNotificationCount(notificationUnReadParams);

  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;

  const issueIds = notifications.map((item) => item.referenceId);
  const [listIssues, setListIssues] = useState<Issue[]>([]);

  const handleFetchWorkspaceIssues = async (issueIds: string[]) => {
    return await issueService.getWorkspaceIssueIds(workspaceId, issueIds, null);
  };

  useEffect(() => {
    if (issueIds.length > 0 && workspaceId) {
      handleFetchWorkspaceIssues(issueIds).then((res) => {
        setListIssues(res);
      });
    }
  }, [notifications]);

  const handleMarkAsRead = async (notificationId?: string) => {
    if (profile?.id) {
      try {
        if (notificationId) {
          await notificationService.markAsRead(notificationId);
        } else {
          await notificationService.markAllAsRead();
        }
        if (refreshNotificationCount) {
          refreshNotificationCount();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    refreshNotifications();
    refreshNotificationUnReads();
  }, [notificationCount]);

  const handleRef = (projectId?: string, module?: string, issueIs?: string, notificationId?: string) => {
    if (projectId && module) {
      navigate(`/projects/${projectId}/${module.toLowerCase()}/inspections`, { state: { id: issueIs } });
      if (setOpen && notificationId) {
        setOpen(false);
        handleMarkAsRead(notificationId);
      }
    }
  };

  const handleFetchUsersByListId = async (listId: string[]) => {
    try {
      const response = await userService.getUsersByListId(listId);
      setUsers(response.rows);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (notifications.length > 0) {
      const listId = [...new Set(notifications.map((notification) => String(notification.notifierId)))];
      handleFetchUsersByListId(listId);
    }
  }, [notifications, limitItem]);

  const fetchMoreNotifications = async () => {
    try {
      const params = {
        limit: `${limitItem}`,
      };
      const unReadsParams = {
        limit: `${limitItem}`,
      };

      refreshNotifications(params);

      if (notificationCount > limitItem) {
        refreshNotificationUnReads(unReadsParams);
        refreshNotificationCount(unReadsParams);
      }
    } catch (error) {
      console.error('Error fetching more notifications:', error);
    } finally {
      setIsFetching(false);
    }
  };
  useEffect(() => {
    if (isFetching) {
      setLimitItem(limitItem + 10);
      fetchMoreNotifications();
    }
  }, [isFetching]);

  const renderHeader = (): JSX.Element => {
    return (
      <div className='flex justify-between mb-2'>
        {isAll ? (
          <Typography.Text type='secondary' className='text-uppercase'>
            {t('today')}
          </Typography.Text>
        ) : (
          <Typography.Text type='secondary' className='text-uppercase'>
            {/*{t('today')}*/}
          </Typography.Text>
        )}
        <div>
          <Typography.Link
            className='text-size-12'
            disabled={notificationUnReads.length === 0}
            onClick={() => {
              //TODO: integrate with API
              handleMarkAsRead();
            }}
          >
            {t('Mark all as read')}
          </Typography.Link>
          {/*{props.showMoreOption && <MoreOutlined className='ml-4' />}*/}
        </div>
      </div>
    );
  };

  const shortedTimeAgo = (dateTime: string) => {
    const result = getTimeAgoFromNow(dateTime);
    if (result.includes('minutes')) {
      return result.replace('minutes', 'mins');
    }
    if (result.includes('minute')) {
      return result.replace('minute', 'min');
    }

    return result;
  };

  const renderContent = (notification: NotificationModel, showDivider?: boolean): JSX.Element => {
    const issue = listIssues?.find((item) => item.id === notification.referenceId);
    const user = users?.find((user) => user.id === notification.notifierId);
    const issueNumber = issue?.number?.split('-')[issue?.number?.split('-').length - 1] || '';
    const newBody =
      issue?.IssueType.Module.name === 'Quality' && notification.body.includes('Inspection')
        ? notification.body.replace('Inspection', 'Observation')
        : notification.body;
    let moduleCode = '';
    if (issue?.number) {
      if (issue?.number?.split('-').length > 1) {
        moduleCode = issue?.number?.split('-')[0] + '-';
      }
    }
    const types = [
      ...Object.values(CHANGE_BODY_NOTIFICATION_INSPECTION),
      ...Object.values(CHANGE_BODY_NOTIFICATION_OBSERVATION),
    ];
    let title = '';
    let username = '';
    for (const notificationTitle of types) {
      if (newBody.includes(notificationTitle)) {
        title = notificationTitle;
        username = newBody.replace(title, '').trim();
        break;
      } else {
        username = newBody;
      }
    }
    return (
      <div
        onClick={() => {
          handleMarkAsRead(notification.id);
        }}
        className='content'
      >
        <div className='relative mr-[10px] flex flex-col items-center'>
          {user?.profilePhoto ? (
            <AvatarItem size={'small'} className='ml-3' src={user?.profilePhoto} />
          ) : (
            <Avatar size={'small'} className='ml-3'>
              {user?.firstName?.substring(0, 1)}
            </Avatar>
          )}
        </div>

        <div className='flex-1'>
          <div className='flex text-size-14 items-center'>
            <div className='flex-1 flex justify-between'>
              <span className='font-weight-bold font-size-14'>
                {t(username)} {t(title)}
              </span>
            </div>
          </div>

          <div className='flex'>
            <span className='all-tab-discipline mr-1'>{issue?.IssueDiscipline?.code}</span>
            <div className='mr-1'>
              <div
                onClick={() => {
                  handleRef(issue?.projectId, issue?.IssueType.Module.name, issue?.id, notification.id);
                }}
                className='issues-text text-color-polaris-black mr-0.5'
              >
                {issue?.name}
              </div>
              <div className='text-color-grey text-size-12'>
                {`#${moduleCode}${issueNumber.padStart(NUMBERING_DIGITS, '0')} | ${user?.fullName}`}
              </div>
            </div>
            {/*<StatusTag*/}
            {/*  id={issue?.IssueStatus.name ? issue?.IssueStatus.name : ''}*/}
            {/*  name={t(issue?.IssueStatus.name ? issue?.IssueStatus.name : '')}*/}
            {/*  code={issue?.IssueStatus.code}*/}
            {/*/>*/}
          </div>
          <div className='flex items-center'>
            <span className='text-color-secondary text-size-12'>{issue?.Project.name}</span>
            <span className={`type ${issue?.IssueType.Module.name}`}>{issue?.IssueType.Module.name}</span>
          </div>

          {/*{notification.caption && <div className='caption'>{notification.caption}</div>}*/}
        </div>

        <div className='flex flex-col justify-between'>
          {notificationUnReads.find((value) => value.id === notification.id) && (
            <div className='ml-1 text-right'>
              <Radio
                className='m-0 block text-end'
                checked
                onClick={() => {
                  handleMarkAsRead(notification.id);
                }}
              />
            </div>
          )}
          <div className='text-size-14 text-color whitespace-nowrap mt-auto'>
            {shortedTimeAgo(notification.createdAt)}
          </div>
        </div>
        {showDivider && <Divider className='divider' type='vertical' />}
      </div>
    );
  };

  // const renderSubContent = (notification: NotificationModel, showCanRead?: boolean): JSX.Element | null => {
  //   if (selectedNotificationId.includes(notification.id)) {
  //     return (
  //       <>
  //         {notification.map((subNotification) => (
  //           <React.Fragment key={subNotification.id}>
  //             {renderContent(subNotification, true, showCanRead)}{' '}
  //           </React.Fragment>
  //         ))}
  //         <Typography.Link
  //           className='ml-[66px]'
  //           onClick={() => {
  //             handleOpenSubNotification(notification.id);
  //           }}
  //         >
  //           {t('Show less')}
  //         </Typography.Link>
  //       </>
  //     );
  //   }
  //
  //   return (
  //     <Typography.Link
  //       onClick={() => {
  //         // handleOpenSubNotification(notifications.);
  //       }}
  //       className='ml-[66px] text-size-12'
  //     >{`+ ${notification?.children?.length} ${t('assignee update from')} ${
  //       notification?.children?.[0]?.name
  //     }`}</Typography.Link>
  //   );
  // };

  const renderContentList = (notifications: NotificationModel[]) => {
    return (
      <>
        {notifications.length > 0 ? (
          <>
            {notifications.map((notification) => (
              <React.Fragment key={notification.id}>{renderContent(notification, false)}</React.Fragment>
            ))}
          </>
        ) : (
          <>
            {!isAll && (
              <Flex vertical justify={'center'} align={'center'}>
                <div className={'bell-container'}>
                  <BellFilled style={{ fontSize: 48, color: 'rgba(0, 29, 61, 0.2)' }} />
                </div>
                <Typography.Text className={'no-notification-text'}>{t('No Notifications')}</Typography.Text>
              </Flex>
            )}
          </>
        )}
      </>
    );
  };

  const renderOlder = (notifications: NotificationModel[]): JSX.Element => {
    return (
      <div>
        <Typography.Text type='secondary' className='text-uppercase'>
          {t('OLDER')}
        </Typography.Text>
        {notifications.map((notification) => (
          <React.Fragment key={notification.id}>{renderContent(notification, false)}</React.Fragment>
        ))}
      </div>
    );
  };

  // const renderNote = () => {
  //   return (
  //     <div className='note'>
  //       <Flag />
  //       <Typography.Text type='secondary'>{t("That's all your notifications from the last 30 days")}</Typography.Text>
  //     </div>
  //   );
  // };

  return (
    <div className='notification-list-tabs'>
      {renderHeader()}
      {renderContentList(
        isAll ? notifications.filter((item) => dayjs(item.createdAt).isSame(dayjs(), 'day')) : notificationUnReads
      )}
      {isAll && renderOlder(notifications.filter((item) => !dayjs(item.createdAt).isSame(dayjs(), 'day')))}
      {/*{renderNote()}*/}
      {notifications.length === 0 && isAll && (
        <Flex vertical justify={'center'} align={'center'}>
          <div className={'bell-container'}>
            <BellFilled style={{ fontSize: 48, color: 'rgba(0, 29, 61, 0.2)' }} />
          </div>
          <Typography.Text className={'no-notification-text'}>{t('No Notifications')}</Typography.Text>
        </Flex>
      )}
    </div>
  );
};
NotificationListAllTab.defaultProps = {
  showMoreOption: true,
};
export default NotificationListAllTab;
