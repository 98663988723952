import { CheckOutlined } from '@ant-design/icons';
import { Flex, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

type SelectStatusProps = {
  options?: {
    label: string;
    value: string;
    code: string;
  }[];
  onChange: (value?: string) => void;
  onSelectedChange: (value?: string) => void;
  value?: string;
  isReadonly?: boolean;
};

const SelectStatus = (props: SelectStatusProps) => {
  const [selectedOption, setSelectedOption] = useState({ value: '', code: '', label: '' });

  const getColor = (value: string) => {
    switch (value) {
      case 'OP':
        return 'orange';
      case 'OD':
        return 'red';
      case 'FI':
        return 'blue';
      case 'CL':
        return 'green';
      default:
        break;
    }
  };

  useEffect(() => {
    props.onChange(selectedOption?.value);
  }, [selectedOption]);

  useEffect(() => {
    if (props?.value) {
      const option = props.options?.find((option) => option.value === props?.value);
      selectedOption.value = props?.value;
      selectedOption.code = option?.code || '';
      selectedOption.label = option?.label || '';
    }
    setSelectedOption({ ...selectedOption });
  }, [props.value]);

  return (
    <Select
      defaultValue={props.value}
      rootClassName={`select-status ${selectedOption.code} ${props.isReadonly ? 'read-only' : ''}`}
      onChange={(value) => {
        const option = props.options?.find((option) => option.value === value);
        setSelectedOption({ ...selectedOption, value, code: option?.code || '' });
        props.onSelectedChange(value);
      }}
      popupMatchSelectWidth={false}
      labelRender={() => {
        return (
          <Tag color={getColor(selectedOption?.code as string)} style={{ margin: 0 }}>
            {selectedOption?.label}
          </Tag>
        );
      }}
    >
      {props?.options?.map((option) => (
        <Select.Option key={option.value}>
          <Flex align='center' justify='space-between'>
            <Tag color={getColor(option.code)} style={{ margin: 0 }}>
              {option.label}
            </Tag>
            {option.value === selectedOption?.value && <CheckOutlined style={{ color: '#1677FF', marginLeft: 10 }} />}
          </Flex>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectStatus;
