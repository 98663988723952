import { StarOutlined, StarFilled } from '@ant-design/icons';
import { Project, Workspace } from 'model';
import { Card, Col, Row, Typography } from 'antd';
import { motion } from 'framer-motion';
import PROJECT_DEFAULT_IMAGE from 'assets/images/project-types/demo.jpeg';
import MotionImgItem from 'components/common/pre-signed-url-items/MotionImgItem';

type ProjectItemProps = {
  project: Project;
  workspace?: Workspace | null;
  sectionId?: string;
  myWorkspaceId?: string;
  markProjectAsFavorite: (project: Project, isFavorite: boolean) => void;
  onSelectedProject: (project: Project) => void;
};
export default function ProjectItem(props: ProjectItemProps) {
  const { project, workspace, sectionId, markProjectAsFavorite, onSelectedProject } = props;
  const projectImage = project?.coverPath ?? project?.ProjectType?.picture ?? PROJECT_DEFAULT_IMAGE;

  return (
    <motion.div whileHover={{ scale: 1.05, transition: { duration: 0.35 } }}>
      <div title={project.name}>
        <Card
          hoverable
          className='project-card'
          style={{ width: 215 }}
          cover={
            <MotionImgItem
              onClick={() => {
                onSelectedProject(project);
              }}
              layoutId={`${sectionId}-${project.id}`}
              src={projectImage}
            ></MotionImgItem>
          }
        >
          <div className='project-star'>
            {project?.ProjectUser?.isFavorite ? (
              <StarFilled
                onClick={() => markProjectAsFavorite(project, false)}
                style={{ fontSize: 20, color: '#D4B106' }}
              />
            ) : (
              <StarOutlined
                onClick={() => markProjectAsFavorite(project, true)}
                style={{ fontSize: 20, color: '#D4B106' }}
              />
            )}
          </div>
          <Row
            className='project-card-footer'
            onClick={() => {
              onSelectedProject(project);
            }}
          >
            <Col className='project-info'>
              <Typography.Text className='project-title'>{project.name}</Typography.Text>
              <Typography.Text type='secondary'>{project?.Workspace?.name || workspace?.name}</Typography.Text>
            </Col>
          </Row>
        </Card>
      </div>
    </motion.div>
  );
}
