import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from 'store/auth-context';
import { LANGUAGEOPTIONS } from 'utils/contants';

const Language = () => {
  const { t } = useTranslation();
  const { language, onChangeLanguage } = useAuthContext();
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const handleLanguageChange = (language: string) => {
    onChangeLanguage(language);
    setSelectedLanguage(language);
  };
  const languageMenu = (
    <Menu onClick={({ key }) => handleLanguageChange(key)}>
      {LANGUAGEOPTIONS.map((option) => (
        <Menu.Item key={option.id} icon={<img src={option.emoji} alt='' width={24} />}>
          {t(option.label)}
        </Menu.Item>
      ))}
    </Menu>
  );
  const selectedOption = LANGUAGEOPTIONS.find((option) => option.id === selectedLanguage);

  return (
    <Space>
      {selectedOption && <img src={selectedOption.emoji} alt='' width={24} />}
      <Dropdown overlay={languageMenu} placement='bottomRight' arrow>
        <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
          <DownOutlined style={{ color: 'white' }} />
        </a>
      </Dropdown>
    </Space>
  );
};
export default Language;
