import { FilePdfOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { AttachmentType } from 'model';
import React, { useMemo, useRef, useState } from 'react';
import { fileService } from 'services';
import { formatUrlAttachments } from 'utils';

type InspectionTestPlanTableUpdateProps = {
  placeholder: string;
  onChange: (file: { name: string; url: string; attachmentTypeId: string } | null) => void;
  attachmentType: string;
  attachmentTypes: AttachmentType[];
  value?: UploadFile;
};
const InspectionTestPlanTableUpdate = (props: InspectionTestPlanTableUpdateProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>(props.value ? [props.value] : []);
  const attachmentType = props?.attachmentTypes?.find((i) => i.code === props.attachmentType);
  const elementRef = useRef<HTMLAnchorElement>(null);

  const isTextOverflow = useMemo(() => {
    if (!elementRef.current) return false;
    return elementRef.current?.scrollWidth > elementRef?.current?.clientWidth;
  }, [elementRef.current]);

  const handleRequestFile: UploadProps['customRequest'] = async (option) => {
    const response = await fileService.uploadFileAdvanced(
      option.file as File,
      (option.file as File).name,
      'application/pdf',
      10
    );

    setFileList((fileList) => [
      ...fileList,
      {
        ...(option.file as UploadFile),
        name: (option.file as File).name,
        url: response.fileUrl,
      },
    ]);
    props.onChange({
      name: (option.file as File).name,
      url: response.fileUrl,
      attachmentTypeId: attachmentType?.id || '',
    });
  };
  return (
    <Form.Item
      className='control-item'
      valuePropName='fileList'
      getValueFromEvent={(e) => {
        return Array.isArray(e) ? e : e && e.fileList;
      }}
    >
      <Upload
        onRemove={(file) => {
          const deletedFileIndex = fileList.indexOf(file);
          fileList.splice(deletedFileIndex, 1);
          setFileList([...fileList]);
        }}
        listType='text'
        maxCount={1}
        fileList={fileList}
        customRequest={handleRequestFile}
        itemRender={(originNode, file, fileList, action) => {
          return (
            file.url && (
              <div className='flex items-center gap-1 w-[140px]'>
                <FilePdfOutlined className='text-primary' />
                <Tooltip title={`${isTextOverflow ? file.name : ''}`} placement='top'>
                  <a
                    className='text-primary whitespace-nowrap text-ellipsis overflow-hidden'
                    onClick={(e) => {
                      e.preventDefault();
                      fileService.downloadFile(file?.url || '', formatUrlAttachments(file?.url || ''));
                    }}
                    rel={'noreferrer'}
                    ref={elementRef}
                    href={file.url}
                  >
                    {file.name}
                  </a>
                </Tooltip>
                <DeleteOutlined
                  onClick={() => {
                    action.remove();
                    props.onChange(null);
                  }}
                />
              </div>
            )
          );
        }}
        accept='application/pdf'
      >
        {fileList.length === 0 ? <Button icon={<UploadOutlined />}>{props.placeholder}</Button> : ''}
      </Upload>
    </Form.Item>
  );
};

export default InspectionTestPlanTableUpdate;
