import { useState } from 'react';
import SearchSelect from '../../../../../common/SearchSelect';
import { DefaultOptionType } from 'antd/es/select';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectProjectWorkBreakdownStructures } from 'store/my-projects.slice';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { QualityITPData } from 'types/project';

export type InspectionTestPlanTableActiveProps = {
  value?: string;
  onChange?: (data: DefaultOptionType | string) => void;
  type: 'select' | 'input' | 'button' | 'file';
  placeholder?: string;
  disciplineId?: string;
  onClick?: () => void;
  level?: number;
  orderText?: string;
  name?: string;
  activity?: string;
  selectedActivityList?: QualityITPData[];
};

const InspectionTestPlanTableActive = ({
  value,
  onChange,
  type,
  placeholder,
  disciplineId,
  level,
  orderText,
  onClick,
  selectedActivityList,
}: InspectionTestPlanTableActiveProps) => {
  const [activity, setActivity] = useState<DefaultOptionType | null>(null);
  const [subActivity, setSubActivity] = useState<string>('');
  const { projectId } = useParams();
  const workBreakdownStructures = useSelector((state: RootState) =>
    selectProjectWorkBreakdownStructures(state, projectId as string)
  );
  const activityOptions = workBreakdownStructures
    ?.filter((wbs) => wbs?.parentId === disciplineId)
    .map((activity) => {
      const hasSelected = selectedActivityList?.some((data) => data.key === activity?.id);

      return {
        label: activity.name,
        value: activity.id,
        disabled: hasSelected,
      };
    });

  const CustomPlaceholder = (): JSX.Element => {
    return (
      <div>
        <PlusOutlined /> {placeholder}
      </div>
    );
  };

  const getComponent = () => {
    switch (type) {
      case 'select': {
        return (
          <SearchSelect
            className='mb-2 ml-2 select'
            placeholder={<CustomPlaceholder />}
            options={activityOptions}
            onChange={(_, option) => {
              const activityOption = option as DefaultOptionType;
              if (onChange) {
                onChange(activityOption);
              }
              setActivity(null);
            }}
            value={activity}
            dynamicWidth
          />
        );
      }

      case 'input': {
        return (
          <Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            className={'input-field'}
            allowClear={true}
            value={subActivity}
            onPressEnter={(e) => {
              e.stopPropagation();
              if (onChange) {
                onChange(subActivity);
              }
              setSubActivity('');
            }}
            onChange={(e) => setSubActivity(e.target.value)}
            prefix={<PlusOutlined style={{ color: '#1677ff' }} />}
            placeholder={placeholder}
          />
        );
      }
      case 'button': {
        return (
          <div className='tree-item-control' onClick={() => onClick && onClick()}>
            <Button type='dashed' icon={<PlusOutlined />}>
              {placeholder}
            </Button>
          </div>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className={`flex items-center inspection-test-plan-tree-title level-${level}`}>
      <span className={'active-name'}>
        {orderText?.split(' ').join('.')} {value}
      </span>
      {getComponent()}
    </div>
  );
};

export default InspectionTestPlanTableActive;
