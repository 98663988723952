import {
  AttachmentType,
  Country,
  DateFormat,
  IssueDiscipline,
  IssuePriority,
  IssueStatus,
  IssueType,
  Language,
  TimeZone,
  Uom,
  ModuleFunction,
} from 'model';
import { Modules } from 'model/Modules';
import MomStatus from 'model/MomStatus';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const commonService = {
  getCountries: async (query: QueryParams) => {
    return request.get<PaginationData<Country>>(`/countries`, {
      params: query,
    });
  },
  getLanguages: async (query: QueryParams) => {
    return request.get<Language[]>('/languages', {
      params: query,
    });
  },
  getDateFormat: async (query: QueryParams) => {
    return request.get<DateFormat[]>('/date-formats?orderBy=order,asc', {
      params: query,
    });
  },
  getUoms: async (query: QueryParams) => {
    return request.get<Uom[]>('/uoms', {
      params: query,
    });
  },
  getTimeZones: async (query: QueryParams) => {
    return request.get<TimeZone[]>('/timezones', {
      params: query,
    });
  },
  getIssueStatuses: async (query: QueryParams) => {
    return request.get<PaginationData<IssueStatus>>(`/issue-statuses`, {
      params: query,
    });
  },
  getIssuePriorities: async (query: QueryParams) => {
    return request.get<PaginationData<IssuePriority>>(`/issue-priorities`, {
      params: query,
    });
  },
  getIssueTypes: async (query: QueryParams) => {
    return request.get<PaginationData<IssueType>>(`/issue-types`, {
      params: query,
    });
  },
  getIssueDisciplines: async (query: QueryParams) => {
    return request.get<PaginationData<IssueDiscipline>>(`/issue-disciplines`, {
      params: query,
    });
  },
  getAttachmentTypes: async (query: QueryParams) => {
    return request.get<PaginationData<AttachmentType>>(`/attachment-types`, {
      params: query,
    });
  },
  getModuleFunctions: async (query: QueryParams) => {
    return request.get<PaginationData<ModuleFunction>>(`/module-functions`, {
      params: query,
    });
  },
  getModules: async (query: QueryParams) => {
    return request.get<PaginationData<Modules>>(`/modules`, {
      params: query,
    });
  },
  getMomItemStatuses: async (query: QueryParams) => {
    return request.get<MomStatus[]>(`/mom-item-statuses`, {
      params: query,
    });
  },
};

export default commonService;
