import { useSortable } from '@dnd-kit/sortable';
import React, { useState } from 'react';

type SortableInspectionTestPlanRowProps = {
  children?: React.ReactNode;
  'data-row-key'?: string;
  direction?: string;
  className?: string;
};

export const DRAGGABLE_ALLOWED_KEY = 'data-draggable';

const SortableInspectionTestPlanRow = (props: SortableInspectionTestPlanRowProps) => {
  const { children, ...rest } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'] || '',
  });
  const [enableDrag, setEnableDrag] = useState(false);
  const hasEnableDrag = enableDrag && isDragging;

  const style = {
    cursor: 'move',
    opacity: hasEnableDrag ? 0.5 : 1,
    transform: hasEnableDrag && transform ? `translate3d(${transform?.x}px, ${transform?.y}px, 0)` : undefined,
    transition: hasEnableDrag ? 'unset' : transition,
    zIndex: hasEnableDrag ? 9999 : undefined,
  };

  const handleDraggableTarget = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    if (target?.tagName === 'TD' || target?.tagName === 'DIV') {
      setEnableDrag(() => true);
      return;
    }
    setEnableDrag(() => false);
  };

  return (
    <tr
      {...rest}
      id={props['data-row-key']}
      className={`${rest.className}`}
      style={{ ...style, position: isDragging ? 'relative' : undefined }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      data-draggable={enableDrag}
      onMouseDown={handleDraggableTarget}
    >
      {children}
    </tr>
  );
};

export default SortableInspectionTestPlanRow;
