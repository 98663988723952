import { ClockCircleOutlined, CloseCircleOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Flex, Layout, MenuProps, message, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { DeleteConfirmation, SearchInputUser, TablePagination } from 'components';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';
import dayjs from 'dayjs';
import { useParametersSetting } from 'hooks';
import { useMyWorkspaceParams } from 'hooks/workspace';
import { Issue, IssueStatus, User } from 'model';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { issueService } from 'services';
import useAuthContext from 'store/auth-context';
import { QueryParams } from 'types';
import {
  convertDateFormat,
  loadEnLocale,
  loadEsLocale,
  loadFrLocale,
  loadIdLocale,
  loadKmLocale,
  loadPtLocale,
  loadViLocale,
  loadZhCnLocale,
} from 'utils';
import { ISSUE_STATUSES, NUMBERING_DIGITS, TASK_STATUS_COLOR } from 'utils/contants';

type InspectionsProps = {
  users: User[];
  module: string;
  projectId: string;
  workspaceId: string;
  issues: Issue[];
  issuesCount: number;
  issueParams: QueryParams;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (value: React.Key[]) => void;
  onEditIssue: (value: string) => void;
  refreshIssues: () => void;
  refreshUser: () => void;
  loading?: boolean;
  onFilter: (value: QueryParams) => void;
};
export default function InspectionsListView(props: InspectionsProps) {
  const {
    module,
    users,
    projectId,
    workspaceId,
    issues,
    issuesCount,
    issueParams,
    selectedRowKeys,
    setSelectedRowKeys,
    onEditIssue,
    refreshIssues,
    refreshUser,
    loading,
    onFilter,
  } = props;
  const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
  const [locale, setLocale] = useState<string>('en');
  const { t, i18n } = useTranslation();
  const [parametersParams] = useMyWorkspaceParams({ projectId });
  const [parametersSettings, parametersLoading] = useParametersSetting(parametersParams);
  const { profile } = useAuthContext();
  const [isDelete, setIsDelete] = useState<{
    open: boolean;
    issueId?: string;
  }>({
    open: false,
    issueId: undefined,
  });
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const handleMenuClick = (info: MenuInfo, issueId: string) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'edit':
          onEditIssue(issueId);
          break;
        case 'delete':
          setIsDelete({ open: true, issueId });
          break;
      }
    }
  };
  const handleDeleteIssue = async () => {
    try {
      const issueId = isDelete.issueId;
      if (issueId) {
        await issueService.deleteIssueById(workspaceId, projectId, issueId);
        refreshIssues();
        setIsDelete({ open: false, issueId: undefined });
      } else {
        message.error(module && module === 'Safety' ? t('Inspection not existed!') : t('Observation not existed!'));
      }
    } catch (error) {
      console.log(error);
      setIsDelete({ open: false, issueId: undefined });
    }
  };

  const items: MenuProps['items'] = [
    {
      label: module && module === 'Safety' ? t('Edit inspection') : t('Edit observation'),
      key: 'edit',
      icon: <EditOutlined />,
      danger: false,
      disabled: false,
    },
    {
      label: module && module === 'Safety' ? t('Delete inspection') : t('Delete observation'),
      key: 'delete',
      icon: <CloseCircleOutlined />,
      danger: true,
      disabled: true,
    },
  ];
  const menuProps: MenuProps = { items };

  const renderStatusTag = (value: IssueStatus) => {
    return <Tag color={TASK_STATUS_COLOR[value.code]}> {t(value.name)} </Tag>;
  };

  const columns: ColumnsType<Issue> = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'number',
      key: 'createdAt',
      onCell: () => {
        return {
          style: {
            maxWidth: '70px',
          },
        };
      },
      render: (_, record) => {
        const number = record?.number?.split('-')[record?.number?.split('-').length - 1] || '';
        let moduleCode = '';
        if (record?.number) {
          if (record?.number?.split('-').length > 1) {
            moduleCode = record?.number?.split('-')[0] + '-';
          }
        }
        return <span>{number ? '#' + moduleCode + number.padStart(NUMBERING_DIGITS, '0') : ''}</span>;
      },
    },
    {
      sorter: true,
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      onCell: () => {
        return {
          style: {
            width: '300px',
          },
        };
      },
      render: (name, record) => {
        const isReadyForInspection: boolean = record.IssueStatus.code === ISSUE_STATUSES.READY_FOR_INSPECTION;
        const isOpen: boolean = record.IssueStatus.code === ISSUE_STATUSES.OPEN;
        const isExpired: boolean = record?.plannedEndDate
          ? dayjs(record?.plannedEndDate) < dayjs().subtract(1, 'day')
          : false;

        return (
          <Flex align='center'>
            <span className='circle-fill mr-2' style={{ minWidth: '30px' }}>
              {module === 'Safety' ? record?.IssueDiscipline?.code : record?.Discipline?.code}
            </span>
            <Flex style={{ flexDirection: 'column' }}>
              <div>
                <span className='mr-1'> {name ? name : ''} </span>
                {(isOpen || isReadyForInspection) && isExpired ? (
                  <ClockCircleOutlined style={{ color: '#FF4D4F' }} />
                ) : (
                  ''
                )}
              </div>
            </Flex>
          </Flex>
        );
      },
    },
    {
      // sorter: true,
      title: module && module === 'Safety' ? t('Inspection type') : t('Observation type'),
      dataIndex: 'IssueType',
      key: 'issueTypeId',
      width: '80px',
      render: (type) => <span>{type?.name}</span>,
    },
    {
      sorter: true,
      title: t('Company'),
      dataIndex: 'Company',
      key: 'Company.name',
      render: (company) => <span>{company?.name}</span>,
    },
    {
      // sorter: true,
      title: t('Assignee'),
      dataIndex: 'assigneeId',
      key: 'assigneeId',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (assigneeId, record) => {
        const assigneeName = users?.find((user) => user.id === assigneeId)?.fullName;
        return (
          <SearchInputUser
            users={users}
            dynamicClassName='search-user'
            refreshUser={refreshUser}
            refreshIssues={refreshIssues}
            workspaceId={workspaceId}
            projectId={projectId}
            issueId={record.id}
            companyId={record?.Company?.id}
            assigneeName={assigneeName}
            reporterId={record.reporterId}
            profile={profile}
            assigneeId={assigneeId}
            status={record.IssueStatus?.code}
          />
        );
      },
    },
    {
      // sorter: true,
      title: t('Priority'),
      dataIndex: 'IssuePriority',
      key: 'IssuePriority.orderNumber',
      render: (priority) => {
        return priority?.icon ? <img src={priority?.icon} alt='' className='mr-2' width={16} height={16} /> : <></>;
      },
    },
    {
      sorter: true,
      title: module && module === 'Safety' ? t('Inspection date') : t('Observation date'),
      width: '80px',
      dataIndex: 'plannedStartDate',
      key: 'plannedStartDate',
      render: (plannedStartDate) => <span>{dayjs(plannedStartDate).locale(locale).format(dateFormat).toString()}</span>,
    },
    {
      sorter: true,
      title: t('Status'),
      dataIndex: 'IssueStatus',
      key: 'IssueStatus',
      render: (value) => renderStatusTag(value),
    },
    {
      sorter: true,
      title: t('Zoning'),
      dataIndex: 'Zone',
      key: 'Zone.name',
      render: (zone) => <span>{zone?.name}</span>,
    },
    {
      align: 'right',
      dataIndex: 'id',
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (value, record: Issue) => {
        const isAssignerCompany =
          userCompanyId === users?.find((user) => user.id === record.reporterId)?.WorkspaceUser?.Company?.id;
        const isAssignee = profile?.id === record?.assigneeId;
        const isDeleteRecord = isAssignerCompany && (!isAssignee || record?.reporterId === record?.assigneeId);
        // Disable delete button
        let updatedMenuProps = { ...menuProps };
        if (menuProps.items) {
          const updatedItems = menuProps.items.map((item) => {
            if (item?.key === 'delete') {
              return {
                ...item,
                disabled: !isDeleteRecord,
              };
            }
            return item;
          });
          updatedMenuProps = { ...menuProps, items: updatedItems };
        }
        return (
          <>
            <Flex justify={'flex-end'}>
              {record.Photos?.length > 0 && (
                <ImgItem
                  className='border-image ma-2'
                  style={{ height: 24 }}
                  alt=''
                  src={record.Photos[0].thumbnail}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = record.Photos[0].filePath as string;
                  }}
                />
              )}
              {(record.Plans?.length || 0) > 0 && (
                <ImgItem
                  className='border-image ma-2'
                  style={{ height: 24 }}
                  alt=''
                  src={record.Plans?.[0].thumbnail}
                />
              )}
              <Dropdown
                menu={{
                  ...updatedMenuProps,
                  onClick: (info) => handleMenuClick(info, value),
                }}
                trigger={['click']}
              >
                <EllipsisOutlined className='icon-ellipse' />
              </Dropdown>
            </Flex>
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Issue[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: Issue) => ({ name: record.name }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys: React.Key[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((key: React.Key, index: number) => {
            return index % 2 !== 0 ? false : true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys: React.Key[]) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((key: React.Key, index: number) => {
            return index % 2 !== 0 ? true : false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Issue> | SorterResult<Issue>[]
    // extra: TableCurrentDataSource<Issue>
  ) => {
    if (sorter) {
      let sorterOrder: string | null;
      if (Array.isArray(sorter)) {
        // If sorter is an array, use the first item's order
        const firstSorter = sorter[0];
        sorterOrder = firstSorter.order === 'ascend' ? `asc` : firstSorter.order === 'descend' ? 'desc' : null;
      } else {
        // If sorter is a single object, use its order directly
        sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      }
      let orderBy = 'createdAt,desc';
      if (Array.isArray(sorter)) {
        // If sorter is an array, use the first item's order and columnKey
        const firstSorter = sorter[0];
        sorterOrder = firstSorter.order === 'ascend' ? `asc` : firstSorter.order === 'descend' ? 'desc' : null;
        if (sorterOrder) {
          if (firstSorter.columnKey === 'IssueStatus') {
            orderBy = `IssueStatus.orderNumber,${sorterOrder}|plannedEndDate,${sorterOrder}`;
          } else {
            orderBy = `${firstSorter.columnKey},${sorterOrder}`;
          }
        }
      } else {
        // If sorter is a single object, use its order and columnKey directly
        sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
        if (sorterOrder) {
          if (sorter.columnKey === 'IssueStatus') {
            orderBy = `IssueStatus.orderNumber,${sorterOrder}|plannedEndDate,${sorterOrder}`;
          } else {
            orderBy = `${sorter.columnKey},${sorterOrder}`;
          }
        }
      }
      onFilter({ orderBy });
    }
  };
  const handleLanguageChange = (newLocale: string) => {
    // Load the locale dynamically using import
    switch (newLocale) {
      case 'en':
        loadEnLocale();
        break;
      case 'fr':
        loadFrLocale();
        break;
      case 'vn':
        loadViLocale();
        return setLocale('vi');
        break;
      case 'cn':
        loadZhCnLocale();
        return setLocale('zh-cn');
        break;
      case 'pt':
        loadPtLocale();
        break;
      case 'id':
        loadIdLocale();
        break;
      case 'km':
        loadKmLocale();
        break;
      case 'es':
        loadEsLocale();
        break;
      default:
        break;
    }
    setLocale(newLocale);
  };

  const handleGetDateFormat = async () => {
    const date = parametersSettings.find(
      (obj) => (obj as { code: string; value: string }).code === 'date_format'
    )?.value;
    if (date) {
      setDateFormat(convertDateFormat(date));
    }
  };
  useEffect(() => {
    if (parametersLoading !== 'pending') {
      handleGetDateFormat();
    }
  }, [parametersLoading]);
  useEffect(() => {
    handleLanguageChange(i18n.language);
  }, [i18n.language]);
  return (
    <Layout.Content className='settings-content'>
      <DeleteConfirmation
        isModalOpen={isDelete.open}
        title={module && module === 'Safety' ? t('Delete the inspection') : t('Delete the observation')}
        description={
          module && module === 'Safety'
            ? t('Are you sure to delete this inspection?')
            : t('Are you sure to delete this observation?')
        }
        onDelete={handleDeleteIssue}
        onCancel={() => setIsDelete({ open: false, issueId: undefined })}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={issueParams}
        issuesCount={issuesCount}
      />
      <Table
        rowSelection={{
          onCell: () => ({
            onClick: (event) => {
              event.stopPropagation();
            },
          }),
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={false}
        loading={loading}
        className='workspaces-list-table my-4 inspection-table'
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={issues}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => onEditIssue(record.id),
        })}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={issueParams}
        issuesCount={issuesCount}
      />
    </Layout.Content>
  );
}
