import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { ModalState } from 'types';
import { UploadDocument } from 'components/common';
import { Attachment, AttachmentInfo, BaseIssue } from 'model';
import { attachmentService } from 'services';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { MaterialDetailType } from './MaterialDetail';
import AttachmentDocumentList, { AttachmentDocumentData } from 'components/common/AttachmentDocumentList';
import { selectProjectUsers } from 'store/my-projects.slice';
import { useAppSelector } from 'store';
import useAuthContext from 'store/auth-context';

type MARsListDocumentSectionProps = {
  isReadOnly: boolean;
  selectedMarBaseIssue: BaseIssue | null;
  workspaceId: string;
  projectId: string;
  position: MaterialDetailType;
  attachments: Attachment[];
  documentsInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  fetchMarAttachments: (position?: string) => void;
  setDocumentsInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
};
export type MARFileUpload = {
  attachmentTypeId?: string | null;
  name?: string;
  filePath?: string;
  createdAt?: string;
  fullName?: string;
  firstName?: string;
  profilePhoto?: string;
  comment?: string;
};
export default function MARsListDocumentSection(props: MARsListDocumentSectionProps) {
  const { t } = useTranslation();
  const {
    isReadOnly,
    selectedMarBaseIssue,
    workspaceId,
    projectId,
    position,
    attachments,
    documentsInfo,
    setDocumentsInfo,
    fetchMarAttachments,
  } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const users = useAppSelector(selectProjectUsers);
  const { profile } = useAuthContext();
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const marDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MAR_DOCUMENT);

  const attachmentResult: AttachmentDocumentData[] = useMemo(() => {
    setLoading(true);
    const newAttachments = attachments.map((attachment) => ({
      ...attachment,
      userCreated: attachment?.MarAttachments?.[0]?.userCreated,
    }));
    let currentAttachments = [] as AttachmentInfo[];
    let userIds: (string | undefined)[] = [];
    if (attachments?.length > 0) {
      currentAttachments = (selectedMarBaseIssue ? newAttachments : attachments) as AttachmentInfo[];
      userIds = [...new Set(currentAttachments?.map((attachment) => attachment?.userCreated))];
    } else if (position === 'proposed') {
      currentAttachments = (selectedMarBaseIssue ? newAttachments : documentsInfo.proposed) as AttachmentInfo[];
      userIds = [...new Set(currentAttachments?.map((attachment) => attachment?.userCreated))];
    } else {
      currentAttachments = (selectedMarBaseIssue ? newAttachments : documentsInfo.specified) as AttachmentInfo[];
      userIds = [...new Set(currentAttachments?.map((attachment) => attachment?.userCreated))];
    }
    const userList = users?.filter((user) => userIds.includes(user.id));
    setLoading(false);
    return userList.map((user) => {
      const userAttachments = (currentAttachments as (AttachmentInfo & Attachment)[])?.filter(
        (attachment) => attachment.userCreated === user.id
      );
      const isUploadedUser = user?.id === profile?.id;
      const currentUser = users?.find((user) => user.id === profile?.id);
      const isCompanyOriginator = currentUser?.WorkspaceUser?.Company?.id === selectedMarBaseIssue?.companyOriginatorId;
      const createdAt = userAttachments[userAttachments.length - 1]?.createdAt || new Date().toISOString();

      return {
        attachmentDocuments: userAttachments
          ?.filter((i) => i.attachmentTypeId === marDocumentType?.id)
          .map((attachment: { name?: string; filePath?: string }) => ({
            filePath: attachment.filePath,
            fileName: attachment?.name,
            hideDelete: !isCompanyOriginator && !isUploadedUser,
          })),
        userInfo: {
          fullName: user?.fullName,
          firstName: user?.firstName,
          profilePhoto: user?.profilePhoto,
          createdAt,
        },
      };
    });
  }, [
    attachments,
    documentsInfo.proposed.length,
    documentsInfo.specified.length,
    selectedMarBaseIssue,
    users,
    position,
  ]);

  const isDisable = useMemo(() => {
    if (attachmentResult?.length === 0) {
      return false;
    }
    return attachmentResult[0].attachmentDocuments.length >= 3;
  }, [attachmentResult]);

  const handleAddDocument = async (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => {
    try {
      if (selectedMarBaseIssue) {
        const materialProposed = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
          (material: { isProposed: boolean }) => material.isProposed === true
        );
        let marDescriptionId = materialProposed?.id;
        if (position === 'specified') {
          const materialSpecified = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
            (material: { isProposed: boolean }) => material.isProposed === false
          );
          marDescriptionId = materialSpecified?.id;
        }
        await attachmentService.createAttachment(workspaceId, projectId, {
          filePath: response.fileUrl,
          name: name,
          attachmentTypeId: marDocumentType?.id as string,
          zoneId: selectedMarBaseIssue?.zoneId,
          baseIssueId: selectedMarBaseIssue.id,
          marDescriptionId: marDescriptionId,
        });
        fetchMarAttachments();
      } else {
        if (marDocumentType) {
          const data: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] } = { ...documentsInfo };
          if (position === 'proposed') {
            data.proposed.push({
              name,
              filePath: response.fileUrl,
              attachmentTypeId: marDocumentType?.id,
              userCreated: profile?.id || '',
            });
          } else {
            data.specified.push({
              name,
              filePath: response.fileUrl,
              attachmentTypeId: marDocumentType?.id,
              userCreated: profile?.id || '',
            });
          }
          setDocumentsInfo(data);
        }
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };

  const handleRemoveDocument = async (filePath: string) => {
    try {
      if (selectedMarBaseIssue) {
        setLoading(true);
        const id = attachments.find((attachment) => attachment.filePath === filePath)?.id || '';
        await attachmentService.deleteAttachment(workspaceId, projectId, id);
        fetchMarAttachments(position);
        setLoading(false);
      } else {
        const data: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] } = { ...documentsInfo };
        const index = (data.proposed || data.specified).findIndex((file) => file.filePath === filePath);
        if (position === 'proposed') {
          data.proposed.splice(index, 1);
        } else {
          data.specified.splice(index, 1);
        }
        setDocumentsInfo(data);
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };

  return (
    <div className='list-document-popup-container'>
      <Row gutter={24}>
        {isOpenUpload !== null && (
          <UploadDocument
            isModalOpen={isOpenUpload}
            setIsModalOpen={(value: boolean) => setIsOpenUpload(value)}
            handleAddDocument={(name: string, response) => handleAddDocument(name, response)}
          />
        )}
        <div className='mar-document-container'>
          <Typography.Title className='material-group-title' level={3}>
            {t('Material Approval Request')}
          </Typography.Title>
          {!isReadOnly && (
            <Button
              type='dashed'
              icon={<PlusOutlined />}
              onClick={() => setIsOpenUpload(true)}
              disabled={isDisable}
              className='text-size-12'
            >
              {t('Add Document')}
            </Button>
          )}
        </div>
        {attachmentResult?.length > 0 ? (
          <AttachmentDocumentList
            isLoading={loading}
            data={attachmentResult}
            hideDelete={props.isReadOnly}
            onRemoveFile={handleRemoveDocument}
          />
        ) : (
          <Empty style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        )}
      </Row>
    </div>
  );
}
