import { Dropdown, Table, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Company } from 'model';
import { useState } from 'react';
import { ColumnsType, TableProps } from 'antd/es/table';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FolderOpenOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { TablePagination } from 'components';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { CompanyProfile } from 'components/company';
import DeleteCompanyConfirmation from 'components/company/DeleteCompanyConfirmation';
import { QueryParams } from 'types';
type WorkspaceCompanyListProps = {
  companies: Company[];
  loading?: boolean;
  refreshCompanies: () => void;
  issuesCount: number;
  issueParams: QueryParams;
  onFilter: (value: QueryParams) => void;
};
export default function WorkspaceCompanyList(props: WorkspaceCompanyListProps) {
  const [isCompanyProfile, setIsCompanyProfile] = useState<boolean | null>(null);
  const [isCompanyDelete, setIsCompanyDelete] = useState<boolean | null>(null);
  const [companyDetails, setCompanyDetails] = useState<Company | undefined>();
  const { companies, loading, refreshCompanies, issuesCount, issueParams, onFilter } = props;
  const { t } = useTranslation();
  const onCompanyEdit = (value: Company) => {
    setCompanyDetails(value);
    setIsCompanyProfile(true);
  };
  const onCompanyDelete = async (value: Company) => {
    setCompanyDetails(value);
    setIsCompanyDelete(true);
  };

  const onCompanyUpdated = () => {
    refreshCompanies();
    setIsCompanyProfile(false);
  };
  const onCompanyDeleted = () => {
    refreshCompanies();
    setIsCompanyDelete(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableProps['rowSelection'] = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Company[]) => {
      console.log(`newSelectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: Company) => ({
      name: record.name,
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const columns: ColumnsType<Company> = [
    {
      sorter: true,
      title: t('Company'),
      dataIndex: 'name',
      key: 'name',
      render: (value) => <span>{value}</span>,
    },
    {
      sorter: true,
      title: t('Code'),
      dataIndex: 'companyCode',
      key: 'companyCode',
      render: (value) => <span>{value}</span>,
    },
    {
      sorter: true,
      title: t('Category'),
      dataIndex: 'CompanyCategory',
      key: 'CompanyCategory.name',
      render: (value) => <span>{value?.name}</span>,
    },
    {
      title: t('Projects'),
      dataIndex: 'ProjectCompanies',
      width: '15%',
      render: (value) => (
        <span>
          {value?.length} {t('Projects')}
        </span>
      ),
    },
    {
      title: t('Members'),
      dataIndex: 'WorkspaceUsers',
      width: '15%',
      render: (value) => (
        <span>
          {value?.length} {t('members')}
        </span>
      ),
    },
    {
      title: '',
      width: 10,
      align: 'center',
      render: (value) => (
        <Dropdown
          menu={{
            items: [
              {
                label: t('Invite members'),
                key: '1',
                icon: <UsergroupAddOutlined />,
              },
              {
                label: t('Edit company'),
                key: '2',
                icon: <EditOutlined />,
                onClick: () => onCompanyEdit(value),
              },
              {
                label: t('Add to project'),
                key: '3',
                icon: <FolderOpenOutlined />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Delete company')}</span>,
                key: '4',
                onClick: () => onCompanyDelete(value),
                icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
              },
            ],
            onClick: (e) => console.log('click', e),
          }}
        >
          <EllipsisOutlined className='cursor-pointer' />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Company>
  ) => {
    if (sorter) {
      const sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        orderBy = `${sorter.columnKey},${sorterOrder}`;
      }
      onFilter({ orderBy });
    }
  };
  return (
    <Layout.Content className='settings-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={issueParams}
        issuesCount={issuesCount}
      />
      {isCompanyProfile !== null && (
        <CompanyProfile
          isModalOpen={isCompanyProfile}
          companyDetails={companyDetails}
          setIsModalOpen={setIsCompanyProfile}
          onSubmit={onCompanyUpdated}
        />
      )}
      {isCompanyDelete !== null && (
        <DeleteCompanyConfirmation
          isModalOpen={isCompanyDelete}
          companyDetails={companyDetails}
          setIsModalOpen={setIsCompanyDelete}
          onSubmit={onCompanyDeleted}
        />
      )}
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading}
        pagination={false}
        className='workspaces-list-table my-4'
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={companies}
        onChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter as SorterResult<Company>)
        }
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={issueParams}
        issuesCount={issuesCount}
      />
    </Layout.Content>
  );
}
