import { useMemo } from 'react';
import { QueryParams } from 'types';
import { SafetyRiskActivity } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchProjectSafetyRiskActivities } from 'store/my-projects.slice';
import { RootState } from 'store';

const selectProjectSafetyRiskActivities = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.safetyRiskActivities;
const selectProjectSafetyRiskActivitiesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.safetyRiskActivitiesLoading;

export const useProjectSafetyRiskActivitiesParams = (
  options: {
    workspaceId?: string;
    projectId?: string;
  } & { [key: string]: any }
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, [workspaceId, projectId, query?.limit, query?.page, query?.search, query?.sort]);
};

export const useProjectSafetyRiskActivities = (query: any) => {
  const { projectId } = query;
  return useFetchByParams<SafetyRiskActivity[]>({
    action: fetchProjectSafetyRiskActivities,
    dataSelector: (state: RootState) =>  
      selectProjectSafetyRiskActivities(state, projectId),
    loadingSelector: (state: RootState) =>
      selectProjectSafetyRiskActivitiesLoading(state, projectId),
    params: query,
  });
};
