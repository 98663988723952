import { BlockOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Form, FormInstance, Row, Select, TreeSelect } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DataNode } from 'antd/es/tree';
import { CustomizeRequiredMark, SearchSelect } from 'components/common';
import { findPath } from 'components/common/TreeSelectTagRender';
import {
  Company,
  Issue,
  IssueDiscipline,
  IssuePriority,
  IssueType,
  Phase,
  User,
  WorkBreakdownStructure,
  Zone,
} from 'model';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectParametersSetting } from 'store/my-projects.slice';
import { convertDateFormat } from 'utils';
const { Option } = Select;

type CreateIssueDetailProps = {
  form: FormInstance;
  issue?: Issue | null;
  companies: Company[];
  users: User[];
  issuePriorities: IssuePriority[];
  issueDisciplines: IssueDiscipline[];
  issueTypes: IssueType[];
  zones: Zone[];
  phases: Phase[];
  onChangeDiscipline: (value: string) => void;
  isUnSaveStatus?: boolean;
  onSelectPlan?: () => void;
  hasPlan?: boolean;
  onChangeWatcher?: () => void;
  profile?: User | null;
  isClosedStatus?: boolean;
  isRFIStatus?: boolean;
  isOpen: boolean;
  isDraftStatus: boolean;
  isNotAssigneeOrReporter: boolean;
  isAssigner?: boolean;
  isDisabled?: boolean;
  isFromPlan?: boolean;
  onValuesChange?: (value: { name?: SetStateAction<string>; zoneId?: SetStateAction<string> }) => void;
  zoneUpdated: string;
  zonesTreeData: DataNode[];
  setZoneUpdated: (value: string) => void;
  module: string;
  disciplines: WorkBreakdownStructure[];
  activities: WorkBreakdownStructure[];
  onChangeWbsDiscipline: (value: string) => void;
  isGoodPractice?: boolean;
};

export default function CreateIssueDetail(props: CreateIssueDetailProps) {
  const {
    issue,
    form,
    companies,
    users,
    issuePriorities,
    issueDisciplines,
    issueTypes,
    phases,
    onSelectPlan,
    hasPlan,
    onChangeDiscipline,
    isUnSaveStatus,
    onChangeWatcher,
    profile,
    isClosedStatus,
    isRFIStatus,
    isOpen,
    isDraftStatus,
    isNotAssigneeOrReporter,
    isAssigner,
    isDisabled,
    isFromPlan,
    zoneUpdated,
    zonesTreeData,
    setZoneUpdated,
    onValuesChange,
    module,
    disciplines,
    activities,
    onChangeWbsDiscipline,
    isGoodPractice,
  } = props;

  const { t } = useTranslation();
  const [selectedCompanyId, setSelectedCompanyId] = useState(issue?.companyId);
  const [selectedDisciplineId, setSelectedDisciplineId] = useState(issue?.disciplineId);
  const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
  const [isActivityDisabled, setIsActivityDisabled] = useState<boolean>(true);
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const isWatcherDisable = issue?.id
    ? isClosedStatus ||
      isRFIStatus ||
      (isNotAssigneeOrReporter && userCompanyId !== issue?.companyId && !isAssigner) ||
      (isDraftStatus && !isAssigner)
    : false;
  const activityFilter = useMemo(() => {
    return activities?.filter((activity) => activity.parentId === selectedDisciplineId);
  }, [selectedDisciplineId, activities, isOpen]);
  useEffect(() => {
    if (issue?.id) {
      const existingDiscipline = disciplines?.findIndex((discipline) => discipline.id === issue?.disciplineId);
      form.setFieldValue(['disciplineId'], existingDiscipline > -1 ? issue?.disciplineId : null);
      setSelectedDisciplineId(issue?.disciplineId);
    } else {
      setSelectedDisciplineId('');
    }
  }, [isOpen, issue?.id]);

  useEffect(() => {
    if (issue?.activityId && activityFilter && activityFilter.length > 0) {
      const findActivity = activityFilter?.find((user) => user.id === issue?.activityId);
      if (!findActivity) form.setFieldValue('activityId', null);
    }
    form.getFieldValue('disciplineId');
  }, [activityFilter, issue?.activityId]);

  const onSelectWbsDiscipline = (disciplineId: string) => {
    if (!disciplineId) {
      form.setFieldValue('disciplineId', null);
      onChangeWbsDiscipline(disciplineId);
      setSelectedDisciplineId(disciplineId);
      form.setFieldValue('activityId', null);
      setIsActivityDisabled(true);
    } else {
      setSelectedDisciplineId(disciplineId);
      form.setFieldValue('activityId', null);
      onChangeWbsDiscipline(disciplineId);
      setIsActivityDisabled(false);
    }
  };

  useEffect(() => {
    if (selectedDisciplineId) {
      setIsActivityDisabled(false);
    } else {
      setIsActivityDisabled(true);
    }
  }, [selectedDisciplineId]);

  const onChangeActivity = (activityId: string) => {
    if (!activityId) {
      form.setFieldValue('activityId', null);
    } else {
      const selectedActivity = activities?.find((activity) => activity.id === activityId);
      const existingDiscipline = disciplines?.findIndex((discipline) => discipline.id === selectedActivity?.parentId);
      if (disciplines[existingDiscipline]?.id) setSelectedDisciplineId(disciplines[existingDiscipline]?.id);
      form.setFieldValue(['disciplineId'], existingDiscipline > -1 ? selectedActivity?.parentId : null);
    }
    form.validateFields(['disciplineId']);
  };

  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [selectedCompanyId, isOpen]);
  useEffect(() => {
    if (issue?.id) {
      //Company setup
      const existingCompany = companies?.findIndex((company) => company.id === issue?.companyId);
      form.setFieldValue(['companyId'], existingCompany > -1 ? issue?.companyId : null);
      setSelectedCompanyId(issue?.companyId);
    } else {
      setSelectedCompanyId('');
    }
  }, [isOpen, issue?.id]);

  useEffect(() => {
    if (issue?.assigneeId && assigneeFilter && assigneeFilter.length > 0) {
      const findAssignee = assigneeFilter?.find((user) => user.id === issue?.assigneeId);
      if (!findAssignee) form.setFieldValue('assigneeId', null);
    }
    form.getFieldValue('companyId');
  }, [assigneeFilter, issue?.assigneeId]);

  const onChangeCompany = (companyId: string) => {
    if (!companyId) {
      form.setFieldValue('companyId', null);
    } else {
      setSelectedCompanyId(companyId);
      form.setFieldValue('assigneeId', null);
    }
  };

  const onChangeAssignee = (assigneeId: string) => {
    if (!assigneeId) {
      form.setFieldValue('assigneeId', null);
    } else {
      const selectedUser = users?.find((user) => user.id === assigneeId);
      const existingCompany = companies?.findIndex(
        (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
      );
      if (companies[existingCompany]?.id) setSelectedCompanyId(companies[existingCompany]?.id);
      form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
    }
    form.validateFields(['companyId']);
  };

  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const capitalizedValue = capitalizeFirstLetter(e.target.value);
    form.setFieldsValue({ description: capitalizedValue });
  };
  const parametersSettings = useSelector(selectParametersSetting);
  const handleGetDateFormat = async () => {
    const date = parametersSettings.find((obj: { code: string }) => obj.code === 'date_format')?.value;

    if (date) {
      setDateFormat(convertDateFormat(date));
    }
  };
  useEffect(() => {
    handleGetDateFormat();
  });

  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(findPath(zonesTreeData, zoneId));
  };
  return (
    <div className='create-issue-form'>
      <Form requiredMark={CustomizeRequiredMark} form={form} layout='vertical' onValuesChange={onValuesChange}>
        <Row>
          <Col span={24}>
            <Form.Item
              name='name'
              label={module && module === 'Safety' ? t('Inspection name') : t('Observation name')}
              rules={[
                {
                  required: true,
                  message: `${module && module === 'Safety' ? t('Please enter Inspection name') : t('Please enter Observation name')}`,
                },
                {
                  max: 50,
                  message:
                    module && module === 'Safety'
                      ? t('Inspection name cannot exceed 50 characters')
                      : t('Observation name cannot exceed 50 characters'),
                },
              ]}
            >
              <TextArea
                count={{
                  show: !isDisabled,
                  max: 50,
                }}
                allowClear={!isDisabled}
                placeholder={module && module === 'Safety' ? t('Enter inspection name') : t('Enter observation name')}
                autoSize={{ minRows: 1, maxRows: 6 }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='issueTypeId'
              label={module && module === 'Safety' ? t('Inspection type') : t('Observation type')}
              rules={[
                {
                  required: true,
                  message: `${module && module === 'Safety' ? t('Please select Inspection type') : t('Please select Observation type')}`,
                },
              ]}
            >
              <SearchSelect
                placeholder={t('Select type')}
                options={issueTypes?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                disabled={isDisabled}
              />
            </Form.Item>
            {isGoodPractice && (
              <Alert
                message={t(`Good Practice limits Observation status to Draft or Close only!`)}
                type='warning'
                showIcon
                className='good-practice-message'
              />
            )}
          </Col>
          {module === 'Quality' ? (
            <Col span={12}>
              <Form.Item name='issuePriorityId' label={t('Priority')} rules={[{ required: true, message: '' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder='Select priority'
                  defaultValue={[t('Select priority')]}
                  disabled={isDisabled}
                >
                  {issuePriorities?.map((option) => (
                    <Option key={option.id} value={option.id} label={option.name}>
                      <div>
                        {option.icon && <img src={option.icon} alt='' width={16} height={16} className='mr-2' />}
                        <span>{option.name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item
                name='issueDisciplineId'
                label={t('Inspection discipline')}
                rules={[{ required: true, message: `${t('Please select Inspection discipline')}` }]}
              >
                <SearchSelect
                  placeholder={t('Select discipline')}
                  onChange={onChangeDiscipline}
                  disabled={isDisabled}
                  options={issueDisciplines?.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='companyId'
              label={t('Company assignee')}
              rules={[{ required: true, message: `${t('Please select Company assignee')}` }]}
            >
              <SearchSelect
                placeholder={t('Select')}
                onChange={onChangeCompany}
                disabled={isDisabled}
                options={companies?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='assigneeId' label={t('Assignee')} rules={[{ required: false, message: '' }]}>
              <SearchSelect
                placeholder={t('Select')}
                onChange={onChangeAssignee}
                disabled={isDisabled}
                options={assigneeFilter?.map((option) => {
                  return {
                    value: option.id,
                    label: option.fullName,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {module === 'Safety' && (
            <Col span={12}>
              <Form.Item name='issuePriorityId' label={t('Priority')} rules={[{ required: true, message: '' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder='Select priority'
                  defaultValue={[t('Select priority')]}
                  disabled={isDisabled}
                >
                  {issuePriorities?.map((option) => (
                    <Option key={option.id} value={option.id} label={option.name}>
                      <div>
                        {option.icon && <img src={option.icon} alt='' width={16} height={16} className='mr-2' />}
                        <span>{option.name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name='phaseId' label={t('Stage')}>
              <SearchSelect
                placeholder={t('Select')}
                disabled={isDisabled}
                options={phases?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        {module === 'Quality' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='disciplineId'
                label={t('Discipline')}
                rules={[{ required: true, message: `${t('Please select Observation discipline')}` }]}
              >
                <SearchSelect
                  placeholder={t('Select discipline')}
                  onChange={onSelectWbsDiscipline}
                  disabled={isDisabled}
                  options={disciplines?.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='activityId' label={t('Activity')}>
                <SearchSelect
                  placeholder={t('Select')}
                  disabled={isActivityDisabled || isDisabled}
                  onChange={onChangeActivity}
                  options={activityFilter?.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='plannedStartDate'
              label={module && module === 'Safety' ? t('Inspection date') : t('Observation date')}
            >
              <DatePicker
                disabled={isDraftStatus || isUnSaveStatus || isDisabled || (!isDisabled && isAssigner) || !isAssigner}
                placeholder={t('Select')}
                style={{ width: '100%' }}
                format={dateFormat}
              />
            </Form.Item>
          </Col>

          {!isClosedStatus ? (
            <Col span={12}>
              <Form.Item
                name='plannedEndDate'
                label={t('Deadline')}
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !getFieldValue('plannedStartDate') ||
                        (value &&
                          getFieldValue('plannedStartDate') &&
                          value > getFieldValue('plannedStartDate')?.subtract(1, 'day'))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error());
                    },
                  }),
                ]}
              >
                <DatePicker
                  disabled={isDisabled}
                  placeholder={t('Select date')}
                  style={{ width: '100%' }}
                  format={dateFormat}
                  disabledDate={(date) => date.isBefore(form.getFieldValue('plannedStartDate')?.subtract(1, 'day'))}
                />
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item
                name='effectiveEndDate'
                label={t('End date')}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !getFieldValue('effectiveEndDate') ||
                        (value && getFieldValue('v') && value >= getFieldValue('effectiveEndDate'))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error());
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder={t('Select date')}
                  style={{ width: '100%' }}
                  format='YYYY-MM-DD'
                  disabled={isClosedStatus}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='effectiveStartDate' label={t('Start date') }>
              <DatePicker
                disabled={false}
                placeholder={t('Select date')}
                style={{ width: '100%' }}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          </Col> */}

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name='watcherIds' label={t('watchers')} rules={[{ required: false, message: '' }]}>
              <SearchSelect
                disabled={isWatcherDisable}
                onChange={onChangeWatcher}
                showSearch
                mode='multiple'
                allowClear
                maxTagCount='responsive'
                placeholder={t('Add watchers')}
                optionFilterProp='children'
                options={users?.map((option) => ({
                  value: option.id,
                  label: option.fullName,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name='description'
              label={t('Description')}
              rules={[
                {
                  max: 300,
                  message: t('Description cannot exceed 300 characters'),
                },
              ]}
            >
              <TextArea
                style={{ textTransform: 'none' }}
                count={{
                  show: !isDisabled,
                  max: 300,
                }}
                allowClear={!isDisabled}
                placeholder={t('Enter description')}
                autoSize={{ minRows: 1, maxRows: 6 }}
                disabled={isDisabled}
                onChange={handleDescriptionChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} align='top'>
          <Col span={12}>
            <Form.Item name='zoneId' label={t('Zoning')} rules={[{ required: true, message: '' }]}>
              <TreeSelect
                value={zoneUpdated}
                disabled={isDraftStatus || isUnSaveStatus || isDisabled || (!isDisabled && isAssigner)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={zonesTreeData}
                placeholder={t('Select')}
                treeDefaultExpandAll
                onChange={onChangeZone}
              />
              <div style={{ display: 'none' }}>{zoneUpdated}</div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label=' '>
              <Button
                disabled={isDisabled || isFromPlan}
                className='primary-dashed-btn'
                icon={<BlockOutlined />}
                type='dashed'
                onClick={onSelectPlan}
                style={{ width: '100%' }}
              >
                {hasPlan ? t('Change plan') : t('Select plan')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {/* <div className='sub-title mt-6'>
          Inspection #1232 created by John Smith on 2024-01-15 @ 08:15
        </div> */}
        {/* <Divider className='customize-divider mt-6' /> */}
      </Form>
    </div>
  );
}
