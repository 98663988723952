import { CommentOutlined, DeleteOutlined, TagOutlined } from '@ant-design/icons';
import { Drawer, Form, Layout, message, Spin, Tabs, TabsProps } from 'antd';
import {
  CustomizeRequiredMark,
  DeleteConfirmation,
  PermitToWorkDetail,
  PermitToWorkHistory,
  PermitToWorkInspectionModal,
  PermitToWorkValidationStep,
  PermitToWorkHeader,
} from 'components';
import dayjs from 'dayjs';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useMyWorkspaceWorkPackagesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectWorkPackages,
} from 'hooks';
import useUserEntity from 'hooks/common/useUserProfile';
import { mockPTWDetails } from 'mock-data/project-data';
import { Attachment, Company, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import { selectProjectUsers } from 'store/my-projects.slice';
import { ATTACHMENT_TYPES, TASK_STATUS_HEADER_COLOR, VALIDATION_STATUS, PTW_STATUSES } from 'utils/contants';
import { PTWFileUpload, PTWFormValues } from './PermitToWorkForm/PermitToWorkDetail';
import PermitToWorkFooter, { EActionType, FooterProps, WorkspaceViewType } from './PermitToWorkForm/PermitToWorkFooter';
import { PTWValidationData } from './PermitToWorkForm/PermitToWorkValidationStep';

type PermitToWorkFormProps = {
  isOpen: boolean;
  module: string;
  workspaceId: string;
  projectId: string;
  profile: User | null;
  selectedId?: string | null;
  companies?: Company[];
  users?: User[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshBaseIssues: (params: string, selectedId?: string) => void;
  refreshDisciplineByFunction: () => void;
};

type StatusConfig = {
  footerButton?: FooterProps['buttons'];
  isReadyOnly?: boolean;
};

export default function PermitToWorkForm(props: PermitToWorkFormProps) {
  const { t } = useTranslation();
  const { isOpen, module, setOpen, profile, projectId, workspaceId, selectedId } = props;
  const [form] = Form.useForm<PTWFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStartInspectionLoading, setIsStartInspectionLoading] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const fieldValue = Form.useWatch([], form);
  const [attachmentsInfo, setAttachmentsInfo] = useState<
    { id: string; name: string; filePath: string; userCreated: string }[]
  >([]);
  const [selectedBaseIssue, setSelectedBaseIssue] = useState<typeof mockPTWDetails | null>(null);

  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');

  const { user } = useUserEntity({ projectId, workspaceId });

  const users = useSelector(selectProjectUsers);

  const [openStartInspection, setOpenStartInspection] = useState(false);

  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [companies] = useProjectCompanies(companyParams);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [workPackagesQuery] = useMyWorkspaceWorkPackagesParams({
    projectId,
    workspaceId,
  });
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  useProjectWorkPackages(workPackagesQuery);
  useAttachmentTypes(attachmentTypeQuery);

  const ptwAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_CHECKLIST));
  const ptwPhoto = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_PHOTO));
  const ptwRefDocumentation = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_REF_DOCUMENTATION));

  const hasOneValidation = useMemo(() => {
    return (
      selectedBaseIssue?.BaseValidations?.filter(
        (validation) =>
          validation.validationStatus === VALIDATION_STATUS.APPROVED ||
          validation.validationStatus === VALIDATION_STATUS.APPROVED_WITH_COMMENT
      ).length === 1
    );
  }, [selectedBaseIssue?.BaseValidations]);

  const isOriginator = useMemo(() => {
    return selectedBaseIssue?.originatorId === user?.id;
  }, [selectedBaseIssue?.originatorId, user?.id]);

  const isDisable = useMemo(() => {
    return hasOneValidation || !isOriginator;
  }, [hasOneValidation, isOriginator]);

  // const [openTransferModal, setOpenTransferModal] = useState(false);
  // const ownValidation = useMemo(() => {
  //   return selectedBaseIssue?.BaseValidations?.find((validation) => validation.validatorId === user?.id) || null;
  // }, [selectedBaseIssue?.BaseValidations, user?.id]);

  const toAttachment = (attachment: Attachment) => {
    return {
      attachmentTypeId: attachment.attachmentTypeId,
      name: attachment.name,
      filePath: attachment.filePath,
      comment: attachment.comment,
    };
  };

  const mapUserInfoToAttachment = (attachment: Attachment, index: number, showAll?: boolean): PTWFileUpload => {
    const userInfo = users.find((user: User) => user.id === attachment?.RefAttachment?.userCreated);
    let mappedUserInfo = null;
    if (index === 0 || showAll) {
      mappedUserInfo = {
        profilePhoto: userInfo?.profilePhoto || '',
        fullName: userInfo?.fullName || '',
        firstName: userInfo?.firstName || '',
      };
    }

    return {
      attachmentTypeId: attachment.attachmentTypeId || '',
      name: attachment?.name || '',
      filePath: attachment?.filePath || '',
      createdAt: attachment?.createdAt,
      comment: attachment?.RefAttachment?.comment || '',
      ...mappedUserInfo,
    };
  };

  const validationsData = useMemo(() => {
    return (
      selectedBaseIssue?.BaseValidations?.map((baseValidation) => {
        const user = users?.find((user) => user.id === baseValidation.validatorId);
        return {
          companyName: baseValidation.ValidatorCompany?.name,
          companyCode: baseValidation.ValidatorCompany?.companyCode,
          status: baseValidation.pinComment
            ? VALIDATION_STATUS.APPROVED_WITH_COMMENT
            : baseValidation?.validationStatus,
          id: baseValidation.id,
          userName: user?.fullName,
          updatedAt: baseValidation?.updatedAt,
          comment: baseValidation?.comment,
          attachments:
            baseValidation?.ValidationAttachment?.filter(
              (validationAttachment) => validationAttachment.attachmentTypeId === ptwAttachment?.id
            ) || [],
          validationPictures:
            baseValidation?.ValidationAttachment?.filter(
              (validationAttachment) => validationAttachment.attachmentTypeId === ptwPhoto?.id
            ).map((data, index) => mapUserInfoToAttachment(data, index, true)) || [],
        };
      }) || []
    );
  }, [selectedBaseIssue, users]);

  const formConfig: StatusConfig = useMemo(() => {
    if (!selectedBaseIssue && selectedId) return {};
    const hasInvitedInspector = selectedBaseIssue?.WorkInspectionRequest?.WirInvitations.some(
      (wir) => user?.id === wir?.inspectorId
    );
    const hasInspectorChangeStatus = selectedBaseIssue?.BaseValidations?.some(
      (baseValidation) =>
        baseValidation.validatorId === user?.id &&
        baseValidation.validationStatus !== VALIDATION_STATUS.REVIEW_IN_PROGRESS
    );
    const hasOriginator = selectedBaseIssue?.originatorId === user?.id;
    // const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
    // const isAssignerCompany = userCompanyId === users?.find((user) => user.id === selectedBaseIssue?.originatorId)?.WorkspaceUser?.Company?.id;
    switch (selectedBaseIssue?.IssueStatus?.code) {
      case PTW_STATUSES.REVIEW_IN_PROGRESS:
        return {
          footerButton: [
            {
              label: t(EActionType.Cancel),
              actionType: EActionType.Cancel,
              hidden: hasInvitedInspector,
              type: 'default',
            },
            {
              label: t(EActionType.Transfer),
              actionType: EActionType.Transfer,
              hidden: !hasInvitedInspector || hasInspectorChangeStatus,
              type: 'default',
            },
            {
              // label: !hasInvitedInspector ? t(EActionType.InviteForInspection) : t(EActionType.StartInspection),
              // disabled: (!hasInvitedInspector && !isOriginator) || hasInspectorChangeStatus || (isOriginator && hasOneValidation),
              // actionType: hasInvitedInspector ? EActionType.OpenStartInspectionModal : EActionType.InviteForInspection,

              label: t(EActionType.StartInspection),
              actionType: EActionType.OpenStartInspectionModal,
            },
          ],
          isReadyOnly: isDisable,
        };
      case PTW_STATUSES.DRAFT:
        return {
          footerButton: [
            {
              position: 'left',
              type: 'primary',
              actionType: EActionType.Delete,
              disabled: !isOriginator,
              danger: true,
              icon: <DeleteOutlined />,
            },
            {
              type: 'default',
              label: t(EActionType.Submit),
              actionType: EActionType.Submit,
              disabled: !hasOriginator,
            },
            {
              label: t(EActionType.InviteForInspection),
              actionType: EActionType.InviteForInspection,
              disabled: disableSubmitButton || !hasOriginator,
            },
          ],
          isReadyOnly: !hasOriginator,
        };

      case PTW_STATUSES.REJECTED:
      case PTW_STATUSES.APPROVED:
        return {
          footerButton: [
            {
              label: !hasInvitedInspector ? t(EActionType.InviteForInspection) : t(EActionType.StartInspection),
              disabled: true,
            },
          ],
          isReadyOnly: true,
        };
      case PTW_STATUSES.APPROVED_WITH_COMMENT:
        return {
          footerButton: [
            {
              label: !hasInvitedInspector ? t(EActionType.InviteForInspection) : t(EActionType.StartInspection),
              disabled: true,
            },
          ],
          isReadyOnly: true,
        };

      default:
        return {
          footerButton: [
            {
              type: 'default',
              label: t(EActionType.SaveAsDraft),
              actionType: EActionType.SaveAsDraft,
            },
            {
              label: t(EActionType.Submit),
              actionType: EActionType.Submit,
              disabled: disableSubmitButton,
            },
          ],
        };
    }
  }, [selectedBaseIssue, disableSubmitButton]);

  const onClose = () => {
    setOpen(false);
    setSelectedBaseIssue(null);
    setActiveTab('detail');
    form.resetFields();
    setAttachmentsInfo([]);
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
  };

  const submitForm = async () => {
    try {
      setIsLoading(true);
      setOpen(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: t('Permit To Work Details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'controlStatus',
      label: t('Control Status'),
      disabled: !selectedBaseIssue || selectedBaseIssue.IssueStatus?.code === PTW_STATUSES.DRAFT,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  const updateFormValue = async () => {
    setIsLoading(true);
    try {
      setSelectedBaseIssue(mockPTWDetails);
      setDefaultValue(mockPTWDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedId) return;
    updateFormValue();
  }, [selectedId, ptwAttachment, ptwPhoto]);

  const filterAttachmentByTypeId = (selectedBaseIssue: typeof mockPTWDetails, attachmentTypeId?: string) => {
    return selectedBaseIssue?.WorkInspectionRequest?.WirAttachment.filter(
      (ptwAttachment) => ptwAttachment.attachmentTypeId === attachmentTypeId
    );
  };

  const mapIssueAttachments = (selectedBaseIssue: typeof mockPTWDetails): PTWFileUpload[] => {
    const originatorAttachment =
      filterAttachmentByTypeId(selectedBaseIssue, ptwAttachment?.id).map((data, index) =>
        mapUserInfoToAttachment(data, index)
      ) || [];
    const inspectorAttachment =
      selectedBaseIssue?.BaseValidations?.flatMap((baseValidation) => {
        const result = baseValidation?.ValidationAttachment?.filter(
          (validationAttachment) => validationAttachment.attachmentTypeId === ptwAttachment?.id
        );
        return result ? result.map((data, index) => mapUserInfoToAttachment(data, index)) : null;
      }).filter(Boolean) || [];
    return [...originatorAttachment, ...(inspectorAttachment as PTWFileUpload[])];
  };

  const mapIssuePhotos = (selectedBaseIssue: typeof mockPTWDetails) => {
    const result = selectedBaseIssue.BaseValidations?.flatMap((baseValidation) => {
      const data = baseValidation?.ValidationAttachment?.flatMap((validationAttachment) => {
        if (validationAttachment.attachmentTypeId !== ptwPhoto?.id) return undefined;
        return toAttachment(validationAttachment);
      }).filter(Boolean) as PTWFileUpload[];

      return data;
    });
    return result || [];
  };

  const setDefaultValue = (selectedBaseIssue: typeof mockPTWDetails) => {
    form.setFieldsValue({
      permitToWorkName: selectedBaseIssue?.name?.trim() || '',
      permitToWorkDescription: selectedBaseIssue?.description || '',
      activity: selectedBaseIssue?.WorkInspectionRequest?.activityId,
      discipline: selectedBaseIssue?.WorkInspectionRequest?.disciplineId,
      safetyRiskLevel: 'Moderate',
      startDate: selectedBaseIssue?.createdAt && dayjs(selectedBaseIssue?.createdAt),
      endDate: selectedBaseIssue?.updatedAt && dayjs(selectedBaseIssue?.updatedAt),
      inspectionDate:
        selectedBaseIssue?.WorkInspectionRequest?.inspectionDate &&
        dayjs(selectedBaseIssue?.WorkInspectionRequest.inspectionDate),
      inspectionTime:
        selectedBaseIssue?.WorkInspectionRequest?.inspectionDate &&
        dayjs(selectedBaseIssue?.WorkInspectionRequest?.inspectionDate),
      inspectors: selectedBaseIssue?.WorkInspectionRequest?.WirInvitations?.map(
        (wirInvitation: { companyId: string; inspectorId: string }) => ({
          companyId: wirInvitation.companyId,
          userId: wirInvitation.inspectorId,
        })
      ),
      plan: {
        plan: selectedBaseIssue?.plan?.plan,
        attachment: selectedBaseIssue?.plan?.attachment,
        zoneId: selectedBaseIssue?.plan?.zoneId,
      },
      zoneId: selectedBaseIssue?.plan?.zoneId,
      distributions: selectedBaseIssue?.watcherIds || [],
      subActivity: selectedBaseIssue?.WorkInspectionRequest?.subActivityId,
      controlPoint: selectedBaseIssue?.WorkInspectionRequest?.controlPointId,
      checklistAttachments: mapIssueAttachments(selectedBaseIssue),
      workPackage: selectedBaseIssue?.WorkInspectionRequest?.workPackageId,
      comment: selectedBaseIssue?.WorkInspectionRequest?.comment || '',
      validationPictures: mapIssuePhotos(selectedBaseIssue),
      referenceDocuments:
        filterAttachmentByTypeId(selectedBaseIssue, ptwRefDocumentation?.id).map((data, index) =>
          mapUserInfoToAttachment(data, index)
        ) || [],
      pictures:
        filterAttachmentByTypeId(selectedBaseIssue, ptwPhoto?.id).map((data, index) =>
          mapUserInfoToAttachment(data, index, true)
        ) || [],
    });
  };

  const startInspection = async () => {
    try {
      setIsStartInspectionLoading(true);
      setOpenStartInspection(false);
      setOpen(false);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      setIsStartInspectionLoading(false);
    }
  };
  const onDelete = async () => {
    try {
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onTransfer = async () => {
    try {
      // setOpenTransferModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = (actionType: EActionType) => {
    switch (actionType) {
      case EActionType.InviteForInspection:
        submitForm();
        break;
      case EActionType.Submit:
      case EActionType.SaveAsDraft:
        submitForm();
        break;
      case EActionType.OpenStartInspectionModal:
        setOpenStartInspection(true);
        break;
      case EActionType.StartInspection:
        startInspection();
        break;
      case EActionType.Cancel:
        setOpen(false);
        break;
      case EActionType.Delete:
        setIsDeleteConfirmation(true);
        break;
      case EActionType.Transfer:
        onTransfer();
        break;
      default:
        break;
    }
  };

  // const handleTransfer = async () => {
  //   try {
  //     setOpenTransferModal(false);
  //     setOpen(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const updateDisableButton = (allField: PTWFormValues) => {
    if (!allField) return;
    const { inspectors, ...restField } = allField;
    const requireFields = [
      'permitToWorkName',
      'workPackage',
      'discipline',
      'inspectionDate',
      'inspectionTime',
      'startDate',
      'endDate',
    ];

    const hasRequireField = Object.keys(restField)?.some(
      (keyField) => requireFields.includes(keyField) && !(restField as unknown as Record<string, string>)[keyField]
    );

    const hasRequireListField = inspectors
      ?.slice(0, 1)
      ?.some((inspector) => !inspector?.companyId || !inspector?.userId);

    setDisableSubmitButton(hasRequireListField || hasRequireField);
  };
  const handleChangeValue = (_: PTWFormValues[keyof PTWFormValues], allField: PTWFormValues) => {
    updateDisableButton(allField);
  };

  useEffect(() => {
    updateDisableButton(fieldValue);
  }, [fieldValue]);

  return (
    <Layout.Content>
      <Form
        requiredMark={CustomizeRequiredMark}
        id='create-update-wir-form'
        form={form}
        labelCol={{ span: 24 }}
        layout='vertical'
        onValuesChange={handleChangeValue}
      >
        <DeleteConfirmation
          isModalOpen={isDeleteConfirmation}
          title={t('Delete the Permit To Work')}
          description={t('Are you sure to delete this Permit To Work?')}
          onDelete={() => {
            setIsDeleteConfirmation(false);
            onDelete();
          }}
          onCancel={() => setIsDeleteConfirmation(false)}
        />
        <Drawer
          className={`mar-drawer ${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
          styles={{
            header: {
              backgroundColor: TASK_STATUS_HEADER_COLOR['SAFETY_HEADER'],
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={1025}
          onClose={onClose}
          closeIcon={false}
          open={isOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <PermitToWorkHeader
              selectedBaseIssue={selectedBaseIssue}
              onClose={onClose}
              profile={profile}
              isUnSaveStatus={false}
              user={user}
            />
          }
          footer={<PermitToWorkFooter buttons={formConfig?.footerButton} onActionClick={handleFormSubmit} />}
        >
          <Spin spinning={isLoading}>
            <Tabs
              className='mar-tab-create'
              defaultActiveKey='detail'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
            {selectedBaseIssue && (
              <PermitToWorkValidationStep
                validationData={validationsData as PTWValidationData[]}
                baseIssues={selectedBaseIssue}
              />
            )}
            {/* <TransferModal
              isOpen={openTransferModal}
              setOpen={setOpenTransferModal}
              onTransfer={handleTransfer}
              baseValidation={ownValidation}
              projectId={projectId}
              workspaceId={workspaceId}
              user={user}
            /> */}
            {activeTab === 'detail' && (
              <PermitToWorkDetail
                baseIssues={selectedBaseIssue}
                companies={companies}
                form={form}
                isReadOnly={!!formConfig?.isReadyOnly}
                user={user}
                projectId={props?.projectId || ''}
                workspaceId={props?.workspaceId || ''}
                module={module}
                attachmentsInfo={attachmentsInfo}
                setAttachmentsInfo={(value) => setAttachmentsInfo(value)}
              />
            )}
            {activeTab === 'controlStatus' && (
              <PermitToWorkHistory validationData={validationsData as PTWValidationData[]} users={users} />
            )}
            {openStartInspection && (
              <PermitToWorkInspectionModal
                onCancel={() => setOpenStartInspection(false)}
                isOpen={openStartInspection}
                onSubmitStartInspection={() => handleFormSubmit(EActionType.StartInspection)}
                isLoading={isStartInspectionLoading}
                user={user}
              />
            )}
          </Spin>
        </Drawer>
      </Form>
    </Layout.Content>
  );
}
