import { DeleteOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  GetProp,
  Input,
  message,
  Modal,
  ModalProps,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
  TreeSelect,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DataNode } from 'antd/es/tree';
import { CustomizeRequiredMark, CustomTagRender, DatePickerRange, SearchSelect } from 'components';
import dayjs from 'dayjs';
import {
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueTypes,
  useIssueTypesParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { Company, IssueStatus, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { issueService } from 'services';
import fileService from 'services/file.service';
import { convertZoneToTreeData } from 'utils';
import { ISSUE_STATUSES, TASK_STATUS_COLOR } from 'utils/contants';
const { Title, Text } = Typography;
const Option = Select.Option;

const NEW_ITEM = 'CREATE_NEW_ITEM';

type GenerateReportsProps = {
  isGenerateReports: boolean;
  profile: User | null;
  users: User[];
  module: string;
  workspaceId: string;
  projectId: string;
  companies: Company[];
  issueStatuses: IssueStatus[];
  selectedRowKeys: React.Key[];
  setIsGenerateReports: (value: boolean) => void;
} & ModalProps;
export default function GenerateReports(props: GenerateReportsProps) {
  const {
    isGenerateReports,
    setIsGenerateReports,
    profile,
    module,
    workspaceId,
    projectId,
    users,
    companies,
    issueStatuses,
    selectedRowKeys,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [orderBy, setOrderBy] = useState<{ key: string; direction: string }[]>([
    {
      key: 'plannedStartDate',
      direction: 'DESC',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [issueTypesQuery] = useIssueTypesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [wbsQuery] = useWorkBreakdownStructureParams({
    projectId,
    workspaceId,
    orderBy: 'order,asc',
    limit: 'unlimited',
  });
  const [wbs] = useWorkBreakdownStructures(wbsQuery);
  const [mainPhase] = useMemo(() => wbs?.filter((phase) => phase.isMain === true) || [], [wbs]);
  const disciplines = useMemo(() => wbs?.filter((phase) => phase.parentId === mainPhase?.id) || [], [wbs]);

  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [groupIssues, setGroupIssues] = useState('allIssues');
  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [isGenerateReports, selectedCompanyId]);
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const handleSortChange = (index: number, sortBy: string, order: string) => {
    const newOrderBy = [...orderBy];
    newOrderBy[index] = { key: sortBy, direction: order };
    setOrderBy(newOrderBy);
  };
  const handleDeleteSort = (index: number) => {
    const newOrderBy = [...orderBy];
    newOrderBy.splice(index, 1);
    setOrderBy(newOrderBy);
  };

  const defaultReportName = useMemo(
    () =>
      `${module} ${module && module === 'Safety' ? t('Inspection') : t('Observation')} - Report ${dayjs().format('DD/MM/YYYY').toString()}`,
    [isGenerateReports]
  );

  function handleCancel(): void {
    form.resetFields();
    setOrderBy([
      {
        key: 'plannedStartDate',
        direction: 'DESC',
      },
    ]);
    setIsLoading(false);
    setIsGenerateReports(false);
    setIsDisable(false);
    setSelectedCompanyId('');
    setGroupIssues('allIssues');
    setDisableGroup(false);
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const fields: { [key: string]: boolean } = {};
      checkedValues.checkedOptions1.forEach((value) => (fields[value] = true));
      checkedValues.checkedOptions2.forEach((value) => (fields[value] = true));
      const data = {
        reportName: form.getFieldValue('reportName'),
        reportCode: form.getFieldValue('code'),
        createdBy: form.getFieldValue('createdBy'),
        userCreated: profile?.id,
        // inspectionDateFrom: dateRange? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
        // inspectionDateTo: dateRange? dayjs(dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : undefined,
        // module: module,
        orderBy: orderBy,
        executiveSummary: form.getFieldValue('executiveSummary'),
        fields: fields,
        emails: form.getFieldValue('emails'),
        issueIds: selectedRowKeys.length > 0 ? selectedRowKeys : undefined,
      };

      // Filters
      const values = await form.getFieldsValue();
      let companyId = undefined;
      let assigneeId = undefined;
      let watcherId = undefined;
      let profileId = undefined;

      if (values.groupIssues === 'myIssues') {
        profileId = `${profile?.id}`;
      } else if (values.groupIssues === 'companyIssues' && userCompanyId) {
        companyId = `["${userCompanyId}"]`;
      } else if (values.groupIssues === 'watchedIssues' && profile?.id) {
        watcherId = `${profile?.id}`;
      } else {
        if (values.companyId) {
          companyId = `["${values.companyId}"]`;
        }
        if (values.assigneeId) {
          assigneeId = `["${values.assigneeId}"]`;
        }
      }
      const issueDisciplineId =
        module !== 'Quality' && values.issueDisciplineId && values.issueDisciplineId.length > 0
          ? `[${'"' + values.issueDisciplineId.join('","') + '"'}]`
          : undefined;
      const disciplineId =
        module === 'Quality' && values.disciplineId && values.disciplineId.length > 0
          ? `[${'"' + values.disciplineId.join('","') + '"'}]`
          : undefined;
      const issuePriorityId =
        values.issuePriorityId && values.issuePriorityId.length > 0
          ? `[${'"' + values.issuePriorityId.join('","') + '"'}]`
          : undefined;
      const issueStatusId =
        values.issueStatusId && values.issueStatusId.length > 0
          ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
          : undefined;
      const zoneId =
        values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;
      const params = {
        'plannedStartDate[gte]':
          values.plannedStartDate && values.plannedStartDate[0]
            ? dayjs(values.plannedStartDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
            : undefined,
        'plannedStartDate[lte]':
          values.plannedStartDate && values.plannedStartDate[1]
            ? dayjs(values.plannedStartDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
            : undefined,
        issueTypeId: values.issueTypeId ? values.issueTypeId : undefined,
        'companyId[]': companyId,
        'assigneeId[]': assigneeId,
        'disciplineId[]': disciplineId,
        'issueDisciplineId[]': issueDisciplineId,
        'issuePriorityId[]': issuePriorityId,
        'issueStatusId[]': issueStatusId,
        'zoneId[]': zoneId,
        profileId: profileId,
        watcherId: watcherId,
        module: module,
        include: 'IssuePriority|IssueStatus|IssueType|Phase|Company|Zone|IssueDiscipline|Photos|Plans|Discipline',
      };
      const reportType = form.getFieldValue('reportType');
      if (reportType === 'basicReport') {
        const reportResponse = await issueService.getReportPdf(workspaceId, projectId, data, params);
        await fileService.downloadFile(reportResponse.url, `${data.reportName}.pdf`);
      } else if (reportType === 'detailedReport') {
        const reportResponse = await issueService.getDetailedReportPdf(workspaceId, projectId, data, params);
        await fileService.downloadFile(reportResponse.url, `${data.reportName}.pdf`);
      } else if (reportType === 'summaryReport') {
        const reportResponse = await issueService.getSummaryReportPdf(workspaceId, projectId, data, params);
        await fileService.downloadFile(reportResponse.url, `${data.reportName}.pdf`);
      }
      setOrderBy([
        {
          key: 'plannedStartDate',
          direction: 'DESC',
        },
      ]);
      setIsLoading(false);
      setIsGenerateReports(false);
      setSelectedCompanyId('');
      setGroupIssues('allIssues');
      setDisableGroup(false);
      setIsDisable(false);
      form.resetFields();
      message.success(t('Your report has been generated!'));
    } catch (error) {
      console.log(error);
      message.error(t(`${(error as Error).message}`));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isGenerateReports) {
      form.resetFields();
      form.setFieldValue('createdBy', profile?.fullName);
      form.setFieldValue('reportName', defaultReportName);

      setCheckedValues({
        checkedOptions1: optionalOptions.options1.map((item) => item.value),
        checkedOptions2: optionalOptions.options2.map((item) => item.value),
      });
      form.setFieldValue('plannedStartDate', [dayjs().subtract(15, 'day'), dayjs()]);
      form.setFieldValue('groupIssues', groupIssues);
      const defaultStatuses = issueStatuses
        .filter((i) => [ISSUE_STATUSES.OPEN, ISSUE_STATUSES.READY_FOR_INSPECTION].includes(i.code))
        .map((i) => i.id);
      form.setFieldValue('issueStatusId', defaultStatuses);
    }
  }, [isGenerateReports]);

  const mandatoryOptions = [
    { label: module && module === 'Safety' ? t('Inspection name') : t('Observation name'), value: 'name' },
    { label: t('Company assignee'), value: 'companyAssignee' },
    { label: t('Status'), value: 'status' },
    {
      label: module && module === 'Safety' ? t('Inspection discipline') : t('Observation discipline'),
      value: 'issueDiscipline',
    },
    { label: t('Priority'), value: 'priority' },
    { label: t('ID Number'), value: 'createdAt' },
    { label: t('Assigner'), value: 'assigner' },
    { label: t('Assignee'), value: 'assignee' },
  ];

  const optionalOptions = {
    options1: [
      { label: module && module === 'Safety' ? t('Inspection type') : t('Observation type'), value: 'issueType' },
      { label: t('Zoning'), value: 'zone' },
      { label: t('Stage'), value: 'phase' },
    ],
    options2: [
      {
        label: module && module === 'Safety' ? t('Inspection date') : t('Observation date'),
        value: 'plannedStartDate',
      },
      { label: t('Deadline'), value: 'plannedEndDate' },
      { label: t('Image'), value: 'image' },
      { label: t('Plans'), value: 'plans' },
    ],
  };

  const [checkedValues, setCheckedValues] = useState<{
    checkedOptions1: string[];
    checkedOptions2: string[];
  }>({
    checkedOptions1: optionalOptions.options1.map((item) => item.value),
    checkedOptions2: optionalOptions.options2.map((item) => item.value),
  });

  const fieldOptions = [
    { label: module && module === 'Safety' ? t('Inspection name') : t('Observation name'), value: 'name' },
    { label: t('ID number'), value: 'createdAt' },
    { label: t('Company assignee'), value: 'Company.name' },
    { label: t('Status'), value: 'IssueStatus.orderNumber' },
    { label: t('Priority'), value: 'IssuePriority.orderNumber' },
    { label: module && module === 'Safety' ? t('Inspection type') : t('Observation type'), value: 'IssueType.name' },
    { label: t('Zoning'), value: 'Zone.name' },
    { label: t('Stage'), value: 'Phase.name' },
    { label: t('Assignee'), value: 'assignee' },
    { label: t('Assigner'), value: 'assigner' },
    { label: t('Deadline'), value: 'plannedEndDate' },
  ];

  const orderOptions = [
    { label: t('Ascending'), value: 'ASC' },
    { label: t('Descending'), value: 'DESC' },
  ];

  const checkBoxCustom = (label: string, key: React.Key) => {
    return (
      <div className='checkbox-wrapper' key={key}>
        <label style={{ display: 'flex' }}>
          <span style={{ opacity: 0.5 }} className='mr-2'>
            <LockOutlined />
          </span>
          <span style={{ opacity: 0.5, minWidth: '90%' }}>{label}</span>
        </label>
      </div>
    );
  };

  const onChangeOptions1: GetProp<typeof Checkbox.Group, 'onChange'> = (values) => {
    setCheckedValues({
      ...checkedValues,
      checkedOptions1: values as string[],
    });
  };

  const onChangeOptions2: GetProp<typeof Checkbox.Group, 'onChange'> = (values) => {
    setCheckedValues({ ...checkedValues, checkedOptions2: values as string[] });
  };

  const onSelectAll = () => {
    setCheckedValues({
      checkedOptions1: optionalOptions.options1.map((item) => item.value),
      checkedOptions2: optionalOptions.options2.map((item) => item.value),
    });
  };

  const onClearAll = () => {
    setCheckedValues({ checkedOptions1: [], checkedOptions2: [] });
  };

  const handleChange = (value: string) => {
    console.log(value);
    setInputValue('');
  };

  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setGroupIssues(e.target.value);
    setDisableGroup(e.target.value === 'allIssues' ? false : true);
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };

  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = users?.find((user) => user.id === assigneeId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onFormValuesChange = () => {
    const checkFormValues = async () => {
      const reportName = form.getFieldValue('reportName');
      if (reportName) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    };
    checkFormValues();
  };
  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
      setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);
  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(zoneId);
  };
  return (
    <Modal
      title={
        <Typography.Text style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }} className='text-uppercase'>
          {t('New Report')}
        </Typography.Text>
      }
      open={isGenerateReports}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button loading={isLoading} onClick={handleSubmit} disabled={isDisable} key='submit' type='primary'>
          {t('Generate report')}
        </Button>,
      ]}
      width={723}
    >
      <div className='generate-report-form'>
        <Form
          form={form}
          name='report'
          layout='vertical'
          requiredMark={CustomizeRequiredMark}
          initialValues={{
            mandatory: mandatoryOptions,
            optional: optionalOptions,
            field: fieldOptions,
            order: orderOptions,
            reportType: 'basicReport',
          }}
          onValuesChange={onFormValuesChange}
        >
          <Title level={2}>{t('Report type')}</Title>
          <Form.Item name='reportType'>
            <Radio.Group style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Radio value={'basicReport'}>{t('Basic Report')}</Radio>
              <Radio value={'detailedReport'}>{t('Detailed Report')}</Radio>
              <Radio value={'summaryReport'}>{t('Summary Report')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'reportName'}
            label={t('Report name')}
            rules={[
              { required: true, message: '' },
              {
                max: 50,
                message: t('Report name cannot exceed 50 characters'),
              },
            ]}
          >
            <Input
              count={{
                show: true,
                max: 50,
              }}
              allowClear
              placeholder={t('Please enter')}
            />
          </Form.Item>
          <Row gutter={24} className='mb-0'>
            <Col span={12}>
              <Form.Item name={'createdBy'} label={t('Created by')} rules={[{ required: true, message: '' }]}>
                <Input disabled={true} placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'code'} label={t('Code')}>
                <Input placeholder={t('Please enter')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={'executiveSummary'} label={t('Executive summary')}>
            <TextArea placeholder={t('Please enter')} />
          </Form.Item>
          <Form.Item name={'emails'} label={t('Recipients list')}>
            <Select
              mode='tags'
              showSearch
              allowClear
              maxTagCount='responsive'
              placeholder={t('Please select')}
              onSearch={(e) => setInputValue(e)}
              onChange={handleChange}
              optionLabelProp='label'
              filterOption={false}
            >
              {users
                ?.filter(
                  (user) =>
                    !inputValue ||
                    user?.email?.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                    user?.fullName?.toString().toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((user) => (
                  <Option key={user.id} value={user.email} label={user.email}>
                    <div className='custom-select-input-user'>
                      <div className='text-circle'>{user.email?.charAt(0).toUpperCase()}</div>
                      <div className='ml-2'>
                        <div className='ttl'>{user.fullName}</div>
                        <div className='desc'>{user.email}</div>
                      </div>
                    </div>
                  </Option>
                ))}

              {inputValue && users?.filter((o) => o.email === inputValue).length === 0 && (
                <Option key={NEW_ITEM} value={inputValue} className='invite-option'>
                  {`+ ${t('Send to')} ${inputValue}`}
                </Option>
              )}
            </Select>
          </Form.Item>
          {/* <Row gutter={16} align='bottom'>
            <Col span={24}>
              <Form.Item
                name='plannedStartDate'
                label={module && module === 'Safety' ? t('Inspection date range') : t('Observation date range')}
              >
                <DatePickerRange style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row> */}
          {selectedRowKeys.length <= 0 && (
            <>
              <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
              <Title level={2}>{t('Filters')}</Title>
              <Row gutter={16} align='stretch' className='mb-0'>
                <Col span={12}>
                  <Form.Item name='groupIssues' initialValue={groupIssues}>
                    <Radio.Group onChange={onChangeGroupIssues}>
                      <Space direction='vertical'>
                        <Radio style={{ lineHeight: '2.4' }} value='allIssues'>
                          {module && module === 'Safety' ? t('All inspections') : t('All observations')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='myIssues'>
                          {module && module === 'Safety' ? t('My inspections') : t('My observations')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='companyIssues'>
                          {module && module === 'Safety' ? t('My company inspections') : t('My company observations')}
                        </Radio>
                        <Radio style={{ lineHeight: '2.4' }} value='watchedIssues'>
                          {module && module === 'Safety' ? t('Watched inspections') : t('Watched observations')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className='mb-0'>
                  <Form.Item name='companyId' label={t('Company assignee')}>
                    <SearchSelect
                      disabled={isDisableGroup}
                      allowClear
                      placeholder={t('Select')}
                      onChange={onChangeCompany}
                      options={companies?.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item name='assigneeId' label={t('Assignee')}>
                    <SearchSelect
                      disabled={isDisableGroup}
                      allowClear
                      placeholder={t('Select')}
                      onChange={onChangeAssignee}
                      options={assigneeFilter?.map((option) => ({
                        value: option.id,
                        label: option.fullName,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={24}>
                  <Form.Item
                    name='plannedStartDate'
                    label={module && module === 'Safety' ? t('Inspection date range') : t('Observation date range')}
                  >
                    <DatePickerRange style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={12}>
                  <Form.Item
                    name='issueTypeId'
                    label={module && module === 'Safety' ? t('Inspection type') : t('Observation type')}
                  >
                    <SearchSelect
                      allowClear
                      placeholder={t('Select')}
                      options={issueTypes?.map((option) => ({
                        value: option.id,
                        label: t(option.name),
                      }))}
                    />
                  </Form.Item>
                </Col>
                {module === 'Safety' ? (
                  <Col span={12}>
                    <Form.Item name='issueDisciplineId' label={t('Inspection discipline')}>
                      <SearchSelect
                        allowClear
                        showSearch
                        mode='multiple'
                        maxTagCount='responsive'
                        placeholder={t('Select')}
                        options={issueDisciplines?.map((option) => ({
                          value: option.id,
                          label: t(option.name),
                        }))}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={12}>
                    <Form.Item name='disciplineId' label={t('Observation discipline')}>
                      <SearchSelect
                        allowClear
                        showSearch
                        mode='multiple'
                        maxTagCount='responsive'
                        placeholder={t('Select')}
                        options={disciplines?.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row gutter={16} align='bottom' className='mb-0'>
                <Col span={12}>
                  <Form.Item
                    name='issuePriorityId'
                    label={t('Priority')}
                    // rules={[
                    //   { required: true, message: '' },
                    // ]}
                  >
                    <Select
                      allowClear
                      // showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issuePriorities?.map((option) => ({
                        value: option.id,
                        label: (
                          <div>
                            {option.icon && <img src={option.icon} alt='' width={16} height={16} className='mr-2' />}
                            <span>{t(option.name)}</span>
                          </div>
                        ),
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='issueStatusId'
                    label={t('Status')}
                    // rules={[
                    //   { required: true, message: '' },
                    // ]}
                  >
                    <Select
                      allowClear
                      // showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issueStatuses?.map((option) => ({
                        value: option.id,
                        label: <Tag color={TASK_STATUS_COLOR[option.code]}>{t(option.name)}</Tag>,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name='zoneId' label={t('Zoning')} className='mb-0'>
                <TreeSelect
                  multiple
                  maxTagCount='responsive'
                  style={{ width: '100%' }}
                  value={zoneUpdated}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={zonesTreeData}
                  placeholder={t('Select')}
                  treeDefaultExpandAll
                  onChange={onChangeZone}
                  tagRender={(props) => CustomTagRender(props, zonesTreeData)}
                />
              </Form.Item>
            </>
          )}

          <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
          <div>
            <Title level={2}>{t('Fields')}</Title>
            <Row gutter={24}>
              <Col span={7}>
                <Text>{t('Mandatory')}</Text>
                <div className='mt-3'>
                  {mandatoryOptions?.map((option, index) => checkBoxCustom(option.label, index))}
                </div>
              </Col>
              <Col className='px-0' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Divider type='vertical' style={{ height: '100%' }} />
              </Col>
              <Col span={16}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text>{t('Optional')}</Text>
                  <Button onClick={() => onSelectAll()} className='select-all-btn' style={{ right: 62 }}>
                    {t('Select All')}
                  </Button>
                  <Text className='select-all-btn' style={{ right: 50 }}>
                    -
                  </Text>
                  <Button
                    onClick={() => onClearAll()}
                    className='select-all-btn'
                    style={{
                      position: 'absolute',
                      right: 0,
                      marginRight: '12px',
                    }}
                  >
                    {t('Clear')}
                  </Button>
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className='mt-3'>
                      <Checkbox.Group
                        name='optional'
                        options={optionalOptions.options1}
                        onChange={onChangeOptions1}
                        value={checkedValues.checkedOptions1}
                        className='check-box-column'
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='mt-3'>
                      <Checkbox.Group
                        name='optional'
                        options={optionalOptions.options2}
                        onChange={onChangeOptions2}
                        value={checkedValues.checkedOptions2}
                        className='check-box-column'
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
          <div className='mt-6'>
            <Title level={2}>{t('Sort')}</Title>
            <Form.List name='sort'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Row gutter={24}>
                        <Col span={22}>
                          <Row gutter={20} key={key} align='middle' className='report-filter'>
                            <Col span={3}>
                              <Text>{t('Sort by')}</Text>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                {...restField}
                                name={[name, 'sortBy']}
                                rules={[{ required: true, message: '' }]}
                              >
                                <SearchSelect
                                  allowClear={false}
                                  placeholder={t('Please select')}
                                  options={fieldOptions?.map((field) => ({
                                    value: field.value,
                                    label: field.label,
                                    disabled:
                                      orderBy.some((item) => item.key === field.value) ||
                                      field.value === 'assignee' ||
                                      field.value === 'assigner',
                                  }))}
                                  onChange={(value) => handleSortChange(index, value, orderBy[index]?.direction)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Text>{t('in')}</Text>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                {...restField}
                                name={[name, 'order']}
                                rules={[{ required: true, message: '' }]}
                              >
                                <SearchSelect
                                  placeholder={t('Please select')}
                                  options={orderOptions?.map((order) => ({
                                    value: order.value,
                                    label: order.label,
                                  }))}
                                  onChange={(value) => handleSortChange(index, orderBy[index]?.key, value)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Text>{t('order')}</Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            className='mt-5'
                            onClick={() => {
                              remove(name);
                              handleDeleteSort(index);
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Form.Item>
                    <Button className='select-all-btn mb-16' onClick={() => add()} icon={<PlusOutlined />}>
                      {t('Add sort')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
