import { useMemo } from "react";
import { QueryParams } from "types";
import { CompanyCategory } from "model";
import { useFetchByParams, useQueryParams } from "hooks";
import {
  fetchCompanyCategories,
  selectMyWorkspaceCompanyCategories,
  selectMyWorkspaceCompanyCategoriesLoading,
} from "store/my-workspace.slice";

export const useMyWorkspaceCompanyCategoriesParams = (
  options: {
    workspaceId?: string;
  } & { [key: string]: any }
) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, [workspaceId, query?.limit, query?.page, query?.search, query?.sort]);
};

export const useMyWorkspaceCompanyCategories = (query: any) => {
  return useFetchByParams<CompanyCategory[]>({
    action: fetchCompanyCategories,
    dataSelector: selectMyWorkspaceCompanyCategories,
    loadingSelector: selectMyWorkspaceCompanyCategoriesLoading,
    params: query,
  });
};
