import { User } from 'model';
import PasswordReset from 'model/PasswordReset';
import UserInvitation from 'model/UserInvitation';
import request from 'requesters/user.request';

const authService = {
  checkEmailIsExist: async (email: string) => {
    return request.post<{ isExist: boolean }>('/check-email', { email });
  },
  verifyInvitationToken: async (token: string) => {
    return request.get<UserInvitation>('/check-invitation', { params: { token } });
  },
  signUpWithPassword: async (values: any) => {
    return request.post<{ registerUserOutput: { token: string } & any }>('/register', values);
  },
  requestAccountCreation: async (values: any) => {
    return request.post<{ registerUserOutput: { token: string } & any }>('/request-account-creation', values);
  },
  signInWithPassword: async (values: { email: string; password: string }) => {
    return request.post<{ token: string; user: User }>('/login', values);
  },
  profile: async () => {
    return request.get<User>('/profile');
  },
  updatepProfile: async (data: any) => {
    return request.put<User>('/profile', data);
  },
  signOut: async () => {
    return request.post('/logout');
  },
  googleLogin: async (values: { token: string }) => {
    return await request.post<{
      isExist: boolean;
      email?: string;
      user?: User;
      token?: string;
    }>('/google-auth/callback', values);
  },
  googleRegister: async (values: { token: string; data: User }) => {
    return await request.post<{ registerUserOutput: { token: string } & any }>('/request-account-creation', {
      token: values.token,
      ...values.data,
    });
  },
  microsoftLogin: async (values: { token: string }) => {
    return await request.post<{
      isExist: boolean;
      email?: string;
      user?: User;
      token?: string;
    }>('/microsoft-auth/callback', values);
  },
  microsoftRegister: async (values: { token: string; data: User }) => {
    return await request.post<{ registerUserOutput: { token: string } & any }>('/request-account-creation',{
      token: values.token,
      ...values.data,
    });
  },
  requestResetPassword: async (data: { email: string }) => {
    return request.post<User>('/request-reset-password', data);
  },
  verifyResetPasswordToken: async (token: string) => {
    return request.get<PasswordReset>('/check-reset-password', { params: { token } });
  },
  resetPassword: async (data: { newPassword: string }, token: string) => {
    return request.post<User>('/reset-password', data, { params: { token } });
  },
  verifySecretKey: async (email: string) => {
    return request.get('/verify-secret-key', { params: { email } });
  },
  verifyOtp: async (data: { email: string; otp: string }) => {
    return await request.get('/verify-otp', {
      params: data,
    });
  },
  generateUri: async (data: { email: string, secret: string }) => {
    return await request.get('/generate-uri', {
      params: data,
    });
  },

  checkPassword: async (data: { email: string; password: string }) => {
    return request.post('/check-password', data);
  },

  removeSecretKey: async (data: { email: string }) => {
    return request.put('/remove-secret-key', data);
  },

  saveSecretKey: async (data: { email: string, secret: string }) => {
    return request.put('/save-secret-key', data);
  },

  generateSecretKey: async () => {
    return request.get('/generate-secret-key');
  },
};

export default authService;
