import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, Row } from 'antd';
import { BaseIssue } from 'model';
import { useMemo } from 'react';
import { TRA_STATUSES } from 'utils/contants';
import { DeleteOutlined } from '@ant-design/icons';

export enum EDocumentsTransmissionActionType {
  SaveAsDraft = 'SaveAsDraft',
  SendToRecipient = 'SendToRecipient',
  Delete = 'Delete',
}
type ProjectDocumentsTransmissionFooterProps = {
  onActionClick: (type: EDocumentsTransmissionActionType) => void;
  issueStatus: BaseIssue['IssueStatus'];
  isReadOnly?: boolean;
};

export default function ProjectDocumentsTransmissionFooter(props: ProjectDocumentsTransmissionFooterProps) {
  const { t } = useTranslation();
  const showDelete = useMemo(() => props?.issueStatus?.code === TRA_STATUSES.DRAFT, [props.issueStatus]);
  const formValues = Form.useWatch([]);

  const isDisabledSendToRecipientButton = useMemo(() => {
    const requiredFields = [
      'companyIssuer',
      'issuerName',
      'companyId',
      'assigneeIds',
      'subject',
      'issuePriorityId',
      'transmittalDocuments',
    ];

    return requiredFields?.some((field) => !formValues?.[field] || formValues?.[field].length === 0);
  }, [formValues]);

  return (
    <Row gutter={16} align='middle' style={{ padding: '5px 24px' }}>
      {showDelete && (
        <Button
          danger
          type='primary'
          onClick={() => props.onActionClick(EDocumentsTransmissionActionType.Delete)}
          icon={<DeleteOutlined />}
        />
      )}
      {!props?.isReadOnly && (
        <Flex className='ml-auto'>
          <Button onClick={() => props.onActionClick(EDocumentsTransmissionActionType.SaveAsDraft)}>
            {t('Save as Draft')}
          </Button>
          <Button
            disabled={isDisabledSendToRecipientButton}
            onClick={() => props.onActionClick(EDocumentsTransmissionActionType.SendToRecipient)}
            type='primary'
          >
            {t('Send to Recipient(s)')}
          </Button>
        </Flex>
      )}
    </Row>
  );
}
