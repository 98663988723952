import { Avatar, Button, Col, DatePicker, Divider, Flex, Form, FormInstance, Row, TimePicker, Typography } from 'antd';
import { ReadOnlyDataField, SearchSelect, UploadDocument } from 'components/common';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { ReactNode, useMemo, useState } from 'react';
import { DeleteOutlined, FilePdfOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import { Rule } from 'antd/es/form';
import { BaseIssue, Company, User } from 'model';
import { fileService } from 'services';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store';
import {
  selectActivitiesByDiscipline,
  selectControlPointsBySubActivity,
  selectProjectUsers,
  selectProjectWorkPackages,
  selectProjectZones,
  selectSubActivitiesByActivity,
  selectWorkBreakDownStructureByLevel,
} from 'store/my-projects.slice';
import { convertZoneToTreeData } from 'utils';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import { selectAttachmentByCode } from 'store/common.slice';
import { ATTACHMENT_TYPES } from 'utils/contants';
import EditableFormField from 'components/common/EditableFormField';
import { findPath } from 'components/common/TreeSelectTagRender';
import WorkInspectionRequestDetailZoning from './WorkInspectionRequestDetailZoning';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import { ReactComponent as NodataImage } from 'assets/images/no-attachment.svg';
import InviteFormField from 'components/common/InviteFormField';

type WIRDetailProps = {
  form: FormInstance<WIRFormValues>;
  isReadOnly: boolean;
  companies: Company[];
  baseIssues?: BaseIssue;
  user?: User | null;
};

export type WIRFileUpload = {
  attachmentTypeId?: string | null;
  name?: string;
  filePath?: string;
  createdAt?: string | Date;
  fullName?: string;
  firstName?: string;
  profilePhoto?: string;
  comment?: string;
};

export type WIRFormValues = {
  activity?: string;
  zoning?: string;
  discipline?: string;
  inspectionDate?: dayjs.Dayjs | null;
  inspectionTime?: dayjs.Dayjs | null;
  inspectors: {
    companyId: string | null;
    userId?: string | null;
  }[];
  distributions: string[];
  subActivity: string;
  workInspectionDescription: string | null;
  workInspectionName: string;
  checklistAttachments?: WIRFileUpload[];
  validationStatus?: string;
  comment: string | null;
  pictures: WIRFileUpload[];
  workPackage?: string;
  controlPoint?: string;
  validationPictures: WIRFileUpload[];
  validationChecklist: WIRFileUpload[];
  validationComment?: string;
  referenceDocuments?: WIRFileUpload[];
};

const { Title } = Typography;
export default function WorkInspectionRequestDetail(props: WIRDetailProps) {
  const { isReadOnly, form } = props;
  const { t } = useTranslation();
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [openReferenceDocument, setOpenReferenceDocument] = useState<boolean>(false);

  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const formValues = form.getFieldsValue(true) as WIRFormValues;

  const subActivities = useAppSelector(selectSubActivitiesByActivity(Form.useWatch('activity') || formValues.activity));
  const controlPoints = useAppSelector(
    selectControlPointsBySubActivity(Form.useWatch('subActivity') || formValues.subActivity)
  );
  const zones = useSelector(selectProjectZones);
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);

  const watchedDiscipline = Form.useWatch('discipline') || formValues?.discipline;
  const activities = useAppSelector(selectActivitiesByDiscipline(watchedDiscipline));
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  const picturesFieldValue = formValues.pictures || ([] as WIRFileUpload[]);
  const [, setForceRerender] = useState(false);

  const wirChecklistAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_CHECKLIST));

  const wirPhotoAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_PHOTO));

  const wirReferenceDocument = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_REF_DOCUMENTATION));

  const attachments = formValues?.checklistAttachments || [];

  const referenceDocuments = formValues?.referenceDocuments || [];

  const users = useSelector(selectProjectUsers);

  const workPackages = useSelector(selectProjectWorkPackages);

  const handleAddDocument = (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => {
    if (!response?.fileUrl) return;
    attachments.push({
      name: name,
      filePath: response?.fileUrl || '',
      attachmentTypeId: wirChecklistAttachment?.id || '',
      createdAt: new Date().toISOString(),
      profilePhoto: props.user?.profilePhoto,
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
    });
    form.setFieldValue('checklistAttachments', attachments);
  };

  const handleAddReferenceDocument = (
    name: string,
    response: { fileName: string; fileUrl: string; keyFile: string }
  ) => {
    referenceDocuments.push({
      name: name,
      filePath: response?.fileUrl || '',
      attachmentTypeId: wirReferenceDocument?.id || '',
    });
    form.setFieldValue('referenceDocuments', referenceDocuments);
  };

  const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
    return data?.map((value) => ({
      value: value.id,
      label: value[labelKey as keyof typeof value],
    }));
  };

  const handleAddPicture = (data: UploadPhotoModalValue) => {
    if (!data || !wirPhotoAttachment?.id) return;
    const pictureData = {
      id: data.fileUrl,
      name: data.fileName,
      filePath: data.fileUrl,
      attachmentTypeId: wirPhotoAttachment?.id || '',
      profilePhoto: props.user?.profilePhoto,
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
      comment: data.comment,
    };

    picturesFieldValue.push(pictureData);
    form.setFieldValue('pictures', picturesFieldValue);
    setIsOpenUpload(false);
  };

  const renderConditionalFieldItem = ({
    columns,
  }: {
    columns: {
      style?: React.CSSProperties;
      label?: string;
      readonly?: {
        style?: React.CSSProperties;
        content?: ReactNode;
        hideLabel?: boolean;
      };
      editable: {
        rules?: Rule[];
        name?: string;
        content: ReactNode;
      };
    }[];
  }) => {
    return (
      <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
        {columns.map((col) => {
          if (!isReadOnly && !col.editable.content) return null;
          return (
            <Col
              flex={1}
              span={24 / columns.length}
              key={col.editable.name}
              style={{ ...(isReadOnly && col?.readonly?.style), ...col?.style }}
            >
              {isReadOnly ? (
                <ReadOnlyDataField
                  title={!col?.readonly?.hideLabel ? col?.label : ''}
                  content={col?.readonly?.content}
                />
              ) : (
                <Form.Item
                  className={'text-color-polaris-black'}
                  name={col.editable?.name}
                  label={col?.label ? t(`${col?.label}`) : ''}
                  rules={col.editable?.rules}
                  layout='vertical'
                >
                  {col.editable?.content}
                </Form.Item>
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderInviteForInspection = () => {
    return (
      <div>
        <Typography.Title level={3} className='mb-[10px] h-[32px] !leading-[32px]'>
          {t('Invite for Inspection')}
        </Typography.Title>
        {renderConditionalFieldItem({
          columns: [
            {
              label: 'Inspection Date',
              readonly: {
                content: formValues?.inspectionDate ? dayjs(formValues?.inspectionDate).format('YYYY-MM-DD') : '',
              },
              style: { maxWidth: 'calc(50% - 18px)' },
              editable: {
                rules: [{ required: true, message: 'Please select inspection date' }],
                name: 'inspectionDate',
                content: (
                  <DatePicker
                    placeholder={t('Inspection Date')}
                    style={{ width: '100%' }}
                    format='YYYY-MM-DD'
                    // disabledDate={(date) => date.isBefore(dayjs(), 'day')}
                  />
                ),
              },
            },
            {
              style: { maxWidth: 'calc(50% - 18px)' },
              label: 'Inspection Time',
              editable: {
                name: 'inspectionTime',
                rules: [{ required: true, message: 'Please select inspection time' }],
                content: (
                  <TimePicker
                    minuteStep={15}
                    placeholder={t('Inspection Time')}
                    format='hh:mm A'
                    variant={'outlined'}
                    popupClassName='wir-time-picker'
                  />
                ),
              },
              readonly: {
                style: { paddingLeft: '16px' },
                content: formValues?.inspectionTime ? <div>{formValues?.inspectionTime.format('hh:mm A')}</div> : '',
              },
            },
          ],
        })}
        <InviteFormField
          renderFooter={(add) => {
            return (
              <div className='text-right'>
                {!props?.isReadOnly && (
                  <Button className='!w-[208px] text-size-12' onClick={add} type='dashed' icon={<PlusOutlined />}>
                    {t('Add Inspector')}
                  </Button>
                )}
              </div>
            );
          }}
          name='inspectors'
          users={users}
          form={form}
          companies={props?.companies}
          isReadOnly={isReadOnly}
          isRequire
          showDefault
        />
        {renderConditionalFieldItem({
          columns: [
            {
              readonly: {
                content: mapDataToOptions(users, 'fullName')
                  .filter((user) => formValues.distributions?.includes(user.value))
                  .map((user) => user.label)
                  .join(', '),
              },
              label: 'Distribution',
              editable: {
                name: 'distributions',
                content: (
                  <SearchSelect
                    showSearch
                    mode='multiple'
                    allowClear
                    maxTagCount='responsive'
                    placeholder={t('Select Users')}
                    optionFilterProp='children'
                    options={mapDataToOptions(users, 'fullName')}
                  />
                ),
              },
            },
          ],
        })}
      </div>
    );
  };

  const renderReferenceDocumentContent = () => {
    if (!referenceDocuments.length) {
      return (
        <Flex className='h-[165px]' align='center' justify='center' vertical gap={13}>
          <NodataImage />
          <Typography.Text className='text-size-14 text-color-disabled'>{t('No Data')}</Typography.Text>
        </Flex>
      );
    }

    return (
      <Flex gap={16} wrap>
        {referenceDocuments?.map((referenceDocument) => {
          return (
            <div className='file-box basis-[calc(50%-16px)]' key={referenceDocument.filePath}>
              <FilePdfOutlined color='primary' className='color-primary' />
              <Typography.Link
                onClick={() => {
                  if (!referenceDocument) return;
                  fileService.downloadFile(referenceDocument?.filePath || '', `${referenceDocument?.name}.pdf`);
                }}
              >
                {t(`${referenceDocument?.name}.pdf`)}
              </Typography.Link>
              {!props.isReadOnly && (
                <DeleteOutlined
                  className='cursor-pointer'
                  onClick={() => {
                    handleRemoveFile('referenceDocuments', referenceDocument.filePath);
                  }}
                />
              )}
            </div>
          );
        })}
      </Flex>
    );
  };
  const renderReferenceDocument = () => {
    return (
      <div>
        <Flex align='center' justify='space-between' className='inspection-checklist-box'>
          <Title className='read-only-mar-title' level={3}>
            {t('Reference Documents')}
          </Title>
          {renderConditionalFieldItem({
            columns: [
              {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                editable: {
                  name: 'referenceDocuments',
                  content: (
                    <>
                      <Button
                        type='dashed'
                        icon={<PlusOutlined />}
                        onClick={() => setOpenReferenceDocument(true)}
                        className='text-size-12'
                      >
                        {t('Add Documents')}
                      </Button>
                      {openReferenceDocument && (
                        <UploadDocument
                          isModalOpen={openReferenceDocument}
                          setIsModalOpen={(value: boolean) => setOpenReferenceDocument(value)}
                          handleAddDocument={handleAddReferenceDocument}
                        />
                      )}
                    </>
                  ),
                },
              },
            ],
          })}
        </Flex>
        {renderConditionalFieldItem({
          columns: [
            {
              readonly: {
                content: renderReferenceDocumentContent(),
              },
              editable: {
                content: renderReferenceDocumentContent(),
              },
            },
          ],
        })}
      </div>
    );
  };

  const handleRemoveFile = (keyField: keyof WIRFormValues, filePatch?: string) => {
    if (!filePatch) return;
    const result = (formValues?.[keyField] as WIRFileUpload[])?.filter(
      (attachment) => attachment.filePath !== filePatch
    );
    form.setFieldValue(keyField, [...result]);
    setForceRerender((prev) => !prev);
  };

  const renderPictures = () => {
    const mappedPhotos = formValues?.pictures?.map((picture) => ({
      ...picture,
      picture: picture.profilePhoto,
      username: picture.fullName || '',
      hideDelete: props?.isReadOnly,
      firstName: picture.firstName,
    })) as AttachmentPhoto[];

    return (
      <AttachmentList
        attachmentPhotos={mappedPhotos}
        onDelete={(filePath) => {
          handleRemoveFile('pictures', filePath);
        }}
        hideDelete={props.isReadOnly}
      />
    );
  };

  const renderPictureSection = () => {
    return (
      <React.Fragment>
        <Flex align='center' justify='space-between' className='inspection-checklist-box'>
          <Title className='read-only-mar-title' level={3}>
            {t('Pictures')}
          </Title>
          {renderConditionalFieldItem({
            columns: [
              {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                editable: {
                  name: 'pictures',
                  content: (
                    <>
                      <Button
                        type='dashed'
                        icon={<PlusOutlined />}
                        onClick={() => setIsOpenUpload(true)}
                        className='text-size-12'
                      >
                        {t('Add Pictures')}
                      </Button>
                      <UploadPhotoModal
                        isModalOpen={isOpenUpload}
                        setIsModalOpen={setIsOpenUpload}
                        onSubmit={handleAddPicture}
                      />
                    </>
                  ),
                },
              },
            ],
          })}
        </Flex>
        <div className='picture-box'>{formValues?.pictures?.length > 0 && renderPictures()}</div>
      </React.Fragment>
    );
  };

  const renderInspectionChecklist = () => {
    if (attachments?.length === 0) return null;

    return (
      <Flex gap={16} vertical>
        {attachments.map((attachment) => {
          return (
            <Flex gap={16} key={attachment.filePath}>
              <div className='file-box basis-2/4'>
                <FilePdfOutlined color='primary' className='color-primary' />
                <Typography.Link
                  onClick={() => {
                    if (!attachment) return;
                    fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
                  }}
                >
                  {t(`${attachment?.name}.pdf`)}
                </Typography.Link>
                {!props.isReadOnly && (
                  <DeleteOutlined
                    className='cursor-pointer'
                    onClick={() => {
                      handleRemoveFile('checklistAttachments', attachment.filePath);
                    }}
                  />
                )}
              </div>

              <Flex align='center' className='basis-2/4' gap={4}>
                {attachment?.profilePhoto || attachment?.fullName ? (
                  <React.Fragment>
                    {attachment.profilePhoto ? (
                      <AvatarItem size={'large'} style={{ width: 34, height: 34 }} src={attachment.profilePhoto} />
                    ) : (
                      <Avatar style={{ width: 34, height: 34 }}>{attachment?.firstName?.substring(0, 1)}</Avatar>
                    )}
                    <Flex className='flex flex-col'>
                      <Typography.Text style={{ fontSize: '14px' }} className='font-bold'>
                        {attachment.fullName}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: '12px' }}>
                        {dayjs(attachment.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                      </Typography.Text>
                    </Flex>
                  </React.Fragment>
                ) : (
                  <span>&nbsp;</span>
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <>
      <div className='create-mar-form wir-form-container'>
        <Row className='ready-only-mar-container' gutter={16}>
          <Col span={12} style={{ padding: 0 }}>
            <Title className='read-only-mar-title !mb-[10px]' level={3}>
              {t('Work Inspection General Information ')}
            </Title>

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: formValues.workInspectionName,
                  },
                  label: 'Work Inspection Name',
                  editable: {
                    rules: [
                      { required: true, message: 'Please select work inspection name' },
                      {
                        max: 100,
                        message: t('Work Inspection Name cannot exceed 100 characters'),
                      },
                    ],
                    name: 'workInspectionName',
                    content: (
                      <TextArea
                        className={'mdi-input-field'}
                        count={{
                          show: true,
                          max: 100,
                        }}
                        showCount
                        allowClear
                        placeholder={t('Enter Work Inspection Name')}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        onChange={(e) => {
                          const value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          props.form.setFieldValue('workInspectionName', value);
                        }}
                      />
                    ),
                  },
                },
              ],
            })}

            <EditableFormField
              isReadonly={props.isReadOnly}
              columns={[
                {
                  label: t('Work Inspection Description'),
                  name: 'workInspectionDescription',
                  type: 'textarea',
                  readOnlyProps: {
                    className: 'min-h-[32px] leading-[32px] block',
                  },
                  editableProps: {
                    placeholder: t('Add description here'),
                    allowClear: true,
                    count: { max: 300, show: true },
                  },
                  rules: [
                    {
                      max: 300,
                      message: t('Work Inspection Description cannot exceed 300 characters'),
                    },
                  ],
                },
              ]}
            />

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: workPackages?.find((workPackage) => workPackage.id === formValues.workPackage)?.name || '',
                  },
                  label: 'Work Package',
                  editable: {
                    name: 'workPackage',
                    rules: [{ required: true, message: 'Please select work package' }],
                    content: (
                      <SearchSelect placeholder={t('Select Work Package')} options={mapDataToOptions(workPackages)} />
                    ),
                  },
                },
              ],
            })}

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: disciplines.find((discipline) => discipline.id === formValues?.discipline)?.name,
                  },
                  label: 'Discipline',
                  editable: {
                    rules: [{ required: true, message: 'Please select discipline' }],
                    name: 'discipline',
                    content: (
                      <SearchSelect
                        onChange={() => {
                          form.setFieldValue('activity', null);
                          form.setFieldValue('subActivity', null);
                          form.setFieldValue('controlPoint', null);
                        }}
                        placeholder={t('Select discipline')}
                        options={mapDataToOptions(disciplines)}
                        breakText
                      />
                    ),
                  },
                },
                {
                  readonly: {
                    content: activities?.find((activity) => activity.id === formValues?.activity)?.name,
                  },
                  label: 'Activity',
                  editable: {
                    name: 'activity',
                    content: (
                      <SearchSelect
                        onChange={() => {
                          form.setFieldValue('subActivity', null);
                          form.setFieldValue('controlPoint', null);
                        }}
                        placeholder={t('Select activity')}
                        options={mapDataToOptions(activities)}
                        breakText
                      />
                    ),
                  },
                },
              ],
            })}

            {renderConditionalFieldItem({
              columns: [
                {
                  label: 'Sub-activity',
                  readonly: {
                    content: subActivities?.find((subActivity) => subActivity.id === formValues?.subActivity)?.name,
                  },
                  editable: {
                    name: 'subActivity',
                    content: (
                      <SearchSelect
                        placeholder={t('Select Sub-activity')}
                        options={mapDataToOptions(subActivities)}
                        breakText
                        onChange={() => {
                          form.setFieldValue('controlPoint', null);
                        }}
                      />
                    ),
                  },
                },
                {
                  label: 'Control point',
                  readonly: {
                    content: controlPoints?.find((controlPoint) => controlPoint.id === formValues?.controlPoint)?.name,
                  },
                  editable: {
                    name: 'controlPoint',
                    content: (
                      <SearchSelect
                        breakText
                        placeholder={t('Select Control Point')}
                        options={mapDataToOptions(controlPoints)}
                      />
                    ),
                  },
                },
              ],
            })}

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: findPath(zonesTreeData, formValues?.zoning || ''),
                  },
                  label: 'Zoning',
                  editable: {
                    name: 'zoning',
                    content: <WorkInspectionRequestDetailZoning />,
                  },
                },
              ],
            })}

            <Flex align='center' justify='space-between' className='inspection-checklist-box'>
              <Title className='read-only-mar-title' level={3}>
                {t('Inspection Checklist ')}
              </Title>
              {renderConditionalFieldItem({
                columns: [
                  {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    },
                    editable: {
                      name: 'checklistAttachments',
                      content: (
                        <>
                          <Button
                            type='dashed'
                            icon={<UploadOutlined />}
                            onClick={() => setOpenUpload(true)}
                            className='text-size-12'
                          >
                            {t('Upload Checklist')}
                          </Button>
                          {openUpload && (
                            <UploadDocument
                              isModalOpen={openUpload}
                              setIsModalOpen={(value: boolean) => setOpenUpload(value)}
                              handleAddDocument={handleAddDocument}
                            />
                          )}
                        </>
                      ),
                    },
                  },
                ],
              })}
            </Flex>

            {attachments.length > 0 &&
              renderConditionalFieldItem({
                columns: [
                  {
                    readonly: {
                      content: renderInspectionChecklist(),
                    },
                    editable: {
                      content: renderInspectionChecklist(),
                    },
                  },
                ],
              })}

            <EditableFormField
              isReadonly={props.isReadOnly}
              columns={[
                {
                  label: t('Comment'),
                  name: 'comment',
                  type: 'textarea',
                  readOnlyProps: {
                    className: 'h-[32px] leading-[32px] block',
                  },
                  editableProps: {
                    placeholder: t('Add comment here'),
                    allowClear: true,
                  },
                },
              ]}
            />
          </Col>
          <Col span={1}>
            <Divider type='vertical' style={{ height: '100%' }} />
          </Col>
          <Col span={11}>
            {renderInviteForInspection()}
            {renderReferenceDocument()}
            {renderPictureSection()}
          </Col>
        </Row>
      </div>
    </>
  );
}
