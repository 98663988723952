import axios from 'axios';
import { getAccessToken } from 'services/token.service';

const notificationRequester = axios.create({
  baseURL: process.env.REACT_APP_BASE_NOTIFICATION_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

notificationRequester.interceptors.request.use(async (config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    config.headers.Authorization = undefined;
  }
  return config;
});
// Add a response interceptor
notificationRequester.interceptors.response.use(
  function (response: any) {
    if (response?.data?.status === 'FAILED') {
      return Promise.reject({ error: true, message: response.data?.cause });
    }
    if (response?.data?.output) {
      response.data = JSON.parse(response.data.output);
    }
    return Promise.resolve(response.data);
  },
  function (error: any) {
    if (error.response) {
      error.status = error.response.status;
      error.message = error.response.statusText;
      if (error.response.data) {
        error.message = error.response.data.message;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
    console.log(error);
    if (error?.status && error.status === 401) {
      // removeToken();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default notificationRequester;
