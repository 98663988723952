import { useFetchByParams, useQueryParams } from 'hooks';
import { Issue } from 'model';
import { useMemo } from 'react';
import { RootState } from 'store';
import { fetchProjectIssues } from 'store/my-projects.slice';
import { QueryParams } from 'types';

const selectProjectIssues = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.issues;

const selectProjectIssuesCount = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.issuesCount;

const selectProjectIssuesLoading = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.issuesLoading;

type ProjectIssuesParamsType = {
  workspaceId: string;
  projectId: string;
} & QueryParams;

export const useProjectIssuesParams = (
  options: ProjectIssuesParamsType
): [QueryParams, (params?: QueryParams) => void] => {
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const filteredParams = useMemo(() => {
    const { tab, selectedZone, ...restParams } = params;
    console.info(tab, selectedZone);
    return { ...restParams, ...options };
  }, [params]);

  return [filteredParams, onParamChange];
};

export const useProjectIssues = (query: QueryParams) => {
  const { projectId, module } = query;
  return useFetchByParams<Issue[]>({
    action: fetchProjectIssues,
    dataSelector: (state: RootState) => selectProjectIssues(state, projectId, module),
    loadingSelector: (state: RootState) => selectProjectIssuesLoading(state, projectId, module),
    params: query,
  });
};

export const useProjectIssuesCount = (query: QueryParams) => {
  const { projectId, module } = query;
  return useFetchByParams<number>({
    action: fetchProjectIssues,
    dataSelector: (state: RootState) => selectProjectIssuesCount(state, projectId, module),
    loadingSelector: (state: RootState) => selectProjectIssuesLoading(state, projectId, module),
    params: query,
  });
};
