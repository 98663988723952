import { PlusOutlined } from '@ant-design/icons';
import { Flex, Typography, Button } from 'antd';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import UploadPicture, { UploadPictureData } from 'components/common/UploadPicture';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { t } from 'i18next';
import { ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import { ModalState } from 'types';
import { ATTACHMENT_TYPES } from 'utils/contants';

type ProjectMinutesOfMeetingContentDetailsPicturesProps = {
  isReadonly?: boolean;
  pictures?: AttachmentPhoto[];
  onChange: (data: AttachmentPhoto[]) => void;
  readOnlyTitle?: ReactNode;
  title?: ReactNode;
};

const ProjectMinutesOfMeetingContentDetailsPictures = (props: ProjectMinutesOfMeetingContentDetailsPicturesProps) => {
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [attachmentPhotos, setAttachmentPhotos] = useState<AttachmentPhoto[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  useAttachmentTypes(attachmentTypeQuery);
  const validationAttachmentPicture = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.MOM_PHOTO));
  const handleAddPicture = (data: UploadPictureData) => {
    setAttachmentPhotos([
      ...attachmentPhotos,
      {
        id: `local-id-${data.imageURL}`,
        attachmentTypeId: validationAttachmentPicture?.id,
        name: data.fileName,
        filePath: data.imageURL,
      },
    ]);
  };

  const handleRemoveAttachment = (filePatch?: string) => {
    if (!filePatch) return;
    setAttachmentPhotos((prevAttachment) => prevAttachment.filter((attachment) => attachment.filePath !== filePatch));
  };

  // const setDefaultValue = () => {
  //   const result = picturesOfIssue.map((data: AttachmentPhoto) => ({
  //     ...data,
  //     hideDelete: true,
  //   }));
  //   setAttachmentPhotos(result);
  // };

  useEffect(() => {
    if (!props.pictures?.length) return;
    setAttachmentPhotos(props?.pictures);
  }, [props.pictures]);

  useEffect(() => {
    props.onChange(attachmentPhotos);
  }, [attachmentPhotos]);
  return (
    <div>
      <Flex align='center' justify='space-between' className='mb-4'>
        {props.isReadonly && attachmentPhotos.length > 0 && <Typography.Text>{props?.readOnlyTitle}</Typography.Text>}
        {!props.isReadonly && <Typography.Title level={3}>{props?.title}</Typography.Title>}
        {!props.isReadonly && (
          <Button onClick={() => setIsOpenUpload(true)} type='dashed' icon={<PlusOutlined />}>
            {t('Add Pictures')}
          </Button>
        )}
      </Flex>

      <UploadPicture
        handleAddPicture={handleAddPicture}
        isLoading={isUploading}
        isModalOpen={!!isOpenUpload}
        setIsLoading={setIsUploading}
        setIsModalOpen={setIsOpenUpload}
      />

      <AttachmentList
        hideDelete={props.isReadonly}
        attachmentPhotos={attachmentPhotos}
        onDelete={handleRemoveAttachment}
        width={93}
        height={93}
      />
    </div>
  );
};

export default ProjectMinutesOfMeetingContentDetailsPictures;
