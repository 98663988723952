import { Layout } from 'antd';
import { AnimatedPage } from 'components';
const { Footer: AntFooter } = Layout;

export default function Footer() {
  return (
    <AnimatedPage>
      <AntFooter className="footer">
        POLARIS EDGE {process.env.REACT_APP_VERSION ?? '1.0.0'}
      </AntFooter>
    </AnimatedPage>
  );
}
