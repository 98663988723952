import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Flex, Form, FormInstance, Row, Select, TimePicker, Typography } from 'antd';
import { Rule } from 'antd/es/form';
import { ReactComponent as DoubledownOutlined } from 'assets/images/icons/priorities/DoubledownOutlined.svg';
import { ReactComponent as DoubleupOutlined } from 'assets/images/icons/priorities/DoubleupOutlined.svg';
import { ReactComponent as DownOutlined } from 'assets/images/icons/priorities/DownOutlined.svg';
import { ReactComponent as UpOutlined } from 'assets/images/icons/priorities/UpOutlined.svg';
// import { ReactComponent as NodataImage } from 'assets/images/no-attachment.svg';
import { PermitToWorkDetailZoning } from 'components';
import { MiniPlan, ReadOnlyDataField, SearchSelect } from 'components/common';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import EditableFormField from 'components/common/EditableFormField';
import InviteFormField from 'components/common/InviteFormField';
// import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import SelectPlan, { SelectPlanData, SelectPlanRefHandle } from 'components/common/SelectPlan';
import { findPath } from 'components/common/TreeSelectTagRender';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
import dayjs from 'dayjs';
import { useProjectSafetyRiskActivities, useProjectSafetyRiskActivitiesParams } from 'hooks';
import { mockPTWDetails } from 'mock-data/project-data';
import { Attachment, Company, IssueAttachment, User } from 'model';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { fileService } from 'services';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import {
  selectActivitiesByDiscipline,
  // selectControlPointsBySubActivity,
  selectProjectUsers,
  selectProjectWorkPackages,
  selectProjectZones,
  // selectSubActivitiesByActivity,
  selectWorkBreakDownStructureByLevel,
} from 'store/my-projects.slice';
import { EWorkBreakDownStructureLevelID } from 'types';
import { convertZoneToTreeData } from 'utils';
import { ATTACHMENT_TYPES } from 'utils/contants';
import PermitToWorkSectionCheckList from './PermitToWorkSectionCheckList';

type PTWDetailProps = {
  workspaceId: string;
  projectId: string;
  form: FormInstance<PTWFormValues>;
  isReadOnly: boolean;
  companies: Company[];
  baseIssues?: typeof mockPTWDetails | null;
  user?: User | null;
  module: string;
  attachmentsInfo: { id: string; name: string; filePath: string; userCreated: string }[];
  setAttachmentsInfo: (value: { id: string; name: string; filePath: string; userCreated: string }[]) => void;
};

export type PTWFileUpload = {
  BaseIssueAttachments?: {
    userCreated?: string;
    id?: string;
    comment?: string;
  }[];
  attachmentTypeId?: string | null;
  name?: string;
  filePath?: string;
  createdAt?: string | Date | null;
  fullName?: string;
  firstName?: string;
  profilePhoto?: string;
  comment?: string;
};

export type PTWFormValues = {
  permitToWorkName: string;
  permitToWorkDescription: string;
  activity?: string;
  safetyRiskLevel?: string;
  zoneId?: string;
  discipline?: string;
  startDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  inspectionDate?: dayjs.Dayjs | null;
  inspectionTime?: dayjs.Dayjs | null;
  inspectors: {
    companyId: string | null;
    userId?: string | null;
  }[];
  distributions: string[];
  subActivity: string;
  checklistAttachments?: PTWFileUpload[];
  validationStatus?: string;
  comment: string;
  pictures: PTWFileUpload[];
  workPackage?: string;
  controlPoint?: string;
  validationPictures: PTWFileUpload[];
  validationChecklist: PTWFileUpload[];
  validationComment?: string;
  referenceDocuments?: PTWFileUpload[];
  plan?: {
    plan?: IssueAttachment | null | undefined;
    attachment?: Attachment | null;
    zoneId?: string | null;
  };
};

const listPTWName = [
  'General Safety ',
  'Hot Work',
  'Confined Space',
  'Mobile crane',
  'Scaffolding Excavation',
  'Live electrical',
  'Lifting operations',
  'Elevating Platform',
  'Equipment operator',
  'Piling',
  'Pressure Test & Air Flushing',
  'Lock Out / Tag Out',
];
const { Title } = Typography;
export default function PermitToWorkDetail(props: PTWDetailProps) {
  const { isReadOnly, form, workspaceId, projectId, module, baseIssues, attachmentsInfo, setAttachmentsInfo } = props;
  const { t } = useTranslation();
  // const [openUpload, setOpenUpload] = useState<boolean>(false);
  // const [openReferenceDocument, setOpenReferenceDocument] = useState<boolean>(false);
  const formValues = form.getFieldsValue(true) as PTWFormValues;
  const formInstance = Form.useFormInstance();
  const formValuesWatch = Form.useWatch([]) || {};
  const [planData, setPlanData] = useState<SelectPlanData | null>(null);
  const selectPlanRef = useRef<SelectPlanRefHandle>(null);
  const plan = formInstance.getFieldValue('plan');

  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  // const subActivities = useAppSelector(selectSubActivitiesByActivity(Form.useWatch('activity') || formValues.activity));
  // const controlPoints = useAppSelector(
  //   selectControlPointsBySubActivity(Form.useWatch('subActivity') || formValues.subActivity)
  // );
  const zones = useSelector(selectProjectZones);
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);
  const [safetyRiskActivitiesParams] = useProjectSafetyRiskActivitiesParams({
    workspaceId,
    projectId,
    orderBy: 'order,asc',
  });
  const [safetyRiskActivities] = useProjectSafetyRiskActivities(safetyRiskActivitiesParams);
  const watchedDiscipline = Form.useWatch('discipline') || formValues?.discipline;
  const activities = useAppSelector(selectActivitiesByDiscipline(watchedDiscipline));
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  const picturesFieldValue = formValues.pictures || ([] as PTWFileUpload[]);
  const [, setForceRerender] = useState(false);
  // const ptwChecklistAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_CHECKLIST));
  const ptwPhotoAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_PHOTO));
  // const ptwReferenceDocument = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_REF_DOCUMENTATION));

  // const attachments = formValues?.checklistAttachments || [];

  // const referenceDocuments = formValues?.referenceDocuments || [];

  const users = useSelector(selectProjectUsers);

  const workPackages = useSelector(selectProjectWorkPackages);

  // const handleUploadChecklist = (name: string, response: { fileName: string; fileUrl: string; keyFile: string }) => {
  //   if (!response?.fileUrl) return;
  //   attachments.push({
  //     name: name,
  //     filePath: response?.fileUrl || '',
  //     attachmentTypeId: ptwChecklistAttachment?.id || '',
  //     createdAt: new Date().toISOString(),
  //     profilePhoto: props.user?.profilePhoto,
  //     fullName: props.user?.fullName,
  //     firstName: props.user?.firstName,
  //   });
  //   form.setFieldValue('checklistAttachments', attachments);
  // };

  // const handleAddReferenceDocument = (
  //   name: string,
  //   response: { fileName: string; fileUrl: string; keyFile: string }
  // ) => {
  //   referenceDocuments.push({
  //     name: name,
  //     filePath: response?.fileUrl || '',
  //     attachmentTypeId: ptwReferenceDocument?.id || '',
  //   });
  //   form.setFieldValue('referenceDocuments', referenceDocuments);
  // };

  const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
    return data?.map((value) => ({
      value: value.id,
      label: value[labelKey as keyof typeof value],
    }));
  };

  const handleAddPicture = (data: UploadPhotoModalValue) => {
    if (!data || !ptwPhotoAttachment?.id) return;
    const pictureData = {
      id: data.fileUrl,
      name: data.fileName,
      filePath: data.fileUrl,
      attachmentTypeId: ptwPhotoAttachment?.id || '',
      profilePhoto: props.user?.profilePhoto,
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
      comment: data.comment,
    };

    picturesFieldValue.push(pictureData);
    form.setFieldValue('pictures', picturesFieldValue);
    setIsOpenUpload(false);
  };

  const renderConditionalFieldItem = ({
    columns,
  }: {
    columns: {
      style?: React.CSSProperties;
      label?: string;
      readonly?: {
        style?: React.CSSProperties;
        content?: ReactNode;
        hideLabel?: boolean;
      };
      editable: {
        rules?: Rule[];
        name?: string;
        content: ReactNode;
      };
    }[];
  }) => {
    return (
      <Row className={isReadOnly ? 'read-only-row' : ''} gutter={16}>
        {columns.map((col) => {
          if (!isReadOnly && !col.editable.content) return null;
          return (
            <Col
              flex={1}
              span={24 / columns.length}
              key={col.editable.name}
              style={{ ...(isReadOnly && col?.readonly?.style), ...col?.style }}
            >
              {isReadOnly ? (
                <ReadOnlyDataField
                  title={!col?.readonly?.hideLabel ? col?.label : ''}
                  content={col?.readonly?.content}
                />
              ) : (
                <Form.Item
                  className={'text-color-polaris-black'}
                  name={col.editable?.name}
                  label={col?.label ? t(`${col?.label}`) : ''}
                  rules={col.editable?.rules}
                  layout='vertical'
                >
                  {col.editable?.content}
                </Form.Item>
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderInviteForInspection = () => {
    return (
      <div>
        <Typography.Title level={3} className='mb-[10px] h-[32px] !leading-[32px]'>
          {t('Invite for Approval')}
        </Typography.Title>
        {renderConditionalFieldItem({
          columns: [
            {
              label: 'Inspection Date',
              readonly: {
                content: formValues?.inspectionDate ? dayjs(formValues?.inspectionDate).format('YYYY-MM-DD') : '',
              },
              style: { maxWidth: 'calc(50% - 18px)' },
              editable: {
                name: 'inspectionDate',
                content: (
                  <DatePicker
                    placeholder={t('Inspection Date')}
                    style={{ width: '100%' }}
                    format='YYYY-MM-DD'
                    // disabledDate={(date) => date.isBefore(dayjs(), 'day')}
                  />
                ),
              },
            },
            {
              style: { maxWidth: 'calc(50% - 18px)' },
              label: 'Inspection Time',
              editable: {
                name: 'inspectionTime',
                content: (
                  <TimePicker
                    minuteStep={15}
                    placeholder={t('Inspection Time')}
                    format='hh:mm A'
                    variant={'outlined'}
                    popupClassName='wir-time-picker'
                  />
                ),
              },
              readonly: {
                style: { paddingLeft: '16px' },
                content: formValues?.inspectionTime ? <div>{formValues?.inspectionTime.format('hh:mm A')}</div> : '',
              },
            },
          ],
        })}
        <InviteFormField
          renderFooter={(add) => {
            return (
              <div className='text-right'>
                {!props?.isReadOnly && (
                  <Button className='!w-[208px] text-size-12' onClick={add} type='dashed' icon={<PlusOutlined />}>
                    {t('Add Approver')}
                  </Button>
                )}
              </div>
            );
          }}
          name='inspectors'
          users={users}
          form={form}
          companies={props?.companies}
          isReadOnly={isReadOnly}
          isRequire
          showDefault
        />
        {renderConditionalFieldItem({
          columns: [
            {
              readonly: {
                content: mapDataToOptions(users, 'fullName')
                  .filter((user) => formValues.distributions?.includes(user.value))
                  .map((user) => user.label)
                  .join(', '),
              },
              label: 'Distribution',
              editable: {
                name: 'distributions',
                content: (
                  <SearchSelect
                    showSearch
                    mode='multiple'
                    allowClear
                    maxTagCount='responsive'
                    placeholder={t('Select Users')}
                    optionFilterProp='children'
                    options={mapDataToOptions(users, 'fullName')}
                  />
                ),
              },
            },
          ],
        })}
      </div>
    );
  };

  // const renderReferenceDocumentContent = () => {
  //   if (!referenceDocuments.length) {
  //     return (
  //       <Flex className='h-[165px]' align='center' justify='center' vertical gap={13}>
  //         <NodataImage />
  //         <Typography.Text className='text-size-14 text-color-disabled'>{t('No Data')}</Typography.Text>
  //       </Flex>
  //     );
  //   }

  //   return (
  //     <Flex gap={16} wrap>
  //       {referenceDocuments?.map((referenceDocument) => {
  //         return (
  //           <div className='file-box basis-[calc(50%-16px)]' key={referenceDocument.filePath}>
  //             <FilePdfOutlined color='primary' className='color-primary' />
  //             <Typography.Link
  //               onClick={() => {
  //                 if (!referenceDocument) return;
  //                 fileService.downloadFile(referenceDocument?.filePath || '', `${referenceDocument?.name}.pdf`);
  //               }}
  //             >
  //               {t(`${referenceDocument?.name}.pdf`)}
  //             </Typography.Link>
  //             {!props.isReadOnly && (
  //               <DeleteOutlined
  //                 className='cursor-pointer'
  //                 onClick={() => {
  //                   handleRemoveFile('referenceDocuments', referenceDocument.filePath);
  //                 }}
  //               />
  //             )}
  //           </div>
  //         );
  //       })}
  //     </Flex>
  //   );
  // };
  // const renderReferenceDocument = () => {
  //   return (
  //     <div>
  //       <Flex align='center' justify='space-between' className='inspection-checklist-box'>
  //         <Title className='read-only-mar-title font-weight-bold' level={3}>
  //           {t('Reference Documents')}
  //         </Title>
  //         {renderConditionalFieldItem({
  //           columns: [
  //             {
  //               style: {
  //                 display: 'flex',
  //                 flexDirection: 'column',
  //                 justifyContent: 'flex-end',
  //               },
  //               editable: {
  //                 name: 'referenceDocuments',
  //                 content: (
  //                   <>
  //                     <Button
  //                       type='dashed'
  //                       icon={<PlusOutlined />}
  //                       onClick={() => setOpenReferenceDocument(true)}
  //                       className='text-size-12'
  //                     >
  //                       {t('Add Documents')}
  //                     </Button>
  //                     {openReferenceDocument && (
  //                       <UploadDocument
  //                         isModalOpen={openReferenceDocument}
  //                         setIsModalOpen={(value: boolean) => setOpenReferenceDocument(value)}
  //                         handleAddDocument={handleAddReferenceDocument}
  //                       />
  //                     )}
  //                   </>
  //                 ),
  //               },
  //             },
  //           ],
  //         })}
  //       </Flex>
  //       {renderConditionalFieldItem({
  //         columns: [
  //           {
  //             readonly: {
  //               content: renderReferenceDocumentContent(),
  //             },
  //             editable: {
  //               content: renderReferenceDocumentContent(),
  //             },
  //           },
  //         ],
  //       })}
  //     </div>
  //   );
  // };

  const handleRemoveFile = (keyField: keyof PTWFormValues, filePatch?: string) => {
    if (!filePatch) return;
    const result = (formValues?.[keyField] as PTWFileUpload[])?.filter(
      (attachment) => attachment.filePath !== filePatch
    );
    form.setFieldValue(keyField, [...result]);
    setForceRerender((prev) => !prev);
  };

  const renderPictures = () => {
    const mappedPhotos = formValues?.pictures?.map((picture) => ({
      ...picture,
      picture: picture.profilePhoto,
      username: picture.fullName || '',
      hideDelete: props?.isReadOnly,
      firstName: picture.firstName,
    })) as AttachmentPhoto[];

    return (
      <AttachmentList
        attachmentPhotos={mappedPhotos}
        onDelete={(filePath) => {
          handleRemoveFile('pictures', filePath);
        }}
        hideDelete={props.isReadOnly}
      />
    );
  };

  const renderPictureSection = () => {
    return (
      <React.Fragment>
        <Flex align='center' justify='space-between' className='inspection-checklist-box'>
          <Title className='read-only-mar-title' level={3}>
            {t('Pictures')}
          </Title>
          {renderConditionalFieldItem({
            columns: [
              {
                style: {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                editable: {
                  name: 'pictures',
                  content: (
                    <>
                      <Button
                        type='dashed'
                        icon={<PlusOutlined />}
                        onClick={() => setIsOpenUpload(true)}
                        className='text-size-12'
                      >
                        {t('Add Pictures')}
                      </Button>
                      <UploadPhotoModal
                        isModalOpen={isOpenUpload}
                        setIsModalOpen={setIsOpenUpload}
                        onSubmit={handleAddPicture}
                      />
                    </>
                  ),
                },
              },
            ],
          })}
        </Flex>
        <div className='picture-box'>{formValues?.pictures?.length > 0 && renderPictures()}</div>
      </React.Fragment>
    );
  };

  // const renderInspectionChecklist = () => {
  //   if (attachments?.length === 0) return null;

  //   return (
  //     <Flex gap={16} vertical>
  //       {attachments.map((attachment) => {
  //         return (
  //           <Flex gap={16} key={attachment.filePath}>
  //             <div className='file-box basis-2/4'>
  //               <FilePdfOutlined color='primary' className='color-primary' />
  //               <Typography.Link
  //                 onClick={() => {
  //                   if (!attachment) return;
  //                   fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
  //                 }}
  //               >
  //                 {t(`${attachment?.name}.pdf`)}
  //               </Typography.Link>
  //               {!props.isReadOnly && (
  //                 <DeleteOutlined
  //                   className='cursor-pointer'
  //                   onClick={() => {
  //                     handleRemoveFile('checklistAttachments', attachment.filePath);
  //                   }}
  //                 />
  //               )}
  //             </div>

  //             <Flex align='center' className='basis-2/4' gap={4}>
  //               {attachment?.profilePhoto || attachment?.fullName ? (
  //                 <React.Fragment>
  //                   {attachment.profilePhoto ? (
  //                     <AvatarItem size={'large'} style={{ width: 34, height: 34 }} src={attachment.profilePhoto} />
  //                   ) : (
  //                     <Avatar style={{ width: 34, height: 34 }}>{attachment?.firstName?.substring(0, 1)}</Avatar>
  //                   )}
  //                   <Flex className='flex flex-col'>
  //                     <Typography.Text style={{ fontSize: '14px' }} className='font-bold'>
  //                       {attachment.fullName}
  //                     </Typography.Text>
  //                     <Typography.Text style={{ fontSize: '12px' }}>
  //                       {dayjs(attachment.createdAt).format('DD-MM-YYYY HH:mm:ss')}
  //                     </Typography.Text>
  //                   </Flex>
  //                 </React.Fragment>
  //               ) : (
  //                 <span>&nbsp;</span>
  //               )}
  //             </Flex>
  //           </Flex>
  //         );
  //       })}
  //     </Flex>
  //   );
  // };

  useEffect(() => {
    if (!plan) return;
    setPlanData(plan);
  }, [plan]);

  const onChangePlan = (planData: SelectPlanData | null) => {
    setPlanData(planData);
    form.setFieldValue('zoneId', planData?.zoneId || zones?.[6]?.id);
  };
  return (
    <>
      <div className='create-mar-form wir-form-container'>
        <Row className='ready-only-mar-container' gutter={16}>
          <Col span={12} style={{ padding: 0 }}>
            <Title className='read-only-mar-title !mb-[10px] font-weight-bold' level={3}>
              {t('Permit To Work General Information')}
            </Title>

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: listPTWName?.find((name) => name === formValues.permitToWorkName) || '',
                  },
                  label: 'Permit To Work Name',
                  editable: {
                    name: 'permitToWorkName',
                    rules: [{ required: true, message: 'Please Select Permit To Work Name' }],
                    content: (
                      <SearchSelect
                        placeholder={t('Select Permit To Work Name')}
                        options={listPTWName.map((d) => ({ value: d, label: d }))}
                      />
                    ),
                  },
                },
              ],
            })}

            <EditableFormField
              isReadonly={props.isReadOnly}
              columns={[
                {
                  label: t('Permit To Work Description'),
                  name: 'permitToWorkDescription',
                  type: 'textarea',
                  readOnlyProps: {
                    className: 'min-h-[32px] leading-[32px] block',
                  },
                  editableProps: {
                    placeholder: t('Add description here'),
                  },
                },
              ]}
            />
            {renderConditionalFieldItem({
              columns: [
                {
                  label: t('PTW Start Date'),
                  readonly: {
                    content: formValues?.inspectionDate ? dayjs(formValues?.inspectionDate).format('YYYY-MM-DD') : '',
                  },
                  style: { maxWidth: 'calc(50% - 18px)' },
                  editable: {
                    rules: [{ required: true, message: t('Select date') }],
                    name: 'startDate',
                    content: (
                      <DatePicker placeholder={t('PTW Start Date')} style={{ width: '100%' }} format='YYYY-MM-DD' />
                    ),
                  },
                },
                {
                  label: t('PTW End Date'),
                  readonly: {
                    content: formValues?.endDate ? dayjs(formValues?.endDate).format('YYYY-MM-DD') : '',
                  },
                  style: { maxWidth: 'calc(50% - 18px)' },
                  editable: {
                    rules: [{ required: true, message: t('Select date') }],
                    name: 'endDate',
                    content: (
                      <DatePicker placeholder={t('PTW Start Date')} style={{ width: '100%' }} format='YYYY-MM-DD' />
                    ),
                  },
                },
              ],
            })}
            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: workPackages?.find((workPackage) => workPackage.id === formValues.workPackage)?.name || '',
                  },
                  label: 'Work Package',
                  editable: {
                    name: 'workPackage',
                    rules: [{ required: true, message: 'Please select work package' }],
                    content: (
                      <SearchSelect placeholder={t('Select Work Package')} options={mapDataToOptions(workPackages)} />
                    ),
                  },
                },
              ],
            })}
            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: disciplines.find((discipline) => discipline.id === formValues?.discipline)?.name,
                  },
                  label: 'Category',
                  editable: {
                    rules: [{ required: true, message: 'Please select category' }],
                    name: 'discipline',
                    content: (
                      <SearchSelect
                        onChange={() => {
                          form.setFieldValue('activity', null);
                          form.setFieldValue('subActivity', null);
                          form.setFieldValue('controlPoint', null);
                        }}
                        placeholder={t('Select category')}
                        options={mapDataToOptions(disciplines)}
                        breakText
                      />
                    ),
                  },
                },
                {
                  readonly: {
                    content: activities?.find((activity) => activity.id === formValues?.activity)?.name,
                  },
                  label: 'Sub-category',
                  editable: {
                    name: 'activity',
                    content: (
                      <SearchSelect
                        placeholder={t('Select Sub-category')}
                        options={mapDataToOptions(activities)}
                        breakText
                      />
                    ),
                  },
                },
              ],
            })}
            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: (safetyRiskActivities || []).find(
                      (discipline) => discipline.name === formValues?.discipline
                    )?.name,
                  },
                  label: 'Safety Risk Level',
                  editable: {
                    rules: [{ required: true, message: 'Please select Safety Risk Level' }],
                    name: 'safetyRiskLevel',
                    content: (
                      <Select placeholder={t('Select Safety Risk Level')}>
                        {safetyRiskActivities?.map((option) => (
                          <Select.Option key={option.id} value={option.id} label={option.name}>
                            <div>
                              {option.code === 'MIN' && <DownOutlined className='mr-2' />}
                              {option.code === 'LOW' && <DoubledownOutlined className='mr-2' />}
                              {option.code === 'MOD' && <UpOutlined className='mr-2' />}
                              {option.code === 'HIG' && <DoubleupOutlined className='mr-2' />}
                              <span className={`risk-level ${option.code}`}>{option.name}</span>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  },
                },
              ],
            })}

            {renderConditionalFieldItem({
              columns: [
                {
                  readonly: {
                    content: findPath(zonesTreeData, formValues?.zoneId || zones?.[6]?.id) || '',
                  },
                  label: 'Zoning',
                  editable: {
                    name: 'zoneId',
                    content: <PermitToWorkDetailZoning disabled={true} />,
                  },
                },
              ],
            })}
            <Flex className='inspection-checklist-box' vertical>
              <SelectPlan
                onChangePlan={onChangePlan}
                name={formValuesWatch?.name}
                ref={selectPlanRef}
                plan={planData?.plan}
              />
              {planData?.plan && planData?.attachment && (
                <Flex justify='center' className='my-5'>
                  <MiniPlan
                    width={488}
                    onDelete={selectPlanRef?.current?.resetPlan}
                    filePath={planData?.attachment?.filePath as string}
                    imgWidth={planData?.attachment?.width as number}
                    imgHeight={planData?.attachment?.height as number}
                    onClick={() => {
                      selectPlanRef?.current?.setOpenPin(true);
                    }}
                    layers={planData.plan?.layerData ? [planData.plan?.layerData] : []}
                    module={module}
                    plan={planData?.plan}
                    inspectionName={formValuesWatch?.name}
                    planImage={planData?.attachment}
                  />
                </Flex>
              )}
            </Flex>
            <PermitToWorkSectionCheckList
              isReadOnly={isReadOnly}
              selectedBaseIssue={baseIssues ?? null}
              workspaceId={workspaceId}
              projectId={projectId}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={(value) => {
                if (value) {
                  setAttachmentsInfo(value);
                }
              }}
            />
            {/* <Flex align='center' justify='space-between' className='inspection-checklist-box'>
              <Title className='read-only-mar-title font-weight-bold' level={3}>
                {t('Permit To Work Checklist ')}
              </Title>
              {renderConditionalFieldItem({
                columns: [
                  {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    },
                    editable: {
                      name: 'checklistAttachments',
                      content: (
                        <>
                          <Button
                            type='dashed'
                            icon={<UploadOutlined />}
                            onClick={() => setOpenUpload(true)}
                            className='text-size-12'
                          >
                            {t('Upload Checklist')}
                          </Button>
                          {openUpload && (
                            <UploadDocument
                              isModalOpen={openUpload}
                              setIsModalOpen={(value: boolean) => setOpenUpload(value)}
                              handleAddDocument={handleUploadChecklist}
                            />
                          )}
                        </>
                      ),
                    },
                  },
                ],
              })}
            </Flex>
            {attachments.length > 0 &&
              renderConditionalFieldItem({
                columns: [
                  {
                    readonly: {
                      content: renderInspectionChecklist(),
                    },
                    editable: {
                      content: renderInspectionChecklist(),
                    },
                  },
                ],
              })} */}
            <EditableFormField
              isReadonly={props.isReadOnly}
              columns={[
                {
                  label: t('Comment'),
                  name: 'comment',
                  type: 'textarea',
                  readOnlyProps: {
                    className: 'h-[32px] leading-[32px] block',
                  },
                  editableProps: {
                    placeholder: t('Add comment here'),
                  },
                },
              ]}
            />
          </Col>
          <Col span={1}>
            <Divider type='vertical' style={{ height: '100%' }} />
          </Col>
          <Col span={11}>
            {renderInviteForInspection()}
            {/* {renderReferenceDocument()} */}
            <PermitToWorkSectionCheckList
              isReadOnly={isReadOnly}
              selectedBaseIssue={baseIssues ?? null}
              workspaceId={workspaceId}
              projectId={projectId}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={(value) => {
                if (value) {
                  setAttachmentsInfo(value);
                }
              }}
            />
            {renderPictureSection()}
          </Col>
        </Row>
      </div>
    </>
  );
}
