import { ReactNode, useState } from 'react';
import { Layout } from 'antd';
import { WorkspaceSidebar, Footer } from 'components';
const { Content } = Layout;

const WorkspaceSettingLayout = ({ children }: { children: ReactNode }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout hasSider>
      <WorkspaceSidebar
        collapsed={collapsed}
        toggleCollapsed={() => { setCollapsed(!collapsed); }}
      />
      <Layout
        style={{
          marginLeft: collapsed ? "80px" : "300px",
          transition: "all 0.25s",
          background: '#EAF0F9'
        }}
      >
        <Content className='main-content'>
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default WorkspaceSettingLayout;
