import { Company, Project, Workspace } from 'model';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const workspaceService = {
  getMyWorkspace: async (filters?: QueryParams) => {
    return request.get<Workspace>('/my-workspace', {
      params: filters,
    });
  },
  getWorkspaceUsers: async (workspaceId: string, query: QueryParams) => {
    return request.get<
      {
        id: string;
        userId: string;
        status: string;
        Company: Company;
        ProjectUsers: { id: string; Project: Project }[];
      }[]
    >(`/workspaces/${workspaceId}/users`, {
      params: query,
    });
  },
  getProjectUsers: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<
      PaginationData<{
        id: string;
        userId: string;
        status: string;
        Company: Company;
        ProjectUsers: { id: string; Project: Project }[];
      }>
    >(`/workspaces/${workspaceId}/projects/${projectId}/users`, {
      params: query,
    });
  },
  getWorkspaceById: async (workspaceId: string, query: QueryParams) => {
    return request.get<Project>(`/workspaces/${workspaceId}`, {
      params: query,
    });
  },

  updateWorkspaceById: async (workspaceId: string, data: { name: string; logoPath: string; coverPath: string }) => {
    return request.put(`/workspaces/${workspaceId}`, data);
  },
  updateWorkspaceSettings: async (
    workspaceId: string,
    data: {
      workspaceSettings: {
        code: string;
        value: string | boolean | string[] | undefined;
      }[];
    }
  ) => {
    return request.put(`/workspaces/${workspaceId}/workspace-settings/multiple-create-or-update`, data);
  },
};

export default workspaceService;
