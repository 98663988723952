import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { UploadMultipleDocumentFileValue } from '../../common/UploadMultipleDocument';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { DocumentsTransmissionFormValues } from './ProjectDocumentsTransmissionForm';
import TruncatedAttachmentDocument from 'components/common/TruncatedAttachmentDocument';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import UploadMultipleDocument from '../../common/UploadMultipleDocument';

type MSSsListDocumentSectionProps = {
  isReadOnly: boolean;
  workspaceId: string;
  projectId: string;
  attachmentsInfo: { name: string; revision: string; filePath: string }[];
  setAttachmentsInfo: (value: { name: string; revision: string; filePath: string }[]) => void;
};
export default function ProjectDocumentsTransmissionListDocument(props: MSSsListDocumentSectionProps) {
  const { t } = useTranslation();
  const { isReadOnly } = props;
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const form = useANTDFormInstance<DocumentsTransmissionFormValues>();
  const attachments = form.getFieldValue(['transmittalDocuments']);
  const [, setForceRerender] = useState(false);

  const handleAddDocument = async (documents: UploadMultipleDocumentFileValue[]) => {
    const transmittalDocuments = form.getFieldValue('transmittalDocuments');
    transmittalDocuments.push(...documents);
    form.setFieldValue('transmittalDocuments', [...transmittalDocuments]);
    setForceRerender((prev) => !prev);
  };

  const handleRemoveDocument = async (filePath: string) => {
    const transmittalDocuments = form.getFieldValue('transmittalDocuments');
    const result = transmittalDocuments.filter((file) => file.filePath !== filePath);
    form.setFieldValue('transmittalDocuments', [...result]);
    setForceRerender((prev) => !prev);
  };

  return (
    <div className='list-document-popup-container' style={{ width: '100%' }}>
      <Row>
        <Form.Item name={'transmittalDocuments'}>
          {isOpenUpload && (
            <UploadMultipleDocument
              attachmentTypeCode={ATTACHMENT_TYPES.DOCUMENT_TRANSMISSIONS}
              isModalOpen={isOpenUpload}
              setIsModalOpen={(value: boolean) => setIsOpenUpload(value)}
              handleAddDocument={handleAddDocument}
            />
          )}
        </Form.Item>
        <div className={`document-transmission-attachment-container ${!isReadOnly ? 'mb-4' : ''}`}>
          <Typography.Title className='form-title ' level={3}>
            {t('Transmittal Documents')}
          </Typography.Title>
          {!isReadOnly && (
            <Button
              type='dashed'
              icon={<PlusOutlined />}
              onClick={() => setIsOpenUpload(true)}
              className='text-size-12'
            >
              {t('Add Document')}
            </Button>
          )}
        </div>
        <div className='list-document-group'>
          <Row className={'list-document'}>
            <Col span={8}>
              <Typography.Text className={`text-size-12 ${isReadOnly ? 'text-color-secondary' : ''}`}>
                {t('Document Name / Description')}
                <span className='text-red-500'> *</span>
              </Typography.Text>
            </Col>
            <Col span={7}>
              <Typography.Text className={`text-size-12 ${isReadOnly ? 'text-color-secondary' : ''}`}>
                {t('Revision')}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text className={`text-size-12 ${isReadOnly ? 'text-color-secondary' : ''}`}>
                {t('File')}
                <span className='text-red-500'> *</span>
              </Typography.Text>
            </Col>
            <Col span={1} className='mss-list-doc'></Col>
          </Row>
          <Row>
            <List
              dataSource={attachments}
              style={{ width: '100%' }}
              renderItem={(item) => (
                <Row className={'mss-document-row'} style={{ width: '100%' }}>
                  <Col span={8}>
                    <Typography.Text>{item.documentName}</Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Typography.Text>{item.revision}</Typography.Text>
                  </Col>
                  <Col span={9}>
                    <TruncatedAttachmentDocument
                      hideDelete={isReadOnly}
                      style={{
                        border: 'none',
                        padding: '8px 0px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      key={item.filePath}
                      fileName={item.name}
                      filePath={item.filePath}
                      onRemoveFile={() => {
                        handleRemoveDocument(item?.filePath);
                      }}
                    />
                  </Col>
                </Row>
              )}
            />
          </Row>
        </div>
      </Row>
    </div>
  );
}
