import { useMemo } from 'react';
import { QueryParams } from 'types';
import { IssueDiscipline } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchIssueDisciplines, selectIssueDisciplines } from 'store/common.slice';

export const useIssueDisciplinesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, []);
};

export const useIssueDisciplines = (query: QueryParams) => {
  return useFetchByParams<IssueDiscipline[]>({
    action: fetchIssueDisciplines,
    dataSelector: selectIssueDisciplines,
    params: query,
  });
};
