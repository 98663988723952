import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Flex, Layout } from 'antd';
import { CustomColumnType, StatusData, TooltipData } from 'components/common/DynamicTable';
import { UserCompanyData } from 'components/common/UserCompany';
import { BaseIssue, Company, User } from 'model';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectBaseIssuesParamsType } from 'hooks/base-issue';
import { kpiData } from 'types/project';
import { DynamicCollapseTableProps } from 'components/common/DynamicCollapseTable';
import CategoryBaseIssueTable, { DisciplineBaseIssueTableRefHandle } from 'components/common/CategoryBaseIssueTable';
import { Dayjs } from 'dayjs';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { INVITATION_ROLE, ISSUE_STATUSES } from 'utils/contants';

export type MOMDataSource = {
  id: string;
  number?: TooltipData;
  subject: TooltipData;
  meetingDate: string | Date | Dayjs;
  submittedBy?: UserCompanyData;
  attendees?: {
    companyName?: string;
    userName?: string;
  }[];
  status: StatusData;
  momReqort: string;
  category?: string;
  originatorId?: string;
};

type ListViewProps = {
  baseIssues?: BaseIssue[];
  projectBaseIssueParams: ProjectBaseIssuesParamsType;
  categoryBaseIssueTableRef: RefObject<DisciplineBaseIssueTableRefHandle>;
  onActionClick?: DynamicCollapseTableProps<MOMDataSource>['onActionClick'];
  onTotalKpi: (kpi: kpiData) => void;
  refreshCategoryByFunction: () => void;
  duplicateBaseissueId?: string;
  companies?: Company[];
  profile: User | null;
};

export default function ProjectMinutesOfMeetingListView(props: ListViewProps) {
  const { t } = useTranslation();
  const users = useAppSelector(selectProjectUsers);

  const columns: CustomColumnType<MOMDataSource>[] = [
    {
      sortKey: 'MinutesOfMeeting.momNumber',
      title: t('Number'),
      dataIndex: 'number',
      key: 'MinutesOfMeeting.momNumber',
      component: {
        type: 'tooltip',
      },
      width: 210,
      onCell: () => {
        return {
          style: {
            maxWidth: '180px',
          },
        };
      },
    },
    {
      sortKey: 'name',
      title: t('Subject'),
      dataIndex: 'subject',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
      width: 300,
    },
    {
      sortKey: 'MinutesOfMeeting.meetingDate',
      title: t('Meeting Date'),
      dataIndex: 'meetingDate',
      width: 120,
      component: {
        type: 'date',
      },
    },
    {
      title: t('Submitted By'),
      dataIndex: 'submittedBy',
      width: 100,
      component: {
        type: 'user-company',
      },
    },
    {
      title: t('Attendees'),
      dataIndex: 'attendees',
      width: 100,
      component: {
        type: 'user-company',
      },
      onCell: () => {
        return {
          style: {
            width: 'max-content',
            maxWidth: 200,
          },
        };
      },
    },
    {
      sortKey: 'IssueStatus.orderNumber',
      title: t('Status'),
      width: 140,
      dataIndex: 'status',
      component: {
        type: 'status',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: 140,
          },
        };
      },
    },
    {
      title: t('MOM Report'),
      width: 50,
      dataIndex: 'momReport',
      render: () => {
        return (
          <Flex justify='center' align='center'>
            <Button
              type='link'
              icon={<FilePdfOutlined />}
              className='ml-0 pa-0 d-flex'
              style={{ height: 'auto' }}
            ></Button>
          </Flex>
        );
      },
    },
    {
      dataIndex: 'id',
      width: 50,
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit',
            key: 'edit',
            isDisabled: (record) => {
              const isDisibleRecord = record?.originatorId === props.profile?.id;
              const checkStatus = record?.status?.code === ISSUE_STATUSES.ISSUED;
              return !isDisibleRecord || checkStatus;
            },
          },
          {
            label: 'Duplicate',
            key: 'duplicate',
          },
          {
            label: 'Delete',
            key: 'delete',
            isDisabled: (record) => {
              const isDisibleRecord = record?.originatorId === props.profile?.id;
              return !isDisibleRecord;
            },
          },
        ],
      },
    },
  ];
  const generateMOMDataSource = (baseIssues: BaseIssue): MOMDataSource => {
    if (!baseIssues) return {} as MOMDataSource;
    const user = users?.find((user) => user.id === baseIssues.originatorId);
    const [, code] = baseIssues.IssueStatus?.code?.split('_') || [];
    const attendees = baseIssues?.BaseIssueWatchers?.filter((receiver) => receiver.role === INVITATION_ROLE.RECEIVER);

    return {
      id: baseIssues.id,
      number: {
        title: baseIssues?.MinutesOfMeeting?.momNumber,
      },
      subject: {
        title: baseIssues.name?.trim() || '',
      },
      meetingDate: baseIssues?.MinutesOfMeeting?.meetingDate || '',
      submittedBy: {
        companyName: user?.WorkspaceUser?.Company?.name || '',
        userName: user?.fullName || '',
      },
      attendees: attendees?.map((invitation) => {
        const user = users?.find((user) => user.id === invitation.watcherId);
        const company = props?.companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
        return {
          companyName: company?.name || '',
          userName: user?.fullName || '',
          color: 'default',
        };
      }),
      status: { code: code || '', name: baseIssues.IssueStatus?.name || '' },
      category: baseIssues?.MinutesOfMeeting?.categoryId,
      originatorId: baseIssues?.originatorId,
      momReqort: '',
    };
  };
  return (
    <Layout.Content>
      <CategoryBaseIssueTable
        ref={props.categoryBaseIssueTableRef}
        columns={columns}
        header={t('Categories')}
        onActionClick={props.onActionClick}
        onMapDataSource={generateMOMDataSource}
        projectBaseIssueParams={props?.projectBaseIssueParams}
        onTotalKpi={props.onTotalKpi}
        refreshDiscipline={props?.refreshCategoryByFunction}
      />
    </Layout.Content>
  );
}
