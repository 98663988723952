import { Layout, Typography, Table, Tag, Dropdown } from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  MailOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { User } from 'model';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useState } from 'react';
import ProjectUser from 'model/ProjectUser';
import { TablePagination } from 'components';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';
import { TablePaginationIssueParams } from 'components/common/TablePagination';
const { Text } = Typography;

type WorkspaceUserListProps = {
  users: User[];
  loading?: boolean;
  usersCount: number;
  pageSize: number;
  currentPage: number;
  onFilter: (value: TablePaginationIssueParams & { orderBy?: string }) => void;
};
export default function WorkspaceUserList(props: WorkspaceUserListProps) {
  const { users, usersCount, pageSize, currentPage, onFilter } = props;
  const { t } = useTranslation();
  const statusColors = {
    active: 'green',
    inactive: 'magenta',
    pending: 'orange',
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const columns: ColumnsType<User> = [
    {
      sorter: (a, b) => {
        const fullNameA = a.fullName || '';
        const fullNameB = b.fullName || '';
        return fullNameA.localeCompare(fullNameB);
      },
      title: t('Name'),
      width: 200,
      dataIndex: 'fullName',
      render: (_, record: User) => (
        <div className='line-item-with-avatar'>
          <div className='item-avatar'>
            {record.profilePhoto && <ImgItem src={record.profilePhoto} alt={record.firstName} />}
          </div>
          <div className='item-info'>
            <div className='title'>{[record.firstName, record.lastName].filter((i) => i).join(' ')}</div>
            <Text type='secondary'>{record.email}</Text>
          </div>
        </div>
      ),
    },
    {
      sorter: (a, b) => {
        const companyA = a.Company?.name || '';
        const companyB = b.Company?.name || '';
        return companyA.localeCompare(companyB);
      },
      title: t('Company'),
      dataIndex: 'Company',
      width: 160,
      render: (company) => <span>{company?.name}</span>,
    },
    {
      sorter: (a, b) => {
        const jobTitleA = a.jobTitle || '';
        const jobTitleB = b.jobTitle || '';
        return jobTitleA.localeCompare(jobTitleB);
      },
      width: 200,
      title: t('Job title'),
      dataIndex: 'jobTitle',
      render: (jobTitle) => <span>{jobTitle}</span>,
    },
    {
      title: t('Projects'),
      dataIndex: 'ProjectUsers',
      render: (value) => <span>{value?.map((item: ProjectUser) => item.Project?.name).join(', ')}</span>,
      sorter: (a, b) =>
        (a.ProjectUsers?.[0]?.Project?.name || '').localeCompare(b.ProjectUsers?.[0]?.Project?.name || ''),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      width: 20,
      render: (value: keyof typeof statusColors) => <Tag color={statusColors[value]}>{value}</Tag>,
    },
    {
      title: '',
      width: 10,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              ...(record.UserInvitations?.length
                ? [
                    {
                      label: t('Copy invitation link'),
                      key: '0',
                      icon: <UsergroupAddOutlined />,
                      onClick: () =>
                        navigator.clipboard.writeText(
                          `${window.location.hostname}/auth?invitationToken=${record.UserInvitations?.[0]?.token}`
                        ),
                    },
                  ]
                : []),
              {
                label: t('Set as active'),
                key: '1',
                icon: <UsergroupAddOutlined />,
              },
              {
                label: t('Set as inactive'),
                key: '2',
                icon: <UsergroupDeleteOutlined />,
              },
              {
                label: t('Resend invitation'),
                key: '3',
                icon: <MailOutlined />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Revoke invitation')}</span>,
                key: '4',
                icon: <CloseCircleOutlined style={{ color: '#FF4D4F' }} />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Revoke access')}</span>,
                key: '5',
                icon: <CloseCircleOutlined style={{ color: '#FF4D4F' }} />,
              },
              {
                label: <span style={{ color: '#FF4D4F' }}>{t('Delete user')}</span>,
                key: '6',
                icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
              },
            ],
            onClick: (e) => console.log('click', e),
          }}
        >
          <EllipsisOutlined className='cursor-pointer' />
        </Dropdown>
      ),
    },
  ];
  const rowSelection: TableProps['rowSelection'] = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: User) => ({
      name: record.firstName,
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <Layout.Content className='settings-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={{
          limit: pageSize,
          page: currentPage,
        }}
        issuesCount={usersCount}
      />
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          pageSize: pageSize,
          total: usersCount,
          current: currentPage,
        }}
        className='workspaces-list-table project-user-table my-6'
        columns={columns}
        rowKey={(row) => row.id + row.Company?.id}
        dataSource={users}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={{
          limit: pageSize,
          page: currentPage,
        }}
        issuesCount={usersCount}
      />
    </Layout.Content>
  );
}
