import { useMemo } from 'react';
import { QueryParams } from 'types';
import { ProjectType } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import {
  fetchProjectTypes,
  selectMyWorkspaceProjectTypes,
  selectMyWorkspaceProjectTypesLoading,
} from 'store/my-workspace.slice';

export const useMyWorkspaceProjectTypesParams = (
  options: {
    workspaceId?: string;
  } & { [key: string]: any }
) => {
  const { workspaceId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, [workspaceId, query?.limit, query?.page, query?.search, query?.sort]);
};

export const useMyWorkspaceProjectTypes = (query: any) => {
  return useFetchByParams<ProjectType[]>({
    action: fetchProjectTypes,
    dataSelector: selectMyWorkspaceProjectTypes,
    loadingSelector: selectMyWorkspaceProjectTypesLoading,
    params: query,
  });
};
