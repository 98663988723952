import { useFetchByParams, useQueryParams } from 'hooks';
import { DesignResponse, designQueryParams } from 'modules/DocumentManagement/models/Design';
import { useMemo } from 'react';
import { RootState } from 'store';
import { fetchProjectShopDrawings, fetchProjectShopDrawingsDisciplines } from 'store/my-projects.slice';
import { QueryParams } from 'types';

const selectProjectShopDrawings = (state: RootState, projectId: string) =>
  state.myProjects.projects?.[projectId]?.shopDrawings;

const selectProjectShopDrawingsLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects?.[projectId]?.shopDrawingsLoading;

const selectProjectShopDrawingsDisciplines = (state: RootState, projectId: string) =>
  state.myProjects.projects?.[projectId]?.shopDrawingDisciplines;

const selectProjectShopDrawingsDisciplinesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects?.[projectId]?.shopDrawingDisciplinesLoading;

export const useProjectShopDrawings = (query: designQueryParams) => {
  const { projectId } = query;
  const res = useFetchByParams<DesignResponse>({
    action: fetchProjectShopDrawings,
    dataSelector: (state: RootState) => selectProjectShopDrawings(state, projectId),
    loadingSelector: (state: RootState) => selectProjectShopDrawingsLoading(state, projectId),
    params: query,
  });
  return res;
};

export const useProjectShopDrawingDisciplines = (query: QueryParams) => {
  const { projectId } = query;
  const res = useFetchByParams<{ discipline: string }[]>({
    action: fetchProjectShopDrawingsDisciplines,
    dataSelector: (state: RootState) => selectProjectShopDrawingsDisciplines(state, projectId),
    loadingSelector: (state: RootState) => selectProjectShopDrawingsDisciplinesLoading(state, projectId),
    params: query,
  });
  return res;
};
export const useProjectShopDrawingDisciplinesParams = (
  options: QueryParams
): [query: QueryParams, onParamChange: (params: QueryParams) => void] => {
  const { projectId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const queryParams = { ...params, ...options };

    return queryParams;
  }, [projectId, params]);

  return [query, onParamChange];
};

export const useProjectShopDrawingParams = (
  options: QueryParams
): [query: QueryParams, onParamChange: (params: QueryParams) => void] => {
  const { projectId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const queryParams = { ...params, ...options };

    return queryParams;
  }, [projectId, params]);

  return [query, onParamChange];
};
