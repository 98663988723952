import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, ModalProps, message } from 'antd';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

type CloseConfirmationProps = {
  isModalOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onCancel: () => void;
} & ModalProps;
export default function CloseConfirmation(props: CloseConfirmationProps) {
  const { isModalOpen, title, description, onCancel, onClose } = props;
  const { t } = useTranslation();

  const handleOk = async () => {
    try {
      onClose();
      message.success(t('Closed success!'));
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal className='delete-confirmation' open={isModalOpen} closable={false} footer={null}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <p className='mb-4 title'>
          <ExclamationCircleOutlined style={{ color: '#52c41a' }} /> {title}
        </p>
      </div>
      <p className='mb-4 description'>{description}</p>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button ghost type='primary' className='close-btn' key='submit' onClick={handleOk}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  );
}
