import AssetType from 'model/AssetType';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const assetService = {
  getAllAsset: async (workspaceId: string, query?: QueryParams) => {
    return request.get<PaginationData<AssetType>>(`/workspaces/${workspaceId}/assets`, {
      params: query,
    });
  },
  createAsset: async (workspaceId: string, asset: AssetType) => {
    return request.post<AssetType>(`/workspaces/${workspaceId}/assets`, asset);
  },
  updateAsset: async (workspaceId: string, assetId: string, asset: AssetType) => {
    return request.put(`/workspaces/${workspaceId}/assets/${assetId}`, asset);
  },
  deleteAssets: async (workspaceId: string, assetIds: string[]) => {
    return request.delete(`/workspaces/${workspaceId}/assets`, {
      params: assetIds,
    });
  },
  deleteAssetById: async (workspaceId: string, assetId: string) => {
    return request.delete(`/workspaces/${workspaceId}/assets/${assetId}`);
  },
  getAssetById: async (workspaceId: string, assetId: string) => {
    return request.get(`/workspaces/${workspaceId}/assets/${assetId}`);
  },
  getProjectEquipmentList: async (workspaceId: string, projectId: string, query?: QueryParams) => {
    return request.get<PaginationData<AssetType>>(`/workspaces/${workspaceId}/projects/${projectId}/assets`, {
      params: query,
    });
  },
};

export default assetService;
