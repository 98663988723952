import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Design } from 'model';
import { designFieldValues } from 'modules/DocumentManagement/models/Design';

type ManageRevisionsHeaderProps = {
  managingRevisionGroup?: Design;
};

const ManageRevisionsHeader: React.FC<ManageRevisionsHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { managingRevisionGroup } = props;
  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='create-inspection-header'>
        <Col>
          <Space direction='vertical' style={{ width: '100%', rowGap: 0 }}>
            <div className='title'>
              {managingRevisionGroup?.title as string} - {t('Revisions')}
            </div>
            <div>
              <Typography.Text type='secondary' style={{ paddingLeft: '12px' }}>
                {`${designFieldValues.disciplineValues[managingRevisionGroup?.discipline as string]?.value} `}
              </Typography.Text>
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ManageRevisionsHeader;
