import { useMemo } from 'react';
import { Flex, Space, Steps, Typography } from 'antd';
import { BaseIssue, MDIsValidationHistory } from 'model';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  FilePdfOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { ATTACHMENT_TYPES, VALIDATION_STATUS } from 'utils/contants';
import { useTranslation } from 'react-i18next';
import marIconInProgress from 'assets/images/icons/mar-in-progress-icon-blue.png';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import { fileService } from 'services';
import { selectAttachmentByCode } from 'store/common.slice';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';

type MDIsHistoryProps = {
  companyHistories: MDIsValidationHistory[];
  baseIssues?: BaseIssue;
};

export default function MDIsHistory(props: MDIsHistoryProps) {
  const { t } = useTranslation();
  const renderCommentIcon = (status: boolean | null) => {
    switch (status) {
      case true:
        return <CheckCircleOutlined />;
      case false:
        return <CloseCircleOutlined />;
      case null:
        return <QuestionCircleOutlined />;
    }
  };
  const validationAttachmentDocument = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.VAD));
  const ValidationPhotoAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.VAA));
  const users = useAppSelector(selectProjectUsers);
  const getStatusNameAndIcon = (status: string) => {
    switch (status) {
      case VALIDATION_STATUS.APPROVED || VALIDATION_STATUS.VALIDATED:
        return {
          name: t('Approved'),
          icon: <CheckCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.REJECTED:
        return {
          name: t('Rejected'),
          icon: <CloseCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.IN_REVIEW:
        return {
          name: t('Review In Progress'),
          icon: <img width={24} src={marIconInProgress} alt='' />,
        };
      case VALIDATION_STATUS.SUBMITTED:
        return {
          name: t('Submitted'),
          icon: <SendOutlined size={24} />,
        };
      default:
        return null;
    }
  };

  const history = useMemo(() => {
    return [...props.companyHistories].reverse().map((validation) => {
      const mappedPhotos = validation?.comments[0]?.attachment
        .filter((picture) => picture.attachmentTypeId === ValidationPhotoAttachment?.id)
        .map((picture) => {
          const user = users.find((user) => user.id === picture?.userCreated);
          return {
            ...picture,
            picture: user?.profilePhoto,
            username: user?.fullName || '',
            hideDelete: true,
            firstName: user?.firstName,
            comment: picture.comment,
          };
        }) as AttachmentPhoto[];
      const mappeaAttachments = validation?.comments[0]?.attachment
        .filter((picture) => picture.attachmentTypeId === validationAttachmentDocument?.id)
        .map((picture) => {
          const user = users.find((user) => user.id === picture?.userCreated);
          return {
            ...picture,
            picture: user?.profilePhoto,
            username: user?.fullName || '',
            hideDelete: true,
            firstName: user?.firstName,
            comment: picture.comment,
          };
        }) as AttachmentPhoto[];

      return {
        className: `ant-steps-item-${validation.status}`,
        icon: getStatusNameAndIcon(validation?.status || '')?.icon,
        description: (
          <>
            <div className='company-name'>{validation?.company}</div>
            {VALIDATION_STATUS.IN_REVIEW === validation?.status && (
              <Space className='user-information' size={4}>
                <span className='status'>{getStatusNameAndIcon(validation?.status || '')?.name}</span>
                <span className='text-color-polaris-black'>by</span>
                <span className='username'>{validation?.comments[0].user}</span>
                <span className={`date`}>{validation?.comments[0].date}</span>
              </Space>
            )}
            {VALIDATION_STATUS.IN_REVIEW !== validation?.status &&
              VALIDATION_STATUS.SUBMITTED !== validation?.status && (
                <div className='mdi-comments__container'>
                  <div className='mdi-comments__collapse-title-container'>
                    <Typography.Text>{t('Technical Document')}</Typography.Text>
                    <span
                      className={`mdi-comments__status-icon ${
                        validation?.comments[0]?.technicalDocumentCompliancy === null
                          ? 'not-applicable'
                          : validation?.comments[0]?.technicalDocumentCompliancy
                            ? 'validated'
                            : 'rejected'
                      }`}
                    >
                      {renderCommentIcon(validation?.comments[0]?.technicalDocumentCompliancy)}
                    </span>
                  </div>
                  <div className='mdi-comments__collapse-title-container'>
                    <Typography.Text>{t('Quantity')}</Typography.Text>
                    <span
                      className={`mdi-comments__status-icon ${
                        validation?.comments[0]?.quantityCompliancy === null
                          ? 'not-applicable'
                          : validation?.comments[0]?.quantityCompliancy
                            ? 'validated'
                            : 'rejected'
                      }`}
                    >
                      {renderCommentIcon(validation?.comments[0]?.quantityCompliancy)}
                    </span>
                  </div>
                  <div className='mdi-comments__collapse-title-container'>
                    <Typography.Text>{t('Physical Condition')}</Typography.Text>
                    <span
                      className={`mdi-comments__status-icon ${
                        validation?.comments[0]?.physicalConditionCompliancy === null
                          ? 'not-applicable'
                          : validation?.comments[0]?.physicalConditionCompliancy
                            ? 'validated'
                            : 'rejected'
                      }`}
                    >
                      {renderCommentIcon(validation?.comments[0]?.physicalConditionCompliancy)}
                    </span>
                  </div>
                  <div className='mdi-comments__collapse-title-container'>
                    <Typography.Text className='overallCompliancy'>
                      {t('Compliant with Material Approval Request')}
                    </Typography.Text>
                    <span
                      className={`mdi-comments__status-icon  ${
                        validation?.comments[0]?.overallCompliancy === null
                          ? 'not-applicable'
                          : validation?.comments[0]?.overallCompliancy
                            ? 'validated'
                            : 'rejected'
                      }`}
                    >
                      {renderCommentIcon(validation?.comments[0]?.overallCompliancy)}
                    </span>
                  </div>
                </div>
              )}
            {validation?.comments[0].comment && (
              <>
                <Typography.Text type='secondary' style={{ fontStyle: 'italic' }}>
                  {t('Comment')}
                </Typography.Text>
                <div
                  className={`comment ${validation?.status === VALIDATION_STATUS.APPROVED_WITH_COMMENT ? 'approved-with-comment' : ''}`}
                >
                  {validation?.comments[0].comment}
                </div>
              </>
            )}
            {mappeaAttachments.length > 0 && (
              <>
                <Typography.Text type='secondary' style={{ fontStyle: 'italic' }}>
                  {t('Documents uploaded')}
                </Typography.Text>
                <Flex wrap gap={8}>
                  {mappeaAttachments.map((attachment) => {
                    return (
                      <div className='attachment' key={attachment?.filePath}>
                        <FilePdfOutlined color='primary' className='color-primary' />

                        <Typography.Link
                          onClick={() => {
                            fileService.downloadFile(attachment?.filePath || '', `${attachment?.name ?? ''}.pdf`);
                          }}
                        >
                          {`${attachment?.name}.pdf`}
                        </Typography.Link>
                      </div>
                    );
                  })}
                </Flex>
              </>
            )}

            {mappedPhotos.length > 0 ? (
              <div className='mt-2'>
                <Typography.Text type='secondary' style={{ fontStyle: 'italic' }}>
                  {t('Pictures uploaded')}
                </Typography.Text>
                <AttachmentList hideDelete={true} attachmentPhotos={mappedPhotos} />
              </div>
            ) : null}
            {VALIDATION_STATUS.SUBMITTED === validation?.status && (
              <Space className='user-information' size={4}>
                <span className='status'>{getStatusNameAndIcon(validation?.status || '')?.name}</span>
                <span className='text-color-polaris-black'>by</span>
                <span className='username'>{validation?.comments[0].user}</span>
                <span className={`date`}>{validation?.comments[0].date}</span>
              </Space>
            )}
          </>
        ),
      };
    });
  }, [props.baseIssues]);

  return (
    <div className='wir-history-container'>
      <Steps direction='vertical' items={history} labelPlacement='vertical' />
    </div>
  );
}
