import { Breadcrumb, Menu, MenuProps, Tag, theme } from 'antd';
import { useProjectByIdParams, useProjectById } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import envService, { HiddenFeatures } from 'services/env.service';
import { EModuleName, getModulePath } from 'utils/my-project';

type MenuInspectionsProps = {
  module: string;
  workspaceId: string;
  className?: string;
};

export default function MenuInspections(props: MenuInspectionsProps) {
  const { module, workspaceId } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const params = useParams();
  const projectId = params.projectId as string;

  const [projectParams] = useProjectByIdParams({ projectId, workspaceId });
  const [project] = useProjectById(projectParams);
  const [itemsProject, setItemsProject] = useState<MenuProps['items']>([]);

  useEffect(() => {
    if (module === 'Quality') {
      const items: MenuProps['items'] = [
        {
          label: t('Dashboard'),
          key: 'dashboard',
        },
        {
          label: t('ITP'),
          key: 'itp',
          disabled: envService.isFeatureHidden(HiddenFeatures.ITP),
        },
        {
          label: t('Observations'),
          key: 'observations',
        },
        {
          label: t('Material approvals'),
          key: 'material-approvals',
          disabled: envService.isFeatureHidden(HiddenFeatures.MAR),
        },
        {
          label: t('Material deliveries'),
          key: 'material-deliveries',
          disabled: envService.isFeatureHidden(HiddenFeatures.MDI),
        },
        {
          label: t('Method statements'),
          key: 'method-statements',
          disabled: envService.isFeatureHidden(HiddenFeatures.MS),
        },
        {
          label: t('Shop drawings'),
          key: 'shop-drawings',
        },
        {
          label: t('Work inspections'),
          key: 'work-inspections',
          disabled: envService.isFeatureHidden(HiddenFeatures.WIR),
        },
        {
          label: t('Non Conformity'),
          key: 'non-conformity-report',
          disabled: envService.isFeatureHidden(HiddenFeatures.NCR),
        },
      ];
      setItemsProject(items);
    } else if (module === 'Safety') {
      const items: MenuProps['items'] = [
        {
          label: t('Dashboard'),
          key: 'dashboard',
        },
        {
          label: t('Inspections'),
          key: 'inspections',
        },
        {
          label: t('Safety Violations'),
          key: 'safety-violations',
          disabled: envService.isFeatureHidden(HiddenFeatures.SFV),
        },
        {
          label: t('Near Misses'),
          key: 'near-misses',
          disabled: envService.isFeatureHidden(HiddenFeatures.NM),
        },
        {
          label: t('Accidents'),
          key: 'accidents',
          disabled: envService.isFeatureHidden(HiddenFeatures.ACD),
        },
        {
          label: t('Permit To Work'),
          key: 'permit-to-work',
          disabled: envService.isFeatureHidden(HiddenFeatures.PTW),
        },
      ];
      setItemsProject(items);
    } else if (module === EModuleName.DocumentManagement) {
      const items: MenuProps['items'] = [
        {
          label: t('Work Breakdown Structure'),
          key: 'dm-work-breakdown-structure',
          disabled: envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT),
        },
        {
          label: t('Numbering System'),
          key: 'numbering-system',
          disabled: envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT),
        },
        {
          label: t('Design register'),
          key: 'designs',
        },
        ...(!envService.isFeatureHidden(HiddenFeatures.DOC_HUB)
          ? [
              {
                label: t('Document Hub'),
                key: 'documents-hub',
                disabled: envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT),
              },
            ]
          : []),
        {
          label: t('Doc Transmission'),
          key: 'documents-transmission',
          disabled: envService.isFeatureHidden(HiddenFeatures.DOCS_MANAGEMENT),
        },
        {
          label: t('Minutes Of Meeting'),
          key: 'minutes-of-meeting',
          disabled: envService.isFeatureHidden(HiddenFeatures.MOM),
        },
        {
          label: t('Letters'),
          key: 'letters',
          disabled: envService.isFeatureHidden(HiddenFeatures.LETTERS),
        },
      ];
      setItemsProject(items);
    } else if (module === EModuleName.Construction) {
      const items: MenuProps['items'] = [
        {
          label: t('Change Orders'),
          key: 'change-orders',
        },
      ];
      setItemsProject(items);
    }
  }, [module]);

  const onClick: MenuProps['onClick'] = (e) => {
    const path = getModulePath(module);
    console.log(path);
    console.log('path', path);

    navigate(`/projects/${projectId}/${path}/${e.key}`);
  };

  return (
    <>
      <Breadcrumb items={[{ title: project?.name }, { title: <Tag color={token.colorPrimary}> {t(module)} </Tag> }]} />
      <Menu
        className='inspection-menu'
        onClick={onClick}
        selectedKeys={[lastSegment]}
        mode='horizontal'
        items={itemsProject}
      />
    </>
  );
}
