import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
  theme,
  TreeSelect,
  Typography,
} from 'antd';
import { DataNode } from 'antd/es/tree';
import { CustomTagRender, DatePickerRange, SearchSelect } from 'components';
import dayjs from 'dayjs';
import {
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueTypes,
  useIssueTypesParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { Company, IssueStatus, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'types';
import { convertZoneToTreeData, jsonToArray, parseQueryParams } from 'utils';
import { TASK_STATUS_COLOR } from 'utils/contants';

type FilterFormData = {
  assigneeId?: string;
  companyId?: string;
  groupIssues?: string;
  issueDisciplineId?: string[];
  issuePriorityId?: string[];
  issueStatusId?: string[];
  issueTypeId?: string;
  plannedStartDate: Date[];
  zoneId?: string[];
  disciplineId?: string[];
};

type FilterIssueFormProps = {
  companies: Company[];
  users: User[];
  issueStatuses: IssueStatus[];
  userCompanyId?: string;
  workspaceId: string;
  projectId: string;
  isOpenFilter: boolean;
  setIsOpenFilter: (value: boolean) => void;
  handleFilter: (value: QueryParams) => void;
  isReset: boolean;
  setReset: (value: boolean) => void;
  module: string;
  isOwnerCompany: boolean;
};
export default function FilterIssueForm(props: FilterIssueFormProps) {
  const {
    companies,
    users,
    issueStatuses,
    userCompanyId,
    handleFilter,
    isReset,
    projectId,
    workspaceId,
    setReset,
    isOpenFilter,
    setIsOpenFilter,
    module,
    isOwnerCompany,
  } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formValuesWatch = Form.useWatch([], form);
  // const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [lastSubmitFilterData, setLastSubmitFilterData] = useState<FilterFormData>({
    assigneeId: undefined,
    companyId: undefined,
    groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues',
    issueDisciplineId: [],
    issuePriorityId: [],
    issueStatusId: [],
    issueTypeId: undefined,
    plannedStartDate: [],
    zoneId: [],
    disciplineId: [],
  });
  // console.log(lastSubmitFilterData);
  // const [initialFilterData, setInitialFilterData] = useState({});

  const [issueTypesQuery] = useIssueTypesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [wbsQuery] = useWorkBreakdownStructureParams({
    projectId,
    workspaceId,
    orderBy: 'order,asc',
    limit: 'unlimited',
  });
  const [wbs] = useWorkBreakdownStructures(wbsQuery);
  const [mainPhase] = useMemo(() => wbs?.filter((phase) => phase.isMain === true) || [], [wbs]);
  const disciplines = useMemo(() => wbs?.filter((phase) => phase.parentId === mainPhase?.id) || [], [wbs]);

  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [isOpenFilter, selectedCompanyId]);
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const setUpData = (filterData: FilterFormData) => {
    const groupIssue = filterData.groupIssues ?? 'allIssues';
    form.setFieldsValue(filterData);
    form.setFieldValue('groupIssues', groupIssue);
    setDisableGroup(groupIssue === 'allIssues' ? false : true);
  };
  useEffect(() => {
    const formattedParams: FilterFormData = {
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues',
      issueDisciplineId: [],
      issuePriorityId: [],
      issueStatusId: [],
      issueTypeId: undefined,
      plannedStartDate: [],
      zoneId: [],
      disciplineId: [],
    };
    const params = parseQueryParams(window.location.search);
    if (params['plannedStartDate[gte]']) {
      formattedParams.plannedStartDate = [dayjs(params['plannedStartDate[gte]'].replace('+', ' ')).toDate()];
    }
    if (params['plannedStartDate[lte]']) {
      formattedParams.plannedStartDate = [
        ...(formattedParams?.plannedStartDate ?? []),
        dayjs(params['plannedStartDate[lte]'].replace('+', ' ')).toDate(),
      ];
    }
    formattedParams.issueTypeId = params['issueTypeId'] || undefined;
    if (params['companyId[]']) {
      const companyId = jsonToArray(params['companyId[]'])[0];
      if (companyId !== userCompanyId) formattedParams.companyId = companyId || undefined;
      else {
        if (params['assigneeId[]']) {
          formattedParams.companyId = companyId || undefined;
        } else {
          formattedParams.groupIssues = 'companyIssues';
        }
      }
    }
    if (params['profileId']) {
      formattedParams.groupIssues = 'myIssues';
    }
    if (params['watcherId']) {
      formattedParams.groupIssues = 'watchedIssues';
    }
    if (params['assigneeId[]']) {
      formattedParams['assigneeId'] = jsonToArray(params['assigneeId[]'])[0] || undefined;
    }
    if (params['issueDisciplineId[]']) {
      formattedParams.issueDisciplineId = jsonToArray(params['issueDisciplineId[]']);
    }
    if (params['issuePriorityId[]']) {
      formattedParams.issuePriorityId = jsonToArray(params['issuePriorityId[]']);
    }
    if (params['issueStatusId[]']) {
      formattedParams.issueStatusId = jsonToArray(params['issueStatusId[]']);
    }
    if (params['zoneId[]']) {
      formattedParams.zoneId = jsonToArray(params['zoneId[]']);
    }
    if (params['disciplineId[]']) {
      formattedParams.disciplineId = jsonToArray(params['disciplineId[]']);
    }

    setLastSubmitFilterData(formattedParams);
    // setUpData(formattedParams);
  }, []);

  useEffect(() => {
    if (isOpenFilter) {
      setUpData(lastSubmitFilterData);
    }
  }, [isOpenFilter, lastSubmitFilterData]);

  useEffect(() => {
    if (isReset) {
      form.resetFields();
      setDisableGroup(false);
      setLastSubmitFilterData({
        assigneeId: undefined,
        companyId: undefined,
        groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues',
        issueDisciplineId: [],
        issuePriorityId: [],
        issueStatusId: [],
        issueTypeId: undefined,
        plannedStartDate: [],
        zoneId: [],
        disciplineId: [],
      });
      setIsOpenFilter(false);
      setReset(false);
    }
  }, [isReset]);

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };
  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = users?.find((user) => user.id === assigneeId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setDisableGroup(e.target.value === 'allIssues' ? false : true);
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };

  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    handleFilter(values);
    setIsOpenFilter(false);
  };
  const onResetFilter = () => {
    form.resetFields();
    setDisableGroup(false);
    setLastSubmitFilterData({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues',
      issueDisciplineId: [],
      issuePriorityId: [],
      issueStatusId: [],
      issueTypeId: undefined,
      plannedStartDate: [],
      zoneId: [],
      disciplineId: [],
    });
    handleFilter({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues',
      issueDisciplineId: [],
      issuePriorityId: [],
      issueStatusId: [],
      issueTypeId: undefined,
      plannedStartDate: [],
      zoneId: [],
      disciplineId: [],
    });
    setIsOpenFilter(false);
  };

  useEffect(() => {
    const isObjectUndefined =
      formValuesWatch &&
      Object.keys(formValuesWatch)
        .filter((key) => key !== 'groupIssues')
        .every((key) => formValuesWatch[key] === undefined || formValuesWatch[key] === null);
    if (
      (isObjectUndefined &&
        (formValuesWatch['groupIssues'] === 'allIssues' || formValuesWatch['groupIssues'] === undefined)) ||
      !formValuesWatch
    ) {
      setIsFiltering(false);
    } else {
      setIsFiltering(true);
    }
  }, [formValuesWatch]);

  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
      setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);
  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(zoneId);
  };
  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}
              className='text-uppercase'
            >
              {t('Filter')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                // console.log(lastSubmitFilterData);
                // form.setFieldsValue(lastSubmitFilterData);
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            <Flex key='filter-actions' justify='end' className='mx-5'>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='groupIssues' initialValue={'myIssues'}>
                  <Radio.Group defaultValue={'myIssues'} onChange={onChangeGroupIssues}>
                    <Space direction='vertical'>
                      <Radio disabled={!isOwnerCompany} style={{ lineHeight: '2.4' }} value='allIssues'>
                        {module && module === 'Safety' ? t('All inspections') : t('All observations')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myIssues'>
                        {module && module === 'Safety' ? t('My inspections') : t('My observations')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='companyIssues'>
                        {module && module === 'Safety' ? t('My company inspections') : t('My company observations')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='watchedIssues'>
                        {module && module === 'Safety' ? t('Watched inspections') : t('Watched observations')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company assignee')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeCompany}
                    options={companies?.map((option) => ({ value: option.id, label: option.name }))}
                  />
                </Form.Item>
                <Form.Item className='filter-form__item' name='assigneeId' label={t('Assignee')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeAssignee}
                    options={assigneeFilter?.map((option) => ({ value: option.id, label: option.fullName }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={24}>
                <Form.Item
                  className='filter-form__item'
                  name='plannedStartDate'
                  label={module && module === 'Safety' ? t('Inspection date range') : t('Observation date range')}
                >
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                <Form.Item
                  className='filter-form__item'
                  name='issueTypeId'
                  label={module && module === 'Safety' ? t('Inspection type') : t('Observation type')}
                >
                  <SearchSelect
                    allowClear
                    placeholder={t('Select')}
                    options={issueTypes?.map((option) => ({ value: option.id, label: t(option.name) }))}
                  />
                </Form.Item>
              </Col>
              {module === 'Safety' ? (
                <Col span={12}>
                  <Form.Item className='filter-form__item' name='issueDisciplineId' label={t('Inspection discipline')}>
                    <SearchSelect
                      allowClear
                      showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={issueDisciplines?.map((option) => ({ value: option.id, label: t(option.name) }))}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12}>
                  <Form.Item className='filter-form__item' name='disciplineId' label={t('Observation discipline')}>
                    <SearchSelect
                      allowClear
                      showSearch
                      mode='multiple'
                      maxTagCount='responsive'
                      placeholder={t('Select')}
                      options={disciplines?.map((option) => ({ value: option.id, label: t(option.name || '') }))}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='issuePriorityId' label={t('Priority')}>
                  <Select
                    allowClear
                    // showSearch
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={issuePriorities?.map((option) => ({
                      value: option.id,
                      label: (
                        <div>
                          {option.icon && <img src={option.icon} alt='' width={16} height={16} className='mr-2' />}
                          <span>{t(option.name)}</span>
                        </div>
                      ),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {' '}
                <Form.Item className='filter-form__item' name='issueStatusId' label={t('Status')}>
                  <Select
                    allowClear
                    // showSearch
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={issueStatuses?.map((option) => ({
                      value: option.id,
                      label: <Tag color={TASK_STATUS_COLOR[option.code]}>{t(option.name)}</Tag>,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Flex vertical>
              <Form.Item className='filter-form__item' name='zoneId' label={t('Zoning')}>
                <TreeSelect
                  multiple
                  maxTagCount='responsive'
                  style={{ width: '100%' }}
                  value={zoneUpdated}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={zonesTreeData}
                  placeholder={t('Select')}
                  treeDefaultExpandAll
                  onChange={onChangeZone}
                  tagRender={(props) => CustomTagRender(props, zonesTreeData)}
                />
              </Form.Item>
            </Flex>
          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={() => {
        setIsOpenFilter(!isOpenFilter);
      }}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : '', borderColor: isFiltering ? token.colorPrimary : '' }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
