import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Flex,
  Form,
  FormInstance,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  TreeSelect,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';
import { SearchSelect } from 'components';
import { Company, User } from 'model';
import TextArea from 'antd/es/input/TextArea';
import EditableFormField from './EditableFormField';
import { mapDataToOptions } from 'utils/object';
import { ATTACHMENT_TYPES, MAR_STATUSES, MSS_STATUSES } from 'utils/contants';
import DocumentAdder, { AttachmentBaseIssue } from './DocumentAdder';

const { Text, Title } = Typography;

export type Attachment = {
  attachmentTypeId?: string | null;
  name?: string;
  filePath?: string;
  createdAt?: string;
  fullName?: string;
  firstName?: string;
  profilePhoto?: string;
  comment?: string;
};

type MaterialApprovalRequestPopupProps = {
  isModalOpen: boolean;
  form: FormInstance;
  companies: Company[];
  users: User[];
  isExternal: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  startValidation: (
    selection: string,
    isFinal: boolean,
    validatorId: string | null,
    validatorCompanyId: string | null,
    hasApproveWithComment?: boolean
  ) => void;
  currentCompany: Company | null;
  currentValidator: User | null;
  originator: User | undefined;
  startFor: 'MAR' | 'MSS';
  profile: User | null;
};
export default function ValidationPopup(props: MaterialApprovalRequestPopupProps) {
  const { t } = useTranslation();
  const {
    isModalOpen,
    form,
    companies = [],
    users,
    setIsModalOpen,
    startValidation,
    currentCompany,
    currentValidator,
    originator,
    startFor,
    profile,
  } = props;

  // User is login
  const currentUser = users.find((user) => user.id === profile?.id);
  const isValidStatus =
    !form.getFieldValue('IssueStatus')?.code ||
    form.getFieldValue('IssueStatus')?.code === MSS_STATUSES.DRAFT ||
    form.getFieldValue('IssueStatus')?.code === MAR_STATUSES.DRAFT;
  const isCompanyOriginator =
    currentUser?.WorkspaceUser?.Company?.id === form.getFieldValue('companyOriginatorId') && isValidStatus;

  const [selectedButton, setSelectedButton] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [companyRep, setCompanyRep] = useState('');
  const [isFinal, setIsFinal] = useState<boolean>(false);
  const [hasApproveWithComment, setHasApproveWithComment] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [documentsInfo, setDocumentsInfo] = useState<AttachmentBaseIssue[]>([]);

  const [value, setValue] = useState('');
  const [filterCompanyReps, setFilterCompanyReps] = useState<User[]>([]);
  const [selectText, setSelectText] = useState('');

  const distributionOption = useMemo(() => {
    return companies.map((company) => {
      const userCompanies = users.filter((user: User) => user.WorkspaceUser?.Company?.id === company.id);
      return {
        title: company.name,
        value: company.id,
        className: 'mss-distribution',
        disableCheckbox: true,
        children: userCompanies.map((user) => {
          return {
            title: user.fullName,
            value: user.id,
          };
        }),
      };
    });
  }, [users, companies]);

  useEffect(() => {
    setCompanyId('');
    setCompanyRep('');
    setSelectedButton('');
    setValue('');

    if (!isModalOpen) {
      form.resetFields(['validation-comment']);
      if (isCompanyOriginator) {
        form.resetFields(['watcherIds']);
      }
      setAttachments([]);
      setDocumentsInfo([]);
    }
  }, [isModalOpen, isCompanyOriginator]);

  useEffect(() => {
    const modalHeader = document.querySelector('.ant-modal-header');
    if (modalHeader) {
      modalHeader.className = `ant-modal-header ${isCompanyOriginator ? 'internal' : selectedButton}`;
    }
    const modalClose = document.querySelector('.ant-modal-close-x');
    if (modalClose) {
      modalClose.className = `ant-modal-close-x ${isCompanyOriginator ? 'internal' : selectedButton}`;
    }
  }, [selectedButton, isCompanyOriginator]);

  useEffect(() => {
    if (isCompanyOriginator) {
      setValue('submit');
    }
  }, [isCompanyOriginator]);

  const buttonColors: { [key: string]: string } = {
    internal: '#1677FF',
    internal_approve: '#237804',
    external: '#1677FF',
    external_approve: '#237804',
    approve: '#237804',
    reject: '#CF1322',
    approve_with_comment: '#237804',
  };
  const onChangeCompany = (companyId: string) => {
    if (!companyId) {
      form.setFieldValue('companyId', null);
      setCompanyId('');
      return;
    }
    setCompanyId(companyId);
    const autoFillUsers = users?.filter((user) => user.WorkspaceUser?.Company?.id === companyId);
    setCompanyRep(autoFillUsers[0]?.id ?? '');
    form.validateFields(['companyId']);
  };
  const onChangeCompanyRep = (companyRep: string) => {
    if (!companyRep) {
      form.setFieldValue('companyRep', null);
      setCompanyRep('');
      return;
    }
    setCompanyRep(companyRep);
    const selectedUser = users?.find((user) => user.id === companyRep);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    if (companies[existingCompany]?.id) {
      setCompanyId(companies[existingCompany]?.id);
    }
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
    form.validateFields(['companyRep']);
    form.validateFields(['companyId']);
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    setSelectedButton(e.target.value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleValidate = async () => {
    try {
      const valid = await form.validateFields();
      if (valid) {
        const comment = form.getFieldValue('validation-comment');
        const attachmentResult = [...attachments].map((attachment) => ({ ...attachment, comment }));
        form.setFieldValue('validationAttachments', attachmentResult);
        startValidation(value, isFinal, companyRep, companyId, hasApproveWithComment);
        setIsModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleButtonClick = (buttonValue: string) => {
    if (selectedButton === buttonValue) {
      setSelectedButton('');
      setValue('');
    } else {
      setSelectedButton(buttonValue);
      setValue(buttonValue);
    }
  };

  const renderButton = (buttonValue: string, label: string, customClass: string) => (
    <Button
      type='default'
      shape='round'
      style={{
        minWidth:
          buttonValue === 'internal_approve' ||
          buttonValue === 'internal' ||
          buttonValue === 'external' ||
          buttonValue === 'external_approve'
            ? 210
            : buttonValue === 'approve_with_comment'
              ? 232
              : 120,
      }}
      className={`custom-button-validation mt-1 ${customClass} ${selectedButton === buttonValue ? 'selected' : ''}`}
      onClick={() => handleButtonClick(buttonValue)}
    >
      {selectedButton === buttonValue && <CheckCircleFilled />}
      {label}
    </Button>
  );

  const customFooter = (): React.ReactNode => {
    return (
      <Flex className={'validation-footer'} justify={'end'}>
        <div className='main-validation-btn'>
          <Button key='back' onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button
            key='next'
            type={'primary'}
            disabled={
              form.getFieldValue('companyId') == null ||
              form.getFieldValue('companyRep') == null ||
              (selectedButton === 'approve_with_comment' && !form.getFieldValue('validation-comment'))
            }
            style={
              form.getFieldValue('companyId') != null &&
              form.getFieldValue('companyRep') != null &&
              (selectedButton === 'approve_with_comment' ? form.getFieldValue('validation-comment') : true) &&
              titleColor !== '#001D3D'
                ? {
                    backgroundColor: titleColor,
                    color: 'white',
                  }
                : {}
            }
            onClick={handleValidate}
          >
            {t('Submit')}
          </Button>
        </div>
      </Flex>
    );
  };

  const isDisabledCompany = (company: Company) => {
    const isUserEmpty = company.WorkspaceUsers?.length === 0;
    const hasCurrentCompany = company.id === currentCompany?.id;

    if (isCompanyOriginator) {
      return isUserEmpty;
    }

    return isUserEmpty || (selectedButton.includes('external') && hasCurrentCompany);
  };

  useEffect(() => {
    switch (selectedButton) {
      case 'internal':
        setValue('transfer');
        setIsFinal(false);
        setHasApproveWithComment(false);
        setSelectText('Select the next Reviewer');
        form.setFieldValue(['companyId'], currentCompany?.id);
        setCompanyId(currentCompany?.id || '');
        form.setFieldValue(['companyRep'], null);
        break;
      case 'internal_approve':
        setValue('validate');
        setIsFinal(false);
        setHasApproveWithComment(false);
        setSelectText('Select the next Approver');
        form.setFieldValue(['companyId'], currentCompany?.id);
        setCompanyId(currentCompany?.id || '');
        form.setFieldValue(['companyRep'], null);
        break;

      case 'external':
        setValue('transfer');
        setIsFinal(false);
        setHasApproveWithComment(false);
        setSelectText('Select the next Reviewer');
        form.setFieldValue(['companyId'], null);
        setCompanyId('');

        form.setFieldValue(['companyRep'], null);
        break;
      case 'external_approve':
        setValue('validate');
        setIsFinal(false);
        setHasApproveWithComment(false);
        setSelectText('Select the next Approver');
        form.setFieldValue(['companyId'], null);
        form.setFieldValue('companyRep', null);
        setCompanyId('');
        break;
      case 'approve_with_comment':
      case 'approve':
        setValue('validate');
        setIsFinal(true);
        if (selectedButton === 'approve_with_comment') {
          setHasApproveWithComment(true);
        } else {
          setHasApproveWithComment(false);
        }
        setSelectText(`You are about to APPROVE the ${startFor}`);
        setCompanyId(currentCompany?.id || '');
        setCompanyRep(currentValidator?.id || '');
        form.setFieldValue(['companyId'], currentCompany?.id || '');
        form.setFieldValue('companyRep', currentValidator?.id || '');
        form.validateFields(['companyRep', 'companyId']);
        break;
      case 'reject':
        setValue('reject');
        setIsFinal(false);
        setHasApproveWithComment(false);
        setCompanyRep(originator?.id || '');
        setCompanyId(originator?.WorkspaceUser?.Company?.id || '');

        form.setFieldValue(['companyId'], originator?.WorkspaceUser?.Company?.id || '');
        form.setFieldValue('companyRep', originator?.id || '');
        form.validateFields(['companyRep']);
        form.validateFields(['companyId']);
        setSelectText(`You are about to REJECT the ${startFor} it will send back to its Originator`);
        break;
      default: {
        setSelectText('Select the next Reviewer/Approver');
        break;
      }
    }
  }, [selectedButton]);

  useEffect(() => {
    const filterUsers = users?.filter((user) => !companyId || user.WorkspaceUser?.Company?.id === companyId);
    setFilterCompanyReps(filterUsers);
  }, [companyId, users, selectedButton]);

  const isDisabled =
    selectedButton === 'approve' || selectedButton === 'reject' || selectedButton === 'approve_with_comment';
  const titleColor = selectedButton ? buttonColors[selectedButton] : isCompanyOriginator ? '#1677FF' : '#001D3D';

  useEffect(() => {
    if (selectedButton.length === 0) {
      form.resetFields(['companyId', 'companyRep']);
    }
  }, [selectedButton]);
  console.log('render', documentsInfo);

  return (
    <Modal
      className={`validation-modal`}
      width={629}
      title={
        <span style={{ color: titleColor }}>
          {isCompanyOriginator ? t('Submittal Form') : t('Review and Approval Form')}
        </span>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={customFooter}
      wrapClassName={'custom-modal'}
    >
      <Form form={form} layout={'vertical'}>
        <div className='validation-main-content'>
          {!isCompanyOriginator && (
            <DocumentAdder
              title={startFor === 'MSS' ? t('Commented Method Statement') : t('Commented Material Approval Request')}
              attachmentType={ATTACHMENT_TYPES.VAD}
              onChange={(attachments) => {
                setAttachments(attachments);
                setDocumentsInfo(attachments);
              }}
              documentsInfo={documentsInfo}
            />
          )}

          {!isCompanyOriginator && (
            <div className='mb-5'>
              <Title className={'validation-label-title text-size-14'}>{t('Comment')}</Title>
              <Form.Item name={'validation-comment'}>
                <TextArea
                  className={'validation-comment'}
                  placeholder={t('Add comment here...')}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </div>
          )}
          {!isCompanyOriginator && (
            <>
              <div className='form-group'>
                <Text className={'validation-block'}>
                  {t('Send to a colleague')} <span style={{ fontWeight: 'bold' }}>{t('within your company')}</span>{' '}
                  {'for further review and/ or approval'}
                </Text>
                <div className='btn-validation'>
                  <Radio.Group className='validation-radio-group' value={value} onChange={onChange}>
                    {renderButton('internal', t('Reviewed and Forward'), 'custom-button-internal')}
                    {renderButton('internal_approve', t('Approved and Forward'), 'custom-button-next-approver')}
                  </Radio.Group>
                </div>
              </div>
              <div className='form-group mt-5'>
                <Text className={'validation-block'}>
                  {t('Forward to the')} <span style={{ fontWeight: 'bold' }}>{t('next company')}</span>{' '}
                  {'for their review and/ or approval'}
                </Text>
                <div className='btn-validation'>
                  <Radio.Group className='validation-radio-group' value={value} onChange={onChange}>
                    {renderButton('external', t('Reviewed and Forward'), 'custom-button-internal')}
                    {renderButton('external_approve', t('Approved and Forward'), 'custom-button-next-approver')}
                  </Radio.Group>
                </div>
              </div>
            </>
          )}

          <div className='form-group'>
            <Title className={'validation-label-title'} level={5}>
              {selectText}
            </Title>
            <Row>
              <Col span={24} className={'validation-block'}>
                <div className={'validation-column-data'}>
                  <Form.Item
                    className={'selection-field'}
                    name={'companyId'}
                    label={t('Company')}
                    labelAlign={'left'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <SearchSelect
                      disabled={
                        isDisabled ||
                        (!isCompanyOriginator && selectedButton.length === 0) ||
                        selectedButton === 'internal' ||
                        selectedButton === 'internal_approve'
                      }
                      placeholder={t('Select Company')}
                      value={companyId}
                      options={(companies ?? []).map((company) => ({
                        value: company.id,
                        label: company.name,
                        disabled: isDisabledCompany(company),
                      }))}
                      onChange={(value) => onChangeCompany(value)}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className={'validation-column-data'}>
                  <Form.Item
                    name={'companyRep'}
                    className={'selection-field'}
                    rules={[{ required: true, message: '' }]}
                    label={t('Name')}
                    labelAlign={'right'}
                  >
                    <SearchSelect
                      disabled={isDisabled || (!isCompanyOriginator && selectedButton.length === 0)}
                      placeholder={t('Select Name')}
                      value={companyRep}
                      options={(filterCompanyReps ?? []).map((user) => ({
                        value: user.id,
                        label: user.fullName,
                        disabled:
                          user.id == currentValidator?.id ||
                          (selectedButton.includes('external') &&
                            user.WorkspaceUser?.Company?.id === currentCompany?.id),
                      }))}
                      onChange={(value) => onChangeCompanyRep(value)}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {isCompanyOriginator && (
              <div>
                <Title className={'validation-label-title'} level={5}>
                  {t('Comment')}
                </Title>
                <Form.Item name={'validation-comment'}>
                  <TextArea
                    className={'validation-comment'}
                    placeholder={t('Add comment here...')}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </div>
            )}

            {!isCompanyOriginator && (
              <div className='form-group'>
                <Text className={'validation-block mt-5'}>
                  <span style={{ fontWeight: 'bold' }}>{t('Finalize')}</span> {'the review process for this document'}
                </Text>
                <div className='btn-validation '>
                  <Radio.Group className='validation-radio-group' value={value} onChange={onChange}>
                    {renderButton('reject', t('Reject'), 'custom-button-reject')}
                    {renderButton('approve_with_comment', t('Approve with Comments'), 'custom-button-approve')}
                    {renderButton('approve', t('Approve'), 'custom-button-approve')}
                  </Radio.Group>
                </div>
              </div>
            )}

            <Typography.Title level={3} className='validation-label-title !mb-[10px]'>
              {t('Distribution')}
            </Typography.Title>

            {!isCompanyOriginator && (
              <EditableFormField
                isReadonly={!isCompanyOriginator}
                columns={[
                  {
                    label: '',
                    name: 'watcherIds',
                    type: 'multiple',
                    editableProps: {
                      placeholder: t('Select Users'),
                      options: mapDataToOptions(users, 'fullName'),
                    },
                  },
                ]}
              />
            )}

            {isCompanyOriginator && (
              <Form.Item name='watcherIds' rules={[{ required: false, message: '' }]}>
                <TreeSelect
                  style={{ width: '100%' }}
                  treeData={distributionOption}
                  placeholder={t('Select Users')}
                  treeDefaultExpandAll
                  multiple
                  maxTagCount='responsive'
                  filterTreeNode={(input, node) => {
                    return (node?.title as string)?.toLowerCase().includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
