import {
  DeleteOutlined,
  DoubleLeftOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Flex, Form, Input, MenuProps, Table, Tag, Tooltip, UploadFile } from 'antd';
import { RuleObject } from 'antd/es/form';
import { FormInstance } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Design } from 'model';
import { UnpublishedDesignForm, designFieldValues } from 'modules/DocumentManagement/models/Design';
import { MenuInfo } from 'rc-menu/lib/interface';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react';

type CurrentListViewProps = {
  revisionDraft: Design | null;
  setRevisionDraft: Dispatch<SetStateAction<Design | null>>;
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  drawerManagingRevision: { open: boolean; design?: Design };
  projectId: string;
  workspaceId: string;
  singleDownload: (designId?: string, filename?: string) => Promise<void>;
  newUploadedRevision: UnpublishedDesignForm | null;
  setNewUploadedRevision: Dispatch<SetStateAction<UnpublishedDesignForm | null>>;
  form: FormInstance;
  newCurrentRevision: Design | null;
  resetRevisionList: () => void;
  setDeleteList: Dispatch<SetStateAction<string[]>>;
  deleteList: string[];
  setLocalCurrentRevision: (newDesign: Design) => void;
  revisionList: Design[];
  openPDFNewTab: (design: Design) => void;
  currentRevision: UnpublishedDesignForm | null;
  loading: boolean;
  fileRegex: RegExp;
  fileRegexToString: string;
};

export default function ManageRevisionsCurrentListView(props: CurrentListViewProps) {
  const {
    loading,
    currentRevision,
    revisionDraft,
    openPDFNewTab,
    drawerManagingRevision,
    setNewUploadedRevision,
    singleDownload,
    setFileList,
    newUploadedRevision,
    form,
    newCurrentRevision,
    setDeleteList,
    deleteList,
    fileRegex,
    fileRegexToString,
  } = props;
  const emptyDesign = {
    title: '',
    Company: {
      name: '',
    },
    createdAt: '',
    id: 'draftId',
    revision: '',
    discipline: '',
    issueDate: '',
    deadlineDate: '',
    status: 'draft',
    fileExtension: '',
  };
  const deleteStyle: CSSProperties = { textDecoration: 'line-through', color: 'gray' };
  const [data, setData] = useState<Design[]>([]);

  useEffect(() => {
    if (revisionDraft?.id !== currentRevision?.id) {
      setData([
        ...(newUploadedRevision ? [emptyDesign] : []),
        ...(revisionDraft ? [revisionDraft as Design] : []),
        ...(newCurrentRevision ? [newCurrentRevision as Design] : currentRevision ? [currentRevision as Design] : []),
      ]);
    } else {
      setData([...(newUploadedRevision ? [emptyDesign] : []), ...(revisionDraft ? [revisionDraft as Design] : [])]);
    }
  }, [newCurrentRevision, drawerManagingRevision.design, newUploadedRevision, currentRevision, revisionDraft]);

  useEffect(() => {
    if (newUploadedRevision) {
      form.setFieldValue('title', newUploadedRevision.title);
      form.setFieldValue('issueDate', dayjs());
      setDeleteList((currentlist) => [...currentlist, revisionDraft?.id as string]);
    } else {
      setDeleteList((currentList) => {
        return currentList.filter((str) => str !== (revisionDraft?.id as string));
      });
    }
  }, [newUploadedRevision]);
  const items: MenuProps['items'] = [
    {
      label: t('Download'),
      key: 'download',
      icon: <DownloadOutlined />,
      danger: false,
    },
    {
      label: t('Delete Revision'),
      key: 'delete',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const regexFieldRule = () => {
    return {
      required: true,
      validator: (rule: RuleObject, value: string) => {
        return new Promise((resolve, reject) => {
          if (fileRegex.test(value)) {
            resolve('');
          } else {
            reject(`${t('File names cannot contain the following characters')}: ${fileRegexToString}`);
          }
        });
      },
    };
  };

  const handleMenuClick = async (info: MenuInfo, designTitle: string, designId: string) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'delete':
          setDeleteList((currentList) => [...currentList, designId]);
          break;
        case 'download':
          singleDownload(designId, designTitle);
          break;
      }
    }
  };
  const menuProps: MenuProps = { items };
  const columns: ColumnsType<Design> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      render: (text, record) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Form.Item name='title' className='marginless-form-item' rules={[{ required: true }, regexFieldRule]}>
            <Input placeholder={t('Enter Title')} />
          </Form.Item>
        ) : (
          <Flex justify='space-between' style={deleteList.includes(record?.id) ? deleteStyle : {}}>
            <Flex>
              <FilePdfOutlined style={record?.status === 'draft' ? { color: 'gray' } : { color: 'red' }} />
              <button className='invisibleButtonLink' onClick={() => openPDFNewTab(record)}>
                <p style={{ marginLeft: 12 }}>{text}</p>
              </button>
            </Flex>
            {record?.initialRevisionId && record?.status !== 'draft' && (
              <Tooltip title={t('This revision will be set as current')}>
                <DoubleLeftOutlined className='rotate-90' style={{ color: '#3069C4' }} />
              </Tooltip>
            )}
            {record.id === revisionDraft?.id && newUploadedRevision && (
              <Tooltip title={t('Uploading will overwrite the current unpublished revision')}>
                <WarningTwoTone twoToneColor='#fa8c16' />
              </Tooltip>
            )}
          </Flex>
        ),
    },
    {
      title: t('Revision'),
      dataIndex: 'revision',
      render: (text, record) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Form.Item name='revision' className='marginless-form-item'>
            <Input placeholder={t('Enter Revision')} />
          </Form.Item>
        ) : (
          <Flex justify='center'>
            <Tag
              color={deleteList.includes(record?.id) ? 'default' : 'blue'}
              style={deleteList.includes(record?.id) ? deleteStyle : { fontWeight: 'lighter', borderRadius: '20px' }}
              className='normal-case'
            >
              {text}
            </Tag>
          </Flex>
        ),
    },
    {
      title: t('Issue Date'),
      dataIndex: 'issueDate',
      render: (value, record) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Form.Item name='issueDate' className='marginless-form-item'>
            <DatePicker format={'DD/MM/YYYY'} />
          </Form.Item>
        ) : (
          <p style={deleteList.includes(record?.id) ? deleteStyle : {}}>
            {dayjs(dayjs(value).isValid() ? value : record.createdAt).format('DD/MM/YYYY')}
          </p>
        ),
    },
    {
      title: t('Expected Deadline'),
      dataIndex: 'deadlineDate',
      render: (value, record) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Form.Item name='deadlineDate' className='marginless-form-item'>
            <DatePicker format={'DD/MM/YYYY'} />
          </Form.Item>
        ) : (
          <p style={deleteList.includes(record?.id) ? deleteStyle : {}}>
            {dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : null}
          </p>
        ),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      render: (value, record) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Tag>{t('Unpublished')}</Tag>
        ) : (
          <Tag
            style={deleteList.includes(record?.id) ? deleteStyle : {}}
            color={designFieldValues.statusValues[value]?.color}
          >
            {t(designFieldValues.statusValues[value]?.value)}
          </Tag>
        ),
    },

    {
      align: 'right',
      dataIndex: 'id',
      fixed: 'right',
      width: '3.5em',
      render: (value, record: Design) =>
        newUploadedRevision && record.id === 'draftId' ? (
          <Flex>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                setFileList([]);
                setNewUploadedRevision(null);
              }}
            ></Button>
          </Flex>
        ) : (
          <Flex justify={'flex-end'}>
            <Dropdown
              menu={{
                ...menuProps,
                onClick: (info) => handleMenuClick(info, `${record.title}.${record.fileExtension}`, value),
              }}
              trigger={['click']}
            >
              <Button icon={<EllipsisOutlined />} type='link' style={{ color: 'black' }}></Button>
            </Dropdown>
          </Flex>
        ),
    },
  ];
  return (
    <Form form={form}>
      <Table
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined />,
        }}
        className='backgroundless-table'
        pagination={false}
        columns={columns}
        dataSource={data}
      />
    </Form>
  );
}
