import React, { Key, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Empty,
  Layout,
  message,
  Modal,
  ModalProps,
  Row,
  Space,
  Spin,
  Tree,
  TreeDataNode,
  Typography,
} from 'antd';
import { EventDataNode, TreeProps } from 'antd/es/tree';
import { DataNode } from 'antd/es/tree';
import { useTranslation } from 'react-i18next';
import ProjectWorkBreakdownStructureTreeTitle from './ProjectWorkBreakdownStructureTreeTitle';
import { WorkBreakdownStructure } from 'model';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import workBreakdownStructureService from 'services/work-breakdown-structure.services';
import { Content } from 'antd/es/layout/layout';
import ProjectWorkBreakdownStructureDefaultTree from './ProjectWorkBreakdownStructureDefaultTree';

type ProjectWorkBreakdownStructureTreeProps = {
  workBreakdownStructures: WorkBreakdownStructure[];
  workDefaultBreakdownStructures: WorkBreakdownStructure[];
  workspaceId: string;
  projectId: string;
  refreshWorkBreakdownStructures: () => void;
  isModalOpen: boolean;
  updateWorkBreakdownStructures: WorkBreakdownStructure[];
  setIsModalOpen: (value: boolean) => void;
  setUpdateWorkBreakdownStructures: (WorkBreakdownStructures: WorkBreakdownStructure[]) => void;
  isCustom: boolean;
  loading: boolean;
  order: number;
  nodeKey: string;
} & ModalProps;
export default function ProjectWorkBreakdownStructureTree(props: ProjectWorkBreakdownStructureTreeProps) {
  const {
    workBreakdownStructures,
    workspaceId,
    projectId,
    refreshWorkBreakdownStructures,
    workDefaultBreakdownStructures,
    isModalOpen,
    setIsModalOpen,
    loading,
  } = props;

  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<string[]>(['root']);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>(['root']);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [defaultTreeData, setDefaultTreeData] = useState<DataNode[]>([]);
  const [importedTree, setImportedTree] = useState<DataNode[]>([]);
  const getColorForLevel = (level: number) => {
    const levelColors = ['orange', 'pink', 'purple', 'white'];
    return levelColors[level] || 'black';
  };

  const updateNode = async (
    name: string,
    nodeId: string,
    parentId: string | null,
    newOrder: number,
    isMain: boolean
  ) => {
    try {
      const response = await workBreakdownStructureService.updateWorkBreakdownStructure(
        workspaceId,
        projectId,
        nodeId,
        {
          name: name,
          parentId,
          order: newOrder,
          isMain,
        }
      );
      if (response) {
        message.success('Node updated successfully');
        refreshWorkBreakdownStructures(); // Refresh the tree to reflect changes
      } else {
        throw new Error('Failed to update node');
      }
    } catch (error) {
      message.error(t((error as Error).message));
    }
  };

  const onDrop: TreeProps['onDrop'] = async (info) => {
    const dragNode = info.dragNode;
    const dropNode = info.node;
    const dragPosition = info.dragNode.pos.split('-');
    const dropPosition = info.node.pos.split('-');

    console.log(dragNode, dropNode);

    const isHigher = +dragPosition[dragPosition.length - 1] < +dropPosition[dropPosition.length - 1];

    const isFirstPosition = (dragNode: EventDataNode<TreeDataNode>, dropNode: EventDataNode<TreeDataNode>) => {
      return dragNode.pos.split('-').length === dropNode.pos.split('-').length + 1 && dropNode.expanded;
    };

    const isSameLevel = (dragPosition: string[], dropPosition: string[]) => {
      // dragPosition.pop();
      // dropPosition.pop();
      return (
        info.dropToGap && dragPosition.length === dropPosition.length
        // &&
        // dragPosition.join('') === dropPosition.join('')
      );
    };

    let newParentId: string | null = null;
    let newOrder: number = 0;
    let nodeName: string | null = null;

    if (isFirstPosition(dragNode, dropNode)) {
      newParentId = workBreakdownStructures.find((workBreakdownStructure) => workBreakdownStructure.id === dropNode.key)
        ?.id as string | null;
      newOrder = 1;
      nodeName = workBreakdownStructures.find((workBreakdownStructure) => workBreakdownStructure.id === dragNode.key)
        ?.name as string | null;
    } else if (isSameLevel(dragPosition, dropPosition)) {
      newParentId = workBreakdownStructures.find((workBreakdownStructure) => workBreakdownStructure.id === dropNode.key)
        ?.parentId as string | null;
      const dragParentId = workBreakdownStructures.find(
        (workBreakdownStructure) => workBreakdownStructure.id === dragNode.key
      )?.parentId as string | null;
      newOrder = newParentId === dragParentId && isHigher ? info.dropPosition : info.dropPosition + 1;
      nodeName = workBreakdownStructures.find((workBreakdownStructure) => workBreakdownStructure.id === dragNode.key)
        ?.name as string | null;
    } else {
      message.error('Cannot move to a different level');
      return;
    }

    const nodeLevel = workBreakdownStructures.find(
      (workBreakdownStructure) => workBreakdownStructure.id === dragNode.key
    )?.level as string;
    if (nodeLevel === '0') {
      newOrder = newOrder - 1;
    }

    const isMain = workBreakdownStructures.find((workBreakdownStructure) => workBreakdownStructure.id === dragNode.key)
      ?.isMain as boolean;

    // Update the node with the new parentId and order
    await updateNode(nodeName ?? '', dragNode.key.toString(), newParentId ?? null, newOrder, isMain);
  };

  const handleSubmitData = async () => {
    try {
      const response = await workBreakdownStructureService.createDefaultWorkBreakdownStructure(workspaceId, projectId);
      if (response) {
        message.success('Work breakdown structure created successfully');
      } else {
        throw new Error('Failed to create work breakdown structure');
      }
    } catch (error) {
      message.error(t((error as Error).message));
    }
    setImportedTree([]);
    setIsModalOpen(false);
    refreshWorkBreakdownStructures();
    localStorage.setItem('defaultExpandedKeys', '');
  };

  const handleSkip = async () => {
    try {
      const data = {
        name: 'General',
        order: 1,
        parentId: null,
        isMain: true,
      };
      const response = await workBreakdownStructureService.createWorkBreakdownStructure(workspaceId, projectId, data);
      if (response) {
        message.success('Work breakdown structure created successfully');
      } else {
        throw new Error('Failed to create work breakdown structure');
      }
    } catch (error) {
      message.error(t((error as Error).message));
    }
    setImportedTree([]);
    setIsModalOpen(false);
    localStorage.setItem('defaultExpandedKeys', '');
  };

  const rootNode = {
    title: (
      <ProjectWorkBreakdownStructureTreeTitle
        nodeTitle={t('')}
        nodeKey='root'
        nodeColor='transparent'
        nodeCode=''
        workspaceId={workspaceId}
        projectId={projectId}
        order={0}
        refreshWorkBreakdownStructure={refreshWorkBreakdownStructures}
        workBreakdownParent={null}
        currentLevel={-1}
        isCustom={false}
        isMain={false}
      />
    ),
    key: 'root',
  };

  useEffect(() => {
    const workBreakdownStructuresIds: string[] = [];
    workBreakdownStructures?.map((workBreakdownStructure) =>
      workBreakdownStructuresIds.push(workBreakdownStructure.id.toString())
    );
    // setExpandedKeys((prevKeys) => [...prevKeys, ...workBreakdownStructuresIds]);
    const tree = convertDataToTree(workBreakdownStructures);
    tree.unshift(rootNode);
    setTreeData(tree);
    const defaultTree = convertDefaultDataToTree(workDefaultBreakdownStructures);
    setDefaultTreeData(defaultTree);
  }, [workBreakdownStructures, workDefaultBreakdownStructures]);
  const convertDataToTree = (data: WorkBreakdownStructure[]): DataNode[] => {
    const buildTree = (
      items: WorkBreakdownStructure[],
      parentId: string | null = null,
      level: number = 0
    ): DataNode[] => {
      const tree: DataNode[] = [];
      items?.forEach((item: WorkBreakdownStructure) => {
        if (item.parentId === parentId && level <= 2) {
          const children = buildTree(items, item.id, level + 1);
          const node: DataNode = {
            title: (
              <ProjectWorkBreakdownStructureTreeTitle
                nodeTitle={t(`${item.name}`)}
                nodeKey={item.id}
                nodeCode={t(`${item.code}`) ?? ''}
                nodeColor={getColorForLevel(level)}
                workspaceId={workspaceId}
                projectId={projectId}
                order={item.order}
                refreshWorkBreakdownStructure={refreshWorkBreakdownStructures}
                workBreakdownParent={item.parentId}
                currentLevel={level}
                isCustom={item.isCustom ?? false}
                isMain={item.isMain ?? false}
              />
            ),
            key: item.id,
            children: children?.length ? children : undefined,
          };

          tree.push(node);
        }
      });
      return tree;
    };

    const workBreakdownStructuresINodes: DataNode[] = buildTree(data);
    return workBreakdownStructuresINodes;
  };
  const convertDefaultDataToTree = (data: WorkBreakdownStructure[]): DataNode[] => {
    const buildTree = (
      items: WorkBreakdownStructure[],
      parentId: string | null = null,
      level: number = 0
    ): DataNode[] => {
      const tree: DataNode[] = [];
      items?.forEach((item: WorkBreakdownStructure) => {
        if (item.parentId === parentId && level <= 2) {
          const children = buildTree(items, item.id, level + 1);
          const node: DataNode = {
            title: (
              <ProjectWorkBreakdownStructureDefaultTree
                nodeTitle={item.name}
                nodeKey={item.id}
                nodeColor={getColorForLevel(level)}
                nodeCode={item.code ?? ''}
              />
            ),
            key: item.id,
            children: children?.length ? children : undefined,
          };
          tree.push(node);
        }
      });
      return tree;
    };

    const workBreakdownStructuresINodes: DataNode[] = buildTree(data);
    return workBreakdownStructuresINodes;
  };

  useEffect(() => {
    const savedExpandedKeys = localStorage.getItem('expandedKeys');
    const savedDefaultExpandedKeys = localStorage.getItem('defaultExpandedKeys');
    const parsedExpandedKeys = savedExpandedKeys ? JSON.parse(savedExpandedKeys) : [];
    const parsedDefaultExpandedKeys = savedDefaultExpandedKeys ? JSON.parse(savedDefaultExpandedKeys) : [];

    if (workBreakdownStructures) {
      const allKeys = workBreakdownStructures.map((item) => item.id.toString());
      const commonKeys = allKeys.filter((key) => parsedExpandedKeys.includes(key));

      const keysToExpand = parsedExpandedKeys.length && commonKeys.length ? parsedExpandedKeys : ['root', ...allKeys];
      setExpandedKeys(keysToExpand);

      const tree = convertDataToTree(workBreakdownStructures);
      tree.unshift(rootNode);
      setTreeData(tree);
    }

    if (workDefaultBreakdownStructures) {
      const allDefaultKeys = workDefaultBreakdownStructures.map((item) => item.id.toString());
      const commonKeys = allDefaultKeys.filter((key) => parsedDefaultExpandedKeys.includes(key));

      const defaultKeysToExpand =
        parsedDefaultExpandedKeys.length && commonKeys.length ? parsedDefaultExpandedKeys : ['root', ...allDefaultKeys];
      setDefaultExpandedKeys(defaultKeysToExpand);

      const defaultTree = convertDefaultDataToTree(workDefaultBreakdownStructures);
      setDefaultTreeData(defaultTree);
    }
  }, [workBreakdownStructures, workDefaultBreakdownStructures, importedTree]);

  const handleExpand: TreeProps['onExpand'] = (selectedKeys: Key[]) => {
    setExpandedKeys(selectedKeys as string[]);
    localStorage.setItem('expandedKeys', JSON.stringify(selectedKeys));
  };

  const handleDefaultExpand: TreeProps['onExpand'] = (selectedKeys: Key[]) => {
    setDefaultExpandedKeys(selectedKeys as string[]);
    localStorage.setItem('defaultExpandedKeys', JSON.stringify(selectedKeys));
  };

  const onDragEnter: TreeProps['onDragEnter'] = (info) => {
    // handle drag enter event if needed
    console.log(info);
  };
  const handleImport = () => {
    if (importedTree.length === 0) {
      setImportedTree(defaultTreeData);
    }
  };

  const handleCancel = () => {
    setImportedTree([]);
    setIsModalOpen(false);
    localStorage.setItem('defaultExpandedKeys', '');
  };
  const handleCancelDefaultWBS = () => {
    setImportedTree([]);
  };

  return (
    <div className='work-breakdown-structure'>
      <div className='work-breakdown-structure-content'>
        <Alert
          className='work-breakdown-structure-alert'
          message='Only the phase marked as “Main” will be used for quality functions, though you still have the capability to specify additional phases for future use'
          type='info'
          showIcon
        />
        <div className='work-breakdown-structure-head'>
          <div className='sub-title'>{t('Edit Phases & Disciplines & Activities')}</div>
        </div>
        <Spin spinning={loading}>
          {workBreakdownStructures?.length > 0 ? (
            <Tree
              style={{ minHeight: '250px' }}
              className='work-breakdown-structure-tree'
              showLine
              defaultExpandedKeys={['root']}
              onExpand={handleExpand}
              expandedKeys={expandedKeys}
              treeData={treeData}
              blockNode={false}
              draggable={{
                icon: null,
              }}
              onDragEnter={onDragEnter}
              onDrop={onDrop}
            />
          ) : (
            <Modal
              title='Work Breakdown Structure Setting'
              centered
              open={isModalOpen}
              onCancel={handleCancel}
              width={1000}
              footer={[
                <div key='action' style={{ marginRight: '20px' }}>
                  <Button
                    key='skip'
                    onClick={handleSkip}
                    style={{
                      border: '1px dashed',
                      color: '#1677FF',
                      backgroundColor: '#E6F4FF',
                    }}
                  >
                    Skip & Start from Scratch
                  </Button>
                  <Button
                    key='ok'
                    onClick={handleSubmitData}
                    disabled={importedTree.length <= 0}
                    style={
                      importedTree.length > 0
                        ? {
                            backgroundColor: '#0591FF',
                            color: '#e6f7ff',
                          }
                        : {}
                    }
                  >
                    OK
                  </Button>
                </div>,
              ]}
            >
              <Row justify='center' align='middle' style={{ textAlign: 'center' }}>
                <Col span={24}>
                  <Typography.Text strong>{t('Your WBS is still empty!')}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text>
                    {t('You can use the default WBS proposed by Polaris by clicking the IMPORT button')}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text>{t('Otherwise you can start from scratch with your own WBS')}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  gap: '10px',
                  padding: '20px',
                  height: '280px',
                  marginBottom: '15px',
                }}
              >
                <Layout className='work-breakdown-structure-tree-box'>
                  <Content className='work-breakdown-structure-tree-defaultTree'>
                    <Tree
                      style={{ minHeight: '250px' }}
                      className='work-breakdown-structure-tree'
                      showLine
                      defaultExpandAll
                      defaultExpandedKeys={['root']}
                      onExpand={handleDefaultExpand}
                      expandedKeys={defaultExpandedKeys}
                      treeData={defaultTreeData}
                      blockNode={false}
                      draggable={{ icon: null }}
                      onDragEnter={onDragEnter}
                    />
                  </Content>
                </Layout>
                <Space direction='vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    type='primary'
                    className='work-breakdown-structure-tree-button'
                    onClick={handleImport}
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <DoubleRightOutlined /> IMPORT
                  </Button>
                  <Button
                    type='primary'
                    onClick={handleCancelDefaultWBS}
                    disabled={importedTree?.length <= 0}
                    className='work-breakdown-structure-tree-button'
                    style={{
                      backgroundColor: importedTree?.length > 0 ? '#FF4D4F' : undefined,
                      borderColor: importedTree?.length > 0 ? '#FF4D4F' : undefined,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <DoubleLeftOutlined />
                  </Button>
                </Space>
                <Layout className='work-breakdown-structure-tree-box'>
                  <Content className='work-breakdown-structure-tree-defaultTree'>
                    {importedTree.length > 0 ? (
                      <Tree
                        style={{ minHeight: '250px', width: 'auto' }}
                        className='work-breakdown-structure-tree'
                        showLine
                        defaultExpandedKeys={defaultExpandedKeys}
                        onExpand={handleDefaultExpand}
                        expandedKeys={defaultExpandedKeys}
                        treeData={importedTree}
                        blockNode={false}
                        draggable={{ icon: null }}
                        onDragEnter={onDragEnter}
                        onDrop={onDrop}
                      />
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </Content>
                </Layout>
              </Row>
            </Modal>
          )}
        </Spin>
      </div>
    </div>
  );
}
