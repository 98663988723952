import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Flex, Input, Layout, Typography } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { selectSelectedProject } from 'store/my-projects.slice';
import { useSearchDebounce } from 'utils';
import {
  useMyWorkspaceCompanies,
  useMyWorkspaceCompaniesParams,
  useMyWorkspaceUsers,
  useMyWorkspaceUsersParams,
  useOtherProjects,
  useOtherProjectsParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectWorkPackages,
  useProjectWorkPackagesCount,
  useProjectWorkPackagesParams,
} from 'hooks';
import { ProjectContent, ProjectWorkPackageListView, CreateOrUpdatePWP } from 'components';
import useAuthContext from 'store/auth-context';
import { useParams } from 'react-router-dom';

const { Title } = Typography;
const { Content } = Layout;
export default function ProjectWorkPackage() {
  const params = useParams();
  const projectId = params.projectId as string;
  const [otherProjectsParams] = useOtherProjectsParams();
  const selectedProject = useAppSelector(selectSelectedProject);
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);

  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [selectedPWP, setSelectedPWP] = useState<string | null>(null);

  const [, setSearchValue] = useSearchDebounce();

  const { profile } = useAuthContext();
  const [projectWorkPackageParams, onProjectWorkPackageParamsChange] = useProjectWorkPackagesParams({
    projectId: projectId as string,
    workspaceId,
    include: 'Company|Project',
    orderBy: 'createdAt,desc',
  });
  const [projectWorkPackages, , refreshProjectWorkPackages] = useProjectWorkPackages(projectWorkPackageParams);
  const [projectWorkPackagesCount] = useProjectWorkPackagesCount(projectWorkPackageParams);
  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [companies] = useProjectCompanies(companyParams);
  const [workspaceCompanyParams] = useMyWorkspaceCompaniesParams({
    workspaceId,
    orderBy: 'name',
  });
  const [workspaceCompanies] = useMyWorkspaceCompanies(workspaceCompanyParams);
  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(queryUser);
  const [queryWorkspaceUser] = useMyWorkspaceUsersParams({ projectId, workspaceId });
  const [workspaceUsers] = useMyWorkspaceUsers(queryWorkspaceUser);

  const handleEditForm = (id: string) => {
    setSelectedPWP(id);
    setIsFormOpen(true);
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };

  const onFinish = () => {
    setSelectedPWP(null);
  };

  return (
    <ProjectContent>
      <Content className='project-settings-page user-settings'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Title>{t('Work Packages Management')}</Title>
            <div className='sub-title'>
              {t('View and manage work packages in')}
              <b className='primary-color ml-1'>{selectedProject?.name}</b>
            </div>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content className='settings-content'>
          {isFormOpen !== null && (
            <CreateOrUpdatePWP
              selectedPWP={selectedPWP}
              isOpenForm={isFormOpen}
              workspaceId={workspaceId}
              projectId={projectId}
              profile={profile}
              companies={companies}
              workspaceCompanies={workspaceCompanies}
              users={users}
              workspaceUsers={workspaceUsers}
              setIsOpenForm={setIsFormOpen}
              onFinish={onFinish}
              refreshProjectWorkPackages={refreshProjectWorkPackages}
            />
          )}
          <Flex className='mb-4' justify='flex-end' align='flex-end'>
            <Flex>
              <Input
                name='search-button'
                className='search-button mr-3'
                placeholder={t('Type to search')}
                onBlur={(e) => handleInputBlur(e.target.value)}
                onFocus={handleSearchIconClick}
                style={{
                  width: showSearchInput ? '250px' : '0px',
                  border: showSearchInput ? '1px solid #d9d9d9' : 'none',
                }}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchValue(e.target.value)}
                allowClear
              />
              <Button
                onClick={() => {
                  console.log('on filter');
                }}
                icon={<FilterOutlined />}
              >
                {t('Filter')}
              </Button>
              <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsFormOpen(true)}>
                {t('Add Work Package')}
              </Button>
            </Flex>
          </Flex>
          {/* <ShopDrawingsStatistic kpiData={kpiData} /> */}
          <ProjectWorkPackageListView
            workspaceId={workspaceId}
            projectId={projectId}
            projectWorkPackages={projectWorkPackages}
            projectWorkPackagesCount={projectWorkPackagesCount}
            loading={false}
            isFormOpen={isFormOpen}
            projectWorkPackageParams={projectWorkPackageParams}
            users={users}
            editForm={(value: string) => handleEditForm(value)}
            onFilter={(value) => {
              onProjectWorkPackageParamsChange(value);
            }}
          />
        </Content>
      </Content>
    </ProjectContent>
  );
}
