import { CSSProperties, useEffect, useState } from 'react';
import { Flex, Collapse, CollapseProps, theme } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import { KpiStatistic } from 'components/common';
import { kpiData } from 'types';
import { MOM_STATUSES } from 'utils/contants';

type ProjectMinutesOfMeetingStatisticProps = {
  kpiData: kpiData;
  handleFilterByKPI: (value: string) => void;
  isResetKPI: boolean;
  setResetKPI: (value: boolean) => void;
};

export default function ProjectMinutesOfMeetingStatistic(props: ProjectMinutesOfMeetingStatisticProps) {
  const { kpiData, handleFilterByKPI, isResetKPI, setResetKPI } = props;
  const { token } = theme.useToken();
  const [activeKpi, setActiveKpi] = useState<string | null>(null);

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const onApplyFilter = (code: string) => {
    if (activeKpi === code) {
      setActiveKpi(null);
      handleFilterByKPI('');
    } else {
      setActiveKpi(code);
      handleFilterByKPI(code);
    }
  };
  useEffect(() => {
    if (isResetKPI) {
      setActiveKpi(null);
      setResetKPI(false);
    }
  }, [isResetKPI]);
  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      children: (
        <Flex wrap='wrap' gap={20}>
          <KpiStatistic
            onClick={() => onApplyFilter(MOM_STATUSES.DRAFT)}
            status='draft'
            value={kpiData?.DR}
            title='Draft'
            isActive={activeKpi === MOM_STATUSES.DRAFT}
          />
          <KpiStatistic
            onClick={() => onApplyFilter(MOM_STATUSES.READY_FOR_REVIEW)}
            status='review-in-progress'
            value={kpiData?.RIP}
            title='Ready for Review'
            isActive={activeKpi === MOM_STATUSES.READY_FOR_REVIEW}
          />
          <KpiStatistic
            onClick={() => onApplyFilter(MOM_STATUSES.ISSUED)}
            status='issued'
            value={kpiData?.ISD}
            title='Issued'
            isActive={activeKpi === MOM_STATUSES.ISSUED}
          />
        </Flex>
      ),
      style: panelStyle,
    },
  ];

  return (
    <Collapse
      size={'small'}
      className='inspection-collapse ma-0'
      ghost
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretUpOutlined
          rotate={isActive ? 0 : 180}
          style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '20px', width: '20px' }}
        />
      )}
      expandIconPosition='end'
      style={{ background: 'transparent' }}
      items={getItems(panelStyle)}
    />
  );
}
