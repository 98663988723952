import WorkBreakdownStructure from 'model/WorkBreakdownStructure';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const workBreakdownStructureService = {
  getProjectWorkBreakdownStructures: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<WorkBreakdownStructure>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-breakdown-structures`,
      {
        params: query,
      }
    );
  },
  createWorkBreakdownStructure: async (
    workspaceId: string,
    projectId: string,
    data: { name: string; parentId?: string | null }
  ) => {
    return request.post<PaginationData<WorkBreakdownStructure>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-breakdown-structures`,
      data
    );
  },
  updateWorkBreakdownStructure: async (
    workspaceId: string,
    projectId: string,
    workBreakdownStructureId: string,
    data: {
      name?: string;
      parentId?: string | null;
      order?: number;
      isMain?: boolean;
      itpOrder?: string | number | null;
    }
  ) => {
    return request.put<PaginationData<WorkBreakdownStructure>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-breakdown-structures/${workBreakdownStructureId}`,
      data
    );
  },

  deleteWorkBreakdownStructure: async (workspaceId: string, projectId: string, workBreakdownStructureId: string) => {
    return request.delete<PaginationData<WorkBreakdownStructure>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-breakdown-structures/${workBreakdownStructureId}`
    );
  },
  createDefaultWorkBreakdownStructure: async (workspaceId: string, projectId: string) => {
    return request.post<PaginationData<WorkBreakdownStructure[]>>(
      `/workspaces/${workspaceId}/projects/${projectId}/add-default-work-breakdown-structures`
    );
  },
  getProjectDefaultWorkBreakdownStructures: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<WorkBreakdownStructure>>(
      `/workspaces/${workspaceId}/projects/${projectId}/work-breakdown-structures?defaultWbs=true`,
      {
        params: query,
      }
    );
  },
};

export default workBreakdownStructureService;
