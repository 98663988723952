import { EllipsisOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Layout, MenuProps, Table, TableColumnsType, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { AnimatedPage, TablePagination } from 'components';
import AttachmentRender from 'components/common/AttachmentRender';
import CustomTooltip from 'components/common/CustomTooltip';
import { TablePaginationIssueParams } from 'components/common/TablePagination';
import UserCompany from 'components/common/UserCompany';
import dayjs from 'dayjs';
import { BaseIssue, BaseIssueWatcher, Company, User } from 'model';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'types';
import { TRA_PRIORITIES_COLOR, NUMBERING_DIGITS } from 'utils/contants';

type ListViewProps = {
  users: User[];
  profile: User | null;
  module: string;
  projectId: string;
  workspaceId: string;
  dataList: BaseIssue[];
  dataListCount?: number;
  dataListParams: QueryParams;
  companies: Company[];
  loading?: boolean;
  onEditIssue: (value: string) => void;
  refreshBaseIssues: () => void;
  refreshUser: () => void;
  onFilter: (
    value: TablePaginationIssueParams & {
      orderBy?: string;
    }
  ) => void;
};
type PriorityCode = 'STANDARD' | 'CONFIDENTIAL' | 'IMPORTANT' | 'URGENT';

export default function ProjectDocumentsTransmissionListViewIncoming(props: ListViewProps) {
  const { users, loading, dataList, dataListCount, dataListParams, onEditIssue, onFilter } = props;
  const { t } = useTranslation();
  const [dateFormat] = useState<string>('YYYY-MM-DD');
  const [locale] = useState<string>('en');

  const handleMenuClick = (info: MenuInfo, docTransmissionId: string) => {
    const { key } = info;

    if (key) {
      switch (key) {
        case 'view':
          onEditIssue(docTransmissionId);
          break;
      }
    }
  };
  function numberingPadding(inputString: string) {
    const parts = inputString.split('-');
    const lastPart = parts.pop(); // Extract the last part
    if (!lastPart) return inputString;
    const paddedLastPart = lastPart.padStart(NUMBERING_DIGITS, '0'); // Pad the last part with zeros
    parts.push(paddedLastPart); // Push the padded last part back to the array
    return parts.join('-'); // Join all parts back together
  }

  const items: MenuProps['items'] = [{ label: t('View'), key: 'view', icon: <EyeOutlined />, danger: false }];
  const menuProps: MenuProps = { items };
  const columns: TableColumnsType<BaseIssue> = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'DocumentTransmission',
      key: 'DocumentTransmission.dtNumber',
      width: '50px',
      className: 'dt-number-column',
      onCell: () => {
        return {
          style: {
            maxWidth: '200px',
          },
        };
      },
      render: (DocumentTransmission) => {
        return (
          <CustomTooltip
            title={DocumentTransmission?.dtNumber ? numberingPadding(DocumentTransmission?.dtNumber) : ''}
          />
        );
      },
    },
    {
      sorter: true,
      title: t('Subject'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name) => {
        return <CustomTooltip title={name} />;
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
    },
    {
      title: t('Priority'),
      dataIndex: 'DocumentTransmission',
      key: 'DocumentTransmission.IssuePriority.name',
      render: (DocumentTransmission) => {
        const color = DocumentTransmission?.IssuePriority?.code as PriorityCode;
        return (
          <span style={{ color: TRA_PRIORITIES_COLOR[color] || 'defaultColor' }}>
            {DocumentTransmission?.IssuePriority?.name}{' '}
          </span>
        );
      },
      width: 100,
    },
    {
      // sorter: true,
      title: t('Issued By'),
      dataIndex: 'originatorId',
      key: 'originatorId',
      className: 'dt-company-column',
      render: (originatorId) => {
        const user = (users || []).find((user) => user.id === originatorId);
        return (
          <UserCompany
            data={{
              companyName: user?.WorkspaceUser?.Company?.name,
              userName: user?.fullName,
              color: 'default',
            }}
          />
        );
      },
    },
    {
      title: t('Distribution'),
      dataIndex: 'BaseIssueWatchers',
      key: 'BaseIssueWatchers',
      className: 'dt-company-column',
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
      render: (BaseIssueWatchers: BaseIssueWatcher[]) => {
        const distributions = BaseIssueWatchers?.filter((distribution) => distribution.role === 'distribution').map(
          (distribution) => {
            const user = users?.find((user) => user.id === distribution.watcherId);
            return {
              companyName: user?.WorkspaceUser?.Company?.name || '',
              userName: user?.fullName || '',
              color: 'default',
            };
          }
        );
        return <UserCompany data={distributions} />;
      },
    },
    {
      sorter: true,
      title: t('Received Date'),
      dataIndex: 'createdAt',
      width: 100,
      key: 'createdAt',
      render: (createdAt) => (
        <span>{createdAt ? dayjs(createdAt).locale(locale).format(dateFormat).toString() : ''}</span>
      ),
    },
    {
      align: 'center',
      title: t('Transmittal Letter'),
      dataIndex: 'Letter',
      width: 50,
      key: 'Letter',
      render: () => {
        return (
          <Flex justify='center' align='center'>
            <Button
              type='link'
              icon={<FilePdfOutlined />}
              className='ml-0 pa-0 d-flex'
              style={{ height: 'auto' }}
            ></Button>
          </Flex>
        );
      },
    },
    {
      align: 'right',
      dataIndex: 'id',
      key: 'actions',
      width: 20,
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (value) => (
        <Flex justify={'flex-end'}>
          <Dropdown
            menu={{
              ...menuProps,
              onClick: (info) => handleMenuClick(info, value),
            }}
            trigger={['click']}
          >
            <EllipsisOutlined className='icon-ellipse' />
          </Dropdown>
        </Flex>
      ),
    },
  ];
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BaseIssue>
  ) => {
    if (sorter) {
      const sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        if (sorter.columnKey === 'IssueStatus') {
          orderBy = `IssueStatus.orderNumber,${sorterOrder}`;
        } else {
          orderBy = `${sorter.columnKey},${sorterOrder}`;
        }
      }
      onFilter({ orderBy });
    }
  };

  return (
    <Layout.Content className='doc-transmission-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={dataListParams}
        issuesCount={dataListCount}
      />
      <AnimatedPage>
        <Table
          className='doc-transmission-table my-4 doc-transmission-table'
          columns={columns}
          expandable={{
            rowExpandable: (record) => {
              const attachments = record?.DocumentTransmission?.DtAttachment;
              return !!(attachments && attachments.some((opt) => opt.name));
            },
            expandedRowRender: (record) => {
              const attachments = record?.DocumentTransmission?.DtAttachment;
              if (!attachments || attachments.length === 0) return null;

              return (
                <Flex className='doc-transmission-expandable' justify='flex-start'>
                  {attachments.map((opt, idx) => opt.name && <AttachmentRender key={idx} name={opt.name} />)}
                </Flex>
              );
            },
          }}
          rowKey={(row) => row.id}
          dataSource={dataList}
          loading={loading}
          pagination={false}
          scroll={{ x: 'max-content' }}
          onChange={(pagination, filters, sorter) =>
            handleTableChange(pagination, filters, sorter as SorterResult<BaseIssue>)
          }
          onRow={(record) => ({
            onClick: () => onEditIssue(record.id),
          })}
          tableLayout='auto'
        />
      </AnimatedPage>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={dataListParams}
        issuesCount={dataListCount}
      />
    </Layout.Content>
  );
}
