const removeExcessMozillaAndVersion: RegExp = /^mozilla\/\d\.\d\W/;
const browserPattern: RegExp = /(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g;
const engineAndVersionPattern: RegExp = /^(ver|cri|gec)/;
const brandList: string[] = ['chrome', 'opera', 'safari', 'edge', 'firefox'];
const unknown: string = 'Unknown';
const empty: string = '';
const { isArray } = Array;

let userAgentData = window.navigator.userAgent;
let userAgent = window.navigator.userAgent;

const mobiles = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
  android: /android/,
};

const desktops = {
  windows: /win/,
  mac: /macintosh/,
  linux: /linux/,
};

const detectPlatform = (
  customUserAgent?: string,
  customUserAgentData?: string
): { os: string; browser: string; version: string } | undefined => {
  // Use a provided UA string instead of the browser's UA
  userAgent = typeof customUserAgent === 'string' ? customUserAgent : userAgent;

  // Use a provided UA data string instead of the browser's UA data
  userAgentData = typeof customUserAgentData === 'string' ? JSON.parse(customUserAgentData) : userAgentData;

  if (userAgent) {
    const ua: string = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty);

    // Determine the operating system.
    const mobileOS = Object.keys(mobiles).find((os) => {
      return mobiles[os as keyof typeof mobiles].test(ua) && window.navigator.maxTouchPoints! >= 1;
    });
    const desktopOS = Object.keys(desktops).find((os) => desktops[os as keyof typeof desktops].test(ua));
    const os: string = mobileOS || desktopOS || unknown;

    // Extract browser and version information.
    const browserTest: RegExpMatchArray | null = ua.match(browserPattern);
    const versionRegex: RegExp = /version\/(\d+(\.\d+)*)/;
    const safariVersion: RegExpMatchArray | null = ua.match(versionRegex);
    const saVersion: string | null = isArray(safariVersion) ? safariVersion[1] : null;
    const browserOffset: number | undefined | null =
      browserTest && (browserTest.length > 2 && !engineAndVersionPattern.test(browserTest[1]) ? 1 : 0);
    const browserResult: string[] | undefined | null =
      browserTest && browserTest[browserTest.length - 1 - (browserOffset || 0)].split('/');
    const browser: string | undefined = (browserResult && browserResult[0]) || unknown;
    const version: string = saVersion ? saVersion : (browserResult && browserResult[1]) || unknown;

    return { os, browser, version };
  } else if (userAgentData) {
    const os: string = (userAgentData as unknown as { platform: string }).platform.toLowerCase();
    let platformData: { browser: string; version: string } | undefined;

    // Extract platform brand and version information.
    for (const agentBrand of (userAgentData as unknown as { brands: Array<{ brand: string; version: string }> })
      .brands) {
      const agentBrandEntry = agentBrand.brand.toLowerCase();
      const foundBrand: string | undefined = brandList.find((brand) => {
        if (agentBrandEntry.includes(brand)) {
          return brand;
        }
        return null;
      });
      if (foundBrand) {
        platformData = { browser: foundBrand, version: agentBrand.version };
        break;
      }
    }
    const brandVersionData: { browser: string; version: string } = platformData || {
      browser: unknown,
      version: unknown,
    };
    return { os, ...brandVersionData };
  }
};

export default detectPlatform;
