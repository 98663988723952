import { lazy } from 'react';
import { AppRouteType } from 'types';
const DesignRegister = lazy(() => import('modules/DocumentManagement/pages/DesignRegister'));

export const documentManagementRoutes: AppRouteType[] = [
  {
    name: 'Design Register',
    path: '/designs',
    component: DesignRegister,
  },
];
