import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  TimeRangePickerProps,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchSelect } from 'components/common';
import { Company, User } from 'model';
import { useState, useMemo, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { parseQueryParams, jsonToArray } from 'utils';
import { QueryParams } from 'types';

type FilterFormProps = {
  companies: Company[];
  users: User[];
  userCompanyId?: string;
  isSafetyPage: boolean;
  handleFilter: (value: QueryParams) => void;
  isOwnerCompany: boolean;
};
export default function FilterForm(props: FilterFormProps) {
  const { companies, handleFilter, users, userCompanyId, isOwnerCompany, isSafetyPage } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [groupIssues, setGroupIssues] = useState(isOwnerCompany ? 'allIssues' : 'companyIssues');
  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [selectedCompanyId, users]);
  useEffect(() => {
    setDisableGroup(groupIssues !== 'allIssues');
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  }, [groupIssues]);
  useEffect(() => {
    const formattedParams: Record<string, string[] | string> = {};
    const params = parseQueryParams(window.location.search);

    if (params['plannedStartDate[gte]']) {
      formattedParams['plannedStartDate'] = [
        dayjs((params['plannedStartDate[gte]'] as string).replace('+', ' ')) as unknown as string,
      ];
    }
    if (params['plannedStartDate[lte]']) {
      formattedParams['plannedStartDate'] = [
        ...formattedParams['plannedStartDate'],
        dayjs((params['plannedStartDate[lte]'] as string).replace('+', ' ')) as unknown as string,
      ];
    }
    if (params['assigneeId[]']) {
      formattedParams['assigneeId'] = jsonToArray(params['assigneeId[]'] as string);
    }
    if (params['companyId[]']) {
      const companyId = jsonToArray(params['companyId[]'] as string)[0];
      if (companyId !== userCompanyId) formattedParams['companyId'] = companyId;
      else {
        if (formattedParams['assigneeId']) {
          formattedParams['companyId'] = companyId;
        } else {
          formattedParams['groupIssues'] = 'companyIssues';
          setGroupIssues('companyIssues');
        }
      }
    }
    if (params['profileId']) {
      formattedParams['groupIssues'] = 'myIssues';
      setGroupIssues('myIssues');
    }
    if (params['watcherId']) {
      formattedParams['groupIssues'] = 'watchedIssues';
      setGroupIssues('watchedIssues');
    }
    form.setFieldsValue(formattedParams);
  }, [userCompanyId]);

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };
  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = users?.find((user) => user.id === assigneeId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setGroupIssues(e.target.value);
    setDisableGroup(e.target.value !== 'allIssues');
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };
  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    handleFilter(values);
  };
  const onResetFilter = () => {
    form.resetFields();
    setGroupIssues(isOwnerCompany ? 'allIssues' : 'companyIssues');
    setDisableGroup(!isOwnerCompany);
    handleFilter({});
  };

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    } else {
      console.log('Clear');
    }
  };

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs()] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <Form
      labelCol={{ span: 24 }}
      layout='vertical'
      form={form}
      className='filter-form'
      initialValues={{ groupIssues: isOwnerCompany ? 'allIssues' : 'companyIssues' }}
    >
      <Typography.Title level={2}>{t('Dashboard parameters')}</Typography.Title>
      <Row gutter={16} align='stretch' className='my-5'>
        <Col>
          <Form.Item className='filter-form__item' name='groupIssues' initialValue={groupIssues}>
            <Radio.Group onChange={onChangeGroupIssues} value={groupIssues} defaultValue={groupIssues}>
              <Space direction='vertical'>
                <Radio value='allIssues' disabled={!isOwnerCompany}>
                  {isSafetyPage ? t('All inspections') : t('All observations')}
                </Radio>
                <Radio value='myIssues'>{isSafetyPage ? t('My inspections') : t('My observations')}</Radio>
                <Radio value='companyIssues'>
                  {isSafetyPage ? t('My company inspections') : t('My company observations')}
                </Radio>
                <Radio value='watchedIssues'>
                  {isSafetyPage ? t('Watched inspections') : t('Watched observations')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align='stretch' className='my-5'>
        <Col span={24}>
          <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company assignee')}>
            <SearchSelect
              disabled={isDisableGroup}
              allowClear
              placeholder={t('Select')}
              onChange={onChangeCompany}
              options={companies?.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
            />
          </Form.Item>
          <Form.Item className='filter-form__item mb-0' name='assigneeId' label={t('Assignee')}>
            <SearchSelect
              disabled={isDisableGroup}
              allowClear
              placeholder={t('Select')}
              onChange={onChangeAssignee}
              options={assigneeFilter?.map((option) => ({
                value: option.id,
                label: option.fullName,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align='bottom' className='mb-5'>
        <Col span={24}>
          <Form.Item
            className='filter-form__item mb-3'
            name='plannedStartDate'
            label={isSafetyPage ? t('Inspection date range') : t('Observation date range')}
          >
            <DatePicker.RangePicker
              placeholder={[t('From date'), t('To date')]}
              presets={rangePresets}
              onChange={onRangeChange}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Flex justify='end' className='my-5'>
        <Button onClick={onResetFilter} className='px-4'>
          {t('Reset')}
        </Button>
        <Button onClick={onApplyFilter} className='px-4' type='primary'>
          {t('Update')}
        </Button>
      </Flex>
    </Form>
  );
}
