import { IssueHistory, User } from 'model';
import CommentItem from './CommentItem';

type CreateCommentProps = {
  isClosedStatus: boolean;
  users: User[];
  issueHistory: IssueHistory[];
  isLoading: boolean;
  module: string;
};

export default function CreateComment(props: CreateCommentProps) {
  const { isClosedStatus, users, issueHistory, isLoading, module } = props;

  return (
    <div className='comment'>
      <CommentItem
        isClosedStatus={isClosedStatus}
        users={users}
        issueHistory={issueHistory}
        isLoading={isLoading}
        module={module}
      />
    </div>
  );
}
