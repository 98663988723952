import { Avatar, Flex, Select, Typography } from 'antd';
import React from 'react';
import AvatarItem from './pre-signed-url-items/AvatarItem';

type UserOption = {
  id?: string;
  url: string;
  name: string;
  companyName: string;
};

type SearchUserCustomProps = {
  options: UserOption[];
  onChange: (userId: string) => void;
};

const SearchUserCustom = (props: SearchUserCustomProps) => {
  return (
    <div className='search-user-custom'>
      <Select
        className='select-box'
        optionFilterProp='label'
        showSearch
        onChange={props.onChange}
        optionLabelProp='label'
        dropdownStyle={{
          maxHeight: 150,
          overflow: 'auto',
        }}
      >
        {props.options.map((option) => {
          return (
            <Select.Option key={option.id} value={option.id} label={option.name}>
              <Flex align='center' gap={5}>
                {option?.url ? (
                  <AvatarItem size={'large'} style={{ width: 32, height: 32 }} src={option.url} />
                ) : (
                  <Avatar style={{ width: 34, height: 34 }}>{option?.name?.substring(0, 1)}</Avatar>
                )}
                <Flex vertical>
                  <Typography.Text className='username'>{option.name}</Typography.Text>
                  <Typography.Text className='company-name'>{option.companyName}</Typography.Text>
                </Flex>
              </Flex>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SearchUserCustom;
