import React, { LazyExoticComponent, ReactNode } from 'react';
import { USER_STATUSES } from 'utils/contants';
export * from './safety-ptw';
export * from './project';
export type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface DynamicObject<T> {
  [key: string]: T;
}

export interface AppRouteType {
  name: string;
  path: string;
  auth?: boolean;
  component: LazyExoticComponent<() => JSX.Element | null>;
  roles?: number;
  isPublic?: boolean;
  showHeader?: boolean;
  sidebar?: 'workspace-settings' | 'project-settings' | 'void' | 'notification';
  children?: ReactNode;
}

export type PaginationData<M> = { count: number; rows: M[]; kpiData: Record<string, number> };

type DynamicFields = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type QueryParams = {
  search?: string;
  page?: string | number;
  limit?: string | number | 'unlimited';
  include?: string;
  orderBy?: string;
  subQuery?: boolean;
  'plannedStartDate[gte]'?: string;
  'plannedStartDate[lte]'?: string;
  issueTypeId?: string;
  companyOriginatorId?: string;
  originatorId?: string;
  filterType?: string;
  'workPackageId[]'?: string;
  'issueDisciplineId[]'?: string;
  'companyId[]'?: string;
  'assigneeId[]'?: string;
  'issuePriorityId[]'?: string;
  'issueStatusId[]'?: string;
  'zoneId[]'?: string;
  watcherId?: string;
  profileId?: string;
  searchBy?: string;
  searchKey?: string;
  moduleFunction?: string;
} & DynamicFields;

export type UserStatus = (typeof USER_STATUSES)[keyof typeof USER_STATUSES];

export type UomTypes = 'CURRENCY' | 'MANPOWER_UNITS' | 'MEASUREMENT' | 'MEASURING_SYSTEM' | 'WORKING_DAY';

export type ModalState = boolean | null;

export type PointCoordinate = number[];
export type RectangleCoordinate = number[][][];
export type LineStringCoordinate = number[][];
export type CircleCoordinate = number[];
export type Coordinate = PointCoordinate | RectangleCoordinate | LineStringCoordinate | CircleCoordinate;

export type Geometry = {
  type: 'Polygon' | 'MultiPolygon' | 'Point' | 'MultiPoint' | 'LineString' | 'MultiLineString';
  radius?: number;
  coordinates: Coordinate;
};
export interface LayerData {
  id?: string;
  type: 'Feature';
  properties?: {
    color?: string | undefined;
    weight?: number | undefined;
  };
  geometry?: Geometry;
}

export enum LayerTypes {
  MARKER = 'marker',
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
  PAINT = 'paint',
}

export type TableRowsSelected = { [key: string]: React.Key[] };

export type BaseIssueKpi = {
  draft: number;
  reviewInProgress: number;
  reviewInProgressOverdue: number;
  approved: number;
  approvedWithCm: number;
  rejected: number;
  goodPractices: number;
  closureTimeInDays: number;
};
export type ResponseBaseIssueKpis = {
  [key: string]: BaseIssueKpi;
};

export type IssueKpi = {
  draft: number;
  open: number;
  openOverdue: number;
  readyForReview: number;
  readyForReviewOverdue: number;
  closed: number;
  goodPractices: number;
  closureTimeInDays: number;
};

export type ResponseIssueKpis = {
  [key: string]: {
    QO: IssueKpi;
    QNC: IssueKpi;
    GP: IssueKpi;
  };
};
