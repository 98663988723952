import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { Dropdown, Flex, Spin, Tag, TagProps, TooltipProps } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import { SorterResult, TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISSUE_STATUSES } from 'utils/contants';
import CustomTooltip from './CustomTooltip';
import LabelTag, { LabelTagProps } from './LabelTag';
import TablePagination, { TablePaginationProps } from './TablePagination';
import UserCompany, { UserCompanyData } from './UserCompany';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const actionKey = ['edit', 'delete', 'duplicate'];

export enum EDynamicTableActionRow {
  Edit = 'edit',
  Delete = 'delete',
  SelectedRow = 'selected-row',
  Duplicate = 'duplicate',
}

export type StatusData = { code?: string; name?: string; color?: TagProps['color'] };
export type InspectorsRepliedWithStatus = {
  inspectorsReplied: {
    'review-in-progress'?: number;
    approved?: number;
    rejected?: number;
  };
  status: StatusData;
};
export type TooltipData = {
  title?: string;
  showLockIcon?: boolean;
};
export type PriorityData = {
  iconUrl?: string;
};

type ActionProps<T> = {
  label: string;
  key: (typeof actionKey)[number];
  isDisabled?: (record: T) => boolean;
};

export type DynamicTableDataSource<T> = T & { id?: string; name?: TooltipData };

export type DynamicTableColumn<T> = {
  sortKey?: string;
  component?:
    | {
        type: 'status';
        className?: string;
      }
    | {
        type: 'status-validation';
      }
    | {
        type: 'date';
      }
    | {
        type: 'datetime';
      }
    | {
        type: 'user-company';
      }
    | {
        type: 'action';
        props: ActionProps<T>[];
      }
    | {
        type: 'tooltip';
        props?: TooltipProps;
      }
    | { type: 'tag-list'; props?: Omit<LabelTagProps, 'label' | 'tagName'> }
    | { type: 'priority' };
};

export type CustomColumnType<T> = ColumnType<DynamicTableDataSource<T>> & DynamicTableColumn<T>;

export type DynamicTableProps<T> = {
  columns: CustomColumnType<T>[];
  onRowClick?: (record: DynamicTableDataSource<T>) => void;
  pagination?: TablePaginationProps & {
    showTop?: boolean;
  };
  module?: 'Quality' | 'Safety' | 'Toolbox' | 'Construction';
  onActionClick?: (type: string, record: DynamicTableDataSource<T>) => void;
  dataSource?: DynamicTableDataSource<T>[];
  isLoading?: boolean;
  onSort?: (sortKey?: string) => void;
  hideCheckBox?: boolean;
  bordered?: boolean;
  setSelectedRowKeys?: (value: React.Key[]) => void;
  rowKeys?: React.Key[];
};

const DynamicTable = <T extends object>(props: DynamicTableProps<T>) => {
  const { rowKeys = [] } = props;
  const { t } = useTranslation();

  const getActionMenu = (actionMenu: ActionProps<T>[], record: T) => {
    return actionMenu.map((menu) => {
      const { key, label, isDisabled } = menu;
      switch (key) {
        case EDynamicTableActionRow.Edit:
          return {
            label: label,
            key: EDynamicTableActionRow.Edit,
            icon: <EditOutlined />,
            danger: false,
            disabled: isDisabled ? isDisabled(record) : false,
          };
        case EDynamicTableActionRow.Delete:
          return {
            label: label,
            key: EDynamicTableActionRow.Delete,
            icon: <CloseCircleOutlined />,
            danger: true,
            disabled: isDisabled ? isDisabled(record) : false,
          };
        case EDynamicTableActionRow.Duplicate:
          return {
            label: label,
            key: EDynamicTableActionRow.Duplicate,
            icon: <CopyOutlined />,
            disabled: isDisabled ? isDisabled(record) : false,
          };
        default:
          return null;
      }
    });
  };

  const getComponent = (data: T[keyof T], column: CustomColumnType<T>, record: DynamicTableDataSource<T>) => {
    let result = null;
    let customData;

    if (!column) return result;

    const { component } = column;
    switch (component?.type) {
      case 'status': {
        customData = data as StatusData;
        const code = customData?.code?.includes('_') ? customData?.code?.split('_')[1] : customData.code;
        result = (
          <div className='status-box'>
            <Tag className={`${!customData?.color ? code?.toLowerCase() : ''}`} color={customData?.color}>
              {customData.name}
              {code === ISSUE_STATUSES.OPEN_START && <FieldTimeOutlined className='ml-1' />}
              {code === ISSUE_STATUSES.CLOSED_END && <IssuesCloseOutlined className='ml-1' />}
            </Tag>
          </div>
        );
        break;
      }
      case 'status-validation': {
        customData = data as InspectorsRepliedWithStatus;
        const status = customData.status;
        const code = status?.code?.includes('_') ? status?.code?.split('_')[1] : status.code;
        const inspectorsReplied = customData.inspectorsReplied;
        result = (
          <>
            <Flex vertical>
              <div className='status-box'>
                <Tag className={`${!status?.color ? code?.toLowerCase() : ''}`} color={status?.color}>
                  {status.name}
                  {code === ISSUE_STATUSES.OPEN_START && <FieldTimeOutlined className='ml-1' />}
                  {code === ISSUE_STATUSES.CLOSED_END && <IssuesCloseOutlined className='ml-1' />}
                </Tag>
              </div>
              {code !== ISSUE_STATUSES.DRAFT && (
                <Flex className={`mt-2 status-count`}>
                  <Tag className={`rip`}>{inspectorsReplied['review-in-progress']}</Tag>
                  <Tag className={`ap`}>{inspectorsReplied['approved']}</Tag>
                  <Tag className={`re`}>{inspectorsReplied['rejected']}</Tag>
                </Flex>
              )}
            </Flex>
          </>
        );
        break;
      }
      case 'user-company':
        customData = data as UserCompanyData;
        result = <UserCompany data={customData} />;
        break;
      case 'date': {
        customData = data as string;
        result = <span>{customData && dayjs(customData).locale('en').format('YYYY-MM-DD').toString()}</span>;
        break;
      }
      case 'datetime': {
        if (!data) break;
        customData = data as string;
        const dateResult = dayjs(customData).locale('en');
        result = (
          <Flex vertical>
            <span>{dateResult.format('YYYY-MM-DD').toString()}</span>
            <span className='text-color-secondary'>{dateResult.format('hh:mm A')}</span>
          </Flex>
        );
        break;
      }
      case 'action':
        result = (
          <Flex justify={'flex-end'}>
            <Dropdown
              menu={{
                items: getActionMenu(component.props, record),
                onClick: (info) => props?.onActionClick?.(info.key, record),
              }}
              trigger={['click']}
            >
              <EllipsisOutlined className='icon-ellipse' />
            </Dropdown>
          </Flex>
        );
        break;
      case 'tooltip': {
        customData = data as TooltipData;
        result = (
          <Flex>
            <CustomTooltip title={customData?.title} />
            {customData?.showLockIcon && <ClockCircleOutlined style={{ color: '#FF4D4F', marginLeft: '4px' }} />}
          </Flex>
        );
        break;
      }
      case 'tag-list': {
        if (!data) break;
        customData = data as LabelTagProps[];
        result = customData?.map((item: LabelTagProps) => <LabelTag key={`${item.tagName}-${item.id}`} {...item} />);
        break;
      }
      case 'priority': {
        if (!data) break;
        customData = data as PriorityData;
        result = customData?.iconUrl ? <img src={customData?.iconUrl || ''} width={16} height={16} /> : '';
        break;
      }
      default:
        customData = data as string;
        result = <div className='text-color-polaris-black'>{customData || ''}</div>;
        break;
    }
    return <div className='custom-cell-container'>{result || ''}</div>;
  };

  const columns = useMemo(() => {
    return props?.columns.map((column) => {
      return {
        ...column,
        sorter: !!(column?.sortKey || column?.sorter),
        onCell: (props: DynamicTableDataSource<T>) => {
          return {
            ...column?.onCell?.(props),
            onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (column?.component?.type === 'action') {
                event.stopPropagation();
              }
            },
          };
        },
        render: column?.render || ((value, record) => getComponent(value, column, record)),
      };
    });
  }, [props.columns]);

  const handleSort = (sorter: SorterResult<T>) => {
    const customSorterColumn = sorter?.column as CustomColumnType<T>;
    props?.onSort?.(
      customSorterColumn?.key || customSorterColumn?.sortKey
        ? `${customSorterColumn?.key || customSorterColumn?.sortKey},${sorter?.order === 'ascend' ? 'asc' : 'desc'}`
        : undefined
    );
  };

  const rowSelection: TableRowSelection<DynamicTableDataSource<T>> = {
    selectedRowKeys: rowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      props?.setSelectedRowKeys?.(newSelectedRowKeys);
    },
    getCheckboxProps: (record: DynamicTableDataSource<T>) => ({ name: record.name?.title }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: t('Select Odd Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            return index % 2 !== 0 ? false : true;
          });
          props?.setSelectedRowKeys?.(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: t('Select Even Row'),
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index: number) => {
            return index % 2 !== 0 ? true : false;
          });
          props?.setSelectedRowKeys?.(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <Spin spinning={!!props.isLoading}>
      <div className={`dynamic-table ${`module-${props?.module?.toLowerCase() || 'quality'}`}`}>
        <React.Fragment>
          {props.pagination?.showTop && (
            <div className='bg-white px-4 py-6 rounded-b-[8px]'>
              <TablePagination {...props.pagination} />
            </div>
          )}
          <Table
            bordered={props?.bordered}
            rowSelection={
              props?.hideCheckBox
                ? undefined
                : {
                    ...rowSelection,
                    onCell: () => ({
                      onClick: (event) => {
                        event.stopPropagation();
                      },
                    }),
                    type: 'checkbox',
                  }
            }
            rowKey={(row) => {
              return row?.id || '';
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  props?.onRowClick?.(record);
                  props?.onActionClick?.(EDynamicTableActionRow.SelectedRow, record);
                },
              };
            }}
            columns={columns}
            dataSource={props.dataSource}
            pagination={false}
            onChange={(_, __, sort) => {
              handleSort(sort as SorterResult<T>);
            }}
            scroll={{ x: 'max-content' }}
          />
          {props.pagination && (
            <div className='bg-white px-4 py-6 rounded-b-[8px]'>
              <TablePagination {...props.pagination} />
            </div>
          )}
        </React.Fragment>
      </div>
    </Spin>
  );
};

export default DynamicTable;
