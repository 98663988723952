import { Flex, Typography, Tag, Tooltip, Avatar } from 'antd';
import React from 'react';
import CustomTooltip from './CustomTooltip';
import AvatarItem from './pre-signed-url-items/AvatarItem';
import dayjs, { Dayjs } from 'dayjs';

export type UserCompanyData = {
  companyName?: string;
  userName?: string;
  date?: string | Dayjs;
  color?: string;
  id?: string;
  avatar?: {
    url?: string;
    fallbackName?: string;
  };
};

export type UserCompanyProps = {
  data: UserCompanyData | UserCompanyData[];
};

const UserCompany = (props: UserCompanyProps) => {
  const { data } = props;

  const renderAvatar = (avatar: UserCompanyData['avatar']) => {
    if (!avatar) return null;
    return avatar?.url ? (
      <AvatarItem
        size={'large'}
        style={{ minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32 }}
        src={avatar?.url}
      />
    ) : (
      <Avatar style={{ minWidth: 34, minHeight: 34, maxWidth: 34, maxHeight: 34 }}>
        {avatar?.fallbackName?.substring(0, 1)}
      </Avatar>
    );
  };
  const renderTooltipContent = (data: UserCompanyData) => {
    const formattedDate = data?.date ? dayjs(data?.date).locale('en').format('YYYY-MM-DD') : '';
    return (
      <Flex vertical className='user-company-title'>
        <Tag>
          <Flex vertical>
            <Typography.Text className='company-name'>{data.companyName}</Typography.Text>
            <Typography.Text className='username'>{data.userName}</Typography.Text>
            {formattedDate && <Typography.Text className='date'>{formattedDate}</Typography.Text>}
          </Flex>
        </Tag>
      </Flex>
    );
  };

  const renderText = (data: UserCompanyData) => {
    const formattedDate = data?.date ? dayjs(data?.date).locale('en').format('YYYY-MM-DD') : '';

    return (
      <Tag className={`tag ${data.color ? `active-color-box ${data.color}` : ''}`}>
        <Flex gap={10} align='center'>
          {renderAvatar(data.avatar)}
          <Flex vertical className='w-full'>
            {data.companyName && (
              <Typography.Text className='company-name text-color-polaris-black text-size-14'>
                <CustomTooltip
                  rootClassName='user-company-tooltip'
                  content={data.companyName}
                  title={renderTooltipContent(data)}
                />
              </Typography.Text>
            )}
            {data.userName && (
              <Typography.Text
                className={`username ${formattedDate ? 'text-color-polaris-black' : 'text-color-secondary'} text-size-12`}
              >
                <CustomTooltip
                  rootClassName='user-company-tooltip'
                  content={data.userName}
                  title={renderTooltipContent(data)}
                />
              </Typography.Text>
            )}
            <Typography.Text className='text-color-secondary text-size-12'>{formattedDate}</Typography.Text>
          </Flex>
        </Flex>
      </Tag>
    );
  };
  const renderUserCompany = () => {
    if (!props) return;
    if (typeof data === 'object' && !Array.isArray(data)) {
      return renderText(data);
    } else if (Array.isArray(data) && data.length > 0) {
      const additionalCompanies: UserCompanyData[] = data?.slice(1) || [];

      return (
        <React.Fragment>
          {renderText(data[0])}
          <Tooltip
            rootClassName='user-company-tooltip'
            title={
              <Flex className='user-company-title'>
                {additionalCompanies?.map((company) => (
                  <Tag key={company.id}>
                    <Flex vertical>
                      <Typography.Text className='username'>{company.companyName}</Typography.Text>
                      <Typography.Text className='company-name'>{company.userName}</Typography.Text>
                    </Flex>
                  </Tag>
                ))}
              </Flex>
            }
            placement='topRight'
          >
            {additionalCompanies.length > 0 && <Tag className='number'>+{additionalCompanies.length}</Tag>}
          </Tooltip>
        </React.Fragment>
      );
    }
  };

  return (
    <div className='user-company'>
      <Flex className='user-company-box'>{renderUserCompany()}</Flex>
    </div>
  );
};

export default UserCompany;
