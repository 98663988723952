import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Country } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchCountries, selectCountries } from 'store/common.slice';

export const useCountriesParams = (options?: any) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited', orderBy: 'name' };

    return [queryParams];
  }, []);
};

export const useCountries = (query: any) => {
  return useFetchByParams<Country[]>({
    action: fetchCountries,
    dataSelector: selectCountries,
    params: query,
  });
};
