import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Flex, Form, message, Modal, Select, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CustomizeRequiredMark } from 'components/common';
import { Company, User } from 'model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { projectService } from 'services';

type RevokeCompanyConfirmationProps = {
  isRevoke: boolean;
  workspaceId: string;
  projectId: string;
  companyId: string | null;
  companies: Company[];
  profile: User | null;
  users: User[];
  isAssignedToCompany: boolean;
  isCompanyCreated: boolean;
  isRelatedWorkPackage: boolean;
  isRelatedBaseIssues: boolean;
  refreshCompanies: () => void;
  setIsRevoke: (value: boolean) => void;
};

export default function RevokeCompanyConfirmation(props: RevokeCompanyConfirmationProps) {
  const {
    isRevoke,
    workspaceId,
    projectId,
    companyId,
    companies,
    profile,
    users,
    isAssignedToCompany,
    isCompanyCreated,
    isRelatedWorkPackage,
    isRelatedBaseIssues,
    refreshCompanies,
    setIsRevoke,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const company = companies.find((company) => company.id === companyId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAssignerCompany, setSelectedAssignerCompany] = useState<string | null>();
  const [selectedAssigner, setSelectedAssigner] = useState<string | null>();
  const [selectedAssigneeCompany, setSelectedAssigneeCompany] = useState<string | null>();
  const [selectedAssignee, setSelectedAssignee] = useState<string | null>();

  const handleChangeAssignerCompany = (value: string | null) => {
    setSelectedAssignerCompany(value);
    const companyUsers = (users || []).filter((user) => user?.WorkspaceUser?.Company?.id === value);
    form.setFieldValue('assignerId', (companyUsers || [])[0]?.id || null);
    setSelectedAssigner((companyUsers || [])[0]?.id || null);
  };
  const handleChangeAssigner = (value: string | null) => {
    setSelectedAssigner(value);
    const selectUser = (users || []).find((user) => user.id === value);
    form.setFieldValue('assignerCompanyId', selectUser?.WorkspaceUser?.Company?.id || null);
    setSelectedAssignerCompany(selectUser?.WorkspaceUser?.Company?.id || null);
  };

  const handleChangeAssigneeCompany = (value: string | null) => {
    setSelectedAssigneeCompany(value);
    const companyUsers = (users || []).filter((user) => user?.WorkspaceUser?.Company?.id === value);
    form.setFieldValue('assigneeId', (companyUsers || [])[0]?.id || null);
    setSelectedAssignee((companyUsers || [])[0]?.id || null);
  };
  const handleChangeAssignee = (value: string | null) => {
    setSelectedAssignee(value);
    const selectUser = (users || []).find((user) => user.id === value);
    form.setFieldValue('assigneeCompanyId', selectUser?.WorkspaceUser?.Company?.id || null);
    setSelectedAssigneeCompany(selectUser?.WorkspaceUser?.Company?.id || null);
  };

  const handleCancel = () => {
    setIsRevoke(false);
  };
  const handleSubmit = async () => {
    try {
      if (isRelatedWorkPackage || isRelatedBaseIssues) {
        setIsRevoke(false);
        return;
      }
      setIsLoading(true);
      const values = form.getFieldsValue();
      let issueInfo = null;
      if (isCompanyCreated || isAssignedToCompany) {
        issueInfo = {
          reporterId: values?.assignerId ?? null,
          assigneeId: values.assigneeId,
          companyId: values.assigneeCompanyId,
        };
      }
      await projectService.revokeAccessV2(workspaceId, projectId, companyId || '', { issueInfo });
      message.success(t('Revoke successfully'));
      setIsRevoke(false);
      refreshCompanies();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error('Revoke failed: ' + (error as Error).message);
    }
  };

  useEffect(() => {
    const profileUser = (users || []).find((user) => user.id === profile?.id);
    form.setFieldValue('assignerCompanyId', profileUser?.WorkspaceUser?.Company?.id || null);
    form.setFieldValue('assigneeCompanyId', profileUser?.WorkspaceUser?.Company?.id || null);
    setSelectedAssignerCompany(profileUser?.WorkspaceUser?.Company?.id || null);
    setSelectedAssigneeCompany(profileUser?.WorkspaceUser?.Company?.id || null);
    form.setFieldValue('assigneeId', profileUser?.id || null);
    form.setFieldValue('assignerId', profileUser?.id || null);
    setSelectedAssigner(profileUser?.id || null);
    setSelectedAssignee(profileUser?.id || null);
  }, [isRevoke]);

  return (
    <Modal
      title={
        <Flex gap={8} className='py-3'>
          {isRelatedWorkPackage || isRelatedBaseIssues ? (
            <>
              <CloseCircleFilled style={{ color: '#FF4D4F' }} />
              <Typography.Title level={5}>{t('This company cannot be revoked')}</Typography.Title>
            </>
          ) : (
            <>
              <ExclamationCircleFilled style={{ color: '#FAAD14' }} />
              <Typography.Title level={5}>
                {t('Are you sure you wish to revoke access from this company?')}
              </Typography.Title>
            </>
          )}
        </Flex>
      }
      open={isRevoke}
      onCancel={handleCancel}
      maskClosable={false}
      closable={false}
      className={`revoke-company-confirmation ${!isAssignedToCompany ? 'no-members' : ''}`}
      footer={[
        !isRelatedWorkPackage && !isRelatedBaseIssues && (
          <Button key='back' onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        ),
        <Button
          loading={isLoading}
          onClick={handleSubmit}
          // disabled={isDisable}
          key='submit'
          type='primary'
        >
          {t('OK')}
        </Button>,
      ]}
    >
      {(isRelatedWorkPackage || isRelatedBaseIssues) ? (
        <>
          <Typography.Text style={{ fontStyle: 'italic' }} className='mt-0'>
          {t('This company is used in other functions and therefore cannot be deleted')}
          </Typography.Text>
        </>
      ) : (
        <>
          {(isAssignedToCompany || isCompanyCreated) && (
            <>
              <Typography.Text style={{ fontStyle: 'italic' }} className='mt-0'>
                {t('By revoking')} <span style={{ fontWeight: 'bold' }}>{company?.name}</span> {t('Company')}
                {', '}
                {t(
                  `you should specify the new ${
                    isCompanyCreated ? (isAssignedToCompany ? 'Assigner and/or Assignee' : 'Assigner') : 'Assignee'
                  } of the related Inspections`
                )}
                :
                <br />
              </Typography.Text>
            </>
          )}
          {isCompanyCreated && (
            <>
              <div className='mt-5'>
                <Typography.Text style={{ fontStyle: 'italic' }}>
                  {'1/ '}
                  {t('Select a new Assigner')}
                </Typography.Text>
              </div>
              <Form form={form} requiredMark={CustomizeRequiredMark} layout='vertical' className='mt-3'>
                <FormItem
                  style={{ fontSize: '14px' }}
                  name={'assignerCompanyId'}
                  label={t('Assigner Company')}
                  className='mb-3'
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('Select a company')}
                    onChange={(value) => handleChangeAssignerCompany(value)}
                    value={selectedAssignerCompany}
                    options={(companies || [])
                      .filter((company) => company.id !== companyId)
                      .map((company) => ({
                        label: company.name,
                        value: company.id,
                        disabled: (company?.WorkspaceUsers ?? []).length <= 0,
                      }))}
                  />
                </FormItem>
                <FormItem style={{ fontSize: '14px' }} name={'assignerId'} label={t('Assigner Name')} required>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('Select an assigner')}
                    onChange={(value) => handleChangeAssigner(value)}
                    value={selectedAssigner}
                    options={(users || [])
                      .filter(
                        (user) =>
                          !selectedAssignerCompany || user.WorkspaceUser?.Company?.id === selectedAssignerCompany
                      )
                      .map((user) => ({ label: user.fullName, value: user.id }))}
                  />
                </FormItem>
              </Form>
            </>
          )}
          {isAssignedToCompany && (
            <div className={isCompanyCreated ? 'mt-8' : 'mt-5'}>
              <Typography.Text style={{ fontStyle: 'italic' }}>
                {isCompanyCreated ? '2/ ' : '1/ '}
                {t('Select a new Assignee')}
              </Typography.Text>

              <Form form={form} requiredMark={CustomizeRequiredMark} layout='vertical' className='mt-3'>
                <FormItem
                  style={{ fontSize: '14px' }}
                  name={'assigneeCompanyId'}
                  label={t('Assignee Company')}
                  className='mb-3'
                  required
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('Select a company')}
                    onChange={(value) => handleChangeAssigneeCompany(value)}
                    value={selectedAssigneeCompany}
                    options={(companies || [])
                      .filter((company) => company.id !== companyId)
                      .map((company) => ({
                        label: company.name,
                        value: company.id,
                        disabled: (company?.WorkspaceUsers ?? []).length <= 0,
                      }))}
                  />
                </FormItem>
                <FormItem style={{ fontSize: '14px' }} name={'assigneeId'} label={t('Assignee Name')} required>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('Select an assignee')}
                    onChange={(value) => handleChangeAssignee(value)}
                    value={selectedAssignee}
                    options={(users || [])
                      .filter(
                        (user) =>
                          !selectedAssigneeCompany || user.WorkspaceUser?.Company?.id === selectedAssigneeCompany
                      )
                      .map((user) => ({ label: user.fullName, value: user.id }))}
                  />
                </FormItem>
              </Form>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
