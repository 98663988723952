import {
  Col,
  DatePicker,
  DatePickerProps,
  Flex,
  Form,
  FormItemProps,
  Input,
  InputProps,
  Row,
  Select,
  SelectProps,
  Tag,
  TagProps,
  TimePicker,
  TimePickerProps,
} from 'antd';
import { ReactNode } from 'react';
import SearchSelect from './SearchSelect';
import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ChoiceToggle, { ChoiceToggleProps } from './ChoiceToggle';
import SelectTimePeriod, { SelectTimePeriodProps } from './SelectTimePeriod';
import ReactQuillCustom, { ReactQuillCustomProps } from './ReactQuillCustom';
import SelectZoning, { SelectZoningProps } from './SelectZoning';
import { findPath } from './TreeSelectTagRender';
import { convertZoneToTreeData } from 'utils';

type FieldType =
  | {
      type?: 'input';
      readOnlyProps?: React.HTMLProps<HTMLSpanElement>;
      editableProps: InputProps;
    }
  | {
      type?: 'select';
      editableProps: SelectProps;
    }
  | {
      type?: 'select-icon';
      editableProps: SelectProps;
    }
  | {
      type?: 'date';
      editableProps: DatePickerProps;
    }
  | {
      type?: 'textarea';
      readOnlyProps?: React.HTMLProps<HTMLSpanElement>;
      editableProps: TextAreaProps;
    }
  | {
      type?: 'multiple';
      readOnlyProps?: TagProps;
      editableProps: SelectProps;
    }
  | {
      type?: 'time';
      editableProps: TimePickerProps;
    }
  | {
      type?: 'choice-toggle';
      editableProps: ChoiceToggleProps;
    }
  | {
      type?: 'time-period';
      editableProps?: SelectTimePeriodProps;
    }
  | {
      type?: 'react-quill';
      editableProps: ReactQuillCustomProps;
    }
  | {
      type?: 'empty';
    }
  | {
      type?: 'zoning';
      editableProps: SelectZoningProps;
    };

type Column = FormItemProps & { children?: ReactNode } & FieldType;
type EditableFormFieldProps = {
  isReadonly?: boolean;
  columns: Column[];
  formListName?: string;
};
const EditableFormField = (props: EditableFormFieldProps) => {
  const form = Form.useFormInstance();

  const { t } = useTranslation();

  const renderEditableField = (column: Column) => {
    switch (column.type) {
      case 'select':
        return <SearchSelect {...column.editableProps} />;
      case 'input':
        return <Input {...column.editableProps} />;
      case 'date':
        return <DatePicker {...column.editableProps} style={{ width: '100%', ...column?.editableProps?.style }} />;
      case 'textarea':
        return <TextArea {...column.editableProps} style={{ whiteSpace: 'pre-line' }} />;
      case 'multiple':
        return (
          <SearchSelect
            showSearch
            allowClear
            maxTagCount='responsive'
            optionFilterProp='children'
            {...column.editableProps}
            mode='multiple'
          />
        );
      case 'time': {
        return (
          <TimePicker
            format='h:mm A'
            variant={'outlined'}
            {...column.editableProps}
            style={{ width: '100%', ...column?.editableProps?.style }}
          />
        );
      }

      case 'choice-toggle': {
        return <ChoiceToggle {...column?.editableProps} />;
      }
      case 'time-period': {
        return <SelectTimePeriod {...column?.editableProps} />;
      }
      case 'select-icon': {
        return (
          <Select style={{ width: '100%' }} placeholder='Select priority'>
            {column.editableProps?.options?.map((option) => (
              <Select.Option key={option.id} value={option.value} label={option.label}>
                <div>
                  {option.icon && <img src={option.icon} alt='' width={16} height={16} className='mr-2' />}
                  <span>{option.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        );
      }
      case 'react-quill': {
        return <ReactQuillCustom {...column.editableProps} />;
      }
      case 'zoning': {
        return <SelectZoning {...column?.editableProps} />;
      }
      default:
        return null;
    }
  };

  const getFormValue = (name: string | Array<string>) => {
    if (props.formListName) {
      return form.getFieldValue([props.formListName, ...name]);
    }

    return form.getFieldValue(name as string);
  };
  const renderReadonlyValue = (column: Column) => {
    const fieldValue = getFormValue(column?.name);
    switch (column.type) {
      case 'select': {
        const selectedValue = Array.isArray(fieldValue) ? fieldValue?.[0] : fieldValue;
        const selectedOption = column?.editableProps?.options?.find((option) => option.value === selectedValue);
        return selectedOption?.label || '--';
      }
      case 'textarea':
      case 'input':
        return (
          <span {...column?.readOnlyProps} className={`${column?.readOnlyProps?.className || ''} break-words`}>
            {fieldValue || '--'}
          </span>
        );
      case 'date':
        return fieldValue
          ? dayjs(fieldValue)
              .locale('en')
              .format((column?.editableProps?.format as string) || 'YYYY-MM-DD')
          : '--';
      case 'time':
        return fieldValue
          ? dayjs(fieldValue)
              .locale('en')
              .format((column?.editableProps?.format as string) || 'hh:mm')
          : '--';
      case 'multiple': {
        const selectedOptions: string[] =
          fieldValue
            ?.map(
              (selectedOption: number) =>
                (
                  column?.editableProps?.options?.find((option) => option.value === selectedOption)?.label as string
                )?.trim() || ''
            )
            .filter((label: string) => label) || [];
        return (
          selectedOptions?.map((value) => (
            <Tag key={value} {...column?.readOnlyProps}>
              {value}
            </Tag>
          )) || '--'
        );
      }

      case 'choice-toggle': {
        const selectedOption = column?.editableProps?.options?.find((option) => option.value === fieldValue);
        return (
          selectedOption?.value && (
            <ChoiceToggle
              value={selectedOption.value}
              options={[
                {
                  ...selectedOption,
                  style: { pointerEvents: 'none' },
                },
              ]}
            />
          )
        );
      }
      case 'time-period': {
        return (
          <span>
            {fieldValue.periodNumber} {fieldValue.timePeriod}
          </span>
        );
      }
      case 'select-icon': {
        const selectedOption = column?.editableProps?.options?.find((option) => option.value === fieldValue);

        return (
          <div>
            {selectedOption?.icon && <img src={selectedOption?.icon} alt='' width={16} height={16} className='mr-2' />}
            <span>{selectedOption?.label}</span>
          </div>
        );
      }
      case 'react-quill': {
        return <ReactQuillCustom value={fieldValue as string} readOnly={true} theme={'bubble'} />;
      }

      case 'zoning': {
        const zonesTreeData = convertZoneToTreeData(column?.editableProps?.zones);
        return <span>{fieldValue ? findPath(zonesTreeData, fieldValue) : '--'}</span>;
      }
    }
  };

  return (
    <Row gutter={15} className='editable-form-field'>
      {props.columns.map((column: Column) => {
        return (
          <Col span={24 / props.columns.length} key={column.name}>
            {props.isReadonly ? (
              <Flex className='readonly-field mb-4' vertical>
                <span className='text-size-12 text-color-secondary'>{t(column?.label as string)}</span>
                <span className='text-size-14 text-color-polaris-black'>{renderReadonlyValue(column)}</span>
              </Flex>
            ) : (
              <Form.Item layout='vertical' rules={column?.rules} label={column.label} name={column.name}>
                {column?.children || renderEditableField(column)}
              </Form.Item>
            )}
          </Col>
        );
      })}
    </Row>
  );
};

export default EditableFormField;
