import { useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, ModalProps, Row, Select, Typography } from 'antd';
import { Modal, SearchSelect, CreateCompany } from 'components';
import { Company, Project } from 'model';
import { useTranslation } from 'react-i18next';
import { useMyWorkspaceCompanies, useMyWorkspaceCompaniesParams, useMyWorkspaceParams } from 'hooks';
import { useMyWorkspace } from 'hooks/workspace';

type AddCompanyToProjectProps = {
  isModalOpen: boolean;
  existingCompanies?: Company[];
  onSubmit: (values: { companyId: string; companyRole: string; companyType?: string }[]) => void;
  isSubmit?: boolean;
  setIsModalOpen: (value: boolean) => void;
  workspaceId: string;
  projectId: string;
  project?: Project | null;
} & ModalProps;

export default function AddCompanyToProject(props: AddCompanyToProjectProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, project, workspaceId, isSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean | null>(null);
  const [indexOfNewCompany, setIndexOfNewCompany] = useState<number | null>(null);

  const [companyQuery] = useMyWorkspaceCompaniesParams({ workspaceId, orderBy: 'createdAt,desc' });
  const [companies, , refreshCompanies] = useMyWorkspaceCompanies(companyQuery);
  const [workspaceParams] = useMyWorkspaceParams({
    workspaceId,
  });
  const [workspace] = useMyWorkspace(workspaceParams);
  const isWorkspaceOwner = workspace?.id === workspaceId;

  const remainingCompanies = useMemo(() => {
    return companies?.filter(
      (company) => !company.ProjectCompanies?.find((projectCompany) => projectCompany.projectId === project?.id)
    );
  }, [companies]);
  const remainingCompaniesIds = useMemo(() => {
    return remainingCompanies?.map((item) => item.id);
  }, [remainingCompanies]);

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields();
    }
  }, [isModalOpen]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldValue('companies');
      setSelectedIds([]);
      onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedIds([]);
  };

  const onCompanySelect = (id: string, index: number) => {
    const newSelectedIds = [...selectedIds];
    newSelectedIds.splice(index, 1, id);
    setSelectedIds(newSelectedIds);
  };

  const removeCompany = (name: number) => {
    const removedId = form.getFieldValue(['companies', name])?.companyId;
    setSelectedIds((prev) => prev.filter((i) => i !== removedId));
  };

  const onCompanyCreate = (value: Company) => {
    setIsCreateOpen(false);
    refreshCompanies();
    const newSelectedIds = [...selectedIds];
    if (indexOfNewCompany !== null) {
      newSelectedIds.splice(indexOfNewCompany, 1, value.id);
    }
    setSelectedIds(newSelectedIds);
    form.setFieldValue(['companies', indexOfNewCompany], { companyId: value.id });
  };

  return (
    <Modal
      title={t('Add companies to') + ' ' + project?.name + ' ' + t('project')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' loading={isSubmit} type='primary' onClick={handleOk}>
          {t('Validate')}
        </Button>,
      ]}
    >
      {isCreateOpen !== null && (
        <CreateCompany isModalOpen={isCreateOpen} setIsModalOpen={setIsCreateOpen} onSubmit={onCompanyCreate} />
      )}
      <Typography.Title level={3} className='section-title'>
        {t('Companie(s)')}
      </Typography.Title>
      <Form form={form} name='companies' layout='vertical' initialValues={{ companies: [{}] }}>
        <Form.List name='companies'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row gutter={24} key={key} align='middle' style={{ marginBottom: 16 }}>
                  <Col sm={8} md={9}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      {...restField}
                      className='form-item-inline'
                      name={[name, 'companyId']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <SearchSelect
                        placeholder={t('Company Name')}
                        onChange={(id) => onCompanySelect(id, index)}
                        options={companies
                          ?.filter((company) => remainingCompaniesIds?.includes(company.id))
                          .map((option) => ({
                            value: option.id,
                            label: option.name,
                            disabled: selectedIds.some((selectedId) => selectedId === option.id),
                          }))}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {isWorkspaceOwner && (
                              <Button
                                type='link'
                                onClick={() => {
                                  setIsCreateOpen(true);
                                  setIndexOfNewCompany(index);
                                }}
                                icon={<PlusOutlined />}
                              >
                                {t('Register company')}
                              </Button>
                            )}
                          </>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={8} md={7}>
                    <Form.Item style={{ marginBottom: 0 }} {...restField} name={[name, 'companyType']}>
                      <Input placeholder={t('Company Type')} />
                    </Form.Item>
                  </Col>
                  <Col sm={8} md={7}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      {...restField}
                      name={[name, 'companyRole']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select placeholder={t('Company Role')}>
                        <Select.Option value='owner'>{t('Owner')}</Select.Option>
                        <Select.Option value='member'>{t('Member')}</Select.Option>
                        <Select.Option value='guest'>{t('Guest')}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {index > 0 && (
                    <Col sm={24} md={1}>
                      <DeleteOutlined
                        onClick={() => {
                          removeCompany(name);
                          remove(name);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  className='primary-dashed-btn my-6'
                  style={{ marginBottom: 16 }}
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  {t('Add company')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}
