import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Flex, Form, Row, Tag, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { BaseIssue, IssueDiscipline, User } from 'model';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectProjectUsers, selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { EWorkBreakDownStructureLevelID } from 'types/project';

type MARsHeaderProps = {
  discipline?: IssueDiscipline | null;
  isUnSaveStatus?: boolean;
  onClose: () => void;
  profile: User | null;
  selectedBaseIssue?: BaseIssue;
  user?: User | null;
};

export default function WorkInspectionRequestHeader(props: MARsHeaderProps) {
  const { selectedBaseIssue, onClose } = props;
  const form = useFormInstance();
  const name = Form.useWatch('workInspectionName') || form.getFieldValue('workInspectionName');
  const disciplineId = Form.useWatch('discipline') || form.getFieldValue('discipline');
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const disciplineCode = disciplines.find((discipline) => discipline.id === disciplineId)?.code || '-';
  const users = useAppSelector(selectProjectUsers);
  const originator = users.find((u) => u.id === (selectedBaseIssue?.originatorId || props?.profile?.id));

  const code = useMemo(() => {
    const numberSystem = selectedBaseIssue?.WorkInspectionRequest?.wirNumber?.split('-');
    if (!numberSystem) return '';
    numberSystem[numberSystem?.length - 1] = numberSystem[numberSystem?.length - 1].padStart(4, '0');
    return numberSystem.join('-');
  }, [selectedBaseIssue?.WorkInspectionRequest?.wirNumber]);

  const renderStatus = () => {
    if (!selectedBaseIssue?.IssueStatus) return <Tag>{t('Unsaved')}</Tag>;

    return (
      <div>
        <Tag>
          <Flex align='center'>
            <Tag className='circle-fill small' color={selectedBaseIssue?.IssueStatus.color || ''} bordered={false} />
            {t(selectedBaseIssue?.IssueStatus.name)}
          </Flex>
        </Tag>
      </div>
    );
  };

  const renderName = () => {
    return (
      <Flex vertical justify='center' className='ml-2' wrap='nowrap' rootClassName='name-box'>
        <Typography.Title level={2}>{name}</Typography.Title>
        <Flex className='name-group'>
          {code && <Typography.Text>{code}</Typography.Text>}
          {originator?.WorkspaceUser?.Company?.name && (
            <Typography.Text>{originator?.WorkspaceUser?.Company?.name}</Typography.Text>
          )}
          {originator?.fullName && <Typography.Text>{originator?.fullName}</Typography.Text>}
          {selectedBaseIssue?.IssueStatus?.name !== 'Draft' && selectedBaseIssue?.BaseValidations?.[0]?.createdAt && (
            <Typography.Text>
              {t('Submitted on:')}{' '}
              {dayjs(selectedBaseIssue?.BaseValidations?.[0]?.createdAt).locale('en').format('DD-MM-YYYY')}
            </Typography.Text>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='mb-2'>
        <Col span={12} className='header'>
          <DoubleRightOutlined className='icon' onClick={onClose} style={{ color: 'white' }} />
        </Col>
        <Col span={12} className='header text-right'>
          <Button
            size='small'
            type='text'
            onClick={onClose}
            icon={<CloseOutlined className='icon' style={{ color: 'white' }} />}
          />
        </Col>
      </Row>
      <Flex justify='space-between' align='center'>
        <Flex gap={6} align='center' className='text-white'>
          <Avatar className='avt-color'>{disciplineCode?.toString().toUpperCase()}</Avatar>
          {renderName()}
        </Flex>

        {renderStatus()}
      </Flex>
    </div>
  );
}
