import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from 'types';
import { getExistProp } from 'utils';

export default function useQueryParams<T>(isListen = false): [query: T, onParamChange: (param?: QueryParams) => void] {
  const prevKeyword = useRef('');
  const [searchParams, setSearchParams] = useSearchParams();
  const onParamChange = (param?: QueryParams) => {
    if (prevKeyword.current !== param?.keyword) {
      prevKeyword.current = param?.keyword;
    }
    // reset to first page if the users input new keyword
    if (param?.keyword && param?.keyword !== prevKeyword) {
      param.page = 1;
    }
    setSearchParams(
      getExistProp({
        ...query,
        ...param,
      })
    );
  };
  const query = useMemo(() => {
    if (!isListen) {
      return {};
    }
    const result: { [key: string]: string | number } = Object.fromEntries([...searchParams]);
    if (result.page) {
      result.page = parseInt(result.page as string);
    } else {
      result.page = 1;
    }
    if (result.limit !== 'unlimited') {
      if (result.limit) {
        result.limit = parseInt(result.limit as string);
      } else {
        result.limit = 10;
      }
    }
    return result;
  }, [searchParams]) as T & QueryParams;
  return [query, onParamChange];
}
