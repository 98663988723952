import { CSSProperties, useEffect, useState } from 'react';
import { Flex, Collapse, CollapseProps, theme } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import { KpiStatistic } from 'components/common';
import { TRA_STATUSES } from 'utils/contants';

type MaterialApprovalRequestsStatisticProps = {
  kpiData: {
    totalKpi: number;
    draft: number;
    issued: number;
  };
  activeKey: string;
  handleFilterByKPI: (value: string) => void;
  isResetKPI: boolean;
  setResetKPI: (value: boolean) => void;
};

export default function ProjectDocumentsTransmissionStatistic(props: MaterialApprovalRequestsStatisticProps) {
  const { kpiData, activeKey, handleFilterByKPI, isResetKPI, setResetKPI } = props;
  const { token } = theme.useToken();
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const [activeKpi, setActiveKpi] = useState<string | null>(null);
  const onApplyFilter = (code: string) => {
    if (activeKpi === code) {
      setActiveKpi(null);
      handleFilterByKPI('');
    } else {
      setActiveKpi(code);
      handleFilterByKPI(code);
    }
  };
  useEffect(() => {
    if (isResetKPI) {
      setActiveKpi(null);
      setResetKPI(false);
    }
  }, [isResetKPI]);
  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      children: (
        <Flex wrap='wrap' gap={20}>
          {activeKey === 'incoming' && (
            <>
              <KpiStatistic status='total-of-letters' value={kpiData?.totalKpi} title='Total of transmittals' />
            </>
          )}
          {activeKey === 'outgoing' && (
            <>
              <KpiStatistic
                status='draft'
                value={kpiData?.draft}
                title='Draft'
                onClick={() => onApplyFilter(TRA_STATUSES.DRAFT)}
                isActive={activeKpi === TRA_STATUSES.DRAFT}
              />
              <KpiStatistic
                status='issued'
                value={kpiData?.issued}
                title='Issued'
                onClick={() => onApplyFilter(TRA_STATUSES.ISSUED)}
                isActive={activeKpi === TRA_STATUSES.ISSUED}
              />
            </>
          )}
        </Flex>
      ),
      style: panelStyle,
    },
  ];

  return (
    <Collapse
      size={'small'}
      className='inspection-collapse ma-0'
      ghost
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretUpOutlined
          rotate={isActive ? 0 : 180}
          style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '20px', width: '20px' }}
        />
      )}
      expandIconPosition='end'
      style={{ background: 'transparent' }}
      items={getItems(panelStyle)}
    />
  );
}
