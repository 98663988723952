import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

type SearchSelectProps = SelectProps & {
  dynamicWidth?: boolean;
  selectedLabel?: string;
  keepSelectedWidthLabel?: boolean;
  breakText?: boolean;
};
export default function SearchSelect(props: SearchSelectProps) {
  const { options, popupClassName, dynamicWidth, labelRender, ...otherProps } = props;
  const spanRef = useRef<HTMLSpanElement>(null);
  const spanDropdownRef = useRef<HTMLSpanElement>(null);

  const [width, setWidth] = useState<number | undefined>(undefined);
  const [dropdownWidth, setDropdownWidth] = useState<undefined | number>(undefined);

  const OFFSET_PADDING = 45;

  useEffect(() => {
    const label = props.selectedLabel || props.options?.find((option) => option.value === props.value)?.label;
    if (label && spanRef.current) {
      spanRef.current.innerHTML = label as string;
      setWidth(spanRef.current.offsetWidth + OFFSET_PADDING);
    }
  }, [props.selectedLabel, props.value, props.options, spanRef]);

  useEffect(() => {
    if (!spanDropdownRef.current) return;
    let widthList = props?.options?.map((option) => {
      spanDropdownRef.current!.innerHTML = option.label as string;
      return spanDropdownRef.current!.offsetWidth;
    });
    if (widthList) {
      setDropdownWidth(Math.max(...widthList) + OFFSET_PADDING);
    }
  }, [props.options, spanDropdownRef]);

  const innerPopupClassName = useMemo(() => {
    if (props?.dynamicWidth) {
      return `custom-popup ${popupClassName ? popupClassName : ''}`;
    }

    if (props?.breakText) {
      return `break-text ${popupClassName ? popupClassName : ''}`;
    }
    return popupClassName || '';
  }, [popupClassName, props?.dynamicWidth, props?.breakText]);

  const renderDynamicWidthSection = () => {
    return (
      <>
        <span
          ref={spanRef}
          style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap', fontSize: 'inherit' }}
        ></span>
        <span
          ref={spanDropdownRef}
          style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap', fontSize: 'inherit' }}
        ></span>
      </>
    );
  };

  return (
    <>
      <Select
        popupClassName={innerPopupClassName}
        style={{ width: props?.keepSelectedWidthLabel ? undefined : width }}
        labelRender={labelRender}
        showSearch
        allowClear
        options={options}
        optionFilterProp='children'
        dropdownRender={(menu) => (
          <div
            className='break-work'
            style={{ width: props.options && props.options?.length > 0 ? dropdownWidth : undefined }}
          >
            {menu}
          </div>
        )}
        filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input?.toLowerCase())}
        {...otherProps}
      />
      {props.dynamicWidth && renderDynamicWidthSection()}
    </>
  );
}
