import { Row, Col, Button } from 'antd';
import { BaseIssue, User } from 'model';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MAR_STATUSES } from 'utils/contants';
import { WorkspaceViewType } from '../CreateOrUpdateMAR';

type MARsFooterProps = {
  profile: User | null;
  isSubmit?: boolean;
  isSubmitAsDraft?: boolean;
  activeTab: string;
  isAvailableSubmit?: boolean;
  onSubmitAsDraft: () => void;
  isDisableStartValidation?: boolean;
  setOpenValidationModal: (isOpen: boolean) => void;
  setActiveTab: (isOpen: WorkspaceViewType) => void;
  onClose: () => void;
  isReadOnly: boolean;
  isSave: boolean;
  selectedMarBaseIssue?: BaseIssue | null;
  user: User | null;
};

export default function MARsFooter(props: MARsFooterProps) {
  const {
    isSubmit,
    isSubmitAsDraft,
    onSubmitAsDraft,
    setOpenValidationModal,
    isReadOnly,
    profile,
    selectedMarBaseIssue,
    isDisableStartValidation,
    isSave,
    setActiveTab,
    activeTab,
  } = props;
  const { t } = useTranslation();
  const [isDisableSaveAsDraftButton, setIsDisableSaveAsDraftButton] = useState(false);
  useEffect(() => {
    if (isReadOnly) {
      setIsDisableSaveAsDraftButton(true);
    } else {
      setIsDisableSaveAsDraftButton(false);
    }
  }, [isReadOnly]);

  const isRejected = selectedMarBaseIssue?.IssueStatus?.code === MAR_STATUSES.REJECTED;
  const isApproved = selectedMarBaseIssue?.IssueStatus?.code === MAR_STATUSES.APPROVED;
  const isApprovedWithComment = selectedMarBaseIssue?.IssueStatus?.code === MAR_STATUSES.APPROVED_WITH_COMMENT;

  const userLogin = profile?.id;
  const userLoginCompany = props.user?.WorkspaceUser?.Company?.id;
  const originatorCompanyId = selectedMarBaseIssue?.companyOriginatorId;
  const baseValidations = selectedMarBaseIssue?.BaseValidations;

  let disabledValidation = false;
  if (!selectedMarBaseIssue) {
    // Check required fields name, originator, originator company, work package
    disabledValidation = isDisableStartValidation ? true : false;
  } else {
    // Not check required fields for readonly
    if (!isReadOnly && isDisableStartValidation) {
      disabledValidation = true;
    }
    if (isRejected || isApproved || isApprovedWithComment) {
      disabledValidation = true;
    } else if (
      (baseValidations === null || (baseValidations || [])?.length < 1) &&
      userLoginCompany !== originatorCompanyId
    ) {
      disabledValidation = true;
    } else if (baseValidations && baseValidations.length > 0 && baseValidations[0].validatorId !== userLogin) {
      disabledValidation = true;
    }
  }

  return (
    <Row gutter={16} justify={'end'} align='middle'>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button
          onClick={() => {
            if (activeTab !== 'detail') {
              setActiveTab('detail');
            }
            onSubmitAsDraft();
          }}
          disabled={isDisableSaveAsDraftButton}
          loading={isSubmitAsDraft}
        >
          {t(isSave ? 'Save' : 'Save as Draft')}
        </Button>
        <Button
          type='primary'
          loading={isSubmit}
          onClick={() => {
            if (activeTab !== 'detail') {
              setActiveTab('detail');
            }
            setOpenValidationModal(true);
          }}
          disabled={disabledValidation}
        >
          {t('Next')}
        </Button>
      </Col>
    </Row>
  );
}
