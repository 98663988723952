import { Issue, IssueAttachment } from 'model';
import request from 'requesters/core.request';

const issueAttachmentService = {
  updateIssueAttachment: async (
    workspaceId: string,
    projectId: string,
    issueAttachmentId: string,
    data: IssueAttachment
  ) => {
    return request.put<Issue>(
      `/workspaces/${workspaceId}/projects/${projectId}/issue-attachments/${issueAttachmentId}`,
      data
    );
  },
};

export default issueAttachmentService;
