import { useMemo } from 'react';
import { QueryParams } from 'types';
import { useFetchByParams, useQueryParams } from 'hooks';
import {
  fetchProjectDefaultWorkBreakdownStructures,
  fetchProjectWorkBreakdownStructures,
  selectDefaultProjectWorkBreakdownStructures,
  selectDefaultProjectWorkBreakdownStructuresLoading,
  selectProjectWorkBreakdownStructures,
  selectProjectWorkBreakdownStructuresLoading,
} from 'store/my-projects.slice';
import { RootState } from 'store';
import WorkBreakdownStructure from 'model/WorkBreakdownStructure';

export const useWorkBreakdownStructures = (query: { projectId: string } & QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<WorkBreakdownStructure[]>({
    action: fetchProjectWorkBreakdownStructures,
    dataSelector: (state: RootState) => selectProjectWorkBreakdownStructures(state, projectId),
    loadingSelector: (state: RootState) => selectProjectWorkBreakdownStructuresLoading(state, projectId),
    params: query,
  });
};

export const useWorkBreakdownStructureParams = (
  options: {
    workspaceId: string;
    projectId: string;
  } & QueryParams
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId, projectId]);
};
export const useDefaultWorkBreakdownStructures = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<WorkBreakdownStructure[]>({
    action: fetchProjectDefaultWorkBreakdownStructures,
    dataSelector: (state: RootState) => selectDefaultProjectWorkBreakdownStructures(state, projectId),
    loadingSelector: (state: RootState) => selectDefaultProjectWorkBreakdownStructuresLoading(state, projectId),
    params: query,
  });
};
export const useDefaultWorkBreakdownStructureParams = (
  options: {
    workspaceId: string;
    projectId: string;
  } & QueryParams
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, defaultWbs: true, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId, projectId]);
};
