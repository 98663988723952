import TicketType from 'model/TicketType';
import { PaginationData, QueryParams } from 'types';
import request from 'requesters/ticketing.request';

const ticketingService = {
  getTickets: async (query: { userId: string } & QueryParams) => {
    return request.get<PaginationData<TicketType>>(`/tickets`, {
      params: query,
    });
  },

  createTicket: async (ticket: TicketType) => {
    return await request.post(`/tickets`, ticket, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  addAttachments: async (attachments: FormData, ticketId: string) => {
    return await request.put(`/tickets/${ticketId}/attachments`, attachments, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default ticketingService;
