import { ClockCircleOutlined } from '@ant-design/icons';
import { Flex, Layout, message, Tag } from 'antd';
import { DeleteConfirmation, ValidationHistoryStepOnTable } from 'components/common';
import AttachmentsCell from 'components/common/AttachmentsCell';
import CustomTooltip from 'components/common/CustomTooltip';
import DisciplineBaseIssueTable, {
  DisciplineBaseIssueTableRefHandle,
} from 'components/common/DisciplineBaseIssueTable';
import { CustomColumnType } from 'components/common/DynamicTable';
import UserCompany from 'components/common/UserCompany';
import dayjs from 'dayjs';
import { useParametersSetting } from 'hooks';
import { useMyWorkspaceParams } from 'hooks/workspace';
import { BaseIssue, User } from 'model';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { baseIssueService } from 'services';
import { QueryParams } from 'types';
import { kpiData } from 'types/project';
import {
  convertDateFormat,
  loadEnLocale,
  loadEsLocale,
  loadFrLocale,
  loadIdLocale,
  loadKmLocale,
  loadPtLocale,
  loadViLocale,
  loadZhCnLocale,
} from 'utils';
import { MSS_STATUS_COLOR, MSS_STATUSES, NUMBERING_DIGITS } from 'utils/contants';

type MethodStatementsProps = {
  projectId: string;
  workspaceId: string;
  users: User[];
  profile: User | null;
  projectBaseIssueParams: QueryParams;
  editForm: (id: string) => void;
  onFilter: (value: { [key: string]: string }) => void;
  disciplineBaseIssueTableRef?: RefObject<DisciplineBaseIssueTableRefHandle>;
  onTotalKpi: (kpi: kpiData) => void;
  refreshDisciplineByFunction: () => void;
};

export default function MethodStatementsListView(props: MethodStatementsProps) {
  const { t, i18n } = useTranslation();
  const { users, projectId, workspaceId, projectBaseIssueParams, editForm } = props;
  const [locale, setLocale] = useState<string>('en');
  const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
  const [parametersParams] = useMyWorkspaceParams({ projectId });
  const [parametersSettings, parametersLoading] = useParametersSetting(parametersParams);
  const [isDelete, setIsDelete] = useState<{
    open: boolean;
    methodStatementId?: string;
    disciplineId?: string;
  }>({
    open: false,
    methodStatementId: undefined,
    disciplineId: '',
  });

  const handleLanguageChange = (newLocale: string) => {
    // Load the locale dynamically using import
    switch (newLocale) {
      case 'en':
        loadEnLocale();
        break;
      case 'fr':
        loadFrLocale();
        break;
      case 'vn':
        loadViLocale();
        return setLocale('vi');
        break;
      case 'cn':
        loadZhCnLocale();
        return setLocale('zh-cn');
        break;
      case 'pt':
        loadPtLocale();
        break;
      case 'id':
        loadIdLocale();
        break;
      case 'km':
        loadKmLocale();
        break;
      case 'es':
        loadEsLocale();
        break;
      default:
        break;
    }
    setLocale(newLocale);
  };

  function numberingPadding(inputString: string) {
    const parts = inputString.split('-');
    const lastPart = parts.pop(); // Extract the last part
    if (!lastPart) return inputString;
    const paddedLastPart = lastPart.padStart(NUMBERING_DIGITS, '0'); // Pad the last part with zeros
    parts.push(paddedLastPart); // Push the padded last part back to the array
    return parts.join('-'); // Join all parts back together
  }
  const handleGetDateFormat = async () => {
    const date = parametersSettings.find((obj) => obj.code === 'date_format')?.value;
    if (date) {
      setDateFormat(convertDateFormat(date));
    }
  };
  useEffect(() => {
    if (parametersLoading !== 'pending') {
      handleGetDateFormat();
    }
  }, [parametersLoading]);

  useEffect(() => {
    handleLanguageChange(i18n.language);
  }, [i18n.language]);

  const handleMenuClick = (key: string, record: BaseIssue) => {
    if (key) {
      switch (key) {
        case 'edit':
          editForm(record.id);
          break;
        case 'delete':
          setIsDelete({
            open: true,
            methodStatementId: record.id,
            disciplineId: record.MethodStatementSubmission?.disciplineId,
          });
          break;
      }
    }
  };
  const handleDeleteBaseIssue = async () => {
    try {
      const methodStatementId = isDelete.methodStatementId;
      if (methodStatementId) {
        await baseIssueService.deleteBaseIssueById(workspaceId, projectId, methodStatementId);
        setIsDelete({ open: false, methodStatementId: undefined });
        props.disciplineBaseIssueTableRef?.current?.refreshData(isDelete?.disciplineId, methodStatementId);
      } else {
        message.error(t('Material Approval Request not existed!'));
      }
    } catch (error) {
      console.log(error);
      setIsDelete({ open: false, methodStatementId: undefined });
    }
  };

  const columns: CustomColumnType<BaseIssue>[] = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'MethodStatementSubmission',
      key: 'MethodStatementSubmission.mssNumber',
      onCell: () => {
        return {
          style: {
            maxWidth: '210px',
          },
        };
      },
      render: (MethodStatementSubmission) => {
        return (
          <CustomTooltip
            title={MethodStatementSubmission?.mssNumber ? numberingPadding(MethodStatementSubmission?.mssNumber) : ''}
          />
        );
      },
    },
    {
      sorter: true,
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      onCell: () => {
        return {
          style: {
            maxWidth: '250px',
          },
        };
      },
      render: (name, record) => {
        const isReadyForInspection: boolean = record?.IssueStatus?.code === MSS_STATUSES.REVIEW_IN_PROGRESS;
        const isExpired: boolean = record?.endDate ? dayjs(record?.endDate) < dayjs().subtract(1, 'day') : false;

        return (
          <Flex>
            <CustomTooltip title={name} />
            {isReadyForInspection && isExpired && (
              <ClockCircleOutlined style={{ color: '#FF4D4F', marginLeft: '4px' }} />
            )}
          </Flex>
        );
      },
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
    },
    {
      title: 'Sub-activity',
      dataIndex: 'subActivity',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
    },
    {
      title: t('Submitted By'),
      dataIndex: 'originatorId',
      width: 120,
      key: 'originatorId',
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
      render: (originatorId) => {
        const user = (users || []).find((user) => user.id === originatorId);
        return (
          <UserCompany
            data={{
              companyName: user?.WorkspaceUser?.Company?.name,
              userName: user?.fullName,
            }}
          />
        );
      },
    },
    {
      title: t('Submitted To'),
      dataIndex: 'BaseValidations',
      key: 'submittedTo',
      width: 120,
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
      render: (value, record) => {
        return ValidationHistoryStepOnTable(record, true, users);
      },
    },
    {
      sorter: true,
      title: t('Issue Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (createdAt, record) => (
        <span>{record && dayjs(createdAt).locale(locale).format(dateFormat).toString()}</span>
      ),
    },
    {
      sorter: true,
      title: t('Deadline'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate, record) => (
        <span>{record.endDate && dayjs(record.endDate).locale(locale).format(dateFormat).toString()}</span>
      ),
    },
    {
      sorter: true,
      title: t('Status'),
      dataIndex: 'IssueStatus',
      key: 'IssueStatus.orderNumber',
      width: 130,
      render: (IssueStatus) => {
        return (
          <Tag className={IssueStatus.code} color={MSS_STATUS_COLOR[IssueStatus.code || 'DR']}>
            {' '}
            {t(IssueStatus.name)}{' '}
          </Tag>
        );
      },
    },
    {
      title: t('Attachments'),
      dataIndex: 'Attachments',
      sorter: true,
      key: 'Attachments.name',
      onCell: () => {
        return {
          style: {
            maxWidth: '180px',
          },
        };
      },
      render: (_, record: BaseIssue) => {
        const attachments = record.Attachments?.map((attachment) => {
          return {
            filePath: attachment?.filePath,
            fileName: attachment?.name,
            hideDelete: true,
            id: attachment?.id,
          };
        });

        return <AttachmentsCell openPreview attachments={attachments} />;
      },
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit Method Statement',
            key: 'edit',
          },
          {
            label: 'Delete Method Statement',
            key: 'delete',
            isDisabled: (record) => {
              const userCompanyId = users?.find((user: User) => user.id === props.profile?.id)?.WorkspaceUser?.Company
                ?.id;
              const isDeleteRecord =
                userCompanyId ===
                users.find((user: User) => user.id === record?.originatorId)?.WorkspaceUser?.Company?.id;
              return !isDeleteRecord;
            },
          },
        ],
      },
    },
  ];

  return (
    <Layout.Content className='settings-content'>
      <DeleteConfirmation
        isModalOpen={isDelete.open}
        title={t('Delete the Method Statement')}
        description={t('Are you sure to delete this Method Statement?')}
        onDelete={handleDeleteBaseIssue}
        onCancel={() => setIsDelete({ open: false, methodStatementId: undefined })}
      />
      <DisciplineBaseIssueTable
        ref={props.disciplineBaseIssueTableRef}
        columns={columns}
        header={t('Discipline')}
        onActionClick={handleMenuClick}
        onRowClick={(record) => {
          editForm(record?.id || '');
        }}
        onMapDataSource={(baseIssue: BaseIssue) => {
          return {
            ...baseIssue,
            activity: {
              title: baseIssue?.MethodStatementSubmission?.Activity?.name,
            },
            subActivity: {
              title: baseIssue?.MethodStatementSubmission?.SubActivity?.name,
            },
          };
        }}
        projectBaseIssueParams={projectBaseIssueParams}
        loadAllListTrigger={projectBaseIssueParams}
        onTotalKpi={props.onTotalKpi}
        refreshDiscipline={props?.refreshDisciplineByFunction}
      />
    </Layout.Content>
  );
}
