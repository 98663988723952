import { Flex, Avatar, Typography, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import AvatarItem from './pre-signed-url-items/AvatarItem';
import TruncatedAttachmentDocument, { TruncatedAttachmentDocumentProps } from './TruncatedAttachmentDocument';

export type AttachmentDocumentUserInfo = {
  fullName?: string;
  firstName?: string;
  profilePhoto?: string;
  createdAt?: string;
  comment?: string;
};

export type AttachmentDocumentData = {
  attachmentDocuments: TruncatedAttachmentDocumentProps[];
  userInfo: AttachmentDocumentUserInfo;
};

export type AttachmentDocumentListProps = {
  data: AttachmentDocumentData[];
  hideDelete?: boolean;
  onRemoveFile: (filePath: string) => void;
  isLoading?: boolean;
};

const AttachmentDocumentList = (props: AttachmentDocumentListProps) => {
  const { t } = useTranslation();

  if (props?.data && props?.data?.length === 0) return null;
  return (
    <div className='attachment-document-list'>
      <Spin spinning={props.isLoading}>
        {props.data.map((content) => {
          if (content.attachmentDocuments.length === 0) return null;

          return (
            <div className='attachment-document-content' key={content.userInfo?.createdAt}>
              <Flex align='center' className='mb-[10px]' gap={4}>
                {content.userInfo.profilePhoto ? (
                  <AvatarItem size={'large'} style={{ width: 34, height: 34 }} src={content.userInfo.profilePhoto} />
                ) : (
                  <Avatar style={{ width: 34, height: 34 }}>{content.userInfo?.firstName?.substring(0, 1)}</Avatar>
                )}
                <Flex className='flex flex-col'>
                  <Typography.Text className='text-size-14'>
                    <span className='font-bold'>{content.userInfo.fullName}</span>
                    <span className='ml-1 italic'>{t('uploaded documents')}</span>
                  </Typography.Text>
                  <Typography.Text className='text-size-14 text-color-description'>
                    {dayjs(content.userInfo.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                  </Typography.Text>
                </Flex>
              </Flex>
              {content.userInfo?.comment && (
                <div className='mb-[10px]'>
                  <Typography.Text className='text-size-12 block' type='secondary'>
                    {t('Comment')}
                  </Typography.Text>
                  <Typography.Text className='text-size-14 text-color-polaris-black'>
                    {content.userInfo.comment}
                  </Typography.Text>
                </div>
              )}

              <div>
                <Typography.Text className='text-size-12 block mb-[10px]' type='secondary'>
                  {t('Documents')}
                </Typography.Text>
                <Flex gap={10} wrap>
                  {content.attachmentDocuments?.map((attachmentDocument) => {
                    return (
                      <TruncatedAttachmentDocument
                        key={attachmentDocument.filePath}
                        fileName={attachmentDocument.fileName}
                        filePath={attachmentDocument.filePath}
                        onRemoveFile={() => props.onRemoveFile(attachmentDocument.filePath || '')}
                        hideDelete={attachmentDocument.hideDelete || props.hideDelete}
                      />
                    );
                  })}
                </Flex>
              </div>
            </div>
          );
        })}
      </Spin>
    </div>
  );
};

export default AttachmentDocumentList;
