import { Project } from 'model';
import { Button, Col, Flex, Modal, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PROJECT_DEFAULT_IMAGE from 'assets/images/project-types/demo.jpeg';
import { ModuleIcon } from 'components/common';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';

type ProjectModulesModalProps = {
  selectedProject: Project | null | undefined;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};
export default function ProjectModulesModal(props: ProjectModulesModalProps) {
  const { selectedProject, isModalOpen, setIsModalOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const projectModules = [
    {
      color: '#405D72',
      disabled: false,
      key: 'document-management/designs',
      label: t('Doc. Management'),
    },
    {
      color: '#3069C4',
      disabled: false,
      key: 'quality/dashboard',
      label: t('Quality'),
    },
    {
      color: '#D37F07',
      disabled: false,
      key: 'safety/dashboard',
      label: t('Safety'),
    },
    {
      color: '#7263A8',
      disabled: true,
      key: '',
      label: t('Project'),
    },
    {
      color: '#B74E06',
      disabled: true,
      key: 'finance',
      label: t('Finance'),
    },
    {
      color: '#8401FE',
      disabled: true,
      key: 'procurement',
      label: t('Procurement'),
    },
    {
      color: '#741C47',
      disabled: true,
      key: 'construction',
      label: t('Construction'),
    },
    {
      color: '#8D9E0D',
      disabled: true,
      key: 'environment',
      label: t('Environment'),
    },
  ];
  const projectImage = selectedProject?.coverPath ?? selectedProject?.ProjectType?.picture ?? PROJECT_DEFAULT_IMAGE;

  const handleCardClick = (key: string) => {
    navigate(`/projects/${selectedProject?.id}/${key}`);
  };
  const handleQualityDashboardClick = () => {
    const key = 'quality/dashboard';
    navigate(`/projects/${selectedProject?.id}/${key}`);
  };
  return (
    <Modal open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} className='project-module__modal'>
      <Row justify='center' onClick={handleQualityDashboardClick} className='mt-7 project-module_img'>
        <ImgItem
          src={projectImage}
          alt={selectedProject?.name}
          style={{ height: 184 }}
          className='radius-sm max-w-full'
        />
      </Row>
      <Flex justify='center' vertical={true} className='mt-6'>
        <Typography.Text
          className='mb-3 project-module__title'
          onClick={handleQualityDashboardClick}
          style={{ cursor: 'pointer' }}
        >
          {selectedProject?.name}
        </Typography.Text>
        <Typography.Text className='mb-3 project-module__subtitle'>{t('Choose Module')}</Typography.Text>
      </Flex>
      <Row gutter={[16, 24]} justify={'center'} className='mb-6'>
        {projectModules.map((module, index) => (
          <Col key={index}>
            <Button
              onClick={() => {
                handleCardClick(module.key);
              }}
              className='project-module__button'
              icon={<ModuleIcon color={module.color} width={40} height={40} />}
              style={{ color: module.color }}
              disabled={module.disabled}
              block
            >
              <span className='project-module__text'>{module.label}</span>
            </Button>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}
