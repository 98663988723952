import { Button, ButtonProps, Row, Space } from 'antd';
import { ReactNode } from 'react';

export type WorkspaceViewType = 'detail' | 'controlStatus';

export enum EWIRActionType {
  SaveAsDraft = 'SaveAsDraft',
  Duplicate = 'Duplicate',
  InviteForInspection = 'InviteForInspection',
  Delete = 'Delete',
  Save = 'Save',
  Cancel = 'cancel',
  StartInspection = 'StartInspection',
  OpenStartInspectionModal = 'OpenStartInspectionModal',
  Transfer = 'Transfer',
}
export type WIRFooterProps = {
  buttons?: (ButtonProps & {
    label?: string;
    actionType?: EWIRActionType;
    position?: string;
  })[];
  onActionClick: (type: EWIRActionType) => void;
};

export default function WorkInspectionRequestFooter(props: WIRFooterProps) {
  const buttons = props?.buttons?.reduce<{ left: ReactNode[]; right: ReactNode[] }>(
    (init, button) => {
      const result = { left: init.left, right: init.right };
      if (button.position === 'left') {
        result.left.push(
          <Button {...button} onClick={() => button?.actionType && props.onActionClick(button?.actionType)}>
            {button.label}
          </Button>
        );
      } else {
        result.right.push(
          <Button
            type={'primary'}
            onClick={() => button?.actionType && props.onActionClick(button?.actionType)}
            {...button}
          >
            {button.label}
          </Button>
        );
      }
      return result;
    },
    { left: [], right: [] }
  );

  return (
    <Row>
      <Space>{buttons?.left}</Space>
      <Space style={{ marginLeft: 'auto' }}>{buttons?.right}</Space>
    </Row>
  );
}
