import picture from '../assets/images/pictures/_Image with Fixed Ratio_.png';
import picture1 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 2.png';
import picture2 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 3.png';
import picture3 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 4.png';
import picture4 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 5.png';
import picture5 from '../assets/images/pictures/_Image with Fixed Ratio_ copy 6.png';
import picture6 from '../assets/images/pictures/_Image with Fixed Ratio_ copy.png';
import logo from '../assets/images/pictures/logo.png';
import { BankFilled, ThunderboltOutlined, SketchOutlined, ToolOutlined } from '@ant-design/icons';

import { CategoriesType, ProjectType } from 'model/DataCustomization';

export const coreCategories: CategoriesType[] = [
  {
    key: '1',
    name: 'MEP contractor',
    code: '236',
    isCore: true,
  },
  {
    key: '2',
    name: 'General contractor',
    code: '952',
    isCore: true,
  },
  {
    key: '3',
    name: 'Design & build contractor',
    code: '148',
    isCore: true,
  },
];


export const coreRoles: CategoriesType[] = [
  {
    key: '1',
    name: 'Plumbing',
    code: '824',
    isCore: true,

  },
  {
    key: '2',
    name: 'Steel structure',
    code: '724',
    isCore: true,

  },
  {
    key: '3',
    name: 'Electricity',
    code: '541',
    isCore: true,

  },
];

export const projectTypes: ProjectType[] = [
  {
    key: 1,
    name: 'Factories',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 2,
    name: 'Retail stores',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 3,
    name: 'Offices',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 4,
    name: 'Residential buildings',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 5,
    name: 'Villas',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 6,
    name: 'Banks',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 7,
    name: 'Hospitals/Clinics',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 8,
    name: 'Labs',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 9,
    name: 'Cultural centers',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 10,
    name: 'Religious buildings',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 11,
    name: 'Building for sports activities',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 12,
    name: 'Airport',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 13,
    name: 'Rail',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 14,
    name: 'Roads/Highways',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 15,
    name: 'Bridge',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
  {
    key: 16,
    name: 'Hotel/Resort',
    isCore: true,
    picture: picture,
    icon: ThunderboltOutlined,
    iconColor: '#737373',
  },
];

