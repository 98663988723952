import { useMemo } from 'react';
import { QueryParams } from 'types';
import { ModuleFunction } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';

import { fetchModuleFunctions, fetchModules, selectModuleFunctions, selectModules } from 'store/common.slice';

export const useModuleFunctionParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();
  
  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [options?.moduleId]);
};

export const useModuleFunctions = (query: QueryParams) => {
  return useFetchByParams<ModuleFunction[]>({
    action: fetchModuleFunctions,
    dataSelector: selectModuleFunctions,
    params: query,
  });
};

export const useModulesParams = (options?: QueryParams) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, []);
};

export const useModules = (query: QueryParams & { name?: string }) => {
  return useFetchByParams<ModuleFunction[]>({
    action: fetchModules,
    dataSelector: selectModules,
    params: query,
  });
};
