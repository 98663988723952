import { AnimatedPage } from 'components/common';
import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setSelectedProjectId } from 'store/my-projects.slice';

export default function ProjectContent({ children }: { children: ReactNode }) {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedProjectId(projectId));
  }, [projectId]);

  return <AnimatedPage>{children}</AnimatedPage>;
}
