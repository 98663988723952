import {
  CaretDownOutlined,
  CommentOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Flex, Form, Popconfirm, Popover, Space, Spin, Tag, Typography } from 'antd';
import DocumentAdder, { AttachmentBaseIssue } from 'components/common/DocumentAdder';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import dayjs, { Dayjs } from 'dayjs';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import { BaseIssue, User } from 'model';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { ATTACHMENT_TYPES, INVITATION_ROLE, RFI_STATUSES } from 'utils/contants';
import { ERFIActionType } from '../CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import { EBaseIssueMessagesAction } from 'model/BaseIssueMessages';
import { useState } from 'react';
import ReactQuillCustom from 'components/common/ReactQuillCustom';
import { createRFIPermissionChecker } from 'utils/rfiHelper';

export type RFITheadValue = {
  localId: string;
  id?: string;
  title: string;
  updatedDate: string | Dayjs | Date;
  isMainThread?: boolean;
  toRecipients?: {
    companyId: string | null;
    userId: string | null;
  };
  ccRecipients?: {
    companyId: string | null;
    userIds: string[];
  }[];
  commentUserId?: string;
  comment?: string | null;
  attachments: AttachmentBaseIssue[];
  statusCode?: string;
  action: EBaseIssueMessagesAction;
};

export type RFIThreadFormValues = {
  thread: RFITheadValue[];
};

type Props = {
  loggedUser?: User | null;
  onActionClick: (type: ERFIActionType, thread?: RFITheadValue) => void;
  baseIssue?: BaseIssue | null;
};

const RequestForInformationDetailThread = (props: Props) => {
  const { t } = useTranslation();
  const form = useANTDFormInstance<RFIThreadFormValues>();
  const users = useAppSelector(selectProjectUsers);
  const formValue = Form.useWatch([], form) || form.getFieldsValue(true);
  const [loadingId, setLoadingId] = useState<string | null>(null);
  const [isEditThreadId, setisEditThreadId] = useState<string | null>(null);
  const [previousComment, setPreviousComment] = useState<{ content: string; attachments: AttachmentBaseIssue[] }>({
    content: '',
    attachments: [],
  });

  const renderTitle = (data: RFITheadValue) => {
    return (
      <Flex align='center'>
        <Tag className='text-size-16' color={!data.isMainThread ? 'orange' : 'default'}>
          {data.isMainThread ? t('Title') : t('Replied')}
        </Tag>
        <Typography.Title level={3}>{data?.title}</Typography.Title>
        <Typography.Text type='secondary' className='ml-2 text-size-16'>
          {data.action === EBaseIssueMessagesAction.COMMENT_EDIT && t('(edited)')}
        </Typography.Text>
        <Typography.Text type='secondary' className='ml-auto text-size-14 font-normal'>
          {dayjs(data?.updatedDate).format('DD-MM-YYYY hh:mm:ss')}
        </Typography.Text>
      </Flex>
    );
  };

  const renderToPopoverContent = ({ title, data }: { title: string; data: { users: User[] }[] }) => {
    return (
      <Flex gap={8}>
        <Typography.Text type='secondary' className='text-size-14 w-[24px]'>
          {title}
        </Typography.Text>
        <Flex vertical>
          {data.map((user) => {
            return (
              <Flex vertical key={user?.users[0].WorkspaceUser?.Company?.id}>
                <Typography.Text className='font-bold tex-size-14'>
                  {user?.users[0].WorkspaceUser?.Company?.name}
                </Typography.Text>
                {user?.users.map((user) => (
                  <Typography.Text key={user.id} className='text-size-14'>
                    {user?.fullName} {`(${user.email})`}
                  </Typography.Text>
                ))}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  const renderSender = (thread: RFITheadValue) => {
    const commentUser = users?.find((user) => user.id === thread?.commentUserId);
    const toRecipient = users?.find((user) => user.id === thread?.toRecipients?.userId);
    const ccRecipients = thread?.ccRecipients?.map((ccRecipient) => {
      return {
        users: users.filter((user) => ccRecipient.userIds.includes(user.id)),
      };
    });

    const to = [
      toRecipient?.fullName?.trim(),
      ...(ccRecipients?.flatMap((ccRecipient) => ccRecipient.users?.map((user) => user?.fullName?.trim())) || []),
    ]
      .filter(Boolean)
      .join(', ');

    return (
      <Flex align='center' gap={8}>
        {commentUser?.profilePhoto ? (
          <AvatarItem size={'large'} style={{ width: 64, height: 64 }} src={commentUser?.profilePhoto} />
        ) : (
          <Avatar style={{ width: 64, height: 64, fontSize: '16px' }}>{commentUser?.firstName?.substring(0, 1)}</Avatar>
        )}

        <Flex vertical>
          <Space size={4}>
            <Typography.Title level={3}>{commentUser?.fullName}</Typography.Title>
            <Typography.Text type='secondary' className='text-size-16'>
              {commentUser?.email ? `(${commentUser?.email})` : ''}
            </Typography.Text>
          </Space>
          <Typography.Text type='secondary' className='text-size-16'>
            {commentUser?.WorkspaceUser?.Company?.name}
          </Typography.Text>
          {to && (
            <div>
              <Typography.Text className='text-size-16'>
                {'To:'} {to}
              </Typography.Text>

              <Popover
                content={() => {
                  return (
                    <Space direction='vertical'>
                      {toRecipient &&
                        renderToPopoverContent({
                          title: 'To:',
                          data: [
                            {
                              users: [toRecipient],
                            },
                          ],
                        })}
                      {ccRecipients && renderToPopoverContent({ title: 'CC:', data: ccRecipients })}
                    </Space>
                  );
                }}
                placement='rightTop'
                trigger='click'
              >
                <CaretDownOutlined />
              </Popover>
            </div>
          )}
        </Flex>
      </Flex>
    );
  };

  const renderAction = (fieldValue: RFITheadValue, onRemove: () => void, index: number) => {
    const isEditThread = isEditThreadId === fieldValue?.id;
    const numberOfReply = formValue.thread
      .slice(1)
      ?.filter(
        (thread) =>
          thread.action === EBaseIssueMessagesAction.COMMENT || thread.action === EBaseIssueMessagesAction.COMMENT_EDIT
      )?.length;
    const checkPermission = createRFIPermissionChecker(
      props?.baseIssue?.originatorId,
      props?.loggedUser as User,
      props?.baseIssue?.BaseIssueWatchers,
      props?.baseIssue?.IssueStatus?.code || ''
    );

    const isEnableSendButton = !(
      props.baseIssue?.name &&
      props.baseIssue?.InformationRequest?.priorityId &&
      props.baseIssue?.InformationRequest?.disciplineId &&
      props?.baseIssue?.BaseIssueWatchers?.some((user) => user.role === INVITATION_ROLE.RECEIVER)
    );

    return (
      <Flex gap={8} align='center' className='mt-4'>
        {checkPermission(ERFIActionType.DeleteForm) && fieldValue.isMainThread && (
          <Button
            onClick={() => props.onActionClick(ERFIActionType.DeleteForm)}
            danger
            type='primary'
            icon={<DeleteOutlined />}
          />
        )}
        <Flex className='ml-auto' gap={8}>
          {fieldValue.isMainThread && (
            <Flex align='center' gap={4}>
              <CommentOutlined style={{ fontSize: '16px' }} />{' '}
              {formValue?.thread?.length > 1 ? (
                <Typography.Text>
                  {numberOfReply} {t('Reply')}
                </Typography.Text>
              ) : (
                ''
              )}
            </Flex>
          )}
          {checkPermission(ERFIActionType.Close) && fieldValue.isMainThread && (
            <Button
              onClick={() => {
                props.onActionClick(ERFIActionType.Close);
              }}
              style={{ backgroundColor: '#73D13D', color: 'white', marginLeft: 0 }}
            >
              {t('Close the RFI')}
            </Button>
          )}
          {!isEditThread &&
            !fieldValue.isMainThread &&
            props.loggedUser?.id === fieldValue.commentUserId &&
            props?.baseIssue?.IssueStatus?.code !== RFI_STATUSES.CLOSED && (
              <Popconfirm
                icon={<ExclamationCircleOutlined style={{ color: 'red', marginRight: 8 }} />}
                title={
                  <div>
                    <span style={{ fontWeight: 'lighter', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.88)' }}>
                      {t('Delete your reply')}
                    </span>
                    <p>{t('Are you sure to delete this reply?')}</p>
                  </div>
                }
                onConfirm={onRemove} // Confirm deletion logic
                okText='Yes'
                cancelText='No'
              >
                <DeleteTwoTone twoToneColor='red' style={{ fontSize: '24px', cursor: 'pointer' }} />
              </Popconfirm>
            )}
          {fieldValue.isMainThread && checkPermission(ERFIActionType.EditForm) && (
            <Button
              onClick={() => {
                props.onActionClick(ERFIActionType.EditForm);
              }}
              type='default'
              style={{ marginLeft: 0 }}
            >
              {t('Edit my RFI')}
            </Button>
          )}
          {!isEditThread &&
            !fieldValue.isMainThread &&
            props.loggedUser?.id === fieldValue.commentUserId &&
            props?.baseIssue?.IssueStatus?.code !== RFI_STATUSES.CLOSED && (
              <Button
                onClick={() => {
                  setisEditThreadId(fieldValue.id as string);
                  setPreviousComment({ content: fieldValue.comment || '', attachments: fieldValue.attachments });
                }}
                type='default'
              >
                {t('Edit')}
              </Button>
            )}
          {fieldValue.isMainThread && checkPermission(ERFIActionType.SendToRecipient) && (
            <Button
              type='primary'
              className='!ml-[0px] !normal-case'
              disabled={!!isEnableSendButton}
              onClick={() => props.onActionClick(ERFIActionType.SendToRecipient)}
            >
              {'Send To Recipient(s)'}
            </Button>
          )}
          {isEditThread && (
            <Button
              type='default'
              onClick={() => {
                setisEditThreadId(null);
                fieldValue.comment = previousComment.content;
                fieldValue.attachments = previousComment.attachments;
                form.setFieldValue(['thread', index], fieldValue);
              }}
            >
              {'Cancel'}
            </Button>
          )}

          {isEditThread && (
            <Button
              type='primary'
              className='!ml-[0px] !normal-case'
              onClick={async () => {
                setLoadingId(fieldValue?.id as string);
                await props.onActionClick(ERFIActionType.EditThread, fieldValue);
                setLoadingId(null);
                setisEditThreadId(null);
              }}
            >
              {'Save'}
            </Button>
          )}
        </Flex>
      </Flex>
    );
  };

  const parseAddedUser = (comment: string) => {
    try {
      const parsedData = JSON.parse(comment);
      const user = users.find((user) => user.id === parsedData?.to);
      return user; // JSON is valid; returns parsed object
    } catch {
      return null; // JSON is invalid; returns null or any error-handling logic
    }
  };

  const renderMessageAlert = (thread: RFITheadValue) => {
    const commentUser = users?.find((user) => user.id === thread?.commentUserId);
    const addedUser = parseAddedUser(thread?.comment || '') as User;

    return (
      <Flex align='center' gap={16} className={`message-alert ${thread?.action?.toLowerCase()}`}>
        {commentUser?.profilePhoto ? (
          <AvatarItem size={'large'} style={{ width: 34, height: 34 }} src={commentUser?.profilePhoto} />
        ) : (
          <Avatar style={{ width: 64, height: 64, fontSize: '16px' }}>{commentUser?.firstName?.substring(0, 1)}</Avatar>
        )}

        <Flex vertical>
          <Space size={4}>
            <Typography.Text className='text-color-polaris-black font-bold text-size-14'>
              {commentUser?.fullName}
            </Typography.Text>
            <Typography.Text className='text-size-14 text-color-polaris-black italic action-color'>
              {thread.action === EBaseIssueMessagesAction.COMMENT_DELETE
                ? t('deleted a message')
                : t(`added ${addedUser?.fullName} from ${commentUser?.WorkspaceUser?.Company?.name}`)}
            </Typography.Text>
          </Space>
          <Typography.Text type='secondary' className='text-size-12'>
            {commentUser?.WorkspaceUser?.Company?.name}
          </Typography.Text>
        </Flex>

        <Typography.Text type='secondary' className='ml-auto text-size-14 action-color'>
          {dayjs(thread?.updatedDate).format('MM-DD-YYYY hh:mm:ss')}
        </Typography.Text>
      </Flex>
    );
  };

  const renderReadOnlyThread = (fieldValue: RFITheadValue, name: number) => {
    return (
      <>
        {fieldValue.comment && (
          <ReactQuillCustom placeholder='' value={fieldValue.comment as string} readOnly={true} theme={'bubble'} />
        )}
        <Divider />
        <DocumentAdder
          title={`${t('Your Reply')}:`}
          attachmentType={ATTACHMENT_TYPES.BASE_DOCUMENT}
          onChange={(attachments) => {
            form.setFieldValue(['thread', name, 'attachments'], attachments);
          }}
          documentsInfo={fieldValue.attachments}
          layout='inline'
          readOnlyTitle={`${t('Attachments')} (${fieldValue.attachments?.length})`}
        />
      </>
    );
  };

  const renderEditThread = (fieldValue: RFITheadValue, name: number) => {
    return (
      <>
        <DocumentAdder
          title={`${t('Your Reply')}:`}
          attachmentType={ATTACHMENT_TYPES.BASE_DOCUMENT}
          onChange={(attachments) => {
            form.setFieldValue(['thread', name, 'attachments'], attachments);
          }}
          documentsInfo={fieldValue?.attachments}
          layout='inline'
        />
        <ReactQuillCustom
          value={fieldValue?.comment || ''}
          onChange={(value) => {
            form.setFieldValue(['thread', name, 'comment'], value);
          }}
        />
      </>
    );
  };

  return (
    <Form.List name='thread' initialValue={[]}>
      {(fields) => (
        <Flex vertical gap={30}>
          {fields.map(({ key, name }) => {
            const fieldValue = form.getFieldValue(['thread', name]);
            if (
              fieldValue.action === EBaseIssueMessagesAction.COMMENT_DELETE ||
              fieldValue.action === EBaseIssueMessagesAction.ADD_USER
            ) {
              return renderMessageAlert(fieldValue);
            }

            return (
              <Spin spinning={loadingId === fieldValue.id} key={fieldValue.localId}>
                <Card
                  key={key}
                  title={renderTitle(fieldValue)}
                  styles={{
                    header: {
                      background: '#F0F0F0',
                      borderBottom: '1px solid #D9D9D9',
                    },
                  }}
                >
                  {renderSender(fieldValue)}
                  {isEditThreadId === fieldValue?.id
                    ? renderEditThread(fieldValue, name)
                    : renderReadOnlyThread(fieldValue, name)}
                  {renderAction(
                    fieldValue,
                    async () => {
                      setLoadingId(fieldValue?.id as string);
                      await props.onActionClick(ERFIActionType.DeleteThread, fieldValue);
                      setLoadingId(null);
                    },
                    name
                  )}
                </Card>
              </Spin>
            );
          })}
        </Flex>
      )}
    </Form.List>
  );
};

export default RequestForInformationDetailThread;
