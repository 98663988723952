import { CloseCircleOutlined, EllipsisOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Layout, MenuProps, Table, TableColumnsType, TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TablePagination } from 'components';
import CustomTooltip from 'components/common/CustomTooltip';
import UserCompany from 'components/common/UserCompany';
import dayjs from 'dayjs';
import { BaseIssue, BaseIssueWatcher, Company, IssueStatus, User } from 'model';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRA_PRIORITIES_COLOR, TRA_STATUS_COLOR, TRA_STATUSES, NUMBERING_DIGITS } from 'utils/contants';
import { QueryParams } from 'types';
import AttachmentRender from 'components/common/AttachmentRender';
import { useDeleteBaseIssue } from 'hooks/common/useDeleteBaseissue';
import { TablePaginationIssueParams } from 'components/common/TablePagination';

type ListViewProps = {
  users: User[];
  profile: User | null;
  module: string;
  projectId: string;
  workspaceId: string;
  dataList: BaseIssue[];
  dataListCount: number;
  dataListParams: QueryParams;
  companies: Company[];
  loading?: boolean;
  onEditIssue: (value: string) => void;
  refreshBaseIssues: () => void;
  refreshUser: () => void;
  onFilter: (
    value: TablePaginationIssueParams & {
      orderBy?: string;
    }
  ) => void;
};
type PriorityCode = 'STANDARD' | 'CONFIDENTIAL' | 'IMPORTANT' | 'URGENT';

export default function DocumentsTransmissionListView(props: ListViewProps) {
  const {
    users,
    loading,
    dataList,
    workspaceId,
    projectId,
    companies,
    profile,
    dataListCount,
    dataListParams,
    onEditIssue,
    onFilter,
    refreshBaseIssues,
  } = props;
  const { t } = useTranslation();
  const [dateFormat] = useState<string>('YYYY-MM-DD');
  const [locale] = useState<string>('en');

  const { openDeleteConfirm } = useDeleteBaseIssue({
    title: t('Delete the Documents Transmission'),
    description: t('Are you sure to delete this Documents Transmission?'),
  });

  const handleMenuClick = (info: MenuInfo, docTransmissionId: string) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'view':
          onEditIssue(docTransmissionId);
          break;
        case 'delete':
          openDeleteConfirm(workspaceId, projectId, docTransmissionId).then(() => {
            refreshBaseIssues();
          });
          break;
      }
    }
  };

  function numberingPadding(inputString: string) {
    const parts = inputString.split('-');
    const lastPart = parts.pop(); // Extract the last part
    if (!lastPart) return inputString;
    const paddedLastPart = lastPart.padStart(NUMBERING_DIGITS, '0'); // Pad the last part with zeros
    parts.push(paddedLastPart); // Push the padded last part back to the array
    return parts.join('-'); // Join all parts back together
  }
  const items: MenuProps['items'] = [
    {
      label: t('View'),
      key: 'view',
      icon: <EyeOutlined />,
      danger: false,
      disabled: false,
    },
    {
      label: t('Delete'),
      key: 'delete',
      icon: <CloseCircleOutlined />,
      danger: true,
      disabled: true,
    },
  ];
  const menuProps: MenuProps = { items };
  const renderStatusTag = (value: IssueStatus) => {
    return <Tag color={TRA_STATUS_COLOR[value.code as keyof typeof TRA_STATUS_COLOR]}> {t(value.name)} </Tag>;
  };

  const columns: TableColumnsType<BaseIssue> = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'DocumentTransmission',
      key: 'DocumentTransmission.dtNumber',
      className: 'dt-number-column',
      width: 50,
      onCell: () => {
        return {
          style: {
            maxWidth: '200px',
          },
        };
      },
      render: (DocumentTransmission) => {
        return (
          <CustomTooltip
            title={DocumentTransmission?.dtNumber ? numberingPadding(DocumentTransmission?.dtNumber) : ''}
          />
        );
      },
    },
    {
      sorter: true,
      title: t('Subject'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return <CustomTooltip title={name} />;
      },
      width: '300px',
      onCell: () => {
        return {
          style: {
            maxWidth: '500px',
          },
        };
      },
    },
    {
      title: t('Priority'),
      dataIndex: 'DocumentTransmission',
      key: 'DocumentTransmission.IssuePriority.name',
      render: (DocumentTransmission) => {
        const color = DocumentTransmission?.IssuePriority?.code as PriorityCode;
        return (
          <span style={{ color: TRA_PRIORITIES_COLOR[color] || 'defaultColor' }}>
            {DocumentTransmission?.IssuePriority?.name}{' '}
          </span>
        );
      },
      width: 100,
    },
    {
      title: t('Issued to'),
      dataIndex: 'BaseIssueWatchers',
      key: 'BaseIssueWatchers',
      className: 'dt-company-column',
      render: (BaseIssueWatchers: BaseIssueWatcher[]) => {
        const filter = BaseIssueWatchers?.filter((receiver) => receiver.role === 'receiver');
        const receivers = filter.map((receiver) => {
          const user = users?.find((user) => user.id === receiver.watcherId);
          return {
            userName: user?.fullName || '',
            color: 'default',
            companyName: user?.WorkspaceUser?.Company?.name || '',
          };
        });

        return <UserCompany data={receivers} />;
      },
    },
    {
      title: t('Distribution'),
      dataIndex: 'BaseIssueWatchers',
      key: 'BaseIssueWatchers',
      className: 'dt-company-column',
      render: (BaseIssueWatchers: BaseIssueWatcher[]) => {
        const distributions = BaseIssueWatchers?.filter((distribution) => distribution.role === 'distribution').map(
          (distribution) => {
            const user = users?.find((user) => user.id === distribution.watcherId);
            const company = companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
            return {
              companyName: company?.name || '',
              userName: user?.fullName || '',
              color: 'default',
            };
          }
        );
        return <UserCompany data={distributions} />;
      },
    },
    {
      sorter: true,
      title: t('Issue Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
      render: (createdAt) => (
        <span>{createdAt ? dayjs(createdAt).locale(locale).format(dateFormat).toString() : ''}</span>
      ),
    },
    {
      sorter: true,
      title: t('Status'),
      dataIndex: 'IssueStatus',
      key: 'IssueStatus',
      render: (value) => renderStatusTag(value),
      width: 100,
    },
    {
      align: 'center',
      title: t('Transmittal Letter'),
      dataIndex: 'Letter',
      key: 'Letter',
      width: 50,
      render: () => {
        return (
          <Flex justify='center' align='center'>
            <Button
              type='link'
              icon={<FilePdfOutlined />}
              className='ml-0 pa-0 d-flex'
              style={{ height: 'auto' }}
            ></Button>
          </Flex>
        );
      },
    },
    {
      align: 'right',
      dataIndex: 'id',
      width: 50,
      onCell: () => ({
        onClick: (event) => {
          event.stopPropagation();
        },
      }),
      render: (value, record: BaseIssue) => {
        const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
        const isDeleteRecord =
          userCompanyId === users.find((user) => user.id === record?.originatorId)?.WorkspaceUser?.Company?.id;
        // Disable delete button
        let updatedMenuProps = { ...menuProps };
        if (menuProps.items) {
          const updatedItems = menuProps.items.map((item) => {
            if (item?.key === 'delete') {
              return {
                ...item,
                disabled: !isDeleteRecord || record.IssueStatus?.code === TRA_STATUSES.ISSUED,
              };
            }
            return item;
          });
          updatedMenuProps = { ...menuProps, items: updatedItems };
        }
        return (
          <>
            <Flex justify={'flex-end'}>
              <Dropdown
                menu={{
                  ...updatedMenuProps,
                  onClick: (info) => handleMenuClick(info, value),
                }}
                trigger={['click']}
              >
                <EllipsisOutlined className='icon-ellipse' />
              </Dropdown>
            </Flex>
          </>
        );
      },
    },
  ];
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BaseIssue>
  ) => {
    if (sorter) {
      const sorterOrder = sorter.order === 'ascend' ? `asc` : sorter.order === 'descend' ? 'desc' : null;
      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        if (sorter.columnKey === 'IssueStatus') {
          orderBy = `IssueStatus.orderNumber,${sorterOrder}`;
        } else {
          orderBy = `${sorter.columnKey},${sorterOrder}`;
        }
      }
      onFilter({ orderBy });
    }
  };

  return (
    <Layout.Content className='doc-transmission-content'>
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={dataListParams}
        issuesCount={dataListCount}
      />
      <Table
        className='doc-transmission-table my-4 doc-transmission-table'
        columns={columns}
        expandable={{
          rowExpandable: (record) => {
            const attachments = record?.DocumentTransmission?.DtAttachment;
            return !!(attachments && attachments.some((opt) => opt.name));
          },
          expandedRowRender: (record) => {
            const attachments = record?.DocumentTransmission?.DtAttachment;
            if (!attachments || attachments.length === 0) return null;

            return (
              <Flex className='doc-transmission-expandable' justify='flex-start'>
                {attachments.map((opt, idx) => opt.name && <AttachmentRender key={idx} name={opt.name} />)}
              </Flex>
            );
          },
        }}
        rowKey={(row) => row.id}
        dataSource={dataList}
        loading={loading}
        pagination={false}
        scroll={{ x: 'max-content' }}
        onChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter as SorterResult<BaseIssue>)
        }
        onRow={(record) => ({
          onClick: () => onEditIssue(record.id),
        })}
      />
      <TablePagination
        onSubmit={(value) => {
          onFilter(value);
        }}
        issueParams={dataListParams}
        issuesCount={dataListCount}
      />
    </Layout.Content>
  );
}
