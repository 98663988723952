import { Row, Col, Button } from 'antd';
import { BaseIssue, User } from 'model';
import { useTranslation } from 'react-i18next';
import { MDI_STATUSES, VALIDATION_STATUS } from 'utils/contants';

type MDIsFooterProps = {
  isSubmit?: boolean;
  isSubmitAsDraft?: boolean;
  isDisableSubmit: boolean;
  selectedMdiBaseIssue: BaseIssue | null;
  userReviewed: string[];
  profile: User | null;
  onClose: () => void;
  onSubmit: () => void;
  onInspection: () => void;
  onSubmitAsDraft: () => void;
  onEdit?: () => void;
  onSave?: () => void;
};

export default function MDIsFooter(props: MDIsFooterProps) {
  const {
    isSubmit,
    isSubmitAsDraft,
    isDisableSubmit,
    selectedMdiBaseIssue,
    userReviewed,
    profile,
    onSubmit,
    onInspection,
    onSubmitAsDraft,
  } = props;
  const { t } = useTranslation();
  const isDraft = selectedMdiBaseIssue?.IssueStatus?.code === MDI_STATUSES.DRAFT;
  const isApproved = selectedMdiBaseIssue?.IssueStatus?.code === MDI_STATUSES.APPROVED;
  const isRejected = selectedMdiBaseIssue?.IssueStatus?.code === MDI_STATUSES.REJECTED;
  const isValidator = userReviewed.includes(profile?.id || '');
  const originator = selectedMdiBaseIssue?.originatorId === profile?.id;
  const currentUserValidation = selectedMdiBaseIssue?.BaseValidations?.find((val) => val.validatorId === profile?.id);
  const isReviewed = currentUserValidation?.validationStatus !== VALIDATION_STATUS.IN_REVIEW;

  return (
    <Row gutter={16} justify={'end'} align='middle'>
      <Col span={12} style={{ textAlign: 'right' }}>
        {!selectedMdiBaseIssue || isDraft ? (
          <>
            {originator && isDraft ? (
              <>
                <Button onClick={onSubmitAsDraft} loading={isSubmitAsDraft}>
                  {t('Save')}
                </Button>
                <Button type='primary' disabled={isDisableSubmit} loading={isSubmit} onClick={onSubmit}>
                  {t('Invite for Inspection')}
                </Button>
              </>
            ) : (
              !originator &&
              !isDraft && (
                <>
                  <Button onClick={onSubmitAsDraft} loading={isSubmitAsDraft}>
                    {t('Save as Draft')}
                  </Button>
                  <Button type='primary' disabled={isDisableSubmit} loading={isSubmit} onClick={onSubmit}>
                    {t('Invite for Inspection')}
                  </Button>
                </>
              )
            )}
          </>
        ) : (
          <Button
            type='primary'
            loading={isSubmit}
            onClick={onInspection}
            disabled={!isValidator || isReviewed || isApproved || isRejected}
          >
            {t('Start Inspection')}
          </Button>
        )}
      </Col>
    </Row>
  );
}
