import { CommentOutlined, TagOutlined } from '@ant-design/icons';
import { Drawer, Form, message, Spin, Tabs, TabsProps } from 'antd';
import { MDIsAttachment, MDIsDetail, MDIsFooter, MDIsHeader, MDIsHistory, MDIsValidation } from 'components';
import dayjs from 'dayjs';
import {
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useMyWorkspaceWorkPackagesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectWorkPackages,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ISSUE_DISCIPLINES,
  ISSUE_PRIORITIES,
  MDI_STATUSES,
  MODULE_FUNCTIONS,
  TASK_STATUS_HEADER_COLOR,
  VALIDATION_STATUS,
} from 'utils/contants';
import { baseIssueService } from 'services';
import { AttachmentInfo, BaseIssue, BaseValidation, Company, MDIsValidationHistory, ModuleFunction, User } from 'model';
import { titleCase } from 'utils';
import MDIsValidationHistoryStep from './HistoryMDI/MDIsValidationHistoryStep';
import useUserEntity from 'hooks/common/useUserProfile';

type CreateOrUpdateMDIProps = {
  MARs: BaseIssue[];
  moduleFunction: ModuleFunction[];
  isOpen: boolean;
  isEdit: boolean;
  isReject: boolean;
  isCreate: boolean;
  isDraft: boolean;
  module: string;
  workspaceId: string;
  baseIssueId: string | null;
  projectId: string;
  idRecord: string | undefined;
  setOpen: (value: boolean) => void;
  setEdit: (value: boolean) => void;
  setCreate: (value: boolean) => void;
  setIsDraft: (value: boolean) => void;
  users: User[];
  refreshBaseIssues: (disciplineId: string) => void;
  companyOriginator: string | undefined;
  setBaseIssueId: (value: string | null) => void;
  profile: User | null;
};
export type WorkspaceViewType = 'detail' | 'attachments' | 'comments' | 'history';

export default function CreateOrUpdateMDI(props: CreateOrUpdateMDIProps) {
  const { t } = useTranslation();
  const {
    users,
    isOpen,
    isEdit,
    module,
    workspaceId,
    projectId,
    setOpen,
    isReject,
    baseIssueId,
    isCreate,
    setEdit,
    setCreate,
    moduleFunction,
    MARs,
    refreshBaseIssues,
    companyOriginator,
    setBaseIssueId,
    profile,
    isDraft,
    setIsDraft,
  } = props;

  const [form] = Form.useForm();
  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.MATERIAL_DELIVERY_REQUEST,
  });
  const { user } = useUserEntity({ projectId, workspaceId });

  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);

  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [createMDIData, setCreateMDIData] = useState({
    originatorId: '',
    companyOriginatorId: '',
  });
  const [selectedMdiBaseIssue, setSelectedMdiBaseIssue] = useState<BaseIssue | null>(null);
  const [requestName, setRequestName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userReviewed, setUserReviewed] = useState<string[]>([]);
  const [documentsInfo, setDocumentsInfo] = useState<AttachmentInfo[]>([]);
  const values = Form.useWatch([], form);
  const [workPackagesQuery] = useMyWorkspaceWorkPackagesParams({
    projectId,
    workspaceId,
  });
  const [photosInfo, setPhotosInfo] = useState<AttachmentInfo[]>([]);
  const [workPackages] = useProjectWorkPackages(workPackagesQuery);
  const companyHistories: MDIsValidationHistory[] = useMemo(() => {
    if (!selectedMdiBaseIssue?.BaseValidations) return [];

    const history: MDIsValidationHistory[] = [];
    const userReviews: string[] = [];
    const companyReviews: string[] = [];

    const convertCompliancy = (value: boolean | null | undefined) => {
      switch (value) {
        case undefined:
          return null;
        default:
          return value;
      }
    };

    const addComment = (
      companyIndex: number,
      validation: BaseValidation,
      user: User | undefined,
      status: string,
      companyName: string
    ) => {
      const attachments = validation?.MdiValidation?.Attachments || [];
      const attachmentInfos = attachments.map((attachment) => ({
        id: attachment.id,
        filePath: attachment.filePath,
        name: attachment.name,
        attachmentTypeId: attachment.attachmentTypeId,
        userCreated: attachment.MdiValidationAttachment?.userCreated,
        comment: attachment.MdiValidationAttachment?.comment,
      }));
      history[companyIndex].comments.push({
        id: validation.id,
        comment: validation.comment,
        user: user?.fullName || '',
        date: dayjs(validation.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
        company: companyName,
        attachment: attachmentInfos,
        technicalDocumentCompliancy: convertCompliancy(validation?.MdiValidation?.technicalDocumentCompliancy),
        quantityCompliancy: convertCompliancy(validation?.MdiValidation?.quantityCompliancy),
        overallCompliancy: convertCompliancy(validation?.MdiValidation?.overallCompliancy),
        physicalConditionCompliancy: convertCompliancy(validation?.MdiValidation?.physicalConditionCompliancy),
        status: {
          code: status,
          name: titleCase(t(status)),
        },
      });
    };

    const addCompany = (
      validation: BaseValidation,
      user: User | undefined,
      status: string,
      company: Company | undefined,
      companyId: string
    ) => {
      if (company) {
        const attachments = validation?.MdiValidation?.Attachments || [];
        const attachmentInfos = attachments.map((attachment) => ({
          id: attachment.id,
          filePath: attachment.filePath,
          name: attachment.name,
          attachmentTypeId: attachment.attachmentTypeId,
          userCreated: attachment.MdiValidationAttachment?.userCreated,
          comment: attachment.MdiValidationAttachment?.comment,
        }));
        history.push({
          id: companyId,
          company: company?.name || '',
          code: company?.companyCode || '',
          status,
          comments: [
            {
              id: validation.id,
              comment: validation.comment,
              user: user?.fullName || '',
              date: dayjs(validation.updatedAt).format('DD-MM-YYYY HH:mm:ss'),
              company: company?.name || '',
              attachment: attachmentInfos,
              technicalDocumentCompliancy: convertCompliancy(validation?.MdiValidation?.technicalDocumentCompliancy),
              quantityCompliancy: convertCompliancy(validation?.MdiValidation?.quantityCompliancy),
              overallCompliancy: convertCompliancy(validation?.MdiValidation?.overallCompliancy),
              physicalConditionCompliancy: convertCompliancy(validation?.MdiValidation?.physicalConditionCompliancy),
              status: {
                code: status,
                name: titleCase(t(status)),
              },
            },
          ],
        });
      }
    };

    selectedMdiBaseIssue.BaseValidations.forEach((validation, index) => {
      const user = users.find((u) => u.id === validation.validatorId);
      const findCompanyIndex = history.findIndex((h) => h.id === validation.validatorCompanyId);

      const currentCompanyId =
        validation.validationStatus === VALIDATION_STATUS.REJECTED ||
        validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? validation.validatorCompanyId
          : '';
      const currentCompany =
        validation.validationStatus === VALIDATION_STATUS.REJECTED ||
        validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? validation.ValidatorCompany
          : undefined;
      const currentUser =
        validation.validationStatus === VALIDATION_STATUS.REJECTED ||
        validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? user
          : undefined;
      const currentStatus =
        validation.validationStatus === VALIDATION_STATUS.REJECTED
          ? VALIDATION_STATUS.REJECTED
          : validation.validationStatus === VALIDATION_STATUS.APPROVED
            ? VALIDATION_STATUS.APPROVED
            : VALIDATION_STATUS.IN_REVIEW;
      const lastComment =
        validation.validationStatus === VALIDATION_STATUS.REJECTED ||
        validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? validation.comment
          : '';

      if (index === 0) {
        if (currentCompany) {
          addCompany(
            { ...validation, comment: lastComment },
            currentUser,
            currentStatus,
            currentCompany,
            currentCompanyId
          );
          companyReviews.push(currentCompanyId);
          userReviews.push(currentUser?.id || '');
        }
        if (
          validation.validationStatus !== VALIDATION_STATUS.REJECTED &&
          validation.validationStatus !== VALIDATION_STATUS.APPROVED &&
          validation.prvValidatorCompanyId !== validation.validatorCompanyId
        ) {
          addCompany(
            validation,
            user,
            validation.validationStatus,
            validation.ValidatorCompany,
            validation.validatorCompanyId
          );
          companyReviews.push(validation.validatorCompanyId);
          userReviews.push(user?.id || '');
        } else if (
          validation.validationStatus !== VALIDATION_STATUS.REJECTED &&
          validation.validationStatus !== VALIDATION_STATUS.APPROVED
        ) {
          const companyIndex = history.findIndex((h) => h.id === validation.validatorCompanyId);
          addComment(
            companyIndex,
            validation,
            user,
            validation.validationStatus,
            validation.ValidatorCompany?.name || ''
          );
          userReviews.push(user?.id || '');
        }
      } else if (findCompanyIndex === -1) {
        addCompany(
          validation,
          user,
          validation.validationStatus,
          validation.ValidatorCompany,
          validation.validatorCompanyId
        );
        companyReviews.push(validation.validatorCompanyId);
        userReviews.push(user?.id || '');
      } else {
        addCompany(
          validation,
          user,
          validation.validationStatus,
          validation.ValidatorCompany,
          validation.validatorCompanyId
        );
        companyReviews.push(validation.validatorCompanyId);
        userReviews.push(user?.id || '');
      }
    });
    if (selectedMdiBaseIssue && selectedMdiBaseIssue.BaseValidations.length > 0) {
      const originatorUser = users.find((user) => user.id === selectedMdiBaseIssue.originatorId);
      const originatorCompany = companies.find((company) => company.id === selectedMdiBaseIssue.companyOriginatorId);
      history.push({
        id: selectedMdiBaseIssue.companyOriginatorId,
        company: originatorCompany?.name || '',
        code: originatorCompany?.companyCode || '',
        status: VALIDATION_STATUS.SUBMITTED,
        comments: [
          {
            id: selectedMdiBaseIssue.id,
            comment: '',
            user: originatorUser?.fullName || '',
            date: dayjs(selectedMdiBaseIssue.createdAt).format('DD-MM-YYYY HH:mm:ss'),
            company: originatorCompany?.name || '',
            attachment: [],
            technicalDocumentCompliancy: null,
            quantityCompliancy: null,
            overallCompliancy: null,
            physicalConditionCompliancy: null,
            status: {
              code: VALIDATION_STATUS.SUBMITTED,
              name: titleCase(t(VALIDATION_STATUS.SUBMITTED)),
            },
          },
        ],
      });
    }
    setUserReviewed(userReviews);
    return history.reverse();
  }, [selectedMdiBaseIssue]);

  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: t('Material Delivery Inspection details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'comments',
      label: t('Control Status'),
      disabled: companyHistories.length <= 0,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  const onChangeForm = (value: { name: string }) => {
    if (Object.keys(value)?.[0] === 'name') {
      setRequestName(value.name ?? '');
    }
  };
  const onClose = (disciplineId?: string) => {
    setOpen(false);
    setSelectedMdiBaseIssue(null);
    setDocumentsInfo([]);
    setPhotosInfo([]);
    setBaseIssueId(null);
    setActiveTab('detail');
    refreshBaseIssues(disciplineId ? disciplineId : '');
    setIsDraft(false);
    form.resetFields();
  };

  const handleSaveAsDraftOrStartValidation = async (
    fields: Array<{ name: string; value: string }>,
    isSaveAsDraft: boolean
  ) => {
    try {
      setIsLoading(true);
      const valid = await form.validateFields([fields]);
      if (valid) {
        const values = await form.getFieldsValue();
        const draftStatus = issueStatuses.find((status) => status.code === MDI_STATUSES.DRAFT)?.id;
        const ripStatus = issueStatuses.find((status) => status.code === MDI_STATUSES.REVIEW_IN_PROGRESS)?.id;
        values.mdiInvitations = values.mdiInvitations.map(
          (invitation: { companyId: string; inspectorId: string }, index: number) => ({
            ...invitation,
            order: index,
          })
        );
        let combinedDateTime;

        if (values.inspectionDate !== undefined) {
          const inspectionDate = dayjs(values.inspectionDate);
          const inspectionTime = values.inspectionTime;

          if (inspectionDate && inspectionTime) {
            combinedDateTime = inspectionDate.hour(inspectionTime.hour()).minute(inspectionTime.minute());
          } else {
            combinedDateTime = inspectionDate;
          }
        } else {
          combinedDateTime = undefined;
        }

        const mdiValidations: {
          validatorCompanyId: string;
          validatorId: string;
          revision: number;
          validationStatus: string;
        }[] = values.mdiInvitations.map((invitation: { companyId: string; inspectorId: string }) => {
          return {
            validatorCompanyId: invitation.companyId,
            validatorId: invitation.inspectorId,
            revision: 0,
            validationStatus: VALIDATION_STATUS.IN_REVIEW,
          };
        });

        const data = {
          ...createMDIData,
          name: values.name,
          isSaveAsDraft: isSaveAsDraft,
          attachments: {
            attachmentsIds: values.attachments || [],
          },
          attachmentsInfo: documentsInfo.concat(photosInfo),

          mdi: {
            ...values,
            mdiInvitations: values?.mdiInvitations[0]?.companyId ? values.mdiInvitations : null,
            mdiValidations: !isSaveAsDraft ? mdiValidations : [],
          },
          moduleFunctionId: moduleFunction.find((func) => func.code === MODULE_FUNCTIONS.MATERIAL_DELIVERY_REQUEST)?.id,
          watcherIds: values.watcherIds,
          statusId: isSaveAsDraft ? draftStatus : ripStatus,
        };
        data.mdi.marId = MARs.find((mar) => mar.id === values.marId)?.MaterialApprovalRequest?.id;
        data.mdi.inspectionDate = combinedDateTime;
        if (values.mdiInvitations[0].companyId === undefined) {
          delete values.mdiInvitations;
        }

        if (baseIssueId) {
          data.mdi.id = baseIssueData?.MaterialDeliveryInspection?.id;
          await baseIssueService.updateBaseIssue(workspaceId, projectId, baseIssueId, data);
        } else {
          await baseIssueService.createBaseIssue(workspaceId, projectId, data);
        }

        form.resetFields();
        onClose(baseIssueData?.MaterialDeliveryInspection?.MaterialApprovalRequest?.disciplineId || '');
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      message.error((e as Error).message);
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    await form.validateFields(['marId', 'name']);
    handleSaveAsDraftOrStartValidation(
      [
        { name: 'marId', value: 'someValue' },
        { name: 'name', value: 'someOtherValue' },
      ],
      false
    ).then(() => {
      onClose(MARs.find((mar) => mar.id === values.marId)?.MaterialApprovalRequest?.disciplineId || '');
      setCreate(false);
      setEdit(false);
    });
  };
  const onInspection = () => {
    setIsModalOpen(true);
  };
  const onEdit = () => {
    setEdit(true);
  };
  const onSave = () => {
    setEdit(false);
    setIsDraft(false);
  };

  const onSubmitAsDraft = async () => {
    try {
      await form.validateFields(['marId', 'name']);
      const values = await form.getFieldsValue();

      await handleSaveAsDraftOrStartValidation(
        [
          { name: 'marId', value: values.marId },
          { name: 'name', value: values.name },
        ],
        true
      ).then(() => {
        onClose(MARs.find((mar) => mar.id === values.marId)?.MaterialApprovalRequest?.disciplineId || '');
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [baseIssueData, setBaseIssueData] = useState<BaseIssue | null>(null);

  const getBaseIssue = async (baseIssueId: string) => {
    try {
      setIsLoading(true);
      const baseIssueData = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'MDI',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      setBaseIssueData(baseIssueData);
      const currentUser = users.find((u) => u.id === baseIssueData?.originatorId);
      // Readonly when status is
      if (
        // Review in progress
        baseIssueData.IssueStatus?.code === MDI_STATUSES.REVIEW_IN_PROGRESS ||
        // Approved
        baseIssueData.IssueStatus?.code === MDI_STATUSES.APPROVED ||
        baseIssueData.IssueStatus?.code === MDI_STATUSES.DRAFT ||
        // Reject and user not the originator
        (baseIssueData.IssueStatus?.code === MDI_STATUSES.REJECTED && baseIssueData.originatorId !== currentUser?.id) ||
        // User in-review is not in the same company with originator
        ((baseIssueData?.BaseValidations || []).length > 0 &&
          (baseIssueData?.BaseValidations || [])[0]?.validatorCompanyId !== currentUser?.WorkspaceUser?.Company?.id)
      ) {
        setIsDraft(false);
      }
      setSelectedMdiBaseIssue(baseIssueData);
      if (baseIssueData.startDate) {
        baseIssueData.startDate = dayjs(baseIssueData.startDate);
      }
      if (baseIssueData.endDate) {
        baseIssueData.endDate = dayjs(baseIssueData.endDate);
      }
      if (baseIssueData.IssueStatus?.code === MDI_STATUSES.DRAFT) {
        setIsDraft(true);
      }
      form.setFieldsValue(baseIssueData);
      form.setFieldsValue(baseIssueData.MethodStatementSubmission);
      setRequestName(baseIssueData.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(t((error as Error).message));
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
  };

  useEffect(() => {
    if (isOpen) {
      if (baseIssueId) {
        getBaseIssue(baseIssueId);
      } else {
        form.setFieldValue('plannedStartDate', dayjs());
        if (zones) {
          form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
        }
        // Discipline setup
        const generalDiscipline = issueDisciplines?.find((type) => type.code === ISSUE_DISCIPLINES.GENERAL);
        form.setFieldValue('issueDisciplineId', generalDiscipline?.id);
        // Priority setup
        form.setFieldValue(
          'issuePriorityId',
          issuePriorities?.find((item) => item.code === ISSUE_PRIORITIES.MEDIUM)?.id
        );
        setDocumentsInfo([]);
        setPhotosInfo([]);
      }
    }
  }, [isOpen, baseIssueId]);
  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setBaseIssueData(null);
      setSelectedMdiBaseIssue(null);
      setBaseIssueId(null);
    }
  }, [isOpen]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsDisableSubmit(false))
      .catch(() => setIsDisableSubmit(true));
  }, [form, values]);
  useEffect(() => {
    if (isDraft) {
      if (MARs[0]?.originatorId != profile?.id) {
        setEdit(false);
        setCreate(false);
      } else {
        setEdit(true);
        setCreate(true);
      }
    }
  }, [isOpen]);
  return (
    <>
      <MDIsValidation
        isCreate={isCreate}
        isModalOpen={isModalOpen}
        selectedMdiBaseIssue={selectedMdiBaseIssue}
        profile={profile}
        workspaceId={workspaceId}
        projectId={projectId}
        setIsModalOpen={setIsModalOpen}
        onClose={() => {
          onClose(selectedMdiBaseIssue?.MaterialDeliveryInspection?.MaterialApprovalRequest?.disciplineId || '');
        }}
      />
      <Form form={form} labelCol={{ span: 24 }} layout='vertical' onValuesChange={onChangeForm}>
        <Drawer
          className={`${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
          styles={{
            header: {
              backgroundColor: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'],
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={1025}
          onClose={() => onClose()}
          closeIcon={false}
          bodyStyle={{ padding: '0' }}
          open={isOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <MDIsHeader
              requestName={requestName}
              setRequestName={setRequestName}
              form={form}
              isUnSaveStatus={true}
              onClose={() => {
                onClose(selectedMdiBaseIssue?.MaterialDeliveryInspection?.MaterialApprovalRequest?.disciplineId || '');
              }}
              profile={profile}
              selectedMdiBaseIssue={selectedMdiBaseIssue}
              user={user}
            />
          }
          footer={
            !isLoading && (
              <MDIsFooter
                isDisableSubmit={isDisableSubmit}
                selectedMdiBaseIssue={selectedMdiBaseIssue}
                userReviewed={userReviewed}
                profile={profile}
                onClose={onClose}
                onEdit={onEdit}
                onSave={onSave}
                onSubmit={onSubmit}
                onInspection={onInspection}
                onSubmitAsDraft={onSubmitAsDraft}
              />
            )
          }
        >
          <Spin spinning={isLoading}>
            <Tabs
              className={'mdi-tabs'}
              defaultActiveKey={'detail'}
              activeKey={activeTab}
              items={items}
              onChange={handleTabChange}
            />
            {companyHistories.length > 0 && <MDIsValidationHistoryStep items={companyHistories} />}
            {activeTab === 'detail' && MARs && (
              <MDIsDetail
                companyOriginator={companyOriginator}
                setCreateMDIData={setCreateMDIData}
                setRequestName={setRequestName}
                baseIssueData={selectedMdiBaseIssue}
                projectId={projectId}
                workspaceId={workspaceId}
                form={form}
                MARs={MARs}
                users={users}
                isEdit={isEdit}
                isReject={isReject}
                isOpen={isOpen}
                companies={companies}
                isDraft={isDraft}
                workPackages={workPackages}
                documentsInfo={documentsInfo}
                photosInfo={photosInfo}
                setPhotosInfo={setPhotosInfo}
                setDocumentsInfo={(value: AttachmentInfo[]) => setDocumentsInfo(value)}
              />
            )}
            {activeTab === 'attachments' && (
              <MDIsAttachment
                onAddAttachment={() => console.log('Add attachment')}
                onDelete={() => console.log('Delete attachment')}
              />
            )}
            {activeTab === 'comments' && <MDIsHistory companyHistories={companyHistories} />}
          </Spin>
        </Drawer>
      </Form>
    </>
  );
}
