import { FileSyncOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, message, Typography } from 'antd';
import { t } from 'i18next';
import WorkInspectionRequestStatistic from './WorkInspectionRequestStatistic';
import WorkInspectionRequestListsView, { WorkInspectionRequestData } from './WorkInspectionRequestListsView';
import { useEffect, useRef, useState } from 'react';
import {
  useInspectionTestPlain,
  useInspectionTestPlanParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { CreateOrUpdateWorkInspectionRequest } from './index';
import { kpiData } from 'types/project';
import { MODULE_FUNCTIONS } from 'utils/contants';
import useAuthContext from 'store/auth-context';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/DisciplineBaseIssueTable';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { useFetchProjectDisciplineByFunction, useFetchProjectDisciplineByFunctionParams } from 'hooks/base-issue';
import GenerateReports from './Reports/GenerateReports';
import { QueryParams, TableRowsSelected } from 'types';
import useUserEntity from 'hooks/common/useUserProfile';
import dayjs from 'dayjs';
import { parseQueryParams, useSearchDebounce } from 'utils';
import FilterWorkInspectionRequestForm, { WIR_FILTER_TYPE } from './FilterWorkInspectionRequestForm';
import BaseIssueKpiStatistic from 'components/common/BaseIssueKpiStatistic';

type WorkInspectionRequestsProps = {
  module: string;
  workspaceId: string;
  projectId: string;
};
export default function WorkInspectionRequests(props: WorkInspectionRequestsProps) {
  const { module, workspaceId, projectId } = props;
  const { user } = useUserEntity({ projectId, workspaceId });

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [baseIssueId, setBaseIssueId] = useState<string | null>(null);
  const [selectedKpiCode, setSelectedKpiCode] = useState<string | null>(null);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [companies] = useProjectCompanies(companyParams);
  const { profile } = useAuthContext();
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  useProjectZones(zonesParams);

  const loggedProjectCompany = companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
  const loggedCompanyRole = loggedProjectCompany?.ProjectCompanies?.find(
    (projectCompany) => projectCompany.projectId === projectId
  )?.companyRole;
  const isOwnerCompany = loggedCompanyRole === 'owner';

  const [totalKpi, setTotalKpi] = useState<kpiData>({
    // DR: 0,
    // RIP: 0,
    // RE: 0,
    // AP: 0,
    // RIPO: 0,
  });
  const [isGenerateReports, setIsGenerateReports] = useState<boolean | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<TableRowsSelected>({});
  const [selectedDuplicateId, setSelectedDuplicateId] = useState<string | null>(null);

  const [projectBaseIssueParams, setProjectParams] = useState<Record<string, string | number | undefined>>({
    projectId: projectId as string,
    limit: 10,
    page: 1,
    workspaceId,
    module,
    type: 'WIR',
    include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
  });

  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: props.projectId as string,
    workspaceId: props.workspaceId as string,
    orderBy: 'order',
    mainPhase: true,
  });
  useWorkBreakdownStructures(workBreakdownStructureQuery);

  const [inspectionTestPlanParams] = useInspectionTestPlanParams({
    projectId: projectId as string,
    workspaceId: workspaceId as string,
    include: 'TemplateAttachment|SpecAttachment',
  });
  useInspectionTestPlain(inspectionTestPlanParams);
  const disciplineBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);
  const [ProjectDisciplineByFunctionParams] = useFetchProjectDisciplineByFunctionParams({
    workspaceId,
    projectId,
    type: 'WIR',
  });
  const [, , refreshDisciplineByFunction] = useFetchProjectDisciplineByFunction(ProjectDisciplineByFunctionParams);

  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(queryUser);

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.WIR,
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);

  const handleEditForm = (id: string) => {
    try {
      setSelectedDuplicateId(null);
      setBaseIssueId(id ?? null);
      setIsFormOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDuplicateForm = (id: string) => {
    console.log('isFormOpen', isFormOpen);
    if (isFormOpen) {
      setIsFormOpen(false);
      setBaseIssueId(null);
      setTimeout(() => {
        setSelectedDuplicateId(id);
        setIsFormOpen(true);
      }, 100);
    } else {
      setBaseIssueId(null);
      setSelectedDuplicateId(id);
      setIsFormOpen(true);
    }
  };
  // TODO: Search function
  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };
  const handleSearch = async () => {
    try {
      const param = {
        ...projectBaseIssueParams,
        searchBy: '["BaseIssue.name","Company.name","WorkInspectionRequest.wirNumber","Zone.name"]',
        searchKey: searchValue,
        page: 1,
      };
      setProjectParams(param);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  useEffect(() => {
    const params = parseQueryParams(window.location.search);
    if (params.searchKey) {
      setSearchValue('');
      setShowSearchInput(false);
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);
  const handleActionClick = (type: string, record: WorkInspectionRequestData) => {
    switch (type) {
      case 'edit':
        handleEditForm(record.id);
        break;
      case 'duplicate':
        handleDuplicateForm(record.id);
        break;
      case 'open-detail': {
        setBaseIssueId(record.id);
        setIsFormOpen(true);
        break;
      }
      default:
        break;
    }
  };

  const handlerRowsKeyChange = (keys: React.Key[], distributionId?: string) => {
    const newKeys = { ...selectedRowKeys };
    if (!distributionId) return;
    newKeys[distributionId] = keys;
    setSelectedRowKeys(newKeys);
  };

  useEffect(() => {
    const issueStatusId = issueStatuses.find((status) => status.code === selectedKpiCode)?.id;
    setProjectParams({
      ...projectBaseIssueParams,
      'statusId[]': issueStatusId ? `[${'"' + issueStatusId + '"'}]` : undefined,
    });
  }, [selectedKpiCode, issueStatuses.length]);

  const handleRefreshDiscipline = (disciplineId?: string) => {
    disciplineBaseIssueTableRef.current?.refreshData(disciplineId, baseIssueId);
  };

  const handleFilter = async (values: QueryParams) => {
    let companyId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;
    const startDate = values.startDate && values.startDate[0];
    const endDate = values.startDate && values.startDate[1];

    if (values.groupIssues === WIR_FILTER_TYPE.myBaseIssue) {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === WIR_FILTER_TYPE.myCompany && user?.WorkspaceUser?.Company?.id) {
      companyId = `${user?.WorkspaceUser?.Company?.id}`;
    } else if (values.groupIssues === WIR_FILTER_TYPE.watched && profile?.id) {
      watcherId = `${profile?.id}`;
    }

    if (values.companyId) {
      companyId = `${values.companyId}`;
    }
    if (values.assigneeId) {
      assigneeId = `${values.assigneeId}`;
    }

    const zoneId = values.zoneId && values.zoneId.length > 0 ? `[${'"' + values.zoneId.join('","') + '"'}]` : undefined;

    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const workPackageId =
      values.workPackageId && values.workPackageId.length > 0
        ? `[${'"' + values.workPackageId.join('","') + '"'}]`
        : undefined;

    const params = {
      ...projectBaseIssueParams,
      'createdAt[gte]':
        startDate && values.startDate && values.groupIssues !== WIR_FILTER_TYPE.myInspections
          ? dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      'createdAt[lte]':
        endDate && values.groupIssues !== WIR_FILTER_TYPE.myInspections
          ? dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      'inspectionDate[gte]':
        startDate && values.groupIssues === WIR_FILTER_TYPE.myInspections
          ? dayjs(startDate).startOf('day').toISOString()
          : undefined,
      'inspectionDate[lte]':
        endDate && values.groupIssues === WIR_FILTER_TYPE.myInspections
          ? dayjs(endDate).endOf('day').toISOString()
          : undefined,
      'statusId[]': issueStatusId,
      'workPackageId[]': workPackageId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : companyId,
      originatorId: assigneeId,
      'zoneId[]': zoneId,
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };
    setProjectParams(params);
    setIsResetFilterByKPI(true);
  };

  return (
    <Layout.Content className='settings-content'>
      {isFormOpen && (
        <CreateOrUpdateWorkInspectionRequest
          isOpen={isFormOpen}
          isEdit={true}
          module={module}
          workspaceId={workspaceId}
          projectId={projectId}
          profile={profile}
          baseIssueId={baseIssueId}
          refreshBaseIssues={handleRefreshDiscipline}
          selectedDuplicateId={selectedDuplicateId}
          onDuplicate={handleDuplicateForm}
          setOpen={(isOpen) => {
            if (!isOpen) {
              setBaseIssueId(null);
              setSelectedDuplicateId(null);
            }
            setIsFormOpen(isOpen);
          }}
        />
      )}
      <Flex className='mb-4' justify='space-between' align='flex-end'>
        <Flex>
          <Typography.Title level={2} className='mr-16 setting-section-header'>
            {' '}
            {t('Work Inspection Request - WIR')}
          </Typography.Title>
        </Flex>
        <Flex>
          {/** waiting for integrate api */}
          <Input
            name='search-input'
            className='search-button mr-3'
            placeholder={t('Type to search')}
            onBlur={(e) => handleInputBlur(e.target.value)}
            onFocus={handleSearchIconClick}
            style={{
              width: showSearchInput ? '250px' : '0px',
              border: showSearchInput ? '1px solid #d9d9d9' : 'none',
            }}
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchValue(e.target.value)}
            allowClear
          />

          <FilterWorkInspectionRequestForm
            companies={companies}
            users={users}
            issueStatuses={issueStatuses}
            workspaceId={workspaceId}
            userCompanyId={user?.WorkspaceUser?.Company?.id}
            projectId={projectId}
            isOpenFilter={!!isOpenFilter}
            setIsOpenFilter={setIsOpenFilter}
            handleFilter={(value) => {
              handleFilter(value);
            }}
            isReset={isResetFilter || false}
            setReset={setIsResetFilter}
            module={module}
            isOwnerCompany={isOwnerCompany}
          />

          <Button onClick={() => setIsGenerateReports(true)} icon={<FileSyncOutlined />}>
            {t('Generate report')}
          </Button>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              setIsFormOpen(true);
            }}
          >
            {t('Add Request')}
          </Button>
        </Flex>
      </Flex>
      {/* <WorkInspectionRequestStatistic
        onApplyFilter={setSelectedKpiCode}
        isResetKPI={isResetFilterByKPI}
        setResetKPI={setIsResetFilterByKPI}
        kpiData={totalKpi}
      /> */}
      <BaseIssueKpiStatistic
        onFilter={(params) => {          
          setProjectParams(params);
          setIsResetFilter(true);
        }}
        kpiData={totalKpi}
        issueStatuses={issueStatuses}
        projectBaseIssueParams={projectBaseIssueParams}
        isResetKPI={isResetFilterByKPI}
        onResetKPI={setIsResetFilterByKPI}
      />

      <WorkInspectionRequestListsView
        users={users || []}
        projectBaseIssueParams={projectBaseIssueParams}
        onActionClick={handleActionClick}
        profile={profile}
        duplicateForm={handleDuplicateForm}
        onTotalKpi={(totalKpi) => setTotalKpi(totalKpi)}
        companies={companies}
        workspaceId={workspaceId}
        projectId={projectId}
        isFormOpen={isFormOpen}
        refreshDisciplineByFunction={refreshDisciplineByFunction}
        editForm={(id: string) => handleEditForm(id)}
        disciplineBaseIssueTableRef={disciplineBaseIssueTableRef}
        refreshBaseIssues={handleRefreshDiscipline}
        selectedRowKeys={selectedRowKeys}
        handlerRowsKeyChange={handlerRowsKeyChange}
      />

      {isGenerateReports !== null && (
        <GenerateReports
          isGenerateReports={isGenerateReports === true}
          profile={profile}
          users={users}
          module={module}
          workspaceId={workspaceId}
          projectId={projectId}
          companies={companies}
          issueStatuses={issueStatuses}
          selectedRowKeys={selectedRowKeys}
          isOwnerCompany={true}
          setIsGenerateReports={(value: boolean) => {
            setIsGenerateReports(value);
            setSelectedRowKeys({});
          }}
        />
      )}
    </Layout.Content>
  );
}
