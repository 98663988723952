import { useEffect, useState } from 'react';
import { Button, Flex, Select, Tabs, TabsProps, Tag, Typography, notification } from 'antd';
import { Project, Workspace } from 'model';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { AppstoreOutlined, PlusOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { WorkspaceViewType } from 'pages/Home/Home';
import {
  WorkspaceCollapse,
  ProjectList,
  CreateProject,
  EditProject,
} from 'components';
import { projectService } from 'services';
import { Link } from 'react-router-dom';

type MyWorkspacesProps = {
  myWorkspace: Workspace | null;
  projects: Project[];
  loading?: boolean;
  onRefreshProjects: Function;
  markProjectAsFavorite: (
    workspaceId: string,
    projectId: string,
    isFavorite: boolean
  ) => void;
  selectGroupProjects: string,
  onChangeGroupProjects: (
    value: string,
  ) => void;
};

export default function MyWorkspaces(props: MyWorkspacesProps) {
  const {
    myWorkspace,
    projects,
    onRefreshProjects,
    markProjectAsFavorite,
    selectGroupProjects,
    onChangeGroupProjects,
    loading,
  } = props;
  const { t } = useTranslation();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [notify, contextHolder] = notification.useNotification();
  const [isEditOpen, setIsEditOpen] = useState<boolean | null>(false);
  const [createdProjectId, setCreatedProjectId] = useState<string>('');
  const workspaceId = myWorkspace?.id as string;
  const [isViewType, setViewType] = useState<WorkspaceViewType>('gallery');

  useEffect(() => {
    if (!isEditOpen) {
      setCreatedProjectId('');
    }
  }, [isEditOpen]);

  const onSubmitCreate = async (values: any) => {
    try {
      setIsSubmit(true);
      values.workspaceId = workspaceId;
      const { projectId } = await projectService.createProject(workspaceId, values);
      setCreatedProjectId(projectId);
      onRefreshProjects();
      setIsSubmit(false);
      setIsCreateOpen(false);
      setIsEditOpen(true);
    } catch (error) {
      notify.error({
        message: t('Error'),
        description: t((error as Error)?.message),
      });
      setIsSubmit(false);
    }
  };
  const onSubmitUpdate = async (values: any) => {
    try {
      setIsSubmit(true);
      values.workspaceId = myWorkspace?.id;
      await projectService.updateProject(workspaceId, values.id as string, values);
      onRefreshProjects();
      setIsSubmit(false);
      setIsEditOpen(false);
    } catch (error) {
      notify.error({
        message: t('Error'),
        description: t((error as Error)?.message),
      })
      setIsSubmit(false);
    }
  };
  return (
    <div className="workspaces my-workspaces">
      <Flex className='mb-4' justify='space-between' align="center">
        <Flex justify={'start'} align={'center'}>
          <div className='workspace-header'>
            <Typography.Title level={2} className='workspace-title mr-2'>
              {t('Projects') + ': '}
            </Typography.Title>
          </div>
          <Select
            value={selectGroupProjects}
            // variant={'borderless'}
            maxTagCount={'responsive'}
            className='workspace-title'
            style={{ minWidth: '170px' }}
            onChange={onChangeGroupProjects}
            options={[
              { value: 'allProjects', label: t('All projects') },
              { value: 'myWorkspace', label: t('My projects') },
              { value: 'guestProjects', label: t('Projects as Guest') },
            ]}
          />
        </Flex>
        <Flex justify={'space-between'} align={'center'}>
          <Button
            type="text" size='small'
            key={'gallery'}
            onClick={() => setViewType('gallery' as WorkspaceViewType)}>
            <AppstoreOutlined
              style={{
                fontSize: 20,
                color: isViewType === 'gallery' ? '#0958d9' : '',
              }}
            />
          </Button>
          <Button
            type="text" size='small'
            key={'list'}
            className='ml-0'
            onClick={() => setViewType('list' as WorkspaceViewType)}
          >
            <UnorderedListOutlined style={{ fontSize: 20, color: isViewType === 'list' ? '#0958d9' : '' }} />
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateOpen(true)}
          >
            {t('Create project')}
          </Button>
        </Flex>
      </Flex>
      {contextHolder}
      {isCreateOpen !== null && (
        <CreateProject
          isModalOpen={isCreateOpen}
          setIsModalOpen={setIsCreateOpen}
          loading={isSubmit}
          onSubmit={onSubmitCreate}
        />
      )}
      {isEditOpen !== null && (
        <EditProject
          isModalOpen={isEditOpen}
          setIsModalOpen={setIsEditOpen}
          onSubmit={onSubmitUpdate}
          loading={isSubmit}
          workspaceId={workspaceId}
          projectId={createdProjectId}
        />
      )}
      <AnimatePresence>
        <ProjectList
          sectionId={myWorkspace?.id}
          markProjectAsFavorite={markProjectAsFavorite}
          createAction={() => setIsCreateOpen(true)}
          loading={loading}
          projects={projects}
          workspace={myWorkspace}
          viewType={isViewType}
        />
        {/* 
        <WorkspaceCollapse
          title={`${myWorkspace?.name} (${projects.length || 0})`}
          tag={
            <>
              <Link className='d-flex mx-1' to={'/workspace-settings/general'}>
                <SettingOutlined style={{ fontSize: 20 }} />
              </Link>
              <Tag color={'purple'}>{t('Company plan')}</Tag>
            </>
          }>
          <ProjectList
            sectionId={myWorkspace?.id}
            markProjectAsFavorite={markProjectAsFavorite}
            createAction={() => setIsCreateOpen(true)}
            loading={loading}
            projects={projects}
            workspace={myWorkspace}
            viewType={isViewType}
          />
        </WorkspaceCollapse>
        */}
      </AnimatePresence>
    </div>
  );
}
