import WirInvitation from 'model/WirInvitation';
import request from 'requesters/core.request';

const wirInvitationService = {
  updateWirInvitation: async <T = unknown, U = unknown>(
    workspaceId: string,
    projectId: string,
    wirInvitationId: string,
    data: T,
    params?: U
  ) => {
    return request.put<WirInvitation>(
      `/workspaces/${workspaceId}/projects/${projectId}/wir-invitations/${wirInvitationId}`,
      data,
      { params }
    );
  },
};
export default wirInvitationService;
