import { Project } from 'model';
import { ControlType } from 'model/ControlType';
import { Frequencies } from 'model/Frequencies';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
import { QualityControlMatrices } from 'model/QualityControlMatrix';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';

const projectService = {
  getProjects: async (workspaceId: string, query: QueryParams) => {
    return request.get<PaginationData<Project>>(`/workspaces/${workspaceId}/projects`, {
      params: query,
    });
  },
  getProjectById: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<Project>(`/workspaces/${workspaceId}/projects/${projectId}`, {
      params: query,
    });
  },
  getOtherProjects: async (query: QueryParams) => {
    return request.get<PaginationData<Project>>('/other-projects', {
      params: query,
    });
  },
  getFavoriteProjects: async (query: QueryParams) => {
    return request.get<PaginationData<Project>>('/favorite-projects', {
      params: query,
    });
  },
  createProject: async (workspaceId: string, data: QueryParams) => {
    return request.post<{ projectId: string }>(`/workspaces/${workspaceId}/projects`, data);
  },
  updateProject: async (workspaceId: string, projectId: string, data: QueryParams) => {
    return request.put(`/workspaces/${workspaceId}/projects/${projectId}`, data);
  },
  deleteProject: async (workspaceId: string, projectId: string) => {
    return request.delete(`/workspaces/${workspaceId}/projects/${projectId}`);
  },
  markProjectAsFavorite: async (workspaceId: string, projectId: string) => {
    return request.post<Project>(`/workspaces/${workspaceId}/projects/${projectId}/favorite`);
  },

  unmarkProjectAsFavorite: async (workspaceId: string, projectId: string) => {
    return request.delete<Project>(`/workspaces/${workspaceId}/projects/${projectId}/favorite`);
  },

  updateProjectSettings: async (projectId: string, data: QueryParams) => {
    return request.put(`/projects/${projectId}/project-settings/multiple-create-or-update`, data);
  },
  getProjectSettings: async (projectId: string) => {
    return request.get(`/projects/${projectId}/project-settings`);
  },
  revokeAccess: async (workspaceId: string, projectId: string, companyId: string) => {
    return request.delete(`/workspaces/${workspaceId}/projects/${projectId}/revoke-access/${companyId}`);
  },
  revokeAccessV2: async (workspaceId: string, projectId: string, companyId: string, data: QueryParams) => {
    return request.put(`/workspaces/${workspaceId}/projects/${projectId}/revoke-access-v2/${companyId}`, data);
  },
  createInspectionTestPlans: async (workspaceId: string, projectId: string, data: QueryParams) => {
    return request.post(`/workspaces/${workspaceId}/projects/${projectId}/inspection-test-plans`, data);
  },
  updateInspectionTestPlans: async (
    workspaceId: string,
    projectId: string,
    inspectionTestPlanId: string,
    data: QueryParams
  ) => {
    return request.put(
      `/workspaces/${workspaceId}/projects/${projectId}/inspection-test-plans/${inspectionTestPlanId}`,
      data
    );
  },
  deleteInspectionTestPlans: async (workspaceId: string, projectId: string, inspectionTestPlanId: string) => {
    return request.delete(
      `/workspaces/${workspaceId}/projects/${projectId}/inspection-test-plans/${inspectionTestPlanId}`
    );
  },
  getInspectionTestPlans: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<InspectionTestPlanModel>>(
      `/workspaces/${workspaceId}/projects/${projectId}/inspection-test-plans`,
      {
        params: query,
      }
    );
  },
  getControlType: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<ControlType>>(
      `/workspaces/${workspaceId}/projects/${projectId}/inspection-types`,
      {
        params: query,
      }
    );
  },
  getFrequencies: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<Frequencies>>(`/workspaces/${workspaceId}/projects/${projectId}/frequencies`, {
      params: query,
    });
  },
  getQualityControlMatrices: async (workspaceId: string, projectId: string, query: QueryParams) => {
    return request.get<PaginationData<QualityControlMatrices>>(
      `/workspaces/${workspaceId}/projects/${projectId}/quality-control-matrices`,
      {
        params: query,
      }
    );
  },
};

export default projectService;
