import { Button, Modal, message, Upload, ModalProps, Form, Input } from 'antd';
import Zone from 'model/Zone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { attachmentService, fileService } from 'services';
import { AttachmentType } from 'model';
import { formatUrlAttachments } from 'utils';

const { Dragger } = Upload;

type ProjectZoningUploadPlanProps = {
  locationPlanType: AttachmentType | undefined;
  workspaceId: string;
  projectId: string;
  isModalOpen: boolean;
  selectedZone: Zone | undefined;
  getAttachments: () => void;
  setIsModalOpen: (value: boolean) => void;
} & ModalProps;
function getBase64(file: File): Promise<FileReader['result']> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default function ProjectZoningUploadPlan(props: ProjectZoningUploadPlanProps) {
  const { isModalOpen, setIsModalOpen, selectedZone, workspaceId, projectId, locationPlanType, getAttachments } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewFile, setPreviewFile] = useState<FileReader['result'] | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      form.resetFields();
      setFile(null);
      setPreviewFile(null);
    }
  }, [isModalOpen]);

  const handleOk = async () => {
    if (!file) {
      setIsError(true);
      return;
    }
    setIsLoading(true);
    try {
      const imgResponse = await fileService.uploadFileAdvanced(file, fileName, fileType, 10);
      // console.log(imgResponse)
      onUploadPlan(imgResponse);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const onUploadPlan = async (values: { fileUrl: string; fileName: string; keyFile: string }) => {
    try {
      await form.validateFields();
      const formValues = await form.getFieldsValue();
      try {
        if (formValues.name && values.fileUrl) {
          await attachmentService.createLocationPlan(workspaceId, projectId, {
            filePath: values.fileUrl,
            keyFile: values.keyFile,
            name: formValues.name,
            attachmentTypeId: locationPlanType?.id as string,
            zoneId: selectedZone?.id,
          });
          getAttachments();
          setIsLoading(false);
          setIsModalOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onBeforeUpload = async (uploadFile: File) => {
    const isJpgOrPng =
      uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png' || uploadFile.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error(t('You can only upload JPG/PNG/PDF file!'));
      return false;
    }

    if (uploadFile.type === 'application/pdf') {
      setIsLoading(true);
      const { imageFile, imagePreview } = await fileService.pdfToImage(uploadFile);
      setFile(imageFile);
      setFileType(imageFile.type);
      setFileName(imageFile.name);
      setPreviewFile(imagePreview);
      setIsLoading(false);
    } else {
      setFile(uploadFile);
      setFileType(uploadFile.type);
      setFileName(uploadFile.name);
      const filePreview = await getBase64(uploadFile);
      setPreviewFile(filePreview);
    }
    form.setFieldValue('name', formatUrlAttachments(uploadFile.name, true));
    return false;
  };
  return (
    <Modal
      title={`${t('Upload a plan to')} ${selectedZone?.name}`}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk} loading={isLoading}>
          {t('Upload')}
        </Button>,
      ]}
      width={723}
    >
      <Form form={form} name='upload-plan' layout='vertical' className='mt-10'>
        <Dragger
          name='file'
          multiple={false}
          accept='image/*,application/pdf'
          showUploadList={false}
          className={isError ? 'error' : ''}
          beforeUpload={onBeforeUpload}
        >
          {previewFile ? (
            <div className='preview-container'>
              <img src={previewFile as string} alt='' />
            </div>
          ) : (
            <>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
              <p className='ant-upload-hint'>{t('Support for a single upload.')}</p>
            </>
          )}
        </Dragger>
        <Form.Item className={'mt-5'} label={t('Plan name')} name='name' rules={[{ required: true, message: '' }]}>
          <Input placeholder={t('Plan name')} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
