import { CheckCircleFilled, DeleteOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Modal, Spin, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { UploadDocument } from 'components/common';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
import { UploadDocumentFile } from 'components/toolbox-request-for-information/CreateOrUpdateRequestForInformation/UploadDocument';
import { User } from 'model';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileService } from 'services';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import { ModalState } from 'types';
import { ATTACHMENT_TYPES, VALIDATION_STATUS } from 'utils/contants';
import { PTWFileUpload } from './PermitToWorkDetail';

type PermitToWorkInspectionModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmitStartInspection: () => void;
  isLoading?: boolean;
  user: User | null;
};
const PermitToWorkInspectionModal = (props: PermitToWorkInspectionModalProps) => {
  const { t } = useTranslation();
  const [inspectionStatus, setInspectionStatus] = useState('');
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(false);
  const [attachmentPhotos, setAttachmentPhotos] = useState<PTWFileUpload[]>([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachments, setAttachments] = useState<PTWFileUpload[]>([]);
  const form = useFormInstance();
  const ptwChecklistAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_CHECKLIST));
  const ptwValidationPhotoAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.PTW_PHOTO));
  const commentFieldValue = Form.useWatch(['validationComment'], form);

  const hasDisabledSubmit = useMemo(() => {
    switch (inspectionStatus) {
      case VALIDATION_STATUS.APPROVED_WITH_COMMENT:
        if (commentFieldValue) {
          return false;
        }
        return true;
      case VALIDATION_STATUS.APPROVED:
      case VALIDATION_STATUS.REJECTED:
        return false;
      default:
        return true;
    }
  }, [inspectionStatus, attachments?.length, commentFieldValue]);

  const getInspectionChecked = (status: string) => {
    return inspectionStatus === status ? 'checked' : '';
  };

  const InspectionStatus = () => {
    return (
      <div className='inspection-status-box'>
        <Button
          className={`${VALIDATION_STATUS.REJECTED} ${getInspectionChecked(VALIDATION_STATUS.REJECTED)}`}
          onClick={() =>
            setInspectionStatus(inspectionStatus === VALIDATION_STATUS.REJECTED ? '' : VALIDATION_STATUS.REJECTED)
          }
        >
          {getInspectionChecked(VALIDATION_STATUS.REJECTED) && <CheckCircleFilled height={14} width={14} />}
          {t('Reject')}
        </Button>

        <Button
          className={`${VALIDATION_STATUS.APPROVED_WITH_COMMENT} ${getInspectionChecked(VALIDATION_STATUS.APPROVED_WITH_COMMENT)}`}
          onClick={() =>
            setInspectionStatus(
              inspectionStatus === VALIDATION_STATUS.APPROVED_WITH_COMMENT
                ? ''
                : VALIDATION_STATUS.APPROVED_WITH_COMMENT
            )
          }
        >
          {getInspectionChecked(VALIDATION_STATUS.APPROVED_WITH_COMMENT) && (
            <CheckCircleFilled height={14} width={14} />
          )}
          {t('Approve With Comments')}
        </Button>

        <Button
          className={`${VALIDATION_STATUS.APPROVED} ${getInspectionChecked(VALIDATION_STATUS.APPROVED)}`}
          onClick={() =>
            setInspectionStatus(inspectionStatus === VALIDATION_STATUS.APPROVED ? '' : VALIDATION_STATUS.APPROVED)
          }
        >
          {getInspectionChecked(VALIDATION_STATUS.APPROVED) && <CheckCircleFilled height={14} width={14} />}
          {t('Approve')}
        </Button>
      </div>
    );
  };

  const handleAddAttachment = (name: string, response: UploadDocumentFile) => {
    if (!response?.fileUrl) return;
    attachments.push({
      name: name,
      filePath: response?.fileUrl || '',
      attachmentTypeId: ptwChecklistAttachment?.id || '',
    });
    setAttachments([...attachments]);
  };

  const InspectionCheckList = () => {
    return (
      <div className='inspection-checklist-box'>
        <div className='checklist-header'>
          <Typography.Title level={3} className='mb-4'>
            {t('Approved Permit To Work Checklist')}
          </Typography.Title>
          <Flex gap={8} wrap>
            {attachments.length > 0 &&
              attachments.map((attachment) => {
                return (
                  <div className='file-box' key={attachment.filePath}>
                    <FilePdfOutlined color='primary' className='color-primary' />
                    <Typography.Link
                      onClick={() => {
                        if (!attachment) return;
                        fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
                      }}
                    >
                      {t(`${attachment?.name}.pdf`)}
                    </Typography.Link>
                    <DeleteOutlined
                      className='cursor-pointer'
                      onClick={() => {
                        const newAttachments = attachments.filter((item) => item.filePath !== attachment.filePath);
                        setAttachments([...newAttachments]);
                      }}
                    />
                  </div>
                );
              })}
            <Button
              onClick={() => setOpenAttachment(true)}
              type='dashed'
              icon={<PlusOutlined />}
              className='text-size-12'
            >
              {t('Add Attachment')}
            </Button>
          </Flex>
        </div>

        {openAttachment && (
          <UploadDocument
            isModalOpen={openAttachment}
            setIsModalOpen={(value: boolean) => setOpenAttachment(value)}
            handleAddDocument={handleAddAttachment}
          />
        )}
      </div>
    );
  };

  const handleAddPicture = (data: UploadPhotoModalValue) => {
    const pictureData = {
      id: data.fileUrl,
      name: data.fileName,
      filePath: data.fileUrl,
      attachmentTypeId: ptwValidationPhotoAttachment?.id || '',
      profilePhoto: props.user?.profilePhoto,
      fullName: props.user?.fullName,
      firstName: props.user?.firstName,
      comment: data.comment,
    };
    setAttachmentPhotos([...attachmentPhotos, pictureData]);
    setIsOpenUpload(false);
  };

  const handleRemovePictures = (filePatch?: string) => {
    if (!filePatch) return;
    setAttachmentPhotos((prevAttachment) => prevAttachment.filter((attachment) => attachment.filePath !== filePatch));
  };

  const renderPicture = () => {
    const mappedPhotos = attachmentPhotos?.map((picture) => ({
      ...picture,
      picture: picture.profilePhoto,
      username: picture.fullName || '',
      firstName: picture.firstName,
    })) as AttachmentPhoto[];

    return (
      <div className='picture-box'>
        <Typography.Title className='mb-4' level={3}>
          {t('Pictures')}
        </Typography.Title>
        <UploadPhotoModal isModalOpen={!!isOpenUpload} setIsModalOpen={setIsOpenUpload} onSubmit={handleAddPicture} />

        <div className='picture-content'>
          <div className='picture-preview-box'>
            <AttachmentList attachmentPhotos={mappedPhotos} onDelete={handleRemovePictures}>
              <Button
                className={'text-size-12 h-[145px] w-[145px]'}
                onClick={() => setIsOpenUpload(true)}
                type='dashed'
                icon={<PlusOutlined />}
              >
                {t('Add Pictures')}
              </Button>
            </AttachmentList>
          </div>
        </div>
      </div>
    );
  };

  const renderComment = () => {
    return (
      <div className='picture-box'>
        <Typography.Title className='mb-4 text-size-14' level={3}>
          {t('Comment')}
        </Typography.Title>
        <Form.Item name='validationComment'>
          <Input.TextArea rows={4} placeholder={t('Enter your comment')} />
        </Form.Item>
      </div>
    );
  };

  const handleSubmitModal = () => {
    form.setFieldValue('validationChecklist', attachments);
    form.setFieldValue('validationPictures', attachmentPhotos);
    form.setFieldValue('validationStatus', inspectionStatus);
    props.onSubmitStartInspection();
  };

  const handleCancel = () => {
    form.setFieldValue('validationComment', '');
    props.onCancel();
  };

  return (
    <Modal
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' onClick={handleSubmitModal} disabled={hasDisabledSubmit} type='primary' htmlType='submit'>
          {t('Submit')}
        </Button>,
      ]}
      classNames={{
        footer: `footer-title ${inspectionStatus}`,
        header: `header-title ${inspectionStatus}`,
      }}
      className={`validation-modal safety ${inspectionStatus} permit-to-work `}
      title={t('Permit To Work Form')}
      open={props.isOpen}
      onCancel={handleCancel}
    >
      <Spin spinning={props.isLoading}>
        <InspectionStatus />
        <InspectionCheckList />
        {renderPicture()}
        {renderComment()}
      </Spin>
    </Modal>
  );
};

export default PermitToWorkInspectionModal;
