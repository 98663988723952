import flagCN from 'assets/images/flag/ch.png';
import flagES from 'assets/images/flag/es.png';
import flagFR from 'assets/images/flag/fr.png';
import flagIN from 'assets/images/flag/id.png';
import flagKM from 'assets/images/flag/km.png';
import flagPT from 'assets/images/flag/pt.png';
import flagRU from 'assets/images/flag/ru.png';
import flagTH from 'assets/images/flag/th.png';
import flagUK from 'assets/images/flag/uk.png';
import flagVN from 'assets/images/flag/vn.png';
import cnSVG from 'assets/images/flags-svg/cn.svg';
import esSVG from 'assets/images/flags-svg/es.svg';
import frSVG from 'assets/images/flags-svg/fr.svg';
import idSVG from 'assets/images/flags-svg/id.svg';
import kmSVG from 'assets/images/flags-svg/km.svg';
import ptSVG from 'assets/images/flags-svg/pt.svg';
import thSVG from 'assets/images/flags-svg/th.svg';
import ukSVG from 'assets/images/flags-svg/uk.svg';
import vnSVG from 'assets/images/flags-svg/vn.svg';
import highIcon from '../assets/images/inspection-priority/high.svg';
import lowIcon from '../assets/images/inspection-priority/low.svg';
import mediumIcon from '../assets/images/inspection-priority/medium.svg';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const MOTION_VARIANTS = {
  initial: {
    opacity: 0,
    y: 20,
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  out: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.4,
    },
  },
};

export const ROLES: Record<string, string> = {
  WORKSPACE_GUEST: 'WORKSPACE_GUEST',
  WORKSPACE_OWNER: 'WORKSPACE_OWNER',
  WORKSPACE_ADMIN: 'WORKSPACE_ADMIN',
  PROJECT_GUEST: 'PROJECT_GUEST',
  PROJECT_OWNER: 'PROJECT_OWNER',
  PROJECT_ADMIN: 'PROJECT_ADMIN',
};

export const ROLE_TYPES: Record<string, string> = {
  PROJECT: 'project',
  WORKSPACE: 'workspace',
};

export const USER_STATUSES: Record<string, string> = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const TASK_PRIORITY_ICON: Record<string, string> = {
  LOW: lowIcon,
  MEDIUM: mediumIcon,
  HIGH: highIcon,
};

export const TASK_STATUS_COLOR: Record<string, string> = {
  DR: '',
  OP: 'orange',
  OO: 'red',
  RFI: 'blue',
  RIO: 'purple',
  CL: 'green',
};

export const MAR_STATUS_COLOR: Record<string, string> = {
  RE: 'red',
  DR: '',
  RIP: 'blue',
  AP: 'green',
  'MAR-RE': 'red',
  'MAR-DR': '',
  'MAR-RIP': 'orange',
  'MAR-AP': 'green',
  MAR_RE: 'red',
  MAR_DR: '',
  MAR_RIP: 'blue',
  MAR_AP: 'green',
};

export const MDI_STATUS_COLOR: Record<string, string> = {
  RE: 'red',
  DR: '',
  RIP: 'orange',
  AP: 'green',
  'MDI-RE': 'red',
  'MDI-DR': '',
  'MDI-RIP': 'orange',
  'MDI-AP': 'green',
  MDI_RE: 'red',
  MDI_DR: '',
  MDI_RIP: 'blue',
  MDI_AP: 'green',
};

export const WIR_STATUS_COLOR: Record<string, string> = {
  WIR_RE: 'red',
  WIR_DR: '',
  WIR_RIP: 'blue',
  WIR_AP: 'green',
};

export const MSS_STATUS_COLOR: Record<string, string> = {
  MSS_RE: 'red',
  MSS_DR: '',
  MSS_RIP: 'blue',
  MSS_AP: 'green',
};

export const TRA_STATUS_COLOR: Record<string, string> = {
  TRA_DR: '',
  TRA_FAP: 'blue',
  TRA_ISD: 'green',
};
export const TRA_PRIORITIES_COLOR: Record<string, string> = {
  STANDARD: '#1677FF',
  CONFIDENTIAL: '#389E0D',
  IMPORTANT: '#FA8C16',
  URGENT: '#F5222D',
};
export const LETTER_PRIORITIES_COLOR: Record<string, string> = {
  STANDARD: '#1677FF',
  CONFIDENTIAL: '#389E0D',
  IMPORTANT: '#FA8C16',
  URGENT: '#F5222D',
};
export const LETTER_STATUS_COLOR: Record<string, string> = {
  LET_DR: '',
  LET_ISD: 'green',
};
export const MOM_STATUS_COLOR: Record<string, string> = {
  MOM_DR: '',
  MOM_RIP: 'blue',
  MOM_ISD: 'green',
};
export const RFI_STATUS_COLOR: Record<string, string> = {
  RFI_DR: '',
  RFI_OP: 'orange',
  RFI_CL: 'green',
};
export const T_PRIORITIES_COLOR: Record<string, string> = {
  STANDARD: '#1677FF',
  CONFIDENTIAL: '#389E0D',
  IMPORTANT: '#FA8C16',
  URGENT: '#F5222D',
};
export const DT_PRIORITIES_COLOR: Record<string, string> = {
  STANDARD: '#1677FF',
  CONFIDENTIAL: '#389E0D',
  IMPORTANT: '#FA8C16',
  URGENT: '#F5222D',
};
// export const TASK_STATUS_HEADER_COLOR  = {
//   DR: '#7e868b',
//   OP: '#FFD591',
//   OO: '#FFA39E',
//   RFI: '#91CAFF',
//   RIO: '#D3ADF7',
//   CL: '#52C41A',
// };

// bright color
export const TASK_STATUS_HEADER_COLOR: Record<string, string> = {
  // INS
  DR: '#D9D9D9',
  OP: '#FFE7BA',
  OO: '#FFA39E',
  RFI: '#BAE0FF',
  RIO: '#D3ADF7',
  CL: '#D9F7BE',
  // MSS
  MSS_DR: '#D9D9D9',
  MSS_AP: '#52C41A',
  MSS_RIP: '#91CAFF',
  MSS_RE: '#FFA39E',
  MSS_APC: '#389E0D',
  // MDI
  MDI_DR: '#D9D9D9',
  MDI_AP: '#52C41A',
  MDI_RIP: '#91CAFF',
  MDI_RE: '#FFA39E',
  MDI_APC: '#389E0D',
  // MAR
  MAR_DR: '#D9D9D9',
  MAR_AP: '#52C41A',
  MAR_RIP: '#91CAFF',
  MAR_RE: '#FFA39E',
  MAR_APC: '#389E0D',

  // WIR
  WIR_DR: '#D9D9D9',
  WIR_AP: '#52C41A',
  WIR_RIP: '#91CAFF',
  WIR_RE: '#FFA39E',
  WIR_APC: '#389E0D',
  // MOM
  MOM_ISD: '#52C41A',
  MOM_RIP: '#91CAFF',
  MOM_DR: '#7E868B',

  // RFI
  RFI_OP: '#FFD591',
  RFI_CL: '#52C41A',
  RFI_DR: '#D9D9D9 ',

  // TRA
  TRA_DR: 'rgba(0, 0, 0, 0.45)',
  TRA_ISD: '#52C41A',
  // LETTER
  LET_DR: '#7e868b',
  LET_ISD: '52C41A',
  //Ticketing
  T_HEAD: '#3069C4',
  RP: '#BAE0FF',
  CRE: '#FFE7BA',
  CLO: '#D9F7BE',
  //Asset
  A_HEAD: '#DF6664',

  //Change Order
  CO_DR: '#D9D9D9',
  CO_AP: '#52C41A',
  CO_RE: '#FFA39E',
  CO_RIP: '#91CAFF',
  //Header
  QUALITY_HEADER: '#3069C4',
  SAFETY_HEADER: '#D37F07',
  CONSTRUCTION_HEADER: '#741C47',
  CONSTRUCTION_LIGHT: '#F1E8ED',
};

export const HISTORY_ACTION_COLOR: Record<string, string> = {
  'uploaded-attachment': '#00000073',
  'deleted-attachment': '#00000073',
  'left-the-comment': '#00000073',
  'called-for-review': '#1677FF',
  'validated-the-inspection': '#52C41A',
  'rejected-the-inspection': '#FF4D4F',
  'closed-the-inspection': '#52C41A',
  'created-the-inspection': '#00000073',
  'submitted-the-inspection': '#FFA500',
  'assigned-the-inspection': '#1677FF',
  'transferred-the-inspection': '#1677FF',
  'self-assigned-the-inspection': '#1677FF',
};
export const TASK_ATTACHMENT_TYPE = {
  IP: 'IP',
  LP: 'LP',
};

export const ATTACHMENT_TYPES = {
  LOCATION_PLAN: 'LP',
  INSPECTION_PHOTO: 'IP',
  MSS_DOCUMENT: 'MSD',
  MAR_PHOTO: 'MAP',
  MAR_DOCUMENT: 'MAD',
  MDI_PHOTO: 'MDP',
  MDI_DOCUMENT: 'MDD',
  SPECIFICATION: 'SP',
  BROCHURE: 'BR',
  INSPECTION_TEST_PLAN_TEMPLATE: 'ITT',
  INSPECTION_TEST_PLAN_SPECS: 'ITS',
  WIR_CHECKLIST: 'WIC',
  WIR_PHOTO: 'VAA',
  WIR_REF_DOCUMENTATION: 'WRD',
  VAD: 'VAD',
  VAA: 'VAA',
  DOCUMENT_TRANSMISSIONS: 'DTD',
  LETTERS: 'LTD',
  BASE_DOCUMENT: 'BDA',
  BASE_PHOTO: 'BPA',
  PTW_CHECKLIST: 'WIC',
  PTW_PHOTO: 'VAA',
  PTW_REF_DOCUMENTATION: 'WRD',
  MOM_DOCUMENT: 'MID',
  MOM_PHOTO: 'MIP',
};

// Please note: The order of declarations in ISSUE_STATUSES affects the display order.
// Ensure to declare the statuses in the desired order of appearance.
export const ISSUE_STATUSES = {
  DRAFT: 'DR',
  OPEN: 'OP',
  OPEN_OVERDUE: 'OO',
  ACTION_AGREE: 'AA',
  REVIEW_IN_PROGRESS: 'RIP',
  REVIEW_IN_PROGRESS_OVERDUE: 'RIPO',
  APPROVED: 'AP',
  ISSUED: 'ISD',
  APPROVED_WITH_COMMENT: 'APC',
  REJECT: 'RE',
  READY_FOR_INSPECTION: 'RFI',
  CLOSED: 'CL',
  READY_FOR_INSPECTION_OVERDUE: 'RIO',
  REJECTED: 'RE',
  OPEN_START: 'OPS',
  CLOSED_END: 'CLE',
  AVERAGE_CLOSURE_TIME: 'AVG',
  TOTAL: 'TOTAL',
};

export const ISSUE_STATUSES_TITLE = {
  DRAFT: 'Draft',
  REVIEW_IN_PROGRESS: 'Review in Progress',
  REVIEW_IN_PROGRESS_OVERDUE: 'Review in Progress',
  APPROVED: 'Approved',
  APPROVED_WITH_COMMENT: 'Approved with Comments',
  ISSUED: 'Issued',
  REJECT: 'Rejected',
  REJECTED: 'Rejected',
  READY_FOR_INSPECTION: 'Ready for Review',
  READY_FOR_INSPECTION_OVERDUE: 'Ready for Review',
  OPEN: 'Open',
  OPEN_OVERDUE: 'Open',
  OPEN_START: 'Open',
  CLOSED: 'Closed',
  CLOSED_END: 'Closed',
  ACTION_AGREE: 'Action Agreed',
};

export const MSS_STATUSES = {
  DRAFT: 'MSS_DR',
  REVIEW_IN_PROGRESS: 'MSS_RIP',
  APPROVED: 'MSS_AP',
  REJECTED: 'MSS_RE',
  REVIEW_IN_PROGRESS_OVERDUE: 'MSS_RIPO',
  APPROVED_WITH_COMMENT: 'MSS_APC',
};
export const MAR_STATUSES = {
  DRAFT: 'MAR_DR',
  APPROVED: 'MAR_AP',
  REJECTED: 'MAR_RE',
  REVIEW_IN_PROGRESS: 'MAR_RIP',
  APPROVED_WITH_COMMENT: 'MAR_APC',
};
export const MDI_STATUSES = {
  DRAFT: 'MDI_DR',
  APPROVED: 'MDI_AP',
  REJECTED: 'MDI_RE',
  REVIEW_IN_PROGRESS: 'MDI_RIP',
};

export const WIR_STATUSES = {
  DRAFT: 'WIR_DR',
  APPROVED: 'WIR_AP',
  APPROVED_WITH_COMMENT: 'WIR_APC',
  REJECTED: 'WIR_RE',
  REVIEW_IN_PROGRESS: 'WIR_RIP',
};

export const PTW_STATUSES = {
  DRAFT: 'PTW_DR',
  REVIEW_IN_PROGRESS: 'PTW_RIP',
  APPROVED: 'PTW_AP',
  APPROVED_WITH_COMMENT: 'PTW_APC',
  REJECTED: 'PTW_RE',
  OPEN: 'PTW_OP',
  CLOSED: 'PTW_CL',
};
export const PTW_STATUSES_TITLE = {
  DRAFT: 'Draft',
  REVIEW_IN_PROGRESS: 'Review in Progress',
  APPROVED: 'Approved',
  APPROVED_WITH_COMMENT: 'Approved with comments',
  REJECTED: 'Rejected',
  OPEN: 'Open',
  CLOSED: 'Closed',
  SUBMITTED: 'Submitted',
  TO_BE_REVISED: 'To be revised',
};

export const TRA_STATUSES = {
  DRAFT: 'TRA_DR',
  FOR_APPROVAL: 'TRA_FAP',
  ISSUED: 'TRA_ISD',
};
export const LET_STATUSES = {
  DRAFT: 'LET_DR',
  ISSUED: 'LET_ISD',
};
export const RFI_STATUSES = {
  DRAFT: 'RFI_DR',
  CLOSED: 'RFI_CL',
  OPEN: 'RFI_OP',
};
export const MOM_STATUSES = {
  DRAFT: 'MOM_DR',
  READY_FOR_REVIEW: 'MOM_RIP',
  ISSUED: 'MOM_ISD',
};

export const ISSUE_PRIORITIES = {
  HIGHEST: 'HIGHEST',
  HIGHT: 'HIGHT',
  LOWEST: 'LOWEST',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
};

export const ISSUE_TYPES = {
  SAFETY_VIOLATIONS: 'SV',
  SAFETY_OBSERVATIONS: 'SO',
  QUALITY_NON_CONFORMITIES: 'QNC',
  PUNCH: 'PU',
  QUALITY_OBSERVATIONS: 'QO',
};

export const ISSUE_DISCIPLINES = {
  ELECTRICAL_HIGH_VOLTAGE: 'HV',
  STRUCTURAL: 'ST',
  CIVIL: 'CV',
  ARCHITECTURAL: 'AR',
  INTERIOR_DECORATION: 'ID',
  GENERAL: 'GN',
  ELECTRICAL_LOW_VOLTAGE: 'LV',
  PLUMBING: 'PL',
  FIRE_FIGHTING: 'FF',
};
export const HISTORY_ACTION = {
  UPLOADED_ATTACHMENT: 'uploaded-attachment',
  DELETED_ATTACHMENT: 'deleted-attachment',
  LEFT_THE_COMMENT: 'left-the-comment',
  CALLED_FOR_REVIEW: 'called-for-review',
  VALIDATED_THE_INSPECTION: 'validated-the-inspection',
  REJECTED_THE_INSPECTION: 'rejected-the-inspection',
  CLOSED_THE_INSPECTION: 'closed-the-inspection',
  CREATED_THE_INSPECTION: 'created-the-inspection',
  SUBMITTED_THE_INSPECTION: 'submitted-the-inspection',
  ASSIGNED_THE_INSPECTION: 'assigned-the-inspection',
  TRANSFERRED_THE_INSPECTION: 'transferred-the-inspection',
  SELF_ASSIGNED_THE_iNSPECTION: 'self-assigned-the-inspection',
};
export const SHOULD_NOT_CAPITALIZE = [
  'in',
  'as',
  'a',
  'an',
  'the',
  'and',
  'but',
  'or',
  'for',
  'nor',
  'on',
  'at',
  'to',
  'from',
  'by',
  'with',
  'per',
];

export const LANGUAGEOPTIONS = [
  {
    id: 'en',
    label: 'English',
    emoji: flagUK,
  },
  {
    id: 'cn',
    label: '中文',
    emoji: flagCN,
  },
  {
    id: 'es',
    label: 'Spanish',
    emoji: flagES,
  },
  {
    id: 'fr',
    label: 'Français',
    emoji: flagFR,
  },
  {
    id: 'id',
    label: 'Bahasa',
    emoji: flagIN,
  },
  {
    id: 'km',
    label: 'Khmer',
    emoji: flagKM,
  },
  {
    id: 'pt',
    label: 'Portuguese',
    emoji: flagPT,
  },
  {
    id: 'ru',
    label: 'Русский',
    emoji: flagRU,
  },
  {
    id: 'th',
    label: 'แบบไทย',
    emoji: flagTH,
  },
  {
    id: 'vn',
    label: 'Tiếng Việt',
    emoji: flagVN,
  },
];

export const VALIDATION_STATUS = {
  REJECTED: 'rejected',
  IN_REVIEW: 'in-review',
  VALIDATED: 'validated',
  APPROVED: 'approved',
  TRANSFERRED: 'transferred',
  TO_BE_REVISED: 'to-be-revised',
  REVIEW_IN_PROGRESS: 'review-in-progress',
  APPROVED_WITH_COMMENT: 'approved-with-comment',
  SUBMITTED: 'submitted',
  SEND_TO_RECIPIENT: 'send-to-recipient',
  SEND_TO_RECIPIENTS: 'send-to-recipients',
  INTERNAL_REVIEW: 'internal-review',
  EXTERNAL_REVIEW: 'external-review',
  ISSUED: 'issued',
  SEND_FOR_VALIDATION: 'send-for-validation',
};

export const VALIDATION_STATUS_COLOR = {
  REJECTED: 'red',
  IN_REVIEW: 'orange',
  VALIDATED: 'green',
  APPROVED: 'green',
  IN_REVIEW_BLUE: 'blue',
};

export const VALIDATION_ACTION = {
  REJECT: 'reject',
  VALIDATE: 'validate',
  TRANSFER: 'transfer',
  FINAL_VALIDATION: 'final-validation',
  SUBMIT: 'submit',
  SENT_TO_RECIPIENT: 'sent-to-recipients',
  IN_REVIEW: 'in review',
};

export const VALIDATION_STEP_TYPE = {
  INTERNAL: 'internal-validation',
  EXTERNAL: 'external-validation',
  REVISION: 'revision',
};

export const MODULE_FUNCTIONS = {
  MATERIAL_APPROVAL_REQUEST: 'MAR',
  MATERIAL_DELIVERY_REQUEST: 'MDI',
  METHOD_STATEMENT_SUBMISSION: 'MSS',
  SHOP_DRAWING: 'SD',
  INSPECTION: 'INS',
  ANALYTICS: 'ANA',
  ZONING: 'ZON',
  WIR: 'WIR',
  TRA: 'TRA',
  //todo: update to PTW
  PTW: 'WIR',
  MOM: 'MOM',
  RFI: 'RFI',
};

export const NUMBERING_DIGITS = 4;

export const COUNTRY_CODE = [
  {
    id: 'fr',
    icon: frSVG,
    name: 'France',
    value: '33',
  },
  {
    id: 'es',
    icon: esSVG,
    name: 'Spain',
    value: '34',
  },
  {
    id: 'uk',
    icon: ukSVG,
    name: 'United Kingdom',
    value: '44',
  },
  {
    id: 'id',
    icon: idSVG,
    name: 'Indonesia',
    value: '62',
  },
  {
    id: 'th',
    icon: thSVG,
    name: 'Thailand',
    value: '66',
  },
  {
    id: 'vn',
    icon: vnSVG,
    name: 'Vietnam',
    value: '84',
  },
  {
    id: 'cn',
    icon: cnSVG,
    name: 'China',
    value: '86',
  },
  {
    id: 'pt',
    icon: ptSVG,
    name: 'Portugal',
    value: '351',
  },
  {
    id: 'km',
    icon: kmSVG,
    name: 'Cambodia',
    value: '855',
  },
];

export const INVITATION_ROLE = {
  RECEIVER: 'receiver',
  DISTRIBUTION: 'distribution',
};

export const BASE_MESSAGE_ACTION = {
  FORM_EDIT: 'form-edit',
  COMMENT: 'comment',
  COMMENT_DELETE: 'comment-delete',
  COMMENT_EDIT: 'comment-edit',
  ADD: 'transfer',
};

export const CO_STATUSES = {
  DRAFT: 'CO_DR',
  APPROVED: 'CO_AP',
  APPROVED_WITH_COMMENT: 'CO_APC',
  REJECTED: 'CO_RE',
  REVIEW_IN_PROGRESS: 'CO_RIP',
};
export const CHANGE_BODY_NOTIFICATION_INSPECTION = {
  CALL_FOR_REVIEW_AN_INSPECTION: 'Called for Review an Inspection',
  CHANGE_THE_PRIORITY_TO_HIGH: 'changed the Priority of Inspection to High',
  CHANGE_THE_PRIORITY_TO_LOW: 'changed the Priority of Inspection to LOW',
  COMMENTED_ON_AN_INSPECTION: 'commented on an Inspection',
  RE_TRANSFERRED_AN_INSPECTION: 're-transferred an Inspection',
  TRANSFERRED_TO_YOU_AN_INSPECTION: 'transferred to you an Inspection',
  UPLOADED_AN_ATTACHMENT: 'uploaded an attachment for an Inspection',
  CALLED_FOR_REVIEW: 'Called for Review an Inspection',
  ASSIGNED_TO_YOU: 'assigned to you an Inspection',
  RE_ASSIGNED: 're-assigned an Inspection',
  VALIDATED_AN_INSPECTION: 'Validated an Inspection',
  CREATED_AN_INSPECTION: 'created an Inspection',
  CLOSED_AN_INSPECTION: 'Closed an Inspection',
  REJECTED_AN_INSPECTION: 'Rejected an Inspection',
  SUBMITTED_AN_INSPECTION: 'Submitted an Inspection',
  ADDED_WATCHER_ON_INSPECTION: 'Added watcher on Inspection',
};
export const CHANGE_BODY_NOTIFICATION_OBSERVATION = {
  CALL_FOR_REVIEW_AN_INSPECTION: 'Called for Review an Observation',
  CHANGE_THE_PRIORITY_TO_HIGH: 'changed the Priority of Observation to High',
  CHANGE_THE_PRIORITY_TO_LOW: 'changed the Priority of Observation to LOW',
  COMMENTED_ON_AN_INSPECTION: 'commented on an Observation',
  RE_TRANSFERRED_AN_INSPECTION: 're-transferred an Observation',
  TRANSFERRED_TO_YOU_AN_INSPECTION: 'transferred to you an Observation',
  UPLOADED_AN_ATTACHMENT: 'uploaded an attachment for an Observation',
  CALLED_FOR_REVIEW: 'Called for Review an Observation',
  ASSIGNED_TO_YOU: 'assigned to you an Observation',
  RE_ASSIGNED: 're-assigned an Observation',
  VALIDATED_AN_INSPECTION: 'Validated an Inspection',
  CREATED_AN_INSPECTION: 'created an Inspection',
  CLOSED_AN_INSPECTION: 'Closed an Inspection',
  REJECTED_AN_INSPECTION: 'Rejected an Inspection',
  SUBMITTED_AN_INSPECTION: 'Submitted an Inspection',
  ADDED_WATCHER_ON_INSPECTION: 'Added watcher on Inspection',
};
export const SI_STATUSES = {
  DRAFT: 'SI_DR',
  REVIEW_IN_PROGRESS: 'SI_RIP',
  ISSUED: 'SI_IS',
  ISSUED_WITH_COMMENTS: 'SI_IC',
  TO_REVISE: 'SI_TR',
  REJECTED: 'SI_RE',
  CANCELLED: 'SI_CA',
};
