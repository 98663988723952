import { PlusOutlined } from '@ant-design/icons';
import { Flex, Typography, Button } from 'antd';
import React from 'react';

export type HeaderWithButtonProps = {
  isReadonly?: boolean;
  title?: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  buttonText?: string;
  marginBottom?: string
  className?: string;
};
const HeaderWithButton = (props: HeaderWithButtonProps) => {
  return (
    <Flex align='center' justify='space-between' className={`mb-${props.marginBottom || 4} ${props?.className || ''}`}>
      <Typography.Title level={3}>{props.title}</Typography.Title>
      {!props.isReadonly && (
       <Button onClick={props?.onClick} type='dashed' icon={<PlusOutlined />}>
          {props.buttonText}
        </Button>
      )}
    </Flex>
  );
};

export default HeaderWithButton;
