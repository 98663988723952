import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Flex, Layout, Tag, message } from 'antd';
import { User } from 'model';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_TYPES, ISSUE_STATUSES, MAR_STATUS_COLOR, NUMBERING_DIGITS } from 'utils/contants';
import { DeleteConfirmation, ValidationHistoryStepOnTable } from 'components';
import dayjs from 'dayjs';
import { BaseIssue } from '../../model';
import { formatUrlAttachments } from 'utils';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { baseIssueService, fileService } from 'services';
import { useAppSelector } from 'store';
import { selectNewProjectWorkBreakdownStructures } from 'store/my-projects.slice';
import CustomTooltip from 'components/common/CustomTooltip';
import UserCompany from 'components/common/UserCompany';
import { CustomColumnType } from 'components/common/DynamicTable';
import DisciplineBaseIssueTable, {
  DisciplineBaseIssueTableRefHandle,
} from 'components/common/DisciplineBaseIssueTable';
import { kpiData } from 'types/project';
import { ProjectBaseIssuesParamsType } from 'hooks/base-issue';
import { TableRowsSelected } from 'types';

type MaterialApprovalRequestsProps = {
  projectId: string;
  workspaceId: string;
  users: User[];
  projectBaseIssueParams: ProjectBaseIssuesParamsType;
  profile: User | null;
  disciplineBaseIssueTableRef?: RefObject<DisciplineBaseIssueTableRefHandle>;
  editForm: (id: string) => void;
  onTotalKpi: (kpi: kpiData) => void;
  handlerRowsKeyChange: (value: React.Key[], id?: string) => void;
  selectedRowKeys?: TableRowsSelected;
  setSelectedRowKeys: (value: TableRowsSelected) => void;
  refreshDisciplineByFunction: () => void;
};

export default function MaterialApprovalRequestListView(props: MaterialApprovalRequestsProps) {
  const { users, projectId, workspaceId, projectBaseIssueParams, editForm, handlerRowsKeyChange, selectedRowKeys } =
    props;
  const { t } = useTranslation();
  // const [locale, setLocale] = useState<string>('en');
  const locale = 'en';
  // const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
  const dateFormat = 'YYYY-MM-DD';
  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const workBreakDownStructures = useAppSelector(selectNewProjectWorkBreakdownStructures);
  const [isbaseIussesRelateMDI, setIsbaseIussesRelateMDI] = useState<boolean>(false);

  const [isDelete, setIsDelete] = useState<{
    open: boolean;
    materialApprovalRequestId?: string;
    disciplineId?: string;
  }>({
    open: false,
    materialApprovalRequestId: undefined,
    disciplineId: '',
  });

  function numberingPadding(inputString: string) {
    const parts = inputString.split('-');
    const lastPart = parts.pop(); // Extract the last part
    if (!lastPart) return inputString;
    const paddedLastPart = lastPart.padStart(NUMBERING_DIGITS, '0'); // Pad the last part with zeros
    parts.push(paddedLastPart); // Push the padded last part back to the array
    return parts.join('-'); // Join all parts back together
  }

  const handleMenuClick = (key: string, record: BaseIssue) => {
    if (key) {
      switch (key) {
        case 'edit':
          editForm(record.id);
          break;
        case 'delete':
          setIsDelete({
            open: true,
            materialApprovalRequestId: record.id,
            disciplineId: record.MaterialApprovalRequest?.disciplineId,
          });
          break;
      }
    }
  };
  const handleDeleteBaseIssue = async () => {
    try {
      const materialApprovalRequestId = isDelete.materialApprovalRequestId;
      if (materialApprovalRequestId) {
        await baseIssueService.deleteBaseIssueById(workspaceId, projectId, materialApprovalRequestId);
        setIsDelete({ open: false, materialApprovalRequestId: undefined });
        setIsbaseIussesRelateMDI(false);
        props.disciplineBaseIssueTableRef?.current?.refreshData(isDelete?.disciplineId, materialApprovalRequestId);
      } else {
        message.error(t('Material Approval Request not existed!'));
      }
    } catch (error) {
      console.log(error);
      setIsbaseIussesRelateMDI(true);
      setIsDelete({ open: false, materialApprovalRequestId: undefined });
    }
  };

  const columns: CustomColumnType<BaseIssue>[] = [
    {
      sorter: true,
      title: t('Number'),
      dataIndex: 'MaterialApprovalRequest',
      key: 'MaterialApprovalRequest.marNumber',
      onCell: () => {
        return {
          style: {
            maxWidth: '250px',
          },
        };
      },
      render: (MaterialApprovalRequest) => {
        return (
          <CustomTooltip
            title={MaterialApprovalRequest?.marNumber ? numberingPadding(MaterialApprovalRequest?.marNumber) : ''}
          />
        );
      },
    },
    {
      sorter: true,
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
      render: (name) => {
        return <CustomTooltip title={name} />;
      },
      width: 300,
    },
    {
      sorter: true,
      title: t('Activity'),
      dataIndex: 'MaterialApprovalRequest',
      key: 'MaterialApprovalRequest.activityId',
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
      render: (MaterialApprovalRequest) => {
        const activity = workBreakDownStructures?.find((wbs) => wbs.id === MaterialApprovalRequest?.activityId);
        return <CustomTooltip title={activity?.name || ''} />;
      },
      width: 100,
    },
    {
      title: 'Sub-activity',
      dataIndex: 'subActivity',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '100px',
          },
        };
      },
    },
    {
      // sorter: true,
      title: t('Submitted By'),
      dataIndex: 'originatorId',
      key: 'originatorId',
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
      render: (originatorId) => {
        const user = (users || []).find((user) => user.id === originatorId);
        return (
          <UserCompany
            data={{
              companyName: user?.WorkspaceUser?.Company?.name,
              userName: user?.fullName,
            }}
          />
        );
      },
      width: 120,
    },
    {
      // sorter: true,
      title: t('Submitted To'),
      dataIndex: 'BaseValidations',
      key: 'submittedTo',
      width: 150,
      onCell: () => {
        return {
          style: {
            maxWidth: '150px',
          },
        };
      },
      render: (value, record) => {
        return ValidationHistoryStepOnTable(record, true, users);
      },
    },
    {
      sorter: true,
      title: t('Issue Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (createdAt, record) => (
        <span>{record && dayjs(createdAt).locale(locale).format(dateFormat).toString()}</span>
      ),
    },
    {
      sorter: true,
      title: t('Status'),
      dataIndex: 'statusId',
      key: 'IssueStatus.orderNumber',
      width: 130,
      render: (statusId, record) => {
        return (
          <>
            {record.IssueStatus?.name && record.IssueStatus?.code ? (
              <Tag className={record.IssueStatus?.code} color={MAR_STATUS_COLOR[record.IssueStatus?.code || 'DR']}>
                {t(record.IssueStatus?.name)}
              </Tag>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: t('Attachments'),
      dataIndex: 'MaterialApprovalRequest',
      key: 'MaterialApprovalRequest',
      onCell: () => {
        return {
          style: {
            maxWidth: '120px',
          },
        };
      },
      render: (value, record: BaseIssue) => {
        const Attachments = record.MaterialApprovalRequest?.MarDescriptions?.[0]?.Attachments || [];
        const marDocumentType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MAR_DOCUMENT);
        const docs = Attachments.filter((i) => i.attachmentTypeId === marDocumentType?.id);
        return (
          <>
            {docs?.length > 0 && (
              <Flex vertical justify='flex-start'>
                {docs.map((opt) => (
                  <Button
                    key={opt?.id}
                    type='link'
                    icon={<FilePdfOutlined />}
                    className='ml-0 pa-0 d-flex'
                    style={{ height: 'auto', justifyContent: 'normal' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      fileService.openFile(opt?.filePath || '');
                    }}
                  >
                    <CustomTooltip title={formatUrlAttachments(opt?.filePath || '')} />
                  </Button>
                ))}
              </Flex>
            )}
          </>
        );
      },
      width: 120,
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit Material Approval Request',
            key: 'edit',
          },
          {
            label: 'Delete Material Approval Request',
            key: 'delete',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            isDisabled: (record: any) => {
              const userCompanyId = users?.find((user: User) => user.id === props.profile?.id)?.WorkspaceUser?.Company
                ?.id;
              const isDeleteRecord =
                userCompanyId ===
                users.find((user: User) => user.id === record?.originatorId)?.WorkspaceUser?.Company?.id;
              return !isDeleteRecord;
            },
          },
        ],
      },
    },
  ];

  return (
    <Layout.Content className='settings-content'>
      <DeleteConfirmation
        isModalOpen={isDelete.open}
        title={t('Delete the Material Approval Request')}
        description={t('Are you sure to delete this Material Approval Request?')}
        onDelete={handleDeleteBaseIssue}
        onCancel={() => setIsDelete({ open: false, materialApprovalRequestId: undefined })}
        relation={isbaseIussesRelateMDI}
      />
      <DisciplineBaseIssueTable
        ref={props.disciplineBaseIssueTableRef}
        columns={columns}
        header={t('Discipline')}
        onActionClick={handleMenuClick}
        onRowClick={(record) => {
          editForm(record.id);
        }}
        onMapDataSource={(baseIssue: BaseIssue) => {
          return {
            ...baseIssue,
            activity: baseIssue?.MaterialApprovalRequest?.Activity?.name,
            subActivity: baseIssue?.MaterialApprovalRequest?.SubActivity?.name,
          };
        }}
        projectBaseIssueParams={projectBaseIssueParams}
        loadAllListTrigger={projectBaseIssueParams}
        onTotalKpi={props.onTotalKpi}
        handlerRowsKeyChange={handlerRowsKeyChange}
        selectedRowKeys={selectedRowKeys}
        refreshDiscipline={props?.refreshDisciplineByFunction}
        excludeKpiField={[ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE]}
      />
    </Layout.Content>
  );
}
