import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from 'model';
import TicketType from 'model/TicketType';
import ticketingService from 'services/ticketing.services';
import { RootState, resetAll } from 'store';
import { Loading, QueryParams } from 'types';

interface MyUserState {
  myUser: User | null;
  myUserLoading: Loading;
  tickets: TicketType[];
  ticketsCount: number;
  ticketsLoading: Loading;
}

const name = 'ny-user';
const initialState: MyUserState = {
  myUser: null,
  myUserLoading: 'idle',
  tickets: [],
  ticketsCount: 0,
  ticketsLoading: 'idle',
};

export const fetchTicketing = createAsyncThunk(
  `${name}/list-of-tickets`,
  async (query: { userId: string } & QueryParams, { rejectWithValue }) => {
    try {
      const response = await ticketingService.getTickets(query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const myUserSlice = createSlice({
  name,
  initialState,
  reducers: {
    initMyUser: (state, action) => {
      state.myUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchTicketing.pending, (state) => {
        state.ticketsLoading = 'pending';
      })
      .addCase(fetchTicketing.fulfilled, (state, action) => {
        state.ticketsLoading = 'idle';
        state.tickets = action.payload.rows;
        state.ticketsCount = action.payload.count;
      })
      .addCase(fetchTicketing.rejected, (state) => {
        state.ticketsLoading = 'idle';
      });
  },
});

export const selectMyUserTickets = (state: RootState) => state.myUser.tickets;
export const selectMyUserTicketsCount = (state: RootState) =>
  state.myUser.ticketsCount;
export const selectMyUserTicketsLoading = (state: RootState) =>
  state.myUser.ticketsLoading;

export const { initMyUser } = myUserSlice.actions;

export default myUserSlice.reducer;
