import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import { RFIFormValues } from './RequestForInformationForm';
import DocumentAdder from 'components/common/DocumentAdder';

type MSSsListDocumentSectionProps = {
  isReadOnly: boolean;
  workspaceId: string;
  projectId: string;
  attachmentsInfo: { name: string; filePath: string }[];
  setAttachmentsInfo: (value: { name: string; filePath: string }[]) => void;
};
export default function RequestForInformationListDocument(props: MSSsListDocumentSectionProps) {
  const { t } = useTranslation();
  const form = useANTDFormInstance<RFIFormValues>();
  const attachment = Form.useWatch('attachments') || form.getFieldValue('attachments') || [];

  return (
    <div className='list-document-popup-container'>
      <Form.Item name={'attachments'}>
        <DocumentAdder
          isReadonly={props.isReadOnly}
          title={t('Attachments')}
          attachmentType={ATTACHMENT_TYPES.BASE_DOCUMENT}
          onChange={(attachments) => {
            form.setFieldValue('attachments', attachments);
          }}
          documentsInfo={attachment}
          layout='inline'
        />
      </Form.Item>
    </div>
  );
}
