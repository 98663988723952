import { FilePdfTwoTone } from '@ant-design/icons';
import { CustomColumnType, DynamicCollapseTable, TooltipData } from 'components';
import { findPath } from 'components/common/TreeSelectTagRender';
import { mockPTWTableData } from 'mock-data/project-data';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectProjectZones } from 'store/my-projects.slice';
import { EIncidentEntityName } from 'types/incident-form';
import { convertZoneToTreeData } from 'utils';
import { ISSUE_STATUSES } from 'utils/contants';

export type PTWTableData = {
  id: string;
  number: string;
  name: TooltipData | string;
  subCategory: string;
  zoneId: string;
  submittedBy: string;
  submittedTo: string;
  category?: string;
  issueDate: string;
  status?: {
    code?: string;
    name?: string;
  };
  report?: string;
};

type PermitToWorkListsViewProps = {
  onActionClick: (action: string, record: PTWTableData) => void;
  entityName: EIncidentEntityName;
};
const PermitToWorkListsView = (props: PermitToWorkListsViewProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isSafetyPage = location.pathname.includes('safety');
  const module = isSafetyPage ? 'Safety' : 'Quality';
  const zones = useSelector(selectProjectZones);
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);

  const getReportTitle = () => {
    switch (props.entityName) {
      case EIncidentEntityName.Hazard:
        return t('Hazards Report');
      case EIncidentEntityName.NearMisses:
        return t('Near Miss Report');
      case EIncidentEntityName.SafetyViolation:
        return t('Violation Report');
      case EIncidentEntityName.Accidents:
        return t('Accident Report');
      case EIncidentEntityName.PermitToWork:
        return t('Permit to work Report');
      default:
        return '';
    }
  };

  const columns: CustomColumnType<PTWTableData>[] = [
    {
      title: t('Number'),
      dataIndex: 'number',
      render: (value, record) => {
        return record.status?.code !== ISSUE_STATUSES.DRAFT ? value : '';
      },
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      sortKey: 'name',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            width: '200px',
          },
        };
      },
    },
    {
      title: t('Sub-category'),
      dataIndex: 'subCategory',
      onCell: () => {
        return { style: { maxWidth: '100px' } };
      },
    },
    {
      title: t('Zoning'),
      dataIndex: 'zoneId',
      onCell: () => {
        return { style: { maxWidth: '240px' } };
      },
      render: (zoneId) => {
        return findPath(zonesTreeData, zoneId);
      },
    },
    {
      title: t('Submitted by'),
      dataIndex: 'submittedBy',
      width: 150,
      onCell: () => {
        return { style: { maxWidth: '150px' } };
      },
    },
    {
      title: t('Submitted To'),
      dataIndex: 'submittedTo',
      width: 150,
      onCell: () => {
        return { style: { maxWidth: '150px' } };
      },
    },
    {
      title: t('Inspection date'),
      dataIndex: 'inspectionDate',
      component: { type: 'date' },
      width: 110,
      sortKey: 'inspectionDate',
    },
    {
      title: t('Start Date'),
      dataIndex: 'issueDate',
      component: { type: 'date' },
      width: 110,
      sortKey: 'issueDate',
    },
    {
      title: t('End Date'),
      dataIndex: 'issueDate',
      component: { type: 'date' },
      width: 110,
      sortKey: 'issueDate',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      sortKey: 'IssueStatus.orderNumber',
      component: {
        type: 'status',
      },
    },
    {
      title: getReportTitle(),
      dataIndex: 'report',
      width: 90,
      render: () => {
        return (
          <span className='text-primary text-size-20'>
            <FilePdfTwoTone />
          </span>
        );
      },
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit',
            key: 'edit',
          },
          {
            label: 'Delete',
            key: 'delete',
          },
        ],
      },
    },
  ];

  return (
    <DynamicCollapseTable
      columns={columns}
      data={mockPTWTableData}
      header={t('Category')}
      module={module}
      onActionClick={props.onActionClick}
      onRowClick={(record) => {
        props?.onActionClick('selected-row', record);
      }}
    />
  );
};

export default PermitToWorkListsView;
