import { DatePicker, Form, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useMemo, useState } from 'react';
import { MOMItemDataSource } from './ProjectMinutesOfMeetingContentDetails';
import dayjs, { Dayjs } from 'dayjs';
import { Company, User } from 'model';
import { SearchSelect } from 'components/common';

type ProjectMinutesOfMeetingContentDetailsCompanyProps = {
  responsibleCompany: string;
  personInCharge: string;
  dueDate: string | Date | Dayjs;
  onFieldChange: (key: keyof MOMItemDataSource, value: unknown) => void;
  isReadonly?: boolean;
  companies: Company[];
  users: User[];
  form: FormInstance;
  index: number;
};
const ProjectMinutesOfMeetingContentDetailsCompany = (props: ProjectMinutesOfMeetingContentDetailsCompanyProps) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    props.onFieldChange('responsibleCompany', companyId);
  };
  const onChangeDueDate = (dueDate: string) => {
    props.onFieldChange('dueDate', dueDate);
  };
  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = props.users?.find((user) => user.id === assigneeId);
    props.onFieldChange('responsibleCompany', selectedUser?.WorkspaceUser?.Company?.id);
    props.onFieldChange('personInCharge', assigneeId);
  };

  const assigneeFilter = useMemo(() => {
    return props.users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [selectedCompanyId]);

  return (
    <Form form={props.form} layout='vertical'>
      <div className={props.isReadonly ? 'flex flex-col items-center h-[180px]' : 'h-[340px]'}>
        {props.isReadonly ? (
          <Typography.Text className='text-size-14 font-bold'>
            {props.companies?.find((option) => option.id === props.responsibleCompany)?.name}
          </Typography.Text>
        ) : (
          <Form.Item name={['items', props.index, 'responsibleCompany']} label='Responsible Company'>
            <SearchSelect
              options={props.companies?.map((company) => {
                return {
                  value: company.id,
                  label: company.name,
                };
              })}
              defaultValue={props.responsibleCompany}
              onChange={onChangeCompany}
            />
          </Form.Item>
        )}

        {props.isReadonly ? (
          <Typography.Text className='text-size-14 text-center block'>
            {props.users?.find((option) => option.id === props.personInCharge)?.fullName}
          </Typography.Text>
        ) : (
          <Form.Item name={['items', props.index, 'personInCharge']} label='Person in Charge'>
            <SearchSelect
              options={assigneeFilter.map((user) => {
                return {
                  value: user.id,
                  label: user.fullName,
                };
              })}
              defaultValue={props.personInCharge}
              onChange={onChangeAssignee}
            />
          </Form.Item>
        )}
      </div>

      <div className='due-date'>
        {props.isReadonly ? (
          <Typography.Text className='text-size-14'>
            {props.dueDate ? dayjs(props.dueDate).format('YYYY-MM-DD') : '--'}
          </Typography.Text>
        ) : (
          <Form.Item name={['items', props.index, 'dueDate']} label='Due Date'>
            <DatePicker format='YYYY-MM-DD' onChange={onChangeDueDate} />
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default ProjectMinutesOfMeetingContentDetailsCompany;
