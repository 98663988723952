import { Button, Col, Flex, Form, FormInstance, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchSelect } from 'components/common';
import { useOtherProjects, useOtherProjectsParams, useProjectCompanies, useProjectCompaniesParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { User } from 'model';

type InviteForInspectionProps = {
  form: FormInstance;
  projectId: string;
  companyOriginator: string | undefined;
  users: User[];
  isOpen: boolean;
  isReadOnly: boolean;
};

const { Text, Title } = Typography;

export default function InviteForInspection(props: InviteForInspectionProps) {
  const { t } = useTranslation();
  const { form, projectId, companyOriginator, users, isOpen, isReadOnly } = props;

  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);
  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

  const [companies] = useProjectCompanies(companyParams);
  const [username, setUsername] = useState<string>();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedInspectorIds, setSelectedInspectorIds] = useState<string[]>([]);
  const [isRemovedView, setIsRemoved] = useState<boolean>(false);

  const onChangeCompany = (companyId: string, index: number) => {
    setIsRemoved(false);
    if (!companyId) {
      setSelectedCompany('');
      form.setFieldValue(['mdiInvitations', index, 'companyId'], null);
    }
    setSelectedCompany(companyId);
    const autoFillUsers = users?.filter((user) => !companyId || user.WorkspaceUser?.Company?.id === companyId);
    if (companyId && autoFillUsers[0]?.id) {
      form.setFieldValue(['mdiInvitations', index, 'inspectorId'], autoFillUsers[0]?.id);
      handleSelectInspectorId(autoFillUsers[0]?.id, index);
    } else {
      form.setFieldValue(['mdiInvitations', index, 'inspectorId'], null);
    }
    handleSelectCompany(companyId, index);

    form.validateFields([
      ['mdiInvitations', index, 'inspectorId'],
      ['mdiInvitations', index, 'inspectorId'],
    ]);
  };

  const onChangeCompanyRep = (inspectorId: string[], index: number) => {
    setIsRemoved(false);
    if (!inspectorId) {
      form.setFieldValue(['mdiInvitations', index, 'inspectorId'], null);
    } else {
      setUsername(inspectorId[0]);
      handleSelectInspectorId(inspectorId[0], index);
      const selectedUser = users?.find((user) => user.id === inspectorId[0]);
      const existingCompany = companies?.findIndex(
        (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
      );
      if (companies[existingCompany]?.id) setSelectedCompany(companies[existingCompany]?.id);
      handleSelectCompany(companies[existingCompany]?.id, index);
      form.setFieldValue(
        ['mdiInvitations', index, 'companyId'],
        existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null
      );
    }
    form.validateFields([
      ['mdiInvitations', index, 'companyId'],
      ['mdiInvitations', index, 'inspectorId'],
    ]);
  };

  const handleSelectCompany = (companyId: string, index: number) => {
    setIsRemoved(false);
    const newSelectedItems = [...selectedCompanies];
    newSelectedItems[index] = companyId;
    setSelectedCompanies(newSelectedItems);
  };
  const handleSelectInspectorId = (inspectorId: string, index: number) => {
    setIsRemoved(false);
    const newSelectedItems = [...selectedCompanies];
    newSelectedItems[index] = inspectorId;
    setSelectedInspectorIds(newSelectedItems);
  };

  const handleRemove = (index: number) => {
    const newSelectedCompany = [...selectedCompanies];
    const newSelectedInspectorId = [...selectedInspectorIds];
    newSelectedCompany.splice(index, 1);
    newSelectedInspectorId.splice(index, 1);
    setSelectedInspectorIds(newSelectedInspectorId);
    setSelectedCompanies(newSelectedCompany);
    setSelectedCompany(null);
    setIsRemoved(true);
  };
  const handleChangeFilterUser = async (index: number) => {
    setIsRemoved(false);
    const values = await form.getFieldValue(['mdiInvitations', index, 'companyId']);
    if (values) {
      setSelectedCompany(values);
    } else {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCompanies([]);
    }
  }, [isOpen]);

  return (
    <>
      <Form form={form}>
        <Form.List name={'mdiInvitations'} initialValue={[{ companyId: null, inspectorId: null }]}>
          {(fields, { add, remove }) => (
            <>
              <Flex vertical>
                <Flex>
                  <Title level={3} className='mt-2 mb-2'>
                    {t('Distribution')}
                  </Title>
                </Flex>
                <Flex justify='space-between'>
                  <Flex gap={12} vertical style={{ width: '100%' }}>
                    <Row gutter={10} style={{ marginBottom: '10px' }}>
                      <Col span={12}>
                        <Text>
                          {t('Company Name')}
                          <span className={'required-fields-mdi'}></span>
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Text>
                          {t('Emails / Names')}
                          <span className={'required-fields-mdi'}></span>
                        </Text>
                      </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                      {fields.map(({ name }, index) => (
                        <>
                          <Col span={12}>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: '',
                                },
                              ]}
                              name={[index, 'companyId']}
                            >
                              <SearchSelect
                                placeholder={t('Select company')}
                                value={selectedCompany}
                                options={(companies ?? []).map((company) => ({
                                  value: company.id,
                                  label: company.name,
                                  disabled:
                                    (company?.WorkspaceUsers ?? []).length <= 0 ||
                                    company.id === companyOriginator ||
                                    selectedCompanies.includes(company.id),
                                }))}
                                onChange={(value) => onChangeCompany(value, index)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                                name={[index, 'inspectorId']}
                                style={{ width: '100%' }}
                              >
                                <SearchSelect
                                  mode='multiple'
                                  placeholder={t('Select user')}
                                  value={username}
                                  maxTagCount={'responsive'}
                                  onFocus={() => {
                                    handleChangeFilterUser(index);
                                  }}
                                  options={users
                                    .filter((user) =>
                                      !selectedCompany
                                        ? isRemovedView
                                          ? true
                                          : !selectedCompanies.includes(user.WorkspaceUser?.Company?.id || '') &&
                                            user.WorkspaceUser?.Company?.id !== companyOriginator
                                        : user.WorkspaceUser?.Company?.id === selectedCompany
                                    )
                                    .map((user) => {
                                      return {
                                        value: user.id,
                                        label: user.fullName,
                                      };
                                    })}
                                  onChange={(value) => onChangeCompanyRep(value, index)}
                                />
                              </Form.Item>
                              <DeleteOutlined
                                style={{
                                  color: 'grey',
                                  fontSize: 20,
                                  alignItems: 'center',
                                }}
                                onClick={() => {
                                  {
                                    handleRemove(index);
                                    remove(name);
                                  }
                                }}
                              />
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                    {!isReadOnly && (
                      <Flex justify={'flex-end'} className={'mt-5'}>
                        <Button
                          type='dashed'
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add();
                          }}
                          disabled={fields.length >= 3}
                          className='text-size-12'
                        >
                          {t('Add Company')}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
}
