import React from 'react';
import { Avatar, Col, Row, Timeline, Typography, Spin } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IssueHistory, User } from 'model';
import { HISTORY_ACTION, HISTORY_ACTION_COLOR } from 'utils/contants';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import ImageItem from 'components/common/pre-signed-url-items/ImageItem';

type CommentItemProps = {
  isClosedStatus: boolean;
  users: User[];
  issueHistory: IssueHistory[];
  isLoading: boolean;
  module: string;
};

const CommentItem: React.FC<CommentItemProps> = ({ users, issueHistory, isLoading, module }) => {
  const { t } = useTranslation();
  return (
    <Spin spinning={isLoading}>
      <Timeline mode='left' style={{ marginTop: '10px', padding: '10px' }}>
        {issueHistory.map((item) => {
          const userCreatedByHistory = users.find((user) => user.id === item.userCreatedId);
          const isAssigned = item.action === HISTORY_ACTION.ASSIGNED_THE_INSPECTION;
          const isTransferred = item.action === HISTORY_ACTION.TRANSFERRED_THE_INSPECTION;
          const isValidated = item.action === HISTORY_ACTION.VALIDATED_THE_INSPECTION;
          const isCalledForReview = item.action === HISTORY_ACTION.CALLED_FOR_REVIEW;
          const isRejected = item.action === HISTORY_ACTION.REJECTED_THE_INSPECTION;
          let userName = '';
          let company = '';
          let status = '';
          if (isAssigned && item.title?.includes('|')) {
            [userName, company] = item.title.split('|').map((str) => str.trim());
          } else if (isTransferred && item.title?.includes('|')) {
            [userName, company] = item.title.split('|').map((str) => str.trim());
          } else if (isValidated) {
            status = 'Closed';
          } else if (isCalledForReview) {
            status = 'Ready for review';
          } else if (isRejected) {
            status = 'rejected';
          }

          const textColor = HISTORY_ACTION_COLOR[item?.action] || '#00000073';
          return (
            <Timeline.Item
              key={item.id}
              dot={
                userCreatedByHistory?.profilePhoto ? (
                  <AvatarItem size={32} src={userCreatedByHistory?.profilePhoto} />
                ) : (
                  <Avatar size={32}>{userCreatedByHistory?.firstName?.substring(0, 1)}</Avatar>
                )
              }
            >
              <div style={{ marginLeft: '10px', width: '100%' }}>
                <Row justify='space-between'>
                  <Col span={19} flex='auto'>
                    <Typography.Text strong style={{ fontSize: '14px' }}>
                      {userCreatedByHistory?.fullName}{' '}
                    </Typography.Text>
                    <Typography.Text italic>
                      {isAssigned ? (
                        userName !== 'undefined' ? (
                          <span style={{ color: textColor }}>
                            {t('assigned to')} {userName} {t('from')} {company} {t('company')}
                          </span>
                        ) : (
                          <span style={{ color: textColor }}>
                            {t('assigned to')} {company} {t('company')}
                          </span>
                        )
                      ) : isTransferred ? (
                        <span style={{ color: textColor }}>
                          {t('transferred to')} {userName}
                        </span>
                      ) : isValidated || isCalledForReview ? (
                        <>
                          {module && module === 'Safety'
                            ? t('changed the inspection status to')
                            : t('changed the observation status to')}
                          <span>&nbsp;</span>
                          <span style={{ color: textColor }}>{status}</span>
                        </>
                      ) : isRejected ? (
                        <>
                          <span style={{ color: textColor }}>{status}</span>
                          <span>&nbsp;</span>
                          {module && module === 'Safety'
                            ? t('the inspection and changed the inspection status to')
                            : t('the observation and changed the observation status to')}
                          <span>&nbsp;</span>
                          <span style={{ color: 'orange' }}> {t('Open')}</span>
                        </>
                      ) : item.action === HISTORY_ACTION.CLOSED_THE_INSPECTION ? (
                        <span style={{ color: textColor }}>
                          {module && module === 'Safety' ? t('closed the inspection') : t('closed the observation')}
                        </span>
                      ) : item.action === HISTORY_ACTION.CREATED_THE_INSPECTION ? (
                        <span style={{ color: textColor }}>
                          {module && module === 'Safety' ? t('created the inspection') : t('created the observation')}
                        </span>
                      ) : item.action === HISTORY_ACTION.SUBMITTED_THE_INSPECTION ? (
                        <span style={{ color: textColor }}>
                          {module && module === 'Safety'
                            ? t('submitted the inspection')
                            : t('submitted the observation')}
                        </span>
                      ) : item.action === HISTORY_ACTION.SELF_ASSIGNED_THE_iNSPECTION ? (
                        <span style={{ color: textColor }}>
                          {module && module === 'Safety'
                            ? t('self-assigned the inspection')
                            : t('self-assigned the observation')}
                        </span>
                      ) : (
                        <span style={{ color: textColor }}>{item.title}</span>
                      )}
                    </Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Typography.Text type='secondary'>
                      {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Typography.Text type='secondary'>
                    {userCreatedByHistory?.WorkspaceUser?.Company?.name}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Paragraph>{item.comment}</Typography.Paragraph>
                </Row>
                {item.Attachment && (
                  <Row>
                    <ImageItem src={item.Attachment.filePath} alt='Image' style={{ width: '50px', height: '50px' }} />
                  </Row>
                )}
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Spin>
  );
};

export default CommentItem;
