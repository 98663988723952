import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Loading, QueryParams } from 'types';

type FetchByParamsProps = {
  action: Function;
  dataSelector?: any;
  loadingSelector?: any;
  errorSelector?: any;
  params?: any;
};

export default function useFetchByParams<T>(
  props: FetchByParamsProps
): [T, Loading, (options?: QueryParams) => void, any] {
  const { action, dataSelector, loadingSelector, errorSelector, params } = props;

  const dispatch = useAppDispatch();
  const data: T = useAppSelector(dataSelector);

  const loadingSelectorFn = loadingSelector ? loadingSelector : () => null;
  const isLoading: Loading = useAppSelector(loadingSelectorFn);

  const errorSelectorFn = errorSelector ? errorSelector : () => null;
  const isError = useAppSelector(errorSelectorFn);

  const fetch = (options?: QueryParams) => {
    
    dispatch(action({ ...params, ...options }));
  };

  useEffect(() => {
    if (params) fetch();
  }, [params]);

  return [data, isLoading, fetch, isError];
}
