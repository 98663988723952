import { Form } from 'antd';
import BaseIssueFormHeader from 'components/common/BaseIssueFormHeader';
import { BaseIssue, User } from 'model';
import { MOM_STATUSES } from 'utils/contants';

type ProjectMinutesOfMeetingHeaderProps = {
  isUnSaveStatus?: boolean;
  profile: User | null;
  onClose: () => void;
  selectedBaseIssue?: BaseIssue | null;
  user?: User | null;
};
export default function ProjectMinutesOfMeetingHeader(props: ProjectMinutesOfMeetingHeaderProps) {
  const form = Form.useFormInstance();

  return (
    <BaseIssueFormHeader
      name={Form.useWatch('subject') || form.getFieldValue('subject')}
      originatorId={form.getFieldValue('originatorId')}
      user={props?.user}
      hideDiscipline
      profile={props?.profile}
      issueStatus={props.selectedBaseIssue?.IssueStatus}
      onClose={props?.onClose}
      baseIssueNumber={props.selectedBaseIssue?.MinutesOfMeeting?.momNumber}
      submittedDate={
        props.selectedBaseIssue?.IssueStatus?.code !== MOM_STATUSES.DRAFT
          ? props.selectedBaseIssue?.BaseValidations?.[0]?.createdAt
          : undefined
      }
    />
  );
}
