import React from 'react';
import { Form, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

type MARFirstStepContentProps = {
  selection: string;
  isOriginator: boolean;
  onChange: (value: RadioChangeEvent) => void;
};

export default function MARFirstStepContent(props: MARFirstStepContentProps) {
  const { t } = useTranslation();
  const { selection, isOriginator, onChange } = props;
  return (
    <>
      <Title className={'validation-block'} level={5}>
        {t('Your action')}
      </Title>
      <Form.Item initialValue={'validate'} name={'validation-option'}>
        <Radio.Group className={'validation-block'} onChange={onChange} value={selection}>
          <Space direction='vertical'>
            <Radio value={'validate'}>{t('VALIDATE')}</Radio>
            {!isOriginator && <Radio value={'reject'}>{t('REJECT')}</Radio>}
            <Radio value={'transfer'}>{t('TRANSFER (without validation)')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={'first-step-comment'}>
        <TextArea
          className={'first-step-comment'}
          onChange={(e) => {}}
          placeholder={t('Add comment here...')}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
    </>
  );
}