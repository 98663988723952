import { useEffect } from 'react';
import { Button, Divider, Form, ModalProps, Select, Typography } from 'antd';
import { CustomizeRequiredMark, Modal } from 'components';
import { useTranslation } from 'react-i18next';
import {
  useMyWorkspaceProjects,
  useMyWorkspaceProjectsParams,
  useMyWorkspaceCompanies,
  useMyWorkspaceCompaniesParams,
  useProjectRoles,
  useProjectRolesQueryParams,
} from 'hooks';

export type UserInvitationFormValues = {
  emails: string[];
  companyId: string;
  projectIds: string[];
  projectRoleId?: string;
  userRole: string;
};

type WorkspaceUserInvitationProps = {
  isModalOpen: boolean;
  workspaceId: string;
  loading: boolean;
  onSubmit: (values: UserInvitationFormValues) => void;
  setIsModalOpen: (value: boolean) => void;
} & ModalProps;

export default function WorkspaceUserInvitation(props: WorkspaceUserInvitationProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, workspaceId, loading } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<UserInvitationFormValues>();
  const [projectQueries] = useMyWorkspaceProjectsParams({ workspaceId });
  const [projects] = useMyWorkspaceProjects(projectQueries);
  const [companyQueries] = useMyWorkspaceCompaniesParams({ workspaceId });
  const [companies] = useMyWorkspaceCompanies(companyQueries);
  const [query] = useProjectRolesQueryParams();
  const [roles] = useProjectRoles(query);
  const guestRole = roles?.find((item) => item.name === 'Project Guest');

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen, form]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.projectRoleId = guestRole?.id;
      values.userRole = 'member';
      onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('Invite users')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel} key='back'>
          {t('Cancel')}
        </Button>,
        <Button onClick={handleOk} loading={loading} key='submit' type='primary'>
          {t('Invite')}
        </Button>,
      ]}
    >
      <Form requiredMark={CustomizeRequiredMark} form={form} name='user-invitation' layout='vertical'>
        <Typography.Title level={3} className='section-title'>
          {t('Users')}
        </Typography.Title>
        <Form.Item name='emails' rules={[{ required: true, message: '' }]}>
          <Select mode='tags' open={false} placeholder={t('Please enter the emails')} />
        </Form.Item>

        <Typography.Title level={3} className='section-title'>
          {t('Company')}
        </Typography.Title>
        <Form.Item
          className='form-item-inline'
          labelCol={{ span: 12 }}
          label={t('Select the users company')}
          name='companyId'
          rules={[{ required: true, message: '' }]}
        >
          <Select
            showSearch
            placeholder={t('Company Name')}
            options={companies?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                {/* <Button type="link" icon={<PlusOutlined />}>
                  {t('Register company')}
                </Button> */}
              </>
            )}
          />
        </Form.Item>

        <Divider className='mb-3 mt-15' />

        <Typography.Title level={3} className='section-title'>
          {t('Projects - optional')}
        </Typography.Title>
        <Form.Item
          className='form-item-inline'
          labelCol={{ span: 12 }}
          label={t('Invite users to join projects')}
          name='projectIds'
        >
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            maxTagCount='responsive'
            placeholder={t('Projects')}
            options={projects?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
          />
        </Form.Item>
        {/* <Form.Item
          className="form-item-inline"
          labelCol={{ span: 12 }}
          label={t('Project role')}
          name="projectRoleId"
          rules={[
            {
              validator(_, value) {
                if (form.getFieldValue('projectIds')?.length > 0) {
                  if (!value) {
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            placeholder={t('Select project role')}
            options={roles?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
}
