import { Button, Form, Modal, Typography } from 'antd';
import SearchUserCustom from 'components/common/SearchUserCustom';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { RFIThreadFormValues } from './RequestForInformationDetailThread';
import useUserEntity from 'hooks/common/useUserProfile';
import { ERFIActionType } from '../CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import { User } from 'model';

type RequestForInformationDetailTransferModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  projectId: string;
  workspaceId: string;
  onActionClick: (type: ERFIActionType, user?: User | null) => void;
};
const RequestForInformationDetailTransferModal = (props: RequestForInformationDetailTransferModalProps) => {
  const { t } = useTranslation();

  const users = useAppSelector(selectProjectUsers);
  const form = useANTDFormInstance<RFIThreadFormValues>();
  const mainThread = form.getFieldValue('thread') ? form.getFieldValue('thread')[0] : null;
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const { user } = useUserEntity({ projectId: props.projectId, workspaceId: props.workspaceId });

  const sameCompanyUsers = useMemo(() => {
    return users.filter((userValue) => {
      const sameCompanyUser = userValue.WorkspaceUser?.Company?.id === user?.WorkspaceUser?.Company?.id;
      const hasNotInSelectedUser =
        !(mainThread?.commentUserId === userValue?.id) &&
        !mainThread?.toRecipients?.userId?.includes(userValue.id) &&
        !mainThread?.ccRecipients?.flatMap((cc) => cc.userIds).includes(userValue.id);
      return sameCompanyUser && hasNotInSelectedUser;
    });
  }, [users, user, mainThread]);

  return (
    <Modal
      footer={[
        <Button key='back' onClick={props.onCancel}>
          {t('Cancel')}
        </Button>,
        <Button
          key='submit'
          type='primary'
          form='add-item-modal'
          htmlType='submit'
          onClick={() => {
            props.onActionClick(ERFIActionType.AddUser, selectedUser);
          }}
        >
          {t('Transfer')}
        </Button>,
      ]}
      className={`request-for-information-transfer-modal`}
      title={`Transfer`}
      open={props.isOpen}
      onCancel={props.onCancel}
    >
      <Typography.Text className='mt-[-31px] mb-[15px]'>
        {t(`Add another user belong to `)}
        <Typography.Text strong>{user?.WorkspaceUser?.Company?.name || ''}</Typography.Text>
      </Typography.Text>

      <Form.Item name={'transfer-user'}>
        <SearchUserCustom
          onChange={(userId: string) => {
            const user = users?.find((user) => user.id === userId);
            setSelectedUser(user);
          }}
          options={sameCompanyUsers.map((user) => ({
            id: user.id,
            name: user.fullName || '',
            companyName: user.WorkspaceUser?.Company?.name || '',
            url: user?.profilePhoto || '',
          }))}
        />
      </Form.Item>
    </Modal>
  );
};

export default RequestForInformationDetailTransferModal;
