const CustomizeRequiredMark = (
  label: React.ReactNode,
  { required }: { required: boolean }
) => (
  <>
    {label}
    {required && (
      <span className='ml-1' style={{ color: 'red' }}>
        *
      </span>
    )}
  </>
);

export default CustomizeRequiredMark;
