import { User } from 'model';
import request from 'requesters/user.request';
import { PaginationData, QueryParams } from '../types';
import { UserInvitationFormValues } from 'components/workspace-settings/WorkspaceUser/WorkspaceUserInvitation';
import { AddUserFormValue } from 'components/project-settings/ProjectUser/ProjectUserInvitation';

const userService = {
  getWorkspaceUsers: async (workspaceId: string, query: QueryParams) => {
    return request.get<User[]>(`/workspaces/${workspaceId}/users`, {
      params: query,
    });
  },
  getProjectUsers: async (workspaceId: string, projectId: string, query?: QueryParams) => {
    return request.get<User[]>(`/workspaces/${workspaceId}/projects/${projectId}/users`, {
      params: query,
    });
  },
  getUsersByListId: async (ids: string[]) => {
    const idsString = `${'"' + ids.join('","') + '"'}`;
    return request.get<PaginationData<User>>(`users`, {
      params: { 'id[]': `[${idsString}]` },
    });
  },
  inviteUsersToWorkspace: async (workspaceId: string, data: UserInvitationFormValues) => {
    return request.post(`/workspaces/${workspaceId}/invite-users`, data);
  },
  inviteUsersToProject: async (workspaceId: string, projectId: string, data: AddUserFormValue['users']) => {
    return request.post(`/workspaces/${workspaceId}/projects/${projectId}/invite-users`, data);
  },
};

export default userService;
