import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Flex, MenuProps, Tag, Tooltip, Upload, UploadFile } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import dayjs from 'dayjs';
import { Design, Zone } from 'model';
import { DesignResponse, RawDesignForm, designFieldValues } from 'modules/DocumentManagement/models/Design';
import { Dispatch, Key, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuInfo } from 'rc-menu/lib/interface';
import { QueryParams } from 'types';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';
import { TablePagination } from 'components/common';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';
import useUserEntity from 'hooks/common/useUserProfile';

type DesignsListViewProps = {
  setDrawerManagingRevision: Dispatch<SetStateAction<{ open: boolean; design?: Design; fromListView?: boolean }>>;
  maxRowAmount: number;
  data: DesignResponse;
  openPDFNewTab: (design: Design) => void;
  singleDownload: (filename: string, designId?: string) => void;
  setDrawerEditingDesign: (design: Design) => void;
  showDrawer: (editing: boolean) => void;
  setIsDelete: Dispatch<
    SetStateAction<{
      open: boolean;
      designId?: string;
      discipline?: string;
      initialRevisionId?: string;
    }>
  >;
  setNestedSelectedKeys: (discipline: string, data: Key[]) => void;
  designTablesSelectedKeys: { [discipline: string]: Key[] };
  setNestedOrderParams: (discipline: string, disciplineParams: string) => void;
  setNestedPaginationParams: (discipline: string, paginationParams: QueryParams) => void;
  projectId: string;
  workspaceId: string;
  discipline: string;
  expanded: boolean;
  revisionFileList: UploadFile[];
  setRevisionFileList: Dispatch<SetStateAction<UploadFile[]>>;
  revisionList: Design[];
  setRevisionList: Dispatch<SetStateAction<Design[]>>;
  getRevisions: (workspaceId: string, projectId: string, designId: string) => Promise<Design[]>;

  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<
    {
      id: string;
      hasDuplicate: boolean;
    }[]
  >;
  unpublishedModalDesigns: Design[];
  setUnpublishedModalDesigns: Dispatch<SetStateAction<Design[]>>;
  setUnpublishedModalIsOpen: Dispatch<SetStateAction<boolean>>;
  beforeUpload: (file: UploadFile) => Promise<boolean>;
  designRegisterPhases?: DesignRegisterPhase[];
  zones: Zone[];
};

export default function DesignsListView(props: DesignsListViewProps) {
  const { t } = useTranslation();
  const {
    setUnpublishedModalIsOpen,
    setUnpublishedModalDesigns,
    setDrawerManagingRevision,
    data,
    setNestedSelectedKeys,
    designTablesSelectedKeys,
    openPDFNewTab,
    singleDownload,
    setDrawerEditingDesign,
    showDrawer,
    setIsDelete,
    projectId,
    maxRowAmount,
    discipline,
    setNestedOrderParams,
    setNestedPaginationParams,
    revisionFileList,
    setRevisionFileList,
    beforeUpload,
    designRegisterPhases,
    zones,
    workspaceId,
  } = props;
  const [clickedDesign, setClickedDesign] = useState<Design>();
  const [pageSize, setPageSize] = useState(maxRowAmount);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useUserEntity({ projectId, workspaceId });
  const handleBeforeUpload = async (fileList: UploadFile) => {
    setDrawerManagingRevision({ open: true, design: clickedDesign, fromListView: true });
    return await beforeUpload(fileList);
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Design> | SorterResult<Design>[]
  ) => {
    if (sorter) {
      let sorterOrder: string | null;
      const sort = sorter as SorterResult<Design>;
      switch (sort.order) {
        case 'ascend':
          sorterOrder = 'asc';
          break;
        case 'descend':
          sorterOrder = 'desc';
          break;
        default:
          sorterOrder = null;
          break;
      }

      let orderBy = 'createdAt,desc';
      if (sorterOrder) {
        orderBy = `${sort.field},${sorterOrder}`;
      }
      setNestedOrderParams(discipline, orderBy);
    }
  };

  const handleMenuClick = async (
    info: MenuInfo,
    designTitle: string,
    designId: string,
    projectId: string,
    record: Design
  ) => {
    const { key } = info;
    if (key) {
      switch (key) {
        case 'publish':
          setUnpublishedModalIsOpen(true);
          setUnpublishedModalDesigns([record]);

          break;
        case 'manage':
          setDrawerManagingRevision({
            open: true,
            design: record,
          });
          break;
        case 'upload':
          break;
        case 'edit':
          setDrawerEditingDesign(record);
          showDrawer(true);
          break;
        case 'download':
          await singleDownload(designId, designTitle);
          break;
        case 'delete':
          setIsDelete({
            open: true,
            designId,
            discipline: record.discipline,
            initialRevisionId: record.initialRevisionId ?? undefined,
          });
          break;
      }
    }
  };

  const canUserEditOrDeleteDesign = (design: Design | undefined) => {
    return (
      design?.issuedBy === user?.WorkspaceUser?.Company?.id &&
      design?.status !== 'approved' &&
      design?.status !== 'uploading'
    );
  };

  const items: MenuProps['items'] = [
    {
      label: <p style={{ color: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'] }}>{t('Publish')}</p>,
      key: 'publish',
      icon: <CheckCircleOutlined style={{ color: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'] }} />,
      danger: false,
    },
    {
      label: t('Download'),
      key: 'download',
      icon: <DownloadOutlined />,
      danger: false,
      disabled: clickedDesign?.status === 'uploading',
    },
    {
      label: (
        <Flex align='stretch'>
          <Upload
            style={{ marginBottom: 0 }}
            fileList={revisionFileList}
            onChange={({ fileList: newFileList }) => {
              setRevisionFileList(newFileList);
            }}
            accept='application/pdf'
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            disabled={clickedDesign?.status === 'uploading' || clickedDesign?.status === 'draft'}
          >
            <Flex> {t('Upload New Revision')}</Flex>
          </Upload>
        </Flex>
      ),
      key: 'upload',
      icon: <PlusOutlined />,
      danger: false,
      disabled: clickedDesign?.status === 'uploading',
    },
    {
      label: t('Edit Info'),
      key: 'edit',
      icon: <EditOutlined />,
      danger: false,
      disabled: !canUserEditOrDeleteDesign(clickedDesign),
    },
    {
      label: t('Manage Revisions'),
      key: 'manage',
      icon: <SettingOutlined />,
      danger: false,
      disabled: clickedDesign?.status === 'uploading',
    },
    {
      label: t('Delete'),
      key: 'delete',
      icon: <CloseCircleOutlined />,
      danger: true,
      disabled: !canUserEditOrDeleteDesign(clickedDesign),
    },
  ];
  const menuProps: MenuProps = { items };

  const nestedColumns: ColumnsType<Design> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      fixed: 'left',
      width: '10em',
      sorter: true,
      render: (text, record) => {
        return (
          <Flex justify='space-between'>
            <Flex>
              <FilePdfOutlined
                style={{ color: record.status === 'draft' || record.status === 'uploading' ? 'gray' : 'red' }}
              />

              <button
                className='invisibleButtonLink'
                style={{ textAlign: 'left', paddingLeft: '12px' }}
                onClick={() => openPDFNewTab(record)}
              >
                {text}
              </button>
            </Flex>
            {record.initialRevisionId && (
              <Tooltip title={t('This unpublished file has a published revision')}>
                <InfoCircleOutlined style={{ color: '#cccccc' }} />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: t('Number'),
      dataIndex: 'number',
      sorter: true,
      width: '3.5em',
      render: (text) => {
        return <p style={{ fontWeight: 'lighter' }}>{text}</p>;
      },
    },
    {
      title: t('Revision'),
      dataIndex: 'revision',
      sorter: true,
      width: '3.5em',

      render: (text, record) => {
        return (
          <Flex justify='center'>
            <Button
              onClick={() => {
                if (record.status !== 'uploading') {
                  setDrawerManagingRevision({ open: true, design: record });
                }
              }}
              style={{ width: '0' }}
              type='link'
              className='invisibleButtonLink'
            >
              <Flex
                justify='center'
                onClick={() => {
                  setDrawerManagingRevision({ open: true, design: record });
                }}
              >
                <Tag color={'blue'} style={{ fontWeight: 'lighter', borderRadius: '20px' }}>
                  <div className='normal-case'>{text}</div>
                </Tag>
              </Flex>
            </Button>
          </Flex>
        );
      },
    },
    {
      title: t('Phase'),
      dataIndex: 'phase',
      sorter: true,
      width: '3.5em',
      render: (value) => {
        return <p>{designRegisterPhases && designRegisterPhases.find((p) => p.id === value)?.name}</p>;
      },
    },
    {
      title: t('Issue Date'),
      dataIndex: 'issueDate',
      sorter: true,
      width: '3.5em',
      render: (value, record) => <p>{dayjs(dayjs(value).isValid() ? value : record.createdAt).format('DD/MM/YYYY')}</p>,
    },
    {
      title: t('Expected Deadline'),
      dataIndex: 'deadlineDate',
      sorter: true,
      width: '3.5em',
      render: (value) => <p>{!!value && dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : null}</p>,
    },
    {
      title: t('Document Type'),
      dataIndex: 'documentType',
      sorter: true,
      width: '7em',
      render: (value) => <p>{t(designFieldValues.documentTypeValues[value])}</p>,
    },
    {
      title: t('Area'),
      dataIndex: 'area',
      sorter: true,
      width: '3.5em',
      render: (value) => <p>{zones && t(zones.find((zone) => zone.id === value)?.name ?? '')}</p>,
    },
    {
      title: t('Issued by'),
      dataIndex: ['Company', 'name'],
      sorter: true,
      width: '3.5em',
    },
    {
      title: t('Issued To'),
      dataIndex: ['issuedTo'],
      sorter: true,
      width: '3.5em',
    },
    {
      title: t('Ball In Court'),
      dataIndex: ['ballInCourt'],
      sorter: true,
      width: '3.5em',
    },
    {
      title: t('Published For'),
      dataIndex: 'status',
      sorter: true,
      width: '3.5em',
      render: (text) => (
        <Tag color={designFieldValues.statusValues[text].color}>{t(designFieldValues.statusValues[text]?.value)}</Tag>
      ),
    },
    {
      align: 'right',
      dataIndex: 'id',
      fixed: 'right',
      width: '3.5em',
      render: (value, record: Design) => (
        <Flex justify={'flex-end'}>
          <Dropdown
            overlayClassName='borderless-dropdown-item'
            menu={{
              ...menuProps,
              onClick: (info) => {
                handleMenuClick(info, `${record.title}.${record.fileExtension}`, value, projectId, record);
              },
            }}
            trigger={['click']}
          >
            <Button
              style={{ paddingLeft: 8, paddingRight: 0, color: 'black' }}
              type='link'
              onClick={() => {
                setClickedDesign(record);
              }}
            >
              {<EllipsisOutlined />}{' '}
            </Button>
          </Dropdown>
        </Flex>
      ),
    },
  ];
  return (
    <ConfigProvider>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Table
          loading={!data?.[discipline]}
          size='small'
          showSorterTooltip={false}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setNestedSelectedKeys(discipline, selectedRowKeys);
            },
            selectedRowKeys: designTablesSelectedKeys[discipline],
            type: 'checkbox',
            checkStrictly: false,
          }}
          pagination={false}
          onChange={handleTableChange}
          columns={nestedColumns}
          dataSource={data?.[discipline] ? data[discipline].rows : []}
          rowKey={(row) => row.id}
          showHeader={true}
        />
        <div className='bg-white px-4 py-6 rounded-b-[8px]'>
          <TablePagination
            onSubmit={(value) => {
              setCurrentPage(Number(value.page));
              setPageSize(Number(value.limit));
              setNestedPaginationParams(discipline, value);
            }}
            issueParams={{ page: currentPage, limit: pageSize }}
            issuesCount={data?.[discipline] ? data[discipline].count : 0}
          />
        </div>
      </div>
    </ConfigProvider>
  );
}
