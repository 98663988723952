import { useFetchByParams, useQueryParams } from 'hooks';
import { useMemo } from 'react';
import { RootState } from 'store';
import { fetchProjectIssueKpis } from 'store/my-projects.slice';
import { QueryParams } from 'types';

const selectProjectIssueKpis = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.issueKpis;

const selectProjectIssueKpisLoading = (state: RootState, projectId: string, module: string) =>
  state.myProjects.projects?.[projectId]?.modules?.[module]?.issueKpisLoading;

type ProjectIssueKpisParamsType = {
  workspaceId: string;
  projectId: string;
} & QueryParams;

export const useProjectIssueKpisParams = (
  options: ProjectIssueKpisParamsType
): [query: ProjectIssueKpisParamsType, onParamChange: (params: QueryParams) => void] => {
  const { workspaceId, projectId } = options;
  const [params, onParamChange] = useQueryParams<QueryParams>(true);

  const query = useMemo(() => {
    const { ...restParams } = params;
    delete restParams.selectedZone;
    delete restParams.tab;
    return { ...restParams, ...options };
  }, [workspaceId, projectId, params]);

  return [query, onParamChange];
};

export const useProjectIssueKpis = (query: QueryParams) => {
  const { projectId, module } = query;
  return useFetchByParams<{
    kpiData: {
      draft: number;
      open: number;
      closed: number;
      readyForInspection: number;
      readyForInspectionOverdue: number;
      openOverdue: number;
    };
  }>({
    action: fetchProjectIssueKpis,
    dataSelector: (state: RootState) => selectProjectIssueKpis(state, projectId, module),
    loadingSelector: (state: RootState) => selectProjectIssueKpisLoading(state, projectId, module),
    params: query,
  });
};
