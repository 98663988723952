import * as d3 from 'd3';
import { FC, RefObject, useEffect, useRef } from 'react';
import { useResizeObserver } from './useResizeObserver';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';
import { useTranslation } from 'react-i18next';
import { titleCase } from 'utils';
import { Loading } from 'types';

const StackBarChart: FC<IChart> = ({ chartData, svgWrapperRef, loading, isSafetyPage }) => {
  const { t } = useTranslation();
  const dimensions = useResizeObserver(svgWrapperRef);
  const svgRef = useRef<SVGSVGElement>(null);

  const svg = d3.select(svgRef?.current);

  const OPACITY_ON_OVER = 1;
  const DEFAULT_OPACITY = 1;
  const LINE_OPACITY = 0.8;
  const FONT_SIZE = '11px';

  const margin = { top: 30, right: 10, bottom: 30, left: 100 };

  const statuses: { [key: string]: string } = {
    DR: t('Draft'),
    OP: t('Open'),
    RFI: titleCase(t('Ready for review')),
    CL: t('Closed'),
  };

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style('background', 'rgba(0,0,0,0.6)')
    .style('color', '#fff')
    .style('padding', '10px')
    .style('border-radius', '5px')
    .style('pointer-events', 'none');

  const color = d3
    .scaleOrdinal()
    .domain(['DR', 'OP', 'RFI', 'CL'])
    .range([
      TASK_STATUS_HEADER_COLOR.DR,
      TASK_STATUS_HEADER_COLOR.OP,
      TASK_STATUS_HEADER_COLOR.RFI,
      TASK_STATUS_HEADER_COLOR.CL,
    ])
    .unknown('#ccc');

  // Add animation when data changed
  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;
    const height = dimensions?.height - (margin?.top + margin?.bottom);
    const width = dimensions?.width - margin?.right;
    const maxValue = Math.max(...chartData.map((data) => data.DR + data.OP + data.RFI + data.CL));

    svg.selectAll('g').remove();
    // svg.selectAll<SVGGElement, any>('g:not(.x-axis, .y-axis)').remove();

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .rangeRound([margin.left, width - margin.right]);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(width / 100, 's')
      .tickSize(height - margin.top - margin.bottom);
    const yScale = d3
      .scaleBand()
      .domain([...(chartData || []).map((data) => data.companyName)])
      .rangeRound([height, margin.top + margin.bottom])
      .padding(0.15);

    // Create y-axis for left side
    const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

    let series: d3.Series<{ [key: string]: number }, string>[] = [];

    if (chartData.length > 0)
      series = d3
        .stack()
        .keys(Object.keys(chartData[0]).filter((k) => k !== 'companyName' && k !== 'companyId'))
        .value((d, k) => d[k])(chartData as Iterable<{ [key: string]: number }>)
        .map((d) => {
          return (
            d.forEach((v) => {
              return ((v as unknown as { key: string }).key = d.key);
            }),
            d
          );
        });
    const rect = svg
      .append('g')
      .selectAll('g')
      .data([...series])
      .join('g');

    if (loading === 'idle') {
      rect
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        // .attr('x', (d: any) => xScale(d[0]) ?? 0)
        .attr('x', margin.left)
        .attr('y', (d) => yScale((d.data as unknown as ChartDataType).companyName) ?? 0)
        // .attr('width', (d: any) => xScale(d[1]) - xScale(d[0]))
        .attr('width', 0)
        .attr('height', yScale.bandwidth())
        .attr('fill', (d) => color((d as unknown as { key: string }).key) as string)
        .attr('opacity', DEFAULT_OPACITY)
        .on('mouseover', function (event: MouseEvent, d) {
          tooltip
            .html(
              `<div>${
                (d as unknown as { key: string }).key === 'DR'
                  ? t('Draft')
                  : (d as unknown as { key: string }).key === 'CL'
                    ? t('Closed')
                    : (d as unknown as { key: string }).key === 'RFI'
                      ? titleCase(t('Ready for review'))
                      : (d as unknown as { key: string }).key === 'OP'
                        ? t('Open')
                        : 'Count'
              }: ${d.data[(d as unknown as { key: string }).key]} ${isSafetyPage ? t('Inspections') : t('Observations')}</div>`
            )
            .style('visibility', 'visible');

          d3.select(this);
        })
        .on('mousemove', function (event: MouseEvent) {
          tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px');
        })
        .on('mouseout', function () {
          tooltip.html(``).style('visibility', 'hidden');
          d3.select(this).transition().attr('opacity', DEFAULT_OPACITY);
        })
        .transition()
        .duration(1000)
        .attr('width', (d) => xScale(d[1]) - xScale(d[0]))
        .attr('x', (d) => xScale(d[0]) ?? 0);

      svg
        // .select('.x-axis')
        .append('g')
        .attr('class', 'x-axis')
        .style('transform', `translateY(${margin.top + margin.bottom}px)`)
        .style('color', 'gray')
        .style('font-size', FONT_SIZE)
        .call(xAxis)
        .call((g) => g.selectAll('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick:not(:first-of-type) line')
            .attr('stroke-opacity', LINE_OPACITY)
            .attr('stroke-dasharray', '2,2')
        )
        .selectAll('.tick text')
        .style('opacity', 0)
        .transition()
        .duration(1000)
        .style('opacity', 1);

      svg
        // .select('.y-axis')
        .append('g')
        .attr('class', 'y-axis')
        .style('transform', `translateX(${margin?.left}px)`)
        .style('font-size', FONT_SIZE)
        .call(yAxis)
        .call((g) => g.selectAll('.domain').remove())
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-5px')
        .attr('dy', '4px')
        .attr('transform', 'rotate(-30)')
        .style('opacity', 0)
        .transition()
        .duration(1000)
        .style('opacity', 1);
    }

    // Legend
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${dimensions.width / 4}, ${0})`);
    legend
      .selectAll('rect')
      .data(Object.entries(statuses))
      .enter()
      .append('rect')
      .attr('fill', (d: [string, string]) => color(d[0]) as string)
      .attr('x', (_, i) => (dimensions.width / 6) * i)
      .attr('y', 0)
      .attr('width', 15)
      .attr('height', 15);

    legend
      .selectAll('.legend-label')
      .data(Object.entries(statuses))
      .enter()
      .append('text')
      .attr('class', 'legend-label')
      .style('font-family', 'sans-serif')
      .style('font-size', FONT_SIZE)
      .attr('x', (_, i) => (dimensions.width / 6) * i + 20)
      .attr('y', 15)
      .text((d) => d[1]);
  }, [chartData]);

  // Responsive when the window is resized
  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;
    const height = dimensions?.height - (margin?.top + margin?.bottom);
    const width = dimensions?.width - margin?.right;
    const maxValue = Math.max(...chartData.map((data) => data.DR + data.OP + data.RFI + data.CL));

    svg.selectAll('g').remove();
    // svg.selectAll<SVGGElement, any>('g:not(.x-axis, .y-axis)').remove();

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .rangeRound([margin.left, width - margin.right]);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(width / 100, 's')
      .tickSize(height - margin.top - margin.bottom);

    const yScale = d3
      .scaleBand()
      .domain([...chartData.map((data) => data.companyName)])
      .rangeRound([height, margin.top + margin.bottom])
      .padding(0.15);
    // Create y-axis for left side
    const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);

    let series: d3.Series<
      {
        [key: string]: number;
      },
      string
    >[] = [];
    if (chartData.length > 0)
      series = d3
        .stack()
        .keys(Object.keys(chartData[0]).filter((k) => k !== 'companyName' && k !== 'companyId'))
        .value((d, k) => d[k])(chartData as Iterable<{ [key: string]: number }>)
        .map((d) => {
          return (
            d.forEach((v) => {
              return ((v as unknown as { key: string }).key = d.key);
            }),
            d
          );
        });
    const rect = svg
      .append('g')
      .selectAll('g')
      .data([...series])
      .join('g');

    if (loading === 'idle') {
      rect
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('x', (d) => xScale(d[0]) ?? 0)
        .attr('y', (d) => yScale((d.data as unknown as ChartDataType).companyName) ?? 0)
        .attr('width', (d) => xScale(d[1]) - xScale(d[0]))
        .attr('height', yScale.bandwidth())
        .attr('fill', (d) => color((d as unknown as { key: string }).key) as string)
        .attr('opacity', DEFAULT_OPACITY)
        .on('mouseover', function (event: MouseEvent, d) {
          tooltip
            .html(
              `<div>${
                (d as unknown as { key: string }).key === 'DR'
                  ? t('Draft')
                  : (d as unknown as { key: string }).key === 'CL'
                    ? t('Closed')
                    : (d as unknown as { key: string }).key === 'RFI'
                      ? titleCase(t('Ready for review'))
                      : (d as unknown as { key: string }).key === 'OP'
                        ? t('Open')
                        : 'Count'
              }: ${d.data[(d as unknown as { key: string }).key]} ${t('Inspections')}</div>`
            )
            .style('visibility', 'visible');

          d3.select(this).transition().attr('opacity', OPACITY_ON_OVER);
        })
        .on('mousemove', function (event: MouseEvent) {
          tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px');
        })
        .on('mouseout', function () {
          tooltip.html(``).style('visibility', 'hidden');
          d3.select(this).transition().attr('opacity', DEFAULT_OPACITY);
        });
    }
    svg
      // .select('.x-axis')
      .append('g')
      .attr('class', 'x-axis')
      .style('transform', `translateY(${margin.top + margin.bottom}px)`)
      .style('color', 'gray')
      .style('font-size', FONT_SIZE)
      .call(xAxis)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick:not(:first-of-type) line')
          .attr('stroke-opacity', LINE_OPACITY)
          .attr('stroke-dasharray', '2,2')
      );

    svg
      // .select('.y-axis')
      .append('g')
      .attr('class', 'y-axis')
      .style('transform', `translateX(${margin?.left}px)`)
      .style('font-size', FONT_SIZE)
      .call(yAxis)
      .call((g) => g.selectAll('.domain').remove())
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-5px')
      .attr('dy', '4px')
      .attr('transform', 'rotate(-30)');

    // Legend
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${dimensions.width / 4}, ${0})`);
    legend
      .selectAll('rect')
      .data(Object.entries(statuses))
      .enter()
      .append('rect')
      .attr('fill', (d: [string, string]) => color(d[0]) as string)
      .attr('x', (_, i) => (dimensions.width / 6) * i)
      .attr('y', 0)
      .attr('width', 15)
      .attr('height', 15);

    legend
      .selectAll('.legend-label')
      .data(Object.entries(statuses))
      .enter()
      .append('text')
      .attr('class', 'legend-label')
      .style('font-family', 'sans-serif')
      .style('font-size', FONT_SIZE)
      .attr('x', (_, i) => (dimensions.width / 6) * i + 20)
      .attr('y', 15)
      .text((d) => d[1]);
  }, [dimensions]);

  return (
    <div className='d3js'>
      <svg ref={svgRef} width={dimensions?.width ?? 0} height={dimensions?.height ?? 0}>
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
    </div>
  );
};

interface IChart {
  chartData: ChartDataType[];
  svgWrapperRef: RefObject<HTMLDivElement>;
  loading?: Loading;
  isSafetyPage: boolean;
}
type ChartDataType = {
  companyId: string;
  companyName: string;
  DR: number;
  OP: number;
  RFI: number;
  CL: number;
};

export default StackBarChart;
