import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { AnimatedPage } from 'components';
import { motion } from 'framer-motion';
import { Attachment, Issue, User } from 'model';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayerData } from 'types';
import { ISSUE_STATUSES } from 'utils/contants';
import AttachmentDrawing from './AttachmentDrawing';
import AttachmentItem from './AttachmentItem';
const { Content } = Layout;

const motionItem = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

type CreateAttachmentProps = {
  onSubmitDrawing: (attachment: Attachment, layerData: LayerData | null) => Promise<void>;
  onAddAttachment: (value: boolean) => void;
  attachments?: Attachment[];
  onDelete: (attachmentId: string) => void;
  issue?: Issue | null;
  isLoading?: boolean;
  module?: string;
  isAttachmentDisable: boolean;
  users: User[];
};

export default function CreateAttachments(props: CreateAttachmentProps) {
  const {
    onAddAttachment,
    attachments,
    onDelete,
    issue,
    isAttachmentDisable,
    users,
    onSubmitDrawing,
    isLoading,
    module,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);

  const onSelectAttachment = (attachment: Attachment) => {
    setSelectedAttachment(attachment);
    setTimeout(() => {
      setIsOpen(true);
    });
  };

  const onSubmitHandler = async (layerData: LayerData | null) => {
    await onSubmitDrawing(selectedAttachment as Attachment, layerData);
    setSelectedAttachment(null);
    setIsOpen(false);
  };

  return (
    <AnimatedPage>
      <AttachmentDrawing
        isModalOpen={isOpen}
        setIsModalOpen={(value) => {
          setIsOpen(value);
          setSelectedAttachment(null);
        }}
        module={module}
        isLoading={isLoading}
        disabled={isAttachmentDisable}
        onSubmit={onSubmitHandler}
        plan={selectedAttachment?.IssueAttachments?.[0] ?? null}
        inspectionName={'inspectionName'}
        planImage={selectedAttachment}
        users={users}
      />
      <Content className='main-content edit-attachment'>
        <div className='workspaces photos attachments'>
          <Row gutter={20}>
            {attachments?.map((attachment, index) => (
              <Col xs={24} sm={12} md={12} lg={12} xl={12} className='full-width-col' key={attachment.id}>
                <motion.div
                  key={attachment.id}
                  variants={motionItem}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                    delay: index * 0.25,
                  }}
                  style={{ marginBottom: '15px' }}
                >
                  <AttachmentItem
                    attachment={attachment}
                    onDelete={onDelete}
                    isClosedStatus={issue?.IssueStatus?.code === ISSUE_STATUSES.CLOSED}
                    isAttachmentDisable={isAttachmentDisable}
                    users={users}
                    onDrawing={onSelectAttachment}
                  />
                </motion.div>
              </Col>
            ))}
            {!isAttachmentDisable && (
              <Col span={12}>
                <Button
                  type='dashed'
                  className='add-attachment-action'
                  onClick={() => onAddAttachment(true)}
                  disabled={isAttachmentDisable}
                >
                  <PlusOutlined />
                  <p>{t('Add attachment')}</p>
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </Content>
    </AnimatedPage>
  );
}
