import { ReactNode, useMemo, useState } from 'react';
import { Layout } from 'antd';
import { ProjectSidebar, Footer } from 'components';
import { useLocation } from 'react-router-dom';
const { Content } = Layout;

const ProjectSettingLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const lightClass = useMemo(() => {
    const [type] = location.pathname.match('quality|safety|document-management|toolbox|construction') || [];
    switch (type) {
      case 'quality':
        return 'quality-light';
      case 'safety':
        return 'safety-light';
      case 'document-management':
      case 'toolbox':
        return 'toolbox-light';
      case 'construction':
        return 'construction-light';
      default:
        return '';
    }
  }, [location]);

  return (
    <Layout hasSider>
      <ProjectSidebar
        collapsed={collapsed}
        toggleCollapsed={() => {
          setCollapsed(!collapsed);
        }}
      />
      <Layout
        style={{
          marginLeft: collapsed ? '80px' : '300px',
          transition: 'all 0.25s',
        }}
      >
        <Content className={`main-content ${lightClass}`}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default ProjectSettingLayout;
