import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { BaseIssue, Company, User } from 'model';
import { VALIDATION_STATUS, VALIDATION_STATUS_COLOR } from 'utils/contants';
import UserCompany from './UserCompany';

export default function ValidationHistoryStepOnTable(baseIssue: BaseIssue, isMss: boolean, users?: User[]) {
  if (!baseIssue?.BaseValidations) return <></>;

  const history: {
    id: string;
    company: string;
    status: string;
    color: string;
    updatedAt: string;
    username?: string;
  }[] = [];

  const addCompany = (status: string, company?: Company, color?: string, updatedAt?: string, validatorId?: string) => {
    const username = users?.find((user) => user?.id === validatorId)?.fullName;
    history.push({
      id: company?.id || '',
      company: company?.name || '',
      status,
      color: color || '',
      updatedAt: updatedAt || '',
      username: username || '',
    });
  };

  baseIssue.BaseValidations.some((validation, index) => {
    const currentCompany =
      validation.validationStatus === VALIDATION_STATUS.REJECTED ||
      validation.validationStatus === VALIDATION_STATUS.APPROVED
        ? validation.PrvValidatorCompany || validation.ValidatorCompany
        : validation.ValidatorCompany;
    const currentStatus =
      validation.validationStatus === VALIDATION_STATUS.REJECTED
        ? VALIDATION_STATUS.REJECTED
        : validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? VALIDATION_STATUS.APPROVED
          : VALIDATION_STATUS.IN_REVIEW;
    const currentColor =
      currentStatus === VALIDATION_STATUS.REJECTED
        ? VALIDATION_STATUS_COLOR.REJECTED
        : validation.validationStatus === VALIDATION_STATUS.APPROVED
          ? VALIDATION_STATUS_COLOR.APPROVED
          : isMss
            ? VALIDATION_STATUS_COLOR.IN_REVIEW_BLUE
            : VALIDATION_STATUS_COLOR.IN_REVIEW;
    const updatedAt = validation.MdiValidation?.updatedAt || currentCompany?.updatedAt;

    addCompany(currentStatus, currentCompany, currentColor, updatedAt, validation?.validatorId);
    return true;
  });

  return (
    <div className='mss-tag mar-tag'>
      {history.length > 0 &&
        (isMss ? (
          <UserCompany
            data={{
              color: history[0].color || 'default',
              companyName: history[0].company,
              userName: history[0].username,
            }}
          />
        ) : (
          <Tag className='whitespace-normal' color={history[0].color} key={history[0].id}>
            {history[0].company}{' '}
            {history[0].status === VALIDATION_STATUS.REJECTED ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
          </Tag>
        ))}
    </div>
  );
}
