import { Tooltip, TooltipProps } from 'antd';
import { ReactNode, useEffect, useRef, useState } from 'react';

export type CustomTooltipProps = TooltipProps & {
  title: ReactNode;
  content?: ReactNode;
  width?: string | number;
  open?: boolean;
};

const CustomTooltip = (props: CustomTooltipProps) => {
  const [open, setOpen] = useState(false);
  const [numberOfRender, setNumberOfRender] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const updateOpenTooltip = () => {
    if (!containerRef.current) return;
    setOpen(containerRef.current.scrollWidth > containerRef.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateOpenTooltip);
    return () => {
      window.removeEventListener('resize', updateOpenTooltip);
    };
  }, [containerRef]);

  useEffect(() => {
    if (numberOfRender === 2) return;
    updateOpenTooltip();
    setTimeout(() => {
      setNumberOfRender(numberOfRender + 1);
    }, 1);
  }, [numberOfRender]);

  return (
    <Tooltip {...props} open={undefined} title={props?.open || open ? props?.title : false} placement='top'>
      <div
        className='custom-tooltip'
        ref={(currentRef) => {
          if (!currentRef) return;
          containerRef.current = currentRef;
          setOpen(currentRef.scrollWidth > currentRef.clientWidth);
        }}
      >
        {props?.content || props.title}
      </div>
    </Tooltip>
  );
};

export default CustomTooltip;
