import { Button, Flex, Form, Modal } from 'antd';
import { Design, Zone } from 'model';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditUnpublishedDesign from './EditUnpublishedDesign';
import { designApprovalStatusKeys, PublishDesignInput, RawDesignForm } from 'modules/DocumentManagement/models/Design';
import { useOtherProjects, useOtherProjectsParams, useProjectUsers, useProjectUsersParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { DesignRegisterPhase } from 'model/ProjectDataCustomization';

type PublishDesignModalProps = {
  fileRegex: RegExp;
  fileRegexToString: string;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  designs: Design[];
  setDesigns: Dispatch<SetStateAction<Design[]>>;
  updateSelectedRowKeys(): void;
  checkDuplicate: (
    workspaceId: string,
    projectId: string,
    data: RawDesignForm[]
  ) => Promise<
    {
      id: string;
      hasDuplicate: boolean;
    }[]
  >;
  projectId: string;
  publishDesign: (
    workspaceId: string,
    projectId: string,
    input: PublishDesignInput
  ) => Promise<{
    id: string;
  }>;
  refreshAll: () => void;
  disciplines: { discipline: string }[];
  disciplineMapIdToName: Map<string, string>;
  modalType?: 'publish' | 'upload';
  designRegisterPhases: DesignRegisterPhase[];
  zones: Zone[];
};

export default function PublishDesignModal(props: PublishDesignModalProps) {
  const {
    fileRegex,
    fileRegexToString,
    designs,
    setDesigns,
    updateSelectedRowKeys,
    isModalOpen,
    setIsModalOpen,
    checkDuplicate,
    projectId,
    publishDesign,
    refreshAll,
    disciplines,
    disciplineMapIdToName,
    modalType,
    designRegisterPhases,
    zones,
  } = props;
  const { t } = useTranslation();
  const footerName = new Map([
    ['approval', 'Files to submit'],
    ['publish', 'Files to publish'],
    ['upload', 'Files to upload'],
  ]);
  const [form] = Form.useForm();
  const [duplicateTitleFieldRuleEnabled] = useState<boolean>(true);
  const [duplicateIdsPerGroup, setDuplicateIdsPerGroup] = useState<{ [key: string]: string[] }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [titleRevisionErrors, setTitleRevisionErrors] = useState<{
    [key: string]: boolean;
  }>({});
  const [fieldErrors, setFieldErrors] = useState<object[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);
  const [queryUser] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(queryUser);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState(Date.now());
  useEffect(() => {
    if (isModalOpen) {
      form.resetFields();
      setSelectedKeys(designs.map((des) => des.id));
      setLoading(false);
    }
    setIsOpen(isModalOpen);
    setState(Date.now());
  }, [isModalOpen]);

  const customValidateFields = async () => {
    form.validateFields({ validateOnly: true });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDesigns([]);
    setSelectedKeys([]);
    setTitleRevisionErrors({});
    updateSelectedRowKeys();
  };
  const findGroupDuplicates = (items: RawDesignForm[]) => {
    const IDsPerTitleRev = items.reduce((groups: { [key: string]: string[] }, item: RawDesignForm) => {
      const key = `${item.title}-${item.revision}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item.id);
      return groups;
    }, {});
    const tempDuplicateIdsPerGroup: { [key: string]: string[] } = {};
    Object.keys(IDsPerTitleRev).forEach((item) => {
      if (IDsPerTitleRev[item].length > 1) {
        tempDuplicateIdsPerGroup[item] = IDsPerTitleRev[item];
      }
    });
    setDuplicateIdsPerGroup(tempDuplicateIdsPerGroup);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const fieldsValues = await form.getFieldsValue();
    const designFieldsValues = fieldsValues['columns'];
    setFieldErrors(form.getFieldsError());
    const rawDesignForms: RawDesignForm[] = Object.keys(designFieldsValues)
      .filter((id) => selectedKeys.includes(id))
      .map((id) => ({
        title: (designFieldsValues[id].title ?? '').trim(),
        revision: (designFieldsValues[id].revision ?? '').trim(),
        id: id as string,
      }));
    findGroupDuplicates(rawDesignForms);
    await checkDuplicate(workspaceId, projectId, rawDesignForms).then((response) => {
      const tempErrors: { [key: string]: boolean } = titleRevisionErrors;
      response.map((validation) => (tempErrors[validation.id] = validation.hasDuplicate));
      setTitleRevisionErrors(tempErrors);
    });
    const updateDesigns: Design[] = designs
      .filter((des) => selectedKeys.includes(des.id))
      .map((design) => ({
        ...design,
        status: fieldsValues['status'],
        phase: fieldsValues['phase'],
        discipline: fieldsValues['discipline'],
        ...designFieldsValues[design.id],
      }));
    const publishDesignInput = {
      approval: designApprovalStatusKeys.includes(fieldsValues['status']),
      designs: updateDesigns,
      users: []
        .concat(
          fieldsValues['invitations'].map((i: { companyId: string; inspectorIds: string[] }) => i['inspectorIds'])
        )
        .flat()
        .map((id) => ({
          id,
          email: users.find((u) => u.id === id)?.email ?? '',
          firstName: users.find((u) => u.id === id)?.firstName ?? '',
          lastName: users.find((u) => u.id === id)?.lastName ?? '',
        }))
        .filter((u) => u.email !== ''),
    };
    form
      .validateFields()
      .then(async () => {
        await publishDesign(workspaceId, projectId, publishDesignInput);
      })
      .then(() => {
        refreshAll();
        setLoading(false);
        handleCancel();
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {});
  };
  const footerTitle = modalType === 'publish' ? 'Publish' : 'Submit';
  const footer = (
    <Flex justify='space-between'>
      <div>
        <Flex className='invisible-field' style={{ fontWeight: 'bold' }}>
          {t(`${footerName.get(modalType!)}`) + ` ${selectedKeys.length}/${designs.length}`}
        </Flex>
      </div>
      <div>
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button loading={loading} onClick={handleSubmit} type='primary' disabled={!selectedKeys.length}>
          {t(footerTitle)}
        </Button>
      </div>
    </Flex>
  );

  const titleModal = modalType === 'publish' ? 'Publish documents' : 'Send for approvals';

  return (
    <Modal
      title={t(titleModal)}
      open={isModalOpen}
      closable={false}
      footer={footer}
      width={'80%'}
      centered
      onCancel={handleCancel}
      closeIcon={true}
    >
      <EditUnpublishedDesign
        key={state}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
        designs={designs}
        selectedUnpublished={selectedKeys}
        setSelectedUnpublished={setSelectedKeys}
        useBulkEdit={false}
        form={form}
        titleRevisionErrors={titleRevisionErrors}
        setTitleRevisionErrors={setTitleRevisionErrors}
        customValidateFields={customValidateFields}
        fileRegex={fileRegex}
        fileRegexToString={fileRegexToString}
        hasAttemptedUpload={true}
        duplicateIdsPerGroup={duplicateIdsPerGroup}
        setDuplicateIdsPerGroup={setDuplicateIdsPerGroup}
        duplicateTitleFieldRuleEnabled={duplicateTitleFieldRuleEnabled}
        disciplines={disciplines}
        disciplineMapIdToName={disciplineMapIdToName}
        type={modalType}
        users={users}
        projectId={projectId}
        isOpen={isOpen}
        designRegisterPhases={designRegisterPhases}
        zones={zones}
      />
    </Modal>
  );
}
