import { Col, Form, FormInstance, Row, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect } from 'components/common';
import { User } from 'model';
import { Company } from 'model';
import { VALIDATION_ACTION } from 'utils/contants';

const { Title } = Typography;
type SecondStepContentProps = {
  form: FormInstance;
  users: User[];
  companies: Company[];
  selection: string;
  companyId: string;
  companyRep: string;
  currentCompany: Company | null;
  currentValidator: User | null;
  isExternal: boolean;
  isFinal: boolean;
  userReviewed: string[];
  companyReviewed: string[];
  originator: User | undefined;
  setCompanyId: (id: string) => void;
  setCompanyRep: (id: string) => void;
  setIsFinal: (value: boolean) => void;
};
export default function SecondStepContent(props: SecondStepContentProps) {
  const { t } = useTranslation();
  const {
    form,
    users,
    companies,
    selection,
    companyId,
    companyRep,
    currentCompany,
    currentValidator,
    isExternal,
    isFinal,
    userReviewed,
    companyReviewed,
    originator,
    setCompanyId,
    setCompanyRep,
    setIsFinal,
  } = props;

  const [filterCompanyReps, setFilterCompanyReps] = useState<User[]>([]);
  const [labelField, setLabelField] = useState<string>('');
  const [labelName, setLabelName] = useState<string>('');
  const [titleField, setTitleField] = useState<string>('');

  const handleSwitchChange = (checked: boolean) => {
    if (checked){
      // Set value currentValidator and currentValidator company
      setCompanyId(currentCompany?.id || '');
      form.setFieldValue(['companyId'], currentCompany?.id || '');
      setCompanyRep(currentValidator?.id || '');
      form.setFieldValue('companyRep', currentValidator?.id || '');
    }
    else {
      setCompanyRep('');
      form.setFieldValue('companyRep', null);
    }
    setIsFinal(checked);
  };

  const onChangeCompany = (companyId: string) => {
    if (!companyId) {
      form.setFieldValue('companyId', null);
      setCompanyId('');
      return;
    }
    setCompanyId(companyId);
    const autoFillUsers = users?.filter((user) => user.WorkspaceUser?.Company?.id === companyId);
    form.setFieldValue('companyRep', autoFillUsers[0]?.id ?? null);
    setCompanyRep(autoFillUsers[0]?.id ?? '');
  };

  const onChangeCompanyRep = (companyRep: string) => {
    if (!companyRep) {
      form.setFieldValue('companyRep', null);
      setCompanyRep('');
      return;
    }
    setCompanyRep(companyRep);
    const selectedUser = users?.find((user) => user.id === companyRep);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    if (companies[existingCompany]?.id) {
      setCompanyId(companies[existingCompany]?.id);
    }
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };

  useEffect(() => {
    const filterUsers = users?.filter((user) => !companyId || user.WorkspaceUser?.Company?.id === companyId);
    setFilterCompanyReps(filterUsers);
  }, [companyId, users]);

  useEffect(() => {
    switch (selection) {
      case 'validate':
        setLabelName('Company Validator');
        setLabelField('Validator Name');
        setCompanyId(currentCompany?.id || '');
        form.setFieldValue(['companyId'], currentCompany?.id || '');
        if (!isFinal){
          setCompanyRep('');
          form.setFieldValue('companyRep', null);
        }
        return setTitleField('Select the next Validator or Company Validator');
      case 'reject':
        setLabelName('Company Originator');
        setLabelField('Originator Name');
        // Set value originator and originator company
        setCompanyId(originator?.WorkspaceUser?.Company?.id || '');
        form.setFieldValue(['companyId'], originator?.WorkspaceUser?.Company?.id || '');
        setCompanyRep(originator?.id || '');
        form.setFieldValue('companyRep', originator?.id || '');
        return setTitleField('You are about to REJECT the MSS it will send back to its Originator');
      case 'transfer':
        setLabelName('Company');
        setLabelField('Transfer Name');
        // Set value currentValidator company and list users
        form.setFieldValue(['companyId'], currentCompany?.id || '');
        const filterUsers = users?.filter((user) => user.WorkspaceUser?.Company?.id === currentCompany?.id);
        setFilterCompanyReps(filterUsers);
        setCompanyRep(currentValidator?.id || '');
        return setTitleField('Select the next Transfer');
      default:
        break;
    }
  }, [selection]);
  return (
    <>
      {selection === VALIDATION_ACTION.VALIDATE && isExternal ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Title level={5} style={{ fontWeight: !isFinal ? 'bold' : 'normal' }}>
            {t('Next Validator')}
          </Title>
          <Switch checked={isFinal} onChange={handleSwitchChange} style={{ margin: '0 10px' }} />
          <Title level={5} style={{ fontWeight: isFinal ? 'bold' : 'normal' }}>
            {t('Final Validation')}
          </Title>
        </div>
      ) : (
        <Title className={'validation-block'} level={5}>
          {t(titleField)}
        </Title>
      )}
      <Row>
        <Col span={24} className={'validation-block'}>
          <div className={'validation-column-data'}>
            <Form.Item
              className={'selection-field'}
              name={'companyId'}
              label={t(labelName)}
              labelAlign={'left'}
              rules={[{ required: true, message: '' }]}
            >
              <SearchSelect
                disabled={selection !== 'validate' || isFinal}
                placeholder={t('Select')}
                value={companyId}
                options={(companies ?? []).map((company) => ({
                  value: company.id,
                  label: company.name,
                  disabled:
                    (company?.WorkspaceUsers ?? []).length <= 0 ||
                    (currentCompany?.id !== company.id && companyReviewed.includes(company.id)),
                }))}
                onChange={(value) => onChangeCompany(value)}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div className={'validation-column-data'}>
            <Form.Item
              name={'companyRep'}
              className={'selection-field'}
              rules={[{ required: true, message: '' }]}
              label={t(labelField)}
              labelAlign={'right'}
            >
              <SearchSelect
                disabled={selection === 'reject' || isFinal}
                placeholder={t('Select')}
                value={companyRep}
                options={(filterCompanyReps ?? []).map((user) => ({
                  value: user.id,
                  label: user.fullName,
                  disabled:
                    (userReviewed.includes(user.id)),
                }))}
                onChange={(value) => onChangeCompanyRep(value)}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  );
}
