import { Button, Card, Col, Flex, Form, Input, Popover, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePickerRange } from 'components';
import { Company, Zone } from 'model';
import { useState, useEffect, CSSProperties } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { QueryParams } from 'types';
import { designFieldValues } from 'modules/DocumentManagement/models/Design';

type FilterDesignFormProps = {
  companies: Company[];
  handleFilter: (value: QueryParams) => void;
  defaultParams: QueryParams;
  style?: CSSProperties;
  zones: Zone[];
};
export default function FilterDesignForm(props: FilterDesignFormProps) {
  const { companies, handleFilter, defaultParams, style, zones } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    handleFilter(values);
    setIsOpenFilter(false);
  };
  const onResetFilter = () => {
    form.resetFields();
    handleFilter(defaultParams);
    setIsOpenFilter(false);
  };

  useEffect(() => {
    onResetFilter();
  }, []);

  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '38px',
              }}
              className='text-uppercase'
            >
              {t('Filter')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                setIsOpenFilter(false);
              }}
              icon={
                <CloseOutlined
                  style={{
                    color: 'rgba(0, 29, 61, 0.45)',
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                />
              }
            />
          }
          actions={[
            <Flex key='applyOrReset' justify='end' className='mx-5'>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='number' label={t('Number')}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='revision' label={t('Revision')}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                {' '}
                <Form.Item className='filter-form__item' name='status' label={t('Status')}>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={Object.keys(designFieldValues.statusValues).map((phase) => {
                      return { value: phase, label: designFieldValues.statusValues[phase].value };
                    })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='documentType' label={t('Document Type')}>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={Object.keys(designFieldValues.documentTypeValues).map((phase) => {
                      return { value: phase, label: designFieldValues.documentTypeValues[phase] };
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={24}>
                <Form.Item className='filter-form__item' name='issueDate' label={t('Issue date range')}>
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={24}>
                <Form.Item className='filter-form__item' name='deadlineDate' label={t('Expected deadline range')}>
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={12}>
                {' '}
                <Form.Item className='filter-form__item' name='area' label={t('Area')}>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={
                      zones &&
                      zones.map((zone) => {
                        return { value: zone.id, label: zone.name };
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='issuedBy' label={t('Issued by')}>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={companies?.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align='bottom' className='mb-5'></Row>
          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={() => {
        setIsOpenFilter(!isOpenFilter);
      }}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        style={style}
        icon={<FilterOutlined />}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
