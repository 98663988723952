import React from 'react';
import { Avatar, Col, Row, Space, Typography } from 'antd';

import { Design } from 'model';

type CreateDesignHeaderProps = {
  editingDesign: Design;
  editingTitle: string;
  editingRevision: string;
};

const CreateDesignHeader: React.FC<CreateDesignHeaderProps> = (props) => {
  const { editingDesign, editingTitle, editingRevision } = props;

  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='create-inspection-header'>
        <Col>
          <div className='title-input'>
            <span className='avatar'>
              <Avatar>{editingRevision}</Avatar>
            </span>
            <Space direction='vertical' style={{ width: '100%', rowGap: 0 }}>
              <div className='title'>{editingTitle}</div>
              <div>
                <Typography.Text type='secondary' style={{ paddingLeft: '12px' }}>
                  {`${editingDesign.number} - ${editingDesign.Company?.name as string}`}
                </Typography.Text>
              </div>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreateDesignHeader;
