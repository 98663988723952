import { Collapse, CollapseProps, Divider, Flex, Row, Space, Steps, Tag, Timeline, Typography } from 'antd';
import { Attachment, BaseIssue, User } from 'model';
import { useMemo } from 'react';
import { VALIDATION_STATUS } from 'utils/contants';
import ProgressBlueIcon from 'assets/images/progress-blue.png';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  FilePdfOutlined,
  SendOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { fileService } from 'services';
import { useTranslation } from 'react-i18next';
import { WIRValidationData } from './WorkInspectionRequestValidationStep';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import moment from 'moment';
import useAuthContext from 'store/auth-context';
import IconDot from 'assets/images/icon-dot.svg';
import { useSelector } from 'react-redux';
import { selectProjectZones } from 'store/my-projects.slice';
import { convertZoneToTreeData } from 'utils';
import { findPath } from 'components/common/TreeSelectTagRender';
import ImageItem from 'components/common/pre-signed-url-items/ImageItem';

type WorkInspectionRequestHistoryProps = {
  baseIssues?: BaseIssue;
  validationData: WIRValidationData[];
  users: User[];
  workspaceId: string;
};

const WorkInspectionRequestHistory = (props: WorkInspectionRequestHistoryProps) => {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const originator = props.users.find((u) => u.id === (props.baseIssues?.originatorId || profile?.id));
  const history = props.baseIssues?.BaseIssueMessages;

  type HistoryItem = {
    id: string;
    baseIssueId: string;
    userId: string;
    companyId: string;
    refTable: string;
    refId: string;
    createdAt: string | Date | number;
    message: string;
  };

  const zones = useSelector(selectProjectZones);
  const zonesTreeData = useMemo(() => convertZoneToTreeData(zones), [zones]);
  // const inspectorHistory = props.baseIssues?.BaseIssueMessages?.filter((h) => h.refTable === 'Users' && h.message.includes('The Inspector')) || [];
  // const deletedInspectorsIds = inspectorHistory.filter((h) => h.refId.split(';')[0]).map((h) => h.refId.split(';')[0]);
  // const addedInspectorsIds = inspectorHistory.filter((h) => h.refId.split(';')[1]).map((h) => h.refId.split(';')[1]);

  let historyByDay: Record<string, HistoryItem[]> = {};
  if (history && history.length > 0) {
    historyByDay = history.reduce<Record<string, HistoryItem[]>>((acc, item) => {
      const date = new Date(item.createdAt);
      const day = date.toISOString().split('T')[0]; // Get the YYYY-MM-DD part

      if (!acc[day]) {
        acc[day] = [];
      }

      acc[day].push(item);
      return acc;
    }, {});

    historyByDay = Object.fromEntries(
      Object.entries(historyByDay)
        .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime()) // Sort dates descending
        .map(([day, items]) => [
          day,
          items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()), // Sort items descending by time
        ])
    );
  }
  const getStatusNameAndIcon = (status: string) => {
    switch (status) {
      case VALIDATION_STATUS.APPROVED_WITH_COMMENT:
        return {
          name: t('Approved with Comments'),
          icon: <CheckCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.APPROVED:
        return {
          name: t('Approved'),
          icon: <CheckCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.TO_BE_REVISED:
        return {
          name: t('To be revised'),
          icon: <CloseCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.REJECTED:
        return {
          name: t('Rejected'),
          icon: <CloseCircleFilled size={24} />,
        };
      case VALIDATION_STATUS.REVIEW_IN_PROGRESS:
        return {
          name: t('Review In Progress'),
          icon: <img width={24} src={ProgressBlueIcon} alt='' />,
        };
      case VALIDATION_STATUS.SUBMITTED:
        return {
          name: t('Submitted'),
          icon: <SendOutlined width={24} />,
        };
      default:
        return null;
    }
  };

  const controlStatus = useMemo(() => {
    return props?.validationData?.map((validation) => {
      const mappedPhotos = validation?.validationPictures?.map((picture) => ({
        ...picture,
        picture: picture.profilePhoto,
        username: picture.fullName || '',
        hideDelete: true,
        firstName: picture.firstName,
      })) as AttachmentPhoto[];

      return {
        className: `ant-steps-item-${validation.status}`,
        icon: getStatusNameAndIcon(validation?.status || '')?.icon,
        description: (
          <>
            <div className='company-name'>{validation?.companyName}</div>
            <Space className='user-information' size={4}>
              <span className='status'>{getStatusNameAndIcon(validation?.status || '')?.name}</span>
              <span className='text-color-polaris-black'>by</span>
              <span className='username'>{validation?.userName}</span>
              <span className={`date`}>{dayjs(validation?.updatedAt).format('DD-MM-YYYY HH:mm:ss')}</span>
            </Space>

            {validation?.comment && (
              <Row className={'wir-control-status-row'}>
                <Typography.Text className='wir-control-status-field'>{t('Comment')}</Typography.Text>
                <Typography.Text className='wir-control-status-value'>{validation?.comment}</Typography.Text>
              </Row>
            )}

            {validation?.attachments && validation?.attachments?.length > 0 && (
              <Row className={'wir-control-status-row'}>
                <Typography.Text className='wir-control-status-field'>{t('Documents Uploaded')}</Typography.Text>
                <Flex wrap gap={8}>
                  {validation.attachments.map((attachment) => {
                    return (
                      <div className='attachment' key={attachment?.filePath}>
                        <FilePdfOutlined color='primary' className='color-primary' />

                        <Typography.Link
                          onClick={() => {
                            fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
                          }}
                        >
                          {`${attachment?.name}.pdf`}
                        </Typography.Link>
                      </div>
                    );
                  })}
                </Flex>
              </Row>
            )}

            {validation?.validationPictures && validation?.validationPictures?.length > 0 ? (
              <Row className={'wir-control-status-row'}>
                <Typography.Text className='wir-control-status-field'>{t('Pictures Uploaded')}</Typography.Text>
                <div className='mt-2'>
                  <AttachmentList hideDelete={true} attachmentPhotos={mappedPhotos} />
                </div>
              </Row>
            ) : null}
          </>
        ),
      };
    });
  }, [props.baseIssues]);

  const controlStatusTabData = [
    ...controlStatus,
    {
      className: `ant-steps-item-${VALIDATION_STATUS.SUBMITTED}`,
      icon: getStatusNameAndIcon(VALIDATION_STATUS.SUBMITTED || '')?.icon,
      description: (
        <>
          <Typography.Title className='company-name font-weight-bold' level={3}>
            {originator?.WorkspaceUser?.Company?.name}
          </Typography.Title>
          <Space className='user-information' size={4}>
            <Typography.Text className='status'>
              {getStatusNameAndIcon(VALIDATION_STATUS.SUBMITTED || '')?.name}
            </Typography.Text>
            <Typography.Text className='text-color-polaris-black'>by</Typography.Text>
            <Typography.Text className='username'>{originator?.fullName}</Typography.Text>
            <Typography.Text className={`date`}>
              {dayjs(props.baseIssues?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
            </Typography.Text>
          </Space>
        </>
      ),
    },
  ];

  function StyledDescription({ description }: { description: string }) {
    // Split text by quoted parts and keep the quotes in the output
    const parts = description?.split(/(".*?")/);

    return (
      <Typography.Text italic className='text-grey'>
        {parts?.map((part, index) =>
          part.startsWith('"') && part.endsWith('"') ? (
            <Typography.Text key={index} className='text-color-polaris-black' style={{ fontStyle: 'normal' }}>
              {part}
            </Typography.Text>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </Typography.Text>
    );
  }

  const historyTabData2: CollapseProps['items'] = [
    {
      key: '1',
      label: t('History'),
      children: (
        <Flex wrap='wrap' vertical>
          {Object.entries(historyByDay).map(([date, items], index) => {
            return (
              <Flex wrap='wrap' vertical key={index}>
                <Space>
                  <Tag icon={<ClockCircleFilled />} className='timeline-time-tag'>
                    {moment(date).format('DD/MM/YYYY')}
                  </Tag>
                </Space>
                <Flex wrap='wrap' vertical className='ml-6'>
                  <Timeline mode='left' style={{ marginTop: '20px' }}>
                    {items.map((item) => {
                      const userCreatedByHistory = props.users.find((user) => user.id === item.userId);
                      let actionDescribe = '';
                      let description = '';
                      let isTransfer = false;
                      let actionUser = '';
                      let attachmentElements = null;
                      switch (item.refTable) {
                        case 'Attachments': {
                          actionDescribe = item.message?.split(';')[0] + ' ';

                          const attachmentsIds = item.refId?.split(';') || []; // Ensure an array even if refId is empty or undefined

                          const allAttachments = [];
                          for (const attachment of props.baseIssues?.WorkInspectionRequest?.WirAttachment || []) {
                            allAttachments.push(attachment as Attachment);
                          }
                          const deletedAttachments =
                            props.baseIssues?.WorkInspectionRequest?.WirDeletedAttachments?.map(
                              (attachment) => attachment.Attachment as Attachment
                            ) || [];
                          for (const deletedAttachment of deletedAttachments) {
                            allAttachments.push(deletedAttachment);
                          }

                          const attachments =
                            allAttachments?.filter((attachment) => attachmentsIds.includes(attachment.id)) || [];

                          description = attachments?.map((attachment) => attachment.name).join(', ') || '';
                          const isDeleted = actionDescribe.includes('deleted');

                          if (actionDescribe.includes('Pictures') && attachments && attachments.length > 0) {
                            attachmentElements = (
                              <div>
                                {attachments.map((attachment) => (
                                  <ImageItem
                                    className='history-image'
                                    src={attachment.filePath}
                                    alt='Image'
                                    preview={!isDeleted}
                                    style={isDeleted ? { opacity: 0.5 } : {}}
                                    key={attachment.id}
                                  />
                                ))}
                              </div>
                            );
                          } else {
                            attachmentElements = (
                              <div>
                                {attachments?.map((attachment) => (
                                  <div className='history-attachment' key={attachment?.filePath}>
                                    <FilePdfOutlined color='primary' className='color-primary' />
                                    <Typography.Link
                                      onClick={() => {
                                        fileService.downloadFile(attachment?.filePath || '', `${attachment?.name}.pdf`);
                                      }}
                                      disabled={isDeleted}
                                    >
                                      {`${attachment?.name}.pdf`}
                                    </Typography.Link>
                                  </div>
                                ))}
                              </div>
                            );
                          }
                          break;
                        }
                        case 'Zones': {
                          actionDescribe = item.message.split(';')[0] + ' ';
                          const replaceString: string[] = [];
                          const [from, to] = item.refId.split(';');
                          if (from) {
                            replaceString.push(findPath(zonesTreeData, from || ''));
                          }
                          if (to) {
                            replaceString.push(findPath(zonesTreeData, to || ''));
                          }
                          description = item.message
                            .split(';')[1]
                            .replace(/"([^"]*)"/g, () => `"${replaceString[index++]}"`);
                          break;
                        }
                        case 'Users': {
                          actionDescribe = item.message.split(';')[0] + ' ';
                          const replaceString: string[] = [];
                          const [from, to] = item.refId.split(';');
                          if (from) {
                            const fromUser = props.users.find((u) => u.id === from);
                            const fromCompany = fromUser?.WorkspaceUser?.Company;
                            replaceString.push(
                              `${fromUser?.fullName || 'N/A'} ${actionDescribe.includes('Inspector') ? ` from ${fromCompany?.name || 'N/A'} company` : ''}`
                            );
                          }
                          if (to) {
                            const toUser = props.users.find((u) => u.id === to);
                            const toCompany = toUser?.WorkspaceUser?.Company;
                            replaceString.push(
                              `${toUser?.fullName || 'N/A'} ${actionDescribe.includes('Inspector') ? ` from ${toCompany?.name || 'N/A'} company` : ''}`
                            );
                          }
                          let index = 0;
                          if (actionDescribe.includes('transferred')) {
                            isTransfer = true;

                            // Extract initial description and update it with replacements
                            const [initialMessage] = item.message.split(';');
                            actionDescribe = initialMessage.replace(/"([^"]*)"/g, () => `${replaceString[index++]}`);

                            // Extract the user part and the action part separately
                            const [userPart, transferPart] = actionDescribe.split('transferred');
                            actionUser = userPart.trim();
                            actionDescribe = `transferred ${transferPart.trim()}`;

                            // Get the company name if it exists
                            const userCompany = props.users.find((u) => u.id === to)?.WorkspaceUser?.Company;
                            actionDescribe += ` from ${userCompany?.name || 'N/A'} company`;
                          }
                          description = item.message
                            .split(';')[1]
                            .replace(/"([^"]*)"/g, () => `"${replaceString[index++]}"`);
                          break;
                        }
                        default: {
                          actionDescribe = item.message.split(';')[0] + ' ';
                          description = item.message.split(';')[1];
                          if (actionDescribe.includes('Inspection Date')) {
                            let index = 0;
                            const [from, to] = item.refId.split(';');
                            const replaceString: string[] = [];
                            const isSameDay = moment(from).isSame(moment(to), 'day');
                            if (isSameDay) {
                              actionDescribe = actionDescribe.replace('Inspection Date', 'Inspection Time');
                            }
                            if (from) {
                              replaceString.push(moment(from).format(isSameDay ? 'HH:mm A' : 'DD/MM/YYYY HH:mm A'));
                            }
                            if (to) {
                              replaceString.push(moment(to).format(isSameDay ? 'HH:mm A' : 'DD/MM/YYYY HH:mm A'));
                            }
                            description = description.replace(/"([^"]*)"/g, () => `"${replaceString[index++]}"`);
                          }
                          break;
                        }
                      }
                      return (
                        <Timeline.Item key={item.id} dot={<img src={IconDot} alt='history-timeline' width='8px' />}>
                          <div style={{ marginLeft: '10px', width: '100%' }}>
                            <Flex wrap='wrap'>
                              <Flex vertical>
                                <Flex wrap='wrap'>
                                  {isTransfer ? (
                                    <>
                                      <Typography.Text strong className='mr-1 font-weight-bold'>
                                        {actionUser}
                                      </Typography.Text>
                                      <Typography.Text className='mr-1' italic style={{ color: '#3069C4' }}>
                                        {t(actionDescribe) + ' '}
                                      </Typography.Text>
                                    </>
                                  ) : (
                                    <>
                                      <Typography.Text className='mr-1' italic>
                                        {t(actionDescribe) + ' '}
                                      </Typography.Text>
                                      <Typography.Text strong className='mr-1 font-weight-bold'>
                                        {userCreatedByHistory?.fullName}
                                      </Typography.Text>
                                    </>
                                  )}
                                  <Typography.Text italic>{t('at')}</Typography.Text>
                                  <Typography.Text className='ml-2 text-grey'>
                                    {moment(item.createdAt).format('HH:mm:ss')}
                                  </Typography.Text>
                                </Flex>
                                {attachmentElements ? (
                                  <Flex>{attachmentElements}</Flex>
                                ) : (
                                  <Flex>
                                    <StyledDescription description={description} />
                                  </Flex>
                                )}
                              </Flex>
                            </Flex>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      ),
    },
  ];

  // const commentsTabData: CollapseProps['items'] = [
  //   {
  //     key: '2',
  //     label: t('Comments'),
  //     children: (
  //       <Flex vertical className='pa-5'>
  //         {mockWIRHistory.map((item, index) => {
  //           const userCreatedByHistory = props.users.find((user) => user.id === item.userCreatedId);
  //           return (
  //             <Timeline mode='left' style={{ marginTop: '20px' }} key={index}>
  //               <Timeline.Item
  //                 dot={
  //                   userCreatedByHistory?.profilePhoto ? (
  //                     <AvatarItem size={32} src={userCreatedByHistory?.profilePhoto} />
  //                   ) : (
  //                     <Avatar size={32}>{userCreatedByHistory?.firstName?.substring(0, 1)}</Avatar>
  //                   )
  //                 }
  //               >
  //                 <Flex vertical>
  //                   <Flex justify='space-between'>
  //                     <Typography.Text>
  //                       <strong className='mr-1 font-weight-bold'>{userCreatedByHistory?.fullName}</strong>
  //                       <Typography.Text className='text-grey' italic>
  //                         {t('left the comment')}
  //                       </Typography.Text>
  //                     </Typography.Text>
  //                     <Typography.Text className='text-grey'>
  //                       {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
  //                     </Typography.Text>
  //                   </Flex>
  //                   <Flex wrap='wrap' className='mb-2 '>
  //                     <Typography.Text className='text-grey text-size-12'>
  //                       {userCreatedByHistory?.WorkspaceUser?.Company?.name}
  //                     </Typography.Text>
  //                   </Flex>
  //                   <Flex wrap='wrap'>
  //                     <Typography.Text>{item.comment}</Typography.Text>
  //                   </Flex>
  //                 </Flex>
  //               </Timeline.Item>
  //             </Timeline>
  //           );
  //         })}
  //       </Flex>
  //     ),
  //   },
  // ];
  return (
    <div className='wir-history-container'>
      <Steps direction='vertical' items={controlStatusTabData} labelPlacement='vertical' className='mt-6' />
      <Divider orientation='left'>{t('Control History')}</Divider>
      <Collapse defaultActiveKey={['1']} items={historyTabData2} className='mt-6' />
      {/* <Collapse defaultActiveKey={['2']} items={commentsTabData} className='mt-6' /> */}
    </div>
  );
};

export default WorkInspectionRequestHistory;
