import { CommentOutlined, CopyOutlined, DeleteOutlined, TagOutlined } from '@ant-design/icons';
import { ButtonProps, Drawer, Form, Layout, message, Spin, Tabs, TabsProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ATTACHMENT_TYPES,
  MODULE_FUNCTIONS,
  TASK_STATUS_HEADER_COLOR,
  VALIDATION_STATUS,
  WIR_STATUSES,
} from 'utils/contants';
import {
  CustomizeRequiredMark,
  DeleteConfirmation,
  WorkInspectionRequestDetail,
  WorkInspectionRequestFooter,
  WorkInspectionRequestHeader,
} from 'components';
import { baseIssueService, baseValidationService } from 'services';
import { Attachment, BaseIssue, User } from 'model';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useMyWorkspaceWorkPackagesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectWorkPackages,
} from 'hooks';
import { EWIRActionType, WIRFooterProps, WorkspaceViewType } from './CreateOrUpdateWIR/WorkInspectionRequestFooter';
import {
  WorkInspectionRequestHistory,
  WorkInspectionRequestInspectionModal,
  WorkInspectionRequestValidationStep,
} from './CreateOrUpdateWIR';
import { WIRFileUpload, WIRFormValues } from './CreateOrUpdateWIR/WorkInspectionRequestDetail';
import useUserEntity from 'hooks/common/useUserProfile';
import { useSelector } from 'react-redux';
import { selectProjectUsers } from 'store/my-projects.slice';
import dayjs from 'dayjs';
import { selectAttachmentByCode } from 'store/common.slice';
import { useAppSelector } from 'store';
import { WIRValidationData } from './CreateOrUpdateWIR/WorkInspectionRequestValidationStep';
import TransferModal from './CreateOrUpdateWIR/WorkInspectionRequestTransfer';
import wirInvitationService from 'services/wir-invitation.service';
import refAttachmentService from 'services/ref-attachment.service';

type CreateOrUpdateWIRProps = {
  isOpen: boolean;
  isEdit: boolean;
  module: string;
  workspaceId: string;
  projectId: string;
  profile: User | null;
  baseIssueId?: string | null;
  setOpen: (value: boolean) => void;
  refreshBaseIssues: (params: string, baseIssueId?: string) => void;
  selectedDuplicateId: string | null;
  onDuplicate: (id: string) => void;
};

type WIRStatusConfig = {
  footerButton?: WIRFooterProps['buttons'];
  isReadyOnly?: boolean;
};

export default function CreateOrUpdateWorkInspectionRequest(props: CreateOrUpdateWIRProps) {
  const { t } = useTranslation();
  const { isOpen, module, setOpen, profile, projectId, workspaceId, baseIssueId, selectedDuplicateId, onDuplicate } =
    props;
  const [form] = Form.useForm<WIRFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStartInspectionLoading, setIsStartInspectionLoading] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const fieldValue = Form.useWatch([], form);

  const [selectedBaseIssue, setSelectedBaseIssue] = useState<BaseIssue>();

  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.WIR,
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const { user } = useUserEntity({ projectId, workspaceId });

  const users = useSelector(selectProjectUsers);

  const [moduleFunctionsParams] = useModuleFunctionParams();
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsParams);

  const [openStartInspection, setOpenStartInspection] = useState(false);

  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [companies] = useProjectCompanies(companyParams);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [workPackagesQuery] = useMyWorkspaceWorkPackagesParams({
    projectId,
    workspaceId,
  });
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  useProjectWorkPackages(workPackagesQuery);
  useAttachmentTypes(attachmentTypeQuery);

  const wirAttachment = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_CHECKLIST));
  const wirPhoto = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_PHOTO));
  const wirRefDocumentation = useAppSelector(selectAttachmentByCode(ATTACHMENT_TYPES.WIR_REF_DOCUMENTATION));

  const selectedBaseValidation = useMemo(() => {
    return selectedBaseIssue?.BaseValidations?.find((validation) => validation.validatorId === user?.id);
  }, [selectedBaseIssue?.BaseValidations, user]);

  const hasOneValidation = useMemo(() => {
    return (
      selectedBaseIssue?.BaseValidations?.filter(
        (validation) =>
          validation.validationStatus === VALIDATION_STATUS.APPROVED ||
          validation.validationStatus === VALIDATION_STATUS.APPROVED_WITH_COMMENT
      ).length === 1
    );
  }, [selectedBaseIssue?.BaseValidations]);
  const isOriginator = useMemo(() => {
    return selectedBaseIssue?.originatorId === user?.id;
  }, [selectedBaseIssue?.originatorId, user?.id]);
  const isDisable = useMemo(() => {
    return hasOneValidation || !isOriginator;
  }, [hasOneValidation, isOriginator]);

  const [openTransferModal, setOpenTransferModal] = useState(false);
  const ownValidation = useMemo(() => {
    return selectedBaseIssue?.BaseValidations?.find((validation) => validation.validatorId === user?.id) || null;
  }, [selectedBaseIssue?.BaseValidations, user?.id]);
  const ownInvitation = useMemo(() => {
    return (
      selectedBaseIssue?.WorkInspectionRequest?.WirInvitations?.find(
        (wirInvitation) => wirInvitation.inspectorId === user?.id
      ) || null
    );
  }, [selectedBaseIssue?.WorkInspectionRequest?.WirInvitations, user?.id]);
  const invitedUserIds = useMemo(() => {
    return selectedBaseIssue?.WorkInspectionRequest?.WirInvitations?.map((wirInvitation) => {
      return wirInvitation.inspectorId;
    });
  }, [selectedBaseIssue?.WorkInspectionRequest?.WirInvitations]);

  const toAttachment = (attachment: WIRFileUpload) => {
    return {
      attachmentTypeId: attachment.attachmentTypeId,
      name: attachment.name,
      filePath: attachment.filePath,
      comment: attachment.comment,
    };
  };

  const mapUserInfoToAttachment = (attachment: Attachment, index: number, showAll?: boolean): WIRFileUpload => {
    const userInfo = users.find((user: User) => user.id === attachment?.RefAttachment?.userCreated);
    let mappedUserInfo = null;
    if (index === 0 || showAll) {
      mappedUserInfo = {
        profilePhoto: userInfo?.profilePhoto || '',
        fullName: userInfo?.fullName || '',
        firstName: userInfo?.firstName || '',
      };
    }

    return {
      attachmentTypeId: attachment.attachmentTypeId || '',
      name: attachment?.name || '',
      filePath: attachment?.filePath || '',
      createdAt: attachment?.createdAt,
      comment: attachment?.RefAttachment?.comment || '',
      ...mappedUserInfo,
    };
  };

  const validationsData = useMemo(() => {
    return (
      selectedBaseIssue?.BaseValidations?.map((baseValidation) => {
        const user = users?.find((user) => user.id === baseValidation.validatorId);
        return {
          companyName: baseValidation.ValidatorCompany?.name,
          companyCode: baseValidation.ValidatorCompany?.companyCode,
          status: baseValidation.pinComment
            ? VALIDATION_STATUS.APPROVED_WITH_COMMENT
            : baseValidation?.validationStatus,
          id: baseValidation.id,
          userName: user?.fullName,
          updatedAt: baseValidation?.updatedAt,
          comment: baseValidation?.comment,
          attachments:
            baseValidation?.ValidationAttachment?.filter(
              (validationAttachment) => validationAttachment.attachmentTypeId === wirAttachment?.id
            ) || [],
          validationPictures:
            baseValidation?.ValidationAttachment?.filter(
              (validationAttachment) => validationAttachment.attachmentTypeId === wirPhoto?.id
            ).map((data, index) => mapUserInfoToAttachment(data, index, true)) || [],
        };
      }) || []
    );
  }, [selectedBaseIssue, users]);

  const formConfig: WIRStatusConfig = useMemo(() => {
    if (!selectedBaseIssue && baseIssueId) return {};
    const hasInvitedInspector = selectedBaseIssue?.WorkInspectionRequest.WirInvitations.some(
      (wir) => user?.id === wir?.inspectorId
    );
    const hasInspectorChangeStatus = selectedBaseIssue?.BaseValidations?.some(
      (baseValidation) =>
        baseValidation.validatorId === user?.id &&
        baseValidation.validationStatus !== VALIDATION_STATUS.REVIEW_IN_PROGRESS
    );
    const hasOriginator = selectedBaseIssue?.originatorId === user?.id;
    const hasOriginatorCompany = selectedBaseIssue?.companyOriginatorId === user?.WorkspaceUser?.Company?.id;
    // const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
    // const isAssignerCompany =
    //   userCompanyId === users?.find((user) => user.id === selectedBaseIssue?.originatorId)?.WorkspaceUser?.Company?.id;
    switch (selectedBaseIssue?.IssueStatus?.code) {
      case WIR_STATUSES.REVIEW_IN_PROGRESS:
        return {
          footerButton: [
            {
              label: t('Cancel'),
              actionType: EWIRActionType.Cancel,
              hidden: hasInvitedInspector,
              type: 'default',
            },
            {
              label: t('Transfer'),
              actionType: EWIRActionType.Transfer,
              hidden: !hasInvitedInspector || hasInspectorChangeStatus,
              type: 'default',
            },
            {
              label: t('Save'),
              actionType: EWIRActionType.InviteForInspection,
              hidden: !(hasInvitedInspector && isOriginator) || hasInspectorChangeStatus,
              type: 'default',
            },
            {
              label: !hasInvitedInspector ? t('Invite for Inspection') : t('Start Inspection'),
              disabled:
                (!hasInvitedInspector && !isOriginator) ||
                hasInspectorChangeStatus ||
                (isOriginator && hasOneValidation),
              actionType: hasInvitedInspector
                ? EWIRActionType.OpenStartInspectionModal
                : EWIRActionType.InviteForInspection,
            },
          ],
          isReadyOnly: isDisable,
        };
      case WIR_STATUSES.DRAFT:
        return {
          footerButton: [
            {
              actionType: EWIRActionType.Delete,
              position: 'left',
              disabled: !isOriginator,
              danger: true,
              type: 'primary',
              icon: <DeleteOutlined />,
            },
            {
              label: t('Save'),
              type: 'default',
              actionType: EWIRActionType.Save,
              disabled: !hasOriginator && !hasOriginatorCompany,
            },
            {
              label: t('Invite for Inspection'),
              actionType: EWIRActionType.InviteForInspection,
              disabled: disableSubmitButton || (!hasOriginator && !hasOriginatorCompany),
            },
          ],
          isReadyOnly: !hasOriginator && !hasOriginatorCompany,
        };

      case WIR_STATUSES.REJECTED:
      case WIR_STATUSES.APPROVED:
        return {
          footerButton: [
            {
              label: !hasInvitedInspector ? t('Invite for Inspection') : t('Start Inspection'),
              disabled: true,
            },
          ],
          isReadyOnly: true,
        };
      case WIR_STATUSES.APPROVED_WITH_COMMENT:
        return {
          footerButton: [
            {
              label: !hasInvitedInspector ? t('Invite for Inspection') : t('Start Inspection'),
              disabled: true,
            },
          ],
          isReadyOnly: true,
        };

      default:
        return {
          footerButton: [
            {
              label: t('Save as Draft'),
              type: 'default',
              actionType: EWIRActionType.SaveAsDraft,
            },
            {
              label: t('Invite for Inspection'),
              actionType: EWIRActionType.InviteForInspection,
              disabled: disableSubmitButton,
            },
          ],
        };
    }
  }, [selectedBaseIssue, disableSubmitButton]);

  const onClose = () => {
    setOpen(false);
    setActiveTab('detail');
    form.resetFields();
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
  };

  const generateDataByStatus = (isSaveAsDraft: boolean, formData: WIRFormValues) => {
    if (isSaveAsDraft) {
      return {
        isSaveAsDraft: true,
        statusId: issueStatuses.find((status) => status.code === WIR_STATUSES.DRAFT)?.id,
        wirValidation: null,
        wirValidationStatus: null,
      };
    }

    return {
      isSaveAsDraft: false,
      statusId: issueStatuses.find((status) => status.code === WIR_STATUSES.REVIEW_IN_PROGRESS)?.id,
      wirValidations: formData?.inspectors
        .filter(
          (inspector) =>
            inspector.companyId &&
            inspector.userId &&
            selectedBaseIssue?.BaseValidations?.find(
              (baseValidation) => baseValidation.validatorId === inspector.userId
            ) === undefined
        )
        .map((inspector) => ({
          validatorCompanyId: inspector.companyId,
          validatorId: inspector.userId,
          revision: 0,
          validationStatus: VALIDATION_STATUS.REVIEW_IN_PROGRESS,
        })),
    };
  };

  const generateIssuesPayload = (formData: WIRFormValues, isSaveAsDraft: boolean) => {
    const moduleFunctionId =
      moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.WIR)?.id || null;
    const statusData = generateDataByStatus(isSaveAsDraft, formData);
    const hasInvitation = formData.inspectors.some((inspector) => inspector?.userId || inspector?.companyId);

    return {
      name: formData.workInspectionName?.trim(),
      zoneId: formData.zoning || null,
      description: formData.workInspectionDescription || null,
      isSaveAsDraft: statusData?.isSaveAsDraft,
      originatorId: selectedBaseIssue?.originatorId || user?.id,
      companyOriginatorId: selectedBaseIssue?.companyOriginatorId || user?.WorkspaceUser?.Company?.id,
      statusId: statusData?.statusId,
      moduleFunctionId: moduleFunctionId,
      watcherIds: formData?.distributions || [],
      wir: {
        workPackageId: formData.workPackage,
        disciplineId: formData.discipline,
        activityId: formData.activity || null,
        subActivityId: formData.subActivity || null,
        controlPointId: formData.controlPoint || null,
        wirInvitations: hasInvitation
          ? formData.inspectors
              ?.filter((inspector) => inspector?.companyId && inspector?.userId)
              .map((data, index) => ({
                companyId: data.companyId,
                inspectorId: data.userId,
                order: index,
              }))
          : [],
        inspectionDate: formData.inspectionDate
          ?.hour(formData?.inspectionTime?.hour() || 0)
          .minute(formData?.inspectionTime?.minute() || 0)
          .toISOString(),
        wirAttachmentInfo: [
          ...(formData?.checklistAttachments || []),
          ...(formData?.referenceDocuments || []),
          ...(formData?.pictures || []),
        ],
        wirValidations: statusData?.wirValidations,
        comment: formData?.comment || null,
        workspaceId: formData.workPackage,
      },
    };
  };
  const submitForm = async (isSaveAsDraft: boolean) => {
    try {
      await form.validateFields(
        isSaveAsDraft ? ['workInspectionName', 'discipline', 'workInspectionDescription'] : undefined
      );
      setIsLoading(true);
      const values = (await form.getFieldsValue(true)) as WIRFormValues;

      const result = generateIssuesPayload(values, isSaveAsDraft);

      if (baseIssueId) {
        await baseIssueService.updateBaseIssue(workspaceId, projectId, baseIssueId, result);
        const baseValidationToDelete = selectedBaseIssue?.BaseValidations?.filter(
          (baseValidation) => !values?.inspectors.some((inspector) => inspector?.userId === baseValidation?.validatorId)
        );
        if (baseValidationToDelete && baseValidationToDelete?.length > 0) {
          for (const baseValidation of baseValidationToDelete) {
            await baseValidationService.deleteBaseValidation(
              workspaceId,
              projectId,
              selectedBaseIssue?.id || '',
              baseValidation.id
            );
          }
        }
        if (
          result?.wir?.wirAttachmentInfo?.length === 0 &&
          selectedBaseIssue?.WorkInspectionRequest?.WirAttachment &&
          selectedBaseIssue?.WorkInspectionRequest?.WirAttachment?.length > 0
        ) {
          for (const attachment of selectedBaseIssue.WorkInspectionRequest.WirAttachment) {
            const refAttachmentId = attachment.RefAttachment?.id;
            if (refAttachmentId) {
              await refAttachmentService.deleteRefAttachment(workspaceId, projectId, refAttachmentId);
            }
          }
        }
      } else {
        await baseIssueService.createBaseIssue(workspaceId, projectId, result);
      }
      setOpen(false);
      form.resetFields();
      props.refreshBaseIssues(values?.discipline || '');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: t('Work Inspection Details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'controlStatus',
      label: t('Control Status'),
      disabled: !selectedBaseIssue || selectedBaseIssue.IssueStatus?.code === WIR_STATUSES.DRAFT,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  const updateFormValue = async () => {
    setIsLoading(true);
    try {
      const id = baseIssueId || selectedDuplicateId;
      const baseIssue = await baseIssueService.getBaseIssueById(workspaceId, projectId, id as string, {
        type: 'WIR',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      if (selectedDuplicateId) {
        setDefaultValue({ ...baseIssue, BaseValidations: [] });
      } else {
        setSelectedBaseIssue(baseIssue);
        setDefaultValue(baseIssue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (baseIssueId || selectedDuplicateId) {
      updateFormValue();
    }
  }, [baseIssueId, wirAttachment, wirPhoto, selectedDuplicateId]);

  const filterAttachmentByTypeId = (selectedBaseIssue: BaseIssue, attachmentTypeId?: string) => {
    return selectedBaseIssue?.WorkInspectionRequest?.WirAttachment.filter(
      (wirAttachment) => wirAttachment.attachmentTypeId === attachmentTypeId
    );
  };

  const mapIssueAttachments = (selectedBaseIssue: BaseIssue): WIRFileUpload[] => {
    const originatorAttachment =
      filterAttachmentByTypeId(selectedBaseIssue, wirAttachment?.id).map((data, index) =>
        mapUserInfoToAttachment(data, index)
      ) || [];
    const inspectorAttachment =
      selectedBaseIssue?.BaseValidations?.flatMap((baseValidation) => {
        const result = baseValidation?.ValidationAttachment?.filter(
          (validationAttachment) => validationAttachment.attachmentTypeId === wirAttachment?.id
        );
        return result ? result.map((data, index) => mapUserInfoToAttachment(data, index)) : null;
      }).filter(Boolean) || [];
    return [...originatorAttachment, ...(inspectorAttachment as WIRFileUpload[])];
  };

  const mapIssuePhotos = (selectedBaseIssue: BaseIssue) => {
    const result = selectedBaseIssue.BaseValidations?.flatMap((baseValidation) => {
      const data = baseValidation?.ValidationAttachment?.flatMap((validationAttachment) => {
        if (validationAttachment.attachmentTypeId !== wirPhoto?.id) return undefined;
        return toAttachment(validationAttachment);
      }).filter(Boolean) as WIRFileUpload[];

      return data;
    });
    return result || [];
  };

  const setDefaultValue = (selectedBaseIssue: BaseIssue) => {
    form.setFieldsValue({
      activity: selectedBaseIssue?.WorkInspectionRequest?.activityId,
      zoning: selectedBaseIssue?.Zone?.id,
      discipline: selectedBaseIssue?.WorkInspectionRequest?.disciplineId,
      inspectionDate:
        selectedBaseIssue?.WorkInspectionRequest?.inspectionDate &&
        dayjs(selectedBaseIssue?.WorkInspectionRequest.inspectionDate),
      inspectionTime:
        selectedBaseIssue?.WorkInspectionRequest?.inspectionDate &&
        dayjs(selectedBaseIssue?.WorkInspectionRequest?.inspectionDate),
      inspectors: selectedBaseIssue?.WorkInspectionRequest?.WirInvitations?.map((wirInvitation) => ({
        companyId: wirInvitation.companyId,
        userId: wirInvitation.inspectorId,
      })),
      distributions: selectedBaseIssue?.watcherIds || [],
      subActivity: selectedBaseIssue?.WorkInspectionRequest?.subActivityId,
      workInspectionDescription: selectedBaseIssue?.description || null,
      workInspectionName: selectedBaseIssue?.name?.trim() || '',
      controlPoint: selectedBaseIssue?.WorkInspectionRequest?.controlPointId,
      checklistAttachments: mapIssueAttachments(selectedBaseIssue),
      workPackage: selectedBaseIssue?.WorkInspectionRequest?.workPackageId,
      comment: selectedBaseIssue?.WorkInspectionRequest?.comment || null,
      validationPictures: mapIssuePhotos(selectedBaseIssue),
      referenceDocuments:
        filterAttachmentByTypeId(selectedBaseIssue, wirRefDocumentation?.id).map((data, index) =>
          mapUserInfoToAttachment(data, index)
        ) || [],
      pictures:
        filterAttachmentByTypeId(selectedBaseIssue, wirPhoto?.id).map((data, index) =>
          mapUserInfoToAttachment(data, index, true)
        ) || [],
    });
  };

  const getValidationAttachmentInfo = (values: WIRFormValues) => {
    const result = [];

    if (values.validationChecklist?.length) {
      result.push(...values.validationChecklist.map(toAttachment));
    }

    if (values.validationPictures?.length) {
      result.push(...values.validationPictures.map(toAttachment));
    }
    return result;
  };

  const startInspection = async () => {
    try {
      setIsStartInspectionLoading(true);
      const values = form.getFieldsValue(true) as WIRFormValues;

      if (!selectedBaseValidation || !baseIssueId) return;
      const validationStatus =
        values.validationStatus === VALIDATION_STATUS.APPROVED_WITH_COMMENT
          ? VALIDATION_STATUS.APPROVED
          : values.validationStatus;
      const result = {
        validatorId: selectedBaseValidation.validatorId,
        validatorCompanyId: selectedBaseValidation.validatorCompanyId,
        revision: 0,
        validationStatus,
        pinComment: values.validationStatus === VALIDATION_STATUS.APPROVED_WITH_COMMENT,
        comment: values.validationComment,
        wirValidation: {
          validatorId: selectedBaseValidation.validatorId,
          validatorCompanyId: selectedBaseValidation.validatorCompanyId,
          revision: 0,
          validationStatus: validationStatus,
          validationAction: validationStatus,
          comment: values.validationComment,
          validationAttachmentsInfo: getValidationAttachmentInfo(values),
        },
      };

      await baseValidationService.updateBaseValidation(
        workspaceId,
        projectId,
        baseIssueId,
        selectedBaseValidation.id,
        result
      );

      setOpenStartInspection(false);
      setOpen(false);
      props.refreshBaseIssues(values?.discipline || '');
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      setIsStartInspectionLoading(false);
    }
  };
  const onDelete = async () => {
    try {
      await baseIssueService.deleteBaseIssueById(workspaceId, projectId, baseIssueId as string);
      setOpen(false);
      props.refreshBaseIssues(selectedBaseIssue?.WorkInspectionRequest?.disciplineId || '', baseIssueId as string);
    } catch (error) {
      console.log(error);
    }
  };

  const onTransfer = async () => {
    try {
      setOpenTransferModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = (actionType: EWIRActionType) => {
    switch (actionType) {
      case EWIRActionType.InviteForInspection:
        submitForm(false);
        break;
      case EWIRActionType.Save:
      case EWIRActionType.SaveAsDraft:
        submitForm(true);
        break;
      case EWIRActionType.OpenStartInspectionModal:
        setOpenStartInspection(true);
        break;
      case EWIRActionType.StartInspection:
        startInspection();
        break;
      case EWIRActionType.Cancel:
        setOpen(false);
        break;
      case EWIRActionType.Delete:
        setIsDeleteConfirmation(true);
        break;
      case EWIRActionType.Duplicate:
        onDuplicate(baseIssueId as string);
        break;
      case EWIRActionType.Transfer:
        onTransfer();
        break;
      default:
        break;
    }
  };

  const handleTransfer = async (newValidatorId: string) => {
    try {
      if (ownValidation && ownInvitation) {
        await baseValidationService.updateBaseValidation(
          workspaceId,
          projectId,
          baseIssueId as string,
          ownValidation.id,
          {
            validatorId: newValidatorId,
            validatorCompanyId: user?.WorkspaceUser?.Company?.id || '',
            revision: ownValidation.revision || 0,
          }
        );
        await wirInvitationService.updateWirInvitation(workspaceId, projectId, ownInvitation.id, {
          inspectorId: newValidatorId,
        });
        setOpenTransferModal(false);
        setOpen(false);
        props.refreshBaseIssues(selectedBaseIssue?.id || '');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateDisableButton = (allField: WIRFormValues) => {
    if (!allField) return;
    const { inspectors, ...restField } = allField;
    const requireFields = ['workInspectionName', 'workPackage', 'discipline', 'inspectionDate', 'inspectionTime'];

    const hasRequireField = Object.keys(restField)?.some(
      (keyField) => requireFields.includes(keyField) && !(restField as unknown as Record<string, string>)[keyField]
    );

    const hasRequireListField = inspectors
      ?.slice(0, 1)
      ?.some((inspector) => !inspector?.companyId || !inspector?.userId);

    setDisableSubmitButton(hasRequireListField || hasRequireField);
  };
  const handleChangeValue = (_: WIRFormValues[keyof WIRFormValues], allField: WIRFormValues) => {
    updateDisableButton(allField);
  };

  useEffect(() => {
    updateDisableButton(fieldValue);
  }, [fieldValue]);

  return (
    <Layout.Content>
      <Form
        requiredMark={CustomizeRequiredMark}
        id='create-update-wir-form'
        form={form}
        labelCol={{ span: 24 }}
        layout='vertical'
        onValuesChange={handleChangeValue}
      >
        <DeleteConfirmation
          isModalOpen={isDeleteConfirmation}
          title={t('Delete the Work Inspection Request')}
          description={t('Are you sure to delete this Work Inspection Request?')}
          onDelete={() => {
            setIsDeleteConfirmation(false);
            onDelete();
          }}
          onCancel={() => setIsDeleteConfirmation(false)}
        />
        <Drawer
          className={`mar-drawer ${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
          styles={{
            header: {
              backgroundColor: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'],
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={1025}
          onClose={onClose}
          closeIcon={false}
          open={isOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <WorkInspectionRequestHeader
              selectedBaseIssue={selectedBaseIssue}
              onClose={onClose}
              profile={profile}
              isUnSaveStatus={false}
              user={user}
            />
          }
          footer={
            <WorkInspectionRequestFooter
              buttons={[
                ...(formConfig?.footerButton || []),
                ...(baseIssueId && formConfig?.footerButton
                  ? [
                      {
                        actionType: EWIRActionType.Duplicate,
                        position: 'left',
                        type: 'primary',
                        icon: <CopyOutlined />,
                      } as ButtonProps,
                    ]
                  : []),
              ]}
              onActionClick={handleFormSubmit}
            />
          }
        >
          <Spin spinning={isLoading}>
            <Tabs
              className='mar-tab-create'
              defaultActiveKey='detail'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
            {selectedBaseIssue && (
              <WorkInspectionRequestValidationStep
                validationData={validationsData as WIRValidationData[]}
                baseIssues={selectedBaseIssue}
              />
            )}
            <TransferModal
              isOpen={openTransferModal}
              setOpen={setOpenTransferModal}
              onTransfer={handleTransfer}
              baseValidation={ownValidation}
              projectId={projectId}
              workspaceId={workspaceId}
              user={user}
              validators={invitedUserIds}
            />
            {activeTab === 'detail' && (
              <WorkInspectionRequestDetail
                baseIssues={selectedBaseIssue}
                companies={companies}
                form={form}
                isReadOnly={!!formConfig?.isReadyOnly}
                user={user}
              />
            )}
            {activeTab === 'controlStatus' && (
              <WorkInspectionRequestHistory
                baseIssues={selectedBaseIssue}
                validationData={validationsData as WIRValidationData[]}
                users={users}
                workspaceId={workspaceId}
              />
            )}
            {openStartInspection && (
              <WorkInspectionRequestInspectionModal
                onCancel={() => setOpenStartInspection(false)}
                isOpen={openStartInspection}
                onSubmitStartInspection={() => handleFormSubmit(EWIRActionType.StartInspection)}
                isLoading={isStartInspectionLoading}
                user={user}
              />
            )}
          </Spin>
        </Drawer>
      </Form>
    </Layout.Content>
  );
}
