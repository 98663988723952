import { Avatar, Badge, Dropdown, Flex, Image, Layout as AntdLayout, Menu } from 'antd';
import Logo from 'assets/images/polaris_edge.png';

import { SlQuestion } from 'react-icons/sl';

import { BellOutlined, SettingOutlined, SettingTwoTone } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from 'store/auth-context';
import UserSettings from 'components/UserSettings/UserSettings';
import { ModuleIcon } from 'components/common';
import envService, { HiddenFeatures } from 'services/env.service';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import NotificationList from 'components/notification/NotificationList/NotificationList';
import AvatarItem from 'components/common/pre-signed-url-items/AvatarItem';
import { onMessageListener } from 'firebase';
import { useGetNotificationParams, useNotificationCount } from 'hooks/notificaition';

const Header = ({ sidebar }: { sidebar?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isSettingsPage = location.pathname.includes('workspace-settings');
  const SettingsIcon = isSettingsPage ? SettingTwoTone : SettingOutlined;

  const myWorkspace = useAppSelector(selectMyWorkspace);
  // const notificationCount = useAppSelector(selectNotificationCount)

  const { profile } = useAuthContext();
  const [notificationCountParams] = useGetNotificationParams({
    userId: profile?.id,
  });

  const [notificationCount, , refreshNotificationCount] = useNotificationCount(notificationCountParams);

  onMessageListener().then(() => {
    refreshNotificationCount();
  });

  const menuHorizontal = [
    {
      key: '/',
      label: t('Project'),
      color: '#7263A8',
    },
    {
      key: '#',
      label: t('Finance'),
      color: '#B74E06',
    },
    {
      key: '#',
      label: t('Document Management'),
      color: '#405D72',
    },
    {
      key: '#',
      label: t('Procurement'),
      color: '#8401FE',
    },
    {
      key: '#',
      label: t('Construction'),
      color: '#741C47',
    },
    {
      key: '#',
      label: t('Quality'),
      color: '#3069C4',
    },
    {
      key: '#',
      label: t('Safety'),
      color: '#D37F07',
    },
    {
      key: '#',
      label: t('Environment'),
      color: '#8D9E0D',
    },
    ...(!envService.isFeatureHidden(HiddenFeatures.ASSET)
      ? [
          {
            key: `assets/${myWorkspace?.id}`,
            label: t('Asset Register'),
            color: '#DF6664',
          },
        ]
      : []),
  ];
  const renderUserSettings = () => <UserSettings />;

  const userMenu = <div className=''>{renderUserSettings()}</div>;

  return (
    <>
      <div className='header-container'>
        <AntdLayout.Header>
          <Link to={'/'}>
            <Image width={100} preview={false} src={Logo} />
          </Link>
          {sidebar !== 'project-settings' && (
            <Menu
              theme={'light'}
              mode='horizontal'
              className='header-menu'
              onClick={(e) => navigate(e.key)}
              selectedKeys={[location.pathname]}
              items={menuHorizontal.map((item) => {
                return {
                  key: item.key,
                  label: (
                    <Menu.Item className='header-menu-item' style={{ borderBottomColor: item.color }}>
                      <ModuleIcon color={item.color} width={16} height={16} />
                      <span className='ml-2' style={{ color: item.color }}>
                        {item.label}
                      </span>
                    </Menu.Item>
                  ),
                };
              })}
            />
          )}
          <Flex justify={'space-between'} align={'center'}>
            <Link className='d-flex mx-3' to={'/ticketing'}>
              <SlQuestion style={{ fontSize: 20 }} />
            </Link>
            <Link className='d-flex mx-3' to={'/workspace-settings/general'}>
              <SettingsIcon style={{ fontSize: 20 }} />
            </Link>
            {!envService.isFeatureHidden(HiddenFeatures.NOTIFICATION)
              ? profile && (
                  <NotificationList>
                    <Link className='d-flex mx-3' to={'#'}>
                      {notificationCount > 0 ? (
                        <Badge count={notificationCount} showZero>
                          <BellOutlined style={{ fontSize: 20, color: 'grey' }} />
                        </Badge>
                      ) : (
                        <BellOutlined style={{ fontSize: 20, color: 'grey' }} />
                      )}
                    </Link>
                  </NotificationList>
                )
              : null}
            <Dropdown overlay={userMenu} placement='bottomRight' className='header-avatar' trigger={['click']}>
              <Link className='d-flex' to={'#'} onClick={(e) => e.preventDefault()}>
                {profile?.profilePhoto ? (
                  <AvatarItem size={'small'} className='ml-3' src={profile?.profilePhoto} />
                ) : (
                  <Avatar size={'small'} className='ml-3'>
                    {profile?.firstName?.substring(0, 1)}
                  </Avatar>
                )}
              </Link>
            </Dropdown>
          </Flex>
        </AntdLayout.Header>
      </div>
    </>
  );
};
export default Header;
