import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DynamicCollapseTable, { DynamicCollapseTableProps, DynamicCollapseTableRefHandle } from './DynamicCollapseTable';
import { Spin } from 'antd';
import { CustomColumnType } from './DynamicTable';
import { baseIssueService } from 'services';
import { kpiData } from 'types/project';
import { BaseIssue, WorkBreakdownStructure } from 'model';
import { ISSUE_STATUSES } from 'utils/contants';
import { QueryParams, TableRowsSelected } from 'types';
import { useAppSelector } from 'store';
import { selectProjectDisciplinesByFunction, selectProjectDisciplinesByFunctionLoading } from 'store/my-projects.slice';

type DisciplineBaseIssueDataSource<T extends object> = DynamicCollapseTableProps<T>['data'];

type DisciplineBaseIssueTableProps<T extends object> = {
  header: string;
  columns: CustomColumnType<T>[];
  onRowClick?: DynamicCollapseTableProps<T>['onRowClick'];
  onActionClick: DynamicCollapseTableProps<T>['onActionClick'];
  onMapDataSource: (record: BaseIssue) => T;
  projectBaseIssueParams: QueryParams;
  loadAllListTrigger?: boolean | Record<string, string>;
  onTotalKpi: (kpi: { [x: string]: kpiData }) => void;
  handlerRowsKeyChange?: (value: React.Key[], id?: string) => void;
  selectedRowKeys?: TableRowsSelected;
  refreshDiscipline: () => void;
  module?: string;
  duplicateBaseissueId?: string;
  excludeKpiField?: string[];
  mockKpiData?: Record<string, number>;
};
export type DisciplineBaseIssueTableRefHandle = {
  refreshData: (disciplineId: string | undefined, baseIssueId: string | null) => void;
};

const DisciplineBaseIssueTable = <T extends object>(
  props: DisciplineBaseIssueTableProps<T>,
  ref: React.Ref<DisciplineBaseIssueTableRefHandle>
) => {
  const { handlerRowsKeyChange, selectedRowKeys } = props;
  const tableRef = useRef<DynamicCollapseTableRefHandle>(null);
  const [dataSource, setDataSource] = useState<DisciplineBaseIssueDataSource<T>>([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const updateQueue = useRef<{ disciplineId?: string | undefined; baseIssueId?: string | null } | null>(null);
  const disciplines = useAppSelector(selectProjectDisciplinesByFunction);
  const disciplinesLoading = useAppSelector(selectProjectDisciplinesByFunctionLoading);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      props.refreshDiscipline();
    }
  }, [props?.loadAllListTrigger]);

  useEffect(() => {
    if (dataSource.length > 0 && !isFirstLoad) initDisciplineByWirList();
  }, [props?.loadAllListTrigger, dataSource.length, isFirstLoad]);

  useImperativeHandle(ref, () => ({
    refreshData: (disciplineId: string | undefined, baseIssueId: string | null) => {
      processRefreshDiscipline(disciplineId, baseIssueId);
    },
  }));

  const initDisciplineByWirList = async () => {
    if (props?.loadAllListTrigger) {
      setIsLoadingList(true);
      const result =
        dataSource?.map(async (data) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { workspaceId, projectId, module, ...params } = props?.projectBaseIssueParams || {};
          const newParams = { ...params, 'disciplineId[]': `["${data.id}"]` };
          try {
            const response = await baseIssueService.getAllBaseIssues(workspaceId, projectId, newParams);
            data.dataSource = response?.rows?.map(props.onMapDataSource) || [];
            console.log('response?.kpiData', response?.kpiData);

            data.tags = generateTagsKpi(props?.mockKpiData || response?.kpiData);
            data.pagination = {
              issuesCount: response.count,
              issueParams: props.projectBaseIssueParams,
            };
            return data;
          } catch (error) {
            console.error(error);
          }
        }) || [];

      const resultPromise = (await Promise.all(result)) as unknown as DisciplineBaseIssueDataSource<T>;

      const resultDataSource = resultPromise.filter(Boolean);

      setDataSource(resultDataSource);
      setIsLoadingList(false);
      setIsFirstLoad(true);
    }
  };

  const generateTagsKpi = (kpiData?: Record<string, number>) => {
    if (!kpiData) return [];
    return Object.keys(kpiData)
      .filter((kpiKey) => !props?.excludeKpiField?.includes(kpiKey))
      .map((baseIssueKpiKey) => {
        return {
          code: baseIssueKpiKey,
          id: `${kpiData?.[baseIssueKpiKey]}`,
          value: kpiData?.[baseIssueKpiKey],
          isOverdue: baseIssueKpiKey === ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE,
        };
      });
  };

  const processRefreshDiscipline = (disciplineId?: string, baseIssueId?: string | null) => {
    const isNotExistDiscipline = dataSource.every((data) => data.id !== disciplineId);
    if (isNotExistDiscipline) {
      updateQueue.current = { disciplineId, baseIssueId };
      props?.refreshDiscipline?.();
      return;
    }

    if (!disciplineId) return;

    const selectedDisciplineId = dataSource?.find((data) => {
      const hasReloadId = data?.dataSource?.some?.((data) => data?.id === baseIssueId);
      if (hasReloadId) {
        return true;
      }
      return false;
    })?.id;

    if (selectedDisciplineId === disciplineId) {
      reFreshData(disciplineId);
    } else {
      reFreshData(disciplineId);
      if (selectedDisciplineId) {
        reFreshData(selectedDisciplineId);
      }
    }
  };

  const reFreshData = async (disciplineId: string) => {
    try {
      if (!disciplineId) return;
      const updatedIndex = dataSource?.findIndex((wirData) => wirData.id === disciplineId);
      let updatedParams = {};

      if (updatedIndex !== -1) {
        dataSource[updatedIndex].isLoading = true;
        setDataSource([...dataSource]);
        tableRef.current?.updateActiveKey(disciplineId);
        updatedParams = {
          orderBy: dataSource[updatedIndex]?.orderBy,
          page: dataSource[updatedIndex].pagination?.issueParams?.page,
          limit: dataSource[updatedIndex].pagination?.issueParams?.limit,
        };
      }
      const { workspaceId, projectId, ...params } = props.projectBaseIssueParams;
      const newParams = {
        ...params,
        ...updatedParams,
        'disciplineId[]': `["${disciplineId}"]`,
        module: undefined,
      };

      const response = await baseIssueService.getAllBaseIssues(workspaceId, projectId, newParams);

      if (updatedIndex !== -1) {
        dataSource[updatedIndex].dataSource = response.rows?.map(props.onMapDataSource) || [];
        dataSource[updatedIndex].tags = generateTagsKpi(response?.kpiData);

        dataSource[updatedIndex].isLoading = false;
        if (dataSource[updatedIndex].pagination) {
          dataSource[updatedIndex].pagination!.issuesCount = response.count;
        }
        setDataSource([...dataSource]);
      }
      if (response.rows?.length === 0) {
        processRefreshDiscipline('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaginationChange = (params?: { [key: string]: string }, disciplineId?: string) => {
    const updateIndex = dataSource.findIndex((wirData) => wirData.id === disciplineId);
    if (updateIndex !== -1) {
      dataSource[updateIndex].isLoading = true;
      dataSource[updateIndex].pagination = {
        issuesCount: dataSource[updateIndex].pagination?.issuesCount,
        issueParams: {
          ...params,
        },
      };

      reFreshData(disciplineId || '');
      setDataSource([...dataSource]);
    }
  };

  const generateDefaultDiscipline = (discipline: WorkBreakdownStructure) => {
    return {
      id: discipline.id,
      tags: [],
      dataSource: [],
      name: discipline?.name || '',
      pagination: {
        issuesCount: 0,
        issueParams: {
          limit: 10,
          page: 1,
        },
      },
    };
  };

  useEffect(() => {
    if (disciplines?.length > 0 && disciplinesLoading === 'succeeded') {
      const result =
        disciplines?.map((discipline: WorkBreakdownStructure) => {
          const currentDataSource = dataSource.find((data) => data.id === discipline?.id);
          if (currentDataSource) {
            return currentDataSource;
          }
          return generateDefaultDiscipline(discipline);
        }) || [];
      setDataSource([...result]);
    } else if (disciplines?.length === 0) {
      setDataSource([]);
    }
  }, [disciplines?.length, disciplinesLoading]);

  useEffect(() => {
    const totalKpi = dataSource.reduce(
      (result, initValue) => {
        if (!initValue?.tags?.length) return result;
        (initValue?.tags || []).forEach((tag) => {
          if (tag.code) {
            if (!result[tag.code as keyof kpiData]) {
              result[tag.code as keyof kpiData] = {
                value: 0,
              };
            }
            result[tag.code as keyof kpiData].value! += tag.value;
          }
        });
        return result;
      },
      {} as { [key: string]: kpiData }
    );

    props?.onTotalKpi?.(totalKpi);
  }, [dataSource]);

  const queueDataRefresh = () => {
    if (updateQueue.current?.disciplineId) {
      processRefreshDiscipline(updateQueue?.current?.disciplineId, updateQueue?.current?.baseIssueId);
      updateQueue.current = null;
    }
  };

  const updateNonEmptyDataSource = () => {
    const result = dataSource.filter((discipline) => discipline?.dataSource && discipline?.dataSource?.length > 0);
    setDataSource(result);
  };

  useEffect(() => {
    queueDataRefresh();
  }, [dataSource]);

  useEffect(() => {
    updateNonEmptyDataSource();
  }, [dataSource.length]);

  const handleSort = (id?: string) => {
    if (!id) return;
    reFreshData(id);
  };

  return (
    <Spin spinning={isLoadingList}>
      <DynamicCollapseTable
        ref={tableRef}
        columns={props?.columns}
        data={dataSource}
        header={props.header}
        onRowClick={props.onRowClick}
        module={props.module as 'Quality' | 'Safety'}
        onActionClick={props?.onActionClick}
        onPaginationChange={handlePaginationChange}
        onSort={handleSort}
        handlerRowsKeyChange={handlerRowsKeyChange}
        selectedRowKeys={selectedRowKeys}
      />
    </Spin>
  );
};

const DisciplineBaseIssueTableForwardRef = forwardRef(DisciplineBaseIssueTable) as <T extends object>(
  props: DisciplineBaseIssueTableProps<T> & { ref?: React.Ref<DisciplineBaseIssueTableRefHandle> }
) => ReturnType<typeof DynamicCollapseTable>;

export default DisciplineBaseIssueTableForwardRef;
