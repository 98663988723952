import { useDebounceFunc } from 'hooks/common/useDeboundFunction';
import React, { useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export type ReactQuillCustomProps = {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  theme?: 'snow' | 'bubble';
  placeholder?: string;
  disbileImage?: boolean;
};

const ReactQuillCustom = React.forwardRef((props: ReactQuillCustomProps, ref: React.ForwardedRef<ReactQuill>) => {
  const [value, setValue] = useState('');
  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ...(props.disbileImage ? [] : [['image']]),
        ['clean'],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    };
  }, []);

  const formats = useMemo(() => {
    return [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];
  }, []);

  const onChangeWithDebound = useDebounceFunc((value: string) => {
    props.onChange?.(value);
  }, 300);

  useEffect(() => {
    setValue((prev) => {
      if (props.value) {
        return props.value;
      }
      if (!props.value && prev) return '';
      return prev;
    });
  }, [props.value]);

  return (
    <div id='custom-react-quill-container'>
      <ReactQuill
        ref={ref}
        theme={props.theme}
        value={value}
        modules={modules}
        formats={formats}
        className={`rich-text-editor mt-1 ${props?.readOnly ? 'readonly' : ''}`}
        onChange={(value) => {
          setValue(value);
          onChangeWithDebound(value === '<p><br></p>' ? '' : value);
        }}
        bounds={'#custom-react-quill-container'}
        placeholder={props?.readOnly ? undefined : props?.placeholder}
        readOnly={props?.readOnly}
      />
    </div>
  );
});
ReactQuillCustom.displayName = 'ReactQuillCustom';
ReactQuillCustom.defaultProps = {
  theme: 'snow',
  placeholder: 'Add text here',
};

export default ReactQuillCustom;
