import { Flex } from 'antd';
import React from 'react';
import TruncatedAttachmentDocument, { TruncatedAttachmentDocumentProps } from './TruncatedAttachmentDocument';
import CustomTooltip from './CustomTooltip';
import { fileService } from 'services';

type AttachmentsCellProps = {
  attachments: TruncatedAttachmentDocumentProps[];
  openPreview?: boolean;
};
const AttachmentsCell = (props: AttachmentsCellProps) => {
  if (!props.attachments || props.attachments.length === 0) return null;

  const additionsAttachment = props?.attachments.slice(1);
  const nameList = additionsAttachment.map((attachment) => {
    const hasPdfExtension = attachment.fileName?.slice(attachment.fileName?.lastIndexOf('.') + 1) === 'pdf';
    const currentFileName = hasPdfExtension ? attachment.fileName : `${attachment?.fileName}.pdf`;
    return (
      <div
        key={attachment.id}
        className='hover:underline cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          fileService?.openFile(attachment?.filePath || '');
        }}
      >
        {currentFileName}
      </div>
    );
  });

  return (
    <Flex gap={8}>
      <TruncatedAttachmentDocument
        style={{ minWidth: 'auto', maxWidth: additionsAttachment.length > 0 ? 'calc(100% - 40px)' : '100%' }}
        key={props.attachments[0].id}
        openPreview={props.openPreview}
        {...props.attachments[0]}
      />
      {additionsAttachment.length > 0 && (
        <CustomTooltip
          open
          content={
            <Flex
              align='center'
              justify='center'
              className='h-[100%] w-[30px] text-size-12 border-dashed border border-[1px] text-primary border-[#D9D9D9]'
            >
              + {additionsAttachment.length}
            </Flex>
          }
          title={nameList}
        />
      )}
    </Flex>
  );
};

export default AttachmentsCell;
