import { CSSProperties, useEffect, useState } from 'react';
import { fileService } from 'services';
import { motion } from 'framer-motion';

export default function MotionImgItem({
  src,
  onClick,
  layoutId,
  style,
  className,
  alt,
  onError
}: {
  src: string | undefined;
  onClick?(): void;
  layoutId?: string;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  alt?: string | undefined;
  onError?: React.ReactEventHandler<HTMLImageElement> | undefined;
}) {
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  useEffect(() => {
    if (src && src.includes('.s3')) {
      fileService.getSignedUrlGet({ keyFile: src.substring(src.indexOf('polaris/') + 8) }).then((r) => {
        setFileUrl(r.preSignedURL);
      });
    } else {
      setFileUrl(src);
    }
  }, [src]);

  return <motion.img onClick={onClick} layoutId={layoutId} alt={alt} src={fileUrl} style={style} className={className} onError={ onError } />;
}
