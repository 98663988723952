import { Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, SendOutlined, UserOutlined } from '@ant-design/icons';
import marIconInProgress from 'assets/images/icons/mar-in-progress-icon-blue.png';
import ProgressBlueIcon from 'assets/images/progress-blue.png';

type CustomStepsBarIconProps = {
  CompanyName: string;
  status: 'wait' | 'process' | 'finish' | 'error' | 'revised' | 'review-in-progress' | 'submitted';
};

export default function CustomStepsBarIcon(props: CustomStepsBarIconProps) {
  const { CompanyName, status } = props;
  return (
    <>
      <div className={`icon-progress custom-icon-${status}`}>
        <div className={`custom-icon`}>
          {status == 'submitted' && <SendOutlined size={21} className={'icon-progress-bar'} />}
          {status == 'finish' && <CheckCircleFilled size={21} className={'icon-progress-bar'} />}
          {status == 'process' && <img src={marIconInProgress} alt={status} />}
          {status == 'wait' && <UserOutlined size={21} className={'icon-progress-bar'} />}
          {status == 'error' && <CloseCircleFilled size={21} className={'icon-progress-bar'} />}
          {status == 'revised' && <CloseCircleFilled size={21} className={'icon-progress-bar'} />}
          {status == 'review-in-progress' && <img src={ProgressBlueIcon} alt={status} />}
        </div>
        <Typography.Text className={'custom-icon-text'}>{CompanyName}</Typography.Text>
      </div>
    </>
  );
}
