import React from 'react';
import { ReactComponent as ExternalLink } from 'assets/images/external-link.svg';
import { ReactComponent as MoreOutlined } from 'assets/images/more-outlined.svg';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type NotificationProps = {
  onNavigateNotification: () => void;
};

const NotificationListHeader: React.FC<NotificationProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='notification-list-header flex items-center justify-between'>
      <Typography.Title>{t('Notifications')}</Typography.Title>
      <div className='flex items-center'>
        <ExternalLink
          className='cursor-pointer'
          onClick={() => {
            navigate('/notification');
            props.onNavigateNotification();
          }}
        />
      </div>
    </div>
  );
};

export default NotificationListHeader;
