import { useMemo } from 'react';
import { QueryParams } from 'types';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchProjectZones } from 'store/my-projects.slice';
import { RootState } from 'store';
import Zone from 'model/Zone';

const selectProjectZones = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.zones;
const selectProjectZonesLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.zonesLoading;

export const useProjectZones = (query: any) => {
  const { projectId } = query;
  return useFetchByParams<Zone[]>({
    action: fetchProjectZones,
    dataSelector: (state: RootState) =>  
      selectProjectZones(state, projectId),
    loadingSelector: (state: RootState) =>
      selectProjectZonesLoading(state, projectId),
    params: query,
  });
};

export const useProjectZonesParams = (options: {
  workspaceId: string;
  projectId: string;
} & QueryParams) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options, limit: 'unlimited' };

    return [queryParams];
  }, [workspaceId, projectId]);
};