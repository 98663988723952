import { SelectPlanData } from 'components/common/SelectPlan';
import { SelectTimePeriodProps } from 'components/common/SelectTimePeriod';
import dayjs from 'dayjs';
import { IssueStatus } from 'model';

export type CorrectiveActionFormValues = {
  companyName: number | null;
  personInCharge: number | null;
  correctiveAction: number | null;
  deadline: dayjs.Dayjs | null;
  correctiveActionDescription: string;
  IssueStatus: IssueStatus;
  isAdded?: boolean;
};

export type IncidentFormValues = CorrectiveActionFormValues & {
  name: string;
  description: string;
  category: string;
  zoning: number | null;
  stage: number | null;
  severity: number | null;
  causeActionDistribution: string[];
  distribution: string[];
  correctiveActionStatus?: IssueStatus | null;
  pictureOfIssue: {
    id: string;
    name: string;
    filePath: string;
    picture: string;
    username: string;
    createdAt: string;
  }[];
  causes?: {
    cause?: number | null;
    causeDescription?: string;
  }[];
  actions?: CorrectiveActionFormValues[];
  comments: string;
  plan: SelectPlanData;
  witness?: {
    companyId?: string;
    userId?: string;
  }[];
  informationDate: dayjs.Dayjs | null;
  informationTime: dayjs.Dayjs | null;
  injuredCompany: string;
  injuredName: string;
  injuredPart: string[] | number[];
  injuredMedicalTreatment: string | number;
  injuredLostTimeIncident: SelectTimePeriodProps['value'] | null;
  injuredPoliceInformed: string | number;
};

export enum EIncidentFeatureTabs {
  Information = 'information',
  CausesActions = 'causesActions',
  ActionStatus = 'actionStatus',
  Injury = 'injury',
}

export enum EActionStatusReadonly {
  CorrectiveAction = 'CorrectiveAction',
  Comment = 'Comment',
  PictureOfResolved = 'PictureOfResolved',
}

export enum EIncidentEntityName {
  Hazard = 'Hazard',
  SafetyViolation = 'Safety Violations',
  NearMisses = 'Near Misses',
  Accidents = 'Accidents',
  PermitToWork = 'Permit To Work',
}
