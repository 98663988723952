import { lazy } from 'react';
import { AppRouteType } from 'types';

const ProjectChangeOrders = lazy(() => import('modules/construction/pages/ProjectChangeOrders'));
const SiteInstructions = lazy(() => import('pages/SiteInstructions/SiteInstruction'));
export const constructionRoutes: AppRouteType[] = [
  {
    name: 'Change Orders',
    auth: true,
    parentPath: '/project',
    path: 'projects/:projectId/construction/change-orders',
    component: ProjectChangeOrders,
    isPublic: true,
    showMenu: true,
    showHeader: true,
    sidebar: 'project-settings',
  } as AppRouteType,

  {
    name: 'Site Instructions',
    auth: true,
    parentPath: '/project',
    path: 'projects/:projectId/construction/site-instructions',
    component: SiteInstructions,
    isPublic: true,
    showMenu: true,
    showHeader: true,
    sidebar: 'project-settings',
  } as AppRouteType,
];
