import { BlockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useIssueStatuses, useIssueStatusesParams } from 'hooks';
import { Attachment, IssueAttachment } from 'model';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ModalState } from 'types';
import PinInspection from './PinInspection';
import { useAppSelector } from 'store';
import { selectProjectZones } from 'store/my-projects.slice';

export type SelectPlanData = {
  plan: IssueAttachment | null;
  zoneId: string | null;
  attachment: Attachment | null;
};

export type SelectPlanProps = {
  onChangePlan: (planData: SelectPlanData | null) => void;
  name: string;
  plan?: IssueAttachment | null;
};

export type SelectPlanRefHandle = {
  resetPlan: () => void;
  setOpenPin: (status: boolean) => void;
};

const SelectPlan = forwardRef<SelectPlanRefHandle, SelectPlanProps>((props, ref) => {
  const { t } = useTranslation();
  const [isOpenPin, setIsOpenPin] = useState<ModalState | number>(null);
  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const location = useLocation();
  const module = location.pathname.includes('safety') ? 'Safety' : 'Quality';
  const [plan, setPlan] = useState<IssueAttachment | null>(props?.plan || null);
  const [planImage, setPlanImage] = useState<Attachment | null>(null);
  const zones = useAppSelector(selectProjectZones)

  const [internalZoneId, setInternalZoneId] = useState<string | null>('');

  const handleChangeLocationPlan = (
    zoneId: string | null,
    planData: IssueAttachment | null,
    planImageData: Attachment | null
  ) => {
    setPlan(planData);
    setPlanImage(planImageData);
    setInternalZoneId(zoneId);
    props.onChangePlan({ plan: planData, zoneId: zoneId, attachment: planData?.Attachment || planImageData });
  };

  useImperativeHandle(ref, () => ({
    resetPlan: () => {
      setPlan(null);
      setPlanImage(null);
      props.onChangePlan({ plan: null, zoneId: internalZoneId, attachment: null });
    },
    setOpenPin: (isOpen: boolean) => {
      setIsOpenPin(isOpen);
    },
  }));

  useEffect(() => {
    if (zones?.length) {
      setInternalZoneId(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);

  // useEffect(() => {
  //   props.onChangePlan({ plan, zoneId: internalZoneId, attachment: plan?.Attachment || planImage });
  // }, [plan, planImage, internalZoneId]);

  return (
    <div>
      <Button
        className='primary-dashed-btn'
        icon={<BlockOutlined />}
        type='dashed'
        onClick={() => {
          setIsOpenPin(true);
        }}
        style={{ width: '100%' }}
      >
        {plan ? t('Change plan') : t('Select plan')}
      </Button>

      {isOpenPin !== null && (
        <PinInspection
          issueStatuses={issueStatuses}
          module={module}
          isModalOpen={isOpenPin}
          setIsModalOpen={setIsOpenPin}
          onSubmit={handleChangeLocationPlan}
          zoneId={internalZoneId || ''}
          plan={plan}
          inspectionName={props.name}
          planImage={planImage}
          zones={zones}
        />
      )}
    </div>
  );
});

SelectPlan.displayName = 'SelectPlan';
export default SelectPlan;
