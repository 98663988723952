import { Button, Col, Flex, Form, FormInstance, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchSelect } from 'components/common';
import { useOtherProjects, useOtherProjectsParams, useProjectCompanies, useProjectCompaniesParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { BaseIssue, User } from 'model';
import { INVITATION_ROLE } from 'utils/contants';
import { MOMFormValues } from 'components/project-minutes-of-meeting/ProjectMinutesOfMeetingCreateOrUpdate';

export type UserDistributionValue = {
  companyId?: string | null;
  inspectorIds?: string[];
};

type UserDistributionProps = {
  form: FormInstance;
  projectId: string;
  users: User[];
  isOpen: boolean;
  isReadOnly: boolean;
  companyOriginator?: string;
  companyRecipient?: string;
  isRequired?: boolean;
  maxCompanies?: number;
  title?: string;
  showTopButton?: boolean;
  formName?: string;
  nameLabel?: string;
  companyLabel?: string;
  selectedBaseIssue?: BaseIssue | null;
};

export default function ProjecetMinutesOfMeetingUserDistribution(props: UserDistributionProps) {
  const { t } = useTranslation();
  const {
    form,
    projectId,
    companyOriginator,
    companyRecipient,
    users,
    isOpen,
    isReadOnly,
    maxCompanies,
    title,
    isRequired,
  } = props;

  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);
  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const watchedValue = Form.useWatch([]) as MOMFormValues;

  const [companies] = useProjectCompanies(companyParams);
  const [username, setUsername] = useState<string>();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedInspectorIds, setSelectedInspectorIds] = useState<string[]>([]);
  const [isRemovedView, setIsRemoved] = useState<boolean>(false);
  const filteredUsers = users.filter((user) => {
    const existingInspectorIds = watchedValue?.attendees?.flatMap((attendee) => attendee?.inspectorIds ?? []);
    return !existingInspectorIds?.includes(user.id);
  });
  const onChangeCompany = (companyId: string, index: number) => {
    setIsRemoved(false);
    if (!companyId) {
      setSelectedCompany('');
      form.setFieldValue([props?.formName || 'invitations', index, 'companyId'], null);
    }
    setSelectedCompany(companyId);
    const autoFillUsers = filteredUsers?.filter((user) => !companyId || user.WorkspaceUser?.Company?.id === companyId);
    if (companyId && autoFillUsers[0]?.id) {
      form.setFieldValue([props?.formName || 'invitations', index, 'inspectorIds'], autoFillUsers[0]?.id);
      handleSelectInspectorId(autoFillUsers[0]?.id, index);
    } else {
      form.setFieldValue([props?.formName || 'invitations', index, 'inspectorIds'], null);
    }
    handleSelectCompany(companyId, index);

    form.validateFields([
      [props?.formName || 'invitations', index, 'companyId'],
      [props?.formName || 'invitations', index, 'inspectorIds'],
    ]);
  };

  const onChangeCompanyRep = (inspectorId: string[], index: number) => {
    setIsRemoved(false);
    if (!inspectorId) {
      form.setFieldValue([props?.formName || 'invitations', index, 'inspectorIds'], null);
    } else {
      setUsername(inspectorId[0]);
      handleSelectInspectorId(inspectorId[0], index);
      const selectedUser = filteredUsers?.find((user) => user.id === inspectorId[0]);
      const existingCompany = companies?.findIndex(
        (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
      );
      if (companies[existingCompany]?.id) setSelectedCompany(companies[existingCompany]?.id);
      handleSelectCompany(companies[existingCompany]?.id, index);
      form.setFieldValue(
        [props?.formName || 'invitations', index, 'companyId'],
        existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null
      );
    }
    form.validateFields([
      [props?.formName || 'invitations', index, 'companyId'],
      [props?.formName || 'invitations', index, 'inspectorIds'],
    ]);
  };

  const handleSelectCompany = (companyId: string, index: number) => {
    setIsRemoved(false);
    const newSelectedItems = [...selectedCompanies];
    newSelectedItems[index] = companyId;
    setSelectedCompanies(newSelectedItems);
  };
  const handleSelectInspectorId = (inspectorId: string, index: number) => {
    setIsRemoved(false);
    const newSelectedItems = [...selectedCompanies];
    newSelectedItems[index] = inspectorId;
    setSelectedInspectorIds(newSelectedItems);
  };

  const handleRemove = (index: number) => {
    const newSelectedCompany = [...selectedCompanies];
    const newSelectedInspectorId = [...selectedInspectorIds];
    newSelectedCompany.splice(index, 1);
    newSelectedInspectorId.splice(index, 1);
    setSelectedInspectorIds(newSelectedInspectorId);
    setSelectedCompanies(newSelectedCompany);
    setSelectedCompany(null);
    setIsRemoved(true);
  };
  const handleChangeFilterUser = async (index: number) => {
    setIsRemoved(false);
    const values = await form.getFieldValue([props?.formName || 'invitations', index, 'companyId']);
    if (values) {
      setSelectedCompany(values);
    } else {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCompanies([]);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      if (props.selectedBaseIssue && props.selectedBaseIssue.BaseIssueWatchers) {
        const initialValues = props.selectedBaseIssue.BaseIssueWatchers.filter(
          (invitation) => invitation.role === INVITATION_ROLE.DISTRIBUTION
        ).map((invitation) => ({
          companyId: invitation.companyId,
          inspectorId: invitation.watcherId,
        }));

        if (initialValues.length > 0) {
          setSelectedCompanies(initialValues.map(({ companyId }) => companyId));
          setSelectedInspectorIds(initialValues.map(({ inspectorId }) => inspectorId));
        } else {
          setSelectedCompanies([]);
          setSelectedInspectorIds([]);
        }
      } else {
        setSelectedCompanies([]);
        setSelectedInspectorIds([]);
      }
    } else {
      setSelectedCompanies([]);
      setSelectedInspectorIds([]);
    }
  }, [isOpen, props.selectedBaseIssue]);
  return (
    <>
      <Form form={form}>
        <Form.List name={props?.formName || 'invitations'} initialValue={[{ companyId: null, inspectorIds: null }]}>
          {(fields, { add, remove }) => (
            <>
              <Flex vertical>
                <Flex align='center' justify='space-between'>
                  <Typography.Title level={3} className='form-title'>
                    {t(title ?? 'Distribution')}
                  </Typography.Title>
                  {!isReadOnly && props?.showTopButton && (
                    <Button
                      type='dashed'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        add();
                      }}
                      disabled={maxCompanies !== undefined && fields.length >= maxCompanies}
                      className='text-size-12'
                    >
                      {t('Add Company')}
                    </Button>
                  )}
                </Flex>
                <Flex justify='space-between'>
                  <Flex vertical style={{ width: '100%' }}>
                    <Row style={{ marginBottom: '8px' }}>
                      <Col span={12} className='pr-2'>
                        <Typography.Text>
                          {t(props?.companyLabel || 'Company Name')}
                          <span className={'required-fields-mdi'}></span>
                        </Typography.Text>
                      </Col>
                      <Col span={12} className='pl-2'>
                        <Typography.Text>
                          {t(props?.nameLabel || 'Emails / Names')}
                          <span className={'required-fields-mdi'}></span>
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      {fields.map(({ name }, index) => (
                        <>
                          <Col span={12} style={{ minHeight: '32px' }}>
                            <Form.Item name={[index, 'companyId']} style={{ marginBottom: '0px' }}>
                              <SearchSelect
                                placeholder={t('Select company')}
                                value={selectedCompany}
                                options={(companies ?? []).map((company) => ({
                                  value: company.id,
                                  label: company.name,
                                  disabled:
                                    (company?.WorkspaceUsers ?? []).length <= 0 ||
                                    company.id === companyOriginator ||
                                    company.id === companyRecipient ||
                                    selectedCompanies.includes(company.id),
                                }))}
                                onChange={(value) => onChangeCompany(value, index)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <div
                              style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', height: '100%' }}
                            >
                              <Form.Item
                                name={[index, 'inspectorIds']}
                                style={{ width: '100%', marginBottom: '0px' }}
                                rules={[{ required: isRequired, message: t('Please select at least one user.') }]}
                              >
                                <SearchSelect
                                  mode='multiple'
                                  placeholder={t('Select user')}
                                  value={username}
                                  maxTagCount={'responsive'}
                                  onFocus={() => {
                                    handleChangeFilterUser(index);
                                  }}
                                  options={filteredUsers
                                    .filter((user) =>
                                      !selectedCompany
                                        ? isRemovedView
                                          ? true
                                          : !selectedCompanies.includes(user.WorkspaceUser?.Company?.id || '') &&
                                            user.WorkspaceUser?.Company?.id !== companyOriginator &&
                                            user.WorkspaceUser?.Company?.id !== companyRecipient
                                        : user.WorkspaceUser?.Company?.id === selectedCompany
                                    )
                                    .map((user) => {
                                      return {
                                        value: user.id,
                                        label: user.fullName,
                                      };
                                    })}
                                  onChange={(value) => onChangeCompanyRep(value, index)}
                                />
                              </Form.Item>
                              <DeleteOutlined
                                style={{
                                  color: 'grey',
                                  fontSize: 20,
                                  alignItems: 'center',
                                }}
                                onClick={() => {
                                  {
                                    handleRemove(index);
                                    remove(name);
                                  }
                                }}
                              />
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                    {!isReadOnly && !props?.showTopButton && (
                      <Flex justify={'flex-end'}>
                        <Button
                          type='dashed'
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add();
                          }}
                          disabled={maxCompanies !== undefined && fields.length >= maxCompanies}
                          className='text-size-12 mt-4'
                        >
                          {t('Add Company')}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
}
