import { MaterialCategory } from 'model';
import request from 'requesters/core.request';
import { PaginationData, QueryParams } from 'types';
const materialCategoryService = {
    getMaterialCategories: async (
        workspaceId: string,
        projectId: string,
        query?: QueryParams
    ) => {
        return request.get<PaginationData<MaterialCategory[]>>(
            `/workspaces/${workspaceId}/projects/${projectId}/material-categories`,
            {
                params: query,
            }
        );
    },
};
export default materialCategoryService;