import { Loading } from 'types';
import StackBarChart from './features/StackBarChart';
import { useRef } from 'react';
type IssueStatusByCompanyData = {
  companyId: string;
  companyName: string;
  DR: {
    count: number;
  };
  OP: { count: number };
  CL: {
    count: number;
  };
  RFI: {
    count: number;
  };
};
type GraphStatusByCompanyProps = {
  issueStatusByCompany: IssueStatusByCompanyData[];
  loading?: Loading;
  isSafetyPage: boolean;
};
export const GraphStatusByCompany = (props: GraphStatusByCompanyProps) => {
  const { issueStatusByCompany, loading, isSafetyPage } = props;

  type ChartDataType = {
    companyId: string;
    companyName: string;
    DR: number;
    OP: number;
    RFI: number;
    CL: number;
  };
  let chartData: ChartDataType[] = [];
  if (issueStatusByCompany) {
    chartData = issueStatusByCompany
      .map((item) => {
        const totalCount = item.DR.count + item.OP.count + item.RFI.count + item.CL.count;
        if (totalCount !== 0) {
          return {
            companyId: item.companyId,
            companyName: item.companyName,
            DR: item.DR.count,
            OP: item.OP.count,
            RFI: item.RFI.count,
            CL: item.CL.count,
          };
        } else {
          return null;
        }
      })
      .filter((item): item is ChartDataType => item !== null);
  }
  const svgLargeRef = useRef<HTMLDivElement>(null);
  const boxContentHeight =
    issueStatusByCompany?.length > 10 ? 'h-30rem' : issueStatusByCompany?.length > 8 ? 'h-25rem' : 'h-72';
  return (
    <div className='stack-bar-chart'>
      <div ref={svgLargeRef} className={`w-12/12 ${boxContentHeight}`}>
        <StackBarChart
          chartData={chartData}
          svgWrapperRef={svgLargeRef}
          loading={loading}
          isSafetyPage={isSafetyPage}
        />
      </div>
    </div>
  );
};
