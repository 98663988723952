import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Flex, ModalProps, message } from 'antd';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationProps = {
  isModalOpen: boolean;
  title: string;
  description: string;
  onDeleteAll: (string?: string) => void;
  onDeleteUnpublished: (string?: string) => void;
  onCancel: () => void;
  containsDraft: boolean;
  cantUndo?: boolean;
} & ModalProps;
export default function DesignDeleteConfirmation(props: DeleteConfirmationProps) {
  const { isModalOpen, title, description, onCancel, onDeleteUnpublished, containsDraft, onDeleteAll } = props;
  const { t } = useTranslation();

  const handleOk = async (deleteAll: boolean) => {
    try {
      if (deleteAll) {
        onDeleteAll();
      } else {
        onDeleteUnpublished();
      }
      message.success(t('Delete success!'));
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal className='delete-confirmation' open={isModalOpen} closable={false} footer={null}>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <p className='mb-4 title'>
          <ExclamationCircleOutlined style={{ color: 'red' }} /> {title}
        </p>
      </div>
      <p className='mb-4 description'>{description}</p>

      <Flex justify='space-around' style={{ marginBottom: '2em', justifyContent: 'center' }}>
        <div style={{ color: '#aaa' }}>{t('You cannot undo this action')}</div>
      </Flex>

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div>
          <Button key='back' onClick={handleCancel}>
            {t('Cancel')}
          </Button>

          <Button
            danger
            key='submit'
            onClick={() => {
              handleOk(true);
            }}
          >
            {containsDraft ? t('Delete All') : t('Delete')}
          </Button>
          {containsDraft && (
            <Button
              danger
              type='primary'
              key='submit'
              onClick={() => {
                handleOk(false);
              }}
            >
              {t('Delete unpublished only')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
