export const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
  return data?.map((value) => ({
    value: value.id,
    label: value[labelKey as keyof typeof value],
  }));
};

export const generateId = (prefix = 'id') => {
  const uniqueId = `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
  return uniqueId;
};
