import AppRoutes from 'routes';
import './i18n/config';
import { AnimatePresence } from 'framer-motion';
import { ConfigProvider } from 'antd';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { requestUserPermission, susbribeToPushNotification } from 'firebase';
import useAuthContext from 'store/auth-context';
import envService, { HiddenFeatures } from 'services/env.service';

const mapPrimaryColors = new Map([
  ['safety', '#D37F07'],
  ['quality', '#3069C4'],
  ['design', '#405D72'],
  ['directory', '#3069C4'],
  ['ticketing', '#3069C4'],
  ['assets', '#DF6664'],
  ['default', '#1677ff'],
  ['toolbox', '#405D72'],
  ['document-management', '#405D72'],
  ['finance', '#B74E06'],
  ['construction', '#9C727E'],
]);

const mapPrimaryBgColors = new Map([
  ['safety', '#FBF2E6'],
  ['quality', '#EAF0F9'],
  ['design', '#F0F0F0'],
  ['directory', '#EAF0F9'],
  ['ticketing', '#EAF0F9'],
  ['assets', '#fcf0ef'],
  ['default', '#F8FCFF'],
  ['toolbox', '#F0F0F0'],
  ['document-management', '#F0F0F0'],
  ['construction', '#F1E8ED'],
]);

function App() {
  const location = useLocation();
  const themeCustomize = getCustomizeTheme(location.pathname);
  const colorPrimary = mapPrimaryColors.get(themeCustomize);
  const colorPrimaryBg = mapPrimaryBgColors.get(themeCustomize);
  const headerBg = mapPrimaryBgColors.get(themeCustomize);
  const { profile } = useAuthContext();

  useEffect(() => {
    if (!envService.isFeatureHidden(HiddenFeatures.NOTIFICATION)) {
      requestUserPermission();
    }
  }, []);

  useEffect(() => {
    if (profile) {
      if (!envService.isFeatureHidden(HiddenFeatures.NOTIFICATION)) {
        susbribeToPushNotification();
      }
    }
  }, [profile]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          colorTextBase: '#001d3d',
          colorBgBase: '#fff',
          colorBgLayout: '#fff',
          colorBgContainer: '#fff',
          fontFamily: 'Montserrat',
        },
        components: {
          Segmented: {
            fontFamily: 'Montserrat',
          },
          Table: {
            fontFamily: 'Montserrat',
            headerBg: headerBg,
            headerSortActiveBg: headerBg,
            headerSortHoverBg: headerBg,
            headerFilterHoverBg: headerBg,
            colorTextHeading: '#001D3D',
            colorPrimary: colorPrimary,
          },
          Typography: {
            fontFamilyCode: 'Montserrat',
            fontSizeHeading1: 28,
            fontSizeHeading2: 20,
            fontSizeHeading3: 16,
            lineHeightHeading1: 1.642,
            lineHeightHeading2: 1.9,
            lineHeightHeading3: 1.125,

            titleMarginBottom: '0',
            titleMarginTop: '0',

            colorTextDescription: 'rgba(0, 0, 0, 0.45)',
          },
          Card: {
            fontFamily: 'Montserrat',
            borderRadiusLG: 6,
          },
          Input: {
            fontFamily: 'Montserrat',
            colorBgContainer: '#F8FCFF',
            colorFillAlter: '#F8FCFF',
          },
          Select: {
            fontFamily: 'Montserrat',
            selectorBg: '#F8FCFF',
            // colorTextDisabled: '#001d3d'
          },
          DatePicker: {
            fontFamily: 'Montserrat',
            colorBgContainer: '#F8FCFF',
          },
          Button: {
            fontFamily: 'Montserrat',
            defaultGhostColor: colorPrimary,
            defaultGhostBorderColor: colorPrimary,
          },
          Form: {
            fontFamily: 'Montserrat',
            colorTextDescription: 'rgba(0, 0, 0, 0.45)',
          },
          Menu: {
            fontFamily: 'Montserrat',
            subMenuItemBg: '#FFFFFF',
            itemMarginInline: 0,
            itemSelectedColor: '#3069C4',
            itemSelectedBg: '#e6f4ff',
            itemActiveBg: '#e6f4ff',
            // itemHoverBg: colorPrimaryBg
          },
          Tree: {
            fontFamily: 'Montserrat',
            directoryNodeSelectedBg: colorPrimary,
            directoryNodeSelectedColor: colorPrimaryBg,
            nodeHoverBg: colorPrimaryBg,
            nodeSelectedBg: colorPrimaryBg,
          },
        },
      }}
    >
      <div className='App'>
        <AnimatePresence mode='wait'>
          <AppRoutes />
        </AnimatePresence>
      </div>
    </ConfigProvider>
  );
}

const getCustomizeTheme = (path: string): string => {
  if (path.includes('safety')) {
    return 'safety';
  }
  if (path.includes('quality')) {
    return 'quality';
  }
  if (path.includes('design')) {
    return 'design';
  }
  if (path.includes('directory')) {
    return 'directory';
  }
  if (path.includes('ticketing')) {
    return 'ticketing';
  }
  if (path.includes('assets')) {
    return 'assets';
  }
  if (path.includes('toolbox')) {
    return 'toolbox';
  }
  if (path.includes('document-management')) {
    return 'document-management';
  }
  if (path.includes('construction')) {
    return 'construction';
  }
  if (path.includes('finance')) {
    return 'finance';
  }
  return 'default';
};

export default App;
