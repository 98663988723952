import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography, TableColumnsType, Form, Button, FormInstance, message } from 'antd';
import { t } from 'i18next';
import { mockProjectMinutesOfMeetingData } from 'mock-data/project-data';
import React, { useEffect, useState } from 'react';
import DragTable from 'components/common/DragTable';
import ProjectMinutesOfMeetingContentDetailsDescription from './ProjectMinutesOfMeetingContentDetailsDescription';
import SelectStatus from 'components/common/SelectStatus';
import ProjectMinutesOfMeetingContentDetailsCompany from './ProjectMinutesOfMeetingContentDetailsCompany';
import { AttachmentPhoto } from 'components/common/AttachmentList';
import { AttachmentBaseIssue } from 'components/common/DocumentAdder';
import { useDebounceFunc } from 'hooks/common/useDeboundFunction';
import dayjs, { Dayjs } from 'dayjs';
import { BaseIssue, Company, User } from 'model';
import BaseIssueMomItem from 'model/BaseIssueMomItem';
import MomStatus from 'model/MomStatus';
import { ISSUE_STATUSES, MOM_STATUSES } from 'utils/contants';
import momItemService from 'services/momItem.service';

type ProjectMinutesOfMeetingContentDetailsProps = {
  categories: (typeof mockProjectMinutesOfMeetingData)['categories'];
  title: string;
  showReadonly?: boolean;
  form: FormInstance;
  companies: Company[];
  users: User[];
  momItem: BaseIssueMomItem[];
  momItemStatuses: MomStatus[];
  isFormOpen: boolean;
  selectedBaseIssue: BaseIssue | null;
  onDeleteMomItem?: (value: string) => void;
  isOriginator: boolean;
  workspaceId: string;
  projectId: string;
};

export type MOMItemDataSource = {
  id: string;
  subject?: string;
  description?: string;
  documents?: AttachmentBaseIssue[];
  pictures?: AttachmentPhoto[];
  responsibleCompany?: string;
  personInCharge?: string;
  status?: string;
  dueDate?: string | Date | Dayjs | null;
};

const ProjectMinutesOfMeetingContentDetails: React.FC<ProjectMinutesOfMeetingContentDetailsProps> = (props) => {
  const [dataSource, setDataSource] = useState<MOMItemDataSource[]>([]);
  const isStatusIssued = props.selectedBaseIssue?.IssueStatus?.code === MOM_STATUSES.ISSUED;
  const [, setRerender] = useState(false);
  const columns: TableColumnsType<MOMItemDataSource> = [
    {
      width: 50,
      align: 'center',
      title: 'Item',
      dataIndex: 'id',
      render: (id) => {
        const index = dataSource.findIndex((data) => data.id === id);
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'id',
      render: (id, record) => {
        const index = dataSource.findIndex((data) => data.id === id);
        return (
          <ProjectMinutesOfMeetingContentDetailsDescription
            index={index}
            pictures={record.pictures || []}
            description={record.description}
            documents={record.documents || []}
            subject={record.subject}
            onFieldChange={(key, value): void => {
              handleChangeDataWithDebound(key, value, id);
            }}
            isReadonly={props?.showReadonly}
          />
        );
      },
    },
    {
      width: 200,

      className: 'company-cell',
      title: 'Company/Person in Charge/ Due Date',
      onCell: () => {
        return {
          style: {
            verticalAlign: 'top',
            maxWidth: '200px',
          },
        };
      },
      dataIndex: 'id',
      render: (id, record) => {
        const index = dataSource.findIndex((data) => data.id === id);
        return (
          <ProjectMinutesOfMeetingContentDetailsCompany
            dueDate={record.dueDate || ''}
            personInCharge={record.personInCharge || ''}
            responsibleCompany={record.responsibleCompany || ''}
            onFieldChange={(key, value) => {
              handleChangeData(key, value, id);
            }}
            form={props.form}
            index={index}
            isReadonly={props?.showReadonly}
            companies={props.companies}
            users={props.users}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'id',
      onCell: () => {
        return {
          style: {
            width: '180px',
          },
        };
      },
      render: (id) => {
        const dataValue = dataSource?.find((data) => data.id === id);
        return (
          <Form.Item>
            <SelectStatus
              isReadonly={props.showReadonly && !(props.isOriginator && isStatusIssued)}
              value={dataValue?.status}
              onChange={(value) => {
                handleChangeData('status', value, id);
              }}
              onSelectedChange={(value) => {
                if (props.isOriginator && isStatusIssued) {
                  handleUpdateMomItemsStatus('status', value, id);
                }
              }}
              options={props?.momItemStatuses?.map((status) => ({
                value: status.id,
                label: status.name,
                code: status.code,
              }))}
            />
          </Form.Item>
        );
      },
    },
    {
      dataIndex: 'id',
      width: 50,
      render: (id: string) => {
        return (
          !props?.showReadonly && (
            <DeleteOutlined
              style={{ color: '#F5222D' }}
              onClick={() => {
                const isTempId = id.startsWith('temp-');

                if (!isTempId) {
                  props.onDeleteMomItem?.(id);
                }

                const items = props.form.getFieldValue('items') as MOMItemDataSource[];
                props.form.setFieldValue(
                  'items',
                  items?.filter((item) => item.id !== id)
                );
                setRerender((prev) => !prev);
              }}
            />
          )
        );
      },
    },
  ];

  const handleChangeDataWithDebound = useDebounceFunc((key: string, value: unknown, id: string) => {
    const index = dataSource.findIndex((data) => data.id === id);
    (dataSource[index] as Record<string, unknown>)[key] = value;
    setDataSource([...dataSource]);
  }, 500);

  const handleChangeData = (key: string, value: unknown, id: string) => {
    const index = dataSource.findIndex((data) => data.id === id);
    (dataSource[index] as Record<string, unknown>)[key] = value;
    props.form.setFieldValue('items', dataSource);
    setDataSource([...dataSource]);
  };

  const handleUpdateMomItemsStatus = async (key: string, value: unknown, id: string) => {
    try {
      const index = dataSource.findIndex((data) => data.id === id);
      (dataSource[index] as Record<string, unknown>)[key] = value;
      // props.form.setFieldValue('items', dataSource);
      // setDataSource([...dataSource]);
      if (props.selectedBaseIssue && props.selectedBaseIssue?.MinutesOfMeeting?.id) {
        const data: unknown[] = [];
        dataSource?.forEach((item, index) => {
          data.push({
            id: props.momItem[index]?.id || undefined,
            companyId: item.responsibleCompany,
            assigneeId: item.personInCharge,
            dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
            name: item.subject,
            momId: props.selectedBaseIssue?.MinutesOfMeeting?.id,
            description: item.description,
            statusId: item.status,
            momItemAttachmentInfo: [
              ...(item.documents || []),
              ...(item.pictures?.map((picture) => ({
                filePath: picture?.filePath,
                name: picture?.name,
                attachmentTypeId: picture.attachmentTypeId,
              })) || []),
            ],
          });
        });
        await momItemService.updateMomItemsById(
          props.workspaceId,
          props.projectId,
          props.selectedBaseIssue?.id,
          props.selectedBaseIssue?.MinutesOfMeeting?.id,
          data
        );
        message.success(t('Update success!'));
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
    }
  };

  useEffect(() => {
    if (props.form.getFieldValue('items')) {
      setDataSource(props.form.getFieldValue('items'));
    }
  }, [props.form.getFieldValue('items')]);

  useEffect(() => {
    if (!props.isFormOpen) {
      setDataSource([]);
    }
  }, [props.isFormOpen]);

  return (
    <React.Fragment>
      <Typography.Title level={3} className='form-title'>
        {t(props.title)}
      </Typography.Title>
      <DragTable
        isReadonly={props.showReadonly}
        columns={columns}
        dataSource={dataSource}
        setDataSource={setDataSource}
      />
      {!props.showReadonly && (
        <Button
          type='dashed'
          onClick={() => {
            const openStatus = props?.momItemStatuses.find((status) => status.code === ISSUE_STATUSES.OPEN);
            setDataSource([...dataSource, { id: `temp-${new Date().toISOString()}`, status: openStatus?.id }]);
          }}
          icon={<PlusOutlined />}
          className='text-size-12 d-block ml-auto mt-4'
        >
          {t('Add Item')}
        </Button>
      )}
    </React.Fragment>
  );
};
ProjectMinutesOfMeetingContentDetails.defaultProps = {
  showReadonly: false,
};
export default ProjectMinutesOfMeetingContentDetails;
