import { useEffect } from 'react';
import { Button, Col, Form, Input, ModalProps, Row, Select, Typography } from 'antd';
import { Modal } from 'components';
import { Company } from 'model';
import { useTranslation } from 'react-i18next';

type FormValues = {
  companyId: string;
  companyRole: string;
  companyType?: string;
};

type EditCompanyInProjectProps = {
  isModalOpen: boolean;
  company: Company;
  onSubmit: (values: FormValues[]) => void;
  isSubmit?: boolean;
  setIsModalOpen: (value: boolean) => void;
  workspaceId: string;
  projectId: string;
} & ModalProps;

export default function EditCompanyInProject(props: EditCompanyInProjectProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, company, isSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => {
    if (isModalOpen) {
      form.setFieldsValue({
        companyId: company.id,
        companyName: company.name,
        companyType: company.ProjectCompanies?.[0]?.companyType || '',
        companyRole: company.ProjectCompanies?.[0]?.companyRole,
      });
    }
  }, [isModalOpen, company]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const submitData: FormValues = {
        companyId: values.companyId,
        companyRole: values.companyRole,
      };

      if (values.companyType) {
        submitData.companyType = values.companyType;
      }
      onSubmit([submitData]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('Edit company')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' loading={isSubmit} type='primary' onClick={handleOk}>
          {t('Submit')}
        </Button>,
      ]}
    >
      <Typography.Title level={3} className='section-title'>
        {t('Company')}
      </Typography.Title>
      <Form form={form} name='editCompany' layout='vertical'>
        <Row gutter={24} align='middle' className='edit-company-content'>
          <Col sm={8} md={9}>
            <Form.Item name='companyId' rules={[{ required: true, message: '' }]} hidden>
              <Input />
            </Form.Item>
            <Form.Item name='companyName' rules={[{ required: true, message: '' }]}>
              <Input placeholder={t('Company Name')} disabled />
            </Form.Item>
          </Col>
          <Col sm={8} md={7}>
            <Form.Item name='companyType' rules={[{ required: false }]}>
              <Input placeholder={t('Company Type')} />
            </Form.Item>
          </Col>
          <Col sm={8} md={7}>
            <Form.Item name='companyRole' rules={[{ required: true, message: '' }]}>
              <Select placeholder={t('Company Role')}>
                <Select.Option value='owner'>{t('Owner')}</Select.Option>
                <Select.Option value='member'>{t('Member')}</Select.Option>
                <Select.Option value='guest'>{t('Guest')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
