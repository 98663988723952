import { WarningOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Comment, DeleteButton, RejectModal, Transfer } from 'components';
import { Issue, User } from 'model';
import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { titleCase } from 'utils';
import { ISSUE_STATUSES } from 'utils/contants';
import CloseButton from './FooterButton/CloseButton';

type CreateTaskFooterProps = {
  issue?: Issue | null;
  issueId?: string | null;
  isDelete?: boolean;
  isClosing?: boolean;
  isSubmit?: boolean;
  isSubmitAsDraft?: boolean;
  isAvailableSubmit?: boolean;
  issueCode?: string | null;
  onDelete: () => void;
  onClose: () => void;
  onSubmit: () => void;
  onSubmitAsDraft: () => void;
  onSubmitComment: () => void;
  isAssigneeEdit?: boolean;
  isAssignerEdit?: boolean;
  onCallForReview: () => void;
  profile?: User | null;
  users: User[];
  onSubmitWatchers: () => void;
  onValidateForm: () => void;
  onReject: () => void;
  onFinish: () => void;
  projectId: string;
  workspaceId: string;
  isDisabled?: boolean;
  isNotAssigneeOrReporter?: boolean;
  isDraftStatus?: boolean;
  handleMessageChange: (event: { target: { value: SetStateAction<string> } }) => void;
  module: string;
  isGoodPractice?: boolean;
};

const CreateTaskFooter = (props: CreateTaskFooterProps) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    issue,
    issueId,
    isDelete,
    isClosing,
    isSubmit,
    isSubmitAsDraft,
    isAvailableSubmit,
    issueCode,
    onDelete,
    onClose,
    onSubmit,
    onSubmitAsDraft,
    isAssigneeEdit,
    isAssignerEdit,
    onCallForReview,
    profile,
    projectId,
    workspaceId,
    users,
    onSubmitWatchers,
    onValidateForm,
    onReject,
    onFinish,
    isDisabled,
    isNotAssigneeOrReporter,
    handleMessageChange,
    module,
    isGoodPractice,
  } = props;
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);

  const assigneeName = users?.find((user) => user.id === issue?.assigneeId)?.fullName;
  const assignerName: string = users?.find((user) => user.id === issue?.reporterId)?.fullName || '';
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const isAssigner = profile?.id === issue?.reporterId;
  const isAssignee = profile?.id === issue?.assigneeId;
  const isAssignerCompany =
    userCompanyId === users?.find((user) => user.id === issue?.reporterId)?.WorkspaceUser?.Company?.id;
  const inSameCompany =
    issue?.companyId === users?.find((user) => user.id === issue?.reporterId)?.WorkspaceUser?.Company?.id;
  const commentHandler = () => {
    setIsFormOpen(true);
  };

  const onClickReject = () => {
    setIsOpenRejectModal(true);
  };

  const transferHandler = () => {
    setIsCreateOpen(true);
  };

  const onSave = () => {
    onSubmitWatchers();
  };

  const onAssignerSave = () => {
    onSubmit();
  };

  const renderAssigneeActions = () => (
    <>
      {isAssigneeEdit ? (
        <Row gutter={16} justify='end' align='middle'>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button onClick={onFinish}>{t('Cancel')}</Button>
            <Button
              loading={isSubmit}
              disabled={!isAvailableSubmit}
              type='primary'
              onClick={() => {
                if (isGoodPractice) onClose();
                else {
                  if (isAssignerCompany && isAssignee) onAssignerSave();
                  else onSave();
                }
              }}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row gutter={16} justify='space-between' align='middle'>
          <Col span={12}>
            <Button type='dashed' ghost loading={isSubmit} onClick={commentHandler}>
              {t('Comment')}
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {!(isAssignerCompany && isAssignee) && (
              <Button loading={isSubmit} onClick={transferHandler}>
                {t('Transfer')}
              </Button>
            )}
            <Button type='primary' loading={isSubmit} onClick={onCallForReview}>
              {titleCase(t('Call for review'))}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );

  const renderAssignerOrReporterActions = () => (
    <>
      {/* Assigner/Reporter and assignee is different */}
      {issue?.reporterId !== issue?.assigneeId && !inSameCompany ? (
        issueCode === ISSUE_STATUSES.READY_FOR_INSPECTION ? (
          renderReadyForReviewActions()
        ) : (
          <Row gutter={16} justify='space-between' align='middle'>
            <Col span={12}>
              <DeleteButton
                onDelete={onDelete}
                isDelete={isDelete}
                isDisabled={isDisabled && !isAssignerCompany}
                module={module}
              />
              <CloseButton
                onClose={onClose}
                isClosing={isClosing}
                isDisabled={isDisabled && !isAssignerCompany}
                module={module}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button onClick={onFinish}>{t('Cancel')}</Button>
              <Button
                disabled={!isAvailableSubmit}
                type='primary'
                loading={isSubmit}
                onClick={() => {
                  if (isGoodPractice) onClose();
                  else onSubmit();
                }}
              >
                {t('Save')}
              </Button>
            </Col>
          </Row>
        )
      ) : (
        <>
          {/* Assigner/Reporter and assignee is the same */}
          {!isAssignerEdit ? (
            issueCode === ISSUE_STATUSES.READY_FOR_INSPECTION ? (
              (!isAssignee || issue?.reporterId === issue?.assigneeId) && renderReadyForReviewActions()
            ) : (
              <Row gutter={16} justify='space-between' align='middle'>
                <Col span={12}>
                  {(!isAssignee || issue?.reporterId === issue?.assigneeId) && (
                    <>
                      <DeleteButton
                        onDelete={onDelete}
                        isDelete={isDelete}
                        isDisabled={isDisabled && !isAssignerCompany}
                        module={module}
                      />
                      <CloseButton
                        onClose={onClose}
                        isClosing={isClosing}
                        isDisabled={isDisabled && !isAssignerCompany}
                        module={module}
                      />
                    </>
                  )}
                  <Button type='dashed' ghost loading={isSubmit} onClick={commentHandler}>
                    {t('Comment')}
                  </Button>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button type='primary' loading={isSubmit} onClick={onCallForReview}>
                    {titleCase(t('Call for review'))}
                  </Button>
                </Col>
              </Row>
            )
          ) : (
            <Row gutter={16} justify='end' align='middle'>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button onClick={onFinish}>{t('Cancel')}</Button>
                <Button
                  loading={isSubmit}
                  disabled={!isAvailableSubmit}
                  type='primary'
                  onClick={() => {
                    if (isGoodPractice) onClose();
                    else onAssignerSave();
                  }}
                >
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );

  const renderDraftActions = () => (
    <Row gutter={16} justify='space-between' align='middle'>
      <Col span={12}>
        {(!isAssignee || issue?.reporterId === issue?.assigneeId) && (
          <>
            <DeleteButton
              onDelete={onDelete}
              isDelete={isDelete}
              isDisabled={isDisabled && !isAssignerCompany && !isAssignee}
              module={module}
            />
            <CloseButton
              onClose={onClose}
              isClosing={isClosing}
              isDisabled={isDisabled && !isAssignerCompany && !isAssignee}
              module={module}
            />
          </>
        )}
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button onClick={onSubmitAsDraft} loading={isSubmitAsDraft} disabled={!isAssignerCompany}>
          {titleCase(t('Save as draft'))}
        </Button>
        {!isGoodPractice && (
          <Button
            type='primary'
            loading={isSubmit}
            onClick={onSubmit}
            disabled={!isAssignerCompany ? true : !isAvailableSubmit}
          >
            {t('Submit')}
          </Button>
        )}
      </Col>
    </Row>
  );

  const renderReadyForReviewActions = () => (
    <Row gutter={16} justify='space-between' align='middle'>
      <Col span={12}>
        <DeleteButton
          onDelete={onDelete}
          isDelete={isDelete}
          isDisabled={isDisabled && !isAssignerCompany}
          module={module}
        />
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <RejectModal
          isOpen={isOpenRejectModal}
          setOpen={setIsOpenRejectModal}
          onReject={onReject}
          assigneeName={assigneeName}
          issueId={issueId || ''}
          userCreated={profile?.id}
          workspaceId={workspaceId}
          projectId={projectId}
          handleMessageChange={handleMessageChange}
          module={module}
        />
        <Button type='dashed' className='btn-reject' loading={isSubmit} onClick={onClickReject}>
          <>
            <WarningOutlined style={{ color: '#FF4D4F', marginRight: 8 }} />
            {t('Reject')}
          </>
        </Button>
        <Button disabled={!isAvailableSubmit} type='primary' loading={isSubmit} onClick={onValidateForm}>
          {t('Validate')}
        </Button>
      </Col>
    </Row>
  );

  const renderUnsavedActions = () => (
    <Row gutter={16} justify='end' align='middle'>
      <Col span={16} style={{ textAlign: 'right' }}>
        <Button onClick={onSubmitAsDraft} loading={isSubmitAsDraft}>
          {titleCase(t('Save as draft'))}
        </Button>
        {!isGoodPractice && (
          <>
            <Button type='primary' loading={isSubmit} onClick={onSubmit} disabled={!isAvailableSubmit}>
              {t('Submit')}
            </Button>
          </>
        )}
      </Col>
    </Row>
  );

  const renderClosedActions = () => (
    <Row gutter={16} justify='space-between' align='middle'>
      <Col span={12}>
        <DeleteButton
          onDelete={onDelete}
          isDelete={isDelete}
          isDisabled={isDisabled && !isAssignerCompany}
          module={module}
        />
      </Col>
    </Row>
  );

  /**
   * This function renders the different action buttons based on the user's role
   * and the issue's status.
   * @return {JSX.Element}
   */
  const IssueActions = () => {
    // 1. If the issue ID is not provided, render the unsaved actions
    if (!issueId) {
      return renderUnsavedActions();
    }

    // 2. If the issue is closed, render the closed actions
    if (issueCode === ISSUE_STATUSES.CLOSED) {
      if (isAssignerCompany && (!isAssignee || issue?.reporterId === issue?.assigneeId)) return renderClosedActions();
      else return;
    }

    // 3. If the user is not the assignee or reporter, check conditions 3.1
    // else show nothing
    if (isNotAssigneeOrReporter) {
      return (
        // 3.1 If user in assignee's company and issue is open, render the assignee actions
        userCompanyId === issue?.companyId && issueCode === ISSUE_STATUSES.OPEN && renderAssigneeActions()
      );
    }

    // 4. If the issue is a draft, render the draft actions
    if (issueCode === ISSUE_STATUSES.DRAFT) {
      if (isAssigner || isAssignerCompany) return renderDraftActions();
      else return;
    }

    // 5. If the user is the assigner, render the assigner or reporter actions
    // if (isAssignerCompany && !isAssignee) {
    if (isAssignerCompany) {
      return renderAssignerOrReporterActions();
    }

    return issueCode === ISSUE_STATUSES.OPEN && renderAssigneeActions();
  };

  return (
    <>
      <Comment
        isOpen={isFormOpen}
        setOpen={setIsFormOpen}
        issueId={issueId || ''}
        userCreated={profile?.id}
        workspaceId={workspaceId}
        projectId={projectId}
      />
      <Transfer
        workspaceId={workspaceId}
        projectId={projectId}
        issue={issue}
        isTransferOpen={isCreateOpen}
        setIsTransferOpen={setIsCreateOpen}
        users={users}
        companyId={issue?.companyId}
        reporterId={issue?.reporterId}
        assigneeId={issue?.assigneeId}
        issueId={issueId || ''}
        onFinish={onFinish}
        assignerName={assignerName}
        module={module}
      />
      {IssueActions()}
    </>
  );
};

export default CreateTaskFooter;
