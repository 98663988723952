import { useTranslation } from 'react-i18next';
import { Button, Form, Input, ModalProps, Typography } from 'antd';
import { CustomizeRequiredMark, Modal, SearchSelect } from 'components';
import { useMyWorkspaceProjectTypes, useMyWorkspaceProjectTypesParams } from 'hooks';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';

type CreateProjectProps = {
  isModalOpen: boolean;
  loading?: boolean;
  onSubmit: (values: unknown) => void;
  setIsModalOpen: (value: boolean) => void;
} & ModalProps;
export default function CreateProject(props: CreateProjectProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, loading } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const [projectTypeQuery] = useMyWorkspaceProjectTypesParams({
    workspaceId: myWorkspace?.id,
    limit: 'unlimited',
    orderBy: 'name',
  });
  const [projectTypes] = useMyWorkspaceProjectTypes(projectTypeQuery);

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('Create a project')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' loading={loading} type='primary' onClick={handleOk}>
          {t('Create project')}
        </Button>,
      ]}
    >
      <Typography.Title level={3} className='section-title'>
        {t('Overview')}
      </Typography.Title>
      <Form requiredMark={CustomizeRequiredMark} form={form} name='create-project' layout='vertical'>
        <Form.Item label={t('Project name')} name='name' rules={[{ required: true, message: '' }]}>
          <Input placeholder={t('Project name')} />
        </Form.Item>
        <Form.Item
          name='code'
          label={<span style={{ textTransform: 'initial' }}>{t('Code (4 characters.)')}</span>}
          rules={[{ required: true, message: '', max: 4, min: 4 }]}
        >
          <Input placeholder={t('Enter code')} />
        </Form.Item>
        <Form.Item label={<>{t('Project type')}</>} name='projectTypeId' rules={[{ required: true, message: '' }]}>
          <SearchSelect
            placeholder={t('Select project type')}
            options={projectTypes?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
