import { Button, Form, ModalProps, message } from 'antd';
import { Modal } from 'components';
import { Company } from 'model';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { companyService } from 'services';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';

type DeleteCompanyConfirmationProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  companyDetails?: Company;
  onSubmit: () => void;
} & ModalProps;
export default function DeleteCompanyConfirmation(props: DeleteCompanyConfirmationProps) {
  const { isModalOpen, setIsModalOpen, companyDetails, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;

  const handleOk = async () => {
    try {
      await companyService.deleteCompanies(workspaceId, companyDetails?.id as string);
      message.success(t('Delete company success!'));
      onSubmit();
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('Delete confirmation')}
      className='company-delete-confirm-modal'
      open={isModalOpen}
      closable={false}
      footer={null}
    >
      <Button key='back' onClick={handleCancel}>
        {t('Cancel')}
      </Button>
      <Button danger key='submit' onClick={handleOk}>
        {t('Delete')}
      </Button>
    </Modal>
  );
}
