import { useEffect, useState } from 'react';
import { fileService } from 'services';
import { LatLngBoundsLiteral } from 'leaflet';
import { ImageOverlay } from 'react-leaflet';

export default function ImageOverlayItem({
  url,
  bounds
}: {
  url: string;
  bounds: LatLngBoundsLiteral;
}) {
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  useEffect(() => {
    if (url?.includes('.s3')) {
      fileService.getSignedUrlGet({ keyFile: url.substring(url.indexOf('polaris/') + 8) }).then((r) => {
        setFileUrl(r.preSignedURL);
      });
    } else {
      setFileUrl(url);
    }
  }, [url]);

  return fileUrl ? <ImageOverlay url={fileUrl} bounds={bounds} /> : <></>;
}
