import Attachment from './Attachment';

export enum EBaseIssueMessagesAction {
  FORM_EDIT = 'form-edit',
  COMMENT = 'comment',
  COMMENT_DELETE = 'comment-delete',
  COMMENT_EDIT = 'comment-edit',
  ADD_USER = 'add-user',
}

export type BaseIssueMessages = {
  id: string;
  baseIssueId: string;
  userId: string;
  companyId: string;
  message: string;
  action: EBaseIssueMessagesAction;
  createdAt: string; // Consider `Date` if you want to handle it as a date object.
  updatedAt: string; // Consider `Date` if you want to handle it as a date object.
  deletedAt: string | null;
  BaseIssueMessageAttachments: Attachment[];
  refTable: string;
  refId: string;
};
