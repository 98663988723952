import { Col, DatePicker, Form, FormInstance, Input, Row, Select, TreeSelect, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CustomizeRequiredMark, ReadOnlyDataField, SearchSelect } from 'components/common';
import {
  BaseIssue,
  Category,
  Company,
  IssueDiscipline,
  IssuePriority,
  IssueType,
  Phase,
  SafetyRiskActivity,
  User,
  WorkPackage,
  Zone,
} from 'model';
import { useTranslation } from 'react-i18next';
import MSSsListDocumentSection from './MSSsListDocument';
import dayjs from 'dayjs';
import { DataNode } from 'antd/es/tree';
import { findPath } from 'components/common/TreeSelectTagRender';
import { useAppSelector } from 'store';
import {
  selectActivitiesByDiscipline,
  selectControlPointsBySubActivity,
  selectSubActivitiesByActivity,
  selectWorkBreakDownStructureByLevel,
} from 'store/my-projects.slice';
import { mapDataToOptions } from 'utils/object';
import { EWorkBreakDownStructureLevelID } from 'types/project';

type CreateMSSDetailProps = {
  form: FormInstance;
  companies: Company[];
  users: User[];
  issuePriorities: IssuePriority[];
  issueDisciplines: IssueDiscipline[];
  issueTypes: IssueType[];
  zones: Zone[];
  phases: Phase[];
  profile: User | null;
  workPackages: WorkPackage[];
  categories: Category[];
  safetyRiskActivities: SafetyRiskActivity[];
  isReadOnly: boolean;
  selectedMssBaseIssue: BaseIssue | null;
  workspaceId: string;
  projectId: string;
  attachmentsInfo: { id: string; name: string; filePath: string; userCreated: string }[];
  setAttachmentsInfo: (value: { id: string; name: string; filePath: string; userCreated: string }[]) => void;
  handleChangeRequestName: (name: string) => void;
  zonesTreeData: DataNode[];
  zoneUpdated: string;
  setZoneUpdated: (value: string) => void;
  refreshBaseIssues?: (baseIssueId: string) => void;
};

export default function CreateMSSDetail(props: CreateMSSDetailProps) {
  const {
    form,
    users,
    workPackages,
    categories,
    safetyRiskActivities,
    isReadOnly,
    selectedMssBaseIssue,
    workspaceId,
    projectId,
    attachmentsInfo,
    setAttachmentsInfo,
    handleChangeRequestName,
    zoneUpdated,
    setZoneUpdated,
    zonesTreeData,
  } = props;
  const { t } = useTranslation();
  const formValues = form.getFieldsValue(true);
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));

  const originator = (users || []).find((user) => user.id === selectedMssBaseIssue?.originatorId);
  const watchedDiscipline = Form.useWatch('disciplineId') || formValues?.disciplineId;
  const activities = useAppSelector(selectActivitiesByDiscipline(watchedDiscipline));
  const subActivities = useAppSelector(
    selectSubActivitiesByActivity(Form.useWatch('activityId') || formValues.activityId)
  );
  const controlPoints = useAppSelector(
    selectControlPointsBySubActivity(Form.useWatch('subActivityId') || formValues.subActivityId)
  );

  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(findPath(zonesTreeData, zoneId));
  };
  return (
    <div className='create-mss-form'>
      <Form requiredMark={CustomizeRequiredMark} form={form} layout='vertical'>
        <Typography.Title className='material-group-title' level={3}>
          {t('General Information')}
        </Typography.Title>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField title={t('Method Statement Name')} content={selectedMssBaseIssue?.name || '--'} />
            ) : (
              <Form.Item
                name='name'
                label={t('Method Statement Name')}
                rules={[
                  { required: true, message: '' },
                  {
                    max: 100,
                    message: t('Method Statement name cannot exceed 100 characters'),
                  },
                ]}
              >
                <Input
                  maxLength={100}
                  showCount
                  allowClear={true}
                  placeholder={t('Enter Method Statement name')}
                  onChange={(e) => handleChangeRequestName(e.target.value)}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={16} className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Company Originator')}
                content={selectedMssBaseIssue?.Company?.name || '--'}
              />
            ) : (
              <Form.Item
                name='companyOriginatorId'
                label={t('Company Originator')}
                rules={[{ required: true, message: '' }]}
              >
                <SearchSelect
                  placeholder={t('Select Company Originator')}
                  disabled={true}
                  options={(users || []).map((user) => {
                    return {
                      value: user.WorkspaceUser?.Company?.id,
                      label: user.WorkspaceUser?.Company?.name,
                    };
                  })}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField title={t('Originator Name')} content={originator?.fullName || '--'} />
            ) : (
              <Form.Item name='originatorId' label={t('Originator Name')} rules={[{ required: true, message: '' }]}>
                <SearchSelect
                  placeholder={t('Select Originator')}
                  disabled={true}
                  options={(users || []).map((user) => {
                    return {
                      value: user.id,
                      label: user.fullName,
                    };
                  })}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Work Package')}
                content={selectedMssBaseIssue?.MethodStatementSubmission?.WorkPackage?.name || '--'}
              />
            ) : (
              <Form.Item name='workPackageId' label={t('Work Package')} rules={[{ required: true, message: '' }]}>
                <SearchSelect
                  allowClear={false}
                  placeholder={t('Select Work Package')}
                  options={(workPackages || []).map((workPackage) => {
                    return {
                      value: workPackage.id,
                      label: workPackage.name,
                    };
                  })}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={16} className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Discipline')}
                content={disciplines.find((discipline) => discipline.id === formValues?.disciplineId)?.name || '--'}
              />
            ) : (
              <Form.Item name='disciplineId' label={t('Discipline')} rules={[{ required: true, message: '' }]}>
                <SearchSelect
                  placeholder={t('Select Discipline')}
                  options={mapDataToOptions(disciplines)}
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldValue('disciplineId', null);
                    }
                    form.setFieldValue('activityId', null);
                    form.setFieldValue('subActivityId', null);
                    form.setFieldValue('controlPointId', null);
                  }}
                  breakText
                />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Activity')}
                content={activities?.find((activity) => activity.id === formValues?.activityId)?.name || '--'}
              />
            ) : (
              <Form.Item name='activityId' label={t('Activity')}>
                <SearchSelect
                  placeholder={t('Select Activity')}
                  options={mapDataToOptions(activities)}
                  breakText
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldValue('activityId', null);
                    }
                    form.setFieldValue('subActivityId', null);
                    form.setFieldValue('controlPointId', null);
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={16} className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Sub-activity')}
                content={
                  subActivities?.find((subActivity) => subActivity.id === formValues?.subActivityId)?.name || '--'
                }
              />
            ) : (
              <Form.Item name='subActivityId' label={t('Sub-activity')}>
                <SearchSelect
                  placeholder={t('Select Sub-activity')}
                  options={mapDataToOptions(subActivities)}
                  breakText
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldValue('subActivityId', null);
                    }
                    form.setFieldValue('controlPointId', null);
                  }}
                />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Control Point')}
                content={
                  controlPoints?.find((controlPoint) => controlPoint.id === formValues?.controlPointId)?.name || '--'
                }
              />
            ) : (
              <Form.Item name='controlPointId' label={t('Control Point')}>
                <SearchSelect
                  placeholder={t('Select Control Point')}
                  options={mapDataToOptions(controlPoints)}
                  breakText
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldValue('controlPointId', null);
                    }
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={16} className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Approval Deadline')}
                content={
                  selectedMssBaseIssue?.endDate ? dayjs(selectedMssBaseIssue?.endDate).format('YYYY-MM-DD') : '--'
                }
              />
            ) : (
              <Form.Item name='endDate' label={t('Approval Deadline')}>
                <DatePicker
                  placeholder={t('Select date')}
                  style={{ width: '100%' }}
                  format='YYYY-MM-DD'
                  disabledDate={(date) => date.isBefore(new Date(), 'day')}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Zoning')}
                content={
                  selectedMssBaseIssue?.Zone?.id ? findPath(zonesTreeData, selectedMssBaseIssue?.Zone?.id) : '--'
                }
              />
            ) : (
              <Form.Item name='zoneId' label={t('Zoning')} rules={[{ required: false, message: '' }]}>
                <TreeSelect
                  style={{ width: '100%' }}
                  value={zoneUpdated}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={zonesTreeData}
                  placeholder={t('Select Location/Area')}
                  treeDefaultExpandAll
                  onChange={onChangeZone}
                />
                <div style={{ display: 'none' }}>{zoneUpdated}</div>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Typography.Title className='read-only-mar-title read-only-row' level={3}>
          {t('Method Statement Description')}
        </Typography.Title>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Description of Works')}
                content={selectedMssBaseIssue?.description || '--'}
              />
            ) : (
              <Form.Item
                name='description'
                label={t('Description of Works')}
                rules={[
                  {
                    max: 255,
                    message: t('Description cannot exceed 300 characters'),
                  },
                ]}
              >
                <TextArea
                  style={{ textTransform: 'none' }}
                  count={{
                    show: true,
                    max: 255,
                  }}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  allowClear
                  placeholder={t('Add description here...')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Typography.Title className='read-only-mar-title read-only-row' level={3}>
          {t('Method Statement Safety')}
        </Typography.Title>
        <Row gutter={16} className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Safety Risk Severity')}
                content={selectedMssBaseIssue?.MethodStatementSubmission?.SafetyRisk?.name || '--'}
              />
            ) : (
              <Form.Item
                name='safetyRiskId'
                label={t('Safety Risk Severity')}
                rules={[{ required: false, message: '' }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('Select Severity')}
                  options={(safetyRiskActivities || []).map((safetyRiskActivity) => {
                    return {
                      value: safetyRiskActivity.id,
                      label: safetyRiskActivity.name,
                    };
                  })}
                ></Select>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Safety Category')}
                content={
                  (selectedMssBaseIssue?.BaseIssueCategories || []).length > 0
                    ? selectedMssBaseIssue?.BaseIssueCategories?.map((category, index) => {
                        const safetyCategory = (categories || []).find((c) => c.id === category.safetyCategoryId);
                        return safetyCategory
                          ? safetyCategory.name +
                              (index !== (selectedMssBaseIssue?.BaseIssueCategories || []).length - 1 ? ', ' : '')
                          : '';
                      })
                    : '--'
                }
              />
            ) : (
              <Form.Item name='categoryIds' label={t('Safety Category')}>
                <Select
                  allowClear
                  mode='multiple'
                  maxTagCount='responsive'
                  placeholder={t('Select Safety Category')}
                  options={(categories || [])?.map((category) => {
                    return {
                      value: category.id,
                      label: category.name,
                    };
                  })}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Safety Risk Description')}
                content={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {selectedMssBaseIssue?.MethodStatementSubmission?.safetyRiskDescription || '--'}
                  </div>
                }
              />
            ) : (
              <Form.Item name='safetyRiskDescription' label={t('Safety Risk Description')}>
                <TextArea
                  count={{
                    show: false,
                    max: 255,
                  }}
                  allowClear={true}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  placeholder={t('Describe')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            {isReadOnly ? (
              <ReadOnlyDataField
                title={t('Sequence of Works')}
                content={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {selectedMssBaseIssue?.MethodStatementSubmission?.sequence || '--'}
                  </div>
                }
              />
            ) : (
              <Form.Item name='sequence' label={t('Sequence of Works')} rules={[{ required: false, message: '' }]}>
                <TextArea
                  count={{
                    show: false,
                    max: 255,
                  }}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  allowClear
                  placeholder={t('Add Sequence of Works ')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row className={isReadOnly ? 'read-only-row' : ''}>
          <Col span={24}>
            <MSSsListDocumentSection
              key={`${selectedMssBaseIssue?.id || ''}`}
              isReadOnly={isReadOnly}
              selectedMssBaseIssue={selectedMssBaseIssue}
              workspaceId={workspaceId}
              projectId={projectId}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={(value) => {
                if (value) {
                  setAttachmentsInfo(value);
                }
              }}
              refreshBaseIssues={props.refreshBaseIssues}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
