import { Avatar } from 'antd';
import { AvatarSize } from 'antd/es/avatar/AvatarContext';
import { CSSProperties, useEffect, useState } from 'react';
import { fileService } from 'services';

export default function AvatarItem({
  src,
  style,
  size,
  className,
  alt,
}: {
  src: string | undefined;
  style?: CSSProperties | undefined;
  size?: AvatarSize | undefined
  className?: string | undefined;
  alt?: string | undefined;
 }
) {
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  useEffect(() => {
    if (src && src.includes('.s3')) {
      fileService.getSignedUrlGet({ keyFile: src.substring(src.indexOf('polaris/') + 8) }).then((r) => {
        setFileUrl(r.preSignedURL);
      });
    } else {
      setFileUrl(src);
    }
  }, [src]);

  return <Avatar src={fileUrl} style={style} size={size} className={className} alt={alt} />;
}
