import { FileSyncOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchDebounce } from 'utils';

type ListViewLayoutProps = {
  header?: string;
  onGenerateReport?: () => void;
  addBtnProps: {
    text?: string;
    onClick: () => void;
  };
  children?: ReactNode;
  className?: string;
  onSearch?: (searchValue: string) => void;
  filterNode?: ReactNode;
};

const ListViewLayout = (props: ListViewLayoutProps) => {
  const { t } = useTranslation();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useSearchDebounce();
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      return;
    }
    props?.onSearch?.(searchValue);
  }, [searchValue]);

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };

  const renderAction = () => {
    return (
      <Flex style={{ justifyContent: 'flex-end', marginTop: '15px', marginBottom: '15px' }}>
        <Input
          name='search-input'
          className='search-button mr-3'
          placeholder={t('Type to search')}
          onBlur={(e) => handleInputBlur(e.target.value)}
          onFocus={handleSearchIconClick}
          style={{
            width: showSearchInput ? '250px' : '0px',
            border: showSearchInput ? '1px solid #d9d9d9' : 'none',
          }}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          allowClear
        />
        {props?.filterNode || null}
        {props?.onGenerateReport && (
          <Button onClick={props?.onGenerateReport} icon={<FileSyncOutlined />}>
            {t('Generate report')}
          </Button>
        )}
        <Button type='primary' icon={<PlusOutlined />} onClick={() => props.addBtnProps?.onClick()}>
          {props.addBtnProps.text}
        </Button>
      </Flex>
    );
  };

  return (
    <div className={`list-view-layout ${props?.className || ''}`}>
      {props?.header && (
        <Typography.Title level={2} className='mr-4' style={{ height: '32px' }}>
          {props?.header}
        </Typography.Title>
      )}
      {renderAction()}
      {props?.children}
    </div>
  );
};

export default ListViewLayout;
