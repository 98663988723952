import { useRef } from 'react';
import PieChart from './features/PieChart';
import { IssueByDiscipline } from 'model';

type GraphByDisciplineProps = {
  issueByDiscipline: IssueByDiscipline[];
  loading?: any;
};
export const GraphByDisciplines = (props: GraphByDisciplineProps) => {
  const { issueByDiscipline, loading } = props;
  const svgLargeRef: any = useRef<SVGSVGElement>();

  const fillColors = [
    '#115f9a',
    '#1984c5',
    '#22a7f0',
    '#63bff0',
    '#a7d5ed',
    '#e2e2e2',
    '#e1a692',
    '#de6e56',
    '#e14b31',
    '#c23728',
  ];
  let chartData: any[] = [];
  let filteredIssues: IssueByDiscipline[] = [];

  if (issueByDiscipline)
    filteredIssues = issueByDiscipline.filter(
      (item) => item?.count && item.count > 0
    );
  if (filteredIssues && filteredIssues.length > 0) {
    let dataSorted: any[] = [...filteredIssues];
    chartData = dataSorted
      .sort((a: any, b: any) => {
        return a.count - b.count;
      })
      .map((item, index) => {
        return {
          name: item.name,
          count: item.count,
          fillColor: fillColors[index],
        };
      });
  }
  return (
    <div ref={svgLargeRef} className='w-12/12 h-72'>
      <PieChart
        data={chartData}
        svgWrapperRef={svgLargeRef}
        padding={10}
        loading={loading}
      />
    </div>
  );
};
