import React from 'react';
import {
  BarChartOutlined,
  CloudOutlined,
  ToolOutlined,
  TeamOutlined,
  BankOutlined,
  DollarOutlined,
  GlobalOutlined,
  AuditOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, Flex, Select, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import logoOrange from 'assets/images/pictures/logo-orange.png';
import { useNavigate, useLocation } from 'react-router-dom';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  disabled?: boolean,
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}

const itemsWorkspace = [
  {
    id: '1',
    name: 'Workspace A',
    code: 'MYW',
  },
  {
    id: '2',
    name: 'Workspace B',
    code: 'WB',
  },
  {
    id: '3',
    name: 'Workspace C',
    code: 'WC',
  },
];

type SidebarProps = {
  collapsed: boolean;
  toggleCollapsed: () => void;
};
const { Text } = Typography;

const WorkspaceSidebar = (props: SidebarProps) => {
  const { collapsed, toggleCollapsed } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const items: MenuProps['items'] = [

      getItem(t('General Configuration'), 'general', <GlobalOutlined />),
      // getItem(t('Advanced'), 'advanced', <AuditOutlined />, null, true),
      getItem(t('Companies'), 'companies', <BankOutlined />),
      getItem(t('Users'), 'users', <TeamOutlined />),
      getItem(t('Data customization'), 'data-customization', <AuditOutlined />),
      getItem(t('Modules Configuration'), 'modules-configuration', <ControlOutlined />),
      getItem(t('Plans & Billing'), 'plansbilling', <DollarOutlined />, null, true),


    // getItem(t('Modules'), 'modules', null, [
    //   getItem(t('Toolbox'), 'toolbox', <ToolOutlined />, null, true),
    //   getItem(t('Quality'), 'quality', <BarChartOutlined />, null, true),
    //   getItem(t('Safety'), 'safety', <CloudOutlined />, null, true),
    // ], false, 'group'),
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(`/workspace-settings/${e.key}`);
  };
  return (
    <Sider
      className={'siderbar project-sidebar'}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <Flex
        gap={5}
        vertical
        className={'siderbar-header workspace-siderbar-header'}
      >
        {
          !collapsed &&
          <Text className={'workspaces-text'}>
            {t('Workspace Settings')}
          </Text>
        }
        <Button
          type="link"
          icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          onClick={() => toggleCollapsed()}
        />
        {/*
        {!collapsed &&
          <Flex>
            <img src={logoOrange} alt={'logo'} />
            <Select
              defaultValue={itemsWorkspace[0].id}
              bordered={false}
              style={{ width: 150 }}
              options={itemsWorkspace?.map((option) => ({
                value: option.id,
                label: option.name
              }))}
            />
          </Flex>
        }
        */}
      </Flex>
      <Menu
        className={'workspace-menu'}
        onClick={onClick}
        defaultSelectedKeys={[lastSegment]}
        defaultOpenKeys={['settings']}
        mode='inline'
        items={items}
      />
    </Sider>
  );
};

export default WorkspaceSidebar;
