import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Flex, Form, Row, Tag, Typography } from 'antd';
import { t } from 'i18next';
import { BaseIssue, IssueDiscipline, User, ValidationHistory } from 'model';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';
import dayjs from 'dayjs';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectProjectUsers, selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { EWorkBreakDownStructureLevelID } from 'types/project';

type MARsHeaderProps = {
  discipline?: IssueDiscipline | null;
  requestName?: string;
  isUnSaveStatus?: boolean;
  profile: User | null;
  onClose: () => void;
  companyHistories: ValidationHistory[];
  selectedMarBaseIssue: BaseIssue | null;
};

export default function MARsHeader(props: MARsHeaderProps) {
  const { onClose, selectedMarBaseIssue } = props;
  const code = useMemo(() => {
    const numberSystem = selectedMarBaseIssue?.MaterialApprovalRequest?.marNumber?.split('-');
    if (!numberSystem) return '';
    numberSystem[numberSystem?.length - 1] = numberSystem[numberSystem?.length - 1].padStart(4, '0');
    return numberSystem.join('-');
  }, [selectedMarBaseIssue?.MaterialApprovalRequest?.marNumber]);

  const form = useFormInstance();
  const disciplineId = Form.useWatch('disciplineId') || form.getFieldValue('disciplineId');
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const disciplineCode = disciplines.find((discipline) => discipline.id === disciplineId)?.code || '-';
  const name = Form.useWatch('name') || form.getFieldValue('name');
  const users = useAppSelector(selectProjectUsers);
  const originator = users.find((u) => u.id === (selectedMarBaseIssue?.originatorId || props?.profile?.id));

  const renderStatus = () => {
    if (!selectedMarBaseIssue?.IssueStatus) return <Tag>{t('Unsaved')}</Tag>;

    return (
      <div>
        <Tag>
          <Flex align='center'>
            <Tag
              className='circle-fill small'
              color={TASK_STATUS_HEADER_COLOR?.[selectedMarBaseIssue?.IssueStatus.code] || ''}
              bordered={false}
            />
            {t(selectedMarBaseIssue?.IssueStatus.name)}
          </Flex>
        </Tag>
      </div>
    );
  };
  const renderName = () => {
    return (
      <Flex vertical justify='center' className='ml-2' wrap='nowrap' rootClassName='name-box'>
        <Typography.Title
          level={2}
          style={{ lineHeight: '1.4', maxWidth: '65ch', whiteSpace: 'normal', wordWrap: 'break-word' }}
        >
          {name}
        </Typography.Title>
        <Flex className='name-group'>
          {code && <Typography.Text>{code}</Typography.Text>}
          {originator?.WorkspaceUser?.Company?.name && (
            <Typography.Text>{originator?.WorkspaceUser?.Company?.name}</Typography.Text>
          )}
          {originator?.fullName && <Typography.Text>{originator?.fullName}</Typography.Text>}
          {selectedMarBaseIssue?.IssueStatus?.name !== 'Draft' &&
            selectedMarBaseIssue?.BaseValidations?.[0]?.createdAt && (
              <Typography.Text>
                {t('Submitted on:')}{' '}
                {dayjs(selectedMarBaseIssue?.BaseValidations?.[0]?.createdAt).locale('en').format('DD-MM-YYYY')}
              </Typography.Text>
            )}
        </Flex>
      </Flex>
    );
  };
  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='mb-2'>
        <Col span={12} className='header'>
          <DoubleRightOutlined className='icon' onClick={onClose} style={{ color: 'white' }} />
        </Col>
        <Col span={12} className='header text-right'>
          <Button
            size='small'
            type='text'
            onClick={onClose}
            icon={<CloseOutlined className='icon' style={{ color: 'white' }} />}
          />
        </Col>
      </Row>
      <Flex justify='space-between' align='center'>
        <Flex gap={6} align='center' className='text-white'>
          <Avatar className='avt-color'>{disciplineCode?.toString().toUpperCase()}</Avatar>
          {renderName()}
        </Flex>
        {renderStatus()}
      </Flex>
    </div>
  );
}
