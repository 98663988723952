import { Layout } from 'antd';
import { BaseIssue, User } from 'model';
import { TablePaginationProps } from 'components/common/TablePagination';
import DynamicTable, {
  CustomColumnType,
  DynamicTableProps,
  PriorityData,
  StatusData,
  TooltipData,
} from 'components/common/DynamicTable';
import { UserCompanyData } from 'components/common/UserCompany';
import dayjs from 'dayjs';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';
import { EModuleName, getBaseIssueWatcher } from 'utils/my-project';
import { selectProjectBaseIssuesCount } from 'hooks/base-issue';
import { QueryParams } from 'types';
import { RFI_STATUSES } from 'utils/contants';
import { EBaseIssueMessagesAction } from 'model/BaseIssueMessages';
import { ERFIActionType } from './CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import { createRFIPermissionChecker } from 'utils/rfiHelper';

type ListViewProps = {
  user: User | null;
  onSort: (params: QueryParams) => void;
  projectBaseIssueParams: TablePaginationProps['issueParams'];
  loading?: boolean;
  projectId: string;
  onActionClick?: DynamicTableProps<RFIDataSource>['onActionClick'];
  baseIssues?: BaseIssue[];
  onPaginationChange: (params: QueryParams) => void;
};

export type RFIDataSource = {
  id: string;
  number?: TooltipData;
  discipline?: TooltipData;
  subject?: TooltipData;
  priority?: PriorityData;
  issuedBy?: UserCompanyData;
  issuedTo?: UserCompanyData;
  distribution?: UserCompanyData[];
  lastUpdatedBy?: UserCompanyData;
  status?: StatusData;
  numberReply?: number;
  issuedDate?: string;
  disabledAction: {
    delete: boolean;
    edit: boolean;
  };
};

export default function RequestForInformationListView(props: ListViewProps) {
  const { loading } = props;
  const users = useAppSelector(selectProjectUsers);
  const baseIssueCount = useAppSelector(selectProjectBaseIssuesCount(EModuleName.Quality));

  const columns: CustomColumnType<RFIDataSource>[] = [
    {
      sortKey: 'InformationRequest.rfiNumber',
      title: 'Number',
      dataIndex: 'number',
      width: 'max-content',
      component: {
        type: 'tooltip',
      },
    },
    {
      title: 'Discipline',
      sortKey: 'InformationRequest.Discipline.name',
      dataIndex: 'discipline',
      component: {
        type: 'tooltip',
      },
      width: 250,
      onCell: () => {
        return {
          style: {
            maxWidth: '250px',
          },
        };
      },
    },
    {
      width: '300px',
      title: 'Subject',
      dataIndex: 'subject',
      sortKey: 'name',
      component: {
        type: 'tooltip',
      },
      onCell: () => {
        return {
          style: {
            maxWidth: '300px',
          },
        };
      },
    },
    {
      title: 'Priority',
      sortKey: 'InformationRequest.IssuePriority.orderNumber',
      align: 'center',
      dataIndex: 'priority',
      component: {
        type: 'priority',
      },
    },
    {
      width: '140px',
      title: 'Issued By',
      dataIndex: 'issuedBy',
      component: {
        type: 'user-company',
      },
    },
    {
      width: '120px',
      title: 'Issued To',
      dataIndex: 'issuedTo',
      component: {
        type: 'user-company',
      },
    },
    {
      width: '140px',
      title: 'Distribution',
      dataIndex: 'distribution',
      component: {
        type: 'user-company',
      },
    },
    {
      width: '135px',
      title: 'Last Updated By',
      dataIndex: 'lastUpdatedBy',
      component: {
        type: 'user-company',
      },
    },
    {
      title: 'Issued Date',
      sortKey: 'updatedAt',
      dataIndex: 'issuedDate',
      component: {
        type: 'date',
      },
      width: 'max-content',
    },
    {
      title: 'Status',
      sortKey: 'IssueStatus.orderNumber',
      dataIndex: 'status',
      width: 'max-content',
      component: {
        type: 'status',
      },
    },
    {
      align: 'center',
      width: '50px',
      title: 'Number Reply',
      dataIndex: 'numberReply',
    },
    {
      dataIndex: 'id',
      component: {
        type: 'action',
        props: [
          {
            label: 'Edit',
            key: 'edit',
            isDisabled: (record) => record?.disabledAction?.edit,
          },
          {
            label: 'Delete',
            key: 'delete',
            isDisabled: (record) => record?.disabledAction?.delete,
          },
        ],
      },
    },
  ];

  const generateRFIDataSource = (baseIssues?: BaseIssue[]): RFIDataSource[] => {
    if (!baseIssues) return [];
    return baseIssues?.map((baseIssue) => {
      const watcher = getBaseIssueWatcher(baseIssue?.BaseIssueWatchers);
      const issuedUser: User | undefined = users?.find((user) => user.id === baseIssue?.originatorId);
      const userUpdate = users?.find((user) => user.id === (baseIssue.lastUpdated?.userId || baseIssue.originatorId));
      const numberOfReply = baseIssue?.BaseIssueMessages?.filter(
        (thread) =>
          thread.action === EBaseIssueMessagesAction.COMMENT || thread.action === EBaseIssueMessagesAction.COMMENT_EDIT
      )?.length;

      const checkPermission = createRFIPermissionChecker(
        baseIssue.originatorId,
        props?.user,
        baseIssue?.BaseIssueWatchers,
        baseIssue?.IssueStatus?.code || ''
      );

      return {
        id: baseIssue.id,
        number: {
          title: baseIssue?.InformationRequest?.rfiNumber,
        },
        discipline: {
          title: baseIssue.InformationRequest?.Discipline?.name,
        },
        subject: {
          title: baseIssue.name,
          showLockIcon:
            baseIssue?.endDate && baseIssue?.IssueStatus?.code === RFI_STATUSES.OPEN
              ? dayjs(baseIssue?.endDate) < dayjs().subtract(1, 'day')
              : false,
        },
        priority: {
          iconUrl: baseIssue.InformationRequest?.IssuePriority?.icon,
        },
        distribution: watcher?.distribution,
        issuedBy: {
          userName: issuedUser?.fullName,
          companyName: issuedUser?.WorkspaceUser?.Company?.name,
        },
        issuedTo: watcher?.issuedTo,
        lastUpdatedBy: {
          userName: userUpdate?.fullName,
          date: (baseIssue.lastUpdated?.updatedAt as string) || baseIssue.updatedAt,
          avatar: {
            url: userUpdate?.profilePhoto,
            fallbackName: userUpdate?.fullName,
          },
        },
        issuedDate: baseIssue.createdAt as string,
        status: {
          code: baseIssue?.IssueStatus?.code,
          name: baseIssue?.IssueStatus?.name,
        },
        numberReply: numberOfReply || 0,
        disabledAction: {
          delete: !checkPermission(ERFIActionType.DeleteForm),
          edit: !checkPermission(ERFIActionType.EditForm),
        },
      };
    });
  };

  const handleSort = (sortKey?: string) => {
    props.onSort({
      orderBy: sortKey,
    });
  };

  return (
    <Layout.Content className='settings-content'>
      <DynamicTable
        pagination={{
          issuesCount: baseIssueCount,
          issueParams: { page: props?.projectBaseIssueParams?.page, limit: props?.projectBaseIssueParams?.limit },
          onSubmit: props.onPaginationChange,
          showTop: true,
        }}
        columns={columns}
        dataSource={generateRFIDataSource(props?.baseIssues)}
        isLoading={loading}
        onActionClick={props.onActionClick}
        onSort={handleSort}
      />
    </Layout.Content>
  );
}
