import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { SearchSelect } from 'components/common';
import { useTranslation } from 'react-i18next';
import { EControlTypeCode } from 'types/project';

type InspectionTestPlanTableControlTypeProps = SelectProps & {
  onChange: (value?: string | null) => void;
  options: DefaultOptionType[];
};
const InspectionTestPlanTableControlType = (props: InspectionTestPlanTableControlTypeProps) => {
  const { t } = useTranslation();

  const shorted = (label: string): string => {
    if (!label) return '';
    return label.split('-')[0];
  };

  const selectedLabel = props?.options?.find((option) => option?.value === props?.value)?.label;

  return (
    <SearchSelect
      {...props}
      labelRender={(data) => {
        return (
          <div className={`status-box`}>
            <span className={`status-${shorted(data?.label as string).trim()}`}>{shorted(data?.label as string)}</span>
          </div>
        );
      }}
      selectedLabel={shorted((selectedLabel || '') as string)}
      dynamicWidth
      placeholder={t('Control Type')}
      rootClassName={`control-type-root-select ${props.value ? 'active-status' : ''}`}
      popupClassName={`control-type-select`}
      optionRender={(option) => {
        const code = shorted(option?.label as string).trim() as EControlTypeCode;
        return (
          <div className={`status-${Object.values(EControlTypeCode).includes(code) ? code : EControlTypeCode.Custom}`}>
            {option.label}
          </div>
        );
      }}
      labelInValue
      onChange={(_, option) => {
        const currentOption = option as DefaultOptionType;
        props.onChange((currentOption?.value as string) || null);
      }}
      options={props.options}
    />
  );
};

export default InspectionTestPlanTableControlType;
