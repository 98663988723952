import { useState, useEffect } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'components';

type MiniPDFProps = {
  filePath: string;
  width: number | string;
  height: number | string;
  isDisabled?: boolean;
  CloseModal: () => void;
  module?: string;
  title: string | undefined;
};

export default function MiniPDF(props: MiniPDFProps) {
  const { filePath, width, height, CloseModal, isDisabled, module, title } = props;

  const [isModalPdfOpen, setIsModalPdfOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isDisabled) {
      setIsModalPdfOpen(true);
    }
  }, [isDisabled]);

  if (!filePath) return null;
  //
  return (
    <div className='mini-pdf' style={{ width: width, }}>
      {isModalPdfOpen && (
        <Modal
          title={title}
          open={isModalPdfOpen}
          maskClosable={true}
          width={width}
          height={height}
          onCancel={CloseModal}
          className={`pin-inspection-plan-modal ${
            module === 'Safety' ? 'safety-light' : module === 'Quality' ? 'quality-light' : ''
          }`}
          footer={null}
        >
          <iframe src={filePath} style={{ width: '100%', height: '60vh' }} frameBorder='0' title={title} />
        </Modal>
      )}
    </div>
  );
}
