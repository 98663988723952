import {
  FilterOutlined,
  SearchOutlined,
  SortDescendingOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
const { CheckableTag } = Tag;

export default function WorkspaceFilter() {
  const { t } = useTranslation();
  return (
    <div className="workspace-filter">
      <CheckableTag checked={false}><SearchOutlined /> {t('Search')}</CheckableTag>
      <CheckableTag checked={true}><FilterOutlined /> {t('Filter')} (1)</CheckableTag>
      <CheckableTag checked={false}><SortDescendingOutlined /> {t('Sort')}</CheckableTag>
      <CheckableTag checked={false}><EyeInvisibleOutlined /> {t('Hide')}</CheckableTag>
    </div>
  );
}
