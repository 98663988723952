import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DynamicCollapseTable, { DynamicCollapseTableProps, DynamicCollapseTableRefHandle } from './DynamicCollapseTable';
import { Spin, TagProps } from 'antd';
import { CustomColumnType, DynamicTableDataSource } from './DynamicTable';
import { baseIssueService } from 'services';
import { kpiData } from 'types/project';
import { BaseIssue } from 'model';
import { ISSUE_STATUSES } from 'utils/contants';
import { QueryParams, TableRowsSelected } from 'types';
import { useAppSelector } from 'store';
import { selectProjectCategoriesByFunction, selectProjectCategoriesByFunctionLoading } from 'store/my-projects.slice';
import BaseIssueMomCategory from 'model/BaseIssueMomCategory';
import { TablePaginationProps } from './TablePagination';

type DisciplineBaseIssueDataSource<T extends object> = DynamicCollapseTableProps<T>['data'];

type Data<T extends object> = {
  id: string;
  name?: string;
  tags?: TagProps[];
  dataSource?: DynamicTableDataSource<T>[];
  pagination?: Omit<TablePaginationProps, 'onSubmit'>;
  isLoading?: boolean;
  orderBy?: string;
};

type DisciplineBaseIssueTableProps<T extends object> = {
  header: string;
  columns: CustomColumnType<T>[];
  onRowClick?: DynamicCollapseTableProps<T>['onRowClick'];
  onActionClick: DynamicCollapseTableProps<T>['onActionClick'];
  onMapDataSource: (record: BaseIssue) => T;
  projectBaseIssueParams: QueryParams;
  excludeOverdue?: boolean;
  excludeOpprovedWithComment?: boolean;
  onTotalKpi: (kpi: kpiData) => void;
  handlerRowsKeyChange?: (value: React.Key[], id?: string) => void;
  selectedRowKeys?: TableRowsSelected;
  refreshDiscipline: () => void;
  module?: string;
};
export type DisciplineBaseIssueTableRefHandle = {
  refreshData: (momCategoryIds: string | undefined, baseIssueId: string | null) => void;
};

const CategoryBaseIssueTable = <T extends object>(
  props: DisciplineBaseIssueTableProps<T>,
  ref: React.Ref<DisciplineBaseIssueTableRefHandle>
) => {
  const { handlerRowsKeyChange, selectedRowKeys } = props;
  const tableRef = useRef<DynamicCollapseTableRefHandle>(null);
  const [dataSource, setDataSource] = useState<DisciplineBaseIssueDataSource<T>>([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const updateQueue = useRef<{ momCategoryIds?: string | undefined; baseIssueId?: string | null } | null>(null);
  const categories = useAppSelector(selectProjectCategoriesByFunction);
  const categoryLoading = useAppSelector(selectProjectCategoriesByFunctionLoading);
  useImperativeHandle(ref, () => ({
    refreshData: (momCategoryIds: string | undefined, baseIssueId: string | null) => {
      processRefreshDiscipline(momCategoryIds, baseIssueId);
    },
  }));

  const initDisciplineByWirList = async (data: Data<T>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { workspaceId, projectId, module, ...params } = props?.projectBaseIssueParams || {};
    const newParams = { ...params, 'momCategoryIds[]': `["${data.id}"]` };
    try {
      const response = await baseIssueService.getAllBaseIssues(workspaceId, projectId, newParams);
      data.dataSource = response?.rows?.map(props.onMapDataSource) || [];
      data.tags = generateTagsKpi(response?.kpiData);
      data.pagination = {
        issuesCount: response.count,
        issueParams: props.projectBaseIssueParams,
      };
      return data;
    } catch (error) {
      console.error(error);
    }
    return data;
  };

  const generateTagsKpi = (kpiData?: Record<string, number>) => {
    if (!kpiData) return [];

    return Object.keys(kpiData)
      .filter((kpiKey) => {
        const excludeOverdue = props.excludeOverdue ? kpiKey !== ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE : true;
        const excludeApprovedWithComment = props.excludeOpprovedWithComment
          ? kpiKey !== ISSUE_STATUSES.APPROVED_WITH_COMMENT
          : true;
        return excludeOverdue && excludeApprovedWithComment;
      })
      .map((baseIssueKpiKey) => {
        return {
          code: baseIssueKpiKey,
          id: `${kpiData?.[baseIssueKpiKey]}`,
          value: kpiData?.[baseIssueKpiKey],
          isOverdue: baseIssueKpiKey === ISSUE_STATUSES.REVIEW_IN_PROGRESS_OVERDUE,
        };
      });
  };

  const processRefreshDiscipline = (momCategoryIds?: string, baseIssueId?: string | null) => {
    const isNotExistDiscipline = dataSource.every((data) => data.id !== momCategoryIds);
    if (isNotExistDiscipline) {
      updateQueue.current = { momCategoryIds, baseIssueId };
      props?.refreshDiscipline?.();
      return;
    }

    if (!momCategoryIds) return;

    const selectedDisciplineId = dataSource?.find((data) => {
      const hasReloadId = data?.dataSource?.some?.((data) => data?.id === baseIssueId);
      if (hasReloadId) {
        return true;
      }
      return false;
    })?.id;

    if (selectedDisciplineId === momCategoryIds) {
      reFreshData(momCategoryIds);
    } else {
      reFreshData(momCategoryIds);
      if (selectedDisciplineId) {
        reFreshData(selectedDisciplineId);
      }
    }
  };

  const reFreshData = async (momCategoryIds: string) => {
    try {
      if (!momCategoryIds) return;
      const updatedIndex = dataSource?.findIndex((wirData) => wirData.id === momCategoryIds);
      let updatedParams = {};

      if (updatedIndex !== -1) {
        dataSource[updatedIndex].isLoading = true;
        setDataSource([...dataSource]);
        tableRef.current?.updateActiveKey(momCategoryIds);
        updatedParams = {
          orderBy: dataSource[updatedIndex]?.orderBy,
          page: dataSource[updatedIndex].pagination?.issueParams?.page,
          limit: dataSource[updatedIndex].pagination?.issueParams?.limit,
        };
      }
      const { workspaceId, projectId, ...params } = props.projectBaseIssueParams;
      const newParams = {
        ...params,
        ...updatedParams,
        'momCategoryIds[]': `["${momCategoryIds}"]`,
        module: undefined,
      };

      const response = await baseIssueService.getAllBaseIssues(workspaceId, projectId, newParams);

      if (updatedIndex !== -1) {
        dataSource[updatedIndex].dataSource = response.rows?.map(props.onMapDataSource) || [];
        dataSource[updatedIndex].tags = generateTagsKpi(response?.kpiData);
        dataSource[updatedIndex].isLoading = false;
        if (dataSource[updatedIndex].pagination) {
          dataSource[updatedIndex].pagination!.issuesCount = response.count;
        }
        setDataSource([...dataSource]);
      }
      if (response.rows?.length === 0) {
        processRefreshDiscipline('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaginationChange = (params?: { [key: string]: string }, momCategoryIds?: string) => {
    const updateIndex = dataSource.findIndex((wirData) => wirData.id === momCategoryIds);
    if (updateIndex !== -1) {
      dataSource[updateIndex].isLoading = true;
      dataSource[updateIndex].pagination = {
        issuesCount: dataSource[updateIndex].pagination?.issuesCount,
        issueParams: {
          ...params,
        },
      };

      reFreshData(momCategoryIds || '');
      setDataSource([...dataSource]);
    }
  };

  const generateDefaultDiscipline = (discipline: BaseIssueMomCategory) => {
    return {
      id: discipline.id,
      tags: [],
      dataSource: [],
      name: discipline?.name || '',
      pagination: {
        issuesCount: 0,
        issueParams: {
          limit: 10,
          page: 1,
        },
      },
    };
  };

  const buildDiscipline = async () => {
    setIsLoadingList(true);

    const result = categories?.map(async (discipline: BaseIssueMomCategory) => {
      const dataSource = generateDefaultDiscipline(discipline);
      const updatedDataSource = await initDisciplineByWirList(dataSource);
      return updatedDataSource;
    });

    const disciplinePromise = await Promise.all(result);
    const nonEmptyDisciplines = disciplinePromise.filter((discipline) => discipline.dataSource?.length);
    setDataSource(nonEmptyDisciplines as DisciplineBaseIssueDataSource<T>);
    setIsLoadingList(false);
  };

  useEffect(() => {
    if (!categories) return;
    if (categoryLoading !== 'succeeded') return;
    buildDiscipline();
  }, [categories?.length, props.projectBaseIssueParams, categoryLoading]);

  useEffect(() => {
    if (!dataSource.length) return;
    const totalKpi = dataSource.reduce(
      (result, initValue) => {
        if (!initValue?.tags?.length) return result;
        (initValue?.tags || []).forEach((tag) => {
          if (tag.code) {
            result[tag.code as keyof typeof result] += tag.value;
          }
        });
        return result;
      },
      { DR: 0, RIP: 0, ISD: 0 }
    );
    props?.onTotalKpi?.(totalKpi);
  }, [dataSource]);

  const handleSort = (id?: string) => {
    if (!id) return;
    reFreshData(id);
  };

  return (
    <Spin spinning={isLoadingList}>
      <DynamicCollapseTable
        ref={tableRef}
        columns={props?.columns}
        data={dataSource}
        header={props.header}
        onRowClick={props.onRowClick}
        module={props.module as 'Quality' | 'Safety'}
        onActionClick={props?.onActionClick}
        onPaginationChange={handlePaginationChange}
        onSort={handleSort}
        handlerRowsKeyChange={handlerRowsKeyChange}
        selectedRowKeys={selectedRowKeys}
      />
    </Spin>
  );
};

const DisciplineBaseIssueTableForwardRef = forwardRef(CategoryBaseIssueTable) as <T extends object>(
  props: DisciplineBaseIssueTableProps<T> & { ref?: React.Ref<DisciplineBaseIssueTableRefHandle> }
) => ReturnType<typeof DynamicCollapseTable>;

export default DisciplineBaseIssueTableForwardRef;
