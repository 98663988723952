import { InstructionCauseInput } from 'model/ProjectDataCustomization';
import { Key } from 'react';
import request from 'requesters/core.request';

const instructionService = {
  createOrUpdateInstructionCause: async (workspaceId: string, projectId: string, data: InstructionCauseInput[]) => {
    return request.post<InstructionCauseInput[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/instruction-causes-list`,
      data
    );
  },

  getAllInstructionCauses: async (workspaceId: string, projectId: string) => {
    return request.get<InstructionCauseInput[]>(`/workspaces/${workspaceId}/projects/${projectId}/instruction-causes`);
  },

  deleteInstructionCauseByID: async (workspaceId: string, projectId: string, instructionCauseId: Key[]) => {
    return request.delete(`/workspaces/${workspaceId}/projects/${projectId}/instruction-causes/${instructionCauseId}`, {
      params: instructionCauseId,
    });
  },
};

export default instructionService;
