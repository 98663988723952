import React, { useMemo, useState } from 'react';
import UserDistribution, { UserDistributionValue } from './UserDistribution';
import { mapDataToOptions } from 'utils/object';
import { Company, User } from 'model';
import { Col, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import EditableFormField from './EditableFormField';
import { useParams } from 'react-router-dom';

export type InvitationDistributionValue = {
  issuer: {
    companyId?: string;
    userId?: string;
  };
  receiver: {
    companyId?: string | null;
    userIds: string[];
  };
  distributions: UserDistributionValue[];
};

type FieldConfig = {
  title?: string;
  companyLabel?: string;
  userLabel?: string;
  isMultiUserSelect?: boolean;
};

type InvitationDistributionProps = {
  companies: Company[];
  users: User[];
  isReadOnly?: boolean;
  issuer?: FieldConfig;
  receiver?: FieldConfig;
  distribution?: FieldConfig;
};

const InvitationDistribution = (props?: InvitationDistributionProps) => {
  const { t } = useTranslation();
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const form = Form.useFormInstance();
  const watchedReceiver = Form.useWatch('receiver') as InvitationDistributionValue['receiver'];
  const watchedReceiverUserIds = watchedReceiver?.userIds || [];
  const issuer = form.getFieldValue('issuer') as InvitationDistributionValue['issuer'];
  const watchedDistributions = Form.useWatch('distributions') as UserDistributionValue[];
  const { projectId } = useParams();

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    const selectedUser = props?.users?.find((user) => user.WorkspaceUser?.Company?.id === companyId);
    form.setFieldsValue({
      receiver: {
        userIds: companyId && selectedUser?.id ? selectedUser?.id : null,
      },
    });
    form.validateFields([
      ['receiver', 'companyId'],
      ['receiver', 'userIds'],
    ]);
  };

  const onChangeReceiverName = (assigneeIds: string[]) => {
    if (!assigneeIds?.length) {
      form.setFieldsValue({
        receiver: {
          userIds: null,
          companyId: null,
        },
      });
      setSelectedCompanyId('');
    } else {
      const selectedUser = props?.users?.find((user) => user.id === assigneeIds[0]);
      const existingCompany = props?.companies?.findIndex(
        (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
      ) as number;
      if (props?.companies[existingCompany]?.id) setSelectedCompanyId(props.companies[existingCompany]?.id);
      if (existingCompany > -1) {
        form.setFieldsValue({
          receiver: {
            companyId: selectedUser?.WorkspaceUser?.Company?.id || '',
          },
        });
      } else {
        form.setFieldsValue({
          receiver: {
            companyId: null,
          },
        });
      }
    }

    form.validateFields([
      ['receiver', 'companyId'],
      ['receiver', 'userIds'],
    ]);
  };

  const assigneeFilter = useMemo(() => {
    const distributions = watchedDistributions?.flatMap((invitation) => invitation?.inspectorIds);
    const newUser = props?.users.filter((user) => !distributions?.includes(user.id));
    return newUser?.filter(
      (user) =>
        (!selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId) && user.id !== issuer?.userId
    );
  }, [selectedCompanyId, watchedDistributions, issuer, watchedReceiver?.userIds]);

  const distributionFilter = useMemo(() => {
    return props?.users.filter((user) => !watchedReceiverUserIds?.includes(user.id));
  }, [watchedReceiverUserIds]);

  return (
    <div className='w-full'>
      <div>
        <Typography.Title className='form-title' level={3}>
          {props?.issuer?.title || t('From')}
        </Typography.Title>
        <EditableFormField
          isReadonly={props?.isReadOnly}
          columns={[
            {
              label: props?.issuer?.companyLabel || t('Company Issuer'),
              name: ['issuer', 'companyId'],
              type: 'select',
              rules: [{ required: true, message: '' }],
              editableProps: {
                options: mapDataToOptions(props?.companies || []),
                placeholder: t(`Select ${props?.issuer?.companyLabel || 'Company Issuer'}`),
                disabled: true,
              },
            },
            {
              label: props?.issuer?.userLabel || t('Issuer Name'),
              name: ['issuer', 'userId'],
              type: 'select',
              rules: [{ required: true, message: '' }],
              editableProps: {
                options: mapDataToOptions(props?.users || [], 'fullName'),
                placeholder: t(`Select ${props?.issuer?.userLabel || 'Issuer Name'}`),
                disabled: true,
              },
            },
          ]}
        />
      </div>
      <div>
        <Typography.Title className='form-title' level={3}>
          {props?.receiver?.title || t('To')}
        </Typography.Title>

        <EditableFormField
          isReadonly={props?.isReadOnly}
          columns={[
            {
              label: props?.receiver?.companyLabel || t('Company'),
              name: ['receiver', 'companyId'],
              type: 'select',
              rules: [{ required: true, message: '' }],
              editableProps: {
                options: props?.companies?.map((option) => ({
                  value: option.id,
                  label: option.name,
                })),
                placeholder: t(`Select ${props?.receiver?.companyLabel || 'Company'}`),
                onChange: onChangeCompany,
              },
            },
            {
              label: props?.receiver?.userLabel || t('Recipient Name'),
              name: ['receiver', 'userIds'],
              type: props?.receiver?.isMultiUserSelect ? 'multiple' : 'select',
              rules: [{ required: true, message: '' }],
              editableProps: {
                options: assigneeFilter?.map((option) => {
                  return {
                    value: option.id,
                    label: option.fullName,
                  };
                }),
                placeholder: t(`Select ${props?.receiver?.userLabel || 'Recipient Name'}`),
                onChange: (value: string | string[]) => {
                  if (typeof value === 'object') {
                    onChangeReceiverName(value);
                  } else {
                    onChangeReceiverName(value ? [value] : []);
                  }
                },
              },
            },
          ]}
        />
      </div>
      <div>
        {props?.isReadOnly ? (
          <>
            <Typography.Title className='form-title' level={3}>
              {props.distribution?.title || t('Distribution')}
            </Typography.Title>
            <Row gutter={16}>
              <Col span={12} className='leading-[32px]'>
                <span className='text-size-12 text-color-secondary'>
                  {props.distribution?.companyLabel || t('Company Name')}
                </span>
              </Col>
              <Col span={12} className='leading-[32px]'>
                <span className='text-size-12 text-color-secondary'>
                  {props.distribution?.userLabel || t('Emails / Names')}
                </span>
              </Col>
            </Row>
            {(form.getFieldValue('distributions') as UserDistributionValue[])?.map((item, index: number) => {
              const company = props?.companies?.find((company) => company?.id === item.companyId);
              return (
                <Row gutter={16} key={index} className='mb-2'>
                  <Col span={12}>
                    <Typography.Text>{company?.name}</Typography.Text>
                  </Col>
                  <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    {item.inspectorIds?.map((userId, index) => {
                      const selectedUser = props?.users.find((user) => user.id === userId);
                      return <Typography.Text key={index}>{selectedUser?.fullName}</Typography.Text>;
                    })}
                  </Col>
                </Row>
              );
            })}
          </>
        ) : (
          <UserDistribution
            form={form}
            projectId={projectId as string}
            companyOriginator={''}
            users={distributionFilter || []}
            isOpen={false}
            isReadOnly={!!props?.isReadOnly}
            formName='distributions'
            maxCompanies={3}
          />
        )}
      </div>
    </div>
  );
};

export default InvitationDistribution;
