import * as d3 from 'd3';
import { FC, useEffect, useRef, useState } from 'react';

import { useResizeObserver } from './useResizeObserver';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';
import { useTranslation } from 'react-i18next';
import { titleCase } from 'utils';

interface IData {
  group: string;
  DR: number;
  OP: number;
  RFI: number;
  CL: number;
}

interface IChart {
  data: IData[];
  svgWrapperRef: any;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  isYaxisRight?: boolean;
  loading?: any;
}
const BarplotGrouped: FC<IChart> = ({ data, svgWrapperRef, loading }) => {
  const { t } = useTranslation();
  const dimensions: any = useResizeObserver(svgWrapperRef);
  const svgRef = useRef<SVGSVGElement>(null);
  
  const margin = { top: 30, right: 30, bottom: 20, left: 60 };
  const FONT_SIZE = '11px';
  const LEGEND_WIDTH = 15;
  const subgroups = ['DR', 'OP', 'RFI', 'CL'];
  const statuses: { [key: string]: string } = {
    DR: t('Draft'),
    OP: t('Open'),
    RFI: titleCase(t('Ready for review')),
    CL: t('Closed'),
  };
  // color palette = one color per subgroup
  const color = d3
    .scaleOrdinal()
    .domain(['DR', 'OP', 'RFI', 'CL'])
    .range([
      TASK_STATUS_HEADER_COLOR.DR,
      TASK_STATUS_HEADER_COLOR.OP,
      TASK_STATUS_HEADER_COLOR.RFI,
      TASK_STATUS_HEADER_COLOR.CL,
    ]);
  

  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;
    const height = dimensions?.height - (margin?.top + margin?.bottom);
    const width = dimensions?.width - margin?.right;
    const maxValue = Math.max(
      ...data?.map((d: IData) => Math.max(d.DR, d.OP, d.RFI, d.CL))
    );

    const svg = d3.select(svgRef?.current);
    svg.selectAll<SVGGElement, any>('g').remove();

    // Add X axis
    const x: any = d3
      .scaleBand()
      .domain([...data?.map(({ group }) => group)])
      .rangeRound([margin.left, width - margin.right])
      .padding(0.2);

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, maxValue])
      .rangeRound([height, margin.top + margin.bottom]);

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding(0.05);
      
    // Show the bars
    if (loading === 'idle') {
      svg
        .append('g')
        .selectAll('g')
        // Enter in data = loop group per group
        .data(data)
        .enter()
        .append('g')
        .attr('transform', (d) => 'translate(' + x(d.group) + ',0)')
        .selectAll('rect')
        .data((d: any) => {
          return subgroups.map((key) => {
            return { group: d.group, key: key, value: d[key] };
          });
        })
        .enter()
        .append('rect')
        .attr('x', (d) => xSubgroup(d.key) ?? 0)
        .attr('y', (d) => y(d.value))
        // .attr('y', (d) => height)
        .attr('width', xSubgroup.bandwidth())
        .attr('height', (d) => height - y(d.value))
        // .attr('height', 0)
        .attr('fill', (d) => color(d.key) as string)
        .on('mouseenter', (event, item) => {
          svg
            .selectAll('.tooltip')
            .data([item])
            .join((enter) => enter.append('text').attr('y', y(item?.value) - 4))
            .attr('class', 'tooltip')
            .text(`${item?.value}`)
            .attr(
              'x',
              x(item.group) + xSubgroup(item.key) + xSubgroup.bandwidth() / 2 ??
                0
            )
            .style('font-size', FONT_SIZE)
            .transition()
            .duration(500)
            .attr('y', y(item?.value) - 8)
            .style('font-size', '14px')
            .attr('fill', color(item.key) as string)
            //make text bold
            .style('font-weight', 'bold')
            .style('opacity', 1)
            .attr('text-anchor', 'middle');
        })
        .on('mouseleave', () => svg.select('.tooltip').remove());
    }

    svg
      // .select('.x-axis')
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).tickSizeOuter(0));

    svg
      // .select('.y-axis')
      .append('g')
      .attr('class', 'x-axis')
      .style('transform', `translateX(${margin?.left}px)`)
      .call(d3.axisLeft(y).tickSizeOuter(0));

    // Legend
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${dimensions.width / 4}, 0)`);
    legend
      .selectAll('rect')
      .data(Object.entries(statuses))
      .enter()
      .append('rect')
      .attr('fill', (d: [string, string]) => color(d[0]) as string)
      .attr('x', (_, i) => (dimensions.width / 6) * i)
      .attr('y', 0)
      .attr('width', LEGEND_WIDTH)
      .attr('height', LEGEND_WIDTH);

    legend
      .selectAll('.legend-label')
      .data(Object.entries(statuses))
      .enter()
      .append('text')
      .attr('class', 'legend-label')
      .style('font-family', 'sans-serif')
      .style('font-size', FONT_SIZE)
      .attr('x', (_, i) => (dimensions.width / 6) * i + LEGEND_WIDTH + 5)
      .attr('y', LEGEND_WIDTH)
      .text((d) => d[1]);
  }, [dimensions]);

  useEffect(() => {
    if (!svgRef?.current || !dimensions) return;
    const height = dimensions?.height - (margin?.top + margin?.bottom);
    const width = dimensions?.width - margin?.right;
    const maxValue = Math.max(
      ...data?.map((d: IData) => Math.max(d.DR, d.OP, d.RFI, d.CL))
    );

    const svg = d3.select(svgRef?.current);
    svg.selectAll<SVGGElement, any>('g').remove();
    // Add X axis
    const x: any = d3
      .scaleBand()
      .domain([...data?.map(({ group }) => group)])
      .rangeRound([margin.left, width - margin.right])
      .padding(0.2);

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, maxValue])
      .rangeRound([height, margin.top + margin.bottom]);

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding(0.05);

    // Show the bars
    if (loading === 'idle') {
      svg
        .append('g')
        .selectAll('g')
        // Enter in data = loop group per group
        .data(data)
        .enter()
        .append('g')
        .attr('transform', (d) => 'translate(' + x(d.group) + ',0)')
        .selectAll('rect')
        .data((d: any) => {
          return subgroups.map((key) => {
            return { group: d.group, key: key, value: d[key] };
          });
        })
        .enter()
        .append('rect')
        .attr('x', (d) => xSubgroup(d.key) ?? 0)
        // .attr('y', (d) => y(d.value))
        .attr('y', (d) => height)
        .attr('width', xSubgroup.bandwidth())
        // .attr('height', (d) => height - y(d.value))
        .attr('height', 0)
        .attr('fill', (d) => color(d.key) as string)
        .on('mouseenter', (event, item) => {
          svg
            .selectAll('.tooltip')
            .data([item])
            .join((enter) => enter.append('text').attr('y', y(item?.value) - 4))
            .attr('class', 'tooltip')
            .text(`${item?.value}`)
            .attr(
              'x',
              x(item.group) + xSubgroup(item.key) + xSubgroup.bandwidth() / 2 ??
                0
            )
            .style('font-size', FONT_SIZE)
            .transition()
            .duration(500)
            .attr('y', y(item?.value) - 8)
            .style('font-size', '14px')
            .attr('fill', color(item.key) as string)
            //make text bold
            .style('font-weight', 'bold')
            .style('opacity', 1)
            .attr('text-anchor', 'middle');
        })
        .on('mouseleave', () => svg.select('.tooltip').remove())
        .transition()
        .duration(1000)
        .attr('y', (d) => y(d.value))
        .attr('height', (d) => height - y(d.value));

      svg
        // .select('.x-axis')
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .selectAll('.tick text')
        .style('opacity', 0)
        .transition()
        .duration(1000)
        .style('opacity', 1);

      svg
        // .select('.y-axis')
        .append('g')
        .attr('class', 'x-axis')
        .style('transform', `translateX(${margin?.left}px)`)
        .call(d3.axisLeft(y).tickSizeOuter(0))
        .selectAll('.tick text')
        .style('opacity', 0)
        .transition()
        .duration(1000)
        .style('opacity', 1);
    }
    // Legend
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${dimensions.width / 4}, 0)`);
    legend
      .selectAll('rect')
      .data(Object.entries(statuses))
      .enter()
      .append('rect')
      .attr('fill', (d: [string, string]) => color(d[0]) as string)
      .attr('x', (_, i) => (dimensions.width / 6) * i)
      .attr('y', 0)
      .attr('width', LEGEND_WIDTH)
      .attr('height', LEGEND_WIDTH);

    legend
      .selectAll('.legend-label')
      .data(Object.entries(statuses))
      .enter()
      .append('text')
      .attr('class', 'legend-label')
      .style('font-family', 'sans-serif')
      .style('font-size', FONT_SIZE)
      .attr('x', (_, i) => (dimensions.width / 6) * i + LEGEND_WIDTH + 5)
      .attr('y', LEGEND_WIDTH)
      .text((d) => d[1]);
  }, [data]);

  return (
    <div>
      <svg ref={svgRef} width={dimensions?.width ?? 0} height={300}>
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
    </div>
  );
};

export default BarplotGrouped;
