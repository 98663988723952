import React, { ReactNode, useRef, useState } from 'react';
import NotificationHeader from './NotificationListHeader';
import { Popover } from 'antd';
import NotificationListTabs from './NotificationListTabs';


type NotificationListProps = {
  children: ReactNode;
};

const NotificationList: React.FC<NotificationListProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [ isFetching, setIsFetching] = useState(false);
  const scrollDemoRef = useRef(null);

  const handleScroll = () => {

    if (scrollDemoRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDemoRef.current;
      const threshold = 100;
      if (scrollHeight - (scrollTop + clientHeight) <= threshold) {
        if (!isFetching) {
          setIsFetching(true);
        }
      }
    }
  };

  const renderContent = (): JSX.Element => {
    return (
      <div
        onScroll={handleScroll}
        ref={scrollDemoRef}
        className="overflow-auto	h-[calc(100vh-105px)]  w-[609px] p-6">
        <NotificationHeader onNavigateNotification={() => setOpen(false)} />
        <NotificationListTabs
          setOpen={setOpen}
          showMoreOption={false}
          isFetching={isFetching}
          setIsFetching={setIsFetching}
        />
      </div>
    );
  };

  return (
    <Popover
      trigger={'click'}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement={'bottomRight'}
      content={renderContent()}>
      {props.children}
    </Popover>
  );
};

export default NotificationList;
