import { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import L, { LatLng, LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import icon from 'assets/images/leaflet/marker-tool-pin-fill.svg';
import IconPinDR from 'assets/images/leaflet/pin-DR.svg';
import IconPinOP from 'assets/images/leaflet/pin-OP.svg';
import IconPinOO from 'assets/images/leaflet/pin-OO.svg';
import IconPinRFI from 'assets/images/leaflet/pin-RFI.svg';
import IconPinRIO from 'assets/images/leaflet/pin-RIO.svg';
import IconPinCL from 'assets/images/leaflet/pin-CL.svg';
import { Map, LatLngBoundsLiteral, CRS } from 'leaflet';
import {
  FeatureGroup,
  ImageOverlay,
  MapContainer,
  Marker,
  useMapEvents,
  Rectangle,
  Circle,
  Polyline,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Issue, IssueStatus } from 'model';
import dayjs from 'dayjs';
import LeafletCustomControl from './LeafletCustomControl';
import type { FeatureCollection, Feature } from 'geojson';
import { LayerData } from 'types';
import { convertGeometry } from 'utils';
import ImageOverlayItem from 'components/common/pre-signed-url-items/ImageOverlayItem';

delete L.Icon.Default.prototype['_getIconUrl' as any as keyof L.Icon.Default];
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: null,
  iconRetinaUrl: icon,
  iconSize: [28, 28],
  iconAnchor: [14, 28],
  // popupAnchor : [-3, -76]
});

type OverlayImageProps = {
  imageBounds: LatLngBoundsLiteral;
  url: string;
};

const OverlayImage = (props: OverlayImageProps) => {
  const { imageBounds, url } = props;
  return (
    <ImageOverlayItem
      bounds={imageBounds}
      url={url}
      // transparent={true}
    />
  );
};

let isEnablePaint = false;
const PaintHandler = ({
  map,
  onChange,
}: {
  map: Map;
  onEnd: Function;
  onChange: (value: LayerData | null) => void;
}) => {
  let myPolyline: L.Polyline;
  let paintMode = false;
  useMapEvents({
    mousedown() {
      if (!isEnablePaint) return;
      paintMode = true;
      if (paintMode) {
        myPolyline = L.polyline([], { color: 'red', weight: 2 }).addTo(map as Map);
      }
    },
    mouseup() {
      if (myPolyline) {
        console.log(myPolyline);
        myPolyline.remove();
        onChange({
          type: 'Feature',
          properties: layerProperties,
          geometry: {
            type: 'LineString',
            coordinates: (myPolyline.getLatLngs() as LatLng[]).map((latlng) => [latlng.lat, latlng.lng]),
          },
        });
      }
      paintMode = false;
    },
    mousemove(e: any) {
      if (paintMode) {
        myPolyline.addLatLng(e.latlng);
      }
    },
  });
  return null;
};

const layerProperties = {
  color: 'red',
  weight: 2,
  opacity: 1,
  fillOpacity: 0,
};
type LocationPlanProps = {
  width?: number;
  height?: number;
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
  disabled?: boolean;
  data?: LayerData | null;
  onChange: (value: LayerData | null, zoomRatio?: number) => void;
  issues?: Issue[];
  selectedIssue?: any;
  setSelectedIssue?: (value: any) => void;
  issueStatuses?: IssueStatus[];
  module: string;
};

const LocationPlan = (props: LocationPlanProps) => {
  const {
    width,
    height,
    imageUrl,
    imageWidth,
    imageHeight,
    issues,
    issueStatuses,
    setSelectedIssue,
    data,
    onChange,
    module,
    disabled,
  } = props;
  const [featureGroup, setFeatureGroup] = useState<L.FeatureGroup | null>(null);
  const [layerDisplay, setLayerDisplay] = useState<LayerData | null>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [imageOverlay, setImageOverlay] = useState<any>(null);
  const bounds: LatLngBoundsLiteral = useMemo(
    () => [
      [0, 0],
      [height ? height : imageHeight, width ? width : imageWidth],
    ],
    [height, width, imageHeight, imageWidth]
  );
  const maxBounds: LatLngBoundsLiteral = useMemo(
    () => [
      [-50, -50],
      [height ? height + 50 : imageHeight + 50, width ? width + 50 : imageWidth + 50],
    ],
    [height, width, imageHeight, imageWidth]
  );
  const zoomRatio: number = useMemo(() => (width ? width / imageWidth : 1), [width, imageWidth]);

  useEffect(() => {
    if (bounds) {
      setImageOverlay(<OverlayImage imageBounds={bounds} url={imageUrl} />);
    }
  }, [bounds, imageUrl]);

  useEffect(() => {
    if (layerDisplay) {
      const updateLayerDisplay = structuredClone(layerDisplay) as LayerData;
      if (updateLayerDisplay?.geometry) {
        updateLayerDisplay.geometry = convertGeometry(updateLayerDisplay.geometry, 1 / zoomRatio);
      }
      onChange(updateLayerDisplay, zoomRatio);
    }
    isEnablePaint = false;
    if (map) {
      map.getContainer().style.cursor = 'default';
    }
  }, [layerDisplay, zoomRatio]);

  useEffect(() => {
    if (data === null) {
      setLayerDisplay(null);
    }
    if (data && layerDisplay === null) {
      const convertedLayer = structuredClone(data) as LayerData;
      if (convertedLayer.geometry) {
        convertedLayer.geometry = convertGeometry(convertedLayer.geometry, zoomRatio);
      }
      setLayerDisplay(convertedLayer);
    }
  }, [data, zoomRatio]);

  const getIssueMarker = (issueId: string) => {
    const issue = issues?.find((i) => i.id === issueId);
    if (issue) {
      const isExpired: boolean = issue?.plannedEndDate
        ? dayjs(issue?.plannedEndDate) < dayjs().subtract(1, 'day')
        : false;

      const issueStatusMap: Record<string, string> = {
        DR: IconPinDR,
        OP: isExpired ? IconPinOO : IconPinOP,
        RFI: isExpired ? IconPinRIO : IconPinRFI,
        CL: IconPinCL,
      };
      const issueStatus = issueStatuses?.find((status) => status.id === issue.issueStatusId)?.code;
      const pinIcon = issueStatusMap[issueStatus as string] || icon;

      return new L.Icon({
        iconUrl: pinIcon,
        iconRetinaUrl: pinIcon,
        iconSize: [28, 28],
        iconAnchor: [14, 28],
      });
    }
  };

  useEffect(() => {
    if (issues?.length && featureGroup && zoomRatio) {
      if (featureGroup?.getLayers()) {
        featureGroup.clearLayers();
      }

      const geojson: FeatureCollection = {
        type: 'FeatureCollection',
        features: issues
          .filter((i) => i.Attachments?.length && i.Attachments[0]?.IssueAttachment?.layerData)
          .map((issue) => {
            const _layerData = structuredClone(issue?.Attachments?.[0]?.IssueAttachment?.layerData) as LayerData;
            _layerData.id = issue.id;
            if (_layerData?.geometry) {
              _layerData.geometry = convertGeometry(_layerData.geometry, zoomRatio, true);
            }
            return _layerData as Feature;
          }),
      };
      console.log(geojson);
      L.geoJSON(geojson, {
        style: (feature) => feature?.properties,
        pointToLayer: (feature, latlng) => {
          return L.marker(latlng, {
            icon: getIssueMarker(feature.id as string),
          });
        },
      }).eachLayer((layer) => {
        layer.on('click', (e) => {
          if (setSelectedIssue) {
            const selectedIssue = issues?.find((i) => i.id === e.target.feature.id);
            if (selectedIssue) {
              setSelectedIssue(selectedIssue);
            }
          }
        });
        if (
          layer instanceof L.Polyline ||
          layer instanceof L.Polygon ||
          layer instanceof L.Marker ||
          layer instanceof L.Rectangle
        ) {
          if (layer?.feature?.geometry?.radius) {
            const circle = new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
              radius: layer.feature?.geometry.radius,
              ...layer.feature?.properties,
              id: layer.feature?.id,
            }).addTo(featureGroup);
            circle.on('click', (e: L.LeafletMouseEvent) => {
              if (setSelectedIssue) {
                const selectedIssue = issues?.find((i) => i.id === e.target.options.id);
                if (selectedIssue) {
                  setSelectedIssue(selectedIssue);
                }
              }
            });
          } else {
            featureGroup?.addLayer(layer);
          }
        }
      });
    }
  }, [issues, issueStatuses, bounds, featureGroup, zoomRatio]);

  useEffect(() => {
    if (map) {
      map.invalidateSize(true);
      map.setMaxBounds(maxBounds);
      map.fitBounds(bounds);
      map.setMinZoom(map.getBoundsZoom(bounds, false));
      map.on('draw:drawstart', function (e: any) {
        isEnablePaint = false;
      });
    }
  }, [width, height, map]);

  const onControlCreated = (e: any) => {
    const { layerType, layer } = e;
    layer.remove();
    if (layerType === 'marker') {
      // Handle marker creation
      const { lat, lng } = (layer as L.Marker).getLatLng();
      setLayerDisplay({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [lat, lng],
        },
      });
    } else if (layerType === 'rectangle') {
      // Handle rectangle creation
      setLayerDisplay({
        type: 'Feature',
        properties: layerProperties,
        geometry: {
          type: 'Polygon',
          coordinates: [((layer as L.Rectangle).getLatLngs()[0] as LatLng[]).map((latlng) => [latlng.lat, latlng.lng])],
        },
      });
    } else if (layerType === 'circle') {
      // Handle circle creation
      const { lat, lng } = (layer as L.Marker).getLatLng();
      setLayerDisplay({
        type: 'Feature',
        properties: layerProperties,
        geometry: {
          type: 'Polygon',
          radius: (layer as L.Circle).getRadius(),
          coordinates: [lat, lng],
        },
      });
    } else {
      // Handle other layer types (e.g., polyline, polygon, rectangle, circle)
    }
  };
  const markerHandlers = useMemo(
    () => ({
      dragend(e: any) {
        const { lat, lng } = e.target.getLatLng();
        setLayerDisplay({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [lat, lng],
          },
        });
      },
    }),
    []
  );

  const onDrawing = (map: Map | null) => {
    isEnablePaint = true;
    if (map) {
      map.dragging.disable();
      map.getContainer().style.cursor = 'crosshair';
    }
  };

  const renderLayers = useMemo(() => {
    switch (layerDisplay?.geometry?.type) {
      case 'Point':
        return (
          <Marker
            eventHandlers={markerHandlers}
            draggable={!disabled}
            position={layerDisplay?.geometry?.coordinates as LatLngExpression}
          />
        );
      case 'Polygon':
        if (layerDisplay?.geometry?.radius) {
          return (
            <Circle
              pathOptions={{ ...layerDisplay?.properties, interactive: true }}
              center={layerDisplay?.geometry?.coordinates as LatLngExpression}
              radius={layerDisplay?.geometry?.radius}
            />
          );
        }
        return (
          <Rectangle
            pathOptions={{ ...layerDisplay?.properties, interactive: true }}
            bounds={layerDisplay?.geometry?.coordinates as LatLngBoundsExpression}
          />
        );
      case 'LineString':
        return (
          <Polyline
            pathOptions={layerDisplay?.properties}
            positions={layerDisplay?.geometry?.coordinates as LatLngExpression[]}
          />
        );
    }
  }, [layerDisplay]);

  return (
    <Spin spinning={false}>
      <div style={{ width, height }} id='map-wrapper'>
        <MapContainer
          id={`map-container-${module === 'Safety' ? 'safety' : 'quality'}`}
          ref={setMap}
          style={{ height: '100%', minHeight: '100%' }}
          bounds={bounds}
          maxBounds={maxBounds}
          crs={CRS.Simple}
          minZoom={-10}
          maxZoom={5}
          zoomSnap={0}
          scrollWheelZoom={true}
        >
          <PaintHandler
            onEnd={() => {
              isEnablePaint = false;
              map?.dragging.enable();
            }}
            map={map as Map}
            onChange={setLayerDisplay}
          />
          <FeatureGroup ref={setFeatureGroup} />
          <FeatureGroup>
            {!disabled && (
              <EditControl
                position='topleft'
                edit={{
                  edit: false,
                  remove: false,
                }}
                onCreated={onControlCreated}
                draw={{
                  rectangle: { shapeOptions: layerProperties },
                  circle: { shapeOptions: layerProperties },
                  polygon: false,
                  circlemarker: false,
                  polyline: false,
                }}
              />
            )}
            {renderLayers}
          </FeatureGroup>
          {!disabled && (
            <LeafletCustomControl prepend position='topleft'>
              <div className='leaflet-draw-draw-paint div-control' onClick={() => onDrawing(map)} />
            </LeafletCustomControl>
          )}
          {imageOverlay}
        </MapContainer>
      </div>
    </Spin>
  );
};

export { LocationPlan };
