import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Select,
  TimePicker,
  Typography,
} from 'antd';
import { CustomizeRequiredMark, ReadOnlyDataField } from 'components/common';
import { BaseIssue, User, Company, WorkPackage, AttachmentInfo, Attachment } from 'model';
import { useTranslation } from 'react-i18next';
import { MARsInformation } from 'components';
import { attachmentService, baseIssueService } from 'services';
import { useAttachmentTypes, useAttachmentTypesParams, useProjectZones, useProjectZonesParams } from 'hooks';
import MaterialDetailForm from './MaterialDetail';

const { Option } = Select;

type CreateMDIsDetailProps = {
  form: FormInstance;
  users: User[];
  isEdit: boolean;
  projectId: string;
  setCreateMDIData: (data: { originatorId: string; companyOriginatorId: string }) => void;
  setRequestName: (data: string) => void;
  documentsInfo: AttachmentInfo[];
  setDocumentsInfo: (value: AttachmentInfo[]) => void;
  photosInfo: AttachmentInfo[];
  setPhotosInfo: (value: AttachmentInfo[]) => void;
  isReject: boolean;
  isOpen: boolean;
  MARs: BaseIssue[];
  workspaceId: string;
  companyOriginator: string | undefined;
  baseIssueData: BaseIssue | null;
  companies: Company[];
  isDraft: boolean;
  workPackages?: WorkPackage[];
};

const { Title } = Typography;

export default function CreateMDIsDetail(props: CreateMDIsDetailProps) {
  const {
    form,
    users,
    isEdit,
    projectId,
    workspaceId,
    MARs,
    setCreateMDIData,
    setRequestName,
    isOpen,
    companyOriginator,
    baseIssueData,
    workPackages,
    documentsInfo,
    setDocumentsInfo,
    setPhotosInfo,
    photosInfo,
  } = props;

  const { t } = useTranslation();
  const [baseIssueId, setBaseIssueId] = useState<string>('');
  const [MAR, setMAR] = useState<BaseIssue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [marAttachments, setMarAttachments] = useState<Attachment[]>([]);

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const handleChange = (value: string) => {
    setBaseIssueId(value);
    form.setFieldValue('marId', value);
  };
  const handleFetchMAR = async () => {
    const res = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
      type: 'MAR',
      include: 'BaseIssueWatchers|IssueStatus|Zone|ModuleFunction|Company|BaseIssueCategories|BaseIssueAttachments',
    });

    return res;
  };
  const fetchAttachments = async () => {
    setAttachmentLoading(true);
    if (baseIssueData) {
      const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
        projectId,
        baseIssueId: baseIssueData.id,
        limit: 'unlimited',
      });
      setAttachments(rows);
    }
    setAttachmentLoading(false);
  };
  useEffect(() => {
    if (baseIssueData) {
      setBaseIssueId(baseIssueData?.MaterialDeliveryInspection?.MaterialApprovalRequest?.baseIssueId || '');
    } else if (!baseIssueData) {
      setBaseIssueId('');
      setMAR(null);
      form.setFieldsValue('');
    }
  }, [baseIssueData]);
  useEffect(() => {
    if (baseIssueId && isOpen && baseIssueData) {
      setLoading(true);
      handleFetchMAR().then((res) => {
        setLoading(false);
        setMAR(res);
        setCreateMDIData({
          originatorId: res.originatorId,
          companyOriginatorId: res.companyOriginatorId,
        });
        form.setFieldValue('name', baseIssueData.name);
        setRequestName(baseIssueData.name);
      });
      if (baseIssueData?.MaterialDeliveryInspection?.inspectionDate) {
        const inspectionDate = dayjs(baseIssueData.MaterialDeliveryInspection.inspectionDate).hour();
        if (inspectionDate < 8) {
          form.setFieldValue(
            'inspectionDate',
            dayjs(baseIssueData?.MaterialDeliveryInspection?.inspectionDate, 'YYYY-MM-DD').add(1, 'day')
          );
        } else {
          form.setFieldValue(
            'inspectionDate',
            dayjs(baseIssueData?.MaterialDeliveryInspection?.inspectionDate, 'YYYY-MM-DD')
          );
        }
        form.setFieldValue('inspectionTime', dayjs(baseIssueData?.MaterialDeliveryInspection?.inspectionDate));
      }
    } else if (baseIssueId && isOpen) {
      setLoading(true);
      handleFetchMAR().then((res) => {
        setLoading(false);
        setMAR(res);
        setCreateMDIData({
          originatorId: res.originatorId,
          companyOriginatorId: res.companyOriginatorId,
        });
        form.setFieldValue('name', `${res.name} Inspection`);
        setRequestName(`${res.name} Inspection`);
      });
    }
  }, [baseIssueId, isOpen]);

  useEffect(() => {
    if (MAR?.id) {
      form.setFieldsValue({ marId: MAR?.id });
      form.setFieldsValue({ quantity: baseIssueData?.MaterialDeliveryInspection?.quantity });
    }
  }, [MAR, form, baseIssueData]);
  useEffect(() => {
    if (isOpen && baseIssueData) {
      fetchAttachments();
      fetchMarAttachments();
    } else {
      form.setFieldsValue('');
      setBaseIssueId('');
      setMAR(null);
      setAttachments([]);
      setMarAttachments([]);
    }
  }, [isOpen, baseIssueData]);

  useEffect(() => {
    const nameFieldValue = form.getFieldValue('name');
    setRequestName(nameFieldValue);
  }, [form.getFieldValue('name')]);
  const fetchMarAttachments = async (currentPosition?: string) => {
    try {
      const materialProposed = (MAR?.MaterialApprovalRequest?.MarDescriptions || []).find(
        (material: { isProposed: boolean }) => material.isProposed === true
      );
      let marDescriptionId = materialProposed?.id;
      if (currentPosition === 'specified') {
        const materialSpecified = (MAR?.MaterialApprovalRequest?.MarDescriptions || []).find(
          (material: { isProposed: boolean }) => material.isProposed === false
        );
        marDescriptionId = materialSpecified?.id;
      }
      if (MAR) {
        const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
          projectId,
          baseIssueId: MAR?.MaterialApprovalRequest?.baseIssueId,
          marDescriptionId: marDescriptionId,
          limit: 'unlimited',
        });
        setMarAttachments(rows);
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
    }
  };
  return (
    <Form requiredMark={CustomizeRequiredMark} form={form} layout='vertical'>
      <div className='create-mar-form'>
        <Row className={'ready-only-mar-container px-3'} gutter={16}>
          <Col span={11}>
            <div className='read-only-mar-form'>
              <Title level={3} className='read-only-mar-title'>
                {t('MAR Reference')}
              </Title>
              {isEdit ? (
                <Row className={'read-only-row'} style={{ margin: '15px 0' }}>
                  <Col span={24}>
                    <div className={'read-only-column-data'}>
                      {MARs && (
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                          name={'marId'}
                          label={t('Search a MAR by Name or Number')}
                        >
                          <Select
                            variant={'outlined'}
                            optionFilterProp={'label'}
                            filterOption={(input: string, option: { label: string; value: string } | undefined) => {
                              if (!option) return false;

                              const baseIssue = MARs.find(
                                (item) =>
                                  item.id === option.value && item?.MaterialApprovalRequest?.disciplineId !== null
                              );
                              const matchesName =
                                !!baseIssue?.name && baseIssue.name.toLowerCase().includes(input.toLowerCase());
                              const matchesMarNumber =
                                !!baseIssue?.MaterialApprovalRequest?.marNumber &&
                                baseIssue.MaterialApprovalRequest.marNumber.toLowerCase().includes(input.toLowerCase());

                              return matchesName || matchesMarNumber;
                            }}
                            allowClear
                            onClear={() => {
                              form.resetFields(['name']);
                              setRequestName('');
                            }}
                            className='mdi-selection-mars'
                            placeholder={MAR?.name ? MAR.name : t('Please select MAR')}
                            style={{ flex: 1 }}
                            showSearch
                            optionLabelProp='label'
                            onChange={handleChange}
                            value={baseIssueId}
                            disabled={MARs && MARs.length <= 0}
                          >
                            {MARs &&
                              MARs.filter((baseIssue) => baseIssue?.MaterialApprovalRequest?.disciplineId !== null).map(
                                (baseIssue) => (
                                  <Option
                                    key={baseIssue?.MaterialApprovalRequest?.id}
                                    value={baseIssue.id}
                                    label={baseIssue.name}
                                  >
                                    <div className='custom-option'>
                                      <div className='option-name'>{baseIssue.name}</div>
                                      <div className='option-code'>{baseIssue.MaterialApprovalRequest?.marNumber}</div>
                                      <div className='option-submit-at'>
                                        <span>{t('Submitted on: ')}</span>
                                        <span>
                                          {dayjs(baseIssue.createdAt).format('DD-MM-YYYY')}
                                          {t(' by ')}
                                        </span>
                                        <span>
                                          {users.find((user) => user.id === baseIssue.originatorId)?.fullName}
                                        </span>
                                        <span>
                                          {' - '}
                                          {baseIssue.Company?.name}
                                        </span>
                                      </div>
                                    </div>
                                  </Option>
                                )
                              )}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className={'read-only-row'} style={{ margin: '15px 0' }}>
                  <Col span={24}>
                    <div className={'read-only-column-data'}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                        label={t('Search a MAR by Name or Number')}
                      >
                        <Input disabled name={'marId'} value={MAR?.name} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              )}
              <Title level={3} className='read-only-mar-title'>
                {t('MAR Information')}
              </Title>

              {baseIssueId ? (
                <MARsInformation
                  users={users}
                  MAR={MAR || null}
                  loading={loading}
                  attachmentTypes={attachmentTypes}
                  workPackages={workPackages}
                  zones={zones}
                  attachments={marAttachments}
                  projectId={projectId}
                  workspaceId={workspaceId}
                  fetchMarAttachments={(position?: string) => fetchMarAttachments(position)}
                />
              ) : (
                <Row
                  className={'read-only-row'}
                  style={{
                    margin: '15px 0',
                    display: 'flex',
                    height: '60vh',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Col span={24}>
                    <Empty />
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col span={1}>
            <Divider type='vertical' style={{ height: '100%' }} />
          </Col>
          <Col span={12}>
            <div className='read-only-mar-form'>
              <Title level={3} className='read-only-mar-title read-only-row'>
                {t('Material Delivery Inspection')}
              </Title>
              {isEdit ? (
                <>
                  <Row className={'read-only-row'}>
                    <Col span={24}>
                      <div className={'read-only-column-data'}>
                        <Form.Item
                          name={'name'}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                          label={t('MDI Name')}
                        >
                          <Input
                            className={'mdi-input-field'}
                            showCount
                            maxLength={100}
                            allowClear={true}
                            placeholder={t('Enter MDI Name')}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className={'read-only-row'} gutter={8}>
                    <Col span={12} className={'mdi-inspection-date'}>
                      <div className={'read-only-column-data'}>
                        <Form.Item
                          name={'inspectionDate'}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                          label={t('Inspection Date')}
                        >
                          <DatePicker
                            placeholder={t('Inspection Date')}
                            disabledDate={(current) => {
                              const customDate = dayjs().format('YYYY-MM-DD');
                              return current && current < dayjs(customDate, 'YYYY-MM-DD');
                            }}
                            variant={'outlined'}
                            className={'mdi-inspection-date mdi-input-field'}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12} className={'mdi-Inspection-time '}>
                      <div className={'read-only-column-data'}>
                        <Form.Item
                          name={'inspectionTime'}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                          label={t('Inspection Time')}
                        >
                          <TimePicker
                            placeholder={t('Inspection Time')}
                            format='h:mm A'
                            minuteStep={15}
                            variant={'outlined'}
                            className={'mdi-inspection-time mdi-input-field'}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className={'read-only-row'} style={{ margin: '15px 0' }}>
                    <Col span={24}>
                      <ReadOnlyDataField title={'MDI name'} content={baseIssueData?.name} />
                    </Col>
                  </Row>
                  <Row className={'read-only-row'}>
                    <Col span={12} className={'mdi-inspection-date'}>
                      <ReadOnlyDataField
                        title={'Inspection Date'}
                        content={
                          baseIssueData && baseIssueData.MaterialDeliveryInspection
                            ? dayjs(baseIssueData.MaterialDeliveryInspection.inspectionDate).isValid()
                              ? dayjs(baseIssueData.MaterialDeliveryInspection.inspectionDate).format('YYYY-MM-DD')
                              : '--'
                            : '--'
                        }
                      />
                    </Col>
                    <Col span={12} className={'mdi-Inspection-time '}>
                      <ReadOnlyDataField
                        title={'Inspection Time'}
                        content={
                          baseIssueData && baseIssueData.MaterialDeliveryInspection
                            ? dayjs(baseIssueData.MaterialDeliveryInspection.inspectionDate).isValid()
                              ? dayjs(baseIssueData.MaterialDeliveryInspection.inspectionDate).format('h:mm A')
                              : '--'
                            : '--'
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              <MaterialDetailForm
                isEdit={isEdit}
                selectedMdiBaseIssue={baseIssueData}
                workspaceId={workspaceId}
                projectId={projectId}
                isOpen={isOpen}
                companyOriginator={companyOriginator}
                users={users}
                form={form}
                attachmentLoading={attachmentLoading}
                materialLoading={attachmentLoading}
                documentsInfo={documentsInfo}
                attachments={attachments}
                photosInfo={photosInfo}
                fetchAttachments={() => fetchAttachments()}
                setDocumentsInfo={(value: AttachmentInfo[]) => setDocumentsInfo(value)}
                setPhotosInfo={(value: AttachmentInfo[]) => setPhotosInfo(value)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
}
