import { notification } from 'antd';
import { initializeApp } from '@firebase/app';
import { deleteToken, getMessaging, getToken, onMessage } from '@firebase/messaging';
import { firebaseConfig } from 'utils/contants';
import Fingerprintjs from '@fingerprintjs/fingerprintjs';
import { NotificationToken } from 'model';
import { NotificationPlatform } from 'model/NotificationToken';
import notificationService from 'services/notification.service';
import detectPlatform from 'utils/detectPlatform';
import envService, { HiddenFeatures } from 'services/env.service';

const firebaseApp = !envService.isFeatureHidden(HiddenFeatures.NOTIFICATION) && initializeApp(firebaseConfig);
const messaging =
  !envService.isFeatureHidden(HiddenFeatures.NOTIFICATION) && firebaseApp && (getMessaging(firebaseApp) as any);

const susbribeToPushNotification = async (isRenewToken = false) => {
  try {
    const fingerprint = await (await Fingerprintjs.load()).get();
    const platform = detectPlatform();
    let oldToken;
    if (isRenewToken) {
      oldToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPIKEY });
      await deleteToken(messaging);
    }
    const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPIKEY });
    const notificationToken: NotificationToken = {
      oldToken,
      deviceToken: token,
      deviceId: fingerprint.visitorId,
      deviceName: platform
        ? `${platform.browser}/${platform.version} ${platform.os}`
        : (fingerprint.components.platform as any)?.value,
      platform: NotificationPlatform.Web,
    };
    await notificationService.subscribeNotification(notificationToken);
    console.log('Subscribed to push notification');
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

const unsusbribeToPushNotification = async () => {
  try {
    const fingerprint = await (await Fingerprintjs.load()).get();
    await deleteToken(messaging);
    await notificationService.unsubscribeNotification(fingerprint.visitorId);
    console.log('Subscribed to push notification');
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

const requestUserPermission = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      // susbribeToPushNotification();
    } else {
      console.log('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // Handle the notification or update your UI
      const notifyData = payload.notification;
      console.log('Foreground Message:', payload);
      notification.open({
        message: notifyData?.title,
        description: notifyData?.body,
        duration: 5,
      });
      resolve({...payload});
    });
  });

export {
  messaging,
  requestUserPermission,
  susbribeToPushNotification,
  onMessageListener,
  unsusbribeToPushNotification,
};
