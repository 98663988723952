import { DoubleRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Space, Typography, Tag, Form, FormInstance, Button, Flex } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { BaseIssue, IssueDiscipline, User } from 'model';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectProjectUsers, selectWorkBreakDownStructureByLevel } from 'store/my-projects.slice';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import { TASK_STATUS_HEADER_COLOR } from 'utils/contants';

type MDIsHeaderProps = {
  discipline?: IssueDiscipline[];
  requestName?: string;
  isUnSaveStatus?: boolean;
  onClose: () => void;
  setRequestName: (value: string) => void;
  form: FormInstance;
  selectedMdiBaseIssue: BaseIssue | null;
  profile: User | null;
  user?: User | null;
};

export default function MDIsHeader(props: MDIsHeaderProps) {
  const { form, onClose, setRequestName, selectedMdiBaseIssue } = props;
  const users = useAppSelector(selectProjectUsers);
  const originator = users.find((u) => u.id === (selectedMdiBaseIssue?.originatorId || props?.profile?.id));
  const name = Form.useWatch('name') || form.getFieldValue('name');
  const code = useMemo(() => {
    const numberSystem = selectedMdiBaseIssue?.MaterialDeliveryInspection?.mdiNumber?.split('-');
    if (!numberSystem) return '';
    numberSystem[numberSystem?.length - 1] = numberSystem[numberSystem?.length - 1].padStart(4, '0');
    return numberSystem.join('-');
  }, [selectedMdiBaseIssue?.MaterialDeliveryInspection?.mdiNumber]);
  const renderLabel = (label: string, color?: string) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tag className='circle-fill small' color={color ?? ''} bordered={false} />
      {t(label)}
    </div>
  );
  const disciplines = useAppSelector(selectWorkBreakDownStructureByLevel(EWorkBreakDownStructureLevelID.Discipline));
  const marDiscipline = selectedMdiBaseIssue?.MaterialDeliveryInspection?.MaterialApprovalRequest;
  const filteredDisciplines = disciplines?.filter((issue) => issue.id === marDiscipline?.disciplineId);

  const renderName = () => {
    return (
      <Flex vertical justify='center' className='ml-2' wrap='nowrap' rootClassName='name-box'>
        <Typography.Title level={2}>{name}</Typography.Title>
        <Flex className='name-group'>
          {code && <Typography.Text>{code}</Typography.Text>}
          {originator?.WorkspaceUser?.Company?.name && (
            <Typography.Text>{originator?.WorkspaceUser?.Company?.name}</Typography.Text>
          )}
          {originator?.fullName && <Typography.Text>{originator?.fullName}</Typography.Text>}
          {selectedMdiBaseIssue?.BaseValidations?.[0]?.createdAt && (
            <Typography.Text>
              {t('Submitted on:')}{' '}
              {dayjs(selectedMdiBaseIssue?.BaseValidations?.[0]?.createdAt).locale('en').format('DD-MM-YYYY')}
            </Typography.Text>
          )}
        </Flex>
      </Flex>
    );
  };
  return (
    <div className={`custom-modal small-drawer`} style={{ width: '100%', height: '100%' }}>
      <Row justify='space-between' className='mb-2'>
        <Col span={12} className='header'>
          <DoubleRightOutlined className='icon' onClick={onClose} style={{ color: 'white' }} />
        </Col>
        <Col span={12} className='header text-right'>
          <Button
            size='small'
            type='text'
            onClick={() => {
              onClose();
              form.resetFields();
              setRequestName('');
            }}
            icon={<CloseOutlined className='icon' style={{ color: 'white' }} />}
          />
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col>
          <div className='title-input'>
            <span className='avatar'>
              <Avatar className='my-3 avt-color'>
                {filteredDisciplines?.[0]?.code?.toString().toUpperCase() || '-'}
              </Avatar>
            </span>
            <Space direction='vertical' style={{ width: '100%', rowGap: 0 }}>
              <div>
                {renderName()}
                {/* <Typography.Text type='secondary' style={{ paddingLeft: '12px', color: 'white' }}>
                {selectedMdiBaseIssue?.MaterialDeliveryInspection?.mdiNumber 
                ? selectedMdiBaseIssue?.MaterialDeliveryInspection?.mdiNumber : profile?.fullName}
                </Typography.Text>
                { selectedMdiBaseIssue && dayjs(selectedMdiBaseIssue.MaterialDeliveryInspection?.inspectionDate).isValid() && (
                  <Typography.Text type='secondary' style={{ paddingLeft: '12px', color: 'white' }}>
                      Inspection Date : {dayjs(selectedMdiBaseIssue.MaterialDeliveryInspection?.inspectionDate).format('YYYY-MM-DD')}
                  </Typography.Text>
                )} */}
              </div>
            </Space>
          </div>
        </Col>
        {/* Omitted the rest for brevity */}
        <Space wrap>
          <Form.Item className='mb-0'>
            {!selectedMdiBaseIssue ? (
              <Tag>{t('Unsaved')}</Tag>
            ) : (
              <Tag>
                {renderLabel(
                  selectedMdiBaseIssue.IssueStatus?.name || 'Draft',
                  TASK_STATUS_HEADER_COLOR[selectedMdiBaseIssue.IssueStatus?.code || 'MDI_DR']
                )}
              </Tag>
            )}
          </Form.Item>
        </Space>
      </Row>
    </div>
  );
}
