import { Button, Modal, Form, Input, ModalProps, TreeSelect, message } from 'antd';
import Zone from 'model/Zone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { attachmentService } from 'services';
import { Attachment, AttachmentType } from 'model';
import { DataNode } from 'antd/es/tree';
import ImgItem from 'components/common/pre-signed-url-items/ImgItem';
import { convertZoneToTreeData } from 'utils';
import { findPath } from 'components/common/TreeSelectTagRender';

type ProjectZoningUpdatePlanProps = {
  locationPlanType: AttachmentType | undefined;
  workspaceId: string;
  projectId: string;
  attachmentId: string;
  isModalUpdatePlan: boolean;
  selectedZone: Zone | undefined;
  attachment: Attachment;
  name: string;
  zones: Zone[];
  keyUpdatePlan: string | null;
  setKeyUpdatePlan: (value: string | null) => void;
  getAttachments: () => void;
  setIsModalOpen: (value: boolean) => void;
} & ModalProps;

export default function ProjectZoningUpdatePlan(props: ProjectZoningUpdatePlanProps) {
  const {
    isModalUpdatePlan,
    setIsModalOpen,
    workspaceId,
    projectId,
    attachmentId,
    getAttachments,
    attachment,
    zones,
    keyUpdatePlan,
    setKeyUpdatePlan,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zoneUpdated, setZoneUpdated] = useState<string | undefined | null>(attachment?.zoneId);
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  useEffect(() => {
    const zoneIds: string[] = [];
    zones?.map((zone) => zoneIds.push(zone.id.toString()));
    const tree = convertZoneToTreeData(zones);
    setTreeData(tree);
  }, [zones]);

  useEffect(() => {
    form.resetFields();
    if (isModalUpdatePlan && keyUpdatePlan !== null && attachment) {
      form.setFieldValue('name', attachment?.name);
      form.setFieldValue('zoneId', attachment?.zoneId);
      if (attachment?.zoneId) {
        setZoneUpdated(findPath(treeData, attachment?.zoneId));
      }
    }
  }, [isModalUpdatePlan, attachment, keyUpdatePlan]);

  const onChangeZone = (newValue: string) => {
    form.setFieldValue('zoneId', newValue);
    setZoneUpdated(findPath(treeData, newValue));
  };
  const handleOk = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const formValues = await form.getFieldsValue();
      await attachmentService.updateAttachment(workspaceId, projectId, attachmentId, formValues);
      getAttachments();
      setIsLoading(false);
      setIsModalOpen(false);
      setKeyUpdatePlan(null);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setKeyUpdatePlan(null);
  };

  return (
    <Modal
      title={keyUpdatePlan === 'rename' ? `${t('Rename')}` : `${t('Move Zone')}`}
      open={isModalUpdatePlan}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk} loading={isLoading}>
          {t('Update')}
        </Button>,
      ]}
      width={723}
    >
      <Form form={form} name='update-plan' layout='vertical'>
        <div className='form-update-plan'>
          <ImgItem src={attachment?.filePath} alt='Preview' />
        </div>
        {keyUpdatePlan === 'rename' && (
          <Form.Item
            className={'mt-5'}
            label={t('Plan name')}
            name='name'
            rules={[{ required: true, message: t('Please input plan name!') }]}
          >
            <Input />
          </Form.Item>
        )}
        {keyUpdatePlan === 'movezone' && (
          <Form.Item className={'mt-5'} label={t('Zoning')} name='zoneId' rules={[{ required: true, message: '' }]}>
            <TreeSelect
              style={{ width: '100%' }}
              value={zoneUpdated ?? ''}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder={t('Select')}
              treeDefaultExpandAll
              onChange={onChangeZone}
            />
            <div style={{ display: 'none' }}>{zoneUpdated}</div>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
