import React, { ReactNode } from 'react';
import { Button, Tooltip } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

interface TooltipButtonProps {
  name: string;
  children?: ReactNode;
}

const AttachmentRender: React.FC<TooltipButtonProps> = ({ name }) => {
  const truncateName = (name: string, maxLength: number = 20) => {
    return name.length > maxLength ? `${name.slice(0, maxLength)}[...]` : name;
  };

  return (
    <Tooltip title={name}>
      <Button
        type='link'
        icon={<FilePdfOutlined />}
        className='ml-4 pa-2 d-flex'
        style={{
          width: '240px',
          height: '34px',
          background: '#FFFFFF',
        }}
      >
        {`${truncateName(name)}.pdf`}
      </Button>
    </Tooltip>
  );
};

export default AttachmentRender;
