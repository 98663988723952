import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
const { Text } = Typography;

interface RejectProps {
  module: string;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  onReject: () => void;
  assigneeName: string | undefined;
  issueId: string;
  projectId: string;
  workspaceId: string;
  userCreated: string | undefined;
  handleMessageChange: (event: { target: { value: SetStateAction<string> } }) => void;
}

const RejectModal: React.FC<RejectProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, setOpen, onReject, assigneeName, handleMessageChange, module } = props;
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = async () => {
    try {
      setIsSubmit(true);
      await onReject();
      setIsSubmit(false);
      setOpen(false);
    } catch (error) {
      setIsSubmit(false);
      setOpen(false);
      message.error(t('Oop! Something wrong'));
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={
          <>
            <ExclamationCircleFilled style={{ color: '#FAAD14', marginRight: 8 }} />
            {t('Reject')}
          </>
        }
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        width={400}
        footer={
          <>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type='primary' onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button type='dashed' onClick={handleOk} danger loading={isSubmit}>
                {
                  <>
                    <WarningOutlined style={{ color: '#FF4D4F', marginRight: 8 }} />
                    {t('Reject')}
                  </>
                }
              </Button>
            </Col>
          </>
        }
      >
        <Form form={form} name='comment' layout='vertical'>
          <Form className='mb-5'>
            <Row gutter={16} justify='space-between' align='middle' className='row-comment'>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <Text>
                  <Trans
                    i18nKey={`A notification will be sent to <b>${assigneeName}</b> to inform him and the ${module && module === 'Safety' ? t('inspection') : t('observation')} will be reset to open`}
                    components={{ b: <strong /> }}
                  />
                </Text>
              </Col>
              <Col span={24}>
                <Form.Item name='comment' label={t('Add a comment')}>
                  <TextArea onChange={handleMessageChange} placeholder={t('Leave a comment')} allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Form>
      </Modal>
    </>
  );
};

export default RejectModal;
