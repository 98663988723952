import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ModalState } from 'types';
import { useAttachmentTypes, useAttachmentTypesParams } from 'hooks';
import { ATTACHMENT_TYPES } from 'utils/contants';
import { Attachment, AttachmentInfo, BaseIssue, User } from 'model';
import { attachmentService } from 'services';
import { MaterialDetailType } from './MaterialDetail';
import AttachmentList, { AttachmentPhoto } from 'components/common/AttachmentList';
import UploadPhotoModal, { UploadPhotoModalValue } from 'components/common/UploadPhotoModal';
import { useAppSelector } from 'store';
import { selectProjectUsers } from 'store/my-projects.slice';

type PicturePopupProps = {
  isReadOnly: boolean;
  selectedMarBaseIssue: BaseIssue | null;
  workspaceId: string;
  projectId: string;
  position: MaterialDetailType;
  materialLoading: boolean;
  attachments: Attachment[];
  photosInfo: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] };
  setPhotosInfo: (value: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] }) => void;
  fetchMarAttachments: (position: string) => void;
  user?: User | null;
};

export default function MARsListPicture(props: PicturePopupProps) {
  const { t } = useTranslation();
  const {
    isReadOnly,
    selectedMarBaseIssue,
    workspaceId,
    projectId,
    position,
    materialLoading,
    attachments,
    photosInfo,
    fetchMarAttachments,
    setPhotosInfo,
  } = props;
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentPhotos, setAttachmentPhotos] = useState<Attachment[]>([]);

  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const marPhotoType = attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.MAR_PHOTO);
  const users = useAppSelector(selectProjectUsers);

  const [, setPictures] = useState<Attachment[]>([]);

  const fetchAttachments = async () => {
    if (selectedMarBaseIssue) {
      const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
        projectId,
        baseIssueId: selectedMarBaseIssue.id,
        attachmentTypeId: marPhotoType?.id,
        limit: 'unlimited',
      });
      setPictures(rows);
    } else {
      // const data: { name: string; filePath: string; attachmentTypeId: string }[] = photoInfo;
      // setPictures(data);
      // setPhotoInfo(data);
    }
  };

  const handleAddPicture = async (response: UploadPhotoModalValue) => {
    try {
      if (selectedMarBaseIssue) {
        setLoading(true);
        const materialProposed = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
          (material: { isProposed: boolean }) => material.isProposed === true
        );
        let marDescriptionId = materialProposed?.id;
        if (position === 'specified') {
          const materialSpecified = (selectedMarBaseIssue?.MaterialApprovalRequest?.MarDescriptions || []).find(
            (material: { isProposed: boolean }) => material.isProposed === false
          );
          marDescriptionId = materialSpecified?.id;
        }
        await attachmentService.createAttachment(workspaceId, projectId, {
          filePath: response.fileUrl,
          name: response.fileName,
          attachmentTypeId: marPhotoType?.id as string,
          zoneId: selectedMarBaseIssue?.zoneId,
          baseIssueId: selectedMarBaseIssue.id,
          marDescriptionId: marDescriptionId,
          width: response.width,
          height: response.height,
          comment: response?.comment,
        });

        fetchMarAttachments(position);
        setIsOpenUpload(false);
        setLoading(false);
      } else {
        if (marPhotoType) {
          const data: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] } = { ...photosInfo };
          if (position === 'proposed') {
            data.proposed = [
              ...data.proposed,
              {
                name: response.fileName,
                filePath: response.fileUrl,
                attachmentTypeId: marPhotoType.id,
                comment: response.comment,
              },
            ];
          } else {
            data.specified = [
              ...data.specified,
              {
                name: response.fileName,
                filePath: response.fileUrl,
                attachmentTypeId: marPhotoType.id,
                comment: response.comment,
              },
            ];
          }
          setIsOpenUpload(false);
          setPhotosInfo(data);
        }
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };

  const handleRemovePicture = async (filePath: string) => {
    try {
      if (selectedMarBaseIssue) {
        setLoading(true);
        const id = attachments.find((attachment) => attachment.filePath === filePath)?.id || '';
        await attachmentService.deleteAttachment(workspaceId, projectId, id);
        fetchMarAttachments(position);
        setLoading(false);
      } else {
        const data: { proposed: AttachmentInfo[]; specified: AttachmentInfo[] } = { ...photosInfo };
        if (position === 'proposed') {
          data.proposed.splice(0, 1);
        } else {
          data.specified.splice(0, 1);
        }
        setPhotosInfo(data);
      }
    } catch (error) {
      console.log(error);
      message.error((error as Error).message);
      setLoading(false);
    }
  };
  const renderPictures = () => {
    let mappedPhotos = [] as AttachmentPhoto[];
    if (attachmentPhotos.length > 0) {
      mappedPhotos = attachmentPhotos?.map((picture) => {
        const user = users.find((user) => user.id === picture?.MarAttachments?.[0].userCreated);
        return {
          ...picture,
          picture: user?.profilePhoto,
          username: user?.fullName || '',
          hideDelete: props?.isReadOnly,
          firstName: user?.firstName,
          comment: picture?.MarAttachments?.[0].comment || '',
        };
      });
    } else if (position === 'proposed') {
      mappedPhotos = photosInfo.proposed?.map((picture) => ({
        ...picture,
        picture: props.user?.profilePhoto,
        username: props.user?.fullName || '',
        hideDelete: props?.isReadOnly,
        firstName: props.user?.firstName,
        comment: picture?.comment || '',
      })) as AttachmentPhoto[];
    } else {
      mappedPhotos = photosInfo.specified?.map((picture) => ({
        ...picture,
        picture: props.user?.profilePhoto,
        username: props.user?.fullName || '',
        hideDelete: props?.isReadOnly,
        firstName: props.user?.firstName,
        comment: picture?.comment || '',
      })) as AttachmentPhoto[];
    }

    return (
      <AttachmentList
        attachmentPhotos={mappedPhotos}
        hideDelete={props.isReadOnly}
        onDelete={(filePath) => {
          handleRemovePicture(filePath);
        }}
      />
    );
  };

  useEffect(() => {
    if (position) {
      const photos = attachments.filter((i) => i.attachmentTypeId === marPhotoType?.id);
      setAttachmentPhotos(photos);
    }
  }, [position, attachments]);
  useEffect(() => {
    if (selectedMarBaseIssue) {
      fetchAttachments();
    }
  }, [selectedMarBaseIssue]);
  return (
    <div className='picture-popup-container'>
      <Spin spinning={loading || materialLoading}>
        {!isReadOnly && (
          <>
            <Row gutter={24}>
              {isOpenUpload !== null && (
                <UploadPhotoModal
                  isModalOpen={isOpenUpload}
                  setIsModalOpen={setIsOpenUpload}
                  onSubmit={handleAddPicture}
                />
              )}
            </Row>
            <Row>
              <div className='mar-picture-container'>
                <p className='picture-group-text'>{t('Pictures')}</p>
                <Button
                  className='text-size-12'
                  type='dashed'
                  icon={<PlusOutlined />}
                  onClick={() => setIsOpenUpload(true)}
                >
                  {t('Add Picture')}
                </Button>
              </div>
            </Row>
          </>
        )}
        <div className='picture-box'>{renderPictures()}</div>
      </Spin>
    </div>
  );
}
