import { Button, Col, Flex, Form, FormInstance, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ReadOnlyDataField, SearchSelect } from 'components/common';
import { useOtherProjects, useOtherProjectsParams, useProjectCompanies, useProjectCompaniesParams } from 'hooks';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import { BaseIssue, MdiInvitation, User } from 'model';
import { baseIssueService } from 'services';
import ProjectUser from 'model/ProjectUser';

type InviteForInspectionProps = {
  form: FormInstance;
  projectId: string;
  companyOriginator: string | undefined;
  users: User[];
  isOpen: boolean;
  MDI: BaseIssue | null;
  loading?: boolean;
  isEdit: boolean;
};
interface Invitation {
  id?: string;
  companyId?: string;
  inspectorId?: string;
}
const { Text, Title } = Typography;
type SelectCompanyOptions = {
  label: string;
  value: string;
  disabled: boolean;
  userIds: (string | undefined)[];
};
export default function InviteForInspection(props: InviteForInspectionProps) {
  const { t } = useTranslation();
  const { form, projectId, users, isOpen, MDI, isEdit } = props;

  const [otherProjectsParams] = useOtherProjectsParams();
  const [otherProjects] = useOtherProjects(otherProjectsParams);
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const foundOrderProject = otherProjects.find((project) => project.id === projectId);
  const workspaceId = foundOrderProject?.workspaceId ?? (myWorkspace?.id as string);
  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

  const [companies] = useProjectCompanies(companyParams);
  const watchedValues =
    (Form?.useWatch?.('mdiInvitations', props.form) as Invitation[]) || form.getFieldValue('mdiInvitations');
  const [selectedCompanies, setSelectedCompanies] = useState<unknown[]>([]);
  const [selectedInspectorIds, setSelectedInspectorIds] = useState<unknown[]>([]);
  const [, setIsRemoved] = useState<boolean>(false);
  const companyChangeKeyRef = useRef<null | number>(null);
  const nameChangeRef = useRef<null | number>(null);

  const companyOptions: SelectCompanyOptions[] = useMemo(() => {
    return companies?.map((company) => {
      const isUserEmpty = company.WorkspaceUsers?.length === 0;
      const userIds = (watchedValues || [])
        .map((watchedValue) => watchedValue?.inspectorId)
        .filter((id) => id !== undefined);
      const projectUsers = company?.WorkspaceUsers
        ? company?.WorkspaceUsers?.flatMap((workspaceUser) =>
            workspaceUser?.ProjectUsers?.flatMap((projectUser: ProjectUser) => projectUser.userId)
          ).filter((id) => id !== undefined)
        : [];
      const isFullCompany: boolean = projectUsers.every((userId) => userIds.includes(userId));

      return {
        label: company.name,
        value: company.id,
        disabled: isUserEmpty || isFullCompany,
        userIds: company?.WorkspaceUsers
          ? company?.WorkspaceUsers?.flatMap((workspaceUser) =>
              workspaceUser?.ProjectUsers?.flatMap((projectUser: ProjectUser) => projectUser.userId)
            )
          : [],
      };
    });
  }, [watchedValues, companies]);
  const validUser = useMemo(() => {
    return props.users.filter((user) => {
      const validCompany = companyOptions?.filter((company) => !company.disabled);
      return validCompany?.some((company) => company.value === user.WorkspaceUser?.Company?.id);
    });
  }, [companyOptions, props.users, watchedValues]);
  const resetNameOnCompanyChange = () => {
    if (companyChangeKeyRef.current === null) return;
    const currentUserId = watchedValues[companyChangeKeyRef.current].inspectorId;
    const currentCompanyId = watchedValues[companyChangeKeyRef.current].companyId;

    const user = props?.users?.find((user) => user.id === currentUserId);
    const hasUserInCompany = user?.WorkspaceUser?.Company?.id === currentCompanyId;

    if (!hasUserInCompany) {
      watchedValues[companyChangeKeyRef.current].inspectorId = undefined;
      props.form.setFieldValue('mdiInvitations', watchedValues);
    }
    companyChangeKeyRef.current = null;
  };

  useEffect(() => {
    resetNameOnCompanyChange();
  }, [watchedValues]);
  const generateCompanyUser = (selectedInspector: Invitation | null) => {
    let result = validUser;

    if (selectedInspector?.companyId) {
      result = props.users?.filter((user) => user.WorkspaceUser?.Company?.id === selectedInspector?.companyId) || [];
    }
    return result.map((user) => {
      const isSelectedUser = watchedValues?.some((watchedValue) => watchedValue?.inspectorId === user.id);
      return {
        label: user.fullName,
        value: user.id,
        disabled: isSelectedUser,
      };
    });
  };

  const autofillCompany = () => {
    if (nameChangeRef.current === null) return;
    const userId = watchedValues[nameChangeRef.current].inspectorId;

    const selectedCompany = companyOptions.find((company) => company?.userIds.includes(userId));

    if (!selectedCompany) return;
    watchedValues[nameChangeRef.current].companyId = selectedCompany?.value;
    props.form.setFieldValue('mdiInvitations', watchedValues);

    props.form.setFields([
      {
        name: ['mdiInvitations', nameChangeRef.current, 'companyId'],
        errors: [],
      },
    ]);
    nameChangeRef.current = null;
  };

  useEffect(() => {
    autofillCompany();
  }, [watchedValues, companyOptions]);

  // const onChangeCompany = (companyId: string, index: number) => {
  //   setIsRemoved(false);
  //   if (!companyId) {
  //     setSelectedCompany(null);
  //     form.setFieldValue(['mdiInvitations', index, 'companyId'], null);
  //   } else {
  //     setSelectedCompany(companyId);
  //     const autoFillUsers = users?.filter((user) => user.WorkspaceUser?.Company?.id === companyId);
  //     if (autoFillUsers[0]?.id) {
  //       form.setFieldValue(['mdiInvitations', index, 'inspectorId'], autoFillUsers[0]?.id);
  //       handleSelectInspectorId(autoFillUsers[0]?.id, index);
  //     } else {
  //       form.setFieldValue(['mdiInvitations', index, 'inspectorId'], null);
  //     }
  //     handleSelectCompany(companyId, index);
  //   }
  //   form.validateFields([
  //     ['mdiInvitations', index, 'companyId'],
  //     ['mdiInvitations', index, 'inspectorId'],
  //   ]);
  // };

  // const onChangeCompanyRep = (inspectorId: string, index: number) => {
  //   setIsRemoved(false);
  //   if (!inspectorId) {
  //     form.setFieldValue(['mdiInvitations', index, 'inspectorId'], null);
  //   } else {
  //     setUsername(inspectorId);
  //     handleSelectInspectorId(inspectorId, index);
  //     const selectedUser = users?.find((user) => user.id === inspectorId);
  //     const existingCompanyIndex = companies?.findIndex(
  //       (company) => company.id === selectedUser?.WorkspaceUser?.Company?.id
  //     );
  //     if (existingCompanyIndex !== -1) {
  //       const existingCompanyId = companies[existingCompanyIndex]?.id;
  //       setSelectedCompany(existingCompanyId ?? null);
  //       handleSelectCompany(existingCompanyId ?? '', index);
  //       form.setFieldValue(['mdiInvitations', index, 'companyId'], existingCompanyId ?? null);
  //     } else {
  //       form.setFieldValue(['mdiInvitations', index, 'companyId'], null);
  //     }
  //   }
  //   form.validateFields([
  //     ['mdiInvitations', index, 'companyId'],
  //     ['mdiInvitations', index, 'inspectorId'],
  //   ]);
  // };

  // const handleSelectCompany = (companyId: string, index: number) => {
  //   setIsRemoved(false);
  //   const newSelectedItems = [...selectedCompanies];
  //   newSelectedItems[index] = companyId;
  //   setSelectedCompanies(newSelectedItems);
  // };
  // const handleSelectInspectorId = (inspectorId: string, index: number) => {
  //   setIsRemoved(false);
  //   const newSelectedItems = [...selectedCompanies];
  //   newSelectedItems[index] = inspectorId;
  //   setSelectedInspectorIds(newSelectedItems);
  // };

  const handleRemove = (index: number) => {
    const newSelectedCompany = [...selectedCompanies];
    const newSelectedInspectorId = [...selectedInspectorIds];
    if (MDI && MDI.MaterialDeliveryInspection && MDI.MaterialDeliveryInspection.MdiInvitations) {
      const mdiInvitation = MDI.MaterialDeliveryInspection.MdiInvitations[index];
      if (mdiInvitation) {
        baseIssueService.deleteMdiInvitations(workspaceId, projectId, mdiInvitation.id);
      }
    }
    newSelectedCompany.splice(index, 1);
    newSelectedInspectorId.splice(index, 1);
    setSelectedInspectorIds(newSelectedInspectorId);
    setSelectedCompanies(newSelectedCompany);
    setSelectedCompany(null);

    setIsRemoved(true);
  };
  const handleChangeFilterUser = async (index: number) => {
    setIsRemoved(false);
    const values = await form.getFieldValue(['mdiInvitations', index, 'companyId']);
    if (values) {
      setSelectedCompany(values);
    } else {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCompanies([]);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen && MDI && MDI.MaterialDeliveryInspection && MDI.MaterialDeliveryInspection.MdiInvitations) {
      const initialValues = MDI.MaterialDeliveryInspection.MdiInvitations.map((invitation: Invitation) => ({
        id: invitation.id,
        companyId: invitation.companyId,
        inspectorId: invitation.inspectorId,
        inspectorName: users.find((user) => user.id === invitation.inspectorId)?.fullName || '',
      }));
      form.setFieldsValue({ mdiInvitations: initialValues });
      if (initialValues.length > 0) {
        setSelectedCompanies(initialValues.map((invitation: Invitation) => invitation.companyId));
        setSelectedInspectorIds(initialValues.map((invitation: Invitation) => invitation.inspectorId));
      } else {
        const initialValues = [{ id: null, companyId: null, inspectorId: null }];
        form.setFieldsValue({ mdiInvitations: initialValues });
      }
    } else if (!isOpen) {
      const initialValues = [{ id: null, companyId: null, inspectorId: null }];
      setSelectedCompanies([]);
      form.setFieldsValue({ mdiInvitations: initialValues });
    }
  }, [isOpen, MDI]);

  return (
    <>
      {isEdit ? (
        <Form form={form}>
          <Form.List name={'mdiInvitations'} initialValue={[{ id: null, companyId: null, inspectorId: null }]}>
            {(fields, { add, remove }) => (
              <>
                <Flex vertical>
                  <Flex>
                    <Title level={3} className='read-only-mar-title read-only-row'>
                      {t('Invite for Inspection')}
                    </Title>
                  </Flex>
                  <Flex justify='space-between'>
                    <Flex gap={12} vertical style={{ width: '100%' }}>
                      <Row gutter={10}>
                        <Col span={11}>
                          <Text>
                            {t('Company')}
                            <span className={'required-fields-mdi'}> *</span>
                          </Text>
                        </Col>
                        <Col span={11}>
                          <Text>
                            {t('Name')}
                            <span className={'required-fields-mdi'}> *</span>
                          </Text>
                        </Col>
                        <Col span={2}></Col>
                      </Row>
                      {fields.map((field, index) => {
                        const selectedInspector = watchedValues?.[field?.name]?.companyId
                          ? watchedValues?.[field?.name]
                          : null;
                        const companyUser = generateCompanyUser(selectedInspector);
                        return (
                          <>
                            <Row gutter={[10, 20]} className={`invite-for-inspection invite-row-${index}`}>
                              <Col span={11}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: '',
                                    },
                                  ]}
                                  name={[field.name, 'companyId']}
                                >
                                  <SearchSelect
                                    placeholder={t('Select company')}
                                    value={selectedCompany}
                                    options={companyOptions}
                                    onChange={() => {
                                      companyChangeKeyRef.current = field.name;
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: '',
                                    },
                                  ]}
                                  name={[field.name, 'inspectorId']}
                                  valuePropName='inspectorName'
                                >
                                  <SearchSelect
                                    placeholder={t('Select user')}
                                    onFocus={() => {
                                      handleChangeFilterUser(index);
                                    }}
                                    value={form.getFieldValue(['mdiInvitations', index, 'inspectorName'])}
                                    options={companyUser}
                                    onChange={() => {
                                      nameChangeRef.current = field.name;
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              {index > 0 && (
                                <Col span={2}>
                                  <DeleteOutlined
                                    style={{
                                      color: 'grey',
                                      fontSize: 20,
                                      marginTop: '5px',
                                    }}
                                    onClick={() => {
                                      {
                                        handleRemove(index);
                                        remove(field.name);
                                      }
                                    }}
                                  />
                                </Col>
                              )}
                            </Row>
                          </>
                        );
                      })}
                    </Flex>
                  </Flex>
                </Flex>
                <Row style={{ marginTop: '25px' }}>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Button
                      type='dashed'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        add();
                      }}
                      // disabled={fields.length >= 3}
                      className='mar-add-comment-btn'
                    >
                      {t('Add Inspector')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      ) : (
        <>
          <Title level={3} className='read-only-mar-title read-only-row'>
            {t('Invite for Inspection')}
          </Title>
          <Row gutter={10} className={'read-only-row'}>
            <Col span={12}>
              <ReadOnlyDataField title={'Company'} />
            </Col>
            <Col span={12}>
              <ReadOnlyDataField title={'Name'} />
            </Col>
          </Row>
          <Row gutter={[10, 15]} className={'invite-for-inspection'}>
            {MDI?.MaterialDeliveryInspection?.MdiInvitations &&
              MDI?.MaterialDeliveryInspection?.MdiInvitations.map((item: MdiInvitation, index: number) => (
                <React.Fragment key={index}>
                  <Col span={12}>
                    <ReadOnlyDataField
                      content={companies.find((company) => company.id === item.companyId)?.name || '--'}
                    />
                  </Col>
                  <Col span={12}>
                    <ReadOnlyDataField content={users.find((user) => user.id === item.inspectorId)?.fullName || '--'} />
                  </Col>
                </React.Fragment>
              ))}
          </Row>
        </>
      )}
    </>
  );
}
