import request from 'requesters/core.request';
import axios, { AxiosHeaders } from 'axios';
import { Attachment } from 'model';
import { message } from 'antd';
import { t } from 'i18next';
import * as pdfjs from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker';
import shopDrawingService from './shop-drawing.services';
import designService from '../modules/DocumentManagement/services/design.services';
import { QueryParams } from 'types';

const fileService = {
  startMultipartUpload: (params: { fileName: string; fileType: string }) => {
    return request.get('/start-multipart-upload', { params });
  },
  getSignedMultipartUpload: (params: QueryParams) => {
    return request.get('/pre-signed-url-multipart-upload', { params });
  },
  completeMultipartUpload: (params: QueryParams) => {
    return request.post('/complete-multipart-upload', params);
  },
  getSignedUrlUpload: (params: { fileType: string; fileName: string }) => {
    return request.get<{ preSignedURL: string; fileUrl: string; keyFile: string }>('/pre-signed-url', { params });
  },
  getSignedUrlGet: (params: { keyFile: string }): Promise<{ preSignedURL: string }> => {
    return request.get('/pre-signed-url-get', { params });
  },
  openFile: async (url: string) => {
    const preSignedURL =
      url.includes('.s3') && !url.includes('X-Amz-Credential')
        ? await fileService
            .getSignedUrlGet({ keyFile: url.substring(url.indexOf('polaris/') + 8) })
            .then((r) => r.preSignedURL)
        : url;

    window.open(preSignedURL, '_blank');
  },
  downloadFile: async (url: string, fileName: string) => {
    const preSignedURL =
      url.includes('.s3') && !url.includes('X-Amz-Credential')
        ? await fileService
            .getSignedUrlGet({ keyFile: url.substring(url.indexOf('polaris/') + 8) })
            .then((r) => r.preSignedURL)
        : url;

    const response = await axios({
      url: preSignedURL,
      method: 'GET',
      responseType: 'blob',
    });
    const url_2 = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url_2;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  },
  downloadImage: async (attachment: Attachment) => {
    try {
      const preSignedURL =
        attachment.filePath?.includes('.s3') && !attachment.filePath.includes('X-Amz-Credential')
          ? await fileService
              .getSignedUrlGet({ keyFile: attachment.filePath.substring(attachment.filePath.indexOf('polaris/') + 8) })
              .then((r) => r.preSignedURL)
          : attachment.filePath;
      const response = await axios({
        url: preSignedURL,
        method: 'GET',
        responseType: 'blob',
        headers: { 'Cache-Control': 'no-cache' },
      });
      const url_2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url_2;
      link.setAttribute('download', 'attachment.png');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  },

  uploadFileSignedUrl: (presignedUrl: string, blob: Blob | File, fileType: string) => {
    return axios.put<{ fileName: string; fileUrl: string; headers: AxiosHeaders }>(presignedUrl, blob, {
      headers: {
        'Content-Type': fileType,
      },
    });
  },
  uploadFile: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return request.post<{
      imageURL: string;
      fileName: string;
      width: number;
      height: number;
    }>(`${process.env.REACT_APP_BASE_CORE_API}/upload-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  uploadFileAdvanced: async (
    file: File,
    fileName: string,
    fileType: string,
    chunkSize = 10
  ): Promise<{ fileName: string; fileUrl: string; keyFile: string }> => {
    return new Promise((resolve, reject) => {
      const run = async () => {
        try {
          const fileSize = file.size;
          // Start multipart upload if file size > 5MB
          if (fileSize > 5000000) {
            const { uploadId, keyFile } = await fileService.startMultipartUpload({
              fileName,
              fileType,
            });
            const CHUNK_SIZE = chunkSize * 5000000;
            const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
            const promisesArray = [];
            let start;
            let end;
            let blob;
            for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
              start = (index - 1) * CHUNK_SIZE;
              end = index * CHUNK_SIZE;
              blob = index < CHUNKS_COUNT ? file.slice(start, end) : file.slice(start);

              // Get presigned URL for each part
              const { preSignedURL } = await fileService.getSignedMultipartUpload({
                keyFile,
                partNumber: index,
                uploadId,
              });

              // Send part to aws server

              promisesArray.push(fileService.uploadFileSignedUrl(preSignedURL, blob, fileType));
            }
            const resolvedArray = await Promise.all(promisesArray);
            const uploadPartsArray: {
              ETag: {
                fileName: string;
                fileUrl: string;
                headers: string;
              };
              PartNumber: number;
            }[] = [];
            resolvedArray.forEach((resolvedPromise, index) => {
              uploadPartsArray.push({
                ETag: resolvedPromise.headers.etag,
                PartNumber: index + 1,
              });
            });

            // CompleteMultipartUpload in the backend server
            const response = await fileService.completeMultipartUpload({
              fileType,
              keyFile,
              parts: uploadPartsArray,
              uploadId,
            });
            resolve(response);
          } else {
            // Get presigned URL for each part
            const { preSignedURL, fileUrl, keyFile } = await fileService.getSignedUrlUpload({
              fileName,
              fileType,
            });

            await fileService.uploadFileSignedUrl(preSignedURL, file, fileType);
            resolve({ fileName, fileUrl, keyFile });
          }
        } catch (error) {
          reject(error);
        }
      };

      return run();
    });
  },

  /**
   * @function pdfToImage
   * @description Convert a PDF file to an image
   * @param {File} file - The PDF file
   * @returns {Promise<{imageFile: File, imagePreview: string}>} - The image file and its preview
   */
  pdfToImage: async (file: File): Promise<{ imageFile: File; imagePreview: string }> => {
    return new Promise((resolve) => {
      const run = async () => {
        const fileReader = new FileReader();
        fileReader.onload = async function (this: FileReader) {
          // Step 4: turn array buffer into typed array
          const typedarray: Uint8Array = new Uint8Array(this.result as ArrayBuffer);

          // Step 5: pdfjs should be able to read this
          const pdfDoc = await pdfjs.getDocument(typedarray).promise;

          // Get the first page of the PDF document
          const page = await pdfDoc.getPage(1);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });

          // Create a canvas element to render the image
          const canvas = document.createElement('canvas');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Render the PDF page to the canvas
          const context = canvas.getContext('2d');
          if (context) await page.render({ canvasContext: context, viewport }).promise;

          // Convert the rendered image on the canvas to a base64 string
          const imageData = canvas.toDataURL('image/png');

          // Convert the base64 string to a Blob object
          const base64Data = imageData.split(',')[1];
          const byteArray = atob(base64Data);
          const byteNumbers = new Array(byteArray.length);
          for (let i = 0; i < byteArray.length; i++) {
            byteNumbers[i] = byteArray.charCodeAt(i);
          }
          const byteArrayBuffer = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArrayBuffer], { type: 'image/png' });

          // Create a new File object from the Blob object
          const imageFile = new File([blob], `${file.name}.png`, {
            type: 'image/png',
          });
          resolve({ imageFile: imageFile, imagePreview: imageData });
        };

        fileReader.readAsArrayBuffer(file);
      };
      run();
    });
  },

  //TODO: delete duplicate with uploadFileAdvanced
  uploadShopDrawingAndDesignFileAdvanced: async (
    workspaceId: string,
    projectId: string,
    shopDrawingId: string,
    file: File,
    fileType: string,
    isDraft: boolean,
    setPercentage: (newPercentage: number) => void,
    chunkSize = 10,
    isShopDrawing: boolean
  ): Promise<{ fileName: string; fileUrl: string; keyFile: string }> => {
    return new Promise((resolve, reject) => {
      const run = async () => {
        try {
          const fileSize = file.size;
          // Start multipart upload if file size > 5MB
          if (fileSize > 5000000) {
            const { uploadId, keyFile } = await (
              isShopDrawing
                ? shopDrawingService.startShopDrawingMultipartUpload
                : designService.startDesignMultipartUpload
            )(workspaceId, projectId, shopDrawingId, {
              fileName: shopDrawingId,
              fileType,
            });
            const CHUNK_SIZE = chunkSize * 5000000;
            const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
            const promisesArray = [];
            let chunksSent = 0;
            const updatePercentage = () => {
              chunksSent++;
              setPercentage((chunksSent / CHUNKS_COUNT) * 100);
            };
            let start;
            let end;
            let blob;
            for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
              start = (index - 1) * CHUNK_SIZE;
              end = index * CHUNK_SIZE;
              blob = index < CHUNKS_COUNT ? file.slice(start, end) : file.slice(start);

              // Get presigned URL for each part
              const { preSignedURL } = await fileService.getSignedMultipartUpload({
                keyFile,
                partNumber: index,
                uploadId,
              });

              // Send part to aws server
              promisesArray.push(
                fileService.uploadFileSignedUrl(preSignedURL, blob, fileType).then((res) => {
                  updatePercentage();
                  return res;
                })
              );
            }
            const resolvedArray = await Promise.all(promisesArray);
            const uploadPartsArray: {
              ETag: {
                fileName: string;
                fileUrl: string;
                headers: string;
              };
              PartNumber: number;
            }[] = [];
            resolvedArray.forEach((resolvedPromise, index) => {
              uploadPartsArray.push({
                ETag: resolvedPromise.headers.etag,
                PartNumber: index + 1,
              });
            });

            // CompleteMultipartUpload in the backend server
            const response = await (
              isShopDrawing ? shopDrawingService.endShopDrawingMultipartUpload : designService.endDesignMultipartUpload
            )(workspaceId, projectId, shopDrawingId, {
              fileType,
              keyFile,
              parts: uploadPartsArray,
              uploadId,
              draft: isDraft,
            });
            resolve(response);
          } else {
            await (isShopDrawing ? shopDrawingService.sendShopDrawingsAttachment : designService.sendDesignAttachment)(
              workspaceId,
              projectId,
              {
                designId: shopDrawingId,
                file,
                draft: isDraft,
              }
            ).then(() => {
              setPercentage(100);
            });
            resolve({ fileName: shopDrawingId, fileUrl: '', keyFile: '' });
          }
        } catch (error) {
          reject(error);
        }
      };
      run();
    });
  },
};

export default fileService;
