import { useMemo } from 'react';
import { QueryParams } from 'types';
import { useFetchByParams, useQueryParams } from 'hooks';
import Notification from 'model/NotificationModel';
import {
  fetchNotifications, fetchNotificationsCount,
  fetchNotificationsUnRead,
  selectNotificationLoading,
  selectNotifications,
  selectNotificationUnRead,
} from 'store/notification.slice';
import { RootState } from 'store';

type NotificationParamsType = {
  userId?: string
} & QueryParams


export const useGetNotificationParams = (
  options: NotificationParamsType) :[query: NotificationParamsType, onParamChange: Function]  => {
  const [params,onParamChange] = useQueryParams<QueryParams>();

  const { userId } = options;

  const query= useMemo(() => {
    return { ...options, ...params };
  }, [userId, params]);

  return  [query, onParamChange]
};

export const useGetNotification = (query: {userId?: string} & QueryParams) => {
  return useFetchByParams<Notification[]>({
    action: fetchNotificationsUnRead,
    dataSelector: selectNotificationUnRead,
    loadingSelector: selectNotificationLoading,
    params: query,
  });
};

export const useGetNotifications = (query: QueryParams) => {
  return useFetchByParams<Notification[]>({
    action:fetchNotifications,
    dataSelector: selectNotifications,
    loadingSelector: selectNotificationLoading,
    params: query,
  });
};

export const useNotificationCount = (query: any) => {
  return useFetchByParams<number>({
    action: fetchNotificationsCount,
    dataSelector: (state: RootState) => state.notification.notificationCount,
    loadingSelector: selectNotificationLoading,
    params: query,
  });
};
