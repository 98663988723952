import { CommentOutlined, LinkOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Form, message, Spin, Tabs, TabsProps } from 'antd';
import { DataNode } from 'antd/es/tree';
import {
  CreateAttachment,
  CreateComment,
  CreateIssueDetail,
  CreateIssueFooter,
  CreateIssueHeader,
  MiniPlan,
  PinInspection,
  UploadModal,
} from 'components';
import { findPath } from 'components/common/TreeSelectTagRender';
import dayjs from 'dayjs';
import {
  useAttachmentTypes,
  useAttachmentTypesParams,
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useIssueTypes,
  useIssueTypesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectPhases,
  useProjectPhasesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { useWorkBreakdownStructureParams, useWorkBreakdownStructures } from 'hooks/work-breakdown-structure';
import { Attachment, Issue, IssueAttachment, IssueDiscipline, IssueHistory, WorkBreakdownStructure } from 'model';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { attachmentService, issueAttachmentService, issueService } from 'services';
import useAuthContext from 'store/auth-context';
import { LayerData, ModalState } from 'types';
import { convertZoneToTreeData, getLayerType } from 'utils';
import {
  ATTACHMENT_TYPES,
  ISSUE_DISCIPLINES,
  ISSUE_PRIORITIES,
  ISSUE_STATUSES,
  ISSUE_TYPES,
  TASK_STATUS_HEADER_COLOR,
} from 'utils/contants';

type CreateOrUpdateProps = {
  isOpen: boolean;
  onFinish: () => void;
  setOpen: (value: boolean) => void;
  issueId: string | null;
  refreshIssues: () => void;
  module: string;
  workspaceId: string;
  isFromPlan?: boolean;
  planData?: IssueAttachment | null;
};
export type WorkspaceViewType = 'detail' | 'attachments' | 'comments';

export default function CreateOrUpdate(props: CreateOrUpdateProps) {
  const { isOpen, setOpen, onFinish, issueId, refreshIssues, module, workspaceId, isFromPlan, planData } = props;
  const { profile } = useAuthContext();
  const params = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const projectId = params.projectId as string;

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [issueTypesQuery] = useIssueTypesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [wbsQuery] = useWorkBreakdownStructureParams({
    projectId,
    workspaceId,
    orderBy: 'order,asc',
    limit: 'unlimited',
  });
  const [wbs] = useWorkBreakdownStructures(wbsQuery);
  const [mainPhase] = useMemo(() => wbs?.filter((phase) => phase.isMain === true) || [], [wbs]);
  const disciplines = useMemo(() => wbs?.filter((phase) => phase.parentId === mainPhase?.id) || [], [wbs]);
  const activities = useMemo(() => {
    // Extract all discipline ids
    const disciplineIds = disciplines?.map((discipline) => discipline.id);

    // Filter wbs to find those whose parentId matches any of the discipline ids
    return wbs?.filter((phase) => phase.parentId && disciplineIds?.includes(phase.parentId)) || [];
  }, [wbs, disciplines]);

  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [attachmentTypeQuery] = useAttachmentTypesParams();
  const [attachmentTypes] = useAttachmentTypes(attachmentTypeQuery);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [usersParams] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(usersParams);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitAsDraft, setIsSubmitAsDraft] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitAttachment, setIsSubmitAttachment] = useState(false);
  const [isOpenPin, setIsOpenPin] = useState<ModalState | number>(null);
  const [isOpenUpload, setIsOpenUpload] = useState<ModalState>(null);
  const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState<WorkBreakdownStructure | IssueDiscipline | null>(null);
  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');
  const [plan, setPlan] = useState<IssueAttachment | null>(null);
  const [planImage, setPlanImage] = useState<Attachment | null>(null);
  const [attachmentsInfo, setAttachmentsInfo] = useState<Attachment[]>([]);
  const [issuesHistory, setIssuesHistory] = useState<IssueHistory[]>([]);
  const formValuesWatch = Form.useWatch([], form);
  const [showContent, setShowContent] = useState(true);
  const [isAvailableSubmit, setIsAvailableSubmit] = useState(false);
  const [isAvailableSaveDraft, setIsAvailableSaveDraft] = useState(true);
  const [phasesParams] = useProjectPhasesParams({
    workspaceId,
    projectId,
    orderBy: 'order',
  });
  const [phases] = useProjectPhases(phasesParams);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [inspectionName, setInspectionName] = useState('');
  const [isAssigneeEdit, setIsAssigneeEdit] = useState(false);
  const [isAssignerEdit, setIsAssignerEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isGoodPractice, setIsGoodPractice] = useState<boolean>(false);
  const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
  const isUnSaveStatus = !issueId && isOpen;
  const [isAttachmentDisabled, setIsAttachmentDisabled] = useState<boolean>(false);
  const isDraftStatus = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.DRAFT;
  const isClosedStatus = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.CLOSED;
  const isRFIStatus = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.READY_FOR_INSPECTION;
  const isNotAssigneeOrReporter =
    profile?.id !== selectedIssue?.assigneeId && profile?.id !== selectedIssue?.reporterId && !isUnSaveStatus;

  const isAssignerCompany =
    userCompanyId === users?.find((user) => user.id === selectedIssue?.reporterId)?.WorkspaceUser?.Company?.id;
  const isAssigneeCompany = userCompanyId === selectedIssue?.companyId;
  const goodPracticeType = issueTypes.find((issueType) => issueType.code === 'GP');

  const inspectionPhotoType = useMemo(() => {
    return attachmentTypes?.find((i) => i.code === ATTACHMENT_TYPES.INSPECTION_PHOTO);
  }, [attachmentTypes]);
  const [messageInput, setMessageInput] = useState('');
  const [zoneUpdated, setZoneUpdated] = useState<string>(zones?.[0]?.id);
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);

  type FormValues = {
    name: string | null | undefined;
    description: string | null | undefined;
    isSaveAsDraft: boolean | undefined;
    issueStatusId: string | null | undefined;
    issueTypeId: string | null | undefined;
    issuePriorityId: string | null | undefined;
    issueDisciplineId: string | null | undefined;
    zoneId: string | null | undefined;
    assigneeId: string | null | undefined;
    companyId: string | null | undefined;
    plannedStartDate: Date | dayjs.Dayjs | string | null | undefined;
    plannedEndDate: Date | dayjs.Dayjs | string | null | undefined;
    effectiveStartDate: Date | dayjs.Dayjs | string | null | undefined;
    effectiveEndDate: Date | dayjs.Dayjs | string | null | undefined;
    comment: string | null | undefined;
    plan: IssueAttachment | null | undefined;
    assigneeName: string | null | undefined;
    activityId: string | null | undefined;
    disciplineId: string | null | undefined;
    phaseId: string | null | undefined;
    watcherIds: string[] | null | undefined;
  };

  useEffect(() => {
    console.log(form.getFieldsValue());
  }, [form.getFieldsValue()]);

  const getIssue = async (issueId: string) => {
    try {
      setIsLoading(true);
      const issueData = await issueService.getIssueById(workspaceId, projectId, issueId, {
        include: 'IssueWatchers|Plans|IssueStatus|Zone',
      });
      setSelectedIssue(issueData);
      if (issueData.plannedStartDate) {
        issueData.plannedStartDate = dayjs(issueData.plannedStartDate);
      }
      if (issueData.plannedEndDate) {
        issueData.plannedEndDate = dayjs(issueData.plannedEndDate);
      }
      if (issueData.effectiveStartDate) {
        issueData.effectiveStartDate = dayjs(issueData.effectiveStartDate);
      }
      if (issueData.effectiveEndDate) {
        issueData.effectiveEndDate = dayjs(issueData.effectiveEndDate);
      }
      form.setFieldsValue(issueData);
      if (issueData?.Plans?.length) {
        setPlanImage(issueData.Plans[0]);
        setPlan(issueData?.Plans[0].IssueAttachment || null);
      } else {
        setPlanImage(null);
        setPlan(null);
      }
      let zonesTree = zonesTreeData || [];
      if (zonesTree.length === 0) zonesTree = convertZoneToTreeData(zones);
      setZoneUpdated(findPath(zonesTree, issueData.zoneId));
      setInspectionName(issueData.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(t((error as Error).message));
    }
  };

  useEffect(() => {
    if (planData) {
      setPlan(planData);
      if (planData?.Attachment) {
        setPlanImage(planData.Attachment);
      }
    }
  }, [planData]);

  useEffect(() => {
    let attachmentDisable = true;
    const isOpenStatus = selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.OPEN;
    if (isDraftStatus && isAssignerCompany) {
      setIsDisabled(false);
      attachmentDisable = false;
    } else {
      setIsDisabled(true);
      if (isOpenStatus && isAssignerCompany) setIsDisabled(false);
      if (isOpenStatus && (isAssignerCompany || isAssigneeCompany || !isNotAssigneeOrReporter))
        attachmentDisable = false;
    }
    if (isUnSaveStatus) {
      setIsDisabled(false);
      attachmentDisable = false;
    }
    setIsAttachmentDisabled(attachmentDisable);
  }, [selectedIssue?.IssueStatus, isUnSaveStatus, isAssignerCompany]);

  useEffect(() => {
    if (issueId && isOpen) {
      getIssue(issueId);
      setIsAssigneeEdit(false);
      setIsAssignerEdit(false);
    } else {
      if (zones) {
        form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
        setZoneUpdated(findPath(zonesTreeData, zones?.find((zone) => zone.parentId === null)?.id || zones[0].id));
      }
      form.setFieldValue('plannedStartDate', dayjs());
      form.setFieldValue('name', '');
      setInspectionName('');
    }
  }, [issueId, isOpen, zones]);

  useEffect(() => {
    if (selectedIssue) {
      if (selectedIssue?.issueDisciplineId !== null && module === 'Safety') {
        const issueDiscipline = issueDisciplines?.find((d) => d.id === selectedIssue?.issueDisciplineId);
        setSelectedDiscipline(issueDiscipline || null);
      } else if (selectedIssue?.disciplineId) {
        const discipline = disciplines?.find((d) => d.id === selectedIssue?.disciplineId);
        setSelectedDiscipline(discipline || null);
      }
    }
  }, [selectedIssue, issueDisciplines]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setAttachments([]);
      setAttachmentsInfo([]);
      setPlan(null);
      setSelectedDiscipline(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (issueStatuses?.length && !issueId && isOpen) {
      form.setFieldValue('issueStatusId', issueStatuses.find((status) => status.code === ISSUE_STATUSES.DRAFT)?.id);
    }
  }, [issueStatuses, issueId, isOpen]);

  useEffect(() => {
    if (issuePriorities?.length && !issueId && isOpen) {
      form.setFieldValue('issuePriorityId', issuePriorities?.find((item) => item.code === ISSUE_PRIORITIES.MEDIUM)?.id);
    }
  }, [issuePriorities, issueId, isOpen]);

  useEffect(() => {
    if (zones?.length && !issueId && isOpen) {
      if (!isFromPlan) {
        form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
      }

      // Discipline setup
      const generalDiscipline = issueDisciplines?.find((type) => type.code === ISSUE_DISCIPLINES.GENERAL);
      form.setFieldValue('issueDisciplineId', generalDiscipline?.id);

      // Quality setup
      if (module === 'Safety') {
        const issueType = issueTypes?.find((type) => type.code === ISSUE_TYPES.SAFETY_OBSERVATIONS);
        form.setFieldValue('issueTypeId', issueType?.id);
      } else {
        const issueType = issueTypes?.find((type) => type.code === ISSUE_TYPES.QUALITY_OBSERVATIONS);
        form.setFieldValue('issueTypeId', issueType?.id);
      }
    }
  }, [zones, issueId, isOpen]);

  useEffect(() => {
    if (zones?.length && !issueId && plan?.Attachment?.zoneId) {
      form.setFieldValue('zoneId', plan?.Attachment?.zoneId);
      setZoneUpdated(findPath(zonesTreeData, plan?.Attachment?.zoneId));
    }
  }, [zones, issueId, isOpen, plan]);
  const handleMessageChange = (event: { target: { value: SetStateAction<string> } }) => {
    setMessageInput(event.target.value);
  };
  const convertSubmitData = (values: FormValues, isSaveAsDraft: boolean = false) => {
    values.isSaveAsDraft = isSaveAsDraft;

    if (values.plannedStartDate) {
      values.plannedStartDate = dayjs(values.plannedStartDate).format('YYYY-MM-DD');
    }
    if (values.plannedEndDate) {
      values.plannedEndDate = dayjs(values.plannedEndDate).format('YYYY-MM-DD');
    }
    if (values.effectiveStartDate) {
      values.effectiveStartDate = dayjs(values.effectiveStartDate).format('YYYY-MM-DD');
    }
    if (values.effectiveEndDate) {
      values.effectiveEndDate = dayjs(values.effectiveEndDate).format('YYYY-MM-DD');
    }
    values.plan = plan;
    if (!values.comment) {
      values.comment = messageInput;
    }
    if (!values.assigneeId) {
      values.assigneeId = null;
    } else {
      const user = users.find((user) => user.id === values.assigneeId);
      values.assigneeName = user?.fullName || null;
    }
    if (!values.companyId) {
      values.companyId = null;
    }
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      try {
        values.isSaveAsDraft = false;
        convertSubmitData(values);
        setIsSubmit(true);
        values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.OPEN)?.id;
        if (isUnSaveStatus) {
          values.attachmentsInfo = attachmentsInfo.map((info) => ({
            name: info.name,
            filePath: info.filePath,
            IssueAttachments: info.IssueAttachments,
          }));
        }
        if (issueId) {
          await issueService.updateIssue(workspaceId, projectId, issueId, values);
        } else {
          await issueService.createIssue(workspaceId, projectId, values);
        }
        refreshIssues();
        setIsSubmit(false);
        onFinish();
        form.resetFields();
        setSelectedIssue(null);
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
        message.error(t('Oop! Something wrong'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitAsDraft = async () => {
    try {
      const vaild = await form.validateFields(['name']);
      if (vaild) {
        try {
          if (isAvailableSaveDraft) {
            const values = await form.getFieldsValue();
            try {
              values.isSaveAsDraft = true;
              convertSubmitData(values, true);
              setIsSubmitAsDraft(true);
              values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.DRAFT)?.id;
              values.attachmentsInfo = attachmentsInfo.map((info) => ({
                name: info.name,
                filePath: info.filePath,
                IssueAttachments: info.IssueAttachments,
              }));
              if (issueId) {
                await issueService.updateIssue(workspaceId, projectId, issueId, values);
              } else {
                await issueService.createIssue(workspaceId, projectId, values);
              }
              setIsSubmitAsDraft(false);
              setSelectedIssue(null);
              onFinish();
            } catch (error) {
              setIsSubmitAsDraft(false);
              setSelectedIssue(null);
              console.log(error);
              message.error(t('Oop! Something wrong'));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCallForReview = async () => {
    try {
      const values = await form.getFieldsValue();
      convertSubmitData(values);
      values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.READY_FOR_INSPECTION)?.id;
      if (issueId) {
        await issueService.updateIssue(workspaceId, projectId, issueId, values);
      } else {
        await issueService.createIssue(workspaceId, projectId, values);
      }
      onFinish();
      setSelectedIssue(null);
    } catch (error) {
      console.log(error);
    }
  };

  const onReject = async () => {
    try {
      const values = await form.getFieldsValue();
      convertSubmitData(values);
      values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.OPEN)?.id;
      if (issueId) {
        await issueService.updateIssue(workspaceId, projectId, issueId, values);
      }
      onFinish();
      setSelectedIssue(null);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitWatchers = async () => {
    try {
      setIsSubmit(true);
      const watcherIds = await form.getFieldValue(['watcherIds']);
      const data: { watcherIds: string[]; isSaveAsDraft: boolean } = {
        watcherIds: watcherIds,
        isSaveAsDraft: true,
      };
      if (workspaceId && projectId && issueId) {
        await issueService.updateIssue(workspaceId, projectId, issueId, data);
      }
      setIsSubmit(false);
      onFinish();
      setIsAssigneeEdit(false);
      setIsAssignerEdit(false);
      setSelectedIssue(null);
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };

  const onValidateForm = async () => {
    try {
      setIsSubmit(true);
      const values = await form.getFieldsValue();
      values.effectiveEndDate = dayjs();
      convertSubmitData(values);
      values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.CLOSED)?.id;
      if (issueId) {
        await issueService.updateIssue(workspaceId, projectId, issueId, values);
      } else {
        await issueService.createIssue(workspaceId, projectId, values);
      }
      setIsSubmit(false);
      onFinish();
      setIsAssigneeEdit(false);
      setIsAssignerEdit(false);
      setSelectedIssue(null);
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };

  const onDelete = async () => {
    try {
      setIsDelete(true);
      await issueService.deleteIssueById(workspaceId, projectId, issueId as string);
      setIsDelete(false);
      onFinish();
      setSelectedIssue(null);
    } catch (error) {
      setIsDelete(false);
      console.log(error);
    }
  };

  const onChangeIntoCloseStatus = async () => {
    try {
      await form.validateFields(['name']);
      setIsClosing(true);
      const values = await form.getFieldsValue();
      values.isSaveAsDraft = true;
      values.effectiveEndDate = dayjs();
      convertSubmitData(values, true);
      values.issueStatusId = issueStatuses?.find((status) => status.code === ISSUE_STATUSES.CLOSED)?.id;
      if (issueId) {
        await issueService.updateIssue(workspaceId, projectId, issueId, values);
      } else {
        await issueService.createIssue(workspaceId, projectId, values);
      }
      setIsClosing(false);
      onFinish();
      setIsAssigneeEdit(false);
      setIsAssignerEdit(false);
      setSelectedIssue(null);
    } catch (error) {
      setIsClosing(false);
      console.log(error);
    }
  };

  const getAttachments = async () => {
    try {
      setIsLoadingAttachment(true);
      if (isUnSaveStatus) {
        const transformedAttachments = attachmentsInfo.map((info) => ({
          ...info,
          thumbnail: info.thumbnail ?? undefined,
        })) as Attachment[];

        setAttachments(transformedAttachments);
      } else if (!isUnSaveStatus && isOpen) {
        const { rows } = await attachmentService.getAttachments(workspaceId, projectId, {
          projectId,
          issueId,
          attachmentTypeId: inspectionPhotoType?.id,
          limit: 'unlimited',
          orderBy: 'createdAt,desc',
        });
        setAttachments(rows);
      }
      setIsLoadingAttachment(false);
    } catch (error) {
      setIsLoadingAttachment(false);
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };
  const getHistories = async () => {
    if (!isUnSaveStatus && isOpen && issueId) {
      setIsLoadingHistory(true);
      try {
        const issueHistoryData = await issueService.getIssueHistoryById(workspaceId, projectId, issueId, {
          include: 'Attachment',
        });
        setIssuesHistory(issueHistoryData);
      } catch (error) {
        message.error(t('Oop! Something wrong'));
        console.log(error);
      } finally {
        setIsLoadingHistory(false);
      }
    }
  };
  const handleToggleContent = () => {
    setOpen(false);
    setSelectedIssue(null);
  };
  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: module && module === 'Safety' ? t('Inspection details') : t('Observation details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      disabled: false,
      icon: <LinkOutlined />,
      children: null,
    },
    {
      key: 'comments',
      label: t('History'),
      disabled: !issueId,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
    if (key === 'attachments' && issueId) {
      getAttachments();
    }
    if (key === 'comments' && issueId) {
      getHistories();
    }
  };
  const onClose = () => {
    setOpen(false);
    setIsModalVisible(false);
    setSelectedIssue(null);
  };

  const handleShowDrawerContent = () => {
    setActiveTab('detail');
    setShowContent(false);
  };

  const onUpload = async (values: {
    fileUrl: string;
    fileName: string;
    width: number;
    height: number;
    comment: string;
  }) => {
    try {
      if (!isUnSaveStatus) {
        await attachmentService.createAttachment(workspaceId, projectId, {
          filePath: values.fileUrl,
          name: values.fileName,
          width: values.width,
          height: values.height,
          attachmentTypeId: inspectionPhotoType?.id as string,
          zoneId: selectedIssue?.zoneId,
          issueId,
          comment: values.comment,
        });
      } else {
        setAttachmentsInfo([
          ...attachmentsInfo,
          {
            id: String(attachmentsInfo.length),
            name: values.fileName,
            filePath: values.fileUrl,
            width: values.width,
            height: values.height,
            projectId: null,
            thumbnail: values.fileUrl,
            thumbnail2x: values.fileUrl,
            zoneId: null,
            attachmentTypeId: null,
            comment: values.comment,
            IssueAttachments: [
              {
                comment: values.comment,
                createdAt: new Date().toISOString(),
                userCreated: profile?.id,
              },
            ],
          },
        ]);
      }
      getAttachments();
      setIsUploading(false);
      setIsOpenUpload(false);
    } catch (error) {
      setIsUploading(false);
      message.error(t('Oop! Something wrong'));
      console.log(error);
    }
  };
  const onDeleteAttachment = async (attachmentId: string) => {
    try {
      if (isUnSaveStatus) {
        setAttachmentsInfo((prevInfo) => prevInfo.filter((info) => info.id !== attachmentId));
      } else {
        await attachmentService.deleteAttachment(workspaceId, projectId, attachmentId);
      }
      getAttachments();
    } catch (error) {
      message.error(t('Oop! Something wrong'));
      console.log(error);
    }
  };

  useEffect(() => {
    setShowContent(true);
    setActiveTab('detail');
  }, [props.isOpen]);

  const onChangeIssueDiscipline = (value: string) => {
    const discipline = issueDisciplines?.find((discipline) => discipline.id === value);
    setSelectedDiscipline(discipline || null);
  };

  const onChangeWbsDiscipline = (value: string) => {
    const discipline = disciplines?.find((discipline) => discipline.id === value);
    setSelectedDiscipline(discipline || null);
  };

  useEffect(() => {
    if (isOpen) {
      const issueTypeId = form.getFieldValue('issueTypeId');
      if (goodPracticeType?.id && goodPracticeType?.id === issueTypeId) {
        setIsGoodPractice(true);
      } else {
        setIsGoodPractice(false);
      }
      if (formValuesWatch?.name?.length > 50 || formValuesWatch?.description?.length > 300) {
        setIsAvailableSaveDraft(false);
        setIsAvailableSubmit(false);
      } else {
        setIsAvailableSaveDraft(true);
      }

      if (
        formValuesWatch?.companyId &&
        // formValuesWatch?.phaseId &&
        formValuesWatch?.name !== '' &&
        formValuesWatch?.name?.length <= 50 &&
        formValuesWatch?.issueTypeId &&
        formValuesWatch?.issuePriorityId &&
        formValuesWatch?.zoneId &&
        (!formValuesWatch?.description || formValuesWatch?.description?.length <= 300) &&
        (formValuesWatch?.issueDisciplineId || formValuesWatch?.disciplineId)
      ) {
        setIsAvailableSubmit(true);
      } else {
        setIsAvailableSubmit(false);
      }
    }
  }, [formValuesWatch]);

  const onChangeLocationPlan = (
    zoneId: string | null,
    planData: IssueAttachment | null,
    planImageData: Attachment | null
  ) => {
    form.setFieldValue('zoneId', zoneId);
    setPlan(planData);
    setPlanImage(planImageData);
  };

  const onChangeForm = (value: { name?: SetStateAction<string>; zoneId?: SetStateAction<string> }) => {
    if (Object.keys(value)?.[0] === 'name') {
      setInspectionName(value.name as string);
    }
    if (Object.keys(value)?.[0] === 'zoneId') {
      setPlan(null);
      setPlanImage(null);
    }
    setIsAssignerEdit(true);
    setIsAssigneeEdit(true);
  };

  const onClearInspectionPin = () => {
    setPlan(null);
    setPlanImage(null);
  };

  const miniPlanImage = useMemo(() => {
    return plan?.Attachment || planImage;
  }, [planImage, plan]);

  const onSubmitComment = () => {};
  useEffect(() => {
    getAttachments();
  }, [attachmentsInfo]);

  useEffect(() => {
    if (!isOpen) {
      setMessageInput('');
    }
  }, [onReject]);

  const onSubmitDrawing = async (attachment: Attachment, layerData: LayerData | null) => {
    try {
      if (isUnSaveStatus) {
        const newAttachments = [...attachmentsInfo];
        const selectedIndex = attachmentsInfo.findIndex((info) => info.id === attachment.id);
        newAttachments[selectedIndex].IssueAttachments = [
          { ...newAttachments[selectedIndex].IssueAttachments?.[0], layerData, layerType: getLayerType(layerData) },
        ];
        setAttachmentsInfo(newAttachments);
      } else {
        setIsSubmitAttachment(true);
        await issueAttachmentService.updateIssueAttachment(
          workspaceId,
          projectId,
          attachment.IssueAttachments?.[0]?.id as string,
          {
            ...attachment.IssueAttachments?.[0],
            layerData,
            layerType: getLayerType(layerData),
          }
        );
        setIsSubmitAttachment(false);
      }
      getAttachments();
    } catch (error) {
      setIsSubmitAttachment(false);
      console.log(error);
    }
  };
  useEffect(() => {
    const tree = convertZoneToTreeData(zones);
    setZonesTreeData(tree);
  }, [zones]);
  useEffect(() => {
    const zoneId = form.getFieldValue('zoneId');
    setZoneUpdated(findPath(zonesTreeData, zoneId));
  }, [isOpenPin]);
  return (
    <Form form={form} labelCol={{ span: 24 }} layout='vertical' onValuesChange={onChangeForm}>
      {isOpenUpload !== null && (
        <UploadModal
          isModalOpen={isOpenUpload}
          setIsModalOpen={setIsOpenUpload}
          onSubmit={onUpload}
          isLoading={isUploading}
          setIsLoading={setIsUploading}
        />
      )}
      {isOpenPin !== null && (
        <PinInspection
          issueStatuses={issueStatuses}
          module={module}
          isModalOpen={isOpenPin}
          setIsModalOpen={setIsOpenPin}
          onSubmit={onChangeLocationPlan}
          formInstance={form}
          plan={plan}
          inspectionName={inspectionName}
          planImage={planImage}
          zones={zones}
        />
      )}
      <Drawer
        className={` ${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
        styles={{
          header:
            module && module === 'Safety'
              ? {
                  backgroundColor: TASK_STATUS_HEADER_COLOR['SAFETY_HEADER'],
                  boxShadow: 'inset 0em -0.2em 0.2em #FBF2E6',
                }
              : {
                  backgroundColor: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'],
                  boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
                },
        }}
        title={
          <CreateIssueHeader
            onClose={onClose}
            visible={isModalVisible}
            isCreateTaskVisible={isOpen}
            isUnSaveStatus={!issueId && isOpen}
            inspectionName={inspectionName}
            onToggleContent={handleToggleContent}
            onShowDrawerContent={handleShowDrawerContent}
            formInstance={form}
            discipline={selectedDiscipline}
            initialHeaderValue={''}
            selectedIssue={selectedIssue}
            users={users}
            profile={profile}
          />
        }
        placement={'right'}
        width={715}
        onClose={onClose}
        closeIcon={false}
        open={isOpen}
        maskClosable={false}
        destroyOnClose={false}
        footer={
          <CreateIssueFooter
            issue={selectedIssue}
            issueId={issueId}
            onSubmitComment={onSubmitComment}
            isDelete={isDelete}
            isClosing={isClosing}
            isSubmit={isSubmit}
            isSubmitAsDraft={isSubmitAsDraft}
            isAvailableSubmit={useMemo(() => isAvailableSubmit, [isAvailableSubmit])}
            issueCode={selectedIssue?.IssueStatus?.code}
            onDelete={onDelete}
            onClose={onChangeIntoCloseStatus}
            onSubmit={onSubmit}
            onSubmitAsDraft={onSubmitAsDraft}
            isAssigneeEdit={isAssigneeEdit}
            isAssignerEdit={isAssignerEdit}
            onCallForReview={onCallForReview}
            profile={profile}
            users={users}
            onSubmitWatchers={() => onSubmitWatchers()}
            onValidateForm={() => onValidateForm()}
            onReject={() => onReject()}
            workspaceId={workspaceId}
            projectId={projectId}
            onFinish={onFinish}
            isDisabled={isDisabled}
            isNotAssigneeOrReporter={isNotAssigneeOrReporter && !isAssignerCompany}
            isDraftStatus={isDraftStatus}
            handleMessageChange={handleMessageChange}
            module={module}
            isGoodPractice={isGoodPractice}
          />
        }
      >
        <Spin spinning={isLoading}>
          {showContent && (
            <Tabs
              defaultActiveKey='detail'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
              tabBarExtraContent={
                <Flex justify={'space-between'} align={'center'}>
                  <Button type='text' size='small' key={'detail'} onClick={() => handleTabChange('detail')}></Button>
                  <Button
                    type='text'
                    size='small'
                    key={'attachments'}
                    onClick={() => handleTabChange('attachments')}
                  ></Button>
                  <Button
                    type='text'
                    size='small'
                    key={'comments'}
                    onClick={() => handleTabChange('comments')}
                  ></Button>
                </Flex>
              }
            />
          )}
          {showContent && (
            <div>
              <div className={`${activeTab === 'detail' ? 'visible zindex-high' : 'hidden zindex-low'}`}>
                <CreateIssueDetail
                  onValuesChange={onChangeForm}
                  issueDisciplines={issueDisciplines}
                  issuePriorities={issuePriorities}
                  issueTypes={issueTypes}
                  companies={companies}
                  users={users}
                  zones={zones}
                  form={form}
                  issue={selectedIssue}
                  phases={phases}
                  onChangeDiscipline={onChangeIssueDiscipline}
                  isUnSaveStatus={!issueId && isOpen}
                  hasPlan={!!plan}
                  onSelectPlan={() => setIsOpenPin(true)}
                  onChangeWatcher={() => setIsAssigneeEdit(true)}
                  profile={profile}
                  isClosedStatus={isClosedStatus}
                  isRFIStatus={isRFIStatus}
                  isOpen={isOpen}
                  isDraftStatus={isDraftStatus}
                  isNotAssigneeOrReporter={isNotAssigneeOrReporter}
                  isAssigner={isAssignerCompany}
                  isDisabled={isDisabled}
                  isFromPlan={isFromPlan}
                  zoneUpdated={zoneUpdated}
                  setZoneUpdated={setZoneUpdated}
                  zonesTreeData={zonesTreeData}
                  module={module}
                  disciplines={disciplines}
                  activities={activities}
                  onChangeWbsDiscipline={onChangeWbsDiscipline}
                  isGoodPractice={isGoodPractice}
                />
                {plan && miniPlanImage && (
                  <Flex justify='center'>
                    <MiniPlan
                      width={246}
                      onDelete={onClearInspectionPin}
                      filePath={miniPlanImage?.filePath as string}
                      imgWidth={miniPlanImage?.width as number}
                      imgHeight={miniPlanImage?.height as number}
                      onClick={() => setIsOpenPin(1)}
                      layers={plan?.layerData ? [plan?.layerData] : []}
                      isDisabled={isDisabled || (isFromPlan ? !isFromPlan : isDisabled)}
                      module={module}
                      plan={plan}
                      inspectionName={inspectionName}
                      planImage={planImage}
                    />
                  </Flex>
                )}
              </div>
              <div className={`${activeTab === 'attachments' ? 'visible zindex-high' : 'hidden zindex-low'}`}>
                <Spin spinning={isLoadingAttachment && !attachments?.length}>
                  <CreateAttachment
                    attachments={attachments}
                    onSubmitDrawing={onSubmitDrawing}
                    isLoading={isSubmitAttachment}
                    onAddAttachment={setIsOpenUpload}
                    onDelete={onDeleteAttachment}
                    issue={selectedIssue}
                    isAttachmentDisable={isAttachmentDisabled}
                    users={users}
                  />
                </Spin>
              </div>
              {activeTab === 'comments' && (
                <CreateComment
                  isClosedStatus={selectedIssue?.IssueStatus?.code === ISSUE_STATUSES.CLOSED}
                  users={users}
                  issueHistory={issuesHistory}
                  isLoading={isLoadingHistory}
                  module={module}
                />
              )}
            </div>
          )}
        </Spin>
      </Drawer>
    </Form>
  );
}
