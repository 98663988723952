import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Space,
  Steps,
  TableColumnsType,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { CustomizeRequiredMark } from 'components/common';
import { RcFile } from 'antd/es/upload';
import Table from 'antd/es/table';
import FILE_STORAGE from 'assets/images/file-storage.svg';
import { fileService } from 'services';
import { useAppSelector } from 'store';
import { selectAttachmentByCode } from 'store/common.slice';
import { ATTACHMENT_TYPES } from 'utils/contants';

const { Dragger } = Upload;
const { Text } = Typography;

export type UploadMultipleDocumentFileValue = {
  id: string;
  filePath: string;
  name: string;
  documentName: string;
  revision: string;
  attachmentTypeId?: string;
};

type UploadMultipleDocumentProps = {
  isModalOpen: boolean;
  attachmentTypeCode: (typeof ATTACHMENT_TYPES)[keyof typeof ATTACHMENT_TYPES];
  setIsModalOpen: (value: boolean) => void;
  handleAddDocument: (documents: UploadMultipleDocumentFileValue[]) => void;
} & ModalProps;

interface DataType {
  uid: string;
  name: string;
  documentName: string;
  revision: string;
  file: RcFile;
}

export default function UploadMultipleDocument(props: UploadMultipleDocumentProps) {
  const { isModalOpen, setIsModalOpen, handleAddDocument } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState<DataType[]>([]);

  const [current, setCurrent] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const attachmentTypes = useAppSelector(selectAttachmentByCode(props?.attachmentTypeCode));
  const isDisableUpload = useMemo(() => {
    return files?.filter((file) => selectedRowKeys.includes(file.uid))?.some((file) => !file.documentName);
  }, [files, selectedRowKeys]);

  const next = async () => {
    switch (current) {
      case 1: {
        setLoading(true);
        const filesPromise = files.map(async (file) => {
          const uploadedFile = await fileService.uploadFileAdvanced(file.file, file.name, 'application/pdf', 10);
          return {
            id: file.uid,
            filePath: uploadedFile.fileUrl,
            name: file.name,
            documentName: file.documentName,
            revision: file.revision,
            attachmentTypeId: attachmentTypes?.id,
          };
        });
        const fileResult = await Promise.all(filesPromise);
        const selectedFiles = fileResult?.filter((file) => selectedRowKeys.includes(file.id));
        setLoading(false);
        handleAddDocument(selectedFiles);
        break;
      }
      default:
        break;
    }
    setCurrent(current + 1);
  };

  useEffect(() => {
    if (isModalOpen) {
      setFiles([]);
      setCurrent(0);
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onBeforeUpload = async (file: RcFile, fileList: RcFile[]) => {
    const hasError = fileList.some((file) => file.type !== 'application/pdf');

    if (hasError) {
      message.error(t('Please upload only PDF files.'));
      setIsError(true);
    } else {
      const fileResult = fileList.map((file) => {
        const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'));
        return {
          uid: file.uid,
          name: file.name,
          documentName: nameWithoutExtension,
          revision: '',
          file: file,
        };
      });
      setFiles(fileResult);
      setSelectedRowKeys(fileResult?.map((file) => file.uid) || []);
      setIsError(false);
    }

    return false;
  };

  const updateFilesValue = (uid: string, key: keyof DataType, value: string) => {
    const fileIndex = files.findIndex((file) => file.uid === uid);
    if (fileIndex !== -1 && key !== 'file') {
      files[fileIndex][key] = value;
    }
    setFiles([...files]);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: 'uid',
      render: () => {
        return null;
      },
    },
    {
      key: 'name',
      title: t('File name'),
      dataIndex: 'name',
      render: (fileName: string) => {
        const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        return (
          <Text>
            <PaperClipOutlined className={'mr-1'} />
            {nameWithoutExtension}
          </Text>
        );
      },
      width: '200px',
    },
    {
      key: 'documentName',
      title: (
        <span>
          {t('Document Name / Description')} <span className='text-red-600'>*</span>
        </span>
      ),
      dataIndex: 'documentName',
      render: (value, record) => (
        <Form.Item className={'document-transmission-upload-input'} rules={[{ required: true }]}>
          <Input
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = (e.target as HTMLInputElement).value;
              updateFilesValue(record.uid, 'documentName', value);
            }}
          />
        </Form.Item>
      ),
    },
    {
      key: 'revision',
      title: t('Revision'),
      dataIndex: 'revision',
      render: (_, record) => (
        <Form.Item className={'document-transmission-upload-input'}>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = (e.target as HTMLInputElement).value;
              updateFilesValue(record.uid, 'revision', value);
            }}
          />
        </Form.Item>
      ),
      width: '130px',
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const firstStepContent = () => {
    return (
      <Form requiredMark={CustomizeRequiredMark} form={form} layout='vertical'>
        <Form.Item>
          <Dragger
            name='file'
            multiple={true}
            accept='application/pdf'
            showUploadList={false}
            className={isError ? 'error' : ''}
            beforeUpload={onBeforeUpload}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
            <p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
          </Dragger>
        </Form.Item>
        {files.map((file) => (
          <Flex align={'center'} className={'mb-2'} justify={'space-between'} key={file.uid}>
            <Text>
              <PaperClipOutlined className={'mr-1'} />
              {file.name}
            </Text>
          </Flex>
        ))}
      </Form>
    );
  };
  const secondContent = () => {
    return (
      <Form className={'document-transmission-upload-form'} form={form}>
        <Table
          rowKey={(row) => row.uid}
          className={'document-transmission-upload-table'}
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={files}
        />
      </Form>
    );
  };
  const lastContent = () => {
    return (
      <Flex className={'document-transmission-upload-last-content'}>
        <img className={'mt-5'} src={FILE_STORAGE} alt='' width={80} height={80} />
        <Text className={'last-content-text'}>
          {t('Your files are uploading, you can close this window and resume your work.')}
        </Text>
      </Flex>
    );
  };

  const steps = [
    {
      key: 'Select Files',
      title: t('Select Files'),
      content: firstStepContent(),
    },
    {
      key: 'Add Information',
      title: t('Add Information'),
      content: secondContent(),
    },
    {
      key: 'Upload',
      title: t('Upload'),
      content: lastContent(),
    },
  ];

  const customFooter = () => {
    return (
      <Flex className={'document-transmission-upload-custom-footer'} justify={'space-between'} align={'center'}>
        {current == 1 ? (
          <Text
            className={'files-to-upload'}
          >{`${selectedRowKeys.length}/${files.length} ${t('Files to upload')}`}</Text>
        ) : (
          <Text></Text>
        )}
        <Space>
          {current !== 2 && (
            <Button key='back' onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          )}
          {current === 0 ? (
            <Button
              key='submit'
              type='primary'
              onClick={() => {
                next();
              }}
              disabled={files.length === 0}
              loading={loading}
              className='mar-add-document-text'
            >
              {t('Next ')}
            </Button>
          ) : current === 1 ? (
            <Button
              key='submit'
              type='primary'
              onClick={() => {
                next();
              }}
              disabled={selectedRowKeys.length === 0 || isDisableUpload}
              loading={loading}
              className='mar-add-document-text'
            >
              {loading ? t('Uploading') : t('Upload')}
            </Button>
          ) : (
            <Button
              key='submit'
              type='primary'
              onClick={handleCancel}
              loading={loading}
              className='mar-add-document-text'
            >
              {t('close')}
            </Button>
          )}
        </Space>
      </Flex>
    );
  };
  return (
    <Modal
      className={`upload-multiple-documents`}
      title={t('Upload Documents')}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={customFooter}
      width={468}
    >
      <Steps
        className={'mb-5'}
        current={current}
        items={steps.map((item) => ({ key: item.title, title: item.title }))}
      />
      <div>{steps[current].content}</div>
    </Modal>
  );
}
