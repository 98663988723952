import { Flex, Form, FormInstance, FormListOperation, Typography } from 'antd';
import { Company, User } from 'model';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderWithButton, { HeaderWithButtonProps } from './HeaderWithButton';
import SearchSelect from './SearchSelect';
import ReadOnlyDataField from './ReadOnlyDataField';
import { DeleteOutlined } from '@ant-design/icons';
import { Omit } from '@reduxjs/toolkit/dist/tsHelpers';
import ProjectUser from 'model/ProjectUser';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

type InviteFormFieldProps<T> = {
  form: FormInstance<T>;
  name: string;
  companies: Company[];
  users: User[];
  header?: Omit<HeaderWithButtonProps, 'onClick'>;
  renderTitle?: (add: FormListOperation['add']) => JSX.Element;
  renderFooter?: (add: FormListOperation['add']) => JSX.Element;
  isReadOnly?: boolean;
  fieldLabel?: {
    company: string;
    name: string;
  };
  isRequire?: boolean;
  showDefault?: boolean;
};
type inviteFieldValue = {
  companyId?: string;
  userId?: string;
};

type SelectCompanyOptions = {
  label: string;
  value: string;
  disabled: boolean;
  userIds: (string | undefined)[];
};

const InviteFormField = <T,>(props: InviteFormFieldProps<T>) => {
  const { t } = useTranslation();
  // const { profile } = useAuthContext();
  const form = useFormInstance();

  const watchedValues =
    (Form?.useWatch?.(props.name, props.form) as inviteFieldValue[]) || form.getFieldValue(props.name);
  const companyChangeKeyRef = useRef<null | number>(null);
  const nameChangeRef = useRef<null | number>(null);
  const addRef = useRef<FormListOperation['add']>(() => console.log('Initial function'));

  const companyOptions: SelectCompanyOptions[] = useMemo(() => {
    return props?.companies?.map((company) => {
      const isUserEmpty = company.WorkspaceUsers?.length === 0;
      const userIds = (watchedValues || []).map((watchedValue) => watchedValue.userId).filter((id) => id !== undefined);
      const projectUsers = company?.WorkspaceUsers
        ? company?.WorkspaceUsers?.flatMap((workspaceUser) =>
            workspaceUser?.ProjectUsers?.flatMap((projectUser: ProjectUser) => projectUser.userId)
          ).filter((id) => id !== undefined)
        : [];
      const isFullCompany: boolean = projectUsers.every((userId) => userIds.includes(userId));

      return {
        label: company.name,
        value: company.id,
        disabled: isUserEmpty || isFullCompany,
        userIds: company?.WorkspaceUsers
          ? company?.WorkspaceUsers?.flatMap((workspaceUser) =>
              workspaceUser?.ProjectUsers?.flatMap((projectUser: ProjectUser) => projectUser.userId)
            )
          : [],
      };
    });
  }, [watchedValues, props.companies]);

  const validUser = useMemo(() => {
    return props.users.filter((user) => {
      const validCompany = companyOptions?.filter((company) => !company.disabled);
      return validCompany?.some((company) => company.value === user.WorkspaceUser?.Company?.id);
    });
  }, [companyOptions, props.users, watchedValues]);

  const resetNameOnCompanyChange = () => {
    if (companyChangeKeyRef.current === null) return;
    const currentUserId = watchedValues[companyChangeKeyRef.current].userId;
    const currentCompanyId = watchedValues[companyChangeKeyRef.current].companyId;

    const user = props?.users?.find((user) => user.id === currentUserId);
    const hasUserInCompany = user?.WorkspaceUser?.Company?.id === currentCompanyId;

    if (!hasUserInCompany) {
      watchedValues[companyChangeKeyRef.current].userId = undefined;
      props.form.setFieldValue(props.name, watchedValues);
    }
    companyChangeKeyRef.current = null;
  };

  useEffect(() => {
    resetNameOnCompanyChange();
  }, [watchedValues]);

  const mapDataToOptions = (data: { id: string }[], labelKey: string = 'name') => {
    return data?.map((value) => ({
      value: value.id,
      label: value[labelKey as keyof typeof value],
    }));
  };

  const ItemLabel = ({ name }: { name: string; require?: boolean }) => {
    return (
      <Typography.Text className='text-size-12 h-[32px] leading-[32px]' style={{ color: 'rgba(0, 29, 61, 0.88)' }}>
        <span className='text-color-tiny'>{t(name)}</span>
        {!props.isReadOnly && props?.isRequire && (
          <span className='ml-1' style={{ color: 'red' }}>
            *
          </span>
        )}
      </Typography.Text>
    );
  };

  const isShowDeleteButton = (index: number) => {
    if (props.isReadOnly) return false;
    else if (index === 0 && props?.isRequire) {
      return false;
    }
    return true;
  };

  const generateCompanyUser = (selectedInspector: inviteFieldValue | null) => {
    let result = validUser;

    if (selectedInspector?.companyId) {
      result = props.users?.filter((user) => user.WorkspaceUser?.Company?.id === selectedInspector?.companyId) || [];
    }
    return result.map((user) => {
      const isSelectedUser = watchedValues?.some((watchedValue) => watchedValue.userId === user.id);
      return {
        label: user.fullName,
        value: user.id,
        disabled: isSelectedUser,
      };
    });
  };

  const autofillCompany = () => {
    if (nameChangeRef.current === null) return;
    const userId = watchedValues[nameChangeRef.current].userId;

    const selectedCompany = companyOptions.find((company) => company?.userIds.includes(userId));

    if (!selectedCompany) return;
    watchedValues[nameChangeRef.current].companyId = selectedCompany?.value;
    props.form.setFieldValue(props.name, watchedValues);

    props.form.setFields([
      {
        name: [props.name, nameChangeRef.current, 'companyId'],
        errors: [],
      },
    ]);
    nameChangeRef.current = null;
  };

  useEffect(() => {
    autofillCompany();
  }, [watchedValues, companyOptions]);

  useEffect(() => {
    if (props.showDefault && (!watchedValues || watchedValues?.length === 0)) {
      props.form.setFieldValue(props.name, [{ companyId: null, userId: null }]);
    }
  }, [watchedValues, props.showDefault]);

  return (
    <div className='invite-form-field'>
      {props.renderTitle
        ? props.renderTitle(addRef.current)
        : !!props?.header && (
            <HeaderWithButton
              {...props.header}
              isReadonly={props?.isReadOnly}
              onClick={(e) => {
                addRef.current(e);
              }}
            />
          )}
      <Form.List name={props.name}>
        {(fields, { add, remove }) => {
          addRef.current = add;
          return (
            <div className='invite-for-inspection'>
              <div>
                {(fields?.length > 0 || props.isReadOnly) && (
                  <Flex gap={16}>
                    <div className='flex-item'>
                      <ItemLabel name={props.fieldLabel?.company || 'Company'} />
                      {fields.length === 0 && <div>--</div>}
                    </div>
                    <div className='flex-item'>
                      <ItemLabel name={props.fieldLabel?.name || 'Name'} />
                      {fields.length === 0 && <div>--</div>}
                    </div>
                    <div className='w-[18px]'></div>
                  </Flex>
                )}
                {fields?.map((field, index) => {
                  const selectedInspector = watchedValues?.[field?.name]?.companyId
                    ? watchedValues?.[field?.name]
                    : null;
                  const companyUser = generateCompanyUser(selectedInspector);
                  return (
                    <Flex gap={16} key={field.key}>
                      <div className='flex-item'>
                        {!props.isReadOnly ? (
                          <Form.Item
                            name={[field.name, 'companyId']}
                            rules={[{ required: props.isRequire && index === 0, message: 'Please select company' }]}
                          >
                            <SearchSelect
                              onChange={() => {
                                companyChangeKeyRef.current = field.name;
                              }}
                              placeholder={t('Select company')}
                              options={companyOptions}
                            />
                          </Form.Item>
                        ) : (
                          <div className='mb-[15px]'>
                            <ReadOnlyDataField
                              title={''}
                              content={
                                mapDataToOptions(props.companies)?.find(
                                  (company) => company?.value === selectedInspector?.companyId
                                )?.label || '--'
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className='flex-item'>
                        {!props.isReadOnly ? (
                          <Form.Item
                            name={[field.name, 'userId']}
                            rules={[{ required: props.isRequire && index === 0, message: 'Please select name' }]}
                          >
                            <SearchSelect
                              onChange={() => {
                                nameChangeRef.current = field.name;
                              }}
                              placeholder={t('Select name')}
                              options={companyUser}
                            />
                          </Form.Item>
                        ) : (
                          <div className='mb-[15px]'>
                            <ReadOnlyDataField
                              title={''}
                              content={
                                companyUser?.find((user) => user?.value === selectedInspector?.userId)?.label || '--'
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className='w-[18px]'>
                        {isShowDeleteButton(index) && (
                          <DeleteOutlined
                            className='mt-[7px]'
                            style={{ fontSize: '18px' }}
                            onClick={() => remove(field.name)}
                          />
                        )}
                      </div>
                    </Flex>
                  );
                })}
              </div>
            </div>
          );
        }}
      </Form.List>
      {props.renderFooter && props.renderFooter(addRef.current)}
    </div>
  );
};

export default InviteFormField;
