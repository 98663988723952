import { useRef } from 'react';
import BarplotGrouped from './features/BarplotGrouped';
import { IssueStatusByZone } from 'model';

type GraphStatusByZoneProps = {
  issueStatusByZone: IssueStatusByZone[];
  loading?: any;
};
export const GraphStatusByZone = (props: GraphStatusByZoneProps) => {
  const { issueStatusByZone, loading } = props;
  const svgLargeRef: any = useRef<SVGSVGElement>();
  // type ChartDataType = {
  //   group: string;
  //   DR: number;
  //   OP: number;
  //   CL: number;
  //   RFI: number;
  // };
  // const data: ChartDataType[] = [
  //   { group: 'Basement', DR: 5, OP: 4, RFI: 7, CL: 0, },
  //   { group: 'Basement East', DR: 5, OP: 4, RFI: 7, CL: 0, },
  //   { group: 'Basement West', DR: 5, OP: 4, RFI: 7, CL: 0, },
  //   { group: 'Floor 1', DR: 2, OP: 4, RFI: 5, CL: 5, },
  //   { group: 'Floor 2', DR: 2, OP: 4, RFI: 5, CL: 5, },
  //   { group: 'Floor 3', DR: 2, OP: 4, RFI: 5, CL: 5, },
  //   { group: 'General', DR: 3, OP: 8, RFI: 4, CL: 7, },
  //   { group: 'Rooftop', DR: 3, OP: 8, RFI: 4, CL: 7, },
  // ]

  let chartData: any[] = [];
  if (issueStatusByZone) {
    chartData = issueStatusByZone
      .map((item) => {
        const totalCount =
          item.DR.count + item.OP.count + item.RFI.count + item.CL.count;
        if (totalCount !== 0) {
          return {
            group: item.zoneName,
            DR: item.DR.count,
            OP: item.OP.count,
            RFI: item.RFI.count,
            CL: item.CL.count,
          };
        } else {
          return null; // When the count is 0
        }
      })
      .filter((item): item is any => item !== null);
  }
  return (
    <div ref={svgLargeRef} className='w-12/12 h-100'>
      <BarplotGrouped
        data={chartData}
        svgWrapperRef={svgLargeRef}
        margin={{ top: 0, right: 10, bottom: 40, left: 30 }}
        isYaxisRight={false}
        loading={loading}
      />
    </div>
  );
};
