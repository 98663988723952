import React, { useState, useEffect, useMemo } from 'react';
import { Select, message } from 'antd';
import { t } from 'i18next';
import { User } from 'model';
import { issueService } from 'services';
import { STATUS_CODES } from 'http';
import { ISSUE_STATUSES } from 'utils/contants';
const { Option } = Select;

type SearchInputProps = {
  users: User[];
  dynamicClassName?: string;
  refreshUser: () => void;
  refreshIssues: () => void;
  workspaceId: string;
  projectId: string;
  issueId: string;
  companyId?: string;
  assigneeName?: string;
  value?: string;
  reporterId: string;
  profile: User | null;
  assigneeId: string;
  status: string;
};
const SearchInputUser: React.FC<SearchInputProps> = (props) => {
  const {
    users,
    dynamicClassName,
    workspaceId,
    projectId,
    issueId,
    companyId,
    refreshUser,
    assigneeName,
    refreshIssues,
    reporterId,
    profile,
    assigneeId,
    status,
  } = props;
  const userCompanyId = profile?.WorkspaceUser?.Company?.id;
  const isAssignerCompany = userCompanyId === users?.find((user) => user.id === reporterId)?.WorkspaceUser?.Company?.id;
  const isDisabledAssignee =
    status === ISSUE_STATUSES.CLOSED ||
    status === ISSUE_STATUSES.READY_FOR_INSPECTION ||
    (isAssignerCompany ? status === ISSUE_STATUSES.OPEN || status === ISSUE_STATUSES.DRAFT : false);
  const [value, setValue] = useState<string | undefined>(assigneeName);

  const [inputValue, setInputValue] = useState<any>();
  const options = useMemo(() => {
    return users?.filter(
      (user) =>
        (!companyId || user?.WorkspaceUser?.Company?.id === companyId) &&
        (reporterId === profile?.id ? true : user.id !== reporterId)
    );
  }, [users, companyId]);

  useEffect(() => {
    setValue(assigneeName);
  }, [assigneeName]);

  const handleChange = async (assigneeId: string) => {
    try {
      const companyId = options.find((user) => user.id === assigneeId)?.WorkspaceUser?.Company?.id;
      let submitData = {};
      if (!assigneeId) {
        submitData = {
          isSaveAsDraft: true,
          assigneeId: null,
        };
        setValue('');
      } else {
        submitData = {
          isSaveAsDraft: true,
          assigneeId: assigneeId,
          companyId,
        };
      }
      await issueService.updateIssue(workspaceId, projectId, issueId, submitData);
      refreshUser();
      refreshIssues();
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };

  const getNameInitials = (name: string): String => {
    const words = name.trim().split(' ');

    if (words?.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    } else {
      return words[0].substring(0, 1).toUpperCase() + words[1].substring(0, 1).toUpperCase();
    }
  };

  return (
    <Select
      allowClear
      disabled={isDisabledAssignee}
      showSearch
      value={value || assigneeName}
      placeholder={t('Assign')}
      // style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      optionFilterProp='children'
      filterOption={false}
      onSearch={(e) => setInputValue(e)}
      onChange={handleChange}
      notFoundContent={null}
      optionLabelProp='label'
      // defaultOpen={true}
      className={dynamicClassName}
      popupMatchSelectWidth={false}
    >
      {(options || [])
        ?.filter((o) => !inputValue || o?.fullName?.toString().toLowerCase().includes(inputValue))
        .map((d) => (
          <Option key={d.id} value={d.id} label={d.fullName}>
            <div key={d.id} className='search-input-user'>
              {/* <ImgItem src={d.avatar} alt='' style={{ width: 24, height: 24 }} /> */}
              <span className='circle-fill'>{getNameInitials(d.fullName ? d.fullName : '')}</span>
              <div className='ml-1'>
                <div className='ttl'>{d.fullName}</div>
                <div className='desc'>{d?.WorkspaceUser?.Company?.name}</div>
              </div>
            </div>
          </Option>
        ))}
    </Select>
  );
};

export default SearchInputUser;
