import { CommentOutlined, TagOutlined } from '@ant-design/icons';
import { Drawer, Form, Layout, message, Spin, Tabs, TabsProps } from 'antd';
import { MARsDetail, MARsFooter, MARsHeader, ValidationHistoryStep } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import {
  useCountries,
  useCountriesParams,
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueStatuses,
  useIssueStatusesParams,
  useIssueTypes,
  useIssueTypesParams,
  useModuleFunctionParams,
  useModuleFunctions,
  useMyWorkspaceWorkPackagesParams,
  useProjectCompanies,
  useProjectCompaniesParams,
  useProjectPhases,
  useProjectPhasesParams,
  useProjectUsers,
  useProjectUsersParams,
  useProjectWorkPackages,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { baseIssueService, baseValidationService } from 'services';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from 'store/auth-context';
import {
  ISSUE_DISCIPLINES,
  ISSUE_PRIORITIES,
  MAR_STATUSES,
  MODULE_FUNCTIONS,
  TASK_STATUS_HEADER_COLOR,
  VALIDATION_ACTION,
  VALIDATION_STATUS,
  VALIDATION_STEP_TYPE,
} from 'utils/contants';
import {
  Attachment,
  AttachmentInfo,
  BaseIssue,
  IssueStatus,
  MARDescription,
  MaterialApprovalRequest,
  MaterialCategory,
  ValidationHistory,
} from 'model';
import MARsHistory from './CreateOrUpdateMAR/MARsHistory';
import ValidationPopup from 'components/common/ValidationPopup';
import { convertZoneToTreeData } from 'utils';
import { DataNode } from 'antd/es/tree';
import { findPath } from 'components/common/TreeSelectTagRender';
import useUserEntity from 'hooks/common/useUserProfile';

type MarValidationData = {
  validatorId: string | null;
  validatorCompanyId: string | null;
  prevStepId: null;
  prevStepType: string;
  prvValidatorCompanyId: string;
  prvValidatorId: string;
  validationAction: string;
  validationStatus: string;
  comment: string;
  revision: number;
};
type CreateOrUpdateMARProps = {
  isOpen: boolean;
  isEdit: boolean;
  isCreate: boolean;
  module: string;
  workspaceId: string;
  projectId: string;
  baseIssueId: string | null;
  materialCategories: MaterialCategory[];
  onFinish: () => void;
  setOpen: (value: boolean) => void;
  isReviewInProgress: boolean;
  refreshBaseIssues: (disciplineId: string) => void;
};

export type WorkspaceViewType = 'detail' | 'comments';

export default function CreateOrUpdateMAR(props: CreateOrUpdateMARProps) {
  const { t } = useTranslation();
  const {
    isOpen,
    module,
    workspaceId,
    projectId,
    setOpen,
    isCreate,
    baseIssueId,
    materialCategories,
    refreshBaseIssues,
  } = props;
  const { profile } = useAuthContext();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMarBaseIssue, setSelectedMarBaseIssue] = useState<BaseIssue | null>(null);
  const [workPackagesQuery] = useMyWorkspaceWorkPackagesParams({
    projectId,
    workspaceId,
  });

  const [workPackages] = useProjectWorkPackages(workPackagesQuery);
  const [issueTypesQuery] = useIssueTypesParams({ module });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  // const [wbsQuery] = useWorkBreakdownStructureParams({
  //   projectId,
  //   workspaceId,
  //   orderBy: 'order,asc',
  //   limit: 'unlimited',
  // })
  // const [wbs] = useWorkBreakdownStructures(wbsQuery);
  // const [mainPhase] = useMemo(() => wbs?.filter((phase) => phase.isMain === true) || [], [wbs])
  // const disciplines = useMemo(() => wbs?.filter((phase) => phase.parentId === mainPhase?.id) || [], [wbs])
  // const activities = useMemo(() => {
  //   const disciplineIds = disciplines?.map((discipline) => discipline.id);
  //   return wbs?.filter((phase) =>
  //     phase.parentId && disciplineIds?.includes(phase.parentId)
  //   ) || [];
  // }, [wbs, disciplines]);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const { user } = useUserEntity({ projectId, workspaceId });
  const [companies] = useProjectCompanies(companiesParams);
  const [usersParams] = useProjectUsersParams({ projectId, workspaceId });
  const [users] = useProjectUsers(usersParams);
  const currentUser = (users || []).find((u) => u.id === profile?.id);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const [zoneUpdated, setZoneUpdated] = useState<string>('');
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);
  const [phasesParams] = useProjectPhasesParams({ workspaceId, projectId });
  const [phases] = useProjectPhases(phasesParams);
  const [isAvailableSubmit, setIsAvailableSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState<WorkspaceViewType>('detail');
  const [requestName, setRequestName] = useState('');
  const formValuesWatch = Form.useWatch([], form);

  const [countriesParams] = useCountriesParams();
  const [countries] = useCountries(countriesParams);
  const [, setIsAvailableSaveDraft] = useState(true);
  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: 'MAR',
  });
  const [moduleFunctionsQuery] = useModuleFunctionParams({
    orderBy: 'name',
  });
  const [moduleFunctions] = useModuleFunctions(moduleFunctionsQuery);
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const [isMARsModalOpen, setIsMARsModalOpen] = useState(false);
  const [documentsInfo, setDocumentsInfo] = useState<{ proposed: AttachmentInfo[]; specified: AttachmentInfo[] }>({
    proposed: [],
    specified: [],
  });
  const [photosInfo, setPhotosInfo] = useState<{ proposed: AttachmentInfo[]; specified: AttachmentInfo[] }>({
    proposed: [],
    specified: [],
  });

  const [userCanValidation] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDisableStartValidation, setIsDisableStartValidation] = useState<boolean>(true);
  const [dataFormMARsDetails, setDataFormMARsDetails] = useState<
    | (BaseIssue &
        MaterialApprovalRequest & { productionPeriodUnit: string; comments?: string; attachments?: Attachment[] })
    | null
  >(null);
  const currentBaseIssuesCompany = (selectedMarBaseIssue?.BaseValidations || [])[0]?.ValidatorCompany;
  const currentValidator = users.find((u) => u.id === (selectedMarBaseIssue?.BaseValidations || [])[0]?.validatorId);
  const originator = users.find((u) => u.id === selectedMarBaseIssue?.originatorId);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
      setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);

  const getBaseIssue = async (baseIssueId: string) => {
    try {
      setIsLoading(true);
      const baseIssueData = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'MAR',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });

      setSelectedMarBaseIssue(baseIssueData);
      if (baseIssueData.startDate) {
        baseIssueData.startDate = dayjs(baseIssueData.startDate);
      }
      if (baseIssueData.endDate) {
        baseIssueData.endDate = dayjs(baseIssueData.endDate);
      }
      if (baseIssueData.MaterialApprovalRequest?.deliveryDate) {
        baseIssueData.MaterialApprovalRequest.deliveryDate = dayjs(baseIssueData.MaterialApprovalRequest?.deliveryDate);
      }
      if (baseIssueData.MaterialApprovalRequest?.requiredOnSiteDate) {
        baseIssueData.MaterialApprovalRequest.requiredOnSiteDate = dayjs(
          baseIssueData.MaterialApprovalRequest?.requiredOnSiteDate
        );
      }
      form.setFieldValue('comments', baseIssueData?.description);
      form.setFieldsValue(baseIssueData);
      form.setFieldsValue(baseIssueData.MaterialApprovalRequest);
      setZoneUpdated(findPath(zonesTreeData, baseIssueData.zoneId));

      if (baseIssueData.MaterialApprovalRequest?.productionPeriod?.split(' ')[0] == 'null') {
        form.setFieldValue('productionPeriod', null);
      } else {
        form.setFieldValue('productionPeriod', baseIssueData.MaterialApprovalRequest?.productionPeriod?.split(' ')[0]);
      }
      form.setFieldValue(
        'productionPeriodUnit',
        baseIssueData.MaterialApprovalRequest?.productionPeriod?.split(' ')[1]
      );

      setRequestName(baseIssueData.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(t((error as Error).message));
    }
  };

  const onChangeForm = (value: BaseIssue) => {
    if (Object.keys(value)?.[0] === 'name') {
      setRequestName(value.name);
    }
  };

  const onClose = (disciplineId?: string) => {
    setOpen(false);
    setSelectedMarBaseIssue(null);
    setDocumentsInfo({ proposed: [], specified: [] });
    setPhotosInfo({ proposed: [], specified: [] });
    refreshBaseIssues(disciplineId ? disciplineId : '');
    setDataFormMARsDetails(null);
    setIsReadOnly(false);
    setActiveTab('detail');
    setRequestName('');
    form.resetFields();
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key as WorkspaceViewType);
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue('plannedStartDate', dayjs());
      if (zones) {
        form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
        setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
      }
      // Discipline setup
      const generalDiscipline = issueDisciplines?.find((type) => type.code === ISSUE_DISCIPLINES.GENERAL);
      form.setFieldValue('issueDisciplineId', generalDiscipline?.id);
      // Priority setup
      form.setFieldValue('issuePriorityId', issuePriorities?.find((item) => item.code === ISSUE_PRIORITIES.MEDIUM)?.id);
    } else {
      setDocumentsInfo({ proposed: [], specified: [] });
      setPhotosInfo({ proposed: [], specified: [] });
      setAttachments([]);
    }
  }, [isOpen, issueDisciplines, issuePriorities]);

  const onSubmitWithStartValidation = async (marValidationData: MarValidationData, hasApproveWithComment?: boolean) => {
    try {
      if (marValidationData) {
        try {
          const values = dataFormMARsDetails;
          const validationAttachments = form.getFieldValue('validationAttachments');
          const watcherIds = form.getFieldValue('watcherIds');
          const marMaterialProposed = {
            countryId: form.getFieldValue(['countryId', 'proposed']),
            manufacturer: form.getFieldValue(['manufacturer', 'proposed']),
            description: form.getFieldValue(['description', 'proposed']),
            tradeName: form.getFieldValue(['tradeName', 'proposed']),
            model: form.getFieldValue(['model', 'proposed']),
            type: form.getFieldValue(['type', 'proposed']),
          } as MARDescription;
          const marMaterialSpecified = {
            countryId: form.getFieldValue(['countryId', 'specified']),
            manufacturer: form.getFieldValue(['manufacturer', 'specified']),
            description: form.getFieldValue(['description', 'specified']),
            tradeName: form.getFieldValue(['tradeName', 'specified']),
            model: form.getFieldValue(['model', 'specified']),
            type: form.getFieldValue(['type', 'specified']),
          };
          let submitData = {
            name: values?.name || undefined,
            moduleFunctionId:
              moduleFunctions?.find((functionItem) => functionItem.code === MODULE_FUNCTIONS.MATERIAL_APPROVAL_REQUEST)
                ?.id || null,
            startDate: values?.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : null,
            endDate: values?.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
            companyOriginatorId: values?.companyOriginatorId || null,
            originatorId: values?.originatorId || null,
            zoneId: values?.zoneId || null,
            isSaveAsDraft: false,
            watcherIds: watcherIds || [],
            description: values?.comments,
            mar: {
              workPackageId: values?.workPackageId || null,
              disciplineId: values?.disciplineId || null,
              activityId: values?.activityId || null,
              subActivityId: values?.subActivityId || null,
              controlPointId: values?.controlPointId || null,
              requiredOnSiteDate: (values?.requiredOnSiteDate as Dayjs)?.toISOString().slice(0, 10) || undefined,
              deliveryDate: (values?.deliveryDate as Dayjs)?.toISOString().slice(0, 10) || undefined,
              productionPeriod: `${values?.productionPeriod || null} ${values?.productionPeriodUnit || 'Days'}`,
              revision: 0,
              marDescriptionProposed: {
                tradeName: marMaterialProposed?.tradeName || undefined,
                description: marMaterialProposed?.description || undefined,
                manufacturer: marMaterialProposed?.manufacturer || undefined,
                type: marMaterialProposed?.type || undefined,
                model: marMaterialProposed?.model || undefined,
                isProposed: true,
                countryId: marMaterialProposed?.countryId || undefined,
                attachments: {
                  attachmentsIds: values?.attachments || [],
                },
                attachmentsInfo: documentsInfo.proposed.concat(photosInfo.proposed),
              },
              marDescriptionSpecified: {
                tradeName: marMaterialSpecified?.tradeName || undefined,
                description: marMaterialSpecified?.description || undefined,
                manufacturer: marMaterialSpecified?.manufacturer || undefined,
                type: marMaterialSpecified?.type || undefined,
                model: marMaterialSpecified?.model || undefined,
                isProposed: false,
                countryId: marMaterialSpecified?.countryId || undefined,
                attachments: {
                  attachmentsIds: values?.attachments || [],
                },
                attachmentsInfo: documentsInfo.specified.concat(photosInfo.specified),
              },
              marValidation: {
                ...marValidationData,
                pinComment: hasApproveWithComment,
                prvValidatorCompanyId: marValidationData?.prvValidatorCompanyId || values?.companyOriginatorId,
                prvValidatorId: marValidationData?.prvValidatorId || values?.originatorId,
                prevStepType:
                  values?.companyOriginatorId === marValidationData.validatorCompanyId
                    ? 'internal-validation'
                    : 'external-validation',
                validationAttachmentsInfo: validationAttachments,
              },
            },
          };
          if (!selectedMarBaseIssue) {
            const statusCode = MAR_STATUSES.REVIEW_IN_PROGRESS;

            submitData = {
              ...submitData,
              isSaveAsDraft: statusCode === MAR_STATUSES.DRAFT,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id || '',
              // attachmentsInfo: attachmentsInfo.concat(photoInfo),
              // attachmentsInfo: documentsInfo.proposed.concat(documentsInfo.specified).concat(photosInfo.proposed).concat(photosInfo.specified),
            } as typeof submitData;
            await baseIssueService.createBaseIssue(workspaceId, projectId, submitData);
          } else {
            let statusCode = MAR_STATUSES.REVIEW_IN_PROGRESS;

            switch (marValidationData.validationAction) {
              case VALIDATION_ACTION.VALIDATE:
                if (
                  statusCode === MAR_STATUSES.DRAFT &&
                  values?.companyOriginatorId !== marValidationData.validatorCompanyId
                ) {
                  statusCode = MAR_STATUSES.REVIEW_IN_PROGRESS;
                }
                break;
              case VALIDATION_ACTION.TRANSFER:
                break;
              case VALIDATION_ACTION.REJECT:
                statusCode = MAR_STATUSES.REJECTED;
                break;
              case VALIDATION_ACTION.FINAL_VALIDATION:
                if (hasApproveWithComment) {
                  statusCode = MAR_STATUSES.APPROVED_WITH_COMMENT;
                } else {
                  statusCode = MAR_STATUSES.APPROVED;
                }
                break;

              default:
                break;
            }

            submitData = {
              ...submitData,
              isSaveAsDraft: statusCode === MAR_STATUSES.DRAFT,
              statusId: issueStatuses?.find((status) => status.code === statusCode)?.id,
              attachmentsInfo: [],
              mar: { ...submitData.mar, id: selectedMarBaseIssue?.MaterialApprovalRequest?.id },
            } as typeof submitData;
            await baseIssueService.updateBaseIssue(workspaceId, projectId, selectedMarBaseIssue.id, submitData);
          }
          onClose(values?.disciplineId || '');
        } catch (error) {
          console.log(error);
          message.error((error as Error).message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitAsDraft = async () => {
    try {
      const valid = await form.validateFields(['name', 'disciplineId']);
      if (valid) {
        try {
          const values = await form.getFieldsValue();
          const marMaterialProposed = {
            countryId: form.getFieldValue(['countryId', 'proposed']),
            manufacturer: form.getFieldValue(['manufacturer', 'proposed']),
            description: form.getFieldValue(['description', 'proposed']),
            tradeName: form.getFieldValue(['tradeName', 'proposed']),
            model: form.getFieldValue(['model', 'proposed']),
            type: form.getFieldValue(['type', 'proposed']),
          } as MARDescription;
          const marMaterialSpecified = {
            countryId: form.getFieldValue(['countryId', 'specified']),
            manufacturer: form.getFieldValue(['manufacturer', 'specified']),
            description: form.getFieldValue(['description', 'specified']),
            tradeName: form.getFieldValue(['tradeName', 'specified']),
            model: form.getFieldValue(['model', 'specified']),
            type: form.getFieldValue(['type', 'specified']),
          };
          try {
            let statusObj = {} as IssueStatus | undefined;
            if (selectedMarBaseIssue) {
              statusObj = issueStatuses?.find((status) => status.code === selectedMarBaseIssue.IssueStatus?.code);
            } else {
              statusObj = issueStatuses?.find((status) => status.code === 'MAR_DR');
            }
            const submitData = {
              isSaveAsDraft: true,
              name: values.name || undefined,
              moduleFunctionId: moduleFunctions?.find((status) => status.code === 'MAR')?.id,
              statusId: statusObj?.id,
              startDate: dayjs(values.startDate).format('YYYY-MM-DD') || undefined,
              endDate: dayjs(values.endDate).format('YYYY-MM-DD') || undefined,
              companyOriginatorId: values.companyOriginatorId || undefined,
              originatorId: values.originatorId || undefined,
              description: values.comments || undefined,
              zoneId: values.zoneId || null,
              watcherIds: undefined,
              // attachmentsInfo: attachmentsInfo.concat(photoInfo),
              mar: {
                workPackageId: values.workPackageId || undefined,
                disciplineId: values?.disciplineId || undefined,
                activityId: values?.activityId || undefined,
                subActivityId: values?.subActivityId || undefined,
                controlPointId: values?.controlPointId || undefined,
                requiredOnSiteDate: values.requiredOnSiteDate?.toISOString().slice(0, 10) || undefined,
                deliveryDate: values.deliveryDate?.toISOString().slice(0, 10) || undefined,
                productionPeriod: `${values?.productionPeriod || null} ${values?.productionPeriodUnit || 'Days'}`,
                marDescriptionProposed: {
                  tradeName: marMaterialProposed?.tradeName || undefined,
                  description: marMaterialProposed?.description || undefined,
                  manufacturer: marMaterialProposed?.manufacturer || undefined,
                  type: marMaterialProposed?.type || undefined,
                  model: marMaterialProposed?.model || undefined,
                  isProposed: true,
                  countryId: marMaterialProposed?.countryId || undefined,
                  attachments: {
                    attachmentsIds: values.attachments || [],
                  },
                  attachmentsInfo: documentsInfo.proposed.concat(photosInfo.proposed),
                },
                marDescriptionSpecified: {
                  tradeName: marMaterialSpecified?.tradeName || undefined,
                  description: marMaterialSpecified?.description || undefined,
                  manufacturer: marMaterialSpecified?.manufacturer || undefined,
                  type: marMaterialSpecified?.type || undefined,
                  model: marMaterialSpecified?.model || undefined,
                  isProposed: false,
                  countryId: marMaterialSpecified?.countryId || undefined,
                  attachments: {
                    attachmentsIds: values.attachments || [],
                  },
                  attachmentsInfo: documentsInfo.specified.concat(photosInfo.specified),
                },
              },
            };
            if (baseIssueId) {
              await baseIssueService.updateBaseIssue(workspaceId, projectId, baseIssueId, submitData);
            } else {
              await baseIssueService.createBaseIssue(workspaceId, projectId, submitData);
            }
            setSelectedMarBaseIssue(null);
            onClose(values.disciplineId);
          } catch (error) {
            setSelectedMarBaseIssue(null);
            message.error(t('Oop! Something wrong'));
            console.error(error);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (formValuesWatch?.name?.length > 100) {
        setIsDisableStartValidation(true);
        setIsAvailableSaveDraft(false);
        setIsAvailableSubmit(false);
      } else {
        setIsAvailableSaveDraft(true);
      }

      if (
        formValuesWatch?.workPackageId &&
        formValuesWatch?.disciplineId &&
        formValuesWatch?.name !== '' &&
        formValuesWatch?.name?.length <= 100 &&
        formValuesWatch?.tradeName &&
        formValuesWatch?.manufacturer
      ) {
        setIsAvailableSubmit(true);
        setIsDisableStartValidation(false);
      } else {
        setIsAvailableSubmit(false);
        setIsDisableStartValidation(true);
      }
    }
  }, [formValuesWatch]);

  const marValidate = async (
    selection: string,
    isFinal: boolean,
    validatorId: string | null,
    validatorCompanyId: string | null,
    hasApproveWithComment?: boolean
  ) => {
    try {
      // Validate for external company (read only)
      let validationAction = '';
      let validationStatus = '';
      switch (selection) {
        case VALIDATION_ACTION.SUBMIT:
          validationAction = VALIDATION_ACTION.SUBMIT;
          validationStatus = VALIDATION_STATUS.SUBMITTED;
          break;
        case VALIDATION_ACTION.VALIDATE:
          if (isFinal) {
            validationAction = VALIDATION_ACTION.FINAL_VALIDATION;
            validationStatus = VALIDATION_STATUS.APPROVED;
            break;
          }
          if (isFinal) {
            validationAction = VALIDATION_ACTION.FINAL_VALIDATION;
            validationStatus = VALIDATION_STATUS.APPROVED;
            break;
          }
          validationAction = VALIDATION_ACTION.VALIDATE;
          validationStatus = VALIDATION_STATUS.VALIDATED;
          break;
        case VALIDATION_ACTION.REJECT:
          validationAction = VALIDATION_ACTION.REJECT;
          validationStatus = VALIDATION_STATUS.REJECTED;
          break;
        case VALIDATION_ACTION.TRANSFER:
          validationAction = VALIDATION_ACTION.TRANSFER;
          validationStatus = VALIDATION_STATUS.TRANSFERRED;
          break;
        default:
          break;
      }

      const comment: string = form.getFieldValue('validation-comment');
      const validationAttachments = form.getFieldValue('validationAttachments');
      const prvValidatorCompanyId =
        (selectedMarBaseIssue?.BaseValidations || [])[0]?.validatorCompanyId ??
        selectedMarBaseIssue?.companyOriginatorId;
      const prvValidatorId = (selectedMarBaseIssue?.BaseValidations || [])[0]?.validatorId ?? profile?.id;
      const prevStepType: string =
        (selectedMarBaseIssue?.BaseValidations || [])[0]?.validatorCompanyId === validatorCompanyId
          ? VALIDATION_STEP_TYPE.INTERNAL
          : VALIDATION_STEP_TYPE.EXTERNAL;

      const marValidationData = {
        validatorId: validatorId,
        validatorCompanyId: validatorCompanyId,
        prevStepId: null,
        prevStepType: prevStepType,
        prvValidatorCompanyId: prvValidatorCompanyId,
        prvValidatorId: prvValidatorId,
        validationAction: validationAction,
        validationStatus: validationStatus,
        comment: comment,
        revision: 0,
      };

      if (baseIssueId) {
        if (isReadOnly) {
          const dataSubmit = {
            baseIssueId: baseIssueId,
            validatorId: validatorId,
            validatorCompanyId: validatorCompanyId,
            prevStepId: (selectedMarBaseIssue?.BaseValidations || [])[0]?.id || null,
            prevStepType: prevStepType,
            prvValidatorCompanyId: prvValidatorCompanyId || null,
            prvValidatorId: prvValidatorId || null,
            validationAction: validationAction,
            validationStatus: validationStatus,
            comment: comment,
            revision: 0,
            pinComment: hasApproveWithComment,
            validationAttachmentsInfo: validationAttachments,
          };
          const params = {
            type: 'MAR',
          };
          await baseValidationService.createBaseValidation(workspaceId, projectId, baseIssueId, dataSubmit, params);
        } else {
          onSubmitWithStartValidation(marValidationData, hasApproveWithComment);
        }
      } else {
        onSubmitWithStartValidation(marValidationData, hasApproveWithComment);
      }
      onClose(selectedMarBaseIssue?.MaterialApprovalRequest?.disciplineId || '');
      setIsMARsModalOpen(false);
    } catch (error) {
      console.log(error);
      message.error(t((error as Error).message));
    }
  };

  const companyHistories: ValidationHistory[] = useMemo(() => {
    if (!selectedMarBaseIssue?.BaseValidations) return [];
    const result: ValidationHistory[] = [];

    let currentActions: ValidationHistory = {
      id: '',
      company: '',
      code: '',
      status: '',
      comments: [],
    };

    for (let i = 0; i < selectedMarBaseIssue?.BaseValidations.length; i++) {
      const item = selectedMarBaseIssue?.BaseValidations[i];
      const prvUser = users.find((u) => u.id === item.prvValidatorId);
      const User = users.find((u) => u.id === item.validatorId);

      if (
        i === 0 &&
        item?.validationStatus !== VALIDATION_STATUS.REJECTED &&
        item?.validationStatus !== VALIDATION_STATUS.APPROVED
      ) {
        currentActions = {
          id: item.validatorCompanyId,
          company: item.ValidatorCompany?.name || '',
          code: item.ValidatorCompany?.companyCode || '',
          status: VALIDATION_STATUS.IN_REVIEW,
          comments: [
            {
              id: item.id,
              comment: '',
              user: User?.fullName || '',
              userId: item?.validatorId || '',
              date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              company: item.PrvValidatorCompany?.name || '',
              status: {
                name: VALIDATION_STATUS.IN_REVIEW,
                code: VALIDATION_STATUS.IN_REVIEW,
              },
              pinComment: item.pinComment,
            },
          ],
        };
      }

      if (!currentActions || item.prvValidatorCompanyId !== currentActions.id) {
        if (currentActions.comments.length > 0) {
          result.push(currentActions);
        }
        currentActions = {
          id: item.prvValidatorCompanyId,
          company: item.PrvValidatorCompany?.name || '',
          code: item.PrvValidatorCompany?.companyCode || '',
          status: item.validationStatus,
          comments: [
            {
              id: item.id,
              comment: item.comment,
              user: prvUser?.fullName || '',
              userId: item.prvValidatorId || '',
              date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              company: item.PrvValidatorCompany?.name || '',
              attachment: item.ValidationAttachment,
              status: {
                name: item.validationStatus,
                code: item.validationStatus,
              },
              pinComment: item.pinComment,
            },
          ],
        };
      } else {
        currentActions.comments.push({
          id: item.id,
          comment: item.comment,
          user: prvUser?.fullName || '',
          userId: item.prvValidatorId || '',
          date: dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
          company: item.PrvValidatorCompany?.name || '',
          attachment: item.ValidationAttachment,
          pinComment: item.pinComment,
          status: {
            name: item.validationStatus,
            code: item.validationStatus,
          },
        });
      }
    }

    if (currentActions.comments.length > 0) {
      result.push(currentActions);
    }

    const userCompanyId = users?.find((user) => user.id === profile?.id)?.WorkspaceUser?.Company?.id;
    const isRejected = selectedMarBaseIssue.IssueStatus?.code === MAR_STATUSES.REJECTED;
    const isApproved = selectedMarBaseIssue.IssueStatus?.code === MAR_STATUSES.APPROVED;
    const isApprovedWithComment = selectedMarBaseIssue.IssueStatus?.code === MAR_STATUSES.APPROVED_WITH_COMMENT;
    const draft = selectedMarBaseIssue.IssueStatus?.code === MAR_STATUSES.DRAFT;

    if (isRejected || isApproved || isApprovedWithComment) {
      setIsReadOnly(true);
    } else {
      if (
        (draft && userCompanyId === selectedMarBaseIssue.Company?.id) ||
        (userCompanyId === selectedMarBaseIssue.companyOriginatorId &&
          currentBaseIssuesCompany?.id === selectedMarBaseIssue.companyOriginatorId)
      ) {
        setIsReadOnly(false);
      } else {
        setIsReadOnly(true);
      }
    }

    return result;
  }, [selectedMarBaseIssue]);

  useEffect(() => {
    if (isOpen) {
      if (baseIssueId) {
        getBaseIssue(baseIssueId);
      } else {
        const currentUser = users.find((user) => user.id === profile?.id);
        form.setFieldValue('companyOriginatorId', currentUser?.WorkspaceUser?.Company?.id);
        form.setFieldValue('originatorId', currentUser?.id);
        form.setFieldValue('name', '');
        if (zones) {
          form.setFieldValue('zoneId', zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
          setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
        }
        setRequestName('');
        setIsReadOnly(false);
        setPhotosInfo({ proposed: [], specified: [] });
      }
    }
  }, [baseIssueId, zones, isOpen, users]);

  const items: TabsProps['items'] = [
    {
      key: 'detail',
      label: t('Material Approval Request Details'),
      icon: <TagOutlined />,
      children: null,
    },
    {
      key: 'comments',
      label: t('Validation History'),
      disabled: companyHistories.length <= 0,
      icon: <CommentOutlined />,
      children: null,
    },
  ];

  useEffect(() => {
    if (profile) {
      if (isCreate) {
        return setIsReadOnly(false);
      }
    }
  }, [userCanValidation, profile]);

  useEffect(() => {
    if (!isOpen) return;

    const name = form.getFieldValue('name');
    const workPackageId = form.getFieldValue('workPackageId');
    const disciplineId = form.getFieldValue('disciplineId');
    const manufacturer = form.getFieldValue(['manufacturer', 'proposed']);

    const isNameValid = name?.length >= 1;
    const isWorkPackageIdValid = !!workPackageId;
    const isDiscriplineValid = !!disciplineId;
    const isManufacturerValid = !!manufacturer;

    setIsDisableStartValidation(!(isNameValid && isWorkPackageIdValid && isDiscriplineValid && isManufacturerValid));
  }, [formValuesWatch, isOpen]);
  useEffect(() => {
    if (baseIssueId && activeTab === 'comments') {
      getBaseIssue(baseIssueId);
    }
  }, [activeTab, baseIssueId]);
  return (
    <Layout.Content className='material-approval-request-create-modal'>
      <ValidationPopup
        profile={profile}
        startFor={'MAR'}
        key={`${isMARsModalOpen}`}
        currentValidator={(currentValidator ? currentValidator : currentUser ? currentUser : originator) || null}
        currentCompany={
          (currentBaseIssuesCompany
            ? currentBaseIssuesCompany
            : selectedMarBaseIssue?.Company
              ? selectedMarBaseIssue?.Company
              : currentUser?.WorkspaceUser?.Company) || null
        }
        isModalOpen={isMARsModalOpen}
        companies={companies}
        users={users}
        form={form}
        isExternal={companyHistories.length > 1 ? true : false}
        setIsModalOpen={setIsMARsModalOpen}
        originator={originator}
        startValidation={(
          selection: string,
          isFinal: boolean,
          validatorId: string | null,
          validatorCompanyId: string | null,
          hasApproveWithComment?: boolean
        ) => marValidate(selection, isFinal, validatorId, validatorCompanyId, hasApproveWithComment)}
      />
      <Form form={form} labelCol={{ span: 24 }} layout='vertical' onValuesChange={onChangeForm}>
        <Drawer
          className={`mar-drawer ${module && module === 'Safety' ? 'safety-light' : 'quality-light'}`}
          styles={{
            header: {
              backgroundColor: TASK_STATUS_HEADER_COLOR['QUALITY_HEADER'],
              boxShadow: 'inset 0em -0.2em 0.2em #EAF0F9',
            },
          }}
          placement={'right'}
          width={1025}
          onClose={() => onClose()}
          closeIcon={false}
          open={isOpen}
          maskClosable={false}
          destroyOnClose={false}
          title={
            <MARsHeader
              requestName={requestName}
              profile={profile}
              isUnSaveStatus={selectedMarBaseIssue ? false : true}
              onClose={() => {
                onClose(selectedMarBaseIssue?.MaterialApprovalRequest?.disciplineId || '');
              }}
              companyHistories={companyHistories}
              selectedMarBaseIssue={selectedMarBaseIssue}
            />
          }
          footer={
            <MARsFooter
              isReadOnly={isReadOnly}
              user={user}
              profile={profile}
              activeTab={activeTab}
              isAvailableSubmit={isAvailableSubmit}
              isSave={companyHistories.length > 0}
              onSubmitAsDraft={onSubmitAsDraft}
              selectedMarBaseIssue={selectedMarBaseIssue}
              isDisableStartValidation={isDisableStartValidation}
              setActiveTab={setActiveTab}
              onClose={onClose}
              setOpenValidationModal={async (value) => {
                try {
                  const valid = await form.validateFields(['name']);
                  if (valid) {
                    const values = await form.getFieldsValue();
                    setDataFormMARsDetails(values);
                    setIsMARsModalOpen(value);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          }
        >
          <Spin spinning={isLoading}>
            <Tabs
              className='mar-tab-create'
              defaultActiveKey='detail'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
            {companyHistories.length > 0 && <ValidationHistoryStep isMAR={true} items={companyHistories} />}
            {activeTab === 'detail' && (
              <>
                <MARsDetail
                  isReadOnly={isReadOnly}
                  form={form}
                  companies={companies}
                  users={users}
                  issuePriorities={issuePriorities}
                  issueDisciplines={issueDisciplines}
                  // disciplines={disciplines}
                  // activities={activities}
                  issueTypes={issueTypes}
                  zones={zones}
                  zonesTreeData={zonesTreeData}
                  zoneUpdated={zoneUpdated}
                  setZoneUpdated={setZoneUpdated}
                  phases={phases}
                  isOpen={isOpen}
                  profile={profile}
                  countries={countries}
                  selectedMarBaseIssue={selectedMarBaseIssue}
                  workPackages={workPackages}
                  materialCategories={materialCategories}
                  onValuesChange={onChangeForm}
                  documentsInfo={documentsInfo}
                  setDocumentsInfo={setDocumentsInfo}
                  photosInfo={photosInfo}
                  setPhotosInfo={setPhotosInfo}
                  projectId={projectId}
                  workspaceId={workspaceId}
                  setAttachments={setAttachments}
                  attachments={attachments}
                />
              </>
            )}
            {activeTab === 'comments' && <MARsHistory companyHistories={companyHistories} />}
          </Spin>
        </Drawer>
      </Form>
    </Layout.Content>
  );
}
