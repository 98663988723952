import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Role } from 'model';
import { ROLE_TYPES } from 'utils/contants';
import { useFetchByParams, useQueryParams } from 'hooks';
import {
  fetchWorkspaceRoles,
  selectWorkspaceRoles,
  selectWorkspaceRolesLoading,
  fetchProjectRoles,
  selectProjectRoles,
  selectProjectRolesLoading,
} from 'store/role.slice';

export const useWorkspaceRolesParams = () => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      limit: 'unlimited',
      type: ROLE_TYPES.WORKSPACE,
    };

    return [queryParams];
  }, []);
};

export const useWorkspaceRoles = (query: any) => {
  return useFetchByParams<Role[]>({
    action: fetchWorkspaceRoles,
    dataSelector: selectWorkspaceRoles,
    loadingSelector: selectWorkspaceRolesLoading,
    params: query,
  });
};

export const useProjectRolesQueryParams = () => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      limit: 'unlimited',
      type: ROLE_TYPES.PROJECT,
    };

    return [queryParams];
  }, []);
};

export const useProjectRoles = (query: any) => {
  return useFetchByParams<Role[]>({
    action: fetchProjectRoles,
    dataSelector: selectProjectRoles,
    loadingSelector: selectProjectRolesLoading,
    params: query,
  });
};
