import { Design } from 'model';
import {
  DesignAttachmentForm,
  EditDesignForm,
  PublishDesignInput,
  RawDesignForm,
  UnpublishedDesignForm,
} from 'modules/DocumentManagement/models/Design';
import { Key } from 'react';
import request from 'requesters/core.request';
import { AxiosRequestConfig } from 'axios';

const shopDrawingService = {
  getProjectShopDrawings: async (workspaceId: string, projectId: string, query: object) => {
    return request.get<Design[]>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings`, {
      params: query,
    });
  },
  getProjectShopDrawingsDisciplines: async (workspaceId: string, projectId: string) => {
    return request.get<string[]>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/disciplines`);
  },
  getShopDrawingRevisions: async (workspaceId: string, projectId: string, shopDrawingId: string) => {
    return request.get<Design[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${shopDrawingId}/revisions`
    );
  },
  createShopDrawings: async (projectId: string, workspaceId: string, data: UnpublishedDesignForm[]) => {
    return request.post<{ id: string; uid: string }[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings`,
      data
    );
  },
  findDuplicateShopDrawings: async (workspaceId: string, projectId: string, data: RawDesignForm[]) => {
    return request.post<{ id: string; hasDuplicate: boolean }[]>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/duplicates`,
      {
        ...data,
      }
    );
  },

  sendShopDrawingsAttachment: async (workspaceId: string, projectId: string, data: DesignAttachmentForm) => {
    const formData = new FormData();
    formData.append('files', data.file);
    formData.append('designId', data.designId);
    formData.append('draft', data.draft.toString());
    return request.post<Design>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/attachments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  getShopDrawingById: async (workspaceId: string, projectId: string, designId: string) => {
    return request.get<Design>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${designId}`, {});
  },
  updateShopDrawing: async (
    workspaceId: string,
    projectId: string,
    designId: string,
    updatedDesign: EditDesignForm
  ) => {
    return request.put<Design>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${designId}`,
      updatedDesign
    );
  },
  updateShopDrawingDraftBulk: async (workspaceId: string, projectId: string, updatedDesigns: EditDesignForm[]) => {
    return request.put<Design>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/drafts`, updatedDesigns);
  },
  createShopDrawingRevision: async (
    workspaceId: string,
    projectId: string,
    designId: string,
    shopDrawing: EditDesignForm
  ) => {
    return request.post<{ id: string }>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${designId}/revisions`,
      shopDrawing
    );
  },

  updateShopDrawingRevision: async (
    workspaceId: string,
    projectId: string,
    oldDesignId: string,
    newDesignid: string
  ) => {
    return request.put<{ id: string }>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${oldDesignId}/revisions`,
      newDesignid
    );
  },
  publishShopDrawing: async (workspaceId: string, projectId: string, input: PublishDesignInput) => {
    return request.put<{ id: string }>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/publish`, input);
  },
  downloadShopDrawing: async (
    workspaceId: string,
    projectId: string,
    designId: string,
    config?: AxiosRequestConfig
  ) => {
    return request.get<string>(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${designId}/download`,
      config
    );
  },
  downloadShopDrawings: async (workspaceId: string, projectId: string, designId: string[]) => {
    return request.post<string>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/download`, designId);
  },
  deleteShopDrawingsAndRevisions: async (workspaceId: string, projectId: string, designIds: Key[]) => {
    return request.delete<Design>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/revisions`, {
      params: designIds,
    });
  },
  deleteShopDrawingsById: async (workspaceId: string, projectId: string, designIds: Key[]) => {
    return request.delete<Design>(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/list`, {
      params: designIds,
    });
  },
  startShopDrawingMultipartUpload: async (
    workspaceId: string,
    projectId: string,
    shopDrawingId: string,
    params: { fileName: string; fileType: string }
  ) => {
    return request.get(`/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${shopDrawingId}/upload-start`, {
      params,
    });
  },
  endShopDrawingMultipartUpload: async (
    workspaceId: string,
    projectId: string,
    shopDrawingId: string,
    params: {
      fileType: string;
      keyFile: string;
      parts: {
        ETag: {
          fileName: string;
          fileUrl: string;
          headers: string;
        };
        PartNumber: number;
      }[];
      uploadId: string;
      draft: boolean;
    }
  ) => {
    return request.post(
      `/workspaces/${workspaceId}/projects/${projectId}/shop-drawings/${shopDrawingId}/upload-end`,
      params
    );
  },
};
export default shopDrawingService;
