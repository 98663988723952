import { CSSProperties } from 'react';

type ModuleIconProps = {
  color?: string;
  style?: CSSProperties;
  width?: number;
  height?: number;
};

const ModuleIcon = ({ color = '#001d3d', width = 15, height = 14, style }: ModuleIconProps) => {
  return (
    <svg width={width} height={height} style={style} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.8097 0.254517H5.19026C2.32376 0.254517 0 2.56349 0 5.41175V34.8428C0 37.691 2.32376 40 5.19026 40H34.8097C37.6762 40 40 37.691 40 34.8428V5.41175C40 2.56349 37.6762 0.254517 34.8097 0.254517Z'
        fill={color}
      />
      <path
        d='M5.80566 0V32.825C5.80566 33.1742 5.94526 33.509 6.19373 33.7559C6.44221 34.0028 6.77921 34.1415 7.13061 34.1415H20.857C21.2084 34.1415 21.5454 34.0028 21.7939 33.7559C22.0424 33.509 22.1819 33.1742 22.1819 32.825V7.51114C22.1819 6.78706 21.6979 6.54043 21.1061 6.96259L10.5693 14.4886C9.97747 14.9108 9.49342 15.849 9.49342 16.5731V29.1309C9.49342 29.48 9.63301 29.8149 9.88149 30.0618C10.13 30.3087 10.467 30.4474 10.8184 30.4474H31.915C32.2664 30.4474 32.6034 30.3087 32.8518 30.0618C33.1003 29.8149 33.2399 29.48 33.2399 29.1309V15.1609C33.2399 14.8118 33.1003 14.4769 32.8518 14.23C32.6034 13.9831 32.2664 13.8444 31.915 13.8444H27.1946C26.8432 13.8444 26.5062 13.9831 26.2578 14.23C26.0093 14.4769 25.8697 14.8118 25.8697 15.1609V32.8987C25.8697 33.2479 26.0093 33.5828 26.2578 33.8296C26.5062 34.0765 26.8432 34.2152 27.1946 34.2152H39.9379'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M33.5504 6.18343C33.7137 6.34721 33.9565 6.46694 34.1657 6.55352C33.9596 6.63835 33.7246 6.75504 33.5635 6.91507C33.4373 7.04044 33.3588 7.21054 33.3085 7.33653C33.2741 7.42268 33.242 7.51748 33.212 7.61707C33.1792 7.50414 33.1437 7.39663 33.1053 7.29986C33.0545 7.17203 32.975 6.99974 32.8464 6.87313C32.7085 6.73732 32.5192 6.63484 32.3389 6.5569C32.5257 6.47717 32.7247 6.37157 32.8671 6.23098C32.9945 6.10524 33.0734 5.93533 33.1238 5.80869C33.1573 5.72449 33.1885 5.63224 33.2174 5.53551C33.2433 5.61765 33.2706 5.69604 33.2996 5.76836C33.3493 5.89254 33.4267 6.05943 33.5504 6.18343Z'
        fill='#7263A8'
        stroke='white'
      />
    </svg>
  );
};

export default ModuleIcon;
