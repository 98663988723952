import { useMemo } from 'react';
import { QueryParams } from 'types';
import { Project, ParameterSetting } from 'model';
import { useFetchByParams, useQueryParams } from 'hooks';
import { fetchProjects, selectMyWorkspaceProjects, selectMyWorkspaceProjectsLoading } from 'store/my-workspace.slice';
import {
  fetchFavoriteProjects,
  fetchOtherProjects,
  selectFavoriteProjects,
  selectFavoriteProjectsLoading,
  selectOtherWorkspacesProjects,
  selectOtherWorkspacesProjectsLoading,
} from 'store/other-workspaces.slice';
import { RootState } from 'store';
import {
  fetchControlType,
  fetchFrequencies,
  fetchInspectionTestPlain,
  fetchMyParametersSettingByProjectId,
  fetchProjectById,
  fetchQualityControlMatrices,
  selectParametersLoading,
  selectParametersSetting,
  selectProjectControlType,
  selectProjectControlTypeLoading,
  selectProjectFrequencies,
  selectProjectFrequenciesLoading,
  selectProjectInspectionTestPlan,
  selectProjectInspectionTestPlanLoading,
  selectProjectQualityControlMatrices,
  selectProjectQualityControlMatricesLoading,
} from 'store/my-projects.slice';
import { InspectionTestPlanModel } from 'model/InspectionTestPlain';
import { QualityControlMatrices } from 'model/QualityControlMatrix';
import { Frequencies } from 'model/Frequencies';
import { ControlType } from 'model/ControlType';

const selectProjectById = (state: RootState, projectId: string) => state.myProjects.projects[projectId]?.projectData;
const selectProjectByIdLoading = (state: RootState, projectId: string) =>
  state.myProjects.projects[projectId]?.projectDataLoading;

export const useMyWorkspaceProjectsParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      include: 'ProjectType',
      orderBy: 'createdAt,desc',
      limit: 'unlimited',
    };

    return [queryParams];
  }, []);
};

export const useMyWorkspaceProjects = (query: QueryParams) => {
  return useFetchByParams<Project[]>({
    action: fetchProjects,
    dataSelector: selectMyWorkspaceProjects,
    loadingSelector: selectMyWorkspaceProjectsLoading,
    params: query,
  });
};

export const useOtherProjectsParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      include: 'ProjectType',
      limit: 'unlimited',
    };

    return [queryParams];
  }, []);
};

export const useOtherProjects = (query: QueryParams) => {
  return useFetchByParams<Project[]>({
    action: fetchOtherProjects,
    dataSelector: selectOtherWorkspacesProjects,
    loadingSelector: selectOtherWorkspacesProjectsLoading,
    params: query,
  });
};

export const useFavoriteProjectsParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      include: 'ProjectType',
      limit: 'unlimited',
    };

    return [queryParams];
  }, []);
};

export const useFavoriteProjects = (query: QueryParams) => {
  return useFetchByParams<Project[]>({
    action: fetchFavoriteProjects,
    dataSelector: selectFavoriteProjects,
    loadingSelector: selectFavoriteProjectsLoading,
    params: query,
  });
};

export const useProjectByIdParams = (
  options: {
    workspaceId: string;
    projectId: string;
  } & QueryParams
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = { ...query, ...options };

    return [queryParams];
  }, [workspaceId, projectId]);
};

export const useProjectById = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<Project>({
    action: fetchProjectById,
    dataSelector: (state: RootState) => selectProjectById(state, projectId),
    loadingSelector: (state: RootState) => selectProjectByIdLoading(state, projectId),
    params: query,
  });
};

export const useParametersSetting = (query: QueryParams) => {
  return useFetchByParams<ParameterSetting[]>({
    action: fetchMyParametersSettingByProjectId,
    dataSelector: selectParametersSetting,
    loadingSelector: selectParametersLoading,
    params: query,
  });
};

export const useInspectionTestPlanParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      orderBy: 'order,asc',
      limit: 'unlimited',
    };

    return [queryParams];
  }, []);
};

export const useInspectionTestPlain = (query: QueryParams) => {
  return useFetchByParams<InspectionTestPlanModel[]>({
    action: fetchInspectionTestPlain,
    dataSelector: selectProjectInspectionTestPlan,
    loadingSelector: selectProjectInspectionTestPlanLoading,
    params: query,
  });
};

export const useControlTypeParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      orderBy: 'name',
      limit: 'unlimited',
    };

    return [queryParams];
  }, []);
};

export const useControlTypes = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<ControlType[]>({
    action: fetchControlType,
    dataSelector: (state: RootState) => selectProjectControlType(state, projectId),
    loadingSelector: selectProjectControlTypeLoading,
    params: query,
  });
};

export const useFrequenciesParams = (
  options: QueryParams & {
    workspaceId: string;
    projectId: string;
  }
) => {
  const { workspaceId, projectId } = options;
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      limit: 'unlimited',
      orderBy: 'order',
    };

    return [queryParams];
  }, [workspaceId, projectId]);
};

export const useFrequencies = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<Frequencies[]>({
    action: fetchFrequencies,
    dataSelector: (state: RootState) => selectProjectFrequencies(state, projectId),
    loadingSelector: selectProjectFrequenciesLoading,
    params: query,
  });
};

export const useQualityControlMatricesParams = (options?: QueryParams & { [key: string]: string }) => {
  const [query] = useQueryParams<QueryParams>();

  return useMemo(() => {
    const queryParams = {
      ...query,
      ...options,
      limit: 'unlimited',
      orderBy: 'order',
    };

    return [queryParams];
  }, []);
};

export const useQualityControlMatrices = (query: QueryParams) => {
  const { projectId } = query;
  return useFetchByParams<QualityControlMatrices[]>({
    action: fetchQualityControlMatrices,
    dataSelector: (state: RootState) => selectProjectQualityControlMatrices(state, projectId),
    loadingSelector: selectProjectQualityControlMatricesLoading,
    params: query,
  });
};
