import { RequestForInformationCreateOrUpdate, RequestForInformationListView } from 'components';
import { useState } from 'react';
import { t } from 'i18next';
import { TablePaginationIssueParams } from 'components/common/TablePagination';
import ListViewLayout from 'components/layout/ListViewLayout/ListViewLayout';
import { selectProjectBaseIssuesKpiData, useProjectBaseIssues, useProjectBaseIssuesParams } from 'hooks/base-issue';
import { RFIDataSource } from './RequestForInformationListView';
import { EDynamicTableActionRow } from 'components/common/DynamicTable';
import { useNavigate } from 'react-router-dom';
import BaseIssueKpiStatistic from 'components/common/BaseIssueKpiStatistic';
import { ISSUE_STATUSES } from 'utils/contants';
import { useAppSelector } from 'store';
import { selectIssueStatuses } from 'store/common.slice';
import { EModuleName } from 'utils/my-project';
import { useDeleteBaseIssue } from 'hooks/common/useDeleteBaseissue';
import useUserEntity from 'hooks/common/useUserProfile';
import { selectProjectCompanies, selectProjectUsers } from 'store/my-projects.slice';
import { QueryParams } from 'types';
import FilterBaseIssueForm from './FilterBaseIssueForm';
import dayjs from 'dayjs';

type RequestForInformationProps = {
  workspaceId: string;
  projectId: string;
  isOwnerCompany: boolean;
  module: string;
};
export default function RequestForInformation(props: RequestForInformationProps) {
  const { workspaceId, projectId, module } = props;
  const navigate = useNavigate();

  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [baseIssueId, setBaseIssueId] = useState('');
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const [isResetFilterForm, setIsResetFilterForm] = useState<boolean>(false);

  const [projectBaseIssueParams, onProjectIssuesParamsChange] = useProjectBaseIssuesParams({
    projectId: projectId as string,
    workspaceId,
    module,
    limit: 10,
    type: 'RFI',
    include:
      'IssueStatus|BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
  });

  const [baseIssues, isBaseIssuesLoading, refreshBaseIssues] = useProjectBaseIssues(projectBaseIssueParams);
  const issueStatuses = useAppSelector(selectIssueStatuses);
  const handleCloseForm = () => {
    refreshBaseIssues();
    setBaseIssueId('');
    setIsFormOpen(false);
  };
  const baseIssueKpi = useAppSelector(selectProjectBaseIssuesKpiData(module as EModuleName));
  const kpiReadonlyData = {
    [ISSUE_STATUSES.AVERAGE_CLOSURE_TIME]: { value: baseIssueKpi?.AVG },
    [ISSUE_STATUSES.TOTAL]: { value: baseIssueKpi?.TOTAL, title: 'Total of RFIs' },
  };
  const kpiData = {
    [ISSUE_STATUSES.DRAFT]: { value: baseIssueKpi?.DR },
    [ISSUE_STATUSES.OPEN]: { value: baseIssueKpi?.OP },
    [ISSUE_STATUSES.OPEN_OVERDUE]: { value: baseIssueKpi?.OO },
    [ISSUE_STATUSES.CLOSED]: { value: baseIssueKpi?.CL },
  };
  const { user } = useUserEntity({ projectId, workspaceId });
  const companies = useAppSelector(selectProjectCompanies);
  const users = useAppSelector(selectProjectUsers);

  const loggedProjectCompany = companies?.find((company) => company.id === user?.WorkspaceUser?.Company?.id);
  const loggedCompanyRole = loggedProjectCompany?.ProjectCompanies?.find(
    (projectCompany) => projectCompany.projectId === projectId
  )?.companyRole;
  const isOwnerCompany = loggedCompanyRole === 'owner';

  const { openDeleteConfirm } = useDeleteBaseIssue({
    title: t('Delete the Requests for information'),
    description: t('Are you sure to delete this Requests for information?'),
  });

  const handleActionClick = (action: string, record: RFIDataSource) => {
    switch (action) {
      case EDynamicTableActionRow.Edit:
        setBaseIssueId(record?.id);
        setIsFormOpen(true);
        break;
      case EDynamicTableActionRow.Delete:
        openDeleteConfirm(workspaceId, projectId, record?.id || '').then(handleCloseForm);
        break;
      case EDynamicTableActionRow.SelectedRow:
        navigate(`/projects/${projectId}/toolbox/request-for-information/${record.id}`);
        break;
      default:
        break;
    }
  };

  const handleFilter = async (values: QueryParams) => {
    let companyId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${user?.id}`;
    } else if (values.groupIssues === 'myCompany' && user?.WorkspaceUser?.Company?.id) {
      companyId = `${user?.WorkspaceUser?.Company?.id}`;
    } else if (values.groupIssues === 'watched' && user?.id) {
      watcherId = `${user?.id}`;
    } else {
      if (values.companyId) {
        companyId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }

    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const disciplineId =
      values.disciplineId && values.disciplineId.length > 0
        ? `[${'"' + values.disciplineId.join('","') + '"'}]`
        : undefined;

    const params = {
      ...projectBaseIssueParams,
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      'statusId[]': issueStatusId,
      'disciplineIds[]': disciplineId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : companyId,
      originatorId: assigneeId,
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };

    onProjectIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
  };

  return (
    <ListViewLayout
      filterNode={
        <FilterBaseIssueForm
          companies={companies}
          users={users}
          issueStatuses={issueStatuses}
          workspaceId={workspaceId}
          userCompanyId={user?.WorkspaceUser?.Company?.id}
          projectId={projectId}
          isOpenFilter={!!isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          handleFilter={(value) => {
            handleFilter(value);
          }}
          isReset={isResetFilterForm}
          setReset={setIsResetFilterForm}
          module={module}
          isOwnerCompany={isOwnerCompany}
        />
      }
      onSearch={(searchValue) => {
        const param = {
          ...projectBaseIssueParams,
          searchBy: '["BaseIssue.name","Company.name","InformationRequest.rfiNumber","Zone.name"]',
          searchKey: searchValue,
          page: 1,
        };
        onProjectIssuesParamsChange(param);
      }}
      addBtnProps={{
        onClick: () => {
          setIsFormOpen(true);
        },
        text: t('RFI'),
      }}
    >
      {isFormOpen !== null && (
        <RequestForInformationCreateOrUpdate
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          projectId={projectId}
          workspaceId={workspaceId}
          baseIssueId={baseIssueId}
          onClose={handleCloseForm}
        />
      )}

      <BaseIssueKpiStatistic kpiData={kpiReadonlyData} issueStatuses={issueStatuses} />

      <BaseIssueKpiStatistic
        onFilter={(params) => {
          onProjectIssuesParamsChange(params);
          setIsResetFilterForm(true);
        }}
        kpiData={kpiData}
        issueStatuses={issueStatuses}
        projectBaseIssueParams={projectBaseIssueParams}
        isResetKPI={isResetFilterByKPI}
        onResetKPI={setIsResetFilterByKPI}
      />
      <RequestForInformationListView
        user={user}
        onPaginationChange={onProjectIssuesParamsChange}
        projectId={projectId}
        projectBaseIssueParams={projectBaseIssueParams}
        baseIssues={baseIssues}
        onActionClick={handleActionClick}
        loading={isBaseIssuesLoading === 'pending'}
        onSort={onProjectIssuesParamsChange}
      />
    </ListViewLayout>
  );
}
