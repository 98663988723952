import { BaseIssue, User } from 'model';
import { Form } from 'antd';
import { DocumentsTransmissionFormValues } from './ProjectDocumentsTransmissionForm';
import BaseIssueFormHeader from 'components/common/BaseIssueFormHeader';
import { useANTDFormInstance } from 'hooks/common/useANTDFormInstance';

type RequestForInformationHeaderProps = {
  isUnSaveStatus?: boolean;
  profile: User | null;
  onClose: () => void;
  selectedBaseIssue?: BaseIssue | null;
  user?: User | null;
};

export default function RequestForInformationHeader(props: RequestForInformationHeaderProps) {
  const form = useANTDFormInstance<DocumentsTransmissionFormValues>();

  return (
    <BaseIssueFormHeader
      name={Form.useWatch('subject') || form.getFieldValue('subject')}
      originatorId={form.getFieldValue('issuerName')}
      user={props?.user}
      profile={props?.profile}
      issueStatus={props.selectedBaseIssue?.IssueStatus}
      onClose={props?.onClose}
      baseIssueNumber={props.selectedBaseIssue?.DocumentTransmission?.dtNumber}
      submittedDate={props.selectedBaseIssue?.BaseValidations?.[0]?.createdAt}
    />
  );
}
