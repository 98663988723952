import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Flex, Form, Input, Row, Typography } from 'antd';

import { CustomizeRequiredMark, Modal, SearchSelect, UploadFormItem } from 'components';
import {
  useCountries,
  useCountriesParams,
  useMyWorkspaceProjectTypes,
  useMyWorkspaceProjectTypesParams,
  useProjectById,
  useProjectByIdParams,
} from 'hooks';
import dayjs from 'dayjs';

type EditProjectProps = {
  projectId: string;
  workspaceId: string;
  isModalOpen: boolean;
  loading?: boolean;
  onSubmit: (values: unknown) => void;
  setIsModalOpen: (value: boolean) => void;
};

export default function EditProject(props: EditProjectProps) {
  const { isModalOpen, setIsModalOpen, onSubmit, loading, projectId, workspaceId } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [projectById] = useProjectByIdParams({ projectId, workspaceId });
  const [project] = useProjectById(projectById);
  const [projectTypeQuery] = useMyWorkspaceProjectTypesParams({
    workspaceId,
    limit: 'unlimited',
  });
  const [projectTypes] = useMyWorkspaceProjectTypes(projectTypeQuery);
  const [countryParams] = useCountriesParams();
  const [countries] = useCountries(countryParams);

  useEffect(() => {
    if (project && isModalOpen) {
      if (project.plannedStartDate) {
        project.plannedStartDate = dayjs(project.plannedStartDate, 'YYYY-MM-DD');
      }
      if (project.plannedEndDate) {
        project.plannedEndDate = dayjs(project.plannedEndDate, 'YYYY-MM-DD');
      }
      form.setFieldsValue(project);
    }
  }, [project, isModalOpen]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (values.plannedStartDate) {
        values.plannedStartDate = dayjs(values.plannedStartDate).format('YYYY-MM-DD');
      }

      if (values.plannedEndDate) {
        values.plannedEndDate = dayjs(values.plannedEndDate).format('YYYY-MM-DD');
      }
      values.id = project.id;
      onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleChangeLogo = (imageUrl: string) => {
  //   if (imageUrl) {
  //     form.setFieldValue('logoPath', imageUrl);
  //   }
  // };

  const handleChangeCover = (imageUrl: string) => {
    if (imageUrl) {
      form.setFieldValue('coverPath', imageUrl);
    }
  };

  return (
    <Modal
      title={t('Edit project')}
      open={isModalOpen}
      onOk={handleOk}
      style={{ top: 40 }}
      classNames={{ body: 'edit-project-modal-body' }}
      onCancel={handleCancel}
      footer={[
        <Flex key={'footer'} justify='space-between'>
          <Button key='back' onClick={handleCancel}>
            {t('Setup later')}
          </Button>
          <Button key='submit' loading={loading} type='primary' onClick={handleOk}>
            {t('Save project')}
          </Button>
        </Flex>,
      ]}
    >
      <Typography.Title level={3} className='section-title'>
        {t('Overview')}
      </Typography.Title>
      <Form requiredMark={CustomizeRequiredMark} form={form} name='edit-project' layout='vertical'>
        <Form.Item label={t('Project name')} name='name' rules={[{ required: true, message: '' }]}>
          <Input placeholder={t('Project name')} />
        </Form.Item>
        <Form.Item
          label={<span style={{ textTransform: 'initial' }}>{t('Code (4 characters.)')}</span>}
          name='code'
          rules={[{ required: true, message: '', max: 4, min: 4 }]}
        >
          <Input placeholder={t('Enter code')} />
        </Form.Item>
        <Form.Item label={<>{t('Project type')}</>} name='projectTypeId' rules={[{ required: true, message: '' }]}>
          <SearchSelect
            placeholder={t('Select project type')}
            options={projectTypes?.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        </Form.Item>
        <Form.Item label={<>{t('Project description')} </>} name='description'>
          <Input.TextArea rows={3} placeholder={t('Enter a project description')} />
        </Form.Item>

        {/* <Form.Item label={t("Logo")} name="logoPath">
          <UploadFormItem
            imgUrl={project?.logoPath}
            onChange={handleChangeLogo}
            extraText={t("Upload logo in JPG, SVG or PNG to identify your workspace and use in reports")}
          />
        </Form.Item> */}
        <Form.Item label={t('Cover image')} name='coverPath'>
          <UploadFormItem
            imgUrl={project?.coverPath}
            onChange={handleChangeCover}
            extraText={t('Upload a cover image in JPG or PNG')}
          />
        </Form.Item>
        <Divider className='mb-3 mt-8' />
        <Typography.Title level={3} className='section-title'>
          {t('Location')}
        </Typography.Title>
        <Form.Item labelCol={{ span: 12 }} label={t('Address')} name='address'>
          <Input placeholder={t('Building, block, street')} />
        </Form.Item>
        <Form.Item label={t('City')} name='city'>
          <Input placeholder={t('Enter City')} />
        </Form.Item>
        <Form.Item label={t('Zipcode')} name='zipCode'>
          <Input placeholder={t('Zipcode')} />
        </Form.Item>
        <Form.Item label={t('Country')} name='countryId'>
          <SearchSelect
            placeholder={t('Country')}
            options={countries?.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
          />
        </Form.Item>
        <Divider className='mb-3 mt-15' />
        <Typography.Title level={3} className='section-title'>
          {t('Timeline')}
        </Typography.Title>
        <Form.Item label={t('Start date')} name='plannedStartDate'>
          <DatePicker placeholder={t('Select date')} />
        </Form.Item>
        <Form.Item
          label={t('Ending date')}
          name={'plannedEndDate'}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  !getFieldValue('plannedStartDate') ||
                  (value && getFieldValue('plannedStartDate') && value >= getFieldValue('plannedStartDate'))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error());
              },
            }),
          ]}
        >
          <DatePicker placeholder={t('Select date')} />
        </Form.Item>
        {/* <Typography.Title level={3} className="mb-6 mt-16">
          {t('Optional - Add members')}
        </Typography.Title>
        <Form.Item
          label={t('Invite users to join the project')}
          name="invite_users"
        >
          <Input />
        </Form.Item> */}
        <Divider className='mb-3 mt-16' />
        <Typography.Title level={3} className='section-title'>
          {t('Danger zone')}
        </Typography.Title>
        <Row gutter={24}>
          <Col sm={24} md={12} className='ant-form-text'>
            <Button type='primary' danger icon={<WarningOutlined />}>
              {t('Delete project')}
            </Button>
          </Col>
          <Col sm={24} md={12}>
            <Typography.Text className='ant-form-item-extra'>{t('This operation can’t be undone')}</Typography.Text>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
