import { Input } from 'antd';
import useDebounce from 'hooks/common/useDebound';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type InspectionTestPlanInputProps = {
  value: string;
  onChange: (value: string) => void;
};

const InspectionTestPlanInput = (props: InspectionTestPlanInputProps) => {
  const [value, setValue] = useState(props.value);
  const debouncedValue = useDebounce(value, 500);
  const hasChangeRef = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!hasChangeRef.current) return;
    props.onChange(debouncedValue);
    hasChangeRef.current = true;
  }, [debouncedValue]);

  return (
    <div>
      <Input
        placeholder={t('Control Point')}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          hasChangeRef.current = true;
        }}
      />
    </div>
  );
};

export default InspectionTestPlanInput;
