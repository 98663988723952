import { NotificationToken } from 'model';
import request from 'requesters/notification.request';
import { NotificationModel } from '../model';
import { PaginationData } from '../types';

const notificationService = {
  subscribeNotification: async (notificationTokenData: NotificationToken) => {
    return await request.post('/notification-tokens', notificationTokenData);
  },
  unsubscribeNotification: async (deviceId: string) => {
    return await request.delete(`/notification-tokens/devices/${deviceId}`);
  },
  getNotifications: async (query: any) => {
    return request.get<PaginationData<NotificationModel>>('notifications', {
      params: query,
    });
  },
  getNotificationsCount: async (query: any) => {
    return request.get('notifications/count-unread', {
      params: query,
    });
  },
  markAsRead: async (notificationId: string) => {
    return request.put(`notifications/${notificationId}/mark-as-read`);
  },
  markAllAsRead: async () => {
    return request.put('notifications/mark-all-as-read');
  },
};

export default notificationService;
