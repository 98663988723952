import React from 'react';

import { Flex, Pagination, PaginationProps, Typography, Select, Space, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { titleCase } from 'utils';

export type TablePaginationIssueParams = {
  limit?: number | string;
  page?: number | string;
};

export interface TablePaginationProps {
  onSubmit?: (value: TablePaginationIssueParams) => void;
  issuesCount?: number;
  issueParams?: TablePaginationIssueParams;
  style?: React.CSSProperties;
}

const TablePagination: React.FC<TablePaginationProps> = ({ onSubmit, issuesCount, issueParams }) => {
  const { t } = useTranslation();
  const onChange: PaginationProps['onChange'] = (page, limit) => {
    if (onSubmit) {
      onSubmit({ page, limit });
    }
  };
  const handleChangeLimit = (limit: string) => {
    if (onSubmit) {
      onSubmit({ page: 1, limit: Number(limit) });
    }
  };

  return (
    <Row align='middle' justify='end'>
      <Col flex='300px' />
      <Col flex='auto'>
        <Flex justify='center' align='center'>
          <Pagination
            onChange={onChange}
            total={issuesCount}
            showSizeChanger={false}
            pageSize={issueParams?.limit ? Number(issueParams?.limit) : 0}
            current={issueParams?.page ? Number(issueParams?.page) : 0}
          />
        </Flex>
      </Col>
      <Col flex='300px'>
        <Flex justify='end' align='center'>
          <Typography.Text style={{ color: 'grey' }}>
            {issuesCount} {t('Results')}
          </Typography.Text>
          <Space className='ml-4'>
            <Typography.Text style={{ color: 'grey' }}>{titleCase(t('Items per page'))}:</Typography.Text>
            <Select
              style={{ width: 70 }}
              value={`${issueParams?.limit}`}
              onChange={handleChangeLimit}
              options={[
                { value: '10', label: '10' },
                { value: '20', label: '20' },
                { value: '50', label: '50' },
                { value: '100', label: '100' },
              ]}
            />
          </Space>
        </Flex>
      </Col>
    </Row>
  );
};

export default TablePagination;
