import { Alert, Button, Flex, Form, Spin, Typography } from 'antd';
import { RequestForInformationCreateOrUpdate } from 'components/index';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IssuesCloseOutlined, LeftOutlined } from '@ant-design/icons';
import { BaseIssue, User } from 'model';
import 'react-quill/dist/quill.snow.css';
import { useForm } from 'antd/es/form/Form';
import RequestForInformationDetailThread, {
  RFITheadValue,
  RFIThreadFormValues,
} from './RequestForInformationDetailThread';
import { ATTACHMENT_TYPES, RFI_STATUSES } from 'utils/contants';
import useUserEntity from 'hooks/common/useUserProfile';
import DocumentAdder, { AttachmentBaseIssue } from 'components/common/DocumentAdder';
import { useNavigate, useParams } from 'react-router-dom';
import { ERFIActionType } from '../CreateOrUpdateRequestForInformation/RequestForInformationFooter';
import RequestForInformationDetailTransferModal from './RequestForInformationDetailTransferModal';
import ReactQuillCustom from 'components/common/ReactQuillCustom';
import { baseIssueService } from 'services';
import { mapBaseIssueAttachments, mapDistributionBaseIssueWatcher } from 'utils/my-project';
import { EBaseIssueMessagesAction } from 'model/BaseIssueMessages';
import dayjs from 'dayjs';
import { selectIssueStatuses } from 'store/common.slice';
import { useAppSelector } from 'store';
import { useDeleteBaseIssue } from 'hooks/common/useDeleteBaseissue';
import { createRFIPermissionChecker } from 'utils/rfiHelper';
import TruncatedAttachmentDocument from 'components/common/TruncatedAttachmentDocument';

type RequestForInformationDetailProps = {
  workspaceId: string;
  projectId: string;
  profile: User | null;
  isOwnerCompany: boolean;
  module: string;
  users: User[];
};

export default function RequestForInformationDetail(props: RequestForInformationDetailProps) {
  const { workspaceId, projectId, users, profile } = props;
  const { rfiId } = useParams();
  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState<boolean>(false);
  const [form] = useForm<RFIThreadFormValues>();
  const { user } = useUserEntity({ projectId: props.projectId, workspaceId: props.workspaceId });
  const [comment, setComment] = useState<{ comment: string; attachments: AttachmentBaseIssue[] }>({
    comment: '',
    attachments: [],
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [baseIssue, setBaseIssue] = useState<BaseIssue | null>(null);

  const isClose = useMemo(() => baseIssue?.IssueStatus?.code === RFI_STATUSES.CLOSED, [baseIssue]);
  const { openDeleteConfirm } = useDeleteBaseIssue({
    title: t('Delete the Requests for information'),
    description: t('Are you sure to delete this Requests for information?'),
  });

  const handleProcedureContentChange = (content: string) => {
    setComment((prevComment) => {
      const result = { ...prevComment };
      result.comment = content;
      return result;
    });
  };
  const issueStatuses = useAppSelector(selectIssueStatuses);

  const processSubmitMessage = async (
    comment: string,
    attachments: AttachmentBaseIssue[],
    action: EBaseIssueMessagesAction,
    baseIssueMessageId?: string | null,
    isLoading?: boolean
  ) => {
    const sender = users.find((u) => u.id === profile?.id);
    const recipients = users.find((user) => user.id === baseIssue?.originatorId);
    const listOfNames: string[] = recipients?.fullName ? [recipients.fullName] : [];
    const cc: string[] = [];
    (baseIssue?.BaseIssueWatchers || []).forEach((watcher) => {
      const user = users.find((user) => user.id === watcher.watcherId);
      if (user && recipients?.email !== user?.email) {
        cc.push(user?.email as string);
        listOfNames.push(user?.fullName as string);
      }
    });
    const payload = {
      message: comment,
      companyId: user?.WorkspaceUser?.Company?.id as string,
      action,
      attachmentInfo: mapBaseIssueAttachments(attachments),
      emailsData: {
        recipients: [recipients?.email],
        cc,
        senderName: sender?.fullName,
        senderEmail: sender?.email,
        senderTitle: sender?.jobTitle,
        senderCompany: sender?.WorkspaceUser?.Company?.name,
        listOfNames,
      },
    };
    if (baseIssueMessageId) {
      await baseIssueService.updateBaseIssueMessage(
        workspaceId,
        projectId,
        rfiId as string,
        baseIssueMessageId,
        payload
      );
    } else {
      await baseIssueService.createBaseIssueMessage(workspaceId, projectId, rfiId as string, payload);
    }
    await fetchBaseIssueById(rfiId as string, isLoading);
  };

  const handleSend = async () => {
    try {
      const thread = form.getFieldValue('thread') as RFITheadValue[];
      thread.push({
        localId: dayjs().toISOString(),
        title: thread[0].title,
        action: EBaseIssueMessagesAction.COMMENT,
        updatedDate: dayjs().toISOString(),
        commentUserId: user?.id,
        ...comment,
      });
      form.setFieldsValue({
        thread: thread,
      });
      setComment(() => ({ comment: '', attachments: [] }));
      await processSubmitMessage(comment.comment, comment.attachments, EBaseIssueMessagesAction.COMMENT, null, false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateRFIForm = async (status: string) => {
    const sender = users.find((u) => u.id === profile?.id);
    const recipients = users.find((user) => user.id === baseIssue?.originatorId);
    const listOfNames: string[] = recipients?.fullName ? [recipients.fullName] : [];
    const cc: string[] = [];
    (baseIssue?.BaseIssueWatchers || []).forEach((watcher) => {
      const user = users.find((user) => user.id === watcher.watcherId);
      if (user && recipients?.email !== user?.email) {
        cc.push(user?.email as string);
        listOfNames.push(user?.fullName as string);
      }
    });
    await baseIssueService.updateBaseIssue(workspaceId, projectId, rfiId as string, {
      name: baseIssue?.name,
      originatorId: baseIssue?.originatorId,
      companyOriginatorId: baseIssue?.companyOriginatorId,
      moduleFunctionId: baseIssue?.moduleFunctionId,
      isSaveAsDraft: false,
      statusId: issueStatuses.find((issueStatus) => issueStatus.code === status)?.id,
      rfi: {
        disciplineId: baseIssue?.InformationRequest?.disciplineId,
      },
      emailsData: {
        recipients: [recipients?.email],
        cc,
        senderName: sender?.fullName,
        senderEmail: sender?.email,
        senderTitle: sender?.jobTitle,
        senderCompany: sender?.WorkspaceUser?.Company?.name,
        listOfNames,
      },
    });
    fetchBaseIssueById(rfiId as string);
  };

  const handleActionClick = async (type: ERFIActionType, data?: unknown) => {
    switch (type) {
      case ERFIActionType.EditForm:
        setIsFormOpen(true);
        break;
      case ERFIActionType.Close:
        await updateRFIForm(RFI_STATUSES.CLOSED);
        break;
      case ERFIActionType.DeleteThread: {
        await baseIssueService.deleteBaseIssueMessage(
          workspaceId,
          projectId,
          baseIssue?.id as string,
          (data as RFITheadValue)?.id as string
        );
        await fetchBaseIssueById(rfiId as string, false);
        break;
      }
      case ERFIActionType.Submit:
        handleSend();
        break;
      case ERFIActionType.OpenTransfer:
        setIsOpenTransferModal(true);
        break;
      case ERFIActionType.AddUser: {
        const addedUser = data as User;
        const comment = {
          from: user?.id || '',
          to: addedUser.id,
          toCompany: addedUser?.WorkspaceUser?.Company?.id,
        };
        setIsOpenTransferModal(false);
        await processSubmitMessage(JSON.stringify(comment), [], EBaseIssueMessagesAction.ADD_USER);
        break;
      }
      case ERFIActionType.EditThread: {
        const threadValue = data as RFITheadValue;
        await processSubmitMessage(
          threadValue?.comment as string,
          threadValue.attachments,
          EBaseIssueMessagesAction.COMMENT_EDIT,
          threadValue.id,
          false
        );
        break;
      }
      case ERFIActionType.DeleteForm: {
        openDeleteConfirm(workspaceId, projectId, rfiId || '').then(() => {
          navigate(`/projects/${projectId}/toolbox/request-for-information`);
        });
        break;
      }
      case ERFIActionType.SendToRecipient: {
        await updateRFIForm(RFI_STATUSES.OPEN);
        break;
      }
      default:
        break;
    }
  };

  const fetchBaseIssueById = async (baseIssueId: string, isLoading: boolean = true) => {
    setIsLoading(isLoading);
    try {
      const baseIssue = await baseIssueService.getBaseIssueById(workspaceId, projectId, baseIssueId, {
        type: 'RFI',
        include:
          'IssueStatus|BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      setBaseIssue(baseIssue);
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const initializeThreads = async (baseIssue: BaseIssue) => {
    const result = [];

    const watcher = mapDistributionBaseIssueWatcher(baseIssue?.BaseIssueWatchers);

    if (baseIssue?.InformationRequest) {
      result.unshift({
        localId: baseIssue?.id,
        id: baseIssue?.id,
        title: baseIssue?.name,
        updatedDate: baseIssue.updatedAt,
        toRecipients: {
          companyId: watcher.receiver?.companyId,
          userId: watcher.receiver?.userIds?.[0],
        },
        statusCode: baseIssue.IssueStatus?.code,
        ccRecipients: watcher.distributions.map((distribution) => ({
          companyId: distribution.companyId,
          userIds: distribution.inspectorIds,
        })),
        isMainThread: true,
        comment: baseIssue.InformationRequest?.content,
        commentUserId: baseIssue?.originatorId,
        attachments: baseIssue?.InformationRequest?.InformationRequestAttachments?.map((rfiAttachment) => ({
          documentId: rfiAttachment.id,
          name: rfiAttachment?.name,
          filePath: rfiAttachment.filePath,
        })),
      });
    }

    if (baseIssue?.BaseIssueMessages?.length) {
      const threads = form.getFieldValue('thread') as RFITheadValue[];
      const replyThreads = threads?.slice(1);

      const validBaseIssueMessage = baseIssue?.BaseIssueMessages?.filter(
        (baseIssueMessage) => baseIssueMessage.action !== EBaseIssueMessagesAction.FORM_EDIT
      );

      const threadComment = validBaseIssueMessage.map((baseIssueMessage, index) => ({
        localId: replyThreads?.[index]?.localId || baseIssueMessage?.id,
        id: baseIssueMessage?.id,
        title: baseIssue?.name,
        updatedDate: baseIssueMessage.updatedAt,
        commentUserId: baseIssueMessage?.userId,
        comment: baseIssueMessage?.message,
        action: baseIssueMessage?.action,
        attachments: baseIssueMessage.BaseIssueMessageAttachments?.map((rfiAttachment) => ({
          documentId: rfiAttachment.id,
          name: rfiAttachment?.name,
          filePath: rfiAttachment.filePath,
        })),
      }));
      result.push(...threadComment);
    }

    form.setFieldsValue({
      thread: result,
    });
  };

  useEffect(() => {
    if (!rfiId) return;
    fetchBaseIssueById(rfiId);
  }, [rfiId]);

  useEffect(() => {
    if (baseIssue) {
      initializeThreads(baseIssue);
    }
  }, [baseIssue]);

  const renderAttachment = () => {
    return (
      <div className='!mb-[16px]'>
        {comment.attachments?.length > 0 &&
          comment.attachments?.map((attachmentDocument) => {
            return (
              <TruncatedAttachmentDocument
                key={attachmentDocument.filePath}
                fileName={attachmentDocument.name}
                filePath={attachmentDocument.filePath}
                onRemoveFile={() => {
                  const newAttachments = comment.attachments.filter(
                    (item) => item.filePath !== attachmentDocument.filePath
                  );
                  comment.attachments = newAttachments;
                  setComment({ ...comment });
                }}
              />
            );
          })}
      </div>
    );
  };

  const renderCommentEditor = () => {
    const checkPermission = createRFIPermissionChecker(
      baseIssue?.originatorId,
      user,
      baseIssue?.BaseIssueWatchers,
      baseIssue?.IssueStatus?.code || ''
    );
    return (
      <>
        {checkPermission(ERFIActionType.Submit) && (
          <>
            <Typography.Title level={3} className='mt-[30px] !mb-[16px]'>
              {t('Your Reply:')}
            </Typography.Title>
            {renderAttachment()}
            <ReactQuillCustom onChange={handleProcedureContentChange} value={comment.comment} />
          </>
        )}

        <Flex className={'mt-4'} gap={8} align={'center'} justify='flex-end'>
          {checkPermission(ERFIActionType.Submit) && (
            <DocumentAdder
              hideResult={true}
              attachmentType={ATTACHMENT_TYPES.BASE_DOCUMENT}
              onChange={(attachments) => {
                const newComment = { ...comment };
                newComment.attachments = attachments;
                setComment(newComment);
              }}
              documentsInfo={comment.attachments}
              layout='inline'
            />
          )}
          {checkPermission(ERFIActionType.AddUser) && (
            <Button onClick={() => handleActionClick(ERFIActionType.OpenTransfer)}>{t('Add User')}</Button>
          )}
          {checkPermission(ERFIActionType.Submit) && (
            <Button
              type={'primary'}
              onClick={() => handleActionClick(ERFIActionType.Submit)}
              disabled={!comment?.comment && !comment.attachments?.length}
            >
              {t('Submit')}
            </Button>
          )}
        </Flex>
      </>
    );
  };

  return (
    <div className='request-for-information-detail'>
      <Flex className={'mb-10'}>
        <Button
          type='link'
          onClick={() => {
            navigate(`/projects/${projectId}/toolbox/request-for-information`);
          }}
          icon={<LeftOutlined style={{ color: 'rgba(0, 29, 61, 1)' }} />}
          style={{ color: 'rgba(0, 29, 61, 1)' }}
        >
          {t('Back to RFI List')}
        </Button>
      </Flex>

      <Form form={form}>
        <Spin spinning={isLoading}>
          <RequestForInformationDetailThread
            loggedUser={user}
            onActionClick={handleActionClick}
            baseIssue={baseIssue}
          />
          {isClose && (
            <Alert
              showIcon
              style={{ background: 'rgba(82, 196, 26, 1)', color: 'white', marginTop: 30 }}
              message={t('The RFI is Closed')}
              icon={<IssuesCloseOutlined style={{ color: 'white' }} />}
            />
          )}
          {renderCommentEditor()}

          {isOpenTransferModal && (
            <RequestForInformationDetailTransferModal
              isOpen={isOpenTransferModal}
              onCancel={() => {
                setIsOpenTransferModal(false);
              }}
              workspaceId={workspaceId}
              projectId={projectId}
              onActionClick={handleActionClick}
            />
          )}
        </Spin>
      </Form>

      {isFormOpen !== null && (
        <RequestForInformationCreateOrUpdate
          isFormOpen={isFormOpen}
          baseIssueId={rfiId}
          setIsFormOpen={setIsFormOpen}
          projectId={projectId}
          workspaceId={workspaceId}
          onClose={async () => {
            await fetchBaseIssueById(rfiId as string, true);
          }}
        />
      )}
    </div>
  );
}
