import { Button, Flex, Input, Layout, message } from 'antd';
import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryParams } from 'types';
import { BaseIssue, User } from 'model';
import {
  ProjectMinutesOfMeetingCreateOrUpdate,
  ProjectMinutesOfMeetingListView,
  ProjectMinutesOfMeetingStatistic,
} from 'components';
import {
  useFetchProjectCategoryByFunction,
  useFetchProjectCategoryByFunctionParams,
  useProjectBaseIssuesParams,
} from 'hooks/base-issue';
import { EDynamicTableActionRow } from 'components/common/DynamicTable';
import { kpiData } from 'types/project';
import { useIssueStatuses, useIssueStatusesParams, useProjectCompanies, useProjectCompaniesParams } from 'hooks';
import { ISSUE_STATUSES, MODULE_FUNCTIONS, MOM_STATUSES } from 'utils/contants';
import { MOMDataSource } from './ProjectMinutesOfMeetingListView';
import { DisciplineBaseIssueTableRefHandle } from 'components/common/CategoryBaseIssueTable';
import dayjs from 'dayjs';
import FilterMinutesOfMeeting from './FilterMinutesOfMeeting';
import { baseIssueService } from 'services';
import momItemService from 'services/momItem.service';
import BaseIssueMomItem from 'model/BaseIssueMomItem';
import { useMomItemStatuses, useMomItemStatusesParams } from 'hooks/momItemStatus';
import { useDeleteBaseIssue } from 'hooks/common/useDeleteBaseissue';
import { useSearchDebounce } from 'utils';

type ProjectMinutesOfMeetingProps = {
  module: string;
  workspaceId: string;
  projectId: string;
  isOwnerCompany: boolean;
  loggedCompanyRoleId: string;
  userCompanyId: undefined | string;
  profile: User | null;
  users: User[];
  refreshUser: (options?: QueryParams | undefined) => void;
};

export default function ProjectMinutesOfMeeting(props: ProjectMinutesOfMeetingProps) {
  const { module, workspaceId, projectId, profile, users, userCompanyId, isOwnerCompany } = props;
  const categoryBaseIssueTableRef = useRef<DisciplineBaseIssueTableRefHandle>(null);
  const { t } = useTranslation();
  const params = useParams();
  const [momItemStatusesParams] = useMomItemStatusesParams();
  const [momItemStatuses] = useMomItemStatuses(momItemStatusesParams);
  const navigate = useNavigate();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean | null>(null);
  const { openDeleteConfirm } = useDeleteBaseIssue({
    title: t('Delete the Minutes of Meeting'),
    description: t('Are you sure to delete this Minutes of Meeting?'),
  });
  const [companyParams] = useProjectCompaniesParams({
    projectId: projectId as string,
    workspaceId,
    orderBy: 'name',
  });
  const [companies] = useProjectCompanies(companyParams);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [projectBaseIssueParams, onProjectBaseIssuesParamsChange] = useProjectBaseIssuesParams({
    projectId: params.projectId as string,
    workspaceId,
    type: 'MOM',
    module,
    include:
      'BaseIssueWatchers|ModuleFunction|IssueStatus|Company|Zone|BaseValidations|BaseIssueCategories|BaseIssueAttachments',
  });
  const [projectCategoryByFunctionParam] = useFetchProjectCategoryByFunctionParams({
    projectId: params.projectId as string,
    workspaceId,
  });
  const [categoryByFunction, , refreishProjectCategoryByFunction] =
    useFetchProjectCategoryByFunction(projectCategoryByFunctionParam);

  const [isResetFilterByKPI, setIsResetFilterByKPI] = useState<boolean>(false);
  const [isResetFilter, setIsResetFilter] = useState<boolean | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const [baseIssueId, setBaseIssueId] = useState<string | null>(null);
  const [duplicateBaseissueId, setDuplicateBaseissueId] = useState('');
  const [searchValue, setSearchValue] = useSearchDebounce();
  const handleInputBlur = (value: string) => {
    if (value.length <= 0) setShowSearchInput(false);
  };
  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };
  const [totalKpi, setTotalKpi] = useState<kpiData>({
    [ISSUE_STATUSES.DRAFT]: 0,
    [ISSUE_STATUSES.REVIEW_IN_PROGRESS]: 0,
    [ISSUE_STATUSES.ISSUED]: 0,
  });

  const [issueStatusesQuery] = useIssueStatusesParams({
    orderBy: 'orderNumber',
    moduleFunction: MODULE_FUNCTIONS.MOM,
  });
  const [issueStatuses] = useIssueStatuses(issueStatusesQuery);
  const handleFilter = async (values: QueryParams) => {
    let CompanyOriginatorId = undefined;
    let assigneeId = undefined;
    let watcherId = undefined;

    if (values.groupIssues === 'myBaseIssue') {
      assigneeId = `${profile?.id}`;
    } else if (values.groupIssues === 'myCompany' && userCompanyId) {
      CompanyOriginatorId = `${userCompanyId}`;
    } else if (values.groupIssues === 'watched' && profile?.id) {
      watcherId = `${profile?.id}`;
    } else {
      if (values.companyId) {
        CompanyOriginatorId = `${values.companyId}`;
      }
      if (values.assigneeId) {
        assigneeId = `${values.assigneeId}`;
      }
    }
    const issueStatusId =
      values.issueStatusId && values.issueStatusId.length > 0
        ? `[${'"' + values.issueStatusId.join('","') + '"'}]`
        : undefined;
    const params = {
      'createdAt[gte]':
        values.startDate && values.startDate[0]
          ? dayjs(values.startDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : undefined,
      'createdAt[lte]':
        values.startDate && values.startDate[1]
          ? dayjs(values.startDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : undefined,
      'statusId[]': issueStatusId,
      companyOriginatorId: !isOwnerCompany && values.groupIssues === 'myCompany' ? undefined : CompanyOriginatorId,
      originatorId: assigneeId,
      // 'companyId[]': [companyId],
      filterType: values.groupIssues,
      watcherId: watcherId,
      page: 1,
    };
    // console.log(params);
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilterByKPI(true);
  };
  const handleFilterByKPI = async (code: string) => {
    const issueStatusId = issueStatuses.find((value) => value.code === code)?.id;
    const params = {
      'createdAt[lte]': undefined,
      'createdAt[gte]': undefined,
      'statusId[]': issueStatusId ? `[${'"' + issueStatusId + '"'}]` : undefined,
      companyOriginatorId: undefined,
      originatorId: undefined,
      filterType: undefined,
      page: 1,
    };
    onProjectBaseIssuesParamsChange(params);
    setIsResetFilter(true);
  };
  const handleOpenForm = () => {
    setIsFormOpen(true);
  };
  const handleRefreshCategory = (CategoryId?: string) => {
    categoryBaseIssueTableRef.current?.refreshData(CategoryId, baseIssueId);
  };
  const fetchBaseIssueById = async (baseIssueId: string) => {
    // setIsLoading(true);
    try {
      const baseIssue = await baseIssueService.getBaseIssueById(props.workspaceId, props.projectId, baseIssueId, {
        type: 'MOM',
        include: 'BaseIssueWatchers|ModuleFunction|IssueStatus|Zone|Company|BaseIssueCategories|BaseIssueAttachments',
      });
      return baseIssue;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      // setIsLoading(false);
    }
  };
  const fetchMomItemById = async (baseIssueId: string, momItemId: string) => {
    // setIsLoading(true);
    try {
      const momItem = await momItemService.getMomItemsById(props.workspaceId, props.projectId, baseIssueId, momItemId, {
        orderBy: 'order',
        include: 'MomItemAttachments',
      });
      return momItem;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      // setIsLoading(false);
    }
  };
  const handleDeleteBaseIssue = async (baseIssueId?: string, categoryId?: string) => {
    try {
      if (baseIssueId && categoryId) {
        await openDeleteConfirm(workspaceId, projectId, baseIssueId);
        handleRefreshCategory(categoryId);
      } else {
        message.error(t('Minutes Of Meeting not existed!'));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const duplicateAsDraft = async (selectedBaseIssue: BaseIssue | null, momItem?: BaseIssueMomItem[] | null) => {
    try {
      const statusObj = issueStatuses?.find((status) => status.code === MOM_STATUSES.DRAFT);
      const selectedBaseIssueName = `Copy of ${selectedBaseIssue?.name}`;

      const submitData = {
        isSaveAsDraft: true,
        statusId: statusObj?.id,
        originatorId: profile?.id,
        name: selectedBaseIssueName,
        companyOriginatorId: userCompanyId,
        moduleFunctionId: selectedBaseIssue?.moduleFunctionId,

        mom: {
          categoryId: selectedBaseIssue?.MinutesOfMeeting?.categoryId,
          meetingLocation: selectedBaseIssue?.MinutesOfMeeting?.meetingLocation,
          meetingDate: dayjs().format('YYYY-MM-DD'),
          nextMeetingLocation: selectedBaseIssue?.MinutesOfMeeting?.nextMeetingLocation,
          momDistributions: selectedBaseIssue?.BaseIssueWatchers?.map((watcher) => ({
            companyId: watcher.companyId,
            role: watcher.role,
            inspectorIds: watcher.watcherId,
          })),
          momItems: momItem?.map((item) => ({
            companyId: item?.companyId,
            assigneeId: item.assigneeId,
            statusId: momItemStatuses[0]?.id,
            dueDate: item.dueDate ? dayjs(item.dueDate).format('YYYY-MM-DD') : null,
            name: item.name,
            description: item.description,
            momItemAttachmentInfo: item.MomItemAttachments,
          })),
        },
      };
      await baseIssueService.createBaseIssue(props.workspaceId, props.projectId, submitData);
      message.success('Duplicated the Minutes Of Meeting successfully!');
      handleRefreshCategory(selectedBaseIssue?.MinutesOfMeeting?.categoryId);
    } catch (error) {
      console.log(error);
    }
  };
  const initializeDraftForm = async (meetingId: string) => {
    if (meetingId) {
      const baseIssue = await fetchBaseIssueById(meetingId);
      if (baseIssue?.MinutesOfMeeting?.id) {
        const momItems = await fetchMomItemById(meetingId, baseIssue?.MinutesOfMeeting?.id);
        if (momItems?.rows) {
          duplicateAsDraft(baseIssue, momItems?.rows);
        }
      }
    }
  };
  const handleActionClick = (action: string, record: MOMDataSource) => {
    switch (action) {
      case EDynamicTableActionRow.Edit:
        setBaseIssueId(record?.id);
        setIsFormOpen(true);
        break;
      case EDynamicTableActionRow.Delete:
        handleDeleteBaseIssue(record?.id, record.category);
        break;
      case EDynamicTableActionRow.Duplicate:
        initializeDraftForm(record?.id);
        setDuplicateBaseissueId(record.id);
        break;
      case EDynamicTableActionRow.SelectedRow:
        navigate(`${window.location.pathname}/${record?.id}`);
        break;
      default:
        break;
    }
  };
  const handleSearch = async () => {
    try {
      const param: Record<string, string> = {
        searchBy: '["BaseIssue.name", "Company.name","Zone.name","MinutesOfMeeting.momNumber"]',
        searchKey: searchValue,
        page: '1',
      };
      onProjectBaseIssuesParamsChange(param);
    } catch (error) {
      console.log(error);
      message.error(t('Oop! Something wrong'));
    }
  };
  useEffect(() => {
    handleSearch();
  }, [searchValue]);
  return (
    <Layout.Content className='project-container'>
      {isFormOpen !== null && (
        <ProjectMinutesOfMeetingCreateOrUpdate
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          meetingId={baseIssueId}
          users={users}
          setBaseIssueId={setBaseIssueId}
          companies={companies}
          projectId={projectId}
          issueStatuses={issueStatuses}
          categoryByFunction={categoryByFunction}
          workspaceId={props.workspaceId}
          onClose={(categoryId) => {
            categoryBaseIssueTableRef.current?.refreshData(categoryId, baseIssueId);
            refreishProjectCategoryByFunction();
          }}
        />
      )}
      <Flex className='mb-4' justify='space-between' align='center'>
        <div>
          <h1 className='leading-[46px]'>{t('Minutes of Meeting Register')}</h1>
          <div className='sub-title'>{t('View and manage minutes of meeting register')}</div>
        </div>
        <Flex>
          <Input
            name='search-input'
            className='search-button mr-3'
            placeholder={t('Type to search')}
            onBlur={(e) => handleInputBlur(e.target.value)}
            onFocus={handleSearchIconClick}
            style={{
              width: showSearchInput ? '250px' : '0px',
              border: showSearchInput ? '1px solid #d9d9d9' : 'none',
            }}
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchValue(e.target.value)}
            allowClear
          />
          {isOpenFilter === null ? (
            <Button
              onClick={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
              icon={<FilterOutlined />}
            >
              {t('Filter')}
            </Button>
          ) : (
            <FilterMinutesOfMeeting
              companies={companies}
              users={users}
              issueStatuses={issueStatuses}
              workspaceId={workspaceId}
              projectId={projectId}
              userCompanyId={userCompanyId}
              isOpenFilter={isOpenFilter}
              setIsOpenFilter={setIsOpenFilter}
              handleFilter={(value) => {
                handleFilter(value);
              }}
              isReset={isResetFilter || false}
              setReset={setIsResetFilter}
              module={module}
              isOwnerCompany={isOwnerCompany}
            />
          )}
          <Button type='primary' icon={<PlusOutlined />} onClick={() => handleOpenForm()}>
            {t('MoM')}
          </Button>
        </Flex>
      </Flex>

      <ProjectMinutesOfMeetingStatistic
        kpiData={totalKpi}
        handleFilterByKPI={handleFilterByKPI}
        isResetKPI={isResetFilterByKPI}
        setResetKPI={setIsResetFilterByKPI}
      />
      <ProjectMinutesOfMeetingListView
        categoryBaseIssueTableRef={categoryBaseIssueTableRef}
        onActionClick={handleActionClick}
        onTotalKpi={setTotalKpi}
        profile={profile}
        companies={companies}
        projectBaseIssueParams={projectBaseIssueParams}
        refreshCategoryByFunction={refreishProjectCategoryByFunction}
        duplicateBaseissueId={duplicateBaseissueId}
      />
    </Layout.Content>
  );
}
