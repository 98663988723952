import { useParams } from 'react-router-dom';

import { Button, Flex, Tooltip, Typography, UploadFile, message, Input, Alert } from 'antd';
import { DeleteConfirmation, ProjectContent } from 'components';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import DesignsDisciplineListView from 'modules/DocumentManagement/components/DesignRegister/DesignsDisciplineListView';

import { PlusOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons';

import { Design } from 'model';
import { Key, SetStateAction, useEffect, useMemo, useState } from 'react';
import { QueryParams } from 'types';
import { EWorkBreakDownStructureLevelID } from 'types/project';
import UpdateDesignDrawer from 'modules/DocumentManagement/components/DesignRegister/UpdateDesignDrawer';
import { useProjectCompanies, useProjectCompaniesParams, useProjectZones, useProjectZonesParams } from 'hooks';
import { DesignResponse, UnpublishedDesignForm } from 'modules/DocumentManagement/models/Design';
import { useAppSelector } from 'store';
import { selectMyWorkspace } from 'store/my-workspace.slice';
import FilterDesignForm from 'modules/DocumentManagement/components/DesignRegister/FilterDesignForm';
import dayjs from 'dayjs';
import UploadDesignModal from 'modules/DocumentManagement/components/DesignRegister/DesignUploadModal';
import shopDrawingService from 'services/shop-drawing.services';
import { useProjectShopDrawingParams, useProjectShopDrawings } from 'hooks/shop-drawings';
import ManageRevisionDrawer from 'modules/DocumentManagement/components/DesignRegister/ManageRevisionsDrawer';
import DesignDeleteConfirmation from 'modules/DocumentManagement/components/DesignRegister/DesignDeleteConfirmation';
import PublishDesignModal from 'modules/DocumentManagement/components/DesignRegister/PublishDesignModal';
import { AxiosProgressEvent } from 'axios';
import { fileService } from 'services';
import MiniPDF from 'components/Modal/MiniPDF';
import { useWorkBreakdownStructures, useWorkBreakdownStructureParams } from 'hooks/work-breakdown-structure';

export default function ShopDrawing() {
  const { t } = useTranslation();
  const NETWORK_ERROR_MESSAGE = {
    content: t('An error occured, please try again later'),
    key: 'uploadMessage',
    duration: 1,
  };
  const [maxRowAmount] = useState(50);
  const FILE_REGEX = /^[^\\/:*?"<>|]*$/;
  const REGEX_TOSTRING = `\\  / : * ? " < > |`;
  const DEFAULT_PARAMS = {
    orderBy: 'createdAt,desc',
    limit: maxRowAmount,
    page: 1,
  };
  const [ShopDrawingsData, setShopDrawingsData] = useState<Design | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const params = useParams();
  const projectId = params.projectId as string;
  const myWorkspace = useAppSelector(selectMyWorkspace);
  const workspaceId = myWorkspace?.id as string;

  const [workBreakdownStructureQuery] = useWorkBreakdownStructureParams({
    projectId: projectId,
    workspaceId: workspaceId,
    orderBy: 'order,asc',
    mainPhase: true,
  });

  const SHOP_DRAWING_PHASES = [{ id: 'icf', name: 'Issued For Construction', order: 1 }];

  const [companiesParams] = useProjectCompaniesParams({
    projectId,
    workspaceId,
  });
  const [companies] = useProjectCompanies(companiesParams);
  const [workBreakDownStructures, wbsLoading] = useWorkBreakdownStructures(workBreakdownStructureQuery);

  const [disciplines, disciplineMapNameToId, disciplineMapIdToName]: [
    { discipline: string }[],
    Map<string, string>,
    Map<string, string>,
  ] = useMemo(() => {
    if (workBreakDownStructures) {
      const workBreakDownStructuresFiltered = workBreakDownStructures.filter(
        (item) => item.level === EWorkBreakDownStructureLevelID.Discipline
      );

      const disciplines = workBreakDownStructuresFiltered.map((item) => {
        return { discipline: item.name } as { discipline: string };
      });
      const disciplineMapNameToId = new Map<string, string>();
      const disciplineMapIdToName = new Map<string, string>();

      const disciplineIds = workBreakDownStructuresFiltered.map((item) => item.id);

      disciplines.forEach((item, index) => {
        disciplineMapNameToId.set(item.discipline, disciplineIds[index]);
        disciplineMapIdToName.set(disciplineIds[index], item.discipline);
      });

      return [disciplines, disciplineMapNameToId, disciplineMapIdToName];
    } else {
      return [[{ discipline: '' }], new Map(), new Map()];
    }
  }, [workBreakDownStructures]);

  const [singleDelete, setSingleDelete] = useState<{
    open: boolean;
    designId?: string;
    initialRevisionId?: string;
    discipline?: string;
  }>({ open: false, designId: undefined, discipline: undefined, initialRevisionId: undefined });
  const [bulkDeleteIsOpen, setBulkDeleteIsOpen] = useState<boolean>(false);
  //revisions
  const [revisionFileList, setRevisionFileList] = useState<UploadFile[]>([]);
  const [newUploadedRevision, setNewUploadedRevision] = useState<UnpublishedDesignForm | null>(null);
  const [currentRevision, setCurrentRevision] = useState<Design | null>(null);

  const [newCurrentRevision, setNewCurrentRevision] = useState<Design | null>(null);
  const [revisionList, setRevisionList] = useState<Design[]>([]);
  const [revisionDraft, setRevisionDraft] = useState<Design | null>(null);
  const [numberOfUnpublishedDrawings, setNumberOfUnpublishedDrawings] = useState<number>(0);

  const [, setDeleteLoading] = useState<boolean>(false);
  const [, setIsDownloading] = useState<boolean>(false); //is used for the downloading button, which is disabled at the moment
  const [, setDownloadsList] = useState<{ label: string; percent: number; error?: boolean; id: string }[]>([]);
  const [, setUploadsList] = useState<{ label: string; percent: number; error?: boolean; id: string }[]>([]);
  const [, setFileModalOpen] = useState<boolean>(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerEditingDrawing, setDrawerEditingDrawing] = useState<Design>({} as Design);
  const [drawerManagingRevision, setDrawerManagingRevision] = useState<{
    open: boolean;
    design?: Design;
    fromListView?: boolean;
  }>({
    open: false,
  });
  //publish design modal
  const [unpublishedModalDesigns, setUnpublishedModalDesigns] = useState<Design[]>([]);
  const [unpublishedModalIsOpen, setUnpublishedModalIsOpen] = useState<boolean>(false);

  // handle search by title bar;
  const [showSearchByTitleInput, setShowSearchByTitleInput] = useState<boolean>(false);
  const handleSearchByTitle = (title: string) => {
    const param = {
      searchBy: title.length ? '["ShopDrawing.title"]' : '',
      searchKey: title ?? '',
      page: 1,
    };
    setGeneralFilterQuery({ ...GeneralFilterQuery, ...param });
  };

  const [pdfFilePath, setPdfFilePath] = useState<string | null>(null);
  const [isModalPdfOpen, setIsModalPdfOpen] = useState<boolean>(false);

  // handle open pdf from email link
  const [drawingIdToOpenOnInit, setDrawingIdToOpenOnInit] = useState<string>();

  const [GeneralFilterQuery, setGeneralFilterQuery] = useProjectShopDrawingParams({});
  const [nestedOrderParams, setNestedOrderParams] = useState<{ [discipline: string]: string }>({});
  const [nestedPaginationParams, setNestedPaginationParams] = useState<{ [discipline: string]: QueryParams }>({});
  const query = useMemo(
    () => ({
      GeneralFilterQuery,
      nestedOrderParams,
      nestedPaginationParams,
      workspaceId,
      projectId,
      disciplineMapNameToId,
    }),
    [GeneralFilterQuery, nestedOrderParams, nestedPaginationParams, projectId, workBreakDownStructures]
  );
  const [shopDrawingTablesData, shopDrawingLoading, refreshDrawings] = useProjectShopDrawings(query);
  const [revisionSumbitLoading, setRevisionSumbitLoading] = useState<boolean>(false);
  const [nestedShopDrawingTablesData, setNestedShopDrawingTablesData] = useState<DesignResponse>({});

  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);

  useEffect(() => {
    setNestedShopDrawingTablesData(shopDrawingTablesData);
    /*will be used in the future for fetching individual tables*/

    // compute number of unpublished shop drawings
    if (shopDrawingTablesData) {
      const unpublishedDrawings = Object.values(shopDrawingTablesData)
        .map((item) => item.rows)
        .flat()
        .filter((item) => item.status === 'draft');
      setNumberOfUnpublishedDrawings(unpublishedDrawings.length);
    }

    if (shopDrawingTablesData && drawingIdToOpenOnInit) {
      const shopDrawing = Object.values(shopDrawingTablesData)
        .map((d) => d.rows)
        .flat()
        .find((t) => t.id === drawingIdToOpenOnInit);
      if (shopDrawing) openPDFInMiniPDF(shopDrawing);
    }
  }, [shopDrawingTablesData]);

  const refreshAll = () => {
    refreshDrawings();
  };
  useEffect(() => {
    refreshAll();
  }, [projectId, workspaceId]);

  useEffect(() => {
    const tempParams = {};
    Object.keys(nestedOrderParams).forEach((discipline) => {
      Object.assign(tempParams, { [discipline]: Object.assign(nestedOrderParams[discipline], GeneralFilterQuery) });
    });
    setNestedOrderParams(tempParams);

    // when shop-drawing is in the url params, we want to open pdf on init
    if (GeneralFilterQuery['shop-drawing']) {
      setDrawingIdToOpenOnInit(GeneralFilterQuery['shop-drawing']);
      delete GeneralFilterQuery['shop-drawing'];
    }
  }, [GeneralFilterQuery]);

  const [designTablesSelectedKeys, setDesignTablesSelectedKeys] = useState<{
    [discipline: string]: Key[];
  }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const updateSelectedRowKeys = () => {
    setSelectedRowKeys(
      Object.keys(designTablesSelectedKeys)
        .map((item) => {
          return designTablesSelectedKeys[item];
        })
        .flat()
    );
  };

  useEffect(() => {
    updateSelectedRowKeys();
  }, [designTablesSelectedKeys]);

  const handleUpload = async (
    fileList: UploadFile[],
    designList: UnpublishedDesignForm[],
    draft: boolean,
    mapNameToIdDiscipline: Map<string, string> = disciplineMapNameToId
  ) => {
    try {
      setFileModalOpen(true);

      designList.forEach((design) => {
        const uploadFile = fileList.find((file) => design.id === file.uid);
        design.disciplineId = mapNameToIdDiscipline.get(design.discipline);
        const dotIndex = uploadFile!.name.lastIndexOf('.');
        const fileExtension = uploadFile!.name.slice(dotIndex + 1) ?? '';
        design.fileExtension = fileExtension;
      });
      const allIds = await shopDrawingService.createShopDrawings(projectId, workspaceId, designList);
      const filePromises = allIds.map((idCouple) => {
        const currentDesign = designList.find((des) => des.id === idCouple.uid);
        setUploadsList((prevList) => {
          const replaceIndex = prevList.findIndex((dl) => {
            return dl.id === idCouple.uid;
          });
          const newItem = {
            label: currentDesign?.title as string,
            id: idCouple.uid,
            percent: 0,
          };

          if (replaceIndex === -1) {
            prevList.push(newItem);
          } else {
            prevList[replaceIndex] = newItem;
          }
          return prevList;
        });

        const updatePercentage = (newPercentage: number) => {
          setUploadsList((prevList) => {
            const replaceIndex = prevList.findIndex((dl) => {
              return dl.id === currentDesign?.id;
            });
            const newItem = {
              label: currentDesign?.title as string,
              id: currentDesign?.id as string,
              percent: newPercentage,
            };
            if (replaceIndex === -1) {
              prevList.push(newItem);
            } else {
              prevList[replaceIndex] = newItem;
            }
            return prevList;
          });
        };
        const filePromise = fileService
          .uploadShopDrawingAndDesignFileAdvanced(
            workspaceId,
            projectId,
            idCouple.id,
            fileList.find((file) => file.uid === idCouple.uid)?.originFileObj as File,
            'application/pdf',
            draft,
            updatePercentage,
            10,
            true
          )
          .catch((e) => {
            handleUploadError(e as Error);
          });
        return filePromise;
      });
      Promise.all(filePromises).then(() => {
        refreshAll();
      });
    } catch (e) {
      handleUploadError(e as Error);
    }
  };

  const handleSingleRevisionUpload = async (
    uploadFile: UploadFile,
    oldId: string,
    uploadShopDrawing: UnpublishedDesignForm,
    draft: boolean
  ) => {
    setFileModalOpen(true);
    try {
      const response = await shopDrawingService.createShopDrawingRevision(
        workspaceId,
        projectId,
        oldId,
        uploadShopDrawing
      );

      setUploadsList((prevList) => {
        const replaceIndex = prevList.findIndex((dl) => {
          return dl.id === uploadShopDrawing.id;
        });
        const newItem = { label: uploadShopDrawing.title as string, id: uploadShopDrawing.id, percent: 0 };

        if (replaceIndex === -1) {
          prevList.push(newItem);
        } else {
          prevList[replaceIndex] = newItem;
        }
        return prevList;
      });

      const updatePercentage = (newPercentage: number) => {
        setDownloadsList((prevList) => {
          const replaceIndex = prevList.findIndex((dl) => {
            return dl.id === uploadShopDrawing.id;
          });
          const newItem = {
            label: uploadShopDrawing.title as string,
            id: uploadShopDrawing.id,
            percent: newPercentage,
          };
          if (replaceIndex === -1) {
            prevList.push(newItem);
          } else {
            prevList[replaceIndex] = newItem;
          }
          return prevList;
        });
      };

      fileService.uploadShopDrawingAndDesignFileAdvanced(
        workspaceId,
        projectId,
        response.id,
        uploadFile.originFileObj as File,
        'application/pdf',
        draft,
        updatePercentage,
        10,
        true
      );

      return response;
    } catch (e) {
      handleUploadError(e as Error);
      setRevisionSumbitLoading(false);
      return { id: 'error' };
    }
  };

  const handleUploadError = (error: Error) => {
    message.error(NETWORK_ERROR_MESSAGE);
    refreshAll();
    console.error(error);
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleDeleteBulk = async () => {
    setDeleteLoading(true);
    try {
      await shopDrawingService.deleteShopDrawingsAndRevisions(workspaceId, projectId, selectedRowKeys);
      setBulkDeleteIsOpen(false);
      setDeleteLoading(false);
      refreshAll();
      setDesignTablesSelectedKeys({});
      updateSelectedRowKeys();
    } catch (e) {
      console.error(e);
      message.error(NETWORK_ERROR_MESSAGE);
      setBulkDeleteIsOpen(false);
      setDeleteLoading(false);
    }
  };
  const handleDeleteShopDrawing = async () => {
    try {
      if (designTablesSelectedKeys[singleDelete.discipline!]?.includes(singleDelete.designId!)) {
        const tempkeyList = designTablesSelectedKeys;
        tempkeyList[singleDelete.discipline as string].splice(
          tempkeyList[singleDelete.discipline as string].findIndex((key: Key) => key === singleDelete.designId),
          1
        );
        setDesignTablesSelectedKeys(tempkeyList);
        updateSelectedRowKeys();
      }
      setSingleDelete({ open: false, designId: undefined, discipline: undefined, initialRevisionId: undefined });
      await shopDrawingService.deleteShopDrawingsAndRevisions(
        workspaceId,
        projectId,
        singleDelete.initialRevisionId ? [singleDelete.initialRevisionId] : [singleDelete.designId as string]
      );

      refreshAll();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteDraftOnly = async () => {
    try {
      if (designTablesSelectedKeys[singleDelete.discipline!]?.includes(singleDelete.designId!)) {
        const tempkeyList = designTablesSelectedKeys;
        tempkeyList[singleDelete.discipline as string].splice(
          tempkeyList[singleDelete.discipline as string].findIndex((key: Key) => key === singleDelete.designId),
          1
        );
        tempkeyList[singleDelete.discipline as string].push(singleDelete.initialRevisionId as string);
        setDesignTablesSelectedKeys(tempkeyList);
        updateSelectedRowKeys();
      }
      setSingleDelete({ open: false, designId: undefined, discipline: undefined, initialRevisionId: undefined });
      await shopDrawingService.deleteShopDrawingsById(
        workspaceId,
        projectId,
        singleDelete.designId ? [singleDelete.designId] : []
      );
      refreshAll();
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddDesign = () => {
    setIsModalOpen(true);
  };
  const openPDFInMiniPDF = async (shopDrawing: Design) => {
    try {
      setShopDrawingsData(shopDrawing);
      const url = await shopDrawingService.downloadShopDrawing(workspaceId, projectId, shopDrawing.id);
      setPdfFilePath(url);
      setIsModalPdfOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setIsModalPdfOpen(false);
    setPdfFilePath('');
  };

  const beforeUpload = async (file: UploadFile) => {
    const allowedMimeTypes = ['application/pdf'];
    let isCompatible = false;
    let isSmallEnough = false;
    if (file.type) {
      isCompatible = allowedMimeTypes.includes(file.type);
    }
    if (file.size) {
      isSmallEnough = file.size / 1024 / 1024 < 100;
    }
    const isDuplicate = fileList.some((item) => item.name === file.name);
    let cantBeUploaded: boolean = false;
    if (!isCompatible) {
      cantBeUploaded = true;
      file.status = 'error';
      file.response = t('You can only upload files of type') + ': PDF';
    }
    if (!isSmallEnough) {
      cantBeUploaded = true;
      file.status = 'error';
      file.response = t('File must smaller than') + ' 100MB';
    }
    if (isDuplicate) {
      cantBeUploaded = true;
      file.status = 'error';
      file.response = `${t(`You've already selected`)} ${file.name}`;
    }
    if (cantBeUploaded) {
      file.error.message = '';
    }
    return cantBeUploaded;
  };

  const singleDownload = async (designId?: string, filename?: string) => {
    try {
      setIsDownloading(true);
      setDownloadsList((prevList) => {
        const replaceIndex = prevList.findIndex((dl) => {
          return dl.id === design;
        });
        const newItem = { label: filename as string, id: design, percent: 0 };
        if (replaceIndex === -1) {
          prevList.push(newItem);
        } else {
          prevList[replaceIndex] = newItem;
        }
        return prevList;
      });
      if (!filename) {
        const singleDesign = await shopDrawingService.getShopDrawingById(workspaceId, projectId, designId as string);
        filename = `${singleDesign.title}.${singleDesign.fileExtension}`;
      }
      const input: string = designId as string;
      const design = input === undefined ? (selectedRowKeys[0] as string) : input;
      setFileModalOpen(true);
      const config = {
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1));
          setDownloadsList((prevList) => {
            const replaceIndex = prevList.findIndex((dl) => {
              return dl.id === design;
            });
            const newItem = { label: filename as string, id: design, percent: percentage };
            if (replaceIndex === -1) {
              prevList.push(newItem);
            } else {
              prevList[replaceIndex] = newItem;
            }
            return prevList;
          });
        },
      };
      const presignedUrl = await shopDrawingService.downloadShopDrawing(workspaceId, projectId, design, config);
      const response = await fetch(presignedUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  };

  const handleBulkPublish = async () => {
    const selectedRows: Design[] = Object.values(shopDrawingTablesData)
      .map((item) => item.rows)
      .flat()
      .filter((row) => selectedRowKeys.includes(row.id as string));
    setUnpublishedModalDesigns(selectedRows);
    setUnpublishedModalIsOpen(true);
  };

  const handleFilter = async (values: QueryParams, phaseModification: boolean = false) => {
    // If we apply the filter form
    if (!phaseModification) {
      const title = values.title;
      const number = values.number;
      const revision = values.revision;
      const documentType =
        values.documentType && values.documentType.length > 0
          ? `[${'"' + values.documentType.join('","') + '"'}]`
          : undefined;
      const area = values.area && values.area.length > 0 ? `[${'"' + values.area.join('","') + '"'}]` : undefined;
      const issuedBy =
        values.issuedBy && values.issuedBy.length > 0 ? `[${'"' + values.issuedBy.join('","') + '"'}]` : undefined;
      const status =
        values.status && values.status.length > 0 ? `[${'"' + values.status.join('","') + '"'}]` : undefined;
      const params = {
        'issueDate[gte]':
          values.issueDate && values.issueDate[0]
            ? dayjs(values.issueDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
            : undefined,
        'issueDate[lte]':
          values.issueDate && values.issueDate[1]
            ? dayjs(values.issueDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
            : undefined,
        'deadlineDate[gte]':
          values.deadlineDate && values.deadlineDate[0]
            ? dayjs(values.deadlineDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
            : undefined,
        'deadlineDate[lte]':
          values.deadlineDate && values.deadlineDate[1]
            ? dayjs(values.deadlineDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
            : undefined,
        issueTypeId: values.issueTypeId ?? undefined,
        title: title,
        number: number,
        revision: revision,
        'documentType[]': documentType,
        'area[]': area,
        'issuedBy[]': issuedBy,
        'status[]': status,
        page: 1,
      };
      setGeneralFilterQuery(params);
    }
    refreshAll();
  };

  return (
    <ProjectContent>
      {isModalPdfOpen && pdfFilePath && ShopDrawingsData && (
        <MiniPDF
          filePath={pdfFilePath}
          title={`${ShopDrawingsData.title} - Preview`}
          isDisabled={false}
          CloseModal={closeModal}
          width={'70%'}
          height={'50%'}
        />
      )}
      <DesignDeleteConfirmation
        containsDraft={!!singleDelete.initialRevisionId}
        isModalOpen={singleDelete.open}
        title={t('Delete document')}
        description={
          singleDelete.initialRevisionId
            ? t('Are you sure to delete this unpublished document AND its published revisions?')
            : t('Are you sure to delete this document and its revisions?')
        }
        onDeleteUnpublished={handleDeleteDraftOnly}
        onDeleteAll={handleDeleteShopDrawing}
        onCancel={() =>
          setSingleDelete({ open: false, designId: undefined, discipline: undefined, initialRevisionId: undefined })
        }
      />
      <DeleteConfirmation
        cantUndo={true}
        isModalOpen={bulkDeleteIsOpen}
        title={selectedRowKeys.length === 1 ? t('Delete document') : t('Delete documents')}
        description={
          selectedRowKeys.length === 1
            ? t('Are you sure to delete this unpublished document AND its published revisions?')
            : t('Are you sure to delete these documents AND their published revisions?')
        }
        onDelete={() => {
          handleDeleteBulk();
        }}
        onCancel={() => setBulkDeleteIsOpen(false)}
      />
      <PublishDesignModal
        refreshAll={refreshAll}
        publishDesign={shopDrawingService.publishShopDrawing}
        projectId={projectId}
        fileRegex={FILE_REGEX}
        fileRegexToString={REGEX_TOSTRING}
        designs={unpublishedModalDesigns}
        setDesigns={setUnpublishedModalDesigns}
        updateSelectedRowKeys={updateSelectedRowKeys}
        isModalOpen={unpublishedModalIsOpen}
        setIsModalOpen={setUnpublishedModalIsOpen}
        checkDuplicate={shopDrawingService.findDuplicateShopDrawings}
        disciplines={disciplines}
        disciplineMapIdToName={disciplineMapIdToName}
        modalType='publish'
        designRegisterPhases={SHOP_DRAWING_PHASES}
        zones={zones}
      />
      <ManageRevisionDrawer
        beforeUpload={beforeUpload}
        fileRegex={FILE_REGEX}
        fileRegexToString={REGEX_TOSTRING}
        currentRevision={currentRevision}
        getDesignById={shopDrawingService.getShopDrawingById}
        revisionDraft={revisionDraft}
        setRevisionDraft={setRevisionDraft}
        openPDFNewTab={openPDFInMiniPDF}
        revisionList={revisionList}
        setRevisionList={setRevisionList}
        newUploadedRevision={newUploadedRevision}
        setNewUploadedRevision={setNewUploadedRevision}
        fileList={revisionFileList}
        setFileList={setRevisionFileList}
        deleteRevisions={shopDrawingService.deleteShopDrawingsById}
        updateCurrentRevision={shopDrawingService.updateShopDrawingRevision}
        getRevisions={shopDrawingService.getShopDrawingRevisions}
        checkDuplicate={shopDrawingService.findDuplicateShopDrawings}
        refreshAll={refreshAll}
        setSubmitLoading={setRevisionSumbitLoading}
        submitLoading={revisionSumbitLoading}
        singleDownload={singleDownload}
        projectId={projectId}
        workspaceId={workspaceId}
        drawerManagingRevision={drawerManagingRevision}
        setDrawerManagingRevision={setDrawerManagingRevision}
        onClose={() => {
          setDrawerManagingRevision({ open: false, design: undefined });
        }}
        handleSingleUpload={handleSingleRevisionUpload}
        updateItem={shopDrawingService.updateShopDrawing}
        newCurrentRevision={newCurrentRevision}
        setNewCurrentRevision={setNewCurrentRevision}
        setCurrentRevision={setCurrentRevision}
      />
      {isModalOpen !== null && (
        <UploadDesignModal
          beforeUpload={beforeUpload}
          fileList={fileList}
          setFileList={setFileList}
          checkDuplicate={shopDrawingService.findDuplicateShopDrawings}
          titleStep2='Add Drawing Info'
          workspaceId={workspaceId}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          projectId={projectId}
          fileRegex={FILE_REGEX}
          fileRegexToString={REGEX_TOSTRING}
          handleUpload={handleUpload}
          disciplines={disciplines}
          disciplineMapIdToName={disciplineMapIdToName}
          designRegisterPhases={SHOP_DRAWING_PHASES}
          zones={zones}
        />
      )}
      <UpdateDesignDrawer
        updateItem={shopDrawingService.updateShopDrawing}
        checkDuplicate={shopDrawingService.findDuplicateShopDrawings}
        workspaceId={workspaceId}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        editingDesign={drawerEditingDrawing}
        projectId={projectId}
        refreshDesigns={refreshAll}
        fileRegex={FILE_REGEX}
        regexToString={REGEX_TOSTRING}
        setEditingDesign={setDrawerEditingDrawing}
        disciplineMapIdToName={disciplineMapIdToName}
        designRegisterPhases={SHOP_DRAWING_PHASES}
        zones={zones}
      />
      <Content className='settings-content'>
        <Flex wrap='wrap' gap={5} className='mb-4' justify='space-between' align='center'>
          <Flex vertical style={{ wordWrap: 'normal', textWrap: 'nowrap' }}>
            <Typography.Title level={2} className='mr-16 setting-section-header'>
              {t('Shop drawings')}
            </Typography.Title>
            <Typography.Text type='secondary'>
              {t('View and track all shop drawings related documents')}
            </Typography.Text>
          </Flex>
          <Flex wrap='wrap' gap={5} justify='flex-end' align='right'>
            <SyncOutlined
              spin={wbsLoading === 'pending' || shopDrawingLoading === 'pending'}
              onClick={() => {
                refreshAll();
              }}
              style={{ fontSize: 20, marginRight: 12 }}
            />
            <Input
              name='search-input'
              className='search-button mr-3'
              placeholder={t('Type to search')}
              onChange={(e) => handleSearchByTitle(e.target.value)}
              onFocus={() => setShowSearchByTitleInput(true)}
              onBlur={(e: { target: { value: SetStateAction<string> } }) => {
                if (!e.target.value.length) setShowSearchByTitleInput(false);
              }}
              style={{
                width: showSearchByTitleInput ? '250px' : '0px',
                border: showSearchByTitleInput ? '1px solid #d9d9d9' : 'none',
              }}
              prefix={<SearchOutlined />}
              allowClear
            />
            <FilterDesignForm
              companies={companies}
              handleFilter={handleFilter}
              defaultParams={DEFAULT_PARAMS}
              style={{ marginLeft: 0, marginRight: 14 }}
              zones={zones}
            />
            <Tooltip title={!selectedRowKeys.length ? t('Select unpublished documents to use this feature') : ''}>
              <Button
                onClick={handleBulkPublish}
                disabled={!selectedRowKeys.length}
                style={{ marginLeft: 0, marginRight: 14 }}
              >
                {t('Publish')}
              </Button>
            </Tooltip>
            <Tooltip title={selectedRowKeys.length === 0 ? t('Select items to use this feature') : ''}>
              <Button
                disabled={selectedRowKeys.length === 0}
                danger
                onClick={() => {
                  setBulkDeleteIsOpen(true);
                }}
                style={{ marginLeft: 0, marginRight: 14 }}
              >
                {t('Delete')}
              </Button>
            </Tooltip>
            <Tooltip
              title={
                !workBreakDownStructures || workBreakDownStructures?.length === 0
                  ? t(
                      'Please ask your Project Administrator to define the Work Breakdown Structure before adding documents'
                    )
                  : ''
              }
            >
              <Button
                style={{ marginLeft: 0, marginRight: 0 }}
                onClick={handleAddDesign}
                type='primary'
                disabled={!workBreakDownStructures || workBreakDownStructures?.length === 0}
                icon={<PlusOutlined />}
              >
                {t('Add Documents')}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
        {numberOfUnpublishedDrawings > 0 && (
          <Alert
            message={t(
              `Unpublished shop drawings are only visible by the members of your company. You have ${numberOfUnpublishedDrawings} unpublished shop drawings`
            )}
            type='warning'
            showIcon
          />
        )}
        <DesignsDisciplineListView
          beforeUpload={beforeUpload}
          checkDuplicate={shopDrawingService.findDuplicateShopDrawings}
          getRevisions={shopDrawingService.getShopDrawingRevisions}
          revisionList={revisionList}
          setRevisionList={setRevisionList}
          setRevisionFileList={setRevisionFileList}
          revisionFileList={revisionFileList}
          setDrawerManagingRevision={setDrawerManagingRevision}
          maxRowAmount={maxRowAmount}
          disciplines={disciplines}
          openPDFNewTab={openPDFInMiniPDF}
          designTableData={nestedShopDrawingTablesData}
          designTablesSelectedKeys={designTablesSelectedKeys}
          setDesignTablesSelectedKeys={setDesignTablesSelectedKeys}
          projectId={projectId}
          singleDownload={singleDownload}
          showDrawer={showDrawer}
          setDrawerEditingDesign={setDrawerEditingDrawing}
          discLoading={wbsLoading === 'pending' || shopDrawingLoading === 'pending'}
          setIsDelete={setSingleDelete}
          workspaceId={workspaceId}
          onFilter={handleFilter}
          orderParams={nestedOrderParams}
          setOrderParams={setNestedOrderParams}
          paginationParams={nestedPaginationParams}
          setPaginationParams={setNestedPaginationParams}
          unpublishedModalDesigns={unpublishedModalDesigns}
          setUnpublishedModalDesigns={setUnpublishedModalDesigns}
          setUnpublishedModalIsOpen={setUnpublishedModalIsOpen}
          module='quality'
          zones={zones}
          designRegisterPhases={SHOP_DRAWING_PHASES}
        />
      </Content>
    </ProjectContent>
  );
}
