import { Space, StepProps, Steps, Tag, Typography } from 'antd';
import { ValidationHistory } from 'model';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { VALIDATION_STATUS } from 'utils/contants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ProgressBlueIcon from 'assets/images/progress-blue.png';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelector } from 'react-redux';
import { selectProjectUsers } from 'store/my-projects.slice';

type MSSsHistoryProps = {
  companyHistories: ValidationHistory[];
};

export default function MSSsHistory(props: MSSsHistoryProps) {
  const { companyHistories } = props;
  const { t } = useTranslation();
  const form = useFormInstance();
  const users = useSelector(selectProjectUsers);

  const distributions = form.getFieldValue('watcherIds');

  const formatItems = useMemo(() => {
    const stepItems: StepProps[] | undefined = [];

    companyHistories.forEach((companyHistories) => {
      stepItems.push({
        status: `${
          companyHistories.status === VALIDATION_STATUS.REJECTED
            ? 'error'
            : companyHistories.status === VALIDATION_STATUS.TRANSFERRED
              ? 'process'
              : companyHistories.status === VALIDATION_STATUS.IN_REVIEW
                ? 'wait'
                : 'finish'
        }`,
        icon: (
          <Space>
            {companyHistories.status === VALIDATION_STATUS.IN_REVIEW && (
              <img width={32} src={ProgressBlueIcon} alt={companyHistories.status} />
            )}
            {companyHistories.status === VALIDATION_STATUS.VALIDATED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.SUBMITTED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.APPROVED && (
              <CheckOutlined style={{ padding: 10 }} className='comments__collapse-icon validated' />
            )}
            {companyHistories.status === VALIDATION_STATUS.REJECTED && (
              <CloseOutlined style={{ padding: 10 }} className='comments__collapse-icon rejected' />
            )}
            {companyHistories.status === VALIDATION_STATUS.TRANSFERRED && (
              <div className='comments__collapse-icon transferred' />
            )}
          </Space>
        ),
        title: <div className={`status-${companyHistories.status}`}>{companyHistories.company}</div>,

        description: (
          <div>
            {companyHistories.comments.map((comment, index) => {
              return (
                <div className={'mb-1'} key={comment.id}>
                  <Typography.Text
                    style={{ fontWeight: 'bold' }}
                    className={`comments__steps-status ${comment.status.code} `}
                  >
                    {' '}
                    {comment.status.code === 'transferred' && 'Reviewed and Forwarded'}
                    {comment.status.code === 'in-review' && 'Review in Progress'}
                    {comment.status.code === 'submitted' && 'Submitted'}
                    {comment.status.code === 'validated' && 'Approved and Forwarded'}
                    {comment.status.code === 'approved'
                      ? comment.pinComment
                        ? 'APPROVED WITH COMMENTS'
                        : 'APPROVED'
                      : ''}
                    {comment.status.code === 'rejected' && 'REJECTED'}{' '}
                  </Typography.Text>
                  <Typography.Text>{`${t('by')} `}</Typography.Text>
                  <Typography.Text style={{ fontWeight: 'bold' }}>{`${comment.user}`}</Typography.Text>
                  <Typography.Text
                    type='secondary'
                    className='text-size-14'
                    style={{
                      color: `${
                        comment.status.name === 'rejected'
                          ? 'red'
                          : comment.status.name === VALIDATION_STATUS.APPROVED ||
                              (comment.status.name === VALIDATION_STATUS.VALIDATED && index === 0)
                            ? 'green'
                            : '#747577'
                      }`,
                    }}
                  >
                    {' '}
                    {comment.date}
                  </Typography.Text>
                  <br />
                  <Typography.Text className={`${comment.pinComment ? 'approved-with-comment ' : 'pt-1'}`}>
                    {' '}
                    {comment.comment}{' '}
                  </Typography.Text>

                  {comment.status.code === 'submitted' && distributions?.length > 0 && (
                    <div>
                      <Typography.Title className='text-size-12 font-bold mt-2 mb-2'>
                        {t('Distribution')}
                      </Typography.Title>
                      {distributions?.map((userId: string) => {
                        const selectedUser = users.find((user) => user.id === userId);
                        return <Tag key={userId}>{selectedUser?.fullName}</Tag>;
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ),
      });
    });
    return stepItems;
  }, [companyHistories]);
  return (
    <div className='comment-container'>
      <Steps direction='vertical' className={'comment-steps-container comments__steps'} items={formatItems} />
    </div>
  );
}
