import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Tag,
  theme, TreeSelect,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePickerRange, SearchSelect } from 'components';
import { Company, IssueStatus, User } from 'model';
import { useEffect, useMemo, useState } from 'react';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { MDI_STATUS_COLOR } from 'utils/contants';
import dayjs from 'dayjs';
import { convertZoneToTreeData, jsonToArray, parseQueryParams } from 'utils';
import {
  useIssueDisciplines,
  useIssueDisciplinesParams,
  useIssuePriorities,
  useIssuePrioritiesParams,
  useIssueTypes,
  useIssueTypesParams,
  useProjectZones,
  useProjectZonesParams,
} from 'hooks';
import { DataNode } from 'antd/es/tree';

type FilterFormData = {
  assigneeId?: string;
  companyId?: string;
  groupIssues?: string;
  issueStatusId?: string[];
  startDate: any[];
  zoneId?: string[];
};

type FilterIssueFormProps = {
  companies: Company[];
  users: User[];
  issueStatuses: IssueStatus[];
  userCompanyId?: string;
  workspaceId: string;
  projectId: string;
  isOpenFilter: boolean;
  setIsOpenFilter: (value: boolean) => void;
  handleFilter: (value: { [key: string]: any }) => void;
  isReset: boolean;
  setReset: (value: boolean) => void;
  module: string;
  isOwnerCompany: boolean;
  zoneUpdated: string;
  setZoneUpdated: (value: string) => void;

  };
export default function FilterMaterialDeliveryInspectionForm(props: FilterIssueFormProps) {
  const {
    companies,
    users,
    issueStatuses,
    userCompanyId,
    handleFilter,
    isReset,
    projectId,
    workspaceId,
    setReset,
    isOpenFilter,
    setIsOpenFilter,
    module,
    isOwnerCompany,
    zoneUpdated,
    setZoneUpdated
  } = props;
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formValuesWatch = Form.useWatch([], form);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isDisableGroup, setDisableGroup] = useState<boolean>(false);
  const [zonesTreeData, setZonesTreeData] = useState<DataNode[]>([]);

  const [lastSubmitFilterData, setLastSubmitFilterData] = useState<FilterFormData>({
    assigneeId: undefined,
    companyId: undefined,
    groupIssues: isOwnerCompany ? 'all' : 'myCompany',
    issueStatusId: [],
    startDate: [],
    zoneId: [],
  });

  const [issueTypesQuery] = useIssueTypesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueTypes] = useIssueTypes(issueTypesQuery);
  const [issueDisciplinesQuery] = useIssueDisciplinesParams({
    module,
    orderBy: 'name,asc',
  });
  const [issueDisciplines] = useIssueDisciplines(issueDisciplinesQuery);
  const [issuePrioritiesQuery] = useIssuePrioritiesParams({
    orderBy: 'orderNumber',
  });
  const [issuePriorities] = useIssuePriorities(issuePrioritiesQuery);
  const [zonesParams] = useProjectZonesParams({
    projectId,
    workspaceId,
    orderBy: 'name',
  });
  const [zones] = useProjectZones(zonesParams);
  const assigneeFilter = useMemo(() => {
    return users?.filter((user) => !selectedCompanyId || user.WorkspaceUser?.Company?.id === selectedCompanyId);
  }, [isOpenFilter, selectedCompanyId]);

  const setUpData = (filterData: FilterFormData) => {
    const groupIssue = filterData.groupIssues ?? 'all';
    form.setFieldsValue(filterData);
    form.setFieldValue('groupIssues', groupIssue);
    setDisableGroup(groupIssue === 'all' ? false : true);
  };

  useEffect(() => {
    const formattedParams: FilterFormData = {
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      zoneId: [],
    };
    const params = parseQueryParams(window.location.search);
    if (params['startDate[gte]']) {
      formattedParams.startDate = [dayjs(params['startDate[gte]'].replace('+', ' '))];
    }
    if (params['startDate[lte]']) {
      formattedParams.startDate = [
        ...formattedParams?.startDate,
        dayjs(params['startDate[lte]'].replace('+', ' ')),
      ];
    }
    if (params['companyId[]']) {
      const companyId = jsonToArray(params['companyId[]'])[0];
      if (companyId !== userCompanyId) formattedParams.companyId = companyId || undefined;
      else {
        if (params['assigneeId[]']) {
          formattedParams.companyId = companyId || undefined;
        } else {
          formattedParams.groupIssues = 'myCompany';
        }
      }
    }
    if (params['profileId']) {
      formattedParams.groupIssues = 'myCompany';
    }
    if (params['watcherId']) {
      formattedParams.groupIssues = 'watchedIssues';
    }
    if (params['assigneeId[]']) {
      formattedParams['assigneeId'] = jsonToArray(params['assigneeId[]'])[0] || undefined;
    }
    if (params['issueStatusId[]']) {
      formattedParams.issueStatusId = jsonToArray(params['issueStatusId[]']);
    }
    if (params['zoneId[]']) {
      formattedParams.zoneId = jsonToArray(params['zoneId[]']);
    }

    setLastSubmitFilterData(formattedParams);
    // setUpData(formattedParams);
  }, []);

  useEffect(() => {
    if (isOpenFilter) {
      setUpData(lastSubmitFilterData);
    }
  }, [isOpenFilter, lastSubmitFilterData]);

  useEffect(() => {
    if (isReset) {
      form.resetFields();
      setDisableGroup(false);
      setLastSubmitFilterData({
        assigneeId: undefined,
        companyId: undefined,
        groupIssues: isOwnerCompany ? 'all' : 'myCompany',
        issueStatusId: [],
        startDate: [],
        zoneId: [],
      });
      setIsOpenFilter(false);
      setReset(false);
    }
  }, [isReset]);

  const onChangeCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    form.setFieldValue('assigneeId', null);
  };
  const onChangeAssignee = (assigneeId: string) => {
    const selectedUser = users?.find((user) => user.id === assigneeId);
    const existingCompany = companies?.findIndex((company) => company.id === selectedUser?.WorkspaceUser?.Company?.id);
    form.setFieldValue(['companyId'], existingCompany > -1 ? selectedUser?.WorkspaceUser?.Company?.id : null);
  };
  const onChangeGroupIssues = (e: RadioChangeEvent) => {
    setDisableGroup(e.target.value === 'all' ? false : true);
    form.setFieldValue('assigneeId', null);
    form.setFieldValue('companyId', null);
  };

  const onApplyFilter = async () => {
    const values = await form.getFieldsValue();
    setLastSubmitFilterData(values);
    handleFilter(values);
    setIsOpenFilter(false);
  };
  const onResetFilter = () => {
    form.resetFields();
    setDisableGroup(false);
    setLastSubmitFilterData({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      zoneId: [],
    });
    handleFilter({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueDisciplineId: [],
      issuePriorityId: [],
      issueStatusId: [],
      issueTypeId: undefined,
      startDate: [],
      zoneId: [],
    });
    setIsOpenFilter(false);
  };

  useEffect(() => {
    const isObjectUndefined =
      formValuesWatch &&
      Object.keys(formValuesWatch)
        .filter((key) => key !== 'groupIssues')
        .every((key) => formValuesWatch[key] === undefined || formValuesWatch[key] === null);
    if (
      (isObjectUndefined &&
        (formValuesWatch['groupIssues'] === 'all' || formValuesWatch['groupIssues'] === undefined)) ||
      !formValuesWatch
    ) {
      setIsFiltering(false);
    } else {
      setIsFiltering(true);
    }
  }, [formValuesWatch]);


  useEffect(() => {
    handleFilter({
      assigneeId: undefined,
      companyId: undefined,
      groupIssues: isOwnerCompany ? 'all' : 'myCompany',
      issueStatusId: [],
      startDate: [],
      zoneId: [],
    });
  }, [isOwnerCompany]);

  useEffect(() => {
    if (zones) {
      const tree = convertZoneToTreeData(zones);
      setZonesTreeData(tree);
      setZoneUpdated(zones?.find((zone) => zone.parentId === null)?.id || zones[0].id);
    }
  }, [zones]);

  const onChangeZone = (zoneId: string) => {
    form.setFieldValue('zoneId', zoneId);
    setZoneUpdated(zoneId)
  };
  return (
    <Popover
      content={
        <Card
          className='inspection-filter'
          bordered={false}
          style={{ width: '580px' }}
          title={
            <Typography.Text
              style={{ fontSize: '20px', fontWeight: '400', lineHeight: '38px' }}
              className='text-uppercase'
            >
              {t('Filter')}
            </Typography.Text>
          }
          extra={
            <Button
              size='small'
              type='text'
              onClick={() => {
                // console.log(lastSubmitFilterData);
                // form.setFieldsValue(lastSubmitFilterData);
                setIsOpenFilter(false);
              }}
              icon={<CloseOutlined style={{ color: 'rgba(0, 29, 61, 0.45)', fontWeight: '600', fontSize: '16px' }} />}
            />
          }
          actions={[
            <Flex justify='end' className='mx-5'>
              <Button onClick={onResetFilter} className='px-4'>
                {t('Reset')}
              </Button>
              <Button onClick={onApplyFilter} className='px-4' type='primary'>
                {t('Apply')}
              </Button>
            </Flex>,
          ]}
        >
          <Form labelCol={{ span: 24 }} layout='vertical' form={form} className='filter-form'>
            <Row gutter={16} align='stretch' className='mb-5'>
              <Col span={12}>
                <Form.Item className='filter-form__item' name='groupIssues' initialValue={'myCompany'} >
                  <Radio.Group defaultValue={'myCompany'} onChange={onChangeGroupIssues}>
                    <Space direction='vertical'>
                      <Radio
                        disabled={!isOwnerCompany}
                        style={{ lineHeight: '2.4' }}
                        value='all'>
                        {t('All MDIs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myBaseIssue'>
                        {t('My MDIs')}
                      </Radio>
                      <Radio style={{ lineHeight: '2.4' }} value='myCompany'>
                        {t('My company MDIs')}
                      </Radio>
                      {/*<Radio style={{ lineHeight: '2.4' }} value='watchedIssues'>*/}
                      {/*  {t('Watched MDIs')}*/}
                      {/*</Radio>*/}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className='filter-form__item mb-3' name='companyId' label={t('Company Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeCompany}
                    options={companies?.map((option) => ({ value: option.id, label: option.name }))}
                  />
                </Form.Item>
                <Form.Item className='filter-form__item' name='assigneeId' label={t('Originator')}>
                  <SearchSelect
                    disabled={isDisableGroup}
                    allowClear
                    placeholder={t('Select')}
                    onChange={onChangeAssignee}
                    options={assigneeFilter?.map((option) => ({ value: option.id, label: option.fullName }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider className='my-5' style={{ borderColor: '#d9d9d9' }} />
            <Row gutter={16} align='bottom' className='mb-5'>
              <Col span={24}>
                <Form.Item className='filter-form__item' name='startDate' label={t('Material Delivery Inspection date range')}>
                  <DatePickerRange style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} align='bottom' className='mb-5'>
              {/*<Col span={12}>*/}
              {/*  <Form.Item name='zoneId' className='filter-form__item' label={t('MAR Zoning')} rules={[{ required: false, message: '' }]}>*/}
              {/*    /!**/}
              {/*        <SearchSelect*/}
              {/*          placeholder={t('Select Zoning')}*/}
              {/*          defaultValue={zones?.find((zone) => zone.parentId === undefined)?.id || ''}*/}
              {/*          options={zones?.map((option) => ({*/}
              {/*            value: option.id,*/}
              {/*            label: option.name,*/}
              {/*          }))}*/}
              {/*        />*/}
              {/*        *!/*/}
              {/*    <TreeSelect*/}
              {/*      multiple*/}
              {/*      maxTagCount='responsive'*/}
              {/*      style={{ width: '100%' }}*/}
              {/*      value={zoneUpdated}*/}
              {/*      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}*/}
              {/*      treeData={zonesTreeData}*/}
              {/*      placeholder={t('Select Zoning')}*/}
              {/*      treeDefaultExpandAll*/}
              {/*      onChange={onChangeZone}*/}
              {/*    />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              <Col span={24}>
                {' '}
                <Form.Item className='filter-form__item' name='issueStatusId' label={t('Status')}>
                  <Select
                    allowClear
                    // showSearch
                    mode='multiple'
                    maxTagCount='responsive'
                    placeholder={t('Select')}
                    options={issueStatuses?.map((option) => ({
                      value: option.id,
                      label: <Tag color={MDI_STATUS_COLOR[option.code]}>{t(option.name)}</Tag>,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      }
      trigger='click'
      placement='bottomRight'
      open={isOpenFilter}
      onOpenChange={() => {
        setIsOpenFilter(!isOpenFilter);
      }}
    >
      <Button
        onClick={() => {
          setIsOpenFilter(!isOpenFilter);
        }}
        icon={<FilterOutlined />}
        style={{ color: isFiltering ? token.colorPrimary : '', borderColor: isFiltering ? token.colorPrimary : '' }}
      >
        {t('Filter')}
      </Button>
    </Popover>
  );
}
